<template>
  <div class="vertical" style="width: 330px;">
    <section class="w-full">
      <div v-if="isMultiSelect" class="square" :class="{ selected: selected && !privacyMode, 'privacy-selected': selected && privacyMode }">
        <i v-if="selected" class="fas fa-check selected-check"></i>
      </div>
      <div v-else class="circle">
        <div v-if="selected" class="selected-circle" :class="privacyMode ? 'pink' : 'purple'"></div>
      </div>

      <i v-if="iconClass" :class="iconClass"></i>

      <strong class="w-auto truncate">{{ label }}</strong>
      <small v-if="editableId">
        <router-link
          @click.native.stop="view"
          :to="fullPath"
          :class="privacyMode ? 'text-pink-600 hover:text-pink-500' : 'text-indigo-600 hover:text-indigo-500'"
          class="transition duration-150 ease-in-out"
          >Edit</router-link
        >
      </small>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PropertyComponent from "../../views/onboarding/deal/property";
import RegionComponent from "../../views/onboarding/onboarding-region";

export default {
  props: ["iconClass", "label", "isMultiSelect", "selected", "editableId", "editableType"],
  computed: {
    ...mapState(["modal", "route"]),
    ...mapGetters(["privacyMode"]),
    fullPath() {
      return this.route.fullPath;
    }
  },
  methods: {
    view() {
      let mounter, component;
      let propertiesModal = false;

      switch (this.editableType) {
        case "onboardingRegionList":
          propertiesModal = this.modal ? _.cloneDeep(this.modal) : false;
          mounter = "mountRegion";
          component = RegionComponent;
          break;
        case "intelRegionList":
          propertiesModal = _.cloneDeep(this.modal); // obtains the current component
          mounter = "mountRegion";
          component = RegionComponent;
          break;
        case "dealInputPropertyList":
        case "intelPropertyList":
          propertiesModal = _.cloneDeep(this.modal); // obtains the current component
          mounter = null;
          component = PropertyComponent;
          break;
      }

      if (mounter) {
        this.$store.commit(mounter, { id: this.editableId });
      }

      setTimeout(() => {
        this.$store.commit("openModal", {
          component: component,
          props: {
            regionId: this.editableId,
            propertyId: this.editableId
          },
          afterClose: propertiesModal
        });
      }, 200);
    }
  }
};
</script>

<style lang="scss" scoped>
.vertical {
  display: flex;
  align-items: center;
  section {
    display: flex;
    align-items: center;
    padding: 5px;
    i {
      font-size: 12px;
      &.privacy-active {
        color: #d61f69;
      }
      &.active {
        color: #5850ec;
      }
    }
    > i {
      margin-right: 10px;
    }
    strong {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      margin-right: 10px;
    }
    .circle {
      border: 1px solid #b7b7b7;
      border-radius: 50px;
      background: #fff;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      .selected-circle {
        border: none;
        border-radius: 50px;
        width: 15px;
        height: 15px;
        &.pink {
          background-color: #d61f69;
        }
        &.purple {
          background-color: #5850ec;
        }
      }
    }
    .square {
      border: 1px solid #b7b7b7;
      border-radius: 5px;
      background: #fff;
      width: 20px;
      min-width: 20px;
      height: 20px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.privacy-selected {
        background-color: #d61f69;
      }
      &.selected {
        background-color: #5850ec;
      }
      .selected-check {
        font-size: 12px;
        color: #fff;
      }
    }
  }
}
</style>
