<template>
  <div v-if="creatingBackchannel || backchannel" :class="propertyFollowing ? '' : 'bg-gray-100'" class="relative flex-shrink-0 flex" style="z-index: 2;">
    <div class="flex-1 px-4 flex flex-col" :class="{ 'pt-4': isMobile && propertyFollowing, 'pt-1': !isMobile && propertyFollowing, 'pb-4': isMobile, 'pb-1': !isMobile }">
      <div class="w-full flex md:ml-0">
        <button @click="newIntel" type="button" v-tooltip="'Post intel'" :class="privacyMode ? 'hover:bg-pink-500 focus:border-pink-300 focus:ring-pink active:bg-pink-700' : 'hover:bg-indigo-500 focus:border-indigo-300 focus:ring-indigo active:bg-indigo-700'" class="relative inline-flex items-end px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-400 hover:text-white focus:z-10 focus:outline-none">
          <svg v-if="privacyMode" class="-ml-1 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
            <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
          </svg>
          <svg v-else class="-ml-1 h-5 w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"/>
          </svg>
        </button>
        <form @submit.prevent="sendMessage" class="-ml-px relative inline-flex flex-grow focus-within:z-10">
          <resizable-textarea>
            <textarea
              v-if="isMobile"
              v-focus
              v-model="message"
              rows="1"
              type="text"
              autocomplete="new-password"
              id="backchannel_message_field"
              class="resize-none block w-full rounded-none pl-3 sm:text-sm sm:leading-5"
              :placeholder="placeholder"
            />
            <textarea
              v-else
              v-focus
              v-model="message"
              @keydown.enter.exact.prevent
              @keyup.enter.exact="sendMessage"
              rows="1"
              type="text"
              autocomplete="new-password"
              id="backchannel_message_field"
              class="resize-none block w-full rounded-none pl-3 sm:text-sm sm:leading-5"
              :placeholder="placeholder"
            />
          </resizable-textarea>
        </form>
        <button
          v-tooltip="sendHelp"
          @click="sendMessage"
          type="button"
          class="-ml-px relative inline-flex items-end px-4 py-2 border text-sm leading-5 font-medium rounded-r-md"
          :class="complete ? 'border-transparent text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:ring-green focus:border-green-700 active:bg-green-700' : 'border-gray-300 text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700'"
        >
          <svg class="h-4 w-4" :class="complete ? 'text-white' : 'text-gray-400'" viewBox="0 0 512 512" fill="currentColor">
            <path d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"/>
          </svg>
        </button>
      </div>
      <span v-if="!isMobile" class="w-full flex py-1 justify-end text-gray-500" style="font-size:0.7em;">
        <span><strong class="font-bold">Return</strong> to send</span>
        <span class="ml-2">
          <strong class="font-bold">Shift + Return</strong> to add a new line
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ResizableTextarea from "../resizable-textarea";

export default {
  components: { ResizableTextarea },
  props: ["propertyFollowing"],
  data() {
    return {
      message: ""
    };
  },
  computed: {
    ...mapState(["currentUser", "adminMode", "route", "newBackchannel"]),
    ...mapGetters(["backchannels", "backchannelTokenParam", "privacyMode"]),
    creatingBackchannel() {
      return this.route.path === "/backchannel/new";
    },
    queryContentType() {
      return _.get(this.route, "query.type", null);
    },
    queryContentId() {
      return _.get(this.route, "query.content", null);
    },
    privateAudience() {
      return _.get(this.newBackchannel, "privateAudience", []);
    },
    hasMessage() {
      return this.message !== "" && _.trim(this.message) !== "";
    },
    hasRecipients() {
      return (
        this.privateAudience.length > 0 || this.hasTeam || this.hasProperty
      );
    },
    isWatchlistProperty() {
      return this.queryContentType === "property";
    },
    hasProperty() {
      return _.get(this.newBackchannel, "propertyFollowing.id", null);
    },
    hasTeam() {
      return _.get(this.newBackchannel, "team.id", null);
    },
    hasContent() {
      return _.get(this.newBackchannel, "contentToken", null);
    },
    complete() {
      if (this.creatingBackchannel) {
        return (
          this.hasRecipients &&
          (this.hasMessage || this.hasContent || this.isWatchlistProperty)
        );
      } else {
        return this.hasMessage;
      }
    },
    sendHelp() {
      return this.complete ? "Send message" : "";
    },
    placeholder() {
      if (
        this.isWatchlistProperty ||
        (this.creatingBackchannel && this.hasContent)
      ) {
        return "Optional message";
      } else {
        return `Private message ${this.counterpartName}`;
      }
    },
    backchannel() {
      return (
        _.find(this.backchannels, { token: this.backchannelTokenParam }) ||
        _.find(this.currentUser.backchannels, {
          token: _.get(
            this.propertyFollowing,
            "backchannelToken",
            this.backchannelTokenParam
          )
        })
      );
    },
    counterpartName() {
      if (this.backchannel) {
        return this.$options.filters.channelName(this.backchannel);
      } else {
        return "";
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    var self = this;

    setTimeout(() => {
      if (self.privateAudience.length > 0) {
        document.getElementById("backchannel_message_field").focus();
      }
    }, 50);
  },
  methods: {
    newIntel() {
      this.$router.push({
        path: "/intel/new/privacy",
        query: { nextUrl: "/intel/new/1" }
      });
    },
    sendMessage() {
      if (this.complete) {
        if (this.creatingBackchannel) {
          if (this.isWatchlistProperty) {
            this.$store.dispatch("inviteToWatchlistProperty", {
              message: this.hasMessage ? this.message : null,
              propertyFollowingId: this.queryContentId
            });
          } else {
            this.$store.dispatch("sendNewBackchannelMessage", {
              message: this.hasMessage ? this.message : null
            });
          }
        } else {
          this.$store.dispatch("sendExistingBackchannelMessage", {
            message: this.message,
            backchannelToken: this.backchannel.token
          });
        }

        this.message = "";
        document
          .getElementById("backchannel_message_field")
          .setAttribute("style", "height:auto;overflow-y:hidden;");

        if (!!analytics && !this.adminMode) {
          analytics.track("Sent private channel message", {
            yearWeek: this.$store.state.yearWeek
          });
        }
      } else if (this.creatingBackchannel) {
        if (this.hasRecipients) {
          this.$store.dispatch("flash", "Please input a message.");
        } else {
          this.$store.dispatch("flash", "Please choose someone to send to.");
        }
      } else {
        this.$store.dispatch("flash", "Please input a message.");
      }
    }
  }
};
</script>
