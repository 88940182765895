<template>
  <div v-if="mapTarget && shouldDisplayMap" :id="containerId" class="w-full h-full" />
</template>

<script>
import { mapGetters, mapState } from "vuex";
import EditRegion from "../../views/onboarding/onboarding-region";
/* global L */

export default {
  props: ["target", "redrawRegions", "localRegions"],
  data() {
    return {
      map: null,
      featureGroup: null,
      shouldDisplayMap: true,
      random: Math.random()
    };
  },
  computed: {
    ...mapState(["activeTarget", "route", "modal", "currentUser"]),
    ...mapGetters([
      "allTargetDeals",
      "contactTokenParam",
      "targetTokenParam",
      "signedIn"
    ]),
    path() {
      return this.route.path;
    },
    containerId() {
      return `${this.targetToken || "market-map"}-${this.random}`;
    },
    accessible() {
      return (
        this.mapTarget.authored ||
        (this.currentUser.admin && !this.localRegions)
      );
    },
    mapTarget() {
      return !!this.localRegions || this.target || this.activeTarget;
    },
    targetToken() {
      return _.get(this.mapTarget, "token", null);
    },
    targetRegions() {
      return this.localRegions || this.mapTarget.regions;
    },
    targetProperties() {
      if (this.allTargetDeals) {
        const rawProperties = this.allTargetDeals.flatMap(deal => {
          if (!deal.confidential || deal.dealTeam) {
            return deal.properties.map(p => Object.assign({}, p, { targetAuthorParticipating: deal.targetAuthorParticipating }));
          } else {
            return [];
          }
        });
        const targetRegionIds = this.targetRegions.map(r => r.id);

        return rawProperties.filter(property => {
          const propertyRegionIds = property.regions.map(r => r.id);

          return _.intersection(targetRegionIds, propertyRegionIds).length > 0;
        });
      } else {
        return [];
      }
    }
  },
  watch: {
    targetRegions: {
      handler() {
        this.clearMapLayers();
        this.displayRegions();
      },
      deep: true
    }
  },
  mounted() {
    this.setupMap();
    this.displayRegions();
  },
  methods: {
    setupMap() {
      if (this.map) {
        this.map.remove();
        this.map = null;
        this.featureGroup = null;
      }

      this.map = L.map(this.containerId, {
        center: [37.0902, -95.7129],
        zoom: 4,
        scrollWheelZoom: false,
        fullscreenControl: true
      });

      L.tileLayer
        .grayscale("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          fadeAnimation: false
        })
        .addTo(this.map);

      var self = this;

      this.map.on("fullscreenchange", function() {
        if (self.map.isFullscreen()) {
          self.map.scrollWheelZoom.enable();
        } else {
          self.map.scrollWheelZoom.disable();
        }
      });

      this.featureGroup = L.featureGroup();
    },
    clearMapLayers() {
      this.featureGroup.eachLayer(layer => {
        this.map.removeLayer(layer);
        this.featureGroup.removeLayer(layer);
      });
    },
    displayRegions() {
      this.targetRegions.forEach(region => {
        const layer = this.mapRegion(region).addTo(this.map);

        this.featureGroup.addLayer(layer);
      });

      this.map.fitBounds(this.featureGroup.getBounds(), { maxZoom: 15 });
    },
    mapRegion(region) {
      let layer;

      switch (region.shape) {
        case "circle":
          layer = L.circle(region.center, {
            radius: _.toNumber(region.radius),
            interactive: this.accessible,
            color: "#4338CA",
            stroke: true,
            fill: true
          });
          break;
        case "rectangle":
          layer = L.rectangle(region.coordinates, {
            interactive: this.accessible,
            color: "#4338CA",
            stroke: true,
            fill: true
          });
          break;
        case "polygon":
          layer = L.polygon(region.coordinates, {
            interactive: this.accessible,
            color: "#4338CA",
            stroke: true,
            fill: true
          });
          break;
        default:
          console.log("invalid shape");
      }

      const self = this;

      if (this.accessible) {
        let marketCardModal = false;

        if (this.modal) {
          marketCardModal = _.cloneDeep(this.modal); // obtains the current component
          marketCardModal.props.target = this.mapTarget; // allows changes to flow through
        }

        layer.on("click", e => {
          self.$store.commit("mountRegion", { region });
          setTimeout(() => {
            self.$store.commit("openModal", {
              component: EditRegion,
              props: {
                regionId: region.id
              },
              afterClose: marketCardModal
            });
          }, 200);
        });
      }

      return layer;
    }
  }
};
</script>
