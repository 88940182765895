<template>
  <span @click="toggleAlert" v-tooltip="toggleHelp" role="checkbox" tabindex="0" :aria-checked="following.subscribed" :class="following.subscribed ? 'bg-indigo-600' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring">
    <span aria-hidden="true" :class="following.subscribed ? 'translate-x-5' : 'translate-x-0'" class="relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200">
      <span :class="following.subscribed ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity">
        <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
          <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
      <span :class="following.subscribed ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity">
        <svg class="h-3 w-3 text-indigo-600" viewBox="0 0 20 20" fill="currentColor">
          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
        </svg>
      </span>
    </span>
  </span>
</template>

<script>
import api from "../../api";

export default {
  props: ["following"],
  computed: {
    property() {
      return this.following.property;
    },
    toggleHelp() {
      if (this.following.subscribed) {
        return "Click to disable email alerts";
      } else {
        return "Click to enable to email alerts";
      }
    }
  },
  methods: {
    toggleAlert() {
      if (this.following.subscribed) {
        this.unsubscribe();
      } else {
        this.subscribe();
      }
    },
    subscribe() {
      api
        .post(`followed_property_notifications/${this.following.id}`)
        .then(json => {
          this.$store.dispatch(
            "flash",
            `Email alerts enabled for ${this.property.name}`
          );
          this.$store.commit("setRefetchWatchlist", true);
          this.$emit("refetch");
        });
    },
    unsubscribe() {
      api
        .delete(`followed_property_notifications/${this.following.id}`)
        .then(json => {
          this.$store.dispatch(
            "flash",
            `Email alerts disabled for ${this.property.name}`
          );
          this.$store.commit("setRefetchWatchlist", true);
          this.$emit("refetch");
        });
    }
  }
};
</script>
