<template>
  <vue-markdown>
# TOWER HUNT TERMS OF SERVICE
Last modified: November 15, 2020

## 1. Introduction
Welcome to Tower Hunt. Tower Hunt is a commercial real estate tool available for you to access online as a software as a service (“Service(s)” or “Tower Hunt”) provided by Pinevale, Inc. (“Pinevale, Inc.”, “we” or “us”). These Tower Hunt Terms of Service (“Terms”) cover your use of and access to the Service and your content in the Service. Your use of Tower Hunt requires that you agree to the Terms. Please read them carefully. If you do not understand the Terms, or do not accept any part of them, then you should not use the Service.

## 2. Your use of Tower Hunt
**Age Restrictions.** In order to use Tower Hunt you must be 13 years of age or older. If you are 13 or older but under 18 years of age, you must have your parent or legal guardian’s permission to use Tower Hunt and to accept the Terms.

**Your Conduct.** You may use Tower Hunt only as permitted by law, including applicable export and re-export control laws and regulations. You are responsible for your conduct and your content stored in Tower Hunt. We may review your conduct and content in Tower Hunt for compliance with the Terms. You shall not permit or assist others to access or use the Services for any purpose other than as permitted herein. You shall not, directly or indirectly, (i) reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code or underlying ideas or algorithms of the Services; (ii) modify, translate, or create derivative works based on the Services; (iii) rent, lease, distribute, sell, resell, assign, or otherwise transfer rights to the Services; (iv) use the Services for time-sharing or service bureau purposes; (v) modify, remove, or obscure any copyright, trademark or other proprietary rights notices from the Services; (vi) publish or disclose to third parties any evaluation of the Services without our prior written consent, (vii) use the Services in order to build a competitive product or service, or (viii) copy any features, functions, or graphics of the Services.

You agree that you will not post, upload, publish, submit or transmit any content that: (i) infringes, misappropriates or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy; (ii) violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability; (iii) is fraudulent, false, misleading or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive; (v) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (vi) is violent or threatening or promotes violence or actions that are threatening to any person or entity; (vii) promotes illegal or harmful activities or substances or (viii) breaches any confidentiality obligation or other contractual obligation that you have (or that the company or other legal entity you represent has) to a third party.

**Your Content.** Tower Hunt allows you to upload, submit, store, send and receive content. You retain ownership of any intellectual property rights that you hold in that content. In short, what belongs to you stays yours.

When you upload, submit, store, send or receive content to Tower Hunt, you give us a worldwide, royalty-free, non-exclusive, limited term license to use, process, host, store, display, transmit, reproduce, modify, create derivative works of (such as those resulting from translations, adaptations or other changes we make so that your content works better with our services) your content in order to perform the Service. You represent to us that you have the necessary rights to grant us this license for any content that you submit to Tower Hunt.

**Feedback about the Service.** During your use of the Service, you may provide certain comments, suggestions, enhancement requests, feedback, and other information to us about the Service (collectively “Feedback”). You acknowledge and agree that Pinevale, Inc. owns all such Feedback and any related intellectual property rights therein. We reserve the right to retain and use any such Feedback in current or future products or services without further compensation to you. To the extent such Feedback is protectable under intellectual property laws, you agree to cooperate with us as needed to obtain such protection as we may desire and you agree to assign your rights to such Information to us.

**Announcements.** In connection with your use of Tower Hunt, we may send you service announcements, administrative messages, and other information. You may opt out of some of those communications.

**Our Tower Hunt Services.** Tower Hunt, Inc. owns and reserves all rights, title, and interest to and in the Services, including all related intellectual property rights therein. Using Tower Hunt does not give you ownership of any intellectual property rights in Tower Hunt or the content you access, including but not limited to algorithms and mathematical analyses. You may not use content from Tower Hunt unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in Tower Hunt. Don’t remove, obscure, or alter any legal notices displayed in or along with Tower Hunt. We may change the Services at any time.

## 3. How Bounties Work
**Most of our Terms of Service explain your relationship with Tower Hunt. This section is different—it explains the relationship between fulfillers and requesters of Tower Hunt bounties, and who’s responsible for what. This is what you’re agreeing to when you request or bid on a Tower Hunt bounty.**

Tower Hunt provides a funding platform for commercial real estate information requests. When a requester creates a bounty on Tower Hunt, they’re inviting other people to form a contract with them. Anyone who bids on a bounty is accepting the requester’s offer, and forming that contract. Tower Hunt is not a party to that contract—the contract is a direct legal agreement between fulfillers and requesters.

Here are the terms that govern when you request or bid on a Tower Hunt bounty:

When a bounty bid is accepted, the fulfiller must fulfill the bounty request. Once a fulfiller has done so, they’ve satisfied their obligation to the requester.

If a fulfiller’s deliverable includes any content from a third party service, the fulfiller must ensure that it is specifically permitted to use such third party content under the third party service’s terms of use. Authorization must be provided upon request.

Throughout the process, fulfillers owe the requester a high standard of effort, honest disclosure, and a dedication to fulfilling the request. At the same time, requesters must understand that they’re not buying something when they accept a bid—they’re helping to create something new, not ordering something that already exists. There’s a chance something could happen that prevents the fulfiller from being able to finish the requested deliverable as promised. The fulfiller is solely responsible for fulfilling the promises made in their bid. If they’re unable to satisfy these terms, they may be subject to legal action by backers.

## 4. How Bounty Funding Works
This section goes over the details of requesting and fulfilling bounties—things like how money gets collected and whether accepted bids can be changed or canceled.

These are the terms that apply when you’re requesting a bounty:

You’re only charged if the bounty receives bids, you accept a bid, and the bidder fulfills the bounty. You’ll provide your payment information when you accept a bid, but you won’t be charged. Your payment will only be collected if the bidder’s provided deliverable is considered fulfilled via approval by Tower Hunt. The exact amount of the bid you accepted is the amount Tower Hunt will collect. If the bounty isn’t fulfilled, you won’t be charged, no funds will be collected, and no money will change hands.

In some cases we’ll reserve the charge on your card. Tower Hunt and its payment partners may authorize or reserve a charge on your credit card (or whatever payment method you use) for any amount up to the full pledge, at any time between the acceptance of the bid and the collection of funds.

Tower Hunt doesn’t offer refunds. Responsibility for fulfilling the bounty lies entirely with the fulfiller. Tower Hunt doesn’t hold funds on fulfillers’ behalf, cannot guarantee fulfillers’ work, and does not offer refunds.

These are the terms that apply when you’re fulfilling a bounty:

We’ll charge our fees before putting funds in your account. Tower Hunt and its payment partners will subtract fees before transmitting the proceeds of a fulfilled bid.

In some cases we’ll hold a fulfilled bid for up to two weeks (“Challenge Period”) to guard against dishonest and/or bad faith submissions.

Some fulfilled bids can’t be collected, which might reduce the amount of revenue you get. Because some payments can’t be collected—for instance, when a requester’s credit card expires before the bounty is fulfilled, and they don’t provide updated information—we can’t guarantee that the amount of revenue you receive will be exactly equal to the full bid amount minus fees.

We'll help resolve payment-card disputes. If a bounty requester disputes the charge with their card issuer, we'll handle re-presenting the charge to settle the dispute with the card issuer. You’ll be notified that a dispute has been filed, and you’ll be able to provide evidence to help us resolve it in your favor. If the cardholder’s dispute is found valid, you authorize us to charge the credit card number you provided when you started your project for the amount of the chargeback.

## 5. Fees, Payment, Points, Crowdfunding
**Our Fees.** Creating an account on Tower Hunt is free. If you create a request for information (”Bounty”) that is successfully fulfilled, we collect a 20% fee and our payment partners collect fees. Our partners’ fees may vary slightly based on your location.

We will not collect any fees without giving you a chance to review and accept them. We reserve the right to change our fees at any time. Some bid revenue paid by bounty requesters are collected by payment providers. Each payment provider is its own company, and we are not responsible for its performance.

You’re responsible for paying any taxes associated with your use of the Services.

**Payment.** You must provide us with a valid credit card and email address. If we are unable to effect payment via your credit card, we will attempt to notify you via email. We may disable your access to the Services until payment is received. You agree to pay reasonable attorneys' fees and court costs incurred by us to collect any unpaid amounts owed by you. Amounts paid for the Services are not refundable.

**Points.** The Services allow you to earn points (“Points”) by performing certain actions. Points are not real money, do not have monetary value, and may never be redeemed for “real world” money, or other items of monetary value from outside of the Services without our written permission. While we may use terms like “buy”, “earn”, “spend” or “sell” in reference to Points, we do so only for convenience and such terms in no way indicate that Points have monetary value or are real money. You acknowledge that Points are not real currency and are not redeemable for any sum of money from us at any time. We make no guarantee as to the nature, quality or value of the features of the Services.

Points obtained via the Services are provided to you under a limited, personal, revocable, non-transferable (except as specifically provided below), non-sublicensable license to use within the Services. Points may not be transferred (except as specifically provided below) or resold in any manner, including, without limitation, by means of any direct sale or auction service. You have no property interest, right or title in or to any such Points appearing or originating in the Services, or any other attributes associated with use of the Services.

We have no liability for hacking or loss of your Points; provided that we will use commercially reasonable efforts to restore Points in the event of any hacking or loss. We have no obligation to, and will not, reimburse you for any Points or any goods or services obtained via Points that are forfeited due to your violation of these Terms. We reserve the right, without prior notification, to limit the quantity of Points and/or to refuse to provide you with any Points. Price, exchangeability, and availability of Points are determined by us in our sole discretion and are subject to change without notice. You agree that we have the absolute right to manage, distribute, regulate, control, modify, cancel, restrict, terminate and/or eliminate Points as we see fit in our sole discretion, and that we will have no liability for exercising such right. You agree that under no circumstances are we liable to you for any damages or claims that may arise from the loss or use of your Points regardless of the circumstances. You absolve us of any responsibility to maintain or update your Points balance. However, if there is a loss of Points in your account due to technical or operational problems with the Services, we may replenish the lost Points once the loss has been verified. Without limiting any of the foregoing, our maximum liability or responsibility to you is to replenish the Points lost.

You may redeem Points as set forth on the Services. We will, in our sole discretion, determine and communicate the availability and exchange rate for any Points, which may be modified at any time. All redemptions are subject to these Terms and all limitations and requirements stated via the Services. All redemptions of Points are final. Once your Points have been redeemed, they will be subtracted from your Point balance and will not be refunded or returned, except in our sole discretion. We may also issue Points at no charge to users, in our discretion. We may subtract Points from your account if we determine, in our sole discretion, that you did not properly “earn” the Points.

**Crowdfunding.** The Service may include a marketplace that supports crowdfunding of data. For example, we will allow users to pay a specified fee to fund the creation of a specific deliverable and acquire access to that deliverable. You’ll provide your payment information when you pledge to fund the deliverable. All crowdfunding fees are non-refundable.

## 6. Privacy Protection and Confidentiality
**Protecting Your Privacy.** Our Privacy Policy explains how we treat your personal data and protect your privacy when you use Tower Hunt. Notwithstanding the foregoing, you are solely responsible for the content of your data and you represent and warrant that you own or have obtained a license to all of the intellectual property rights subsisting in your data.

**Maintaining confidentiality.** You and we agree to maintain the confidentiality of any non-public information received by the other party during or prior to entering into these Terms, including without limitation, non-public technical, financial and business information, software, demonstration programs, routines, algorithms, computer systems, techniques, documentation, designs, procedures, formulas, inventions, improvements, concepts, records, trade secrets, files, memoranda, reports, drawings, plans, price lists, pricing guidance, customer lists, and/or the like ("Confidential Information"). Confidential Information shall also include any and all information received by the Disclosing Party from any third person that the Disclosing Party is obliged to treat as confidential. Confidential Information shall also include information that a party should know is confidential or proprietary based on the circumstances surrounding the disclosure.

Information that is independently developed by one of us without reference to the other's Confidential Information, or that is generally available to the public without breach of any obligation owed hereunder, or that is known or becomes available to one of us other than through violation of these Terms or applicable law, shall not be considered Confidential Information of the other party. The receiving party agrees not to use Confidential Information for any purpose except as necessary to fulfill its obligations and exercise its rights under these Terms. The receiving party shall protect the secrecy of and avoid disclosure and unauthorized use of the disclosing party's Confidential Information to the same degree that it takes to protect its own confidential information and in no event less than reasonable care. Nothing herein will prevent either party from sharing Confidential Information with financial and legal advisors (“Permitted Recipient”); provided, however, that the Permitted Recipients are bound to confidentiality obligations at least as restrictive as those in these Terms and the receiving party shall remain liable for any breach of confidentiality by a Permitted Recipient.

The receiving party shall promptly notify the disclosing party of any actual or suspected unauthorized disclosure of Confidential Information. If the receiving party is compelled by law to disclose any Confidential Information to a third party, the receiving party shall promptly notify the disclosing party, to the extent legally permitted, and reasonably assist the disclosing party in obtaining a protective order (at the disclosing party’s cost).

The receiving party acknowledges that the Confidential Information may still be under development, or may be incomplete, and that such information may relate to products that are under development or planned for development. The receiving party agrees that the Confidential Information is provided "AS IS". THE DISCLOSING PARTY MAKES NO WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE IN CONNECTION WITH ANY CONFIDENTIAL INFORMATION WHICH IT DISCLOSES HEREUNDER.

## 7. Copyright Protection
We respect the intellectual property rights of others and expect you to do the same. We respond to notices of alleged copyright infringement according to the process set out in the U.S. Digital Millennium Copyright Act. Notifications can be sent to legal@towerhunt.com.

## 8. About Open Source Software in our Services
Some software used in Tower Hunt may be offered under an open source license that we will make available to you. There may be provisions in the open source license that expressly override some of these Terms.

## 9. Modifying and Terminating Services
**Changes to Services.** We may make performance or security improvements, change functionalities or features, or make changes in order to comply with the law. We will provide notice of material changes to Tower Hunt that we reasonably believe will adversely impact your use of Tower Hunt. However, there are times when we will need to make changes to Tower Hunt without giving notice.

**Suspension and Termination.** We may suspend or permanently disable your access to Tower Hunt if you materially or repeatedly violate our Terms. We will give you prior notice of us suspending or disabling your access to Tower Hunt. However, we may suspend or disable your access to Tower Hunt without notice if you are using Tower Hunt in a manner that could cause us legal liability or disrupt other users’ ability to access and use Tower Hunt. We may terminate your access to the Services if your non-payment of any fees owed to us are delinquent by thirty (30) days or more. You agree to provide us with complete and accurate billing and contact information. We may terminate your access to the Services if the billing or contact information is false, fraudulent, or invalid.

**Discontinuation of Tower Hunt.** If we decide to discontinue Tower Hunt, we will give you at least 30 days’ prior notice. After the end of this 30 day period you will not be able to access your content.

## 10. Our Warranties and Disclaimers
We provide Tower Hunt using a reasonable level of skill and care and we hope that you will enjoy using Tower Hunt. But there are certain things that we don’t promise about our Services.

YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE BASIS”. TO THE EXTENT PERMITTED BY LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.

WE DO NOT REPRESENT OR WARRANT THAT (i) THE USE OF THE SERVICES WILL BE SECURE, TIMELY, UNINTERRUPTED, OR ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE OR SOFTWARE, (ii) THE SERVICES OR SUPPORT WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (iii) ERRORS OR DEFECTS WILL BE CORRECTED, OR (iv) THE SERVICES OR SUPPORT ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE SERVICES MAY BE SUBJECT TO LIMITATIONS OR ISSUES INHERENT IN THE USE OF THE INTERNET AND PINEVALE, INC. IS NOT RESPONSIBLE FOR ANY PROBLEMS OR OTHER DAMAGE RESULTING FROM SUCH LIMITATIONS OR ISSUES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES AND SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY FAILURE OR NONPERFORMANCE OF THE SERVICE OR ANY ERROR OR OMISSION IN THE SERVICE AND OUR SOLE OBLIGATION SHALL BE FOR US TO USE COMMERCIALLY REASONABLE EFFORTS TO REMEDIATE THE ERROR OR DEFECT IN THE SERVICE.

## 11. Liability for Tower Hunt
We are not liable to you for special, indirect, incidental, consequential, punitive, exemplary, or other similar damages, including, without limitation, loss revenue or profits, in any way arising out of or related to these Terms even if you have been advised of the possibility of such damages. We are not liable to you for any lost content.

The total liability of Pinevale, Inc. for any claims under these Terms is limited to the amount you paid us to use the Services in the immediately preceding twelve (12) month period giving rise to the claim.

## 12. Business uses of our Services
If you are using our Services on behalf of a business, that business accepts these terms. It will hold harmless and indemnify Pinevale, Inc. and its affiliates, officers, agents, and employees from any claim, suit or action arising from or related to the use of the Services or violation of these terms, including any liability or expense arising from claims, losses, damages, suits, judgments, litigation costs and attorneys’ fees.

## 13. Indemnification
You agree to indemnify and hold us and our subsidiaries, affiliates, officers, directors, agents, partners and employees harmless from any claim or demand (including reasonable attorneys’ fees and any damages award, fine or other amount imposed on us) made by any third party due to or arising out of your content, inappropriate use of the Services, violation of these Terms, your gross negligence, willful misconduct, or violation of law or violation of any rights of another. Your obligations under this subsection include claims arising out of the acts or omissions of your employees and agents, any other person to whom you have given access to the Services, and any person who gains access to the Services as a result of your failure to use reasonable security precautions, even if the acts or omissions of such persons were not authorized by you. We will choose legal counsel to defend the claim, provided that these decisions must be reasonable and must be promptly communicated to you. You must comply with our reasonable requests for assistance and cooperation in the defense of the claim. You must pay reasonable expenses due under this Section as we incur them.

## 14. Laws Governing the Terms
The laws of Massachusetts, U.S.A., excluding its conflict of laws rules, will apply to any disputes arising out of or relating to these Terms or Tower Hunt. All claims arising out of or relating to these Terms or Tower Hunt will be litigated exclusively in the federal or state courts of Boston, Massachusetts, USA, and you consent to personal jurisdiction in those courts.

## 15. About these Terms
We may modify these Terms or any additional terms that apply to Tower Hunt, for example: to reflect changes to Tower Hunt or to the law, custom, or political or economic policy; or in response to guidelines issued by regulators or relevant industry bodies; or to enable Tower Hunt to meet its obligations. You should look at the Terms regularly. We’ll post notice of modifications to these Terms on this page. Changes will not apply retroactively and will be effective immediately. If you do not agree to the modified terms, you should discontinue your use of Tower Hunt.

These Terms control the relationship between Pinevale, Inc. and you. They do not create any third party beneficiary rights. The relationship between us is that of independent contractors and not business partners. Neither of us is the agent for the other and neither of us has the right to bind the other on any agreement with a third party.

If you do not comply with these Terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have (such as taking action in the future). If it turns out that a particular term is not enforceable, this will not affect any other terms.

For information about how to contact Pinevale, Inc., please visit our Contact Us page.
  </vue-markdown>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  components: { VueMarkdown }
};
</script>
