<template>
  <div @mouseenter="highlightRecord" @mouseleave="unhighlightRecord" class="flex space-x-3 p-1 hover:bg-gray-100" :style="highlightedBackgroundColor">
    <span class="inline-flex items-center justify-center h-6 w-6 rounded-md" :style="`background-color: ${fillColor};`">
      <span class="text-xs font-medium leading-none text-gray-900">{{ index }}</span>
    </span>
    <div class="flex-1 space-y-1">
      <div class="flex items-center justify-between space-x-2">
        <h3 class="text-xs font-medium">
          <a @click.prevent.stop="viewDetails" class="text-sm font-medium text-gray-900 hover:underline cursor-pointer"><template v-if="intel.type !== 'tenantInMarket'">{{ intel.type | postTypeAlias }}: </template>{{ intel.name }}</a>
          <template v-if="intel.type === 'developmentNews'">{{ intel.properties | totalSize }}</template>
          <template v-else-if="intel.type === 'tenantInMarket'">{{ intel | totalLeaseSize }}: {{ intel.regions | regionLabels }}</template>
        </h3>
        <p class="flex items-center text-right text-xs whitespace-nowrap text-gray-500">
          <template v-if="intel.type === 'tenantInMarket'">
            <span class="mr-1 inline-flex items-center justify-center h-2 w-2 rounded-full bg-red-400"></span>
            TIM
          </template>
          <time v-else :datetime="intel.newsfeedSortDate">{{ sortDate }}</time>
        </p>
      </div>
      <div v-if="intel.notes" v-html="linkifiedNotes" class="text-xs text-gray-500 whitespace-pre-line dont-break-out line-clamp-3" />
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapState } from "vuex";
import IntelCard from "../cards/market-intel";
import linkifyHtml from "linkifyjs/html";
import responsiveDevelopmentProjectPopup from "../../leaflet-responsive-map-development-project-popup";
import router from "../../router";
import store from "../../store";

export default {
  props: ["intel", "index", "map", "featureGroup", "contextType"],
  data() {
    return {
      mapInternalId: null,
      highlighted: false,
      mapInternalRegionIds: []
    };
  },
  computed: {
    ...mapGetters(["signedIn"]),
    ...mapState(["mapNearbyProperties", "currentUser", "highlightedMapRecord"]),
    highlightedBackgroundColor() {
      if (this.highlighted) {
        return `background-color: #FCE7F3;`;
      } else {
        return "";
      }
    },
    fillColor() {
      const age = moment().diff(this.intel.newsfeedSortDate, "days");

      if (age <= 7) {
        return "#2563EB";
      } else if (age <= 30) {
        return "#3B82F6";
      } else if (age <= 60) {
        return "#60A5FA";
      } else if (age <= 90) {
        return "#93C5FD";
      } else if (age <= 365) {
        return "#BFDBFE";
      } else {
        return "#DBEAFE";
      }
    },
    sortDate() {
      return moment(this.intel.newsfeedSortDate).format("MMM YYYY");
    },
    linkifiedNotes() {
      return linkifyHtml(_.get(this.intel, "notes", ""), {
        defaultProtocol: "https",
        className: "font-medium text-indigo-600 hover:text-indigo-500"
      });
    },
    propertyIds() {
      return _.get(this.intel, "properties", []).map(p => {
        return { id: p.id };
      });
    },
    matchingProperties() {
      return _.intersectionBy(this.mapNearbyProperties, this.propertyIds, "id");
    },
    mapInternalIds() {
      return this.matchingProperties.flatMap(p => p.mapInternalId);
    }
  },
  watch: {
    highlightedMapRecord: {
      handler() {
        if (
          this.highlightedMapRecord.contentType === "intel" &&
          this.highlightedMapRecord.contentToken === this.intel.token
        ) {
          this.highlighted = true;
          this.bringPopupForward();
        } else {
          this.highlighted = false;
        }
      },
      deep: true
    }
  },
  mounted() {
    this.displayPricingPopups();
  },
  methods: {
    highlightRecord() {
      this.bringPopupForward();
      this.$store.commit("setHighlightedMapRecord", {
        contentType: "intel",
        contentToken: this.intel.token,
        mapTool: "strategicContext",
        touching: "listItem"
      });

      if (this.intel.type === "tenantInMarket") {
        this.displayRegions();
      }
    },
    unhighlightRecord() {
      this.$store.commit("setHighlightedMapRecord", {
        contentType: null,
        contentToken: null,
        mapTool: null,
        touching: null
      });

      if (this.intel.type === "tenantInMarket") {
        this.removeRegions();
      }
    },
    bringPopupForward() {
      if (this.mapInternalId) {
        this.featureGroup.getLayer(this.mapInternalId).bringToFront();
      } else {
        this.displayPricingPopups();
      }
    },
    displayPricingPopups() {
      const id = _.head(this.mapInternalIds);
      const marker = this.featureGroup.getLayer(id);

      if (marker) {
        const responsivePopup = responsiveDevelopmentProjectPopup({
          store,
          router,
          marker,
          content: this.intel,
          fillColor: this.fillColor,
          isContext: true
        });

        responsivePopup.setLatLng(marker.getLatLng()).addTo(this.map);
        responsivePopup.bringToBack();
        responsivePopup.on("remove", e => {
          this.mapInternalId = null;
        });

        this.featureGroup.addLayer(responsivePopup);
        this.mapInternalId = this.featureGroup.getLayerId(responsivePopup);
      }
    },
    removeRegions: _.debounce(function() {
      if (!this.highlighted) {
        this.mapInternalRegionIds.forEach(id => {
          let layer = this.featureGroup.getLayer(id);

          this.map.removeLayer(layer);
          this.featureGroup.removeLayer(layer);
        });

        this.mapInternalRegionIds = [];
      }
    }, 5),
    displayRegions() {
      this.intel.regions.forEach(region => {
        const layer = this.mapRegion(region).addTo(this.map);

        this.featureGroup.addLayer(layer);
        this.mapInternalRegionIds.push(this.featureGroup.getLayerId(layer));
      });
    },
    mapRegion(region) {
      switch (region.shape) {
        case "circle":
          return L.circle(region.center, {
            radius: _.toNumber(region.radius),
            interactive: false,
            color: this.fillColor,
            stroke: true,
            fill: true,
            fillOpacity: 0.2
          });
        case "rectangle":
          return L.rectangle(region.coordinates, {
            interactive: false,
            color: this.fillColor,
            stroke: true,
            fill: true,
            fillOpacity: 0.2
          });
        case "polygon":
          return L.polygon(region.coordinates, {
            interactive: false,
            color: this.fillColor,
            stroke: true,
            fill: true,
            fillOpacity: 0.2
          });
        default:
          console.log("invalid shape");
      }
    },
    viewDetails() {
      if (this.signedIn) {
        this.$store.commit("closeSlideover");

        const property = _.head(this.matchingProperties);

        this.$store.commit("openModal", {
          component: IntelCard,
          props: {
            postObject: this.intel,
            property
          }
        });
      } else {
        this.$router.push({
          path: "/sign-in",
          query: { nextUrl: `/open_intel/${this.intel.token}` }
        });
        this.$store.commit("closeModal");
      }
    }
  }
};
</script>
