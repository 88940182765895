<template>
  <div class="border-t border-gray-200 w-full flex" :class="flat ? '' : 'rounded-b-lg'">
    <button @click="viewPremiumContent" type="button" :class="{ 'rounded-b-lg': !flat, 'p-2 text-xs leading-4': compact, 'py-4 text-sm leading-5': !compact }" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center text-white font-medium border border-transparent bg-gradient-to-r from-teal-500 to-green-400 hover:to-green-500 focus:border-green-600 focus:ring-green active:to-green-500 focus:z-10">
      <!-- Heroicon name: currency-dollar -->
      <svg :class="compact ? 'w-4 h-4' : 'w-5 h-5'" class="text-white" viewBox="0 0 20 20" fill="currentColor">
        <path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
      </svg>
      <span class="ml-2">{{ insights }} for this {{ content.newsfeedType }}</span>
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LinkedPurchaseableBrowser from "../purchaseables/linked-purchaseable-browser";
import pluralize from "pluralize";

export default {
  props: ["content", "flat", "compact"],
  computed: {
    ...mapState(["modal"]),
    insights() {
      return pluralize("premium insight", this.content.premiumCount || 0, true);
    }
  },
  methods: {
    viewPremiumContent() {
      let detailCardModal = false;

      if (this.modal) {
        detailCardModal = _.cloneDeep(this.modal); // obtains the current component
      }

      this.$store.commit("openModal", {
        component: LinkedPurchaseableBrowser,
        props: {
          contentType: this.content.newsfeedType || this.content.commentsType,
          contentToken: this.content.token || this.content.id,
          contentName: this.content.name
        },
        afterClose: detailCardModal
      });
    }
  }
};
</script>
