export default [
  {
    order: 1,
    name: "Stabilized",
    value: "Stabilized",
    description: "Durable in-place cash flow with little/nothing to do."
  },
  {
    order: 2,
    name: "Value-Add",
    value: "Value-Add",
    description: "In-place cash flow with upside potential."
  },
  {
    order: 3,
    name: "Distressed",
    value: "Distressed",
    description: "Little/no in-place cash flow."
  },
  {
    order: 4,
    name: "Development",
    value: "Development",
    description: "Land and/or complete redevelopment deals."
  }
];
