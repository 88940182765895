<template>
  <div class="wrapper">
    <heading>Nice to meet you {{ firstName }}! What is your role in CRE?</heading>
    <div class="content-wrap w-full sm:w-auto sm:max-w-xl">
      <section class="horizontal">
        <option-select
          v-for="option in options"
          :key="option.name"
          option-type="onboardingIndustryRole"
          setter-name="setNewUserIndustryRole"
          setter-type="commit"
          :store-record="role"
          :option-record="option"
          layout="horizontal"
          :options-count="options.length" />
      </section>
      <option-description v-if="description" :description="description" />
    </div>
    <button @click="next" type="button" class="cta" :class="{ complete: complete }" tabindex="999">Next</button>
  </div>
</template>

<script>
import industryRoles from "../../industry-roles";
import Heading from "../../components/onboarding/heading";
import OptionDescription from "../../components/onboarding/option-description";
import OptionSelect from "../../components/onboarding/option-select";

export default {
  data() {
    return {
      options: industryRoles
    };
  },
  mounted() {
    if (!this.$store.state.onboardingInProcess) {
      this.$router.push({ path: "/start/1" });
    }
  },
  components: { Heading, OptionSelect, OptionDescription },
  computed: {
    name() {
      return this.$store.state.newUser.name;
    },
    firstName() {
      return _.words(this.name)[0];
    },
    role() {
      return this.$store.state.newUser.industryRole;
    },
    optionData() {
      return _.find(this.options, { value: this.role });
    },
    description() {
      if (this.optionData && this.optionData.description) {
        return this.optionData.description;
      } else {
        return null;
      }
    },
    complete() {
      return this.role !== null;
    }
  },
  methods: {
    next() {
      if (this.complete) {
        this.$router.push({ path: "/start/3" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.content-wrap {
  display: flex;
  flex-direction: column;
}
section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  pointer-events: none;
  &.horizontal {
    flex-wrap: wrap;
  }
  &.vertical {
    flex-direction: column;
    align-items: center;
  }
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
