<template>
  <div id="strategic-map" :class="dynamicClass" :style="dynamicStyle" />
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DealCard from "../cards/deal";
import IntelCard from "../cards/market-intel";
import MapFilterSlideover from "./filter-slideover";
import api from "../../api";
import fetchBoundingBoxProperties from "../../fetch-bounding-box-properties";
import filterControl from "../../leaflet-filter-control";
import innerIconFor from "../../property-reference-inner-icons";
import mapStyles from "../../map-styles";
import responsivePreviewCardPopup from "../../leaflet-responsive-map-popup-preview-card";
import router from "../../router";
import setMapFilters from "../../auto-set-map-filters";
import store from "../../store";
import strategicMapContext from "../../leaflet-strategic-map-context";
import strategicMapFeed from "../../leaflet-strategic-map-feed";
import strategicMapLegend from "../../leaflet-strategic-map-legend";
/* global L */

export default {
  props: ["market", "embedded", "dateOverride"],
  data() {
    return {
      map: null,
      intel: null,
      deal: null,
      propertyFollowing: null,
      featureGroup: L.featureGroup(),
      refreshing: false,
      fitting: false,
      primaryPulsingIcon: L.icon.pulse({
        iconSize: [30, 30],
        color: "#F87171",
        fillColor: "#EF4444",
        heartbeat: 1.5,
        innerIconSize: "1rem"
      })
    };
  },
  computed: {
    ...mapState([
      "mapFilters",
      "route",
      "currentUser",
      "temporaryAccess",
      "mapBoundaryMeta"
    ]),
    ...mapGetters(["signedIn", "hasMapFilters", "mapMode"]),
    strategicMap() {
      return this.mapMode === "strategic";
    },
    automaticModeTransition() {
      if (this.signedIn) {
        return this.currentUser.mapModeTransition === "automatic";
      } else {
        return true;
      }
    },
    maxZoom() {
      // return this.strategicMap ? null : 15;
      return 20;
    },
    dynamicClass() {
      if (this.embedded) {
        return "w-full h-full";
      } else {
        return "w-full";
      }
    },
    dynamicStyle() {
      if (this.embedded) {
        return "";
      } else {
        return "height: calc(100vh - 64px);";
      }
    },
    globalMap() {
      return this.queryless && !this.embedded;
    },
    queryless() {
      return _.isEmpty(this.route.query);
    },
    watchlistProperty() {
      if (this.propertyFollowing) {
        return [this.propertyFollowing.property];
      } else {
        return [];
      }
    },
    watchlistCompetitiveSetProperties() {
      if (this.propertyFollowing && this.propertyFollowing.proximityType === "competitiveSet") {
        return this.propertyFollowing.competitiveSetProperties.map(property => {
          return _.merge(property, { markerType: "competitiveSet" });
        });
      } else {
        return [];
      }
    },
    watchlistProximityRegion() {
      if (this.propertyFollowing && this.propertyFollowing.proximityType === "region") {
        return [this.propertyFollowing.region];
      } else {
        return [];
      }
    },
    watchlistRadiusRegion() {
      if (this.propertyFollowing && this.propertyFollowing.proximityType === "radius") {
        const mileToMeter = 1609.34;

        return [
          {
            shape: "circle",
            center: {
              lat: this.propertyFollowing.property.lat,
              lng: this.propertyFollowing.property.lng
            },
            radius: this.propertyFollowing.radius * mileToMeter
          }
        ];
      } else {
        return null;
      }
    },
    regions() {
      return _.unionBy(
        _.get(this.market, "regions", []),
        _.get(this.tokenMarket, "regions", []),
        _.get(this.myDefaultMarket, "regions", []),
        _.get(this.intel, "regions", []),
        this.watchlistProximityRegion,
        this.watchlistRadiusRegion
      );
    },
    properties() {
      return _.unionBy(
        _.get(this.deal, "properties", []),
        _.get(this.intel, "properties", []),
        this.watchlistProperty,
        this.watchlistCompetitiveSetProperties
      );
    },
    myDefaultMarket() {
      if (
        !this.propertyFollowingId &&
        !this.dealToken &&
        !this.intelToken &&
        !this.marketToken &&
        !this.market
      ) {
        return _.head(this.myMarkets);
      } else {
        return null;
      }
    },
    tokenMarket() {
      return _.find(this.myMarkets, { token: this.marketToken });
    },
    myMarkets() {
      return this.$store.getters.contactTargets(null);
    },
    presetLayer() {
      return _.get(this.route, "query.layer", null);
    },
    propertyFollowingId() {
      return _.get(this.route, "query.watchlist", null);
    },
    dealToken() {
      return _.get(this.route, "query.deal", null);
    },
    intelToken() {
      return _.get(this.route, "query.intel", null);
    },
    marketToken() {
      return _.get(this.route, "query.market", null);
    },
    hasLocation() {
      return this.intel && this.intel.lat && this.intel.lng;
    },
    hasRegions() {
      return this.regions.length > 0;
    },
    hasProperties() {
      return this.properties.length > 0;
    },
    locationOnly() {
      return this.hasLocation && !this.hasRegions && !this.hasProperties;
    },
    hasFilteringFocalRecord() {
      return !!this.deal || !!this.intel || !!this.market || !!this.tokenMarket;
    },
    filteringFocalRecord() {
      return this.deal || this.intel || this.market || this.tokenMarket;
    }
  },
  watch: {
    temporaryAccess: {
      handler() {
        if (
          this.temporaryAccess &&
          this.temporaryAccess === "refetchNearbyRegions"
        ) {
          this.fetchNearbyRegions();
          this.openFilters();
          this.$store.commit("clearTemporaryAccess");
        }
      }
    },
    hasFilteringFocalRecord: {
      handler() {
        if (this.hasFilteringFocalRecord) {
          this.autoSetFilters();
          if (this.signedIn) {
            this.openFilters();
          }
        }
      }
    },
    mapFilters: {
      handler() {
        if (this.map) {
          this.refreshAllLayers({ fit: false });
        }
      },
      deep: true
    },
    regions: {
      handler() {
        if (this.embedded && this.hasFilteringFocalRecord) {
          this.autoSetFilters(true);
        }
        if (!this.tokenMarket) {
          this.setupMap();
          this.refreshAllLayers({ fit: true, embeddedOverride: true });
        }
      },
      deep: true
    },
    properties: {
      handler() {
        if (!this.presetLayer) {
          this.setupMap();
          this.refreshAllLayers({ fit: true });
        }
      },
      deep: true
    },
    hasLocation: {
      handler() {
        this.setupMap();
        this.refreshAllLayers({ fit: true });
      }
    },
    tokenMarket: {
      handler() {
        if (this.tokenMarket && !this.embedded) {
          this.autoSetFilters(true);
          this.setupMap();
          this.refreshAllLayers({ fit: true, embeddedOverride: true });
        }
      },
      deep: true
    },
    presetLayer: {
      handler() {
        if (this.presetLayer) {
          this.$store.commit("clearModal");
          this.$store.commit("clearMapFilters");
          this.$store.commit("setMapNearbyProperties", []);
          this.$store.commit("setMapFocalRecord", null);
          this.applyPresetFilter();
          this.refreshAllLayers({ fit: true });
        }
      }
    },
    propertyFollowingId: {
      handler() {
        this.$store.commit("clearModal");
        this.$store.commit("clearMapFilters");
        this.$store.commit("setMapNearbyProperties", []);
        this.$store.commit("setMapFocalRecord", null);
        this.propertyFollowing = null;
        this.fetchMapFocalRecord();
        this.refreshAllLayers({ fit: true });
      }
    },
    dealToken: {
      handler() {
        this.$store.commit("clearModal");
        this.$store.commit("clearMapFilters");
        this.$store.commit("setMapNearbyProperties", []);
        this.$store.commit("setMapFocalRecord", null);
        this.deal = null;
        this.fetchMapFocalRecord();
        this.refreshAllLayers({ fit: true });
      }
    },
    intelToken: {
      handler() {
        this.$store.commit("clearModal");
        this.$store.commit("clearMapFilters");
        this.$store.commit("setMapNearbyProperties", []);
        this.$store.commit("setMapFocalRecord", null);
        this.intel = null;
        this.fetchMapFocalRecord();
        this.refreshAllLayers({ fit: true });
      }
    },
    strategicMap: {
      handler() {
        this.$store.commit("clearModal");
        this.$store.commit("clearMapFilters");
        this.$store.commit("setMapNearbyProperties", []);
        this.$store.commit("setMapFocalRecord", null);
        this.refreshAllLayers({ fit: false });
      }
    }
  },
  mounted() {
    if (this.signedIn) {
      document.title = "Strategic map | Tower Hunt";
    }

    if (this.hasFilteringFocalRecord) {
      this.autoSetFilters();
      if (this.signedIn) {
        this.openFilters();
      }
    } else if (this.globalMap && this.signedIn) {
      this.openFilters();
    } else if (this.presetLayer && this.signedIn) {
      this.applyPresetFilter();
      this.openFilters();
    }

    this.fetchMapFocalRecord();
  },
  beforeDestroy() {
    this.$store.commit("clearMapFilters");
    this.$store.commit("clearMapBoundaryMeta");
    this.$store.commit("setMapNearbyProperties", []);
    this.$store.commit("setMapNearbyRegions", []);
    this.$store.commit("setMapFocalRecord", null);

    if (this.signedIn) {
      this.$store.dispatch("updateMapMode", { mapMode: "heat" });
    }
  },
  methods: {
    openFilters() {
      if (this.signedIn && this.strategicMap) {
        this.$store.commit("openSlideover", {
          component: MapFilterSlideover,
          props: {
            mapFilter: true
          }
        });
      }
    },
    applyPresetFilter() {
      this.$store
        .dispatch("updateMapMode", { mapMode: "strategic" })
        .then(() => {
          switch (this.presetLayer) {
            case "watchlist":
              this.$store.commit("setMapFilters", {
                sources: [
                  {
                    name: "Watchlist",
                    value: "watchlist"
                  }
                ],
                regions: [],
                deals: [],
                loans: [],
                risks: [],
                uses: [],
                dates: {
                  allDates: true,
                  start: new Date(),
                  end: new Date()
                }
              });
              break;
            default:
              return;
          }
        });
    },
    autoSetFilters(reset = false) {
      if (!this.hasMapFilters || reset) {
        const payload = setMapFilters(
          this.filteringFocalRecord,
          this.currentUser.industryRole,
          this.signedIn,
          this.dateOverride
        );

        if (reset) {
          this.$store.commit("clearMapFilters");
          this.$store.commit("setMapFiltering", true);
        }

        this.$store.commit("setMapFilters", payload);
      }
    },
    fetchMapFocalRecord() {
      if (this.propertyFollowingId) {
        api
          .get(`property_followings/${this.propertyFollowingId}`)
          .then(json => {
            this.propertyFollowing = json.data;
            this.$store.commit("setMapFocalRecord", json.data);
            this.$store.dispatch("updateMapMode", { mapMode: "strategic" });
          });
      } else if (this.dealToken) {
        api.get(`open_deal/${this.dealToken}`).then(json => {
          this.deal = json.data;
          this.$store.commit("setMapFocalRecord", json.data);
        });
      } else if (this.intelToken) {
        api.get(`open_intel/${this.intelToken}`).then(json => {
          this.intel = json.data;
          this.$store.commit("setMapFocalRecord", json.data);
        });
      } else if (this.hasRegions) {
        var self = this;

        setTimeout(() => {
          self.setupMap();
          self.refreshAllLayers({ fit: true });
        }, 500);
      }
    },
    setMapBoundaryMeta() {
      const bounds = this.map.getBounds();
      const southwest = bounds.getSouthWest();
      const northeast = bounds.getNorthEast();
      const northeastLatLng = [northeast.lat, northeast.lng];
      const southwestLatLng = [southwest.lat, southwest.lng];
      const zoom = this.map.getZoom();

      this.$store.commit("setMapBoundaryMeta", {
        southwestLatLng,
        northeastLatLng,
        zoom
      });
    },
    setupMap() {
      if (!this.map) {
        this.map = L.map("strategic-map", {
          center: [37.0902, -95.7129],
          zoom: 4,
          maxZoom: this.maxZoom,
          scrollWheelZoom: !L.Browser.mobile,
          fullscreenControl: false
        });

        var self = this;

        this.map.on("moveend", function() {
          self.zoomBasedMapModeToggle();
          if (!self.refreshing && !self.fitting) {
            self.debouncedRefreshAllLayers({ fit: false });
          }
        });

        var roadMutant = L.gridLayer.googleMutant({
          type: "roadmap",
          styles: mapStyles.styles
        });

        var satMutant = L.gridLayer.googleMutant({
          type: "satellite",
          styles: []
        });

        var hybridMutant = L.gridLayer.googleMutant({
          type: "hybrid",
          styles: []
        });

        var transitMutant = L.gridLayer.googleMutant({
          type: "roadmap",
          styles: []
        });

        transitMutant.addGoogleLayer("TransitLayer");

        switch (_.get(this.currentUser, "mapDefaultLayer", "roadmap")) {
          case "roadmap":
            roadMutant.addTo(this.map);
            break;
          case "satellite":
            satMutant.addTo(this.map);
            break;
          case "hybrid":
            hybridMutant.addTo(this.map);
            break;
          case "transit":
            transitMutant.addTo(this.map);
            break;
        }

        L.control
          .layers(
            {
              Base: roadMutant,
              Satellite: satMutant,
              Hybrid: hybridMutant,
              Transit: transitMutant
            },
            {},
            {
              collapsed: true
            }
          )
          .addTo(this.map);

        const filterButton = filterControl({ store, router });

        filterButton.addTo(this.map);
        strategicMapLegend({ store, router });
        L.control.strategicMapLegend().addTo(this.map);

        if (!L.Browser.mobile) {
          strategicMapContext({ store, router, featureGroup: this.featureGroup });
          L.control.strategicMapContext().addTo(this.map);

          if (this.signedIn) {
            strategicMapFeed({ store, router, featureGroup: this.featureGroup });
            L.control.strategicMapFeed().addTo(this.map);
          }
        }

        this.popupListener = function(event) {
          var target = event.target,
            tagName = target.tagName,
            popup = self.map._popup;

          if (tagName === "IMG" && popup) {
            popup.update();
          }
        };

        document
          .querySelector(".leaflet-popup-pane")
          .addEventListener("load", this.popupListener, true);
      }
    },
    zoomBasedMapModeToggle() {
      const oldZoom = this.mapBoundaryMeta.zoom;
      const newZoom = this.map.getZoom();

      if (this.automaticModeTransition && oldZoom <= 15 && newZoom >= 16) {
        this.$store.dispatch("updateMapMode", { mapMode: "strategic" });
      } else if (
        !this.propertyFollowingId &&
        this.automaticModeTransition &&
        oldZoom >= 16 &&
        newZoom <= 15
      ) {
        this.$store.dispatch("updateMapMode", { mapMode: "heat" });
      }

      this.map.setMaxZoom(this.maxZoom);
    },
    debouncedRefreshAllLayers: _.debounce(function() {
      this.refreshAllLayers({ fit: false, embeddedOverride: false });
    }, 1250),
    refreshAllLayers({ fit = false, embeddedOverride = false }) {
      this.refreshing = true;
      this.clearMapLayers();

      if (this.hasLocation) {
        this.displayLocation({ fit });
      }

      if (this.hasRegions) {
        this.displayRegions({ fit, paint: !!this.propertyFollowingId });
      }

      if (this.mapFilters.regions.length > 0) {
        this.displayFilterRegions(embeddedOverride);
      }

      if (this.hasProperties) {
        this.displayProperties({ fit });
      }

      this.fetchNearbyRegions();
      this.fetchNearby();
      this.setMapBoundaryMeta();
      this.refreshing = false;
    },
    clearMapLayers() {
      this.featureGroup.eachLayer(layer => {
        this.map.removeLayer(layer);
        this.featureGroup.removeLayer(layer);
      });
    },
    fitBounds(fit, maxZoom) {
      if (fit) {
        this.fitting = true;
        this.map.fitBounds(this.featureGroup.getBounds(), { maxZoom });
        var self = this;

        setTimeout(() => {
          self.fitting = false;
        }, 30);
      }
    },
    displayRegions({ fit = false, paint = false }) {
      this.regions.forEach(region => {
        let layer;

        if (paint) {
          layer = this.mapFilterRegion(region).addTo(this.map);
        } else {
          layer = this.mapRegion(region).addTo(this.map);
        }

        this.featureGroup.addLayer(layer);
      });

      const maxZoom =
        this.propertyFollowingId ||
        _.get(this.mapFilters, "regions", []).length > 0
          ? null
          : 15;

      this.fitBounds(fit, maxZoom);
    },
    displayFilterRegions(embeddedOverride = false) {
      this.mapFilters.regions.forEach(region => {
        const layer = this.mapFilterRegion(region).addTo(this.map);

        layer.bindTooltip(region.name, { direction: "auto" });
        this.featureGroup.addLayer(layer);
      });

      const maxZoom =
        this.propertyFollowingId ||
        _.get(this.mapFilters, "regions", []).length > 0
          ? null
          : 15;

      if (embeddedOverride) {
        this.fitBounds(true, maxZoom);
      }
    },
    displayProperties({ fit = false }) {
      this.properties.forEach((property, index, arr) => {
        const marker = L.marker([property.lat, property.lng]);

        this.featureGroup.addLayer(marker);
        property.mapInternalId = this.featureGroup.getLayerId(marker);

        if (fit && index === arr.length - 1) {
          const maxZoom = this.propertyFollowingId ? null : 15;

          this.fitBounds(fit, maxZoom);
        }
      });
    },
    displayLocation({ fit = false }) {
      const label = this.intel.location
        ? this.$options.filters.location(this.intel)
        : this.intel.name;
      const marker = L.marker([this.intel.lat, this.intel.lng], {
        riseOnHover: true,
        icon: this.primaryPulsingIcon
      }).addTo(this.map);

      marker.bindTooltip(label);

      this.featureGroup.addLayer(marker);

      this.fitBounds(fit, this.locationOnly ? 10 : 17);
    },
    mapRegion(region) {
      switch (region.shape) {
        case "circle":
          return L.circle(region.center, {
            radius: _.toNumber(region.radius),
            interactive: false,
            stroke: false,
            fill: false
          });
        case "rectangle":
          return L.rectangle(region.coordinates, {
            interactive: false,
            stroke: false,
            fill: false
          });
        case "polygon":
          return L.polygon(region.coordinates, {
            interactive: false,
            stroke: false,
            fill: false
          });
        default:
          console.log("invalid shape");
      }
    },
    mapFilterRegion(region) {
      switch (region.shape) {
        case "circle":
          return L.circle(region.center, {
            radius: _.toNumber(region.radius),
            interactive: true,
            color: "#4338CA",
            stroke: true,
            fill: true,
            fillOpacity: 0.2
          });
        case "rectangle":
          return L.rectangle(region.coordinates, {
            interactive: true,
            color: "#4338CA",
            stroke: true,
            fill: true,
            fillOpacity: 0.2
          });
        case "polygon":
          return L.polygon(region.coordinates, {
            interactive: true,
            color: "#4338CA",
            stroke: true,
            fill: true,
            fillOpacity: 0.2
          });
        default:
          console.log("invalid shape");
      }
    },
    fetchNearby() {
      fetchBoundingBoxProperties({
        map: this.map,
        router,
        store,
        featureGroup: this.featureGroup,
        focalProperties: this.properties,
        strategic: true,
        heatmap: !this.strategicMap
      });
    },
    fetchNearbyRegions() {
      if (this.signedIn && this.strategicMap && this.map.getZoom() > 8) {
        const bounds = this.map.getBounds();
        const southwest = bounds.getSouthWest();
        const northeast = bounds.getNorthEast();
        const northeastLatLng = [northeast.lat, northeast.lng];
        const southwestLatLng = [southwest.lat, southwest.lng];

        api
          .post(
            `nearby_regions/${southwestLatLng}/${northeastLatLng}?zoom=${this.map.getZoom()}`
          )
          .then(response => {
            this.$store.commit(
              "setMapNearbyRegions",
              response.data.nearbyRegions
            );
          });
      }
    }
  }
};
</script>
