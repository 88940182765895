<template>
  <div class="flex flex-col flex-1 scene">
    <div class="flex flex-col flex-1 bg-white rounded-lg shadow card" :class="flipped ? 'is-flipped' : ''">
      <flippable-card-back v-if="flipped" :card="card" @flip="flip" class="card__face card__face--back" />
      <flippable-card-front v-else :card="card" @flip="flip" class="card__face" />
    </div>
  </div>
</template>

<script>
import FlippableCardBack from "./flippable-card-back";
import FlippableCardFront from "./flippable-card-front";

export default {
  components: { FlippableCardFront, FlippableCardBack },
  props: ["card"],
  data() {
    return {
      flipped: false
    };
  },
  methods: {
    flip() {
      this.flipped = !this.flipped;
    }
  }
};
</script>

<style lang="scss" scoped>
.scene {
  perspective: 1000px;
}
.card {
  transition: all 1s;
  transform-style: preserve-3d;
}
.card__face {
  backface-visibility: hidden;
}
.card__face--back {
  transform: rotateY(180deg);
}
.card.is-flipped {
  transform: rotateY(180deg);
  background-color: transparent;
}
</style>
