<template>
  <div class="space-y-1">
    <li class="relative flex shadow-sm rounded-md">
      <a href="" @click.prevent="viewDetails" class="flex-shrink-0 flex items-center justify-center w-2 bg-gradient-to-r from-teal-500 to-green-400 text-white text-sm leading-5 font-medium rounded-l-md" />
      <!-- <a href="" @click.prevent="viewDetails" :class="`flex-shrink-0 flex items-center justify-center w-16 bg-${randomColor} text-white text-sm leading-5 font-medium rounded-l-md`">
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path v-if="purchaseable.contentType === 'dataSet'" d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z" />
          <path v-else d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
        </svg>
      </a> -->
      <a href="" @click.prevent="viewDetails" v-tooltip="hasPriceReduction ? 'Reduced price available' : ''" class="flex-1 flex items-center border-t border-b border-gray-200 bg-white truncate">
        <div class="flex-1 px-4 py-2 truncate">
          <h2 class="text-gray-900 text-sm font-medium hover:text-gray-600 truncate">{{ purchaseable.name }}</h2>
          <p class="text-gray-500 text-xs truncate">{{ purchaseable.description }}</p>
        </div>
      </a>
      <div class="overflow-visible flex items-center border-t border-r border-b border-gray-200 bg-white rounded-r-md">
        <purchaseable-menu v-if="purchaseable.authored" :purchaseable="purchaseable" />
        <div v-else class="flex flex-col justify-center">
          <button @click="purchaseableClickHandler" type="button" v-tooltip="hasPriceReduction ? 'Reduced price available' : ''" class="inline-flex items-center mr-4 px-3.5 py-1 border border-transparent text-xs font-semibold rounded-full shadow-sm text-indigo-600 bg-gray-200 hover:bg-gray-300 hover:text-indigo-500 focus:outline-none focus:border-gray-700 focus:ring-gray active:bg-gray-300">
            <template v-if="purchaseable.licensed">
              <span class="uppercase">Open</span>
            </template>
            <template v-else-if="purchaseable.roleBasedPrice">
              <svg v-if="hasPriceReduction" xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 mr-1" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clip-rule="evenodd" />
                <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />
              </svg>
              <span>${{ grossPrice | formattedNumber(2) }}<span v-if="isSubscription" class="font-normal">/mo</span></span>
            </template>
            <template v-else>
              <span class="uppercase">Get</span>
            </template>
          </button>
          <span v-if="purchaseable.allPrices.length > 1 && !purchaseable.licensed" class="mt-1 mr-4 text-center" style="font-size: 8px;">+{{ purchaseable.allPrices.length - 1 }} pricing options</span>
        </div>
      </div>
    </li>
    <showcase-visibility-toggle v-if="isAdmin && purchaseable.showcaseLinkedId" :purchaseable="purchaseable" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BuyPurchaseable from "../purchaseables/buy-purchaseable";
import PurchaseableCard from "./purchaseable";
import PurchaseableMenu from "../purchaseables/purchaseable-menu";
import ShowcaseVisibilityToggle from "../purchaseables/showcase-visibility-toggle.vue";
import backgroundColors from "../../background-colors";

export default {
  components: { PurchaseableMenu, ShowcaseVisibilityToggle },
  props: ["purchaseable"],
  data() {
    return {
      backgroundColors: backgroundColors
    };
  },
  computed: {
    ...mapState(["modal"]),
    ...mapGetters(["isAdmin", "signedIn"]),
    randomColor() {
      return _.sample(this.backgroundColors);
    },
    isSubscription() {
      return this.purchaseable.roleBasedPrice.isSubscription;
    },
    netPrice() {
      return _.toNumber(_.get(this.purchaseable, "netPrice", 0));
    },
    grossPrice() {
      return _.toNumber(_.get(this.purchaseable, "roleBasedPrice.price", 0));
    },
    hasPriceReduction() {
      return (
        this.grossPrice !== this.netPrice &&
        !this.purchaseable.licensed &&
        !this.purchaseable.authored
      );
    }
  },
  methods: {
    purchaseableClickHandler() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      if (this.purchaseable.licensed) {
        this.viewDetails();
      } else if (this.purchaseable.roleBasedPrice) {
        this.$store.commit("openModal", {
          component: BuyPurchaseable,
          props: { purchaseable: this.purchaseable },
          afterClose: currentModal
        });
      } else if (this.signedIn) {
        console.log("claim free content");
      } else {
        this.$router.push({
          path: "/sign-in",
          query: { nextUrl: `/market/featured` }
        });
        this.$store.commit("closeModal");
      }
    },
    viewDetails() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      this.$store.commit("openModal", {
        component: PurchaseableCard,
        props: {
          purchaseableId: this.purchaseable.id,
          backgroundColor: this.randomColor
        },
        afterClose: currentModal
      });
    }
  }
};
</script>
