<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
  <div class="bg-white">
    <product-marketing-top-bar />

    <div class="bg-gray-800">
      <main class="lg:relative">
        <div class="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
          <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-white sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
              Leads and cap rates
              <br class="xl:hidden">
              <span class="text-indigo-400">for your off-market assets</span>
            </h2>
            <p class="mt-3 max-w-md mx-auto text-lg text-gray-300 sm:text-xl md:mt-5 md:max-w-3xl">
              Anonymously measure demand for any asset in your portfolio in hours, not weeks.
            </p>
            <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div class="rounded-md shadow">
                <router-link v-if="signedIn" to="/off-market/card-manager" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:border-indigo-600 focus:ring-indigo md:py-4 md:text-lg md:px-10">
                  Setup my property
                </router-link>
                <router-link v-else to="/start/1" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:border-indigo-600 focus:ring-indigo md:py-4 md:text-lg md:px-10">
                  Get started
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="relative bg-gray-100 w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <img class="absolute inset-0 w-full h-full object-contain" src="https://assets.towerhunt.com/site/gaugeMarketInterestTight1.jpg" alt="Dashboard">
        </div>
      </main>

      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="relative py-16 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div class="relative">
            <h4 class="text-2xl leading-8 font-extrabold text-white tracking-tight sm:text-3xl sm:leading-9">
              Get credible feedback anonymously
            </h4>
            <p class="mt-3 text-lg leading-7 text-gray-300">
              Tap into Tower Hunt's network of active, reputable investors for a read on your property. You provide the info, we do the rest.
            </p>

            <ul class="mt-10">
              <li>
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <!-- Heroicon name: lightning-bolt -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-lg leading-6 font-medium text-white">Fast, easy, and reliable</h5>
                    <p class="mt-2 text-base leading-6 text-gray-300">
                      After you tell us about your property, Tower Hunt builds and distributes an anonymized card with key data points investors need to provide a read.
                    </p>
                  </div>
                </div>
              </li>
              <li class="mt-10">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <!-- Heroicon name: chart-pie -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-lg leading-6 font-medium text-white">Exclusive data</h5>
                    <p class="mt-2 text-base leading-6 text-gray-300">
                      You'll receive aggregated data about market interest and approximate stabilized cap rates. Use it to track demand in real-time and to shape opinion internally.
                    </p>
                  </div>
                </div>
              </li>
              <li class="mt-10">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <!-- Heroicon name: badge-check -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-lg leading-6 font-medium text-white">No obligation leads</h5>
                    <p class="mt-2 text-base leading-6 text-gray-300">
                      Interested groups can send you blind messages. Only respond if you want to.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="mt-10 -mx-4 lg:mt-0">
            <img class="relative mx-auto rounded-md" width="400" src="https://assets.towerhunt.com/site/offMarketCard4.jpg" alt="Off-market property snapshot card">
          </div>
        </div>
      </div>
    </div>

    <!-- Logo cloud -->
    <div class="bg-indigo-200 bg-opacity-25">
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="lg:grid lg:grid-cols-2 lg:gap-8">
          <h2 class="max-w-md mx-auto text-3xl leading-9 font-extrabold text-indigo-900 text-center lg:max-w-xl lg:text-left">
            Connect with investors from
          </h2>
          <div class="mt-8 flow-root lg:mt-0 self-center">
            <div class="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
              <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                <img class="h-8" src="https://assets.towerhunt.com/site/Bulfinch_indigo0.png" alt="Bulfinch">
              </div>
              <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                <img class="h-8" src="https://assets.towerhunt.com/site/DavisCompanies_indigo0.png" alt="The Davis Companies">
              </div>
              <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                <img class="h-8" src="https://assets.towerhunt.com/site/Nordblom_indigo0.png" alt="Nordblom">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Branded FAQ -->
    <div class="bg-indigo-800">
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 class="text-3xl leading-9 font-extrabold text-white">
          Frequently asked questions
        </h2>
        <div class="mt-6 border-t border-indigo-400 border-opacity-25 pt-10">
          <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                How does this work?
              </template>
              <template v-slot:secondary>
                We create an anonymized summary of a property that you own. The summary looks like the "card" you see above. Your property is distributed in "packs" of 3-5. Recipients review your property and provide feedback based on their level of interest.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                What if I don't want to sell, finance, or recap my property?
              </template>
              <template v-slot:secondary>
                No problem. These properties are off-market and you have no obligation to deal with the people who review your property. You'll tell us what your deal motivation is during card creation, and that information is communicated with the card to help set expectations.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                What information will I get?
              </template>
              <template v-slot:secondary>
                You'll see how many people were offered your property, how many reviewed the property, and what level of interest people had. Interested parties can send you a blind message. To do so, they must submit an anonymous cap rate estimate. You'll see aggregated cap rate data once enough is available.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                Where does the cap rate data come from?
              </template>
              <template v-slot:secondary>
                Anyone who reviews an off-market card may submit an anonymous good faith cap rate estimate. People who submit a good faith estimate are able to then view the aggregated, anonymized data set once a sufficient number of submissions have been made. The data is displayed in a box plot, which helps show the distribution and any outliers.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                What does it cost?
              </template>
              <template v-slot:secondary>
                Property cards are free to create.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                What do I need to do to create a property card?
              </template>
              <template v-slot:secondary>
                First, get in touch with us from the <router-link to="/off-market/card-manager" class="underline">Card Manager page</router-link>. We'll respond and gather some information from you, which we'll use to create the card. Once you approve the content, the card will be distributed in the next available pack.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                What kinds of properties are supported?
              </template>
              <template v-slot:secondary>
                The initial card design supports apartment properties and single- or multi-tenant industrial, office, lab, medical office, and retail properties. Our product road map includes support for additional property types, type-specific attributes (e.g., clear heights for industrial), and development sites.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                Will people know it's my property?
              </template>
              <template v-slot:secondary>
                No. The cards anonymize the property by generalizing its location and other metrics. In particular, you will have the opportunity to specify how the property's location is described.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                Who will see my property?
              </template>
              <template v-slot:secondary>
                You can distribute the property card to investors and/or brokers who focus on property sales or financings. Recipients are selected based on the locations and property types they cover. Recipients are offered a card pack containing your property. If they open the pack, they'll review the contents one property at a time.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                Where do I view the results?
              </template>
              <template v-slot:secondary>
                <router-link to="/off-market/card-manager" class="underline">The Card Manager page</router-link> lists all of your property cards. Each card has a "Gauge" button; click this to view the results and respond to any messages. Data is updated in real-time, so check it every few days.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                What happens if I reply to a message?
              </template>
              <template v-slot:secondary>
                Once you reply, the conversation moves to a 1:1 private channel and your identity becomes known. The (still anonymized) property card is copied into the private channel for context. It is up to you to determine if/when it is appropriate to identify the property.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                How long will my card be available?
              </template>
              <template v-slot:secondary>
                Cards are eligible to be packaged for 90 days after their most recent update. We recommend issuing updates monthly or quarterly.
              </template>
            </text-accordian>
          </dl>
        </div>
      </div>
    </div>

    <!-- CTA section -->
    <div v-if="!signedIn" class="bg-indigo-50">
      <div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-indigo-900 sm:text-4xl sm:leading-10">
          <span class="block">Ready to make things happen?</span>
          <span class="block text-indigo-600">Create a profile today.</span>
        </h2>
        <div class="mt-8 flex lg:flex-shrink-0 lg:mt-0">
          <div class="inline-flex rounded-md shadow">
            <router-link to="/start/1" class="bg-indigo-600 border border-transparent rounded-md py-3 px-5 inline-flex items-center justify-center text-base leading-6 font-medium text-white hover:bg-indigo-500 focus:outline-none focus:ring">
              Get started for free
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Simple footer -->
    <marketing-footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MarketingFooter from "../components/marketing-footer";
import ProductMarketingTopBar from "../components/product-marketing-top-bar";
import TextAccordian from "../components/text-accordian.vue";

export default {
  components: { MarketingFooter, ProductMarketingTopBar, TextAccordian },
  computed: {
    ...mapGetters(["signedIn"])
  },
  mounted() {
    document.title = "Gauge Demand | Tower Hunt";
  }
};
</script>
