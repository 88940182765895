<template>
  <div>
    <card-form v-if="creating" :post-token="marketIntelToken" @cancel="notCreating" @saved="notCreating" />
    <card-index v-else @create-card="creating = true" @edit-card="editCard" />
  </div>
</template>

<script>
import CardForm from "../components/cards/off-market-card-manager/card-form";
import CardIndex from "../components/cards/off-market-card-manager/card-index";

export default {
  components: { CardForm, CardIndex },
  data() {
    return {
      creating: false,
      marketIntelToken: null
    };
  },
  methods: {
    notCreating() {
      this.creating = false;
      this.marketIntelToken = null;
    },
    editCard(token) {
      this.marketIntelToken = token;
      this.creating = true;
    }
  }
};
</script>
