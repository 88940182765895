<template>
  <tr>
    <td class="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
      <div class="flex items-center space-x-3 lg:pl-2">
        <div v-if="license" v-tooltip="statusHelp" :class="`flex-shrink-0 w-2.5 h-2.5 rounded-full bg-${statusColor}`"></div>
        <a href="" @click.prevent="viewDetails" class="truncate hover:text-gray-600">
          <span>{{ purchaseable.name || "Name" }} <span class="text-gray-500 font-normal">{{ purchaseable.description || "Description" }}</span> </span>
        </a>
      </div>
    </td>
    <td class="px-6 py-3 text-sm leading-5 text-gray-500 font-medium">
      <span v-if="license" class="truncate">${{ license.price | formattedNumber(2) }}<span v-if="license.frequency === 'monthly'" class="text-gray-500 font-normal">/mth</span></span>
    </td>
    <td class="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500 text-right">
      <template v-if="license">{{ license.createdAt | moment("MMMM D, YYYY") }}</template>
    </td>
    <td class="pr-6">
      <purchaseable-menu :purchaseable="purchaseable" :license="license" />
    </td>
  </tr>
</template>

<script>
import { mapState } from "vuex";
import PurchaseableCard from "../../cards/purchaseable";
import PurchaseableMenu from "../purchaseable-menu";
import api from "../../../api";
import backgroundColors from "../../../background-colors";

export default {
  components: { PurchaseableMenu },
  props: ["purchaseable"],
  data() {
    return {
      license: null,
      backgroundColors: backgroundColors
    };
  },
  computed: {
    ...mapState(["modal"]),
    randomColor() {
      return _.sample(this.backgroundColors);
    },
    statusColor() {
      if (this.license.revokedAt) {
        return "gray-400";
      } else if (this.license.cancellationState === "pending") {
        return "yellow-300";
      } else {
        return "green-400";
      }
    },
    statusHelp() {
      if (this.license.revokedAt) {
        return "Inactive";
      } else if (this.license.cancellationState === "pending") {
        return "Cancelling at the end of the current billing period";
      } else {
        return "Active";
      }
    }
  },
  mounted() {
    this.fetchLicense();
  },
  methods: {
    fetchLicense() {
      api.get(`purchaseable_licenses/${this.purchaseable.id}`).then(json => {
        this.license = json.data;
      });
    },
    viewDetails() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      this.$store.commit("openModal", {
        component: PurchaseableCard,
        props: {
          purchaseableId: this.purchaseable.id,
          backgroundColor: this.randomColor
        },
        afterClose: currentModal
      });
    }
  }
};
</script>
