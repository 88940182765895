<template>
  <div class="wrapper">
    <heading>What type of intel?</heading>
    <div class="content-wrap">
      <section class="vertical">
        <option-select
          v-for="option in options"
          :key="option.name"
          option-type="marketIntelType"
          setter-name="setIntelType"
          setter-type="commit"
          :store-record="activeIntel.type"
          :option-record="option"
          layout="vertical"
          :options-count="options.length" />
      </section>
      <option-description v-if="description" :description="description" />
    </div>
    <button @click="next" type="button" class="cta" :class="{ complete: complete && !privacyMode, 'privacy-complete': complete && privacyMode }" tabindex="999">
      <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
        <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
      </svg>
      Next
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../../components/onboarding/heading";
import OptionDescription from "../../../components/onboarding/option-description";
import OptionSelect from "../../../components/onboarding/option-select";
import intelTypes from "../../../intel-types";

export default {
  components: { Heading, OptionSelect, OptionDescription },
  data() {
    return {
      options: intelTypes
    };
  },
  computed: {
    ...mapState(["activeIntel"]),
    ...mapGetters(["privacyMode"]),
    optionData() {
      return _.find(this.options, { value: this.activeIntel.type });
    },
    description() {
      if (this.optionData && this.optionData.description) {
        return this.optionData.description;
      } else {
        return null;
      }
    },
    complete() {
      return this.activeIntel.type !== null;
    }
  },
  mounted() {
    this.$store.commit("concludeDealCreation");
    this.$store.commit("clearIntel");
    this.$store.commit("activateIntelCreation");
  },
  methods: {
    next() {
      if (this.complete) {
        if (this.activeIntel.type === "indirectLiveDeal") {
          this.$router.push({
            path: "/deals/new/1",
            query: { active: true, indirect: true }
          });
          this.$store.commit("clearIntel");
          this.$store.commit("concludeIntelCreation");
        } else if (this.activeIntel.type === "closedDeal") {
          this.$router.push({ path: "/deals/new/1" });
          this.$store.commit("clearIntel");
          this.$store.commit("concludeIntelCreation");
        } else {
          this.$router.push({ path: "/intel/new/2" });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.content-wrap {
  display: flex;
  flex-direction: column;
  width: 330px;
}
section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  pointer-events: none;
  &.horizontal {
    flex-wrap: wrap;
  }
  &.vertical {
    flex-direction: column;
    align-items: center;
  }
}
button.cta {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.privacy-complete {
    background: #d61f69;
    box-shadow: rgba(214, 31, 105, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #f17eb8;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
