<template>
  <div class="relative flex flex-col items-center rounded-lg shadow overflow-hidden" v-observe-visibility="{ callback: createImpression, once: true }">
    <div v-if="suggestion" class="block absolute top-0 right-0 pt-2 pr-2">
      <button @click="dismiss" type="button" v-tooltip="'Dismiss suggestion'" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500">
        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </div>
    <router-link :to="`/contacts/${contact.token}`" class="pt-4">
      <avatar-photo :person="contact" circle-size="20" text-size="2xl" />
    </router-link>
    <div class="flex-1 bg-white p-4 flex flex-col justify-between">
      <div class="flex-1">
        <router-link :to="`/contacts/${contact.token}`" class="block text-center">
          <h3 class="mt-2 text-lg leading-7 font-semibold text-gray-900">
            {{ contact.name }}
          </h3>
          <p class="mt-3 text-sm leading-6 text-gray-500">
            <template v-if="contact.industryRole">{{ contact.industryRole | industryRoleAlias(contact.industrySubRole, contact.token) | capitalize }}</template>
            <template v-else>{{ contact | dealRole("participant") }}</template>
            <template v-if="contact.company && contact.industryRole"> at {{ contact.company }}</template>
            <template v-else-if="contact.company"> ({{ contact.company }})</template>
          </p>
          <p v-if="dealVolume && contact.dealCount > 0" class="mt-3 text-xs leading-6 text-gray-500">
            {{ contact | dealsCount }}: {{ contact | dealsVolume }}
          </p>
        </router-link>
      </div>
      <div class="mt-6 flex justify-center items-center">
        <button v-if="isMarketingList && isProspect" type="button" class="inline-flex items-center mr-3 px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-50 focus:outline-none focus:border-green-300 focus:ring-green active:bg-green-200">
          <svg class="h-5 w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg>
        </button>
        <button v-else-if="isMarketingList" @click="addToMarketingList" type="button" class="inline-flex items-center mr-3 px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:ring-indigo active:bg-indigo-200">
          Add
        </button>
        <span v-else-if="isSelf" class="inline-flex rounded-md shadow-sm">
          <span class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
            Me
          </span>
        </span>
        <span v-else-if="contactable && contact.followedByMe" class="inline-flex shadow-sm rounded-md">
          <button @click="contactInfo" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-indigo focus:border-indigo-600 active:bg-indigo-600">
            <svg class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd" />
            </svg>
            Contact
          </button>
        </span>
        <span v-else-if="contact.followedByMe" class="inline-flex rounded-md shadow-sm">
          <span class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
            Following
          </span>
        </span>

        <span v-else-if="signedIn && contact.targetFollowedConsent" class="relative z-0 inline-flex shadow-sm">
          <button @click="follow" v-tooltip="'Follow without message'" type="button" class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-indigo-300 text-sm leading-5 font-medium bg-indigo-100 text-indigo-700 hover:bg-indigo-50 focus:z-10 focus:outline-none focus:border-indigo-300 focus:ring-indigo active:bg-indigo-200">
            Follow
          </button>
          <button @click="message" v-tooltip="messageTooltip" type="button" class="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-indigo-300 text-sm leading-5 font-medium bg-indigo-100 text-indigo-700 hover:bg-indigo-50 focus:z-10 focus:outline-none focus:border-indigo-300 focus:ring-indigo active:bg-indigo-200">
            <svg class="h-5 w-5 text-indigo-700" fill="currentColor" viewBox="0 0 20 20">
              <path d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd" fill-rule="evenodd" />
            </svg>
          </button>
        </span>

        <button v-else @click="follow" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:ring-indigo active:bg-indigo-200">
          Follow
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AvatarPhoto from "../avatar-photo";
import ContactInfo from "./contact-info";
import FollowWithMessage from "../follow-message";
/* global analytics */

export default {
  components: { AvatarPhoto },
  data() {
    return {
      isProspect: false
    };
  },
  props: ["contact", "suggestion", "dealVolume"],
  computed: {
    ...mapState([
      "currentUser",
      "activeDeal",
      "activeDealMarketing",
      "adminMode"
    ]),
    ...mapGetters(["signedIn", "offeredDealTokenParam"]),
    path() {
      return this.$store.state.route.path;
    },
    isSelf() {
      return this.contact.token === this.currentUser.token;
    },
    isMarketingList() {
      return (
        this.offeredDealTokenParam && _.includes(this.path, "marketing-list")
      );
    },
    hasContactInfo() {
      return (
        (this.contact.phone && this.contact.phone !== "") ||
        (this.contact.marketEmail && this.contact.marketEmail !== "")
      );
    },
    publicEmailOrPhone() {
      return this.hasContactInfo && !this.contact.mutualConnection;
    },
    contactable() {
      return this.contact.mutualConnection || this.publicEmailOrPhone;
    },
    messageTooltip() {
      if (this.contactable) {
        return "Contact this person";
      } else {
        return "Follow with message";
      }
    }
  },
  methods: {
    dismiss() {
      if (this.suggestion) {
        if (this.offeredDealTokenParam) {
          this.$store.dispatch("dismissProspect", {
            contactToken: this.contact.token,
            dealToken: this.activeDeal.token
          });
        } else {
          this.$store.dispatch("dismissContact", this.contact.token);
        }
      }
    },
    addToMarketingList() {
      const invitation = {
        email: this.contact.email,
        token: this.contact.token
      };
      const payload = {
        invitation,
        id: this.activeDealMarketing.id
      };
      var self = this;

      this.$store.commit("creatingDealParticipantInvitation");
      this.$store.dispatch("createDealProspectInvitation", payload);
      setTimeout(() => {
        self.$store.commit("cancelDealParticipantInvitation");
        self.isProspect = true;
      }, 300);
    },
    message() {
      if (this.contactable) {
        this.contactInfo();
      } else {
        this.followWithMessage();
      }
    },
    contactInfo() {
      if (!!analytics && !this.adminMode) {
        analytics.track("Viewed contact info", { yearWeek: this.$store.state.yearWeek });
      }

      this.$store.commit("openModal", {
        component: ContactInfo,
        props: {
          contact: this.contact
        }
      });
    },
    followWithMessage() {
      this.$store.commit("openModal", {
        component: FollowWithMessage,
        props: {
          contact: this.contact
        }
      });
    },
    follow() {
      if (this.signedIn) {
        this.$store.dispatch("followContact", {
          contactToken: this.contact.token
        });

        this.contact.followedByMe = true;
      } else {
        this.$store.commit("setNewUserFollowObject", {
          token: this.contact.token,
          type: "contact"
        });
        this.$router.push({ path: `/start/1` });
      }

      if (analytics) {
        analytics.track("Followed user", {
          yearWeek: this.$store.state.yearWeek
        });
      }
    },
    createImpression(isVisible, entry) {
      if (!this.adminMode && isVisible && this.contact.token) {
        this.$store.dispatch("createContactImpression", this.contact.token).then(() => {
          this.$store.dispatch("loadUnreadCounts");
        });
      }
    }
  }
};
</script>
