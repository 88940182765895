<template>
  <div>
    <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Property Timeline</h2>

    <div class="mt-6 flow-root">
      <div v-if="!loaded" class="-mb-8 flex justify-center">
        <grid-loader :loading="true" size="6px" color="#5850ec" />
      </div>
      <ul v-else-if="timelineContent.length > 0" class="-mb-8">
        <li v-for="(content, index) in timelineContent" :key="content.token">
          <property-timeline-deal v-if="content.newsfeedType === 'deal'" :deal="content" :property="property" :extend-line="index !== timelineContent.length - 1" />
          <property-timeline-intel v-else-if="content.newsfeedType === 'intel'" :intel="content" :property="property" :extend-line="index !== timelineContent.length - 1" />
        </li>
      </ul>
      <div v-else class="text-center">
        <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900">No history</h3>
        <p class="mt-1 text-sm text-gray-500">
          Add deals and intel to build this out.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import GridLoader from "vue-spinner/src/GridLoader.vue";
import PropertyTimelineDeal from "./property-timeline-deal.vue";
import PropertyTimelineIntel from "./property-timeline-intel";
import api from "../api";

export default {
  components: { GridLoader, PropertyTimelineDeal, PropertyTimelineIntel },
  props: ["property"],
  data() {
    return {
      loaded: false,
      timelineContent: []
    };
  },
  mounted() {
    api.get(`property_timelines/${this.property.id}`).then(json => {
      this.timelineContent = json.data;
      this.loaded = true;
    });
  }
};
</script>
