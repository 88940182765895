<template>
  <div v-if="loaded">
    <h3 class="text-base leading-6 font-medium text-gray-900">
      <template v-if="cards.length > 0 && selectable">Choose a payment method</template>
      <template v-else-if="cards.length > 0 && !selectable">Your payment methods</template>
      <template v-else>Add a payment method</template>
    </h3>
    <div v-if="capturingPayment" class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
      <p>
        <template v-if="capturingPayment === 'later'">
          You will not be charged now.
        </template>
      </p>
    </div>
    <div class="mt-5 space-y-2">
      <div v-for="record in cards" :key="record.id" class="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
        <div class="sm:flex sm:items-start">
          <svg v-if="unknownCard(record.card.brand)" class="h-8 w-auto sm:flex-shrink-0 sm:h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <title>{{ record.card.brand }}</title>
            <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
            <path fill-rule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clip-rule="evenodd" />
          </svg>
          <img v-else class="h-8 w-auto sm:flex-shrink-0 sm:h-6 text-gray-600" :src="`https://assets.towerhunt.com/site/${brandLogoFor(record.card.brand)}0.png`" :alt="record.card.brand" />
          <div class="mt-3 sm:mt-0 sm:ml-4">
            <div class="text-sm leading-5 font-medium text-gray-900">
              Ending with {{ record.card.last4 }}
            </div>
            <div class="mt-1 text-sm leading-5 text-gray-600 sm:flex sm:items-center">
              <div>
                Expires {{ record.card.exp_month }}/{{ record.card.exp_year }}
              </div>
              <template v-if="record.id === defaultPaymentMethod">
                <span class="hidden sm:mx-2 sm:inline" aria-hidden="true">
                  &middot;
                </span>
                <div class="mt-1 sm:mt-0">
                  <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-indigo-100 text-indigo-800">
                    Default
                  </span>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div v-if="selectable" class="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
          <span class="inline-flex rounded-md shadow-sm">
            <button @click="select(record)" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
              Choose
            </button>
          </span>
        </div>
      </div>
      <payment-method v-if="cards.length === 0 || addingNew" :cancellable="cards.length > 0" :when="capturingPayment" @refresh-cards="refreshCards" @cancel="addingNew = false" />
      <div class="flex justify-end space-x-2">
        <span v-if="capturingPayment && cards.length > 0 && !addingNew" class="inline-flex rounded-md shadow-sm">
          <button
            @click="addingNew = true"
            type="button"
            v-tooltip="'Add another credit card'"
            class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50"
          >
            Add
          </button>
        </span>
        <span v-else-if="!capturingPayment" class="inline-flex rounded-md shadow-sm">
          <button
            @click="visitCustomerPortal"
            type="button"
            v-tooltip="'Manage payment methods'"
            class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50"
          >
            Manage
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentMethod from "./payment-method";
import api from "../api";

export default {
  components: { PaymentMethod },
  props: ["selectable", "capturingPayment"],
  data() {
    return {
      cards: [],
      defaultPaymentMethod: null,
      loaded: false,
      submitting: false,
      addingNew: false
    };
  },
  mounted() {
    this.refreshCards();
  },
  methods: {
    refreshCards() {
      api.get(`my_payment_methods`).then(json => {
        this.cards = json.data.cards;
        this.defaultPaymentMethod = json.data.defaultPaymentMethod;
        this.addingNew = false;
        this.loaded = true;
      });
    },
    select(record) {
      this.$emit("card-selected", record);
    },
    trimmedLower(str) {
      return str.toLowerCase().replace(/\s/g, "");
    },
    unknownCard(brand) {
      return !_.includes(
        ["americanexpress", "discover", "mastercard", "visa"],
        this.trimmedLower(brand)
      );
    },
    brandLogoFor(brand) {
      return this.trimmedLower(brand);
    },
    visitCustomerPortal() {
      this.$store.dispatch("createCustomerPaymentPortalSession");
    }
  }
};
</script>
