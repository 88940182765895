<template>
  <li class="relative col-span-1 flex shadow-sm rounded-md">
    <div class="flex-shrink-0 flex flex-col items-center justify-center w-16 bg-green-400 text-white text-xs leading-5 font-medium rounded-l-md">
      <div>${{ price.price }}</div>
      <div>{{ price.frequency | priceFrequencyAlias }}</div>
    </div>
    <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
      <div class="flex-1 px-4 py-2 text-sm leading-5 truncate">
        <div class="text-gray-900 font-medium hover:text-gray-600">
          {{ price.industryRole | priceIndustryRoleAlias }}
        </div>
        <p class="text-gray-500 truncate">
          <template v-if="price.name">
            <span>{{ price.name || "Name" }} &middot; <span class="text-gray-400 font-normal">{{ price.description || "Description" }}</span></span>
          </template>
          <template v-else>Updated {{ price.updatedAt | moment("M/D/YYYY") }}</template>
        </p>
      </div>
      <div ref="priceMenu" class="flex-shrink-0 pr-2">
        <button @click="toggleMenu" id="pinned-project-options-menu-0" aria-has-popup="true" class="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100">
          <!-- Heroicon name: dots-vertical -->
          <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
          </svg>
        </button>
        <!--
          Dropdown panel, show/hide based on dropdown state.

          Entering: "transition ease-out duration-100"
            From: "transform opacity-0 scale-95"
            To: "transform opacity-100 scale-100"
          Leaving: "transition ease-in duration-75"
            From: "transform opacity-100 scale-100"
            To: "transform opacity-0 scale-95"
        -->
        <transition
          enter-active-class="duration-100 ease-out"
          enter-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="duration-75 ease-in"
          leave-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
        >
          <div v-show="menuOpen" class="z-10 mx-3 origin-top-right absolute right-10 top-3 w-48 mt-1 rounded-md shadow-lg">
            <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="pinned-project-options-menu-0">
              <div class="py-1">
                <a href="" @click.prevent="editPrice" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Edit</a>
              </div>
              <div class="border-t border-gray-100"></div>
              <div class="py-1">
                <a href="" @click.prevent="deletePrice" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Delete</a>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </li>
</template>

<script>
import Swal from "sweetalert2";
import api from "../../api";
import deleteItemOptions from "../../sweet-alert-delete-item-options";

export default {
  props: ["price", "purchaseable"],
  data() {
    return {
      menuOpen: false
    };
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (this.$refs.priceMenu && !this.$refs.priceMenu.contains(evt.target)) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    editPrice() {
      this.$emit("edit");
      this.menuOpen = false;
    },
    deletePrice() {
      Swal.fire(deleteItemOptions).then(result => {
        if (result.value) {
          api
            .delete(
              `purchaseables/${this.purchaseable.id}/purchaseable_prices/${this.price.id}`
            )
            .then(json => {
              this.$emit("refetch-prices");
            });
        }
      });
    }
  }
};
</script>
