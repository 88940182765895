<template>
  <div class="container mx-auto md:mt-12 mb-4 px-4 sm:px-8 lg:px-10 flex flex-col items-center">
    <iframe :style="embedHeight" :src="file.link" class="h-full w-full bg-gray-50" />
  </div>
</template>

<script>
export default {
  props: ["file"],
  data() {
    return {
      modalHeight: null
    };
  },
  computed: {
    embedHeight() {
      if (this.modalHeight) {
        const heightRatio = this.modalHeight > 400 ? 0.9 : 0.7;

        return `height: ${this.modalHeight * heightRatio}px;`;
      } else {
        return "height: 200px";
      }
    }
  },
  mounted() {
    this.modalHeight = document.getElementById("xl-modal").offsetHeight;

    this.debouncedHeightUpdate = _.debounce(() => {
      this.modalHeight = document.getElementById("xl-modal").offsetHeight;
    }, 100);

    window.addEventListener("resize", this.debouncedHeightUpdate);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedHeightUpdate);
  }
};
</script>
