export default [
  {
    order: 1,
    name: "Appraiser",
    value: "appraiser"
  },
  {
    order: 2,
    name: "Architect",
    value: "architect"
  },
  {
    order: 3,
    name: "Attorney",
    value: "attorney"
  },
  {
    order: 4,
    name: "Engineer",
    value: "engineer"
  },
  {
    order: 5,
    name: "General Contractor",
    value: "contractor"
  },
  {
    order: 6,
    name: "Journalist",
    value: "journalist"
  },
  {
    order: 7,
    name: "Market Researcher",
    value: "researcher"
  },
  {
    order: 8,
    name: "Recruiter",
    value: "recruiter"
  },
  {
    order: 9,
    name: "Student",
    value: "student"
  },
  {
    order: 10,
    name: "Technology Vendor",
    value: "tech_vendor"
  },
  {
    order: 11,
    name: "Other",
    value: "other"
  }
];
