<template>
  <div class="wrapper">
    <heading>How much leverage?</heading>
    <button @click="next" type="button" class="cta mb-2" :class="{ complete: true }" tabindex="999">
      <template v-if="hasSize && targetId">Save</template>
      <template v-else-if="hasSize">Next</template>
      <template v-else>Any amount</template>
    </button>
    <form @submit.prevent="next" novalidate class="mb-4">
      <section class="range">
        <div class="input-container">
          <input
            v-focus
            v-model="minimum"
            @focus="focus('min')"
            @blur="blur"
            type="number"
            min="0"
            step="1"
            placeholder=""
            tabindex="1"
            name="minimum_leverage_size"
            class="input-text black"
            :class="{ active: focused === 'min' }"
          >
          <label class="input-label" :class="{ active: focused === 'min' }">Minimum</label>
          <label class="sf-label" :class="{ active: focused === 'min' }">%</label>
        </div>
        <div class="input-container">
          <input
            v-model="maximum"
            @focus="focus('max')"
            @blur="blur"
            type="number"
            min="1"
            step="1"
            placeholder=""
            tabindex="2"
            name="maximum_leverage_size"
            class="input-text black"
            :class="{ active: focused === 'max' }"
          >
          <label class="input-label" :class="{ active: focused === 'max' }">Maximum</label>
          <label class="sf-label" :class="{ active: focused === 'max' }">%</label>
        </div>
      </section>
      <option-description v-if="sizeSummary" :description="sizeSummary" style="margin-top: 20px;" />
      <button @click="next" type="submit" class="hidden" />
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Avatar from "../../components/onboarding/avatar";
import Heading from "../../components/onboarding/heading";
import OptionDescription from "../../components/onboarding/option-description";

export default {
  props: ["targetId"],
  data() {
    return {
      focused: false
    }
  },
  mounted() {
    if (this.onboarding && !this.onboardingInProcess) {
      this.$router.push({ path: "/start/1" });
    } else if (this.creatingTarget && !this.targetCreationInProcess) {
      this.$router.push({ path: "/targets/new/1" });
    }
  },
  components: { Avatar, Heading, OptionDescription },
  computed: {
    ...mapState(["onboardingInProcess", "targetCreationInProcess"]),
    ...mapGetters(["onboarding", "creatingTarget"]),
    activeTarget() {
      return this.$store.state.activeTarget;
    },
    minimum: {
      get() {
        return this.activeTarget.leverageMinimum;
      },
      set(num) {
        this.$store.commit("setLeverageMinimum", num);
      }
    },
    maximum: {
      get() {
        return this.activeTarget.leverageMaximum;
      },
      set(num) {
        this.$store.commit("setLeverageMaximum", num);
      }
    },
    hasSize() {
      return this.minimum || this.maximum;
    },
    sizeSummary() {
      const summary = this.$options.filters.leverageRange(this.activeTarget);

      if (summary === "Min % - Max %") {
        return null;
      } else {
        return summary;
      }
    },
    doesLeasing() {
      return this.$store.getters.showLeaseSize;
    }
  },
  methods: {
    focus(field) {
      this.focused = field;
    },
    blur() {
      this.focused = false;
    },
    next() {
      if (this.targetId) {
        this.$store.dispatch("updateTarget", this.targetId);
        this.$store.commit("closeModal");
      } else if (this.doesLeasing) {
        if (this.onboarding) {
          this.$router.push({ path: "/start/10" });
        } else if (this.creatingTarget) {
          this.$router.push({ path: "/targets/new/8" });
        }
      } else {
        if (this.onboarding) {
          this.$router.push({ path: "/start/11" });
        } else if (this.creatingTarget) {
          this.$router.push({ path: "/targets/new/9" });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  display: flex;
  flex-direction: column;
}
.range {
  display: flex;
  justify-content: center;
}
input {
  vertical-align: middle;
}
.input-container {
  position: relative;
}
.input-text {
  width: 165px;
  padding: 26px 30px 8px 15px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 20px;
  line-height: 18.2px;
  &.active {
    border: 2px solid #5850ec;
  }
}
.input-label {
  position: absolute;
  top: 8px;
  left: 16px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
.sf-label {
  position: absolute;
  top: 31px;
  right: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
