<template>
  <section class="range">
    <div class="input-container">
      <input
        v-focus:index
        v-model="minimum"
        @focus="focus('min')"
        @blur="blur"
        type="number"
        min="0"
        step="1"
        placeholder=""
        :tabindex="index * 2 + 1"
        name="minimum_deal_size"
        class="input-text black"
        :class="{ active: focused === 'min' }"
      >
      <label class="input-label" :class="{ active: focused === 'min' }">{{ name }} Min.</label>
      <label class="sf-label" :class="{ active: focused === 'min' }">{{ measure }}</label>
    </div>
    <div class="input-container">
      <input
        v-model="maximum"
        @focus="focus('max')"
        @blur="blur"
        type="number"
        min="1"
        step="1"
        placeholder=""
        :tabindex="index * 2 + 2"
        name="maximum_deal_size"
        class="input-text black"
        :class="{ active: focused === 'max' }"
      >
      <label class="input-label" :class="{ active: focused === 'max' }">{{ name }} Max.</label>
      <label class="sf-label" :class="{ active: focused === 'max' }">{{ measure }}</label>
    </div>
  </section>
</template>

<script>
import useTypes from "../../use-types";

export default {
  props: ["use", "index"],
  data() {
    return {
      focused: false
    };
  },
  computed: {
    propertyUseData() {
      return _.find(useTypes, { value: this.use.type });
    },
    measure() {
      return this.propertyUseData.measure;
    },
    name() {
      return this.propertyUseData.name;
    },
    minimum: {
      get() {
        return this.use.minimum || null;
      },
      set(num) {
        this.$store.commit("setPropertySizeMinimum", {
          use: this.use.type,
          minimum: num
        });
      }
    },
    maximum: {
      get() {
        return this.use.maximum || null;
      },
      set(num) {
        this.$store.commit("setPropertySizeMaximum", {
          use: this.use.type,
          maximum: num
        });
      }
    }
  },
  methods: {
    focus(field) {
      this.focused = field;
    },
    blur() {
      this.focused = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.range {
  display: flex;
  justify-content: center;
}
input {
  vertical-align: middle;
}
.input-container {
  position: relative;
}
.input-text {
  width: 165px;
  padding: 26px 60px 8px 8px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 18.2px;
  &.active {
    border: 2px solid #5850ec;
  }
}
.input-label {
  position: absolute;
  top: 8px;
  left: 16px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
.sf-label {
  position: absolute;
  top: 31px;
  right: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
</style>
