<template>
  <div @mouseenter="highlightRecord" @mouseleave="unhighlightRecord" class="flex space-x-3 p-1 hover:bg-gray-100" :style="highlightedBackgroundColor">
    <span class="inline-flex items-center justify-center h-6 w-6 rounded-md" :style="`background-color: ${fillColor};`">
      <span class="text-xs font-medium leading-none text-gray-900">{{ index }}</span>
    </span>
    <div class="flex-1 space-y-1">
      <div class="flex items-center justify-between space-x-2">
        <h3 class="text-xs font-medium">
          <a @click.prevent.stop="viewDetails" class="text-sm font-medium text-gray-900 hover:underline cursor-pointer"><template v-if="deal.type !== 'Lease'">{{ deal.type | dealTypeAlias(deal.jointVentureRoles) }}: </template>{{ deal.name }}</a>
          <template v-if="contextType === 'local_development_pipeline'">
            {{ deal.properties | totalSize }}
          </template>
          <template v-else>
            <template v-if="deal.type === 'Lease'">{{ deal | totalLeaseSize }}</template>
            <template v-else-if="deal.totalValue">
              <template v-if="!deal.closeDate">~</template>{{ deal.totalValue | largeDollar }}
              <template v-if="deal.goingInPerUnit">({{ deal | perUnitPrice }})</template>
            </template>
          </template>
        </h3>
        <p class="flex items-center text-right text-xs whitespace-nowrap text-gray-500">
          <template v-if="liveDeal">
            <span class="mr-1 inline-flex items-center justify-center h-2 w-2 rounded-full bg-red-400"></span>
            {{ deal.state | capitalize }}
          </template>
          <time v-else :datetime="deal.newsfeedSortDate">{{ sortDate }}</time>
        </p>
      </div>
      <div v-if="deal.notes || deal.secondaryHeadline" v-html="linkifiedNotes" class="text-xs text-gray-500 whitespace-pre-line dont-break-out line-clamp-3" />
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapState } from "vuex";
import DealCard from "../cards/deal";
import linkifyHtml from "linkifyjs/html";
import responsiveDevelopmentProjectPopup from "../../leaflet-responsive-map-development-project-popup";
import responsivePricingPopup from "../../leaflet-responsive-map-pricing-popup";
import responsiveTenantPopup from "../../leaflet-responsive-map-tenant-popup";
import router from "../../router";
import store from "../../store";

export default {
  props: ["deal", "index", "map", "featureGroup", "contextType"],
  data() {
    return {
      mapInternalId: null,
      highlighted: false
    };
  },
  computed: {
    ...mapGetters(["signedIn"]),
    ...mapState(["mapNearbyProperties", "currentUser", "highlightedMapRecord"]),
    highlightedBackgroundColor() {
      if (this.highlighted) {
        return `background-color: #FCE7F3;`;
      } else {
        return "";
      }
    },
    fillColor() {
      const age = moment().diff(this.deal.newsfeedSortDate, "days");

      if (age <= 7) {
        return this.deal.type === "Lease" ? "#16A34A" : "#D97706";
      } else if (age <= 30) {
        return this.deal.type === "Lease" ? "#22C55E" : "#F59E0B";
      } else if (age <= 60) {
        return this.deal.type === "Lease" ? "#4ADE80" : "#FBBF24";
      } else if (age <= 90) {
        return this.deal.type === "Lease" ? "#86EFAC" : "#FCD34D";
      } else if (age <= 365) {
        return this.deal.type === "Lease" ? "#BBF7D0" : "#FDE68A";
      } else {
        return this.deal.type === "Lease" ? "#DCFCE7" : "#FEF3C7";
      }
    },
    sortDate() {
      return moment(this.deal.newsfeedSortDate).format("MMM YYYY");
    },
    liveDeal() {
      return _.includes(["live", "awarded"], this.deal.state);
    },
    activeDeal() {
      return _.includes(["draft", "live", "awarded"], this.deal.state);
    },
    marketingListDeal() {
      return this.deal.source === "direct";
    },
    linkifiedNotes() {
      return linkifyHtml(
        _.get(this.deal, "notes", "") ||
          _.get(this.deal, "secondaryHeadline", ""),
        {
          defaultProtocol: "https",
          className: "font-medium text-indigo-600 hover:text-indigo-500"
        }
      );
    },
    propertyIds() {
      return this.deal.properties.map(p => {
        return { id: p.id };
      });
    },
    matchingProperties() {
      return _.intersectionBy(this.mapNearbyProperties, this.propertyIds, "id");
    },
    mapInternalIds() {
      return this.matchingProperties.flatMap(p => p.mapInternalId);
    }
  },
  watch: {
    highlightedMapRecord: {
      handler() {
        if (
          this.highlightedMapRecord.contentType === "deal" &&
          this.highlightedMapRecord.contentToken === this.deal.token
        ) {
          this.highlighted = true;
          this.bringPopupForward();
        } else {
          this.highlighted = false;
        }
      },
      deep: true
    }
  },
  mounted() {
    this.displayPricingPopups();
  },
  methods: {
    highlightRecord() {
      this.bringPopupForward();
      this.$store.commit("setHighlightedMapRecord", {
        contentType: "deal",
        contentToken: this.deal.token,
        mapTool: "strategicContext",
        touching: "listItem"
      });
    },
    unhighlightRecord() {
      this.$store.commit("setHighlightedMapRecord", {
        contentType: null,
        contentToken: null,
        mapTool: null,
        touching: null
      });
    },
    bringPopupForward() {
      if (this.mapInternalId) {
        this.featureGroup.getLayer(this.mapInternalId).bringToFront();
      } else {
        this.displayPricingPopups();
      }
    },
    displayPricingPopups() {
      const id = _.head(this.mapInternalIds);
      const marker = this.featureGroup.getLayer(id);

      if (marker) {
        let responsivePopup = null;

        if (this.contextType === "local_peak_pricing") {
          responsivePopup = responsivePricingPopup({
            store,
            router,
            marker,
            deal: this.deal,
            fillColor: this.fillColor,
            isContext: true
          });
        } else if (this.contextType === "local_development_pipeline") {
          responsivePopup = responsiveDevelopmentProjectPopup({
            store,
            router,
            marker,
            content: this.deal,
            fillColor: this.fillColor,
            isContext: true
          });
        } else if (this.contextType === "local_tenants") {
          responsivePopup = responsiveTenantPopup({
            store,
            router,
            marker,
            content: this.deal,
            fillColor: this.fillColor,
            isContext: true
          });
        }

        if (responsivePopup) {
          responsivePopup.setLatLng(marker.getLatLng()).addTo(this.map);
          responsivePopup.bringToBack();
          responsivePopup.on("remove", e => {
            this.mapInternalId = null;
          });

          this.featureGroup.addLayer(responsivePopup);
          this.mapInternalId = this.featureGroup.getLayerId(responsivePopup);
        }
      }
    },
    viewDetails() {
      if (this.signedIn) {
        if (this.activeDeal && this.marketingListDeal && this.deal.dealTeam) {
          const marketingPage =
            this.deal.state === "draft" ? "overview" : "marketing-list";
          const marketingPath = `/deal_offerings/${this.deal.urlHandle || this.deal.token}/${marketingPage}`;

          window.open(marketingPath, "_blank");
        } else if (this.liveDeal && this.marketingListDeal && this.deal.marketingReadAuthorized) {
          const marketingOverviewPath = `/deal_offerings/${this.deal.urlHandle || this.deal.token}/overview`;

          window.open(marketingOverviewPath, "_blank");
        } else {
          this.$store.commit("closeSlideover");

          const property = _.head(this.matchingProperties);

          this.$store.commit("openModal", {
            component: DealCard,
            props: {
              dealObject: this.deal,
              property
            }
          });
        }
      } else {
        if (this.liveDeal && this.marketingListDeal && this.deal.marketingReadAuthorized) {
          const marketingOverviewPath = `/deal_offerings/${this.deal.urlHandle || this.deal.token}/overview`;

          window.open(marketingOverviewPath, "_blank");
        } else {
          this.$router.push({
            path: "/sign-in",
            query: { nextUrl: `/open_deal/${this.deal.token}` }
          });
        }
      }
    }
  }
};
</script>
