<template>
  <div>
    <div class="bg-white shadow overflow-hidden rounded-md">
      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div
          class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
        >
          <div class="ml-4 mt-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Markets
            </h3>
            <p v-if="userIsActiveContact" class="mt-1 text-sm leading-5 text-gray-500">
              You see all of your own markets, even private ones. Visitors to your profile will see just your public markets.
            </p>
            <div v-else-if="contact" class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">
              <div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
                </svg>
                <template v-if="contact.dealCount > 0">{{ contact | dealsCount }}: {{ contact | dealsVolume }}</template>
                <template v-else>No deal history</template>
              </div>
            </div>
          </div>
          <div v-if="userIsActiveContact" class="mt-5 lg:mt-2 ml-4 flex-shrink-0">
            <span class="inline-flex rounded-md shadow-sm">
              <button
                @click="newTarget"
                type="button"
                class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700"
              >
                Create new market
              </button>
            </span>
          </div>
        </div>
      </div>
      <div v-if="hasTargets" class="px-4 py-5 sm:p-6">
        <div class="grid gap-5 max-w-lg mx-auto sm:grid-cols-2 lg:grid-cols-3 sm:max-w-none">
          <div
            v-for="target in targets"
            :key="target.token"
            class="flex flex-col bg-white overflow-hidden shadow rounded-lg list-complete-item"
          >
            <market-preview :target="target" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MarketPreview from "./cards/market-preview";

export default {
  components: { MarketPreview },
  props: ["targetType"],
  computed: {
    ...mapGetters(["contact", "userIsActiveContact", "name"]),
    ...mapState(["activeContact"]),
    targets() {
      return this.$store.getters.contactTargets(this.targetType);
    },
    hasTargets() {
      return this.targets && this.targets.length > 0;
    },
    creatingSharedGroup() {
      return this.$store.state.creatingSharedGroup;
    }
  },
  methods: {
    newTarget() {
      this.$router.push({ path: "/targets/new/1" });
    },
    view(target) {
      if (this.userIsActiveContact) {
        this.$store.commit("mountTarget", { target: target });
      } else {
        return;
      }
    },
    viewPath(target) {
      if (this.activeContact) {
        return `/open/${target.token}`;
      } else {
        return "";
      }
    }
  }
};
</script>
