<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-xl md:max-w-3xl -mt-6 flex flex-col justify-end">
    <div class="flex flex-col-reverse">
      <multi-content-preview-card
        v-if="contentToDiscuss"
        :content="contentToDiscuss"
        class="mt-4 mb-4"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MultiContentPreviewCard from "../components/cards/multi-content-preview-card.vue";
import api from "../api";

export default {
  components: { MultiContentPreviewCard },
  data() {
    return {
      contentToDiscuss: null,
      propertyFollowing: null
    };
  },
  computed: {
    ...mapState(["route"]),
    contentType() {
      return _.get(this.route, "query.type", null);
    },
    contentToken() {
      return _.get(this.route, "query.content", null);
    }
  },
  watch: {
    contentToken: {
      handler(val, oldVal) {
        if (!val && oldVal) {
          this.contentToDiscuss = null;
          this.$store.commit("clearNewBackchannel");
        }
      }
    }
  },
  mounted() {
    if (this.contentToken && this.contentType) {
      if (this.contentType === "property") {
        api.get(`property_followings/${this.contentToken}`).then(json => {
          this.propertyFollowing = json.data;
        });
      } else {
        api.get(`open_${this.contentType}/${this.contentToken}`).then(json => {
          this.contentToDiscuss = json.data;
        });
      }
    }
  }
};
</script>
