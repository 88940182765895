<template>
  <div>
    <nav class="bg-gray-800">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <router-link to="/" class="flex-shrink-0">
              <img class="h-10 w-auto sm:h-12" src="https://assets.towerhunt.com/site/Transparent_T0.png" alt="Tower Hunt" />
            </router-link>
            <div v-for="tab in tabs" :key="tab" @click="select(tab)" class="ml-4 cursor-pointer">
              <div v-if="selectedTab === tab" class="mt-1 block px-3 py-2 rounded-md text-sm sm:text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700">
                {{ tab }}
              </div>
              <div v-else class="mt-1 block px-3 py-2 rounded-md text-sm sm:text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
                {{ tab }}
              </div>
            </div>
          </div>
          <topbar-right-avatar-cluster />
        </div>
      </div>
    </nav>
    <main>
      <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <keep-alive>
          <component :is="currentTabComponent" class="markdown" />
        </keep-alive>
      </div>
    </main>
  </div>
</template>

<script>
import PrivacyPolicy from "../components/privacy-policy.vue";
import TermsOfService from "../components/terms-of-service.vue";
import TopbarRightAvatarCluster from "../components/topbar-right-avatar-cluster";

export default {
  components: { TopbarRightAvatarCluster },
  data() {
    return {
      tabs: ["Terms of Service", "Privacy Policy"],
      selectedTab: "Terms of Service"
    };
  },
  mounted() {
    document.title = "Legal | Tower Hunt";

    this.selectIfNeededFromHash = () => {
      const path = window.location.hash;

      if (path === "#terms-of-service") {
        this.select("Terms of Service");
        document.title = "Terms of Service | Tower Hunt";
      } else if (path === "#privacy-policy") {
        this.select("Privacy Policy");
        document.title = "Privacy Policy | Tower Hunt";
      }
    };

    this.selectIfNeededFromHash();
    window.addEventListener("hashchange", this.selectIfNeededFromHash);
  },
  destroyed() {
    window.removeEventListener("hashchange", this.selectIfNeededFromHash);
  },
  computed: {
    currentTabComponent() {
      switch (this.selectedTab) {
        case "Terms of Service":
          return TermsOfService;
        case "Privacy Policy":
          return PrivacyPolicy;
        default:
          return TermsOfService;
      }
    },
    tabToPath() {
      switch (this.selectedTab) {
        case "Terms of Service":
          return "terms-of-service";
        case "Privacy Policy":
          return "privacy-policy";
        default:
          return "terms-of-service";
      }
    }
  },
  methods: {
    select(tab) {
      this.selectedTab = tab;
      window.location.hash = `${this.tabToPath}`;
    }
  }
};
</script>
