export default function innerIconFor(mostRecentReferenceVisibility) {
  switch (mostRecentReferenceVisibility) {
    case "Anyone in this market":
      return "f57d";
    case "Anyone with investor preview link":
      return "f0c1";
    case "Safezone":
      return "f023";
    case "Private Channel":
      return "f0e7";
    case "Deal team":
    case "Deal participants":
      return "f505";
    case "Deal team and prospects":
    case "Deal team and matching principals":
      return "f4c0";
    case "Marked confidential":
      return "f3ed";
    default:
      return "f128";
  }
}
