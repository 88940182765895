<template>
  <div>
    <people-grid
      v-for="market in markets"
      :key="market.token"
      :market="market"
    />
  </div>
</template>

<script>
import PeopleGrid from "../components/people-grid";

export default {
  components: { PeopleGrid },
  computed: {
    markets() {
      return this.$store.getters.contactTargets(null);
    }
  }
};
</script>
