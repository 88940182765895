<template>
  <div class="px-4 py-5 sm:p-6">
    <div v-if="selectedCard" class="sm:flex sm:items-center sm:justify-between">
      <div>
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Engage this bid
        </h3>
        <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
          <p>
            I agree to pay <strong class="font-semibold">${{ bid.bountyPrice }}</strong> if my request is fulfilled prior to expiration and approved by Tower Hunt.
            <router-link to="/product/bounties" target="_blank" class="ml-1 font-medium text-indigo-600 hover:text-indigo-500">
              Learn more &rarr;
            </router-link>
          </p>
        </div>
      </div>
      <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
        <span class="inline-flex rounded-md shadow-sm">
          <button @click="engage" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
            Engage
          </button>
        </span>
      </div>
    </div>

    <payment-methods v-else :selectable="true" capturing-payment="later" @card-selected="selectCard" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import PaymentMethods from "../../payment-methods";
import api from "../../../api";

export default {
  components: { PaymentMethods },
  props: ["bid"],
  data() {
    return {
      paymentComplete: false,
      submitting: false,
      selectedCard: null
    };
  },
  computed: {
    ...mapState(["adminMode"])
  },
  methods: {
    selectCard(record) {
      this.selectedCard = record;
    },
    engage() {
      const payload = { cardToken: this.selectedCard.id };

      api.patch(`engage_bounty_bid/${this.bid.id}`, payload).then(json => {
        if (!!analytics && !this.adminMode) {
          analytics.track("Bounty bid engaged", {
            yearWeek: this.$store.state.yearWeek,
            bountyPrice: this.bid.bountyPrice
          });
        }

        this.bid.state = "engaged";
        this.$store.commit("closeModal");
      });
    }
  }
};
</script>
