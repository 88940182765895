<template>
  <div>
    <div v-if="editable" class="mt-6">
      <div class="pb-5 border-b border-gray-200 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
        <div class="space-y-2">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Elements
          </h3>
          <p class="max-w-4xl text-sm leading-5 text-gray-500">Curate content from across Tower Hunt.</p>
        </div>
        <div>
          <span class="shadow-sm rounded-md">
            <button @click="addElement" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
              Add element
            </button>
          </span>
        </div>
      </div>
    </div>
    <ul class="mt-6 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2">
      <li v-for="(element, index) in elements" :key="index" class="space-y-1">
        <multi-content-preview-card
          v-if="element.content.newsfeedType === 'deal' || element.content.newsfeedType === 'intel'"
          :content="element.content"
          :preview="true"
        />
        <purchaseable-preview v-else-if="element.content.newsfeedType === 'purchaseable'" :purchaseable="element.content" />
        <a v-if="purchaseable.state !== 'live' && editable" href="" @click.prevent="detachElement(element.id)" class="font-medium text-xs text-red-700 hover:text-red-600">Remove</a>
      </li>
    </ul>
  </div>
</template>

<script>
import MultiContentPreviewCard from "../cards/multi-content-preview-card";
import api from "../../api";

export default {
  components: { MultiContentPreviewCard },
  props: ["purchaseable", "dataSetId", "editable"],
  data() {
    return {
      elements: []
    };
  },
  mounted() {
    this.fetchElements();
  },
  beforeCreate() {
    this.$options.components.PurchaseablePreview = require("../cards/purchaseable-preview.vue").default;
  },
  methods: {
    refetchAll() {
      this.fetchElements();
      this.$store.commit("setRefetchPurchaseables", true);
    },
    fetchElements() {
      api.get(`data_sets/${this.dataSetId}/data_set_elements`).then(json => {
        this.elements = json.data;

        if (this.elements.length === 0) {
          this.$emit("add-element");
        }
      });
    },
    addElement() {
      this.$emit("add-element");
    },
    detachElement(elementId) {
      api
        .delete(`data_sets/${this.dataSetId}/data_set_elements/${elementId}`)
        .then(json => {
          this.$store.dispatch("flash", "Element successfully detached.");
          this.refetchAll();
        });
    }
  }
};
</script>
