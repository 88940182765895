<template>
  <div class="relative p-4 max-w-3xl mx-auto">
    <div class="text-center">
      <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        {{ title }}
      </h2>
      <p v-if="purchaseable.name" class="mt-4 text-xl font-bold leading-6 text-gray-900">
        {{ purchaseable.name }}
      </p>
    </div>
    <div class="my-5">
      <data-set-form v-if="editing" :data-set-id="dataSetId" @done-editing="editing = false" @link-element="linkElement" />
      <data-set-element-list v-else :purchaseable="purchaseable" :data-set-id="dataSetId" :editable="true" @add-element="editing = true" />
    </div>
  </div>
</template>

<script>
import DataSetElementList from "./data-set-element-list";
import DataSetForm from "./data-set-form.vue";
import api from "../../api";

export default {
  components: { DataSetForm, DataSetElementList },
  props: ["purchaseable", "dataSetId"],
  data() {
    return {
      editing: false
    };
  },
  computed: {
    title() {
      if (this.editing) {
        return "Add to Data Set";
      } else {
        return "Data Set";
      }
    }
  },
  methods: {
    linkElement(payload) {
      api
        .post(`data_sets/${this.dataSetId}/data_set_elements`, payload)
        .then(json => {
          this.$store.dispatch(
            "flash",
            "Element successfully added to data set!"
          );
          this.editing = false;
        });
    }
  }
};
</script>
