<template>
  <div class="mt-10 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-12">
    <div class="max-w-md mx-auto lg:max-w-5xl">
      <div :class="`rounded-lg bg-${backgroundColor} px-6 py-8 sm:p-10 lg:flex lg:items-center`">
        <div class="flex-1">
          <div>
            <h3 class="inline-flex px-4 py-1 rounded-full text-xs font-semibold tracking-wide uppercase bg-white text-gray-800">
              Want something else?
            </h3>
          </div>
          <div class="mt-4 text-base text-gray-600">Create a bounty to get help from local experts. Request anything from comps to owner lists. Local experts bid. You pay upon fulfillment.</div>
        </div>
        <div class="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
          <button @click="requestHelp" type="button" class="flex w-full items-center justify-center px-5 py-3 border border-transparent text-sm font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
            Create bounty
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BountyRequest from "../bounties/bounty-request";

export default {
  props: ["backgroundColor"],
  computed: {
    ...mapState(["modal"]),
    ...mapGetters(["signedIn"])
  },
  methods: {
    requestHelp() {
      if (this.signedIn) {
        let currentModal = false;

        if (this.modal) {
          currentModal = _.cloneDeep(this.modal); // obtains the current component
        }

        this.$store.commit("openModal", {
          component: BountyRequest,
          props: {},
          afterClose: currentModal
        });
      } else {
        this.$router.push({
          path: "/sign-in",
          query: { nextUrl: `/bounties/active` }
        });
        this.$store.commit("closeModal");
      }
    }
  }
};
</script>
