<template>
  <market-intel :market-reports-only="true" />
</template>

<script>
import MarketIntel from "./intel";

export default {
  components: { MarketIntel }
};
</script>
