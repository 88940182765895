<template>
  <div>
    <span v-if="liveDealsConsent" class="inline-flex rounded-md shadow-sm">
      <button @click="toggleAndSave" type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
        <svg class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
          <path fill-rule="evenodd" d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" clip-rule="evenodd"/>
        </svg>
        Alerts are on
      </button>
    </span>
    <span v-else class="inline-flex rounded-md shadow-sm">
      <button @click="toggleAndSave" type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
        <svg class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
          <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd"/>
        </svg>
        Alerts are off
      </button>
    </span>
  </div>
</template>

<script>
export default {
  props: ["target"],
  data() {
    return {
      liveDealsConsent: true
    };
  },
  computed: {
    notifications() {
      return _.get(this.target, "notifications", null);
    }
  },
  watch: {
    notifications: {
      handler: function() {
        this.mountNotification();
      },
      deep: true
    }
  },
  mounted() {
    if (this.target.notifications) {
      this.mountNotification();
    }
  },
  methods: {
    toggleAndSave() {
      this.liveDealsConsent = !this.liveDealsConsent;
      this.saveNotification();
    },
    mountNotification() {
      if (this.target.notifications) {
        const { liveDealsConsent } = this.target.notifications;

        this.liveDealsConsent = liveDealsConsent;
      } else {
        this.liveDealsConsent = false;
      }
    },
    saveNotification() {
      const { liveDealsConsent } = this;
      const newInfo = { liveDealsConsent };

      this.$store.dispatch("updateTargetNotifications", newInfo);
    }
  }
};
</script>
