<template>
  <span @click="toggleVisibility" v-tooltip="toggleHelp" role="checkbox" tabindex="0" :aria-checked="isVisible" :class="isVisible ? 'bg-indigo-600' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring">
    <span aria-hidden="true" :class="isVisible ? 'translate-x-5' : 'translate-x-0'" class="relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200">
      <span :class="isVisible ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity">
        <svg class="h-3 w-3 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
          <path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
          <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
        </svg>
      </span>
      <span :class="isVisible ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity">
        <svg class="h-3 w-3 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
        </svg>
      </span>
    </span>
  </span>
</template>

<script>
import api from "../../api";

export default {
  props: ["purchaseable", "showcase"],
  computed: {
    isVisible() {
      if (this.purchaseable) {
        return this.purchaseable.showcaseVisibility;
      } else if (this.showcase) {
        return this.showcase.showcaseVisibility;
      } else {
        return false;
      }
    },
    toggleHelp() {
      if (this.purchaseable) {
        if (this.isVisible) {
          return "Click to hide this content when the parent showcase is being viewed.";
        } else {
          return "Click to show this content when the parent showcase is being viewed.";
        }
      } else if (this.showcase) {
        if (this.isVisible) {
          return "Click to hide this showcase from users.";
        } else {
          return "Click to show this showcase to users.";
        }
      } else {
        if (this.isVisible) {
          return "Click to hide";
        } else {
          return "Click to show";
        }
      }
    }
  },
  methods: {
    toggleVisibility() {
      if (this.isVisible) {
        this.persist(false);
      } else {
        this.persist(true);
      }
    },
    persist(isVisible) {
      const endpoint = this.purchaseable
        ? `showcase_purchaseable_visibility/${this.purchaseable.showcaseLinkedId}`
        : `showcase_visibility/${this.showcase.id}`;

      api.patch(endpoint, { visible: isVisible }).then(json => {
        this.$store.dispatch("flash", "Visibility updated.");
        if (this.purchaseable) {
          this.purchaseable.showcaseVisibility = isVisible;
        } else if (this.showcase) {
          this.showcase.showcaseVisibility = isVisible;
        }
      });
    }
  }
};
</script>
