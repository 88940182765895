<template>
  <div class="bg-gray-100">
    <div class="pt-4 sm:pt-8 lg:pt-12">
      <div class="max-w-screen-xl mx-auto">
        <div class="text-center">
          <h2 class="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
            Your reputation
          </h2>
          <p class="mt-4 text-xl leading-7 text-gray-600">
            Earn simply by honing your craft and being a pro. Use to <router-link to="/product/bounty-bids" class="underline">generate revenue</router-link>.
          </p>
        </div>
      </div>
    </div>
    <div v-if="creditTracking" class="mt-8 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
      <div class="relative">
        <div class="relative max-w-screen-xl mx-auto">
          <div class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
            <div class="bg-white px-6 py-8 lg:flex-shrink-1 lg:p-12">
              <h3 class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
                Track progress
              </h3>
              <p class="mt-6 text-base leading-6 text-gray-500">
                Earn within categories that correlate with industry success. As you make gains here, you should be seeing new opportunities.
              </p>
              <div class="mt-8">
                <div class="flex items-center">
                  <div v-if="professionalCreditsAchieved" class="flex-shrink-0 flex items-center">
                    <button @click="toggleProfessionalCredits" type="button" v-tooltip="'Show/hide progress'" class="text-gray-400 hover:text-gray-900 focus:outline-none focus:text-gray-900">
                      <span class="h-5 flex items-center">
                        <svg class="h-4 w-4 transform" :class="{ '-rotate-180': showProfessionalCredits }" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <h4 class="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-indigo-600" :class="professionalCreditsAchieved ? 'ml-2' : 'ml-0'">
                    Look professional
                  </h4>
                  <div class="flex-1 border-t-2 border-gray-200"></div>
                  <div v-if="professionalCreditsAchieved" class="flex-shrink-0 pl-4">
                    <svg class="h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
                <ul v-if="showProfessionalCredits" class="mt-8 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                  <checklist-item :credit-data="creditTracking.photo" description="Add a photo to your profile" :value="25" router-path="/home/settings" />
                  <checklist-item :credit-data="creditTracking.currentCompany" description="Add your current company" :value="25" router-path="/home/settings" />
                  <checklist-item :credit-data="creditTracking.localMarkets" description="Specify local markets using polygons" :value="25" unit="mkt" :display-value="true" :router-path="`/contacts/${currentUser.token}`" />
                  <checklist-item :credit-data="creditTracking.contactInfo" description="Add contact info" :value="10" router-path="/home/settings" />
                  <checklist-item :credit-data="creditTracking.historicalCompanies" description="Add past companies" :value="10" router-path="/home/settings" />
                  <checklist-item :credit-data="creditTracking.academicBackground" description="Add academic background" :value="5" router-path="/home/settings" />
                </ul>
              </div>
              <div class="mt-8">
                <div class="flex items-center">
                  <h4 class="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-indigo-600">
                    Be relevant every week
                  </h4>
                  <div class="flex-1 border-t-2 border-gray-200"></div>
                </div>
                <ul class="mt-8 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                  <checklist-item :credit-data="creditTracking.intelPopularity" description="Post high-value intel" :value="3" unit="reaction" :display-value="true" :last-week-delta="lastWeekDeltaFor('intelPopularity')" router-path="/intel/new/1" />
                  <checklist-item :credit-data="creditTracking.commentPopularity" description="Add useful comments to deals and intel" :value="3" unit="reaction" :display-value="true" :last-week-delta="lastWeekDeltaFor('commentPopularity')" router-path="/newsfeed" />
                  <checklist-item :credit-data="creditTracking.dealPopularity" description="Post deals that others find interesting" :value="3" unit="reaction" :display-value="true" :last-week-delta="lastWeekDeltaFor('dealPopularity')" router-path="/deals/new/1" />
                  <checklist-item :credit-data="creditTracking.localIntel" description="Post intel within specific markets" :value="10" unit="local post" :display-value="true" :last-week-delta="lastWeekDeltaFor('localIntel')" router-path="/intel/new/1" />
                  <checklist-item :credit-data="creditTracking.localDeals" description="Add deals in your markets" :value="20" unit="deal" :display-value="true" :last-week-delta="lastWeekDeltaFor('localDeals')" router-path="/deals/new/1" />
                  <checklist-item :credit-data="creditTracking.executedBounties" description="Have your bounties fulfilled" :value="20" unit="bounty" :display-value="true" :last-week-delta="lastWeekDeltaFor('executedBounties')" router-path="/bounties/my-bounties" />
                  <checklist-item :credit-data="creditTracking.collectibleCardsReviewed" description="Screen off-market assets" :value="10" unit="card" :display-value="true" :last-week-delta="lastWeekDeltaFor('collectibleCardsReviewed')" router-path="/off-market/packs" />
                  <checklist-item :credit-data="creditTracking.followers" description="Develop a large following" :value="5" unit="follower" :display-value="true" :last-week-delta="lastWeekDeltaFor('followers')" router-path="/network" />
                </ul>
              </div>
              <div class="mt-8">
                <div class="flex items-center">
                  <h4 class="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-indigo-600">
                    Build trust every week
                  </h4>
                  <div class="flex-1 border-t-2 border-gray-200"></div>
                </div>
                <ul class="mt-8 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                  <checklist-item :credit-data="creditTracking.trackRecordMarkets" description="Close 3+ deals in your markets" :value="100" unit="mkt" :display-value="true" :router-path="`/contacts/${currentUser.token}`" />
                  <checklist-item :credit-data="creditTracking.verifiedDeals" description="Verify your closed deals" :value="50" unit="deal" :display-value="true" :last-week-delta="lastWeekDeltaFor('verifiedDeals')" :router-path="`/contacts/${currentUser.token}`" />
                  <checklist-item :credit-data="creditTracking.wouldUseAgainBids" description="Earn positive bounty fulfillment ratings" :value="50" unit="bounty" :display-value="true" :last-week-delta="lastWeekDeltaFor('wouldUseAgainBids')" router-path="/bounties/my-engagements" />
                  <checklist-item :credit-data="creditTracking.approvedOffMarketCapRates" description="Provide off-market cap rate estimates" :value="25" unit="caps" :display-value="true" :last-week-delta="lastWeekDeltaFor('approvedOffMarketCapRates')" router-path="/off-market/watchlist" />
                  <checklist-item :credit-data="creditTracking.acceptedInvitations" description="Convince others to join" :value="10" unit="user" :display-value="true" :last-week-delta="lastWeekDeltaFor('acceptedInvitations')" click-action="inviteUsers" />
                  <checklist-item :credit-data="creditTracking.connections" description="Earn mutual connections" :value="10" unit="person" :display-value="true" :last-week-delta="lastWeekDeltaFor('connections')" router-path="/network" />
                </ul>
              </div>
            </div>
            <div class="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:p-12">
              <p class="text-lg leading-6 font-medium text-gray-900">
                Improve every day
              </p>
              <div class="mt-4 flex items-center justify-center text-5xl leading-none font-extrabold text-gray-900">
                <span>
                  {{ currentUser.creditsCount | formattedNumber }}
                </span>
                <div v-if="creditDelta > 0" class="ml-3 flex items-baseline text-xl leading-7 font-medium text-green-600">
                  <svg class="self-center flex-shrink-0 h-6 w-6 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <span class="sr-only">
                    Increased by
                  </span>
                  {{ creditDelta }}
                </div>
                <div v-else class="ml-3 flex items-baseline text-xl leading-7 font-medium text-gray-500">
                  <svg class="self-center flex-shrink-0 h-6 w-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <span class="sr-only">
                    Unchanged
                  </span>
                  0
                </div>
              </div>
              <p class="mt-4 text-xs leading-5">
                <span class="font-medium text-gray-500">
                  Daily progress resets at 12a est
                </span>
              </p>
              <div class="mt-6">
                <div class="rounded-md shadow" v-tooltip="reputationLevelHelp">
                  <div :class="reputationColor" class="flex items-center justify-center px-5 py-3 text-base leading-6 font-medium rounded-md">
                    {{ currentUser.reputationLevel | capitalize }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ChecklistItem from "../components/credit-tracking/checklist-item";

export default {
  components: { ChecklistItem },
  data() {
    return {
      showProfessionalCredits: true
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    reputationLevelHelp() {
      switch (this.currentUser.reputationLevel) {
        case "bronze":
          return `${this.currentUser.nextReputationLevelCredits} to Silver!`;
        case "silver":
          return `${this.currentUser.nextReputationLevelCredits} to Gold!`;
        case "gold":
          return `Awesome job!`;
        default:
          return `${this.currentUser.nextReputationLevelCredits} to Bronze!`;
      }
    },
    reputationColor() {
      switch (this.currentUser.reputationLevel) {
        case "bronze":
          return "bg-gradient-to-r from-yellow-500 to-orange-700 text-white hover:to-orange-800";
        case "silver":
          return "bg-gradient-to-r from-cool-gray-200 to-gray-400 text-gray-900 hover:to-gray-500";
        case "gold":
          return "bg-gradient-to-r from-yellow-300 to-yellow-500 text-white hover:to-yellow-600";
        default:
          return "text-white bg-gray-900 hover:bg-gray-800";
      }
    },
    creditTracking() {
      return this.currentUser.creditTracking;
    },
    creditsCountDailySnapshot() {
      return this.currentUser.creditsCountDailySnapshot;
    },
    creditsCountWeeklySnapshot() {
      return this.currentUser.creditsCountWeeklySnapshot;
    },
    creditDelta() {
      return (
        this.currentUser.creditsCount - this.currentUser.creditsCountSnapshot
      );
    },
    professionalCreditsAchieved() {
      if (this.creditTracking && _.keys(this.creditTracking).length > 0) {
        const {
          photo,
          currentCompany,
          contactInfo,
          historicalCompanies,
          academicBackground,
          localMarkets
        } = this.creditTracking;

        return [
          photo,
          currentCompany,
          contactInfo,
          historicalCompanies,
          academicBackground,
          localMarkets
        ].every(credit => credit > 0);
      } else {
        return false;
      }
    }
  },
  watch: {
    professionalCreditsAchieved: {
      handler() {
        this.setProfessionalCreditVisibility();
      }
    }
  },
  mounted() {
    document.title = "Credits | Tower Hunt";

    this.setProfessionalCreditVisibility();
    this.$store.dispatch("loadCreditTracking");
  },
  methods: {
    lastWeekDeltaFor(key) {
      const current = _.get(this.creditTracking, key, 0);

      if (_.keys(this.creditsCountWeeklySnapshot).length > 0) {
        const lastWeek = _.get(this.creditsCountWeeklySnapshot, key, 0);

        return current - lastWeek;
      } else if (_.get(this.creditTracking, key, false)) {
        return current - 0;
      } else {
        return 0;
      }
    },
    toggleProfessionalCredits() {
      this.showProfessionalCredits = !this.showProfessionalCredits;
    },
    setProfessionalCreditVisibility() {
      this.showProfessionalCredits = !this.professionalCreditsAchieved;
    }
  }
};
</script>
