<template>
  <div class="relative flex items-start">
    <div class="flex items-center h-5">
      <input @click="select" :id="`check-option-${label}`" :aria-describedby="`check-option-${label}-description`" :name="`check-option-${label}`" type="checkbox" :checked="selected" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
    </div>
    <div class="ml-3 text-sm">
      <label :for="`check-option-${label}`" class="font-medium text-gray-700">{{ label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "layout",
    "setterName",
    "setterType",
    "forgetterName",
    "storeRecord",
    "optionRecord"
  ],
  computed: {
    selected() {
      if (this.optionRecord.id) {
        return !!_.find(this.storeRecord, { id: this.value });
      } else {
        return !!_.find(this.storeRecord, { type: this.value });
      }
    },
    option() {
      return this.optionRecord;
    },
    label() {
      return this.option.name || this.value;
    },
    value() {
      return this.option.value || this.option.id || this.option.name;
    },
    editableId() {
      return !!this.option.id ? this.option.id : null;
    }
  },
  methods: {
    select() {
      let payload;

      if (this.option.id) {
        payload = this.optionRecord;
      } else if (this.setterType === "commit") {
        payload = { type: this.value };
      } else {
        payload = { data: this.value };
      }

      if (this.selected) {
        this.$store[this.setterType](this.forgetterName, payload);
      } else {
        this.$store[this.setterType](this.setterName, payload);
      }
    }
  }
};
</script>
