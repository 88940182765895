<template>
  <div @mouseenter="disableMapScroll" @mouseleave="enableMapScroll" class="leaflet-control w-72 px-2 py-1 bg-white border-2 border-gray-300 rounded-md">
    <div class="flex justify-between items-center">
      <div class="flex items-baseline">
        <h2 class="text-base font-bold text-gray-900">
          Legend
        </h2>
        <a @click.prevent.stop="visible = !visible" class="ml-2 text-xs leading-5 underline cursor-pointer">
          <template v-if="visible">Show less</template>
          <template v-else>Show more</template>
        </a>
      </div>
      <map-mode-toggle class="p-1" />
    </div>
    <div v-if="strategicMap" class="mt-1">
      <h2 class="text-sm font-medium text-gray-900">
        <div v-if="zoom < 9" class="bg-blue-50 border-l-4 border-blue-400 p-1">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/zoom-in -->
              <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M5 8a1 1 0 011-1h1V6a1 1 0 012 0v1h1a1 1 0 110 2H9v1a1 1 0 11-2 0V9H6a1 1 0 01-1-1z" />
                <path fill-rule="evenodd" d="M2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8zm6-4a4 4 0 100 8 4 4 0 000-8z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-sm text-blue-700">
                Please zoom in
              </p>
            </div>
          </div>
        </div>
        <div v-else-if="hasMapFilters" class="bg-yellow-50 border-l-4 border-yellow-400 p-1">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/filter -->
              <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-sm text-yellow-700">
                Filters applied. Zoom-limited results.
                <a @click.prevent.stop="filter" class="font-medium underline text-yellow-700 hover:text-yellow-600 cursor-pointer">
                  View filters
                </a>
              </p>
            </div>
          </div>
        </div>
        <div v-else-if="zoom <= 17" class="bg-green-50 border-l-4 border-green-400 p-1">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/clock -->
              <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-sm text-green-700">
                Showing newest {{ dealCount + intelCount}} results. Zoom in or <a @click.prevent.stop="filter" class="text-sm leading-5 underline cursor-pointer">filter</a>.
              </p>
            </div>
          </div>
        </div>
        <template v-else>Showing all available results.</template>
      </h2>
    </div>
    <div v-if="visible && zoom >= 9" class="mt-2 max-h-48 sm:max-h-16 lg:max-h-48 overflow-y-auto space-y-2">
      <div v-if="mapFocalRecord" class="w-60 flex flex-col space-y-3 sm:items-center sm:flex-row text-xs sm:space-y-0 sm:space-x-3">
        <div class="w-56 flex-shrink-0 flex-1">
          <div class="h-10 flex flex-col justify-center">
            <div class="text-xs font-semibold text-gray-900 truncate">
              {{ mapFocalRecord.name }}
            </div>
            <div class="text-xs text-gray-500">Map subject</div>
          </div>
        </div>
        <div class="min-w-0 flex-0 self-center">
          <div class="space-y-1.5 flex flex-col">
            <div
              class="h-5 w-5 rounded-md ring-1 ring-inset ring-black ring-opacity-0"
              style="background-color: #EF4444;"
            />
          </div>
        </div>
      </div>
      <div v-if="activeVisibilityTypes.length > 0" class="w-60 flex flex-col space-y-3 text-xs">
        <div class="flex-shrink-0">
          <div class="h-10 flex flex-col justify-center">
            <div class="text-xs font-semibold text-gray-900">
              Visibility
            </div>
            <div class="text-xs text-gray-500">Who can see this?</div>
          </div>
        </div>
        <div class="min-w-0 flex-1 grid grid-cols-3 gap-x-4 gap-y-3">
          <div v-for="(type, index) in activeVisibilityTypes" :key="index" class="space-y-1.5 flex flex-col items-center">
            <div class="flex items-center justify-center h-5 w-5 bg-gray-300 text-gray-700 rounded-md ring-1 ring-inset ring-black ring-opacity-0">
              <i :class="type.iconClass"></i>
            </div>
            <div class="px-0.5">
              <div class="text-center font-medium text-gray-900">{{ type.shortName }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="prioritizedVisibleStyles.length > 0" class="w-60 flex flex-col space-y-3 text-xs">
        <div class="flex-shrink-0">
          <div class="h-10 flex flex-col justify-center">
            <div class="text-xs font-semibold text-gray-900">
              Priority
            </div>
            <div class="text-xs text-gray-500">Your Watchlist and internal tracking</div>
          </div>
        </div>
        <div class="min-w-0 flex-1 grid grid-cols-3 gap-x-4 gap-y-3">
          <div v-for="(style, index) in prioritizedVisibleStyles" :key="index" class="space-y-1.5 flex flex-col items-center">
            <div
              class="flex items-center justify-center h-5 w-5 bg-gray-400 text-gray-700 rounded-md ring-1 ring-inset ring-black ring-opacity-0"
              :style="`border-width: 3px; border-radius: .25rem; border-color: ${style.border}`"
            />
            <div class="px-0.5">
              <div class="text-center font-medium text-gray-900">{{ style.priority }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="h-10 flex flex-col justify-center">
        <div class="text-xs font-semibold text-gray-900">
          Recency
        </div>
        <div v-if="strategicMap" class="text-xs text-gray-500">Color ties to property's most recent activity:</div>
        <div v-else class="text-xs text-gray-500">Color ties to how recent the intel is:</div>
      </div>
      <template v-if="strategicMap">
        <div class="w-60 flex flex-col space-y-3 sm:flex-row text-xs sm:space-y-0 sm:space-x-3">
          <div class="w-20 flex-shrink-0">
            <div class="h-10 flex flex-col justify-center">
              <div class="text-xs font-semibold text-gray-900">
                {{ dealCount }} Cap Mkts
              </div>
              <div class="text-xs text-gray-500">Closed:</div>
            </div>
          </div>
          <div class="min-w-0 flex-1 grid grid-cols-3 2xl:grid-cols-6 gap-x-4 gap-y-3 2xl:gap-x-3">
            <div v-for="(style, index) in capMarketStyles" :key="index" class="space-y-1.5 flex flex-col items-center">
              <div
                class="h-4 w-4 rounded-md ring-1 ring-inset ring-black ring-opacity-0"
                :style="`background-color: ${style.fill};`"
              />
              <div class="px-0.5 md:flex md:justify-between md:space-x-2 2xl:space-x-0 2xl:block">
                <div class="w-6 text-center font-medium text-gray-900">{{ style.age }}<template v-if="index === capMarketStyles.length - 1">+</template></div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-60 flex flex-col space-y-3 sm:flex-row text-xs sm:space-y-0 sm:space-x-3">
          <div class="w-20 flex-shrink-0">
            <div class="h-10 flex flex-col justify-center">
              <div class="text-xs font-semibold text-gray-900">
                {{ leaseCount }} Leases
              </div>
              <div class="text-xs text-gray-500">Closed:</div>
            </div>
          </div>
          <div class="min-w-0 flex-1 grid grid-cols-3 2xl:grid-cols-6 gap-x-4 gap-y-3 2xl:gap-x-3">
            <div v-for="(style, index) in leaseStyles" :key="index" class="space-y-1.5 flex flex-col items-center">
              <div
                class="h-4 w-4 rounded-md ring-1 ring-inset ring-black ring-opacity-0"
                :style="`background-color: ${style.fill};`"
              />
              <div class="px-0.5 md:flex md:justify-between md:space-x-2 2xl:space-x-0 2xl:block">
                <div class="w-6 text-center font-medium text-gray-900">{{ style.age }}<template v-if="index === leaseStyles.length - 1">+</template></div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-60 flex flex-col space-y-3 sm:flex-row text-xs sm:space-y-0 sm:space-x-3">
          <div class="w-20 flex-shrink-0">
            <div class="h-10 flex flex-col justify-center">
              <div class="text-xs font-semibold text-gray-900">
                {{ intelCount }} Intel
              </div>
              <div class="text-xs text-gray-500">Updated:</div>
            </div>
          </div>
          <div class="min-w-0 flex-1 grid grid-cols-3 2xl:grid-cols-6 gap-x-4 gap-y-3 2xl:gap-x-3">
            <div v-for="(style, index) in intelStyles" :key="index" class="space-y-1.5 flex flex-col items-center">
              <div
                class="h-4 w-4 rounded-md ring-1 ring-inset ring-black ring-opacity-0"
                :style="`background-color: ${style.fill};`"
              />
              <div class="px-0.5 md:flex md:justify-between md:space-x-2 2xl:space-x-0 2xl:block">
                <div class="w-6 text-center font-medium text-gray-900">{{ style.age }}<template v-if="index === intelStyles.length - 1">+</template></div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div v-else class="w-60 flex flex-col space-y-3 sm:flex-row text-xs sm:space-y-0 sm:space-x-3">
        <div class="w-20 flex-shrink-0">
          <div class="h-10 flex flex-col justify-center">
            <div class="text-xs font-semibold text-gray-900">
              {{ propertyCount | formattedNumber }} Props
            </div>
            <div class="text-xs text-gray-500">Latest intel:</div>
          </div>
        </div>
        <div class="min-w-0 flex-1 grid grid-cols-3 2xl:grid-cols-6 gap-x-4 gap-y-3 2xl:gap-x-3">
          <div v-for="(style, index) in heatMapStyles" :key="index" class="space-y-1.5 flex flex-col items-center">
            <div
              class="h-4 w-4 rounded-md ring-1 ring-inset ring-black ring-opacity-0"
              :style="`background-color: ${style.fill};`"
            />
            <div class="px-0.5 md:flex md:justify-between md:space-x-2 2xl:space-x-0 2xl:block">
              <div class="w-6 text-center font-medium text-gray-900">{{ style.age }}<template v-if="index === heatMapStyles.length - 1">+</template></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MapFilterSlideover from "./filter-slideover";
import MapModeToggle from "./map-mode-toggle.vue";

export default {
  components: { MapModeToggle },
  props: ["map"],
  data() {
    return {
      visible: true,
      visibilityTypes: [
        {
          keys: ["Anyone with investor preview link"],
          shortName: "Need link",
          iconClass: "fas fa-link"
        },
        { keys: ["Safezone"], shortName: "Safezone", iconClass: "fas fa-lock" },
        {
          keys: ["Private Channel"],
          shortName: "Private channel",
          iconClass: "fas fa-bolt"
        },
        {
          keys: ["Deal team", "Deal participants"],
          shortName: "Deal team",
          iconClass: "fas fa-user-shield"
        },
        {
          keys: [
            "Deal team and prospects",
            "Deal team and matching principals"
          ],
          shortName: "Prospects",
          iconClass: "fas fa-hand-holding-usd"
        },
        {
          keys: ["Marked confidential"],
          shortName: "Marked confidential",
          iconClass: "fas fa-shield-alt"
        }
      ],
      heatMapStyles: [
        { age: "1w", fill: "#F472B6" },
        { age: "2w", fill: "#F59E0B" },
        { age: "3w", fill: "#FDE047" },
        { age: "1m", fill: "#4ADE80" },
        { age: "2m", fill: "#06B6D4" },
        { age: "6m", fill: "#4F46E5" }
      ],
      capMarketStyles: [
        { age: "1w", fill: "#D97706" },
        { age: "1m", fill: "#F59E0B" },
        { age: "2m", fill: "#FBBF24" },
        { age: "3m", fill: "#FCD34D" },
        { age: "1y", fill: "#FDE68A" },
        { age: "1y", fill: "#FEF3C7" }
      ],
      leaseStyles: [
        { age: "1w", fill: "#16A34A" },
        { age: "1m", fill: "#22C55E" },
        { age: "2m", fill: "#4ADE80" },
        { age: "3m", fill: "#86EFAC" },
        { age: "1y", fill: "#BBF7D0" },
        { age: "1y", fill: "#DCFCE7" }
      ],
      intelStyles: [
        { age: "1w", fill: "#2563EB" },
        { age: "2w", fill: "#3B82F6" },
        { age: "3w", fill: "#60A5FA" },
        { age: "1m", fill: "#93C5FD" },
        { age: "2m", fill: "#BFDBFE" },
        { age: "2m", fill: "#DBEAFE" }
      ],
      internalPriorityStyles: [
        { priority: "High", value: "high", border: "#D946EF" },
        { priority: "Medium", value: "medium", border: "#8B5CF6" },
        { priority: "Low", value: "low", border: "#06B6D4" },
        { priority: "Watchlist", value: "watchlisted", border: "#6B7280" }
      ]
    };
  },
  computed: {
    ...mapGetters(["hasMapFilters", "signedIn", "mapMode"]),
    ...mapState(["mapNearbyProperties", "mapFocalRecord"]),
    strategicMap() {
      return this.mapMode === "strategic";
    },
    activeVisibilityTypes() {
      var self = this;

      return _.filter(self.visibilityTypes, function(visibilityType) {
        const someNearby = _.some(self.mapNearbyProperties || [], function(p) {
          return self.visibilityTypeIntersection(p, visibilityType);
        });
        const focal = _.some(_.get(self.mapFocalRecord, "properties", []), function(p) {
          return self.visibilityTypeIntersection(p, visibilityType);
        });
        const focalWatchlist = self.visibilityTypeIntersection(
          _.get(self.mapFocalRecord, "property", null),
          visibilityType
        );

        return someNearby || focal || focalWatchlist;
      });
    },
    prioritizedVisibleStyles() {
      var self = this;

      return _.filter(self.internalPriorityStyles, function(priorityStyle) {
        const someNearby = _.some(self.mapNearbyProperties || [], function(p) {
          return self.priorityMatch(p, priorityStyle);
        });
        const focal = _.some(
          _.get(self.mapFocalRecord, "properties", []),
          function(p) {
            return self.priorityMatch(p, priorityStyle);
          }
        );
        const focalWatchlist = self.priorityMatch(
          _.get(self.mapFocalRecord, "property", null),
          priorityStyle
        );

        return someNearby || focal || focalWatchlist;
      });
    },
    propertyCount() {
      return (this.mapNearbyProperties || []).length;
    },
    dealCount() {
      return _.filter(this.mapNearbyProperties || [], function(p) {
        return (
          p.mostRecentReferenceType === "Deal" &&
          p.mostRecentReferenceSubtype !== "Lease"
        );
      }).length;
    },
    leaseCount() {
      return _.filter(this.mapNearbyProperties || [], function(p) {
        return (
          p.mostRecentReferenceType === "Deal" &&
          p.mostRecentReferenceSubtype === "Lease"
        );
      }).length;
    },
    intelCount() {
      return _.filter(this.mapNearbyProperties || [], {
        mostRecentReferenceType: "MarketIntelPost"
      }).length;
    },
    zoom() {
      return this.map.getZoom();
    }
  },
  methods: {
    disableMapScroll() {
      this.map.scrollWheelZoom.disable();
    },
    enableMapScroll() {
      this.map.scrollWheelZoom.enable();
    },
    filter() {
      if (this.signedIn) {
        this.$store.commit("openSlideover", {
          component: MapFilterSlideover,
          props: {
            mapFilter: true
          }
        });
      } else {
        this.$router.push({
          path: "/sign-in",
          query: { nextUrl: this.$store.state.route.fullPath }
        });
      }
    },
    visibilityTypeIntersection(property, visibilityType) {
      if (property) {
        return (
          _.intersection(
            [property.mostRecentReferenceVisibility],
            visibilityType.keys
          ).length > 0
        );
      } else {
        return false;
      }
    },
    priorityMatch(property, priorityStyle) {
      if (property) {
        if (priorityStyle.value === "watchlisted") {
          return (
            property.watchlisted &&
            (!property.internalPriority || property.internalPriority === "none")
          );
        } else {
          return priorityStyle.value === property.internalPriority;
        }
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.leaflet-container a {
  @apply text-indigo-600 font-medium;
}
.leaflet-container a:hover {
  @apply text-indigo-500;
}
</style>
