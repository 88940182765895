<template>
  <div class="wrapper">
    <heading>You need a market-facing email address</heading>
    <form @submit.prevent="save" autocomplete="off" novalidate>
      <div class="input-container mb-2">
        <input
          v-focus
          v-model="marketEmail"
          @focus="focus"
          @blur="blur"
          type="email"
          autocomplete="new-password"
          autocorrect="off"
          spellcheck="false"
          placeholder=""
          tabindex="1"
          name="user_email"
          class="input-text black"
          :class="{ active: focused }"
          required
        >
        <label class="input-label" :class="{ active: focused }">Market-facing email address</label>
        <label class="email-icon" :class="{ active: focused }">
          <i class="far fa-envelope"></i>
        </label>
      </div>

      <option-description v-if="complete || marketEmail === ''" description="This will only be accessible to your mutual connections and sponsors of deals you ask to be contacted about." />
      <option-description v-else description="Not a valid email." />
      <button type="submit" class="cta" :class="{ complete: complete }" tabindex="999">Save</button>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Heading from "../../components/onboarding/heading";
import OptionDescription from "../../components/onboarding/option-description";

export default {
  components: { Heading, OptionDescription },
  data() {
    return {
      focused: false,
      marketEmail: ""
    };
  },
  computed: {
    ...mapState(["currentUser", "emailRegex"]),
    complete() {
      return this.emailRegex.test(this.marketEmail);
    }
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    save() {
      if (this.complete) {
        const newInfo = {
          marketEmail: this.marketEmail
        };

        this.$store.dispatch("updateContactInfo", newInfo);
        this.$store.dispatch("createDealInquiry");
        this.$store.commit("closeModal");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  width: 330px;
}
input {
  vertical-align: middle;
}
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 26px 38px 8px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.active {
    border: 2px solid #5850ec;
  }
}
.input-label {
  position: absolute;
  top: 8px;
  left: 38px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
.email-icon {
  position: absolute;
  top: 19px;
  left: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
.tos-wrap {
  align-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  small {
    margin-top: 20px;
  }
  a {
    text-decoration: none;
  }
}
</style>
