<template>
  <div class="px-4 pt-5 pb-4 sm:p-6">
    <div>
      <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
        <svg class="h-6 w-6 text-green-600" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"/>
        </svg>
      </div>
      <div class="mt-3 text-center sm:mt-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Get in touch with {{ contact.name }}
        </h3>
        <div class="mt-2">
          <p class="text-sm leading-5 text-gray-500">
            Copy current contact info with the buttons below.
          </p>
        </div>
      </div>
    </div>
    <div class="mt-5 sm:mt-6 sm:flex sm:justify-around">
      <span class="flex w-full rounded-md shadow-sm sm:mx-2">
        <button @click="privateChannel" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo sm:text-sm sm:leading-5">
          <svg class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" />
          </svg>
          Private channel
        </button>
      </span>
      <span v-if="contact.phone && contact.phone !== ''" class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:mx-2">
        <button @click="share(contact.phone, 'contact phone number')" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo sm:text-sm sm:leading-5">
          <svg class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
          </svg>
          Phone
        </button>
      </span>
      <span v-if="contact.marketEmail && contact.marketEmail !== ''" class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:mx-2">
        <button @click="share(contact.marketEmail, 'contact email address')" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo sm:text-sm sm:leading-5">
          <svg class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
          </svg>
          Email
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["contact"],
  computed: {
    ...mapState(["adminMode"])
  },
  methods: {
    share(info, trackingName) {
      this.$copyText(info).then(
        () => this.$store.dispatch("flash", `Copied ${trackingName} to clipboard`),
        () => this.$store.dispatch("flash", "Failed to copy")
      );

      if (!!analytics && !this.adminMode) {
        analytics.track(`Copied ${trackingName}`, { yearWeek: this.$store.state.yearWeek });
      }
    },
    privateChannel() {
      const privateAudience = [
        {
          token: this.contact.token
        }
      ];

      this.$store
        .dispatch("createBackchannel", { privateAudience })
        .then(backchannelToken => {
          this.$router.push({
            path: `/backchannel/${backchannelToken}`
          });
          this.$store.commit("closeModal");
        });

      if (!!analytics && !this.adminMode) {
        analytics.track(`Opened private channel`, { yearWeek: this.$store.state.yearWeek });
      }
    }
  }
};
</script>
