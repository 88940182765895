<template>
  <header :class="privacyMode ? 'bg-pink-700' : 'bg-indigo-700'" class="space-y-1 py-6 px-4 sm:px-6">
    <div class="flex items-center justify-between space-x-3">
      <h2 class="text-lg leading-7 font-medium text-white">
        {{ content.name | truncate(30)}}
      </h2>
      <div class="h-7 flex items-center">
        <button @click="close" aria-label="Close panel" :class="privacyMode ? 'text-pink-200' : 'text-indigo-200'" class="hover:text-white">
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
    </div>
    <div>
      <p v-if="content.notes" :class="privacyMode ? 'text-pink-300' : 'text-indigo-300'" class="text-sm leading-5">
        {{ content.notes | truncate(140) }}
      </p>
    </div>
    <div>
      <p :class="privacyMode ? 'text-pink-300' : 'text-indigo-300'" class="flex items-center text-sm leading-5">
        <time v-if="content.closeDate" :datetime="content.closeDate">{{ closeDate }}</time>
        <template v-else-if="isDeal">
          <span v-if="liveDeal" class="mr-1 inline-flex items-center justify-center h-3 w-3 rounded-full bg-red-400"></span>
          {{ content.state | capitalize }}
        </template>
        <time v-else :datetime="content.createdAt">{{ content.createdAt | moment("from", now) }}</time>
        <template v-if="content.pinCount && content.pinCount > 0">
          <span class="mx-1">
            &middot;
          </span>
          <span :class="privacyMode ? 'text-pink-300' : 'text-indigo-300'" class="flex items-center" v-tooltip="'People were helped by this'">
            <svg class="mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
              <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"/>
            </svg>
            <span>{{ content.pinCount }}</span>
          </span>
        </template>
        <template v-if="content.commentCount && content.commentCount > 0">
          <span class="mx-1">
            &middot;
          </span>
          <span :class="privacyMode ? 'text-pink-300' : 'text-indigo-300'" class="flex items-center" v-tooltip="'Comments'">
            <svg class="mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
              <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
              <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
            </svg>
            <span>{{ content.commentCount }}</span>
          </span>
        </template>
        <template v-if="content.bountyCount && content.bountyCount > 0">
          <span class="mx-1">
            &middot;
          </span>
          <span :class="privacyMode ? 'text-pink-300' : 'text-indigo-300'" class="flex items-center" v-tooltip="'Bounties'">
            <svg class="mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
            </svg>
            <span>{{ content.bountyCount }}</span>
          </span>
        </template>
      </p>
    </div>
  </header>
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapState } from "vuex";

export default {
  props: ["content"],
  computed: {
    ...mapGetters(["privacyMode"]),
    ...mapState(["now"]),
    closeDate() {
      return moment(this.content.closeDate).format("MMMM YYYY");
    },
    isDeal() {
      return this.content.newsfeedType === "deal";
    },
    liveDeal() {
      return _.includes(["live", "awarded"], this.content.state);
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
