<template>
  <div v-if="contact" class="lg:flex lg:items-center lg:justify-between">
    <div @click="settings" :class="pointer">
      <avatar-photo :person="contact" circle-size="32" text-size="3xl" class="mb-4 lg:mb-0 lg:mr-4" />
    </div>
    <div class="flex-1 min-w-0">
      <h2 class="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
        {{ name }}<template v-if="userIsActiveContact"> (Me)</template>
      </h2>
      <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">
        <div class="mt-2 flex items-center text-sm leading-5 text-gray-300 sm:mr-6">
          <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path v-if="isFounder" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd" fill-rule="evenodd"/>
            <path v-else d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z"/>
          </svg>
          <template v-if="isFounder">Founder</template>
          <template v-else>{{ contact.industryRole | industryRoleAlias(contact.industrySubRole, contact.token) | capitalize }}</template>
        </div>
        <div v-if="canShowCompany || hasHistoricalCompanies" class="mt-2 flex items-center text-sm leading-5 text-gray-300 sm:mr-6">
          <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd" />
            <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
          </svg>
          <template v-if="canShowCompany">{{ contact.company }}</template><template v-if="hasHistoricalCompanies"><span class="ml-1 text-xs self-end">(Former: {{ contact.historicalCompanies | regionLabels(3) }})</span></template>
        </div>
        <div v-if="hasSchools" class="mt-2 flex items-center text-sm leading-5 text-gray-300 sm:mr-6">
          <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
          </svg>
          {{ contact.schools | regionLabels(3) }}
        </div>
        <div class="mt-2 flex items-center text-sm leading-5 text-gray-300">
          <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"/>
          </svg>
          Following {{ contact.followingCount || 0 }}, Followed by {{ contact.followerCount || 0 }}
        </div>
      </div>
    </div>
    <div class="mt-5 flex lg:mt-0 lg:ml-4">
      <span v-if="userIsActiveContact" class="shadow-sm rounded-md">
        <button @click="settings" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:ring-gray focus:border-gray-700 active:bg-gray-700">
          <svg class="-ml-1 mr-2 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
          </svg>
          Edit
        </button>
      </span>

      <span v-if="canFollow && !signedIn" class="shadow-sm rounded-md">
        <button @click="followContact" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-indigo focus:border-indigo-600 active:bg-indigo-600">
          <svg class="-ml-1 mr-2 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"/>
          </svg>
          Follow
        </button>
      </span>

      <span v-else-if="canFollow" class="relative z-0 inline-flex shadow-sm">
        <button @click="followContact" v-tooltip="'Follow without message'" type="button" class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-indigo-400 text-sm leading-5 font-medium bg-indigo-500 text-white hover:bg-indigo-400 focus:z-10 focus:outline-none focus:border-indigo-600 focus:ring-indigo active:bg-indigo-600">
          Follow
        </button>
        <button @click="message" v-tooltip="messageTooltip" type="button" class="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-indigo-400 text-sm leading-5 font-medium bg-indigo-500 text-white hover:bg-indigo-400 focus:z-10 focus:outline-none focus:border-indigo-600 focus:ring-indigo active:bg-indigo-600">
          <svg class="h-5 w-5 text-white" fill="currentColor" viewBox="0 0 20 20">
            <path d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd" fill-rule="evenodd" />
          </svg>
        </button>
      </span>

      <span v-if="canUnfollow" class="shadow-sm rounded-md" :class="{ 'hidden sm:block': contactable }">
        <button @click="unfollowContact" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:ring-gray focus:border-gray-700 active:bg-gray-700">
          <svg class="-ml-1 mr-2 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
          </svg>
          Unfollow
        </button>
      </span>

      <span v-if="contactable && canUnfollow && !userIsActiveContact" class="sm:ml-3 shadow-sm rounded-md">
        <button @click="contactInfo" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-indigo focus:border-indigo-600 active:bg-indigo-600">
          <svg class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd" />
          </svg>
          Contact
        </button>
      </span>

      <span v-if="contactable && canUnfollow && !userIsActiveContact" ref="menu" :class="{ 'ml-3': contactable }" class="z-40 relative shadow-sm rounded-md sm:hidden">
        <button @click="toggleMenu" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-gray focus:border-gray-800">
          More
          <svg class="-mr-1 ml-2 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </button>

        <div v-show="open" x-transition:enter="transition ease-out duration-200" x-transition:enter-start="transform opacity-0 scale-95" x-transition:enter-end="transform opacity-100 scale-100" x-transition:leave="transition ease-in duration-75" x-transition:leave-start="transform opacity-100 scale-100" x-transition:leave-end="transform opacity-0 scale-95" class="origin-top-left absolute left-0 mt-2 -ml-1 w-48 rounded-md shadow-lg">
          <div class="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5">
            <router-link v-if="contactable && canFollow" @click.native="followContact" to="" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100">Follow</router-link>
            <router-link v-if="contactable && canUnfollow" @click.native="unfollowContact" to="" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100">Unfollow</router-link>
          </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AvatarPhoto from "./avatar-photo";
import ContactInfo from "./cards/contact-info";
import FollowWithMessage from "./follow-message";

export default {
  components: { AvatarPhoto },
  data() {
    return {
      open: false
    };
  },
  computed: {
    ...mapGetters([
      "contact",
      "name",
      "userIsActiveContact",
      "signedIn",
      "followedByMe",
      "hasTargets",
      "contactTokenParam"
    ]),
    ...mapState(["adminMode"]),
    canShowCompany() {
      return this.contact.company;
    },
    canFollow() {
      return (!this.signedIn || (!this.userIsActiveContact && !this.followedByMe)) && this.hasTargets;
    },
    canUnfollow() {
      return (!this.userIsActiveContact && this.followedByMe) && this.hasTargets;
    },
    hasHistoricalCompanies() {
      return this.contact.historicalCompanies.length > 0;
    },
    hasSchools() {
      return this.contact.schools.length > 0;
    },
    hasContactInfo() {
      return (
        (this.contact.phone && this.contact.phone !== "") ||
        (this.contact.marketEmail && this.contact.marketEmail !== "")
      );
    },
    publicEmailOrPhone() {
      return this.hasContactInfo && !this.contact.mutualConnection;
    },
    contactable() {
      return this.contact.mutualConnection || this.publicEmailOrPhone;
    },
    messageTooltip() {
      if (this.contactable) {
        return "Contact this person";
      } else {
        return "Follow with message";
      }
    },
    pointer() {
      if (this.active) {
        return "cursor-pointer";
      } else {
        return "";
      }
    },
    active() {
      return this.signedIn && this.userIsActiveContact;
    },
    isFounder() {
      return this.contactTokenParam === "NfxwbSKdNwLnKJUoZiRJSeK3";
    }
  },
  watch: {
    name: {
      handler() {
        this.setDocumentTitle();
      }
    }
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (this.$refs.menu && !this.$refs.menu.contains(evt.target)) {
        this.open = false;
      }
    });

    this.setDocumentTitle();
  },
  methods: {
    setDocumentTitle() {
      if (this.name && this.contact.company && this.isFounder) {
        document.title = `${this.name} · Founder · ${this.contact.company} | Tower Hunt`;
      } else if (this.name && this.contact.company) {
        document.title = `${this.name} · ${_.capitalize(this.$options.filters.industryRoleAlias(this.contact.industryRole, this.contact.industrySubRole, this.contact.token))} · ${this.contact.company} | Tower Hunt`;
      } else if (this.name) {
        document.title = `${this.name} · ${_.capitalize(this.$options.filters.industryRoleAlias(this.contact.industryRole, this.contact.industrySubRole, this.contact.token))} | Tower Hunt`;
      }
    },
    toggleMenu() {
      this.open = !this.open;
    },
    settings() {
      if (this.active) {
        this.$router.push({ path: "/home/settings" });
      }
    },
    message() {
      if (this.contactable) {
        this.contactInfo();
      } else {
        this.followWithMessage();
      }
    },
    contactInfo() {
      if (!!analytics && !this.adminMode) {
        analytics.track("Viewed contact info", { yearWeek: this.$store.state.yearWeek });
      }

      this.$store.commit("openModal", {
        component: ContactInfo,
        props: {
          contact: this.contact
        }
      });
    },
    followWithMessage() {
      this.$store.commit("openModal", {
        component: FollowWithMessage,
        props: {
          contact: this.contact
        }
      });
    },
    followContact() {
      if (this.signedIn) {
        this.$store.dispatch("followContact", { contactToken: this.contact.token });
      } else {
        this.$store.commit("setNewUserFollowObject", { token: this.contact.token, type: "contact" });
        this.$router.push({ path: `/start/1` });
      }

      if (!!analytics && !this.adminMode) {
        analytics.track("Followed user", { yearWeek: this.$store.state.yearWeek });
      }

      this.open = false;
    },
    unfollowContact() {
      this.$store.dispatch("unfollowContact", this.contact.token);
      this.open = false;
    }
  }
};
</script>
