<template>
  <dl v-if="contact" class="space-y-8">
    <div>
      <dt class="text-sm font-medium text-gray-500">
        Contact info
      </dt>
      <dd v-html="linkifiedInfo" class="mt-1 text-sm text-gray-900 whitespace-pre-line dont-break-out" />
    </div>
  </dl>
</template>

<script>
import linkifyHtml from "linkifyjs/html";

export default {
  props: ["contact"],
  computed: {
    linkifiedInfo() {
      return linkifyHtml(this.contact.contactInfo || "", {
        defaultProtocol: "https",
        className: "font-medium text-indigo-600 hover:text-indigo-500"
      });
    }
  }
};
</script>
