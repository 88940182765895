<template>
  <tr class="overflow-visible">
    <td class="px-6 py-4 whitespace-nowrap" :class="{ 'border-b border-gray-200': index !== lastIndex }">
      <div class="flex items-center">
        <router-link v-if="hasTowerHuntAccount" :to="`/contacts/${person.token}`" target="_blank" class="flex-shrink-0 h-10 w-10">
          <avatar-photo :person="person" circle-size="10" text-size="md" />
        </router-link>
        <div v-else class="flex-shrink-0 h-10 w-10">
          <span class="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
            <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
        </div>
        <div class="ml-4">
          <router-link
            v-if="hasTowerHuntAccount"
            :to="`/contacts/${person.token}`"
            target="_blank"
            class="text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500"
          >
            {{ person.name }}
          </router-link>
          <div class="text-sm leading-5 text-gray-500">
            {{ person.email }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap" :class="{ 'border-b border-gray-200': index !== lastIndex }">
      <div v-if="person.company" class="text-sm leading-5 text-gray-900">
        {{ person.company }}
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap" :class="{ 'border-b border-gray-200': index !== lastIndex }">
      <span
        v-if="activeDeal.state === 'draft'"
        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
      >
        Pre-launch
      </span>
      <span
        v-else-if="person.declined"
        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
      >
        Declined
      </span>
      <span
        v-else-if="person.expired"
        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
      >
        Link expired
      </span>
      <span
        v-else-if="person.viewed"
        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
      >
        Viewed
      </span>
      <span
        v-else-if="!person.dealMarketingState && !activeDealMarketing.sendTeaserEmails"
        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"
      >
        Unviewed
      </span>
      <span
        v-else-if="!person.dealMarketingState && activeDealMarketing.sendTeaserEmails"
        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"
      >
        Notified
      </span>
      <span
        v-else
        v-tooltip="confidentialityAgreementType"
        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
        :class="statusColor"
      >
        {{ person.dealMarketingState | capitalize }}
      </span>
      <span
        v-if="person.followingDeal"
        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
      >
        Following deal
      </span>
      <span
        v-if="person.documentAccess"
        class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"
      >
        Document access
      </span>
    </td>
    <td class="px-6 py-4 whitespace-nowrap" :class="{ 'border-b border-gray-200': index !== lastIndex }">
      <div v-if="person.dealMarketingStateOrdinal >= 2 || person.dealMarketingState === 'declined'" class="text-sm text-gray-900">
        {{ person.impressionCount || 0 }}
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap" :class="{ 'border-b border-gray-200': index !== lastIndex }">
      <div v-if="person.dealMarketingStateOrdinal >= 3 || person.dealMarketingState === 'declined'" class="text-sm text-gray-900">
        Views: {{ person.documentViews || 0 }}
      </div>
      <div v-if="person.dealMarketingStateOrdinal >= 3 || person.dealMarketingState === 'declined'" class="text-sm text-gray-900">
        Downloads: {{ person.documentDownloads || 0 }}
      </div>
    </td>
    <td
      v-if="activeDeal.state !== 'closed'"
      class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
      :class="{ 'border-b border-gray-200': index !== lastIndex }"
    >
      <prospect-menu :person="person" @refetch="refetch" />
    </td>
  </tr>
</template>

<script>
import { mapState } from "vuex";
import AvatarPhoto from "../../components/avatar-photo";
import ProspectMenu from "./prospect-menu";

export default {
  components: { AvatarPhoto, ProspectMenu },
  props: ["person", "index", "lastIndex"],
  data() {
    return {
      removed: false
    };
  },
  computed: {
    ...mapState(["activeDeal", "activeDealMarketing"]),
    hasTowerHuntAccount() {
      return !!this.person.id;
    },
    confidentialityAgreementType() {
      if (this.person.dealMarketingStateOrdinal >= 3) {
        return this.person.confidentialityAgreementType;
      } else {
        return "";
      }
    },
    statusColor() {
      switch (this.person.dealMarketingState) {
        case "notified":
          return "bg-yellow-100 text-yellow-800";
        case "viewed":
          return "bg-green-100 text-green-800";
        case "registered":
        case "downloaded documents":
          return "bg-teal-100 text-teal-800";
        case "wants to discuss":
        case "discussed":
          return "bg-blue-100 text-blue-800";
        case "closed":
          return "bg-indigo-100 text-indigo-800";
        case "declined":
        default:
          return "bg-gray-100 text-gray-800";
      }
    }
  },
  methods: {
    refetch() {
      this.$emit("refetch");
    }
  }
};
</script>
