<template>
  <div class="z-1 flex flex-col">
    <div class="flex-shrink-0 relative">
      <div v-if="mappable" class="h-64">
        <market-map :target="target" />
      </div>
      <div v-if="mappable" class="absolute inset-x-0 bottom-0 transform translate-y-px pointer-events-none" style="z-index: 9995;">
        <div class="flex justify-center transform translate-y-1/2">
          <a @click.prevent="strategicMap" href="" class="inline-flex items-center rounded-full text-sm px-4 py-1 leading-5 bg-indigo-600 hover:bg-indigo-700 font-semibold tracking-wider uppercase text-white pointer-events-auto">
            <!-- Heroicon name: cursor-click -->
            <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z" clip-rule="evenodd" />
            </svg>
            See on strategic map
          </a>
        </div>
      </div>
    </div>
    <router-link @click.native="viewDetails" to="" class="flex-1 bg-white p-6 flex flex-col justify-between">
      <div class="flex-1">
        <span v-if="target.revenueShare" class="mb-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-green-100 text-green-800">
          <svg class="-ml-0.5 mr-1.5 h-4 w-4 text-green-400" fill="currentColor" viewBox="0 0 20 20">
            <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
          </svg>
          Revenue share
        </span>
        <p class="flex text-sm leading-5 font-medium text-indigo-600">
          <span v-if="target.deals.length > 0">{{ target.deals | sortedTypeLabels(2) }}</span>
          <span v-else>All deal types</span>
        </p>
        <span class="block">
          <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
            <template v-if="target.name">{{ target.name }}</template>
            <template v-else>{{ target.regions | regionLabels(3) }}</template>
          </h3>
          <p class="mt-3 text-sm leading-6 text-gray-500 whitespace-pre-line">
            <template v-if="target.notes">{{ truncatedNotes }}</template>
            <template v-else-if="target.uses.length > 0">{{ target.uses | sortedTypeLabels(3) }}</template>
            <template v-else>All property uses</template>
          </p>
          <p v-if="!target.notes" class="mt-3 text-sm leading-6 text-gray-500 whitespace-pre-line">
            <template v-if="target.risks.length > 0">{{ target.risks | sortedTypeLabels(2) }}</template>
            <template v-else>All strategies</template>
          </p>
        </span>
      </div>
      <div class="mt-6 flex items-center justify-between">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400">
              <span class="text-md font-medium leading-none text-white">{{ target.dealCount || 0 }}</span>
            </span>
          </div>
          <div class="ml-3">
            <p class="text-sm leading-5 font-medium text-gray-900">
              <template v-if="target.dealCount > 1">Deals since {{ oldestCloseDate }}</template>
              <template v-else-if="target.dealCount === 1">Deal since {{ oldestCloseDate }}</template>
              <template v-else>Deals added</template>
            </p>
            <deal-volume-summary :record="target" />
          </div>
        </div>
        <button @click.stop.prevent="clone" type="button" v-tooltip="'Clone this market and customize it for yourself.'" class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <!-- Heroicon name: solid/plus -->
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
          </svg>
        </button>
      </div>
    </router-link>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapState } from "vuex";
import DealVolumeSummary from "./deal-volume-summary";
import Market from "./market";
import MarketMap from "../maps/target-map";
import api from "../../api";
/* global analytics */

export default {
  components: { DealVolumeSummary, MarketMap },
  props: ["target"],
  computed: {
    ...mapGetters(["userIsActiveContact", "name", "signedIn"]),
    ...mapState([
      "activeContact",
      "currentUser",
      "modal",
      "route",
      "adminMode"
    ]),
    mappable() {
      return _.get(this.target, "regions", []).length > 0;
    },
    truncatedNotes() {
      return _.truncate(this.target.notes, { length: 225 });
    },
    oldestCloseDate() {
      if (this.target.oldestCloseDate) {
        return moment(this.target.oldestCloseDate).format("YYYY");
      } else {
        return "";
      }
    }
  },
  methods: {
    viewDetails() {
      this.$store.commit("openModal", {
        component: Market,
        props: {
          target: this.target,
          explorable: true
        }
      });
    },
    strategicMap() {
      if (this.signedIn) {
        this.$router.push({
          path: `/map`,
          query: { market: this.target.token }
        });
      } else {
        this.$router.push({
          path: "/sign-in",
          query: { nextUrl: `/map?market=${this.target.token}` }
        });
      }
    },
    clone() {
      if (this.signedIn) {
        api.post(`clone_target/${this.target.token}`).then(json => {
          if (!!analytics && !this.adminMode) {
            analytics.track("Market cloned", {
              yearWeek: this.$store.state.yearWeek,
              marketName: this.$options.filters.marketName(this.target)
            });
          }
          if (this.userIsActiveContact) {
            this.$store.dispatch("loadContactData", this.currentUser.token);
          }
          this.$store.dispatch("flash", "Market cloned successfully! Visit your dashboard or profile to customize.");
          this.$store.commit("closeModal");
        });
      } else {
        this.$router.push({ path: `/clone_markets/${this.target.token}` });
        this.$store.commit("closeModal");
      }
    }
  }
};
</script>
