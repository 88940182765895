<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
  -->
  <div class="bg-white">
    <product-marketing-top-bar />

    <div class="bg-gray-50">
      <!-- Header section -->
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="text-center">
          <h1 class="text-base leading-6 font-semibold text-indigo-600 uppercase tracking-wide">Bounties</h1>
          <p class="mt-1 text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">Earn money for local expertise.</p>
          <p class="max-w-xl mx-auto mt-5 text-xl leading-7 text-gray-500">Bid to provide help to people in your markets. Get paid when you deliver.</p>
        </div>
      </div>
    </div>

    <!-- Split brand panel -->
    <div class="relative bg-white">
      <div class="absolute inset-0" aria-hidden="true">
        <div class="absolute inset-y-0 right-0 w-1/2 bg-gradient-to-r from-indigo-600 to-blue-500"></div>
      </div>
      <div class="relative max-w-screen-xl mx-auto lg:grid lg:grid-cols-2 lg:px-8">
        <div class="bg-white py-16 px-4 sm:py-24 sm:px-6 lg:px-0 lg:pr-8">
          <div class="max-w-lg mx-auto lg:mx-0">
            <h2 class="text-base leading-6 font-semibold tracking-wide text-indigo-600 uppercase">
              Unlock value
            </h2>
            <p class="mt-2 text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">Supplement your income doing what you do best</p>
            <dl class="mt-12 space-y-10">
              <div class="flex">
                <div class="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-indigo-600 to-blue-500 rounded-md flex items-center justify-center">
                  <!-- Heroicon name: users -->
                  <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-medium text-gray-900">
                    Worth your time
                  </dt>
                  <dd class="mt-2 text-base leading-6 text-gray-500">
                    Set your own prices based on the scope, urgency, and difficulty of each request, as well as the value of the information involved.
                  </dd>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-indigo-600 to-blue-500 rounded-md flex items-center justify-center">
                  <!-- Heroicon name: view-list -->
                  <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                  </svg>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-medium text-gray-900">
                    Targeted opportunities
                  </dt>
                  <dd class="mt-2 text-base leading-6 text-gray-500">
                    Like everything else on Tower Hunt, bounties are filtered based on your markets. They're also reviewed for clarity and specificity so you can trust that each one is actionable.
                  </dd>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-indigo-600 to-blue-500 rounded-md flex items-center justify-center">
                  <!-- Heroicon name: view-boards -->
                  <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                  </svg>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-medium text-gray-900">
                    Built around trust
                  </dt>
                  <dd class="mt-2 text-base leading-6 text-gray-500">
                    You bid anonymously, and with each successful fulfillment you build a reputation that helps you get hired and paid more quickly.
                  </dd>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-indigo-600 to-blue-500 rounded-md flex items-center justify-center">
                  <!-- Heroicon name: calendar -->
                  <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-medium text-gray-900">
                    Fast and easy
                  </dt>
                  <dd class="mt-2 text-base leading-6 text-gray-500">
                    It's simple to submit bids and deliverables (including support for multiple attachments). No recreating the wheel.
                  </dd>
                </div>
              </div>
            </dl>
          </div>
        </div>
        <div class="bg-gradient-to-r from-indigo-600 to-blue-500 py-16 px-4 sm:py-24 sm:px-6 lg:bg-none lg:flex lg:items-center lg:justify-end lg:px-0 lg:pl-8">
          <div class="max-w-lg mx-auto w-full space-y-8 lg:mx-0">
            <div>
              <h2 class="sr-only">Price</h2>
              <p class="relative grid grid-cols-1">
                <span class="flex flex-col text-center">
                  <span class="text-5xl leading-none font-extrabold text-white tracking-tight">Set your own price</span>
                  <span class="mt-2 text-base leading-6 font-medium text-indigo-200">keep 80% of the revenue</span>
                </span>
              </p>
            </div>
            <ul class="bg-indigo-700 bg-opacity-50 rounded sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col">
              <li class="py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-indigo-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Sale comps</span>
              </li>
              <li class="border-t border-indigo-400 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-indigo-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Lease comps</span>
              </li>
              <li class="border-t border-indigo-400 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-indigo-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Development pipelines</span>
              </li>
              <li class="border-t border-indigo-400 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white sm:border-t-0 sm:border-l">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-indigo-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Owner lists</span>
              </li>
              <li class="border-t border-indigo-400 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white sm:border-l">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-indigo-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Modeling help</span>
              </li>
              <li class="border-t border-indigo-400 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white sm:border-l">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-indigo-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Individual metrics</span>
              </li>
            </ul>
            <template v-if="signedIn">
              <router-link to="/bounties/active" class="w-full bg-white border border-transparent rounded-md py-4 px-8 flex items-center justify-center text-lg leading-6 font-medium text-indigo-600 hover:text-indigo-500 md:px-10">
                Bid on active bounties
              </router-link>
              <router-link to="/bounties/my-bounties" class="block text-center text-base leading-6 font-medium text-indigo-200 hover:text-white">
                Create a bounty
              </router-link>
            </template>
            <router-link v-else to="/start/1" class="w-full bg-white border border-transparent rounded-md py-4 px-8 flex items-center justify-center text-lg leading-6 font-medium text-indigo-600 hover:text-indigo-500 md:px-10">
              Get started
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Logo cloud -->
    <div class="bg-indigo-300 bg-opacity-25">
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="lg:grid lg:grid-cols-2 lg:gap-8 items-center">
          <h2 class="max-w-md mx-auto text-3xl leading-9 font-extrabold text-indigo-900 text-center lg:max-w-xl lg:text-left">
            Join local market experts from
          </h2>
          <div class="mt-8 flow-root lg:mt-0 self-center">
            <div class="-mt-4 -ml-8 flex flex-wrap justify-around">
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/Bulfinch_indigo0.png" alt="Bulfinch">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/CBRE_indigo0.png" alt="CBRE">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/CushmanWakefield_indigo0.png" alt="Cushman & Wakefield">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/DavisCompanies_indigo0.png" alt="The Davis Companies">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/EastdilSecured_indigo0.png" alt="Eastdil Secured">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/JLL_indigo0.png" alt="JLL">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/newmark_indigo0.png" alt="Newmark">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/Nordblom_indigo0.png" alt="Nordblom">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FAQs -->
    <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <h2 class="text-3xl leading-9 font-extrabold text-gray-900 text-center">
        Frequently asked questions
      </h2>
      <div class="mt-12">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              How does this work?
            </template>
            <template v-slot:secondary>
              Bounties you can bid on are listed on the <router-link to="/bounties/active" class="underline">Active Bounties page</router-link>. You'll also see them in the newsfeed. Bid on any bounty you think you can fulfill before it expires. Fulfill any bids that get accepted. If your deliverable passes a Tower Hunt review, you get paid. That's it!
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              What do people see when I bid?
            </template>
            <template v-slot:secondary>
              Only the bounty requester can see bids. Your bid is anonymous. It will display your pricing, your description of how you can help, and your track record fulfilling bounties. Do not answer the request yet!
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              What if I have some but not all of the requested info?
            </template>
            <template v-slot:secondary>
              No problem! The bounty requester can accept multiple bids. Just be specific about what you can and can't provide when you bid, and have your pricing reflect that. As long as you do a great job delivering on your bid, you'll get paid. The power of the network is that it can help piece together info in highly fragmented markets, so use this to your advantage!
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              What is required for a successful bounty fulfillment?
            </template>
            <template v-slot:secondary>
              Fulfilling a bounty request involves a written response and/or a collection of file attachments. Which you choose depends on the request and what you said you'd do in your bid. To be eligible for a payout, you need to submit your deliverable before expiration. Once you've submitted a deliverable, Tower Hunt reviews it for quality and completeness. Approved submissions are considered fulfilled, at which time the payout revenue is booked.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              Are there any restrictions on what I can use to fulfill a bounty?
            </template>
            <template v-slot:secondary>
              Any information that you know as a result of your experience and expertise in a market is fair game. This is purposefully broad but does exclude certain things. For example, we will not accept deliverables that are exported from paid third-party databases. If we determine a particular deliverable is problematic, we'll attempt to reach you to correct it.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              Anything else I should keep in mind when I fulfill a bounty?
            </template>
            <template v-slot:secondary>
              If your deliverable includes any files, keep in mind that they can expose your identity if you're not careful. In particular, <a href="https://support.microsoft.com/en-us/office/remove-hidden-data-and-personal-information-by-inspecting-documents-presentations-or-workbooks-356b7b5d-77af-44fe-a07f-9aa4d085966f" target="_blank" class="underline">check for any metadata</a> that may be embedded in the file when you create it, especially in office file formats and PDFs.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              When do I get paid?
            </template>
            <template v-slot:secondary>
              You'll book the payout revenue when your deliverable is approved by Tower Hunt. Payment is captured by Tower Hunt and held for two weeks to guard against bad faith submissions. Once the two weeks have elapsed, you'll be paid out immediately.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              How does the 80% revenue share work?
            </template>
            <template v-slot:secondary>
              Upon bounty fulfillment, the requester pays Tower Hunt price you set for your bid. After the two-week challenge window burns off, you'll be paid out 80% of the revenue. Tower Hunt's 20% share covers the operating costs of the marketplace and makes us a profit.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              How do I track my bids and fulfillments?
            </template>
            <template v-slot:secondary>
              Unless you opt out, we'll notify you whenever a new bounty is created in your markets. We also notify you when one of your bids is accepted (including how much time is remaining). Finally, we notify you whenever your bounty deliverables are approved. You can track all of your bounty engagements from the <router-link to="/bounties/my-engagements" class="underline">My Engagements page</router-link>.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              Once my fulfillment has cleared the challenge period, can I offer it to other people in the market?
            </template>
            <template v-slot:secondary>
              Yes! Learn more about selling premium insights <router-link to="/product/sell-premium-content" class="underline">here</router-link>.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              Is there any more documentation than this?
            </template>
            <template v-slot:secondary>
              Our <router-link to="/legal#terms-of-service" target="_blank" class="underline">Terms of Service</router-link> explain bounties further.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              Can I create my own bounty requests, too?
            </template>
            <template v-slot:secondary>
              Yes! Look for the "Create a bounty" button at the top of the newsfeed or on the <router-link to="/bounties/my-bounties" class="underline">Bounties page</router-link>. Learn more about bounty requests <router-link to="/product/bounties" class="underline">here</router-link>.
            </template>
          </text-accordian>
        </dl>
      </div>
    </div>

    <!-- CTA section -->
    <div v-if="!signedIn" class="bg-gradient-to-r from-indigo-600 to-blue-500">
      <div class="max-w-2xl mx-auto py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
        <h2 class="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10">
          <span class="block">Ready to earn?</span>
          <span class="block text-indigo-900">Create a profile today.</span>
        </h2>
        <p class="mt-4 text-lg leading-6 text-indigo-200">We'll also help you make new connections and track your local markets.</p>
        <router-link to="/start/1" class="mt-8 w-full bg-white border border-transparent rounded-md py-3 px-5 inline-flex items-center justify-center text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 sm:w-auto">
          Get started for free
        </router-link>
      </div>
    </div>

    <!-- Simple footer -->
    <marketing-footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MarketingFooter from "../components/marketing-footer";
import ProductMarketingTopBar from "../components/product-marketing-top-bar";
import TextAccordian from "../components/text-accordian.vue";

export default {
  components: { MarketingFooter, ProductMarketingTopBar, TextAccordian },
  computed: {
    ...mapGetters(["signedIn"])
  },
  mounted() {
    document.title = "Bounties: Earn money for local expertise | Tower Hunt";
  }
};
</script>
