<template>
  <div class="block">
    <div v-if="signedIn" class="ml-4 flex items-center md:ml-6">
      <notifications-menu />
      <notepad-button />
      <avatar-menu />
    </div>
    <div v-else class="ml-4 flex items-center md:ml-6">
      <button
        @click="signIn"
        type="button"
        class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:ring-indigo active:bg-indigo-200"
      >
        Sign in
      </button>
      <span class="ml-3 inline-flex rounded-md shadow-sm">
        <button
          @click="joinNow"
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700"
        >
          Join now
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AvatarMenu from "../components/avatar-menu";
import NotepadButton from "./notepad-button";
import NotificationsMenu from "../components/notifications-menu";

export default {
  components: { AvatarMenu, NotepadButton, NotificationsMenu },
  computed: {
    ...mapState(["currentUser"]),
    ...mapGetters(["signedIn"])
  },
  methods: {
    signIn() {
      this.$router.push({ path: "/sign-in" });
    },
    joinNow() {
      this.$router.push({ path: "/start/1" });
    }
  }
};
</script>
