<template>
  <div
    class="z-1 flex flex-grow flex-col"
    v-observe-visibility="{ callback: createImpression, once: true }"
  >
    <div class="flex-shrink-0 relative">
      <template v-if="unmasked">
        <img v-if="deal.coverPhoto" @click="viewDetails" :class="compact ? 'h-28' : 'h-64'" class="w-full object-cover cursor-pointer" :src="deal.coverPhoto" alt="Deal cover photo" />
        <img v-else-if="primaryAttachmentIsImage" @click="viewDetails" :class="compact ? 'h-28' : 'h-64'" class="w-full object-cover cursor-pointer" :src="deal.primaryAttachment" alt="Deal attached photo" />
        <img v-else-if="deal.thumbnail" @click="viewDetails" :class="compact ? 'h-28' : 'h-64'" class="w-full object-cover cursor-pointer" :src="deal.thumbnail" alt="Deal url thumbnail" />
        <div v-else-if="mappable && !compact" class="h-64">
          <deal-map :deal="deal" />
        </div>
        <div v-if="mappable && !compact" class="absolute inset-x-0 bottom-0 transform translate-y-px pointer-events-none" style="z-index: 9995;">
          <div class="flex justify-center transform translate-y-1/2">
            <a @click.prevent="strategicMap" href="" class="inline-flex items-center rounded-full text-sm px-4 py-1 leading-5 bg-indigo-600 hover:bg-indigo-700 font-semibold tracking-wider uppercase text-white pointer-events-auto">
              <!-- Heroicon name: cursor-click -->
              <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z" clip-rule="evenodd" />
              </svg>
              See comps on strategic map
            </a>
          </div>
        </div>
      </template>
      <img v-else @click="viewDetails" :class="compact ? 'h-28' : 'h-auto'" class="w-full object-contain cursor-pointer" src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png" alt="Placeholder image" />
    </div>
    <div :class="compact ? 'p-2' : 'p-6'" class="relative flex-1 bg-white flex flex-col justify-between">
      <right-edge-buttons :content="deal" content-type="deal" />
      <div @click="viewDetails" class="flex-1 cursor-pointer">
        <div :class="compact ? 'text-xs leading-4' : 'text-sm leading-5'" class="flex items-center font-medium text-indigo-600">
          <img v-show="false" class="h-6 w-6 mr-2" src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png" alt="Tower Hunt" />
          <span :class="compact ? 'text-xs leading-4' : 'text-sm leading-5'" class="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-md font-medium bg-yellow-100 text-yellow-800">
            <svg class="-ml-0.5 mr-1.5 h-4 w-4 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
              <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
            </svg>
            {{ deal.type | dealTypeAlias(deal.jointVentureRoles) }}
          </span>
          <span v-if="(deal.type === 'Lease' || deal.totalValue) && unmasked">
            <template v-if="deal.type === 'Lease'">{{
              deal | totalLeaseSize
            }}</template>
            <template v-else>{{ deal.totalValue | largeDollar }}</template>
          </span>
          <template v-if="deal.goingInPerUnit && unmasked">
            <span class="mx-1">
              &middot;
            </span>
            <span>
              {{ deal | perUnitPrice }}
            </span>
          </template>
        </div>
        <span class="block">
          <h3 :class="compact ? 'mt-1 text-base leading-4' : 'mt-2 text-xl leading-7'" class="font-semibold text-gray-900">
            <template v-if="unmasked">{{ deal.name }}</template>
            <template v-else>Confidential</template>
          </h3>
          <div :class="compact ? 'text-xs leading-4' : 'text-sm leading-5'" class="text-gray-500">
            <template v-if="unmasked && deal.properties">
              <template v-if="deal.type === 'Lease'">
                <span>{{ deal.properties | regionLabels }}</span>
                <span class="mx-1">
                  &middot;
                </span>
              </template>
              <span>{{ deal.properties | summarizedLocation }}</span>
              <span class="mx-1">
                &middot;
              </span>
              <span>{{ deal.useTypes }}</span>
            </template>
            <template v-else>Confidential</template>
          </div>
          <div v-if="unmasked && notes" :class="compact ? 'mt-2 text-xs leading-4' : 'mt-4 text-sm leading-6'" class="text-gray-500 whitespace-pre-line">{{ truncated(notes) }}</div>
          <div v-else-if="unmasked && deal.secondaryHeadline" :class="compact ? 'mt-2 text-xs leading-4' : 'mt-4 text-sm leading-6'" class="text-gray-500 whitespace-pre-line">{{ truncated(deal.secondaryHeadline) }}</div>
        </span>
      </div>
      <div :class="compact ? 'mt-2' : 'mt-6'" class="flex items-center justify-between">
        <div class="flex items-center">
          <div>
            <div :class="compact ? 'text-xs leading-4' : 'text-sm leading-5'" class="flex items-center text-gray-500">
              <time v-if="deal.closeDate" :datetime="deal.closeDate">{{ closeDate }}</time>
              <template v-else>
                <span v-if="liveDeal" class="mr-1 inline-flex items-center justify-center h-3 w-3 rounded-full bg-red-400"></span>
                {{ deal.state | capitalize }}
              </template>
            </div>
          </div>
          <template v-if="deal.pinCount && deal.pinCount > 0">
            <span class="mx-1">
              &middot;
            </span>
            <span :class="compact ? 'text-xs' : 'text-sm'" class="flex items-center text-indigo-600" v-tooltip="'People were helped by this'">
              <svg class="mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"/>
              </svg>
              <span>{{ deal.pinCount }}</span>
            </span>
          </template>
          <template v-if="deal.commentCount && deal.commentCount > 0">
            <span class="mx-1">
              &middot;
            </span>
            <span @click="viewComments" :class="compact ? 'text-xs' : 'text-sm'" class="flex items-center text-indigo-600 cursor-pointer" v-tooltip="'Comments'">
              <svg class="mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
              </svg>
              <span>{{ deal.commentCount }}</span>
            </span>
          </template>
          <template v-if="deal.bountyCount && deal.bountyCount > 0">
            <span class="mx-1">
              &middot;
            </span>
            <span @click="viewComments" :class="compact ? 'text-xs' : 'text-sm'" class="flex items-center text-indigo-600 cursor-pointer" v-tooltip="'Bounties'">
              <svg class="mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
              </svg>
              <span>{{ deal.bountyCount }}</span>
            </span>
          </template>
          <template v-if="deal.followed">
            <span class="mx-1">
              &middot;
            </span>
            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-100 text-indigo-800">
              Following
            </span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapState } from "vuex";
import Comments from "../comments";
import DealCard from "./deal";
import DealMap from "../maps/deal-map";
import RightEdgeButtons from "./right-edge-buttons";

export default {
  components: { DealMap, RightEdgeButtons },
  props: ["deal", "compact", "property"],
  computed: {
    ...mapGetters(["userIsActiveContact", "name", "signedIn"]),
    ...mapState([
      "activeContact",
      "currentUser",
      "modal",
      "route",
      "adminMode"
    ]),
    isPipeline() {
      return this.route.path === "/pipeline";
    },
    mappable() {
      return _.get(this.deal, "properties", []).length > 0;
    },
    unmasked() {
      return (
        !this.deal.confidential ||
        this.deal.dealCloser ||
        (this.deal.source === "indirect" && this.deal.authored)
      );
    },
    notes() {
      return this.deal.notes;
    },
    closeDate() {
      return moment(this.deal.closeDate).format("MMMM YYYY");
    },
    marketingListDeal() {
      return this.deal.source === "direct";
    },
    liveDeal() {
      return _.includes(["live", "awarded"], this.deal.state);
    },
    activeDeal() {
      return _.includes(["draft", "live", "awarded"], this.deal.state);
    },
    primaryAttachmentIsImage() {
      if (this.deal.primaryAttachmentType) {
        return _.includes(this.deal.primaryAttachmentType, "image");
      } else {
        return false;
      }
    }
  },
  methods: {
    truncated(text) {
      return _.truncate(text, { length: 140 });
    },
    createImpression(isVisible, entry) {
      if (!this.adminMode && this.signedIn && isVisible) {
        this.$store.dispatch("createDealImpression", this.deal.token).then(() => {
          this.$store.dispatch("loadUnreadCounts");
        });
      }
    },
    viewDetails() {
      if (this.unmasked) {
        if (!!analytics && !this.adminMode) {
          analytics.track("Clicked deal preview card", {
            state: this.deal.state,
            yearWeek: this.$store.state.yearWeek
          });
        }

        if (this.signedIn) {
          if (this.activeDeal && this.marketingListDeal && this.deal.dealTeam) {
            const marketingPage =
              this.deal.state === "draft" ? "overview" : "marketing-list";

            this.$router.push({
              path: `/deal_offerings/${this.deal.urlHandle || this.deal.token}/${marketingPage}`
            });
          } else if (this.liveDeal && this.marketingListDeal && this.deal.marketingReadAuthorized) {
            const marketingOverviewPath = `/deal_offerings/${this.deal.urlHandle || this.deal.token}/overview`;

            window.open(marketingOverviewPath, "_blank");
          } else {
            this.$store.commit("openModal", {
              component: DealCard,
              props: {
                dealObject: this.deal,
                property: this.property
              }
            });
          }
        } else {
          if (this.liveDeal && this.marketingListDeal && this.deal.marketingReadAuthorized) {
            const marketingOverviewPath = `/deal_offerings/${this.deal.urlHandle || this.deal.token}/overview`;

            window.open(marketingOverviewPath, "_blank");
          } else {
            this.$router.push({
              path: "/sign-in",
              query: { nextUrl: `/open_deal/${this.deal.token}` }
            });
          }
          this.$store.commit("closeModal");
        }
      } else {
        this.$store.dispatch("flash", "Access denied");
      }
    },
    viewComments() {
      if (this.signedIn) {
        this.$store.commit("openSlideover", {
          component: Comments,
          props: {
            content: this.deal
          }
        });
      } else {
        this.$router.push({
          path: "/sign-in",
          query: { nextUrl: `/open_deal/${this.deal.token}` }
        });
        this.$store.commit("closeModal");
      }
    },
    strategicMap() {
      this.$router.push({ path: `/map?deal=${this.deal.token}` });
      this.$store.commit("clearModal");
    }
  }
};
</script>
