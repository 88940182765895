<template>
  <div>
    <div v-if="styleType === 'dot'" v-tooltip="recordTypeHelp" :class="`w-2.5 h-2.5 flex-shrink-0 rounded-full bg-${recordColor}-400`"></div>
    <div v-else-if="styleType === 'icon'" class="flex-shrink-0 flex flex-col space-y-1 items-center">
      <button v-tooltip="recordTypeHelp" @click="viewDetails" :class="`inline-flex items-center justify-center h-8 w-8 rounded-full bg-${recordColor}-100 text-${recordColor}-800`">
        <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path v-if="content.newsfeedType === 'intel'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
          <path v-else-if="content.newsfeedType === 'deal'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
          <path v-else-if="content.badgeType === 'message'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
          <path v-else-if="content.badgeType === 'notepad'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          <path v-else-if="content.badgeType === 'backchannel'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
          <path v-else-if="content.badgeType === 'team'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
          <path v-else-if="content.badgeType === 'property'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
          <path v-else-if="content.badgeType === 'file'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
        </svg>
      </button>
      <template v-if="overlayBadge">
        <span v-if="content.safezone" v-tooltip="'Safezone'" class="mx-auto inline-flex items-center p-0.5 rounded-md text-sm font-medium leading-5 bg-pink-100 text-pink-800">
          <svg class="h-4 w-4 text-pink-400" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
          </svg>
        </span>
        <span v-else-if="content.pinned" v-tooltip="'Bookmarked'" class="mx-auto inline-flex items-center p-0.5 rounded-md text-sm font-medium leading-5 bg-yellow-100 text-yellow-800">
          <svg class="h-4 w-4 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </span>
        <span v-else-if="content.badgeType === 'file' && content.hostName === 'dropbox'" v-tooltip="'Dropbox link'" class="mx-auto inline-flex items-center p-0.5 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
          <svg class="h-4 w-4 text-gray-800" fill="currentColor" viewBox="0 0 528 512">
            <path d="M264.4 116.3l-132 84.3 132 84.3-132 84.3L0 284.1l132.3-84.3L0 116.3 132.3 32l132.1 84.3zM131.6 395.7l132-84.3 132 84.3-132 84.3-132-84.3zm132.8-111.6l132-84.3-132-83.6L395.7 32 528 116.3l-132.3 84.3L528 284.8l-132.3 84.3-131.3-85z" />
          </svg>
        </span>
        <span v-else-if="content.badgeType === 'file' && content.hostName === 'google'" v-tooltip="'Google Drive link'" class="mx-auto inline-flex items-center p-0.5 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
          <svg class="h-4 w-4 text-gray-800" fill="currentColor" viewBox="0 0 512 512">
            <path d="M339 314.9L175.4 32h161.2l163.6 282.9H339zm-137.5 23.6L120.9 480h310.5L512 338.5H201.5zM154.1 67.4L0 338.5 80.6 480 237 208.8 154.1 67.4z" />
          </svg>
        </span>
        <span v-else-if="content.badgeType === 'file' && content.hostName === 'microsoft'" v-tooltip="'OneDrive or SharePoint link'" class="mx-auto inline-flex items-center p-0.5 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
          <svg class="h-4 w-4 text-gray-800" fill="currentColor" viewBox="0 0 448 512">
            <path d="M0 32h214.6v214.6H0V32zm233.4 0H448v214.6H233.4V32zM0 265.4h214.6V480H0V265.4zm233.4 0H448V480H233.4V265.4z" />
          </svg>
        </span>
        <span v-else-if="content.badgeType === 'file' && content.hostName === 'tower_hunt'" v-tooltip="'Uploaded file'" class="mx-auto inline-flex items-center p-0.5 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
          <svg class="h-4 w-4 text-gray-800" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
          </svg>
        </span>
        <span v-else-if="content.badgeType === 'file' && content.hostName === 'url'" v-tooltip="'External file link'" class="mx-auto inline-flex items-center p-0.5 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800">
          <svg class="h-4 w-4 text-gray-800" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
          </svg>
        </span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ["content", "styleType", "overlayBadge"],
  computed: {
    recordTypeHelp() {
      switch (this.content.badgeType || this.content.newsfeedType) {
        case "deal":
          return this.$options.filters.dealTypeAlias(this.content.type, this.content.jointVentureRoles);
        case "intel":
          return this.$options.filters.postTypeAlias(this.content.type);
        case "message":
          return "Public discussion";
        case "notepad":
          return "Notepad";
        case "backchannel":
          return "Private channel";
        case "team":
          return "Team";
        case "property":
          return "Watchlist property";
        case "file":
          return "Safezone File";
        default:
          return "Unknown type";
      }
    },
    recordColor() {
      switch (this.content.badgeType || this.content.newsfeedType) {
        case "deal":
          return "yellow";
        case "intel":
          switch (this.content.type) {
            case "jobPosting":
            case "helpRequest":
              return "purple";
            case "industryEvent":
              return "red";
            case "developmentNews":
              return "orange";
            case "infrastructureNews":
              return "yellow";
            case "tenantInMarket":
              return "green";
            case "marketCommentary":
              return "teal";
            case "marketReport":
              return "blue";
            case "offMarketPropertySnapshot":
              return "gray";
            default:
              return "indigo";
          }
        case "message":
          return "blue";
        case "notepad":
        case "file":
          return "indigo";
        case "backchannel":
          return "gray";
        case "team":
          return "yellow";
        case "property":
          return "green";
        default:
          return "indigo";
      }
    }
  },
  methods: {
    viewDetails() {
      this.$emit("view-details");
    }
  }
};
</script>
