<template>
  <div class="relative pb-8">
    <span v-if="extendLine" class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
    <div class="relative flex space-x-3">
      <div>
        <span class="h-8 w-8 rounded-full bg-yellow-100 text-yellow-400 flex items-center justify-center ring-8 ring-white">
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
          </svg>
        </span>
      </div>
      <div class="min-w-0 flex-1 pt-1.5 flex flex-col">
        <div class="flex justify-between space-x-4">
          <div>
            <p v-if="unmasked" class="text-sm text-gray-500">
              <template v-if="deal.type === 'Lease'">{{ deal | totalLeaseSize }}</template>
              <template v-else-if="deal.totalValue">
                <template v-if="!deal.closeDate">~</template>{{ deal.totalValue | largeDollar }}
                <template v-if="deal.goingInPerUnit">({{ deal | perUnitPrice }})</template>
              </template>
              <a @click.prevent="viewDetails" class="font-medium text-gray-900 hover:underline cursor-pointer">{{ deal.type | dealTypeAlias(deal.jointVentureRoles) }}<template v-if="showDealName">: {{ deal.name }}</template></a>
            </p>
            <p v-else class="text-sm text-gray-500">Confidential</p>
          </div>
          <div v-if="unmasked" class="flex items-center text-right text-sm whitespace-nowrap text-gray-500">
            <template v-if="liveDeal">
              <span class="mr-1 inline-flex items-center justify-center h-3 w-3 rounded-full bg-red-400"></span>
              {{ deal.state | capitalize }}
            </template>
            <time v-else :datetime="deal.newsfeedSortDate">{{ sortDate }}</time>
          </div>
        </div>
        <div v-if="unmasked && (deal.notes || deal.secondaryHeadline)" v-html="linkifiedNotes" class="mt-1 text-xs leading-4 text-gray-500 whitespace-pre-line dont-break-out line-clamp-3" />
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapState } from "vuex";
import DealCard from "./cards/deal";
import linkifyHtml from "linkifyjs/html";

export default {
  props: ["deal", "extendLine", "property"],
  computed: {
    ...mapGetters(["signedIn"]),
    ...mapState(["currentUser"]),
    showDealName() {
      if (this.property) {
        return _.toLower(this.property.name) !== _.toLower(this.deal.name);
      } else {
        return true;
      }
    },
    unmasked() {
      return (
        !this.deal.confidential ||
        this.deal.dealCloser ||
        (this.deal.source === "indirect" && this.deal.authored)
      );
    },
    sortDate() {
      return moment(this.deal.newsfeedSortDate).format("MMM YYYY");
    },
    liveDeal() {
      return _.includes(["live", "awarded"], this.deal.state);
    },
    activeDeal() {
      return _.includes(["draft", "live", "awarded"], this.deal.state);
    },
    marketingListDeal() {
      return this.deal.source === "direct";
    },
    linkifiedNotes() {
      return linkifyHtml(
        _.get(this.deal, "notes", "") ||
          _.get(this.deal, "secondaryHeadline", ""),
        {
          defaultProtocol: "https",
          className: "font-medium text-indigo-600 hover:text-indigo-500"
        }
      );
    }
  },
  methods: {
    viewDetails() {
      if (this.unmasked) {
        if (this.signedIn) {
          if (this.activeDeal && this.marketingListDeal && this.deal.dealTeam) {
            const marketingPage =
              this.deal.state === "draft" ? "overview" : "marketing-list";
            const marketingPath = `/deal_offerings/${this.deal.urlHandle || this.deal.token}/${marketingPage}`;

            window.open(marketingPath, "_blank");
          } else if (this.liveDeal && this.marketingListDeal && this.deal.marketingReadAuthorized) {
            const marketingOverviewPath = `/deal_offerings/${this.deal.urlHandle || this.deal.token}/overview`;

            window.open(marketingOverviewPath, "_blank");
          } else {
            this.$store.commit("closeSlideover");
            this.$store.commit("openModal", {
              component: DealCard,
              props: {
                dealObject: this.deal,
                property: this.property
              }
            });
          }
        } else {
          if (this.liveDeal && this.marketingListDeal && this.deal.marketingReadAuthorized) {
            const marketingOverviewPath = `/deal_offerings/${this.deal.urlHandle || this.deal.token}/overview`;

            window.open(marketingOverviewPath, "_blank");
          } else {
            this.$router.push({
              path: "/sign-in",
              query: { nextUrl: `/open_deal/${this.deal.token}` }
            });
          }
        }
      } else {
        this.$store.dispatch("flash", "Access denied");
      }
    }
  }
};
</script>
