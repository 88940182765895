<template>
  <div class="my-4 bg-white shadow overflow-hidden rounded-md">
    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <div
        class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
      >
        <div class="ml-4 mt-2">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <button @click="toggleCriteria" type="button" v-tooltip="'Show/hide market criteria'" class="text-gray-400 hover:text-gray-900 focus:outline-none focus:text-gray-900">
                <span class="h-7 flex items-center">
                  <svg class="h-6 w-6 transform" :class="{ '-rotate-180': showCriteria }" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                  </svg>
                </span>
              </button>
            </div>
            <div class="ml-4">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ heading }}
              </h3>
              <p v-if="description" class="mt-1 text-sm leading-5 text-gray-500">
                {{ description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul v-if="showCriteria">
      <search-result-contact-row
        v-for="(contact, index) in filteredContacts"
        :key="contact.id"
        :contact="contact"
        :class="{ 'border-t': index !== 0, 'border-gray-200': index !== 0 }"
      />
    </ul>
  </div>
</template>

<script>
import SearchResultContactRow from "./search-result-contact-row";

export default {
  components: { SearchResultContactRow },
  props: ["contacts", "heading", "description", "hide"],
  data() {
    return {
      showCriteria: true
    };
  },
  computed: {
    nameFilter() {
      return this.$store.state.nameFilter;
    },
    hasContacts() {
      return this.contacts && this.contacts.length > 0;
    },
    filteredContacts() {
      if (this.hasContacts) {
        if (this.nameFilter) {
          return this.contacts.filter(c => {
            return this.$store.getters.contactNameFilter(c);
          });
        } else {
          return this.contacts;
        }
      } else {
        return [];
      }
    }
  },
  watch: {
    nameFilter: {
      handler() {
        if (this.nameFilter) {
          this.showCriteria = true;
        }
      }
    }
  },
  mounted() {
    if (this.hide) {
      this.showCriteria = false;
    }
  },
  methods: {
    toggleCriteria() {
      this.showCriteria = !this.showCriteria;
    }
  }
};
</script>
