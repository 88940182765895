<template>
  <div class="mt-4 flex-shrink-0 flex items-center md:mt-0 md:ml-4">
    <label v-if="searching" for="search" class="sr-only">Search</label>

    <div v-if="searching" class="flex rounded-md shadow-sm">
      <div class="relative flex-grow focus-within:z-10">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <!-- Heroicon name: search -->
          <svg class="h-5 w-5 text-gray-400" style="z-index: 1;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
          </svg>
        </div>
        <autocomplete
          id="search"
          ref="linkSearch"
          type="text"
          base-class="custom-autocomplete-leading-icon"
          :search="searchLinks"
          :auto-select="true"
          :get-result-value="getLinkResultValue"
          :debounce-time="500"
          @submit="handleLinkSubmit"
          placeholder="Name, address, etc."
        />
      </div>
      <button @click="searching = false" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
        Cancel
      </button>
    </div>

    <template v-else>
      <span v-if="intent && intent.state === 'content_prep'" class="shadow-sm rounded-md">
        <button @click="sellerReview" type="button" v-tooltip="'Notify seller that content is ready for review.'" class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:text-gray-800 active:bg-gray-50">
          Submit
        </button>
      </span>
      <span v-else-if="intent && intent.launchRequested" class="ml-6 inline-flex font-bold text-green-400 text-base leading-5">
        Launch requested
      </span>
      <span v-else-if="intent && intent.state === 'seller_review'" class="ml-6 inline-flex font-bold text-green-400 text-base leading-5">
        {{ intent.state | capitalize }}
      </span>
      <div ref="createContentMenu" class="relative inline-block text-left">
        <div>
          <span class="ml-3 shadow-sm rounded-md">
            <button @click="toggleMenu" v-tooltip="'Create premium content'" type="button" id="create-menu" aria-haspopup="true" aria-expanded="true" class="inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
              <!-- Heroicon name: mail -->
              <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
              </svg>
              <template v-if="intent">Premium...</template>
              <template v-else>Showcase</template>
              <!-- Heroicon name: chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </span>
        </div>

        <transition
          enter-active-class="duration-100 ease-out"
          enter-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="duration-75 ease-in"
          leave-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
        >
          <div v-show="menuOpen" class="origin-top-right absolute left-0 sm:right-0 sm:left-auto mt-2 w-56 rounded-md shadow-lg" style="z-index: 2;">
            <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a v-if="content && contentType !== 'BountyBid'" @click.prevent="attachToContent" href="" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                  <!-- Heroicon name: link -->
                  <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
                  </svg>
                  to {{ content.name | truncate(20) }}
                </a>
                <a href="" @click.prevent="dealSearch" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                  <!-- Heroicon name: link -->
                  <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
                  </svg>
                  to Deal
                </a>
                <a href="" @click.prevent="intelSearch" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                  <!-- Heroicon name: link -->
                  <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
                  </svg>
                  to Intel
                </a>
                <a href="" @click.prevent="linkCustom" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                  <!-- Heroicon name: adjustments -->
                  <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
                  </svg>
                  Custom Filters
                </a>
                <a v-if="intent" href="" @click.prevent="dataSet" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                  <!-- Heroicon name: collection -->
                  <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z" />
                  </svg>
                  Data Set
                </a>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
import * as moment from "moment";
import api from "../../../api";

export default {
  props: ["intent", "content", "contentType"],
  data() {
    return {
      menuOpen: false,
      searchCategory: "deal",
      searching: false
    };
  },
  computed: {
    endpoint() {
      switch (this.searchCategory) {
        case "deal":
          return "search_deals";
        case "intel":
          return "search_intel?strict=true";
        case "premium":
          return "search_purchaseables";
        default:
          return null;
      }
    }
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.createContentMenu &&
        !this.$refs.createContentMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    sellerReview() {
      api
        .post(`purchaseable_intents/${this.intent.id}/seller_review`)
        .then(json => {
          this.intent.state = "seller_review";
          this.$store.dispatch(
            "flash",
            "Seller notified that content is ready for review."
          );
        });
    },
    attachToContent() {
      this.$emit("attach");
      this.menuOpen = false;
    },
    dealSearch() {
      this.searchCategory = "deal";
      this.searching = true;
      setTimeout(() => {
        document.getElementById("search").focus();
      }, 50);
      this.menuOpen = false;
    },
    intelSearch() {
      this.searchCategory = "intel";
      this.searching = true;
      setTimeout(() => {
        document.getElementById("search").focus();
      }, 50);
      this.menuOpen = false;
    },
    linkCustom() {
      this.$emit("link-custom");
      this.menuOpen = false;
    },
    dataSet() {
      this.$emit("data-set");
      this.menuOpen = false;
    },
    searchLinks(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        const query = input === "" || input === null ? null : _.toLower(input);

        if (this.endpoint) {
          api.post(this.endpoint, { name: query }).then(
            json => {
              resolve(json.data);
            },
            failure => {
              this.$store.dispatch("flash", "Invalid search");
            }
          );
        }
      });
    },
    getLinkResultValue(result) {
      switch (this.searchCategory) {
        case "deal":
          return this.dealSearchResult(result);
        case "intel":
          return this.intelSearchResult(result);
        default:
          return `${result.name}`;
      }
    },
    dealSearchResult(deal) {
      const dealType = this.$options.filters.dealTypeAlias(
        deal.type,
        deal.jointVentureRoles
      );
      const location = this.$options.filters.summarizedLocation(
        deal.properties
      );
      const state = deal.closeDate
        ? moment(deal.closeDate).format("MMM YYYY")
        : this.$options.filters.capitalize(deal.state);

      return `${deal.name}\n${location} (${deal.useTypes})\n${dealType}\n${state}`;
    },
    intelSearchResult(intel) {
      const postType = this.$options.filters.postTypeAlias(intel.type);
      const createdAt = moment(intel.createdAt).format("MMM YYYY");
      const notes = this.$options.filters.truncate(intel.notes, 40);

      return `${intel.name}\n${postType}\n${createdAt}\n${notes}`;
    },
    handleLinkSubmit(result) {
      if (result) {
        this.$emit("link-existing", {
          contentToken: result.token,
          contentType: result.newsfeedType
        });
        this.$refs.linkSearch.value = "";
        this.searching = false;
      } else {
        this.$store.dispatch(
          "flash",
          "No matching content in Tower Hunt. Why don't you create it?"
        );
      }
    }
  }
};
</script>
