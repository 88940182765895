<template>
  <div ref="fileStorageHostMenu" @keydown.escape="menuOpen = false" class="relative inline-block text-left" style="z-index: 9999;">
    <div>
      <button @click="toggleMenu" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
        <!-- Heroicon name: solid/plus -->
        <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
        </svg>
        <span>Add files...</span>
        <!-- Heroicon name: solid/chevron-down -->
        <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>
    <transition
      enter-active-class="duration-150 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-show="menuOpen" class="origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
        <div class="py-1" role="none">
          <a @click.prevent="dropboxChooser" href="" class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">
            Choose from Dropbox
          </a>
          <a v-if="googleAuthLoaded" @click.prevent="googleDriveChooser" href="" class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">
            Choose from Google Drive
          </a>
          <a @click.prevent="oneDriveSharePointChooser" href="" class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">
            Choose from OneDrive and SharePoint
          </a>
          <a @click.prevent="localUpload" href="" class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
            Upload from your computer
          </a>
          <a @click.prevent="pasteUrl" href="" class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
            Paste a link
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["googleAuthLoaded"],
  data() {
    return {
      menuOpen: false
    };
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.fileStorageHostMenu &&
        !this.$refs.fileStorageHostMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    pasteUrl() {
      this.$emit("paste-url");
      this.menuOpen = false;
    },
    localUpload() {
      this.$emit("local-upload");
      this.menuOpen = false;
    },
    dropboxChooser() {
      this.$emit("dropbox-chooser");
      this.menuOpen = false;
    },
    googleDriveChooser() {
      this.$emit("google-drive-chooser");
      this.menuOpen = false;
    },
    oneDriveSharePointChooser() {
      this.$emit("one-drive-share-point-chooser");
      this.menuOpen = false;
    }
  }
};
</script>
