<template>
  <fieldset>
    <div class="flex justify-between items-center">
      <legend class="text-base font-medium text-gray-900">Date</legend>
      <span class="relative z-0 inline-flex shadow-sm rounded-md">
        <button @click="subtract(1, 'month')" type="button" class="relative inline-flex items-center p-1.5 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
          1m
        </button>
        <button @click="subtract(1, 'quarter')" type="button" class="-ml-px relative inline-flex items-center p-1.5 border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
          1q
        </button>
        <button @click="subtract(1, 'year')" type="button" class="-ml-px relative inline-flex items-center p-1.5 border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
          1y
        </button>
        <button @click="clearDates" type="button" class="-ml-px relative inline-flex items-center p-1.5 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
          <span>Clear</span>
        </button>
      </span>
    </div>
    <div class="mt-2 flex flex-col items-center space-y-2">
      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="rounded-md bg-gray-100 p-2 w-full">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: solid/check-circle -->
            <svg class="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm font-medium text-gray-800">
              <template v-if="value.allDates">All dates</template>
              <template v-else-if="noEnd">{{ value.start | moment("MMM D, YYYY") }} and beyond</template>
              <template v-else>{{ value.start | moment("MMM D, YYYY") }} - {{ value.end | moment("MMM D, YYYY") }}</template>
            </p>
          </div>
        </div>
      </div>

      <v-date-picker mode="range" :value="value" @input="updateDate" color="gray" is-inline />
    </div>
  </fieldset>
</template>

<script>
import * as moment from "moment";

export default {
  props: ["value"],
  computed: {
    noEnd() {
      return moment().isSame(this.value.end, "day");
    }
  },
  mounted() {
    if (this.value.start === this.value.end) {
      this.updateDate({
        allDates: true,
        start: this.value.start,
        end: this.value.end
      });
    }
  },
  methods: {
    clearDates() {
      this.$emit("clear");
    },
    updateDate({ allDates = false, start, end }) {
      this.$emit("input", { allDates, start, end });
    },
    subtract(quantity, period) {
      const start = moment()
        .subtract(quantity, period)
        .startOf("month")
        .toDate();

      this.updateDate({ start, end: new Date() });
    }
  }
};
</script>
