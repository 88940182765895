<template>
  <div class="wrapper">
    <heading>
      <template v-if="namingRegion">Let's name this market</template>
      <template v-else-if="editing">Resize the shaded area now</template>
      <template v-else-if="drawing">Let's draw something new</template>
      <template v-else-if="hasNewUserRegion">How does the shaded area look?</template>
      <template v-else>
        <template v-if="!onboarding && (newRegion || regionId)">Where is the region?</template>
        <template v-else>Where in the world do you focus?</template>
      </template>
    </heading>
    <region-map v-if="hasLocation || hasNewUserRegion" :map-data="mapData" :class="{ 'h-96': onboarding || creatingTarget, 'h-64': !onboarding && !creatingTarget }" class="mb-8 w-11/12 sm:max-w-xl sm:w-full" />
    <form @submit.prevent autocomplete="off" novalidate>
      <div v-if="!hasLocation && !hasNewUserRegion" class="input-container">
        <gmap-autocomplete
          v-focus
          :value="mapData.location"
          @place_changed="setAddress"
          @focus="focus"
          @blur="blur"
          :options="{fields: ['geometry', 'formatted_address', 'address_components', 'name']}"
          autocomplete="new-password"
          autocorrect="off"
          spellcheck="false"
          placeholder=""
          tabindex="1"
          name="user_location"
          id="gmap-autocomplete"
          class="input-text black"
          :class="{ active: focused }" >
          <template v-slot:input="slotProps">
            <input ref="input" type="text"
              v-on:listeners="slotProps.listeners"
              v-on:attrs="slotProps.attrs" />
          </template>
        </gmap-autocomplete>
        <label class="input-label" :class="{ active: focused }">Location, City, State</label>
        <label class="email-icon" :class="{ active: focused }">
          <i class="fas fa-map-marker-alt"></i>
        </label>
      </div>

      <option-description v-if="mapHelp" :description="mapHelp" style="margin-bottom: 20px;" />
      <option-description v-else-if="onboarding && !hasLocation && !hasNewUserRegion" description="Start with one specific area (e.g., Boston). You can (and should!) add others later." style="width: 330px; margin-bottom: 20px;" />

      <region-name-field v-if="namingRegion" :visible="namingRegion" @submit="next" />

      <template v-if="hasLocation && !namingRegion">
        <template v-if="editing">
          <button @click="finishEditing" type="button" class="cta complete">Looks good</button>
        </template>
        <template v-else>
          <button v-if="hasNewUserRegion && !editing" @click="nameRegion" type="button" class="cta complete" tabindex="999">Looks good</button>
          <button v-if="hasNewUserRegion" @click="editShapes" type="button" class="secondary">Edit this shape</button>
          <button v-if="hasNewUserRegion" @click="clearShapes" type="button" class="secondary">Replace with new shape</button>
          <button @click="clearLocation" type="button" class="secondary">Look up new location</button>
        </template>
      </template>
      <button v-else-if="namingRegion && nextIsSave" @click.stop="next" type="button" class="cta single" :class="{ complete: complete }" tabindex="999">Save</button>
      <button v-else-if="namingRegion" @click.stop="next" type="button" class="cta single" :class="{ complete: complete }" tabindex="999">Next</button>
      <button v-if="namingRegion" @click="stopNaming" type="button" class="secondary">Edit shaded area</button>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Avatar from "../../components/onboarding/avatar";
import Heading from "../../components/onboarding/heading";
import OptionDescription from "../../components/onboarding/option-description";
import RegionMap from "../../components/onboarding/map";
import RegionNameField from "../../components/onboarding/region-name-field";

export default {
  components: { Avatar, Heading, RegionMap, OptionDescription, RegionNameField },
  props: ["regionId"],
  data() {
    return {
      focused: false
    };
  },
  computed: {
    ...mapState([
      "activeTarget",
      "activeRegion",
      "onboardingInProcess",
      "targetCreationInProcess"
    ]),
    ...mapGetters([
      "hasNewUserRegion",
      "activeRegionShape",
      "onboarding",
      "creatingTarget",
      "signedIn"
    ]),
    complete() {
      return this.activeRegion.name !== "";
    },
    nextIsSave() {
      return !this.onboarding;
    },
    newRegion() {
      return (
        !this.onboarding && !this.regionId && !_.get(this.activeRegion, "id")
      );
    },
    targetId() {
      return _.get(this.$store.state, "activeTarget.id", null);
    },
    mapData() {
      return this.activeRegion.mapData;
    },
    drawing() {
      return this.hasLocation && !this.hasNewUserRegion && !this.editing;
    },
    editing() {
      return this.activeRegion.editingShape || this.activeRegion.movingShape;
    },
    namingRegion() {
      return this.activeRegion.namingShape;
    },
    mapHelp() {
      if (this.drawing) {
        switch (this.activeRegion.drawingShape) {
          case "Circle":
            return `${_.capitalize(this.interaction)} the map to place the center of the circle.`
          case "Polygon":
            return `${_.capitalize(this.interaction)} the map to place the first point of the polygon.`
          case "Rectangle":
            return `${_.capitalize(this.interaction)} the map to place the first corner.`
          case "CenterPlaced":
            return `${_.capitalize(this.interaction)} to finish.`
          case "PolygonCompletable":
            return `${_.capitalize(this.interaction)} first marker to finish.`
          case "VertexAddedPolygon":
            return `${_.capitalize(this.interaction)} to continue drawing.`
          default:
            return "Choose a new shape on the left of the map."
        }
      } else if (this.editing) {
        return `${_.capitalize(this.interaction)} points to move them.`
      } else if (this.hasNewUserRegion && !this.namingRegion) {
        return "Don't be afraid to resize or change shape. You can use a polygon, rectangle, or circle.";
      } else {
        return null;
      }
    },
    hasLocation() {
      return this.mapData.lat && this.mapData.lng;
    },
    isDesktop() {
      return this.$store.getters.isDesktop;
    },
    interaction() {
      if ((this.drawing || this.namingRegion) && this.isDesktop) {
        return "click";
      } else if (this.drawing || this.namingRegion) {
        return "tap";
      } else {
        return "drag";
      }
    },
    heightStyle() {
      return { height: this.isDesktop ? "500px" : "330px" };
    },
    widthStyle() {
      return { width: this.isDesktop ? "760px" : "90%" };
    },
    maxWidthStyle() {
      return { "max-width": this.isDesktop ? "760px" : "90%" };
    },
    minMaxWidthStyle() {
      if (this.isDesktop) {
        return {};
      } else {
        return {
          "max-width": "760px",
          "min-width": "330px",
        };
      }
    },
    mapStyle() {
      return _.assign({ "margin-bottom": "20px" }, this.heightStyle, this.widthStyle, this.minMaxWidthStyle);
    }
  },
  mounted() {
    if (this.onboarding && !this.onboardingInProcess) {
      this.$router.push({ path: "/start/1" });
    } else if (!this.targetId && this.creatingTarget && !this.targetCreationInProcess) {
      this.$router.push({ path: "/targets/new/1" });
    }

    if (this.signedIn && !this.activeRegion.id && this.regionId) {
      this.fetchRegion();
    }

    if (this.onboarding && _.get(this.activeTarget, "regions", []).length > 0) {
      const region = _.head(this.activeTarget.regions);

      this.$store.commit("mountRegion", { region });
      this.$store.dispatch("removeRegionWithGetters", region);
    }
  },
  destroyed() {
    if (!this.onboarding) {
      this.$store.commit("clearRegion");
    }
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    nameRegion() {
      if (!this.complete) {
        this.$store.commit("setActiveRegionName", this.activeRegion.mapData.addressData.formatted_address);
      }
      this.$store.commit("setActiveRegionNamingShape", true);
    },
    next() {
      if (this.newRegion) {
        this.$store.dispatch("createRegion", { announce: true });
        this.$store.commit("closeModal");
      } else if (this.regionId) {
        this.$store.dispatch("updateRegion", { announce: false, regionId: this.regionId});
        this.$store.commit("closeModal");
      } else if (this.complete) {
        if (this.onboarding) {
          this.$router.push({ path: "/start/12" });
        } else if (this.creatingTarget) {
          this.$store.dispatch("createOnboardingRegion");
          this.$store.commit("closeModal");
        }
      }

      this.$store.commit("setActiveRegionNamingShape", false);
    },
    setAddress(addressData) {
      if (addressData) {
        const mapData = {
          addressData: addressData,
          lat: addressData.geometry.location.lat(),
          lng: addressData.geometry.location.lng(),
          location: addressData.formatted_address
        }

        this.$store.commit("setActiveRegionMapData", mapData)
      }
    },
    clearLocation() {
      this.$store.commit("clearActiveRegionId");
      this.$store.commit("clearActiveRegionName");
      this.$store.commit("clearActiveRegionMap");
      this.$store.commit("clearActiveRegionMapData");
      this.$store.dispatch("clearActiveRegionShapes");
    },
    clearShapes() {
      this.$store.dispatch("clearActiveRegionShapes");
    },
    editShapes() {
      this.activeRegion.map.pm.enableGlobalEditMode();
    },
    finishEditing() {
      if (this.activeRegion.editingShape) {
        this.activeRegion.map.pm.disableGlobalEditMode();
      } else if (this.activeRegion.movingShape) {
        this.activeRegion.map.pm.toggleGlobalDragMode();
      }
    },
    stopNaming() {
      this.$store.commit("setActiveRegionNamingShape", false);
    },
    fetchRegion() {
      this.$store.dispatch("loadRegion", this.regionId);
    }
  },
  watch: {
    hasLocation: {
      handler() {
        if (!this.hasLocation && document.getElementById("gmap-autocomplete")) {
          document.getElementById("gmap-autocomplete").focus();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
input {
  vertical-align: middle;
}
.subhead {
  padding: 0 20px;
}
.input-container {
  position: relative;
  margin-bottom: 20px;
}
.location-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 26px 38px 8px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.active {
    border: 2px solid #5850ec;
  }
}
.input-label {
  position: absolute;
  top: 8px;
  left: 38px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
.email-icon {
  position: absolute;
  top: 19px;
  left: 13px;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
.clear-action {
  position: absolute;
  top: 19px;
  right: 13px;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  pointer-events: all;
  cursor: pointer;
  color: #b7b7b7;
}
button {
  width: 330px;
  margin-bottom: 5px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
