<template>
  <div class="wrapper">
    <heading>What deal types does the {{ label }} focus on?</heading>
    <button @click="clickHandler" type="button" class="cta mb-2" :class="privacyMode ? 'privacy-complete' : 'complete'" tabindex="999">
      <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
        <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
      </svg>
      <template v-if="hasTypes && postToken">Confirm privacy and edit</template>
      <template v-else-if="hasTypes">Next</template>
      <template v-else>All of them</template>
    </button>
    <div class="content-wrap mb-2">
      <section class="vertical">
        <option-select
          v-for="option in matchingOptions"
          :key="option.name"
          @hover="hover(option.value)"
          @vacate="vacate"
          option-type="intelDealType"
          setter-name="addDealTypeWithGetters"
          forgetter-name="removeDealTypeWithGetters"
          setter-type="dispatch"
          :store-record="intelDealTypes"
          :option-record="option"
          layout="vertical"
          :options-count="matchingOptions.length" />
      </section>
      <option-description v-if="hovering && description" :description="description" class="mb-4" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../../components/onboarding/heading";
import OptionDescription from "../../../components/onboarding/option-description";
import OptionSelect from "../../../components/onboarding/option-select";
import Swal from "sweetalert2";
import anonymousPostUpdateOptions from "../../../sweet-alert-always-anonymous-update-options";
import dealTypes from "../../../industry-role-deal-types";

export default {
  components: { Heading, OptionSelect, OptionDescription },
  props: ["postToken"],
  data() {
    return {
      options: dealTypes,
      hovering: null
    };
  },
  computed: {
    ...mapState(["activeIntel", "currentUser", "intelCreationInProcess"]),
    ...mapGetters(["creatingIntel", "showDealSize", "privacyMode"]),
    label() {
      switch (this.activeIntel.type) {
        case "jobPosting":
          return "job";
        case "industryEvent":
          return "event";
        case "marketReport":
          return "report";
        case "marketCommentary":
          return "post";
        default:
          return "post";
      }
    },
    industryRole() {
      return this.currentUser.industryRole;
    },
    matchingOptions() {
      return _.get(this.options, this.industryRole, []);
    },
    intelDealTypes() {
      return this.activeIntel.deals;
    },
    optionData() {
      return _.find(this.matchingOptions, { value: this.hovering });
    },
    description() {
      if (this.optionData && this.optionData.description) {
        return this.optionData.description;
      } else {
        return null;
      }
    },
    hasTypes() {
      return this.intelDealTypes.length > 0;
    }
  },
  mounted() {
    if (this.creatingIntel && !this.intelCreationInProcess) {
      this.$router.push({ path: "/intel/new/1" });
    }
  },
  methods: {
    clickHandler() {
      if (this.postToken) {
        this.confirmPrivacy();
      } else {
        this.next();
      }
    },
    confirmPrivacy() {
      if (this.postToken) {
        Swal.fire(anonymousPostUpdateOptions).then(result => {
          if (result.value) {
            this.$store.dispatch("updateIntel", {
              postToken: this.postToken,
              updateTags: true
            });

            this.$store.commit("closeModal");
            this.$store.commit("clearIntel");
          }
        });
      }
    },
    next() {
      switch (this.activeIntel.type) {
        case "jobPosting":
        case "industryEvent":
        case "marketCommentary":
        case "marketReport":
          if (this.showDealSize) {
            this.$router.push({ path: "/intel/new/8" });
          } else {
            this.$router.push({ path: "/intel/new/9" });
          }
          break;
      }
    },
    hover(option) {
      this.hovering = option;
    },
    vacate() {
      this.hovering = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.content-wrap {
  display: flex;
  flex-direction: column;
}
section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  pointer-events: none;
  &.horizontal {
    flex-wrap: wrap;
  }
  &.vertical {
    flex-direction: column;
    align-items: center;
  }
}
button.cta {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.privacy-complete {
    background: #d61f69;
    box-shadow: rgba(214, 31, 105, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #f17eb8;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
