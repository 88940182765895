<template>
  <footer :class="`max-w-screen-xl mx-auto overflow-hidden ${py} ${px}`">
    <nav :class="`-mx-5 -my-2 flex flex-wrap justify-center space-x-5 ${textSize}`">
      <div class="py-2">
        <a href="https://towerhunt.com/contacts/NfxwbSKdNwLnKJUoZiRJSeK3" target="_blank" class="text-gray-500 hover:text-gray-900">
          About us
        </a>
      </div>
      <div class="py-2">
        <router-link to="/legal" class="text-gray-500 hover:text-gray-900">
          Privacy & Terms
        </router-link>
      </div>
      <a href="mailto:team@towerhunt.com" target="_blank" rel="noopener noreferrer" class="py-2 flex items-center text-gray-500 hover:text-gray-900 focus:outline-none focus:underline">
        <span>Contact us</span>
        <svg class="ml-1 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
        </svg>
      </a>
    </nav>
    <div class="mt-8 flex justify-center">
      <a href="https://www.linkedin.com/company/towerhunt" target="_blank" class="text-gray-400 hover:text-gray-500">
        <span class="sr-only">LinkedIn</span>
        <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 485 485">
          <path fill-rule="evenodd" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" clip-rule="evenodd"/>
        </svg>
      </a>
    </div>
    <div class="mt-8">
      <p :class="`text-center ${textSize} text-gray-400`">
        &copy; 2021 Pinevale, Inc. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  props: ["paddingY", "paddingX", "compact"],
  computed: {
    py() {
      return this.paddingY || "py-12";
    },
    px() {
      return this.paddingX || "px-4 sm:px-6 lg:px-8";
    },
    textSize() {
      return this.compact ? "text-sm leading-4" : "text-base leading-6";
    }
  }
};
</script>
