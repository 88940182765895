<template>
  <div class="flex items-start">
    <div class="absolute flex items-center h-5">
      <input
        v-model="jobDigestsConsent"
        @change="saveNotification"
        id="job-digests"
        type="checkbox"
        class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600"
      />
    </div>
    <div class="pl-7 text-sm leading-5">
      <label for="digests" class="font-medium text-gray-700">
        Daily Jobs Digest
      </label>
      <p class="text-gray-500">
        Personalized jobs bundled into a single email.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jobDigestsConsent: true
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },
  watch: {
    currentUser: {
      handler(user, oldUser) {
        if (user.signedIn && !oldUser.signedIn) {
          this.mountNotification();
        }
      }
    }
  },
  mounted() {
    if (this.currentUser.signedIn) {
      this.mountNotification();
    }
  },
  methods: {
    mountNotification() {
      const { jobDigestsConsent } = this.currentUser;

      this.jobDigestsConsent = jobDigestsConsent;
    },
    saveNotification() {
      const { jobDigestsConsent } = this;
      const newInfo = { jobDigestsConsent };

      this.$store.dispatch("updateMyNotifications", newInfo);
    }
  }
};
</script>
