<template>
  <h3 :class="`${margin} mx-4 text-center text-xl sm:text-3xl leading-9 font-bold text-gray-900`">
    <slot />
  </h3>
</template>

<script>
export default {
  props: ["marginOverride"],
  computed: {
    margin() {
      return this.marginOverride || "my-6";
    }
  }
};
</script>
