/* global analytics */
import Vue from "vue";
import VueRouter from "vue-router";

import AcceptDealInvitation from "views/accept-deal-invitation.vue";
import AcceptPropertyFollowingInvitation from "views/accept-property-following-invitation.vue";
import AcceptTeamInvitation from "views/accept-team-invitation.vue";
import AcceptUserInvitation from "views/accept-user-invitation.vue";
import Admin from "views/admin.vue";
import Backchannel from "views/backchannel.vue";
import BackchannelAudience from "views/onboarding/deal/add-deal-participant.vue";
import BountyBidMarketing from "views/bounty-bid-marketing.vue";
import BountyDetail from "views/bounty-detail.vue";
import BountyMarketing from "views/bounty-marketing.vue";
import CloneMarketRegistration from "views/clone-market-registration.vue";
import ContentSafezone from "components/cards/details/content-safezone.vue";
import CreateBackchannel from "views/create-backchannel.vue";
import DealInputCloseDate from "views/onboarding/deal/deal-close-date.vue";
import DealInputContainer from "views/onboarding/deal/container.vue";
import DealInputDealName from "views/onboarding/deal/deal-name.vue";
import DealInputDealRole from "views/onboarding/deal/deal-role.vue";
import DealInputDealType from "views/onboarding/deal/deal-type.vue";
import DealInputJVRole from "views/onboarding/deal/deal-jv-role.vue";
import DealInputLeaseSize from "views/onboarding/deal/lease-size.vue";
import DealInputLeverage from "views/onboarding/deal/leverage.vue";
import DealInputLoanSeniority from "views/onboarding/deal/loan-seniority.vue";
import DealInputProperties from "views/onboarding/deal/properties.vue";
import DealInputRiskType from "views/onboarding/deal/risk-type.vue";
import DealInputSeekingJVRole from "views/onboarding/deal/deal-seeking-jv-role.vue";
import DealInputTotalValue from "views/onboarding/deal/deal-size.vue";
import DealInvitationRequestHandler from "views/deal-invitation-request-handler.vue";
import DealMergeForm from "views/record-merging/deal-merge-form.vue";
import DealProspectInvitationHandler from "views/deal-prospect-invitation-handler.vue";
import DuplicateDealCheck from "views/onboarding/deal/duplicate-deal-check.vue";
import FeaturedPurchaseables from "components/purchaseables/featured";
import ForgotPassword from "views/forgot-password.vue";
import GaugeMarketInterest from "components/cards/gauge/market-interest.vue";
import GaugeMarketing from "views/gauge-marketing.vue";
import GaugeMessages from "components/cards/gauge/messages.vue";
import HelpRequests from "views/help-requests.vue";
import HelpRequestsActive from "views/help-requests-active.vue";
import HelpRequestsCompleted from "views/help-requests-completed.vue";
import HelpRequestsMine from "views/help-requests-mine.vue";
import HomeContainer from "views/home-container.vue";
import IntelDealType from "views/onboarding/market-intel/intel-deal-type.vue";
import IntelEventDates from "views/onboarding/market-intel/intel-event-dates.vue";
import IntelLeaseSize from "views/onboarding/market-intel/intel-lease-size.vue";
import IntelLocation from "views/onboarding/market-intel/intel-location.vue";
import IntelName from "views/onboarding/market-intel/intel-name.vue";
import IntelNotes from "views/onboarding/market-intel/intel-notes.vue";
import IntelPrivacy from "views/onboarding/privacy-select.vue";
import IntelProperties from "views/onboarding/market-intel/intel-properties.vue";
import IntelRegions from "views/onboarding/market-intel/intel-regions.vue";
import IntelRiskType from "views/onboarding/market-intel/intel-risk-type.vue";
import IntelType from "views/onboarding/market-intel/intel-type.vue";
import IntelUseType from "views/onboarding/market-intel/intel-use-type.vue";
import Landing from "views/landing.vue";
import Legal from "views/legal.vue";
import Main from "views/main.vue";
import MarketDeals from "components/market-detail/deals.vue";
import MarketIntel from "components/market-detail/intel.vue";
import MarketIntelInputContainer from "views/onboarding/market-intel/container.vue";
import MarketPeople from "components/market-detail/people.vue";
import MarketReports from "components/market-detail/reports.vue";
import MyDashboard from "views/home/my-dashboard.vue";
import MyPropertyWatchlist from "views/home/my-property-watchlist.vue";
import MyPurchaseablesDashboard from "components/purchaseables/dashboard/dashboard";
import MySafezone from "views/home/my-safezone.vue";
import MyTeams from "views/home/my-teams.vue";
import Network from "views/network.vue";
import NetworkConnections from "views/network-connections.vue";
import NetworkSuggestions from "views/network-suggestions.vue";
import Newsfeed from "views/newsfeed.vue";
import OffMarket from "views/off-market.vue";
import OffMarketCardGauge from "views/gauge.vue";
import OffMarketCardManager from "views/off-market-card-manager.vue";
import OffMarketCardMarketing from "views/off-market-card-marketing.vue";
import OffMarketPacks from "views/off-market-packs.vue";
import OffMarketWatchlist from "views/off-market-watchlist.vue";
import OfferedDealContainer from "views/deal-offering-container.vue";
import OfferedDealDocuments from "views/offered-deal/documents.vue";
import OfferedDealMarketingList from "views/offered-deal/marketing-list.vue";
import OfferedDealOverview from "views/offered-deal/overview.vue";
import OfferedDealPhotography from "views/offered-deal/photography.vue";
import OnboardConnectedAccount from "views/onboard-connected-account-handler";
import OnboardSharedPayoutAccount from "views/onboard-shared-payout-account-handler";
import Onboarding from "views/onboarding/onboarding.vue";
import OnboardingAccount from "views/onboarding/onboarding-account.vue";
import OnboardingDealSize from "views/onboarding/onboarding-deal-size.vue";
import OnboardingDealType from "views/onboarding/onboarding-deal-type.vue";
import OnboardingIndustryRole from "views/onboarding/onboarding-industry-role.vue";
import OnboardingJVRole from "views/onboarding/onboarding-jv-role.vue";
import OnboardingLeaseSize from "views/onboarding/onboarding-lease-size.vue";
import OnboardingLeverageSize from "views/onboarding/onboarding-leverage-size.vue";
import OnboardingLoanSeniority from "views/onboarding/onboarding-loan-seniority.vue";
import OnboardingName from "views/onboarding/onboarding-name.vue";
import OnboardingRegion from "views/onboarding/onboarding-region.vue";
import OnboardingRegions from "views/onboarding/onboarding-regions.vue";
import OnboardingRiskType from "views/onboarding/onboarding-risk-type.vue";
import OnboardingUseType from "views/onboarding/onboarding-use-type.vue";
import OpenPack from "views/open-card-pack.vue";
import Pipeline from "views/pipeline.vue";
import PremiumContentBuyerMarketing from "views/premium-content-buyer-marketing";
import PremiumContentMarketing from "views/premium-content-marketing";
import Pricing from "views/pricing";
import ProfileContainer from "views/profile-container";
import PropertyVitals from "components/property-watchlist/property-vitals.vue";
import PurchaseableIntentEditor from "components/purchaseables/dashboard/intent-editor.vue";
import PurchaseablesContainer from "views/purchaseables-container";
import Reputation from "views/reputation.vue";
import ResetPassword from "views/reset-password.vue";
import Settings from "views/settings.vue";
import SignIn from "views/sign-in.vue";
import StrategicMap from "components/maps/strategic-map.vue";
import TargetRead from "views/target-read.vue";
import TopPurchaseables from "components/purchaseables/top";
import WatchlistDiscussion from "components/property-watchlist/watchlist-discussion.vue";
import WatchlistNewsfeed from "components/property-watchlist/watchlist-newsfeed.vue";
import WatchlistNotepad from "components/property-watchlist/watchlist-notepad.vue";
import WatchlistPropertyDetail from "views/watchlist-property-detail.vue";
import WatchlistSettings from "components/property-watchlist/watchlist-settings.vue";
import api from "api";

Vue.use(VueRouter);

const routes = [
  {
    path: "/contacts/:contactToken",
    component: ProfileContainer,
    props: true
  },
  {
    path: "/market",
    component: PurchaseablesContainer,
    props: true,
    children: [
      { path: "featured", component: FeaturedPurchaseables },
      { path: "top", component: TopPurchaseables }
    ]
  },
  {
    path: "/deal_offerings/:offeredDealToken",
    component: OfferedDealContainer,
    props: true,
    children: [
      { path: "overview", component: OfferedDealOverview },
      { path: "photos", component: OfferedDealPhotography },
      { path: "documents", component: OfferedDealDocuments },
      {
        path: "marketing-list",
        component: OfferedDealMarketingList,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: "/",
    component: Main,
    children: [
      {
        path: "admin",
        component: Admin,
        meta: { requiresAuth: true, requiresAdmin: true }
      },
      {
        path: "",
        component: HomeContainer
      },
      {
        path: "home",
        component: HomeContainer,
        meta: { requiresAuth: true },
        children: [
          { path: "dashboard", component: MyDashboard },
          { path: "reputation", component: Reputation },
          { path: "safezone", component: MySafezone },
          { path: "watchlist", component: MyPropertyWatchlist },
          {
            path: "network",
            component: Network,
            children: [
              { path: "teams", component: MyTeams },
              { path: "suggestions", component: NetworkSuggestions },
              { path: "connections", component: NetworkConnections }
            ]
          },
          {
            path: "premium",
            component: MyPurchaseablesDashboard
          },
          {
            path: "intents/:intentId",
            component: PurchaseableIntentEditor,
            props: true
          },
          { path: "settings", component: Settings }
        ]
      },
      {
        path: "newsfeed",
        component: Newsfeed,
        meta: { requiresAuth: true }
      },
      {
        path: "map",
        component: StrategicMap,
        meta: { requiresAuth: true }
      },
      {
        path: "onboard-connected-account",
        component: OnboardConnectedAccount,
        meta: { requiresAuth: true }
      },
      {
        path: "onboard-shared-payout-account/:accountId",
        component: OnboardSharedPayoutAccount,
        props: true,
        meta: { requiresAuth: true }
      },
      {
        path: "property-watchlist/:propertyFollowingId",
        component: WatchlistPropertyDetail,
        meta: { requiresAuth: true },
        props: true,
        children: [
          { path: "newsfeed", component: WatchlistNewsfeed },
          { path: "discussion", component: WatchlistDiscussion },
          { path: "notepad", component: WatchlistNotepad },
          { path: "vitals", component: PropertyVitals },
          { path: "safezone", component: ContentSafezone },
          { path: "settings", component: WatchlistSettings }
        ]
      },
      {
        path: "bounty/:bountyId",
        component: BountyDetail,
        props: true
      },
      {
        path: "gauge/:postToken",
        component: OffMarketCardGauge,
        props: true,
        children: [
          { path: "market-interest", component: GaugeMarketInterest },
          { path: "messages", component: GaugeMessages }
        ]
      },
      {
        path: "bounties",
        component: HelpRequests,
        meta: { requiresAuth: true },
        children: [
          { path: "active", component: HelpRequestsActive },
          { path: "completed", component: HelpRequestsCompleted },
          { path: "my-engagements", component: HelpRequestsActive },
          { path: "my-bounties", component: HelpRequestsMine }
        ]
      },
      {
        path: "off-market",
        component: OffMarket,
        meta: { requiresAuth: true },
        children: [
          { path: "watchlist", component: OffMarketWatchlist },
          { path: "packs", component: OffMarketPacks },
          { path: "card-manager", component: OffMarketCardManager }
        ]
      },
      {
        path: "/backchannel/new",
        component: CreateBackchannel,
        meta: { requiresAuth: true }
      },
      {
        path: "/backchannel/:channelToken",
        component: Backchannel,
        meta: { requiresAuth: true },
        props: true
      },
      {
        path: "/open/:targetToken",
        component: TargetRead,
        meta: { requiresAuth: true },
        props: true,
        children: [
          { path: "deals", component: MarketDeals },
          {
            path: "intel",
            component: MarketIntel,
            props: { marketReportsOnly: false }
          },
          { path: "people", component: MarketPeople },
          { path: "reports", component: MarketReports },
          { path: "map", component: StrategicMap }
        ]
      },
      {
        path: "/open_deal/:dealToken",
        component: Newsfeed,
        meta: { requiresAuth: true },
        props: true
      },
      {
        path: "/open_intel/:intelToken",
        component: Newsfeed,
        meta: { requiresAuth: true },
        props: true
      },
      {
        path: "/deal_invitation_requests/:requestToken/accept",
        component: DealInvitationRequestHandler,
        meta: { requiresAuth: true },
        props: true
      },
      {
        path: "/deal_invitation_requests/:requestToken/decline",
        component: DealInvitationRequestHandler,
        meta: { requiresAuth: true },
        props: true
      },
      { path: "/group/:targetGroupToken", redirect: "/" }
    ]
  },
  { path: "/legal", component: Legal },
  { path: "/welcome", component: Landing },
  { path: "/pricing", component: Pricing },
  { path: "/product/sell-premium-content", component: PremiumContentMarketing },
  { path: "/product/premium-content", component: PremiumContentBuyerMarketing },
  { path: "/product/bounties", component: BountyMarketing },
  { path: "/product/bounty-bids", component: BountyBidMarketing },
  { path: "/product/gauge", component: GaugeMarketing },
  { path: "/product/off-market", component: OffMarketCardMarketing },
  {
    path: "/start",
    component: Onboarding,
    children: [
      { path: "1", component: OnboardingName },
      { path: "2", component: OnboardingIndustryRole },
      { path: "3", component: OnboardingDealType },
      { path: "4", component: OnboardingJVRole },
      { path: "5", component: OnboardingRiskType },
      { path: "6", component: OnboardingLoanSeniority },
      { path: "7", component: OnboardingUseType },
      { path: "8", component: OnboardingDealSize },
      { path: "9", component: OnboardingLeverageSize },
      { path: "10", component: OnboardingLeaseSize },
      { path: "11", component: OnboardingRegion },
      { path: "12", component: OnboardingAccount }
    ]
  },
  { path: "/sign-in", component: SignIn, meta: { guest: true } },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    meta: { guest: true }
  },
  { path: "/password-resets/:token", props: true, component: ResetPassword },
  {
    path: "/open_pack/:packId",
    component: OpenPack,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: "/targets/new",
    component: Onboarding,
    meta: { requiresAuth: true },
    children: [
      { path: "1", component: OnboardingDealType },
      { path: "2", component: OnboardingJVRole },
      { path: "3", component: OnboardingRiskType },
      { path: "4", component: OnboardingLoanSeniority },
      { path: "5", component: OnboardingUseType },
      { path: "6", component: OnboardingDealSize },
      { path: "7", component: OnboardingLeverageSize },
      { path: "8", component: OnboardingLeaseSize },
      { path: "9", component: OnboardingRegions },
      { path: "10", component: OnboardingRegion }
    ]
  },
  {
    path: "/merge/deals/:retireeToken/into/:survivorToken",
    component: DealMergeForm,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: "/deals/new",
    component: DealInputContainer,
    meta: { requiresAuth: true },
    children: [
      { path: "1", component: DealInputDealType },
      { path: "2", component: DealInputJVRole },
      { path: "3", component: DealInputDealRole },
      { path: "3a", component: DealInputSeekingJVRole },
      { path: "4", component: DealInputRiskType },
      { path: "5", component: DealInputLoanSeniority },
      { path: "6", component: DealInputTotalValue },
      { path: "7", component: DealInputLeverage },
      { path: "8", component: DealInputLeaseSize },
      { path: "9", component: DealInputProperties },
      { path: "10", component: DealInputDealName },
      { path: "11", component: DealInputCloseDate },
      { path: "dedupe", component: DuplicateDealCheck },
      { path: "12", component: BackchannelAudience }
    ]
  },
  {
    path: "/intel/new",
    component: MarketIntelInputContainer,
    meta: { requiresAuth: true },
    children: [
      { path: "privacy", component: IntelPrivacy },
      { path: "1", component: IntelType },
      { path: "2", component: IntelName },
      { path: "2a", component: BackchannelAudience },
      { path: "3", component: IntelLocation },
      { path: "4", component: IntelEventDates },
      { path: "5", component: IntelProperties },
      { path: "6", component: IntelRegions },
      { path: "7", component: IntelDealType },
      { path: "8", component: IntelRiskType },
      { path: "9", component: IntelUseType },
      { path: "10", component: IntelLeaseSize },
      { path: "11", component: IntelNotes }
    ]
  },
  {
    path: "/deal_invitations/:dealInvitationToken/accept",
    component: AcceptDealInvitation,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: "/deal_prospect_invitations/:invitationToken/decline",
    component: DealProspectInvitationHandler,
    props: true
  },
  {
    path: "/deal_prospect_invitations/:invitationToken/accept",
    component: DealProspectInvitationHandler,
    props: true
  },
  {
    path: "/user_invitations/:userInvitationToken/accept",
    component: AcceptUserInvitation,
    props: true
  },
  {
    path: "/team_invitations/:teamInvitationToken/accept",
    component: AcceptTeamInvitation,
    props: true
  },
  {
    path: "/property_following_invitations/:propertyFollowingInvitationToken/accept",
    component: AcceptPropertyFollowingInvitation,
    props: true
  },
  {
    path: "/clone_markets/:targetToken",
    component: CloneMarketRegistration,
    props: true
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (document.getElementById("hub-body-container")) {
      document.getElementById("hub-body-container").scrollTo(0, 0);
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") == null) {
      next({ path: "/sign-in", query: { nextUrl: to.fullPath } });
    } else {
      if (to.matched.some(record => record.meta.requiresAdmin)) {
        api.get("whoami").then(result => {
          if (result.data.admin || !!localStorage.getItem("adminToken")) {
            next();
          } else {
            next({ path: "/" });
          }
        });
      } else {
        if (analytics) {
          analytics.page({ path: to.path });
        }

        next();
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem("token") == null) {
      next();
    } else {
      next({ path: "/" });
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  const targetGroup = to.params.targetGroupToken;
  const dashboard = to.path === "/" || to.path === "/home" || to.path === "/home/dashboard" || to.path === "/home/safezone";
  const newsfeed = to.path === "/newsfeed" || to.path === "/";
  const map = to.path === "/map";
  const editingExistingTarget = to.params.targetId;
  const newTarget = to.path === "/targets/new/1";
  const newDeal = to.path === "/deals/new/1";
  const contactToken = to.params.contactToken;
  const targetToken = to.params.targetToken;
  const dealToken = to.params.dealToken;
  const offeredDealToken = to.params.offeredDealToken;
  const intelToken = to.params.intelToken;
  const needsStore =
    targetGroup ||
    dashboard ||
    newsfeed ||
    map ||
    contactToken ||
    targetToken ||
    dealToken ||
    offeredDealToken ||
    intelToken ||
    editingExistingTarget ||
    newTarget ||
    newDeal;

  if (needsStore) {
    const store = require("./store").default;

    if (targetGroup || contactToken) {
      store.commit("clearTarget");
      store.commit("clearIntel");
      store.commit("clearRegion");
      store.commit("clearDeal");
      store.commit("clearNotepadConversionComment");
      store.commit("clearDealMarketing");
      store.commit("clearProperty");
      store.commit("cancelDealParticipantInvitation");
      store.commit("cancelSharedGroup");
      store.commit("clearNameFilter");
    } else if (dashboard || newsfeed || map) {
      if (!store.getters.userIsActiveContact) {
        store.commit("clearActiveContact");
      }

      store.commit("concludeOnboarding");
      store.commit("concludeTargetCreation");
      store.commit("concludeDealCreation");
      store.commit("concludeIntelCreation");
      store.commit("setNewRecordSafezone", true);
      store.commit("clearNotepadConversionComment");
      store.commit("clearOnboarding");
      store.commit("clearTarget");
      store.commit("clearIntel");
      store.commit("clearSearch");
      store.commit("clearDeal");
      store.commit("clearDealMarketing");
      store.commit("clearProperty");
      store.commit("cancelDealParticipantInvitation");
      store.commit("cancelSharedGroup");
      store.commit("clearNameFilter");
      store.commit("setModalNavigationState", {
        horizontalNav: null,
        verticalNav: null
      });
    } else if (targetToken) {
      store.commit("clearDeal");
      store.commit("clearDealMarketing");
      store.commit("clearIntel");
    } else if (dealToken || offeredDealToken) {
      store.commit("clearTarget");
      store.commit("clearIntel");
      store.commit("concludeOnboarding");
      store.commit("clearOnboarding");
    } else if (intelToken) {
      store.commit("clearTarget");
      store.commit("clearDeal");
      store.commit("clearDealMarketing");
    } else if (editingExistingTarget) {
      store.commit("clearSearch");
      store.commit("clearIntel");
    } else if (newTarget) {
      store.commit("clearTarget");
      store.commit("clearRegion");
    } else if (newDeal) {
      store.commit("clearDeal");
      store.commit("clearDealMarketing");
      store.commit("clearProperty");
    }
  }
});

export default router;
