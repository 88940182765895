<template>
  <li class="py-4">
    <div class="flex items-center space-x-4">
      <span v-tooltip="categoryHelp" class="flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-full" :class="account.chargesEnabled ? 'bg-green-400' : 'bg-yellow-300'">
        <svg v-if="account.category === 'building'" class="h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clip-rule="evenodd" />
        </svg>
        <svg v-else-if="account.category === 'team'" class="h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
        </svg>
        <svg v-else-if="account.category === 'company'" class="h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd" />
          <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
        </svg>
      </span>
      <div class="flex-1 min-w-0">
        <div v-if="editingName" class="flex items-center space-x-1">
          <div class="flex-1">
            <label for="name" class="sr-only">Account name</label>
            <input v-focus v-model="accountName" @keydown.enter="saveName" @keydown.escape="cancelName" type="text" name="name" id="name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md" placeholder="Account name">
          </div>
          <button @click="cancelName" type="button" v-tooltip="'Cancel'" class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            <!-- Heroicon name: x -->
            <svg class="h-5 w-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </button>
          <button @click="saveName" type="button" v-tooltip="saveHelp" :disabled="!nameComplete" :class="nameComplete ? '' : 'cursor-not-allowed'" class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
            <!-- Heroicon name: solid/plus -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
        <a v-else href="" @click.prevent="editingName = true" class="text-sm font-medium flex items-center text-indigo-600 hover:text-indigo-500 truncate">
          <span>{{ account.name || `Payout account` }}</span>
          <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
        </a>
        <p class="text-sm text-gray-500 truncate">
          {{ role }} &middot; {{ status }}
        </p>
      </div>
      <div v-if="!editingName" class="flex items-center space-x-2">
        <a href="" @click.prevent="clickHandler" class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50">
          {{ viewLabel }}
        </a>
        <shared-payout-account-menu v-if="account.managed" :account="account" @refetch="refetch" @category="setCategory" />
      </div>
    </div>
  </li>
</template>

<script>
import SharedPayoutAccountMenu from "./shared-payout-account-menu";
import api from "../api";

export default {
  components: { SharedPayoutAccountMenu },
  props: ["account"],
  data() {
    return {
      editingName: false,
      accountName: ""
    };
  },
  computed: {
    nameComplete() {
      return _.trim(this.accountName) !== "";
    },
    categoryHelp() {
      return this.$options.filters.capitalize(this.account.category);
    },
    role() {
      return this.account.managed ? "Manager" : "Member";
    },
    viewLabel() {
      return this.account.chargesEnabled ? "View" : "Setup";
    },
    status() {
      return this.account.chargesEnabled ? "Payable" : "Setup incomplete";
    },
    saveHelp() {
      return this.nameComplete ? "Save" : "Incomplete";
    }
  },
  methods: {
    clickHandler() {
      api.get(`shared_payout_accounts/${this.account.id}`).then(json => {
        if (json.data) {
          if (json.data.newTab) {
            window.open(json.data.url, "_blank");
          } else {
            window.location.href = json.data.url;
          }
        } else {
          this.$store.dispatch("flash", "Could not fetch account link.");
        }
      });
    },
    saveName() {
      if (this.nameComplete) {
        api
          .patch(`shared_payout_accounts/${this.account.id}`, {
            name: this.accountName,
            category: this.account.category
          })
          .then(json => {
            this.$store.dispatch("flash", "Account name updated.");
            this.cancelName();
            this.refetch();
          });
      }
    },
    setCategory(category) {
      api
        .patch(`shared_payout_accounts/${this.account.id}`, {
          name: this.account.name,
          category
        })
        .then(json => {
          this.$store.dispatch("flash", "Account category updated.");
          this.refetch();
        });
    },
    refetch() {
      this.$emit("refetch");
    },
    cancelName() {
      this.accountName = "";
      this.editingName = false;
    }
  }
};
</script>
