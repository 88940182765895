<template>
  <div class="wrapper">
    <heading class-name="black">{{ question }}</heading>
    <button @click="clickHandler" type="button" class="form cta" :class="{ complete: complete && !privacyMode, 'privacy-complete': complete && privacyMode }" tabindex="999">
      <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
        <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
      </svg>
      <template v-if="postToken">Confirm privacy and edit</template>
      <template v-else-if="!required && !hasRegions">Anywhere in the world</template>
      <template v-else>Next</template>
    </button>
    <autocomplete
      id="region-search"
      ref="regionSearch"
      :search="searchRegions"
      :auto-select="true"
      :get-result-value="getResultValue"
      :debounce-time="500"
      @focus="focus('region')"
      @blur="blur"
      @submit="handleRegionSubmit"
      placeholder="Search your regions"
      class="mb-1"
      style="width:330px;"
    />
    <button @click="createRegion" type="button" class="form secondary">
      Create new region
    </button>
    <div class="content-wrap">
      <region-list :regions="intelRegions" class="mb-2" />
      <div class="h-48">
        <intel-map v-if="hasRegions" :post="activeIntel" :redraw-regions="redrawRegions" :class="{ 'mb-4': required }" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../../components/onboarding/heading";
import IntelMap from "../../../components/maps/market-intel-map";
import Region from "../onboarding-region";
import RegionList from "../../../components/region-list";
import Swal from "sweetalert2";
import anonymousPostUpdateOptions from "../../../sweet-alert-always-anonymous-update-options";
import api from "../../../api";

export default {
  components: { Heading, IntelMap, RegionList },
  props: ["postToken"],
  data() {
    return {
      hovering: null,
      focused: false,
      redrawRegions: false
    };
  },
  computed: {
    ...mapState([
      "modal",
      "regions",
      "activeIntel",
      "intelCreationInProcess",
      "currentUser"
    ]),
    ...mapGetters(["creatingIntel", "privacyMode"]),
    question() {
      switch (this.activeIntel.type) {
        case "jobPosting":
          return "Does the job cover particular regions?";
        case "industryEvent":
          return "What is the geographic focus of the event?";
        case "developmentNews":
        case "infrastructureNews":
          return "What geographic area(s) does the project impact?";
        case "tenantInMarket":
          return "Where is the space requirement focused?";
        case "marketCommentary":
        case "marketReport":
        default:
          return "What geographic regions does this apply to?";
      }
    },
    intelRegions() {
      return this.activeIntel.regions;
    },
    hasRegions() {
      return this.intelRegions.length > 0;
    },
    required() {
      return _.includes(
        ["infrastructureNews", "tenantInMarket"],
        this.activeIntel.type
      );
    },
    complete() {
      if (this.required) {
        return this.hasRegions;
      } else {
        return true;
      }
    }
  },
  watch: {
    intelRegions: {
      handler() {
        this.redrawRegions = true;
        setTimeout(() => {
          this.redrawRegions = false;
        }, 100);
      }
    }
  },
  mounted() {
    if (this.creatingIntel && !this.intelCreationInProcess) {
      this.$router.push({ path: "/intel/new/1" });
    }

    document.getElementById("region-search").focus();
  },
  methods: {
    focus(field) {
      this.focused = field;
    },
    blur() {
      this.focused = false;
    },
    searchRegions(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        this.$store.dispatch("executeRegionSearch", input).then(
          json => {
            resolve(json.data);
          },
          failure => {
            this.$store.dispatch("flash", "Invalid search");
          }
        );
      });
    },
    getResultValue(result) {
      return `${result.name} (${_.capitalize(result.shape)})`;
    },
    handleRegionSubmit(result) {
      if (result) {
        api.get(`regions/${result.id}`).then(json => {
          this.$store.dispatch("addRegionWithGetters", json.data.region);
        });
        this.$refs.regionSearch.value = "";
      } else {
        this.$store.dispatch(
          "flash",
          "No matching regions in Tower Hunt. Why don't you create one?"
        );
      }
    },
    clickHandler() {
      if (this.postToken) {
        this.confirmPrivacy();
      } else {
        this.next();
      }
    },
    confirmPrivacy() {
      if (this.complete && this.postToken) {
        Swal.fire(anonymousPostUpdateOptions).then(result => {
          if (result.value) {
            this.$store.dispatch("updateIntel", {
              postToken: this.postToken,
              updateTags: true
            });

            this.$store.commit("closeModal");
            this.$store.commit("clearIntel");
          }
        });
      }
    },
    next() {
      if (this.complete) {
        switch (this.activeIntel.type) {
          case "jobPosting":
          case "industryEvent":
          case "marketCommentary":
          case "marketReport":
            this.$router.push({ path: "/intel/new/7" });
            break;
          case "developmentNews":
          case "infrastructureNews":
            this.$router.push({ path: "/intel/new/11" });
            break;
          case "tenantInMarket":
            this.$router.push({ path: "/intel/new/10" });
            break;
        }
      }
    },
    createRegion() {
      let regionsModal = _.cloneDeep(this.modal); // obtains the current component

      if (this.postToken) {
        this.$store.commit("openModal", {
          component: Region,
          props: {
            regionId: null
          },
          afterClose: regionsModal
        });
      } else {
        this.$store.commit("openModal", {
          component: Region,
          props: {
            regionId: null
          }
        });
      }
    },
    hover(option) {
      this.hovering = option;
    },
    vacate() {
      this.hovering = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}
.content-wrap {
  display: flex;
  flex-direction: column;
  width: 330px;
}
.autocomplete ul {
  z-index: 10001 !important;
}
.autocomplete-result {
  text-align: left;
}
button.form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  margin-bottom: 5px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.privacy-complete {
    background: #d61f69;
    box-shadow: rgba(214, 31, 105, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #f17eb8;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
