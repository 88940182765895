<template>
  <tr>
    <td class="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
      <div class="flex items-center space-x-3 lg:pl-2">
        <div v-tooltip="statusHelp" :class="`flex-shrink-0 w-2.5 h-2.5 rounded-full bg-${statusColor}`"></div>
        <a href="" @click.prevent="editPurchaseable" class="truncate hover:text-gray-600">
          <span>{{ purchaseable.name || "Name" }} <span class="text-gray-500 font-normal">{{ purchaseable.description || "Description" }}</span> </span>
        </a>
      </div>
    </td>
    <td class="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500">
      <span v-if="purchaseable.contentType === 'deal'" v-tooltip="`${purchaseable.contentName}`" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-yellow-100 text-yellow-800">
        <svg class="-ml-0.5 mr-1.5 h-4 w-4 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
          <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
        </svg>
        {{ purchaseable.contentName | truncate(15) }}
      </span>
      <button v-else-if="purchaseable.contentType === 'standalonePurchaseable' && purchaseable.contentToken" @click="editCustomFilters" type="button" v-tooltip="customFiltersHelp" :class="`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-${customFiltersColor}-100 text-${customFiltersColor}-800 hover:bg-${customFiltersColor}-50 hover:text-${customFiltersColor}-900 focus:outline-none focus:ring-${customFiltersColor} focus:border-${customFiltersColor}-700 active:bg-${customFiltersColor}-200`">
        <svg :class="`-ml-0.5 mr-1.5 h-4 w-4 text-${customFiltersColor}-400`" fill="currentColor" viewBox="0 0 20 20">
          <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
        </svg>
        Custom filters
      </button>
      <button v-else-if="purchaseable.contentType === 'dataSet' && purchaseable.contentToken" @click="editDataSet" type="button" v-tooltip="dataSetHelp" :class="`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-${dataSetColor}-100 text-${dataSetColor}-800 hover:bg-${dataSetColor}-50 hover:text-${dataSetColor}-900 focus:outline-none focus:ring-${dataSetColor} focus:border-${dataSetColor}-700 active:bg-${dataSetColor}-200`">
        <svg :class="`-ml-0.5 mr-1.5 h-4 w-4 text-${dataSetColor}-400`" fill="currentColor" viewBox="0 0 20 20">
          <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z" />
        </svg>
        Data set
      </button>
      <intel-type-badge v-else :post="postSnippet" :name-override="true" />
    </td>
    <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500 font-medium">
      <template v-if="purchaseable.approvalStatus === 'seller_approved'">
        Launching
      </template>
      <template v-else>
        {{ purchaseable.state | capitalize }}
      </template>
    </td>
    <td class="px-6 py-3 text-sm leading-5 text-gray-500 font-medium">
      <a href="" @click.prevent="editPricing" class="truncate hover:text-gray-600">
        <template v-if="pricePreview">
          <span>${{ pricePreview.displayPrice.price }}<span v-if="pricePreview.displayPrice.frequency === 'monthly'" class="text-gray-500 font-normal">/mth</span><span v-if="pricePreview.remainingCount > 0" class="ml-2 flex-shrink-0 text-xs leading-5 font-medium">+{{ pricePreview.remainingCount }}</span></span>
        </template>
        <template v-else>+ Add</template>
      </a>
    </td>
    <td class="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500 text-right">
      {{ purchaseable.updatedAt | moment("MMMM D, YYYY") }}
    </td>
    <td class="pr-6">
      <purchaseable-menu :purchaseable="purchaseable" />
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";
import DataSetEditor from "../data-set-editor";
import IntelCard from "../../cards/market-intel";
import IntelTypeBadge from "../../intel-type-badge";
import PricingEditor from "../pricing-editor";
import PurchaseableDetailsForm from "../purchaseable-details-form";
import PurchaseableMenu from "../purchaseable-menu";

export default {
  components: { IntelTypeBadge, PurchaseableMenu },
  props: ["purchaseable"],
  computed: {
    ...mapGetters(["isAdmin"]),
    postSnippet() {
      return {
        name: this.purchaseable.contentName,
        type: this.purchaseable.contentType
      };
    },
    pricePreview() {
      return this.purchaseable.pricePreview;
    },
    statusColor() {
      if (this.purchaseable.state === "live") {
        return "green-400";
      } else if (this.purchaseable.complete) {
        return "yellow-300";
      } else {
        return "pink-600";
      }
    },
    statusHelp() {
      if (this.purchaseable.state === "live") {
        return "Content is live!";
      } else if (this.purchaseable.complete) {
        return "Content is complete but not launched";
      } else {
        return "Content is incomplete. Be sure you've added all of the required info.";
      }
    },
    customFiltersColor() {
      return this.purchaseable.linked ? "green" : "red";
    },
    customFiltersHelp() {
      return this.purchaseable.linked
        ? "Click to edit filters."
        : "No filters have been added. Click to add.";
    },
    dataSetColor() {
      return this.purchaseable.linked ? "green" : "red";
    },
    dataSetHelp() {
      return this.purchaseable.linked
        ? "Click to edit data set."
        : "Data set is empty. Click to edit.";
    }
  },
  methods: {
    editCustomFilters() {
      this.$store.commit("openModal", {
        component: IntelCard,
        props: {
          postToken: this.purchaseable.contentToken,
          updatePurchaseables: true
        }
      });
    },
    editDataSet() {
      this.$store.commit("openModal", {
        component: DataSetEditor,
        props: {
          purchaseable: this.purchaseable,
          dataSetId: this.purchaseable.contentToken
        }
      });
    },
    editPurchaseable() {
      this.$store.commit("openModal", {
        component: PurchaseableDetailsForm,
        props: {
          purchaseable: this.purchaseable
        }
      });
      this.menuOpen = false;
    },
    editPricing() {
      this.$store.commit("openModal", {
        component: PricingEditor,
        props: {
          purchaseable: this.purchaseable
        }
      });
    }
  }
};
</script>
