<template>
  <div class="mt-6 bg-white shadow overflow-visible rounded-md">
    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <div
        class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
      >
        <div class="ml-4 mt-2">
          <h3 class="text-lg leading-6 font-medium text-gray-900 flex items-center">
            <span><template v-if="geospecificIntel">Local </template>Market {{ label | capitalize }} ({{ trailingMonths }})</span>
            <!-- On: "bg-indigo-600", Off: "bg-gray-200" -->
            <span role="checkbox" tabindex="0" :aria-checked="geospecificIntel" v-tooltip="intelScopeHelp" @click="toggleIntelScope" :class="geospecificIntel ? 'bg-indigo-600' : 'bg-gray-200'" class="ml-3 bg-gray-200 relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring">
              <!-- On: "translate-x-5", Off: "translate-x-0" -->
              <span aria-hidden="true" :class="geospecificIntel ? 'translate-x-5' : 'translate-x-0'" class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"></span>
            </span>
          </h3>
        </div>

        <div class="ml-4 mt-2 flex-shrink-0 flex">
          <target-intel-notifications-menu v-if="target.authored || target.followed" :target="target" class="ml-3 md:ml-0" />
          <span v-if="target.authored" class="hidden ml-3 md:inline-flex rounded-md shadow-sm">
            <button
              @click="createIntel"
              type="button"
              :class="privacyMode ? 'bg-pink-600 hover:bg-pink-500 focus:border-pink-700 focus:ring-pink active:bg-pink-700' : 'bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700'"
              class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none"
            >
              Post {{ label }}
            </button>
          </span>
        </div>
      </div>
    </div>
    <div v-if="combinedIntel.length > 0" class="px-4 py-5 sm:p-6">
      <div class="grid gap-5 max-w-lg mx-auto sm:grid-cols-2 lg:grid-cols-3 sm:max-w-none">
        <multi-content-preview-card
          v-for="post in combinedIntel"
          :key="`${post.token}`"
          :content="post"
        />
      </div>
    </div>
    <div v-if="intelPagination && intelPagination.count > 3" class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div class="hidden sm:block">
        <p class="text-sm leading-5 text-gray-700">
          Showing
          <span class="font-medium">{{ intelPagination.from }}</span>
          to
          <span class="font-medium">{{ intelPagination.to }}</span>
          of
          <span class="font-medium">{{ intelPagination.count }}</span>
          posts
        </p>
      </div>
      <div class="flex-1 flex justify-between sm:justify-end">
        <router-link @click.native="fetchIntel(intelPagination.prev_url)" to="" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
          Previous
        </router-link>
        <router-link @click.native="fetchIntel(intelPagination.next_url)" to="" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
          Next
        </router-link>
      </div>
    </div>
    <div v-if="combinedIntel.length === 0" class="relative bg-gray-800">
      <div class="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img class="w-full h-full object-cover" src="https://source.unsplash.com/collection/1976082" alt="Intel intro" />
      </div>
      <div class="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div class="md:ml-auto md:w-1/2 md:pl-10">
          <div class="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
            <template v-if="marketReportsOnly">Market reports</template>
            <template v-else>News, opinions, and hard-to-find info</template>
          </div>
          <h2 class="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
            Stay current
          </h2>
          <p class="mt-3 text-lg leading-7 text-gray-300">
            Nothing new to show you at the moment.
          </p>
          <div class="mt-8">
            <div class="inline-flex rounded-md shadow">
              <a @click.prevent="createIntel" href="" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:ring">
                Post {{ label }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MultiContentPreviewCard from "../cards/multi-content-preview-card";
import TargetIntelNotificationsMenu from "../target-intel-notifications-menu";
import _ from "lodash";

export default {
  components: {
    MultiContentPreviewCard,
    TargetIntelNotificationsMenu
  },
  props: ["marketReportsOnly"],
  data() {
    return {
      geospecificIntel: true,
      fetchingIntelUpdates: false,
      fetchingIntelLongTailUpdates: false
    };
  },
  computed: {
    ...mapState([
      "now",
      "activeTarget",
      "refetchTargetPage",
      "refetchRumoredDeals",
      "currentUser",
      "modal"
    ]),
    ...mapGetters([
      "signedIn",
      "targetTokenParam",
      "userIsActiveContact",
      "activeTargetDeals",
      "showDealSize",
      "showLeaseSize",
      "showLeverageSize",
      "showLoanTypes",
      "showJVRoles",
      "targetHasPropertySize",
      "privacyMode"
    ]),
    target() {
      return this.$store.state.activeTarget;
    },
    intelPagination() {
      return this.activeTarget.intel.pagy;
    },
    combinedIntel() {
      return this.activeTarget.intel.data.filter(post => this.isVisible(post));
    },
    label() {
      return this.marketReportsOnly ? "reports" : "intel";
    },
    trailingMonths() {
      return this.marketReportsOnly ? "T12" : "T1";
    },
    intelScopeHelp() {
      if (this.geospecificIntel) {
        return `View all ${this.label} for this market`;
      } else {
        return `View region-specific ${this.label} for this market`;
      }
    }
  },
  watch: {
    targetTokenParam: {
      handler: function() {
        this.fetchIntel();
        this.$store.commit("createInterval", { callback: this.updateIntel, delay: 3000 });
      }
    },
    fetchingIntelUpdates: {
      handler() {
        if (this.fetchingIntelUpdates) {
          this.$store.dispatch("flash", {
            message: "Checking for new content",
            timeout: 3000
          });
        }
      }
    },
    fetchingIntelLongTailUpdates: {
      handler(val, oldVal) {
        if (!val && oldVal) {
          this.$store.dispatch("flash", {
            message: "New content check complete",
            timeout: 3000
          });
        }
      }
    },
    refetchTargetPage: {
      handler() {
        if (this.refetchTargetPage) {
          this.$store.commit("createInterval", { callback: this.updateIntel, delay: 3000 });
        }
      }
    }
  },
  mounted() {
    this.fetchIntel();
    this.$store.commit("createInterval", { callback: this.updateIntel, delay: 3000 });
  },
  methods: {
    toggleIntelScope() {
      this.geospecificIntel = !this.geospecificIntel;
      this.fetchIntel();
    },
    updateIntel() {
      this.$store
        .dispatch("updateTargetIntel")
        .then(({ fetchPreview, fetchLongTail }) => {
          if (fetchPreview) {
            this.fetchingIntelUpdates = true;
            this.fetchingIntelLongTailUpdates = true;
          } else if (fetchLongTail) {
            if (this.fetchingIntelUpdates) {
              this.fetchIntel();
            }

            this.fetchingIntelUpdates = false;
          } else {
            this.$store.dispatch("clearAllIntervals");
            this.fetchingIntelLongTailUpdates = false;

            this.fetchIntel();
          }
        });
    },
    fetchIntel(url = null) {
      const cleanUrl = url
        ? _.drop(url, 8).join("")
        : `target_intel/${this.targetTokenParam}`;
      const parameterAddedUrl = _.includes(cleanUrl, "page=")
        ? `${cleanUrl}`
        : `${cleanUrl}?geospecific=${this.geospecificIntel}&market_reports=${this.marketReportsOnly}`;

      this.$store.dispatch("loadTargetIntel", parameterAddedUrl);
    },
    createIntel() {
      this.$router.push({
        path: "/intel/new/privacy",
        query: { nextUrl: "/intel/new/1" }
      });
    },
    isVisible(post) {
      return (
        post.pinned || !post.anonymous || (!post.dismissed && !post.downvoted)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.list-complete-item {
  transition: all 2s;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.list-complete-leave-active {
  position: absolute;
}
</style>
