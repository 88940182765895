<template>
  <div v-if="loaded" v-tooltip="'When on, you\'ll receive immediate notifications for new messages'">
    <span v-if="consent" class="inline-flex rounded-md shadow-sm">
      <button @click="optOut" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
        <svg class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
          <path fill-rule="evenodd" d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" clip-rule="evenodd"/>
        </svg>
        Alerts are on
      </button>
    </span>
    <span v-else class="inline-flex rounded-md shadow-sm">
      <button @click="optIn" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
        <svg class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
          <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd"/>
        </svg>
        Alerts are off
      </button>
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["contentType", "contentToken"],
  data() {
    return {
      consent: true,
      loaded: false
    };
  },
  computed: {
    ...mapGetters(["backchannelTokenParam"])
  },
  watch: {
    backchannelTokenParam: {
      handler(token, oldToken) {
        if (
          !!oldToken &&
          token !== oldToken &&
          this.contentType === "backchannel"
        ) {
          this.fetchConsent();
        }
      }
    }
  },
  mounted() {
    this.fetchConsent();
  },
  methods: {
    fetchConsent() {
      this.$store
        .dispatch("loadInstantMessagingConsent", {
          contentType: this.contentType,
          contentToken: this.contentToken
        })
        .then(consent => {
          this.consent = consent;
          this.loaded = true;
        });
    },
    optOut() {
      this.consent = false;
      this.$store.dispatch("destroyInstantMessagingConsent", {
        contentType: this.contentType,
        contentToken: this.contentToken
      });
    },
    optIn() {
      this.consent = true;
      this.$store.dispatch("createInstantMessagingConsent", {
        contentType: this.contentType,
        contentToken: this.contentToken
      });
    }
  }
};
</script>
