<template>
  <div class="relative p-4 max-w-3xl mx-auto">
    <div :class="modal ? 'text-center' : ''">
      <h2 :class="modal ? 'text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10' : 'text-lg leading-6 font-medium text-gray-900'">
        Alert Proximity
      </h2>
      <p v-if="modal" class="mt-4 text-xl font-bold leading-6 text-gray-900">
        {{ property.name }}
      </p>
      <p v-else class="mt-1 text-sm text-gray-500">
        We use this setting to build the newsfeed and send you email alerts.
      </p>
      <p v-if="modal" class="mt-2 text-lg font-medium leading-5 text-gray-700">
        {{ [property] | totalSize }}
      </p>

      <div v-if="!following.authored && !modal" class="mt-2 rounded-md bg-yellow-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: solid/information-circle -->
            <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-yellow-700">
              Proximity settings are read-only. Subscribe to email alerts in the lower-right.
            </p>
          </div>
        </div>
      </div>
    </div>
    <form @submit.prevent :class="modal ? 'mt-10 space-y-8' : 'mt-6 space-y-6'">
      <div class="space-y-4">
        <div>
          <h2 id="types_heading" :class="modal ? 'text-lg leading-6 font-medium text-gray-900' : 'text-base font-medium text-gray-700'">How do you want to define proximity?</h2>
        </div>

        <fieldset>
          <legend class="sr-only">
            Alert proximity types
          </legend>
          <div class="relative bg-white rounded-md -space-y-px">
            <label :class="checked('radius') ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200'" class="rounded-tl-md rounded-tr-md relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3">
              <div class="flex items-center text-sm">
                <input v-model="proximityType" @change="focusSearch" :disabled="!following.authored" type="radio" name="proximity_type" value="radius" class="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="proximity-type-0-label" aria-describedby="proximity-type-0-description-0 proximity-type-0-description-1">
                <span id="proximity-type-0-label" :class="checked('radius') ? 'text-indigo-900' : 'text-gray-900'" class="ml-3 font-medium">Radius</span>
              </div>
              <p id="proximity-type-0-description-0" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                <span :class="checked('radius') ? 'text-indigo-900' : 'text-gray-900'" class="font-medium">From the property</span>
              </p>
              <p id="proximity-type-0-description-1" :class="checked('radius') ? 'text-indigo-700' : 'text-gray-500'" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">Up to 1 mile</p>
            </label>

            <label :class="checked('region') ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200'" class="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3">
              <div class="flex items-center text-sm">
                <input v-model="proximityType" @change="focusSearch" :disabled="!following.authored" type="radio" name="proximity_type" value="region" class="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="proximity-type-1-label" aria-describedby="proximity-type-1-description-0 proximity-type-1-description-1">
                <span id="proximity-type-1-label" :class="checked('region') ? 'text-indigo-900' : 'text-gray-900'" class="ml-3 font-medium">Region</span>
              </div>
              <p id="proximity-type-1-description-0" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                <span :class="checked('region') ? 'text-indigo-900' : 'text-gray-900'" class="font-medium">Within the boundary</span>
              </p>
              <p id="proximity-type-1-description-1" :class="checked('region') ? 'text-indigo-700' : 'text-gray-500'" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">1 custom region</p>
            </label>

            <label :class="checked('competitiveSet') ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200'" class="rounded-bl-md rounded-br-md relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3">
              <div class="flex items-center text-sm">
                <input v-model="proximityType" @change="focusSearch" :disabled="!following.authored" type="radio" name="proximity_type" value="competitiveSet" class="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="proximity-type-2-label" aria-describedby="proximity-type-2-description-0 proximity-type-2-description-1">
                <span id="proximity-type-2-label" :class="checked('competitiveSet') ? 'text-indigo-900' : 'text-gray-900'" class="ml-3 font-medium">Competitive Set</span>
              </div>
              <p id="proximity-type-2-description-0" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                <span :class="checked('competitiveSet') ? 'text-indigo-900' : 'text-gray-900'" class="font-medium">Specific competitors</span>
              </p>
              <p id="proximity-type-2-description-1" :class="checked('competitiveSet') ? 'text-indigo-700' : 'text-gray-500'" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">Up to 25 properties</p>
            </label>
          </div>
        </fieldset>
      </div>

      <div class="space-y-4">
        <div>
          <h2 id="definition_heading" :class="modal ? 'text-lg leading-6 font-medium text-gray-900' : 'text-base font-medium text-gray-700'">{{ definitionHelp }}</h2>
        </div>

        <div v-if="proximityType === 'radius'">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
            <div class="mb-2">
              <label for="radius" class="block text-sm font-medium text-gray-700">Radius</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input v-model="radius" :disabled="!following.authored" type="number" min="0.05" step="0.05" max="1" name="radius" id="radius" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-2 pr-12 sm:text-sm border-gray-300 rounded-md" aria-describedby="radius">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span class="text-gray-500 sm:text-sm" id="price-currency">
                    Miles
                  </span>
                </div>
              </div>
            </div>
            <div class="h-48 sm:col-span-2">
              <intel-map v-if="mapVisible" :post="placeholderIntel('region')" :local-regions="[radiusRegion]" :local-properties=[property] :hide-nearby="true" />
            </div>
          </div>
        </div>

        <div v-else-if="proximityType === 'region'">
          <div v-if="region" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
            <region-list @remove="removeRegion" :local="true" :regions="[region]" :read-only="true" class="mb-2" />
            <div class="h-48 sm:col-span-2">
              <intel-map v-if="mapVisible" :post="placeholderIntel('region')" :local-regions="[region]" :local-properties=[property] :hide-nearby="true" />
            </div>
          </div>

          <div v-else-if="following.authored" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
            <label for="region-search" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
              Look up
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2 flex rounded-md shadow-sm">
              <div class="relative flex-grow focus-within:z-10">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <!-- Heroicon name: search -->
                  <svg class="h-5 w-5 text-gray-400" style="z-index: 1;" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg>
                </div>
                <autocomplete
                  id="region-search"
                  ref="regionSearch"
                  type="text"
                  base-class="custom-autocomplete-leading-icon"
                  :search="searchRegions"
                  :auto-select="true"
                  :get-result-value="getRegionResultValue"
                  :debounce-time="500"
                  @submit="handleRegionSubmit"
                  placeholder="Name"
                />
              </div>
              <button @click="createRegion" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
                <!-- Heroicon name: plus -->
                <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                </svg>
                <span class="ml-2">New</span>
              </button>
            </div>
          </div>
        </div>

        <div v-else-if="proximityType === 'competitiveSet'">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
            <property-list @remove="removeProperty" :local="true" :properties="competitiveSetProperties" :display-size="true" :read-only="true" class="sm:col-span-2 sm:mb-2 sm:max-h-48 sm:overflow-y-auto" />
            <div class="h-48">
              <intel-map v-if="mapVisible" :post="placeholderIntel('property')" :local-properties="mappableCompetitiveSet" :hide-nearby="true" />
            </div>
          </div>

          <div v-if="following.authored" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
            <label for="property-search" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
              Look up
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2 flex rounded-md shadow-sm">
              <div class="relative flex-grow focus-within:z-10">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <!-- Heroicon name: search -->
                  <svg class="h-5 w-5 text-gray-400" style="z-index: 1;" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg>
                </div>
                <autocomplete
                  id="property-search"
                  ref="propertySearch"
                  type="text"
                  base-class="custom-autocomplete-leading-icon"
                  :search="searchProperties"
                  :auto-select="true"
                  :get-result-value="getPropertyResultValue"
                  :debounce-time="500"
                  @focus="handleAutocompleteMapVisibility"
                  @blur="mapVisible = true"
                  @submit="handlePropertySubmit"
                  placeholder="Name, address, etc."
                />
              </div>
              <button @click="createProperty" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
                <!-- Heroicon name: plus -->
                <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                </svg>
                <span class="ml-2">New</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="following.authored" class="pt-5">
        <div class="flex justify-end">
          <button v-if="modal" @click="closeModal" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Cancel
          </button>
          <button v-if="modal" @click="resetProximity" v-tooltip="'Revert to the default setting of a 0.25-mile radius.'" type="button" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            Clear
          </button>
          <button @click="updateProximity" type="button" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Save
          </button>
        </div>
      </div>
      <div v-else class="pt-5">
        <div class="flex justify-end">
          <div class="flex items-center">
            <email-alert-toggle :following="following" @refetch="refetchFollowing" />
            <span class="ml-3" id="email-alerts-label">
              <span class="text-sm font-medium text-gray-900">Email alerts?</span>
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmailAlertToggle from "./email-alert-toggle.vue";
import IntelMap from "../maps/market-intel-map";
import Property from "../../views/onboarding/deal/property.vue";
import PropertyList from "../property-list";
import Region from "../../views/onboarding/onboarding-region.vue";
import RegionList from "../region-list";
import api from "../../api";

export default {
  components: { EmailAlertToggle, IntelMap, PropertyList, RegionList },
  props: ["following"],
  data() {
    return {
      mapVisible: true
    };
  },
  computed: {
    ...mapState(["propertyWatchlistProximity", "deduplicateRecord", "modal"]),
    property() {
      return this.following.property;
    },
    proximityType: {
      get() {
        return this.propertyWatchlistProximity.proximityType;
      },
      set(newType) {
        this.$store.commit("setWatchlistProximityType", newType);
      }
    },
    radius: {
      get() {
        return this.propertyWatchlistProximity.radius;
      },
      set(newRadius) {
        this.$store.commit("setWatchlistProximityRadius", newRadius);
      }
    },
    region() {
      return this.propertyWatchlistProximity.region;
    },
    competitiveSetProperties() {
      return this.propertyWatchlistProximity.competitiveSetProperties;
    },
    definitionHelp() {
      switch (this.proximityType) {
        case "radius":
          return "How far out?";
        case "region":
          return "Which region?";
        case "competitiveSet":
          return "Which properties?";
        default:
          return "Define the alert proximity";
      }
    },
    radiusRegion() {
      const mileToMeter = 1609.34;

      return {
        shape: "circle",
        center: { lat: this.property.lat, lng: this.property.lng },
        radius: this.radius * mileToMeter
      };
    },
    mappableCompetitiveSet() {
      return _.concat(this.competitiveSetProperties, this.property);
    }
  },
  mounted() {
    var self = this;

    setTimeout(() => {
      self.focusSearch();
    }, 50);
  },
  methods: {
    refetchFollowing() {
      this.$emit("refetch");
    },
    resetProximity() {
      if (this.following.authored) {
        this.mapVisible = false;
        var self = this;

        setTimeout(() => {
          self.$store.commit("resetWatchlistProximity");
          self.mapVisible = true;
        }, 50);
      }
    },
    checked(value) {
      return this.proximityType === value;
    },
    closeModal() {
      this.$store.commit("closeModal");
    },
    placeholderIntel(type) {
      return {
        lat: null,
        lng: null,
        token: `${type}-map`,
        name: `${type} map`
      };
    },
    removeRegion() {
      this.$store.commit("setWatchlistProximityRegion", null);
      setTimeout(() => {
        document.getElementById("region-search").focus();
      }, 50);
    },
    searchRegions(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        this.$store.dispatch("executeRegionSearch", input).then(
          json => {
            resolve(json.data);
          },
          failure => {
            this.$store.dispatch("flash", "Invalid search");
          }
        );
      });
    },
    getRegionResultValue(result) {
      return `${result.name} (${_.capitalize(result.shape)})`;
    },
    handleRegionSubmit(result) {
      if (result) {
        api.get(`regions/${result.id}`).then(json => {
          this.$store.commit("setWatchlistProximityRegion", json.data.region);
        });
        this.$refs.regionSearch.value = "";
      } else {
        this.$store.dispatch(
          "flash",
          "No matching regions in Tower Hunt. Why don't you create one?"
        );
      }
    },
    createRegion() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      this.$store.commit("openModal", {
        component: Region,
        props: {
          regionId: null
        },
        afterClose: currentModal
      });
    },
    searchProperties(input) {
      return new Promise(resolve => {
        this.handleAutocompleteMapVisibility();

        if (input.length < 2) {
          return resolve([]);
        }

        this.$store.dispatch("executePropertySearch", input).then(
          json => {
            resolve(json.data);
          },
          failure => {
            this.$store.dispatch("flash", "Invalid search");
          }
        );
      });
    },
    getPropertyResultValue(result) {
      return `${result.name} (${result.cityState})`;
    },
    handlePropertySubmit(result) {
      if (result) {
        api.get(`properties/${result.id}`).then(json => {
          this.$store.commit(
            "addWatchlistProximityCompetitiveSetProperty",
            json.data
          );
          this.$refs.propertySearch.value = "";
          this.handleAutocompleteMapVisibility();
        });
      } else {
        this.$store.dispatch(
          "flash",
          "No matching properties in Tower Hunt. Why don't you create one?"
        );
      }
    },
    createProperty() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      this.$store.commit("openModal", {
        component: Property,
        props: {
          propertyId: null
        },
        afterClose: currentModal
      });
    },
    removeProperty(property) {
      this.$store.commit(
        "removeWatchlistProximityCompetitiveSetProperty",
        property
      );
      setTimeout(() => {
        document.getElementById("property-search").focus();
      }, 50);
    },
    handleAutocompleteMapVisibility() {
      if (this.$refs.propertySearch) {
        this.mapVisible = this.$refs.propertySearch.value === "";
      }
    },
    focusSearch() {
      if (this.following.authored) {
        this.mapVisible = false;
        var self = this;

        switch (this.proximityType) {
          case "radius":
            setTimeout(() => {
              self.mapVisible = true;
              document.getElementById("radius").focus();
            }, 50);
            break;
          case "region":
            setTimeout(() => {
              self.mapVisible = true;
              try {
                document.getElementById("region-search").focus();
              } catch (error) {
                return;
              }
            }, 50);
            break;
          case "competitiveSet":
            setTimeout(() => {
              self.mapVisible = true;
              document.getElementById("property-search").focus();
            }, 50);
            break;
        }
      }
    },
    updateProximity() {
      if (this.following.authored) {
        this.$store.dispatch("updateWatchlistProximity", this.following);
      } else {
        this.closeModal();
      }
    }
  }
};
</script>
