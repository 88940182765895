import leaflet from "leaflet";
import gridLayer from "leaflet.gridlayer.googlemutant";
import customPulseIcon from "../leaflet-custom-pulse-icon";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "@trevoreyre/autocomplete-vue/dist/style.css";
import "leaflet/dist/leaflet.css";
import "leaflet-fullscreen/dist/Leaflet.fullscreen";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import "leaflet-responsive-popup/leaflet.responsive.popup.js";
import "leaflet-responsive-popup/leaflet.responsive.popup.css";
import "leaflet-easybutton";
import "leaflet-easybutton/src/easy-button.css";
import "leaflet-grayscale/TileLayer.Grayscale";
import "leaflet.heat/dist/leaflet-heat";
import * as VueGoogleMaps from "vue2-google-maps";
import * as moment from "moment";
import camelToSentence from "../camel-case-to-sentence-case";
import {
  capitalMarketsVolume,
  currencyAmount,
  formattedDollarAmount,
  leaseVolume,
  propertySizeRange
} from "../formatted-dollar-amount";
import { sync } from "vuex-router-sync";
import App from "../app.vue";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import LazyLoadDirective from "../lazy-load-directive";
import VCalendar from "v-calendar";
import VTooltip from "v-tooltip";
import Vue from "vue";
import Vue2TouchEvents from "vue2-touch-events";
import VueClipboard from "vue-clipboard2";
import VueInfiniteLoading from "vue-infinite-loading"
import VueMoment from "vue-moment";
import VueObserveVisibility from "vue-observe-visibility";
import VueScrollTo from "vue-scrollto";
import _ from "lodash";
import dealTypes from "../deal-input-deal-types";
import industryRoles from "../industry-roles";
import industrySubRoles from "../industry-sub-roles";
import pluralize from "pluralize";
import router from "../router";
import sortedTypes from "../sorted-types";
import sortedTypeLabels from "../sorted-type-labels";
import store from "../store";
import useTypes from "../use-types";

/* global process */

Vue.use(VueClipboard);
Vue.use(VTooltip);
Vue.use(Autocomplete);
Vue.use(VueScrollTo);
Vue.use(Vue2TouchEvents);
Vue.use(VueMoment);
Vue.use(VueObserveVisibility);
Vue.use(VueInfiniteLoading, {
  props: {
    spinner: "default"
    /* other props need to configure */
  },
  system: {
    throttleLimit: 500
    /* other settings need to configure */
  }
});
Vue.use(VCalendar, {
  firstDayOfWeek: 2 // Monday
});
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.GOOGLE_API_KEY,
    libraries: "places,drawing"
  }
});

customPulseIcon();

// eslint-disable-next-line
delete L.Icon.Default.prototype._getIconUrl
// eslint-disable-next-line
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const unsync = sync(store, router);

document.addEventListener("DOMContentLoaded", () => {
  const el = document.body.appendChild(document.createElement("div"));

  Vue.directive("focus", {
    inserted: function(el, binding, vnode) {
      const passedArgument = vnode.context[binding.arg];

      if (passedArgument && _.includes(["marketCommentary", "marketReport"], passedArgument.type)) {
        return;
      } else if (
        passedArgument &&
        _.isNumber(passedArgument) &&
        passedArgument > 0
      ) {
        return;
      } else {
        el.focus();
      }
    }
  });

  Vue.directive("lazyload", LazyLoadDirective);

  Vue.filter("truncate", (str, limit) => {
    return _.truncate(str, { length: limit || 24 });
  });

  Vue.filter("gutMiddle", (str, limit) => {
    const oneThird = _.toInteger((limit || 24) / 3);

    if (str.length > (limit || 24)) {
      return (
        str.substr(0, oneThird) +
        "..." +
        str.substr(str.length - oneThird, str.length)
      );
    }

    return str;
  });

  Vue.filter("cleanUnreadCount", num => {
    if (num < 10) {
      return num;
    } else {
      return "9+";
    }
  });

  Vue.filter("formattedNumber", (num, precision = 0) => {
    return currencyAmount(num, precision);
  });

  Vue.filter("marketName", target => {
    const rawNames = target.regions.map(region => region.name);
    const joinedRegionNames = _.uniq(rawNames).join(", ");

    return target.name || joinedRegionNames;
  });

  Vue.filter("channelName", backchannel => {
    return (
      backchannel.propertyFollowingName ||
      backchannel.teamName ||
      backchannel.members.map(m => m.name).join(", ")
    );
  });

  Vue.filter("location", post => {
    const formattedAddress = post.location;
    const type = post.type;

    if (formattedAddress) {
      const parts = _.split(formattedAddress, ",");
      const trimmed = _.map(parts, _.trim);
      const deduped = _.uniq(trimmed);

      return _.join(deduped, ", ");
    } else if (type === "industryEvent") {
      return "Online only";
    } else if (type === "jobPosting") {
      return "Fully remote";
    }
  });

  Vue.filter("summarizedLocation", dealProperties => {
    const propertyCount = dealProperties.length;
    const propertyCountSummary = " (" + propertyCount + " Properties)";
    const uniqueLocations = _.uniqBy(dealProperties, "cityState");

    if (propertyCount === 1) {
      return dealProperties[0].cityState;
    } else if (uniqueLocations.length === 1) {
      return uniqueLocations[0].cityState + propertyCountSummary;
    } else if (uniqueLocations.length === 2) {
      return (
        uniqueLocations.map(property => property.cityState).join(" & ") +
        propertyCountSummary
      );
    } else {
      return propertyCount + " Properties";
    }
  });

  Vue.filter("eventDates", industryEvent => {
    const dates = industryEvent.dates;

    switch (industryEvent.dateType) {
      case "single":
      case "multiple":
        return dates.map(date => moment(date).format("ddd, MMM D")).join("; ");
      case "range":
        return dates.map(date => moment(date).format("ddd, MMM D")).join(" - ");
    }
  });

  Vue.filter("propertySizeRange", uses => {
    const sortedUses = _.sortBy(uses, [
      u => {
        return _.find(useTypes, { value: u.type }).order;
      }
    ]);

    return _.compact(sortedUses.map(use => propertySizeRange(use))).join(" or ");
  });

  Vue.filter("bountyValueRange", bounty => {
    if (bounty.minimumValue && bounty.maximumValue) {
      return `$${currencyAmount(bounty.minimumValue)} - $${currencyAmount(
        bounty.maximumValue
      )}`;
    } else if (bounty.minimumValue) {
      return `$${currencyAmount(bounty.minimumValue)}+`;
    } else if (bounty.maximumValue) {
      return `up to $${currencyAmount(bounty.maximumValue)}`;
    } else {
      return "$Min - $Max";
    }
  });

  Vue.filter("priceRange", target => {
    if (target.minimum && target.maximum) {
      return `$${formattedDollarAmount(
        target.minimum * 1000000
      )} - $${formattedDollarAmount(target.maximum * 1000000)}`;
    } else if (target.minimum) {
      return `$${formattedDollarAmount(target.minimum * 1000000)}+`;
    } else if (target.maximum) {
      return `Up to $${formattedDollarAmount(target.maximum * 1000000)}`;
    } else {
      return "$Min - $Max";
    }
  });

  Vue.filter("leaseRange", target => {
    if (target.leaseMinimum && target.leaseMaximum) {
      return `${currencyAmount(target.leaseMinimum)} - ${currencyAmount(
        target.leaseMaximum
      )} SF`;
    } else if (target.leaseMinimum) {
      return `${currencyAmount(target.leaseMinimum)}+ SF`;
    } else if (target.leaseMaximum) {
      return `Up to ${currencyAmount(target.leaseMaximum)} SF`;
    } else {
      return "Min SF - Max SF";
    }
  });

  Vue.filter("leverageRange", target => {
    if (target.leverageMinimum && target.leverageMaximum) {
      return `${currencyAmount(target.leverageMinimum)}% - ${currencyAmount(
        target.leverageMaximum
      )}%`;
    } else if (target.leverageMinimum) {
      return `${currencyAmount(target.leverageMinimum)}%+`;
    } else if (target.leverageMaximum) {
      return `Up to ${currencyAmount(target.leverageMaximum)}%`;
    } else {
      return "Min % - Max %";
    }
  });

  Vue.filter("largeDollar", amount => {
    const rawDollar = amount * 1000000;

    return `$${formattedDollarAmount(rawDollar)}`;
  });

  Vue.filter("perUnitPrice", deal => {
    let rawSize;

    if (deal.goingInPerUnit > 10000) {
      rawSize = _.round(deal.goingInPerUnit, -3);
    } else {
      rawSize = _.round(deal.goingInPerUnit);
    }

    return `$${currencyAmount(rawSize)}${deal.perUnitLabel}`;
  });

  Vue.filter("capitalize", str => {
    return _.startCase(_.toLower(str));
  });

  Vue.filter("upperCase", str => {
    return _.upperCase(str);
  });

  Vue.filter("dealRole", (object, objectType) => {
    let dealRole, jvRole;

    switch (objectType) {
      case "deal":
        dealRole = object.targetAuthorDealRole;
        jvRole = object.targetAuthorJVDealRole;
        break;
      case "participant":
        dealRole = object.dealRole;
        jvRole = object.jvDealRole;
        break;
    }

    if (jvRole === "lp") {
      return `${_.upperCase(jvRole)} (${_.capitalize(dealRole)})`;
    } else if (jvRole == "co-gp") {
      return "Co-GP";
    } else {
      return _.upperCase(jvRole) || _.capitalize(dealRole);
    }
  });

  Vue.filter("industryRoleAlias", (role, subRole = null, token = null) => {
    if (token && token === "NfxwbSKdNwLnKJUoZiRJSeK3") {
      return "Founder";
    } else if (subRole) {
      return _.find(industrySubRoles, { value: subRole }).name;
    } else if (role) {
      return _.find(industryRoles, { value: role }).name;
    } else {
      return "Other";
    }
  });

  Vue.filter("dealTypeAlias", (dealType, jointVentureRoles = []) => {
    if (jointVentureRoles.length > 0) {
      if (
        _.find(jointVentureRoles, { type: "lp" }) &&
        _.find(jointVentureRoles, { type: "co-gp" })
      ) {
        return "Co-GP or LP Equity";
      } else if (_.find(jointVentureRoles, { type: "lp" })) {
        return "LP Equity";
      } else if (_.find(jointVentureRoles, { type: "co-gp" })) {
        return "Co-GP Equity";
      } else {
        return "Joint Venture";
      }
    } else {
      const role = store.getters.signedIn
        ? store.state.currentUser.industryRole
        : "observer";
      const matchingOptions = _.get(dealTypes, role, []);

      return _.find(matchingOptions, { value: dealType }).name;
    }
  });

  Vue.filter("postTypeAlias", postType => {
    switch (postType) {
      case "jobPosting":
        return "Job";
      case "industryEvent":
        return "Industry event";
      case "developmentNews":
        return "Development project news";
      case "infrastructureNews":
        return "Infrastructure news";
      case "tenantInMarket":
        return "Tenant in the market";
      case "marketCommentary":
        return "Market commentary";
      case "marketReport":
        return "Market report";
      case "offMarketPropertySnapshot":
        return "Off-market asset";
      case "helpRequest":
        return "Request";
      case "standalonePurchaseable":
        return "Purchaseable";
      default:
        return "Market intel";
    }
  });

  Vue.filter("priceFrequencyAlias", frequency => {
    switch (frequency) {
      case "monthly":
        return "/mo";
      default:
        return "";
    }
  });

  Vue.filter("priceIndustryRoleAlias", role => {
    switch (role) {
      case "all":
        return "Everyone";
      case "principal":
        return "Principals";
      case "broker":
        return "Brokers";
      case "observer":
        return "Others";
      default:
        return "";
    }
  });

  Vue.filter("dealsVolume", record => {
    if (
      _.toNumber(record.capitalMarketsDealVolume) > 0 &&
      _.toNumber(record.leaseDealVolume) > 0
    ) {
      return `${capitalMarketsVolume(record)}, ${leaseVolume(record)}`;
    } else if (_.toNumber(record.capitalMarketsDealVolume) > 0) {
      return capitalMarketsVolume(record);
    } else if (_.toNumber(record.leaseDealVolume) > 0) {
      return leaseVolume(record);
    } else {
      return "";
    }
  });

  Vue.filter("dealsCapitalMarketsVolume", record => {
    return capitalMarketsVolume(record);
  });

  Vue.filter("dealsLeaseVolume", record => {
    return leaseVolume(record);
  });

  Vue.filter("dealsCount", record => {
    const count = pluralize("deal", record.dealCount, true);
    const year = moment(record.oldestCloseDate).format("YY");

    return `${count} since '${year}`;
  });

  Vue.filter("propertiesCount", properties => {
    return pluralize("Property", properties.length, true);
  });

  Vue.filter("searchResultsCount", length => {
    return pluralize("Result", length, true);
  });

  Vue.filter("totalSize", properties => {
    const types = properties.map(property => {
      const {
        office,
        retail,
        industrial,
        multifamily,
        hotel,
        lab,
        healthCare,
        seniorHousing,
        dataCenter,
        studentHousing,
        selfStorage,
        parking
      } = property;

      return {
        office,
        retail,
        industrial,
        multifamily,
        hotel,
        lab,
        healthCare,
        seniorHousing,
        dataCenter,
        studentHousing,
        selfStorage,
        parking
      };
    });
    const sizes = {
      office: _.sumBy(types, "office"),
      retail: _.sumBy(types, "retail"),
      industrial: _.sumBy(types, "industrial"),
      multifamily: _.sumBy(types, "multifamily"),
      hotel: _.sumBy(types, "hotel"),
      lab: _.sumBy(types, "lab"),
      healthCare: _.sumBy(types, "healthCare"),
      seniorHousing: _.sumBy(types, "seniorHousing"),
      dataCenter: _.sumBy(types, "dataCenter"),
      studentHousing: _.sumBy(types, "studentHousing"),
      selfStorage: _.sumBy(types, "selfStorage"),
      parking: _.sumBy(types, "parking")
    };
    const trimmedSizes = _.omitBy(sizes, function(v, k) {
      return v === 0 || v === null || v === "";
    });
    const hasActualUses = _.size(trimmedSizes) > 0;
    const combinedPossibleUses = properties.flatMap(
      property => property.possibleUses
    );
    const hasPossibleUses = combinedPossibleUses.length > 0;

    let actualUses, possibleUses;

    if (hasActualUses) {
      const pretty = _.map(trimmedSizes, function(v, k) {
        switch (k) {
          case "office":
          case "retail":
          case "industrial":
          case "lab":
          case "healthCare":
          case "dataCenter":
            return `${currencyAmount(v)} SF ${camelToSentence(k)}`;
          case "multifamily":
          case "seniorHousing":
          case "studentHousing":
          case "selfStorage":
            return `${currencyAmount(v)} ${camelToSentence(k)} Units`;
          case "hotel":
            return `${currencyAmount(v)} ${camelToSentence(k)} Keys`;
          case "parking":
            return `${currencyAmount(v)} ${camelToSentence(k)} Spaces`;
        }
      });

      actualUses = pretty.join(", ");
    }

    if (hasPossibleUses) {
      const uniquePossibleUses = _.uniqBy(combinedPossibleUses, "type");

      possibleUses = `Possible uses: ${sortedTypeLabels(
        store.state.currentUser,
        uniquePossibleUses
      )}`;
    }

    if (hasActualUses && hasPossibleUses) {
      return `${actualUses}; ${possibleUses}`;
    } else if (hasPossibleUses) {
      return possibleUses;
    } else {
      return actualUses;
    }
  });

  Vue.filter("totalLeaseSize", record => {
    const { office, retail, industrial, lab, healthCare, dataCenter } = record;
    const sizes = { office, retail, industrial, lab, healthCare, dataCenter };
    const trimmedSizes = _.omitBy(sizes, function(v, k) {
      return v === 0 || v === null || v === "";
    });
    const pretty = _.map(trimmedSizes, function(v, k) {
      switch (k) {
        default:
          return `${currencyAmount(v)} SF ${_.capitalize(k)}`;
      }
    });

    return pretty.join(", ");
  });

  Vue.filter("leaseSizeSum", record => {
    const { office, retail, industrial, lab, healthCare, dataCenter } = record;
    const sizes = { office, retail, industrial, lab, healthCare, dataCenter };
    const trimmedSizes = _.omitBy(sizes, function(v, k) {
      return v === 0 || v === null || v === "";
    });

    return _.reduce(
      trimmedSizes,
      function(sum, use) {
        return sum + _.toNumber(use);
      },
      0
    );
  });

  Vue.filter("regionLabels", (regions, limit = null) => {
    const rawNames = _.uniq(regions.map(region => region.name));
    const overflow = limit ? rawNames.length - limit : null;
    const showOverflow = overflow && overflow > 0;

    if (limit && showOverflow) {
      return _.take(rawNames, limit).join(", ") + ` +${overflow} more`;
    } else if (limit) {
      return _.take(rawNames, limit).join(", ");
    } else {
      return rawNames.join(", ");
    }
  });

  Vue.filter("sortedTypeLabels", (types, limit = null) => {
    return sortedTypeLabels(store.state.currentUser, types, limit);
  });

  Vue.filter("initials", name => {
    const words = _.words(name, /[\w]+/g);

    if (words.length > 3) {
      return _.head(_.head(words));
    } else {
      return words.map(w => _.head(w)).join("");
    }
  });

  Vue.filter("companyInitials", name => {
    const words = _.words(name);
    const firstWord = _.head(words);

    return _.head(firstWord);
  });

  new Vue({
    el,
    store,
    router,
    render: h => h(App)
  });
});
