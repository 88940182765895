<template>
  <tr>
    <td class="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
      <div class="flex items-center space-x-3 lg:pl-2">
        <safezone-color-dot :content="content" style-type="dot" />
        <a href="" @click.prevent="viewDetails" class="truncate hover:text-gray-600">
          <span>{{ content.name }} <span class="text-gray-500 font-normal">{{ content.notes }}</span> </span>
        </a>
      </div>
    </td>
    <td v-observe-visibility="{ callback: duplicateCheck, once: true }" class="hidden lg:table-cell px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
      <button v-if="duplicatesChecked && filteredDuplicateDeals.length > 0" @click="viewDuplicates" type="button" v-tooltip="'Review potential crowdsourced matches to merge into.'" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
        <svg class="mr-1.5 h-3 w-3 text-green-400" fill="currentColor" viewBox="0 0 20 20">
          <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
          <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
        </svg>
        <span>Review matches</span>
      </button>
      <button v-else-if="duplicatesChecked" @click="confirmDeclassify" type="button" v-tooltip="'No matching deals on Tower Hunt yet. Declassify this deal to earn revenue and improve Tower Hunt. We\'ll ask you to confirm.'" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-orange-100 text-orange-800">
        <svg class="mr-1.5 h-3 w-3 text-orange-400" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
          <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
        </svg>
        <span>Declassify</span>
      </button>
      <span v-else>Checking...</span>
    </td>
    <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-500 font-medium">
      <div class="flex flex-shrink-0 items-center space-x-2">
        <button @click="chooseDiscussion" type="button" class="mr-1 flex-shrink-0 bg-white inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span class="sr-only">Add team member</span>
          <!-- Heroicon name: solid/plus -->
          <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
          </svg>
        </button>

        <div class="flex flex-shrink-0 -space-x-1" style="width: 84px;">
          <avatar-photo v-for="user in slicedSharingUsers" :key="user.token" :person="user" v-tooltip="userTooltip(user)" border-class="ring-2 ring-white" circle-size="6" text-size="xs" />
        </div>

        <span v-if="sharingOverflow" v-tooltip="overflowTooltip" class="flex-shrink-0 ml-1 text-xs leading-5 font-medium">+{{ sharingOverflow }}</span>
      </div>
    </td>
    <td class="hidden lg:table-cell px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500 text-right">
      {{ content.updatedAt | moment("MMMM D, YYYY") }}
    </td>
    <td class="pr-6">
      <safezone-menu :content="content" />
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AvatarPhoto from "../avatar-photo";
import DealCard from "../cards/deal";
import DiscussionSelector from "../discussion-selector";
import DuplicateDealCheck from "../../views/onboarding/deal/duplicate-deal-check.vue";
import IntelCard from "../cards/market-intel";
import SafezoneColorDot from "../safezone-color-dot.vue";
import SafezoneMenu from "./safezone-menu";
import Swal from "sweetalert2";
import api from "../../api";
import declassifyItemOptions from "../../sweet-alert-safezone-declassify-options";
import namedPostOptions from "../../sweet-alert-named-post-options";

export default {
  components: { AvatarPhoto, SafezoneColorDot, SafezoneMenu },
  props: ["content", "sharing"],
  data() {
    return {
      potentialDuplicateDeals: [],
      duplicatesChecked: false
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "signedIn"]),
    ...mapState(["modal"]),
    filteredDuplicateDeals() {
      if (
        this.content.newsfeedType === "deal" &&
        this.potentialDuplicateDeals.length > 0
      ) {
        return _.differenceBy(
          this.potentialDuplicateDeals,
          [this.content],
          "token"
        );
      } else {
        return [];
      }
    },
    slicedSharingUsers() {
      return _.slice(this.sharing, 0, 4);
    },
    sharingOverflow() {
      const overflow = this.sharing.length - 4;

      return overflow > 0 ? overflow : null;
    },
    overflowTooltip() {
      const dropped = _.drop(this.sharing, 4);

      return dropped.map(user => this.userTooltip(user)).join(", ");
    },
    marketingListDeal() {
      return _.get(this.content, "source", null) === "direct";
    },
    liveDeal() {
      return _.includes(
        ["live", "awarded"],
        _.get(this.content, "state", null)
      );
    },
    activeDeal() {
      return _.includes(
        ["draft", "live", "awarded"],
        _.get(this.content, "state", null)
      );
    }
  },
  methods: {
    duplicateCheck(isVisible, entry) {
      if (isVisible) {
        switch (this.content.newsfeedType) {
          case "deal":
            api
              .post(`duplicate_deals`, { filters: this.content })
              .then(json => {
                this.potentialDuplicateDeals = json.data;
                this.duplicatesChecked = true;
              });
            break;
          case "intel":
            this.duplicatesChecked = true;
            break;
          default:
            return;
        }
      }
    },
    confirmDeclassify() {
      Swal.fire(declassifyItemOptions).then(result => {
        if (result.value) {
          switch (this.content.newsfeedType) {
            case "deal":
              this.$store.dispatch("declassifySafezoneContent", {
                content: this.content
              });
              break;
            case "intel":
              Swal.fire(namedPostOptions).then(result => {
                if (result.value) {
                  this.$store.dispatch("declassifySafezoneContent", {
                    content: this.content,
                    anonymous: true
                  });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  this.$store.dispatch("declassifySafezoneContent", {
                    content: this.content,
                    anonymous: false
                  });
                }
              });
              break;
            default:
              return;
          }
        }
      });
    },
    viewDuplicates() {
      this.$store.commit("openModal", {
        component: DuplicateDealCheck,
        props: {
          deal: this.content,
          localDeals: this.potentialDuplicateDeals
        }
      });
    },
    userTooltip(user) {
      const name = this.$options.filters.capitalize(user.name);
      const role = _.capitalize(
        this.$options.filters.industryRoleAlias(
          user.industryRole,
          user.industrySubRole,
          user.token
        )
      );

      if (user.company) {
        return `${name} (${role} at ${user.company})`;
      } else {
        return `${name} (${role})`;
      }
    },
    chooseDiscussion() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      this.$store.commit("openModal", {
        component: DiscussionSelector,
        props: {
          content: this.content,
          contentType: this.content.newsfeedType
        },
        afterClose: currentModal
      });
    },
    viewDetails() {
      switch (this.content.newsfeedType) {
        case "deal":
          if (this.activeDeal && this.marketingListDeal && this.content.dealTeam) {
            const marketingPage =
              this.content.state === "draft" ? "overview" : "marketing-list";

            this.$router.push({
              path: `/deal_offerings/${this.content.urlHandle || this.content.token}/${marketingPage}`
            });
          } else if (this.liveDeal && this.marketingListDeal && this.content.marketingReadAuthorized) {
            const marketingOverviewPath = `/deal_offerings/${this.content.urlHandle || this.content.token}/overview`;

            window.open(marketingOverviewPath, "_blank");
          } else {
            this.$store.commit("openModal", {
              component: DealCard,
              props: {
                dealObject: this.content,
                property: null
              }
            });
          }
          break;
        case "intel":
          this.$store.commit("openModal", {
            component: IntelCard,
            props: {
              postObject: this.content,
              property: null
            }
          });
          break;
        default:
          return;
      }
    }
  }
};
</script>
