export default {
  principal: [
    {
      order: 1,
      name: "Buying/Selling Properties",
      value: "Buy Equity",
      description:
        "Sourcing property acquisitions and/or executing dispositions."
    },
    {
      order: 2,
      name: "Lending",
      value: "Originate Debt",
      description: "Providing financing."
    },
    {
      order: 3,
      name: "Joint Ventures",
      value: "Recap Equity",
      description: "Funding deals as an LP and/or sourcing LP equity."
    },
    {
      order: 4,
      name: "Buying/Selling Loans",
      value: "Buy Debt",
      description:
        "Acquiring and/or selling the lender position of existing loans."
    },
    {
      order: 5,
      name: "Leasing",
      value: "Lease",
      description: "Leasing space as landlord and/or tenant."
    }
  ],
  broker: [
    {
      order: 1,
      name: "Equity Sales",
      value: "Buy Equity",
      description:
        "Brokering property sales on behalf of sellers and/or buyers."
    },
    {
      order: 2,
      name: "Financings",
      value: "Originate Debt",
      description: "Sourcing financing on behalf of borrowers."
    },
    {
      order: 3,
      name: "Equity Recaps",
      value: "Recap Equity",
      description: "Sourcing JV equity on behalf of GPs and/or LPs."
    },
    {
      order: 4,
      name: "Loan Sales",
      value: "Buy Debt",
      description: "Brokering loan sales on behalf of lenders."
    },
    {
      order: 5,
      name: "Leasing",
      value: "Lease",
      description: "Brokering leases on behalf of landlords and/or tenants."
    }
  ],
  observer: [
    { order: 1, name: "Property Sales", value: "Buy Equity" },
    { order: 2, name: "Financings", value: "Originate Debt" },
    { order: 3, name: "Joint Ventures", value: "Recap Equity" },
    { order: 4, name: "Loan Sales", value: "Buy Debt" },
    { order: 5, name: "Leasing", value: "Lease" }
  ]
};
