<template>
  <div class="px-4 mt-6 sm:px-6 lg:px-8">
    <ul v-if="cards.data.length > 0" class="mt-3 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <multi-content-preview-card
        v-for="(card, index) in cards.data"
        :key="`${card.content.token}-${index}`"
        :content="card.content"
        :attached-bounty="card.attachedBounty"
      />

      <infinite-loading
        v-if="loaded && !endOfFeed"
        :identifier="currentUser.token"
        @infinite="incrementCards"
      />
    </ul>

    <div v-else>
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="text-center">
          <h1 class="text-base leading-6 font-semibold text-indigo-600 tracking-wide uppercase">My Requests</h1>
          <p class="mt-1 text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">Get help from local experts</p>
          <p class="max-w-xl mt-5 mx-auto text-xl leading-7 text-gray-500">Manage your own requests here. <router-link to="/product/bounties" class="underline">Learn more</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import MultiContentPreviewCard from "../components/cards/multi-content-preview-card";
import api from "../api";

export default {
  components: {
    MultiContentPreviewCard,
    InfiniteLoading
  },
  data() {
    return {
      cards: {
        data: [],
        pagy: null
      },
      busy: false,
      endOfFeed: false,
      loaded: false
    };
  },
  computed: {
    ...mapState(["currentUser", "adminMode"]),
    ...mapGetters(["isAdmin"]),
    pagination() {
      return this.cards.pagy;
    }
  },
  mounted() {
    this.fetchCards();
  },
  methods: {
    fetchCards() {
      this.$store.commit("isLoading");
      api.get(`my_help_requests`).then(json => {
        this.cards = json.data;
        this.loaded = true;
        this.$store.commit("doneLoading");
      });
    },
    extendCards({ data, pagy }) {
      this.cards.data = this.cards.data.concat(data);
      this.cards.pagy = pagy;
    },
    incrementCards($state) {
      if (this.pagination) {
        const { count, to } = this.pagination;

        if (count === 0) {
          this.endOfFeed = true;
        } else if (to < count) {
          const cleanUrl = _.drop(this.pagination.next_url, 8).join("");

          this.busy = true;

          api.get(cleanUrl).then(json => {
            this.extendCards(json.data);
            $state.loaded();
            this.busy = false;
          });
        } else {
          this.endOfFeed = true;
          $state.complete();
        }
      }
    }
  }
};
</script>
