<template>
  <div class="bg-gray-50 overflow-y-auto">
    <nav @keydown.escape="open = false" class="bg-indigo-700">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <router-link to="/">
                <img class="h-10 w-auto sm:h-12" src="https://assets.towerhunt.com/site/Transparent_White_T0.png" alt="Tower Hunt logo" />
              </router-link>
            </div>
          </div>
          <div class="hidden md:block">
            <div v-if="signedIn" class="ml-4 flex items-center md:ml-6">
              <notifications-menu />
              <notepad-button />
              <avatar-menu />
            </div>
            <div v-else class="ml-4 flex items-center md:ml-6">
              <button
                @click="signIn"
                type="button"
                class="px-3 py-2 rounded-md text-sm font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
              >
                Sign in
              </button>
              <span class="ml-3 inline-flex rounded-md shadow-sm">
                <button
                  @click="joinNow"
                  type="button"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:ring-indigo active:bg-indigo-200"
                >
                  Join now
                </button>
              </span>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <button @click="toggleMenu" class="inline-flex items-center justify-center p-2 rounded-md text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 focus:text-white" x-bind:aria-label="open ? 'Close main menu' : 'Main menu'" x-bind:aria-expanded="open">
              <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path :class="{'hidden': open, 'inline-flex': !open }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                <path :class="{'hidden': !open, 'inline-flex': open }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div :class="{'block': open, 'hidden': !open}" class="z-20 md:hidden">
        <div v-if="signedIn" class="pt-4 pb-3 border-t border-gray-700">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <avatar-photo :person="currentUser" circle-size="10" text-size="md" />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium leading-none text-white">{{ currentUser.name }}</div>
              <div class="mt-1 text-sm font-medium leading-none text-indigo-300">{{ currentUser.email }}</div>
            </div>
          </div>
          <div class="mt-3 px-2" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <router-link to="/home" @click.native="open = false" class="block px-3 py-2 rounded-md text-base font-medium text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600" role="menuitem">My Dashboard</router-link>
            <router-link to="/home/settings" @click.native="open = false" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600" role="menuitem">Settings</router-link>
            <a href="#" @click.prevent="signOut" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600" role="menuitem">Sign out</a>
          </div>
        </div>
        <div v-else class="pt-4 pb-3 border-t border-gray-700">
          <div class="mt-3 px-2" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <router-link to="/start/1" @click.native="open = false" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600" role="menuitem">Join now</router-link>
            <router-link to="/sign-in" @click.native="open = false" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600" role="menuitem">Sign in</router-link>
          </div>
        </div>
      </div>
    </nav>

    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col sm:align-center">
        <h1 class="text-5xl font-extrabold text-gray-900 sm:text-center">Tools to help you hunt</h1>
        <p class="mt-5 text-xl text-gray-500 sm:text-center">Casual market tracking is free. Upgrade to access hard-to-find info, build a private database, and view the Strategic Map.</p>
        <div class="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
          <button @click="updateBilling('monthly')" type="button" :class="billingFrequency === 'monthly' ? 'bg-white border-gray-200 text-gray-900' : 'mr-0.5 border border-transparent text-gray-700'" class="relative w-1/2 rounded-md shadow-sm py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8">Monthly billing</button>
          <button @click="updateBilling('annual')" type="button" :class="billingFrequency === 'annual' ? 'bg-white border-gray-200 text-gray-900' : 'ml-0.5 border border-transparent text-gray-700'" class="relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8">Yearly billing</button>
        </div>
      </div>
      <div class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
        <div class="border border-gray-200 bg-white rounded-lg shadow-sm divide-y divide-gray-200">
          <div class="p-6">
            <h2 class="text-lg leading-6 font-medium text-gray-900">Gatherer</h2>
            <p class="mt-4 text-sm text-gray-500">Building blocks for market awareness</p>
            <p class="mt-8">
              <span class="text-4xl font-extrabold text-gray-900">Free</span>
            </p>
            <div v-if="signedIn && existingTier === 'gatherer'" class="mt-8 block w-full border-2 border-indigo-500 rounded-md py-2 text-sm font-semibold text-indigo-500 text-center">Your current tier</div>
            <a v-else @click.prevent="updateProductTier('gatherer')" href="" class="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900">{{ existingTier === "gatherer" ? "Get started" : "Downgrade" }}</a>
          </div>
          <div class="pt-6 pb-8 px-6">
            <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
            <ul role="list" class="mt-6 space-y-4">
              <li class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Personalized market newsfeed. No ads.</span>
              </li>

              <li class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Intel heat map.</span>
              </li>

              <li class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Public record intel.</span>
              </li>

              <li class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">2 months of Safezone history. Unlimited watchlist properties.</span>
              </li>

              <li class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Unlimited teams and team members.</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="border border-gray-200 bg-white rounded-lg shadow-sm divide-y divide-gray-200">
          <div class="p-6">
            <h2 class="text-lg leading-6 font-medium text-gray-900">Insider</h2>
            <p class="mt-4 text-sm text-gray-500">Premium intel. Pay only for what you use.</p>
            <p class="mt-8">
              <span class="text-4xl font-extrabold text-gray-900">Up to $0.25</span>
              <span class="text-base font-medium text-gray-500">per intel</span>
            </p>
            <div v-if="signedIn && existingTier === 'insider'" class="mt-8 block w-full border-2 border-indigo-500 rounded-md py-2 text-sm font-semibold text-indigo-500 text-center">Your current tier</div>
            <a v-else @click.prevent="updateProductTier('insider')" href="" class="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900">Get started</a>
          </div>
          <div class="pt-6 pb-8 px-6">
            <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase">Everything in Gatherer, and:</h3>
            <ul role="list" class="mt-6 space-y-4">
              <li class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Access Premium Insights. Market rates.</span>
              </li>

              <li class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Unlock specific datapoints.</span>
              </li>

              <li class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Export to Excel.</span>
              </li>

              <li class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Billed at the end of the month.</span>
              </li>

              <li class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Earn money for contributing. 70/30 revenue split.</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="border border-gray-200 bg-white rounded-lg shadow-sm divide-y divide-gray-200">
          <div class="p-6">
            <h2 class="text-lg leading-6 font-medium text-gray-900">Hunter</h2>
            <p class="mt-4 text-sm text-gray-500">View all your intel on a game-changing map.</p>
            <p class="mt-8">
              <span class="text-4xl font-extrabold text-gray-900">
                <template v-if="billingFrequency === 'monthly'">$29</template>
                <template v-else-if="billingFrequency === 'annual'">$26</template>
              </span>
              <span class="text-base font-medium text-gray-500">per user /mo</span>
            </p>
            <div v-if="signedIn && existingTier === 'hunter'" class="mt-8 block w-full border-2 border-indigo-500 rounded-md py-2 text-sm font-semibold text-indigo-500 text-center">Your current tier</div>
            <a v-else @click.prevent="updateProductTier('hunter')" href="" class="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900">{{ signedIn ? "Buy Hunter" : "Get started" }}</a>
          </div>
          <div class="pt-6 pb-8 px-6">
            <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase">Everything in Insider, and:</h3>
            <ul role="list" class="mt-6 space-y-4">
              <li class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Strategic intel map.</span>
              </li>

              <li class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Unlimited Safezone history.</span>
              </li>

              <li class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Property watchlist email alerts.</span>
              </li>

              <li class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">15% Premium Insight discount.</span>
              </li>

              <li class="flex space-x-3">
                <!-- Heroicon name: solid/check -->
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">80/20 revenue split.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <feature-comparison />
    </div>

    <marketing-footer :compact="true" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AvatarMenu from "../components/avatar-menu";
import AvatarPhoto from "../components/avatar-photo";
import FeatureComparison from "../components/feature-comparison.vue";
import MarketingFooter from "../components/marketing-footer.vue";
import NotepadButton from "../components/notepad-button";
import NotificationsMenu from "../components/notifications-menu";
import Subscribe from "../components/purchaseables/monthly-subscriptions/subscribe.vue";

export default {
  components: {
    AvatarMenu,
    AvatarPhoto,
    FeatureComparison,
    MarketingFooter,
    NotepadButton,
    NotificationsMenu
  },
  data() {
    return {
      open: false,
      productTier: "gatherer",
      billingFrequency: "monthly"
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    ...mapGetters(["signedIn"]),
    existingTier() {
      return _.get(this.currentUser, "productTier", "gatherer");
    }
  },
  watch: {
    currentUser: {
      handler(user, oldUser) {
        if (user.signedIn) {
          this.mountContactInfo();
        }
      }
    }
  },
  mounted() {
    if (this.currentUser.signedIn) {
      this.mountContactInfo();
    }

    document.title = "Pricing | Tower Hunt";
  },
  methods: {
    signIn() {
      this.$router.push({ path: "/sign-in" });
    },
    joinNow() {
      this.$router.push({ path: "/start/1" });
    },
    toggleMenu() {
      this.open = !this.open;
    },
    updateBilling(newFrequency) {
      this.billingFrequency = newFrequency;
    },
    mountContactInfo() {
      const { productTier, billingFrequency } = this.currentUser;

      this.productTier = productTier;
      this.billingFrequency = billingFrequency || "monthly";
    },
    updateProductTier(newTier) {
      this.productTier = newTier;

      if (this.signedIn) {
        const unchangedTier = this.productTier === this.currentUser.productTier;
        const unchangedFrequency =
          this.productTier !== "hunter" ||
          this.billingFrequency === this.currentUser.billingFrequency;

        if (unchangedTier && unchangedFrequency) {
          this.$store.dispatch("flash", "Product tier is already set.");
        } else if (this.productTier === "insider") {
          this.$store.dispatch("flash", "Coming soon!");
          this.mountContactInfo();
        } else {
          this.$store.commit("openModal", {
            component: Subscribe,
            props: {
              newPlan: this.productTier,
              billingFrequency: this.billingFrequency
            }
          });
        }
      } else {
        this.$router.push({ path: "/start/1" });
      }
    }
  }
};
</script>
