<template>
  <div class="sm:flex sm:justify-between">
    <div class="flex items-center">
      <div class="text-sm">
        <p id="duplicate-deal-0-label" class="font-medium text-gray-900">
          {{ deal.name }} ({{ deal.properties | summarizedLocation }})
        </p>
        <div id="duplicate-deal-0-description-0" class="text-gray-500">
          <p class="sm:inline">
            <template v-if="liveDeal">
              <span class="mr-1 inline-flex items-center justify-center h-2 w-2 rounded-full bg-red-400"></span>
              {{ deal.state | capitalize }}
            </template>
            <time v-else :datetime="deal.newsfeedSortDate">{{ sortDate }}</time>
          </p>
          <span class="hidden sm:inline sm:mx-1" aria-hidden="true">&middot;</span>
          <template v-if="deal.type !== 'Lease'">
            <p class="sm:inline">
              {{ deal.risks | sortedTypeLabels }}
            </p>
            <span class="hidden sm:inline sm:mx-1" aria-hidden="true">&middot;</span>
          </template>
          <p class="sm:inline">{{ deal.type | dealTypeAlias(deal.jointVentureRoles) }}</p>
        </div>
        <div id="duplicate-deal-0-description-1" class="text-gray-500">
          <template v-if="deal.type === 'Lease'">
            <p class="sm:inline">{{ deal.properties | regionLabels(3) }}</p>
            <span class="hidden sm:inline sm:mx-1" aria-hidden="true">&middot;</span>
          </template>
          <p class="sm:inline">{{ deal.properties | totalSize }}</p>
        </div>
        <div v-if="notes !== ''" id="duplicate-deal-0-description-2" v-html="notes" class="mt-2 text-gray-500 whitespace-pre-line dont-break-out line-clamp-3" />
      </div>
    </div>
    <div id="duplicate-deal-0-description-3" class="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
      <div class="font-medium text-gray-900">
        <template v-if="deal.type === 'Lease'">{{ deal | totalLeaseSize }}</template>
        <template v-else-if="deal.totalValue">
          <template v-if="!deal.closeDate">~</template>{{ deal.totalValue | largeDollar }}
        </template>
      </div>
      <div v-if="deal.totalValue && deal.goingInPerUnit" class="ml-1 text-gray-500 sm:ml-0">{{ deal | perUnitPrice }}</div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";

export default {
  props: ["deal"],
  computed: {
    notes() {
      return (
        _.get(this.deal, "notes", "") ||
        _.get(this.deal, "secondaryHeadline", "")
      );
    },
    sortDate() {
      return moment(this.deal.newsfeedSortDate).format("MMM YYYY");
    },
    liveDeal() {
      return _.includes(["live", "awarded"], this.deal.state);
    }
  }
};
</script>
