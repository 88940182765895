<template>
  <div
    class="h-screen flex overflow-hidden bg-gray-100"
    @keydown.escape="sidebarOpen = false"
  >
    <!-- Off-canvas menu for mobile -->
    <div class="md:hidden">
      <div
        class="fixed inset-0 z-30 bg-gray-600 opacity-0 pointer-events-none transition-opacity ease-linear duration-300"
        :class="{
          'opacity-75 pointer-events-auto': sidebarOpen,
          'opacity-0 pointer-events-none': !sidebarOpen
        }"
        @click="sidebarOpen = false"
      />
      <div
        class="fixed inset-y-0 left-0 flex flex-col z-40 max-w-xs w-full bg-gray-800 transform ease-in-out duration-300 "
        :class="{
          'translate-x-0': sidebarOpen,
          '-translate-x-full': !sidebarOpen
        }"
      >
        <div class="absolute top-0 right-0 -mr-14 p-1">
          <button
            v-show="sidebarOpen"
            class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
            @click="sidebarOpen = false"
          >
            <svg
              class="h-6 w-6 text-white"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <router-link @click.native="sidebarOpen = false" to="/home" class="flex-shrink-0 flex items-center h-16 px-4 bg-gray-900">
          <img
            class="h-10 w-auto mr-3"
            src="https://assets.towerhunt.com/site/Transparent_T0.png"
            alt="Tower Hunt"
          />
          <span class="text-white font-medium">Tower Hunt</span>
        </router-link>
        <div class="flex-1 h-0 overflow-y-auto">
          <nav class="px-2 py-4">
            <div>
              <router-link
                @click.native="sidebarOpen = false"
                to="/home"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  @click="navigate"
                  :href="href"
                  :class="{ 'bg-gray-900': isActive }"
                  class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-gray-300 focus:outline-none focus:bg-gray-700"
                >
                  <svg
                    class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                  <span class="truncate">
                    Dashboard
                  </span>
                  <!-- <span class="ml-auto inline-block py-0.5 px-2 text-xs leading-4 rounded-full text-white bg-orange-400">
                    New
                  </span> -->
                </a>
              </router-link>
              <router-link
                @click.native="sidebarOpen = false"
                to="/newsfeed"
                v-slot="{ href, navigate, isExactActive }"
              >
                <a
                  @click="navigate"
                  :href="href"
                  :class="{ 'bg-gray-900': isExactActive }"
                  class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-gray-300 focus:outline-none focus:bg-gray-700"
                >
                  <svg
                    class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                    />
                  </svg>
                  <span class="truncate">
                    Newsfeed
                  </span>
                  <span v-if="newsfeedUnreadCount > 0" v-tooltip="'New intel and/or deals'" class="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-white bg-green-400">
                    {{ newsfeedUnreadCount | cleanUnreadCount }}
                  </span>
                </a>
              </router-link>
              <template v-if="expandedFeatures">
                <router-link
                  @click.native="sidebarOpen = false"
                  to="/market/featured"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-gray-900': isActive }"
                    class="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <svg
                      class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
                    </svg>
                    <span class="truncate">
                      Premium Insights
                    </span>
                  </a>
                </router-link>
                <router-link
                  @click.native="sidebarOpen = false"
                  to="/bounties/active"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-gray-900': isActive }"
                    class="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <svg
                      class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span class="truncate">
                      Bounties
                    </span>
                  </a>
                </router-link>
                <router-link
                  v-if="shouldDisplayOffMarket"
                  @click.native="sidebarOpen = false"
                  to="/off-market/packs"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-gray-900': isActive }"
                    class="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <svg
                      class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
                    </svg>
                    <span class="truncate">
                      Off-Market
                    </span>
                  </a>
                </router-link>
              </template>
              <a
                @click.prevent="toggleExpansion('expandedFeatures')"
                class="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 cursor-pointer"
              >
                <svg
                  class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                </svg>
                <span>
                  {{ expandedFeatures ? "Less" : "More" }}
                </span>
              </a>
            </div>

            <div class="mt-8">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <button
                    @click="toggleExpansion('expandedWatchlist')"
                    class="p-1 text-gray-500 rounded-md hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                  >
                    <svg
                      v-if="expandedWatchlist"
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- down -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                    <svg
                      v-else
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- right -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                  <h3 class="pl-1 pr-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider">
                    Watchlist
                  </h3>
                  <span class="ml-auto inline-block py-0.5 px-2 text-xs leading-4 rounded-full text-white bg-cyan-400">
                    Beta
                  </span>
                </div>
                <button
                  v-tooltip="'Add a property'"
                  @click="newProperty"
                  class="p-1 text-gray-500 rounded-full hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                >
                  <svg
                    class="h-5 w-5"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
              <div v-if="expandedWatchlist" class="mt-1">
                <router-link
                  v-if="watchlistProperties.length > 1"
                  :to="`/map?layer=watchlist`"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-gray-900': isExactActive }"
                    class="group flex items-center justify-between h-10 pl-3 pr-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <div class="flex items-center">
                      <svg fill="currentColor" v-tooltip="'Private'" viewBox="0 0 20 20" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clip-rule="evenodd" />
                      </svg>
                      <span class="text-green-600">Map view</span>
                    </div>
                  </a>
                </router-link>

                <router-link
                  @click.native="sidebarOpen = false"
                  v-for="propertyFollowing in watchlistProperties"
                  :key="propertyFollowing.id"
                  :to="`/property-watchlist/${propertyFollowing.id}/`"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-gray-900': isActive }"
                    class="group flex items-center justify-between h-10 pl-3 pr-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <div class="flex items-center">
                      <template v-if="propertyFollowing.proximityType === 'radius'">
                        <svg v-tooltip="'Radius'" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300" fill="currentColor" viewBox="0 0 512 512">
                          <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z" />
                        </svg>
                      </template>
                      <template v-else-if="propertyFollowing.proximityType === 'region'">
                        <svg v-tooltip="'Region'" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300" fill="currentColor" viewBox="0 0 448 512">
                          <path fill="currentColor" d="M384 352c-.35 0-.67.1-1.02.1l-39.2-65.32c5.07-9.17 8.22-19.56 8.22-30.78s-3.14-21.61-8.22-30.78l39.2-65.32c.35.01.67.1 1.02.1 35.35 0 64-28.65 64-64s-28.65-64-64-64c-23.63 0-44.04 12.95-55.12 32H119.12C108.04 44.95 87.63 32 64 32 28.65 32 0 60.65 0 96c0 23.63 12.95 44.04 32 55.12v209.75C12.95 371.96 0 392.37 0 416c0 35.35 28.65 64 64 64 23.63 0 44.04-12.95 55.12-32h209.75c11.09 19.05 31.49 32 55.12 32 35.35 0 64-28.65 64-64 .01-35.35-28.64-64-63.99-64zm-288 8.88V151.12A63.825 63.825 0 0 0 119.12 128h208.36l-38.46 64.1c-.35-.01-.67-.1-1.02-.1-35.35 0-64 28.65-64 64s28.65 64 64 64c.35 0 .67-.1 1.02-.1l38.46 64.1H119.12A63.748 63.748 0 0 0 96 360.88zM272 256c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zM400 96c0 8.82-7.18 16-16 16s-16-7.18-16-16 7.18-16 16-16 16 7.18 16 16zM64 80c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zM48 416c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zm336 16c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16z" />
                        </svg>
                      </template>
                      <template v-else-if="propertyFollowing.proximityType === 'competitiveSet'">
                        <svg v-tooltip="'Competitive Set'" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300" fill="currentColor" viewBox="0 0 20 20">
                          <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clip-rule="evenodd" />
                        </svg>
                      </template>
                      <span v-tooltip="`${propertyFollowing.propertyName}, ${propertyFollowing.cityState}`">{{ propertyFollowing.customName || propertyFollowing.propertyName | truncate(20) }}</span>
                    </div>

                    <span v-if="backchannelUnreadCountFor(propertyFollowing) > 0" v-tooltip="'Unreads'" class="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-white bg-green-400">
                      {{ backchannelUnreadCountFor(propertyFollowing) | cleanUnreadCount }}
                    </span>

                    <button
                      v-else
                      @click.stop.prevent="closeWatchlistProperty(propertyFollowing)"
                      class="p-1 text-gray-500 rounded-full hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                    >
                      <svg
                        class="h-4 w-4"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </a>
                </router-link>
                <div v-if="watchlistProperties.length === 0" class="mt-4 text-center">
                  <svg class="mx-auto h-8 w-8 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                  </svg>
                  <h3 class="mt-2 text-xs font-medium text-gray-500">No properties</h3>
                  <p class="mt-1 text-xs text-gray-600">
                    Get proximity alerts.
                  </p>
                  <div class="mt-3">
                    <button @click="newProperty" type="button" class="inline-flex items-center px-3 py-1.5 border border-transparent shadow-sm text-xs font-medium rounded-md text-gray-400 bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700">
                      <!-- Heroicon name: solid/plus -->
                      <svg class="-ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                      </svg>
                      Add to Watchlist
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-8">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <button
                    @click="toggleExpansion('expandedMarkets')"
                    class="p-1 text-gray-500 rounded-md hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                  >
                    <svg
                      v-if="expandedMarkets"
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- down -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                    <svg
                      v-else
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- right -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                  <h3 class="pl-1 pr-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider">
                    Strategic Map
                  </h3>
                  <span class="ml-auto inline-block py-0.5 px-2 text-xs leading-4 rounded-full text-white bg-cyan-400">
                    Beta
                  </span>
                </div>
                <button
                  v-tooltip="'Create a market'"
                  @click="newTarget"
                  class="p-1 text-gray-500 rounded-full hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                >
                  <svg
                    class="h-5 w-5"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
              <div class="mt-1">
                <router-link
                  @click.native="newsfeedMap"
                  :to="`/map`"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-gray-900': isExactActive }"
                    class="group flex items-center justify-between h-10 pl-3 pr-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <div class="flex items-center">
                      <svg fill="currentColor" v-tooltip="'Private'" viewBox="0 0 20 20" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300">
                        <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
                      </svg>
                      <span class="text-green-600">Newsfeed view</span>
                    </div>
                  </a>
                </router-link>

                <template v-if="expandedMarkets">
                  <router-link
                    @click.native="sidebarOpen = false"
                    v-for="target in targets"
                    :key="target.id"
                    :to="`/map?market=${target.token}`"
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <a
                      @click="navigate"
                      :href="href"
                      :class="{ 'bg-gray-900': isExactActive }"
                      class="group flex items-center justify-between h-10 pl-3 pr-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                    >
                      <div class="flex items-center">
                        <svg v-if="target.searchable" v-tooltip="'Public'" fill="currentColor" viewBox="0 0 20 20" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300">
                          <path d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clip-rule="evenodd" fill-rule="evenodd"></path>
                        </svg>
                        <svg v-else fill="currentColor" v-tooltip="'Private'" viewBox="0 0 20 20" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300">
                          <path d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" fill-rule="evenodd"></path>
                        </svg>
                        <span :class="target.revenueShare ? 'text-green-600' : ''" v-tooltip="target.revenueShare ? 'Revenue share' : ''">{{ target | marketName | truncate(20) }}</span>
                      </div>

                      <span v-if="marketUnreadCountFor(target) > 0" v-tooltip="'New local intel and/or deals'" class="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-white bg-green-400">
                        {{ marketUnreadCountFor(target) | cleanUnreadCount }}
                      </span>
                    </a>
                  </router-link>
                </template>
              </div>
            </div>

            <div class="mt-8">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <button
                    @click="toggleExpansion('expandedOfferings')"
                    class="p-1 text-gray-500 rounded-md hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                  >
                    <svg
                      v-if="expandedOfferings"
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- down -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                    <svg
                      v-else
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- right -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                  <h3
                    class="pl-1 pr-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    My Deal Offerings
                  </h3>
                </div>
                <button
                  v-tooltip="'Create a draft deal'"
                  @click="newActiveDeal"
                  class="p-1 text-gray-500 rounded-full hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                >
                  <svg
                    class="h-5 w-5"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
              <div v-if="expandedOfferings" class="mt-1">
                <router-link
                  @click.native="viewActiveDeal(deal)"
                  v-for="deal in activeDeals"
                  :key="deal.id"
                  to=""
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    class="group flex items-center h-10 pl-3 pr-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <div class="flex items-center">
                      <span v-if="liveDeal(deal)" v-tooltip="'Live'" class="mr-2 inline-flex h-2 w-2 flex-shrink-0 rounded-full bg-red-400"></span>
                      <svg v-else v-tooltip="'Draft'" fill="currentColor" viewBox="0 0 20 20" class="mr-2 h-3 w-3 flex-shrink-0 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300">
                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" fill-rule="evenodd"></path>
                      </svg>
                      <span v-tooltip="dealTypeTooltip(deal)">{{ deal.name | truncate(20) }}</span>
                    </div>

                    <span v-if="offeredDealUnreadCountFor(deal) > 0" v-tooltip="'Prospects need follow-up'" class="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-white bg-green-400">
                      {{ offeredDealUnreadCountFor(deal) | cleanUnreadCount }}
                    </span>
                  </a>
                </router-link>
                <div v-if="activeDeals.length === 0" class="mt-4 text-center">
                  <svg class="mx-auto h-8 w-8 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                  </svg>
                  <h3 class="mt-2 text-xs font-medium text-gray-500">No deals</h3>
                  <p class="mt-1 text-xs text-gray-600">
                    Market your deals.
                  </p>
                  <div class="mt-3">
                    <button @click="newActiveDeal" type="button" class="inline-flex items-center px-3 py-1.5 border border-transparent shadow-sm text-xs font-medium rounded-md text-gray-400 bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700">
                      <!-- Heroicon name: solid/plus -->
                      <svg class="-ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                      </svg>
                      New Deal
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-8">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <button
                    @click="toggleExpansion('expandedChannels')"
                    class="p-1 text-gray-500 rounded-md hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                  >
                    <svg
                      v-if="expandedChannels"
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- down -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                    <svg
                      v-else
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- right -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                  <h3
                    class="pl-1 pr-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    My Private Channels
                  </h3>
                </div>
                <button
                  v-tooltip="'Chat with one of your contacts'"
                  @click="createChannel"
                  class="p-1 text-gray-500 rounded-full hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                >
                  <svg
                    class="h-5 w-5"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
              <div v-if="expandedChannels" class="mt-1">
                <a
                  @click.prevent="openNotepad"
                  href=""
                  class="group flex items-center justify-between h-10 pl-3 pr-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  <div v-tooltip="'My Notes'" class="flex items-center">
                    <svg fill="currentColor" viewBox="0 0 20 20" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300">
                      <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                    </svg>
                    <span>{{ currentUser.name | truncate(20) }} <small class="ml-1 text-xs font-normal">you</small></span>
                  </div>
                </a>
                <router-link
                  @click.native="sidebarOpen = false"
                  v-for="channel in backchannels"
                  :key="channel.token"
                  :to="`/backchannel/${channel.token}`"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-gray-900': isExactActive }"
                    class="group flex items-center justify-between h-10 pl-3 pr-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <div class="flex items-center">
                      <svg v-tooltip="channel.teamName ? 'Team' : 'Private chat'" fill="currentColor" viewBox="0 0 20 20" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300">
                        <path v-if="channel.teamName" d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                        <path v-else d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd" fill-rule="evenodd"></path>
                      </svg>
                      <span v-tooltip="channelTooltip(channel)">{{ channel | channelName | truncate(20) }}</span>
                    </div>

                    <span v-if="backchannelUnreadCountFor(channel) > 0" v-tooltip="'Unreads'" class="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-white bg-green-400">
                      {{ backchannelUnreadCountFor(channel) | cleanUnreadCount }}
                    </span>

                    <button
                      v-else
                      @click.stop.prevent="closePrivateChannel(channel)"
                      class="p-1 text-gray-500 rounded-full hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                    >
                      <svg
                        class="h-4 w-4"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </a>
                </router-link>
                <div v-if="backchannels.length === 0" class="mt-4 text-center">
                  <svg class="mx-auto h-8 w-8 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                  </svg>
                  <h3 class="mt-2 text-xs font-medium text-gray-500">No channels</h3>
                  <p class="mt-1 text-xs text-gray-600">
                    Discuss intel privately.
                  </p>
                  <div class="mt-3">
                    <button @click="createChannel" type="button" class="inline-flex items-center px-3 py-1.5 border border-transparent shadow-sm text-xs font-medium rounded-md text-gray-400 bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700">
                      <!-- Heroicon name: solid/plus -->
                      <svg class="-ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                      </svg>
                      Create channel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <router-link to="/home" class="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
          <img
            class="h-10 w-auto mr-3"
            src="https://assets.towerhunt.com/site/Transparent_T0.png"
            alt="Tower Hunt"
          />
          <span class="text-white font-medium">Tower Hunt</span>
        </router-link>
        <div class="h-0 flex-1 flex flex-col overflow-y-auto">
          <!-- Sidebar component, swap this element with another sidebar if you like -->
          <nav class="flex-1 px-2 py-4 bg-gray-800">
            <div>
              <router-link to="/home" v-slot="{ href, navigate, isActive }">
                <a
                  @click="navigate"
                  :href="href"
                  :class="{ 'bg-gray-900': isActive }"
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                  <span class="truncate">
                    Dashboard
                  </span>
                  <!-- <span class="ml-auto inline-block py-0.5 px-2 text-xs leading-4 rounded-full text-white bg-orange-400">
                    New
                  </span> -->
                </a>
              </router-link>
              <router-link to="/newsfeed" v-slot="{ href, navigate, isExactActive }">
                <a
                  @click="navigate"
                  :href="href"
                  :class="{ 'bg-gray-900': isExactActive }"
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                    />
                  </svg>
                  <span class="truncate">
                    Newsfeed
                  </span>
                  <span v-if="newsfeedUnreadCount > 0" v-tooltip="'New intel and/or deals'" class="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-white bg-green-400">
                    {{ newsfeedUnreadCount | cleanUnreadCount }}
                  </span>
                </a>
              </router-link>
              <template v-if="expandedFeatures">
                <router-link to="/market/featured" v-slot="{ href, navigate, isActive }">
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-gray-900': isActive }"
                    class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <svg
                      class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
                    </svg>
                    <span class="truncate">
                      Premium Insights
                    </span>
                  </a>
                </router-link>
                <router-link to="/bounties/active" v-slot="{ href, navigate, isActive }">
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-gray-900': isActive }"
                    class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <svg
                      class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span class="truncate">
                      Bounties
                    </span>
                  </a>
                </router-link>
                <router-link
                  v-if="shouldDisplayOffMarket"
                  to="/off-market/packs"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-gray-900': isActive }"
                    class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <svg
                      class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
                    </svg>
                    <span class="truncate">
                      Off-Market
                    </span>
                  </a>
                </router-link>
              </template>
              <a
                @click.prevent="toggleExpansion('expandedFeatures')"
                class="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 cursor-pointer"
              >
                <svg
                  class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                </svg>
                <span>
                  {{ expandedFeatures ? "Less" : "More" }}
                </span>
              </a>
            </div>

            <div class="mt-8">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <button
                    @click="toggleExpansion('expandedWatchlist')"
                    class="p-1 text-gray-500 rounded-md hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                  >
                    <svg
                      v-if="expandedWatchlist"
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- down -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                    <svg
                      v-else
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- right -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                  <h3
                    v-tooltip="'Powerful tracking for your important properties.'"
                    class="pl-1 pr-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Watchlist
                  </h3>
                  <span class="ml-auto inline-block py-0.5 px-2 text-xs leading-4 rounded-full text-white bg-cyan-400">
                    Beta
                  </span>
                </div>
                <button
                  v-tooltip="'Add a property'"
                  @click="newProperty"
                  class="p-1 text-gray-500 rounded-full hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                >
                  <svg
                    class="h-5 w-5"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
              <div v-if="expandedWatchlist" class="mt-1">
                <router-link
                  v-if="watchlistProperties.length > 1"
                  :to="`/map?layer=watchlist`"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-gray-900': isExactActive }"
                    class="group flex items-center justify-between h-10 pl-3 pr-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <div class="flex items-center">
                      <svg fill="currentColor" v-tooltip="'Private'" viewBox="0 0 20 20" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clip-rule="evenodd" />
                      </svg>
                      <span class="text-green-600">Map view</span>
                    </div>
                  </a>
                </router-link>

                <router-link
                  v-for="propertyFollowing in watchlistProperties"
                  :key="propertyFollowing.id"
                  :to="`/property-watchlist/${propertyFollowing.id}/`"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    @click="navigate"
                    @mouseenter="hover(propertyFollowing.id)"
                    @mouseleave="vacate"
                    :href="href"
                    :class="{ 'bg-gray-900': isActive }"
                    class="group flex items-center justify-between h-10 pl-3 pr-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <div class="flex items-center">
                      <template v-if="propertyFollowing.proximityType === 'radius'">
                        <svg v-tooltip="'Radius'" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300" fill="currentColor" viewBox="0 0 512 512">
                          <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z" />
                        </svg>
                      </template>
                      <template v-else-if="propertyFollowing.proximityType === 'region'">
                        <svg v-tooltip="'Region'" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300" fill="currentColor" viewBox="0 0 448 512">
                          <path fill="currentColor" d="M384 352c-.35 0-.67.1-1.02.1l-39.2-65.32c5.07-9.17 8.22-19.56 8.22-30.78s-3.14-21.61-8.22-30.78l39.2-65.32c.35.01.67.1 1.02.1 35.35 0 64-28.65 64-64s-28.65-64-64-64c-23.63 0-44.04 12.95-55.12 32H119.12C108.04 44.95 87.63 32 64 32 28.65 32 0 60.65 0 96c0 23.63 12.95 44.04 32 55.12v209.75C12.95 371.96 0 392.37 0 416c0 35.35 28.65 64 64 64 23.63 0 44.04-12.95 55.12-32h209.75c11.09 19.05 31.49 32 55.12 32 35.35 0 64-28.65 64-64 .01-35.35-28.64-64-63.99-64zm-288 8.88V151.12A63.825 63.825 0 0 0 119.12 128h208.36l-38.46 64.1c-.35-.01-.67-.1-1.02-.1-35.35 0-64 28.65-64 64s28.65 64 64 64c.35 0 .67-.1 1.02-.1l38.46 64.1H119.12A63.748 63.748 0 0 0 96 360.88zM272 256c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zM400 96c0 8.82-7.18 16-16 16s-16-7.18-16-16 7.18-16 16-16 16 7.18 16 16zM64 80c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zM48 416c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zm336 16c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16z" />
                        </svg>
                      </template>
                      <template v-else-if="propertyFollowing.proximityType === 'competitiveSet'">
                        <svg v-tooltip="'Competitive Set'" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300" fill="currentColor" viewBox="0 0 20 20">
                          <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clip-rule="evenodd" />
                        </svg>
                      </template>
                      <span v-tooltip="`${propertyFollowing.propertyName}, ${propertyFollowing.cityState}`">{{ propertyFollowing.customName || propertyFollowing.propertyName | truncate(20) }}</span>
                    </div>

                    <span v-if="backchannelUnreadCountFor(propertyFollowing) > 0" v-tooltip="'Unread'" class="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-white bg-green-400">
                      {{ backchannelUnreadCountFor(propertyFollowing) | cleanUnreadCount }}
                    </span>

                    <button
                      v-else-if="propertyFollowing.id === hovering"
                      @click.stop.prevent="closeWatchlistProperty(propertyFollowing)"
                      class="p-1 text-gray-500 rounded-full hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                    >
                      <svg
                        class="h-4 w-4"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </a>
                </router-link>
                <div v-if="watchlistProperties.length === 0" class="mt-4 text-center">
                  <svg class="mx-auto h-8 w-8 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                  </svg>
                  <h3 class="mt-2 text-xs font-medium text-gray-500">No properties</h3>
                  <p class="mt-1 text-xs text-gray-600">
                    Get proximity alerts.
                  </p>
                  <div class="mt-3">
                    <button @click="newProperty" type="button" class="inline-flex items-center px-3 py-1.5 border border-transparent shadow-sm text-xs font-medium rounded-md text-gray-400 bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700">
                      <!-- Heroicon name: solid/plus -->
                      <svg class="-ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                      </svg>
                      Add to Watchlist
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-8">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <button
                    @click="toggleExpansion('expandedMarkets')"
                    class="p-1 text-gray-500 rounded-md hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                  >
                    <svg
                      v-if="expandedMarkets"
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- down -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                    <svg
                      v-else
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- right -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                  <h3
                    v-tooltip="'A personalized, contextually relevant map with all of your intel.'"
                    class="pl-1 pr-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Strategic Map
                  </h3>
                  <span class="ml-auto inline-block py-0.5 px-2 text-xs leading-4 rounded-full text-white bg-cyan-400">
                    Beta
                  </span>
                </div>
                <button
                  v-tooltip="'Create a market'"
                  @click="newTarget"
                  class="p-1 text-gray-500 rounded-full hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                >
                  <svg
                    class="h-5 w-5"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
              <div class="mt-1">
                <router-link
                  @click.native="newsfeedMap"
                  :to="`/map`"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-gray-900': isExactActive }"
                    class="group flex items-center justify-between h-10 pl-3 pr-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <div class="flex items-center">
                      <svg fill="currentColor" v-tooltip="'Private'" viewBox="0 0 20 20" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300">
                        <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
                      </svg>
                      <span class="text-green-600">Newsfeed view</span>
                    </div>
                  </a>
                </router-link>

                <template v-if="expandedMarkets">
                  <router-link
                    v-for="target in targets"
                    :key="target.id"
                    :to="`/map?market=${target.token}`"
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <a
                      @click="navigate"
                      @mouseenter="hover(target.id)"
                      @mouseleave="vacate"
                      :href="href"
                      :class="{ 'bg-gray-900': isExactActive }"
                      class="group flex items-center justify-between h-10 pl-3 pr-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                    >
                      <div class="flex items-center">
                        <svg v-if="target.searchable" v-tooltip="'Public'" fill="currentColor" viewBox="0 0 20 20" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300">
                          <path d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clip-rule="evenodd" fill-rule="evenodd"></path>
                        </svg>
                        <svg v-else fill="currentColor" v-tooltip="'Private'" viewBox="0 0 20 20" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300">
                          <path d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" fill-rule="evenodd"></path>
                        </svg>
                        <span :class="target.revenueShare ? 'text-green-600' : ''" v-tooltip="target.revenueShare ? 'Revenue share' : marketTooltip(target)">{{ target | marketName | truncate(20) }}</span>
                      </div>

                      <span v-if="marketUnreadCountFor(target) > 0" v-tooltip="'New local intel and/or deals'" class="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-white bg-green-400">
                        {{ marketUnreadCountFor(target) | cleanUnreadCount }}
                      </span>
                    </a>
                  </router-link>
                </template>
              </div>
            </div>

            <div class="mt-8">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <button
                    @click="toggleExpansion('expandedOfferings')"
                    class="p-1 text-gray-500 rounded-md hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                  >
                    <svg
                      v-if="expandedOfferings"
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- down -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                    <svg
                      v-else
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- right -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                  <h3
                    v-tooltip="'Market directly through Tower Hunt.'"
                    class="pl-1 pr-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    My Deal Offerings
                  </h3>
                </div>
                <button
                  v-tooltip="'Create a draft deal'"
                  @click="newActiveDeal"
                  class="p-1 text-gray-500 rounded-full hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                >
                  <svg
                    class="h-5 w-5"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
              <div v-if="expandedOfferings" class="mt-1">
                <router-link
                  @click.native="viewActiveDeal(deal)"
                  v-for="deal in activeDeals"
                  :key="deal.id"
                  to=""
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    class="group flex items-center h-10 pl-3 pr-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <div class="flex items-center">
                      <span v-if="liveDeal(deal)" v-tooltip="'Live'" class="mr-2 inline-flex h-2 w-2 flex-shrink-0 rounded-full bg-red-400"></span>
                      <svg v-else v-tooltip="'Draft'" fill="currentColor" viewBox="0 0 20 20" class="mr-2 h-3 w-3 flex-shrink-0 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300">
                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" fill-rule="evenodd"></path>
                      </svg>
                      <span v-tooltip="dealTypeTooltip(deal)">{{ deal.name | truncate(20) }}</span>
                    </div>

                    <span v-if="offeredDealUnreadCountFor(deal) > 0" v-tooltip="'Prospects need follow-up'" class="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-white bg-green-400">
                      {{ offeredDealUnreadCountFor(deal) | cleanUnreadCount }}
                    </span>
                  </a>
                </router-link>
                <div v-if="activeDeals.length === 0" class="mt-4 text-center">
                  <svg class="mx-auto h-8 w-8 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                  </svg>
                  <h3 class="mt-2 text-xs font-medium text-gray-500">No deals</h3>
                  <p class="mt-1 text-xs text-gray-600">
                    Market your deals.
                  </p>
                  <div class="mt-3">
                    <button @click="newActiveDeal" type="button" class="inline-flex items-center px-3 py-1.5 border border-transparent shadow-sm text-xs font-medium rounded-md text-gray-400 bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700">
                      <!-- Heroicon name: solid/plus -->
                      <svg class="-ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                      </svg>
                      New Deal
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-8">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <button
                    @click="toggleExpansion('expandedChannels')"
                    class="p-1 text-gray-500 rounded-md hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                  >
                    <svg
                      v-if="expandedChannels"
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- down -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                    <svg
                      v-else
                      class="h-5 w-5"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <!-- right -->
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                  <h3
                    v-tooltip="'1:1 private chat'"
                    class="pl-1 pr-3 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Private Channels
                  </h3>
                </div>
                <button
                  v-tooltip="'Chat with one of your contacts'"
                  @click="createChannel"
                  class="p-1 text-gray-500 rounded-full hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                >
                  <svg
                    class="h-5 w-5"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
              <div v-if="expandedChannels" class="mt-1">
                <a
                  @click.prevent="openNotepad"
                  href=""
                  class="group flex items-center justify-between h-10 pl-3 pr-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  <div v-tooltip="'My Notes'" class="flex items-center">
                    <svg fill="currentColor" viewBox="0 0 20 20" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300">
                      <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                    </svg>
                    <span>{{ currentUser.name | truncate(20) }} <small class="ml-1 text-xs font-normal">you</small></span>
                  </div>
                </a>
                <router-link
                  v-for="channel in backchannels"
                  :key="channel.token"
                  :to="`/backchannel/${channel.token}`"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    @mouseenter="hover(channel.token)"
                    @mouseleave="vacate"
                    :href="href"
                    :class="{ 'bg-gray-900': isExactActive }"
                    class="group flex items-center justify-between h-10 pl-3 pr-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    <div class="flex items-center">
                      <svg v-tooltip="channel.teamName ? 'Team' : 'Private chat'" fill="currentColor" viewBox="0 0 20 20" class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300">
                        <path v-if="channel.teamName" d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                        <path v-else d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd" fill-rule="evenodd"></path>
                      </svg>
                      <span v-tooltip="channelTooltip(channel)">{{ channel | channelName | truncate(20) }}</span>
                    </div>

                    <span v-if="backchannelUnreadCountFor(channel) > 0" v-tooltip="'Unreads'" class="ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-white bg-green-400">
                      {{ backchannelUnreadCountFor(channel) | cleanUnreadCount }}
                    </span>

                    <button
                      v-else-if="channel.token === hovering"
                      @click.stop.prevent="closePrivateChannel(channel)"
                      class="p-1 text-gray-500 rounded-full hover:text-gray-400 focus:outline-none focus:ring focus:text-gray-500"
                    >
                      <svg
                        class="h-4 w-4"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </a>
                </router-link>
                <div v-if="backchannels.length === 0" class="mt-4 text-center">
                  <svg class="mx-auto h-8 w-8 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                  </svg>
                  <h3 class="mt-2 text-xs font-medium text-gray-500">No channels</h3>
                  <p class="mt-1 text-xs text-gray-600">
                    Discuss intel privately.
                  </p>
                  <div class="mt-3">
                    <button @click="createChannel" type="button" class="inline-flex items-center px-3 py-1.5 border border-transparent shadow-sm text-xs font-medium rounded-md text-gray-400 bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700">
                      <!-- Heroicon name: solid/plus -->
                      <svg class="-ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                      </svg>
                      Create channel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <!-- top bar -->
      <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button
          class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden"
          @click.stop="sidebarOpen = true"
        >
          <div class="relative">
            <svg
              class="h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
            <span v-if="hasUnreads" class="absolute top-0 right-0 block h-2 w-2 rounded-full text-white shadow-solid bg-green-400"></span>
          </div>
        </button>
        <div ref="searchFlyoverInput" class="flex-1 px-4 flex justify-between">
          <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0 -translate-y-1"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-in duration-150"
            leave-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 -translate-y-1"
          >
            <div v-if="searchFlyoverOpen" ref="searchFlyover" class="absolute inset-x-0 transform shadow-lg overflow-hidden" style="z-index: 10000; top: 4rem; min-height: 160px; max-height: 500px;">
              <search-flyover />
            </div>
          </transition>
          <div class="flex-1 flex">
            <div class="w-full flex md:ml-0">
              <label for="search_field" class="sr-only">
                Search
              </label>
              <div
                class="relative w-full text-gray-400 focus-within:text-gray-600"
              >
                <div
                  class="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                >
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    />
                  </svg>
                </div>
                <input
                  v-model="nameFilter"
                  @focus="globalSearch"
                  @keydown.esc="clearFilter"
                  id="search_field"
                  class="block w-full h-full pl-8 pr-3 py-2 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
          <div class="ml-4 flex items-center md:ml-6">
            <notifications-menu />
            <notepad-button />
            <avatar-menu />
          </div>
        </div>
      </div>

      <backchannel-creation-header v-if="isCreatingBackchannel" />
      <backchannel-header v-else-if="isBackchannel" />

      <!-- content goes here -->
      <main
        id="hub-body-container"
        :class="{
          'overflow-hidden': searchFlyoverOpen,
          'overflow-y-auto': !searchFlyoverOpen,
          'py-0': isStrategicMap,
          'py-6': !isStrategicMap
        }"
        class="flex-1 relative z-0 overflow-x-hidden focus:outline-none"
        tabindex="0"
      >
        <div style="z-index: 10000;">
          <div
            v-show="searchFlyoverOpen"
            class="absolute bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center"
            style="z-index: 10000;"
          >
            <transition
              enter-active-class="transition ease-out duration-300"
              enter-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition ease-in duration-200"
              leave-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <div v-show="searchFlyoverOpen" class="absolute inset-0 transition-opacity">
                <div class="fixed inset-0 bg-gray-800 opacity-75"></div>
              </div>
            </transition>
          </div>
        </div>
        <div v-if="isStrategicMap || displayGlobalIntelAdd" :class="displayGlobalIntelAdd ? 'fixed' : 'absolute'" class="bottom-10 right-4 flex flex-col space-y-3" style="z-index: 10000;">
          <button v-if="isStrategicMap && strategicMapMode" @click="downloadCsv" type="button" v-tooltip="'Data Export'" :disabled="csvLoading" class="inline-flex items-center p-3 border border-transparent rounded-full bg-gray-600 hover:bg-gray-500 focus:border-gray-700 focus:ring-gray active:bg-gray-700 shadow-lg text-white focus:outline-none focus:ring-2 focus:ring-offset-2">
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path v-if="csvLoading" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
              <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
          </button>
          <button @click="newIntel" type="button" v-tooltip="'Add news, deals, and more. Filterable and mappable.'" class="inline-flex items-center p-3 border border-transparent rounded-full shadow-lg text-white focus:outline-none focus:ring-2 focus:ring-offset-2" :class="privacyMode ? 'bg-pink-600 hover:bg-pink-500 focus:border-pink-700 focus:ring-pink active:bg-pink-700' : 'bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700'">
            <!-- Heroicon name: light-bulb -->
            <svg v-if="privacyMode" class="h-6 w-6" fill="currentColor" viewBox="0 0 384 512">
              <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
            </svg>
            <svg v-else class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
            </svg>
          </button>
        </div>
        <div :class="isStrategicMap ? '' : 'max-w-7xl mx-auto px-4 sm:px-6 md:px-8'">
          <router-view />
        </div>
      </main>

      <backchannel-message-input v-if="isBackchannel && !searchFlyoverOpen" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AvatarMenu from "../components/avatar-menu";
import BackchannelCreationHeader from "../components/backchannels/backchannel-creation-header";
import BackchannelHeader from "../components/backchannels/backchannel-header";
import BackchannelMessageInput from "../components/backchannels/backchannel-message-input";
import DealCard from "../components/cards/deal";
import Notepad from "../components/notepad";
import NotepadButton from "../components/notepad-button";
import NotificationsMenu from "../components/notifications-menu";
import SearchFlyover from "../components/search-flyover";
import api from "../api";
/* global analytics */

export default {
  components: {
    AvatarMenu,
    BackchannelCreationHeader,
    BackchannelHeader,
    BackchannelMessageInput,
    NotepadButton,
    NotificationsMenu,
    SearchFlyover
  },
  data() {
    return {
      sidebarOpen: false,
      hovering: null,
      expandedFeatures: false,
      expandedWatchlist: true,
      expandedMarkets: true,
      expandedOfferings: true,
      expandedChannels: true,
      csvLoading: false
    };
  },
  computed: {
    ...mapState([
      "loading",
      "currentUser",
      "adminMode",
      "searchFlyoverOpen",
      "activeContact",
      "mapFilters",
      "mapBoundaryMeta",
      "route",
      "modal",
      "displayGlobalIntelAdd"
    ]),
    ...mapGetters([
      "signedIn",
      "isAdmin",
      "mapMode",
      "userIsActiveContact",
      "backchannels",
      "privacyMode",
      "backchannelTokenParam",
      "propertyFollowingIdParam"
    ]),
    shouldDisplayOffMarket() {
      return (
        _.includes(["principal", "broker"], this.currentUser.industryRole) ||
        this.isAdmin
      );
    },
    isCreatingBackchannel() {
      return this.route.path === "/backchannel/new";
    },
    isBackchannel() {
      return _.includes(this.route.path, "/backchannel/");
    },
    myNetworkPage() {
      return this.route.path === "/network";
    },
    fullBleed() {
      return this.isStrategicMap || this.isDashboard;
    },
    isDashboard() {
      return _.includes(this.route.path, "/home/");
    },
    isStrategicMap() {
      return this.route.path === "/map";
    },
    strategicMapMode() {
      return this.mapMode === "strategic";
    },
    nameFilter: {
      get() {
        return this.$store.state.nameFilter;
      },
      set(name) {
        this.$store.commit("setNameFilter", name);
      }
    },
    shouldFetchContact() {
      return this.signedIn && !this.userIsActiveContact;
    },
    watchlistProperties() {
      return _.get(this.currentUser, "watchlistProperties", []);
    },
    targets() {
      return this.$store.getters.contactTargets(null);
    },
    activeDeals() {
      return this.activeContact.activeDeals;
    },
    newsfeedUnreadCount() {
      return this.currentUser.newsfeedUnreadCount;
    },
    networkUnreadCount() {
      return this.currentUser.networkUnreadCount;
    },
    backchannelUnreadCounts() {
      return _.get(this.currentUser, "backchannelUnreadCounts", []);
    },
    offeredDealUnreadCounts() {
      if (this.privacyMode) {
        return [];
      } else {
        return this.currentUser.offeredDealUnreadCounts;
      }
    },
    hasUnreads() {
      return (
        this.newsfeedUnreadCount > 0 ||
        this.networkUnreadCount > 0 ||
        this.backchannelUnreadCounts.some(channel => channel.unreadCount > 0) ||
        this.offeredDealUnreadCounts.some(deal => deal.unreadCount > 0)
      );
    }
  },
  watch: {
    currentUser: {
      handler: function() {
        if (this.shouldFetchContact) {
          this.$store.dispatch("loadContactData", this.currentUser.token);
          this.$store.dispatch("loadUnreadCounts");
        }
      }
    },
    nameFilter: {
      handler: _.debounce(function() {
        if (this.nameFilter && this.nameFilter.length > 2 && !this.modal) {
          this.$store.dispatch("executeSearch");
        } else {
          this.$store.commit("setSearchResults", null);
        }
      }, 1000)
    },
    hasUnreads: {
      handler() {
        var faviconApple = document.getElementById("favicon-apple");
        var favicon32 = document.getElementById("favicon-32");
        var favicon16 = document.getElementById("favicon-16");

        if (this.hasUnreads) {
          faviconApple.href = "/apple-touch-icon-alert.png";
          favicon32.href = "/favicon-alert-32x32.png";
          favicon16.href = "/favicon-alert-16x16.png";
        } else {
          faviconApple.href = "/apple-touch-icon.png";
          favicon32.href = "/favicon-32x32.png";
          favicon16.href = "/favicon-16x16.png";
        }
      }
    }
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.searchFlyover &&
        !this.$refs.searchFlyover.contains(evt.target) &&
        this.$refs.searchFlyoverInput &&
        !this.$refs.searchFlyoverInput.contains(evt.target)
      ) {
        this.$store.commit("setSearchFlyover", false);
        this.$store.commit("clearSearch");
      }
    });

    if (this.shouldFetchContact) {
      this.$store.dispatch("loadContactData", this.currentUser.token);
      this.$store.dispatch("loadUnreadCounts");
    }

    if (this.signedIn && this.$store.state.route.path === "/") {
      this.$router.push({ path: "/home" });
    }

    this.$store.dispatch("loadBackchannels");
    this.mountHubExpansionSettings();
  },
  destroyed() {
    this.$store.commit("setSearchFlyover", false);
    this.$store.commit("clearSearch");
  },
  methods: {
    mountHubExpansionSettings() {
      const {
        hubFeaturesExpanded,
        hubWatchlistExpanded,
        hubMarketsExpanded,
        hubOfferingsExpanded,
        hubChannelsExpanded
      } = this.currentUser;

      this.expandedFeatures = hubFeaturesExpanded;
      this.expandedWatchlist = hubWatchlistExpanded;
      this.expandedMarkets = hubMarketsExpanded;
      this.expandedOfferings = hubOfferingsExpanded;
      this.expandedChannels = hubChannelsExpanded;
    },
    toggleExpansion(hubSection) {
      this[hubSection] = !this[hubSection];
      this.updateHubExpansionSettings();
    },
    updateHubExpansionSettings() {
      const payload = {
        hubFeaturesExpanded: this.expandedFeatures,
        hubWatchlistExpanded: this.expandedWatchlist,
        hubMarketsExpanded: this.expandedMarkets,
        hubOfferingsExpanded: this.expandedOfferings,
        hubChannelsExpanded: this.expandedChannels
      };

      this.$store.dispatch("updateHubExpansionSettings", payload);
    },
    dealTypeTooltip(deal) {
      return this.$options.filters.dealTypeAlias(deal.type, deal.jointVentureRoles);
    },
    marketTooltip(market) {
      return this.$options.filters.marketName(market);
    },
    channelTooltip(channel) {
      if (channel.members.length > 1) {
        return this.$options.filters.channelName(channel);
      } else {
        return "";
      }
    },
    newsfeedMap() {
      this.sidebarOpen = false;
      this.$store.commit("clearMapFilters");
    },
    viewActiveDeal(deal) {
      if (deal.source === "direct lightweight") {
        this.$store.commit("openModal", {
          component: DealCard,
          props: {
            dealObject: deal
          }
        });
      } else {
        if (deal.state === "draft") {
          this.$router.push({ path: `/deal_offerings/${deal.urlHandle || deal.token}/overview` });
        } else {
          this.$router.push({ path: `/deal_offerings/${deal.urlHandle || deal.token}/marketing-list` });
        }
      }
      this.sidebarOpen = false;
    },
    liveDeal(deal) {
      return _.includes(["live", "awarded"], deal.state);
    },
    newProperty() {
      this.sidebarOpen = false;
      this.$router.push({ path: "/home/watchlist", query: { create: true } });
    },
    newTarget() {
      this.$router.push({ path: "/targets/new/1" });
    },
    newActiveDeal() {
      this.$router.push({ path: "/deals/new/1", query: { active: true } });
    },
    newIntel() {
      this.$router.push({
        path: "/intel/new/privacy",
        query: { nextUrl: "/intel/new/1" }
      });
    },
    downloadCsv() {
      this.csvLoading = true;
      const { southwestLatLng, northeastLatLng, zoom } = this.mapBoundaryMeta;
      const payload = {
        sources: this.mapFilters.sources.map(option => option.value),
        regions: this.mapFilters.regions.map(region => region.id),
        deals: this.mapFilters.deals.map(option => option.value),
        loans: this.mapFilters.loans.map(option => option.value),
        risks: this.mapFilters.risks.map(option => option.value),
        uses: this.mapFilters.uses.map(option => option.value),
        dates: this.mapFilters.dates
      };

      api({
        url: `csv_export.csv/${southwestLatLng}/${northeastLatLng}?ts=${new Date().getTime()}&zoom=${zoom}`,
        data: payload,
        method: "post",
        responseType: "blob"
      }).then(
        ({ data }) => {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement("a");

          link.href = url;
          link.setAttribute("download", "tower_hunt.csv");
          document.body.appendChild(link);
          link.click();

          this.$store.dispatch("flash", "CSV export successful");
          if (!!analytics && !this.adminMode) {
            analytics.track("CSV Created", {
              yearWeek: this.$store.state.yearWeek
            });
          }

          this.csvLoading = false;
        },
        failedResponse => {
          this.$store.dispatch("flash", failedResponse);
          this.csvLoading = false;
        }
      );
    },
    openNotepad() {
      this.$store.commit("openSlideover", {
        component: Notepad,
        props: { myNotepad: true }
      });
    },
    createChannel() {
      this.$router.push({ path: "/backchannel/new" });
      this.sidebarOpen = false;
    },
    clearFilter() {
      this.$store.commit("setSearchFlyover", false);
      this.$store.commit("clearSearch");
      document.getElementById("search_field").blur();
    },
    globalSearch() {
      this.$store.commit("setSearchFlyover", true);
    },
    hover(id) {
      this.hovering = id;
    },
    vacate() {
      this.hovering = null;
    },
    closeWatchlistProperty(propertyFollowing) {
      if (
        this.propertyFollowingIdParam &&
        _.toNumber(this.propertyFollowingIdParam) === propertyFollowing.id
      ) {
        this.$router.push({ path: "/home/watchlist" });
      }

      this.$store.dispatch("closeWatchlistProperty", { id: propertyFollowing.id });
    },
    closePrivateChannel(channel) {
      if (
        this.backchannelTokenParam &&
        this.backchannelTokenParam === channel.token
      ) {
        this.$router.push({ path: "/home" });
      }

      this.$store.dispatch("closePrivateChannel", { token: channel.token });
    },
    backchannelUnreadCountFor(channel) {
      const countContainer = _.find(this.backchannelUnreadCounts, { token: channel.token || channel.backchannelToken });

      return countContainer ? countContainer.unreadCount : 0;
    },
    marketUnreadCountFor(target) {
      const countContainer = _.find(this.marketUnreadCounts, { id: target.id });

      return countContainer ? countContainer.unreadCount : 0;
    },
    offeredDealUnreadCountFor(deal) {
      const countContainer = _.find(this.offeredDealUnreadCounts, { id: deal.id });

      return countContainer ? countContainer.unreadCount : 0;
    }
  }
};
</script>
