<template>
  <div class="px-4 py-5 sm:p-6">
    <div v-if="!confirmationFailed" class="flex justify-center">
      <grid-loader v-if="!loaded || submitting" :loading="!loaded || submitting" size="10px" color="#5850ec" />
    </div>

    <!-- TODO: DISPLAY PAYMENT METHODS IF CONFIRMATION FAILS -->
    <template v-if="confirmationFailed">
      <div v-if="isComplimentary" v-observe-visibility="{ callback: trackPurchaseFunnel }" class="sm:flex sm:items-center sm:justify-between">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Apply credit towards {{ purchaseable.name | truncate(30) }}
          </h3>
          <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
            <p>
              Tower Hunt will use <strong class="font-semibold"><span>${{ creditCost | formattedNumber(2) }}</span></strong> from my available credit balance to license this premium content.
            </p>
          </div>
        </div>
        <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
          <grid-loader v-if="submitting" :loading="submitting" size="6px" color="#5850ec" />
          <span v-else class="inline-flex rounded-md shadow-sm">
            <button @click="complimentaryClaim" type="button" :disabled="submitting" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
              Get
            </button>
          </span>
        </div>
      </div>

      <div v-else-if="selectedCard" v-observe-visibility="{ callback: trackPurchaseFunnel }" class="sm:flex sm:items-center sm:justify-between">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ isSubscription ? "Subscribe to" : "Buy" }} {{ purchaseable.name | truncate(30) }}
          </h3>
          <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
            <p>
              I authorize Tower Hunt to charge my card <strong class="font-semibold"><span>${{ netPrice | formattedNumber(2) }}<span v-if="isSubscription" class="font-normal">/mo</span></span></strong> to license this premium content.
              <template v-if="creditCost > 0">Tower Hunt will also use <strong class="font-semibold"><span>${{ creditCost | formattedNumber(2) }}</span></strong> from my available credit balance.</template>
              <router-link to="/product/premium-content" target="_blank" class="ml-1 font-medium text-indigo-600 hover:text-indigo-500">
                Learn more &rarr;
              </router-link>
            </p>
          </div>
        </div>
        <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
          <grid-loader v-if="submitting" :loading="submitting" size="6px" color="#5850ec" />
          <span v-else class="inline-flex rounded-md shadow-sm">
            <button @click="purchaseHandler" type="button" :disabled="submitting" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
              <template v-if="isSubscription">Subscribe</template>
              <template v-else>Buy</template>
            </button>
          </span>
        </div>
      </div>

      <payment-methods v-else :selectable="true" capturing-payment="now" @card-selected="selectCard" />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import PaymentMethods from "../payment-methods";
import api from "../../api";
/* global analytics */
/* global Stripe */

export default {
  components: { GridLoader, PaymentMethods },
  props: ["purchaseable"],
  data() {
    return {
      submitting: false,
      clientSecret: null,
      intentId: null,
      subscription: null,
      paymentMethod: null,
      stripe: null,
      confirmationFailed: false,
      selectedCard: null
    };
  },
  computed: {
    ...mapState(["adminMode"]),
    loaded() {
      if (this.isComplimentary) {
        return true;
      } else if (this.isSubscription) {
        return !!this.stripe;
      } else {
        return !!this.stripe && !!this.clientSecret && !!this.intentId;
      }
    },
    isSubscription() {
      return this.purchaseable.roleBasedPrice.isSubscription;
    },
    isComplimentary() {
      return this.purchaseable.isComplimentary;
    },
    netPrice() {
      return _.toNumber(this.purchaseable.netPrice);
    },
    creditCost() {
      return _.toNumber(this.purchaseable.creditCost);
    }
  },
  mounted() {
    if (!!Stripe) {
      api.get(`payment_api_key`).then(json => {
        const stripeKey = json.data;

        this.stripe = Stripe(stripeKey);
        if (this.isComplimentary) {
          this.confirmationFailed = true;
        } else if (this.isSubscription) {
          this.confirmationFailed = true;
        } else {
          api.post(`payment_intents/${this.purchaseable.id}`).then(json => {
            this.clientSecret = json.data.clientSecret;
            this.intentId = json.data.intentId;
            this.confirmationFailed = true;
          });
        }
      });
    } else {
      this.$store.commit("closeModal");
    }
  },
  methods: {
    trackPurchaseFunnel(isVisible, entry) {
      if (!this.adminMode && !!analytics && isVisible) {
        analytics.track("Selected payment method for premium content", {
          yearWeek: this.$store.state.yearWeek,
          purchaseableId: this.purchaseable.id
        });
      }
    },
    purchaseHandler() {
      if (this.isSubscription && !this.clientSecret) {
        this.createSubscription();
      } else {
        this.confirmPayment();
      }
    },
    complimentaryClaim() {
      this.submitting = true;
      this.licenseContent();
    },
    createSubscription() {
      const payload = {
        paymentMethod: this.paymentMethod,
        priceId: this.purchaseable.roleBasedPrice.id,
        purchaseableId: this.purchaseable.id
      };

      this.submitting = true;
      api.post(`purchaseable_subscriptions`, payload).then(json => {
        this.subscription = json.data.subscription;

        if (this.subscription.status === "active") {
          this.$store.dispatch("flash", "You successfully subscribed!");
          this.confirmationFailed = false;
          this.licenseContent();

          return;
        }

        let paymentIntent = this.subscription.latest_invoice.payment_intent;

        if (
          paymentIntent.status === "requires_action" ||
          paymentIntent.status === "requires_payment_method"
        ) {
          this.clientSecret = paymentIntent.client_secret;

          if (paymentIntent.status === "requires_action") {
            this.submitting = false;
            this.confirmPayment();
          }

          if (paymentIntent.status === "requires_payment_method") {
            this.selectedCard = null;
            this.confirmationFailed = true;
            this.submitting = false;
          }
        }
      });
    },
    confirmPayment() {
      this.submitting = true;
      this.stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: this.paymentMethod
        })
        .then(
          data => {
            if (data.error) {
              console.log(data.error.message);
              this.$store.dispatch("flash", data.error.message);
              this.confirmationFailed = true;
              this.selectedCard = null;
            } else {
              this.$store.dispatch("flash", "Payment successful!");
              this.confirmationFailed = false;
              this.licenseContent();
            }
          },
          failure => {
            this.submitting = false;
          }
        );
    },
    selectCard(record) {
      this.paymentMethod = record.id;
      this.selectedCard = record;
    },
    licenseContent() {
      const payload = {
        paymentIntentId: this.intentId,
        subscriptionId: _.get(this.subscription, "id", null),
        priceId: this.purchaseable.roleBasedPrice.id
      };

      api
        .post(
          `purchaseables/${this.purchaseable.id}/purchaseable_licenses`,
          payload
        )
        .then(json => {
          if (!this.adminMode && !!analytics) {
            let purchaseType;

            if (this.isComplimentary) {
              purchaseType = "Acquired premium content with promotional credit";
            } else if (_.get(this.subscription, "id", null)) {
              purchaseType = "Purchased premium content subscription";
            } else {
              purchaseType = "Purchased standalone premium content";
            }

            analytics.track(purchaseType, {
              yearWeek: this.$store.state.yearWeek,
              purchaseableId: this.purchaseable.id
            });
          }

          this.$store.commit("setRefetchPurchaseables", true);
          this.$store.dispatch("loadSpendableCredits");
          this.submitting = false;
          this.$store.commit("closeModal");
        });
    }
  }
};
</script>

<style>
.stripe-card {
  width: 300px;
  padding: 3px;
  display: inline-block;
  border: 1px solid transparent;
}
.stripe-card.complete {
  border-color: #0e9f6e;
  border-radius: 3px;
}

.payment-form {
  display: flex;
}
</style>
