<template>
  <!--
    Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
    Read the documentation to get started: https://tailwindui.com/documentation
  -->
  <div>
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      My Earnings
    </h3>
    <div class="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
      <div>
        <div class="px-4 py-5 sm:p-6">
          <dl>
            <dt class="text-base leading-6 font-normal text-gray-900" v-tooltip="'Fulfill on time to book revenue. Excludes platform fee.'">
              Hired <small class="text-xs">(Gross)</small>
            </dt>
            <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div class="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                ${{ engaged | formattedNumber(2) }}
                <!-- <span class="ml-2 text-sm leading-5 font-medium text-gray-500">
                  from 70,946
                </span> -->
              </div>
              <!-- <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="sr-only">
                  Increased by
                </span>
                12%
              </div> -->
            </dd>
          </dl>
        </div>
      </div>
      <div class="border-t border-gray-200 md:border-0 md:border-l">
        <div class="px-4 py-5 sm:p-6">
          <dl>
            <dt class="text-base leading-6 font-normal text-gray-900" v-tooltip="'Fulfilled and approved but still within challenge period. Net of platform fee.'">
              Booked <small class="text-xs">(Net)</small>
            </dt>
            <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div class="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                ${{ fulfilled | formattedNumber(2) }}
                <!-- <span class="ml-2 text-sm leading-5 font-medium text-gray-500">
                  from 56.14%
                </span> -->
              </div>
              <!-- <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="sr-only">
                  Increased by
                </span>
                2.02%
              </div> -->
            </dd>
          </dl>
        </div>
      </div>
      <div class="border-t border-gray-200 md:border-0 md:border-l">
        <div class="px-4 py-5 sm:p-6">
          <dl>
            <dt class="text-base leading-6 font-normal text-gray-900" v-tooltip="'Challenge period complete. Net of platform fee.'">
              Earnings <small class="text-xs">(Net)</small>
            </dt>
            <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div class="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                ${{ earnings | formattedNumber(2) }}
                <!-- <span class="ml-2 text-sm leading-5 font-medium text-gray-500">
                  from 28.62
                </span> -->
              </div>
              <button v-if="dashboardEnabled" @click="earningsDashboard" v-tooltip="'View your payouts dashboard'" class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
                </svg>
                <span class="sr-only">
                  Manage
                </span>
                Manage
              </button>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api";

export default {
  data() {
    return {
      engaged: 0,
      fulfilled: 0,
      earnings: 0,
      dashboardEnabled: false
    };
  },
  computed: {},
  mounted() {
    api.get(`my_bounty_earnings_pipeline`).then(json => {
      const { engaged, fulfilled, earnings, dashboardEnabled } = json.data;

      this.engaged = engaged;
      this.fulfilled = fulfilled;
      this.earnings = earnings;
      this.dashboardEnabled = dashboardEnabled;
    });
  },
  methods: {
    earningsDashboard() {
      this.$store.dispatch("createPayoutsDashboardSession");
    }
  }
};
</script>
