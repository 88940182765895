import api from "./api";
import nearbyPropertyMarker from "./leaflet-nearby-property-marker";
/* global L */

export default function fetchBoundingBoxProperties({
  map,
  router,
  store,
  featureGroup,
  focalProperties,
  strategic = false,
  heatmap = true,
  clickableMarkers = true,
  deduplicate = false,
  includeUnreferenced = false
}) {
  return new Promise(resolve => {
    const bounds = map.getBounds();
    const southwest = bounds.getSouthWest();
    const northeast = bounds.getNorthEast();
    const northeastLatLng = [northeast.lat, northeast.lng];
    const southwestLatLng = [southwest.lat, southwest.lng];
    const mapFilters = store.state.mapFilters;
    const payload = {
      sources: mapFilters.sources.map(option => option.value),
      regions: mapFilters.regions.map(region => region.id),
      deals: mapFilters.deals.map(option => option.value),
      loans: mapFilters.loans.map(option => option.value),
      risks: mapFilters.risks.map(option => option.value),
      uses: mapFilters.uses.map(option => option.value),
      dates: mapFilters.dates
    };

    if (heatmap) {
      api
        .post(
          `heatmap/${southwestLatLng}/${northeastLatLng}?zoom=${map.getZoom()}`
        )
        .then(response => {
          const heatmapData = response.data.heatmap;
          const heat = L.heatLayer(heatmapData, {
            radius: 50,
            minOpacity: 0.3,
            maxZoom: 12,
            gradient: {
              0.3: "#4F46E5", // indigo
              0.5: "#06B6D4", // cyan
              0.6: "#4ADE80", // green
              0.8: "#FDE047", // yellow
              0.9: "#F59E0B", // amber
              1: "#F472B6" // rose
            }
          }).addTo(map);

          featureGroup.addLayer(heat);

          if (strategic) {
            store.commit("setMapNearbyProperties", heatmapData);
          }

          resolve(heatmapData);
        });
    } else {
      store.commit("setMapFiltering", true);
      api
        .post(
          `nearby/${southwestLatLng}/${northeastLatLng}?zoom=${map.getZoom()}&unreferenced=${includeUnreferenced}`,
          payload
        )
        .then(response => {
          const nearbyProperties = _.unionBy(
            response.data.nearbyProperties,
            focalProperties,
            "id"
          ).map(property => {
            if (_.get(property, "markerType", null) === "competitiveSet") {
              return property;
            } else {
              return _.merge(property, { markerType: "nearby" });
            }
          });
          const nearbyWithFocal = nearbyProperties.map(property => {
            if (
              _.find(focalProperties, { id: property.id }) &&
              _.get(property, "markerType", null) !== "competitiveSet"
            ) {
              return _.merge(property, { markerType: "focal" });
            } else {
              return property;
            }
          });

          nearbyWithFocal.forEach(property => {
            nearbyPropertyMarker({
              map,
              router,
              store,
              featureGroup,
              property,
              strategic,
              clickable: clickableMarkers,
              deduplicate
            });
          });

          if (strategic) {
            store.commit("setMapNearbyProperties", nearbyProperties);
          }

          store.commit("setMapFiltering", false);

          resolve(nearbyProperties);
        });
    }
  });
}
