<template>
  <!--
    Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
    Read the documentation to get started: https://tailwindui.com/documentation
  -->
  <nav>
    <ul class="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
      <li v-if="state === 'creating'" class="relative md:flex-1 md:flex">
        <!-- Current Step -->
        <div class="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
          <div :class="`flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-${color}-${backgroundBorderColorShade} rounded-full`">
            <p :class="`text-${color}-${textColorShade}`">01</p>
          </div>
          <p :class="`text-sm leading-5 font-medium text-${color}-${textColorShade}`">Create</p>
        </div>

        <div class="hidden md:block absolute top-0 right-0 h-full w-5">
          <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
            <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
          </svg>
        </div>
      </li>

      <li v-else class="relative md:flex-1 md:flex">
        <!-- Completed Step -->
        <div class="group flex items-center w-full">
          <div class="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
            <div :class="`flex-shrink-0 w-10 h-10 flex items-center justify-center bg-${color}-${backgroundBorderColorShade} rounded-full`">
              <!-- Heroicon name: check -->
              <svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
            </div>
            <p class="text-sm leading-5 font-medium text-gray-900">Create</p>
          </div>
        </div>

        <div class="hidden md:block absolute top-0 right-0 h-full w-5">
          <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
            <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
          </svg>
        </div>
      </li>

      <li v-if="state === 'hiring'" class="relative md:flex-1 md:flex">
        <!-- Current Step -->
        <div class="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
          <div :class="`flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-${color}-${backgroundBorderColorShade} rounded-full`">
            <p :class="`text-${color}-${textColorShade}`">02</p>
          </div>
          <p :class="`text-sm leading-5 font-medium text-${color}-${textColorShade}`">Hire</p>
        </div>

        <div class="hidden md:block absolute top-0 right-0 h-full w-5">
          <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
            <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
          </svg>
        </div>
      </li>

      <li v-else-if="state === 'hired' || state === 'completed'" class="relative md:flex-1 md:flex">
        <!-- Completed Step -->
        <div class="group flex items-center w-full">
          <div class="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
            <div :class="`flex-shrink-0 w-10 h-10 flex items-center justify-center bg-${color}-${backgroundBorderColorShade} rounded-full`">
              <!-- Heroicon name: check -->
              <svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
            </div>
            <p class="text-sm leading-5 font-medium text-gray-900">Hired!</p>
          </div>
        </div>

        <div class="hidden md:block absolute top-0 right-0 h-full w-5">
          <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
            <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
          </svg>
        </div>
      </li>

      <li v-else class="relative md:flex-1 md:flex">
        <!-- Upcoming Step -->
        <div class="group flex items-center">
          <div class="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
            <div class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
              <span class="text-gray-500">02</span>
            </div>
            <p class="text-sm leading-5 font-medium text-gray-500">Hire</p>
          </div>
        </div>

        <div class="hidden md:block absolute top-0 right-0 h-full w-5">
          <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
            <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
          </svg>
        </div>
      </li>

      <li v-if="state === 'completed'" class="relative md:flex-1 md:flex">
        <!-- Completed Step -->
        <div class="group flex items-center w-full">
          <div class="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
            <div :class="`flex-shrink-0 w-10 h-10 flex items-center justify-center bg-${color}-${backgroundBorderColorShade} rounded-full`">
              <!-- Heroicon name: check -->
              <svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
            </div>
            <p class="text-sm leading-5 font-medium text-gray-900">Completed!</p>
          </div>
        </div>
      </li>

      <li v-else class="relative md:flex-1 md:flex">
        <!-- Upcoming Step -->
        <div class="group flex items-center">
          <div class="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
            <div class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
              <span class="text-gray-500">03</span>
            </div>
            <p class="text-sm leading-5 font-medium text-gray-500">Completed!</p>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ["color", "state"],
  computed: {
    textColorShade() {
      if (this.color === "yellow") {
        return "500";
      } else {
        return "600";
      }
    },
    backgroundBorderColorShade() {
      if (this.color === "yellow") {
        return "300";
      } else {
        return "600";
      }
    }
  }
};
</script>
