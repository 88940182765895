<template>
  <div class="wrapper">
    <heading>How much leverage?</heading>
    <form @submit.prevent="next" novalidate>
      <div class="input-container">
        <input
          v-focus
          v-model="leverage"
          @focus="focus"
          @blur="blur"
          type="number"
          min="1"
          step="1"
          placeholder=""
          tabindex="1"
          name="leverage"
          class="input-text black"
          :class="{ active: focused && !privacyMode, 'privacy-active': focused && privacyMode }"
        >
        <label class="input-label" :class="{ active: focused && !privacyMode, 'privacy-active': focused && privacyMode }">Fully-funded LTC (%)</label>
        <label class="percent-label" :class="{ active: focused && !privacyMode, 'privacy-active': focused && privacyMode }">%</label>
      </div>
      <option-description v-if="optional" description="Optional" style="margin-top: 20px;" />
      <button @click="next" type="submit" class="cta" :class="{ complete: complete && !privacyMode, 'privacy-complete': complete && privacyMode }" tabindex="999">
        <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
          <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
        </svg>
        <template v-if="standaloneUpdate">Save</template>
        <template v-else>Next</template>
      </button>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DealCloseDate from "./deal-close-date";
import Heading from "../../../components/onboarding/heading";
import OptionDescription from "../../../components/onboarding/option-description";

export default {
  components: { Heading, OptionDescription },
  props: ["dealId", "transitioningToClosed"],
  data() {
    return {
      focused: false
    };
  },
  mounted() {
    if (this.creatingDeal && !this.dealCreationInProcess) {
      this.$router.push(_.merge({ path: "/deals/new/1" }, this.queryParams));
    }
  },
  computed: {
    ...mapState([
      "dealCreationInProcess",
      "activeDeal",
      "activeDealMarketing",
      "modal"
    ]),
    ...mapGetters([
      "creatingActiveDeal",
      "creatingIndirectActiveDeal",
      "creatingDeal",
      "closedDealFinancing",
      "privacyMode"
    ]),
    leverage: {
      get() {
        return this.activeDeal.leverage;
      },
      set(num) {
        this.$store.commit("setDealLeverage", num);
      }
    },
    optional() {
      return this.activeDeal.source === "indirect" || this.creatingActiveDeal;
    },
    complete() {
      if (this.optional) {
        return true;
      } else {
        return !!this.leverage;
      }
    },
    queryParams() {
      if (this.creatingIndirectActiveDeal) {
        return { query: { active: true, indirect: true } };
      } else if (this.creatingActiveDeal) {
        return { query: { active: true } };
      } else {
        return {};
      }
    },
    standaloneUpdate() {
      return (
        this.dealId &&
        !this.closingFromMarketingList &&
        !this.transitioningToClosed
      );
    },
    closingFromMarketingList() {
      return this.activeDealMarketing.id;
    }
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    next() {
      if (this.complete) {
        if (this.standaloneUpdate) {
          this.$store.dispatch("updateDeal", {
            dealId: this.dealId,
            updateTags: true
          });
          this.$store.commit("closeModal");
        } else if (this.closingFromMarketingList || this.transitioningToClosed) {
          this.$store.commit("openModal", {
            component: DealCloseDate,
            props: {
              dealId: this.dealId,
              transitioningToClosed: this.transitioningToClosed
            },
            afterClose: _.get(this.modal, "afterClose", null)
          });
        } else {
          this.$router.push(_.merge({ path: "/deals/new/9" }, this.queryParams));
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  display: flex;
  flex-direction: column;
}
.range {
  display: flex;
  justify-content: center;
}
input {
  vertical-align: middle;
}
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 26px 30px 8px 15px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 20px;
  line-height: 18.2px;
  &.privacy-active {
    border: 2px solid #d61f69;
  }
  &.active {
    border: 2px solid #5850ec;
  }
}
.input-label {
  position: absolute;
  top: 8px;
  left: 16px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.privacy-active {
    color: #d61f69;
  }
  &.active {
    color: #5850ec;
  }
}
.percent-label {
  position: absolute;
  top: 31px;
  right: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.privacy-active {
    color: #d61f69;
  }
  &.active {
    color: #5850ec;
  }
}
button.cta {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.privacy-complete {
    background: #d61f69;
    box-shadow: rgba(214, 31, 105, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #f17eb8;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
