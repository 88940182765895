<template>
  <div class="flex items-center">
    <button @click="toggleMapMode" v-tooltip="mapModeHelp" type="button" :aria-checked="!!strategicMap" :class="!!strategicMap ? 'bg-indigo-600 focus:ring-indigo-500' : 'bg-orange-200 focus:ring-orange-500'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2" role="switch">
      <span class="sr-only">Strategic toggle</span>
      <span :class="!!strategicMap ? 'translate-x-5' : 'translate-x-0'" class="pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200">
        <span :class="!!strategicMap ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity" aria-hidden="true">
          <svg class="h-3 w-3 text-orange-400" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd" />
          </svg>
        </span>
        <span :class="!!strategicMap ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity" aria-hidden="true">
          <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z" clip-rule="evenodd" />
          </svg>
        </span>
      </span>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapGetters(["mapMode"]),
    ...mapState(["currentUser"]),
    strategicMap() {
      return this.mapMode === "strategic";
    },
    mapModeTransition() {
      return this.currentUser.mapModeTransition;
    },
    mapModeHelp() {
      const transitionHelp =
        this.mapModeTransition === "automatic"
          ? "Map will transition at zoom 15 per your settings."
          : "Per your settings, map will only change modes via this toggle.";

      return this.strategicMap
        ? `The Strategic Map shows you deals, intel, and context at the property level. Click to toggle to the heatmap. ${transitionHelp}`
        : `The heatmap shows you Tower Hunt data by recency. Click to toggle to the Strategic Map. ${transitionHelp}`;
    }
  },
  methods: {
    toggleMapMode() {
      const newMapMode = this.mapMode === "heat" ? "strategic" : "heat";
      const payload = { mapMode: newMapMode };

      this.$store.dispatch("updateMapMode", payload);
    }
  }
};
</script>
