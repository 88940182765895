<template>
  <div class="flex-shrink-0">
    <div v-if="shared" class="inline-flex items-center py-2 px-3 border border-transparent rounded-full bg-green-100">
      <!-- Heroicon name: solid/check -->
      <svg class="-ml-1 mr-0.5 h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
      </svg>
      <span class="text-sm font-medium text-green-900"> Shared! </span>
    </div>
    <button v-else @click="share" type="button" class="inline-flex items-center py-2 px-3 border border-transparent rounded-full bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      <!-- Heroicon name: solid/plus-sm -->
      <svg class="-ml-1 mr-0.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
      </svg>
      <span class="text-sm font-medium text-gray-900"> Share <span class="sr-only">with {{ shareWithText }}</span> </span>
    </button>
  </div>
</template>

<script>
export default {
  props: ["licenseeType", "licenseeObject"],
  data() {
    return {
      shared: false
    };
  },
  computed: {
    shareWithText() {
      switch (this.licenseeType) {
        case "PropertyFollowing":
          return "Watchlist Property Members";
        case "User":
          return this.licenseeObject.name;
        case "Backchannel":
          return this.$options.filters.channelName(this.licenseeObject);
        default:
          return "Recipient";
      }
    }
  },
  methods: {
    share() {
      this.$emit("share", {
        licenseeType: this.licenseeType,
        licenseeObject: this.licenseeObject
      });
      this.shared = true;
    }
  }
};
</script>
