<template>
  <vue-markdown>
**Maintaining confidentiality.** You and we agree to maintain the confidentiality of any non-public information received by the other party during or prior to entering into these Terms, including without limitation, non-public technical, financial and business information, software, demonstration programs, routines, algorithms, computer systems, techniques, documentation, designs, procedures, formulas, inventions, improvements, concepts, records, trade secrets, files, memoranda, reports, drawings, plans, price lists, pricing guidance, customer lists, and/or the like ("Confidential Information"). Confidential Information shall also include any and all information received by the Disclosing Party from any third person that the Disclosing Party is obliged to treat as confidential. Confidential Information shall also include information that a party should know is confidential or proprietary based on the circumstances surrounding the disclosure.

Information that is independently developed by one of us without reference to the other's Confidential Information, or that is generally available to the public without breach of any obligation owed hereunder, or that is known or becomes available to one of us other than through violation of these Terms or applicable law, shall not be considered Confidential Information of the other party. The receiving party agrees not to use Confidential Information for any purpose except as necessary to fulfill its obligations and exercise its rights under these Terms. The receiving party shall protect the secrecy of and avoid disclosure and unauthorized use of the disclosing party's Confidential Information to the same degree that it takes to protect its own confidential information and in no event less than reasonable care. Nothing herein will prevent either party from sharing Confidential Information with financial and legal advisors (“Permitted Recipient”); provided, however, that the Permitted Recipients are bound to confidentiality obligations at least as restrictive as those in these Terms and the receiving party shall remain liable for any breach of confidentiality by a Permitted Recipient.

The receiving party shall promptly notify the disclosing party of any actual or suspected unauthorized disclosure of Confidential Information. If the receiving party is compelled by law to disclose any Confidential Information to a third party, the receiving party shall promptly notify the disclosing party, to the extent legally permitted, and reasonably assist the disclosing party in obtaining a protective order (at the disclosing party’s cost).

The receiving party acknowledges that the Confidential Information may still be under development, or may be incomplete, and that such information may relate to products that are under development or planned for development. The receiving party agrees that the Confidential Information is provided "AS IS". THE DISCLOSING PARTY MAKES NO WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE IN CONNECTION WITH ANY CONFIDENTIAL INFORMATION WHICH IT DISCLOSES HEREUNDER.

If you do not comply with these Terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have (such as taking action in the future). If it turns out that a particular term is not enforceable, this will not affect any other terms.
  </vue-markdown>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  components: { VueMarkdown }
};
</script>
