<template>
  <div class="wrapper">
    <heading>How long should this user earn a revenue share for growing this market?</heading>
    <form @submit.prevent="save(true)" novalidate>
      <v-date-picker v-if="expiresAt" mode="single" v-model="expiresAt" color="indigo" is-inline is-required />
      <button type="submit" class="cta mt-8" :class="{ complete: true }" tabindex="999">Save</button>
      <button @click="save(false)" type="button" class="secondary my-1" tabindex="999">No revenue share</button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Heading from "../../components/onboarding/heading";
import api from "../../api";

export default {
  components: { Heading },
  props: ["target"],
  data() {
    return {
      expiresAt: null
    };
  },
  computed: {
    ...mapGetters(["isAdmin"])
  },
  mounted() {
    this.fetchExpiresAt();
  },
  methods: {
    fetchExpiresAt() {
      api
        .get(`target_revenue_participations/${this.target.token}`)
        .then(json => {
          const date = json.data.expiresAt;

          if (date) {
            this.expiresAt = new Date(date);
          } else {
            this.expiresAt = new Date();
          }
        });
    },
    save(revenueParticipation = true) {
      if (!revenueParticipation) {
        this.expiresAt = null;
      }

      api
        .patch(`target_revenue_participations/${this.target.token}`, { expiresAt: this.expiresAt })
        .then(json => {
          this.$store.dispatch(
            "flash",
            revenueParticipation
              ? "Revenue participation expiration date updated!"
              : "Revenue participation removed."
          );
        });

      this.$store.commit("closeModal");
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
button {
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
