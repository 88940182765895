<template>
  <div class="border-t border-gray-200 rounded-b-lg">
    <div v-if="pricing || card.priced || card.expired" class="p-2">
      <div class="text-left">
        <h3 class="text-sm leading-4 text-gray-900">
          <template v-if="approvalDetermined">Admin <span class="font-medium" :class="approvalColor">{{ card.approvalStatus }}</span></template>
          <template v-else-if="card.priced">Admin must approve <span class="text-indigo-600 font-medium">{{ capType }}</span> cap rates</template>
          <template v-else>Submit <span v-tooltip="'Kept separate from any message you send the owner. Bad faith estimates subject to rejection.'" class="underline font-medium">anonymous {{ capType }}</span> cap rate range</template>
        </h3>
      </div>
      <form @submit.prevent="submitPricing" class="pt-2 grid grid-cols-3 gap-2 items-stretch">
        <label for="cap_range" class="sr-only">Cap rate range</label>
        <div class="mt-0 col-span-1">
          <div class="relative max-w-lg rounded-md shadow-sm sm:max-w-xs">
            <input v-focus v-model=minCap type="number" min="1.00" max="20.00" step="0.25" :id="`min_cap_${card.id}`" placeholder="Min" :disabled="card.priced" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5">
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm sm:leading-5" id="min_cap_percentage">
                %
              </span>
            </div>
          </div>
        </div>
        <div class="mt-0 col-span-1">
          <div class="relative max-w-lg rounded-md shadow-sm sm:max-w-xs">
            <input v-model="maxCap" type="number" min="1.25" max="20.25" step="0.25" :id="`max_cap_${card.id}`" placeholder="Max" :disabled="card.priced" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5">
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm sm:leading-5" id="max_cap_percentage">
                %
              </span>
            </div>
          </div>
        </div>
        <span v-if="card.priced && isAdmin" class="relative z-0 inline-flex shadow-sm">
          <button @click="reject" type="button" v-tooltip="'Reject'" class="relative inline-flex flex-1 items-center justify-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-red-500 hover:text-red-400 focus:z-10 focus:outline-none focus:border-gray-300 focus:ring-red active:bg-red-100 active:text-red-500" aria-label="Reject">
            <!-- Heroicon name: thumb-down -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z" />
            </svg>
          </button>
          <button @click="approve" type="button" v-tooltip="'Approve'" class="-ml-px relative inline-flex flex-1 items-center justify-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-green-300 hover:text-green-200 focus:z-10 focus:outline-none focus:border-green-300 focus:ring-green active:bg-green-100 active:text-green-300" aria-label="Approve">
            <!-- Heroicon name: thumb-up -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
            </svg>
          </button>
        </span>

        <span v-else class="inline-flex rounded-md shadow-sm mt-0 col-span-1">
          <button type="submit" :disabled="!complete" class="w-full py-2 px-4 border rounded-md text-sm leading-5 font-medium" :class="buttonStyling">
            Submit
          </button>
        </span>
      </form>
    </div>
    <div v-else class="w-full flex">
      <button @click="pricing = true" class="relative -mr-px w-0 flex-1 rounded-b-lg inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border-transparent bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
        <!-- Heroicon name: lock-open -->
        <svg class="w-5 h-5 text-white" viewBox="0 0 20 20" fill="currentColor">
          <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
        </svg>
        <span class="ml-3 text-white">Unlock cap rate data</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import api from "../../../api";

export default {
  props: ["card"],
  data() {
    return {
      pricing: false,
      minCap: null,
      maxCap: null
    };
  },
  computed: {
    ...mapState(["adminMode"]),
    ...mapGetters(["isAdmin"]),
    minCapNumber() {
      return _.toNumber(this.minCap);
    },
    maxCapNumber() {
      return _.toNumber(this.maxCap);
    },
    hasCaps() {
      return this.minCap && this.maxCap;
    },
    minComplete() {
      return (
        this.hasCaps &&
        this.minCapNumber > 0 &&
        this.minCapNumber < this.maxCapNumber
      );
    },
    maxComplete() {
      return this.hasCaps && this.maxCapNumber > this.minCapNumber;
    },
    snapshotData() {
      return this.card.content.propertySnapshot;
    },
    isStabilized() {
      if (this.longTermLeasable) {
        return this.stabilizedOccupancy && this.snapshotData.walt >= 4;
      } else {
        return this.stabilizedOccupancy;
      }
    },
    capType() {
      // stabilized cap rate corresponds to unstabilized property because you
      // want to know what the investor expects to earn after stabilizing
      return this.isStabilized ? "in-place" : "stabilized";
    },
    longTermLeasable() {
      return _.includes(
        ["Office", "Retail", "Industrial", "Lab", "Healthcare"],
        this.snapshotData.primaryUse
      );
    },
    stabilizedOccupancy() {
      return _.includes(
        ["100%", "90-99%", "91-99%"],
        this.snapshotData.occupancy
      );
    },
    approvalDetermined() {
      return _.includes(["approved", "rejected"], this.card.approvalStatus);
    },
    approvalColor() {
      switch (this.card.approvalStatus) {
        case "approved":
          return "text-green-300";
        case "rejected":
          return "text-red-500";
        default:
          return "text-gray-500";
      }
    },
    complete() {
      return this.minComplete && this.maxComplete && !this.card.priced;
    },
    buttonStyling() {
      if (this.complete) {
        return "inline-flex justify-center border-transparent text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700";
      } else {
        return "border-gray-300 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 cursor-not-allowed";
      }
    }
  },
  mounted() {
    if (this.card.priced && this.isAdmin) {
      this.minCap = this.card.minimumCapRate;
      this.maxCap = this.card.maximumCapRate;
    }
  },
  methods: {
    approve() {
      api
        .patch(`approve_pack_card/${this.card.packId}/${this.card.id}`)
        .then(() => {
          this.card.approvalStatus = "approved";
        });
    },
    reject() {
      api
        .delete(`approve_pack_card/${this.card.packId}/${this.card.id}`)
        .then(() => {
          this.card.approvalStatus = "rejected";
        });
    },
    submitPricing() {
      if (this.complete) {
        api
          .patch(`price_pack_card/${this.card.packId}/${this.card.id}`, {
            minCap: this.minCap,
            maxCap: this.maxCap
          })
          .then(json => {
            if (!!analytics && !this.adminMode) {
              analytics.track("Off-market cap rate estimated", {
                yearWeek: this.$store.state.yearWeek
              });
            }

            this.$store.dispatch("flash", "Submission successful. Thank you!");
            this.$emit("priced", this.card.id);
          });
      } else {
        this.$store.dispatch("flash", "Cap rate range is incomplete!");
      }
    }
  }
};
</script>
