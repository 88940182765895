<template>
  <main>
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
        <nav class="space-y-1">
          <a v-for="tab in permissionedTabs" @click.prevent="selectedTab = tab" :key="tab.name" :href="tab.href" :class="[selectedTab.name === tab.name ? 'bg-gray-50 text-indigo-600 hover:bg-white' : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50', 'group rounded-md px-3 py-2 flex items-center text-sm font-medium']" :aria-current="selectedTab.name === tab.name ? 'page' : undefined">
            <svg :class="[selectedTab.name === tab.name ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :d="tab.svgPath" />
            </svg>
            <span class="truncate">
              {{ tab.name }}
            </span>
          </a>
        </nav>
      </aside>

      <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <keep-alive>
          <component :is="selectedTab.component" v-bind="selectedTabProperties" />
        </keep-alive>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import ContentAttribution from "./content-attribution.vue";
import ContentRelevance from "./content-relevance.vue";

export default {
  props: ["content", "contentType"],
  data() {
    return {
      tabs: [
        {
          name: "Relevance",
          href: "",
          svgPath:
            "M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5",
          component: ContentRelevance
        }
      ],
      selectedTab: {
        name: "Relevance",
        href: "",
        svgPath:
          "M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5",
        component: ContentRelevance
      }
    };
  },
  computed: {
    ...mapGetters(["signedIn", "isAdmin"]),
    permissionedTabs() {
      if (this.isAdmin || this.content.contributed) {
        return _.concat(this.tabs, {
          name: "Contributors",
          href: "",
          svgPath:
            "M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z",
          component: ContentAttribution
        });
      } else {
        return this.tabs;
      }
    },
    selectedTabProperties() {
      switch (this.selectedTab.name) {
        case "Relevance":
        case "Contributors":
          return {
            content: this.content,
            contentType: this.contentType
          };
        default:
          return {};
      }
    }
  }
};
</script>
