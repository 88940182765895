<template>
  <div class="wrapper">
    <heading v-if="dealType === 'Recap Equity'">Were you leaving or joining?</heading>
    <heading v-else>What <template v-if="creatingActiveDeal">is</template><template v-else>was</template> your role?</heading>
    <button v-if="canBeIndirect" @click="indirectDeal" type="button" class="cta complete mb-2" tabindex="999">Anonymous tip</button>
    <div class="content-wrap">
      <section :class="orientation">
        <option-select
          v-for="option in matchingOptions"
          :key="option.name"
          option-type="dealInputDealRole"
          setter-name="setDealRole"
          setter-type="commit"
          :store-record="activeDeal.viewerDealRole"
          :option-record="option"
          :layout="orientation"
          :options-count="matchingOptions.length" />
      </section>
      <option-description v-if="description" :description="description" />
    </div>
    <button @click="next" type="button" class="cta my-4" :class="{ complete: complete }" tabindex="999">
      <template v-if="claimingDeal">Add deal to your profile</template>
      <template v-else-if="dealId">Save</template>
      <template v-else>Next</template>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../../components/onboarding/heading";
import OptionDescription from "../../../components/onboarding/option-description";
import OptionSelect from "../../../components/onboarding/option-select";
import dealRoles from "../../../deal-type-roles";

export default {
  props: ["dealId", "claimingDeal"],
  data() {
    return {
      options: dealRoles
    };
  },
  mounted() {
    if (this.creatingDeal && !this.dealCreationInProcess) {
      this.$router.push(_.merge({ path: "/deals/new/1" }, this.queryParams));
    }
  },
  components: { Heading, OptionSelect, OptionDescription },
  computed: {
    ...mapState(["dealCreationInProcess", "currentUser", "activeDeal"]),
    ...mapGetters([
      "creatingActiveDeal",
      "creatingIndirectActiveDeal",
      "creatingDeal",
      "closedDealCapitalMarkets",
      "closedDealLease"
    ]),
    dealType() {
      return this.activeDeal.type;
    },
    orientation() {
      return this.matchingOptions.length > 3 ? "vertical" : "horizontal";
    },
    matchingOptions() {
      const typedOptions = _.get(this.options, this.dealType, []);

      if (this.creatingActiveDeal) {
        return typedOptions.filter(o => o.activeDeal);
      } else {
        return typedOptions;
      }
    },
    optionData() {
      return _.find(this.matchingOptions, { value: this.activeDeal.viewerDealRole });
    },
    description() {
      if (this.optionData && this.optionData.description) {
        return this.creatingActiveDeal
          ? this.optionData.presentDescription
          : this.optionData.description;
      } else {
        return null;
      }
    },
    complete() {
      return (
        this.activeDeal.viewerDealRole !== null ||
        (this.activeDeal.source === "indirect" && !this.claimingDeal)
      );
    },
    canBeIndirect() {
      return !this.creatingActiveDeal && !this.complete && !this.claimingDeal;
    },
    queryParams() {
      if (this.creatingIndirectActiveDeal) {
        return { query: { active: true, indirect: true } };
      } else if (this.creatingActiveDeal) {
        return { query: { active: true } };
      } else {
        return {};
      }
    }
  },
  methods: {
    indirectDeal() {
      this.$store.commit("setDealSource", "indirect");
      this.next();
    },
    next() {
      if (this.complete) {
        if (this.dealId) {
          if (this.claimingDeal) {
            this.$store.dispatch("claimIndirectDeal", this.dealId);
          } else {
            this.$store.dispatch("updateDeal", {
              dealId: this.dealId,
              updateTags: false
            });
          }
          this.$store.commit("closeModal");
        } else if (this.closedDealCapitalMarkets) {
          this.$router.push(_.merge({ path: "/deals/new/4" }, this.queryParams));
        } else if (this.closedDealLease) {
          this.$router.push(_.merge({ path: "/deals/new/8" }, this.queryParams));
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.content-wrap {
  display: flex;
  flex-direction: column;
}
section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  pointer-events: none;
  &.horizontal {
    flex-wrap: wrap;
  }
  &.vertical {
    flex-direction: column;
    align-items: center;
  }
}
button.cta {
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
