<template>
  <div class="px-4 py-5 sm:p-6">
    <h3 class="text-base leading-6 font-medium text-gray-900">
      Personal Payouts Dashboard
    </h3>
    <div class="mt-2 sm:flex sm:items-start sm:justify-between">
      <div class="max-w-xl text-sm leading-5 text-gray-500">
        <p>
          Tower Hunt partners with <a href="https://stripe.com" target="_blank" class="underline">Stripe</a> to manage payments and payouts. Use the button below to visit your personal dashboard.
        </p>
      </div>
      <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
        <span class="inline-flex rounded-md shadow-sm">
          <button @click="visitPayoutsDashboard" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
            Payout settings
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    visitPayoutsDashboard() {
      this.$store.dispatch("createPayoutsDashboardSession");
    }
  }
};
</script>
