<template>
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
      <div>
        <h3 class="text-lg leading-6 font-medium text-gray-900">Tracking</h3>
        <p class="mt-1 text-sm text-gray-500">Use these fields to stay organized and aligned with your internal records.</p>
      </div>

      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6">
          <label for="custom-name" class="block text-sm font-medium text-gray-700">Custom name</label>
          <input v-focus v-model="customName" type="text" name="custom-name" id="custom-name" autocomplete="password" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        </div>

        <!-- This example requires Tailwind CSS v2.0+ -->
        <fieldset class="col-span-6">
          <legend class="text-sm font-medium text-gray-900">
            Internal priority
          </legend>
          <div class="mt-1 space-y-4">
            <label v-for="priority in priorityLevels" :key="priority.name" :class="`${prioritySelectedClass(priority)} relative block rounded-lg border border-${priority.color}-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-${priority.color}-400 sm:flex sm:justify-between focus:outline-none`">
              <input v-model="internalPriority" type="radio" name="internal-priority" :value="priority.value" class="sr-only" aria-labelledby="internal-priority-0-label" aria-describedby="internal-priority-0-description-0 internal-priority-0-description-1">
              <div class="flex items-center">
                <div class="text-sm">
                  <p id="internal-priority-0-label" class="font-medium text-gray-900">
                    {{ priority.name }}
                  </p>
                </div>
              </div>
              <div :class="priority.value === internalPriority ? `border-${priority.color}-500` : 'border-transparent'" class="absolute -inset-px rounded-lg border-2 pointer-events-none" aria-hidden="true"></div>
            </label>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
      <button @click="save" type="button" class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Save
      </button>
    </div>
  </div>
</template>

<script>
import api from "../../../api";

export default {
  props: ["content", "contentType"],
  data() {
    return {
      customName: null,
      internalPriority: "none",
      priorityLevels: [
        {
          name: "High",
          value: "high",
          color: "fuchsia"
        },
        {
          name: "Medium",
          value: "medium",
          color: "violet"
        },
        {
          name: "Low",
          value: "low",
          color: "cyan"
        },
        {
          name: "None",
          value: "none",
          color: "cool-gray"
        }
      ]
    };
  },
  mounted() {
    this.fetchStandaloneDataField("customName", "custom_name");
    this.fetchStandaloneDataField("internalPriority", "internal_priority");
  },
  methods: {
    prioritySelectedClass(priority) {
      return priority.value === this.internalPriority
        ? `ring-1 ring-offset-2 ring-${priority.color}-500`
        : "";
    },
    fetchStandaloneDataField(frontEndFieldName, backEndFieldName) {
      api
        .get(
          `safezone_data_fields/${this.contentType}/${this.content.token ||
            this.content.id}?field_name=${backEndFieldName}`
        )
        .then(json => {
          const dataField = json.data;

          if (dataField) {
            this[frontEndFieldName] = dataField.fieldValue;
          }
        });
    },
    async save() {
      const customName = this.customName
        ? {
            fieldName: "custom_name",
            fieldValue: this.customName,
            valueType: "String"
          }
        : null;
      const internalPriority = {
        fieldName: "internal_priority",
        fieldValue: this.internalPriority,
        valueType: "String"
      };
      const promises = _.compact([customName, internalPriority]);

      await Promise.all(
        promises.map(payload => {
          api.post(
            `safezone_data_fields/${this.contentType}/${this.content.token ||
              this.content.id}`,
            payload
          );
        })
      );

      this.$store.dispatch("flash", "Tracking data saved!");
    }
  }
};
</script>
