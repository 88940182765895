<template>
  <div id="map" v-observe-visibility="{ callback: initialNearbyFetch, once: true, throttle: 500 }" />
</template>

<script>
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "leaflet/dist/leaflet.css";
import { mapGetters, mapState } from "vuex";
import fetchBoundingBoxProperties from "../../../fetch-bounding-box-properties";
import mapStyles from "../../../map-styles";
import router from "../../../router";
import store from "../../../store";
/* global L */

export default {
  data() {
    return {
      featureGroup: L.featureGroup()
    };
  },
  computed: {
    ...mapState(["activeProperty"]),
    ...mapGetters(["dealIdParam"]),
    zoom() {
      return 17;
    },
    center() {
      return [this.activeProperty.lat, this.activeProperty.lng];
    }
  },
  mounted() {
    this.$store.commit("clearDeduplicate");

    this.$store.commit(
      "setActivePropertyMap",
      L.map("map", { fullscreenControl: true }).setView(this.center, this.zoom)
    );

    var roadMutant = L.gridLayer
      .googleMutant({
        type: "roadmap",
        styles: mapStyles.styles
      })
      .addTo(this.activeProperty.map);

    var satMutant = L.gridLayer.googleMutant({
      type: "satellite",
      styles: []
    });

    var hybridMutant = L.gridLayer.googleMutant({
      type: "hybrid",
      styles: []
    });

    var transitMutant = L.gridLayer.googleMutant({
      type: "roadmap",
      styles: []
    });

    transitMutant.addGoogleLayer("TransitLayer");

    var self = this;

    this.activeProperty.map.on("moveend", function() {
      self.debouncedRefreshMap();
    });

    this.activeProperty.map.on("fullscreenchange", function() {
      if (self.activeProperty.map.isFullscreen()) {
        self.activeProperty.map.scrollWheelZoom.enable();
      } else {
        self.activeProperty.map.scrollWheelZoom.disable();
      }
    });

    L.control
      .layers(
        {
          Base: roadMutant,
          Satellite: satMutant,
          Hybrid: hybridMutant,
          Transit: transitMutant
        },
        {},
        {
          collapsed: true
        }
      )
      .addTo(this.activeProperty.map);

    const marker = L.marker(this.center, { draggable: true }).addTo(
      this.activeProperty.map
    );

    marker.bindTooltip("Drag me!").openTooltip();

    marker.on("pm:edit", e => {
      const currentCoordinates = marker.getLatLng();

      this.$store.commit("updatePropertyLatLng", {
        lat: currentCoordinates.lat,
        lng: currentCoordinates.lng
      });
    });

    document.querySelector(".leaflet-popup-pane").addEventListener(
      "load",
      function(event) {
        var target = event.target,
          tagName = target.tagName,
          popup = self.activeProperty.map._popup;

        if (tagName === "IMG" && popup) {
          popup.update();
        }
      },
      true
    ); // Capture the load event, because it does not bubble.
  },
  methods: {
    debouncedRefreshMap: _.debounce(function() {
      this.refreshMap();
    }, 1000),
    refreshMap() {
      this.clearMapLayers();
      this.fetchNearby();
    },
    clearMapLayers() {
      this.featureGroup.eachLayer(layer => {
        this.activeProperty.map.removeLayer(layer);
        this.featureGroup.removeLayer(layer);
      });
    },
    initialNearbyFetch(isVisible, entry) {
      if (isVisible) {
        this.fetchNearby();
      }
    },
    async fetchNearby() {
      const nearby = await fetchBoundingBoxProperties({
        map: this.activeProperty.map,
        router,
        store,
        featureGroup: this.featureGroup,
        focalProperties: [],
        strategic: false,
        heatmap: false,
        deduplicate: true,
        includeUnreferenced: true
      });

      if (nearby.length > 0) {
        this.$emit("has-nearby");
      } else {
        this.$emit("no-nearby");
      }
    }
  }
};
</script>
