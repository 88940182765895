<template>
  <div ref="intelMenu" @keydown.escape="menuOpen = false" class="relative inline-block text-left" style="z-index: 9998;">
    <div>
      <button @click="toggleMenu" class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600">
        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
        </svg>
      </button>
    </div>
    <transition
      enter-active-class="duration-150 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div
        v-show="menuOpen"
        class="origin-top-right absolute left-0 right-auto mt-2 w-56 rounded-md shadow-lg sm:right-0 sm:left-auto"
      >
        <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
          <router-link
            v-if="isAdmin && post.type !== 'helpRequest' && post.type !== 'standalonePurchaseable'"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="togglePremium"
          >
            <svg v-if="post.premium" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clip-rule="evenodd" />
              <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />
            </svg>
            <svg v-else class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd" />
            </svg>
            <template v-if="post.premium">Make free</template>
            <template v-else>Make premium</template>
          </router-link>
          <router-link
            v-if="post.type !== 'helpRequest' && post.type !== 'standalonePurchaseable'"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="purchaseableIntent"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
            </svg>
            Sell premium insights
          </router-link>
          <router-link
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="share"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
            </svg>
            Copy intel link
          </router-link>
          <router-link
            v-if="post.thumbnail && (isAdmin || post.authored)"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="removeCoverImage"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd" />
            </svg>
            Remove URL image
          </router-link>
          <router-link
            v-if="isAdmin || post.authored"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="editDate"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
            </svg>
            Backdate
          </router-link>
          <router-link
            v-if="isAdmin || post.authored"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="archive"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"/>
            </svg>
            Archive
          </router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import IntelDate from "../views/onboarding/market-intel/intel-date";
import PaymentConnectedAccountGateway from "./payment-connected-account-gateway";
import PurchaseableForm from "./purchaseables/purchaseable-form";

export default {
  props: ["post"],
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    ...mapState(["currentUser", "modal", "route"]),
    ...mapGetters(["isAdmin"])
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (this.$refs.intelMenu && !this.$refs.intelMenu.contains(evt.target)) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    editDate() {
      let intelCardModal = false;

      if (this.modal) {
        intelCardModal = _.cloneDeep(this.modal); // obtains the current component
        intelCardModal.props.post = this.post; // allows changes to flow through
      }

      this.$store.commit("mountIntel", { intel: this.post });
      this.$store.commit("openModal", {
        component: IntelDate,
        props: {
          postToken: this.post.token
        },
        afterClose: intelCardModal
      });
    },
    removeCoverImage() {
      this.$store.dispatch("removeURLThumbnail", {
        contentType: "intel",
        contentToken: this.post.token
      });
      this.menuOpen = false;
    },
    share() {
      this.$copyText(`https://towerhunt.com/open_intel/${this.post.token}`).then(
        () => this.$store.dispatch("flash", `Copied intel link to clipboard`),
        () => this.$store.dispatch("flash", "Failed to copy")
      );

      if (!!analytics && !this.adminMode) {
        analytics.track(`Copied intel link`, { yearWeek: this.$store.state.yearWeek });
      }
    },
    purchaseableIntent() {
      let intelCardModal = false;

      if (this.modal) {
        intelCardModal = _.cloneDeep(this.modal); // obtains the current component
        intelCardModal.props.post = this.post; // allows changes to flow through
      }

      this.$store.commit("openModal", {
        component: PaymentConnectedAccountGateway,
        props: {
          value: {
            component: PurchaseableForm,
            props: {
              contentType: "intel",
              contentToken: this.post.token,
              contentName: this.post.name,
              returnUrl: `https://towerhunt.com/open_intel/${this.post.token}`
            },
            afterClose: intelCardModal
          }
        }
      });
    },
    togglePremium() {
      this.$store.dispatch("toggleIntelPremium", this.post.token);
      this.post.premium = !this.post.premium;
    },
    archive() {
      this.$store.dispatch("deleteIntel", this.post.token);
      this.$store.commit("closeModal");
    }
  }
};
</script>
