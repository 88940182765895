<template>
  <li>
    <router-link
      @click.native="view"
      to=""
      class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
    >
      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="min-w-0 flex-1 flex items-center">
          <div class="flex-shrink-0">
            <img v-if="deal.coverPhoto" class="h-12 w-12 rounded-full object-cover object-center" :src="deal.coverPhoto" alt="Deal cover photo">
            <img v-else-if="primaryAttachmentIsImage" class="h-12 w-12 rounded-full object-cover object-center" :src="deal.primaryAttachment" alt="Deal attached photo">
            <img v-else-if="deal.thumbnail" class="h-12 w-12 rounded-full object-cover object-center" :src="deal.thumbnail" alt="Deal url thumbnail">
            <span v-else class="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-400">
              <svg class="h-5 w-5 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path fill="currentColor" d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
              </svg>
            </span>
          </div>
          <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <div class="text-sm leading-5 font-medium text-indigo-600 truncate">
                <span>{{ deal.name }}</span>
                <template v-if="deal.type === 'Lease'">
                  <span class="mx-1">
                    &middot;
                  </span>
                  <template v-if="!deal.closeDate">~</template>{{ deal | totalLeaseSize }}
                </template>
                <template v-else-if="deal.totalValue">
                  <span class="mx-1">
                    &middot;
                  </span>
                  <template v-if="!deal.closeDate">~</template>{{ deal.totalValue | largeDollar }}
                  <template v-if="deal.goingInPerUnit">({{ deal | perUnitPrice }})</template>
                </template>
              </div>
              <div class="mt-2 flex items-center text-sm leading-5 text-gray-500">
                <span class="truncate">
                  <span v-if="deal.properties && deal.properties.length > 0">{{ deal.properties | summarizedLocation }}</span>
                  <span class="mx-1">
                    &middot;
                  </span>
                  <span>{{ deal.useTypes }}</span>
                </span>
              </div>
            </div>
            <div class="hidden md:block">
              <div>
                <div class="flex items-center text-sm leading-5 text-gray-900">
                  <template v-if="deal.closeDate">
                    <time :datetime="deal.closeDate" class="mr-1">{{ closeDate }}</time>
                    <span>{{ deal.type | dealTypeAlias(deal.jointVentureRoles) }}</span>
                  </template>
                  <template v-else>
                    <span v-if="liveDeal" class="mr-1 inline-flex items-center justify-center h-3 w-3 rounded-full bg-red-400"></span>
                    {{ deal.state | capitalize }} {{ deal.type | dealTypeAlias(deal.jointVentureRoles) }}
                  </template>
                </div>
                <div v-if="notes" class="mt-2 flex items-center text-sm leading-5 text-gray-500 truncate">
                  {{ notes }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
        </div>
      </div>
    </router-link>
  </li>
</template>

<script>
import * as moment from "moment";
import DealCard from "./cards/deal";

export default {
  props: ["deal"],
  computed: {
    isStrategicMap() {
      return this.$store.state.route.path === "/map";
    },
    closeDate() {
      return moment(this.deal.closeDate).format("MMMM YYYY");
    },
    liveDeal() {
      return _.includes(["live", "awarded"], this.deal.state);
    },
    activeDeal() {
      return _.includes(["draft", "live", "awarded"], this.deal.state);
    },
    marketingListDeal() {
      return this.deal.source === "direct";
    },
    primaryAttachmentIsImage() {
      if (this.deal.primaryAttachmentType) {
        return _.includes(this.deal.primaryAttachmentType, "image");
      } else {
        return false;
      }
    },
    notes() {
      return this.deal.secondaryHeadline || this.deal.notes;
    }
  },
  methods: {
    truncated(text) {
      return _.truncate(text, { length: 50 });
    },
    view() {
      if (this.isStrategicMap) {
        this.$router.push({ path: `/map?deal=${this.deal.token}` });
      } else if (this.activeDeal && this.marketingListDeal && this.deal.dealTeam) {
        const marketingPage =
          this.deal.state === "draft" ? "overview" : "marketing-list";

        this.$router.push({
          path: `/deal_offerings/${this.deal.urlHandle || this.deal.token}/${marketingPage}`
        });
      } else if (this.liveDeal && this.marketingListDeal && this.deal.marketingReadAuthorized) {
        this.$router.push({
          path: `/deal_offerings/${this.deal.urlHandle || this.deal.token}/overview`
        });
      } else {
        this.$store.commit("openModal", {
          component: DealCard,
          props: {
            dealToken: this.deal.token
          }
        });
      }

      this.$store.commit("setSearchFlyover", false);
      this.$store.commit("clearSearch");
    }
  }
};
</script>
