<template>
  <div class="wrapper">
    <heading>What kinds of deals do you focus on?</heading>
    <button @click="next" type="button" class="cta mb-2" :class="{ complete: true }" tabindex="999">
      <template v-if="hasTypes && targetId">Save</template>
      <template v-else-if="hasTypes">Next</template>
      <template v-else>All of them</template>
    </button>
    <div class="content-wrap">
      <section class="vertical">
        <option-select
          v-for="option in matchingOptions"
          :key="option.name"
          @hover="hover(option.value)"
          @vacate="vacate"
          option-type="onboardingDealType"
          setter-name="addDealTypeWithGetters"
          forgetter-name="removeDealTypeWithGetters"
          setter-type="dispatch"
          :store-record="targetDealTypes"
          :option-record="option"
          layout="vertical"
          :options-count="matchingOptions.length" />
      </section>
      <option-description v-if="hovering && description" :description="description" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import dealTypes from "../../industry-role-deal-types";
import Heading from "../../components/onboarding/heading";
import OptionDescription from "../../components/onboarding/option-description";
import OptionSelect from "../../components/onboarding/option-select";

export default {
  props: ["targetId"],
  data() {
    return {
      options: dealTypes,
      hovering: null
    };
  },
  mounted() {
    if (this.onboarding && !this.onboardingInProcess) {
      this.$router.push({ path: "/start/1" });
    } else if (this.creatingTarget && !this.targetCreationInProcess) {
      this.$store.commit("concludeIntelCreation");
      this.$store.commit("clearIntel");
      this.$store.commit("activateTargetCreation");
    }
  },
  components: { Heading, OptionSelect, OptionDescription },
  computed: {
    ...mapState(["onboardingInProcess", "targetCreationInProcess", "currentUser", "newUser"]),
    ...mapGetters(["onboarding", "creatingTarget"]),
    industryRole() {
      if (this.targetId || this.creatingTarget) {
        return this.currentUser.industryRole;
      } else {
        return this.newUser.industryRole;
      }
    },
    matchingOptions() {
      return _.get(this.options, this.industryRole, []);
    },
    targetDealTypes() {
      return this.$store.state.activeTarget.deals;
    },
    optionData() {
      return _.find(this.matchingOptions, { value: this.hovering });
    },
    description() {
      if (this.optionData && this.optionData.description) {
        return this.optionData.description;
      } else {
        return null;
      }
    },
    hasTypes() {
      return this.targetDealTypes.length > 0;
    },
    doesCapitalMarkets() {
      return this.$store.getters.showDealSize;
    },
    doesJVInvestments() {
      return this.$store.getters.showJVRoles;
    }
  },
  methods: {
    next() {
      if (this.targetId) {
        this.$store.dispatch("updateTarget", this.targetId);
        this.$store.commit("closeModal");
      } else if (this.doesJVInvestments && this.creatingTarget) {
        this.$router.push({ path: "/targets/new/2" });
      } else if (this.doesCapitalMarkets) {
        if (this.onboarding) {
          this.$router.push({ path: "/start/5" });
        } else if (this.creatingTarget) {
          this.$router.push({ path: "/targets/new/3" });
        }
      } else {
        if (this.onboarding) {
          this.$router.push({ path: "/start/7" });
        } else if (this.creatingTarget) {
          this.$router.push({ path: "/targets/new/5" });
        }
      }
    },
    hover(option) {
      this.hovering = option;
    },
    vacate() {
      this.hovering = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.content-wrap {
  display: flex;
  flex-direction: column;
}
section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  pointer-events: none;
  &.horizontal {
    flex-wrap: wrap;
  }
  &.vertical {
    flex-direction: column;
    align-items: center;
  }
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
