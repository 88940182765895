<template>
  <div class="wrapper">
    <heading>What is the deal's status now?</heading>
    <div class="content-wrap">
      <section :class="orientation">
        <option-select
          v-for="option in matchingOptions"
          :key="option.name"
          option-type="dealState"
          setter-name="setDealState"
          setter-type="commit"
          :store-record="activeDeal.state"
          :option-record="option"
          :layout="orientation"
          :options-count="matchingOptions.length" />
      </section>
      <option-description v-if="description" :description="description" />
    </div>
    <button @click="next" type="button" class="cta my-4" :class="{ complete: complete }" tabindex="999">
      <template v-if="dealState === 'closed'">Next</template>
      <template v-else>Save</template>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DealSize from "./deal-size";
import Heading from "../../../components/onboarding/heading";
import LeaseSize from "./lease-size";
import LoanSeniority from "./loan-seniority";
import OptionDescription from "../../../components/onboarding/option-description";
import OptionSelect from "../../../components/onboarding/option-select";
import dealStates from "../../../deal-states";

export default {
  components: { Heading, OptionSelect, OptionDescription },
  props: ["dealId"],
  data() {
    return {
      options: dealStates,
      originalState: null,
      persisted: false
    };
  },
  computed: {
    ...mapState(["currentUser", "activeDeal", "modal"]),
    ...mapGetters([
      "closedDealLoanSale",
      "closedDealCapitalMarkets",
      "closedDealLease"
    ]),
    dealState() {
      return this.activeDeal.state;
    },
    orientation() {
      return this.matchingOptions.length > 3 ? "vertical" : "horizontal";
    },
    matchingOptions() {
      return this.options;
    },
    optionData() {
      return _.find(this.matchingOptions, { value: this.dealState });
    },
    description() {
      if (this.optionData && this.optionData.description) {
        return this.optionData.description;
      } else {
        return null;
      }
    },
    complete() {
      return this.dealState !== null && this.dealState !== this.originalState;
    }
  },
  mounted() {
    this.originalState = this.dealState;
  },
  destroyed() {
    if (!this.persisted) {
      this.$store.commit("setDealState", this.originalState);
    }
  },
  methods: {
    dealComponent() {
      if (this.closedDealLoanSale) {
        return LoanSeniority;
      } else if (this.closedDealCapitalMarkets) {
        return DealSize;
      } else if (this.closedDealLease) {
        return LeaseSize;
      }
    },
    closeWithChanges() {
      this.persisted = true;
      this.$store.commit("closeModal");
    },
    next() {
      if (this.complete) {
        switch (this.dealState) {
          case "live":
            if (this.originalState === "awarded") {
              this.$store.dispatch("dropDeal");
              this.closeWithChanges();
            }
            break;
          case "awarded":
            this.$store.dispatch("awardDeal");
            this.closeWithChanges();
            break;
          case "closed":
            this.persisted = true;
            this.$store.commit("openModal", {
              component: this.dealComponent(),
              props: {
                dealId: this.activeDeal.id,
                transitioningToClosed: true
              },
              afterClose: _.get(this.modal, "afterClose", null)
            });
            break;
          case "withdrawn":
            this.$store.dispatch("withdrawDeal");
            this.closeWithChanges();
            break;
          default:
            console.log("unknown deal state");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.content-wrap {
  display: flex;
  flex-direction: column;
  width: 330px;
}
section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  pointer-events: none;
  &.horizontal {
    flex-wrap: wrap;
  }
  &.vertical {
    flex-direction: column;
    align-items: center;
  }
}
button.cta {
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
