<template>
  <div class="wrapper">
    <heading>Follow {{ contact.name }} and send a message</heading>
    <form @submit.prevent="save" autocomplete="off" novalidate>
      <div class="input-container mb-2">
        <resizable-textarea>
          <textarea
            v-focus
            v-model="message"
            @focus="focus"
            @blur="blur"
            rows="1"
            autocorrect="off"
            spellcheck="false"
            placeholder=""
            tabindex="1"
            name="message"
            class="input-text black"
            :class="{ active: focused }"
          />
        </resizable-textarea>
      </div>
      <option-description description="A thoughtful message increases the chances someone outside your network will follow you back, becoming a mutual connection." />
      <button type="submit" class="cta" :class="{ complete: true }" tabindex="999">
        <template v-if="message === ''">Follow</template>
        <template v-else>Follow with message</template>
      </button>
    </form>
  </div>
</template>

<script>
import Heading from "./onboarding/heading";
import OptionDescription from "./onboarding/option-description";
import ResizableTextarea from "./resizable-textarea";

export default {
  components: { Heading, OptionDescription, ResizableTextarea },
  props: ["contact"],
  data() {
    return {
      message: "",
      focused: false
    };
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    save() {
      const payload = this.message === "" ? null : this.message;

      this.$store.dispatch("followContact", {
        contactToken: this.contact.token,
        message: payload
      });
      this.contact.followedByMe = true;
      this.$store.commit("closeModal");
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  width: 330px;
}
textarea {
  vertical-align: middle;
}
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.active {
    border: 2px solid #5850ec;
  }
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
