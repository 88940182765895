<template>
  <div>
    <div class="pb-6 px-6 sm:pb-10 sm:px-10">
      <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
        <svg class="h-6 w-6 text-green-600" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
      </div>
      <div class="mt-3 text-center sm:mt-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Every market is its own network
        </h3>
        <div class="mt-2">
          <p class="text-sm leading-5 text-gray-500">
            An organized space for this market's intel, people, and deals.
          </p>
        </div>
      </div>
    </div>
    <div class="border-t-2 border-gray-100 rounded-b-lg pt-6 sm:pt-10 pb-4 sm:pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
      <ul>
        <li class="flex items-start">
          <div class="flex-shrink-0">
            <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
          </div>
          <p class="ml-3 text-base leading-6 font-medium text-gray-500">
            You can tailor your intel and deal notifications for this market from the dropdowns in those sections.
          </p>
        </li>
        <li v-if="userIsActiveContact" class="mt-4 flex items-start">
          <div class="flex-shrink-0">
            <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
          </div>
          <p class="ml-3 text-base leading-6 font-medium text-gray-500">
            There are additional criteria you can specify, such as deal size and loan seniority.
          </p>
        </li>
        <li v-if="userIsActiveContact" class="mt-4 flex items-start">
          <div class="flex-shrink-0">
            <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
          </div>
          <p class="ml-3 text-base leading-6 font-medium text-gray-500">
            If you don't want this market to appear on your profile, you can make it private from the criteria settings.
          </p>
        </li>
      </ul>
      <div class="mt-6 sm:mt-10">
        <span class="flex w-full rounded-md shadow-sm">
          <button @click="dismiss" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo sm:text-sm sm:leading-5">
            Got it
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapGetters(["userIsActiveContact"])
  },
  methods: {
    dismiss() {
      this.$store.dispatch("dismissMarketPageWelcome");
    }
  }
};
</script>
