<template>
  <span @click="toggleVisibility" v-tooltip="toggleHelp" role="checkbox" tabindex="0" :aria-checked="isPaid" :class="isPaid ? 'bg-indigo-600' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring">
    <span aria-hidden="true" :class="isPaid ? 'translate-x-5' : 'translate-x-0'" class="relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200">
      <span :class="isPaid ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity">
        <svg class="h-3 w-3 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
          <path fill-rule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clip-rule="evenodd" />
          <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />
        </svg>
      </span>
      <span :class="isPaid ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity">
        <svg class="h-5 w-5 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path d="M12 8C10.343 8 9 8.895 9 10C9 11.105 10.343 12 12 12C13.657 12 15 12.895 15 14C15 15.105 13.657 16 12 16M12 8V16M12 8C13.11 8 14.08 8.402 14.599 9L12 8ZM12 8V7M12 16V17M12 16C10.89 16 9.92 15.598 9.401 15L12 16Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </span>
    </span>
  </span>
</template>

<script>
import api from "../../api";

export default {
  props: ["attachment"],
  computed: {
    isPaid() {
      return this.attachment.visibility === "paid";
    },
    toggleHelp() {
      if (this.isPaid) {
        return "Only paying customers can access this file. Click to make this a preview instead.";
      } else {
        return "Anyone browsing can see this file as a preview. Click to make this for paid customers instead.";
      }
    }
  },
  methods: {
    toggleVisibility() {
      if (this.isPaid) {
        this.persist("preview");
      } else {
        this.persist("paid");
      }
    },
    persist(visibility) {
      api
        .patch(`purchaseable_attachments/${this.attachment.id}`, { visibility })
        .then(json => {
          this.$store.dispatch("flash", "File visibility updated.");
          this.attachment.visibility = visibility;
        });
    }
  }
};
</script>
