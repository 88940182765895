<template>
  <div></div>
</template>

<script>
import api from "../api";

export default {
  mounted() {
    api
      .post(`payment_connected_accounts`, {
        returnUrl: `https://towerhunt.com/home/settings`
      })
      .then(json => {
        if (json.data.url) {
          window.location.href = json.data.url;
        } else {
          this.$router.push({ path: "/home/settings" });
        }
      });
  }
};
</script>
