<template>
  <div v-if="post">
    <content-visibility :content="post" content-type="intel" :compact="false" />
    <div class="border-b border-gray-200 px-4 py-5 sm:px-6" v-observe-visibility="{ callback: createImpression, once: true }">
      <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div class="ml-4 mt-2">
          <div class="flex items-center">
            <div v-if="post.author" @click="viewProfile" v-tooltip="authorTooltip" class="flex-shrink-0 cursor-pointer">
              <avatar-photo :person="post.author" circle-size="12" text-size="lg" />
            </div>
            <span
              v-else
              v-tooltip="'Posted anonymously'"
              class="h-12 w-12 flex-shrink-0 inline-flex items-center justify-center rounded-full bg-gray-400"
            >
              <svg class="h-5 w-5 text-white" fill="currentColor" viewBox="0 0 384 512">
                <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
              </svg>
            </span>
            <div class="ml-4">
              <h3 class="flex flex-wrap text-lg leading-6 font-medium text-gray-900">
                <template v-if="url && !canEdit">
                  <a @click="logExternalLinkClick()" :href="url" v-tooltip="'This post links to a page outside of Tower Hunt'" target="_blank" class="flex items-center text-indigo-600 hover:text-indigo-500">
                    <span class="mr-1.5">
                      <svg class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
                      </svg>
                    </span>
                    {{ post.name }}
                  </a>
                </template>
                <template v-else-if="canEdit && post.type !== 'standalonePurchaseable'">
                  <a
                    @click.prevent="openModal({ record: post, type: 'intel-name' })"
                    v-tooltip="'Edit post title'"
                    class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
                  >
                    <span>{{ post.name }}</span>
                    <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
                  </a>
                </template>
                <template v-else>
                  {{ post.name }}
                </template>

                <template v-if="post.type === 'tenantInMarket' && canEdit">
                  <a
                    v-if="canEdit"
                    @click.prevent="openModal({ record: post, type: 'intel-lease-size' })"
                    v-tooltip="'Edit requirement size'"
                    class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
                  >
                    <span>: {{ post | totalLeaseSize }}</span>
                    <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
                  </a>
                </template>
                <template v-else-if="post.type === 'tenantInMarket'">: {{ post | totalLeaseSize }}</template>
              </h3>
              <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">
                <div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
                  <intel-type-badge :post="post" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="canAccessMenu" class="ml-4 mt-2 flex-shrink-0">
          <intel-menu :post="post" />
        </div>
      </div>
    </div>
    <preview-card-social-footer v-if="post.type !== 'standalonePurchaseable'" :content="post" :property="property" content-type="intel" :bottom-border="hasBody && post.premiumCount === 0" :thankable="true" />
    <bounty-actions v-if="post.bounty" :bounty="post.bounty" :flat="true" />
    <premium-content-button v-else-if="post.premiumCount > 0" :content="post" :flat="true" />
    <div v-if="hasBody" class="px-4 py-5 sm:p-6">
      <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div v-if="(post.notes || canEdit) && post.type !== 'standalonePurchaseable'" class="sm:col-span-2">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            <template v-if="canEdit">
              <a
                @click.prevent="openModal({ record: post, type: 'intel-notes' })"
                class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
              >
                <span>User commentary</span>
                <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
              </a>
            </template>
            <template v-else>User commentary</template>
          </dt>
          <dd v-if="post.notes" v-html="linkifiedNotes" class="mt-1 text-sm leading-5 text-gray-900 whitespace-pre-line dont-break-out" />
          <dd v-else class="mt-1 text-sm leading-5 text-gray-900 whitespace-pre-line">...</dd>
        </div>
        <div v-if="mappable" class="sm:col-span-1">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Location
          </dt>
          <div class="mt-4 h-48 relative">
            <div class="absolute inset-x-0 top-0 transform translate-y-px pointer-events-none" style="z-index: 9995;">
              <div class="flex justify-center transform -translate-y-1/2">
                <a @click.prevent="strategicMap" href="" class="inline-flex items-center rounded-full text-sm px-4 py-1 leading-5 bg-indigo-600 hover:bg-indigo-700 font-semibold tracking-wider uppercase text-white pointer-events-auto">
                  <!-- Heroicon name: cursor-click -->
                  <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z" clip-rule="evenodd" />
                  </svg>
                  See comps on strategic map
                </a>
              </div>
            </div>

            <intel-map :post="post" />
          </div>
        </div>
        <div v-if="(url || canEdit) && post.type !== 'standalonePurchaseable'" class="iframely-embed" :class="mappable ? 'sm:col-span-1' : 'sm:col-span-2'">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            <template v-if="canEdit">
              <a
                @click.prevent="openModal({ record: post, type: 'intel-url' })"
                class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
              >
                <span>External link</span>
                <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
              </a>
            </template>
            <template v-else>External Link</template>
          </dt>
          <dd v-if="url" class="mt-1 text-sm leading-5 text-gray-900 whitespace-pre-line">
            <div :id="`${post.token}-url`" class="mt-4 mb-2 iframely-embed">
              <a data-iframely-url :href="url"></a>
            </div>
            <failed-url-preview-empty-state v-if="urlPreviewFailed" :url="url" />
          </dd>
          <dd v-else class="mt-1 text-sm leading-5 text-gray-900 whitespace-pre-line">...</dd>
        </div>
        <div v-if="post.primaryAttachment" :class="mappable ? 'sm:col-span-1' : 'sm:col-span-2'">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Attached file
          </dt>
          <div :id="`${post.token}-attachment`" class="mt-4 mb-2 iframely-embed">
            <a data-iframely-url :href="post.primaryAttachment"></a>
          </div>
          <failed-url-preview-empty-state v-if="attachmentPreviewFailed" :url="post.primaryAttachment" />
        </div>
        <div v-if="post.type == 'developmentNews' && shouldDisplay('properties')" class="sm:col-span-1">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Physical size
          </dt>
          <dd class="mt-1 text-sm leading-5 text-gray-900">
            {{ post.properties | totalSize }}
          </dd>
        </div>
        <div v-if="allowsLocation" class="sm:col-span-1">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Location
          </dt>
          <dd class="mt-1 text-sm leading-5 text-gray-900">
            <a
              v-if="canEdit"
              @click.prevent="openModal({ record: post, type: 'intel-location' })"
              v-tooltip="'Edit location'"
              class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
            >
              <span>{{ post | location }}</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </a>
            <template v-else>{{ post | location }}</template>
          </dd>
        </div>
        <div v-if="allowsRegions && shouldDisplay('regions')" class="sm:col-span-1">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Regions
          </dt>
          <dd class="mt-1 text-sm leading-5 text-gray-900">
            <a
              v-if="canEdit"
              @click.prevent="openModal({ record: post, type: 'intel-regions' })"
              v-tooltip="'Edit regions'"
              class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
            >
              <span v-if="has('regions')">{{ post.regions | regionLabels }}</span>
              <span v-else>Add regions...</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </a>
            <template v-else-if="has('regions')">{{ post.regions | regionLabels }}</template>
          </dd>
        </div>
        <div v-if="allowsProperties && shouldDisplay('properties')" class="sm:col-span-1">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Properties
          </dt>
          <dd class="mt-1 text-sm leading-5 text-gray-900">
            <a
              v-if="canEdit"
              @click.prevent="openModal({ record: post, type: 'intel-properties' })"
              v-tooltip="'Edit properties'"
              class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
            >
              <span v-if="has('properties')">{{ post.properties | regionLabels }}</span>
              <span v-else>Add properties...</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </a>
            <template v-else>{{ post.properties | regionLabels }}</template>
          </dd>
        </div>
        <div v-if="allowsDealTypes && shouldDisplay('deals')" class="sm:col-span-1">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Deal types
          </dt>
          <dd class="mt-1 text-sm leading-5 text-gray-900">
            <a
              v-if="canEdit"
              @click.prevent="openModal({ record: post, type: 'intel-deal-types' })"
              v-tooltip="'Edit deal types'"
              class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
            >
              <span v-if="has('deals')">{{ post.deals | sortedTypeLabels }}</span>
              <span v-else>Add deal types...</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </a>
            <template v-else-if="has('deals')">{{ post.deals | sortedTypeLabels }}</template>
          </dd>
        </div>
        <div v-if="allowsUses && shouldDisplay('uses')" class="sm:col-span-1">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Property uses
          </dt>
          <dd class="mt-1 text-sm leading-5 text-gray-900">
            <a
              v-if="canEdit"
              @click.prevent="openModal({ record: post, type: 'intel-use-types' })"
              v-tooltip="'Edit property uses'"
              class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
            >
              <span v-if="has('uses')">{{ post.uses | sortedTypeLabels }}</span>
              <span v-else>Add uses...</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </a>
            <template v-else-if="has('uses')">{{ post.uses | sortedTypeLabels }}</template>
          </dd>
        </div>
        <div v-if="allowsRisks && shouldDisplay('risks')" class="sm:col-span-1">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Strategies
          </dt>
          <dd class="mt-1 text-sm leading-5 text-gray-900">
            <a
              v-if="canEdit"
              @click.prevent="openModal({ record: post, type: 'intel-risk-types' })"
              v-tooltip="'Edit strategies'"
              class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
            >
              <span v-if="has('risks')">{{ post.risks | sortedTypeLabels }}</span>
              <span v-else>Add strategies...</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </a>
            <template v-else-if="has('risks')">{{ post.risks | sortedTypeLabels }}</template>
          </dd>
        </div>
        <div v-if="allowsDates" class="sm:col-span-1">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Event date
          </dt>
          <dd class="mt-1 text-sm leading-5 text-gray-900">
            <a
              v-if="canEdit"
              @click.prevent="openModal({ record: post, type: 'event-dates' })"
              v-tooltip="'Edit event date(s)'"
              class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
            >
              <span v-if="post.dateType">{{ post | eventDates }}</span>
              <span v-else>Add date...</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </a>
            <template v-else-if="post.dateType">{{ post | eventDates }}</template>
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Engagement
          </dt>
          <impression-metrics :content="post" class="mt-1" />
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AvatarPhoto from "../avatar-photo";
import Comments from "../comments";
import ContentVisibility from "./content-visibility.vue";
import FailedUrlPreviewEmptyState from "../failed-url-preview-empty-state.vue";
import ImpressionMetrics from "./impression-metrics";
import IntelDealTypes from "../../views/onboarding/market-intel/intel-deal-type";
import IntelEventDates from "../../views/onboarding/market-intel/intel-event-dates";
import IntelLeaseSize from "../../views/onboarding/market-intel/intel-lease-size";
import IntelLocation from "../../views/onboarding/market-intel/intel-location";
import IntelMap from "../maps/market-intel-map";
import IntelMenu from "../intel-menu";
import IntelName from "../../views/onboarding/market-intel/intel-name";
import IntelNotes from "../../views/onboarding/general-notes";
import IntelProperties from "../../views/onboarding/market-intel/intel-properties";
import IntelRegions from "../../views/onboarding/market-intel/intel-regions";
import IntelRiskTypes from "../../views/onboarding/market-intel/intel-risk-type";
import IntelTypeBadge from "../intel-type-badge";
import IntelUrl from "../../views/onboarding/general-url";
import IntelUseTypes from "../../views/onboarding/market-intel/intel-use-type";
import PreviewCardSocialFooter from "./preview-card-social-footer";
import api from "../../api";
import cleanUrl from "../../clean-url";
import linkifyHtml from "linkifyjs/html";
/* global iframely */
/* global analytics */

export default {
  components: {
    AvatarPhoto,
    ContentVisibility,
    FailedUrlPreviewEmptyState,
    ImpressionMetrics,
    IntelMap,
    IntelMenu,
    IntelTypeBadge,
    PreviewCardSocialFooter
  },
  props: [
    "postObject",
    "postToken",
    "updatePurchaseables",
    "showcaseId",
    "property",
    "openComments"
  ],
  data() {
    return {
      post: null,
      urlPreviewFailed: false,
      attachmentPreviewFailed: false
    };
  },
  computed: {
    ...mapState(["now", "route", "modal", "currentUser", "adminMode"]),
    canAccessMenu() {
      return true;
    },
    authorTooltip() {
      const name = this.$options.filters.capitalize(this.post.author.name);
      const role = _.capitalize(this.$options.filters.industryRoleAlias(this.post.author.industryRole, this.post.author.industrySubRole, this.post.author.token));

      if (this.post.author.company) {
        return `${name} (${role} at ${this.post.author.company})`;
      } else {
        return `${name} (${role})`;
      }
    },
    isVisible() {
      return (
        this.post.pinned ||
        !this.post.anonymous ||
        (!this.post.dismissed && !this.post.downvoted)
      );
    },
    url() {
      if (this.post && this.post.url) {
        return cleanUrl(this.post.url);
      } else {
        return null;
      }
    },
    primaryAttachment() {
      if (this.post && this.post.primaryAttachment) {
        return cleanUrl(this.post.primaryAttachment);
      } else {
        return null;
      }
    },
    linkifiedNotes() {
      return linkifyHtml(_.get(this.post, "notes", ""), {
        defaultProtocol: "https",
        className: "font-medium text-indigo-600 hover:text-indigo-500"
      });
    },
    canEdit() {
      return this.post.authored || this.currentUser.admin;
    },
    hasBody() {
      return (
        this.mappable ||
        this.post.notes ||
        this.url ||
        this.post.primaryAttachment ||
        this.post.type === "standalonePurchaseable"
      );
    },
    mappable() {
      return (
        this.has("regions") || this.post.location || this.has("properties")
      );
    },
    modalIntel() {
      return _.get(this.modal, "props.post", null);
    },
    allowsDates() {
      return _.includes(["industryEvent"], this.post.type);
    },
    allowsLocation() {
      return _.includes(["jobPosting", "industryEvent"], this.post.type);
    },
    allowsDealTypes() {
      return _.includes(["jobPosting", "industryEvent", "marketCommentary", "helpRequest", "marketReport", "standalonePurchaseable"], this.post.type);
    },
    allowsRisks() {
      return _.includes(["jobPosting", "industryEvent", "marketCommentary", "helpRequest", "marketReport", "standalonePurchaseable"], this.post.type);
    },
    allowsUses() {
      return _.includes(["jobPosting", "industryEvent", "marketCommentary", "helpRequest", "marketReport", "standalonePurchaseable"], this.post.type);
    },
    allowsProperties() {
      return _.includes(["developmentNews", "marketCommentary", "helpRequest", "marketReport", "standalonePurchaseable"], this.post.type);
    },
    allowsRegions() {
      return _.includes(["jobPosting", "industryEvent", "tenantInMarket", "infrastructureNews", "marketCommentary", "helpRequest", "marketReport", "standalonePurchaseable"], this.post.type);
    }
  },
  watch: {
    postObject: {
      handler() {
        if (this.postObject) {
          this.post = null;
          var self = this;

          setTimeout(() => {
            self.post = self.postObject;
          }, 250);
        }
      },
      deep: true
    },
    modalIntel: {
      handler() {
        if (this.modalIntel) {
          this.post = this.modalIntel;
          this.checkForCommentsHash();
        }
      },
      deep: true
    },
    url: {
      handler() {
        if (/^https?:\/\//i.test(this.url) && window.iframely) {
          setTimeout(() => {
            iframely.load();
          }, 500);

          var self = this;

          iframely.on("cancel", function(url, parentNode) {
            if (parentNode.id === `${self.post.token}-url`) {
              self.urlPreviewFailed = true;
            } else if (parentNode.id === `${self.post.token}-attachment`) {
              self.attachmentPreviewFailed = true;
            }

            parentNode.remove();
          });
        }
      }
    },
    primaryAttachment: {
      handler() {
        if (/^https?:\/\//i.test(this.primaryAttachment) && window.iframely) {
          setTimeout(() => {
            iframely.load();
          }, 500);

          var self = this;

          iframely.on("cancel", function(url, parentNode) {
            if (parentNode.id === `${self.post.token}-url`) {
              self.urlPreviewFailed = true;
            } else if (parentNode.id === `${self.post.token}-attachment`) {
              self.attachmentPreviewFailed = true;
            }

            parentNode.remove();
          });
        }
      }
    }
  },
  mounted() {
    this.$store.commit("clearDeal");
    this.$store.commit("clearDealMarketing");

    if (this.modalIntel && !this.post) {
      this.post = this.modalIntel;
      this.$store.commit("mountIntel", { intel: this.modalIntel, showcaseId: this.showcaseId });
      this.checkForCommentsHash();
    } else if (this.postToken && !this.post) {
      this.$store.commit("isLoading");
      api.get(`open_intel/${this.postToken}`).then(
        json => {
          this.post = json.data;
          this.$store.commit("mountIntel", { intel: json.data, showcaseId: this.showcaseId });
          this.$store.commit("doneLoading");
          this.checkForCommentsHash();
        },
        failure => {
          this.$store.dispatch("flash", "Invalid sharing link");
          this.$store.commit("doneLoading");
          this.closeModal();
        }
      );
    } else {
      this.post = this.postObject;
      this.checkForCommentsHash();
    }

    if (window.iframely && !!analytics && !this.adminMode) {
      var self = this;

      iframely.on("click", function(href) {
        self.logExternalLinkClick();

        analytics.track("Clicked intel URL preview card", {
          href,
          yearWeek: self.$store.state.yearWeek
        });
      });
    }
  },
  beforeCreate() {
    this.$options.components.BountyActions = require("../bounties/bounty-actions.vue").default;
    this.$options.components.PremiumContentButton = require("./premium-content-button.vue").default;
  },
  destroyed() {
    if (this.updatePurchaseables) {
      this.$store.commit("setRefetchPurchaseables", true);
    }
  },
  methods: {
    shouldDisplay(collectionName) {
      return this.has(collectionName) || this.canEdit;
    },
    has(collectionName) {
      return _.get(this.post, collectionName, []).length > 0;
    },
    checkForCommentsHash() {
      if (this.openComments || _.includes(this.route.hash, "#comments")) {
        this.viewComments();
      }
    },
    logExternalLinkClick() {
      this.$store.dispatch("logExternalLinkClick", {
        contentType: this.post.newsfeedType,
        contentToken: this.post.token
      });
    },
    createImpression(isVisible, entry) {
      if (!this.adminMode && isVisible && this.post.token) {
        this.$store.dispatch("createIntelImpression", this.post.token).then(() => {
          this.$store.dispatch("loadUnreadCounts");
        });
      }
    },
    share(post) {
      this.$copyText(this.sharePath(post)).then(
        () => this.$store.dispatch("flash", "Copied link"),
        () => this.$store.dispatch("flash", "Failed to copy")
      );
    },
    sharePath(post) {
      if (!!analytics && !this.adminMode) {
        analytics.track("Shared intel", {
          yearWeek: this.$store.state.yearWeek
        });
      }

      const urlRoot =
        location.protocol +
        "//" +
        location.hostname +
        (location.port ? ":" + location.port : "");

      return `${urlRoot}/open_intel/${post.token}`;
    },
    pin(post) {
      this.$store.dispatch("pinIntel", post.token);
      post.pinned = !post.pinned;

      if (!!analytics && !this.adminMode) {
        analytics.track("Upvoted intel", {
          yearWeek: this.$store.state.yearWeek
        });
      }
    },
    unpin(post) {
      this.$store.dispatch("unpinIntel", post.token);
      post.pinned = !post.pinned;
    },
    dismiss(post) {
      this.$store.dispatch("dismissIntel", post.token);
      this.$store.commit("closeModal");
    },
    downvote(post) {
      this.$store.dispatch("downvoteIntel", post.token);
      this.$store.commit("closeModal");
    },
    viewProfile() {
      this.$router.push({ path: `/contacts/${this.post.author.token}` });
      this.$store.commit("closeModal");
    },
    viewComments() {
      this.$store.commit("openSlideover", {
        component: Comments,
        props: {
          content: this.post
        }
      });

      if (!!analytics && !this.adminMode) {
        analytics.track("Viewed post comments", {
          yearWeek: this.$store.state.yearWeek
        });
      }
    },
    strategicMap() {
      this.$router.push({ path: `/map?intel=${this.post.token}` });
      this.$store.commit("clearModal");
    },
    openModal({ record, type }) {
      let intelCardModal = false;

      if (this.modal) {
        intelCardModal = _.cloneDeep(this.modal); // obtains the current component
        intelCardModal.props.post = record; // allows changes to flow through
      }

      switch (type) {
        case "intel-name":
          this.$store.commit("mountIntel", { intel: record, showcaseId: this.showcaseId });
          this.$store.commit("openModal", {
            component: IntelName,
            props: {
              postToken: record.token
            },
            afterClose: intelCardModal
          });
          break;
        case "intel-notes":
          this.$store.commit("mountIntel", { intel: record, showcaseId: this.showcaseId });
          this.$store.commit("openModal", {
            component: IntelNotes,
            props: {
              postToken: record.token
            },
            afterClose: intelCardModal
          });
          break;
        case "intel-url":
          this.$store.commit("mountIntel", { intel: record, showcaseId: this.showcaseId });
          this.$store.commit("openModal", {
            component: IntelUrl,
            props: {
              postToken: record.token
            },
            afterClose: intelCardModal
          });
          break;
        case "intel-lease-size":
          this.$store.commit("mountIntel", { intel: record, showcaseId: this.showcaseId });
          this.$store.commit("openModal", {
            component: IntelLeaseSize,
            props: {
              postToken: record.token
            },
            afterClose: intelCardModal
          });
          break;
        case "event-dates":
          this.$store.commit("mountIntel", { intel: record, showcaseId: this.showcaseId });
          this.$store.commit("openModal", {
            component: IntelEventDates,
            props: {
              postToken: record.token
            },
            afterClose: intelCardModal
          });
          break;
        case "intel-use-types":
          this.$store.commit("mountIntel", { intel: record, showcaseId: this.showcaseId });
          this.$store.commit("openModal", {
            component: IntelUseTypes,
            props: {
              postToken: record.token
            },
            afterClose: intelCardModal
          });
          break;
        case "intel-risk-types":
          this.$store.commit("mountIntel", { intel: record, showcaseId: this.showcaseId });
          this.$store.commit("openModal", {
            component: IntelRiskTypes,
            props: {
              postToken: record.token
            },
            afterClose: intelCardModal
          });
          break;
        case "intel-deal-types":
          this.$store.commit("mountIntel", { intel: record, showcaseId: this.showcaseId });
          this.$store.commit("openModal", {
            component: IntelDealTypes,
            props: {
              postToken: record.token
            },
            afterClose: intelCardModal
          });
          break;
        case "intel-location":
          this.$store.commit("mountIntel", { intel: record, showcaseId: this.showcaseId });
          this.$store.commit("openModal", {
            component: IntelLocation,
            props: {
              postToken: record.token
            },
            afterClose: intelCardModal
          });
          break;
        case "intel-regions":
          this.$store.commit("mountIntel", { intel: record, showcaseId: this.showcaseId });
          this.$store.commit("openModal", {
            component: IntelRegions,
            props: {
              postToken: record.token
            },
            afterClose: intelCardModal
          });
          break;
        case "intel-properties":
          this.$store.commit("mountIntel", { intel: record, showcaseId: this.showcaseId });
          this.$store.commit("openModal", {
            component: IntelProperties,
            props: {
              postToken: record.token
            },
            afterClose: intelCardModal
          });
          break;
      }
    }
  }
};
</script>
