<template>
  <div>
    <people-grid v-if="principals.pagy" :people-data="principals" industry-role="principal" :can-invite="true" @fetch="gridFetch" />
    <people-grid v-if="brokers.pagy" :people-data="brokers" industry-role="broker" :can-invite="true" @fetch="gridFetch" />
    <people-grid v-if="observers.pagy" :people-data="observers" industry-role="observer" :can-invite="true" @fetch="gridFetch" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PeopleGrid from "../people-grid.vue";
import _ from "lodash";

export default {
  components: { PeopleGrid },
  props: ["targetToken"],
  computed: {
    ...mapState([
      "now",
      "activeTarget",
      "refetchTargetPage",
      "refetchRumoredDeals",
      "currentUser",
      "modal"
    ]),
    ...mapGetters([
      "signedIn",
      "userIsActiveContact",
      "activeTargetDeals",
      "showDealSize",
      "showLeaseSize",
      "showLeverageSize",
      "showLoanTypes",
      "showJVRoles",
      "targetHasPropertySize",
      "privacyMode"
    ]),
    targetPeopleData() {
      return this.activeTarget.people;
    },
    principals() {
      return _.get(this.targetPeopleData, "principal", { data: [], pagy: null });
    },
    brokers() {
      return _.get(this.targetPeopleData, "broker", { data: [], pagy: null });
    },
    observers() {
      return _.get(this.targetPeopleData, "observer", { data: [], pagy: null });
    }
  },
  watch: {
    targetToken: {
      handler: function() {
        this.fetchAll();
      }
    }
  },
  mounted() {
    this.fetchAll();
  },
  methods: {
    gridFetch({ url, industryRole }) {
      this.fetchPeople(url, industryRole);
    },
    fetchAll() {
      this.fetchPeople(null, "principal");
      this.fetchPeople(null, "broker");
      this.fetchPeople(null, "observer");
    },
    fetchPeople(url = null, industryRole = "principal") {
      const cleanUrl = url
        ? _.drop(url, 8).join("")
        : `target_people/${this.targetToken}`;
      let roleParam = "";

      if (_.includes(cleanUrl, "?page=")) {
        roleParam = `&industry_role=${industryRole}`;
      } else if (_.includes(cleanUrl, "&page=")) {
        roleParam = "";
      } else {
        roleParam = `?industry_role=${industryRole}`;
      }

      const fullUrl = `${cleanUrl}${roleParam}`;

      this.$store.dispatch("loadTargetPeople", { url: fullUrl, industryRole });
    }
  }
};
</script>

<style lang="scss" scoped>
.list-complete-item {
  transition: all 2s;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.list-complete-leave-active {
  position: absolute;
}
</style>
