<template>
  <div>
    <div>
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Personal Information
            </h3>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              Decide what to share with others.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form @submit.prevent.stop="saveContactInfo">
            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="mt-6">
                  <label for="photo" class="block text-sm leading-5 font-medium text-gray-700">
                    Photo
                  </label>
                  <div class="mt-2 flex items-center">
                    <img v-if="photo" class="inline-block h-12 w-12 rounded-full object-cover object-center" :src="photo" alt="" />
                    <span v-else class="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                      <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                    <span class="ml-5">
                      <button @click="launchFilePicker" type="button" class="rounded-md shadow-sm py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800">
                        Change
                      </button>
                      <input
                        ref="userAvatar"
                        type="file"
                        accept="image/*"
                        :data-direct-upload-url="fileUploadUrl"
                        class="hidden"
                        name="User photo"
                        @change="uploadAvatar">
                    </span>
                  </div>
                  <div class="mt-2">
                    <span v-if="avatarError" class="text-sm text-red-600">{{ avatarError }}</span>
                    <span v-else class="text-sm text-gray-500">The file size must be under 3MB. Use a square-sized image.</span>
                  </div>
                </div>

                <div class="mt-6">
                  <div>
                    <label
                      for="person_name"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >Your name</label
                    >
                    <input
                      v-model="name"
                      id="person_name"
                      type="text"
                      class="mt-1 block w-full py-2 px-3 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>

                <div class="mt-6">
                  <div>
                    <label
                      for="company"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >Company</label
                    >
                    <div class="mt-1 flex rounded-md shadow-sm">
                      <div class="relative flex-grow focus-within:z-10">
                        <div
                          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                        >
                          <svg
                            class="h-5 w-5 text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                              clip-rule="evenodd"
                            />
                            <path
                              d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"
                            />
                          </svg>
                        </div>
                        <input
                          v-model="company"
                          id="company"
                          type="text"
                          class="border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm sm:leading-5"
                        />
                      </div>
                      <button
                        v-tooltip="'Anyone will be able to see this. Not editable.'"
                        type="button"
                        class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700"
                      >
                        <svg
                          class="h-5 w-5 text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                          <path
                            fill-rule="evenodd"
                            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span class="ml-2">Public</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="mt-6">
                  <div>
                    <label
                      for="email"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >Market-facing email</label
                    >
                    <div class="mt-1 flex rounded-md shadow-sm">
                      <div class="relative flex-grow focus-within:z-10">
                        <div
                          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                        >
                          <svg
                            class="h-5 w-5 text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                            />
                            <path
                              d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                            />
                          </svg>
                        </div>
                        <input
                          v-model="marketEmail"
                          id="email"
                          type="email"
                          class="border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm sm:leading-5"
                        />
                      </div>
                      <button
                        @click="toggle('shareMarketEmail')"
                        v-tooltip="privacyTooltipFor('shareMarketEmail')"
                        type="button"
                        class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700"
                      >
                        <svg
                          v-if="shareMarketEmail"
                          class="h-5 w-5 text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                          <path
                            fill-rule="evenodd"
                            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <svg
                          v-else
                          class="h-5 w-5 text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                        </svg>
                        <span v-if="shareMarketEmail" class="ml-2">Public</span>
                        <span v-else class="ml-2">Private</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="mt-6">
                  <div>
                    <label
                      for="phone"
                      class="block text-sm font-medium leading-5 text-gray-700"
                    >Market-facing phone</label
                    >
                    <div class="mt-1 flex rounded-md shadow-sm">
                      <div class="relative flex-grow focus-within:z-10">
                        <div
                          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                        >
                          <svg
                            class="h-5 w-5 text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
                            />
                          </svg>
                        </div>
                        <input
                          v-model="phone"
                          id="phone"
                          type="text"
                          class="border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm sm:leading-5"
                        />
                      </div>
                      <button
                        @click="toggle('sharePhone')"
                        v-tooltip="privacyTooltipFor('sharePhone')"
                        type="button"
                        class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700"
                      >
                        <svg
                          v-if="sharePhone"
                          class="h-5 w-5 text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                          <path
                            fill-rule="evenodd"
                            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <svg
                          v-else
                          class="h-5 w-5 text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                        </svg>
                        <span v-if="sharePhone" class="ml-2">Public</span>
                        <span v-else class="ml-2">Private</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <span class="inline-flex rounded-md shadow-sm">
                  <button
                    type="submit"
                    class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700"
                  >
                    Save
                  </button>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="hidden sm:block">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 id="industry-roles" class="text-lg font-medium leading-6 text-gray-900">Industry Role</h3>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              What is your primary focus?
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form>
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <fieldset>
                  <legend class="text-base leading-6 font-medium text-gray-900">Industry role</legend>
                  <p :class="canChangeIndustryRole ? 'text-gray-500' : 'text-red-600'" class="text-sm leading-5">
                    You can only change this once every 6 months.
                  </p>
                  <div v-for="(role, index) in industryRoles" :key="role.value" class="mt-4 flex items-start">
                    <div class="absolute flex items-center h-5">
                      <input v-model="industryRole" @change="setRole" :id="`${role.value}_role`" :value="role.value" :disabled="!canChangeIndustryRole" :class="canChangeIndustryRole ? 'text-indigo-600 focus:ring-indigo-500' : 'text-gray-300 cursor-not-allowed'" name="push_notifications" type="radio" class="border-gray-300 h-4 w-4" />
                    </div>
                    <div class="pl-7 text-sm leading-5">
                      <label :for="`${role.value}_role`" class="font-medium text-gray-700" :class="canChangeIndustryRole ? '' : 'cursor-not-allowed'">{{ role.name }}</label>
                      <p class="text-gray-500">{{ role.description }}</p>
                    </div>
                  </div>
                </fieldset>
                <div v-if="industryRole === 'observer'" class="grid grid-cols-6 mt-4">
                  <div class="pl-7 col-span-6 sm:col-span-3">
                    <label for="industry_sub_role" class="block text-sm font-medium leading-5 text-gray-700">Area of expertise</label>
                    <select v-model="industrySubRole" @change="setSubRole" id="industry_sub_role" class="mt-1 block w-full py-2 px-3 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 bg-white shadow-sm sm:text-sm sm:leading-5">
                      <option v-for="(role, index) in industrySubRoles" :key="role.value" :value="role.value">{{ role.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="hidden sm:block">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 id="searchability" class="text-lg font-medium leading-6 text-gray-900">
              Matchmaking
            </h3>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              Which industry roles do you want to find (or avoid)?
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form @submit.prevent="saveSearchPermissions">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <fieldset>
                  <legend class="text-base leading-6 font-medium text-gray-900">
                    I'm interested in connecting with:
                  </legend>
                  <p class="text-sm leading-5 text-gray-500">
                    People who have you as a contact override this.
                  </p>
                  <div class="mt-4">
                    <div class="flex items-start">
                      <div class="absolute flex items-center h-5">
                        <input
                          v-model="principalVisible"
                          id="principals"
                          type="checkbox"
                          class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600"
                        />
                      </div>
                      <div class="pl-7 text-sm leading-5">
                        <label
                          for="principals"
                          class="font-medium text-gray-700"
                        >Principals</label
                        >
                      </div>
                    </div>
                    <div class="mt-4">
                      <div class="flex items-start">
                        <div class="absolute flex items-center h-5">
                          <input
                            v-model="brokerVisible"
                            id="brokers"
                            type="checkbox"
                            class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600"
                          />
                        </div>
                        <div class="pl-7 text-sm leading-5">
                          <label for="brokers" class="font-medium text-gray-700"
                          >Brokers</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <div class="flex items-start">
                        <div class="absolute flex items-center h-5">
                          <input
                            v-model="observerVisible"
                            id="observers"
                            type="checkbox"
                            class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600"
                          />
                        </div>
                        <div class="pl-7 text-sm leading-5">
                          <label
                            for="observers"
                            class="font-medium text-gray-700"
                          >Others</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  class="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-blue focus:bg-indigo-500 active:bg-indigo-600"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="hidden sm:block">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 id="historical-companies" class="text-lg font-medium leading-6 text-gray-900">
              Historical Companies
            </h3>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              List past firms to connect with fellow industry alums.
            </p>
            <p v-if="showCompanyHelp" class="mt-2 text-sm font-medium leading-5 text-indigo-600">
              If a company is missing, press ENTER to create it.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <div class="shadow sm:rounded-md overflow-visible">
            <div class="relative px-4 py-5 bg-white sm:p-6">
              <autocomplete
                ref="companySearch"
                :search="searchCompanies"
                :debounce-time="500"
                @focus="focus('company')"
                @blur="blur"
                @submit="handleCompanySubmit"
              />
              <historical-company-list />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hidden sm:block">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 id="schools" class="text-lg font-medium leading-6 text-gray-900">
              Schools
            </h3>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              List university connections to increase your visibility.
            </p>
            <p v-if="showSchoolHelp" class="mt-2 text-sm font-medium leading-5 text-indigo-600">
              If a school is missing, press ENTER to create it.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <div class="shadow sm:rounded-md overflow-visible">
            <div class="px-4 py-5 bg-white sm:p-6">
              <autocomplete
                ref="schoolSearch"
                :search="searchSchools"
                :debounce-time="500"
                @focus="focus('school')"
                @blur="blur"
                @submit="handleSchoolSubmit"
              />
              <school-list />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hidden sm:block">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 id="payment-methods" class="text-lg font-medium leading-6 text-gray-900">Billing &amp; Subscription</h3>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              Upgrade your Tower Hunt experience
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2 space-y-5 sm:space-y-6">
          <div class="shadow overflow-hidden sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <payment-methods :selectable="false" />
            </div>
          </div>

          <!-- Plan -->
          <section aria-labelledby="plan-heading">
            <form @submit.prevent>
              <div class="shadow sm:rounded-md sm:overflow-hidden">
                <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
                  <div>
                    <legend id="plan-heading" class="text-base leading-6 font-medium text-gray-900">
                      Product tier
                    </legend>
                    <p class="text-sm leading-5 text-gray-500">
                      <span>Which tools do you need?</span>
                      <router-link to="/pricing" class="ml-1 font-medium text-indigo-600 hover:text-indigo-500">
                        Compare versions &rarr;
                      </router-link>
                    </p>
                  </div>

                  <fieldset>
                    <legend class="sr-only">
                      Product tiers
                    </legend>
                    <div class="relative bg-white rounded-md -space-y-px">
                      <!-- Checked: "bg-indigo-50 border-indigo-200 z-10", Not Checked: "border-gray-200" -->
                      <label :class="productTier === 'gatherer' ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200'" class="rounded-tl-md rounded-tr-md relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none">
                        <div class="flex items-center text-sm">
                          <input v-model="productTier" type="radio" name="pricing-plan" value="gatherer" class="h-4 w-4 text-indigo-500 border-gray-300 focus:ring-indigo-600" aria-labelledby="pricing-plans-0-label" aria-describedby="pricing-plans-0-description-0 pricing-plans-0-description-1">
                          <span id="pricing-plans-0-label" class="ml-3 font-medium text-gray-900">Gatherer</span>
                        </div>
                        <p id="pricing-plans-0-description-0" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                          <!-- Checked: "text-indigo-900", Not Checked: "text-gray-900" -->
                          <span :class="productTier === 'gatherer' ? 'text-indigo-900' : 'text-gray-900'" class="font-medium">Free</span>
                          <!-- Checked: "text-indigo-700", Not Checked: "text-gray-500" -->
                          <span :class="productTier === 'gatherer' ? 'text-indigo-700' : 'text-gray-500'"></span>
                        </p>
                        <!-- Checked: "text-indigo-700", Not Checked: "text-gray-500" -->
                        <p id="pricing-plans-0-description-1" :class="productTier === 'gatherer' ? 'text-indigo-700' : 'text-gray-500'" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">Basic market tracking</p>
                      </label>

                      <!-- Checked: "bg-indigo-50 border-indigo-200 z-10", Not Checked: "border-gray-200" -->
                      <label :class="productTier === 'insider' ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200'" class="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none">
                        <div class="flex items-center text-sm">
                          <input v-model="productTier" type="radio" name="pricing-plan" value="insider" class="h-4 w-4 text-indigo-500 border-gray-300 focus:ring-indigo-600" aria-labelledby="pricing-plans-1-label" aria-describedby="pricing-plans-1-description-0 pricing-plans-1-description-1">
                          <span id="pricing-plans-1-label" class="ml-3 font-medium text-gray-900">Insider</span>
                        </div>
                        <p id="pricing-plans-1-description-0" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                          <!-- Checked: "text-indigo-900", Not Checked: "text-gray-900" -->
                          <span :class="productTier === 'insider' ? 'text-indigo-900' : 'text-gray-900'" class="font-medium">Up to $0.25 / intel</span>
                          <!-- Checked: "text-indigo-700", Not Checked: "text-gray-500" -->
                          <span :class="productTier === 'insider' ? 'text-indigo-700' : 'text-gray-500'"></span>
                        </p>
                        <!-- Checked: "text-indigo-700", Not Checked: "text-gray-500" -->
                        <p id="pricing-plans-1-description-1" :class="productTier === 'insider' ? 'text-indigo-700' : 'text-gray-500'" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">Premium Insights</p>
                      </label>

                      <!-- Checked: "bg-indigo-50 border-indigo-200 z-10", Not Checked: "border-gray-200" -->
                      <label :class="productTier === 'hunter' ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200'" class="rounded-bl-md rounded-br-md relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none">
                        <div class="flex items-center text-sm">
                          <input v-model="productTier" type="radio" name="pricing-plan" value="hunter" class="h-4 w-4 text-indigo-500 border-gray-300 focus:ring-indigo-600" aria-labelledby="pricing-plans-3-label" aria-describedby="pricing-plans-3-description-0 pricing-plans-3-description-1">
                          <span id="pricing-plans-3-label" class="ml-3 font-medium text-gray-900">Hunter</span>
                        </div>
                        <p id="pricing-plans-3-description-0" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                          <!-- Checked: "text-indigo-900", Not Checked: "text-gray-900" -->
                          <span :class="productTier === 'hunter' ? 'text-indigo-900' : 'text-gray-900'" class="font-medium">
                            <template v-if="billingFrequency === 'monthly'">$29 / mo</template>
                            <template v-else-if="billingFrequency === 'annual'">$26 / mo</template>
                          </span>
                          <!-- Checked: "text-indigo-700", Not Checked: "text-gray-500" -->
                          <span :class="productTier === 'hunter' ? 'text-indigo-700' : 'text-gray-500'">
                            <template v-if="billingFrequency === 'monthly'">($348 / yr)</template>
                            <template v-else-if="billingFrequency === 'annual'">($313 / yr)</template>
                          </span>
                        </p>
                        <!-- Checked: "text-indigo-700", Not Checked: "text-gray-500" -->
                        <p id="pricing-plans-3-description-1" :class="productTier === 'hunter' ? 'text-indigo-700' : 'text-gray-500'" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">Strategic Map</p>
                      </label>
                    </div>
                  </fieldset>

                  <div class="flex items-center">
                    <!-- Enabled: "bg-indigo-500", Not Enabled: "bg-gray-200" -->
                    <button @click="toggleBillingFrequency" type="button" :class="billingFrequency === 'annual' ? 'bg-indigo-500' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 transition-colors ease-in-out duration-200" role="switch" :aria-checked="billingFrequency === 'annual'" aria-labelledby="annual-billing-label">
                      <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                      <span aria-hidden="true" :class="billingFrequency === 'annual' ? 'translate-x-5' : 'translate-x-0'" class="inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                    </button>
                    <span class="ml-3" id="annual-billing-label">
                      <span class="text-sm font-medium text-gray-900">Annual billing </span>
                      <span class="text-sm text-gray-500">(Save 10%)</span>
                    </span>
                  </div>
                </div>
                <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button @click="updateProductTier" type="button" class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>

    <div class="hidden sm:block">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 id="payouts" class="text-lg font-medium leading-6 text-gray-900">Payouts</h3>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              Manage your Tower Hunt earnings across personal and shared payout accounts.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2 space-y-5 sm:space-y-6">
          <div class="bg-white shadow overflow-hidden sm:rounded-md">
            <payment-connected-account-gateway :value="payoutsDashboardObject" />
          </div>
          <div class="bg-white shadow overflow-visible sm:rounded-md">
            <shared-payout-accounts-list />
          </div>
        </div>
      </div>
    </div>

    <div class="hidden sm:block">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

    <div v-if="false" class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 id="file-storage" class="text-lg font-medium leading-6 text-gray-900">File Storage</h3>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              Save and retrieve deal files.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <div class="shadow overflow-hidden sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <file-storage-auth />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="false" class="hidden sm:block">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 id="maps" class="text-lg font-medium leading-6 text-gray-900">Strategic Map</h3>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              Adjust <router-link to="/map" class="font-medium text-gray-500 underline">Strategic Map</router-link> behavior.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form @submit.prevent="saveMapSettings">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label for="default-layer" class="block text-sm font-medium text-gray-700">Default layer</label>
                    <select v-model="mapDefaultLayer" id="default-layer" name="default-layer" autocomplete="default-layer" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                      <option value="roadmap">Road</option>
                      <option value="satellite">Satellite</option>
                      <option value="hybrid">Hybrid</option>
                      <option value="transit">Transit</option>
                    </select>
                  </div>

                  <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label for="map-mode" class="block text-sm font-medium text-gray-700">Map mode</label>
                    <map-mode-toggle class="mt-1 py-2" />
                    <p class="mt-2 text-sm text-gray-500" id="map-mode-description">Heat map or Strategic map?</p>
                  </div>

                  <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label for="map-mode-transition" class="block text-sm font-medium text-gray-700">Map mode transition</label>
                    <div class="flex items-center mt-1 py-2">
                      <button @click="toggleMapModeTransition" type="button" :class="mapModeTransition === 'automatic' ? 'bg-indigo-600' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" role="switch" aria-checked="false" aria-labelledby="map-mode-transition-label">
                        <span aria-hidden="true" :class="mapModeTransition === 'automatic' ? 'translate-x-5' : 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                      </button>
                      <span class="ml-3" id="map-mode-transition-label">
                        <span class="text-sm font-medium text-gray-900">{{ mapModeTransition === "automatic" ? "Automatic " : "Manual " }}</span>
                        <span class="text-sm text-gray-500">{{ mapModeTransition === "automatic" ? "(at zoom 15)" : "(via mode toggle)" }}</span>
                      </span>
                    </div>
                    <p class="mt-2 text-sm text-gray-500" id="map-mode-transition-description">Change map mode automatically?</p>
                  </div>
                </div>
              </div>
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="hidden sm:block">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 id="notifications" class="text-lg font-medium leading-6 text-gray-900">
              Notifications
            </h3>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              Decide which communications you'd like to receive.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form>
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <fieldset>
                  <legend class="text-base leading-6 font-medium text-gray-900">
                    By Email
                  </legend>
                  <div class="mt-4">
                    <product-newsletter />
                  </div>
                  <div class="mt-4">
                    <digests />
                  </div>
                  <div class="mt-4">
                    <deal-digests />
                  </div>
                  <div class="mt-4">
                    <discussion-digests />
                  </div>
                  <div class="mt-4">
                    <bounties />
                  </div>
                  <div class="mt-4">
                    <job-digests />
                  </div>
                  <div class="mt-4">
                    <offered-deals />
                  </div>
                  <div class="mt-4">
                    <target-followed />
                  </div>
                </fieldset>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DirectUpload } from "@rails/activestorage";
import { mapState } from "vuex";
import Bounties from "../components/notification-consents/bounties";
import DealDigests from "../components/notification-consents/deal-digests";
import Digests from "../components/notification-consents/digests";
import DiscussionDigests from "../components/notification-consents/discussion-digests";
import FileStorageAuth from "../components/file-storage/file-storage-auth.vue";
import HistoricalCompanyList from "../components/historical-company-list";
import JobDigests from "../components/notification-consents/job-digests";
import MapModeToggle from "../components/maps/map-mode-toggle.vue";
import OfferedDeals from "../components/notification-consents/offered-deals";
import PaymentConnectedAccountDashboard from "../components/payment-connected-account-dashboard.vue";
import PaymentConnectedAccountGateway from "../components/payment-connected-account-gateway.vue";
import PaymentMethods from "../components/payment-methods";
import ProductNewsletter from "../components/notification-consents/product-newsletter";
import SchoolList from "../components/school-list";
import SharedPayoutAccountsList from "../components/shared-payout-accounts-list";
import Subscribe from "../components/purchaseables/monthly-subscriptions/subscribe.vue";
import Swal from "sweetalert2";
import TargetFollowed from "../components/notification-consents/target-followed";
import industryRoleOptions from "../sweet-alert-industry-role-options";
import industryRoles from "../industry-roles";
import industrySubRoles from "../industry-sub-roles";

export default {
  components: {
    Bounties,
    DealDigests,
    Digests,
    DiscussionDigests,
    FileStorageAuth,
    HistoricalCompanyList,
    JobDigests,
    MapModeToggle,
    OfferedDeals,
    PaymentConnectedAccountGateway,
    PaymentMethods,
    ProductNewsletter,
    SchoolList,
    SharedPayoutAccountsList,
    TargetFollowed
  },
  data() {
    return {
      industryRoles: industryRoles,
      industrySubRoles: industrySubRoles,
      mapDefaultLayer: "roadmap",
      mapModeTransition: "automatic",
      productTier: "gatherer",
      billingFrequency: "monthly",
      avatar: null,
      name: null,
      company: null,
      marketEmail: null,
      phone: null,
      shareMarketEmail: false,
      sharePhone: false,
      principalVisible: true,
      brokerVisible: true,
      observerVisible: true,
      canChangeIndustryRole: false,
      industryRole: null,
      industrySubRole: "other",
      fileUploadUrl: "/rails/active_storage/direct_uploads",
      userAvatarPreview: null,
      avatarError: false,
      focused: false
    };
  },
  computed: {
    ...mapState(["currentUser", "adminMode"]),
    primaryEmail() {
      return this.currentUser.email;
    },
    searchable() {
      return this.currentUser.searchable;
    },
    unrestrictedSearchable() {
      return this.$store.getters.contactUnrestrictedSearchable;
    },
    token() {
      return _.get(this.currentUser, "token", "");
    },
    photo() {
      return this.userAvatarPreview || this.avatar;
    },
    companyLookupValue() {
      return this.$refs.companySearch.value;
    },
    schoolLookupValue() {
      return this.$refs.schoolSearch.value;
    },
    showCompanyHelp() {
      return this.focused === "company" && this.companyLookupValue !== "";
    },
    showSchoolHelp() {
      return this.focused === "school" && this.schoolLookupValue !== "";
    },
    payoutsDashboardObject() {
      return {
        component: PaymentConnectedAccountDashboard,
        props: {
          returnUrl: `https://towerhunt.com/home/settings`
        }
      };
    },
    hash() {
      return this.$store.state.route.hash;
    }
  },
  watch: {
    currentUser: {
      handler(user, oldUser) {
        if (user.signedIn) {
          this.mountContactInfo();
        }
      }
    },
    hash: {
      handler() {
        if (this.hash) {
          this.scrollByHash();
        }
      }
    }
  },
  mounted() {
    if (this.currentUser.signedIn) {
      this.mountContactInfo();
    }

    document.title = "Settings | Tower Hunt";

    this.scrollByHash();
  },
  methods: {
    focus(field) {
      this.focused = field;
    },
    blur() {
      this.focused = false;
    },
    scrollByHash() {
      const hash = this.hash;

      if (_.includes(hash, "#searchability")) {
        this.$scrollTo("#searchability", 500, {
          container: "#hub-body-container"
        });
      } else if (_.includes(hash, "#industry-roles")) {
        this.$scrollTo("#industry-roles", 500, {
          container: "#hub-body-container"
        });
      } else if (_.includes(hash, "#historical-companies")) {
        this.$scrollTo("#historical-companies", 500, {
          container: "#hub-body-container"
        });
      } else if (_.includes(hash, "#schools")) {
        this.$scrollTo("#schools", 500, {
          container: "#hub-body-container"
        });
      } else if (_.includes(hash, "#payment-methods")) {
        this.$scrollTo("#payment-methods", 500, {
          container: "#hub-body-container"
        });
      } else if (_.includes(hash, "#payouts")) {
        this.$scrollTo("#payouts", 500, {
          container: "#hub-body-container"
        });
      } else if (_.includes(hash, "#file-storage")) {
        this.$scrollTo("#file-storage", 500, {
          container: "#hub-body-container"
        });
      } else if (_.includes(hash, "#maps")) {
        this.$scrollTo("#maps", 500, {
          container: "#hub-body-container"
        });
      } else if (_.includes(hash, "#notifications")) {
        this.$scrollTo("#notifications", 500, {
          container: "#hub-body-container"
        });
      }
    },
    toggleBillingFrequency() {
      if (this.billingFrequency === "monthly") {
        this.billingFrequency = "annual";
      } else {
        this.billingFrequency = "monthly";
      }
    },
    toggleMapModeTransition() {
      if (this.mapModeTransition === "automatic") {
        this.mapModeTransition = "manual";
      } else {
        this.mapModeTransition = "automatic";
      }
    },
    mountContactInfo() {
      const {
        productTier,
        billingFrequency,
        avatar,
        name,
        company,
        marketEmail,
        shareMarketEmail,
        phone,
        sharePhone,
        principalVisible,
        brokerVisible,
        observerVisible,
        canChangeIndustryRole,
        industryRole,
        industrySubRole,
        mapModeTransition,
        mapDefaultLayer
      } = this.currentUser;

      this.productTier = productTier;
      this.billingFrequency = billingFrequency || "monthly";
      this.avatar = avatar;
      this.name = name;
      this.company = company;
      this.marketEmail = marketEmail;
      this.shareMarketEmail = shareMarketEmail;
      this.phone = phone;
      this.sharePhone = sharePhone;
      this.principalVisible = principalVisible;
      this.brokerVisible = brokerVisible;
      this.observerVisible = observerVisible;
      this.canChangeIndustryRole = canChangeIndustryRole;
      this.industryRole = industryRole;
      this.industrySubRole = industrySubRole;
      this.mapModeTransition = mapModeTransition;
      this.mapDefaultLayer = mapDefaultLayer;
    },
    saveContactInfo() {
      const {
        avatar,
        name,
        company,
        marketEmail,
        shareMarketEmail,
        phone,
        sharePhone
      } = this;
      const newInfo = {
        avatar,
        name,
        company,
        marketEmail,
        shareMarketEmail,
        phone,
        sharePhone
      };

      this.$store.dispatch("updateContactInfo", newInfo);
    },
    saveSearchPermissions() {
      const { principalVisible, brokerVisible, observerVisible } = this;
      const newPermissions = {
        principalVisible,
        brokerVisible,
        observerVisible
      };

      this.$store.dispatch("updateSearchPermissions", newPermissions);
    },
    saveMapSettings() {
      const { mapModeTransition, mapDefaultLayer } = this;
      const newSettings = {
        mapModeTransition,
        mapDefaultLayer
      };

      this.$store.dispatch("updateMapSettings", newSettings);
    },
    toggleSearchability() {
      this.$store.dispatch("toggleUserSearchability");
    },
    searchCompanies(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        const query = input === "" || input === null ? null : _.toLower(input);

        this.$store.dispatch("executeCompanySearch", query).then(
          json => {
            resolve(json.data);
          },
          failure => {
            this.$store.dispatch("flash", "Invalid search");
          }
        );
      });
    },
    searchSchools(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        const query = input === "" || input === null ? null : _.toLower(input);

        this.$store.dispatch("executeSchoolSearch", query).then(
          json => {
            resolve(json.data);
          },
          failure => {
            this.$store.dispatch("flash", "Invalid search");
          }
        );
      });
    },
    handleCompanySubmit(result) {
      const output = result ? result : this.$refs.companySearch.value;

      this.$store.dispatch("createHistoricalCompany", output);
      this.$refs.companySearch.value = "";
    },
    handleSchoolSubmit(result) {
      const output = result ? result : this.$refs.schoolSearch.value;

      this.$store.dispatch("createSchool", output);
      this.$refs.schoolSearch.value = "";
    },
    setRole() {
      Swal.fire(industryRoleOptions).then(result => {
        if (result.value) {
          this.$store.dispatch("setIndustryRole", this.industryRole);
          this.canChangeIndustryRole = false;
        } else {
          this.industryRole = this.currentUser.industryRole;
        }
      });
    },
    setSubRole() {
      this.$store.dispatch("setIndustrySubRole", this.industrySubRole);
    },
    toggle(field) {
      this[field] = !this[field];
    },
    privacyTooltipFor(field) {
      if (this[field]) {
        return "Anyone will be able to see this.";
      } else {
        return "Only mutual connections will be able to see this.";
      }
    },
    launchFilePicker(){
      this.$refs.userAvatar.click();
    },
    uploadAvatar() {
      Array.from(this.$refs.userAvatar.files).forEach((file, index) => {
        if (index === 0) {
          const fileSize = (file.size / 1024 / 1024).toFixed(4); // MB

          if (fileSize > 3) {
            this.avatarError =
              "The file you tried to upload was too large. Please upload a file smaller than 3MB.";
          } else {
            this.uploadFile(file);

            if (!!analytics && !this.adminMode) {
              analytics.track("Uploaded Profile Photo", {
                yearWeek: this.$store.state.yearWeek
              });
            }
            this.avatarError = false;
          }
        }
      });
      this.$refs.userAvatar.value = null;
    },
    uploadFile(file) {
      const upload = new DirectUpload(file, this.fileUploadUrl, this);
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        this.userAvatarPreview = reader.result;
      });

      reader.readAsDataURL(file);

      upload.create((error, blob) => {
        if (error) {
          console.error(error); // eslint-disable-line
        } else {
          this.avatar = blob.signed_id;
        }
      });
    },
    updateProductTier() {
      const unchangedTier = this.productTier === this.currentUser.productTier;
      const unchangedFrequency =
        this.productTier !== "hunter" ||
        this.billingFrequency === this.currentUser.billingFrequency;

      if (unchangedTier && unchangedFrequency) {
        this.$store.dispatch("flash", "Product tier is already set.");
      } else if (this.productTier === "insider") {
        this.$store.dispatch("flash", "Coming soon!");
        this.mountContactInfo();
      } else {
        this.$store.commit("openModal", {
          component: Subscribe,
          props: {
            newPlan: this.productTier,
            billingFrequency: this.billingFrequency
          }
        });
      }
    }
  }
};
</script>
