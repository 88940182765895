import PricingPopup from "./components/maps/pricing-popup";
import Vue from "vue";
/* global L */

const ContextPricingPopup = Vue.extend(PricingPopup);

export default function responsivePricingPopup({
  router,
  store,
  marker,
  deal,
  fillColor,
  isContext = false
}) {
  const popupVueEl = new ContextPricingPopup({
    store,
    router,
    propsData: { deal, fillColor, isContext, marker }
  }).$mount().$el;

  return L.responsivePopup().setContent(popupVueEl);
}
