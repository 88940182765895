<template>
  <div class="wrapper">
    <heading>Let's name this market</heading>
    <form @submit.prevent="save" autocomplete="off" novalidate>
      <div class="input-container">
        <input
          v-focus
          v-model="name"
          @focus="focus"
          @blur="blur"
          type="text"
          autocomplete="new-password"
          autocorrect="off"
          spellcheck="false"
          placeholder=""
          tabindex="1"
          name="target_name"
          class="input-text black"
          :class="{ active: focused }"
        />
        <label class="input-label" :class="{ active: focused }"
          >Market name</label
        >
      </div>
      <button
        type="submit"
        class="cta"
        :class="{ complete: complete }"
        tabindex="999"
      >
        Save
      </button>
    </form>
  </div>
</template>

<script>
import Heading from "../../components/onboarding/heading";

export default {
  props: ["targetId", "existingName"],
  data() {
    return {
      focused: false,
      name: null
    };
  },
  mounted() {
    this.name = this.existingName;
  },
  components: { Heading },
  computed: {
    complete() {
      return this.name !== "";
    }
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    save() {
      if (this.complete) {
        this.$store
          .dispatch("updateTargetName", {
            targetId: this.targetId,
            name: this.name
          })
          .then(() => {
            this.$store.dispatch(
              "loadContactData",
              this.$store.state.currentUser.token
            );
            this.$store.commit("closeModal");
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
input {
  vertical-align: middle;
}
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 26px 15px 8px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.active {
    border: 2px solid #5850ec;
  }
}
.input-label {
  position: absolute;
  top: 8px;
  left: 16px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8da2fb;
    }
  }
}
</style>
