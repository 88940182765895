<template>
  <div class="relative flex-shrink-0 flex bg-gray-100">
    <div class="flex-1 px-4 pt-2 flex flex-col" :class="isMobile ? 'pb-4' : 'pb-1'">
      <div class="w-full flex md:ml-0">
        <form @submit.prevent="sendMessage" class="-ml-px relative inline-flex flex-grow focus-within:z-10">
          <resizable-textarea>
            <textarea
              v-if="isMobile"
              v-focus
              v-model="message"
              rows="1"
              type="text"
              autocomplete="new-password"
              id="notepad_field"
              class="resize-none block w-full rounded-l-md pl-3 sm:text-sm sm:leading-5"
              placeholder="Jot something down"
            />
            <textarea
              v-else
              v-focus
              v-model="message"
              @keydown.enter.exact.prevent
              @keyup.enter.exact="sendMessage"
              rows="1"
              type="text"
              autocomplete="new-password"
              id="notepad_field"
              class="resize-none block w-full rounded-l-md pl-3 sm:text-sm sm:leading-5"
              placeholder="Jot something down"
            />
          </resizable-textarea>
        </form>
        <button
          v-tooltip="sendHelp"
          @click="sendMessage"
          type="button"
          class="-ml-px relative inline-flex items-end px-4 py-2 border text-sm leading-5 font-medium rounded-r-md"
          :class="complete ? 'border-transparent text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:ring-green focus:border-green-700 active:bg-green-700' : 'border-gray-300 text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700'"
        >
          <svg class="h-4 w-4" :class="complete ? 'text-white' : 'text-gray-400'" viewBox="0 0 512 512" fill="currentColor">
            <path d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"/>
          </svg>
        </button>
      </div>
      <span v-if="!isMobile" class="w-full flex py-1 justify-end text-gray-500" style="font-size:0.7em;">
        <span><strong class="font-bold">Return</strong> to send</span>
        <span class="ml-2">
          <strong class="font-bold">Shift + Return</strong> to add a new line
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ResizableTextarea from "./resizable-textarea";

export default {
  components: { ResizableTextarea },
  props: ["content", "contentToken", "contentType"],
  data() {
    return {
      message: ""
    };
  },
  computed: {
    ...mapState(["adminMode"]),
    ...mapGetters(["privacyMode"]),
    complete() {
      return this.message !== "" && _.trim(this.message) !== "";
    },
    sendHelp() {
      return this.complete ? "Send message" : "";
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    sendMessage() {
      if (this.complete) {
        this.$store.dispatch("sendNotepadMessage", {
          message: this.message,
          contentToken: this.contentToken || _.get(this.content, "token", null) || _.get(this.content, "id", null),
          contentType: this.contentType
        });
        this.message = "";
        document
          .getElementById("notepad_field")
          .setAttribute("style", "height:auto;overflow-y:hidden;");

        if (!!analytics && !this.adminMode) {
          analytics.track("Wrote in notepad", {
            yearWeek: this.$store.state.yearWeek
          });
        }
      }
    }
  }
};
</script>
