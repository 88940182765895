<template>
  <div>
    <!-- HERO -->
    <div class="relative bg-gray-50 overflow-hidden">
      <div class="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full">
        <div class="relative h-full max-w-screen-xl mx-auto">
          <svg class="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="svg-pattern-squares-1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#svg-pattern-squares-1)" />
          </svg>
          <svg class="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="svg-pattern-squares-2" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#svg-pattern-squares-2)" />
          </svg>
        </div>
      </div>

      <div class="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
        <div class="max-w-screen-xl mx-auto px-4 sm:px-6">
          <nav class="relative flex items-center justify-between sm:h-10 md:justify-center">
            <div class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
              <div class="flex items-center justify-between w-full md:w-auto">
                <router-link to="/">
                  <img class="h-10 w-auto sm:h-12" src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png" alt="Tower Hunt" />
                </router-link>
                <div class="-mr-2 flex items-center md:hidden">
                  <button @click="open = true" type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500">
                    <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
              <span class="inline-flex rounded-md shadow">
                <router-link v-if="!signedIn" to="/sign-in" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:ring-blue active:bg-gray-50 active:text-indigo-700">
                  Log in
                </router-link>
              </span>
            </div>
          </nav>
        </div>

        <div v-show="open" style="display: none;" class="absolute top-0 inset-x-0 p-2 md:hidden">
          <div class="rounded-lg shadow-md transition transform origin-top-right" v-show="open" x-transition:enter="duration-150 ease-out" x-transition:enter-start="opacity-0 scale-95" x-transition:enter-end="opacity-100 scale-100" x-transition:leave="duration-100 ease-in" x-transition:leave-start="opacity-100 scale-100" x-transition:leave-end="opacity-0 scale-95">
            <div class="rounded-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div class="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img class="h-10 w-auto" src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png" alt="Tower Hunt" />
                </div>
                <div class="-mr-2">
                  <button @click="open = false" type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500">
                    <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <router-link v-if="!signedIn" to="/sign-in" class="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700">
                  Log in
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
          <div class="text-center">
            <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
              Hunt
              <br class="xl:hidden" />
              <span class="text-indigo-600">commercial real estate</span>
              <br class="xl:hidden" />
              <!-- <transition name="slide-fade">
                <span v-if="headlineUser">{{ headlineUser }}</span>
              </transition> -->
              intel
            </h2>
            <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Tower Hunt is a local market tracker for local experts, by local experts.
            </p>
            <div v-if="!signedIn" class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div class="rounded-md shadow">
                <router-link to="/start/1" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo md:py-4 md:text-lg md:px-10">
                  Get started for free
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SHOWCASE MARKETS -->
    <div class="bg-gray-50 pb-16 lg:pb-24">
      <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <div class="lg:text-center">
          <p class="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Market intel</p>
          <h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Personalized deals & news
          </h3>
          <p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            Receive a daily flow of relevant market intel according to your criteria. Explore below or <router-link to="/start/1" class="text-indigo-600 hover:text-indigo-500">get started &rarr;</router-link>
          </p>
        </div>
      </div>

      <showcase-markets class="h-150 mt-6 lg:mt-12 max-w-xl mx-auto lg:max-w-screen-xl" />
    </div>

    <!-- PREMIUM CONTENT SHOWCASES -->
    <div class="bg-gradient-to-r from-indigo-600 to-blue-500">
      <featured :showcase-limit="1" :purchaseable-limit="5" :is-dark="true" class="pb-8 lg:pb-16" />
    </div>

    <!-- LOGO CLOUD -->
    <div class="bg-gray-50 py-16 lg:py-24">
      <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <p class="text-center text-base leading-6 font-semibold uppercase text-gray-600 tracking-wider">
          Join deal professionals from
        </p>
        <div class="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/AFIAA0.png" alt="AFIAA" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/BeaconCapital0.png" alt="Beacon Capital Partners" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/Brixmor0.png" alt="Brixmor" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/Bulfinch0.png" alt="Bulfinch" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/Brookfield0.png" alt="Brookfield Asset Management" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/CBRE0.png" alt="CBRE" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/Colliers0.png" alt="Colliers International" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/CushmanWakefield0.png" alt="Cushman Wakefield" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/DavisCompanies0.png" alt="Davis Companies" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/EastdilSecured0.png" alt="Eastdil Secured" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/Fortress0.png" alt="Fortress" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/Greatland0.png" alt="Greatland" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/Hunneman0.png" alt="Hunneman" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/JLL0.png" alt="JLL" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/LincolnPropertyCompany0.png" alt="Lincoln Property Company" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/MarcusMillichap0.png" alt="Marcus and Millichap" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/MarcusPartners0.png" alt="Marcus Partners" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/NationalDevelopment0.png" alt="National Development" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/newmark0.png" alt="Newmark" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/Nordblom0.png" alt="Nordblom" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/OliverStreet0.png" alt="Oliver Street Capital" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/Prologis0.png" alt="Prologis" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/WaltonStreet0.png" alt="Walton Street Capital" />
          </div>
          <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img class="max-h-12" src="https://assets.towerhunt.com/site/WSDevelopment0.png" alt="WS Development" />
          </div>
        </div>
      </div>
    </div>

    <!--
    <div class="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <div class="lg:text-center">
          <p class="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Market intel</p>
          <h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Personalized deals & news
          </h3>
          <p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            Receive a daily flow of relevant market intel according to your criteria. <router-link to="/start/1" class="text-indigo-600 hover:text-indigo-500">Get started &rarr;</router-link>
          </p>
        </div>

        <img class="mt-12 lg:mt-24 relative rounded-lg shadow-lg" style="z-index: 1;" src="https://assets.towerhunt.com/site/deal_intel_cards_compressed1.jpg" alt="Deals and market intel">

        <svg class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
          <defs>
            <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div class="relative">
            <h4 class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
              Earn money for local expertise
            </h4>
            <p class="mt-3 text-lg leading-7 text-gray-500">
              Bid to provide help to people in your markets. Get paid when you deliver.
            </p>
            <router-link to="/product/bounty-bids" class="mt-3 text-lg leading-7 font-medium text-indigo-600 hover:text-indigo-500">
              Learn more &rarr;
            </router-link>
          </div>

          <div class="mt-10 -mx-4 relative lg:mt-0">
            <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
              <defs>
                <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            <img class="relative mx-auto" width="350" src="https://assets.towerhunt.com/site/bounty0.jpg" alt="Bounty">
          </div>
        </div>

        <svg class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784">
          <defs>
            <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        <div class="relative mt-12 sm:mt-16 lg:mt-24">
          <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div class="lg:col-start-2">
              <h4 class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                View off-market leads
              </h4>
              <p class="mt-3 text-lg leading-7 text-gray-500">
                Personalized deal leads with unlockable cap rate data and owner messaging.
              </p>
              <router-link to="/product/off-market" class="mt-3 text-lg leading-7 font-medium text-indigo-600 hover:text-indigo-500">
                Learn more &rarr;
              </router-link>
            </div>

            <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                <defs>
                  <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <video poster="https://assets.towerhunt.com/site/offMarketCard4.jpg" class="relative mx-auto rounded-lg shadow-lg" style="background-color: rgb(34, 41, 54);" width="490" autoplay loop preload="auto">
                <source src="https://assets.towerhunt.com/site/cardReviewBlackBackground0.webm" type='video/webm'>
                <source src="https://assets.towerhunt.com/site/cardReviewBlackBackground1.mp4" type='video/mp4'>
                <source src="https://assets.towerhunt.com/site/cardReviewBlackBackground0.ogg" type='video/ogg'>
                <p>Your browser does not support the video element.</p>
              </video>
            </div>
          </div>
        </div>

        <svg class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
          <defs>
            <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div class="relative">
            <h4 class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
              Go beyond the headlines
            </h4>
            <p class="mt-3 text-lg leading-7 text-gray-500">
              Participate in discussions that go deep inside current market events.
            </p>
          </div>

          <div class="mt-10 -mx-4 relative lg:mt-0">
            <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
              <defs>
                <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            <img class="relative mx-auto rounded-lg shadow-lg" width="350" src="https://assets.towerhunt.com/site/dealComments1.jpg" alt="Anonymous deal comments">
          </div>
        </div>

        <svg class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784">
          <defs>
            <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        <div class="relative mt-12 sm:mt-16 lg:mt-24">
          <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div class="lg:col-start-2">
              <h4 class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                Promote your deals
              </h4>
              <p class="mt-3 text-lg leading-7 text-gray-500">
                Offer your deals in a beautiful format to an audience of your choosing.
              </p>
            </div>

            <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                <defs>
                  <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <img class="relative mx-auto rounded-lg shadow-lg" width="490" src="https://assets.towerhunt.com/site/dealMarketingOverview0.jpg" alt="Deal marketing page">
            </div>
          </div>
        </div>
      </div>
    </div>
    -->

    <!-- CTA -->
    <div v-if="!signedIn" class="bg-gray-50">
      <div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Ready to make things happen?
          <br />
          <span class="text-indigo-600">Create a profile today.</span>
        </h2>
        <div class="mt-8 flex lg:flex-shrink-0 lg:mt-0">
          <div class="inline-flex rounded-md shadow">
            <router-link to="/start/1" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring">
              Get started for free
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="bg-gray-50">
      <marketing-footer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Featured from "../components/purchaseables/featured";
import MarketingFooter from "../components/marketing-footer";
import ShowcaseMarkets from "../components/showcase-markets";

let interval;

export default {
  components: { Featured, MarketingFooter, ShowcaseMarkets },
  data() {
    return {
      open: false,
      userTypes: ["investors", "lenders", "brokers", "landlords", "tenants"],
      headlineUser: "investors"
    };
  },
  computed: {
    ...mapGetters(["signedIn", "isAdmin"])
  },
  created() {
    var self = this;

    interval = setInterval(function() {
      const currentIndex = _.indexOf(self.userTypes, self.headlineUser);
      let newIndex;

      if (currentIndex === 4) {
        newIndex = 0;
      } else {
        newIndex = currentIndex + 1;
      }

      self.headlineUser = null;
      setTimeout(() => {
        self.headlineUser = self.userTypes[newIndex];
      }, 800);
    }, 3000);
  },
  mounted() {
    document.title = "Tower Hunt - Log In or Sign Up";
  },
  destroyed() {
    clearInterval(interval);
  }
};
</script>

<style lang="scss" scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
