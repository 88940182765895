<template>
  <li>
    <router-link
      :to="viewPath"
      class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
    >
      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="min-w-0 flex-1 flex items-center">
          <div class="flex-shrink-0">
            <avatar-photo :person="contact" circle-size="12" text-size="lg" />
          </div>
          <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <div
                class="text-sm leading-5 font-medium text-indigo-600 truncate"
              >
                {{ contact.name }}
              </div>
              <div
                class="mt-2 flex items-center text-sm leading-5 text-gray-500"
              >
                <svg
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z"
                  />
                </svg>
                <span class="truncate">
                  {{ contact.industryRole | industryRoleAlias(contact.industrySubRole, contact.token) | capitalize }}<template v-if="contact.company"> ({{ contact.company }})</template>
                </span>
              </div>
            </div>
            <div class="hidden md:block">
              <div>
                <div class="text-sm leading-5 text-gray-900">
                  {{ followers }}
                </div>
                <div
                  class="mt-2 flex items-center text-sm leading-5 text-gray-500"
                >
                  <svg
                    :class="{
                      'text-green-400': hasDeals,
                      'text-gray-400': !hasDeals
                    }"
                    class="flex-shrink-0 mr-1.5 h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span class="truncate" v-if="contact.dealCount > 0"
                    >{{ contact | dealsCount }}:
                    {{ contact | dealsVolume }}</span
                  >
                  <span class="truncate" v-else>No deal history</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <svg
            class="h-5 w-5 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </router-link>
  </li>
</template>

<script>
import AvatarPhoto from "./avatar-photo";
import pluralize from "pluralize";

export default {
  components: { AvatarPhoto },
  props: ["contact"],
  computed: {
    viewPath() {
      return `/contacts/${this.contact.token}`;
    },
    hasDeals() {
      return this.contact.dealCount && this.contact.dealCount > 0;
    },
    followers() {
      return pluralize("follower", this.contact.followerCount || 0, true);
    }
  }
};
</script>
