<template>
  <div>
    <ul class="mt-4 w-full">
      <li v-for="property in properties" :key="property.id" class="flex justify-between py-1">
        <div class="flex flex-col text-left">
          <span class="text-sm font-medium leading-5 text-gray-700">{{ property.name }}, {{ property.cityState }}</span>
          <span v-if="displaySize" class="text-xs font-medium leading-5 text-gray-700">{{ [property] | totalSize }}</span>
          <router-link
            @click.native.stop="view(property)"
            :to="fullPath"
            :class="privacyMode ? 'text-pink-600 hover:text-pink-500' : 'text-indigo-600 hover:text-indigo-500'"
            class="text-xs"
          >
            Edit
          </router-link>
        </div>
        <span v-if="!readOnly" class="inline-flex rounded-md shadow-sm">
          <button @click="remove(property)" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
            Remove
          </button>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PropertyComponent from "../views/onboarding/deal/property";

export default {
  props: ["properties", "local", "displaySize", "readOnly"],
  computed: {
    ...mapState(["route", "modal"]),
    ...mapGetters(["privacyMode"]),
    fullPath() {
      return this.route.fullPath;
    }
  },
  methods: {
    view(property) {
      setTimeout(() => {
        let currentModal = false;

        if (this.modal) {
          currentModal = _.cloneDeep(this.modal); // obtains the current component
        }

        this.$store.commit("openModal", {
          component: PropertyComponent,
          props: { propertyId: property.id },
          afterClose: currentModal
        });
      }, 200);
    },
    remove(property) {
      if (!this.readOnly) {
        if (this.local) {
          this.$emit("remove", property);
        } else {
          this.$store.dispatch("removePropertyWithGetters", property);
          document.getElementById("property-search").focus();
        }
      }
    }
  }
};
</script>
