<template>
  <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
    <div class="rounded-md bg-yellow-50 p-4 sm:col-span-2">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm text-yellow-700">
            The information on this tab is accessible by anyone who can see this deal.
          </p>
          <p class="mt-3 text-sm md:mt-0 md:ml-6">
            <a v-show="false" @click.prevent href="" class="whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600">Learn more <span aria-hidden="true">&rarr;</span></a>
          </p>
        </div>
      </div>
    </div>
    <div v-if="deal.notes || deal.authored || isAdmin" class="sm:col-span-2">
      <dt class="text-sm leading-5 font-medium text-gray-500">
        <template v-if="deal.authored || isAdmin">
          <a
            @click.prevent="openModal({ record: deal, type: 'deal-notes' })"
            class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
          >
            <span>User commentary</span>
            <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
          </a>
        </template>
        <template v-else>User commentary</template>
      </dt>
      <dd v-if="deal.notes" v-html="linkifiedNotes" class="mt-1 text-sm leading-5 text-gray-900 whitespace-pre-line dont-break-out" />
      <dd v-else class="mt-1 text-sm leading-5 text-gray-900 whitespace-pre-line">...</dd>
    </div>
    <div v-if="mappable(deal)" class="sm:col-span-1">
      <dt class="text-sm leading-5 font-medium text-gray-500">
        Location
      </dt>
      <div class="mt-4 h-48 relative">
        <div class="absolute inset-x-0 top-0 transform translate-y-px pointer-events-none" style="z-index: 9995;">
          <div class="flex justify-center transform -translate-y-1/2">
            <a @click.prevent="strategicMap" href="" class="inline-flex items-center rounded-full text-sm px-4 py-1 leading-5 bg-indigo-600 hover:bg-indigo-700 font-semibold tracking-wider uppercase text-white pointer-events-auto">
              <!-- Heroicon name: cursor-click -->
              <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z" clip-rule="evenodd" />
              </svg>
              See comps on strategic map
            </a>
          </div>
        </div>

        <deal-map :deal="deal" />
      </div>
    </div>
    <div v-if="url || deal.authored || isAdmin" class="sm:col-span-1">
      <dt class="text-sm leading-5 font-medium text-gray-500">
        <template v-if="deal.authored || isAdmin">
          <a
            @click.prevent="openModal({ record: deal, type: 'deal-url' })"
            class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
          >
            <span>External link</span>
            <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
          </a>
        </template>
        <template v-else-if="url">
          <a @click="logExternalLinkClick()" :href="url" v-tooltip="'This post links to a page outside of Tower Hunt'" target="_blank" class="flex items-center text-indigo-600 hover:text-indigo-500">
            <span class="mr-1.5">
              <svg class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
              </svg>
            </span>
            External Link
          </a>
        </template>
      </dt>
      <dd v-if="url" class="mt-1">
        <div :id="`${deal.token}-url`" class="mt-4 mb-2 iframely-embed">
          <a data-iframely-url :href="url"></a>
        </div>
        <failed-url-preview-empty-state v-if="urlPreviewFailed" :url="url" />
      </dd>
      <dd v-else class="mt-1 text-sm leading-5 text-gray-900 whitespace-pre-line">...</dd>
    </div>
    <div v-if="deal.primaryAttachment || deal.authored || isAdmin" class="sm:col-span-1">
      <dt class="text-sm leading-5 font-medium text-gray-500">
        <template v-if="deal.authored || isAdmin">
          <a
            @click.prevent="openModal({ record: deal, type: 'file-attachment' })"
            class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
          >
            <span>Attached file</span>
            <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
          </a>
        </template>
        <template v-else>Attached file</template>
      </dt>
      <dd v-if="deal.primaryAttachment" class="mt-1">
        <div :id="`${deal.token}-attachment`" class="mt-4 mb-2 iframely-embed">
          <a data-iframely-url :href="deal.primaryAttachment"></a>
        </div>
        <failed-url-preview-empty-state v-if="attachmentPreviewFailed" :url="deal.primaryAttachment" />
      </dd>
      <dd v-else class="mt-1 text-sm leading-5 text-gray-900 whitespace-pre-line">...</dd>
    </div>
    <div v-if="deal.type !== 'Lease'" class="sm:col-span-1">
      <dt class="text-sm leading-5 font-medium text-gray-500">
        <template v-if="deal.authored || isAdmin">
          <a
            @click.prevent="openModal({ record: deal, type: 'risk-type' })"
            class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
          >
            <span>Strategies</span>
            <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
          </a>
        </template>
        <template v-else>Strategies</template>
      </dt>
      <dd class="mt-1 text-sm leading-5 text-gray-900">
        {{ deal.risks | sortedTypeLabels }}
      </dd>
    </div>
    <div class="sm:col-span-1">
      <dt class="text-sm leading-5 font-medium text-gray-500">
        Property uses
      </dt>
      <dd class="mt-1 text-sm leading-5 text-gray-900">
        {{ deal.properties | totalSize }}
      </dd>
    </div>
    <div class="sm:col-span-1">
      <dt class="text-sm leading-5 font-medium text-gray-500">
        Properties
      </dt>
      <dd class="mt-1 text-sm leading-5 text-gray-900">
        <a
          v-if="deal.authored || isAdmin"
          @click.prevent="openModal({ record: deal, type: 'deal-properties' })"
          v-tooltip="'Edit properties'"
          class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
        >
          {{ deal.properties | regionLabels }}
          <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
        </a>
        <template v-else>{{ deal.properties | regionLabels }}</template>
      </dd>
    </div>
    <div v-if="deal.coverPhoto || deal.authored || isAdmin" class="sm:col-span-1">
      <dt class="text-sm leading-5 font-medium text-gray-500">
        <template v-if="deal.authored || isAdmin">
          <a
            @click.prevent="openModal({ record: deal, type: 'deal-cover-photo' })"
            class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
          >
            <span>Cover photo</span>
            <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
          </a>
        </template>
        <template v-else>Cover photo</template>
      </dt>
      <dd v-if="deal.coverPhoto" class="mt-1 text-sm leading-5 text-gray-900 whitespace-pre-line">
        <img @click="openModal({ record: deal, type: 'open-photo' })" class="h-48 w-full object-cover cursor-pointer" :src="deal.coverPhoto" alt="Deal cover photo" />
      </dd>
      <dd v-else class="mt-1 text-sm leading-5 text-gray-900 whitespace-pre-line">...</dd>
    </div>
    <div class="sm:col-span-2">
      <dt class="text-sm leading-5 font-medium text-gray-500">
        People
      </dt>
      <dd v-if="deal.source === 'indirect' || (deal.source === 'direct' && participants.length === 0)" class="flex items-center py-2">
        <div class="min-w-0 flex-1 flex items-center">
          <span
            v-tooltip="'Anonymous tip'"
            class="h-8 w-8 flex-shrink-0 inline-flex items-center justify-center rounded-full bg-gray-400"
          >
            <svg class="h-4 w-4 text-white" fill="currentColor" viewBox="0 0 384 512">
              <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
            </svg>
          </span>
          <div class="min-w-0 flex-1 px-4">
            <div class="text-sm leading-5 font-medium text-gray-900 truncate">
              Anonymous tip
            </div>
          </div>
          <span v-if="deal.state === 'closed' && participants.length === 0" class="inline-flex rounded-md shadow-sm">
            <button @click="claimIndirectDeal" type="button" v-tooltip="'Indicate that you participated on this deal. This boosts your reputation on Tower Hunt.'" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
              Add to my profile
            </button>
          </span>
        </div>
      </dd>
      <ul v-else>
        <li v-for="contact in participants" :key="contact.token">
          <router-link
            @click.native="closeModal"
            :to="`/contacts/${contact.token}`"
            class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
          >
            <div class="flex items-center py-2">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="flex-shrink-0">
                  <avatar-photo :person="contact" circle-size="8" text-size="sm" />
                </div>
                <div class="min-w-0 flex-1 px-4">
                  <div>
                    <div
                      class="text-sm leading-5 font-medium text-indigo-600 truncate"
                    >
                      {{ contact.name }}
                      <span v-if="!deal.participating && contact.askedToJoin" class="font-normal text-gray-500">(Asked to join)</span>
                    </div>
                    <div
                      class="mt-2 flex items-center text-sm leading-5 text-gray-500"
                    >
                      <svg
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z"
                        />
                      </svg>
                      <span class="truncate">
                        {{ contact | dealRole("participant") }}
                        <template v-if="contact.company">({{ contact.company }})</template>
                      </span>
                    </div>
                  </div>
                </div>
                <span v-if="deal.source === 'direct lightweight' && deal.state === 'live' && contact.token !== currentUser.token" class="inline-flex rounded-md shadow-sm">
                  <button @click.stop.prevent="privateMessage(contact)" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
                    Private message
                  </button>
                </span>
                <span v-else-if="!deal.participating && !contact.askedToJoin && contact.token !== currentUser.token" class="inline-flex rounded-md shadow-sm">
                  <button @click.stop.prevent="askToJoin(contact)" type="button" v-tooltip="'This person can allow you to become a participant on this deal, which boosts your reputation.'" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
                    Ask to join
                  </button>
                </span>
              </div>
              <div>
                <svg
                  class="h-5 w-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </dl>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AvatarPhoto from "../../avatar-photo";
import Comments from "../../comments";
import DealJVRole from "../../../views/onboarding/deal/deal-jv-role";
import DealMap from "../../maps/deal-map";
import DealNotes from "../../../views/onboarding/general-notes";
import DealProperties from "../../../views/onboarding/deal/properties";
import DealRole from "../../../views/onboarding/deal/deal-role";
import FailedUrlPreviewEmptyState from "../../failed-url-preview-empty-state.vue";
import FileDragDrop from "../../file-drag-drop.vue";
import GeneralUrl from "../../../views/onboarding/general-url";
import PhotoCard from "../photo";
import PrivateMessage from "../../private-message";
import RiskTypes from "../../../views/onboarding/deal/risk-type";
import cleanUrl from "../../../clean-url";
import linkifyHtml from "linkifyjs/html";
/* global analytics */
/* global iframely */

export default {
  components: {
    AvatarPhoto,
    DealMap,
    FailedUrlPreviewEmptyState
  },
  props: ["deal"],
  data() {
    return {
      urlPreviewFailed: false,
      attachmentPreviewFailed: false,
      participants: []
    };
  },
  computed: {
    ...mapState([
      "modal",
      "properties",
      "route",
      "currentUser",
      "temporaryAccess",
      "adminMode"
    ]),
    ...mapGetters(["signedIn", "isAdmin"]),
    sharePath() {
      const urlRoot =
        location.protocol +
        "//" +
        location.hostname +
        (location.port ? ":" + location.port : "");

      return this.deal ? `${urlRoot}/open_deal/${this.deal.token}` : "#";
    },
    url() {
      if (this.deal && this.deal.url) {
        return cleanUrl(this.deal.url);
      } else {
        return null;
      }
    },
    primaryAttachment() {
      if (this.deal && this.deal.primaryAttachment) {
        return cleanUrl(this.deal.primaryAttachment);
      } else {
        return null;
      }
    },
    linkifiedNotes() {
      return linkifyHtml(_.get(this.deal, "notes", ""), {
        defaultProtocol: "https",
        className: "font-medium text-indigo-600 hover:text-indigo-500"
      });
    },
    directDeal() {
      return _.includes(["direct", "direct lightweight"], this.deal.source);
    }
  },
  watch: {
    url: {
      handler() {
        this.loadIframely(this.url);
      }
    },
    primaryAttachment: {
      handler() {
        this.loadIframely(this.primaryAttachment);
      }
    }
  },
  mounted() {
    this.fetchParticipants();
    this.loadIframely(this.url);
    this.loadIframely(this.primaryAttachment);

    if (window.iframely && !!analytics && !this.adminMode) {
      var self = this;

      iframely.on("click", function(href) {
        self.$store.dispatch("logExternalLinkClick", {
          contentType: self.deal.newsfeedType,
          contentToken: self.deal.token
        });
        analytics.track("Clicked deal URL preview card", {
          href,
          yearWeek: self.$store.state.yearWeek
        });
      });
    }
  },
  methods: {
    loadIframely(url) {
      if (url && /^https?:\/\//i.test(url) && window.iframely) {
        setTimeout(() => {
          iframely.load();
        }, 500);

        var self = this;

        iframely.on("cancel", function(url, parentNode) {
          if (parentNode.id === `${self.deal.token}-url`) {
            self.urlPreviewFailed = true;
          } else if (parentNode.id === `${self.deal.token}-attachment`) {
            self.attachmentPreviewFailed = true;
          }

          parentNode.remove();
        });
      }
    },
    closeModal() {
      this.$store.commit("closeModal");
    },
    logExternalLinkClick() {
      this.$store.dispatch("logExternalLinkClick", {
        contentType: this.deal.newsfeedType,
        contentToken: this.deal.token
      });
    },
    strategicMap() {
      this.$router.push({ path: `/map?deal=${this.deal.token}` });
      this.$store.commit("clearModal");
    },
    mappable(deal) {
      return this.hasProperties(deal);
    },
    hasProperties(deal) {
      return _.get(deal, "properties", []).length > 0;
    },
    fetchParticipants() {
      if (this.directDeal && _.isEmpty(this.participants)) {
        this.$store
          .dispatch("loadDealParticipants", this.deal.id)
          .then(json => {
            this.participants = json.data.participants;
          });
      }
    },
    claimIndirectDeal() {
      if (this.deal.type === "Recap Equity") {
        this.openModal({ record: this.deal, type: "jv-role" });
      } else {
        this.openModal({ record: this.deal, type: "deal-role" });
      }
    },
    privateMessage(contact) {
      this.openModal({ record: this.deal, type: "private-message", contact });
      if (!!analytics && !this.adminMode) {
        analytics.track("Messaged live deal team member", {
          yearWeek: this.$store.state.yearWeek
        });
      }
    },
    askToJoin(contact) {
      this.$store.dispatch("askToJoinDeal", {
        contact: contact,
        deal: this.deal
      });
      if (!!analytics && !this.adminMode) {
        analytics.track("Asked to join deal", {
          yearWeek: this.$store.state.yearWeek
        });
      }
      contact.askedToJoin = true;
    },
    viewComments() {
      this.$store.commit("openSlideover", {
        component: Comments,
        props: {
          content: this.deal
        }
      });

      if (!!analytics && !this.adminMode) {
        analytics.track("Viewed post comments", {
          yearWeek: this.$store.state.yearWeek
        });
      }
    },
    openModal({ record, type, contact = null }) {
      let dealCardModal = false;

      if (this.modal) {
        dealCardModal = _.cloneDeep(this.modal); // obtains the current component
        dealCardModal.props.deal = record; // allows changes to flow through
      }

      switch (type) {
        case "open-photo":
          this.$store.commit("openXLModal", {
            component: PhotoCard,
            props: {
              photo: { image: record.coverPhoto },
              captionable: false,
              coverPhotoDealId: this.deal.id
            },
            afterClose: dealCardModal,
            afterCloseDestination: "modal"
          });
          break;
        case "deal-role":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: DealRole,
            props: {
              dealId: record.id,
              claimingDeal: true
            },
            afterClose: dealCardModal
          });
          break;
        case "jv-role":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: DealJVRole,
            props: {
              dealId: record.id,
              claimingDeal: true
            },
            afterClose: dealCardModal
          });
          break;
        case "risk-type":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: RiskTypes,
            props: {
              dealId: record.id
            },
            afterClose: dealCardModal
          });
          break;
        case "deal-url":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: GeneralUrl,
            props: {
              dealId: record.id
            },
            afterClose: dealCardModal
          });
          break;
        case "deal-cover-photo":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: FileDragDrop,
            props: {
              endpoint: `deal_cover_photos/${record.id}`,
              fileTypes: "image/*",
              fileTypeLabels: "PNG, JPG, GIF",
              multiple: false,
              heading: "Let's add a cover photo",
              marginBottom: true,
              flashMessage: "Cover photo saved successfully",
              afterAction: {
                type: "commit",
                actionName: "setTemporaryAccess"
              }
            },
            afterClose: dealCardModal
          });
          break;
        case "file-attachment":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: FileDragDrop,
            props: {
              endpoint: `content_attachments/${record.newsfeedType}/${record.token}`,
              fileTypes: "image/*,application/pdf",
              fileTypeLabels: "PDF, PNG, JPG, GIF",
              sizeLimit: 25,
              multiple: false,
              heading: "Let's add an attachment",
              marginBottom: true,
              flashMessage: "File attachment saved successfully",
              afterAction: {
                type: "commit",
                actionName: "setTemporaryAccess"
              }
            },
            afterClose: dealCardModal
          });
          break;
        case "deal-notes":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: DealNotes,
            props: {
              dealId: record.id
            },
            afterClose: dealCardModal
          });
          break;
        case "deal-properties":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: DealProperties,
            props: {
              dealId: record.id
            },
            afterClose: dealCardModal
          });
          break;
        case "private-message":
          this.$store.commit("openModal", {
            component: PrivateMessage,
            props: {
              deal: record,
              contact
            },
            afterClose: dealCardModal
          });
      }
    }
  }
};
</script>
