export default {
  title: "Share existing content",
  text: "Recipients will get an email from you that includes your note and the shared content. The content being shared retains its existing privacy status.",
  showCancelButton: false,
  showCloseButton: true,
  confirmButtonColor: "#5850ec",
  cancelButtonColor: "#b7b7b7",
  cancelButtonText: "Post as me",
  confirmButtonText: "Share as me",
  reverseButtons: false,
  focusConfirm: true,
  customClass: {
    header: "thswal-header-class",
    title: "thswal-title-class",
    closeButton: "thswal-close-button-class",
    content: "thswal-content-class",
    actions: "thswal-actions-class",
    confirmButton: "thswal-confirm-button-class",
    cancelButton: "thswal-cancel-button-class"
  }
};
