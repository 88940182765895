<template>
  <div>
    <!-- Intents list (only on smallest breakpoint) -->
    <div class="mt-10 sm:hidden">
      <div class="px-4 sm:px-6">
        <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Seller Source Material</h2>
      </div>
      <ul class="mt-3 border-t border-gray-200 divide-y divide-gray-100">
        <intent-list-row v-for="intent in intents" :key="intent.id" :intent="intent" />
      </ul>
    </div>
    <!-- Intents table (small breakpoint and up) -->
    <div class="hidden mt-8 sm:block">
      <div class="align-middle inline-block min-w-full border-b border-gray-200">
        <table class="min-w-full">
          <thead>
            <tr class="border-t border-gray-200">
              <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                <span class="lg:pl-2">Seller Source Material</span>
              </th>
              <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th class="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 whitespace-nowrap text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Last updated
              </th>
              <th class="pr-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-100">
            <intent-table-row v-for="intent in intents" :key="intent.id" :intent="intent" @refetch="fetchIntents" />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import IntentListRow from "./intent-list-row";
import IntentTableRow from "./intent-table-row";
import api from "../../../api";

export default {
  components: { IntentListRow, IntentTableRow },
  data() {
    return {
      intents: []
    };
  },
  mounted() {
    this.fetchIntents();
  },
  methods: {
    fetchIntents() {
      api.get(`purchaseable_intents`).then(json => {
        this.intents = json.data;
      });
    }
  }
};
</script>
