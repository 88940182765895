<template>
  <div class="bg-gray-900">
    <go-home />
    <div class="pt-12 sm:pt-16 lg:pt-24">
      <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
        <div class="max-w-3xl mx-auto space-y-2 lg:max-w-none">
          <h2 class="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
            Merge Deals
          </h2>
          <p class="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
            Let's make Tower Hunt better
          </p>
          <p class="text-xl text-gray-300">
            Everything works better when there's one version of a deal. Your private data always stays private.
          </p>
        </div>
      </div>
    </div>
    <div class="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
      <div class="relative">
        <div class="absolute inset-0 h-3/4 bg-gray-900"></div>
        <div class="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
            <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                <div>
                  <h3 class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-yellow-100 text-yellow-600" id="archiving-badge">
                    Archiving
                  </h3>
                </div>
                <div class="mt-4">
                  <multi-content-preview-card v-if="retiree" :content="retiree" :compact="true" />
                  <div v-else class="flex justify-center">
                    <grid-loader :loading="true" size="6px" color="#D4D4D8" />
                  </div>
                </div>
              </div>
              <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                <ul class="space-y-4">
                  <template v-if="hasVitalsConflicts">
                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <!-- Heroicon name: outline/check -->
                        <svg class="h-6 w-6 text-yellow-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">
                        Conflicts...
                      </p>
                    </li>
                  </template>
                  <template v-if="hasSafezone">
                    <li v-if="retireeMergeMetadata.notes > 0" class="flex items-start">
                      <div class="flex-shrink-0">
                        <!-- Heroicon name: outline/check -->
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">
                        Migrate {{ notepadLabel }} from notepad
                      </p>
                    </li>

                    <li v-if="retireeMergeMetadata.safezoneData > 0" class="flex items-start">
                      <div class="flex-shrink-0">
                        <!-- Heroicon name: outline/check -->
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">
                        Migrate {{ safezoneDataLabel }}
                      </p>
                    </li>

                    <li v-if="retireeMergeMetadata.safezoneFiles > 0" class="flex items-start">
                      <div class="flex-shrink-0">
                        <!-- Heroicon name: outline/check -->
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">
                        Migrate {{ safezoneFileLabel }}
                      </p>
                    </li>

                    <li v-if="retireeMergeMetadata.backchannelPosts > 0" class="flex items-start">
                      <div class="flex-shrink-0">
                        <!-- Heroicon name: outline/check -->
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">
                        Migrate {{ backchannelPostsLabel }}
                      </p>
                    </li>
                  </template>

                  <li v-else class="flex items-start">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: outline/check -->
                      <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p class="ml-3 text-base text-gray-700">
                      No safezone data to migrate
                    </p>
                  </li>

                  <li v-if="retireePinned" class="flex items-start">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: outline/check -->
                      <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p class="ml-3 text-base text-gray-700">
                      Migrate "thanked" bookmark
                    </p>
                  </li>

                  <li v-if="hasPublicComments" class="flex items-start">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: outline/check -->
                      <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p class="ml-3 text-base text-gray-700">
                      Migrate {{ publicCommentsLabel }}
                    </p>
                  </li>

                  <li v-if="hasBounties" class="flex items-start">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: outline/check -->
                      <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p class="ml-3 text-base text-gray-700">
                      Migrate {{ bountiesLabel }}
                    </p>
                  </li>
                </ul>
                <div v-if="hasVitalsConflicts" class="rounded-md shadow">
                  <a @click.prevent href="" v-tooltip="'This lets you contribute some information to the surviving deal. If you don\'t resolve, the archiving deal info will be archived.'" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900" aria-describedby="tier-standard">
                    Resolve conflicts (optional)
                  </a>
                </div>
              </div>
            </div>

            <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                <div>
                  <h3 class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-green-100 text-green-600" id="surviving-badge">
                    Surviving
                  </h3>
                </div>
                <div class="mt-4">
                  <multi-content-preview-card v-if="survivor" :content="survivor" :compact="true" />
                  <div v-else class="flex justify-center">
                    <grid-loader :loading="true" size="6px" color="#D4D4D8" />
                  </div>
                </div>
              </div>
              <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                <ul class="space-y-4">
                  <li v-if="hasSafezone" class="flex items-start">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: outline/check -->
                      <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p class="ml-3 text-base text-gray-700">
                      We migrate everything for you
                    </p>
                  </li>

                  <li v-if="hasSafezone" class="flex items-start">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: outline/check -->
                      <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p class="ml-3 text-base text-gray-700">
                      Your private data stays private
                    </p>
                  </li>

                  <li class="flex items-start">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: outline/check -->
                      <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p class="ml-3 text-base text-gray-700">
                      This deal receives crowdsourced updates
                    </p>
                  </li>

                  <li v-if="false" class="flex items-start">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: outline/check -->
                      <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p class="ml-3 text-base text-gray-700">
                      Contribute data to this deal to earn money
                    </p>
                  </li>
                </ul>
                <div class="rounded-md shadow">
                  <a @click.prevent="merge" href="" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900" aria-describedby="tier-standard">
                    <template v-if="hasSafezone">Migrate my safezone data</template>
                    <template v-else>Transition to this deal</template>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoHome from "../../components/onboarding/go-home";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import MultiContentPreviewCard from "../../components/cards/multi-content-preview-card";
import api from "../../api";
import pluralize from "pluralize";

export default {
  components: { GoHome, GridLoader, MultiContentPreviewCard },
  props: ["retireeToken", "survivorToken"],
  data() {
    return {
      retiree: null,
      retireeMergeMetadata: null,
      survivor: null
    };
  },
  computed: {
    hasVitalsConflicts() {
      return false;
    },
    hasSafezone() {
      if (this.retireeMergeMetadata) {
        const {
          backchannelPosts,
          notes,
          safezoneData,
          safezoneFiles
        } = this.retireeMergeMetadata;

        return (
          notes > 0 ||
          safezoneData > 0 ||
          safezoneFiles > 0 ||
          backchannelPosts > 0
        );
      } else {
        return false;
      }
    },
    retireePinned() {
      return this.retireeMergeMetadata && this.retireeMergeMetadata.pinned;
    },
    hasPublicComments() {
      return (
        this.retireeMergeMetadata && this.retireeMergeMetadata.publicComments > 0
      );
    },
    hasBounties() {
      return (
        this.retireeMergeMetadata && this.retireeMergeMetadata.attachedBounties > 0
      );
    },
    notepadLabel() {
      return pluralize("note", this.retireeMergeMetadata.notes, true);
    },
    safezoneDataLabel() {
      return pluralize("safezone datapoint", this.retireeMergeMetadata.safezoneData, true);
    },
    safezoneFileLabel() {
      return pluralize("safezone file", this.retireeMergeMetadata.safezoneFiles, true);
    },
    backchannelPostsLabel() {
      return pluralize("backchannel post", this.retireeMergeMetadata.backchannelPosts, true);
    },
    publicCommentsLabel() {
      return pluralize("public comment", this.retireeMergeMetadata.publicComments, true);
    },
    bountiesLabel() {
      return pluralize("attached bounty", this.retireeMergeMetadata.attachedBounties, true);
    }
  },
  mounted() {
    this.fetchVersions();
    this.fetchMergeMetadata();
  },
  methods: {
    fetchVersions() {
      this.fetchDeal(this.retireeToken).then(deal => {
        this.retiree = deal;
      });
      this.fetchDeal(this.survivorToken).then(deal => {
        this.survivor = deal;
      });
    },
    fetchDeal(token) {
      return new Promise(resolve => {
        api.get(`open_deal/${token}`).then(json => {
          const deal = json.data;

          resolve(deal);
        });
      });
    },
    fetchMergeMetadata() {
      api.get(`merge_metadata/deal/${this.retireeToken}`).then(json => {
        this.retireeMergeMetadata = json.data;
      });
    },
    merge() {
      this.$store.commit("isLoading");
      api
        .post(`merge_deal/${this.retireeToken}/${this.survivorToken}`)
        .then(json => {
          this.$store.commit("doneLoading");
          this.$store.dispatch("flash", "Deals successfully merged!");
          this.$router.push({ path: `/open_deal/${this.survivorToken}` });
        });
    }
  }
};
</script>
