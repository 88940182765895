import * as moment from "moment";
import DealCard from "./components/cards/deal";
import IntelCard from "./components/cards/market-intel";
import innerIconFor from "./property-reference-inner-icons";
import responsiveDeduplicatePopup from "./leaflet-responsive-map-deduplicate-card";
import responsivePreviewCardPopup from "./leaflet-responsive-map-popup-preview-card";
/* global L */

export default function nearbyPropertyMarker({
  router,
  store,
  featureGroup,
  map,
  property,
  strategic,
  clickable,
  deduplicate
}) {
  const age = moment().diff(property.mostRecentReferenceDate, "days");
  let fillColor;
  let borderColor;
  let iconSize = [20, 20];
  let heartbeat = 2;
  let innerIconSize = "0.75rem";

  if (property.markerType === "competitiveSet") {
    fillColor = "#F87171";
  } else if (property.markerType === "focal") {
    iconSize = [30, 30];
    fillColor = "#EF4444";
    heartbeat = 1.5;
    innerIconSize = "1rem";
  } else if (property.mostRecentReferenceType === "Deal") {
    if (age <= 7) {
      fillColor =
        property.mostRecentReferenceSubtype === "Lease" ? "#16A34A" : "#D97706";
    } else if (age <= 30) {
      fillColor =
        property.mostRecentReferenceSubtype === "Lease" ? "#22C55E" : "#F59E0B";
    } else if (age <= 60) {
      fillColor =
        property.mostRecentReferenceSubtype === "Lease" ? "#4ADE80" : "#FBBF24";
    } else if (age <= 90) {
      fillColor =
        property.mostRecentReferenceSubtype === "Lease" ? "#86EFAC" : "#FCD34D";
    } else if (age <= 365) {
      fillColor =
        property.mostRecentReferenceSubtype === "Lease" ? "#BBF7D0" : "#FDE68A";
    } else {
      fillColor =
        property.mostRecentReferenceSubtype === "Lease" ? "#DCFCE7" : "#FEF3C7";
    }
  } else if (property.mostRecentReferenceType === "MarketIntelPost") {
    if (age <= 7) {
      fillColor = "#2563EB";
    } else if (age <= 14) {
      fillColor = "#3B82F6";
    } else if (age <= 21) {
      fillColor = "#60A5FA";
    } else if (age <= 30) {
      fillColor = "#93C5FD";
    } else if (age <= 60) {
      fillColor = "#BFDBFE";
    } else {
      fillColor = "#DBEAFE";
    }
  } else {
    fillColor = "#A1A1AA";
  }

  switch (property.internalPriority) {
    case "high":
      borderColor = "#D946EF";
      break;
    case "medium":
      borderColor = "#8B5CF6";
      break;
    case "low":
      borderColor = "#06B6D4";
  }

  if (!borderColor && property.watchlisted) {
    borderColor = "#6B7280";
  }

  var pulsingIcon = L.icon.pulse({
    iconSize,
    fillColor,
    borderColor,
    color: borderColor || fillColor,
    animate: property.markerType === "focal" || property.isLive,
    heartbeat,
    innerIconSize,
    innerIconContentCode: innerIconFor(property.mostRecentReferenceVisibility)
  });

  const marker = L.marker([property.lat, property.lng], {
    riseOnHover: true,
    icon: pulsingIcon
  }).addTo(map);

  const tooltip =
    property.markerType === "competitiveSet"
      ? `Comp set: ${property.customName || property.name}`
      : property.customName || property.name;

  marker.bindTooltip(tooltip);

  if (clickable) {
    marker.on("click", e => {
      if (strategic) {
        if (L.Browser.mobile) {
          switch (property.mostRecentReferenceType) {
            case "Deal":
              store.commit("openModal", {
                component: DealCard,
                props: {
                  dealToken: property.mostRecentReferenceId,
                  property
                }
              });
              break;
            case "MarketIntelPost":
              store.commit("openModal", {
                component: IntelCard,
                props: {
                  postToken: property.mostRecentReferenceId,
                  property
                }
              });
              break;
            default:
              console.log(property.name);
          }
        } else {
          const responsivePopup = responsivePreviewCardPopup({
            store,
            router,
            contentId: property.mostRecentReferenceId,
            contentType: property.mostRecentReferenceType,
            property
          });

          marker
            .unbindPopup()
            .bindPopup(responsivePopup, { minWidth: L.Browser.mobile ? 330 : 400 })
            .openPopup();
        }
      } else if (deduplicate) {
        const deduplicatePopup = responsiveDeduplicatePopup({
          store,
          router,
          contentId: property.id,
          contentType: "Property"
        });

        marker
          .unbindPopup()
          .bindPopup(deduplicatePopup, { minWidth: 330 })
          .openPopup();
      } else {
        let queryLabel;

        if (property.mostRecentReferenceType === "Deal") {
          queryLabel = "deal";
        } else if (property.mostRecentReferenceType === "MarketIntelPost") {
          queryLabel = "intel";
        }

        if (queryLabel) {
          router.push({
            path: `/map?${queryLabel}=${property.mostRecentReferenceId}`
          });
          store.commit("clearModal");
        }
      }
    });
  }

  featureGroup.addLayer(marker);
  property.mapInternalId = featureGroup.getLayerId(marker);
}
