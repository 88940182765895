import NumberHelpers from "number_helpers";
import useTypes from "./use-types";
import _ from "lodash";

export function formattedDollarAmount(amount) {
  const numberOptions = {
    significant: false,
    precision: 1,
    labels: { million: "M", billion: "B", thousand: "K" },
    space_label: false
  };
  const rounded = _.round(amount, 2);
  return NumberHelpers.number_to_human(rounded, numberOptions);
}

export function dollarAmount(amount) {
  const rounded = _.round(amount, 2);
  return NumberHelpers.number_to_currency(rounded, { precision: 0 });
}

export function currencyAmount(amount, precision = 0) {
  const rounded = _.round(amount, 2);
  return NumberHelpers.number_to_currency(rounded, { precision, unit: "" });
}

export const currencyThreshold = 1000000;

export function currencyBelowThreshold(amount, threshold = currencyThreshold) {
  if (amount < threshold) {
    return currencyAmount(amount);
  } else {
    return formattedDollarAmount(amount);
  }
}

export function capitalMarketsVolume(record) {
  return `$${formattedDollarAmount(_.toNumber(record.capitalMarketsDealVolume) * 1000000)}`;
}

export function leaseVolume(record) {
  return `${currencyAmount(_.toNumber(record.leaseDealVolume))} leased sf`;
}

export function propertySizeRange(use) {
  const useData = _.find(useTypes, { value: use.type });
  const { name, measure } = useData;

  if (use.minimum && use.maximum) {
    return `${currencyAmount(use.minimum)} - ${currencyAmount(use.maximum)} ${name} ${measure}`;
  } else if (use.minimum) {
    return `${currencyAmount(use.minimum)}+ ${name} ${measure}`;
  } else if (use.maximum) {
    return `Up to ${currencyAmount(use.maximum)} ${name} ${measure}`;
  } else {
    return null;
  }
}
