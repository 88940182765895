<template>
  <div class="px-4 mt-6 sm:px-6 lg:px-8">
    <bounty-earnings-pipeline v-if="isEngagements" class="mt-3 mb-12" />
    <h3 v-if="isEngagements" class="mb-5 text-lg leading-6 font-medium text-gray-900">
      Bounties
    </h3>
    <ul v-if="cards.data.length > 0" class="mt-3 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <multi-content-preview-card
        v-for="(card, index) in cards.data"
        :key="`${card.content.token}-${index}`"
        :content="card.content"
        :attached-bounty="card.attachedBounty"
      />

      <infinite-loading
        v-if="loaded && !endOfFeed"
        :identifier="currentUser.token"
        @infinite="incrementCards"
      />
    </ul>
    <div v-else>
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="text-center">
          <h1 class="text-base leading-6 font-semibold text-indigo-600 tracking-wide uppercase">
            <template v-if="isActive">Active Bounties</template>
            <template v-else-if="isEngagements">My Engagements</template>
          </h1>
          <p class="mt-1 text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
            <template v-if="isActive">Earn money for local expertise</template>
            <template v-else-if="isEngagements">Fulfill bounties to get paid</template>
          </p>
          <p class="max-w-xl mt-5 mx-auto text-xl leading-7 text-gray-500">
            <template v-if="isActive">Anonymously bid on bounties to earn money. <router-link to="/product/bounty-bids" class="underline">Learn more</router-link></template>
            <template v-else-if="isEngagements">Bounties you're working on will appear here. <router-link to="/product/bounty-bids" class="underline">Learn more</router-link></template>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BidPrivateChat from "../components/bounties/bid-private-chat";
import BidReview from "../components/bounties/bid-review";
import BountyEarningsPipeline from "../components/bounties/bounty-earnings-pipeline";
import InfiniteLoading from "vue-infinite-loading";
import MultiContentPreviewCard from "../components/cards/multi-content-preview-card";
import api from "../api";

export default {
  components: {
    BountyEarningsPipeline,
    MultiContentPreviewCard,
    InfiniteLoading
  },
  data() {
    return {
      cards: {
        data: [],
        pagy: null
      },
      busy: false,
      endOfFeed: false,
      loaded: false
    };
  },
  computed: {
    ...mapState(["currentUser", "temporaryAccess", "route"]),
    pagination() {
      return this.cards.pagy;
    },
    isActive() {
      return this.route.path === "/bounties/active";
    },
    isEngagements() {
      return this.route.path === "/bounties/my-engagements";
    },
    baseEndpoint() {
      if (this.isEngagements) {
        return "my_bounty_engagements";
      } else {
        return "active_help_requests";
      }
    }
  },
  watch: {
    route: {
      handler(route, oldRoute) {
        if (route.path !== oldRoute.path) {
          this.fetchCards();
        }
      }
    },
    temporaryAccess: {
      handler() {
        if (_.isArray(this.temporaryAccess)) {
          const newCards = _.unionBy(
            [this.temporaryAccess],
            this.cards.data,
            "id"
          );

          this.cards.data = newCards;
          this.$store.commit("clearTemporaryAccess");
        }
      }
    }
  },
  mounted() {
    this.fetchCards();
  },
  methods: {
    fetchCards() {
      this.$store.commit("isLoading");
      api.get(this.baseEndpoint).then(json => {
        this.cards = json.data;
        this.loaded = true;
        this.$store.commit("doneLoading");
        this.handleChatParam();
      });
    },
    extendCards({ data, pagy }) {
      this.cards.data = this.cards.data.concat(data);
      this.cards.pagy = pagy;
    },
    incrementCards($state) {
      if (this.pagination) {
        const { count, to } = this.pagination;

        if (count === 0) {
          this.endOfFeed = true;
        } else if (to < count) {
          const cleanUrl = _.drop(this.pagination.next_url, 8).join("");

          this.busy = true;

          api.get(cleanUrl).then(json => {
            this.extendCards(json.data);
            $state.loaded();
            this.busy = false;
          });
        } else {
          this.endOfFeed = true;
          $state.complete();
        }
      }
    },
    handleChatParam() {
      const bidId = _.get(this.$store.state.route, "query.bid", null);
      const openChat = _.get(this.$store.state.route, "query.chat", null);

      if (bidId) {
        api.get(`bounty_bids/${bidId}`).then(
          json => {
            const { bounty, bid } = json.data;

            this.$store.commit("openModal", {
              component: BidReview,
              props: { bounty, bid }
            });

            if (openChat) {
              this.$store.commit("openSlideover", {
                component: BidPrivateChat,
                props: { bounty, bid }
              });
            }
          },
          failure => {
            this.$store.dispatch("flash", "Invalid link");
            this.$router.push({ path: "/bounties/my-bounties" });
          }
        );
      }
    }
  }
};
</script>
