<template>
  <div v-observe-visibility="{ callback: createImpression }" :class="compact ? 'mt-2' : 'mt-6'" class="w-full">
    <div class="w-full bg-white rounded-lg shadow">
      <div class="relative" style="z-index: 2;">
        <div class="absolute inset-x-0 top-0 transform translate-y-px">
          <div class="flex justify-center transform -translate-y-1/2">
            <span :class="compact ? 'px-2 py-1' : 'px-4 py-1'" class="inline-flex rounded-full bg-gradient-to-r from-yellow-300 to-yellow-500 text-xs leading-3 font-semibold tracking-wider uppercase text-white">
              <template v-if="bounty.state === 'fulfilled'">Fulfilled&nbsp;</template>
              <template v-else-if="bounty.expired">Expired&nbsp;</template>
              <template v-else-if="bounty.authored">My&nbsp;</template>
              Bounty
            </span>
          </div>
        </div>
      </div>

      <div class="relative overflow-hidden rounded-lg">
        <div class="border-yellow-300 pointer-events-none absolute inset-0 rounded-lg border-2" style="z-index: 1;"></div>
        <div :class="compact ? 'p-2' : 'p-6'" class="w-full">
          <div class="mb-2 flex justify-between items-center">
            <h3 class="text-xs font-medium text-gray-900">
              Requested by {{ requsterAlias }}
            </h3>
            <attached-bounty-menu v-if="signedIn" :bounty="bounty" @deleted="deleted" />
          </div>
          <resizable-textarea v-if="editing">
            <textarea v-focus v-model="localDescription" id="description" type="text" rows="3" placeholder="Specific requests get better results. What data? Where? How recent? What format? What have you already tried?" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-sm py-1 px-2 block w-full"></textarea>
          </resizable-textarea>
          <div v-else v-html="linkifiedDescription" :class="compact ? 'text-xs' : 'text-sm'" class="text-gray-900 whitespace-pre-line dont-break-out" />
          <a v-if="editing" href="" @click.prevent="save" :class="compact ? 'text-xs' : 'text-sm'" class="font-medium text-indigo-600 hover:text-indigo-500">Save changes</a>
          <a v-else-if="isAdmin && bounty.approvalStatus === 'pending'" href="" @click.prevent="edit" :class="compact ? 'text-xs' : 'text-sm'" class="font-medium text-indigo-600 hover:text-indigo-500">Edit request</a>
        </div>
        <bounty-actions :bounty="bounty" :compact="compact" />
      </div>
    </div>
    <div v-if="signedIn && bounty.approvalStatus === 'approved'" class="mt-2">
      <button @click="togglePin" type="button" v-tooltip="pinHelp" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
        <svg v-if="bounty.pinned" class="-ml-0.5 mr-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" clip-rule="evenodd" />
        </svg>
        <svg v-else class="-ml-0.5 mr-1 h-4 w-4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
        </svg>
        {{ bounty.pinCount }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AttachedBountyMenu from "./attached-bounty-menu";
import BountyActions from "./bounty-actions";
import ResizableTextarea from "../resizable-textarea";
import api from "../../api";
import linkifyHtml from "linkifyjs/html";

export default {
  components: { AttachedBountyMenu, BountyActions, ResizableTextarea },
  props: ["bounty", "compact"],
  data() {
    return {
      editing: false,
      localDescription: ""
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "signedIn"]),
    requsterAlias() {
      if (this.bounty.sponsored) {
        return "Principal";
      } else {
        return _.capitalize(
          this.$options.filters.industryRoleAlias(
            this.bounty.requesterIndustryRole,
            this.bounty.requesterIndustrySubRole
          )
        );
      }
    },
    complete() {
      return _.trim(this.localDescription) !== "";
    },
    linkifiedDescription() {
      return linkifyHtml(this.localDescription, {
        defaultProtocol: "https",
        className: "font-medium text-indigo-600 hover:text-indigo-500"
      });
    },
    pinHelp() {
      if (this.bounty.pinned) {
        return "Following";
      } else {
        return "Get notified if this bounty gets fulfilled and the content is available to buy.";
      }
    }
  },
  mounted() {
    this.localDescription = this.bounty.requestDescription;
  },
  methods: {
    createImpression(isVisible, entry) {
      if (!this.isAdmin && this.signedIn && isVisible) {
        this.$store.dispatch("createBountyImpression", this.bounty.id).then(() => {
          this.$store.dispatch("loadUnreadCounts");
        });
      }
    },
    save() {
      if (this.complete) {
        const payload = {
          requestDescription: this.localDescription
        };

        api
          .patch(`bounty_descriptions/${this.bounty.id}`, payload)
          .then(json => {
            this.bounty.myBid = json.data;
            this.$store.dispatch("flash", "Bounty description updated.");
            this.editing = false;
          });
      }
    },
    edit() {
      this.editing = true;
    },
    togglePin() {
      if (this.bounty.pinned) {
        this.unpin();
      } else {
        this.pin();
      }
    },
    pin() {
      this.bounty.pinCount++;
      this.bounty.pinned = true;

      api.post(`pin_bounty/${this.bounty.id}`).then(() => {
        if (!!analytics) {
          analytics.track("Bounty upvoted", {
            yearWeek: this.$store.state.yearWeek
          });
        }
      });
    },
    unpin() {
      this.bounty.pinCount--;
      this.bounty.pinned = false;

      api.delete(`pin_bounty/${this.bounty.id}`).then(() => {});
    },
    deleted() {
      this.$emit("deleted", this.bounty.id);
    }
  }
};
</script>
