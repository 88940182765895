import MapFilterSlideover from "./components/maps/filter-slideover";
/* global L */

export default function filterControl({ store, router }) {
  return L.easyButton({
    id: "strategic-map-filter-control",
    states: [
      {
        stateName: "default",
        icon: "fa-filter",
        title: "Filter the map",
        onClick: function(btn, map) {
          if (store.getters.signedIn) {
            store.commit("openSlideover", {
              component: MapFilterSlideover,
              props: {
                mapFilter: true
              }
            });
          } else {
            router.push({
              path: "/sign-in",
              query: { nextUrl: store.state.route.fullPath }
            });
          }
        }
      }
    ]
  });
}
