<template>
  <div v-if="post && property">
    <div class="relative pb-5 border-b border-gray-200 space-y-4 sm:pb-0">
      <div class="space-y-3 md:flex md:items-center md:justify-between md:space-y-0">
        <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
          <h3 class="ml-2 mt-2 text-lg leading-6 font-medium text-gray-900">
            <span class="sm:hidden">{{ property.name | truncate(30) }}</span>
            <span class="hidden sm:block">{{ property.name }}</span>
          </h3>
        </div>
        <div class="flex items-center space-x-3 md:absolute md:top-3 md:right-0">
          <template>
            <router-link to="/off-market/card-manager" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium shadow-sm rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
              <svg class="-ml-1 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
              </svg>
              Back to card manager
            </router-link>
          </template>
        </div>
      </div>
      <div>
        <!-- Dropdown menu on small screens -->
        <div class="sm:hidden">
          <select v-model="selectValue" @change="navigate" aria-label="Selected tab" class="block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm sm:leading-5">
            <option value="market-interest">Market Interest</option>
            <option value="messages">Messages</option>
          </select>
        </div>
        <!-- Tabs at small breakpoint and up -->
        <div class="hidden sm:block">
          <nav class="-mb-px flex space-x-8">
            <router-link to="market-interest" v-slot="{ href, navigate, isExactActive }">
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isExactActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Market Interest
              </a>
            </router-link>

            <router-link to="messages" v-slot="{ href, navigate, isExactActive }">
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isExactActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Messages
              </a>
            </router-link>
          </nav>
        </div>
      </div>
    </div>

    <router-view :post="post" />
  </div>
</template>

<script>
import api from "../api";

export default {
  props: ["postToken"],
  data() {
    return {
      post: null,
      property: null,
      selectValue: null
    };
  },
  mounted() {
    api.get(`gauge/${this.postToken}`).then(
      json => {
        this.post = json.data;
      },
      failure => {
        this.$store.dispatch("flash", "Invalid sharing link");
        this.$router.push({ path: "/off-market/card-manager" });
      }
    );
    api.get(`gauge_property/${this.postToken}`).then(
      json => {
        this.property = json.data;
        document.title = `Gauge ${this.property.name} | Tower Hunt`;
      },
      failure => {
        this.$store.dispatch("flash", "Invalid sharing link");
        this.$router.push({ path: "/off-market/card-manager" });
      }
    );

    switch (this.$store.state.route.path) {
      case `/gauge/${this.postToken}/messages`:
        this.selectValue = "messages";
        break;
      default:
        if (this.$store.state.route.path !== `/gauge/${this.postToken}/market-interest`) {
          this.$router.push({ path: `/gauge/${this.postToken}/market-interest` });
        }
        this.selectValue = "market-interest";
    }
  },
  methods: {
    navigate() {
      if (this.$store.state.route.path !== `/gauge/${this.postToken}/${this.selectValue}`) {
        this.$router.push({ path: this.selectValue });
      }
    }
  }
};
</script>
