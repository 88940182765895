<template>
  <section aria-labelledby="datapoints-heading">
    <impression-metrics :content="content" class="mt-1" />
  </section>
</template>

<script>
import ImpressionMetrics from "../impression-metrics";

export default {
  components: { ImpressionMetrics },
  props: ["content", "contentType"]
};
</script>
