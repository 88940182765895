<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="max-w-330 mx-auto flex flex-col items-center">
      <heading margin-override="mt-14 mb-6">Select Intel Privacy</heading>

      <fieldset>
        <legend class="sr-only">
          Privacy setting
        </legend>
        <div class="bg-white rounded-md -space-y-px">
          <!-- Checked: "bg-indigo-50 border-indigo-200 z-10", Not Checked: "border-gray-200" -->
          <label :class="privacy === 'safezone' ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200'" class="rounded-tl-md rounded-tr-md relative border p-4 flex cursor-pointer">
            <input v-model="privacy" type="radio" name="privacy_setting" value="safezone" class="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="privacy-setting-0-label" aria-describedby="privacy-setting-0-description">
            <div class="ml-3 flex flex-col">
              <!-- Checked: "text-indigo-900", Not Checked: "text-gray-900" -->
              <span id="privacy-setting-0-label" :class="privacy === 'safezone' ? 'text-indigo-900' : 'text-gray-900'" class="block text-sm font-medium">
                Private to you (Safezone)
              </span>
              <!-- Checked: "text-indigo-700", Not Checked: "text-gray-500" -->
              <span id="privacy-setting-0-description" :class="privacy === 'safezone' ? 'text-indigo-700' : 'text-gray-500'" class="block text-sm">
                You are the only one able to access this intel
              </span>
            </div>
          </label>

          <!-- Checked: "bg-indigo-50 border-indigo-200 z-10", Not Checked: "border-gray-200" -->
          <label :class="privacy === 'public' ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200'" class="rounded-bl-md rounded-br-md relative border p-4 flex cursor-pointer">
            <input v-model="privacy" type="radio" name="privacy_setting" value="public" class="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="privacy-setting-1-label" aria-describedby="privacy-setting-1-description">
            <div class="ml-3 flex flex-col">
              <!-- Checked: "text-indigo-900", Not Checked: "text-gray-900" -->
              <span id="privacy-setting-1-label" :class="privacy === 'public' ? 'text-indigo-900' : 'text-gray-900'" class="block text-sm font-medium">
                Public access
              </span>
              <!-- Checked: "text-indigo-700", Not Checked: "text-gray-500" -->
              <span id="privacy-setting-1-description" :class="privacy === 'public' ? 'text-indigo-700' : 'text-gray-500'" class="block text-sm">
                This intel would be available to anyone in its market. You can post anonymously or as yourself.
              </span>
            </div>
          </label>
        </div>
      </fieldset>

      <button @click="proceed" type="button" class="my-6 inline-flex items-center justify-center px-6 py-3 border border-transparent uppercase text-base font-bold rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Continue
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Heading from "../../components/onboarding/heading";

export default {
  components: { Heading },
  data() {
    return {
      privacy: "safezone"
    };
  },
  computed: {
    ...mapState(["newRecordIsSafezone", "route"]),
    nextUrl() {
      return this.$route.query.nextUrl || "/intel/new/1";
    }
  },
  mounted() {
    this.privacy = this.newRecordIsSafezone ? "safezone" : "public";
  },
  methods: {
    proceed() {
      this.$store.commit("setNewRecordSafezone", this.privacy === "safezone");
      this.$router.push({ path: this.nextUrl });
    }
  }
};
</script>
