<template>
  <div class="px-4 mt-6 sm:px-6 lg:px-8">
    <form @submit.prevent>
      <div>
        <div>
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Property
            </h3>
            <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
              Each card represents a single property. <span class="font-bold">No one but you will see the property.</span>
            </p>
          </div>
          <div class="mt-6 sm:mt-5">
            <div v-if="property" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <property-list @remove="removeProperty" :local="true" :properties="[property]" :display-size="true" class="mb-2" />
              <div class="h-48">
                <intel-map v-if="property" :post="placeholderIntel('property')" :local-properties=[property] />
              </div>
            </div>

            <div v-else class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="property-search" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                Look up
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2 flex rounded-md shadow-sm">
                <div class="relative flex-grow focus-within:z-10">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <!-- Heroicon name: search -->
                    <svg class="h-5 w-5 text-gray-400" style="z-index: 1;" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <autocomplete
                    id="property-search"
                    ref="propertySearch"
                    type="text"
                    base-class="custom-autocomplete-leading-icon"
                    :search="searchProperties"
                    :auto-select="true"
                    :get-result-value="getPropertyResultValue"
                    :debounce-time="500"
                    @submit="handlePropertySubmit"
                    placeholder="Name, address, etc."
                  />
                </div>
                <button @click="createProperty" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
                  <!-- Heroicon name: plus -->
                  <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                  </svg>
                  <span class="ml-2">New</span>
                </button>
              </div>
            </div>

            <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="primary_use" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                Primary use
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <select v-model="primaryUse" id="primary_use" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5">
                    <option v-for="use in propertyUses" :key="use.value" :value="use.value">{{ use.name }}</option>
                  </select>
                </div>
                <p class="mt-2 text-sm text-gray-500" id="renovated-description">This customizes the fields shown on the card.</p>
              </div>
            </div>

            <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="year_acquired" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                Year acquired
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <input v-model="yearAcquired" id="year_acquired" type="text" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5" placeholder="e.g., 2007">
                </div>
              </div>
            </div>

            <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="year_built" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                Year built
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <input v-model="yearBuilt" id="year_built" type="text" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5" placeholder="e.g., 1982">
                </div>
              </div>
            </div>

            <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="year_renovated" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                Year renovated
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="flex justify-end max-w-lg sm:max-w-xs">
                  <span class="text-sm leading-5 text-gray-500" id="renovated-optional">Optional</span>
                </div>
                <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <input v-model="yearRenovated" id="year_renovated" type="text" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5" placeholder="e.g., 2005">
                </div>
                <p class="mt-2 text-sm text-gray-500" id="renovated-description">Major renovations only.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Region
            </h3>
            <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
              This is how we generalize the location to protect your anonymity.
            </p>
          </div>
          <div class="mt-6 sm:mt-5">
            <div v-if="region" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <region-list @remove="removeRegion" :local="true" :regions="[region]" class="mb-2" />
              <div class="h-48">
                <intel-map v-if="region" :post="placeholderIntel('region')" :local-regions="[region]" :local-properties=[property] />
              </div>
            </div>

            <div v-else class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="region-search" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                Look up
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2 flex rounded-md shadow-sm">
                <div class="relative flex-grow focus-within:z-10">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <!-- Heroicon name: search -->
                    <svg class="h-5 w-5 text-gray-400" style="z-index: 1;" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <autocomplete
                    id="region-search"
                    ref="regionSearch"
                    type="text"
                    base-class="custom-autocomplete-leading-icon"
                    :search="searchRegions"
                    :auto-select="true"
                    :get-result-value="getRegionResultValue"
                    :debounce-time="500"
                    @submit="handleRegionSubmit"
                    placeholder="Name"
                  />
                </div>
                <button @click="createRegion" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
                  <!-- Heroicon name: plus -->
                  <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                  </svg>
                  <span class="ml-2">New</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Current Investment Profile
            </h3>
            <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
              A snapshot of the current status of the property that allows people to determine their interest.
            </p>
          </div>
          <div class="mt-6 sm:mt-5">
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="in_place_noi" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                In-place NOI
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2 max-w-lg sm:max-w-xs">
                <div class="relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm sm:leading-5">
                      $
                    </span>
                  </div>
                  <input v-model="inPlaceNoi" id="in_place_noi" type="text" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm sm:leading-5" placeholder="e.g., 2.3">
                  <div class="absolute inset-y-0 right-0 flex items-center">
                    <select v-model="inPlaceNoiUnits" aria-label="Currency" class="rounded-md focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm sm:leading-5">
                      <option value="millions">M</option>
                      <option value="thousands">K</option>
                    </select>
                  </div>
                </div>
                <p class="mt-2 text-sm text-gray-500" id="renovated-description">Contractual NOI in millions or thousands.</p>
              </div>
            </div>

            <div class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="in_place_occupancy" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                % Leased
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <select v-model="occupancy" id="in_place_occupancy" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5">
                    <option value="100%">100%</option>
                    <option value="90-99%">90-99%</option>
                    <option value="80-89%">80-89%</option>
                    <option value="70-79%">70-79%</option>
                    <option value="60-69%">60-69%</option>
                    <option value="50-59%">50-59%</option>
                    <option value="<50%">&lt;50%</option>
                  </select>
                </div>
              </div>
            </div>

            <div v-if="longTermLeasable" class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="tenant_count" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                Number of Tenants
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                  <select v-model="tenantCount" id="tenant_count" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5">
                    <option value="1">1</option>
                    <option value="2-5">2-5</option>
                    <option value="6-10">6-10</option>
                    <option value="11-25">11-25</option>
                    <option value="26-50">26-50</option>
                    <option value="51-100">51-100</option>
                    <option value=">100">&gt;100</option>
                  </select>
                </div>
              </div>
            </div>

            <div v-if="longTermLeasable" class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="weighted_average_lease_term" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                WALT
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2 max-w-lg sm:max-w-xs">
                <div class="relative rounded-md shadow-sm">
                  <input v-model="walt" id="weighted_average_lease_term" type="text" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm sm:leading-5" placeholder="e.g., 5.5" aria-describedby="walt-years">
                  <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm sm:leading-5" id="walt-years">
                      Yrs
                    </span>
                  </div>
                </div>
                <p class="mt-2 text-sm text-gray-500" id="renovated-description">Weighted average lease term from today.</p>
              </div>
            </div>

            <div v-if="longTermLeasable" class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="investment_grade_net_rentable_area" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                Investment Grade NRA
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="mt-1 relative rounded-md shadow-sm max-w-lg sm:max-w-xs">
                  <input v-model="creditNra" id="investment_grade_net_rentable_area" type="text" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm sm:leading-5" placeholder="e.g., 30" aria-describedby="credit-percent">
                  <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm sm:leading-5" id="credit-percent">
                      %
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-6 sm:mt-5 sm:border-t sm:border-gray-200 sm:pt-5">
              <div role="group" aria-labelledby="label-email">
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div class="text-base leading-6 font-medium text-gray-900 sm:text-sm sm:leading-5 sm:text-gray-700" id="label-email">
                      Sources of complexity
                    </div>
                  </div>
                  <div class="mt-4 sm:mt-0 sm:col-span-2">
                    <div class="max-w-lg">
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input v-model="existingDebt" id="existing_debt" type="checkbox" class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600">
                        </div>
                        <div class="ml-3 text-sm leading-5">
                          <label for="existing_debt" class="font-medium text-gray-700">Existing debt</label>
                          <p class="text-gray-500">Worth noting when assumable or must be defeased.</p>
                        </div>
                      </div>
                      <div class="mt-4">
                        <div class="relative flex items-start">
                          <div class="flex items-center h-5">
                            <input v-model="groundLease" id="ground_lease" type="checkbox" class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600">
                          </div>
                          <div class="ml-3 text-sm leading-5">
                            <label for="ground_lease" class="font-medium text-gray-700">Ground lease</label>
                            <p class="text-gray-500">Always worth noting, especially so if remaining term is short.</p>
                          </div>
                        </div>
                      </div>
                      <div class="mt-4">
                        <div class="relative flex items-start">
                          <div class="flex items-center h-5">
                            <input v-model="condo" id="condo" type="checkbox" class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600">
                          </div>
                          <div class="ml-3 text-sm leading-5">
                            <label for="condo" class="font-medium text-gray-700">Condominium</label>
                            <p class="text-gray-500">Worth noting given additional management layer.</p>
                          </div>
                        </div>
                      </div>
                      <div v-if="longTermLeasable" class="mt-4">
                        <div class="relative flex items-start">
                          <div class="flex items-center h-5">
                            <input v-model="terminationRights" id="termination_rights" type="checkbox" class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600">
                          </div>
                          <div class="ml-3 text-sm leading-5">
                            <label for="termination_rights" class="font-medium text-gray-700">Tenant termination rights</label>
                            <p class="text-gray-500">The larger the tenant(s), the more important to note.</p>
                          </div>
                        </div>
                      </div>
                      <div class="mt-4">
                        <div class="relative flex items-start">
                          <div class="flex items-center h-5">
                            <input v-model="additionalDevelopment" id="development_upside" type="checkbox" class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600">
                          </div>
                          <div class="ml-3 text-sm leading-5">
                            <label for="development_upside" class="font-medium text-gray-700">Additional development potential</label>
                            <p class="text-gray-500">Can increase interest from certain parties.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Card settings
            </h3>
            <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
              Options to customize the behavior of the card to suit your objectives.
            </p>
          </div>
          <div class="mt-6 sm:mt-5">
            <div class="sm:border-t sm:border-gray-200 sm:pt-5">
              <div role="group" aria-labelledby="label-email">
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div class="text-base leading-6 font-medium text-gray-900 sm:text-sm sm:leading-5 sm:text-gray-700" id="label-email">
                      Who may view this card?
                    </div>
                  </div>
                  <div class="mt-4 sm:mt-0 sm:col-span-2">
                    <div class="max-w-lg">
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input v-model="principalVisible" id="principals" type="checkbox" class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600">
                        </div>
                        <div class="ml-3 text-sm leading-5">
                          <label for="principals" class="font-medium text-gray-700">Principals</label>
                          <p class="text-gray-500">Test market interest directly with matching principals.</p>
                        </div>
                      </div>
                      <div class="mt-4">
                        <div class="relative flex items-start">
                          <div class="flex items-center h-5">
                            <input v-model="brokerVisible" id="brokers" type="checkbox" class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600">
                          </div>
                          <div class="ml-3 text-sm leading-5">
                            <label for="brokers" class="font-medium text-gray-700">Brokers</label>
                            <p class="text-gray-500">Gather feedback from brokers in this market.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-6 sm:mt-5 sm:border-t sm:border-gray-200 sm:pt-5">
            <div role="group" aria-labelledby="label-posture">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                <div>
                  <div class="text-base leading-6 font-medium text-gray-900 sm:text-sm sm:leading-5 sm:text-gray-700" id="label-posture">
                    Owner's Deal Posture
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <div class="max-w-lg">
                    <p class="text-sm leading-5 text-gray-500">How motivated are you to transact, either via sale, financing, or joint venture?</p>
                    <div class="mt-4">
                      <div class="flex items-center">
                        <input v-model="ownerDealPosture" id="unmotivated" name="push_notifications" type="radio" value="unmotivated" class="focus:ring-indigo-500 border-gray-300 h-4 w-4 text-indigo-600">
                        <label for="unmotivated" class="ml-3">
                          <span class="block text-sm leading-5 font-medium text-gray-700">Unmotivated. Just seeking data.</span>
                        </label>
                      </div>
                      <div class="mt-4 flex items-center">
                        <input v-model="ownerDealPosture" id="receptive" name="push_notifications" type="radio" value="receptive" class="focus:ring-indigo-500 border-gray-300 h-4 w-4 text-indigo-600">
                        <label for="receptive" class="ml-3">
                          <span class="block text-sm leading-5 font-medium text-gray-700">Receptive to offers and price discovery.</span>
                        </label>
                      </div>
                      <div class="mt-4 flex items-center">
                        <input v-model="ownerDealPosture" id="motivated" name="push_notifications" type="radio" value="motivated" class="focus:ring-indigo-500 border-gray-300 h-4 w-4 text-indigo-600">
                        <label for="motivated" class="ml-3">
                          <span class="block text-sm leading-5 font-medium text-gray-700">Motivated. The time is right for this property.</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 border-t border-gray-200 pt-5">
        <div class="flex items-center justify-end">
          <input v-model="ownerPermission" id="permission" type="checkbox" class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600">
          <label for="permission" class="ml-2 block text-sm leading-5 text-gray-900">
            I have the owner's permission.
          </label>
        </div>
        <div class="mt-2 flex justify-end">
          <span class="inline-flex rounded-md shadow-sm">
            <button @click="cancel" type="button" class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800">
              Cancel
            </button>
          </span>
          <template v-if="postToken">
            <span class="ml-3 inline-flex rounded-md shadow-sm">
              <button @click="destroy" type="button" class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800">
                Delete
              </button>
            </span>
            <span class="ml-3 inline-flex rounded-md shadow-sm">
              <button @click="update" type="button" :disabled="!complete" class="py-2 px-4 border rounded-md text-sm leading-5 font-medium" :class="buttonStyling">
                Update
              </button>
            </span>
          </template>
          <span v-else class="ml-3 inline-flex rounded-md shadow-sm">
            <button @click="save" type="button" :disabled="!complete" class="py-2 px-4 border rounded-md text-sm leading-5 font-medium" :class="buttonStyling">
              Save
            </button>
          </span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import IntelMap from "../../maps/market-intel-map";
import Property from "../../../views/onboarding/deal/property";
import PropertyList from "../../property-list";
import Region from "../../../views/onboarding/onboarding-region";
import RegionList from "../../region-list";
import Swal from "sweetalert2";
import api from "../../../api";
import deleteCardOptions from "../../../sweet-alert-delete-item-options";
import useTypes from "../../../use-types";

export default {
  components: { IntelMap, PropertyList, RegionList },
  props: ["postToken"],
  data() {
    return {
      property: null,
      primaryUse: "Waiting for property",
      yearAcquired: null,
      yearBuilt: null,
      yearRenovated: null,
      region: null,
      inPlaceNoi: null,
      inPlaceNoiUnits: "millions",
      occupancy: "100%",
      tenantCount: "1",
      walt: null,
      creditNra: null,
      existingDebt: false,
      groundLease: false,
      condo: false,
      terminationRights: false,
      additionalDevelopment: false,
      principalVisible: true,
      brokerVisible: false,
      ownerDealPosture: null,
      ownerPermission: false
    };
  },
  computed: {
    ...mapState(["temporaryAccess", "deduplicateRecord"]),
    propertyUses() {
      if (this.property) {
        return this.property.uses
          .filter(use => use !== "Parking")
          .map(use => _.find(useTypes, { value: use }));
      } else {
        return [
          { name: "Waiting for property", value: "Waiting for property" }
        ];
      }
    },
    visible() {
      return this.principalVisible || this.brokerVisible;
    },
    longTermLeasable() {
      return _.includes(
        ["Office", "Retail", "Industrial", "Lab", "Healthcare"],
        this.primaryUse
      );
    },
    complete() {
      const globalComplete =
        this.property &&
        this.yearAcquired &&
        this.yearBuilt &&
        this.region &&
        this.inPlaceNoi &&
        this.visible &&
        this.ownerDealPosture &&
        this.ownerPermission;
      const longTermLeaseComplete = this.walt && this.creditNra;

      switch (this.primaryUse) {
        case "Office":
        case "Retail":
        case "Industrial":
        case "Lab":
        case "Healthcare":
          return globalComplete && longTermLeaseComplete;
        case "Multifamily":
          return globalComplete;
        default:
          return false;
      }
    },
    buttonStyling() {
      if (this.complete) {
        return "inline-flex justify-center border-transparent text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700";
      } else {
        return "border-gray-300 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 cursor-not-allowed";
      }
    },
    persistPayload() {
      if (this.complete) {
        return {
          name: this.region.name,
          anonymous: true,
          uses: this.propertyUses.map(use => {
            return { type: use.value };
          }),
          deals: [{ type: "Buy Equity" }, { type: "Originate Debt" }],
          properties: [this.property],
          regions: [this.region],
          propertySnapshot: {
            primaryUse: this.primaryUse,
            yearAcquired: this.yearAcquired,
            yearBuilt: this.yearBuilt,
            yearRenovated: this.yearRenovated,
            inPlaceNoi: this.inPlaceNoi,
            inPlaceNoiUnits: this.inPlaceNoiUnits,
            occupancy: this.occupancy,
            tenantCount: this.tenantCount,
            walt: this.walt,
            creditNra: this.creditNra,
            existingDebt: this.existingDebt,
            groundLease: this.groundLease,
            condo: this.condo,
            terminationRights: this.terminationRights,
            additionalDevelopment: this.additionalDevelopment,
            principalVisible: this.principalVisible,
            brokerVisible: this.brokerVisible,
            ownerDealPosture: this.ownerDealPosture
          }
        };
      } else {
        return {};
      }
    }
  },
  watch: {
    deduplicateRecord: {
      handler() {
        if (this.deduplicateRecord) {
          this.property = this.deduplicateRecord;
          this.primaryUse = this.propertyUses[0].value;
          this.$store.commit("clearDeduplicate");
          this.$store.commit("clearModal");
        }
      },
      deep: true
    },
    temporaryAccess: {
      handler() {
        if (this.temporaryAccess) {
          if (this.temporaryAccess.type === "property") {
            this.property = this.temporaryAccess.data;
            this.primaryUse = this.propertyUses[0].value;
          } else {
            this.region = this.temporaryAccess.data;
          }

          this.$store.commit("clearTemporaryAccess");
        }
      }
    }
  },
  mounted() {
    if (this.postToken) {
      this.fetchPost();
    }

    document.getElementById("property-search").focus();
  },
  methods: {
    placeholderIntel(type) {
      return {
        lat: null,
        lng: null,
        token: `${type}-map`,
        name: `${type} map`
      };
    },
    removeProperty() {
      this.property = null;
      setTimeout(() => {
        document.getElementById("property-search").focus();
      }, 50);
    },
    removeRegion() {
      this.region = null;
      setTimeout(() => {
        document.getElementById("region-search").focus();
      }, 50);
    },
    cancel() {
      this.$emit("cancel");
    },
    searchProperties(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        this.$store.dispatch("executePropertySearch", input).then(
          json => {
            resolve(json.data);
          },
          failure => {
            this.$store.dispatch("flash", "Invalid search");
          }
        );
      });
    },
    getPropertyResultValue(result) {
      return `${result.name} (${result.cityState})`;
    },
    handlePropertySubmit(result) {
      if (result) {
        api.get(`properties/${result.id}`).then(json => {
          this.property = json.data;
          this.primaryUse = this.propertyUses[0].value;
        });
        this.$refs.propertySearch.value = "";
      } else {
        this.$store.dispatch(
          "flash",
          "No matching properties in Tower Hunt. Why don't you create one?"
        );
      }
    },
    searchRegions(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        this.$store.dispatch("executeRegionSearch", input).then(
          json => {
            resolve(json.data);
          },
          failure => {
            this.$store.dispatch("flash", "Invalid search");
          }
        );
      });
    },
    getRegionResultValue(result) {
      return `${result.name} (${_.capitalize(result.shape)})`;
    },
    handleRegionSubmit(result) {
      if (result) {
        api.get(`regions/${result.id}`).then(json => {
          this.region = json.data.region;
        });
        this.$refs.regionSearch.value = "";
      } else {
        this.$store.dispatch(
          "flash",
          "No matching regions in Tower Hunt. Why don't you create one?"
        );
      }
    },
    createProperty() {
      this.$store.commit("openModal", {
        component: Property,
        props: {
          propertyId: null
        }
      });
    },
    createRegion() {
      this.$store.commit("openModal", {
        component: Region,
        props: {
          regionId: null
        }
      });
    },
    fetchPost() {
      api.get(`off_market_property_snapshots/${this.postToken}`).then(json => {
        this.mountPost(json.data);
      });
    },
    mountPost(post) {
      this.property = post.property;
      this.region = post.region;
      this.primaryUse = post.primaryUse;
      this.yearAcquired = post.yearAcquired;
      this.yearBuilt = post.yearBuilt;
      this.yearRenovated = post.yearRenovated;
      this.inPlaceNoi = post.inPlaceNoi;
      this.inPlaceNoiUnits = post.inPlaceNoiUnits;
      this.occupancy = post.occupancy;
      this.tenantCount = post.tenantCount || "1";
      this.walt = post.walt;
      this.creditNra = post.creditNra;
      this.existingDebt = post.existingDebt;
      this.groundLease = post.groundLease;
      this.condo = post.condo;
      this.terminationRights = post.terminationRights;
      this.additionalDevelopment = post.additionalDevelopment;
      this.principalVisible = post.principalVisible;
      this.brokerVisible = post.brokerVisible;
      this.ownerDealPosture = post.ownerDealPosture;
    },
    destroy() {
      Swal.fire(deleteCardOptions).then(result => {
        if (result.value) {
          api
            .delete(`off_market_property_snapshots/${this.postToken}`)
            .then(() => {
              this.$emit("cancel");
            });
        }
      });
    },
    update() {
      if (this.complete) {
        api
          .patch(
            `off_market_property_snapshots/${this.postToken}`,
            this.persistPayload
          )
          .then(() => {
            this.$emit("saved");
          });
      }
    },
    save() {
      if (this.complete) {
        api
          .post(`off_market_property_snapshots`, this.persistPayload)
          .then(() => {
            if (!!analytics) {
              analytics.track("Off-market snapshot created", {
                yearWeek: this.$store.state.yearWeek
              });
            }

            this.$emit("saved");
          });
      }
    }
  }
};
</script>
