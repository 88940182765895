<template>
  <div class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
    <div class="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="p-2 rounded-lg bg-gray-600 shadow-lg sm:p-3">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span class="flex p-2 rounded-lg bg-gray-800">
              <svg class="h-4 w-4 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg>
            </span>
            <p v-if="dealProspectInvitationViewedAt" class="ml-3 font-medium text-sm text-white truncate">
              <span class="md:hidden">
                Access expires {{ expirationTime | moment("from", now) }}
              </span>
              <span class="hidden md:inline">
                Access expires {{ expirationTime | moment("from", now) }}. Press "Contact me" or "Follow" to unlock this deal offering.
              </span>
            </p>
          </div>
          <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
            <button @click="clearExpiration" type="button" class="-mr-1 flex p-2 rounded-md hover:bg-gray-500 focus:outline-none focus:bg-gray-500" aria-label="Dismiss">
              <svg class="h-4 w-4 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapState } from "vuex";

let interval;

export default {
  computed: {
    ...mapState(["now", "dealProspectInvitationViewedAt"]),
    expirationTime() {
      return moment(this.dealProspectInvitationViewedAt).add(10, "minutes");
    }
  },
  created() {
    var self = this;

    interval = setInterval(function() {
      self.$store.commit("setNow", moment());
    }, 30000);
  },
  destroyed() {
    clearInterval(interval);
  },
  methods: {
    clearExpiration() {
      this.$store.commit("clearProspectInvitationViewedAt");
    }
  }
};
</script>
