<template>
  <div class="pb-16 space-y-6">
    <safezone-data-sharing-form
      v-if="sharingForm"
      :safezone-data-type="safezoneDataType"
      :safezone-data="safezoneData"
      :decorating-content="decoratingContent"
      :decorating-content-type="decoratingContentType"
      @done="sharingForm = false"
    />

    <template v-else>
      <div>
        <h3 class="font-medium text-gray-900">Information</h3>
        <dl class="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
          <div class="py-3 flex justify-between text-sm font-medium">
            <dt class="text-gray-500">Created</dt>
            <dd class="text-gray-900">{{ safezoneData.createdAt | moment("MMMM D, YYYY") }}</dd>
          </div>
          <div class="py-3 flex justify-between text-sm font-medium">
            <dt class="text-gray-500">Last modified</dt>
            <dd class="text-gray-900">{{ safezoneData.updatedAt | moment("MMMM D, YYYY") }}</dd>
          </div>
        </dl>
      </div>
      <div v-observe-visibility="{ callback: fetchLicenses }">
        <h3 class="font-medium text-gray-900">Shared with</h3>
        <div v-if="!loaded" class="mt-4 flex justify-center">
          <grid-loader :loading="true" size="8px" color="#D4D4D8" />
        </div>
        <template v-else>
          <div v-if="isWatchlistProperty" class="mt-2 rounded-md bg-yellow-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-sm text-yellow-700">
                  To protect privacy, sharing recipients are not automatically granted access to the Watchlist page.
                </p>
                <p class="mt-3 text-sm md:mt-0 md:ml-6">
                  <a v-show="false" @click.prevent href="" class="whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600">Learn more <span aria-hidden="true">&rarr;</span></a>
                </p>
              </div>
            </div>
          </div>
          <ul role="list" class="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
            <li v-for="license in licenses" :key="license.id" class="py-3 flex justify-between items-center">
              <div class="flex items-center">
                <div class="flex-shrink-0 relative">
                  <avatar-photo v-if="license.licenseeType === 'PropertyFollowing'" :property-name="license.licensee.name" :property-avatar="license.licensee.property.coverPhoto" icon-size="6" circle-size="8" text-size="xs" />
                  <avatar-photo v-else-if="license.licenseeType === 'Team'" :team-name="license.licensee.name" circle-size="8" text-size="xs" />
                  <avatar-photo v-else :person="license.licensee" circle-size="8" text-size="xs" />
                  <watchlist-property-badge v-if="license.licenseeType === 'PropertyFollowing'" class="absolute -bottom-1 -right-2" />
                  <team-badge v-else-if="license.licenseeType === 'Team'" class="absolute -bottom-1 -right-2" />
                </div>
                <p class="ml-4 text-sm font-medium text-gray-900" v-tooltip="license.licensee.name">{{ license.licensee.name | truncate }}</p>
              </div>
              <button @click="revoke(license)" type="button" class="ml-6 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Remove<span class="sr-only"> {{ license.licensee.name }}</span></button>
            </li>
            <li class="py-2 flex justify-between items-center">
              <button @click="sharingForm = true" type="button" class="group -ml-1 bg-white p-1 rounded-md flex items-center focus:outline-none focus:ring-2 focus:ring-indigo-500">
                <span class="w-8 h-8 rounded-full border-2 border-dashed border-gray-300 flex items-center justify-center text-gray-400">
                  <!-- Heroicon name: solid/plus-sm -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                  </svg>
                </span>
                <span class="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">Share</span>
              </button>
            </li>
          </ul>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import AvatarPhoto from "../avatar-photo";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import SafezoneDataSharingForm from "./safezone-data-sharing-form.vue";
import TeamBadge from "../team-badge";
import WatchlistPropertyBadge from "../watchlist-property-badge";
import api from "../../api";

export default {
  components: {
    AvatarPhoto,
    TeamBadge,
    WatchlistPropertyBadge,
    GridLoader,
    SafezoneDataSharingForm
  },
  props: [
    "safezoneDataType",
    "safezoneData",
    "decoratingContent",
    "decoratingContentType"
  ],
  data() {
    return {
      loaded: false,
      licenses: [],
      sharingForm: false
    };
  },
  computed: {
    isWatchlistProperty() {
      return (
        this.decoratingContentType === "property" &&
        this.decoratingContent.watchlistId
      );
    }
  },
  mounted() {
    this.fetchLicenses();
  },
  methods: {
    refetchLicenses(isVisible, entry) {
      if (isVisible) {
        this.loaded = false;
        this.fetchLicenses();
      }
    },
    fetchLicenses() {
      api
        .get(
          `safezone_data_licenses/${this.safezoneDataType}/${this.safezoneData.localId}`
        )
        .then(json => {
          this.licenses = json.data;
          this.loaded = true;
        });
    },
    revoke(license) {
      api.delete(`safezone_data_licenses/${license.id}`).then(() => {
        this.fetchLicenses();
      });
    }
  }
};
</script>
