<template>
  <div class="wrapper">
    <form @submit.prevent="submit" novalidate>
      <section class="uses mb-4">
        <div class="input-container">
          <input
            v-model="office"
            @focus="focus('office')"
            @blur="blur"
            @keydown.enter="submit"
            type="number"
            min="0"
            step="1"
            placeholder=""
            tabindex="1"
            name="office"
            class="input-text black"
            :class="{ active: focused === 'office' }"
          >
          <label class="input-label" :class="{ active: focused === 'office' }">Office</label>
          <label class="sf-label" :class="{ active: focused === 'office' }">SF</label>
        </div>
        <div class="input-container">
          <input
            v-model="retail"
            @focus="focus('retail')"
            @blur="blur"
            @keydown.enter="submit"
            type="number"
            min="1"
            step="1"
            placeholder=""
            tabindex="2"
            name="retail"
            class="input-text black"
            :class="{ active: focused === 'retail' }"
          >
          <label class="input-label" :class="{ active: focused === 'retail' }">Retail</label>
          <label class="sf-label" :class="{ active: focused === 'retail' }">SF</label>
        </div>
        <div class="input-container">
          <input
            v-model="industrial"
            @focus="focus('industrial')"
            @blur="blur"
            @keydown.enter="submit"
            type="number"
            min="0"
            step="1"
            placeholder=""
            tabindex="3"
            name="industrial"
            class="input-text black"
            :class="{ active: focused === 'industrial' }"
          >
          <label class="input-label" :class="{ active: focused === 'industrial' }">Industrial</label>
          <label class="sf-label" :class="{ active: focused === 'industrial' }">SF</label>
        </div>
        <div class="input-container">
          <input
            v-model="multifamily"
            @focus="focus('multifamily')"
            @blur="blur"
            @keydown.enter="submit"
            type="number"
            min="1"
            step="1"
            placeholder=""
            tabindex="4"
            name="multifamily"
            class="input-text black"
            :class="{ active: focused === 'multifamily' }"
          >
          <label class="input-label" :class="{ active: focused === 'multifamily' }">Residential</label>
          <label class="sf-label" :class="{ active: focused === 'multifamily' }">Units</label>
        </div>
        <div class="input-container">
          <input
            v-model="hotel"
            @focus="focus('hotel')"
            @blur="blur"
            @keydown.enter="submit"
            type="number"
            min="1"
            step="1"
            placeholder=""
            tabindex="5"
            name="hotel"
            class="input-text black"
            :class="{ active: focused === 'hotel' }"
          >
          <label class="input-label" :class="{ active: focused === 'hotel' }">Lodging & Gaming</label>
          <label class="sf-label" :class="{ active: focused === 'hotel' }">Keys</label>
        </div>
        <div class="input-container">
          <input
            v-model="lab"
            @focus="focus('lab')"
            @blur="blur"
            @keydown.enter="submit"
            type="number"
            min="1"
            step="1"
            placeholder=""
            tabindex="6"
            name="lab"
            class="input-text black"
            :class="{ active: focused === 'lab' }"
          >
          <label class="input-label" :class="{ active: focused === 'lab' }">Life Sciences</label>
          <label class="sf-label" :class="{ active: focused === 'lab' }">SF</label>
        </div>
        <div class="input-container">
          <input
            v-model="healthCare"
            @focus="focus('healthCare')"
            @blur="blur"
            @keydown.enter="submit"
            type="number"
            min="0"
            step="1"
            placeholder=""
            tabindex="7"
            name="healthCare"
            class="input-text black"
            :class="{ active: focused === 'healthCare' }"
          >
          <label class="input-label" :class="{ active: focused === 'healthCare' }">Healthcare</label>
          <label class="sf-label" :class="{ active: focused === 'healthCare' }">SF</label>
        </div>
        <div class="input-container">
          <input
            v-model="seniorHousing"
            @focus="focus('seniorHousing')"
            @blur="blur"
            @keydown.enter="submit"
            type="number"
            min="0"
            step="1"
            placeholder=""
            tabindex="8"
            name="seniorHousing"
            class="input-text black"
            :class="{ active: focused === 'seniorHousing' }"
          >
          <label class="input-label" :class="{ active: focused === 'seniorHousing' }">Senior Housing</label>
          <label class="sf-label" :class="{ active: focused === 'seniorHousing' }">Units</label>
        </div>
        <div class="input-container">
          <input
            v-model="dataCenter"
            @focus="focus('dataCenter')"
            @blur="blur"
            @keydown.enter="submit"
            type="number"
            min="1"
            step="1"
            placeholder=""
            tabindex="9"
            name="dataCenter"
            class="input-text black"
            :class="{ active: focused === 'dataCenter' }"
          >
          <label class="input-label" :class="{ active: focused === 'dataCenter' }">Data Center</label>
          <label class="sf-label" :class="{ active: focused === 'dataCenter' }">SF</label>
        </div>
        <div class="input-container">
          <input
            v-model="studentHousing"
            @focus="focus('studentHousing')"
            @blur="blur"
            @keydown.enter="submit"
            type="number"
            min="1"
            step="1"
            placeholder=""
            tabindex="10"
            name="studentHousing"
            class="input-text black"
            :class="{ active: focused === 'studentHousing' }"
          >
          <label class="input-label" :class="{ active: focused === 'studentHousing' }">Student Housing</label>
          <label class="sf-label" :class="{ active: focused === 'studentHousing' }">Units</label>
        </div>
        <div class="input-container">
          <input
            v-model="selfStorage"
            @focus="focus('selfStorage')"
            @blur="blur"
            @keydown.enter="submit"
            type="number"
            min="1"
            step="1"
            placeholder=""
            tabindex="11"
            name="selfStorage"
            class="input-text black"
            :class="{ active: focused === 'selfStorage' }"
          >
          <label class="input-label" :class="{ active: focused === 'selfStorage' }">Self-Storage</label>
          <label class="sf-label" :class="{ active: focused === 'selfStorage' }">Units</label>
        </div>
        <div class="input-container">
          <input
            v-model="parking"
            @focus="focus('parking')"
            @blur="blur"
            @keydown.enter="submit"
            type="number"
            min="1"
            step="1"
            placeholder=""
            tabindex="12"
            name="parking"
            class="input-text black"
            :class="{ active: focused === 'parking' }"
          >
          <label class="input-label" :class="{ active: focused === 'parking' }">Parking</label>
          <label class="sf-label" :class="{ active: focused === 'parking' }">Spaces</label>
        </div>
      </section>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: ["dealId"],
  data() {
    return {
      focused: false
    };
  },
  computed: {
    ...mapState(["activeProperty"]),
    ...mapGetters(["creatingDeal"]),
    office: {
      get() {
        return this.activeProperty.office;
      },
      set(size) {
        this.$store.commit("setPropertySize", { use: "office", size });
      }
    },
    retail: {
      get() {
        return this.activeProperty.retail;
      },
      set(size) {
        this.$store.commit("setPropertySize", { use: "retail", size });
      }
    },
    industrial: {
      get() {
        return this.activeProperty.industrial;
      },
      set(size) {
        this.$store.commit("setPropertySize", { use: "industrial", size });
      }
    },
    lab: {
      get() {
        return this.activeProperty.lab;
      },
      set(size) {
        this.$store.commit("setPropertySize", { use: "lab", size });
      }
    },
    healthCare: {
      get() {
        return this.activeProperty.healthCare;
      },
      set(size) {
        this.$store.commit("setPropertySize", { use: "healthCare", size });
      }
    },
    seniorHousing: {
      get() {
        return this.activeProperty.seniorHousing;
      },
      set(size) {
        this.$store.commit("setPropertySize", { use: "seniorHousing", size });
      }
    },
    dataCenter: {
      get() {
        return this.activeProperty.dataCenter;
      },
      set(size) {
        this.$store.commit("setPropertySize", { use: "dataCenter", size });
      }
    },
    hotel: {
      get() {
        return this.activeProperty.hotel;
      },
      set(size) {
        this.$store.commit("setPropertySize", { use: "hotel", size });
      }
    },
    multifamily: {
      get() {
        return this.activeProperty.multifamily;
      },
      set(size) {
        this.$store.commit("setPropertySize", { use: "multifamily", size });
      }
    },
    selfStorage: {
      get() {
        return this.activeProperty.selfStorage;
      },
      set(size) {
        this.$store.commit("setPropertySize", { use: "selfStorage", size });
      }
    },
    studentHousing: {
      get() {
        return this.activeProperty.studentHousing;
      },
      set(size) {
        this.$store.commit("setPropertySize", { use: "studentHousing", size });
      }
    },
    parking: {
      get() {
        return this.activeProperty.parking;
      },
      set(size) {
        this.$store.commit("setPropertySize", { use: "parking", size });
      }
    }
  },
  mounted() {
    this.$scrollTo("#property-top", 500, {
      container: "#modal-container"
    });
  },
  methods: {
    focus(field) {
      this.focused = field;
    },
    blur() {
      this.focused = false;
    },
    submit() {
      this.$emit("submit");
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  display: flex;
  flex-direction: column;
  width: 330px;
}
.uses {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
input {
  vertical-align: middle;
}
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 26px 90px 8px 15px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 20px;
  line-height: 18.2px;
  &.active {
    border: 2px solid #5850ec;
  }
}
.input-label {
  position: absolute;
  top: 8px;
  left: 16px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
.sf-label {
  position: absolute;
  top: 31px;
  right: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
