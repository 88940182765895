<template>
  <div class="relative p-4 max-w-3xl mx-auto">
    <div class="text-center">
      <h2 class="text-xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-10">
        Configure CA
      </h2>
      <!--
        Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
        Read the documentation to get started: https://tailwindui.com/documentation
      -->
      <div class="mt-2 bg-indigo-50 border-l-4 border-indigo-400 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: information-circle -->
            <svg class="h-5 w-5 text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm leading-5 text-indigo-700">
              By default, we ask the user to accept Tower Hunt's confidentiality language from the <router-link to="/legal#terms-of-service" target="_blank" class="font-medium underline text-indigo-700 hover:text-indigo-600">Terms of Service</router-link>. You can optionally provide your own CA in PDF and Word formats.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-10">
      <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        <div v-if="staticConfidentialityAgreement" class="sm:col-span-1">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            PDF version
          </dt>
          <dd class="mt-1 w-full flex flex-col flex-1">
            <div id="static-confi" class="mt-4 mb-2 iframely-embed flex-1">
              <a data-iframely-url :href="staticConfidentialityAgreement"></a>
            </div>
            <failed-url-preview-empty-state v-if="staticFailed" :url="staticConfidentialityAgreement" />
            <a :href="staticConfidentialityAgreement" target="_blank" class="inline-flex items-center justify-center font-medium text-sm text-gray-700">
              <!-- Heroicon name: download -->
              <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd" />
              </svg>
              <span class="ml-2">Download</span>
            </a>
          </dd>
        </div>
        <div v-else class="sm:col-span-1">
          <div class="flex justify-between">
            <label for="pdf_ca" class="block text-sm font-medium leading-5 text-gray-700">PDF version</label>
            <span class="text-sm leading-5 text-gray-500" id="pdf_ca-optional">Optional</span>
          </div>
          <file-drag-drop
            class="mt-2"
            :endpoint="`static_confidentiality_agreements/${this.activeDealMarketing.id}`"
            :file-types="documentFileTypes.pdf"
            file-type-labels="PDF"
            :size-limit="3"
            :multiple="false"
            :heading="null"
            :full-width="true"
            bottom-margin="mb-0"
            flash-message="PDF CA attached"
            :after-action="{ type: 'commit', actionName: 'mountDealMarketing', payloadKey: 'dealMarketing' }"
          />
        </div>

        <div v-if="editableConfidentialityAgreement" class="sm:col-span-1">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Word version
          </dt>
          <dd class="mt-1 w-full flex flex-col flex-1">
            <div id="editable-confi" class="mt-4 mb-2 iframely-embed flex-1">
              <a data-iframely-url :href="editableConfidentialityAgreement"></a>
            </div>
            <failed-url-preview-empty-state v-if="editableFailed" :url="editableConfidentialityAgreement" />
            <a :href="editableConfidentialityAgreement" target="_blank" class="inline-flex items-center justify-center font-medium text-sm text-gray-700">
              <!-- Heroicon name: download -->
              <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd" />
              </svg>
              <span class="ml-2">Download</span>
            </a>
          </dd>
        </div>
        <div v-else class="sm:col-span-1">
          <div class="flex justify-between">
            <label for="word_ca" class="block text-sm font-medium leading-5 text-gray-700">Word version</label>
            <span class="text-sm leading-5 text-gray-500" id="word_ca-optional">Optional</span>
          </div>
          <file-drag-drop
            class="mt-2"
            :endpoint="`editable_confidentiality_agreements/${this.activeDealMarketing.id}`"
            :file-types="documentFileTypes.word"
            file-type-labels="DOC"
            :size-limit="3"
            :multiple="false"
            :heading="null"
            :full-width="true"
            bottom-margin="mb-0"
            flash-message="Word CA attached"
            :after-action="{ type: 'commit', actionName: 'mountDealMarketing', payloadKey: 'dealMarketing' }"
          />
        </div>
        <form @submit.prevent="updateEmail" class="sm:col-span-2">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium leading-5 text-gray-700">Deal team CA contact email</label>
            <span class="text-sm leading-5 text-gray-500" id="email-optional">Optional</span>
          </div>
          <div class="mt-1 flex rounded-md shadow-sm">
            <div class="relative flex items-stretch flex-grow focus-within:z-10">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <!-- Heroicon name: mail -->
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
              </div>
              <input v-model="email" id="email" type="email" autocomplete="new-password" autocorrect="off" spellcheck="false" placeholder="" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300">
            </div>
            <button type="submit" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
              <!-- Heroicon name: save -->
              <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
              </svg>
              <span>Save</span>
            </button>
          </div>
          <p class="mt-2 text-sm text-gray-500 flex justify-between" id="email-description">
            <span>Who should people contact about the CA? Default: {{ activeDealMarketing.defaultConfidentialityAgreementEmail }}</span>
            <a v-if="hasEmail" href="" @click.prevent="email = ''" class="font-medium text-xs text-indigo-600 hover:text-indigo-500">Revert</a>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { pdf, word } from "../../document-file-types";
import FailedUrlPreviewEmptyState from "../../components/failed-url-preview-empty-state.vue";
import FileDragDrop from "../../components/file-drag-drop";
/* global iframely */

export default {
  components: { FailedUrlPreviewEmptyState, FileDragDrop },
  data() {
    return {
      email: "",
      documentFileTypes: { pdf, word },
      staticFailed: false,
      editableFailed: false
    };
  },
  computed: {
    ...mapState(["activeDeal", "activeDealMarketing"]),
    hasEmail() {
      return _.trim(this.email) !== "";
    },
    staticConfidentialityAgreement() {
      return this.activeDealMarketing.staticConfidentialityAgreement;
    },
    editableConfidentialityAgreement() {
      return this.activeDealMarketing.editableConfidentialityAgreement;
    }
  },
  watch: {
    staticConfidentialityAgreement: {
      handler() {
        this.loadIframely();
      }
    },
    editableConfidentialityAgreement: {
      handler() {
        this.loadIframely();
      }
    }
  },
  mounted() {
    this.mountFields();

    const hasFiles =
      this.activeDealMarketing.staticConfidentialityAgreement ||
      this.activeDealMarketing.editableConfidentialityAgreement;

    if (hasFiles && window.iframely) {
      this.loadIframely();
    }
  },
  methods: {
    loadIframely() {
      setTimeout(() => {
        iframely.load();
      }, 500);

      var self = this;

      iframely.on("cancel", function(url, parentNode) {
        if (parentNode.id === "static-confi") {
          self.staticFailed = true;
        } else if (parentNode.id === "editable-confi") {
          self.editableFailed = true;
        }

        parentNode.remove();
      });
    },
    mountFields() {
      const { customConfidentialityAgreementEmail } = this.activeDealMarketing;

      this.email = customConfidentialityAgreementEmail || "";
    },
    stripEmpty(str) {
      if (_.trim(str) === "") {
        return null;
      } else {
        return _.trim(str);
      }
    },
    updateEmail() {
      const payload = { email: this.stripEmpty(this.email) };

      this.$store.dispatch(
        "updateDealMarketingConfidentialityEmailFields",
        payload
      );
    }
  }
};
</script>
