<template>
  <div class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
    <div class="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3">
        <div class="flex flex-col items-center sm:flex-row sm:justify-between">
          <div class="w-full flex-1 flex items-center justify-start">
            <span class="flex p-2 rounded-lg bg-indigo-800">
              <svg
                class="h-6 w-6 text-white"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </span>
            <p class="ml-3 font-medium text-white truncate">
              <span class="md:hidden">
                Deal is not live
              </span>
              <span class="hidden md:inline">
                Heads up! The deal is currently a draft. Only the deal team can see it until it goes live. Last saved {{ activeDealMarketing.updatedAt | moment("from") }}.
              </span>
            </p>
          </div>
          <div class="mt-2 flex-shrink-0 w-full sm:mt-0 sm:w-auto sm:flex">
            <div class="rounded-md shadow-sm">
              <a
                @click.prevent="goLive"
                href=""
                class="flex items-center justify-center px-4 py-2 border border-transparent text-md leading-5 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:ring"
              >
                <span class="mr-2 inline-flex items-center justify-center h-3 w-3 rounded-full bg-red-400"></span>
                Go live
              </a>
            </div>
            <div class="ml-0 mt-2 sm:ml-2 sm:mt-0 rounded-md shadow-sm">
              <a
                @click.prevent="withdraw"
                href=""
                class="flex items-center justify-center px-4 py-2 border border-indigo-200 text-sm leading-5 font-medium rounded-md text-indigo-200 bg-transparent hover:text-white hover:bg-indigo-600 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-white active:bg-indigo-800"
              >
                Withdraw deal
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    activeDealMarketing() {
      return this.$store.state.activeDealMarketing;
    }
  },
  methods: {
    goLive() {
      this.$store.dispatch("confirmDealLaunch");
    },
    withdraw() {
      this.$store.dispatch("confirmDealWithdrawal");
    }
  }
};
</script>
