export default [
  {
    order: 1,
    name: "Live",
    value: "live",
    description: null,
    icon: "fas fa-comments-dollar"
  },
  {
    order: 2,
    name: "Under Agreement",
    value: "awarded",
    description: null,
    icon: "fas fa-handshake"
  },
  {
    order: 3,
    name: "Closed",
    value: "closed",
    description: null,
    icon: "fas fa-file-signature"
  },
  {
    order: 4,
    name: "Withdrawn",
    value: "withdrawn",
    description: null,
    icon: "fas fa-ban"
  }
];
