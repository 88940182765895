<template>
  <div>
    <div>
      <legend class="text-base leading-6 font-medium text-gray-900">
        Third-party Integrations
      </legend>
      <p class="text-sm leading-5 text-gray-500">
        Leverage storage apps you already use. Choose an option below:
      </p>
      <div class="mt-4">
        <div class="grid grid-cols-3 gap-3">
          <dropbox-toggle />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropboxToggle from "./dropbox-toggle.vue";

export default {
  components: { DropboxToggle }
};
</script>
