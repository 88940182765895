<template>
  <div class="flex flex-col items-center">
    <heading v-if="heading">{{ heading }}</heading>
    <div @drop.prevent="handleDragDropFiles" @dragover.prevent="hover" class="relative overflow-x-hidden flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md" :class="{ 'border-indigo-500': dragging, 'border-3': dragging, 'w-full': fullWidth, 'w-4/5 max-w-lg': !fullWidth, 'mb-8': marginBottom }">
      <div class="absolute top-0 left-0 bg-indigo-100 h-full overflow-hidden direct-upload__progress" :style="progressWidth" />
      <div class="z-30 w-full text-center">
        <svg class="mx-auto h-12 w-12 text-gray-400" :class="{ 'text-indigo-500': dragging }" stroke="currentColor" fill="none" viewBox="0 0 48 48">
          <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <p class="mt-1 text-sm text-gray-600">
          <button @click="launchFilePicker" type="button" class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline">
            Upload <template v-if="multiple">files</template><template v-else>a file</template>
          </button>
          or drag and drop
        </p>
        <p class="mt-1 text-xs text-gray-500">
          {{ fileTypeLabels }} up to {{ fileSizeLimit }}MB<template v-if="multiple"> each</template>
        </p>
        <p v-if="!multiple && singleFileError" class="mt-4 text-xs text-red-400">
          The file you tried to upload was too large. Please upload a file smaller than {{ fileSizeLimit }}MB.
        </p>
        <dd v-else-if="multiple && files.length > 0" class="mt-4 text-xs text-left leading-5 text-gray-900 w-full">
          <ul class="border border-gray-200 rounded-md">
            <li
              v-for="(file, index) in files"
              :key="file.name"
              class="pl-3 pr-4 py-3 flex items-center justify-between text-xs leading-5"
              :class="{ 'border-t': index !== 0, 'border-gray-200': index !== 0 }"
            >
              <div class="w-0 flex-1 flex items-center">
                <svg class="flex-shrink-0 h-5 w-5" :class="{ 'text-gray-400': !file.errorMessage, 'text-red-400': file.errorMessage }" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd"></path>
                </svg>
                <span v-if="file.errorMessage" class="ml-2 flex-1 w-0 truncate text-red-600">{{ file.name }} {{ file.errorMessage }}</span>
                <span v-else class="ml-2 flex-1 w-0 truncate">
                  {{ file.name }}
                </span>
              </div>
              <div v-if="!file.errorMessage" class="ml-4 flex-shrink-0">
                <span class="font-medium text-green-400">
                  Uploaded
                </span>
              </div>
            </li>
          </ul>
        </dd>
      </div>
    </div>
    <input
      ref="files"
      type="file"
      :multiple="multiple"
      :accept="fileTypes"
      :data-direct-upload-url="fileUploadUrl"
      class="hidden"
      name="Files"
      @change="handleInputFiles">
  </div>
</template>

<script>
import { DirectUpload } from "@rails/activestorage";
import Heading from "./onboarding/heading";
import api from "../api";

export default {
  components: { Heading },
  props: [
    "endpoint",
    "localCommit",
    "fileTypes",
    "fileTypeLabels",
    "sizeLimit",
    "multiple",
    "heading",
    "fullWidth",
    "marginBottom",
    "flashMessage",
    "afterAction"
  ],
  data() {
    return {
      files: [],
      uploadedFiles: [],
      pendingFileCount: 0,
      fileUploadUrl: "/rails/active_storage/direct_uploads",
      dragging: false,
      uploading: false,
      uploadingProgress: null,
      uploadingTotal: null
    };
  },
  computed: {
    allowsPDF() {
      return _.includes(this.fileTypes, "application/pdf");
    },
    fileNames() {
      return this.uploadedFiles.map(f => f.name);
    },
    fileSizeLimit() {
      return this.sizeLimit || 10;
    },
    readyToPersist() {
      return (
        this.filesToPersist.length > 0 &&
        this.pendingFileCount === 0 &&
        this.filesToPersist.every(file => !file.errorMessage)
      );
    },
    progressWidth() {
      if (this.uploading && this.uploadingTotal) {
        const progress = (this.uploadingProgress / this.uploadingTotal) * 100;

        return `width: ${progress}%;`;
      } else {
        return "";
      }
    },
    filesToPersist() {
      return _.differenceBy(this.files, this.uploadedFiles, "signedId");
    },
    singleFileError() {
      return this.files.length === 1 && this.files[0].errorMessage;
    }
  },
  watch: {
    filesToPersist: {
      handler() {
        if (this.readyToPersist) {
          this.persistFiles();
        }
      }
    }
  },
  methods: {
    hover(e) {
      e.dataTransfer.dropEffect = "move";
      this.dragging = true;
    },
    launchFilePicker(){
      this.$refs.files.click();
    },
    handleDragDropFiles(e) {
      this.dragging = false;
      let droppedFiles = e.dataTransfer.files;

      if (!droppedFiles) return;
      const toLoad = [...droppedFiles];

      this.pendingFileCount = toLoad.length;
      toLoad.forEach(this.loadFiles);
    },
    handleInputFiles() {
      const toLoad = Array.from(this.$refs.files.files);

      this.pendingFileCount = toLoad.length;
      toLoad.forEach(this.loadFiles);
      this.$refs.files.value = null;
    },
    loadFiles(file, index) {
      if (_.includes(this.fileNames, file.name)) return;

      if (this.multiple) {
        this.processFile(file);
      } else if (index === 0) {
        this.processFile(file);
      }
    },
    processFile(file) {
      const fileSize = (file.size / 1024 / 1024).toFixed(4); // MB

      if (fileSize > this.fileSizeLimit) {
        this.files.push(
          Object.assign(
            {},
            {
              name: file.name,
              size: file.size,
              signedId: null,
              errorMessage: `is > ${this.fileSizeLimit}MB.`
            }
          )
        );
        this.pendingFileCount--;
        setTimeout(() => {
          this.remove(file);
        }, 3500);
      } else {
        this.uploadFile(file);
      }
    },
    uploadFile(file) {
      const upload = new DirectUpload(file, this.fileUploadUrl, this);
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        // console.log("loaded");
      });

      reader.readAsDataURL(file);

      upload.create((error, blob) => {
        if (error) {
          console.error(error); // eslint-disable-line
        } else {
          this.files.push(
            Object.assign(
              {},
              {
                name: file.name,
                size: file.size / 1024 / 1024, // MB
                signedId: blob.signed_id,
                errorMessage: false
              }
            )
          );
          this.pendingFileCount--;
        }
      });
    },
    persistFiles() {
      let payload;

      if (this.multiple) {
        payload = {
          files: this.filesToPersist.map(f => {
            return {
              signedId: f.signedId,
              name: f.name,
              size: f.size
            };
          })
        };
      } else {
        payload = {
          file: {
            signedId: this.files[0].signedId,
            name: this.files[0].name,
            size: this.files[0].size
          }
        };
      }

      if (this.endpoint) {
        api.patch(this.endpoint, payload).then(json => {
          this.$store.dispatch("flash", this.flashMessage);

          if (this.afterAction) {
            let afterActionPayload = this.afterAction.payloadKey ? {} : json.data;

            if (this.afterAction.payloadKey) {
              afterActionPayload[this.afterAction.payloadKey] = json.data;
            }

            switch (this.afterAction.type) {
              case "commit":
                this.$store.commit(
                  this.afterAction.actionName,
                  afterActionPayload
                );
                break;
              case "dispatch":
                this.$store.dispatch(
                  this.afterAction.actionName,
                  afterActionPayload
                );
            }
          }

          this.files.forEach(file => {
            if (_.find(this.uploadedFiles, { signedId: file.signedId })) {
              return;
            } else {
              this.uploadedFiles.push(file);
            }
          });

          if (!this.multiple && this.heading) {
            this.$store.commit("closeModal");
          }
        });
      } else if (this.localCommit) {
        this.$store.commit(this.localCommit, payload);
        this.$store.dispatch("flash", this.flashMessage);

        this.files.forEach(file => {
          if (_.find(this.uploadedFiles, { signedId: file.signedId })) {
            return;
          } else {
            this.uploadedFiles.push(file);
          }
        });

        if (!this.multiple) {
          this.$store.commit("closeModal");
        }
      }
    },
    directUploadWillStoreFileWithXHR(request) {
      request.upload.addEventListener("loadstart", event =>
        this.directUploadDidStart(event)
      );
      request.upload.addEventListener("progress", event =>
        this.directUploadDidProgress(event)
      );
    },
    directUploadDidProgress(event) {
      this.uploadingProgress = event.loaded;
      // console.log("uploading progressed", this.progressWidth);

      if (event.loaded === event.total) {
        // console.log("finished uploading");
        setTimeout(() => {
          this.uploading = false;
          this.uploadingProgress = null;
          this.uploadingTotal = null;
        }, 1000);
      }
    },
    directUploadDidStart(event) {
      // console.log("began uploading", this.progressWidth);
      this.uploading = true;
      this.uploadingProgress = 1;
      this.uploadingTotal = event.total;
    },
    remove(file) {
      const newFiles = this.files.filter(f => f.name !== file.name);

      this.files = newFiles;
    }
  }
};
</script>

<style lang="scss" scoped>
.direct-upload__progress {
  transition: width 120ms ease-out, opacity 60ms 60ms ease-in;
  transform: translate3d(0, 0, 0);
}
</style>
