import TenantPopup from "./components/maps/tenant-popup";
import Vue from "vue";
/* global L */

const ContextTenantPopup = Vue.extend(TenantPopup);

export default function responsiveTenantPopup({
  router,
  store,
  marker,
  content,
  fillColor,
  isContext = false
}) {
  const popupVueEl = new ContextTenantPopup({
    store,
    router,
    propsData: { content, fillColor, isContext, marker }
  }).$mount().$el;

  return L.responsivePopup({ maxWidth: 150 }).setContent(popupVueEl);
}
