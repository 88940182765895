<template>
  <header class="bg-gray-800 space-y-1 py-6 px-4 sm:px-6">
    <div class="flex items-center justify-between space-x-3">
      <h2 class="text-lg leading-7 font-medium text-white">
        {{ currentUser.name }}
      </h2>
      <div class="h-7 flex items-center">
        <button @click="close" aria-label="Close panel" class="text-white">
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
    </div>
    <div>
      <p class="text-white text-sm leading-5">
        This is your space. Take notes, list your to-dos, or keep links handy.
      </p>
    </div>
    <div v-if="content || contentToken" class="rounded-md bg-indigo-50 p-2">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm text-indigo-700">
            Notepad is currently being filtered.
          </p>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["content", "contentToken", "contentType"],
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
