<template>
  <div class="relative p-4 max-w-3xl mx-auto">
    <div class="text-center">
      <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Create Premium Content
      </h2>
      <p v-if="contentName" class="mt-4 text-xl font-bold leading-6 text-gray-900">
        {{ contentName }}
      </p>
      <!--
        Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
        Read the documentation to get started: https://tailwindui.com/documentation
      -->
      <div class="mt-2 bg-indigo-50 border-l-4 border-indigo-400 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: information-circle -->
            <svg class="h-5 w-5 text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm leading-5 text-indigo-700">
              Only you and Tower Hunt can view this content until you launch it. Tower Hunt will create one or more purchaseable items from the content you submit. You have final review, pricing, and launch authority.
              <router-link to="/product/sell-premium-content" target="_blank" class="font-medium underline text-indigo-700 hover:text-indigo-600">
                Learn more &rarr;
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-12">
      <form @submit.prevent="confirmPrivacy" class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        <div class="sm:col-span-1">
          <div class="flex justify-between">
            <label for="narrative" class="block text-sm font-medium leading-5 text-gray-700">Intent</label>
            <span class="text-sm leading-5 text-gray-500" id="narrative-optional">Optional</span>
          </div>
          <div class="mt-2 relative rounded-md shadow-sm">
            <resizable-textarea>
              <textarea v-model="narrative" id="narrative" type="text" rows="4" placeholder="What are we trying to sell here? If anything necessary isn't in the attachments, add it here." class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-sm py-3 px-4 block w-full"></textarea>
            </resizable-textarea>
          </div>
        </div>
        <div class="sm:col-span-1">
          <div class="flex justify-between">
            <label for="attachements" class="block text-sm font-medium leading-5 text-gray-700">File-based content</label>
            <span class="text-sm leading-5 text-gray-500" id="narrative-optional">Optional</span>
          </div>
          <file-drag-drop
            class="mt-2"
            :endpoint="null"
            local-commit="setTemporaryAccess"
            :file-types="deliverableFileTypes"
            file-type-labels="XLS, PPT, DOC, PDF"
            :multiple="true"
            :size-limit="100"
            :heading="null"
            :full-width="true"
            bottom-margin="mb-0"
            flash-message="Deliverables attached" />
        </div>
        <div class="sm:col-span-2">
          <span class="text-sm leading-5 text-gray-900">
            By submitting your content, you affirm that you have permission to sell it per the <router-link to="/legal" target="_blank" class="purple">Terms of Service</router-link>.
          </span>
        </div>
        <div class="sm:col-span-2">
          <button type="submit" :disabled="!complete" :class="buttonStyling" class="w-full flex items-center justify-center py-3 px-4 border border-transparent text-base leading-6 font-medium rounded-md text-white">
            <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
              <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
            </svg>
            <svg
              v-else
              class="-ml-1 mr-2 h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
            Create premium content
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { all } from "../../document-file-types";
import { mapGetters, mapState } from "vuex";
import FileDragDrop from "../file-drag-drop";
import ResizableTextarea from "../resizable-textarea";
import Swal from "sweetalert2";
import anonymousPostOptions from "../../sweet-alert-always-anonymous-options";
import api from "../../api";

export default {
  components: { FileDragDrop, ResizableTextarea },
  props: ["contentType", "contentToken", "contentName"],
  data() {
    return {
      narrative: "",
      deliverables: [],
      documentFileTypes: all
    };
  },
  computed: {
    ...mapGetters(["privacyMode", "isAdmin"]),
    ...mapState(["temporaryAccess", "adminMode"]),
    deliverableFileTypes() {
      return "image/*," + this.documentFileTypes.join(",");
    },
    complete() {
      return this.deliverables.length > 0 || _.trim(this.narrative) !== "";
    },
    buttonStyling() {
      if (this.complete) {
        if (this.privacyMode) {
          return "bg-pink-600 hover:bg-pink-500 focus:outline-none focus:border-pink-700 focus:ring-pink active:bg-pink-700";
        } else {
          return "bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700";
        }
      } else {
        return "border-gray-300 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 cursor-not-allowed";
      }
    },
    privacyBackgroundColor() {
      return this.privacyMode ? "bg-pink-600" : "bg-indigo-600";
    }
  },
  watch: {
    temporaryAccess: {
      handler() {
        if (_.get(this.temporaryAccess, "files", []).length > 0) {
          this.deliverables = _.union(
            this.deliverables,
            this.temporaryAccess.files
          );
        }

        this.$store.commit("clearTemporaryAccess");
      }
    }
  },
  methods: {
    closeModal() {
      this.$store.commit("closeModal");
    },
    confirmPrivacy() {
      if (this.complete) {
        Swal.fire(anonymousPostOptions).then(result => {
          if (result.value) {
            this.persistContent();
          }
        });
      }
    },
    persistContent() {
      if (this.complete) {
        const payload = {
          narrative: this.narrative,
          files: this.deliverables,
          contentType: this.contentType,
          contentToken: this.contentToken
        };

        api.post(`purchaseable_intents`, payload).then(json => {
          if (!!analytics && !this.adminMode) {
            analytics.track("Purchaseable intent created", {
              yearWeek: this.$store.state.yearWeek
            });
          }
          this.$store.dispatch("flash", "We're processing your content now and will be in touch shortly!");
          this.closeModal();
        });
      }
    }
  }
};
</script>
