<template>
  <div v-if="canShowMenu" ref="prospectMenu" class="relative flex justify-end items-center" style="z-index: 9998;">
    <button @click="toggleMenu" id="purchaseable-options-menu-0" aria-has-popup="true" type="button" class="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100">
      <!-- Heroicon name: dots-vertical -->
      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
      </svg>
    </button>
    <transition
      enter-active-class="duration-100 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-75 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-show="menuOpen" class="mx-3 origin-top-right absolute right-7 top-0 w-56 mt-1 rounded-md shadow-lg">
        <div class="z-10 rounded-md bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="project-options-menu-0">
          <template v-if="canShowActions">
            <div class="py-1">
              <a v-if="canMarkDiscussed" href="" @click.prevent="markDiscussed" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                <!-- Heroicon name: chat-alt-2 -->
                <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                  <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                </svg>
                We discussed
              </a>
              <a v-if="canReinvite" @click.prevent="reinvite" href="" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                <!-- Heroicon name: mail -->
                <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                Re-invite
              </a>
              <a v-if="canRevoke" href="" @click.prevent="revokeDocuments" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                <!-- Heroicon name: lock-closed -->
                <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                </svg>
                Revoke documents
              </a>
              <a v-else-if="canGrant" href="" @click.prevent="grantDocuments" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                <!-- Heroicon name: lock-open -->
                <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
                </svg>
                Grant document access
              </a>
            </div>
            <div class="border-t border-gray-100"></div>
          </template>
          <template v-if="canShowDeletions">
            <div class="py-1">
              <a v-if="canDecline" href="" @click.prevent="decline" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                <!-- Heroicon name: thumbs-down -->
                <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z" />
                </svg>
                Decline deal
              </a>
              <a v-if="canRemove" href="" @click.prevent="remove" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                <!-- Heroicon name: trash -->
                <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z" />
                </svg>
                Remove
              </a>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: ["person"],
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "dealProspectInviteParam"]),
    ...mapState(["activeDeal", "activeDealMarketing", "modal", "xlModal"]),
    canShowActions() {
      return (
        this.canMarkDiscussed ||
        this.canReinvite ||
        this.canRevoke ||
        this.canGrant
      );
    },
    canShowMenu() {
      return this.canShowActions || this.canShowDeletions;
    },
    canShowDeletions() {
      return this.canDecline || this.canRemove;
    },
    isActive() {
      return _.includes(["live", "awarded"], this.activeDeal.state);
    },
    canReinvite() {
      return (
        this.isActive &&
        (!this.person.dealMarketingState || this.person.expired) &&
        !this.person.declined
      );
    },
    canMarkDiscussed() {
      return (
        this.isActive &&
        this.person.participationId &&
        !_.includes(["declined", "discussed"], this.person.dealMarketingState)
      );
    },
    canRevoke() {
      return (
        this.isActive &&
        this.person.participationId &&
        this.person.documentAccess
      );
    },
    canGrant() {
      return (
        this.isActive &&
        this.person.participationId &&
        this.person.dealMarketingState !== "declined"
      );
    },
    canDecline() {
      return (
        this.isActive &&
        this.person.dealMarketingState !== "declined" &&
        !this.person.declined
      );
    },
    canRemove() {
      return (
        !this.person.participationId ||
        this.activeDealMarketing.visibility === "restricted"
      );
    }
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.prospectMenu &&
        !this.$refs.prospectMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    remove() {
      this.$store
        .dispatch("removeMarketingListProspect", { prospect: this.person })
        .then(() => {
          this.$emit("refetch");
          this.menuOpen = false;
        });
    },
    decline() {
      const payload = this.person.participationId
        ? { participationId: this.person.particpationId }
        : { invitationId: this.person.invitationId };

      this.$store.dispatch("declineDeal", payload).then(() => {
        this.$emit("refetch");
        this.menuOpen = false;
      });
    },
    reinvite() {
      this.$store
        .dispatch("reinviteMarketingListProspect", { prospect: this.person })
        .then(() => {
          this.$emit("refetch");
          this.menuOpen = false;
        });
    },
    revokeDocuments() {
      this.$store
        .dispatch("revokeDocumentAccess", { prospect: this.person })
        .then(() => {
          this.$emit("refetch");
          this.menuOpen = false;
        });
    },
    grantDocuments() {
      this.$store
        .dispatch("grantDocumentAccess", { prospect: this.person })
        .then(() => {
          this.$emit("refetch");
          this.menuOpen = false;
        });
    },
    markDiscussed() {
      this.$store
        .dispatch("prospectDiscussionComplete", { prospect: this.person })
        .then(() => {
          this.$emit("refetch");
          this.menuOpen = false;
        });
    }
  }
};
</script>
