<template>
  <div class="container mx-auto md:mt-12 mb-4 px-4 sm:px-8 lg:px-10 flex flex-col items-center">
    <div :style="embedHeight" :id="file.id" class="h-full w-full" />
  </div>
</template>

<script>
/* global Dropbox */

export default {
  props: ["file"],
  data() {
    return {
      modalHeight: null,
      embed: null
    };
  },
  computed: {
    embedHeight() {
      if (this.modalHeight) {
        const heightRatio = this.modalHeight > 400 ? 0.9 : 0.7;

        return `height: ${this.modalHeight * heightRatio}px;`;
      } else {
        return "height: 200px";
      }
    }
  },
  mounted() {
    this.modalHeight = document.getElementById("xl-modal").offsetHeight;

    this.debouncedHeightUpdate = _.debounce(() => {
      this.modalHeight = document.getElementById("xl-modal").offsetHeight;
    }, 100);

    window.addEventListener("resize", this.debouncedHeightUpdate);

    const element = document.getElementById(this.file.id);
    var options = {
      // Shared link to Dropbox file
      link: this.file.link,
      file: {
        // Sets the zoom mode for embedded files. Defaults to 'best'.
        zoom: "fit" // or "fit"
      },
      folder: {
        // Sets the view mode for embedded folders. Defaults to 'list'.
        view: "list", // or "grid"
        headerSize: "normal" // or "small"
      }
    };

    if (element) {
      this.embed = Dropbox.embed(options, element);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedHeightUpdate);

    if (this.embed) {
      Dropbox.unmount(this.embed);
    }
  }
};
</script>
