<template>
  <div class="relative p-4 max-w-3xl mx-auto">
    <div class="text-center">
      <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Fulfill this bounty
      </h2>
      <p class="mt-4 text-xl font-bold leading-6 text-gray-900">
        {{ bounty.requestName }}
      </p>
      <bounty-fulfiller-progress class="mt-4" color="yellow" state="fulfilling" />
      <!--
        Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
        Read the documentation to get started: https://tailwindui.com/documentation
      -->
      <div class="mt-2 bg-yellow-50 border-l-4 border-yellow-400 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: exclamation -->
            <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm leading-5 text-yellow-700">
              You are anonymous throughout this process. Only the bounty owner sees your deliverable(s).
              <router-link to="/product/bounty-bids" target="_blank" class="font-medium underline text-yellow-700 hover:text-yellow-600">
                Learn more &rarr;
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-12">
      <form @submit.prevent="confirmPrivacy" class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        <div class="sm:col-span-2">
          <span class="w-full inline-flex rounded-md shadow-sm">
            <button @click="openChat" v-tooltip="'Anonymous 1:1 chat'" type="button" class="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
              <!-- Heroicon name: chat -->
              <svg class="-ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd" />
              </svg>
              Anonymous chat
            </button>
          </span>
        </div>
        <div class="sm:col-span-1">
          <!--
            Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
            Read the documentation to get started: https://tailwindui.com/documentation
          -->
          <div class="rounded-md bg-blue-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: information-circle -->
                <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm leading-5 font-medium text-blue-800">
                  The request
                </h3>
                <div class="mt-2 text-sm leading-5 whitespace-pre-line dont-break-out text-blue-700">
                  <p>{{ bounty.requestDescription }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sm:col-span-1">
          <div class="rounded-md bg-green-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: check-circle -->
                <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm leading-5 font-medium text-green-800">
                  Your accepted bid for ${{ bounty.myBid.bountyPrice }}
                </h3>
                <div class="mt-2 text-sm leading-5 whitespace-pre-line dont-break-out text-green-700">
                  <p>{{ bounty.myBid.qualifications }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sm:col-span-1">
          <div class="flex justify-between">
            <label for="narrative" class="block text-sm font-medium leading-5 text-gray-700">Written response</label>
            <span class="text-sm leading-5 text-gray-500" id="narrative-optional">Optional</span>
          </div>
          <div class="mt-2 relative rounded-md shadow-sm">
            <resizable-textarea>
              <textarea v-model="narrative" id="narrative" type="text" rows="4" placeholder="Answer by typing" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-sm py-3 px-4 block w-full"></textarea>
            </resizable-textarea>
          </div>
        </div>
        <div class="sm:col-span-1">
          <div class="flex justify-between">
            <label for="attachements" class="block text-sm font-medium leading-5 text-gray-700">Deliverables</label>
            <span class="text-sm leading-5 text-gray-500" id="narrative-optional">Optional</span>
          </div>
          <file-drag-drop
            class="mt-2"
            :endpoint="null"
            local-commit="setTemporaryAccess"
            :file-types="deliverableFileTypes"
            file-type-labels="XLS, PPT, DOC, PDF"
            :size-limit="100"
            :multiple="true"
            :heading="null"
            :full-width="true"
            bottom-margin="mb-0"
            flash-message="Deliverables attached" />
        </div>
        <div class="sm:col-span-2">
          <!--
            Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
            Read the documentation to get started: https://tailwindui.com/documentation
          -->
          <div class="rounded-md bg-yellow-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: exclamation -->
                <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm leading-5 font-medium text-yellow-800">
                  Best practices
                </h3>
                <div class="mt-2 text-sm leading-5 text-yellow-700">
                  <ul class="list-disc pl-5">
                    <li>
                      Have you provided everything you said you'd provide in your bid?
                    </li>
                    <li class="mt-1">
                      Before uploading files, <a href="https://support.microsoft.com/en-us/office/remove-hidden-data-and-personal-information-by-inspecting-documents-presentations-or-workbooks-356b7b5d-77af-44fe-a07f-9aa4d085966f" target="_blank" class="underline">strip any embedded metadata</a> (especially in office file formats and PDFs) so you don't accidentally identify yourself.
                    </li>
                    <li class="mt-1">
                      You'll be rated based on whether the requester would work with you again. Have you done your best?
                    </li>
                    <li class="mt-1">
                      Are you being vague enough to protect your identity?
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sm:col-span-2">
          <span class="text-sm leading-5 text-gray-900">
            By submitting your content, you affirm that you have permission to sell it per the <router-link to="/legal" target="_blank" class="purple">Terms of Service</router-link>.
          </span>
        </div>
        <div class="sm:col-span-2">
          <button type="submit" :disabled="!complete" :class="buttonStyling" class="w-full flex items-center justify-center py-3 px-4 border border-transparent text-base leading-6 font-medium rounded-md text-white">
            <svg v-if="!isPublic" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
              <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
            </svg>
            <svg
              v-else
              class="-ml-1 mr-2 h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
              <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
            </svg>
            Confirm fulfillment
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { all } from "../../document-file-types";
import { mapGetters, mapState } from "vuex";
import BidPrivateChat from "./bid-private-chat";
import BountyFulfillerProgress from "./bounty-fulfiller-progress";
import FileDragDrop from "../file-drag-drop";
import ResizableTextarea from "../resizable-textarea";
import Swal from "sweetalert2";
import anonymousPostOptions from "../../sweet-alert-always-anonymous-options";
import api from "../../api";

export default {
  components: { BountyFulfillerProgress, FileDragDrop, ResizableTextarea },
  props: ["bounty"],
  data() {
    return {
      narrative: "",
      deliverables: [],
      documentFileTypes: all
    };
  },
  computed: {
    ...mapGetters(["privacyMode"]),
    ...mapState(["temporaryAccess", "adminMode"]),
    deliverableFileTypes() {
      return "image/*," + this.documentFileTypes.join(",");
    },
    isPublic() {
      return !!this.bounty.myBid.bidder;
    },
    bidIdentity() {
      return this.isPublic ? "as me" : "anonymously";
    },
    complete() {
      return this.deliverables.length > 0 || _.trim(this.narrative) !== "";
    },
    buttonStyling() {
      if (this.complete) {
        if (!this.isPublic) {
          return "bg-pink-600 hover:bg-pink-500 focus:outline-none focus:border-pink-700 focus:ring-pink active:bg-pink-700";
        } else {
          return "bg-gradient-to-r from-yellow-300 to-yellow-500 hover:to-yellow-600 focus:border-yellow-500 focus:ring-yellow active:to-yellow-600";
        }
      } else {
        return "border-gray-300 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 cursor-not-allowed";
      }
    },
    privacyBackgroundColor() {
      return !this.isPublic ? "bg-pink-600" : "bg-indigo-600";
    }
  },
  watch: {
    temporaryAccess: {
      handler() {
        if (_.get(this.temporaryAccess, "files", []).length > 0) {
          this.deliverables = _.union(
            this.deliverables,
            this.temporaryAccess.files
          );
        }

        this.$store.commit("clearTemporaryAccess");
      }
    }
  },
  methods: {
    closeModal() {
      this.$store.commit("closeModal");
    },
    openChat() {
      this.$store.commit("openSlideover", {
        component: BidPrivateChat,
        props: {
          bounty: this.bounty,
          bid: this.bounty.myBid
        }
      });

      if (!!analytics && !this.adminMode) {
        analytics.track("Opened private bounty bid chat", {
          yearWeek: this.$store.state.yearWeek
        });
      }
    },
    confirmPrivacy() {
      if (this.complete) {
        Swal.fire(anonymousPostOptions).then(result => {
          if (result.value) {
            this.fulfillRequest();
          }
        });
      }
    },
    fulfillRequest() {
      if (this.complete) {
        const payload = {
          narrative: this.narrative,
          files: this.deliverables
        };

        api
          .post(`bounty_bid_fulfillments/${this.bounty.myBid.id}`, payload)
          .then(json => {
            if (!!analytics && !this.adminMode) {
              analytics.track("Bounty fulfillment submitted", {
                yearWeek: this.$store.state.yearWeek
              });
            }
            this.bounty.myBid = json.data;
            this.$store.dispatch("flash", "Bounty fulfillment submitted!");
            this.closeModal();
          });
      }
    }
  }
};
</script>
