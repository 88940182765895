<template>
  <transition
    enter-active-class="ease-out duration-700"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-out duration-700"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div v-show="value" class="fixed inset-0 overflow-hidden" style="z-index: 10000;">
      <div class="absolute inset-0 bg-gray-800 opacity-75"></div>
      <div class="absolute inset-0 overflow-hidden">
        <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex">
          <transition
            enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            enter-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            leave-class="translate-x-0"
            leave-to-class="translate-x-full"
          >
            <div ref="slideover" v-show="value" class="w-screen max-w-md">
              <div v-if="value && !myNotepad && content" class="h-full flex flex-col bg-white shadow-xl">
                <newsfeed-post-slideover-header :content="content" @close="close" />
                <div class="min-h-0 flex-1 flex flex-col space-y-6 overflow-y-auto">
                  <div class="relative flex-1 py-6 px-4 sm:px-6">
                    <slot />
                  </div>
                </div>
              </div>

              <div v-else-if="value && myNotepad" class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <notepad-slideover-header :content="content" :content-token="contentToken" :content-type="contentType" @close="close" />
                <div id="slideover-body-container" class="min-h-0 flex-1 flex flex-col space-y-6 overflow-y-auto">
                  <div class="relative flex-1 px-4 sm:px-6">
                    <slot />
                  </div>
                </div>
                <notepad-input :content="content" :content-token="contentToken" :content-type="contentType" />
              </div>

              <div v-else-if="value && (contact || involvedCompany)" class="h-full flex flex-col bg-white shadow-xl">
                <contact-slideover-header :contact="contact" :involved-company="involvedCompany" @edit-contact="editContact" @close="close" />
                <div id="slideover-body-container" class="min-h-0 flex-1 flex flex-col space-y-6 overflow-y-auto">
                  <div class="relative flex-1 px-4 sm:px-6">
                    <slot />
                  </div>
                </div>
                <div v-if="editingSlideoverContact" class="flex-shrink-0 px-4 py-4 flex justify-end items-center">
                  <button @click="cancelContactEditing" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Cancel
                  </button>
                  <button @click="saveContact" type="button" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Save
                  </button>
                </div>
              </div>

              <div v-else-if="value && bounty && bid" class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <bounty-bid-slideover-header :bounty="bounty" :bid="bid" @close="close" />
                <div id="slideover-body-container" class="min-h-0 flex-1 flex flex-col space-y-6 overflow-y-auto">
                  <div class="relative flex-1 px-4 sm:px-6">
                    <slot />
                  </div>
                </div>
                <bid-chat-message-input :bid="bid" />
              </div>

              <div v-else-if="value && property" class="h-full flex flex-col bg-white shadow-xl">
                <property-slideover-header :property="property" @close="close" />
                <div class="min-h-0 flex-1 flex flex-col space-y-6 overflow-y-auto">
                  <div class="relative flex-1 py-6 px-4 sm:px-6">
                    <slot />
                  </div>
                </div>
              </div>

              <div v-else-if="value && safezoneData" class="h-full flex flex-col bg-white shadow-xl">
                <safezone-data-slideover-header :safezone-data="safezoneData" @close="close" />
                <div class="min-h-0 flex-1 flex flex-col space-y-6 overflow-y-auto">
                  <div class="relative flex-1 py-6 px-4 sm:px-6">
                    <slot />
                  </div>
                </div>
              </div>

              <div v-else-if="value && mapFilter" class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <map-filter-slideover-header @close="close" />
                <div id="slideover-body-container" class="min-h-0 flex-1 flex flex-col space-y-6 overflow-y-auto">
                  <div class="relative flex-1 py-6 px-4 sm:px-6">
                    <slot />
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-between items-center">
                  <div class="flex items-center">
                    <grid-loader :loading="mapFiltering" size="6px" color="#27272A" />
                  </div>
                  <div class="flex justify-end items-center">
                    <button @click="close" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300">
                      Cancel
                    </button>
                    <button v-if="hasMapFilters" @click="clearFilter" type="button" class="ml-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                      Clear
                    </button>
                    <button @click="applyFilter" type="button" class="ml-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300">
                      Apply
                    </button>
                  </div>
                </div>
              </div>

              <div v-else-if="value && newsfeedFilter" class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <map-filter-slideover-header @close="close" />
                <div id="slideover-body-container" class="min-h-0 flex-1 flex flex-col space-y-6 overflow-y-auto">
                  <div class="relative flex-1 py-6 px-4 sm:px-6">
                    <slot />
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-between items-center">
                  <div class="flex items-center">
                    <grid-loader :loading="newsfeedFiltering" size="6px" color="#27272A" />
                  </div>
                  <div class="flex justify-end items-center">
                    <button @click="close" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300">
                      Cancel
                    </button>
                    <button v-if="hasNewsfeedFilters" @click="clearFilter" type="button" class="ml-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                      Clear
                    </button>
                    <button @click="applyFilter" type="button" class="ml-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300">
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BidChatMessageInput from "./bounties/bid-chat-message-input";
import BountyBidSlideoverHeader from "./bounties/bounty-bid-slideover-header";
import ContactSlideoverHeader from "./contact-slideover-header";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import MapFilterSlideoverHeader from "./maps/filter-slideover-header";
import NewsfeedPostSlideoverHeader from "./newsfeed-post-slideover-header";
import NotepadInput from "./notepad-input";
import NotepadSlideoverHeader from "./notepad-slideover-header";
import PropertySlideoverHeader from "./property-slideover-header";
import SafezoneDataSlideoverHeader from "./safezone/safezone-data-slideover-header.vue";

export default {
  components: {
    BidChatMessageInput,
    BountyBidSlideoverHeader,
    ContactSlideoverHeader,
    GridLoader,
    MapFilterSlideoverHeader,
    NewsfeedPostSlideoverHeader,
    NotepadInput,
    NotepadSlideoverHeader,
    PropertySlideoverHeader,
    SafezoneDataSlideoverHeader
  },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState([
      "mapFiltering",
      "newsfeedFiltering",
      "editingSlideoverContact"
    ]),
    ...mapGetters([
      "hasMapFilters",
      "hasNewsfeedFilters",
      "newContactComplete"
    ]),
    content() {
      return _.get(this.value.props, "content", null);
    },
    contentToken() {
      return _.get(this.value.props, "contentToken", null);
    },
    contentType() {
      return _.get(this.value.props, "contentType", null);
    },
    bounty() {
      return _.get(this.value.props, "bounty", null);
    },
    bid() {
      return _.get(this.value.props, "bid", null);
    },
    property() {
      return _.get(this.value.props, "property", null);
    },
    mapFilter() {
      return _.get(this.value.props, "mapFilter", false);
    },
    newsfeedFilter() {
      return _.get(this.value.props, "newsfeedFilter", false);
    },
    myNotepad() {
      return _.get(this.value.props, "myNotepad", false);
    },
    safezoneData() {
      return _.get(this.value.props, "safezoneData", null);
    },
    contact() {
      return _.get(this.value, "props.contact", null);
    },
    involvedCompany() {
      return _.get(this.value.props, "involvedCompany", null);
    }
  },
  methods: {
    close() {
      this.$store.commit("closeSlideover");
      this.$store.commit("clearNewContact");
    },
    editContact() {
      this.$store.commit("setEditingSlideoverContact", true);
    },
    cancelContactEditing() {
      if (this.contact) {
        this.$store.commit("setEditingSlideoverContact", false);
        this.$store.commit("clearNewContact");
      } else {
        this.close();
      }
    },
    saveContact() {
      if (this.newContactComplete) {
        this.$store.dispatch("saveNewContact", {
          existingContact: this.contact,
          involvedCompany: this.involvedCompany
        });
      }
    },
    clearFilter() {
      if (this.mapFilter) {
        this.$store.commit("setMapFiltering", true);
        this.$store.commit("clearMapFilters");
      } else if (this.newsfeedFilter) {
        this.$store.commit("setNewsfeedFiltering", true);
        this.$store.commit("clearNewsfeedFilters");
      }
      this.close();
    },
    applyFilter() {
      if (this.mapFilter) {
        this.$store.commit("setMapFiltering", true);
        this.$store.commit("shouldPersistMapFilters", true);
      } else if (this.newsfeedFilter) {
        this.$store.commit("setNewsfeedFiltering", true);
        this.$store.commit("shouldPersistNewsfeedFilters", true);
      }
    }
  }
};
</script>
