<template>
  <header class="space-y-1 py-6 px-4 sm:px-6 bg-gray-800">
    <div class="flex items-center justify-between space-x-3">
      <h2 class="text-lg leading-7 font-medium text-white">
        Filter
      </h2>
      <div class="h-7 flex items-center">
        <button @click="close" aria-label="Close panel" class="text-gray-200 hover:text-white">
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
    </div>
    <div>
      <p class="text-sm leading-5 text-gray-300">
        Focus on the data you care about.
      </p>
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
