<template>
  <div class="relative p-4 max-w-3xl mx-auto">
    <div class="text-center">
      <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        <template v-if="fulfilling">Your bounty fulfillment</template>
        <template v-else>Your bid</template>
      </h2>
      <p class="mt-4 text-xl font-bold leading-6 text-gray-900">
        {{ bounty.requestName }}
      </p>
      <bounty-fulfiller-progress class="mt-4" color="yellow" :state="bid.state" />
      <!--
        Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
        Read the documentation to get started: https://tailwindui.com/documentation
      -->
      <div class="mt-2 bg-yellow-50 border-l-4 border-yellow-400 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: exclamation -->
            <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm leading-5 text-yellow-700">
              You are anonymous throughout this process. Only the bounty owner sees your bid.
              <router-link to="/product/bounty-bids" target="_blank" class="font-medium underline text-yellow-700 hover:text-yellow-600">
                Learn more &rarr;
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <bid-detail :bounty="bounty" :bid="bid" class="mt-6" />
  </div>
</template>

<script>
import BidDetail from "./bid-detail";
import BountyFulfillerProgress from "./bounty-fulfiller-progress";

export default {
  components: { BidDetail, BountyFulfillerProgress },
  props: ["bounty", "bid"],
  computed: {
    fulfilling() {
      return _.includes(["submitted", "fulfilled", "payable"], this.bid.state);
    }
  }
};
</script>
