<template>
  <div v-if="activeContact && activeContact.contact" class="bg-white shadow rounded-md">
    <div class="bg-white shadow overflow-visible rounded-md">
      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div
          class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
        >
          <div class="ml-4 mt-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Closed Deals
            </h3>
            <p v-if="userIsActiveContact" class="mt-1 text-sm leading-5 text-gray-500">
              Visitors to your profile will see all of your closed deals. Confidential deals will be redacted. A Tower Hunt account is required to view details.
            </p>
          </div>
          <div class="ml-4 mt-2 flex-shrink-0 flex">
            <span v-if="userIsActiveContact && !privacyMode" class="inline-flex rounded-md shadow-sm md:hidden">
              <button
                @click="newDeal"
                type="button"
                :class="privacyMode ? 'bg-pink-600 hover:bg-pink-500 focus:border-pink-700 focus:ring-pink active:bg-pink-700' : 'bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700'"
                class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none"
              >
                Add closed deal
              </button>
            </span>
            <closed-deal-notifications-menu v-if="signedIn && !userIsActiveContact && activeContact.contact.followedByMe" :source-record="activeContact.contact" type="contact" class="ml-3 md:ml-0" />
            <span v-if="userIsActiveContact && !privacyMode" class="hidden ml-3 md:inline-flex rounded-md shadow-sm">
              <button
                @click="newDeal"
                type="button"
                :class="privacyMode ? 'bg-pink-600 hover:bg-pink-500 focus:border-pink-700 focus:ring-pink active:bg-pink-700' : 'bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700'"
                class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none"
              >
                Add closed deal
              </button>
            </span>
          </div>
        </div>
      </div>
      <div v-if="deals.length > 0" class="px-4 py-5 sm:p-6">
        <div class="grid gap-5 max-w-lg mx-auto sm:grid-cols-2 lg:grid-cols-3 sm:max-w-none">
          <multi-content-preview-card
            v-for="deal in deals"
            :key="`${deal.token}`"
            :content="deal"
          />
        </div>
      </div>
      <div v-if="dealPagination && dealPagination.count > 3" class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div class="hidden sm:block">
          <p class="text-sm leading-5 text-gray-700">
            Showing
            <span class="font-medium">{{ dealPagination.from }}</span>
            to
            <span class="font-medium">{{ dealPagination.to }}</span>
            of
            <span class="font-medium">{{ dealPagination.count }}</span>
            deals
          </p>
        </div>
        <div class="flex-1 flex justify-between sm:justify-end">
          <router-link @click.native="fetchDeals(dealPagination.prev_url)" to="" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
            Previous
          </router-link>
          <router-link @click.native="fetchDeals(dealPagination.next_url)" to="" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
            Next
          </router-link>
        </div>
      </div>
      <div v-if="deals.length === 0" class="relative bg-gray-800">
        <div class="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <img class="w-full h-full object-cover" src="https://source.unsplash.com/collection/277102" alt="" />
        </div>
        <div class="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <div class="md:ml-auto md:w-1/2 md:pl-10">
            <div class="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
              Deal history
            </div>
            <h2 class="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
              <template v-if="userIsActiveContact">Transactions you've been a part of</template>
              <template v-else>Closed transactions involving this person</template>
            </h2>
            <p class="mt-3 text-lg leading-7 text-gray-300">
              <template v-if="userIsActiveContact">Building out your deal history is central to quantifying your relevance to others.</template>
              <template v-else>There's nothing to show you yet</template>
            </p>
            <div v-if="userIsActiveContact" class="mt-8">
              <div class="inline-flex rounded-md shadow">
                <a @click.prevent="newDeal" href="" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:ring">
                  Add closed deal
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapState } from "vuex";
import ClosedDealNotificationsMenu from "./closed-deal-notifications-menu";
import MultiContentPreviewCard from "./cards/multi-content-preview-card";

export default {
  components: {
    ClosedDealNotificationsMenu,
    MultiContentPreviewCard
  },
  computed: {
    ...mapGetters(["userIsActiveContact", "name", "signedIn", "privacyMode"]),
    ...mapState(["activeContact", "currentUser"]),
    deals() {
      return _.orderBy(
        this.activeContact.deals.data,
        deal => {
          return moment(deal.newsfeedSortDate);
        },
        ["desc"]
      );
    },
    dealPagination() {
      return this.activeContact.deals.pagy;
    },
    shouldFetchDeals() {
      return _.get(this.activeContact, "contact.token", false);
    }
  },
  watch: {
    currentUser: {
      handler: function() {
        if (this.shouldFetchDeals) {
          this.fetchDeals();
        }
      }
    },
    shouldFetchDeals: {
      handler: function() {
        if (this.shouldFetchDeals) {
          this.fetchDeals();
        }
      }
    }
  },
  mounted() {
    if (this.shouldFetchDeals) {
      this.fetchDeals();
    }
  },
  methods: {
    fetchDeals(url = null) {
      const cleanUrl = url ? _.drop(url, 8).join("") : url;

      this.$store.dispatch("loadContactDeals", cleanUrl);
    },
    newDeal() {
      this.$router.push({
        path: "/intel/new/privacy",
        query: { nextUrl: "/deals/new/1" }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  width: 100%;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  i {
    margin: 15px 0;
    font-size: 5em;
  }
}
</style>
