<template>
  <div class="wrapper">
    <heading>Are you only interested in deals of a certain physical size?</heading>
    <button @click="save" type="button" class="cta mb-2" :class="{ complete: true }" tabindex="999">
      <template v-if="targetHasPropertySize && targetId">Save</template>
      <template v-else>Any sizes</template>
    </button>
    <form @submit.prevent="save" novalidate class="mb-4">
      <property-size-range v-for="(use, index) in sortedUses" :key="use.type" :use="use" :index="index" />
      <option-description :description="helpText" style="margin-top: 20px;" />
      <button @click="save" type="submit" class="hidden" />
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Heading from "../../components/onboarding/heading";
import OptionDescription from "../../components/onboarding/option-description";
import PropertySizeRange from "./property-size-range";
import useTypes from "../../use-types";

export default {
  components: { Heading, OptionDescription, PropertySizeRange },
  props: ["targetId"],
  computed: {
    ...mapState(["activeTarget"]),
    ...mapGetters(["targetHasPropertySize"]),
    sizeSummary() {
      const summary = this.$options.filters.propertySizeRange(
        this.activeTarget.uses
      );

      if (this.targetHasPropertySize) {
        return summary;
      } else {
        return null;
      }
    },
    sortedUses() {
      return _.sortBy(this.activeTarget.uses, [u => {
        return _.find(useTypes, { value: u.type }).order;
      }]);
    },
    helpText() {
      if (this.sizeSummary) {
        return this.sizeSummary;
      } else {
        return "Multiple size limits (e.g., retail < 25,000 sf and resi > 150 units) behave as OR, not AND. So, in this example case a deal with 10,000 sf of retail and 100 units of resi would qualify because it meets the retail criteria even though it misses the resi criteria.";
      }
    }
  },
  methods: {
    save() {
      if (this.targetId) {
        this.$store.dispatch("updateTargetPropertySizes", {
          targetId: this.targetId,
          refetch: true
        });
        this.$store.commit("closeModal");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  display: flex;
  flex-direction: column;
  width: 330px;
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
