<template>
  <div v-tooltip="'Start over'" class="absolute top-0 p-4" :class="{ 'left-0': flip, 'right-0': !flip }">
    <button @click="startOver" tabindex="1000" type="button" class="text-gray-500">
      <i class="fas fa-undo"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: ["flip"],
  methods: {
    startOver() {
      this.$store.dispatch("confirmOnboardingReset");
    }
  }
}
</script>
