<template>
  <!-- Main area -->
  <main class="min-w-0 flex-1 border-t border-gray-200 xl:flex">
    <section aria-labelledby="message-heading" class="min-w-0 flex-1 h-full flex flex-col overflow-hidden xl:order-last">
      <!-- Top section -->
      <div class="flex-shrink-0 bg-white border-b border-gray-200">
        <!-- Toolbar-->
        <div class="h-16 flex flex-col justify-center">
          <div v-if="selectedMarket" class="px-4 sm:px-6 lg:px-8">
            <div class="py-3 flex justify-between">
              <div class="flex items-center">
                <h2 class="text-lg font-medium text-gray-900">{{ selectedMarket.name }}</h2>
              </div>
              <div class="flex bg-gray-100 p-0.5 rounded-lg items-center">
                <button @click="viewing = 'newsfeed'" type="button" :class="viewing === 'newsfeed' ? 'bg-white shadow-sm' : 'hover:bg-white hover:shadow-sm'" class="p-1.5 rounded-md text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <!-- Heroicon name: solid/view-list -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                  </svg>
                  <span class="sr-only">Use list view</span>
                </button>
                <button @click="viewing = 'map'" type="button" :class="viewing === 'map' ? 'bg-white shadow-sm' : 'hover:bg-white hover:shadow-sm'" class="ml-0.5 p-1.5 rounded-md text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <!-- Heroicon name: solid/globe -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clip-rule="evenodd" />
                  </svg>
                  <span class="sr-only">Use map view</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="min-h-0 flex-1 overflow-y-auto">
        <!-- Map section-->
        <strategic-map v-if="selectedMarket && viewing === 'map'" :market="selectedMarket" :embedded="true" />

        <!-- Thread section-->
        <ul v-else-if="selectedMarket && viewing === 'newsfeed'" class="py-4 space-y-4 sm:px-6 sm:space-y-8 lg:px-8">
          <div v-if="fetchingNewsfeed" class="mt-4 mb-1 flex justify-center">
            <grid-loader :loading="fetchingNewsfeed" size="6px" color="#5850ec" />
          </div>

          <multi-content-preview-card
            v-for="(post, index) in newsfeed.data"
            :key="`${post.content.token}-${index}`"
            :content="post.content"
            class="col-span-1 mt-8"
          />

          <div v-if="endOfFeed" class="col-span-1 mt-4 bg-white shadow sm:rounded-lg">
            <div key="end-of-feed" class="px-4 py-5 sm:p-6">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Create an account to get the latest intel
              </h3>
              <div class="mt-2">
                <div class="max-w-xl text-sm leading-5 text-gray-500">
                  <p>
                    There's more where this came from.
                  </p>
                </div>
                <div class="mt-5">
                  <span class="inline-flex rounded-md shadow-sm">
                    <a @click.prevent="signUpWithMarket(selectedMarket)" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 cursor-pointer">
                      Customize this market
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <infinite-loading
            v-else-if="loaded"
            :identifier="selectedMarket.token"
            @infinite="incrementNewsfeed"
          />
        </ul>
      </div>
    </section>

    <!-- Market list-->
    <aside class="hidden xl:block xl:flex-shrink-0 xl:order-first">
      <div class="h-full relative flex flex-col w-96 border-r border-gray-200 bg-gray-100">
        <div class="flex-shrink-0">
          <div class="h-16 bg-white px-6 flex flex-col justify-center">
            <div class="flex items-baseline space-x-3">
              <h2 class="text-lg font-medium text-gray-900">Explore a market:</h2>
            </div>
          </div>
          <div class="border-t border-b border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500">
            Trailing 2-week data
          </div>
        </div>
        <nav aria-label="Message list" class="min-h-0 flex-1 overflow-y-auto">
          <div v-if="fetchingMarkets" class="mt-4 mb-1 flex justify-center">
            <grid-loader :loading="fetchingMarkets" size="6px" color="#5850ec" />
          </div>
          <ul v-else-if="markets.length > 0 && selectedMarket" class="border-b border-gray-200 divide-y divide-gray-200">
            <li v-for="market in markets" :key="market.token" :class="selectedMarket.token === market.token ? 'ring-2 ring-inset ring-indigo-600' : ''" class="relative bg-white py-5 px-6 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
              <div class="flex space-x-3">
                <div class="min-w-0 flex-1">
                  <a @click.prevent="setSelectedMarket(market)" href="" class="block focus:outline-none">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    <p class="text-base font-semibold text-gray-900 truncate">{{ market.name }}</p>
                    <p class="text-sm text-gray-500 truncate">
                      <span v-if="market.deals.length > 0">{{ market.deals | sortedTypeLabels(2) }}</span>
                      <span v-else>All deal types</span>
                    </p>
                  </a>
                </div>
              </div>
              <div class="mt-1">
                <p class="line-clamp-2 text-sm text-gray-600">
                  <template v-if="market.notes">{{ market.notes }}</template>
                  <template v-else-if="market.uses.length > 0">{{ market.uses | sortedTypeLabels(3) }}</template>
                  <template v-else>All property uses</template>
                </p>
              </div>
              <div v-if="!market.notes" class="mt-1">
                <p class="line-clamp-2 text-sm text-gray-600">
                  <template v-if="market.risks.length > 0">{{ market.risks | sortedTypeLabels(2) }}</template>
                  <template v-else>All strategies</template>
                </p>
              </div>
              <div v-if="selectedMarket.token === market.token" class="mt-2">
                <button @click="signUpWithMarket(market)" type="button" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                    <!-- Heroicon name: solid/lock-closed -->
                    <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
                    </svg>
                  </span>
                  Customize this market
                </button>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  </main>
</template>

<script>
import GridLoader from "vue-spinner/src/GridLoader.vue";
import InfiniteLoading from "vue-infinite-loading";
import MultiContentPreviewCard from "./cards/multi-content-preview-card";
import StrategicMap from "./maps/strategic-map";
import api from "../api";

export default {
  components: {
    GridLoader,
    InfiniteLoading,
    MultiContentPreviewCard,
    StrategicMap
  },
  data() {
    return {
      loaded: false,
      viewing: "newsfeed",
      fetchingMarkets: false,
      fetchingNewsfeed: false,
      endOfFeed: false,
      markets: [],
      selectedMarket: null,
      newsfeed: {
        data: [],
        pagy: null
      }
    };
  },
  computed: {
    pagination() {
      return this.newsfeed.pagy;
    }
  },
  mounted() {
    this.fetchingMarkets = true;
    api.get(`showcase_markets`).then(json => {
      this.markets = json.data;
      this.fetchingMarkets = false;

      if (this.markets.length > 0) {
        this.selectedMarket = _.head(this.markets);
        this.fetchNewsfeed();
      }
    });
  },
  methods: {
    signUpWithMarket(market) {
      this.$router.push({ path: `/clone_markets/${market.token}` });
    },
    setSelectedMarket(market) {
      if (this.selectedMarket.token !== market.token) {
        this.selectedMarket = market;
        this.fetchNewsfeed();
      }
    },
    fetchNewsfeed() {
      this.loaded = false;
      this.endOfFeed = false;
      this.newsfeed = { data: [], pagy: null };
      this.fetchingNewsfeed = true;
      api.get(`showcase_markets/${this.selectedMarket.token}`).then(json => {
        this.newsfeed = json.data;
        this.fetchingNewsfeed = false;
        this.loaded = true;
      });
    },
    extendNewsfeed({ data, pagy }) {
      this.newsfeed.data = this.newsfeed.data.concat(data);
      this.newsfeed.pagy = pagy;
    },
    incrementNewsfeed($state) {
      if (this.pagination) {
        const { count, to } = this.pagination;

        if (count === 0) {
          this.endOfFeed = true;
        } else if (to < count) {
          const cleanUrl = _.drop(this.pagination.next_url, 8).join("");

          this.busy = true;
          api.get(cleanUrl).then(json => {
            this.extendNewsfeed(json.data);
            $state.loaded();
            this.busy = false;
          });
        } else {
          this.endOfFeed = true;
          $state.complete();
        }
      }
    }
  }
};
</script>
