<template>
  <div class="wrapper">
    <heading>When did the deal close?</heading>
    <template v-if="!closingFromMarketingList">
      <button v-if="underAgreement" @click="setClosed" type="button" class="secondary calendar-button mb-2" tabindex="999">Set a closed date</button>
      <button v-else @click="setUnderAgreement" type="button" class="secondary calendar-button mb-2" tabindex="999">It's under agreement</button>
    </template>
    <form novalidate>
      <v-date-picker v-if="!underAgreement || closingFromMarketingList" mode="single" v-model="closeDate" :color="color" is-inline is-required />
      <option-description class="mt-2" :description="description" />

      <button v-if="!duplicatesLoaded" type="button" :disabled="true" class="control secondary my-1 cursor-not-allowed" tabindex="999">Duplicate deal check...</button>

      <button v-else-if="potentialDuplicates.length > 0" @click="reviewDuplicates" type="button" class="control cta mt-4" :class="privacyMode ? 'privacy-complete' : 'complete'" tabindex="999">
        <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
          <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
        </svg>
        Next
      </button>

      <template v-else-if="dealId">
        <button v-if="updatingDateOnly" @click="confirmPrivacy" type="button" class="cta mt-4" :class="{ complete: true }" tabindex="999">Save</button>

        <template v-else-if="directDeal">
          <button @click="next(false)" type="button" class="cta mt-4" :class="{ complete: true }" tabindex="999">Post deal to your profile</button>
          <button @click="next(true)" type="button" class="secondary my-1" tabindex="999">Post and set as confidential</button>
        </template>

        <button v-else @click="confirmPrivacy" type="button" class="cta mt-4" :class="privacyMode ? 'privacy-complete' : 'complete'" tabindex="999">
          <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
            <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
          </svg>
          Confirm author and post
        </button>
      </template>

      <template v-else-if="newRecordIsSafezone">
        <button @click="safezone" type="button" :class="{ 'mt-4': newRecordIsSafezone, 'mt-1': !newRecordIsSafezone, 'privacy-complete': privacyMode, 'complete': !privacyMode }" class="control cta" tabindex="999">
          <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
            <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
          </svg>
          Save to safezone
        </button>
      </template>

      <template v-else>
        <template v-if="directDeal">
          <button @click="next(false)" type="button" class="cta mt-4" :class="{ complete: true }" tabindex="999">Post deal to your profile</button>
          <button @click="next(true)" type="button" class="secondary my-1" tabindex="999">Post and set as confidential</button>
        </template>

        <template v-else>
          <button @click="confirmPrivacy" type="button" class="cta mt-4" :class="privacyMode ? 'privacy-complete' : 'complete'" tabindex="999">
            <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
              <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
            </svg>
            Confirm author and post
          </button>
        </template>
      </template>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AddDealParticipants from "./add-deal-participant";
import Heading from "../../../components/onboarding/heading";
import OptionDescription from "../../../components/onboarding/option-description";
import Swal from "sweetalert2";
import anonymousPostOptions from "../../../sweet-alert-always-anonymous-options";

export default {
  components: { Heading, OptionDescription },
  props: ["dealId", "transitioningToClosed"],
  computed: {
    ...mapState([
      "dealCreationInProcess",
      "activeDeal",
      "activeDealMarketing",
      "modal",
      "adminMode",
      "newRecordIsSafezone"
    ]),
    ...mapGetters([
      "creatingDeal",
      "creatingActiveDeal",
      "creatingIndirectActiveDeal",
      "privacyMode"
    ]),
    shouldFetchDuplicates() {
      return !this.dealId && !this.activeDealMarketing.id;
    },
    duplicatesLoaded() {
      return _.get(this.activeDeal, "potentialDuplicates.loaded", true);
    },
    potentialDuplicates() {
      return _.get(this.activeDeal, "potentialDuplicates.deals", []);
    },
    color() {
      return this.privacyMode ? "pink" : "indigo";
    },
    closeDate: {
      get() {
        if (this.activeDeal.closeDate) {
          return new Date(this.activeDeal.closeDate);
        } else {
          return new Date();
        }
      },
      set(date) {
        this.$store.commit("setCloseDate", date);
        this.setClosed();

        this.refetchDuplicates();
      }
    },
    updatingDateOnly() {
      return (
        this.dealId &&
        !this.closingFromMarketingList &&
        !this.transitioningToClosed
      );
    },
    closingFromMarketingList() {
      return this.activeDealMarketing.id;
    },
    underAgreement() {
      return this.activeDeal.state === "awarded";
    },
    directDeal() {
      return (
        this.activeDeal.source === "direct" ||
        this.activeDeal.source === "direct lightweight"
      );
    },
    indirectDeal() {
      return this.activeDeal.source === "indirect";
    },
    description() {
      if (this.directDeal) {
        return "Confidential deals redact key details. To protect your privacy, they are not counted in your deal history stats. This may cause you to appear lower in ranked people lists.";
      } else {
        return "Quickly change year/month by clicking on the month name.";
      }
    },
    queryParams() {
      if (this.creatingIndirectActiveDeal) {
        return { query: { active: true, indirect: true } };
      } else if (this.creatingActiveDeal) {
        return { query: { active: true } };
      } else {
        return {};
      }
    }
  },
  mounted() {
    if (this.creatingDeal && !this.dealCreationInProcess) {
      this.$router.push({ path: "/deals/new/1" });
    } else if (this.shouldFetchDuplicates) {
      this.$store.dispatch("fetchPotentialDuplicateDeals");
    } else {
      this.$store.commit("setActiveDealDuplicates", []);
    }

    if ((this.closingFromMarketingList || this.transitioningToClosed) && this.activeDeal.closeDate === null) {
      this.$store.commit("setCloseDate", new Date());
    }
  },
  methods: {
    confirmPrivacy() {
      Swal.fire(anonymousPostOptions).then(result => {
        if (result.value) {
          this.next();
        }
      });
    },
    refetchDuplicates() {
      if (this.shouldFetchDuplicates) {
        this.$store.commit("clearActiveDealDuplicates");
        this.$store.dispatch("fetchPotentialDuplicateDeals");
      }
    },
    setUnderAgreement() {
      this.$store.commit("setDealState", "awarded");
      this.refetchDuplicates();
    },
    setClosed() {
      this.$store.commit("setDealState", "closed");
    },
    safezone() {
      if (!this.underAgreement) {
        this.setClosed();
      }

      this.$store.dispatch("createDeal");
    },
    reviewDuplicates() {
      if (!this.underAgreement) {
        this.setClosed();
      }

      this.$router.push(
        _.merge({ path: "/deals/new/dedupe" }, this.queryParams)
      );
    },
    next(confidential = false) {
      if (this.updatingDateOnly) {
        this.$store.dispatch("updateDeal", {
          dealId: this.dealId,
          updateTags: false
        });
        this.$store.commit("closeModal");
      } else {
        if (confidential) {
          this.$store.commit("toggleNewDealConfidentiality");
        }

        if (this.closingFromMarketingList || this.transitioningToClosed) {
          if (!!analytics && !this.adminMode) {
            analytics.track("Closed Marketed Deal", {
              yearWeek: this.$store.state.yearWeek
            });
          }
          this.setClosed();
          this.$store.dispatch("updateDeal", {
            dealId: this.dealId,
            updateTags: false
          });
          this.$store.dispatch("cancelClosedDealInvitations");

          if (this.closingFromMarketingList) {
            this.$store.commit("openModal", {
              component: AddDealParticipants,
              props: { dealId: this.dealId }
            });
          } else {
            this.$store.commit("closeModal");
          }
        } else {
          if (!this.underAgreement) {
            this.setClosed();
          }
          this.$store.dispatch("createDeal");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
}
button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.privacy-complete {
    background: #d61f69;
    box-shadow: rgba(214, 31, 105, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #f17eb8;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
  &.calendar-button {
    width: 256px !important;
  }
}
</style>
