<template>
  <tr>
    <td class="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
      <div class="flex items-center space-x-3 lg:pl-2">
        <div v-tooltip="statusHelp" :class="`flex-shrink-0 w-2.5 h-2.5 rounded-full bg-${statusColor}`"></div>
        <router-link :to="`intents/${intent.id}`" class="truncate hover:text-gray-600">
          <span>{{ reference }} <span v-if="content" class="text-gray-500 font-normal">to {{ content.name }}</span> </span>
        </router-link>
      </div>
    </td>
    <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500 font-medium">
      <template v-if="intent.launchRequested">
        Launch Requested
      </template>
      <template v-else>
        {{ intent.state | capitalize }}
      </template>
    </td>
    <td class="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500 text-right">
      {{ intent.updatedAt | moment("MMMM D, YYYY") }}
    </td>
    <td class="pr-6">
      <div ref="intentMenu" class="relative flex justify-end items-center">
        <button @click="toggleMenu" id="intent-options-menu-0" aria-has-popup="true" type="button" class="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100">
          <!-- Heroicon name: dots-vertical -->
          <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
          </svg>
        </button>
        <transition
          enter-active-class="duration-100 ease-out"
          enter-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="duration-75 ease-in"
          leave-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
        >
          <div v-show="menuOpen" class="mx-3 origin-top-right absolute right-7 top-0 w-48 mt-1 rounded-md shadow-lg">
            <div class="z-10 rounded-md bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="project-options-menu-0">
              <div class="py-1">
                <router-link :to="`intents/${intent.id}`" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                  <!-- Heroicon name: collection -->
                  <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z" />
                  </svg>
                  Manage
                </router-link>
              </div>
              <div class="border-t border-gray-100"></div>
              <div class="py-1">
                <a href="" @click.prevent="softDelete" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                  <!-- Heroicon name: trash -->
                  <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                  Delete
                </a>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </td>
  </tr>
</template>

<script>
import api from "../../../api";

export default {
  props: ["intent"],
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    content() {
      return _.get(this.intent, "content.content", null) || this.intent.content;
    },
    reference() {
      if (this.content) {
        return "Related";
      } else {
        return "Standalone";
      }
    },
    statusColor() {
      if (this.intent.complete) {
        return "green-400";
      } else {
        return "pink-600";
      }
    },
    statusHelp() {
      if (this.intent.complete) {
        return "All premium content is complete.";
      } else {
        return "Some premium content is incomplete.";
      }
    }
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.intentMenu &&
        !this.$refs.intentMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    softDelete() {
      api.delete(`purchaseable_intents/${this.intent.id}`).then(json => {
        this.$store.dispatch("flash", "Source material archived");
        this.$emit("refetch");
      });
    }
  }
};
</script>
