<template>
  <div>
    <div v-if="statusCheckComplete">
      <component v-if="chargesEnabled && destinationComponent" :is="destinationComponent" v-bind="destinationProps" />
      <div v-else class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Setup Personal Payouts
        </h3>
        <div class="mt-2 sm:flex sm:items-start sm:justify-between">
          <div class="max-w-xl text-sm leading-5 text-gray-500">
            <p>
              First, let's set you up to earn on Tower Hunt. This one-time onboarding process allows you to receive payouts to a personal account. Once complete, you'll be able to sell premium insights and fulfill bounties across the platform.
            </p>
          </div>
          <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
            <span class="inline-flex rounded-md shadow-sm">
              <a :href="onboardingUrl" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
                Let's go
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="px-4 py-5 sm:p-6">
      <div class="flex justify-center">
        <grid-loader :loading="!statusCheckComplete" size="10px" color="#5850ec" />
      </div>
    </div>
  </div>
</template>

<script>
import GridLoader from "vue-spinner/src/GridLoader.vue";
import api from "../api";

export default {
  components: { GridLoader },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      chargesEnabled: false,
      onboardingUrl: null,
      statusCheckComplete: false
    };
  },
  computed: {
    destinationComponent() {
      return _.get(this.value, "component", null);
    },
    destinationProps() {
      return _.get(this.value, "props", {});
    }
  },
  mounted() {
    this.checkConnectedAccount();
    // TODO: Handle refresh_url
  },
  methods: {
    checkConnectedAccount() {
      api
        .post(`payment_connected_accounts`, {
          returnUrl: this.destinationProps.returnUrl
        })
        .then(json => {
          this.chargesEnabled = json.data.chargesEnabled;
          this.onboardingUrl = json.data.url;
          this.statusCheckComplete = true;

          if (
            _.get(this.destinationProps, "closeOnSuccess", false) &&
            this.chargesEnabled
          ) {
            this.$store.commit(
              "setTemporaryAccess",
              this.destinationProps.afterClosePayload
            );
            this.$store.commit("closeModal");
          }
        });
    }
  }
};
</script>
