<template>
  <div class="container mx-auto md:mt-12 mb-4 px-4 sm:px-8 lg:px-10 flex flex-col items-center">
    <div class="text-center md:mb-2">
      <h2 class="text-xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Register for {{ activeDeal.name }}
      </h2>
      <p class="mt-2 sm:mt-4 text-md sm:text-xl font-medium text-gray-900">
        <span>Please review and accept the Confidentiality Agreement.</span>
      </p>
    </div>

    <div v-if="activeDealMarketing.staticConfidentialityAgreement" :style="fileHeight" class="mt-4 mb-2 h-full w-auto flex flex-col flex-1">
      <div class="iframely-embed flex-1 mb-2">
        <a data-iframely-url :href="activeDealMarketing.staticConfidentialityAgreement"></a>
      </div>
      <div class="w-full flex justify-center space-x-4">
        <a :href="activeDealMarketing.staticConfidentialityAgreement" target="_blank" class="inline-flex items-center justify-center font-medium text-sm text-gray-700">
          <!-- Heroicon name: download -->
          <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd" />
          </svg>
          <span class="ml-2">Download PDF version</span>
        </a>
        <a v-if="activeDealMarketing.editableConfidentialityAgreement" :href="activeDealMarketing.editableConfidentialityAgreement" target="_blank" class="inline-flex items-center justify-center font-medium text-sm text-gray-700">
          <!-- Heroicon name: download -->
          <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd" />
          </svg>
          <span class="ml-2">Download Word version</span>
        </a>
      </div>
    </div>

    <div v-else :style="fileHeight" class="mt-4 mb-2 h-full w-auto bg-gray-50 rounded-lg overflow-y-auto">
      <div class="px-4 py-5 sm:p-6">
        <!-- Content goes here -->
        <default-confidentiality class="markdown" />
      </div>
    </div>

    <div class="mt-2 w-full inline-flex items-center justify-center font-medium text-sm text-gray-700">
      <!-- Heroicon name: download -->
      <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
      </svg>
      <span class="ml-2">Please direct CA questions and mark-ups to: {{ activeDealMarketing.customConfidentialityAgreementEmail || activeDealMarketing.defaultConfidentialityAgreementEmail }}</span>
    </div>

    <div class="w-full mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <button @click="accept" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm">
        Accept
      </button>
      <button @click="cancel" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DefaultConfidentiality from "../../components/default-confidentiality.vue";
/* global iframely */

export default {
  components: { DefaultConfidentiality },
  data() {
    return {
      modalHeight: null
    };
  },
  computed: {
    ...mapState([
      "currentUser",
      "properties",
      "activeDeal",
      "activeDealMarketing",
      "modal",
      "adminMode"
    ]),
    ...mapGetters(["signedIn", "dealProspectInviteParam"]),
    fileHeight() {
      if (this.modalHeight) {
        const heightRatio = this.modalHeight > 400 ? 0.7 : 0.5;

        return `height: ${this.modalHeight * heightRatio}px;`;
      } else {
        return "height: 200px";
      }
    }
  },
  mounted() {
    this.modalHeight = document.getElementById("xl-modal").offsetHeight;

    this.debouncedHeightUpdate = _.debounce(() => {
      this.modalHeight = document.getElementById("xl-modal").offsetHeight;
    }, 100);

    window.addEventListener("resize", this.debouncedHeightUpdate);

    if (window.iframely) {
      setTimeout(() => {
        iframely.load();
      }, 500);

      var self = this;

      iframely.on("cancel", function(url, parentNode) {
        parentNode.remove();
        // self.$store.commit("closeXLModal");
        // self.$store.dispatch(
        //   "flash",
        //   "File is temporarily unavailable. Please try again shortly."
        // );
      });
    }
  },
  beforedestroy() {
    window.removeEventListener("resize", this.debouncedHeightUpdate);
  },
  methods: {
    cancel() {
      this.$store.commit("closeXLModal");
    },
    accept() {
      if (this.activeDealMarketing.investorPreview) {
        this.cancel();
      } else {
        this.$store.dispatch("registerDeal");
      }
    }
  }
};
</script>
