<template>
  <div class="wrapper">
    <heading>How much and what kind(s) of space?</heading>
    <form @submit.prevent novalidate>
      <section class="uses">
        <div class="input-container">
          <input
            v-focus
            v-model="office"
            @focus="focus('office')"
            @blur="blur"
            type="number"
            min="0"
            step="1"
            placeholder=""
            tabindex="1"
            name="office"
            class="input-text black"
            :class="{ active: focused === 'office' && !privacyMode, 'privacy-active': focused === 'office' && privacyMode }"
          >
          <label class="input-label" :class="{ active: focused === 'office' && !privacyMode, 'privacy-active': focused === 'office' && privacyMode }">Office</label>
          <label class="sf-label" :class="{ active: focused === 'office' && !privacyMode, 'privacy-active': focused === 'office' && privacyMode }">SF</label>
        </div>
        <div class="input-container">
          <input
            v-model="retail"
            @focus="focus('retail')"
            @blur="blur"
            type="number"
            min="1"
            step="1"
            placeholder=""
            tabindex="2"
            name="retail"
            class="input-text black"
            :class="{ active: focused === 'retail' && !privacyMode, 'privacy-active': focused === 'retail' && privacyMode }"
          >
          <label class="input-label" :class="{ active: focused === 'retail' && !privacyMode, 'privacy-active': focused === 'retail' && privacyMode }">Retail</label>
          <label class="sf-label" :class="{ active: focused === 'retail' && !privacyMode, 'privacy-active': focused === 'retail' && privacyMode }">SF</label>
        </div>
        <div class="input-container">
          <input
            v-model="industrial"
            @focus="focus('industrial')"
            @blur="blur"
            type="number"
            min="0"
            step="1"
            placeholder=""
            tabindex="3"
            name="industrial"
            class="input-text black"
            :class="{ active: focused === 'industrial' && !privacyMode, 'privacy-active': focused === 'industrial' && privacyMode }"
          >
          <label class="input-label" :class="{ active: focused === 'industrial' && !privacyMode, 'privacy-active': focused === 'industrial' && privacyMode }">Industrial</label>
          <label class="sf-label" :class="{ active: focused === 'industrial' && !privacyMode, 'privacy-active': focused === 'industrial' && privacyMode }">SF</label>
        </div>
        <div class="input-container">
          <input
            v-model="lab"
            @focus="focus('lab')"
            @blur="blur"
            type="number"
            min="1"
            step="1"
            placeholder=""
            tabindex="4"
            name="lab"
            class="input-text black"
            :class="{ active: focused === 'lab' && !privacyMode, 'privacy-active': focused === 'lab' && privacyMode }"
          >
          <label class="input-label" :class="{ active: focused === 'lab' && !privacyMode, 'privacy-active': focused === 'lab' && privacyMode }">Life Sciences</label>
          <label class="sf-label" :class="{ active: focused === 'lab' && !privacyMode, 'privacy-active': focused === 'lab' && privacyMode }">SF</label>
        </div>
        <div class="input-container">
          <input
            v-model="healthCare"
            @focus="focus('healthCare')"
            @blur="blur"
            type="number"
            min="0"
            step="1"
            placeholder=""
            tabindex="5"
            name="healthCare"
            class="input-text black"
            :class="{ active: focused === 'healthCare' && !privacyMode, 'privacy-active': focused === 'healthCare' && privacyMode }"
          >
          <label class="input-label" :class="{ active: focused === 'healthCare' && !privacyMode, 'privacy-active': focused === 'healthCare' && privacyMode }">Healthcare</label>
          <label class="sf-label" :class="{ active: focused === 'healthCare' && !privacyMode, 'privacy-active': focused === 'healthCare' && privacyMode }">SF</label>
        </div>
        <div class="input-container">
          <input
            v-model="dataCenter"
            @focus="focus('dataCenter')"
            @blur="blur"
            type="number"
            min="1"
            step="1"
            placeholder=""
            tabindex="6"
            name="dataCenter"
            class="input-text black"
            :class="{ active: focused === 'dataCenter' && !privacyMode, 'privacy-active': focused === 'dataCenter' && privacyMode }"
          >
          <label class="input-label" :class="{ active: focused === 'dataCenter' && !privacyMode, 'privacy-active': focused === 'dataCenter' && privacyMode }">Data Center</label>
          <label class="sf-label" :class="{ active: focused === 'dataCenter' && !privacyMode, 'privacy-active': focused === 'dataCenter' && privacyMode }">SF</label>
        </div>
      </section>
      <option-description v-if="sizeSummary" :description="sizeSummary" style="margin-top: 20px;" />
      <button v-if="postToken" @click="confirmPrivacy" type="button" class="cta" :class="{ complete: intelHasSize && !privacyMode, 'privacy-complete': intelHasSize && privacyMode }" tabindex="999">
        <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
          <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
        </svg>
        Confirm privacy and edit
      </button>
      <button v-else @click="next" type="submit" class="cta" :class="{ complete: intelHasSize && !privacyMode, 'privacy-complete': intelHasSize && privacyMode }" tabindex="999">
        <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
          <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
        </svg>
        Next
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../../components/onboarding/heading";
import OptionDescription from "../../../components/onboarding/option-description";
import Swal from "sweetalert2";
import anonymousPostUpdateOptions from "../../../sweet-alert-always-anonymous-update-options";

export default {
  components: { Heading, OptionDescription },
  props: ["postToken"],
  data() {
    return {
      focused: false
    };
  },
  computed: {
    ...mapState(["activeIntel", "intelCreationInProcess"]),
    ...mapGetters(["creatingIntel", "intelHasSize", "privacyMode"]),
    office: {
      get() {
        return this.activeIntel.office;
      },
      set(size) {
        this.$store.commit("setIntelLeaseSize", { use: "office", size });
      }
    },
    retail: {
      get() {
        return this.activeIntel.retail;
      },
      set(size) {
        this.$store.commit("setIntelLeaseSize", { use: "retail", size });
      }
    },
    industrial: {
      get() {
        return this.activeIntel.industrial;
      },
      set(size) {
        this.$store.commit("setIntelLeaseSize", { use: "industrial", size });
      }
    },
    lab: {
      get() {
        return this.activeIntel.lab;
      },
      set(size) {
        this.$store.commit("setIntelLeaseSize", { use: "lab", size });
      }
    },
    healthCare: {
      get() {
        return this.activeIntel.healthCare;
      },
      set(size) {
        this.$store.commit("setIntelLeaseSize", { use: "healthCare", size });
      }
    },
    dataCenter: {
      get() {
        return this.activeIntel.dataCenter;
      },
      set(size) {
        this.$store.commit("setIntelLeaseSize", { use: "dataCenter", size });
      }
    },
    sizeSummary() {
      const sum = this.$options.filters.leaseSizeSum(this.activeIntel);

      if (sum === 0) {
        return null;
      } else {
        return `${sum} SF Total`;
      }
    }
  },
  mounted() {
    if (this.creatingIntel && !this.intelCreationInProcess) {
      this.$router.push({ path: "/intel/new/1" });
    }
  },
  methods: {
    focus(field) {
      this.focused = field;
    },
    blur() {
      this.focused = false;
    },
    deriveUsesFromProperties() {
      this.$store.commit("clearActiveIntelUses");

      const {
        office,
        retail,
        industrial,
        lab,
        healthCare,
        dataCenter
      } = this.activeIntel;

      if (office && office > 0) {
        this.$store.commit("addUseToSelection", { type: "Office" });
      }
      if (retail && retail > 0) {
        this.$store.commit("addUseToSelection", { type: "Retail" });
      }
      if (industrial && industrial > 0) {
        this.$store.commit("addUseToSelection", { type: "Industrial" });
      }
      if (lab && lab > 0) {
        this.$store.commit("addUseToSelection", { type: "Lab" });
      }
      if (healthCare && healthCare > 0) {
        this.$store.commit("addUseToSelection", { type: "Healthcare" });
      }
      if (dataCenter && dataCenter > 0) {
        this.$store.commit("addUseToSelection", { type: "Data Center" });
      }
    },
    confirmPrivacy() {
      if (this.intelHasSize && this.postToken) {
        Swal.fire(anonymousPostUpdateOptions).then(result => {
          if (result.value) {
            this.deriveUsesFromProperties();

            this.$store.dispatch("updateIntel", {
              postToken: this.postToken,
              updateTags: true
            });

            this.$store.commit("closeModal");
            this.$store.commit("clearIntel");
          }
        });
      }
    },
    next() {
      if (this.intelHasSize) {
        this.deriveUsesFromProperties();

        this.$router.push({ path: "/intel/new/11" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  display: flex;
  flex-direction: column;
  width: 330px;
}
.uses {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
input {
  vertical-align: middle;
}
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 26px 30px 8px 15px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 20px;
  line-height: 18.2px;
  &.privacy-active {
    border: 2px solid #d61f69;
  }
  &.active {
    border: 2px solid #5850ec;
  }
}
.input-label {
  position: absolute;
  top: 8px;
  left: 16px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.privacy-active {
    color: #d61f69;
  }
  &.active {
    color: #5850ec;
  }
}
.sf-label {
  position: absolute;
  top: 31px;
  right: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.privacy-active {
    color: #d61f69;
  }
  &.active {
    color: #5850ec;
  }
}
button.cta {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.privacy-complete {
    background: #d61f69;
    box-shadow: rgba(214, 31, 105, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #f17eb8;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
