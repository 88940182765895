export default [
  {
    order: 1,
    name: "Principal",
    value: "principal",
    description:
      "You are in the deal as owner/landlord, tenant, borrower, and/or lender.",
    icon: "fas fa-hand-holding-usd"
  },
  {
    order: 2,
    name: "Broker",
    value: "broker",
    description: "You facilitate deals on behalf of principals.",
    icon: "fas fa-handshake"
  },
  {
    order: 3,
    name: "Other",
    value: "observer",
    description:
      "Anyone else interested in CRE but not actively making deals, such as service providers, vendors, and journalists.",
    icon: "fas fa-users"
  }
];
