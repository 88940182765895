<template>
  <div>
    <div class="pt-6 pb-2">
      <h3 class="mb-2 text-lg leading-6 font-medium text-gray-900">
        Status
      </h3>
      <bounty-requester-progress class="mb-2" color="indigo" :state="progressState" />
      <!--
        Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
        Read the documentation to get started: https://tailwindui.com/documentation
      -->
      <div class="rounded-md bg-indigo-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: information-circle -->
            <svg class="h-5 w-5 text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm leading-5 text-indigo-700">
              You are anonymous throughout this process. You may hire multiple bidders.
            </p>
            <p class="mt-3 text-sm leading-5 md:mt-0 md:ml-6">
              <router-link to="/product/bounties" target="_blank" class="whitespace-nowrap font-medium text-indigo-700 hover:text-indigo-600">
                Learn more &rarr;
              </router-link>
            </p>
          </div>
        </div>
      </div>

      <h3 class="mt-6 text-lg leading-6 font-medium text-gray-900">
        Bids
      </h3>
      <ul v-if="bids.length > 0" class="divide-y divide-gray-200">
        <li v-for="bid in bids" :key="bid.id" class="py-4">
          <bid-detail :bounty="bounty" :bid="bid" />
        </li>
      </ul>
      <div v-else>
        <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div class="text-center">
            <h1 class="text-base leading-6 font-semibold text-indigo-600 tracking-wide uppercase">
              <template>Bids</template>
            </h1>
            <p class="mt-1 text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
              <template>Local experts who can help</template>
            </p>
            <p class="max-w-xl mt-5 mx-auto text-xl leading-7 text-gray-500">
              <template>Bids, bidder reputation info, and submitted deliverables will display here.</template>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BidDetail from "../bid-detail";
import BountyRequesterProgress from "../bounty-requester-progress";
import api from "../../../api";

export default {
  components: {
    BidDetail,
    BountyRequesterProgress
  },
  props: ["bounty"],
  data() {
    return {
      bids: []
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState(["adminMode"]),
    progressState() {
      switch (this.bounty.state) {
        case "live":
          return "hiring";
        case "awarded":
          return "hired";
        case "fulfilled":
        default:
          return "completed";
      }
    }
  },
  mounted() {
    api.get(`bounties/${this.bounty.id}/bounty_bids`).then(json => {
      this.bids = json.data;
    });
  }
};
</script>
