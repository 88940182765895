<template>
  <div ref="teamMemberMenu" class="flex-shrink-0 pr-2">
    <button @click="toggleMenu" type="button" class="w-8 h-8 bg-gray-100 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      <span class="sr-only">Open options</span>
      <!-- Heroicon name: solid/dots-vertical -->
      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
      </svg>
    </button>

    <transition
      enter-active-class="duration-100 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-75 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-show="menuOpen" class="z-10 mx-3 origin-top-right absolute right-10 top-3 w-48 mt-1 rounded-md shadow-lg bg-gray-100 ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="pinned-project-options-menu-0-button" tabindex="-1">
        <div v-if="member" class="py-1" role="none">
          <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
          <a v-if="canRemove" href="" @click.prevent="removeMember" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-900" role="menuitem" tabindex="-1">Remove</a>
        </div>
        <template v-else-if="invitation">
          <div class="py-1" role="none">
            <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
            <a href="" @click.prevent="resendInvitation" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-900" role="menuitem" tabindex="-1">Resend</a>
          </div>
          <div class="py-1" role="none">
            <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
            <a href="" @click.prevent="revokeInvitation" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-900" role="menuitem" tabindex="-1">Revoke</a>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import api from "../api";

export default {
  props: ["invitation", "member", "role", "propertyFollowing"],
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState(["modal"]),
    canRemove() {
      return (
        _.get(this.propertyFollowing, "authored", false) ||
        _.get(this, "role", null) === "Primary owner"
      );
    }
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.teamMemberMenu &&
        !this.$refs.teamMemberMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    removeMember() {
      const endpoint = this.propertyFollowing
        ? `property_followings/${this.member.propertyFollowingId}/property_following_memberships/${this.member.memberId}`
        : `teams/${this.member.teamId}/team_memberships/${this.member.memberId}`;

      api.delete(endpoint).then(json => {
        this.$store.dispatch("flash", `${this.member.name} removed successfully.`);
        this.$emit("refetch-members");
      });
      this.menuOpen = false;
    },
    resendInvitation() {
      const endpoint = this.propertyFollowing
        ? `property_following_invitations`
        : `team_invitations`;

      api.post(`${endpoint}/${this.invitation.token}`).then(json => {
        this.$store.dispatch("flash", `Invitation resent`);
      });

      this.menuOpen = false;
    },
    revokeInvitation() {
      const endpoint = this.propertyFollowing
        ? `property_following_invitations`
        : `team_invitations`;

      api.delete(`${endpoint}/${this.invitation.token}`).then(json => {
        this.$store.dispatch("flash", `Invitation revoked`);
        this.$emit("refetch-invitations");
      });

      this.menuOpen = false;
    }
  }
};
</script>
