<template>
  <div>
    <div v-if="!loaded" class="mt-3 sm:mt-4 flex justify-center">
      <grid-loader :loading="true" size="8px" color="#D4D4D8" />
    </div>
    <div v-else-if="recentChannels.length > 0" class="pl-4 py-3 sm:pl-6 sm:py-4 border-b border-gray-200 overflow-x-auto">
      <ul class="flex space-x-6">
        <a v-for="channel in recentChannels" :key="channel.token" href="" @click.prevent="shareWith(channel)" v-tooltip="channelTooltip(channel)" class="w-12 flex-shrink-0 space-y-1 flex flex-col items-center">
          <span class="inline-block relative">
            <avatar-photo v-if="channel.propertyFollowingName" :property-name="channel.propertyFollowingName" :property-avatar="channel.propertyFollowingAvatar" icon-size="6" circle-size="12" text-size="md" />
            <avatar-photo v-else :team-name="channel.teamName" :person="channel.members[0]" circle-size="12" text-size="md" />
            <watchlist-property-badge v-if="channel.propertyFollowingName" class="absolute bottom-0 right-0" />
            <team-badge v-else-if="channel.teamName" class="absolute bottom-0 right-0" />
            <backchannel-badge v-else class="absolute bottom-0 right-0" />
          </span>
          <p class="text-xs text-center">
            <span v-if="channel.members.length > 1" class="font-medium text-gray-900">{{ channel.members.length }} People</span>
            <span class="text-gray-500">{{ channel | channelName | truncate(25) }}</span>
          </p>
        </a>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AvatarPhoto from "../avatar-photo";
import BackchannelBadge from "../backchannel-badge";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import TeamBadge from "../team-badge";
import WatchlistPropertyBadge from "../watchlist-property-badge";
import api from "../../api";

export default {
  components: {
    AvatarPhoto,
    BackchannelBadge,
    GridLoader,
    TeamBadge,
    WatchlistPropertyBadge
  },
  props: ["content", "contentType"],
  data() {
    return {
      recentChannels: [],
      loaded: false
    };
  },
  computed: {
    ...mapState(["currentUser"])
  },
  mounted() {
    this.fetchRecentChannels();
  },
  methods: {
    fetchRecentChannels() {
      api
        .get(`my_recent_channels?content_type=${this.contentType}`)
        .then(json => {
          this.recentChannels = json.data.backchannels;
          var self = this;

          this.recentChannels.forEach(channel => {
            if (!channel.teamId) {
              _.remove(channel.members, function(member) {
                return member.token === self.currentUser.token;
              });
            }
          });

          this.loaded = true;
        });
    },
    shareWith(channel) {
      switch (this.contentType) {
        case "property":
        case "deal":
        case "intel":
        default:
          this.draftChannelMessageFor(channel);
      }
    },
    draftChannelMessageFor(channel) {
      if (channel.propertyFollowingId && channel.propertyFollowingName) {
        this.$store.commit("addPropertyToNewBackchannel", {
          id: channel.propertyFollowingId,
          name: channel.propertyFollowingName
        });
      } else if (channel.teamId && channel.teamName) {
        this.$store.commit("addTeamToNewBackchannel", {
          id: channel.teamId,
          name: channel.teamName
        });
      } else {
        channel.members.forEach(member => {
          this.$store.commit("addContactToNewBackchannel", member);
        });
      }

      this.$router.push({
        path: "/backchannel/new",
        query: {
          content: this.content.token || this.content.id,
          type: this.contentType
        }
      });
      this.$store.commit("clearModal");
    },
    channelTooltip(channel) {
      if (channel.members.length > 1) {
        return channel.members.map(user => this.userTooltip(user)).join(", ");
      } else {
        return "";
      }
    },
    userTooltip(user) {
      const name = this.$options.filters.capitalize(user.name);
      const role = _.capitalize(
        this.$options.filters.industryRoleAlias(
          user.industryRole,
          user.industrySubRole,
          user.token
        )
      );

      if (user.company) {
        return `${name} (${role} at ${user.company})`;
      } else {
        return `${name} (${role})`;
      }
    }
  }
};
</script>
