<template>
  <div @click.stop="openPreviewCard" @mouseenter="highlightRecord" @mouseleave="unhighlightRecord" class="pl-2 pr-4 py-1 rounded-lg flex flex-col items-center text-xs cursor-pointer" :class="highlighted ? 'text-white' : 'text-gray-900'" :style="`background-color: ${dynamicFill};`">
    <div class="font-bold">
      <template v-if="deal.type === 'Lease'">{{ deal | totalLeaseSize }}</template>
      <template v-else-if="deal.totalValue">
        <template v-if="deal.goingInPerUnit">{{ deal | perUnitPrice }}</template>
      </template>
    </div>
    <div v-if="deal.totalValue" :class="highlighted ? '' : 'text-gray-700'">
      <template v-if="!deal.closeDate">~</template>{{ deal.totalValue | largeDollar }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["deal", "fillColor", "isContext", "marker"],
  data() {
    return {
      highlighted: false
    };
  },
  computed: {
    ...mapState(["highlightedMapRecord"]),
    dynamicFill() {
      if (this.highlighted) {
        return "#EC4899";
      } else {
        return this.fillColor;
      }
    }
  },
  watch: {
    highlightedMapRecord: {
      handler() {
        if (
          this.highlightedMapRecord.contentType === "deal" &&
          this.highlightedMapRecord.contentToken === this.deal.token
        ) {
          this.highlighted = true;
        } else {
          this.highlighted = false;
        }
      },
      deep: true
    }
  },
  methods: {
    highlightRecord() {
      if (this.isContext) {
        this.$store.commit("setHighlightedMapRecord", {
          contentType: "deal",
          contentToken: this.deal.token,
          mapTool: "strategicContext",
          touching: "popup"
        });
      }
    },
    unhighlightRecord() {
      if (this.isContext) {
        this.$store.commit("setHighlightedMapRecord", {
          contentType: null,
          contentToken: null,
          mapTool: null,
          touching: null
        });
      }
    },
    openPreviewCard() {
      this.marker.fire("click");
    }
  }
};
</script>
