<template>
  <div>
    <ul class="mt-4 w-full">
      <li v-for="region in regions" :key="region.id" class="flex justify-between py-1">
        <div class="flex flex-col text-left">
          <span class="text-sm font-medium leading-5 text-gray-700">{{ region.name }}</span>
          <router-link
            v-if="!readOnly"
            @click.native.stop="view(region)"
            :to="fullPath"
            :class="privacyMode ? 'text-pink-600 hover:text-pink-500' : 'text-indigo-600 hover:text-indigo-500'"
            class="text-xs"
          >
            Edit
          </router-link>
        </div>
        <span v-if="!readOnly" class="inline-flex rounded-md shadow-sm">
          <button @click="remove(region)" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
            Remove
          </button>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import RegionComponent from "../views/onboarding/onboarding-region";

export default {
  props: ["regions", "local", "readOnly"],
  computed: {
    ...mapState(["route"]),
    ...mapGetters(["privacyMode"]),
    fullPath() {
      return this.route.fullPath;
    }
  },
  methods: {
    view(region) {
      if (!this.readOnly) {
        setTimeout(() => {
          this.$store.commit("openModal", {
            component: RegionComponent,
            props: {
              regionId: region.id
            }
          });
        }, 200);
      }
    },
    remove(region) {
      if (!this.readOnly) {
        if (this.local) {
          this.$emit("remove");
        } else {
          this.$store.dispatch("removeRegionWithGetters", region);
          document.getElementById("region-search").focus();
        }
      }
    }
  }
};
</script>
