<template>
  <tr>
    <td class="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
      <div class="flex items-center space-x-3 lg:pl-2">
        <watchlist-color-dot :property="property" />
        <router-link :to="`/property-watchlist/${following.id}/`" class="truncate hover:text-gray-600">
          <span>{{ property.customName || property.name }}</span>
        </router-link>
      </div>
    </td>
    <td class="px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500 text-right">
      {{ property.cityState }}
    </td>
    <td class="hidden lg:table-cell px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500">
      <email-alert-toggle :following="following" />
    </td>
    <td class="hidden lg:table-cell px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500">
      <button @click="editProximity" type="button" v-tooltip="following.authored ? 'Edit alert proximity settings' : ''" :class="`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-${proximityColor}-100 text-${proximityColor}-800 hover:bg-${proximityColor}-50 hover:text-${proximityColor}-900 focus:outline-none focus:ring-${proximityColor} focus:border-${proximityColor}-700 active:bg-${proximityColor}-200`">
        <template v-if="following.proximityType === 'radius'">
          <svg :class="`-ml-0.5 mr-1.5 h-4 w-4 text-${proximityColor}-400`" fill="currentColor" viewBox="0 0 512 512">
            <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z" />
          </svg>
        </template>
        <template v-else-if="following.proximityType === 'region'">
          <svg :class="`-ml-0.5 mr-1.5 h-4 w-4 text-${proximityColor}-400`" fill="currentColor" viewBox="0 0 448 512">
            <path fill="currentColor" d="M384 352c-.35 0-.67.1-1.02.1l-39.2-65.32c5.07-9.17 8.22-19.56 8.22-30.78s-3.14-21.61-8.22-30.78l39.2-65.32c.35.01.67.1 1.02.1 35.35 0 64-28.65 64-64s-28.65-64-64-64c-23.63 0-44.04 12.95-55.12 32H119.12C108.04 44.95 87.63 32 64 32 28.65 32 0 60.65 0 96c0 23.63 12.95 44.04 32 55.12v209.75C12.95 371.96 0 392.37 0 416c0 35.35 28.65 64 64 64 23.63 0 44.04-12.95 55.12-32h209.75c11.09 19.05 31.49 32 55.12 32 35.35 0 64-28.65 64-64 .01-35.35-28.64-64-63.99-64zm-288 8.88V151.12A63.825 63.825 0 0 0 119.12 128h208.36l-38.46 64.1c-.35-.01-.67-.1-1.02-.1-35.35 0-64 28.65-64 64s28.65 64 64 64c.35 0 .67-.1 1.02-.1l38.46 64.1H119.12A63.748 63.748 0 0 0 96 360.88zM272 256c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zM400 96c0 8.82-7.18 16-16 16s-16-7.18-16-16 7.18-16 16-16 16 7.18 16 16zM64 80c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zM48 416c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zm336 16c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16z" />
          </svg>
        </template>
        <template v-else-if="following.proximityType === 'competitiveSet'">
          <svg :class="`-ml-0.5 mr-1.5 h-4 w-4 text-${proximityColor}-400`" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clip-rule="evenodd" />
          </svg>
        </template>
        {{ proximityLabel }}
      </button>
    </td>
    <td class="hidden lg:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 font-medium">
      <div class="flex flex-shrink-0 items-center space-x-2">
        <button v-if="following.authored" @click="chooseDiscussion" type="button" class="mr-1 flex-shrink-0 bg-white inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span class="sr-only">Add team member</span>
          <!-- Heroicon name: solid/plus -->
          <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
          </svg>
        </button>

        <div class="flex flex-shrink-0 -space-x-1" style="width: 84px;">
          <avatar-photo v-for="user in slicedSharingUsers" :key="user.token" :person="user" v-tooltip="userTooltip(user)" border-class="ring-2 ring-white" circle-size="6" text-size="xs" />
        </div>

        <span v-if="sharingOverflow" v-tooltip="overflowTooltip" class="flex-shrink-0 ml-1 text-xs leading-5 font-medium">+{{ sharingOverflow }}</span>
      </div>
    </td>
    <td class="pr-6">
      <watchlist-menu :property="property" :following="following" />
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AlertProximity from "./watchlist-alert-proximity.vue";
import AvatarPhoto from "../avatar-photo";
import DiscussionSelector from "../discussion-selector";
import EmailAlertToggle from "./email-alert-toggle.vue";
import WatchlistColorDot from "../watchlist-color-dot.vue";
import WatchlistMenu from "./watchlist-menu";

export default {
  components: {
    AvatarPhoto,
    WatchlistColorDot,
    WatchlistMenu,
    EmailAlertToggle
  },
  props: ["following", "sharing"],
  computed: {
    ...mapGetters(["isAdmin", "signedIn"]),
    ...mapState(["modal"]),
    property() {
      return this.following.property;
    },
    slicedSharingUsers() {
      return _.slice(this.sharing, 0, 4);
    },
    sharingOverflow() {
      const overflow = this.sharing.length - 4;

      return overflow > 0 ? overflow : null;
    },
    overflowTooltip() {
      const dropped = _.drop(this.sharing, 4);

      return dropped.map(user => this.userTooltip(user)).join(", ");
    },
    proximityLabel() {
      switch (this.following.proximityType) {
        case "radius":
          return "Radius";
        case "region":
          return "Region";
        case "competitiveSet":
          return "Competitive Set";
        default:
          return "Proximity";
      }
    },
    proximityColor() {
      switch (this.following.proximityType) {
        case "radius":
          return "light-blue";
        case "region":
          return "green";
        case "competitiveSet":
          return "orange";
        default:
          return "indigo";
      }
    }
  },
  methods: {
    userTooltip(user) {
      const name = this.$options.filters.capitalize(user.name);
      const role = _.capitalize(
        this.$options.filters.industryRoleAlias(
          user.industryRole,
          user.industrySubRole,
          user.token
        )
      );

      if (user.company) {
        return `${name} (${role} at ${user.company})`;
      } else {
        return `${name} (${role})`;
      }
    },
    chooseDiscussion() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      this.$store.commit("openModal", {
        component: DiscussionSelector,
        props: {
          content: this.following,
          contentType: "property"
        },
        afterClose: currentModal
      });
    },
    editProximity() {
      if (this.following.authored) {
        let currentModal = false;

        if (this.modal) {
          currentModal = _.cloneDeep(this.modal); // obtains the current component
        }

        this.$store.commit("openModal", {
          component: AlertProximity,
          props: {
            following: this.following
          },
          afterClose: currentModal
        });
      }
    }
  }
};
</script>
