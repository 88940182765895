<template>
  <section v-if="loaded" aria-labelledby="files-heading">
    <div class="shadow sm:rounded-md">
      <div :class="files.length > 0 || folders.length > 0 ? 'mb-12' : ''" class="bg-white py-6 px-4 sm:p-6">
        <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div class="ml-4 mt-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Files
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              Privately access and share your {{ contentType }} files.
            </p>
          </div>
          <div v-if="files.length > 0 || folders.length > 0" class="ml-4 mt-4 flex-shrink-0">
            <file-storage-host-dropdown :google-auth-loaded="googleAuthApiLoaded" @paste-url="pasteUrl" @local-upload="localUpload" @dropbox-chooser="dropboxChooser" @google-drive-chooser="googleDriveChooser" @one-drive-share-point-chooser="oneDriveSharePointChooser" />
          </div>
        </div>

        <!-- This example requires Tailwind CSS v2.0+ -->
        <div v-if="files.length > 0" class="mt-6">
          <h2 class="text-gray-900 text-xs font-medium uppercase tracking-wide">Individual Files</h2>
          <ul class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2">
            <li v-for="file in files" :key="file.id" class="relative col-span-1 flex shadow-sm rounded-md">
              <div @click="viewEmbed(file)" class="flex-shrink-0 flex items-center justify-center w-16 bg-gray-200 text-white text-sm rounded-l-md cursor-pointer">
                <svg v-if="file.hostName === 'dropbox'" v-tooltip="'View with Dropbox'" class="h-4 w-4 text-gray-800" fill="currentColor" viewBox="0 0 528 512">
                  <path d="M264.4 116.3l-132 84.3 132 84.3-132 84.3L0 284.1l132.3-84.3L0 116.3 132.3 32l132.1 84.3zM131.6 395.7l132-84.3 132 84.3-132 84.3-132-84.3zm132.8-111.6l132-84.3-132-83.6L395.7 32 528 116.3l-132.3 84.3L528 284.8l-132.3 84.3-131.3-85z" />
                </svg>
                <svg v-else-if="file.hostName === 'google'" v-tooltip="'View with Google Drive'" class="h-4 w-4 text-gray-800" fill="currentColor" viewBox="0 0 512 512">
                  <path d="M339 314.9L175.4 32h161.2l163.6 282.9H339zm-137.5 23.6L120.9 480h310.5L512 338.5H201.5zM154.1 67.4L0 338.5 80.6 480 237 208.8 154.1 67.4z" />
                </svg>
                <svg v-else-if="file.hostName === 'microsoft'" v-tooltip="'View with OneDrive or SharePoint'" class="h-4 w-4 text-gray-800" fill="currentColor" viewBox="0 0 448 512">
                  <path d="M0 32h214.6v214.6H0V32zm233.4 0H448v214.6H233.4V32zM0 265.4h214.6V480H0V265.4zm233.4 0H448V480H233.4V265.4z" />
                </svg>
                <svg v-else-if="file.hostName === 'tower_hunt'" v-tooltip="'View uploaded file'" class="h-4 w-4 text-gray-800" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                </svg>
                <svg v-else-if="file.hostName === 'url'" v-tooltip="'View linked file'" class="h-4 w-4 text-gray-800" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                <div class="flex-1 px-4 py-2 text-sm truncate">
                  <a href="" @click.prevent="viewEmbed(file)" v-tooltip="file.name" class="text-gray-900 font-medium hover:text-gray-600">{{ file.name | gutMiddle(20) }}</a>
                  <p class="flex items-center text-gray-500">
                    <span>File</span>
                    <span v-if="file.sharedBy" class="ml-3 flex flex-shrink-0">
                      <avatar-photo :person="file.sharedBy" v-tooltip="userTooltip(file.sharedBy)" border-class="ring-2 ring-gray-300" circle-size="6" text-size="xs" />
                    </span>
                  </p>
                </div>
                <safezone-file-menu :file="file" :content="content" :content-type="contentType" @remove="remove(file)" />
              </div>
            </li>
          </ul>
        </div>

        <div v-if="folders.length > 0" class="mt-6">
          <h2 class="text-gray-900 text-xs font-medium uppercase tracking-wide">Folders</h2>
          <ul v-if="supported" class="mt-3 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6">
            <div v-for="folder in folders" :key="folder.id" class="flex flex-col text-center sm:col-span-2">
              <li :id="folder.id" class="h-96 bg-gray-100 relative">
                <iframe v-if="useIframe(folder)" :src="folder.link" class="h-full w-full rounded-md border border-gray-200" />
              </li>
              <div class="relative mt-1 flex justify-between items-center">
                <p class="mt-2 flex items-center block text-sm font-medium text-gray-900">
                  <span v-tooltip="folder.name">{{ folder.name | gutMiddle(20) }}</span>
                  <span v-if="folder.sharedBy" class="ml-3 flex flex-shrink-0">
                    <avatar-photo :person="folder.sharedBy" v-tooltip="userTooltip(folder.sharedBy)" border-class="ring-2 ring-gray-300" circle-size="6" text-size="xs" />
                  </span>
                </p>
                <safezone-file-menu :file="folder" :content="content" :content-type="contentType" @remove="remove(folder)" />
              </div>
            </div>
          </ul>
          <ul v-else class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2">
            <li v-for="folder in folders" :key="folder.id" class="relative col-span-1 flex shadow-sm rounded-md">
              <div @click="viewEmbed(folder)" class="flex-shrink-0 flex items-center justify-center w-16 bg-gray-200 text-white text-sm rounded-l-md cursor-pointer">
                <svg v-if="folder.hostName === 'dropbox'" v-tooltip="'View with Dropbox'" class="h-4 w-4 text-gray-800" fill="currentColor" viewBox="0 0 528 512">
                  <path d="M264.4 116.3l-132 84.3 132 84.3-132 84.3L0 284.1l132.3-84.3L0 116.3 132.3 32l132.1 84.3zM131.6 395.7l132-84.3 132 84.3-132 84.3-132-84.3zm132.8-111.6l132-84.3-132-83.6L395.7 32 528 116.3l-132.3 84.3L528 284.8l-132.3 84.3-131.3-85z" />
                </svg>
                <svg v-else-if="folder.hostName === 'google'" v-tooltip="'View with Google Drive'" class="h-4 w-4 text-gray-800" fill="currentColor" viewBox="0 0 512 512">
                  <path d="M339 314.9L175.4 32h161.2l163.6 282.9H339zm-137.5 23.6L120.9 480h310.5L512 338.5H201.5zM154.1 67.4L0 338.5 80.6 480 237 208.8 154.1 67.4z" />
                </svg>
                <svg v-else-if="folder.hostName === 'microsoft'" v-tooltip="'View with OneDrive or SharePoint'" class="h-4 w-4 text-gray-800" fill="currentColor" viewBox="0 0 448 512">
                  <path d="M0 32h214.6v214.6H0V32zm233.4 0H448v214.6H233.4V32zM0 265.4h214.6V480H0V265.4zm233.4 0H448V480H233.4V265.4z" />
                </svg>
                <svg v-else-if="file.hostName === 'tower_hunt'" v-tooltip="'View uploaded file'" class="h-4 w-4 text-gray-800" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                </svg>
                <svg v-else-if="file.hostName === 'url'" v-tooltip="'View linked file'" class="h-4 w-4 text-gray-800" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                <div class="flex-1 px-4 py-2 text-sm truncate">
                  <a href="" @click.prevent="viewEmbed(folder)" v-tooltip="folder.name" class="text-gray-900 font-medium hover:text-gray-600">{{ folder.name | gutMiddle(20) }}</a>
                  <p class="flex items-center text-gray-500">
                    <span>Folder</span>
                    <span v-if="folder.sharedBy" class="ml-3 flex flex-shrink-0">
                      <avatar-photo :person="folder.sharedBy" v-tooltip="userTooltip(folder.sharedBy)" border-class="ring-2 ring-gray-300" circle-size="6" text-size="xs" />
                    </span>
                  </p>
                </div>
                <safezone-file-menu :file="folder" :content="content" :content-type="contentType" @remove="remove(folder)" />
              </div>
            </li>
          </ul>
        </div>

        <div v-if="files.length === 0 && folders.length === 0" class="mt-6 text-center">
          <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
          </svg>
          <h3 class="mt-2 text-sm font-medium text-gray-900">No files</h3>
          <template v-if="supported">
            <p class="mt-1 text-sm text-gray-500">
              Get started by choosing some folders and/or files.
            </p>
            <div class="mt-6">
              <file-storage-host-dropdown :google-auth-loaded="googleAuthApiLoaded" @paste-url="pasteUrl" @local-upload="localUpload" @dropbox-chooser="dropboxChooser" @google-drive-chooser="googleDriveChooser" @one-drive-share-point-chooser="oneDriveSharePointChooser" />
            </div>
          </template>
          <template v-else>
            <p class="mt-1 text-sm text-gray-500">
              We're unable to let you choose files on this browser.
            </p>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { all } from "../../../document-file-types";
import { mapState } from "vuex";
import AvatarPhoto from "../../avatar-photo.vue";
import DropboxEmbedViewer from "../../dropbox-embed-viewer.vue";
import FileDragDrop from "../../file-drag-drop.vue";
import FileStorageHostDropdown from "../../file-storage/file-storage-host-dropdown.vue";
import FileViewer from "../file-viewer.vue";
import GeneralUrl from "../../../views/onboarding/general-url.vue";
import IFrameEmbedViewer from "../../iframe-embed-viewer.vue";
import SafezoneFileMenu from "./safezone-file-menu.vue";
import api from "../../../api";
/* global Dropbox */
/* global gapi */
/* global google */
/* global OneDrive */
/* global process */

export default {
  components: { AvatarPhoto, FileStorageHostDropdown, SafezoneFileMenu },
  props: ["content", "contentType"],
  data() {
    return {
      loaded: false,
      supported: Dropbox.isBrowserSupported(),
      googleAuthApiLoaded: false,
      googlePickerApiLoaded: false,
      googleOAuthToken: null,
      filesEnabled: false,
      embeds: [],
      files: [],
      folders: []
    };
  },
  computed: {
    ...mapState(["modal", "temporaryAccess"]),
    redirectUrl() {
      const urlRoot =
        location.protocol +
        "//" +
        location.hostname +
        (location.port ? ":" + location.port : "");

      return `${urlRoot}/home/settings`;
    }
  },
  watch: {
    temporaryAccess: {
      handler() {
        this.retrieveFromTemporaryAccess();
      }
    }
  },
  mounted() {
    this.retrieveFromTemporaryAccess();
    this.loadGoogleApiPicker();
    this.fetchFileStatus();
    this.fetchFiles();
  },
  activated() {
    if (this.folders.length > 0 && !this.embed) {
      this.embedFolders();
    }
  },
  deactivated() {
    this.clearEmbeds();
  },
  beforeDestroy() {
    this.clearEmbeds();
  },
  methods: {
    retrieveFromTemporaryAccess() {
      if (_.get(this.temporaryAccess, "files", []).length > 0) {
        this.linkFilesToContent(this.temporaryAccess.files, "tower_hunt");
        this.$store.commit("clearTemporaryAccess");
      } else if (_.get(this.temporaryAccess, "safezoneFileUrl", null)) {
        this.linkFilesToContent(this.temporaryAccess.safezoneFileUrl, "url");
        this.$store.commit("clearTemporaryAccess");
      }
    },
    fetchFileStatus() {
      api.get("file_storage_status").then(json => {
        this.filesEnabled = json.data;
        this.loaded = true;
      });
    },
    fetchFiles() {
      api
        .get(
          `safezone_files/${this.contentType}/${this.content.token ||
            this.content.id}`
        )
        .then(json => {
          const { files, folders } = json.data;

          this.files = files;
          this.folders = folders;
          this.loaded = true;
          this.clearEmbeds();
          var self = this;

          setTimeout(() => {
            self.embedFolders();
          }, 50);
        });
    },
    loadGoogleApiPicker() {
      gapi.load("auth", { callback: this.onAuthApiLoad });
      gapi.load("picker", { callback: this.onPickerApiLoad });
    },
    onAuthApiLoad() {
      this.googleAuthApiLoaded = true;
    },
    onPickerApiLoad() {
      this.googlePickerApiLoaded = true;
    },
    googleAuth() {
      var scope = ["https://www.googleapis.com/auth/drive.readonly"];

      window.gapi.auth.authorize(
        {
          client_id: process.env.GOOGLE_CLIENT_ID,
          scope: scope,
          immediate: false
        },
        this.handleAuthResult
      );
    },
    handleAuthResult(authResult) {
      if (authResult && !authResult.error) {
        this.googleOAuthToken = authResult.access_token;
        this.createPicker();
      }
    },
    createPicker() {
      if (this.googlePickerApiLoaded && this.googleOAuthToken) {
        var docsView = new google.picker.DocsView()
          .setIncludeFolders(true)
          .setSelectFolderEnabled(true);

        var picker = new google.picker.PickerBuilder()
          .enableFeature(google.picker.Feature.NAV_HIDDEN)
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .setAppId(process.env.GOOGLE_APP_ID)
          .setOAuthToken(this.googleOAuthToken)
          .addView(docsView)
          .setDeveloperKey(process.env.GOOGLE_API_KEY)
          .setCallback(this.pickerCallback)
          .build();

        picker.setVisible(true);
      }
    },
    pickerCallback(data) {
      if (data.action == google.picker.Action.PICKED) {
        this.linkFilesToContent(data.docs, "google");
      }
    },
    setModalNav() {
      this.$store.commit("setModalNavigationState", {
        horizontalNav: "Safezone",
        verticalNav: "Files"
      });
    },
    pasteUrl() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      this.setModalNav();

      this.$store.commit("openModal", {
        component: GeneralUrl,
        props: { safezone: true },
        afterClose: currentModal
      });
    },
    localUpload() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      this.setModalNav();

      this.$store.commit("openModal", {
        component: FileDragDrop,
        props: {
          endpoint: null,
          localCommit: "setTemporaryAccess",
          fileTypes: "image/*," + all.join(","),
          fileTypeLabels: "XLS, PPT, DOC, PDF, PNG, JPG, GIF",
          sizeLimit: 100,
          multiple: true,
          heading: "Upload Safezone files",
          marginBottom: true,
          flashMessage: "Files saved successfully"
        },
        afterClose: currentModal
      });
    },
    useIframe(file) {
      return file.hostName === "google" || file.hostName === "microsoft";
    },
    oneDriveSharePointChooser() {
      var self = this;
      var odOptions = {
        clientId: process.env.AZURE_APP_ID,
        action: "share",
        multiSelect: true,
        viewType: "all",
        advanced: {
          redirectUri: self.redirectUrl,
          createLinkParameters: { type: "embed", scope: "anonymous" }
        },
        success: function(files) {
          self.linkFilesToContent(files, "microsoft");
        },
        cancel: function() {
          self.$store.dispatch("flash", "Nothing selected");
        },
        error: function(error) {
          console.log(error);
        }
      };

      OneDrive.open(odOptions);
    },
    googleDriveChooser() {
      this.googleAuth();
    },
    dropboxChooser() {
      var self = this;
      const options = {
        // Required. Called when a user selects an item in the Chooser.
        success: function(files) {
          self.linkFilesToContent(files, "dropbox");
        },

        // Optional. Called when the user closes the dialog without selecting a file
        // and does not include any parameters.
        cancel: function() {
          self.$store.dispatch("flash", "Nothing selected");
        },

        // Optional. "preview" (default) is a preview link to the document for sharing,
        // "direct" is an expiring link to download the contents of the file. For more
        // information about link types, see Link types below.
        linkType: "preview", // or "direct"

        // Optional. A value of false (default) limits selection to a single file, while
        // true enables multiple file selection.
        multiselect: true, // or true

        // Optional. A value of false (default) limits selection to files,
        // while true allows the user to select both folders and files.
        // You cannot specify `linkType: "direct"` when using `folderselect: true`.
        folderselect: true // or true
      };

      Dropbox.choose(options);
    },
    linkFilesToContent(files, hostName = "tower_hunt") {
      let typedFiles;

      switch (hostName) {
        case "dropbox":
          typedFiles = files.map(file => {
            return _.merge(file, { hostName });
          });
          break;
        case "google":
          typedFiles = files.map(file => {
            return {
              hostName,
              id: file.id,
              name: file.name,
              link: file.embedUrl,
              thumbnailLink: null,
              isDir: file.type === "folder"
            };
          });
          break;
        case "microsoft":
          typedFiles = files.value.map(file => {
            return {
              hostName,
              id: file.id,
              name: file.name,
              link: _.get(file, "permissions[0].link.webUrl", null),
              thumbnailLink: _.get(file, "thumbnails[0].medium.url", null),
              isDir: _.isNumber(_.get(file, "folder.childCount", false))
            };
          });
          break;
        case "tower_hunt":
          typedFiles = files.map(file => {
            return {
              hostName,
              id: file.signedId,
              name: file.name,
              link: null,
              thumbnailLink: null,
              isDir: false
            };
          });
          break;
        case "url":
          typedFiles = [
            {
              hostName,
              id: null,
              name: files,
              link: files,
              thumbnailLink: null,
              isDir: false
            }
          ];
          break;
        default:
          typedFiles = [];
      }

      api
        .post(
          `safezone_files/${this.contentType}/${this.content.token ||
            this.content.id}`,
          { files: typedFiles }
        )
        .then(json => {
          this.fetchFiles();
          this.$store.dispatch("flash", "Files linked successfully");
        });
    },
    viewEmbed(file) {
      let currentModal = false;
      let component;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      switch (file.hostName) {
        case "dropbox":
          component = DropboxEmbedViewer;
          break;
        case "google":
        case "microsoft":
          component = IFrameEmbedViewer;
          break;
        case "tower_hunt":
        default:
          component = FileViewer;
      }

      this.$store.commit("openXLModal", {
        component,
        props: { file, document: file },
        afterClose: currentModal,
        afterCloseDestination: "modal"
      });
    },
    embedFolders() {
      if (this.supported) {
        this.folders.forEach(folder => {
          if (folder.hostName === "dropbox") {
            const element = document.getElementById(folder.id);
            var options = {
              // Shared link to Dropbox file
              link: folder.link,
              file: {
                // Sets the zoom mode for embedded files. Defaults to 'best'.
                zoom: "best" // or "fit"
              },
              folder: {
                // Sets the view mode for embedded folders. Defaults to 'list'.
                view: "list", // or "grid"
                headerSize: "small" // or "small"
              }
            };

            if (element) {
              const embed = Dropbox.embed(options, element);

              this.embeds.push({ embed, id: folder.id });
            }
          }
        });
      }
    },
    clearEmbeds() {
      if (this.embeds.length > 0) {
        this.embeds.forEach(embed => {
          Dropbox.unmount(embed.embed);
        });
        this.embeds = [];
      }
    },
    remove(file) {
      var existingRecords = file.isDir ? this.folders : this.files;
      const newRecords = existingRecords.filter(f => {
        return f.id !== file.id;
      });
      const embedToRemove = _.find(this.embeds, { id: file.id });

      if (embedToRemove) {
        Dropbox.unmount(embedToRemove.embed);
      }

      if (file.isDir) {
        this.folders = newRecords;
      } else {
        this.files = newRecords;
      }
    },
    userTooltip(user) {
      const name = this.$options.filters.capitalize(user.name);
      const role = _.capitalize(
        this.$options.filters.industryRoleAlias(
          user.industryRole,
          user.industrySubRole,
          user.token
        )
      );

      if (user.company) {
        return `Shared by: ${name} (${role} at ${user.company})`;
      } else {
        return `Shared by: ${name} (${role})`;
      }
    }
  }
};
</script>
