<template>
  <div v-if="loaded" class="flex items-center">
    <button @click="toggleDropbox" type="button" :aria-checked="!!dropboxEnabled" :class="!!dropboxEnabled ? 'bg-indigo-600' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-7 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" role="switch">
      <span :class="!!dropboxEnabled ? 'translate-x-5' : 'translate-x-0'" class="pointer-events-none relative inline-block h-6 w-6 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200">
        <span :class="!!dropboxEnabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity" aria-hidden="true">
          <svg class="h-4 w-4 text-gray-400" fill="currentColor" viewBox="0 0 528 512">
            <path d="M264.4 116.3l-132 84.3 132 84.3-132 84.3L0 284.1l132.3-84.3L0 116.3 132.3 32l132.1 84.3zM131.6 395.7l132-84.3 132 84.3-132 84.3-132-84.3zm132.8-111.6l132-84.3-132-83.6L395.7 32 528 116.3l-132.3 84.3L528 284.8l-132.3 84.3-131.3-85z" />
          </svg>
        </span>
        <span :class="!!dropboxEnabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity" aria-hidden="true">
          <svg class="h-4 w-4 text-indigo-600" fill="currentColor" viewBox="0 0 528 512">
            <path d="M264.4 116.3l-132 84.3 132 84.3-132 84.3L0 284.1l132.3-84.3L0 116.3 132.3 32l132.1 84.3zM131.6 395.7l132-84.3 132 84.3-132 84.3-132-84.3zm132.8-111.6l132-84.3-132-83.6L395.7 32 528 116.3l-132.3 84.3L528 284.8l-132.3 84.3-131.3-85z" />
          </svg>
        </span>
      </span>
    </button>
    <span class="ml-3" id="dropbox-label">
      <span class="text-sm font-medium text-gray-900">{{ !!dropboxEnabled ? "Connected to Dropbox" : "Connect to Dropbox" }}</span>
    </span>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import api from "../../api";
import parseQueryString from "../../parse-query-string";
import removeIntegrationOptions from "../../sweet-alert-remove-integration-options";

export default {
  data() {
    return {
      dropboxEnabled: false,
      loaded: false
    };
  },
  computed: {
    redirectUrl() {
      const urlRoot =
        location.protocol +
        "//" +
        location.hostname +
        (location.port ? ":" + location.port : "");

      return `${urlRoot}/home/settings#file-storage`;
    }
  },
  mounted() {
    const code = parseQueryString(window.location.search).code;

    if (code) {
      this.$router.replace({ query: null });
      window.location.hash = "";
      this.saveDropboxToken(code);
    } else {
      this.fetchDropboxStatus();
    }
  },
  methods: {
    fetchDropboxStatus() {
      api.get("dropbox_api_token").then(
        json => {
          const { enabled } = json.data;

          this.dropboxEnabled = enabled;
          this.loaded = true;
        },
        error => {
          console.log(error);
          this.loaded = true;
        }
      );
    },
    toggleDropbox() {
      if (this.dropboxEnabled) {
        Swal.fire(removeIntegrationOptions).then(result => {
          if (result.value) {
            window.open(
              "https://www.dropbox.com/account/connected_apps",
              "_blank"
            );
          }
        });
      } else {
        this.dropboxAuth();
      }
    },
    dropboxAuth() {
      api.get("dropbox_auth").then(json => {
        const authUrl = json.data;

        window.location = authUrl;
      });
    },
    saveDropboxToken(code) {
      api
        .post("dropbox_api_token", { dropbox_authorization_code: code })
        .then(json => {
          this.dropboxEnabled = true;
          this.loaded = true;
          window.location.hash = "file-storage";
          this.$store.dispatch("flash", "Dropbox connected successfully");
        });
    },
    destroyDropboxAccessToken() {
      api.delete("dropbox_api_token").then(success => {
        this.dropboxEnabled = false;
        this.loaded = true;
      });
    }
  }
};
</script>
