<template>
  <div @click.stop="viewDetails" @mouseenter="highlightRecord" @mouseleave="unhighlightRecord" class="pl-2 pr-4 py-1 rounded-lg flex flex-col items-center text-xs cursor-pointer" :class="highlighted ? 'text-white' : 'text-gray-900'" :style="`background-color: ${dynamicFill};`">
    <safezone-color-dot :content="content" style-type="icon" :overlay-badge="true" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import DealCard from "../cards/deal";
import DropboxEmbedViewer from "../dropbox-embed-viewer.vue";
import FileViewer from "../cards/file-viewer.vue";
import IFrameEmbedViewer from "../iframe-embed-viewer.vue";
import IntelCard from "../cards/market-intel";
import Notepad from "../notepad";
import SafezoneColorDot from "../safezone-color-dot";

export default {
  components: { SafezoneColorDot },
  props: ["content", "isFeed", "marker"],
  data() {
    return {
      highlighted: false
    };
  },
  computed: {
    ...mapState(["highlightedMapRecord"]),
    dynamicFill() {
      if (this.highlighted) {
        return "#6366F1";
      } else {
        return "white";
      }
    },
    comparisonId() {
      switch (this.content.badgeType || this.content.newsfeedType) {
        case "deal":
        case "intel":
          return this.content.token;
        case "file":
          return this.content.localId;
        case "message":
        case "notepad":
        case "backchannel":
        case "team":
        default:
          return this.content.id;
      }
    },
    marketingListDeal() {
      return _.get(this.content, "source", null) === "direct";
    },
    liveDeal() {
      return _.includes(
        ["live", "awarded"],
        _.get(this.content, "state", null)
      );
    },
    activeDeal() {
      return _.includes(
        ["draft", "live", "awarded"],
        _.get(this.content, "state", null)
      );
    }
  },
  watch: {
    highlightedMapRecord: {
      handler() {
        if (
          this.highlightedMapRecord.contentType ===
            (this.content.badgeType || this.content.newsfeedType) &&
          this.highlightedMapRecord.contentToken === this.comparisonId
        ) {
          this.highlighted = true;
        } else {
          this.highlighted = false;
        }
      },
      deep: true
    }
  },
  methods: {
    highlightRecord() {
      if (this.isFeed) {
        this.$store.commit("setHighlightedMapRecord", {
          contentType: this.content.badgeType || this.content.newsfeedType,
          contentToken: this.comparisonId,
          mapTool: "myFeed",
          touching: "popup"
        });
      }
    },
    unhighlightRecord() {
      if (this.isFeed) {
        this.$store.commit("setHighlightedMapRecord", {
          contentType: null,
          contentToken: null,
          mapTool: null,
          touching: null
        });
      }
    },
    openPreviewCard() {
      this.marker.fire("click");
    },
    viewDetails() {
      let fileEmbedComponent;

      switch (this.content.badgeType || this.content.newsfeedType) {
        case "deal":
          if (
            this.activeDeal &&
            this.marketingListDeal &&
            this.content.dealTeam
          ) {
            const marketingPage =
              this.content.state === "draft" ? "overview" : "marketing-list";

            this.$router.push({
              path: `/deal_offerings/${this.content.urlHandle || this.content.token}/${marketingPage}`
            });
          } else if (
            this.liveDeal &&
            this.marketingListDeal &&
            this.content.marketingReadAuthorized
          ) {
            const marketingOverviewPath = `/deal_offerings/${this.content.urlHandle || this.content.token}/overview`;

            window.open(marketingOverviewPath, "_blank");
          } else {
            this.$store.commit("openModal", {
              component: DealCard,
              props: {
                dealObject: this.content,
                property: null
              }
            });
          }
          break;
        case "intel":
          this.$store.commit("openModal", {
            component: IntelCard,
            props: {
              postObject: this.content,
              property: null
            }
          });
          break;
        case "message":
          switch (this.content.contentType) {
            case "Deal":
              this.$store.commit("openModal", {
                component: DealCard,
                props: {
                  dealToken: this.content.contentToken,
                  property: null,
                  openComments: true
                }
              });
              break;
            case "MarketIntelPost":
              this.$store.commit("openModal", {
                component: IntelCard,
                props: {
                  postToken: this.content.contentToken,
                  property: null,
                  openComments: true
                }
              });
              break;
          }
          break;
        case "notepad":
          this.$store.commit("openSlideover", {
            component: Notepad,
            props: {
              myNotepad: true,
              contentToken: this.content.contentToken,
              contentType: this.content.contentType
            }
          });
          break;
        case "backchannel":
        case "team":
          window.open(
            `/backchannel/${this.content.backchannelToken}`,
            "_blank"
          );
          break;
        case "file":
          switch (this.content.hostName) {
            case "dropbox":
              fileEmbedComponent = DropboxEmbedViewer;
              break;
            case "google":
            case "microsoft":
              fileEmbedComponent = IFrameEmbedViewer;
              break;
            case "tower_hunt":
            default:
              fileEmbedComponent = FileViewer;
          }

          this.$store.commit("openXLModal", {
            component: fileEmbedComponent,
            props: { file: this.content, document: this.content }
          });
          break;
        default:
          return;
      }
    }
  }
};
</script>
