<template>
  <div class="relative p-4 max-w-3xl mx-auto">
    <div class="text-center">
      <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Create a bounty
      </h2>
      <p v-if="postContent" class="mt-4 text-xl font-bold leading-6 text-gray-900">
        {{ postContent.name }}
      </p>
      <bounty-requester-progress class="mt-4" color="yellow" state="creating" />
      <!--
        Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
        Read the documentation to get started: https://tailwindui.com/documentation
      -->
      <div class="mt-2 bg-yellow-50 border-l-4 border-yellow-400 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: exclamation -->
            <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm leading-5 text-yellow-700">
              You are anonymous throughout this process.
              <router-link to="/product/bounties" target="_blank" class="font-medium underline text-yellow-700 hover:text-yellow-600">
                Learn more &rarr;
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-12">
      <form @submit.prevent="confirmPrivacy" class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        <div class="sm:col-span-2">
          <label for="message" class="block text-sm font-medium leading-5 text-gray-700">Request</label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <resizable-textarea>
              <textarea v-focus v-model="message" id="message" rows="4" type="text" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-sm py-3 px-4 block w-full"></textarea>
            </resizable-textarea>
          </div>
          <p class="mt-2 text-sm text-gray-500" id="message-description"></p>
        </div>
        <div class="sm:col-span-2">
          <!--
            Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
            Read the documentation to get started: https://tailwindui.com/documentation
          -->
          <div class="rounded-md bg-yellow-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: exclamation -->
                <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm leading-5 font-medium text-yellow-800">
                  Best practices
                </h3>
                <div class="mt-2 text-sm leading-5 text-yellow-700">
                  <ul class="list-disc pl-5">
                    <li>
                      Specific requests get better results: What data? Where? How recent? What format?
                    </li>
                    <li class="mt-1">
                      For metrics, be explicit about what inputs and outputs you want. Avoid abbreviations.
                    </li>
                    <li class="mt-1">
                      Don't request items people aren't allowed to share (e.g., OMs, paid database exports).
                    </li>
                    <li class="mt-1">
                      Are you being vague enough to protect your identity?
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sm:col-span-2">
          <button type="submit" :disabled="!complete" :class="buttonStyling" class="w-full flex items-center justify-center py-3 px-4 border border-transparent text-base leading-6 font-medium rounded-md text-white">
            <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
              <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
            </svg>
            <svg
              v-else
              class="-ml-1 mr-2 h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
            </svg>
            Create bounty
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BountyRequesterProgress from "./bounty-requester-progress";
import ResizableTextarea from "../resizable-textarea";
import Swal from "sweetalert2";
import anonymousPostOptions from "../../sweet-alert-always-anonymous-options";
import api from "../../api";

export default {
  components: { BountyRequesterProgress, ResizableTextarea },
  props: ["postContent", "prefill"],
  data() {
    return {
      message: ""
    };
  },
  computed: {
    ...mapGetters(["privacyMode"]),
    ...mapState(["adminMode"]),
    complete() {
      return _.trim(this.message) !== "";
    },
    buttonStyling() {
      if (this.complete) {
        if (this.privacyMode) {
          return "bg-pink-600 hover:bg-pink-500 focus:outline-none focus:border-pink-700 focus:ring-pink active:bg-pink-700";
        } else {
          return "bg-gradient-to-r from-yellow-300 to-yellow-500 hover:to-yellow-600 focus:border-yellow-500 focus:ring-yellow active:to-yellow-600";
        }
      } else {
        return "border-gray-300 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 cursor-not-allowed";
      }
    },
    privacyBackgroundColor() {
      return this.privacyMode ? "bg-pink-600" : "bg-indigo-600";
    }
  },
  mounted() {
    if (this.prefill) {
      this.message = this.prefill;
    }
  },
  methods: {
    closeModal() {
      this.$store.commit("closeModal");
    },
    confirmPrivacy() {
      if (this.complete) {
        Swal.fire(anonymousPostOptions).then(result => {
          if (result.value) {
            this.sendRequest(true);
          }
        });
      }
    },
    sendRequest(anonymous = true) {
      const payload = {
        message: this.message,
        anonymous: anonymous,
        contentType: _.get(this.postContent, "newsfeedType", null),
        contentToken: _.get(this.postContent, "token", null)
      };

      api.post(`help_requests`, payload).then(json => {
        if (!!analytics && !this.adminMode) {
          analytics.track("Bounty created", {
            yearWeek: this.$store.state.yearWeek
          });
        }
        this.$store.dispatch("flash", "Bounty created!");
        this.closeModal();
      });
    }
  }
};
</script>
