<template>
  <div class="h-140 lg:h-auto flex">
    <section class="min-w-0 flex-1 h-full flex flex-col overflow-hidden lg:overflow-auto">
      <div :class="isMobile || (endOfFeed && newsfeed.data.length === 0) ? '': '-mb-5'" class="flex items-center pt-3 space-x-2">
        <grid-loader v-if="fetchingNewsfeed" :loading="fetchingNewsfeed" size="5px" color="#5850ec" />
        <span v-else class="inline-flex rounded-md shadow-sm" v-tooltip="'Refresh'">
          <button @click="refreshNewsfeed" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-indigo-300 focus:ring-indigo active:text-gray-800 active:bg-gray-50">
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
            </svg>
          </button>
        </span>
        <div class="flex-grow border-t border-gray-200" />
        <div class="flex items-center">
          <email-alert-toggle :following="propertyFollowing" @refetch="refetchFollowing" />
          <span class="ml-3" id="email-alerts-label">
            <span class="text-sm font-medium text-gray-900">Email alerts?</span>
          </span>
        </div>
      </div>
      <div class="overflow-y-auto">
        <div v-if="!loaded && newsfeed.data.length === 0" class="mt-4 mb-1 flex justify-center">
          <grid-loader :loading="!loaded && newsfeed.data.length === 0" size="6px" color="#5850ec" />
        </div>

        <multi-content-preview-card
          v-for="(post, index) in newsfeed.data"
          :key="`${post.content.token}-${index}`"
          :content="post.content"
          class="col-span-1 mt-8"
        />

        <div v-if="endOfFeed" class="col-span-1 mt-4 bg-white shadow sm:rounded-lg">
          <div key="end-of-feed" class="px-4 py-5 sm:p-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Refresh the newsfeed to fetch the latest info
            </h3>
            <div class="mt-2">
              <div class="max-w-xl text-sm leading-5 text-gray-500">
                <p>
                  There's nothing else to show you right now. Do you have anything to share?
                </p>
              </div>
              <div class="mt-5">
                <span class="inline-flex rounded-md shadow-sm">
                  <button @click="newIntel" type="button" :class="privacyMode ? 'bg-pink-600 hover:bg-pink-500 focus:outline-none focus:border-pink-700 focus:ring-pink active:bg-pink-700' : 'bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700'" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white">
                    <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
                      <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
                    </svg>
                    <svg
                      v-else
                      class="-ml-1 mr-2 h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"></path>
                    </svg>
                    Post intel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <infinite-loading
          v-if="loaded && infiniteReady && !endOfFeed"
          class="mt-4"
          :identifier="propertyFollowingId"
          @infinite="incrementNewsfeed"
        />
        <div v-if="loaded && busy" class="mt-3 h-160 flex justify-center">
          <grid-loader :loading="true" size="6px" color="#5850ec" />
        </div>
        <div v-else-if="loaded && !endOfFeed" class="h-12 flex items-end">
          <div class="h-3" v-observe-visibility="{ callback: handleLoaderVisibility }" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import EmailAlertToggle from "./email-alert-toggle.vue";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import InfiniteLoading from "vue-infinite-loading";
import MultiContentPreviewCard from "../cards/multi-content-preview-card";
import api from "../../api";

let newsfeedUpdateInterval;

export default {
  components: {
    EmailAlertToggle,
    GridLoader,
    InfiniteLoading,
    MultiContentPreviewCard
  },
  props: ["propertyFollowing", "propertyFollowingId"],
  data() {
    return {
      busy: false,
      loaded: false,
      fetchingNewsfeed: false,
      infiniteReady: false,
      endOfFeed: false,
      newsfeed: {
        data: [],
        pagy: null
      }
    };
  },
  computed: {
    ...mapGetters(["privacyMode"]),
    ...mapState([]),
    pagination() {
      return this.newsfeed.pagy;
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    propertyFollowingId: {
      handler(newId, oldId) {
        if (oldId && newId !== oldId) {
          this.fetchNewsfeed();
        }
      }
    }
  },
  mounted() {
    this.fetchNewsfeed();
  },
  destroyed() {
    clearInterval(newsfeedUpdateInterval);
  },
  methods: {
    handleLoaderVisibility(isVisible, entry) {
      if (isVisible) {
        setTimeout(() => {
          this.infiniteReady = true;
        }, 500);
      } else {
        this.infiniteReady = false;
      }
    },
    newIntel() {
      this.$router.push({
        path: "/intel/new/privacy",
        query: { nextUrl: "/intel/new/1" }
      });
    },
    fetchNewsfeed() {
      this.loaded = false;
      this.endOfFeed = false;
      this.newsfeed = { data: [], pagy: null };
      api.get(`property_newsfeeds/${this.propertyFollowingId}`).then(json => {
        this.newsfeed = json.data;
        if (this.newsfeed.data.length === 0) {
          this.endOfFeed = true;
        }
        setTimeout(() => {
          this.loaded = true;
        }, 1000);
      });
    },
    refetchFollowing() {
      this.$emit("refetch");
    },
    refreshNewsfeed() {
      api
        .get(`refresh_property_newsfeed/${this.propertyFollowingId}`)
        .then(json => {
          var self = this;

          newsfeedUpdateInterval = setInterval(function() {
            self.updateNewsfeed();
          }, 3000);
          this.updateNewsfeed();
        });
    },
    updateNewsfeed() {
      api.patch(`property_newsfeeds/${this.propertyFollowingId}`).then(json => {
        const { fetchAgain } = json.data;

        if (fetchAgain) {
          this.fetchingNewsfeed = true;
        } else {
          clearInterval(newsfeedUpdateInterval);
          this.fetchingNewsfeed = false;

          this.fetchNewsfeed();
        }
      });
    },
    extendNewsfeed({ data, pagy }) {
      this.newsfeed.data = this.newsfeed.data.concat(data);
      this.newsfeed.pagy = pagy;
      setTimeout(() => {
        this.busy = false;
      }, 1000);
    },
    incrementNewsfeed($state) {
      if (this.pagination) {
        const { count, to } = this.pagination;

        if (count === 0) {
          this.endOfFeed = true;
        } else if (to < count) {
          const cleanUrl = _.drop(this.pagination.next_url, 8).join("");

          this.busy = true;
          this.infiniteReady = false;
          api.get(cleanUrl).then(json => {
            this.extendNewsfeed(json.data);
            $state.loaded();
          });
        } else {
          this.endOfFeed = true;
          $state.complete();
        }
      }
    }
  }
};
</script>
