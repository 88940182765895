<template>
  <div ref="teamMenu" class="flex-shrink-0 pr-2" style="z-index: 9998;">
    <button @click="toggleMenu" class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      <span class="sr-only">Open options</span>
      <!-- Heroicon name: solid/dots-vertical -->
      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
      </svg>
    </button>

    <transition
      enter-active-class="duration-100 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-75 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-show="menuOpen" class="z-10 mx-3 origin-top-right absolute right-10 top-3 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="pinned-project-options-menu-0-button" tabindex="-1">
        <div v-if="team.role === 'Primary owner'" class="py-1" role="none">
          <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
          <a v-if="team.role === 'Primary owner'" href="" @click.prevent="editTeam" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900" role="menuitem" tabindex="-1">Manage</a>
        </div>
        <div class="py-1" role="none">
          <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
          <a v-if="team.role === 'Primary owner'" href="" @click.prevent="deleteTeam" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900" role="menuitem" tabindex="-1">Delete</a>
          <a v-if="team.role === 'Full member'" href="" @click.prevent="leaveTeam" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900" role="menuitem" tabindex="-1">Leave</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2";
import api from "../api";
import deleteTeamOptions from "../sweet-alert-delete-team-options";
import leaveTeamOptions from "../sweet-alert-leave-team-options";

export default {
  props: ["team"],
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState(["modal", "temporaryAccess"])
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (this.$refs.teamMenu && !this.$refs.teamMenu.contains(evt.target)) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    editTeam() {
      this.$emit("edit");
    },
    deleteTeam() {
      Swal.fire(deleteTeamOptions).then(result => {
        if (result.value) {
          api.delete(`teams/${this.team.id}`).then(json => {
            this.$store.dispatch("flash", "Team deleted");
            this.$store.dispatch("loadBackchannels");
            this.$emit("refetch");
          });
        }
      });
      this.menuOpen = false;
    },
    leaveTeam() {
      Swal.fire(leaveTeamOptions).then(result => {
        if (result.value) {
          api
            .delete(
              `teams/${this.team.id}/team_memberships/${this.team.membershipId}`
            )
            .then(json => {
              this.$store.dispatch("flash", `You left ${this.team.name}`);
              this.$store.dispatch("loadBackchannels");
              this.$emit("refetch");
            });
        }
      });
      this.menuOpen = false;
    }
  }
};
</script>
