<template>
  <div @mouseenter="disableMapScroll" @mouseleave="enableMapScroll" class="leaflet-control px-2 py-1 bg-white border-2 border-gray-300 rounded-md" :class="visible ? 'w-72' : 'w-12 h-12 flex'">
    <template v-if="visible">
      <div class="flex items-baseline">
        <h2 class="text-base font-bold text-gray-900">
          Context
        </h2>
        <a @click.prevent.stop="visible = !visible" class="ml-2 text-xs leading-5 underline cursor-pointer">
          Show less
        </a>
      </div>
      <div class="my-2">
        <label for="context-type" class="sr-only">Select context</label>
        <select v-model="contextType" @change="fetchContext" id="context-type" name="context-type" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
          <option value="local_peak_pricing">High per-unit pricing</option>
          <option value="local_development_pipeline">Large development projects</option>
          <option value="local_tenants">Large tenants</option>
        </select>
      </div>
      <div v-if="zoom < 9" class="mt-1">
        <h2 class="text-sm font-medium text-gray-900">
          <!-- This example requires Tailwind CSS v2.0+ -->
          <div class="bg-blue-50 border-l-4 border-blue-400 p-1">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: solid/zoom-in -->
                <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M5 8a1 1 0 011-1h1V6a1 1 0 012 0v1h1a1 1 0 110 2H9v1a1 1 0 11-2 0V9H6a1 1 0 01-1-1z" />
                  <path fill-rule="evenodd" d="M2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8zm6-4a4 4 0 100 8 4 4 0 000-8z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <p class="text-sm text-blue-700">
                  Please zoom in
                </p>
              </div>
            </div>
          </div>
        </h2>
      </div>
      <div id="context-container" v-show="visible && zoom >= 9" class="mt-1 flow-root max-h-48 sm:max-h-72 overflow-y-auto">
        <div v-if="fetching" class="mb-1 flex justify-center">
          <grid-loader :loading="fetching" size="4px" color="#5850ec" />
        </div>
        <ul v-else-if="contextRecords.length > 0" class="mb-1 space-y-2">
          <li v-for="(record, index) in contextRecords" :key="record.token" :id="`${record.newsfeedType}-${record.token}`">
            <strategic-context-deal v-if="record.newsfeedType === 'deal'" :deal="record" :index="index + 1" :map="map" :feature-group="featureGroup" :context-type="contextType" />
            <strategic-context-intel v-if="record.newsfeedType === 'intel'" :intel="record" :index="index + 1" :map="map" :feature-group="featureGroup" :context-type="contextType" />
          </li>
        </ul>
        <div v-else>
          <div class="text-center">
            <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
            </svg>
            <h3 class="mt-2 text-sm font-medium text-gray-900">Strategic Context</h3>
            <p class="mt-1 text-xs text-gray-500">
              When in Strategic Map Mode, we'll automatically display nearby {{ contextExample }} so you better understand {{ contextBenefit }}.
            </p>
            <div v-if="false" class="mt-4 mb-2">
              <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <!-- Heroicon name: solid/plus -->
                <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
                </svg>
                Upgrade now
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <button v-else @click.prevent.stop="visible = !visible" v-tooltip="'Strategic Context'" class="w-full flex items-center justify-center text-gray-700">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z" clip-rule="evenodd" />
      </svg>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import StrategicContextDeal from "./strategic-context-deal";
import StrategicContextIntel from "./strategic-context-intel";
import api from "../../api";

export default {
  components: { GridLoader, StrategicContextDeal, StrategicContextIntel },
  props: ["map", "featureGroup"],
  data() {
    return {
      visible: true,
      contextType: "local_peak_pricing",
      contextRecords: [],
      fetching: false
    };
  },
  computed: {
    ...mapGetters(["hasMapFilters", "signedIn", "mapMode"]),
    ...mapState(["mapNearbyProperties", "mapFilters", "highlightedMapRecord"]),
    zoom() {
      return this.map.getZoom();
    },
    strategicMap() {
      return this.mapMode === "strategic";
    },
    contextExample() {
      switch (this.contextType) {
        case "local_peak_pricing":
          return "comps";
        case "local_development_pipeline":
          return "development projects";
        case "local_tenants":
          return "tenants and TIMs";
        default:
          return "intel";
      }
    },
    contextBenefit() {
      switch (this.contextType) {
        case "local_peak_pricing":
          return "pricing";
        case "local_development_pipeline":
          return "supply";
        case "local_tenants":
          return "demand and tenant mix";
        default:
          return "this market";
      }
    }
  },
  watch: {
    strategicMap: {
      handler() {
        if (!this.strategicMap) {
          this.visible = false;
          this.contextRecords = [];
        }
      }
    },
    mapNearbyProperties: {
      handler: _.debounce(function() {
        if (this.mapNearbyProperties.length > 0) {
          this.fetchContext();
        }
      }, 3000),
      deep: true
    },
    highlightedMapRecord: {
      handler: _.debounce(function() {
        if (
          this.highlightedMapRecord.contentType &&
          this.highlightedMapRecord.contentToken &&
          this.highlightedMapRecord.mapTool === "strategicContext" &&
          this.highlightedMapRecord.touching === "popup" &&
          this.visible
        ) {
          this.$scrollTo(
            `#${this.highlightedMapRecord.contentType}-${this.highlightedMapRecord.contentToken}`,
            300,
            { container: "#context-container" }
          );
        }
      }, 100),
      deep: true
    }
  },
  mounted() {
    this.fetchContext();

    if (!this.strategicMap) {
      this.visible = false;
    }
  },
  methods: {
    disableMapScroll() {
      this.map.scrollWheelZoom.disable();
    },
    enableMapScroll() {
      this.map.scrollWheelZoom.enable();
    },
    fetchContext() {
      if (this.strategicMap) {
        const bounds = this.map.getBounds();
        const southwest = bounds.getSouthWest();
        const northeast = bounds.getNorthEast();
        const northeastLatLng = [northeast.lat, northeast.lng];
        const southwestLatLng = [southwest.lat, southwest.lng];
        const payload = {
          sources: this.mapFilters.sources.map(option => option.value),
          regions: this.mapFilters.regions.map(region => region.id),
          deals: this.mapFilters.deals.map(option => option.value),
          loans: this.mapFilters.loans.map(option => option.value),
          risks: this.mapFilters.risks.map(option => option.value),
          uses: this.mapFilters.uses.map(option => option.value),
          dates: this.mapFilters.dates
        };

        this.fetching = true;
        api
          .post(
            `${
              this.contextType
            }/${southwestLatLng}/${northeastLatLng}?zoom=${this.map.getZoom()}`,
            payload
          )
          .then(json => {
            const filtered = _.filter(json.data, function(record) {
              if (record.newsfeedType === "deal") {
                return !!record.goingInPerUnit || !!record.tenantSize;
              } else {
                return true;
              }
            });

            this.contextRecords = filtered;

            if (!this.visible && this.contextRecords.length > 0) {
              this.visible = true;
            }

            this.fetching = false;
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.leaflet-container a {
  @apply text-indigo-600 font-medium;
}
.leaflet-container a:hover {
  @apply text-indigo-500;
}
</style>
