<template>
  <div>
    <div v-if="fetched">
      <div v-if="busy" class="h-160" />

      <infinite-loading
        v-if="loaded && !endOfFeed"
        direction="top"
        :identifier="currentUser.token"
        @infinite="incrementNotepad"
      />

      <div class="flex flex-col-reverse">
        <comment
          v-for="(message, index) in notepad.data"
          :key="index"
          :comment="message"
          background-color="gray-100"
          :thankable="false"
          :respondable="false"
          @deleted="deleted"
          @unlinked="unlinked"
          class="my-2"
        />
      </div>

      <!-- This example requires Tailwind CSS v2.0+ -->
      <div v-if="notepad.data.length === 0" class="text-center">
        <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900">No notes</h3>
        <p class="mt-1 text-sm text-gray-500">
          Your ideas belong here.
        </p>
      </div>

      <div id="notepad-bottom"></div>
    </div>

    <div v-else class="mt-6 -mb-8 flex justify-center">
      <grid-loader :loading="true" size="6px" color="#5850ec" />
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapState } from "vuex";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import InfiniteLoading from "vue-infinite-loading";
import api from "../api";

export default {
  components: { GridLoader, InfiniteLoading },
  props: ["content", "contentToken", "contentType"],
  data() {
    return {
      busy: false,
      endOfFeed: false,
      fetched: false,
      loaded: false,
      notepad: {
        data: [],
        pagy: null
      }
    };
  },
  computed: {
    ...mapState(["currentUser", "refetchNotepad", "adminMode", "slideover"]),
    newestMessage() {
      return _.head(this.notepad.data);
    },
    pagination() {
      return this.notepad.pagy;
    }
  },
  watch: {
    refetchNotepad: {
      handler() {
        if (this.refetchNotepad) {
          if (this.newestMessage) {
            setTimeout(() => {
              this.unshiftNotepad();
            }, 250);
          } else {
            this.endOfFeed = false;
            this.loaded = false;
            this.fetched = false;
            this.loadNotepad();
          }
        }
      }
    }
  },
  beforeCreate() {
    this.$options.components.Comment = require("./cards/comment.vue").default;
  },
  mounted() {
    this.loadNotepad();
  },
  methods: {
    loadNotepad(scroll = true) {
      const queryParams =
        (this.contentToken || this.content) && this.contentType
          ? `?content_token=${this.contentToken || this.content.token || this.content.id}&content_type=${this.contentType}`
          : "";

      api.get(`notepad_messages${queryParams}`).then(
        json => {
          this.notepad = json.data;
          this.fetched = true;
          this.$store.commit("refetchNotepad", false);
          if (this.notepad.data.length === 0) {
            this.endOfFeed = true;
          }
          if (scroll) {
            var self = this;

            setTimeout(() => {
              if (self.slideover) {
                this.$scrollTo("#notepad-bottom", 100, { container: "#slideover-body-container" });
              } else {
                this.$scrollTo("#notepad-bottom", 100, { container: "#watchlist-notepad-container" });
              }
            }, 250);
          }
          setTimeout(() => {
            this.loaded = true;
          }, 5000);
        },
        failure => {
          // this.$store.commit("closeSlideover");
          // this.$store.commit("closeModal");
        }
      );
    },
    extendNotepad({ data, pagy }) {
      this.notepad.data = this.notepad.data.concat(data);
      this.notepad.pagy = pagy;
    },
    unshiftNotepad() {
      const sinceTime = moment(this.newestMessage.sortDate).add(2, 's').valueOf();
      const queryParams =
        (this.contentToken || this.content) && this.contentType
          ? `?content_token=${this.contentToken || this.content.token || this.content.id}&content_type=${this.contentType}&since=${sinceTime}`
          : `?since=${sinceTime}`;

      api.get(`notepad_messages${queryParams}`).then(json => {
        const newPosts = json.data.data;

        this.notepad.data = newPosts.concat(this.notepad.data);
        this.$store.commit("refetchNotepad", false);

        var self = this;

        setTimeout(() => {
          if (self.slideover) {
            this.$scrollTo("#notepad-bottom", 100, { container: "#slideover-body-container" });
          } else {
            this.$scrollTo("#notepad-bottom", 100, { container: "#watchlist-notepad-container" });
          }
        }, 250);
      });
    },
    deleted(commentId) {
      this.notepad.data = this.notepad.data.filter(message => {
        if (message.id) {
          return message.id !== commentId;
        } else {
          return true;
        }
      });
    },
    unlinked(commentId) {
      this.notepad.data.forEach(message => {
        if (message.id && message.id === commentId) {
          message.contentId = message.contentType = null;
        }
      });
    },
    incrementNotepad($state) {
      if (this.pagination) {
        const { count, to } = this.pagination;

        if (count === 0) {
          this.endOfFeed = true;
        } else if (to < count) {
          const cleanUrl = _.drop(this.pagination.next_url, 8).join("");

          this.busy = true;

          api.get(cleanUrl).then(json => {
            this.extendNotepad(json.data);
            $state.loaded();
            this.busy = false;
          });
        } else {
          this.endOfFeed = true;
          $state.complete();
        }
      }
    }
  }
};
</script>
