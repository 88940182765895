<template>
  <div v-if="bounty">
    <div class="pb-5 border-b border-gray-200 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
      <div class="space-y-2">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          <span class="sm:hidden">{{ bounty.requestName | truncate(30) }}</span>
          <span class="hidden sm:block">{{ bounty.requestName }}</span>
        </h3>
        <p class="max-w-4xl text-sm leading-5 text-gray-500">{{ bounty.requestDescription }}</p>
      </div>
      <div>
        <span class="shadow-sm rounded-md">
          <router-link to="/bounties/my-bounties" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium shadow-sm rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
            <svg class="-ml-1 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
            </svg>
            Back to my bounties
          </router-link>
        </span>
      </div>
    </div>

    <bids :bounty="bounty" />
  </div>
</template>

<script>
import BidPrivateChat from "../components/bounties/bid-private-chat";
import Bids from "../components/bounties/bounty-detail/bids";
import api from "../api";

export default {
  components: { Bids },
  props: ["bountyId"],
  data() {
    return {
      bounty: null,
      selectValue: null
    };
  },
  mounted() {
    api.get(`bounties/${this.bountyId}`).then(
      json => {
        this.bounty = json.data;
        document.title = `Bounty: ${this.bounty.requestName} | Tower Hunt`;
        this.handleChatParam();
      },
      failure => {
        this.$store.dispatch("flash", "Invalid link");
        this.$router.push({ path: "/bounties/my-bounties" });
      }
    );
  },
  methods: {
    handleChatParam() {
      const bidId = _.get(this.$store.state.route, "query.bid", null);
      const openChat = _.get(this.$store.state.route, "query.chat", null);

      if (bidId) {
        api.get(`bounty_bids/${bidId}`).then(
          json => {
            const { bounty, bid } = json.data;

            if (openChat) {
              this.$store.commit("openSlideover", {
                component: BidPrivateChat,
                props: { bounty, bid }
              });
            }
          },
          failure => {
            this.$store.dispatch("flash", "Invalid link");
            this.$router.push({ path: "/bounties/my-bounties" });
          }
        );
      }
    }
  }
};
</script>
