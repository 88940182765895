<template>
  <div>
    <div v-tooltip="property.isLive ? 'In play' : ''" class="w-2.5 h-2.5 flex-shrink-0 rounded-full" :class="property.isLive ? 'bg-red-400' : 'bg-white'"></div>
  </div>
</template>

<script>
export default {
  props: ["property"],
  computed: {},
  methods: {
    viewDetails() {
      this.$emit("view-details");
    }
  }
};
</script>
