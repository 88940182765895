<template>
  <div class="relative">
    <label :for="useType.backEndKey" class="block text-sm font-medium text-gray-700">{{ useType.name }}</label>
    <div class="mt-1 flex rounded-md shadow-sm">
      <div class="flex relative items-stretch flex-grow focus-within:z-10">
        <input v-model="useSize" :name="useType.backEndKey" type="number" min="0" step="1" placeholder="" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pr-16 sm:text-sm border-gray-300">
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm" id="size-measure">
            {{ useType.measure }}
          </span>
        </div>
      </div>
      <attribution-menu
        :content-token="activeProperty.id"
        content-type="property"
        :field="useType.backEndKey"
        buttonClass="-ml-px inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        svgClass="text-gray-400"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AttributionMenu from "../../crowdsourcing/attribution-menu.vue";

export default {
  components: { AttributionMenu },
  props: ["useType"],
  computed: {
    ...mapState(["activeProperty"]),
    useSize: {
      get() {
        return _.get(this.activeProperty, this.useType.frontEndKey, 0);
      },
      set(size) {
        this.$store.commit("setPropertySize", {
          use: this.useType.frontEndKey,
          size
        });
      }
    }
  }
};
</script>
