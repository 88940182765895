<template>
  <div />
</template>

<script>
export default {
  props: ["userInvitationToken"],
  mounted() {
    this.$store.commit("setNewUserInvitationToken", this.userInvitationToken);
    this.$router.push({ path: "/start/1" });
  }
};
</script>
