<template>
  <div class="relative p-4 max-w-xl mx-auto">
    <div>
      <h2 class="text-3xl text-center leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Message from {{ message.author.name }}
      </h2>
      <blockquote class="mt-4 md:flex-grow md:flex md:flex-col">
        <div class="relative text-left text-lg leading-7 font-medium text-gray-500 md:flex-grow">
          <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-200" fill="currentColor" viewBox="0 0 32 32">
            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
          </svg>
          <p class="relative">
            {{ message.body }}
          </p>
        </div>
      </blockquote>
    </div>
    <div class="mt-12">
      <form @submit.prevent="sendMessage" class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        <div class="sm:col-span-2">
          <label for="message" class="block text-sm font-medium leading-5 text-gray-700">Reply</label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <resizable-textarea>
              <textarea v-focus v-model="reply" id="message" type="text" rows="4" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 py-3 px-4 block w-full"></textarea>
            </resizable-textarea>
          </div>
        </div>
        <div class="sm:col-span-2">
          <div class="flex items-start">
            <p class="text-base leading-6 text-gray-500">
              <router-link to="/product/gauge" target="_blank" class="underline">Learn more</router-link> about what happens if you reply.
            </p>
          </div>
        </div>
        <div class="sm:col-span-2">
          <span class="w-full inline-flex rounded-md shadow-sm">
            <button type="submit" class="border rounded-md text-base leading-6 font-medium w-full inline-flex items-center justify-center px-6 py-3 border" :class="buttonStyling" :disabled="!complete">
              Reply
            </button>
          </span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ResizableTextarea from "../../resizable-textarea";
import api from "../../../api";

export default {
  components: { ResizableTextarea },
  props: ["message", "post"],
  data() {
    return {
      reply: ""
    };
  },
  computed: {
    complete() {
      return _.trim(this.reply) !== "";
    },
    buttonStyling() {
      if (this.complete) {
        return "border-transparent text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700";
      } else {
        return "border-gray-300 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 cursor-not-allowed";
      }
    }
  },
  methods: {
    closeModal() {
      this.$store.commit("closeModal");
    },
    sendMessage() {
      if (this.complete) {
        const payload = {
          privateAudience: [
            {
              token: this.message.author.token,
              message: this.reply
            }
          ],
          contentToken: this.post.token,
          messageId: this.message.id
        };

        api.post(`off_market_property_owner_reply`, payload).then(json => {
          if (!!analytics && !this.adminMode) {
            analytics.track("Off-market owner replied", {
              yearWeek: this.$store.state.yearWeek
            });
          }
          this.$store.dispatch("flash", "Reply sent.");
          this.$store.commit("setTemporaryAccess", {
            messageId: this.message.id,
            action: "replied"
          });
          this.$store.dispatch("loadBackchannels");
          this.closeModal();
        });
      }
    }
  }
};
</script>
