export default {
  title: "Are you sure you want to delete this message?",
  text: "Any replies will also be deleted. If you're unsure whether something should be deleted, please report it instead.",
  showCancelButton: true,
  showCloseButton: true,
  focusCancel: true,
  confirmButtonColor: "#5850ec",
  cancelButtonColor: "#b7b7b7",
  confirmButtonText: "Delete",
  reverseButtons: true,
  focusConfirm: false,
  customClass: {
    header: "thswal-header-class",
    title: "thswal-title-class",
    closeButton: "thswal-close-button-class",
    content: "thswal-content-class",
    actions: "thswal-actions-class",
    confirmButton: "thswal-confirm-button-class",
    cancelButton: "thswal-cancel-button-class"
  }
};
