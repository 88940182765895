<template>
  <div class="wrapper">
    <heading>{{ callToAction }}</heading>
    <form @submit.prevent="upload" autocomplete="off" novalidate>
      <div class="input-container mb-2">
        <resizable-textarea>
          <textarea
            v-focus
            v-model="emails"
            @focus="focus('emails')"
            @blur="blur"
            rows="1"
            autocorrect="off"
            spellcheck="false"
            placeholder=""
            tabindex="1"
            name="emails"
            class="input-text black"
            :class="{ active: focused === 'emails' }"
          />
        </resizable-textarea>
      </div>

      <template v-if="complete && action === 'createTowerHuntInvitation'">
        <h3 class="mx-4 text-center text-lg sm:text-xl leading-9 font-bold text-gray-900">
          Optional message
        </h3>
        <div class="input-container my-2">
          <resizable-textarea>
            <textarea
              v-model="message"
              @focus="focus('message')"
              @blur="blur"
              rows="2"
              autocorrect="off"
              spellcheck="false"
              placeholder="Let's connect on here. I've found the platform useful."
              tabindex="2"
              name="message"
              class="input-text black"
              :class="{ active: focused === 'message' }"
            />
          </resizable-textarea>
        </div>
      </template>

      <option-description v-if="complete" :description="`${parsedEmails.length} lines detected.`" />
      <option-description v-else-if="invalidEmails" description="Invalid emails detected. Please remove or replace." />
      <option-description v-else description="Paste email addresses, one per line." />
      <button type="submit" class="cta" :class="{ complete: complete }" tabindex="999">Import</button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../components/onboarding/heading";
import OptionDescription from "../../components/onboarding/option-description";
import ResizableTextarea from "../../components/resizable-textarea";

export default {
  components: { Heading, OptionDescription, ResizableTextarea },
  props: ["action"],
  data() {
    return {
      focused: false,
      emails: "",
      message: ""
    };
  },
  computed: {
    ...mapState([
      "activeDeal",
      "activeDealMarketing",
      "emailRegex",
      "adminMode"
    ]),
    ...mapGetters([]),
    messageComplete() {
      return this.message !== "" && _.trim(this.message) !== "";
    },
    parsedEmails() {
      return _.split(this.emails, /\r?\n/);
    },
    emailsAreValid() {
      return this.parsedEmails.every(email => this.emailRegex.test(email));
    },
    hasEmails() {
      return this.parsedEmails.length > 0 && this.parsedEmails[0] !== "";
    },
    invalidEmails() {
      return this.hasEmails && !this.emailsAreValid;
    },
    complete() {
      if (this.hasEmails && this.emailsAreValid) {
        return this.parsedEmails[0] !== "";
      } else {
        return false;
      }
    },
    callToAction() {
      switch (this.action) {
        case "createTowerHuntInvitation":
          return "Let's invite some people to Tower Hunt";
        case "createDealProspectInvitation":
          return "Let's import some prospects";
        default:
          return "Let's import some people";
      }
    }
  },
  mounted() {
    this.$store.commit("creatingDealParticipantInvitation");
  },
  destroyed() {
    this.$store.commit("cancelDealParticipantInvitation");
  },
  methods: {
    focus(field) {
      this.focused = field;
    },
    blur() {
      this.focused = false;
    },
    upload() {
      if (this.complete) {
        if (this.action === "createDealProspectInvitationBatch") {
          const payload = {
            emails: this.parsedEmails,
            id: this.activeDealMarketing.id
          };

          this.$store.dispatch(this.action, payload);
        } else if (this.action === "createTowerHuntInvitation") {
          this.parsedEmails.forEach(email => {
            const invitation = {
              email,
              token: null,
              message: this.messageComplete ? this.message : null
            };
            const payload = {
              invitation,
              id: null
            };

            this.$store.dispatch(this.action, payload);
          });

          if (!!analytics && !this.adminMode) {
            analytics.track("Invited colleagues", {
              yearWeek: this.$store.state.yearWeek
            });
          }
        }

        this.$store.commit("closeModal");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
textarea {
  vertical-align: middle;
}
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.active {
    border: 2px solid #5850ec;
  }
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
