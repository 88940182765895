<template>
  <button @click="viewDetails" type="button" v-tooltip="'Linked to'" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800">
    <svg class="mr-1.5 h-3 w-3 text-indigo-400" fill="currentColor" viewBox="0 0 20 20">
      <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
    </svg>
    <span v-if="content">{{ content.name | truncate(30) }}</span>
    <span v-else>Fetching...</span>
  </button>
</template>

<script>
import DealCard from "./cards/deal";
import IntelCard from "./cards/market-intel";
import api from "../api";

export default {
  props: ["contentId", "contentType"],
  data() {
    return {
      content: null
    };
  },
  computed: {
    marketingListDeal() {
      return this.content.source === "direct";
    },
    liveDeal() {
      return _.includes(["live", "awarded"], this.content.state);
    },
    activeDeal() {
      return _.includes(["draft", "live", "awarded"], this.content.state);
    }
  },
  watch: {
    contentId: {
      handler(val, oldVal) {
        if (this.contentId && val !== oldVal) {
          this.fetchContent();
        }
      }
    }
  },
  mounted() {
    this.fetchContent();
  },
  methods: {
    fetchContent() {
      let endpoint = null;

      switch (this.contentType) {
        case "Deal":
          endpoint = "deals";
          break;
        case "MarketIntelPost":
          endpoint = "market_intel_posts";
          break;
        case "Property":
          endpoint = "properties";
          break;
        default:
          return;
      }

      if (endpoint) {
        api.get(`${endpoint}/${this.contentId}`).then(json => {
          this.content = json.data;
        });
      }
    },
    viewDetails() {
      if (this.content) {
        switch (this.content.newsfeedType) {
          case "deal":
            if (this.activeDeal && this.marketingListDeal && this.content.dealTeam) {
              const marketingPage =
                this.content.state === "draft" ? "overview" : "marketing-list";

              this.$router.push({
                path: `/deal_offerings/${this.content.urlHandle || this.content.token}/${marketingPage}`
              });
            } else if (this.liveDeal && this.marketingListDeal && this.content.marketingReadAuthorized) {
              const marketingOverviewPath = `/deal_offerings/${this.content.urlHandle || this.content.token}/overview`;

              window.open(marketingOverviewPath, "_blank");
            } else {
              this.$store.commit("openModal", {
                component: DealCard,
                props: {
                  dealObject: this.content
                }
              });
            }
            break;
          case "intel":
            this.$store.commit("openModal", {
              component: IntelCard,
              props: {
                postObject: this.content,
                property: this.property
              }
            });
            break;
          case "property":
            if (this.content.watchlisted) {
              this.$router.push({
                path: `/property-watchlist/${this.content.watchlistId}/newsfeed`
              });
            }
            break;
          default:
            return;
        }

        this.$store.commit("closeSlideover");
      }
    }
  }
};
</script>
