<template>
  <div class="relative p-4 max-w-3xl mx-auto">
    <div class="text-center">
      <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Edit Showcase
      </h2>
      <p class="mt-4 text-xl font-bold leading-6 text-gray-900">
        Combine a clear call-to-action with an attractive image.
      </p>
    </div>
    <div class="mt-10">
      <form @submit.prevent="update">
        <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
          <div class="sm:col-span-1">
            <div class="flex justify-between">
              <label for="category" class="block text-sm font-medium leading-5 text-gray-700">Category</label>
            </div>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input v-model="category" id="category" type="text" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5" placeholder="e.g., Hot Market" aria-describedby="category">
            </div>
          </div>
          <div class="sm:col-span-1">
            <div class="flex justify-between">
              <label for="title" class="block text-sm font-medium leading-5 text-gray-700">Title</label>
            </div>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input v-model="title" id="title" type="text" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5" placeholder="e.g., Life Science" aria-describedby="title">
            </div>
          </div>
          <div class="sm:col-span-2">
            <div class="flex justify-between">
              <label for="cover_photo" class="block text-sm font-medium leading-5 text-gray-700">Image URL</label>
            </div>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input v-model="coverPhoto" id="cover_photo" type="text" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5" placeholder="https://..." aria-describedby="coverPhoto">
            </div>
            <p class="mt-2 text-xs text-gray-500" id="cover_photo">https://source.unsplash.com/[PHOTO_ID] <a href="" @click.prevent="coverPhoto = 'https://source.unsplash.com/[PHOTO_ID]'" class="font-medium text-indigo-600 hover:text-indigo-500">Prefill</a></p>
            <p class="mt-1 text-xs text-gray-500" id="cover_photo">https://source.unsplash.com/collection/[COLLECTION_ID] <a href="" @click.prevent="coverPhoto = 'https://source.unsplash.com/collection/[COLLECTION_ID]'" class="font-medium text-indigo-600 hover:text-indigo-500">Prefill</a></p>
          </div>
          <div class="sm:col-span-2">
            <div>
              <div class="flex justify-between">
                <label for="headline" class="block text-sm font-medium leading-5 text-gray-700">Headline</label>
              </div>
              <div class="mt-2 relative rounded-md shadow-sm">
                <resizable-textarea>
                  <textarea v-model="headline" id="headline" rows="3" placeholder="Why is this topic interesting? How can we help people?" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-sm py-3 px-4 block w-full"></textarea>
                </resizable-textarea>
              </div>
            </div>
          </div>

          <div class="sm:col-span-3">
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
                <button type="submit" :disabled="!complete" :class="buttonStyling" class="w-full flex items-center justify-center py-2 px-4 border border-transparent text-base leading-6 font-medium rounded-md text-white sm:text-sm sm:leading-5">
                  Edit
                </button>
              </span>
              <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                <button @click="cancel" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue sm:text-sm sm:leading-5">
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ResizableTextarea from "../resizable-textarea";
import api from "../../api";

export default {
  components: { ResizableTextarea },
  props: ["showcase"],
  data() {
    return {
      category: "",
      title: "",
      coverPhoto: "",
      headline: ""
    };
  },
  computed: {
    complete() {
      return (
        _.trim(this.category) !== "" &&
        _.trim(this.title) !== "" &&
        _.trim(this.coverPhoto) !== "" &&
        _.trim(this.headline) !== ""
      );
    },
    buttonStyling() {
      if (this.complete) {
        return "bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700";
      } else {
        return "border-gray-300 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 cursor-not-allowed";
      }
    }
  },
  mounted() {
    this.mountShowcase();
  },
  methods: {
    mountShowcase() {
      const { category, title, coverPhoto, headline } = this.showcase;

      this.category = category || "";
      this.title = title || "";
      this.coverPhoto = coverPhoto || "";
      this.headline = headline || "";
    },
    cancel() {
      this.$store.commit("closeModal");
    },
    update() {
      if (this.complete) {
        const payload = {
          category: this.category,
          title: this.title,
          coverPhoto: this.coverPhoto,
          headline: this.headline
        };

        api
          .patch(`purchaseable_showcases/${this.showcase.id}`, payload)
          .then(json => {
            this.$store.commit("setRefetchShowcases", true);
            this.$store.commit("closeModal");
          });
      }
    }
  }
};
</script>
