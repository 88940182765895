<template>
  <span @click="toggleVisibility" v-tooltip="toggleHelp" role="checkbox" tabindex="0" :aria-checked="activeDealMarketing.investorPreview" :class="activeDealMarketing.investorPreview ? 'bg-gray-800' : 'bg-gray-100'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring">
    <span aria-hidden="true" :class="activeDealMarketing.investorPreview ? 'translate-x-5' : 'translate-x-0'" class="relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200">
      <span :class="activeDealMarketing.investorPreview ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity">
        <svg class="h-3 w-3 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
        </svg>
      </span>
      <span :class="activeDealMarketing.investorPreview ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity">
        <svg class="h-4 w-4 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
        </svg>
      </span>
    </span>
  </span>
</template>

<script>
import { mapState } from "vuex";
import api from "../../api";

export default {
  computed: {
    ...mapState(["currentUser", "activeDeal", "activeDealMarketing"]),
    toggleHelp() {
      if (this.activeDealMarketing.investorPreview) {
        return "This is what investors will see. Click to resume editing.";
      } else {
        return "While editing, you'll see a bit more than investors will. Click for an investor preview.";
      }
    }
  },
  methods: {
    toggleVisibility() {
      api
        .patch(
          `deal_marketing_investor_previews/${this.activeDealMarketing.id}`
        )
        .then(json => {
          this.$store.commit("toggleDealMarketingInvestorPreview");
        });
    }
  }
};
</script>
