<template>
  <div>
    <div v-if="!activeDealMarketing.investorPreview && activeDeal.dealTeam" class="bg-white">
      <div class="max-w-screen-xl mx-auto pb-4 px-4 overflow-hidden sm:px-6 lg:px-8">
        <div class="flex justify-center">
          <a @click.prevent="uploadPhotos" href="" class="flex items-center font-medium text-indigo-600 hover:text-indigo-500">
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
            </svg>
            <span class="ml-2">Upload</span>
          </a>
        </div>
      </div>
    </div>
    <div id="photo-container" class="max-w-7xl mx-auto">
      <div v-if="geometry" class="photos relative w-full" :style="photoContainerHeight">
        <div
          v-for="(box, index) in geometry.boxes"
          :id="`${box.id}-${box.order}`"
          :key="index"
          v-lazyload
          v-tooltip="photoHelp(box)"
          class="absolute bg-gray-200 m-1"
          :style="boxSizing(box)"
        >
          <img @click="openPhoto(box.image, $event)" class="w-full h-full object-cover cursor-pointer" :data-url="box.image" />
        </div>
      </div>
      <div v-else class="relative bg-gray-800">
        <div class="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <img class="w-full h-full object-cover" src="https://source.unsplash.com/collection/2155077" alt="Photos intro" />
        </div>
        <div class="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <div class="md:ml-auto md:w-1/2 md:pl-10">
            <div class="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
              Photos
            </div>
            <h2 class="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
              <template v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized">Show every angle</template>
              <template v-else>The deal team hasn't added any photos yet</template>
            </h2>
            <p class="mt-3 text-lg leading-7 text-gray-300">
              <template v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized">Build a gallery of images that present your deal in the best light. Clicking an image enlarges it and allows you to add a caption if you'd like.</template>
              <template v-else>When they do, you'll see a gallery of images here</template>
            </p>
            <div v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" class="mt-8">
              <div class="inline-flex rounded-md shadow">
                <a @click.prevent="uploadPhotos" href="" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:ring">
                  Upload photos
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FileDragDrop from "../../components/file-drag-drop.vue";
import Muuri from "muuri";
import PhotoCard from "../../components/cards/photo";
import api from "../../api";
import justifiedLayout from "justified-layout";

export default {
  data() {
    return {
      photos: [],
      geometry: null,
      grid: null
    };
  },
  computed: {
    ...mapState(["activeDeal", "activeDealMarketing", "modal", "xlModal"]),
    ...mapGetters(["dealProspectInviteParam"]),
    aspectRatios() {
      return this.photos.map(p => p.aspectRatio);
    },
    photoContainerHeight() {
      if (this.geometry) {
        return `height: ${this.geometry.containerHeight}px;`;
      } else {
        return "";
      }
    }
  },
  watch: {
    geometry: {
      handler() {
        if (
          !this.activeDealMarketing.investorPreview &&
          this.activeDeal.marketingWriteAuthorized &&
          this.geometry &&
          !this.grid
        ) {
          setTimeout(() => {
            this.grid = new Muuri(".photos", {
              dragEnabled: true,
              dragStartPredicate: {
                distance: 10,
                delay: 100
              }
            });

            var self = this;

            this.grid.on("dragReleaseEnd", function(item) {
              self.reorderPhotos();
            });
          }, 200);
        }
      }
    },
    modal: {
      handler() {
        if (!this.modal) {
          this.fetchPhotos();
        }
      }
    },
    xlModal: {
      handler() {
        if (!this.xlModal && !this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
          this.fetchPhotos();
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch("dealMarketingImpression");
    this.fetchPhotos();

    this.debouncedGeometryUpdate = _.debounce(() => {
      this.setGeometry();
    }, 100);

    document.title = `${this.activeDeal.name} · Photos | Tower Hunt`;

    window.addEventListener("resize", this.debouncedGeometryUpdate);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedGeometryUpdate);
  },
  methods: {
    fetchPhotos() {
      this.$store.commit("isLoading");

      let query = "";

      if (this.dealProspectInviteParam) {
        query = `?invite=${this.dealProspectInviteParam}`;
      }

      this.photos = [];
      this.geometry = null;
      this.grid = null;

      api
        .get(
          `deal_marketings/${this.activeDealMarketing.id}/deal_marketing_photos${query}`
        )
        .then(json => {
          this.photos = json.data.filter(photo => !!photo.aspectRatio);
          this.setGeometry();
          this.$store.commit("doneLoading");
        });
    },
    reorderPhotos() {
      const gridItems = this.grid.getItems();
      const idOrderPairs = gridItems.map((item, index) => {
        const elementId = _.split(item._element.id, "-");

        return { id: elementId[0], order: index };
      });
      const payload = { photos: idOrderPairs };

      api
        .patch(
          `deal_marketings/${this.activeDealMarketing.id}/photo_orderings`,
          payload
        )
        .then(result => {
          this.$store.dispatch("loadDealMarketing", this.activeDeal.token);
          this.fetchPhotos();
        });
    },
    uploadPhotos() {
      if (!this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
        this.$store.commit("openModal", {
          component: FileDragDrop,
          props: {
            endpoint: `deal_marketing_photo_uploads/${this.activeDealMarketing.id}`,
            fileTypes: "image/*",
            fileTypeLabels: "PNG, JPG, GIF",
            multiple: true,
            heading: "Let's add some photos",
            marginBottom: true,
            flashMessage: "Photos saved successfully"
          }
        });
      }
    },
    openPhoto(url, event) {
      const photo = _.find(this.photos, { image: url });
      const dragElement = event.currentTarget.closest(".muuri-item-releasing");

      if (photo && !dragElement) {
        this.$store.commit("openXLModal", {
          component: PhotoCard,
          props: { photo, captionable: true }
        });
      }
    },
    setGeometry() {
      this.$store.commit("isLoading");
      this.geometry = null;
      this.grid = null;

      if (this.photos.length > 0) {
        const targetWidth = document.getElementById("photo-container").offsetWidth;
        let output = justifiedLayout(this.aspectRatios, {
          containerWidth: targetWidth
        });

        output.boxes = output.boxes.map((box, index) => {
          return Object.assign({}, box, {
            id: this.photos[index].id,
            order: this.photos[index].order,
            image: this.photos[index].image,
            caption: this.photos[index].caption
          });
        });

        this.geometry = output;
        this.$store.commit("doneLoading");
      }
    },
    boxSizing(box) {
      return `width: ${box.width}px; height: ${box.height}px; top: ${box.top}px; left: ${box.left}px`;
    },
    photoHelp(box) {
      if (box.caption) {
        return box.caption;
      } else if (!this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
        return "Click to enlarge and edit. Drag and drop to reorder.";
      }
    }
  }
};
</script>
