<template>
  <div class="wrapper">
    <heading>Let's name this deal</heading>
    <form @submit.prevent autocomplete="off" novalidate>
      <div class="input-container">
        <input
          v-focus
          v-model="name"
          @input="customDealName"
          @focus="focus"
          @blur="blur"
          type="text"
          autocomplete="new-password"
          autocorrect="off"
          spellcheck="false"
          placeholder=""
          tabindex="1"
          name="deal_name"
          class="input-text black"
          :class="{ active: focused && !privacyMode, 'privacy-active': focused && privacyMode }"
        >
        <label class="input-label" :class="{ active: focused && !privacyMode, 'privacy-active': focused && privacyMode }">Deal name</label>
      </div>
      <option-description v-if="creatingDirectActiveDeal && potentialDuplicates.length === 0" description="Either choice below will hide your deal from brokers." style="margin-top: 20px;" />

      <button v-if="!duplicatesLoaded" type="button" :disabled="true" class="control secondary my-1 cursor-not-allowed" tabindex="999">Duplicate deal check...</button>

      <template v-else-if="creatingDirectActiveDeal && potentialDuplicates.length === 0">
        <button @click="postToMatches" type="button" class="control cta mt-4" :class="{ complete: complete }" tabindex="999">Post to matching principals</button>
        <button @click="buildDealPage" type="button" class="control secondary my-1" tabindex="999">Build my own marketing list</button>
      </template>

      <template v-else>
        <button v-if="!newRecordIsSafezone && creatingIndirectActiveDeal && potentialDuplicates.length === 0" @click="confirmPrivacy" type="button" class="control cta mt-4" :class="{ complete: complete && !privacyMode, 'privacy-complete': complete && privacyMode }" tabindex="999">
          <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
            <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
          </svg>
          Confirm author and post
        </button>

        <button v-else-if="activeDealMarketing.id" @click="next" type="button" class="control cta mt-4" :class="{ complete: complete }" tabindex="999">Save</button>

        <button v-else-if="newRecordIsSafezone && creatingIndirectActiveDeal && potentialDuplicates.length === 0" @click="safezone" type="button" :class="{ 'mt-4': newRecordIsSafezone, 'mt-1': !newRecordIsSafezone, 'privacy-complete': complete && privacyMode, 'complete': complete && !privacyMode }" class="control cta" tabindex="999">
          <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
            <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
          </svg>
          Save to Safezone
        </button>

        <button v-else @click="next" type="button" class="control cta mt-4" :class="{ complete: complete && !privacyMode, 'privacy-complete': complete && privacyMode }" tabindex="999">
          <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
            <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
          </svg>
          Next
        </button>
      </template>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../../components/onboarding/heading";
import OptionDescription from "../../../components/onboarding/option-description";
import Swal from "sweetalert2";
import anonymousPostOptions from "../../../sweet-alert-always-anonymous-options";

export default {
  components: { Heading, OptionDescription },
  props: ["dealId"],
  data() {
    return {
      focused: false
    };
  },
  computed: {
    ...mapState([
      "dealCreationInProcess",
      "activeDeal",
      "activeDealMarketing",
      "newRecordIsSafezone"
    ]),
    ...mapGetters([
      "creatingActiveDeal",
      "creatingDirectActiveDeal",
      "creatingIndirectActiveDeal",
      "creatingDeal",
      "dealHasSize",
      "privacyMode"
    ]),
    duplicatesLoaded() {
      return _.get(this.activeDeal, "potentialDuplicates.loaded", true);
    },
    potentialDuplicates() {
      return _.get(this.activeDeal, "potentialDuplicates.deals", []);
    },
    name: {
      get() {
        return this.activeDeal.name;
      },
      set(name) {
        this.$store.commit("setDealName", name);
      }
    },
    complete() {
      return this.name !== "";
    },
    queryParams() {
      if (this.creatingIndirectActiveDeal) {
        return { query: { active: true, indirect: true } };
      } else if (this.creatingActiveDeal) {
        return { query: { active: true } };
      } else {
        return {};
      }
    },
    standaloneUpdate() {
      return this.dealId && !this.activeDealMarketing.id;
    },
    shouldFetchDuplicates() {
      return (
        !this.dealId &&
        !this.activeDealMarketing.id &&
        (this.creatingIndirectActiveDeal || this.creatingActiveDeal)
      );
    }
  },
  mounted() {
    if (this.creatingDeal && !this.dealCreationInProcess) {
      this.$router.push(_.merge({ path: "/deals/new/1" }, this.queryParams));
    } else if (this.shouldFetchDuplicates) {
      this.$store.dispatch("fetchPotentialDuplicateDeals");
    } else {
      this.$store.commit("setActiveDealDuplicates", []);
    }
  },
  methods: {
    customDealName() {
      this.$store.commit("setCustomDealName");
    },
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    confirmPrivacy() {
      Swal.fire(anonymousPostOptions).then(result => {
        if (result.value) {
          this.next();
        }
      });
    },
    safezone() {
      if (this.complete) {
        this.$store.dispatch("createDeal");
      }
    },
    postToMatches() {
      this.$store.commit("setDealState", "live");
      this.$store.commit("setDealSource", "direct lightweight");
      this.$store.commit("setNewRecordSafezone", false);
      this.next();
    },
    buildDealPage() {
      this.next();
    },
    next() {
      if (this.complete) {
        if (this.standaloneUpdate || this.activeDealMarketing.id) {
          this.$store.dispatch("updateDeal", {
            dealId: this.dealId,
            updateTags: false
          });
          this.$store.commit("closeModal");
          if (this.standaloneUpdate) {
            this.$store.commit("clearDeal");
            this.$store.commit("clearDealMarketing");
          }
        } else if (this.creatingIndirectActiveDeal || this.creatingActiveDeal) {
          if (this.potentialDuplicates.length > 0) {
            this.$router.push(
              _.merge({ path: "/deals/new/dedupe" }, this.queryParams)
            );
          } else {
            this.$store.dispatch("createDeal");
          }
        } else {
          this.$router.push({ path: "/deals/new/11" });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  display: flex;
  flex-direction: column;
  width: 330px;
}
input {
  vertical-align: middle;
}
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 26px 15px 8px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.privacy-active {
    border: 2px solid #d61f69;
  }
  &.active {
    border: 2px solid #5850ec;
  }
}
.input-label {
  position: absolute;
  top: 8px;
  left: 16px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.privacy-active {
    color: #d61f69;
  }
  &.active {
    color: #5850ec;
  }
}
button.control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.privacy-complete {
    background: #d61f69;
    box-shadow: rgba(214, 31, 105, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #f17eb8;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
