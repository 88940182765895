<template>
  <div class="mx-auto">
    <div>
      <div class="text-center">
        <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 48 48" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
        <h2 class="mt-2 text-lg font-medium text-gray-900">Share</h2>
        <p class="mt-1 text-sm text-gray-500">
          Grant access to this Safezone data.
        </p>
      </div>
      <form @submit.prevent class="mt-6 flex">
        <label for="email" class="sr-only">Email address</label>
        <div class="relative flex-grow focus-within:z-10">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <!-- Heroicon name: search -->
            <svg class="h-5 w-5 text-gray-400" style="z-index: 1;" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
            </svg>
          </div>
          <autocomplete
            id="contact_search"
            ref="contactSearch"
            type="text"
            base-class="custom-autocomplete-leading-icon-full-round"
            :search="searchChannels"
            :auto-select="true"
            :get-result-value="getChannelResultValue"
            :debounce-time="500"
            @submit="handleChannelSubmit"
            @keydown="handleKeyDown"
            :placeholder="searchPlaceholder"
          />
        </div>
      </form>
    </div>
    <div v-if="isWatchlistProperty && watchlistMembers.length > 0" class="mt-10">
      <h3 class="text-xs font-semibold text-gray-500 uppercase tracking-wide">Watchlist property members</h3>
      <div v-if="!propertyFollowingLoaded" class="mt-4 flex justify-center">
        <grid-loader :loading="true" size="8px" color="#D4D4D8" />
      </div>
      <ul v-else role="list" class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
        <li class="py-4 flex items-center justify-between space-x-3">
          <div class="min-w-0 flex-1 flex items-center space-x-3">
            <div class="flex-shrink-0 relative">
              <avatar-photo :property-name="safezoneData.contentName" :property-avatar="decoratingContent.coverPhoto" icon-size="6" circle-size="10" text-size="sm" />
              <watchlist-property-badge v-if="safezoneData.contentName" class="absolute bottom-0 right-0" />
            </div>
            <div class="min-w-0 flex-1">
              <p class="text-sm font-medium text-gray-900 truncate">All members: {{ safezoneData.contentName }}</p>
              <p class="text-sm font-medium text-gray-500 truncate">{{ watchlistMemberCount }}</p>
            </div>
          </div>
          <safezone-data-sharing-button licensee-type="PropertyFollowing" :licensee-object="null" @share="share" />
        </li>

        <li v-for="member in watchlistMembers" :key="`watchlist-member-${member.token}`" class="py-4 flex items-center justify-between space-x-3">
          <div class="min-w-0 flex-1 flex items-center space-x-3">
            <div class="flex-shrink-0">
              <avatar-photo :person="member" circle-size="10" text-size="sm" />
            </div>
            <div class="min-w-0 flex-1">
              <p class="text-sm font-medium text-gray-900 truncate">{{ member.name }}</p>
              <p class="text-sm font-medium text-gray-500 truncate">{{ member.industryRole | industryRoleAlias(member.industrySubRole, member.token) | capitalize }}</p>
            </div>
          </div>
          <safezone-data-sharing-button licensee-type="User" :licensee-object="member" @share="share" />
        </li>
      </ul>
    </div>
    <div class="mt-10">
      <h3 class="text-xs font-semibold text-gray-500 uppercase tracking-wide">Recent contacts</h3>
      <div v-if="!recentChannelsLoaded" class="mt-4 flex justify-center">
        <grid-loader :loading="true" size="8px" color="#D4D4D8" />
      </div>
      <ul v-else role="list" class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
        <li v-for="channel in recentChannels" :key="channel.token" v-tooltip="channelTooltip(channel)" class="py-4 flex items-center justify-between space-x-3">
          <div class="min-w-0 flex-1 flex items-center space-x-3">
            <div class="flex-shrink-0 relative">
              <avatar-photo v-if="channel.propertyFollowingName" :property-name="channel.propertyFollowingName" :property-avatar="channel.propertyFollowingAvatar" icon-size="6" circle-size="10" text-size="sm" />
              <avatar-photo v-else :team-name="channel.teamName" :person="channel.members[0]" circle-size="10" text-size="sm" />
              <watchlist-property-badge v-if="channel.propertyFollowingName" class="absolute -bottom-1 -right-2" />
              <team-badge v-else-if="channel.teamName" class="absolute -bottom-1 -right-2" />
              <backchannel-badge v-else class="absolute -bottom-1 -right-2" />
            </div>
            <div class="min-w-0 flex-1">
              <p v-if="channel.members.length > 1" class="text-sm font-medium text-gray-900 truncate">{{ channel.members.length }} People</p>
              <p class="text-sm font-medium text-gray-500 truncate">{{ channel | channelName | truncate(25) }}</p>
            </div>
          </div>
          <safezone-data-sharing-button licensee-type="Backchannel" :licensee-object="channel" @share="share" />
        </li>
      </ul>
    </div>
    <div class="mt-10 flex">
      <button @click="done" type="button" class="flex-1 bg-indigo-600 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Done
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AvatarPhoto from "../avatar-photo";
import BackchannelBadge from "../backchannel-badge";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import SafezoneDataSharingButton from "./safezone-data-sharing-button.vue";
import TeamBadge from "../team-badge";
import WatchlistPropertyBadge from "../watchlist-property-badge";
import api from "../../api";
import pluralize from "pluralize";

export default {
  components: {
    AvatarPhoto,
    BackchannelBadge,
    GridLoader,
    SafezoneDataSharingButton,
    TeamBadge,
    WatchlistPropertyBadge
  },
  props: [
    "safezoneDataType",
    "safezoneData",
    "decoratingContent",
    "decoratingContentType"
  ],
  data() {
    return {
      propertyFollowing: null,
      propertyFollowingLoaded: false,
      recentChannelsLoaded: false,
      recentChannels: []
    };
  },
  computed: {
    ...mapState(["currentUser", "emailRegex"]),
    isWatchlistProperty() {
      return (
        this.decoratingContentType === "property" &&
        this.decoratingContent.watchlistId
      );
    },
    watchlistMembers() {
      return _.get(this.propertyFollowing, "sharing", []);
    },
    watchlistMemberCount() {
      return pluralize("Person", this.watchlistMembers.length, true);
    },
    searchPlaceholder() {
      if (this.decoratingContentType === "property") {
        return "team, somebody, or somebody@example.com";
      } else {
        return "team, watchlist property, somebody, or somebody@example.com";
      }
    }
  },
  mounted() {
    this.fetchRecentChannels();

    if (this.isWatchlistProperty) {
      this.fetchPropertyFollowing();
    }
  },
  methods: {
    done() {
      this.$emit("done");
    },
    channelTooltip(channel) {
      if (channel.members.length > 1) {
        return channel.members.map(user => this.userTooltip(user)).join(", ");
      } else {
        return "";
      }
    },
    userTooltip(user) {
      const name = this.$options.filters.capitalize(user.name);
      const role = _.capitalize(
        this.$options.filters.industryRoleAlias(
          user.industryRole,
          user.industrySubRole,
          user.token
        )
      );

      if (user.company) {
        return `${name} (${role} at ${user.company})`;
      } else {
        return `${name} (${role})`;
      }
    },
    fetchPropertyFollowing() {
      return new Promise(resolve => {
        api
          .get(`property_followings/${this.decoratingContent.watchlistId}`)
          .then(
            json => {
              this.propertyFollowing = json.data;
              this.propertyFollowingLoaded = true;
              resolve();
            },
            failure => {
              this.$store.dispatch("flash", "Unauthorized access.");
            }
        );
      });
    },
    fetchRecentChannels() {
      api
        .get(
          `my_recent_channels?content_type=${this.decoratingContentType}&limit=7`
        )
        .then(json => {
          this.recentChannels = json.data.backchannels;
          var self = this;

          this.recentChannels.forEach(channel => {
            if (!channel.teamId) {
              _.remove(channel.members, function(member) {
                return member.token === self.currentUser.token;
              });
            }
          });

          this.recentChannelsLoaded = true;
        });
    },
    searchChannels(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        const query = input === "" || input === null ? null : _.toLower(input);
        const includePropertyWatchlists =
          this.decoratingContentType !== "property";

        api
          .post(
            `search_channels?team=true&property=${includePropertyWatchlists}`,
            {
              name: query
            }
          )
          .then(
            json => {
              resolve(json.data);
            },
            failure => {
              this.$store.dispatch("flash", "Invalid search");
            }
          );
      });
    },
    getChannelResultValue(result) {
      return result.name;
    },
    handleKeyDown(e) {
      const validAddition = this.emailRegex.test(
        this.$refs.contactSearch.value
      );

      if (validAddition && ["Tab", ",", " "].includes(e.key)) {
        this.handleRawEmail();
        var self = this;

        setTimeout(() => {
          self.$refs.contactSearch.value = "";
        }, 10);
      }
    },
    handleChannelSubmit(result) {
      if (result) {
        this.share({
          licenseeType: result.className,
          licenseeObject: result
        }).then(() => {});
      } else {
        this.handleRawEmail();
      }

      this.$refs.contactSearch.value = "";
    },
    handleRawEmail() {
      const output = {
        name: this.$refs.contactSearch.value,
        email: this.$refs.contactSearch.value,
        id: Date.now()
      };
      const validAddition = this.emailRegex.test(
        this.$refs.contactSearch.value
      );

      if (validAddition) {
        this.$store.dispatch("flash", {
          message:
            "Sharing via email is coming soon. For now, please ask the person to join Tower Hunt.",
          timeout: 5000
        });
      } else {
        this.$store.dispatch(
          "flash",
          "Sorry, this doesn't look like a valid team, watchlist property, person in your network, or email address."
        );
      }
    },
    share({ licenseeType, licenseeObject = null }) {
      return new Promise(resolve => {
        let payload = {
          safezoneDataType: this.safezoneDataType,
          safezoneDataId: this.safezoneData.localId,
          licenseeType,
          licenseeId: null,
          licenseeChannelToken: null
        };

        switch (licenseeType) {
          case "User":
            payload.licenseeId = licenseeObject.token;
            break;
          case "PropertyFollowing":
            payload.licenseeId = this.propertyFollowing
              ? this.propertyFollowing.id
              : licenseeObject.id;
            break;
          case "Team":
            payload.licenseeId = licenseeObject.id;
            break;
          case "Backchannel":
            if (licenseeObject.propertyFollowingId) {
              payload.licenseeType = "PropertyFollowing";
              payload.licenseeId = licenseeObject.propertyFollowingId;
              break;
            } else if (licenseeObject.teamId) {
              payload.licenseeType = "Team";
              payload.licenseeId = licenseeObject.teamId;
              break;
            } else {
              payload.licenseeType = "User";
              payload.licenseeChannelToken = licenseeObject.token;
              break;
            }
          default:
            console.log(`Share ${licenseeType}`);
        }

        api.post(`safezone_data_licenses`, payload).then(
          json => {
            this.$store.dispatch("flash", "Safezone data shared!");
            resolve();
          },
          failure => {
            this.$store.dispatch("flash", "Sharing attempt unsuccessful");
          }
        );
      });
    }
  }
};
</script>
