<template>
  <div v-observe-visibility="{ callback: createImpression }" class="flex flex-grow">
    <span
      v-tooltip="comment.author"
      class="mt-3 h-8 w-8 flex-shrink-0 inline-flex items-center justify-center rounded-full bg-gray-400"
    >
      <svg class="h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path v-if="comment.authored" fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
        <path v-else fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
      </svg>
    </span>
    <div class="flex flex-col w-full">
      <div class="relative p-3 ml-2 w-full shadow text-gray-700 text-left" :class="`bg-${backgroundColor}`" style="border-radius: 5px 20px 20px 20px;">
        <div class="flex justify-between">
          <h3 class="text-xs font-medium text-gray-900">
            {{ comment.author }}
          </h3>
          <div class="flex items-center">
            <div class="text-xs text-right text-gray-500">{{ comment.createdAt | moment("from") }}</div>
            <comment-menu :comment="comment" :is-bounty="true" @deleted="deleted" />
          </div>
        </div>
        <div v-html="linkifiedBody" class="text-sm mt-2 whitespace-pre-line dont-break-out" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CommentMenu from "../comment-menu";
import linkifyHtml from "linkifyjs/html";

export default {
  components: { CommentMenu },
  props: ["comment", "backgroundColor"],
  computed: {
    ...mapState(["adminMode"]),
    linkifiedBody() {
      return linkifyHtml(this.comment.body, {
        defaultProtocol: "https",
        className:
          "font-medium text-indigo-600 hover:text-indigo-500"
      });
    }
  },
  methods: {
    createImpression(isVisible, entry) {
      if (!this.adminMode && isVisible) {
        this.$store.dispatch("createCommentImpression", this.comment.id).then(() => {
          this.$store.dispatch("loadUnreadCounts");
        });
      }
    },
    deleted() {
      this.$emit("deleted", this.comment.id);
    }
  }
};
</script>
