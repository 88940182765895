<template>
  <div id="safezone-table">
    <div v-if="!loaded" class="w-full flex justify-center mt-4">
      <grid-loader :loading="!loaded" size="8px" color="#5850ec" />
    </div>
    <template v-else>
      <!-- Catalog list (only on smallest breakpoint) -->
      <div class="mt-10 shadow rounded-lg sm:hidden">
        <div class="py-3 px-4 sm:px-6">
          <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Deals &amp; Intel</h2>
        </div>
        <ul class="bg-white border-t border-gray-200 divide-y divide-gray-100">
          <safezone-list-row v-for="record in safezone.data" :key="record.content.token" :content="record.content" />
        </ul>
        <nav v-if="safezone.pagy && safezone.pagy.count > 10" class="min-w-full bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
          <div class="hidden sm:block">
            <p class="text-sm text-gray-700">
              Showing
              <span class="font-medium">{{ safezone.pagy.from }}</span>
              to
              <span class="font-medium">{{ safezone.pagy.to }}</span>
              of
              <span class="font-medium">{{ safezone.pagy.count }}</span>
              records
            </p>
          </div>
          <div class="flex-1 flex justify-between sm:justify-end">
            <a href="" @click.prevent="fetchSafezone(safezone.pagy.prev_url)" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
              Previous
            </a>
            <a href="" @click.prevent="fetchSafezone(safezone.pagy.next_url)" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
              Next
            </a>
          </div>
        </nav>
      </div>
      <!-- Catalog table (small breakpoint and up) -->
      <div class="hidden mt-8 sm:block">
        <div class="align-middle inline-block min-w-full border-b border-gray-200 shadow sm:rounded-lg">
          <table class="min-w-full">
            <thead class="bg-gray-50">
              <tr class="border-t border-gray-200">
                <th class="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  <span class="lg:pl-2">Deals &amp; Intel</span>
                </th>
                <th class="hidden lg:table-cell whitespace-nowrap px-6 py-3 border-b border-gray-200 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Crowdsourcing
                </th>
                <th class="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Sharing
                </th>
                <th class="hidden lg:table-cell whitespace-nowrap px-6 py-3 border-b border-gray-200 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Last updated
                </th>
                <th class="pr-6 py-3 border-b border-gray-200 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-100">
              <safezone-table-row v-for="record in safezone.data" :key="record.content.token" :content="record.content" :sharing="record.sharing" />
            </tbody>
          </table>
          <nav v-if="safezone.pagy && safezone.pagy.count > 10" class="min-w-full bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
            <div class="hidden sm:block">
              <p class="text-sm text-gray-700">
                Showing
                <span class="font-medium">{{ safezone.pagy.from }}</span>
                to
                <span class="font-medium">{{ safezone.pagy.to }}</span>
                of
                <span class="font-medium">{{ safezone.pagy.count }}</span>
                records
              </p>
            </div>
            <div class="flex-1 flex justify-between sm:justify-end">
              <a href="" @click.prevent="fetchSafezone(safezone.pagy.prev_url)" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                Previous
              </a>
              <a href="" @click.prevent="fetchSafezone(safezone.pagy.next_url)" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                Next
              </a>
            </div>
          </nav>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import SafezoneListRow from "./safezone-list-row";
import SafezoneTableRow from "./safezone-table-row";
import api from "../../api";

export default {
  components: { GridLoader, SafezoneListRow, SafezoneTableRow },
  data() {
    return {
      safezone: {
        data: [],
        pagy: null
      },
      loaded: false
    };
  },
  computed: {
    ...mapState(["refetchSafezone"]),
  },
  watch: {
    refetchSafezone: {
      handler() {
        if (this.refetchSafezone) {
          this.fetchSafezone();
        }
      }
    }
  },
  mounted() {
    this.fetchSafezone().then(() => {
      this.loaded = true;
    });
  },
  methods: {
    fetchSafezone(url = null) {
      const cleanUrl = url ? _.drop(url, 8).join("") : url;
      const endpoint = cleanUrl ? cleanUrl : `safezone_posts`;

      return new Promise(resolve => {
        api.get(endpoint).then(
          json => {
            this.safezone = json.data;
            this.$store.commit("setRefetchSafezone", false);
            resolve();
          },
          failure => {
            resolve();
          }
        );
      });
    }
  }
};
</script>
