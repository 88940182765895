<template>
  <div :id="`cap-rate-box-plot-${snapshotDate}`" />
</template>

<script>
import * as moment from "moment";
import Plotly from "plotly.js-dist";

export default {
  props: ["capRates", "snapshotDate"],
  data() {
    return {
      capRateData: [
        {
          y: this.capRates.map(rate => _.toNumber(rate)),
          boxpoints: "all",
          name: moment(this.snapshotDate).format("MMM YYYY"),
          marker: { color: "#5145CD" },
          jitter: 0.3,
          whiskerwidth: 0.2,
          type: "box"
        }
      ],
      capRateLayout: {
        yaxis: {
          title: "Stabilized cap rate"
          // range: [3, 10]
        },
        font: {
          family: "Inter var, Sans-Serif"
        },
        margin: { l: 80, r: 10, t: 10, b: 40 }
      }
    };
  },
  mounted() {
    Plotly.newPlot(
      `cap-rate-box-plot-${this.snapshotDate}`,
      this.capRateData,
      this.capRateLayout,
      {
        responsive: true,
        displaylogo: false,
        displayModeBar: false
      }
    );
  }
};
</script>
