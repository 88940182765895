<template>
  <div @mouseenter="disableMapScroll" @mouseleave="enableMapScroll" class="leaflet-control px-2 py-1 bg-white border-2 border-gray-300 rounded-md" :class="visible ? 'w-72' : 'w-12 h-12 flex'">
    <template v-if="visible">
      <div class="flex items-baseline">
        <h2 class="text-base font-bold text-gray-900">
          My Feed
        </h2>
        <div class="flex-shrink-0 self-center">
          <svg xmlns="http://www.w3.org/2000/svg" v-tooltip="'My Feed surfaces your recent notepad, safezone, bookmarks, and team/private conversations based on the map\'s location and filters.'" class="ml-1 h-5 w-5 text-gray-700" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
          </svg>
        </div>
        <a @click.prevent.stop="toggleVisibility" class="ml-2 text-xs leading-5 underline cursor-pointer">
          Show less
        </a>
      </div>
      <div v-if="zoom < 9" class="mt-1">
        <h2 class="text-sm font-medium text-gray-900">
          <!-- This example requires Tailwind CSS v2.0+ -->
          <div class="bg-blue-50 border-l-4 border-blue-400 p-1">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: solid/zoom-in -->
                <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M5 8a1 1 0 011-1h1V6a1 1 0 012 0v1h1a1 1 0 110 2H9v1a1 1 0 11-2 0V9H6a1 1 0 01-1-1z" />
                  <path fill-rule="evenodd" d="M2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8zm6-4a4 4 0 100 8 4 4 0 000-8z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <p class="text-sm text-blue-700">
                  Please zoom in
                </p>
              </div>
            </div>
          </div>
        </h2>
      </div>
      <div v-else id="feed-container" class="mt-1 flow-root max-h-48 sm:max-h-96 overflow-y-auto">
        <div v-if="fetching" class="mb-1 flex justify-center">
          <grid-loader :loading="fetching" size="4px" color="#5850ec" />
        </div>
        <ul v-else-if="feed.data.length > 0" class="mb-1">
          <strategic-feed-content v-for="content in feed.data" :id="`${content.badgeType || content.newsfeedType}-${comparisonId(content)}`" :key="comparisonId(content)" :content="content" :map="map" :feature-group="featureGroup" />
        </ul>
        <div v-else>
          <div class="text-center">
            <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
            </svg>
            <h3 class="mt-2 text-sm font-medium text-gray-900">Your personal feed</h3>
            <p class="mt-1 text-xs text-gray-500">
              My Feed surfaces your recent notepad, safezone, bookmarks, and team/private conversations based on the map's location and filters.
            </p>
            <div v-if="false" class="mt-4 mb-2">
              <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <!-- Heroicon name: solid/plus -->
                <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                </svg>
                Learn more
              </button>
            </div>
          </div>
        </div>
        <div v-if="!fetching && endOfFeed" :class="feed.data.length > 0 ? 'text-gray-500' : 'text-white'" class="text-sm text-center">End of feed</div>
        <infinite-loading
          v-else-if="!fetching && loaded"
          :identifier="currentUser.token"
          @infinite="incrementFeed"
        />
      </div>
    </template>
    <button v-else @click.prevent.stop="visible = !visible" v-tooltip="'My Feed'" class="w-full flex items-center justify-center text-gray-700">
      <avatar-photo :person="currentUser" circle-size="6" text-size="sm" />
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AvatarPhoto from "../avatar-photo.vue";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import InfiniteLoading from "vue-infinite-loading";
import StrategicFeedContent from "./strategic-feed-content.vue";
import api from "../../api";

export default {
  components: {
    AvatarPhoto,
    GridLoader,
    InfiniteLoading,
    StrategicFeedContent
  },
  props: ["map", "featureGroup"],
  data() {
    return {
      busy: false,
      loaded: false,
      endOfFeed: false,
      visible: true,
      feed: {
        data: [],
        pagy: null
      },
      fetching: false
    };
  },
  computed: {
    ...mapGetters(["hasMapFilters", "signedIn"]),
    ...mapState([
      "currentUser",
      "mapNearbyProperties",
      "mapFilters",
      "highlightedMapRecord"
    ]),
    zoom() {
      return this.map.getZoom();
    },
    pagination() {
      return this.feed.pagy;
    }
  },
  watch: {
    mapNearbyProperties: {
      handler: _.debounce(function() {
        if (this.mapNearbyProperties.length > 0) {
          this.fetchFeed();
        }
      }, 3000),
      deep: true
    },
    highlightedMapRecord: {
      handler: _.debounce(function() {
        if (
          this.highlightedMapRecord.contentType &&
          this.highlightedMapRecord.contentToken &&
          this.highlightedMapRecord.mapTool === "myFeed" &&
          this.highlightedMapRecord.touching === "popup" &&
          this.visible
        ) {
          this.$scrollTo(
            `#${this.highlightedMapRecord.contentType}-${this.highlightedMapRecord.contentToken}`,
            300,
            { container: "#feed-container" }
          );
        }
      }, 100),
      deep: true
    }
  },
  mounted() {
    this.fetchFeed();
  },
  methods: {
    toggleVisibility() {
      this.visible = !this.visible;

      if (!this.visible) {
        this.enableMapScroll();
      }
    },
    disableMapScroll() {
      this.map.scrollWheelZoom.disable();
    },
    enableMapScroll() {
      this.map.scrollWheelZoom.enable();
    },
    comparisonId(content) {
      switch (content.badgeType || content.newsfeedType) {
        case "deal":
        case "intel":
          return content.token;
        case "file":
          return content.localId;
        case "message":
        case "notepad":
        case "backchannel":
        case "team":
        case "property":
        default:
          return content.id;
      }
    },
    incrementFeed($state) {
      if (this.pagination) {
        const { count, to } = this.pagination;

        if (count === 0) {
          this.endOfFeed = true;
        } else if (to < count) {
          const cleanUrl = _.drop(this.pagination.next_url, 8).join("");
          const payload = {
            sources: this.mapFilters.sources.map(option => option.value),
            regions: this.mapFilters.regions.map(region => region.id),
            deals: this.mapFilters.deals.map(option => option.value),
            loans: this.mapFilters.loans.map(option => option.value),
            risks: this.mapFilters.risks.map(option => option.value),
            uses: this.mapFilters.uses.map(option => option.value),
            dates: this.mapFilters.dates
          };

          this.busy = true;
          api.post(cleanUrl, payload).then(json => {
            const { data, pagy } = json.data;

            this.feed.data = this.feed.data.concat(data);
            this.feed.pagy = pagy;
            $state.loaded();
            this.busy = false;
          });
        } else {
          this.endOfFeed = true;
          $state.complete();
        }
      }
    },
    fetchFeed() {
      const bounds = this.map.getBounds();
      const southwest = bounds.getSouthWest();
      const northeast = bounds.getNorthEast();
      const northeastLatLng = [northeast.lat, northeast.lng];
      const southwestLatLng = [southwest.lat, southwest.lng];
      const payload = {
        sources: this.mapFilters.sources.map(option => option.value),
        regions: this.mapFilters.regions.map(region => region.id),
        deals: this.mapFilters.deals.map(option => option.value),
        loans: this.mapFilters.loans.map(option => option.value),
        risks: this.mapFilters.risks.map(option => option.value),
        uses: this.mapFilters.uses.map(option => option.value),
        dates: this.mapFilters.dates
      };

      this.loaded = false;
      this.fetching = true;
      api
        .post(
          `map_activity/${southwestLatLng}/${northeastLatLng}?zoom=${this.map.getZoom()}`,
          payload
        )
        .then(json => {
          this.feed = json.data;
          this.fetching = false;
          this.loaded = true;

          if (this.feed.data.length > 0) {
            this.visible = true;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.leaflet-container a {
  @apply text-indigo-600 font-medium;
}
.leaflet-container a:hover {
  @apply text-indigo-500;
}
</style>
