import MultiContentPopupWrapper from "./components/maps/multi-content-popup-wrapper";
import Vue from "vue";
/* global L */

const MapPopupWrapper = Vue.extend(MultiContentPopupWrapper);

export default function responsivePreviewCardPopup({
  router,
  store,
  contentId,
  contentType,
  property
}) {
  const popupVueEl = new MapPopupWrapper({
    store,
    router,
    propsData: { contentId, contentType, property }
  }).$mount().$el;

  return L.responsivePopup().setContent(popupVueEl);
}
