<template>
  <div v-if="purchaseable" v-observe-visibility="{ callback: createImpression }" class="flex flex-col">
    <!-- Main -->
    <div class="divide-y divide-gray-200">
      <div class="pb-6">
        <div class="bg-gradient-to-r from-teal-500 to-green-400 h-24 sm:h-20 lg:h-28"></div>
        <div class="-mt-12 flow-root px-4 space-y-6 sm:-mt-8 sm:flex sm:items-end sm:px-6 sm:space-x-6 lg:-mt-15">
          <div>
            <div class="-m-1 flex">
              <div class="inline-flex rounded-lg overflow-hidden border-4 border-white">
                <div class="flex items-center justify-center text-white bg-gray-50 flex-shrink-0 h-24 w-24 sm:h-40 sm:w-40 lg:w-48 lg:h-48">
                  <!-- <svg class="h-12 w-12 sm:h-24 sm:w-24 lg:w-32 lg:h-32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path v-if="purchaseable.contentType === 'dataSet'" d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z" />
                    <path v-else d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
                  </svg> -->
                  <img class="h-auto w-full object-contain" src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png" alt="Tower Hunt" />
                </div>
              </div>
            </div>
          </div>
          <div class="space-y-5 sm:flex-1">
            <div class="sm:max-w-lg">
              <h3 class="font-bold text-xl leading-7 text-gray-900 sm:text-2xl sm:leading-8 truncate">{{ purchaseable.name }}</h3>
              <p class="text-sm leading-5 text-gray-500 truncate">{{ purchaseable.description }}</p>
            </div>
            <div class="flex flex-wrap">
              <span class="flex-shrink-0 w-full inline-flex rounded-md shadow-sm sm:flex-1">
                <button @click="purchaseableClickHandler" type="button" class="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
                  <template v-if="purchaseable.licensed">
                    <span class="uppercase">You own this</span>
                  </template>
                  <template v-else-if="purchaseable.roleBasedPrice">
                    <template v-if="hasPriceReduction">
                      <span class="line-through">${{ grossPrice | formattedNumber(2) }}<span v-if="isSubscription" class="font-normal">/mo</span></span>
                      <span class="ml-1">${{ netPrice | formattedNumber(2) }}<span v-if="isSubscription" class="font-normal">/mo</span></span>
                    </template>
                    <template v-else>
                      <span>${{ grossPrice | formattedNumber(2) }}<span v-if="isSubscription" class="font-normal">/mo</span></span>
                    </template>
                  </template>
                  <template v-else>
                    <span class="uppercase">Get</span>
                  </template>
                </button>
              </span>
              <span v-if="isAdmin" class="mt-3 flex-1 w-full inline-flex rounded-md shadow-sm sm:mt-0 sm:ml-3">
                <button type="button" class="w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
                  Something
                </button>
              </span>
              <span v-if="isAdmin" class="mt-3 ml-3 inline-flex rounded-md shadow-sm sm:mt-0">
                <div ref="purchaseableActionsMenu" class="relative inline-block text-left">
                  <button @click="toggleMenu" type="button" class="inline-flex items-center p-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-400 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
                    <!-- Heroicon name: dots-vertical -->
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                    </svg>
                  </button>

                  <!--
                    Dropdown panel, show/hide based on dropdown state.

                    Entering: "transition ease-out duration-100"
                      From: "transform opacity-0 scale-95"
                      To: "transform opacity-100 scale-100"
                    Leaving: "transition ease-in duration-75"
                      From: "transform opacity-100 scale-100"
                      To: "transform opacity-0 scale-95"
                  -->
                  <transition
                    enter-active-class="duration-100 ease-out"
                    enter-class="opacity-0 scale-95"
                    enter-to-class="opacity-100 scale-100"
                    leave-active-class="duration-75 ease-in"
                    leave-class="opacity-100 scale-100"
                    leave-to-class="opacity-0 scale-95"
                  >
                    <div v-show="menuOpen" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
                      <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
                        <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                          <a v-if="purchaseable.roleBasedPrice && isSubscription" href="" @click.prevent class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Cancel subscription</a>
                          <a href="" @click.prevent class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Something</a>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 py-5 sm:px-0 sm:py-0">
        <dl class="space-y-8 sm:space-y-0">
          <div class="grid grid-cols-1 gap-5 sm:px-6 sm:py-5 sm:grid-cols-3">
            <div class="bg-white overflow-hidden shadow rounded-lg">
              <div class="px-3 py-4 sm:p-5">
                <dl>
                  <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                    {{ purchaseable.reviewSummary.totalReviews | formattedNumber }} {{ reviewCount }}
                  </dt>
                  <dd class="mt-1 text-xl leading-9 font-semibold text-gray-900">
                    {{ positivePercentage }} <template v-if="hasReviews">Recommend</template>
                  </dd>
                </dl>
              </div>
            </div>
            <div class="bg-white overflow-hidden shadow rounded-lg">
              <div class="px-3 py-4 sm:p-5">
                <dl>
                  <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                    Linked to
                  </dt>
                  <dd class="mt-1 text-xl leading-9 font-semibold text-gray-900 truncate">
                    <span v-if="purchaseable.contentType === 'deal'" v-tooltip="`${purchaseable.contentName}`" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-yellow-100 text-yellow-800">
                      <svg class="-ml-0.5 mr-1.5 h-4 w-4 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
                      </svg>
                      {{ purchaseable.contentName | truncate(40) }}
                    </span>
                    <span v-else-if="purchaseable.contentType === 'standalonePurchaseable'" :class="`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-green-100 text-green-800 hover:bg-green-50 hover:text-green-900 focus:outline-none focus:ring-green focus:border-green-700 active:bg-green-200`">
                      <svg :class="`-ml-0.5 mr-1.5 h-4 w-4 text-green-400`" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
                      </svg>
                      Custom filters
                    </span>
                    <span v-else-if="purchaseable.contentType === 'dataSet'" :class="`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-green-100 text-green-800 hover:bg-green-50 hover:text-green-900 focus:outline-none focus:ring-green focus:border-green-700 active:bg-green-200`">
                      <svg :class="`-ml-0.5 mr-1.5 h-4 w-4 text-green-400`" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z" />
                      </svg>
                      Data set
                    </span>
                    <intel-type-badge v-else :post="postSnippet" :name-override="true" :override-length="40" />
                  </dd>
                </dl>
              </div>
            </div>
            <div class="bg-white overflow-hidden shadow rounded-lg">
              <div class="px-3 py-4 sm:p-5">
                <dl>
                  <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                    Seller
                  </dt>
                  <dd class="mt-1 text-xl leading-9 font-semibold text-gray-900">
                    Anonymous
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="sm:flex sm:space-x-6 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <dt class="text-sm leading-5 font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
              Full description
            </dt>
            <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ purchaseable.description }}
            </dd>
          </div>
          <div v-if="purchaseable.licensed || isPreview" class="sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <h2 class="text-lg font-medium text-gray-900">Licensed Content</h2>
            <div v-if="isPreview" class="bg-yellow-50 border-l-4 border-yellow-400 p-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <!-- Heroicon name: solid/exclamation -->
                  <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="ml-3">
                  <p class="text-sm text-yellow-700">
                    This is a seller preview. Normally purchase is required to view licensed content.
                  </p>
                </div>
              </div>
            </div>
            <ul class="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
              <li v-if="purchaseable.purchaseableNarrative" class="col-span-1 bg-white rounded-lg shadow">
                <div class="w-full p-6">
                  <div class="relative text-base leading-7 font-medium text-gray-700 md:flex-grow">
                    <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-6 w-6 text-gray-200" fill="currentColor" viewBox="0 0 32 32">
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <p v-html="linkifiedNarrative" class="relative whitespace-pre-line dont-break-out" />
                  </div>
                </div>
              </li>
              <li v-for="(attachment, index) in licensedAttachments" :key="index" class="col-span-1 bg-white rounded-lg shadow">
                <div class="w-full p-6 flex flex-col flex-1">
                  <div class="iframely-embed flex-1 mb-2">
                    <a data-iframely-url :href="attachment.file"></a>
                  </div>
                  <a :href="attachment.file" target="_blank" class="inline-flex items-center justify-center font-medium text-sm text-gray-700">
                    <!-- Heroicon name: download -->
                    <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd" />
                    </svg>
                    <span class="ml-2">Download</span>
                  </a>
                </div>
              </li>
              <review-purchaseable v-if="!purchaseable.reviewed && !isPreview" :purchaseable="purchaseable" @refetch="fetchPurchaseable" />
            </ul>
          </div>
          <div v-if="previewAttachments.length > 0 && (!purchaseable.licensed || isPreview)" class="sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <h2 class="text-lg font-medium text-gray-900">Preview</h2>
            <div v-if="isPreview" class="bg-yellow-50 border-l-4 border-yellow-400 p-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <!-- Heroicon name: solid/exclamation -->
                  <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="ml-3">
                  <p class="text-sm text-yellow-700">
                    Customers will see this content until they purchase. Edit the premium content to toggle which attachments are previews and which are paid.
                  </p>
                </div>
              </div>
            </div>
            <ul class="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
              <li v-for="(attachment, index) in previewAttachments" :key="index" class="col-span-1 bg-white rounded-lg shadow">
                <div class="w-full p-6 flex flex-col flex-1">
                  <div class="iframely-embed flex-1 mb-2">
                    <a data-iframely-url :href="attachment.file"></a>
                  </div>
                  <a :href="attachment.file" target="_blank" class="inline-flex items-center justify-center font-medium text-sm text-gray-700">
                    <!-- Heroicon name: download -->
                    <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd" />
                    </svg>
                    <span class="ml-2">Download</span>
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <purchaseable-reviews v-if="hasReviews" :purchaseable="purchaseable" class="sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" />
          <div v-if="purchaseable.contentType === 'dataSet'" class="sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <h2 class="text-lg font-medium text-gray-900">Data Set</h2>
            <data-set-element-list
              :purchaseable="purchaseable"
              :data-set-id="purchaseable.contentToken"
              :editable="false"
            />
          </div>
          <div v-else-if="linkedRecord" class="sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
            <h2 class="text-lg font-medium text-gray-900 mb-6">Linked Record</h2>
            <multi-content-preview-card
              :content="linkedRecord"
              :preview="true"
            />
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BuyPurchaseable from "../purchaseables/buy-purchaseable";
import DataSetElementList from "../purchaseables/data-set-element-list.vue";
import IntelTypeBadge from "../intel-type-badge";
import MultiContentPreviewCard from "./multi-content-preview-card";
import PurchaseableReviews from "../purchaseables/purchaseable-reviews";
import ReviewPurchaseable from "../purchaseables/review-purchaseable.vue";
import api from "../../api";
import linkifyHtml from "linkifyjs/html";
import pluralize from "pluralize";
/* global analytics */
/* global iframely */

export default {
  components: {
    IntelTypeBadge,
    MultiContentPreviewCard,
    PurchaseableReviews,
    ReviewPurchaseable,
    DataSetElementList
  },
  props: ["purchaseableId", "backgroundColor", "isPreview"],
  data() {
    return {
      purchaseable: null,
      menuOpen: false,
      linkedRecord: null
    };
  },
  computed: {
    ...mapState(["modal", "adminMode"]),
    ...mapGetters(["isAdmin", "signedIn"]),
    canFetchLinkedRecord() {
      return (
        this.purchaseable.contentType !== "standalonePurchaseable" &&
        this.purchaseable.contentType !== "dataSet"
      );
    },
    hasReviews() {
      return this.purchaseable.reviewSummary.totalReviews > 0;
    },
    isSubscription() {
      return this.purchaseable.roleBasedPrice.isSubscription;
    },
    hasPriceReduction() {
      return this.grossPrice !== this.netPrice;
    },
    netPrice() {
      return _.toNumber(this.purchaseable.netPrice);
    },
    grossPrice() {
      return _.toNumber(this.purchaseable.roleBasedPrice.price);
    },
    postSnippet() {
      return {
        name: this.purchaseable.contentName,
        type: this.purchaseable.contentType
      };
    },
    licensedAttachments() {
      return this.purchaseable.attachments.filter(a => a.visibility === "paid");
    },
    previewAttachments() {
      return this.purchaseable.attachments.filter(
        a => a.visibility === "preview"
      );
    },
    linkifiedNarrative() {
      return linkifyHtml(this.purchaseable.purchaseableNarrative, {
        defaultProtocol: "https",
        className:
          "font-medium text-indigo-600 hover:text-indigo-500"
      });
    },
    positivePercentage() {
      const percentage = this.purchaseable.reviewSummary.positivePercentage;

      if (percentage) {
        return `${percentage}%`;
      } else {
        return "Unrated";
      }
    },
    reviewCount() {
      return pluralize("Review", this.purchaseable.reviewSummary.totalReviews);
    }
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.purchaseableActionsMenu &&
        !this.$refs.purchaseableActionsMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });

    this.fetchPurchaseable();
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    createImpression(isVisible, entry) {
      if (!this.isAdmin && this.signedIn && isVisible) {
        this.$store
          .dispatch("createPurchaseableImpression", this.purchaseable.id)
          .then(() => {
            if (!!analytics && !this.adminMode) {
              analytics.track("Viewed premium content card", {
                yearWeek: this.$store.state.yearWeek,
                purchaseableId: this.purchaseable.id
              });
            }
          });
      }
    },
    purchaseableClickHandler() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      if (this.purchaseable.licensed) {
        console.log("view licensed content");
      } else if (this.purchaseable.roleBasedPrice) {
        this.$store.commit("openModal", {
          component: BuyPurchaseable,
          props: { purchaseable: this.purchaseable },
          afterClose: currentModal
        });
      } else if (this.signedIn) {
        console.log("claim free content");
      } else {
        this.$router.push({
          path: "/sign-in",
          query: { nextUrl: `/market/featured` }
        });
        this.$store.commit("closeModal");
      }
    },
    fetchPurchaseable() {
      api.get(`purchaseables/${this.purchaseableId}`).then(json => {
        this.purchaseable = json.data;

        if (this.canFetchLinkedRecord) {
          this.fetchLinkedRecord();
        }

        const hasFiles =
          this.previewAttachments.length > 0 || this.licensedAttachments.length > 0;

        if (hasFiles && window.iframely) {
          setTimeout(() => {
            iframely.load();
          }, 500);

          iframely.on("cancel", function(url, parentNode) {
            parentNode.remove();
          });
        }
      });
    },
    fetchLinkedRecord() {
      const endpoint =
        this.purchaseable.contentType === "deal" ? "deal" : "intel";

      api.get(`open_${endpoint}/${this.purchaseable.contentToken}`).then(
        json => {
          this.linkedRecord = json.data;
        },
        failure => {}
      );
    }
  }
};
</script>
