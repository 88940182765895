<template>
  <div>
    <div class="bg-white overflow-y-auto" style="max-height: 400px;">
      <ul v-if="searchResults && searchResults.length > 0" class="w-full">
        <template v-if="currentUser.searchCategory === 'people'">
          <search-result-contact-row
            v-for="(contact, index) in searchResults"
            :key="contact.id"
            :contact="contact"
            :class="{ 'border-t': index !== 0, 'border-gray-200': index !== 0 }"
          />
        </template>
        <template v-else-if="currentUser.searchCategory === 'intel'">
          <search-result-row
            v-for="(post, index) in searchResults"
            :key="post.token"
            :post="post"
            :index="index"
            :class="{ 'border-t': index !== 0, 'border-gray-200': index !== 0 }"
          />
        </template>
      </ul>
      <div v-else class="max-w-7xl mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
        <router-link to="" class="-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50">
          <div class="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
            <div class="flex-shrink-0">
              <span class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                </svg>
              </span>
            </div>
            <div class="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
              <div class="space-y-1">
                <p class="text-base leading-6 font-medium text-gray-900">
                  Search
                </p>
                <p class="text-sm leading-5 text-gray-500">
                  Start typing to find {{ searchDescription }}.
                </p>
              </div>
            </div>
          </div>
        </router-link>
        <router-link to="/targets/new/1" class="-m-3 p-3 flex flex-col justify-between space-y-6 rounded-lg hover:bg-gray-50">
          <div class="space-x-4 flex md:h-full lg:flex-col lg:space-x-0 lg:space-y-4">
            <div class="flex-shrink-0">
              <span class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"/>
                </svg>
              </span>
            </div>
            <div class="space-y-2 md:flex-1 md:flex md:flex-col md:justify-between lg:space-y-4">
              <div class="space-y-1">
                <p class="text-base leading-6 font-medium text-gray-900">
                  Looking for more detail?
                </p>
                <p class="text-sm leading-5 text-gray-500">
                  Create a market to discover {{ currentUser.searchCategory }} using multiple filters.
                </p>
                <p class="text-sm leading-5 font-medium text-indigo-600">
                  Get started &rarr;
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="bg-gray-50">
      <div class="max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
        <div class="flow-root">
          <router-link @click.native="category('people')" to="" class="-m-3 p-3 space-x-3 flex items-center rounded-md text-base leading-6 font-medium text-gray-900" :class="{ 'bg-gray-200': isCategory('people'), 'hover:bg-gray-100': !isCategory('people') }">
            <svg class="flex-shrink-0 h-6 w-6" :class="{ 'text-gray-900': isCategory('people'), 'text-gray-400': !isCategory('people') }" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
            </svg>
            <span>People</span>
          </router-link>
        </div>
        <div class="flow-root">
          <router-link @click.native="category('intel')" to="" class="-m-3 p-3 space-x-3 flex items-center rounded-md text-base leading-6 font-medium text-gray-900" :class="{ 'bg-gray-200': isCategory('intel'), 'hover:bg-gray-100': !isCategory('intel') }">
            <svg class="flex-shrink-0 h-6 w-6" :class="{ 'text-gray-900': isCategory('intel'), 'text-gray-400': !isCategory('intel') }" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"/>
            </svg>
            <span>Intel</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SearchResultContactRow from "./search-result-contact-row";
import SearchResultRow from "./search-result-row";

export default {
  components: {
    SearchResultContactRow,
    SearchResultRow
  },
  computed: {
    ...mapState(["currentUser", "search", "searchResults", "nameFilter"]),
    searchDescription() {
      switch (this.currentUser.searchCategory) {
        case "people":
          return "people by name or company";
        case "intel":
          return "deals and intel by name, property, market, and keyword";
        default:
          return "content";
      }
    }
  },
  mounted() {
    if (this.search) {
      this.$store.dispatch("executeSearch");
    } else {
      this.$store.commit("initiateSearch");
    }
  },
  methods: {
    category(newCategory) {
      this.$store.commit("setSearchResults", null);
      this.$store.dispatch("updateUserSearchCategory", newCategory).then(() => {

        if (this.nameFilter && this.nameFilter.length > 2) {
          this.$store.dispatch("executeSearch");
        }
        document.getElementById("search_field").focus();
      });
    },
    isCategory(category) {
      return this.currentUser.searchCategory === category;
    }
  }
};
</script>
