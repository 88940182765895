<template>
  <div v-if="loaded" class="wrapper">
    <heading id="property-top">
      <template v-if="sizingProperty && possibleUses">What uses are possible here?</template>
      <template v-else-if="sizingProperty">How big is it?</template>
      <template v-else-if="namingProperty">Let's name this property</template>
      <template v-else-if="hasLocation">Is the marker correct?</template>
      <template v-else>What is the address or name?</template>
    </heading>
    <button v-if="sizingProperty" @click="togglePossibleUses" type="button" class="cta mb-2" :class="{ complete: true }" tabindex="999">
      <template v-if="possibleUses">Let me enter actual sizes</template>
      <template v-else>Let me select possible uses</template>
    </button>

    <property-map v-if="mapShouldDisplay" @has-nearby="hasNearbyProperties = true" @no-nearby="hasNearbyProperties = false" :class="{ 'h-96': creatingDeal, 'h-64': !creatingDeal }" class="mb-8 w-11/12 sm:max-w-xl sm:w-full" />
    <form @submit.prevent autocomplete="off" novalidate>
      <div v-if="!hasLocation" class="input-container">
        <gmap-autocomplete
          v-focus
          :value="activeProperty.location"
          @place_changed="setAddress"
          @focus="focus"
          @blur="blur"
          :options="{fields: ['geometry', 'formatted_address', 'address_components', 'name']}"
          autocomplete="new-password"
          autocorrect="off"
          spellcheck="false"
          placeholder=""
          tabindex="1"
          name="user_location"
          id="gmap-autocomplete"
          class="input-text black"
          :class="{ active: focused }" >
          <template v-slot:input="slotProps">
            <input ref="input" type="text"
              v-on:listeners="slotProps.listeners"
              v-on:attrs="slotProps.attrs" />
          </template>
        </gmap-autocomplete>
        <label class="input-label" :class="{ active: focused }">Address or name</label>
        <label class="email-icon" :class="{ active: focused }">
          <i class="fas fa-map-marker-alt"></i>
        </label>
      </div>

      <option-description v-if="mapHelp" :description="mapHelp" style="margin-bottom: 20px;" />

      <property-name-field v-if="namingProperty" :visible="namingProperty" @submit="sizeProperty" />

      <property-possible-uses-field v-if="sizingProperty && possibleUses" @submit="save" />
      <property-size-field v-else-if="sizingProperty" @submit="save" />

      <template v-if="editingMarker">
        <button @click="nameProperty" type="button" class="cta complete" tabindex="999">Looks good</button>
        <button @click="clearLocation" type="button" class="secondary">Look up new location</button>
      </template>
      <template v-else-if="hasLocation">
        <template v-if="sizingProperty && isWatchlist">
          <button @click.stop="saveAndRedirectToProperty" type="button" class="cta single" :class="{ complete: propertyComplete }" tabindex="999">Save then visit property</button>
          <button @click.stop="save" type="button" class="secondary">Save and stay on watchlist</button>
        </template>
        <button v-else-if="sizingProperty" @click.stop="save" type="button" class="cta single" :class="{ complete: propertyComplete }" tabindex="999">Save</button>
        <button v-else @click="sizeProperty" type="button" class="cta single" :class="{ complete: nameComplete }" tabindex="999">Next</button>
        <button v-if="namingProperty" @click="stopNaming" type="button" class="secondary">Edit marker location</button>
        <button v-if="sizingProperty" @click="stopSizing" type="button" class="secondary">Edit location and name</button>
      </template>
    </form>
  </div>
</template>

<script>
import {
  GoogleCityTag,
  GoogleStateTag,
  GoogleStreetNumberTag,
  GoogleStreetTag
} from "../../../googleTags.js";
import { mapGetters, mapState } from "vuex";
import Heading from "../../../components/onboarding/heading";
import OptionDescription from "../../../components/onboarding/option-description";
import PropertyMap from "./property-map";
import PropertyNameField from "../../../components/onboarding/property-name-field";
import PropertyPossibleUsesField from "../../../components/onboarding/property-possible-uses-field";
import PropertySizeField from "../../../components/onboarding/property-size-field";
import Swal from "sweetalert2";
import dedupeOptions from "../../../sweet-alert-dedupe-options";

export default {
  components: {
    Heading,
    PropertyMap,
    OptionDescription,
    PropertyNameField,
    PropertyPossibleUsesField,
    PropertySizeField
  },
  props: ["propertyId"],
  data() {
    return {
      focused: false,
      possibleUses: false,
      loaded: false,
      hasNearbyProperties: false
    };
  },
  computed: {
    ...mapState(["activeProperty", "modal", "deduplicateRecord", "route"]),
    ...mapGetters(["propertyComplete", "creatingDeal"]),
    dealId() {
      return _.get(this.$store.state, "activeDeal.id", null);
    },
    mapShouldDisplay() {
      return this.hasLocation && !this.sizingProperty;
    },
    newProperty() {
      return !this.propertyId && !_.get(this.activeProperty, "id");
    },
    editingMarker() {
      return this.hasLocation && !this.namingProperty && !this.sizingProperty;
    },
    namingProperty() {
      return _.get(this.activeProperty, "naming", false);
    },
    sizingProperty() {
      return _.get(this.activeProperty, "sizing", false);
    },
    mapHelp() {
      if (this.editingMarker) {
        return "Change the marker location by dragging it.";
      } else {
        return null;
      }
    },
    hasLocation() {
      return this.activeProperty.lat && this.activeProperty.lng;
    },
    nameComplete() {
      return (
        this.activeProperty.name !== "" && this.activeProperty.name !== null
      );
    },
    isWatchlist() {
      return this.route.path === "/home/watchlist";
    }
  },
  watch: {
    hasLocation: {
      handler() {
        if (!this.hasLocation && document.getElementById("gmap-autocomplete")) {
          document.getElementById("gmap-autocomplete").focus();
        }
      }
    },
    deduplicateRecord: {
      handler() {
        const modalAfterClose = _.get(this.modal, "afterClose", null);

        if (modalAfterClose && this.deduplicateRecord) {
          if (this.isWatchlist) {
            this.$store.commit(
              "addWatchlistProximityCompetitiveSetProperty",
              this.deduplicateRecord
            );
          } else {
            this.$store.dispatch(
              "addPropertyWithGetters",
              this.deduplicateRecord
            );
          }
          this.$store.commit("clearDeduplicate");
          this.$store.commit("closeModal");
        }
      }
    }
  },
  mounted() {
    if (this.propertyId) {
      this.$store.dispatch("loadProperty", this.propertyId).then(() => {
        if (this.activeProperty.possibleUses.length > 0) {
          this.possibleUses = true;
        }
        this.loaded = true;
      });
    } else {
      this.loaded = true;
    }
  },
  destroyed() {
    this.$store.commit("clearProperty");
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    togglePossibleUses() {
      if (this.possibleUses) {
        this.possibleUses = false;
        this.$store.commit("clearPropertyPossibleUses");
      } else {
        this.possibleUses = true;
        this.$store.commit("clearPropertySizes");
      }
    },
    nameProperty() {
      if (!this.propertyId && this.hasNearbyProperties) {
        Swal.fire(dedupeOptions).then(result => {
          if (result.value) {
            this.$store.commit("setActivePropertyNaming", true);
          }
        });
      } else {
        this.$store.commit("setActivePropertyNaming", true);
      }
    },
    sizeProperty() {
      this.stopNaming();
      this.$store.commit("setActivePropertySizing", true);
    },
    saveAndRedirectToProperty() {
      if (this.propertyComplete) {
        this.$store.dispatch("createProperty", {
          announce: true,
          propertyPageRedirect: true
        });
        this.$store.commit("closeModal");
      }
    },
    save() {
      if (this.propertyComplete) {
        if (this.newProperty) {
          this.$store.dispatch("createProperty", { announce: true });
          this.$store.commit("closeModal");
        } else if (this.propertyId) {
          this.$store.dispatch("updateProperty", { announce: false, propertyId: this.propertyId});
          this.$store.commit("closeModal");
        }

        this.$store.commit("setActivePropertyNaming", false);
        this.$store.commit("setActivePropertySizing", false);
      }
    },
    setAddress(addressData) {
      if (addressData) {
        const lat = addressData.geometry.location.lat();
        const lng = addressData.geometry.location.lng();
        const location = addressData.formatted_address;
        const cityState = this.setCityState(addressData);

        this.setPropertyName(addressData);

        this.$store.commit("setPropertyAddress", {
          lat,
          lng,
          location,
          cityState
        });
      }
    },
    setCityState(addressData) {
      const city = this.findGeoTag(addressData, GoogleCityTag);
      const state = this.findGeoTag(addressData, GoogleStateTag);

      if (city !== "" && state !== "") {
        return `${city}, ${state}`;
      } else if (city === "" && state !== "") {
        return state;
      } else if (city !== "" && state === "") {
        return city;
      } else {
        return "";
      }
    },
    setPropertyName(addressData) {
      if (!_.isNull(this.activeProperty.name) && this.activeProperty.name !== "") {
        return;
      }
      const rawStreetNumber = this.findGeoTag(
        addressData,
        GoogleStreetNumberTag
      );

      if (rawStreetNumber !== "") {
        const name = `${rawStreetNumber} ${this.findGeoTag(
          addressData,
          GoogleStreetTag
        )}`;

        this.$store.commit("setPropertyName", name);
      } else {
        const name = `${this.findGeoTag(addressData, GoogleStreetTag)}`;

        this.$store.commit("setPropertyName", name);
      }
    },
    findGeoTag(addressData, tag) {
      const addressComponent = addressData.address_components.filter(
        component => component.types.includes(tag)
      );

      if (addressComponent[0]) {
        return addressComponent[0].short_name;
      } else {
        return "";
      }
    },
    clearLocation() {
      this.$store.commit("clearProperty");
    },
    stopNaming() {
      this.$store.commit("setActivePropertyNaming", false);
    },
    stopSizing() {
      this.$store.commit("setActivePropertySizing", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
input {
  vertical-align: middle;
}
.subhead {
  padding: 0 20px;
}
.input-container {
  position: relative;
  margin-bottom: 20px;
}
.location-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 26px 38px 8px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.active {
    border: 2px solid #5850ec;
  }
}
.input-label {
  position: absolute;
  top: 8px;
  left: 38px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
.email-icon {
  position: absolute;
  top: 19px;
  left: 13px;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
.clear-action {
  position: absolute;
  top: 19px;
  right: 13px;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  pointer-events: all;
  cursor: pointer;
  color: #b7b7b7;
}
button {
  width: 330px;
  margin-bottom: 5px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
