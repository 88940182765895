import * as moment from "moment";
import _ from "lodash";

export default class User {
  constructor(data) {
    this.id = data.id;
    this.token = data.token;
    this.productTier = data.productTier;
    this.billingFrequency = data.billingFrequency;
    this.activated = data.activated;
    this.userCreatedAt = data.userCreatedAt;
    this.signupMonthYear = moment(data.userCreatedAt).format("MMM YYYY");
    this.lastActive = data.lastActive;
    this.searchable = data.searchable;
    this.privacyMode = data.privacyMode;
    this.mapMode = data.mapMode;
    this.mapModeTransition = data.mapModeTransition;
    this.mapDefaultLayer = data.mapDefaultLayer;
    this.searchCategory = data.searchCategory;
    this.newsfeedSortBy = data.newsfeedSortBy;
    this.newsfeedWelcome = data.newsfeedWelcome;
    this.dealMarketingWelcome = data.dealMarketingWelcome;
    this.marketPageWelcome = data.marketPageWelcome;
    this.contactProfileWelcome = data.contactProfileWelcome;
    this.name = data.name;
    this.avatar = data.avatar;
    this.email = data.email;
    this.principalVisible = data.principalVisible;
    this.brokerVisible = data.brokerVisible;
    this.observerVisible = data.observerVisible;
    this.company = data.company;
    this.historicalCompanies = data.historicalCompanies;
    this.schools = data.schools;
    this.marketEmail = data.marketEmail;
    this.phone = data.phone;
    this.shareMarketEmail = data.shareMarketEmail;
    this.sharePhone = data.sharePhone;
    this.canChangeIndustryRole = data.canChangeIndustryRole;
    this.industryRole = data.industryRole;
    this.industrySubRole = data.industrySubRole;
    this.productNewsletterConsent = data.productNewsletterConsent;
    this.targetFollowedConsent = data.targetFollowedConsent;
    this.digestsConsent = data.digestsConsent;
    this.dealDigestsConsent = data.dealDigestsConsent;
    this.unreadDiscussionsConsent = data.unreadDiscussionsConsent;
    this.jobDigestsConsent = data.jobDigestsConsent;
    this.offeredDealConsent = data.offeredDealConsent;
    this.bountyConsent = data.bountyConsent;
    this.followerCount = data.followerCount;
    this.followingCount = data.followingCount;
    this.dealCount = data.dealCount;
    this.reputationLevel = data.reputationLevel;
    this.nextReputationLevelCredits = data.nextReputationLevelCredits;
    this.creditTracking = data.creditsCount;
    this.creditsCountDailySnapshot = data.creditsCountDailySnapshot;
    this.creditsCountWeeklySnapshot = data.creditsCountWeeklySnapshot;
    this.creditsCount = _.get(this.creditTracking, "totalCredits", 0);
    this.creditsCountSnapshot = _.get(
      this.creditsCountDailySnapshot,
      "totalCredits",
      0
    );
    this.earnings = data.earnings;
    this.spendableCredits = data.spendableCredits;
    this.dashboardEnabled = data.dashboardEnabled;
    this.reputation = data.reputation;
    this.backchannels = data.backchannels;
    this.teamsCount = data.teamsCount;
    this.newsfeedUnreadCount = 0;
    this.networkUnreadCount = 0;
    this.pipelineUnreadCount = 0;
    this.jobsUnreadCount = 0;
    this.marketUnreadCounts = [];
    this.backchannelUnreadCounts = [];
    this.offeredDealUnreadCounts = [];
    this.hubFeaturesExpanded = data.hubFeaturesExpanded;
    this.hubWatchlistExpanded = data.hubWatchlistExpanded;
    this.hubMarketsExpanded = data.hubMarketsExpanded;
    this.hubOfferingsExpanded = data.hubOfferingsExpanded;
    this.hubChannelsExpanded = data.hubChannelsExpanded;
    this.admin = data.admin;
    this.signedIn = data.signedIn;
    this.contacts = {
      mutualConnections: [],
      fellowClosedDealParticipants: [],
      followedUsers: [],
      followingUsers: []
    };
    this.watchlistProperties = data.watchlistProperties;
    this.pipeline = {
      direct: {
        data: [],
        pagy: null
      },
      indirect: {
        data: [],
        pagy: null
      }
    };
    this.jobs = {
      premium: {
        data: [],
        pagy: null
      },
      network: {
        data: [],
        pagy: null
      }
    };
  }
}
