<template>
  <div :class="privacyMode ? 'bg-gray-700' : lightColor">
    <div :class="padding" class="flex items-center justify-between">
      <div v-tooltip="privacyModeHelp" :class="privacyMode ? 'text-gray-300' : 'text-gray-900'" class="flex items-center text-sm leading-6 font-medium">
        <svg v-if="privacyMode" class="-ml-1 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 384 512">
          <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
        </svg>
        <h3 id="privacy-headline">
          Privacy {{ privacyMode ? "on" : "off" }}
        </h3>
      </div>
      <div class="flex-shrink-0 flex items-center">
        <span @click="togglePrivacy" role="checkbox" tabindex="0" :aria-checked="privacyMode" :class="privacyMode ? 'bg-pink-600' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring">
          <span aria-hidden="true" :class="privacyMode ? 'translate-x-5 bg-gray-700' : 'translate-x-0 bg-white'" class="relative inline-block h-5 w-5 rounded-full shadow transform transition ease-in-out duration-200">
            <span :class="privacyMode ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity">
              <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
            <span :class="privacyMode ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity">
              <svg class="h-3 w-3 text-pink-600" fill="currentColor" viewBox="0 0 12 12">
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            </span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import privacyModeOptions from "../sweet-alert-privacy-mode-options";

export default {
  props: ["lightBackground", "customPadding"],
  computed: {
    ...mapGetters(["privacyMode"]),
    privacyModeHelp() {
      if (this.privacyMode) {
        return "Any post, deal, or comment you add will be anonymous.";
      } else {
        return "You may add posts, deals, and comments as yourself.";
      }
    },
    lightColor() {
      return this.lightBackground || "bg-white";
    },
    padding() {
      return this.customPadding || "p-4";
    }
  },
  methods: {
    togglePrivacy() {
      this.savePrivacy();
    },
    savePrivacy() {
      Swal.fire(privacyModeOptions).then(result => {
        if (result.value) {
          const payload = { privacyMode: !this.privacyMode };

          this.$store.dispatch("updatePrivacyMode", payload);
        }
      });
    }
  }
};
</script>
