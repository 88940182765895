<template>
  <div>
    <team-form v-if="creating" :team-id="teamId" @cancel="notCreating" @saved="notCreating" />
    <team-index v-else @create-team="creating = true" @edit-team="editTeam" />
  </div>
</template>

<script>
import TeamForm from "../../components/team-form";
import TeamIndex from "../../components/team-index";

export default {
  components: { TeamForm, TeamIndex },
  data() {
    return {
      creating: false,
      teamId: null
    };
  },
  methods: {
    notCreating() {
      this.creating = false;
      this.teamId = null;
    },
    editTeam(id) {
      this.teamId = id;
      this.creating = true;
    }
  }
};
</script>
