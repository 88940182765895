<template>
  <div class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
    <div v-if="editing" class="px-4 py-5 sm:px-6">
      <div>
        <label for="company-name" class="block text-sm font-medium text-gray-700">Company name and role</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <!-- Heroicon name: search -->
            <svg class="h-5 w-5 text-gray-400" style="z-index: 1;" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
            </svg>
          </div>
          <autocomplete
            id="company-search"
            ref="companySearch"
            type="text"
            base-class="custom-autocomplete-leading-icon"
            :search="searchCompanies"
            :auto-select="true"
            :get-result-value="getCompanyResultValue"
            :debounce-time="500"
            :disabled="!!involvedCompany"
            @submit="handleCompanySubmit"
            @keydown="handleKeyDown"
            @blur="handleBlur"
            placeholder="Company name"
          />
          <div class="absolute inset-y-0 right-0 flex items-center">
            <label for="company-involvement" class="sr-only">Company involvement</label>
            <select v-model="companyRole" id="company-involvement" name="company-involvement" class="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
              <option v-for="role in companyRoles" :key="role.value" :value="role.value">{{ role.name }}</option>
            </select>
          </div>
        </div>
        <p v-if="involvedCompany" class="mt-2 text-sm text-gray-500" id="email-description">Company name editing is currently disabled.</p>
      </div>
    </div>
    <div v-else-if="involvedCompany" class="px-4 py-5 sm:px-6">
      <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
        <div class="ml-4 mt-4">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ involvedCompany.name }}
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              {{ involvedCompany.role | capitalize }}
            </p>
          </div>
        </div>
        <div class="ml-4 mt-4 flex-shrink-0 flex">
          <button @click="edit" type="button" class="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <!-- Heroicon name: solid/pencil -->
            <svg class="-ml-1 mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
            </svg>
            <span>
              Edit
            </span>
          </button>
          <button @click="remove" type="button" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <!-- Heroicon name: solid/x -->
            <svg class="-ml-1 mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
            <span>
              Remove
            </span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="involvedCompany" class="px-4 py-5 sm:p-6">
      <h2 class="mb-2 text-gray-500 text-xs font-medium uppercase tracking-wide">Contacts</h2>
      <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <small-contact-card v-for="contact in contacts" :key="contact.id" :contact="contact" :involved-company="involvedCompany" @removed="fetchContacts" />

        <button v-if="!editing" @click="newContact" type="button" class="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none" viewBox="0 0 24 24" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
          </svg>
          <span class="mt-2 block text-sm font-medium text-gray-900">
            Add a contact
          </span>
        </button>
      </ul>
    </div>
    <div v-if="editing" class="px-4 py-3 bg-gray-50 flex justify-end sm:px-6">
      <button @click="cancel" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Cancel
      </button>
      <button @click="save" type="button" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContactSlideover from "../../contact-slideover.vue";
import SmallContactCard from "./small-contact-card.vue";
import Swal from "sweetalert2";
import api from "../../../api";
import dealRoles from "../../../deal-type-roles";
import jvRoles from "../../../jv-roles";
import removeInvolvedCompanyOptions from "../../../sweet-alert-remove-involved-company-options";

export default {
  components: { SmallContactCard },
  props: ["involvedCompany", "content", "contentType", "safezone"],
  data() {
    return {
      editing: false,
      companyName: null,
      companyRole: null,
      contacts: []
    };
  },
  computed: {
    ...mapState(["refetchInvolvedCompanyContacts"]),
    companyRoles() {
      switch (this.contentType) {
        case "deal":
          switch (this.content.type) {
            case "Recap Equity":
              return jvRoles;
            default:
              return _.get(dealRoles, this.content.type, []);
          }
        case "property":
          return [
            { name: "Owner", value: "owner" },
            { name: "Lender", value: "lender" },
            { name: "Landlord broker", value: "landlord broker" },
            { name: "Property manager", value: "property manager" }
          ];
        default:
          return [];
      }
    }
  },
  watch: {
    refetchInvolvedCompanyContacts: {
      handler() {
        if (this.refetchInvolvedCompanyContacts === this.involvedCompany.id) {
          this.fetchContacts();
        }
      }
    }
  },
  mounted() {
    if (this.involvedCompany) {
      this.fetchContacts();
    } else {
      this.editing = true;
      var self = this;

      setTimeout(() => {
        document.getElementById("company-search").focus();
        self.companyRole = _.head(self.companyRoles).value;
      }, 50);
    }
  },
  methods: {
    cancel() {
      if (this.involvedCompany) {
        this.editing = false;
        this.companyName = null;
        this.companyRole = null;
      } else {
        this.$emit("cancel");
      }
    },
    edit() {
      if (this.involvedCompany) {
        const { name, role } = this.involvedCompany;
        var self = this;

        this.editing = true;

        setTimeout(() => {
          self.companyName = name;
          self.$refs.companySearch.value = name;
          self.companyRole = role;
          document.getElementById("company-involvement").focus();
        }, 50);
      }
    },
    fetchContacts() {
      api
        .get(`involved_company_contacts/${this.involvedCompany.id}`)
        .then(json => {
          this.contacts = json.data;
          this.$store.commit("companyContactsRefetched");
        });
    },
    newContact() {
      this.$store.commit("openSlideover", {
        component: ContactSlideover,
        props: {
          involvedCompany: this.involvedCompany,
          contact: null,
          editAtStartup: true
        }
      });
    },
    searchCompanies(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        const query = input === "" || input === null ? null : _.toLower(input);

        this.$store.dispatch("executeCompanySearch", query).then(
          json => {
            resolve(json.data);
          },
          failure => {
            this.$store.dispatch("flash", "Invalid search");
          }
        );
      });
    },
    getCompanyResultValue(result) {
      return result;
    },
    handleBlur() {
      const input = this.$refs.companySearch.value;
      const query = input === "" || input === null ? null : input;

      if (query) {
        this.handleCompanySubmit();
      } else {
        this.companyName = null;
      }
    },
    handleKeyDown(e) {
      const input = this.$refs.companySearch.value;
      const query = input === "" || input === null ? null : input;

      if (query && ["Tab", ",", " "].includes(e.key)) {
        this.handleCompanySubmit();
      }
    },
    handleCompanySubmit(result) {
      const output = result ? result : this.$refs.companySearch.value;

      this.companyName = output;
    },
    remove() {
      Swal.fire(removeInvolvedCompanyOptions).then(result => {
        if (result.value) {
          const involvedCompanyPayload = {
            involvementId: this.involvedCompany.id,
            contentType: this.contentType,
            contentToken: this.content.token || this.content.id,
            safezone: this.safezone
          };

          this.$store
            .dispatch("removeInvolvedCompany", involvedCompanyPayload)
            .then(() => {
              this.$emit("completed");
              this.editing = false;
              this.companyName = null;
              this.companyRole = null;
            });
        }
      });
    },
    save() {
      if (this.involvedCompany) {
        this.updateCompanyInvolvement();
      } else {
        this.saveCompany();
      }
    },
    updateCompanyInvolvement() {
      const involvedCompanyPayload = {
        involvementId: this.involvedCompany.id,
        companyRole: this.companyRole,
        contentType: this.contentType,
        contentToken: this.content.token || this.content.id,
        safezone: this.safezone
      };

      this.$store
        .dispatch("updateInvolvedCompany", involvedCompanyPayload)
        .then(involvedCompany => {
          this.$emit("completed");
          this.editing = false;
          this.companyName = null;
          this.companyRole = null;
        });
    },
    saveCompany() {
      const fetchCompanyName = this.companyName;

      if (fetchCompanyName) {
        this.$store
          .dispatch("createCompany", fetchCompanyName)
          .then(company => {
            const involvedCompanyPayload = {
              companyId: company.id,
              companyRole: this.companyRole,
              contentType: this.contentType,
              contentToken: this.content.token || this.content.id,
              safezone: this.safezone
            };

            this.$store
              .dispatch("createInvolvedCompany", involvedCompanyPayload)
              .then(involvedCompany => {
                this.$emit("completed");
              });
          });
      }
    }
  }
};
</script>
