<template>
  <div class="px-4 mt-6 sm:px-6 lg:px-8">
    <ul v-if="cards.data.length > 0" class="mt-3 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <li v-for="card in cards.data" :key="card.token" class="col-span-1 flex flex-col flex-1">
        <flippable-card :card="card" />
      </li>

      <infinite-loading
        v-if="loaded && !endOfFeed"
        :identifier="currentUser.token"
        @infinite="incrementCards"
      />
    </ul>
    <div v-else>
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div class="bg-indigo-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div class="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div class="lg:self-center">
              <h2 class="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10">
                <span>Hunt off-market assets</span>
              </h2>
              <p class="mt-4 text-lg leading-6 text-indigo-200">Once you open some card packs, the assets you're interested in will display here. <router-link to="/product/off-market" class="underline">Learn more</router-link></p>
            </div>
          </div>
          <div class="relative pb-3/5 -mt-6 md:pb-1/2">
            <img class="absolute inset-0 w-full h-full transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20" src="https://assets.towerhunt.com/site/offMarketWatchlist0.jpg" alt="App screenshot">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FlippableCard from "../components/cards/off-market-watchlist/flippable-card";
import InfiniteLoading from "vue-infinite-loading";

import api from "../api";

export default {
  components: { FlippableCard, InfiniteLoading },
  data() {
    return {
      cards: {
        data: [],
        pagy: null
      },
      busy: false,
      endOfFeed: false,
      loaded: false
    };
  },
  computed: {
    ...mapState(["currentUser", "temporaryAccess"]),
    pagination() {
      return this.cards.pagy;
    }
  },
  watch: {
    temporaryAccess: {
      handler() {
        if (_.isArray(this.temporaryAccess)) {
          const newCards = _.unionBy(
            [this.temporaryAccess],
            this.cards.data,
            "id"
          );

          this.cards.data = newCards;
          this.$store.commit("clearTemporaryAccess");
        }
      }
    }
  },
  mounted() {
    this.fetchCards();
  },
  methods: {
    fetchCards() {
      api.get(`property_snapshots_watchlist`).then(json => {
        this.cards = json.data;
        this.loaded = true;
      });
    },
    extendCards({ data, pagy }) {
      this.cards.data = this.cards.data.concat(data);
      this.cards.pagy = pagy;
    },
    incrementCards($state) {
      if (this.pagination) {
        const { count, to } = this.pagination;

        if (count === 0) {
          this.endOfFeed = true;
        } else if (to < count) {
          const cleanUrl = _.drop(this.pagination.next_url, 8).join("");

          this.busy = true;

          api.get(cleanUrl).then(json => {
            this.extendCards(json.data);
            $state.loaded();
            this.busy = false;
          });
        } else {
          this.endOfFeed = true;
          $state.complete();
        }
      }
    }
  }
};
</script>
