export default {
  title: "Edits are always anonymous",
  text: "We're committed to protecting your privacy.",
  showCancelButton: false,
  showCloseButton: true,
  confirmButtonColor: "#d61f69",
  cancelButtonColor: "#b7b7b7",
  confirmButtonText: "Edit anonymously",
  reverseButtons: false,
  focusConfirm: true,
  customClass: {
    header: "thswal-header-class",
    title: "thswal-title-class",
    closeButton: "thswal-close-button-class",
    content: "thswal-content-class",
    actions: "thswal-actions-class",
    confirmButton: "thswal-confirm-button-class",
    cancelButton: "thswal-cancel-button-class"
  }
};
