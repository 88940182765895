<template>
  <div :ref="bountyRef" @keydown.escape="menuOpen = false" class="relative inline-block text-left" style="z-index: 9998;">
    <div>
      <button @click="toggleMenu" class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600">
        <svg class="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
        </svg>
      </button>
    </div>
    <transition
      enter-active-class="duration-150 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div
        v-show="menuOpen"
        class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg"
      >
        <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
          <router-link
            v-if="bounty.authored || isAdmin"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="archive"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
            Delete
          </router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2";
import deleteItemOptions from "../../sweet-alert-delete-item-options";

export default {
  props: ["bounty"],
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    ...mapState(["currentUser", "modal"]),
    ...mapGetters(["isAdmin"]),
    bountyRef() {
      return `bountyMenu${this.bounty.id}`;
    }
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (this.$refs[this.bountyRef] && !this.$refs[this.bountyRef].contains(evt.target)) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    archive() {
      Swal.fire(deleteItemOptions).then(result => {
        if (result.value) {
          this.$store.dispatch("archiveBounty", this.bounty.id);
          this.$emit("deleted");
          this.menuOpen = false;
        }
      });
    }
  }
};
</script>
