import DevelopmentProjectPopup from "./components/maps/development-project-popup";
import Vue from "vue";
/* global L */

const ContextDevelopmentProjectPopup = Vue.extend(DevelopmentProjectPopup);

export default function responsiveDevelopmentProjectPopup({
  router,
  store,
  marker,
  content,
  fillColor,
  isContext = false
}) {
  const popupVueEl = new ContextDevelopmentProjectPopup({
    store,
    router,
    propsData: { content, fillColor, isContext, marker }
  }).$mount().$el;

  return L.responsivePopup({ maxWidth: 150 }).setContent(popupVueEl);
}
