<template>
  <div>
    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{ action }}: {{ content.name }}
      </h3>
    </div>

    <recent-channels :content="content" :content-type="contentType" />

    <div class="px-4 py-5 sm:p-6 space-y-3 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-3 sm:grid-flow-row-dense">
      <button v-if="commentable" @click="viewPublicComments" type="button" class="w-full inline-flex justify-between items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:text-sm">
        Discuss publicly
        <svg class="-ml-0.5 ml-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
          <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
        </svg>
      </button>
      <button type="button" @click="createChannel" class="w-full inline-flex justify-between items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:text-sm">
        {{ privateSharingLabel }}
        <svg class="-ml-0.5 ml-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
          <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
        </svg>
      </button>
      <button v-if="shareable" @click="share" type="button" class="w-full inline-flex justify-between items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:text-sm">
        Copy sharing link
        <svg class="-ml-0.5 ml-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Comments from "./comments";
import RecentChannels from "./backchannels/recent-channels";
/* global analytics */

export default {
  components: { RecentChannels },
  props: ["content", "contentType"],
  computed: {
    ...mapState(["adminMode"]),
    action() {
      switch (this.contentType) {
        case "property":
          return "Grant Access";
        case "deal":
        case "intel":
          if (this.content.safezone) {
            return "Grant Access";
          } else {
            return "Discuss";
          }
        default:
          return "Discuss";
      }
    },
    privateSharingLabel() {
      switch (this.contentType) {
        case "property":
          return "Share privately";
        case "deal":
        case "intel":
          if (this.content.safezone) {
            return "Share privately";
          } else {
            return "Discuss privately";
          }
        default:
          return "Discuss privately";
      }
    },
    commentable() {
      switch (this.contentType) {
        case "deal":
          return !this.content.safezone && this.content.commentable;
        case "intel":
          return !this.content.safezone;
        default:
          return false;
      }
    },
    shareable() {
      switch (this.contentType) {
        case "deal":
        case "intel":
          return !this.content.safezone;
        default:
          return false;
      }
    }
  },
  methods: {
    viewPublicComments() {
      this.$store.commit("openSlideover", {
        component: Comments,
        props: {
          content: this.content
        }
      });

      if (!!analytics && !this.adminMode) {
        analytics.track("Viewed post comments", {
          yearWeek: this.$store.state.yearWeek
        });
      }

      this.$store.commit("closeModal");
    },
    createChannel() {
      this.$router.push({
        path: "/backchannel/new",
        query: {
          content: this.content.token || this.content.id,
          type: this.contentType
        }
      });
      this.$store.commit("clearModal");
    },
    share() {
      this.$copyText(`https://towerhunt.com/open_${this.contentType}/${this.content.token}`).then(
        () => this.$store.dispatch("flash", `Copied link to clipboard`),
        () => this.$store.dispatch("flash", "Failed to copy")
      );

      if (!!analytics && !this.adminMode) {
        analytics.track(`Copied content link`, {
          yearWeek: this.$store.state.yearWeek,
          contentType: this.contentType
        });
      }

      this.$store.commit("closeModal");
    }
  }
};
</script>
