<template>
  <div id="main">
    <hub v-if="signedIn" />
    <home v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Home from "./landing";
import Hub from "./hub";

export default {
  components: { Home, Hub },
  computed: {
    ...mapGetters(["signedIn"])
  }
};
</script>

<style lang="scss">
.pac-container,
.swal2-container {
  z-index: 10001;
}
.cta-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 10px 0;
}
.sky-bg {
  background: #99e4fc;
}
i.large {
  font-size: 2em;
}
.large > i {
  font-size: 2em;
}
.purple {
  color: #5850ec;
}
.purple-background {
  background-color: #5850ec;
}
.gray {
  color: #999;
}
.light-gray {
  color: #e0e0e0;
}
.gray-background {
  background-color: #e5e5e5;
}
.black {
  color: #4f4f4f;
}
.black-background {
  background-color: #4f4f4f;
}
.sky {
  color: #99E4FC;
}
.sky-background {
  background-color: #99e4fc;
}
.pink {
  color: #F92A82;
}
.pink-background {
  background-color: #f92a82;
}
.green {
  color: #6FCF97;
}
.green-background {
  background-color: #6fcf97;
}
.underlined {
  text-decoration: underline;
}
.clickable {
  cursor: pointer;
}
.left-mg {
  margin-left: 20px;
  margin-right: 20px;
}
.primary {
  font-weight: bold;
}
textarea {
  height: 100px;
}
.flexible {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
}
.pure-form label {
  color: #4f4f4f;
}
.help {
  > h4 {
    margin-top: 0;
  }
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .help-wrapper {
    margin: 5px 0;
    display: flex;
    align-items: center;
    > i {
      font-size: 1.5em;
      text-align: center;
      margin-right: 5px;
      min-width: 40px;
    }
  }
}
.thswal-close-button-class {
  margin: 7px 7px 0 0;
  font-size: 24px;
  font-weight: 700;
  color: #b7b7b7;
}
.thswal-title-class {
  padding: 20px 0 10px 0;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.thswal-content-class {
  font-size: 14px;
  padding: 10px 40px;
}
.thswal-confirm-button-class {
  text-transform: uppercase !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
  padding: 15px !important;
  margin-bottom: 20px !important;
  width: 40%;
}
.thswal-cancel-button-class {
  text-transform: uppercase !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #4f4f4f !important;
  background-color: #f7f7f7 !important;
  padding: 15px !important;
  margin-bottom: 20px !important;
  width: 40%;
}

//tooltips
.tooltip {
  display: block !important;
  z-index: 10000;
  max-width: 300px;
  font-size: 0.8em;

  .tooltip-inner {
    background: #161e2e;
    color: white;
    border-radius: 5px;
    padding: 7px 10px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: #161e2e;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: #161e2e;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(#161e2e, .1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .4s, visibility .4s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .4s;
  }
}
</style>
