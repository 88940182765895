<template>
  <div class="wrapper">
    <heading v-if="stateChange">What's happening with this deal?</heading>
    <heading v-else>Add any relevant details</heading>
    <form @submit.prevent="save" autocomplete="off" novalidate>
      <div class="input-container">
        <resizable-textarea>
          <textarea
            v-focus
            v-model="notes"
            @focus="focus"
            @blur="blur"
            rows="3"
            autocorrect="off"
            spellcheck="false"
            :placeholder="placeholder"
            tabindex="1"
            name="notes"
            class="input-text black"
            :class="{ active: focused }"
          />
        </resizable-textarea>
      </div>
      <button @click="confirmPrivacy" type="button" class="cta" :class="{ complete: complete }" tabindex="999">
        <template v-if="stateChange">Confirm author and post</template>
        <template v-else>Confirm privacy and edit</template>
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../components/onboarding/heading";
import ResizableTextarea from "../../components/resizable-textarea";
import Swal from "sweetalert2";
import anonymousPostOptions from "../../sweet-alert-always-anonymous-options";
import anonymousPostUpdateOptions from "../../sweet-alert-always-anonymous-update-options";

export default {
  components: { Heading, ResizableTextarea },
  props: ["dealId", "targetId", "stateChange", "postToken"],
  data() {
    return {
      focused: false,
      localNotes: ""
    };
  },
  computed: {
    ...mapState(["activeDeal", "activeTarget", "activeIntel"]),
    ...mapGetters([]),
    placeholder() {
      if (this.stateChange) {
        return "Anything you can share about status, pricing, parties involved, etc.";
      } else {
        return "";
      }
    },
    complete() {
      if (this.stateChange) {
        return this.localNotes !== "" && _.trim(this.localNotes) !== "";
      } else {
        return true;
      }
    },
    notes: {
      get() {
        if (this.stateChange) {
          return this.localNotes;
        } else if (this.dealId) {
          return this.activeDeal.notes;
        } else if (this.targetId) {
          return this.activeTarget.notes;
        } else if (this.postToken) {
          return this.activeIntel.notes;
        } else {
          return "";
        }
      },
      set(notes) {
        if (this.stateChange) {
          this.localNotes = notes;
        } else if (this.dealId) {
          this.$store.commit("setActiveDealNotes", notes);
        } else if (this.targetId) {
          this.$store.commit("setActiveTargetNotes", notes);
        } else if (this.postToken) {
          this.$store.commit("setActiveIntelNotes", notes);
        }
      }
    }
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    confirmPrivacy() {
      if (this.complete) {
        if (this.stateChange) {
          Swal.fire(anonymousPostOptions).then(result => {
            if (result.value) {
              this.reportStateChange();
            }
          });
        } else {
          Swal.fire(anonymousPostUpdateOptions).then(result => {
            if (result.value) {
              this.save();
            }
          });
        }
      }
    },
    reportStateChange() {
      this.$store.dispatch("reportDealStateChange", {
        dealId: this.dealId,
        notes: this.notes
      });
      this.$store.commit("closeModal");
      this.$store.commit("clearDeal");
      this.$store.commit("clearDealMarketing");
    },
    save() {
      if (this.dealId) {
        this.$store.dispatch("updateDeal", {
          dealId: this.dealId,
          updateTags: false
        });
        this.$store.commit("closeModal");
        this.$store.commit("clearDeal");
        this.$store.commit("clearDealMarketing");
      } else if (this.targetId) {
        this.$store.dispatch("updateTarget", this.targetId);
        this.$store.commit("closeModal");
      } else if (this.postToken) {
        this.$store.dispatch("updateIntel", {
          postToken: this.postToken,
          updateTags: false
        });
        this.$store.commit("closeModal");
        this.$store.commit("clearIntel");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
textarea {
  vertical-align: middle;
}
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.active {
    border: 2px solid #5850ec;
  }
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
