<template>
  <li>
    <router-link :to="`/property-watchlist/${following.id}/`" class="flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6">
      <div class="flex items-center truncate space-x-3">
        <watchlist-color-dot :property="property" />
        <p class="font-medium truncate text-sm leading-6">{{ property.customName || property.name }}</p>
      </div>
      <!-- Heroicon name: chevron-right -->
      <svg class="ml-4 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
      </svg>
    </router-link>
  </li>
</template>

<script>
import { mapGetters } from "vuex";
import WatchlistColorDot from "../watchlist-color-dot.vue";

export default {
  components: { WatchlistColorDot },
  props: ["following"],
  computed: {
    ...mapGetters(["isAdmin", "signedIn"]),
    property() {
      return this.following.property;
    }
  },
  methods: {}
};
</script>
