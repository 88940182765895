<template>
  <div>
    <dt class="text-lg leading-7">
      <button @click="open = !open" :class="`text-left w-full flex justify-between items-start text-${primaryColor} focus:outline-none`">
        <span :class="`font-medium text-${primaryColor}`">
          <slot name="primary" />
        </span>
        <span class="ml-6 h-7 flex items-center">
          <svg class="h-6 w-6 transform" :class="open ? '-rotate-180' : 'rotate-0'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </span>
      </button>
    </dt>
    <dd v-show="open" class="mt-2 pr-12">
      <p :class="`text-base leading-6 text-${secondaryColor}`">
        <slot name="secondary" />
      </p>
    </dd>
  </div>
</template>

<script>
export default {
  props: ["primaryColor", "secondaryColor"],
  data() {
    return {
      open: false
    };
  }
};
</script>
