<template>
  <div v-if="signedIn && visibility.privacyState" class="w-full flex" :class="visibility.isPublic ? '' : 'bg-gray-700'">
    <div :class="{ 'p-2 text-xs leading-4': compact, 'px-4 py-2 sm:py-4 sm:px-6 text-sm leading-5': !compact, 'text-gray-900': visibility.isPublic, 'text-gray-300': !visibility.isPublic }" class="relative -mr-px w-0 flex-1 inline-flex items-center font-medium">
      <!-- Heroicon name: currency-dollar -->
      <svg v-tooltip="'Who can see this?'" :class="compact ? 'w-4 h-4' : 'w-5 h-5'" viewBox="0 0 20 20" fill="currentColor">
        <template v-if="visibility.isPublic">
          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
          <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
        </template>
        <template v-else>
          <path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
          <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
        </template>
      </svg>
      <div class="ml-2">
        <span class="font-medium">{{ visibility.privacyState }}</span>
      </div>

      <div class="ml-3 flex flex-shrink-0 -space-x-1" style="width: 84px;">
        <avatar-photo v-for="user in audience" :key="user.token" :person="user" v-tooltip="userTooltip(user)" border-class="ring-2 ring-gray-300" circle-size="6" text-size="xs" />
      </div>

      <span v-if="audienceOverflow" v-tooltip="overflowTooltip" class="flex-shrink-0 ml-1 text-xs leading-5 font-medium">+{{ audienceOverflow }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AvatarPhoto from "../avatar-photo.vue";
import api from "../../api";

export default {
  components: { AvatarPhoto },
  props: ["content", "contentType", "compact"],
  data() {
    return {
      visibility: {
        privacy: null,
        audience: []
      }
    };
  },
  computed: {
    ...mapState(["modal"]),
    ...mapGetters(["signedIn"]),
    audience() {
      return _.slice(this.visibility.audience, 0, 4);
    },
    audienceOverflow() {
      const overflow = this.visibility.audience.length - 4;

      return overflow > 0 ? overflow : null;
    },
    overflowTooltip() {
      const dropped = _.drop(this.visibility.audience, 4);

      return dropped.map(user => this.userTooltip(user)).join(", ");
    }
  },
  mounted() {
    if (this.signedIn) {
      api
        .get(`content_visibility/${this.contentType}/${this.content.token || this.content.id}`)
        .then(json => {
          this.visibility = json.data.visibility;
        });
    }
  },
  methods: {
    userTooltip(user) {
      const name = this.$options.filters.capitalize(user.name);
      const role = _.capitalize(
        this.$options.filters.industryRoleAlias(
          user.industryRole,
          user.industrySubRole,
          user.token
        )
      );

      if (user.company) {
        return `${name} (${role} at ${user.company})`;
      } else {
        return `${name} (${role})`;
      }
    }
  }
};
</script>
