<template>
  <main>
    <div v-if="!loaded" class="w-full flex justify-center mt-4">
      <grid-loader :loading="!loaded" size="8px" color="#5850ec" />
    </div>
    <template v-else>
      <!-- Page header -->
      <div class="md:flex md:items-center md:justify-between md:space-x-5">
        <div class="flex items-center space-x-5">
          <div class="flex-shrink-0">
            <div class="relative">
              <img class="h-16 w-16 rounded-full" :src="activeProperty.coverPhoto || 'https://source.unsplash.com/collection/277102'" alt="">
              <span class="absolute inset-0 shadow-inner rounded-full" aria-hidden="true"></span>
            </div>
          </div>
          <div>
            <h1 class="text-2xl font-bold text-gray-900">{{ activeProperty.customName || activeProperty.name }}</h1>
            <p class="text-sm font-medium text-gray-500">{{ activeProperty.cityState }} &middot; {{ [activeProperty] | totalSize }}</p>
          </div>
        </div>
        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <button @click="chooseDiscussion" type="button" v-tooltip="'Invite others to track this watchlist property with you.'" class="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <!-- Heroicon name: solid/globe -->
            <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
            </svg>
            Invite
          </button>
          <button @click="mapView" type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <!-- Heroicon name: solid/globe -->
            <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clip-rule="evenodd" />
            </svg>
            Map view
          </button>
        </div>
      </div>

      <div class="mt-8 grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-2 lg:col-start-1 lg:col-span-2">
          <!-- Tabs -->
          <div class="mt-3 sm:mt-2">
            <div class="sm:hidden">
              <label for="tabs" class="sr-only">Select a tab</label>
              <select v-model="selectValue" id="tabs" name="tabs" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option value="newsfeed">Newsfeed</option>
                <option value="discussion">Discussion {{ unreadLabel }}</option>
                <option value="notepad">Notepad</option>
                <option value="vitals">Vitals</option>
                <option value="safezone">Safezone</option>
                <option value="settings">Settings</option>
              </select>
            </div>
            <div class="hidden sm:block">
              <div class="flex items-center border-b border-gray-200">
                <nav class="flex-1 -mb-px flex space-x-6 xl:space-x-8" aria-label="Tabs">
                  <router-link to="newsfeed" v-slot="{ href, navigate, isActive }">
                    <a
                      @click="navigate"
                      :href="href"
                      :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                      class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                      aria-current="page"
                    >
                      Newsfeed
                    </a>
                  </router-link>

                  <router-link to="discussion" v-slot="{ href, navigate, isActive }">
                    <a
                      @click="navigate"
                      :href="href"
                      :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                      class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                      aria-current="page"
                    >
                      <div class="flex items-center">
                        <span>Discussion</span>
                        <span v-if="backchannelUnreadCount > 0" v-tooltip="'Unreads'" class="ml-2 inline-block py-0.5 px-3 text-xs leading-4 rounded-full text-white bg-green-400">
                          {{ backchannelUnreadCount | cleanUnreadCount }}
                        </span>
                      </div>
                    </a>
                  </router-link>

                  <router-link to="notepad" v-slot="{ href, navigate, isActive }">
                    <a
                      @click="navigate"
                      :href="href"
                      :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                      class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                      aria-current="page"
                    >
                      Notepad
                    </a>
                  </router-link>

                  <router-link to="vitals" v-slot="{ href, navigate, isActive }">
                    <a
                      @click="navigate"
                      :href="href"
                      :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                      class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                      aria-current="page"
                    >
                      Vitals
                    </a>
                  </router-link>

                  <router-link to="safezone" v-slot="{ href, navigate, isActive }">
                    <a
                      @click="navigate"
                      :href="href"
                      :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                      class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                      aria-current="page"
                    >
                      Safezone
                    </a>
                  </router-link>

                  <router-link to="settings" v-slot="{ href, navigate, isActive }">
                    <a
                      @click="navigate"
                      :href="href"
                      :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                      class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                      aria-current="page"
                    >
                      Settings
                    </a>
                  </router-link>
                </nav>
              </div>
            </div>
          </div>

          <router-view
            :property-following-id="propertyFollowingId"
            :property-following="propertyFollowing"
            :property="activeProperty"
            :content="activeProperty"
            content-type="property"
            @refetch="fetchPropertyFollowing"
          />
        </div>

        <section aria-labelledby="timeline-title" class="lg:col-start-3 lg:col-span-1">
          <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 lg:sticky lg:top-0">
            <property-timeline :property="activeProperty" />

            <div class="mt-6 flex flex-col justify-stretch">
              <button @click="newIntel" type="button" :class="privacyMode ? 'bg-pink-600 hover:bg-pink-500 focus:outline-none focus:border-pink-700 focus:ring-pink active:bg-pink-700' : 'bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700'" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white">
                <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
                  <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
                </svg>
                <svg
                  v-else
                  class="-ml-1 mr-2 h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"></path>
                </svg>
                Add intel
              </button>
            </div>
          </div>
        </section>
      </div>
    </template>
  </main>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DiscussionSelector from "../components/discussion-selector.vue";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import PropertyTimeline from "../components/property-timeline.vue";
import api from "../api";

export default {
  components: { GridLoader, PropertyTimeline },
  props: ["propertyFollowingId"],
  data() {
    return {
      selectValue: "newsfeed",
      propertyFollowing: null,
      loaded: false
    };
  },
  computed: {
    ...mapState(["route", "modal", "currentUser", "activeProperty"]),
    ...mapGetters(["privacyMode"]),
    pathTab() {
      return _.last(this.route.path.split("/"));
    },
    backchannelUnreadCounts() {
      return _.get(this.currentUser, "backchannelUnreadCounts", []);
    },
    backchannelUnreadCount() {
      const countContainer = _.find(this.backchannelUnreadCounts, { token: _.get(this.propertyFollowing, "backchannelToken", null) });

      return countContainer ? countContainer.unreadCount : 0;
    },
    unreadLabel() {
      if (this.backchannelUnreadCount > 0) {
        return `(${this.backchannelUnreadCount} new)`;
      } else {
        return "";
      }
    }
  },
  watch: {
    pathTab: {
      handler() {
        this.setSelectFromPath();
      }
    },
    selectValue: {
      handler() {
        if (this.selectValue !== this.pathTab) {
          this.$router.push({ path: this.selectValue });
        }
      }
    },
    propertyFollowingId: {
      handler(newId, oldId) {
        if (oldId && newId !== oldId) {
          this.loaded = false;
          this.$store.commit("resetWatchlistProximity");
          this.fetchPropertyFollowing().then(() => {
            document.title = `Watchlist: ${this.activeProperty.name} | Tower Hunt`;
            this.$store.commit(
              "mountWatchlistProximity",
              this.propertyFollowing
            );
            this.loaded = true;
            this.forwardEmptyPath();
          });
        }
      }
    }
  },
  mounted() {
    this.forwardEmptyPath();
    this.setSelectFromPath();
    this.fetchPropertyFollowing().then(() => {
      document.title = `Watchlist: ${this.activeProperty.name} | Tower Hunt`;
      this.$store.commit("mountWatchlistProximity", this.propertyFollowing);
      this.loaded = true;
    });
  },
  beforeDestroy() {
    this.$store.commit("clearProperty");
    this.$store.commit("resetWatchlistProximity");
  },
  methods: {
    fetchPropertyFollowing() {
      return new Promise(resolve => {
        api.get(`property_followings/${this.propertyFollowingId}`).then(
          json => {
            this.propertyFollowing = json.data;
            this.$store.commit("mountProperty", {
              property: this.propertyFollowing.property
            });
            this.$store.dispatch("loadCurrentUser");
            resolve();
          },
          failure => {
            this.$store.dispatch("flash", "Unauthorized access.");
            this.$router.push({ path: "/home/dashboard" });
          }
        );
      });
    },
    newIntel() {
      this.$router.push({
        path: "/intel/new/privacy",
        query: { nextUrl: "/intel/new/1" }
      });
    },
    chooseDiscussion() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      this.$store.commit("openModal", {
        component: DiscussionSelector,
        props: {
          content: this.propertyFollowing,
          contentType: "property"
        },
        afterClose: currentModal
      });
    },
    mapView() {
      this.$router.push({
        path: "/map",
        query: { watchlist: this.propertyFollowingId }
      });
    },
    forwardEmptyPath() {
      if (
        this.$store.state.route.path ===
        `/property-watchlist/${this.propertyFollowingId}/`
      ) {
        this.$router.push({ path: "newsfeed" });
        this.selectValue = "newsfeed";
      }
    },
    setSelectFromPath() {
      if (
        _.includes(
          [
            "newsfeed",
            "discussion",
            "notepad",
            "vitals",
            "safezone",
            "settings"
          ],
          this.pathTab
        )
      ) {
        this.selectValue = this.pathTab;
      }
    }
  }
};
</script>
