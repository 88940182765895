<template>
  <!-- Notepad-->
  <section aria-labelledby="notes-title">
    <div class="bg-white shadow sm:rounded-lg sm:overflow-hidden">
      <div class="divide-y divide-gray-200">
        <div class="px-4 py-2">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Notepad
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            This is your space. Take notes, list your to-dos, or keep links handy.
          </p>
        </div>
        <div id="watchlist-notepad-container" class="max-h-140 overflow-y-auto px-4 py-6 sm:px-6">
          <notepad :content="propertyFollowing.property" content-type="property" />
        </div>
        <notepad-input :content="propertyFollowing.property" content-type="property" />
      </div>
    </div>
  </section>
</template>

<script>
import Notepad from "../notepad.vue";
import NotepadInput from "../notepad-input.vue";

export default {
  components: { Notepad, NotepadInput },
  props: ["propertyFollowing", "propertyFollowingId"]
};
</script>
