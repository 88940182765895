<template>
  <div>
    <!-- Feature comparison (up to lg) -->
    <section aria-labelledby="mobile-comparison-heading" class="lg:hidden">
      <h2 id="mobile-comparison-heading" class="sr-only">Feature comparison</h2>

      <div class="max-w-2xl mx-auto py-16 px-4 space-y-16 sm:px-6">
        <div class="border-t border-gray-200" key="gatherer.title">
          <div :class="productTier === 'gatherer' ? 'border-indigo-600' : 'border-transparent'" class="-mt-px pt-6 border-t-2 sm:w-1/2">
            <h3 :class="productTier === 'gatherer' ? 'text-indigo-600' : 'text-gray-900'" class="text-sm font-bold">Gatherer</h3>
            <p class="mt-2 text-sm text-gray-500">Building blocks for market awareness.</p>
          </div>
          <h4 class="mt-10 text-sm font-bold text-gray-900">Personalized tracking</h4>

          <div class="mt-6 relative">
            <!-- Fake card background -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
            </div>

            <div :class="productTier === 'gatherer' ? 'ring-2 ring-indigo-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow'" class="relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
              <dl class="divide-y divide-gray-200">
                <up-to-lg-feature-row v-for="feature in personalizationFeatures" :key="feature.featureName" :feature="feature" tier="gatherer" />
              </dl>
            </div>

            <!-- Fake card border -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div :class="productTier === 'gatherer' ? 'ring-2 ring-indigo-600' : 'ring-1 ring-black ring-opacity-5'" class="absolute right-0 w-1/2 h-full rounded-lg"></div>
            </div>
          </div>

          <h4 class="mt-10 text-sm font-bold text-gray-900">Shared database</h4>

          <div class="mt-6 relative">
            <!-- Fake card background -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
            </div>

            <div :class="productTier === 'gatherer' ? 'ring-2 ring-indigo-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow'" class="relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
              <dl class="divide-y divide-gray-200">
                <up-to-lg-feature-row v-for="feature in platformFeatures" :key="feature.featureName" :feature="feature" tier="gatherer" />
              </dl>
            </div>

            <!-- Fake card border -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div :class="productTier === 'gatherer' ? 'ring-2 ring-indigo-600' : 'ring-1 ring-black ring-opacity-5'" class="absolute right-0 w-1/2 h-full rounded-lg"></div>
            </div>
          </div>

          <h4 class="mt-10 text-sm font-bold text-gray-900">Private database</h4>

          <div class="mt-6 relative">
            <!-- Fake card background -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
            </div>

            <div :class="productTier === 'gatherer' ? 'ring-2 ring-indigo-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow'" class="relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
              <dl class="divide-y divide-gray-200">
                <up-to-lg-feature-row v-for="feature in safezoneFeatures" :key="feature.featureName" :feature="feature" tier="gatherer" />
              </dl>
            </div>

            <!-- Fake card border -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div :class="productTier === 'gatherer' ? 'ring-2 ring-indigo-600' : 'ring-1 ring-black ring-opacity-5'" class="absolute right-0 w-1/2 h-full rounded-lg"></div>
            </div>
          </div>

          <h4 class="mt-10 text-sm font-bold text-gray-900">Mapping</h4>

          <div class="mt-6 relative">
            <!-- Fake card background -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
            </div>

            <div :class="productTier === 'gatherer' ? 'ring-2 ring-indigo-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow'" class="relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
              <dl class="divide-y divide-gray-200">
                <up-to-lg-feature-row v-for="feature in mapFeatures" :key="feature.featureName" :feature="feature" tier="gatherer" />
              </dl>
            </div>

            <!-- Fake card border -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div :class="productTier === 'gatherer' ? 'ring-2 ring-indigo-600' : 'ring-1 ring-black ring-opacity-5'" class="absolute right-0 w-1/2 h-full rounded-lg"></div>
            </div>
          </div>
        </div>

        <div class="border-t border-gray-200" key="insider.title">
          <div :class="productTier === 'insider' ? 'border-indigo-600' : 'border-transparent'" class="-mt-px pt-6 border-t-2 sm:w-1/2">
            <h3 :class="productTier === 'insider' ? 'text-indigo-600' : 'text-gray-900'" class="text-sm font-bold">Insider</h3>
            <p class="mt-2 text-sm text-gray-500">Premium intel. Pay only for what you use.</p>
          </div>
          <h4 class="mt-10 text-sm font-bold text-gray-900">Personalized tracking</h4>

          <div class="mt-6 relative">
            <!-- Fake card background -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div class="shadow-md absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
            </div>

            <div :class="productTier === 'insider' ? 'ring-2 ring-indigo-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow'" class="relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
              <dl class="divide-y divide-gray-200">
                <up-to-lg-feature-row v-for="feature in personalizationFeatures" :key="feature.featureName" :feature="feature" tier="insider" />
              </dl>
            </div>

            <!-- Fake card border -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div :class="productTier === 'insider' ? 'ring-2 ring-indigo-600' : 'ring-1 ring-black ring-opacity-5'" class="absolute right-0 w-1/2 h-full rounded-lg"></div>
            </div>
          </div>

          <h4 class="mt-10 text-sm font-bold text-gray-900">Shared database</h4>

          <div class="mt-6 relative">
            <!-- Fake card background -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div class="shadow-md absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
            </div>

            <div :class="productTier === 'insider' ? 'ring-2 ring-indigo-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow'" class="relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
              <dl class="divide-y divide-gray-200">
                <up-to-lg-feature-row v-for="feature in platformFeatures" :key="feature.featureName" :feature="feature" tier="insider" />
              </dl>
            </div>

            <!-- Fake card border -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div :class="productTier === 'insider' ? 'ring-2 ring-indigo-600' : 'ring-1 ring-black ring-opacity-5'" class="absolute right-0 w-1/2 h-full rounded-lg"></div>
            </div>
          </div>

          <h4 class="mt-10 text-sm font-bold text-gray-900">Private database</h4>

          <div class="mt-6 relative">
            <!-- Fake card background -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
            </div>

            <div :class="productTier === 'insider' ? 'ring-2 ring-indigo-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow'" class="relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
              <dl class="divide-y divide-gray-200">
                <up-to-lg-feature-row v-for="feature in safezoneFeatures" :key="feature.featureName" :feature="feature" tier="insider" />
              </dl>
            </div>

            <!-- Fake card border -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div :class="productTier === 'insider' ? 'ring-2 ring-indigo-600' : 'ring-1 ring-black ring-opacity-5'" class="absolute right-0 w-1/2 h-full rounded-lg"></div>
            </div>
          </div>

          <h4 class="mt-10 text-sm font-bold text-gray-900">Mapping</h4>

          <div class="mt-6 relative">
            <!-- Fake card background -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
            </div>

            <div :class="productTier === 'insider' ? 'ring-2 ring-indigo-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow'" class="relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
              <dl class="divide-y divide-gray-200">
                <up-to-lg-feature-row v-for="feature in mapFeatures" :key="feature.featureName" :feature="feature" tier="insider" />
              </dl>
            </div>

            <!-- Fake card border -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div :class="productTier === 'insider' ? 'ring-2 ring-indigo-600' : 'ring-1 ring-black ring-opacity-5'" class="absolute right-0 w-1/2 h-full rounded-lg"></div>
            </div>
          </div>
        </div>

        <div class="border-t border-gray-200" key="hunter.title">
          <div :class="productTier === 'hunter' ? 'border-indigo-600' : 'border-transparent'" class="-mt-px pt-6 border-t-2 sm:w-1/2">
            <h3 :class="productTier === 'hunter' ? 'text-indigo-600' : 'text-gray-900'" class="text-sm font-bold">Hunter</h3>
            <p class="mt-2 text-sm text-gray-500">View all your intel on a game-changing map.</p>
          </div>
          <h4 class="mt-10 text-sm font-bold text-gray-900">Personalized tracking</h4>

          <div class="mt-6 relative">
            <!-- Fake card background -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
            </div>

            <div :class="productTier === 'hunter' ? 'ring-2 ring-indigo-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow'" class="relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
              <dl class="divide-y divide-gray-200">
                <up-to-lg-feature-row v-for="feature in personalizationFeatures" :key="feature.featureName" :feature="feature" tier="hunter" />
              </dl>
            </div>

            <!-- Fake card border -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div :class="productTier === 'hunter' ? 'ring-2 ring-indigo-600' : 'ring-1 ring-black ring-opacity-5'" class="absolute right-0 w-1/2 h-full rounded-lg"></div>
            </div>
          </div>

          <h4 class="mt-10 text-sm font-bold text-gray-900">Shared database</h4>

          <div class="mt-6 relative">
            <!-- Fake card background -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
            </div>

            <div :class="productTier === 'hunter' ? 'ring-2 ring-indigo-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow'" class="relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
              <dl class="divide-y divide-gray-200">
                <up-to-lg-feature-row v-for="feature in platformFeatures" :key="feature.featureName" :feature="feature" tier="hunter" />
              </dl>
            </div>

            <!-- Fake card border -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div :class="productTier === 'hunter' ? 'ring-2 ring-indigo-600' : 'ring-1 ring-black ring-opacity-5'" class="absolute right-0 w-1/2 h-full rounded-lg"></div>
            </div>
          </div>

          <h4 class="mt-10 text-sm font-bold text-gray-900">Private database</h4>

          <div class="mt-6 relative">
            <!-- Fake card background -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
            </div>

            <div :class="productTier === 'hunter' ? 'ring-2 ring-indigo-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow'" class="relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
              <dl class="divide-y divide-gray-200">
                <up-to-lg-feature-row v-for="feature in safezoneFeatures" :key="feature.featureName" :feature="feature" tier="hunter" />
              </dl>
            </div>

            <!-- Fake card border -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div :class="productTier === 'hunter' ? 'ring-2 ring-indigo-600' : 'ring-1 ring-black ring-opacity-5'" class="absolute right-0 w-1/2 h-full rounded-lg"></div>
            </div>
          </div>

          <h4 class="mt-10 text-sm font-bold text-gray-900">Mapping</h4>

          <div class="mt-6 relative">
            <!-- Fake card background -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div class="shadow absolute right-0 w-1/2 h-full bg-white rounded-lg"></div>
            </div>

            <div :class="productTier === 'hunter' ? 'ring-2 ring-indigo-600 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow'" class="relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none">
              <dl class="divide-y divide-gray-200">
                <up-to-lg-feature-row v-for="feature in mapFeatures" :key="feature.featureName" :feature="feature" tier="hunter" />
              </dl>
            </div>

            <!-- Fake card border -->
            <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
              <div :class="productTier === 'hunter' ? 'ring-2 ring-indigo-600' : 'ring-1 ring-black ring-opacity-5'" class="absolute right-0 w-1/2 h-full rounded-lg"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Feature comparison (lg+) -->
    <section aria-labelledby="comparison-heading" class="hidden lg:block">
      <h2 id="comparison-heading" class="sr-only">Feature comparison</h2>

      <div class="max-w-7xl mx-auto py-24 px-8">
        <div class="w-full border-t border-gray-200 flex items-stretch">
          <div class="-mt-px w-1/4 py-6 pr-4 flex items-end">
            <h3 class="mt-auto text-sm font-bold text-gray-900">Personalized tracking</h3>
          </div>

          <div aria-hidden="true" class="pr-4 -mt-px pl-4 w-1/4">
            <div :class="productTier === 'gatherer' ? 'border-indigo-600' : 'border-transparent'" class="py-6 border-t-2">
              <p :class="productTier === 'gatherer' ? 'text-indigo-600' : 'text-gray-900'" class="text-sm font-bold">Gatherer</p>
              <p class="mt-2 text-sm text-gray-500">Building blocks for market awareness.</p>
            </div>
          </div>

          <div aria-hidden="true" class="pr-4 -mt-px pl-4 w-1/4">
            <div :class="productTier === 'insider' ? 'border-indigo-600' : 'border-transparent'" class="py-6 border-t-2">
              <p :class="productTier === 'insider' ? 'text-indigo-600' : 'text-gray-900'" class="text-sm font-bold">Insider</p>
              <p class="mt-2 text-sm text-gray-500">Premium intel. Pay only for what you use.</p>
            </div>
          </div>

          <div aria-hidden="true" class="-mt-px pl-4 w-1/4">
            <div :class="productTier === 'hunter' ? 'border-indigo-600' : 'border-transparent'" class="py-6 border-t-2">
              <p :class="productTier === 'hunter' ? 'text-indigo-600' : 'text-gray-900'" class="text-sm font-bold">Hunter</p>
              <p class="mt-2 text-sm text-gray-500">View all your intel on a game-changing map.</p>
            </div>
          </div>
        </div>

        <div class="relative">
          <!-- Fake card backgrounds -->
          <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
            <div class="w-1/4 pr-4"></div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full bg-white rounded-lg shadow"></div>
            </div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full bg-white rounded-lg shadow-md"></div>
            </div>
            <div class="w-1/4 pl-4">
              <div class="w-full h-full bg-white rounded-lg shadow"></div>
            </div>
          </div>

          <table class="relative w-full">
            <caption class="sr-only">
              Personalization feature comparison
            </caption>
            <thead>
              <tr class="text-left">
                <th scope="col">
                  <span class="sr-only">Feature</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Gatherer plan</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Insider plan</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Hunter plan</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-100">
              <above-lg-feature-row v-for="feature in personalizationFeatures" :key="feature.name" :feature="feature" />
            </tbody>
          </table>

          <!-- Fake card borders -->
          <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
            <div class="w-1/4 pr-4"></div>
            <div class="w-1/4 px-4">
              <div :class="productTier === 'gatherer' ? 'ring-2 ring-indigo-600 ring-opacity-100' : 'ring-1 ring-black ring-opacity-5'" class="w-full h-full rounded-lg"></div>
            </div>
            <div class="w-1/4 px-4">
              <div :class="productTier === 'insider' ? 'ring-2 ring-indigo-600 ring-opacity-100' : 'ring-1 ring-black ring-opacity-5'" class="w-full h-full rounded-lg"></div>
            </div>
            <div class="w-1/4 pl-4">
              <div :class="productTier === 'hunter' ? 'ring-2 ring-indigo-600 ring-opacity-100' : 'ring-1 ring-black ring-opacity-5'" class="w-full h-full rounded-lg"></div>
            </div>
          </div>
        </div>

        <h3 class="mt-10 text-sm font-bold text-gray-900">Shared database</h3>

        <div class="mt-6 relative">
          <!-- Fake card backgrounds -->
          <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
            <div class="w-1/4 pr-4"></div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full bg-white rounded-lg shadow"></div>
            </div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full bg-white rounded-lg shadow-md"></div>
            </div>
            <div class="w-1/4 pl-4">
              <div class="w-full h-full bg-white rounded-lg shadow"></div>
            </div>
          </div>

          <table class="relative w-full">
            <caption class="sr-only">
              Platform comparison
            </caption>
            <thead>
              <tr class="text-left">
                <th scope="col">
                  <span class="sr-only">Feature</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Gatherer plan</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Insider plan</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Hunter plan</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-100">
              <above-lg-feature-row v-for="feature in platformFeatures" :key="feature.name" :feature="feature" />
            </tbody>
          </table>

          <!-- Fake card borders -->
          <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
            <div class="w-1/4 pr-4"></div>
            <div class="w-1/4 px-4">
              <div :class="productTier === 'gatherer' ? 'ring-2 ring-indigo-600 ring-opacity-100' : 'ring-1 ring-black ring-opacity-5'" class="w-full h-full rounded-lg"></div>
            </div>
            <div class="w-1/4 px-4">
              <div :class="productTier === 'insider' ? 'ring-2 ring-indigo-600 ring-opacity-100' : 'ring-1 ring-black ring-opacity-5'" class="w-full h-full rounded-lg"></div>
            </div>
            <div class="w-1/4 pl-4">
              <div :class="productTier === 'hunter' ? 'ring-2 ring-indigo-600 ring-opacity-100' : 'ring-1 ring-black ring-opacity-5'" class="w-full h-full rounded-lg"></div>
            </div>
          </div>
        </div>

        <h3 class="mt-10 text-sm font-bold text-gray-900">Private database</h3>

        <div class="mt-6 relative">
          <!-- Fake card backgrounds -->
          <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
            <div class="w-1/4 pr-4"></div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full bg-white rounded-lg shadow"></div>
            </div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full bg-white rounded-lg shadow-md"></div>
            </div>
            <div class="w-1/4 pl-4">
              <div class="w-full h-full bg-white rounded-lg shadow"></div>
            </div>
          </div>

          <table class="relative w-full">
            <caption class="sr-only">
              Safezone comparison
            </caption>
            <thead>
              <tr class="text-left">
                <th scope="col">
                  <span class="sr-only">Feature</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Gatherer plan</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Insider plan</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Hunter plan</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-100">
              <above-lg-feature-row v-for="feature in safezoneFeatures" :key="feature.name" :feature="feature" />
            </tbody>
          </table>

          <!-- Fake card borders -->
          <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
            <div class="w-1/4 pr-4"></div>
            <div class="w-1/4 px-4">
              <div :class="productTier === 'gatherer' ? 'ring-2 ring-indigo-600 ring-opacity-100' : 'ring-1 ring-black ring-opacity-5'" class="w-full h-full rounded-lg"></div>
            </div>
            <div class="w-1/4 px-4">
              <div :class="productTier === 'insider' ? 'ring-2 ring-indigo-600 ring-opacity-100' : 'ring-1 ring-black ring-opacity-5'" class="w-full h-full rounded-lg"></div>
            </div>
            <div class="w-1/4 pl-4">
              <div :class="productTier === 'hunter' ? 'ring-2 ring-indigo-600 ring-opacity-100' : 'ring-1 ring-black ring-opacity-5'" class="w-full h-full rounded-lg"></div>
            </div>
          </div>
        </div>

        <h3 class="mt-10 text-sm font-bold text-gray-900">Mapping</h3>

        <div class="mt-6 relative">
          <!-- Fake card backgrounds -->
          <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
            <div class="w-1/4 pr-4"></div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full bg-white rounded-lg shadow"></div>
            </div>
            <div class="w-1/4 px-4">
              <div class="w-full h-full bg-white rounded-lg shadow-md"></div>
            </div>
            <div class="w-1/4 pl-4">
              <div class="w-full h-full bg-white rounded-lg shadow"></div>
            </div>
          </div>

          <table class="relative w-full">
            <caption class="sr-only">
              Mapping comparison
            </caption>
            <thead>
              <tr class="text-left">
                <th scope="col">
                  <span class="sr-only">Feature</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Gatherer plan</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Insider plan</span>
                </th>

                <th scope="col">
                  <span class="sr-only">Hunter plan</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-100">
              <above-lg-feature-row v-for="feature in mapFeatures" :key="feature.name" :feature="feature" />
            </tbody>
          </table>

          <!-- Fake card borders -->
          <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
            <div class="w-1/4 pr-4"></div>
            <div class="w-1/4 px-4">
              <div :class="productTier === 'gatherer' ? 'ring-2 ring-indigo-600 ring-opacity-100' : 'ring-1 ring-black ring-opacity-5'" class="w-full h-full rounded-lg"></div>
            </div>
            <div class="w-1/4 px-4">
              <div :class="productTier === 'insider' ? 'ring-2 ring-indigo-600 ring-opacity-100' : 'ring-1 ring-black ring-opacity-5'" class="w-full h-full rounded-lg"></div>
            </div>
            <div class="w-1/4 pl-4">
              <div :class="productTier === 'hunter' ? 'ring-2 ring-indigo-600 ring-opacity-100' : 'ring-1 ring-black ring-opacity-5'" class="w-full h-full rounded-lg"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AboveLgFeatureRow from "./product-tier-feature-comparison/above-lg/feature-row.vue";
import UpToLgFeatureRow from "./product-tier-feature-comparison/up-to-lg/feature-row.vue";

export default {
  components: { AboveLgFeatureRow, UpToLgFeatureRow },
  data() {
    return {
      personalizationFeatures: [
        {
          featureName: "Professional profile",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
        {
          featureName: "Personalized newsfeed",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
        {
          featureName: "Daily Intel Digest",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
        {
          featureName: "Daily Deals Digest",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
        {
          featureName: "Custom Markets",
          planData: [
            {
              name: "gatherer",
              comparisonType: "text",
              comparisonValue: "Unlimited"
            },
            {
              name: "insider",
              comparisonType: "text",
              comparisonValue: "Unlimited"
            },
            {
              name: "hunter",
              comparisonType: "text",
              comparisonValue: "Unlimited"
            },
          ]
        },
        {
          featureName: "Private Markets",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "No"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "No"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
        {
          featureName: "Watchlist email alerts",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "No"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "No"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
      ],
      platformFeatures: [
        {
          featureName: "Public record intel",
          planData: [
            {
              name: "gatherer",
              comparisonType: "text",
              comparisonValue: "Basic"
            },
            {
              name: "insider",
              comparisonType: "text",
              comparisonValue: "Detailed"
            },
            {
              name: "hunter",
              comparisonType: "text",
              comparisonValue: "Detailed"
            },
          ]
        },
        {
          featureName: "Premium Insights",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "No"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
        {
          featureName: "On-demand pricing",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "No"
            },
            {
              name: "insider",
              comparisonType: "text",
              comparisonValue: "Up to $0.25"
            },
            {
              name: "hunter",
              comparisonType: "text",
              comparisonValue: "15% discount"
            },
          ]
        },
        {
          featureName: "Export to Excel",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "No"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
        {
          featureName: "Contribute and edit",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
        {
          featureName: "Earn money",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "No"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
        {
          featureName: "Revenue split (you/TH)",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "No"
            },
            {
              name: "insider",
              comparisonType: "text",
              comparisonValue: "70/30"
            },
            {
              name: "hunter",
              comparisonType: "text",
              comparisonValue: "80/20"
            },
          ]
        },
      ],
      safezoneFeatures: [
        {
          featureName: "Teams and team members",
          planData: [
            {
              name: "gatherer",
              comparisonType: "text",
              comparisonValue: "Unlimited"
            },
            {
              name: "insider",
              comparisonType: "text",
              comparisonValue: "Unlimited"
            },
            {
              name: "hunter",
              comparisonType: "text",
              comparisonValue: "Unlimited"
            },
          ]
        },
        {
          featureName: "Backchannel private chat",
          planData: [
            {
              name: "gatherer",
              comparisonType: "text",
              comparisonValue: "Unlimited"
            },
            {
              name: "insider",
              comparisonType: "text",
              comparisonValue: "Unlimited"
            },
            {
              name: "hunter",
              comparisonType: "text",
              comparisonValue: "Unlimited"
            },
          ]
        },
        {
          featureName: "Safezone history",
          planData: [
            {
              name: "gatherer",
              comparisonType: "text",
              comparisonValue: "2 months"
            },
            {
              name: "insider",
              comparisonType: "text",
              comparisonValue: "2 months"
            },
            {
              name: "hunter",
              comparisonType: "text",
              comparisonValue: "Unlimited"
            },
          ]
        },
        {
          featureName: "Notepad",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
        {
          featureName: "Watchlist properties",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
        {
          featureName: "Safezone posts",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
        {
          featureName: "Safezone files",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
        {
          featureName: "Safezone data fields",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        }
      ],
      mapFeatures: [
        {
          featureName: "Heat map",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
        {
          featureName: "Strategic map",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "No"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "No"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        },
        {
          featureName: "Map filtering",
          planData: [
            {
              name: "gatherer",
              comparisonType: "bool",
              comparisonValue: "No"
            },
            {
              name: "insider",
              comparisonType: "bool",
              comparisonValue: "No"
            },
            {
              name: "hunter",
              comparisonType: "bool",
              comparisonValue: "Yes"
            },
          ]
        }
      ]
    }
  },
  computed: {
    ...mapState(["currentUser"]),
    productTier() {
      return this.currentUser.productTier;
    }
  }
}
</script>
