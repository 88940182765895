<template>
  <div>
    <label for="permissions" class="sr-only">Platform permissions</label>
    <select v-model="platformPermissions" @change="updateUser" id="permissions" name="permissions" class="mt-1 block w-full text-sm pl-2 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded-md">
      <option value="read_only">Read only</option>
      <option value="market_editor">Market editor</option>
      <option value="global_editor">Global editor</option>
    </select>
  </div>
</template>

<script>
import api from "../../api";

export default {
  props: ["user"],
  data() {
    return {
      platformPermissions: "market_editor"
    };
  },
  mounted() {
    this.platformPermissions = this.user.platformPermissions;
  },
  methods: {
    updateUser() {
      api
        .patch(`admin/platform_permissions/${this.user.id}`, {
          platformPermissions: this.platformPermissions
        })
        .then(user => {
          this.$emit("edited", user.data);
          this.$store.dispatch("flash", "Platform permissions updated!");
      });
    }
  }
};
</script>
