import MyFeedPopup from "./components/maps/my-feed-popup";
import Vue from "vue";
/* global L */

const ActivityMyFeedPopup = Vue.extend(MyFeedPopup);

export default function responsiveMyFeedPopup({
  router,
  store,
  marker,
  content,
  isFeed = false
}) {
  const popupVueEl = new ActivityMyFeedPopup({
    store,
    router,
    propsData: { content, isFeed, marker }
  }).$mount().$el;

  return L.responsivePopup({ maxWidth: 150 }).setContent(popupVueEl);
}
