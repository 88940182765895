<template>
  <div class="wrapper">
    <heading class-name="black">What properties does this apply to?</heading>
    <button @click="clickHandler" type="button" class="form cta" :class="{ complete: complete && !privacyMode, 'privacy-complete': complete && privacyMode }" tabindex="999">
      <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
        <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
      </svg>
      <template v-if="postToken">Confirm privacy and edit</template>
      <template v-else-if="!required && !hasProperties">Any property</template>
      <template v-else>Next</template>
    </button>
    <autocomplete
      id="property-search"
      ref="propertySearch"
      :search="searchProperties"
      :auto-select="true"
      :get-result-value="getResultValue"
      :debounce-time="500"
      @focus="focus('property')"
      @blur="blur"
      @submit="handlePropertySubmit"
      placeholder="Search your properties"
      class="mb-1"
      style="width:330px;"
    />
    <button @click="createProperty" type="button" class="form secondary">
      Create new property
    </button>
    <div class="content-wrap">
      <div v-if="localLoading > 0" class="mt-2 space-y-2">
        <div v-for="pending in pendingProperties" :key="pending" class="flex items-center justify-between text-sm">
          <pulse-loader :loading="true" size="6px" color="#D4D4D8" />
          <span class="text-gray-700">Creating property...</span>
        </div>
      </div>
      <property-list :properties="intelProperties" class="mb-2" />
      <div class="h-48">
        <intel-map v-if="hasProperties" :post="activeIntel" :redraw-properties="redrawProperties" :class="{ 'mb-4': required }" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../../components/onboarding/heading";
import IntelMap from "../../../components/maps/market-intel-map";
import Property from "../deal/property";
import PropertyList from "../../../components/property-list";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Swal from "sweetalert2";
import anonymousPostUpdateOptions from "../../../sweet-alert-always-anonymous-update-options";
import api from "../../../api";

export default {
  components: { PulseLoader, Heading, IntelMap, PropertyList },
  props: ["postToken"],
  data() {
    return {
      hovering: null,
      focused: false,
      redrawProperties: false
    };
  },
  computed: {
    ...mapState([
      "modal",
      "properties",
      "activeIntel",
      "intelCreationInProcess",
      "currentUser",
      "localLoading",
      "deduplicateRecord"
    ]),
    ...mapGetters(["creatingIntel", "privacyMode"]),
    pendingProperties() {
      return _.times(this.localLoading, String);
    },
    intelProperties() {
      return this.activeIntel.properties;
    },
    hasProperties() {
      return this.intelProperties.length > 0;
    },
    required() {
      return this.activeIntel.type === "developmentNews";
    },
    complete() {
      if (this.required) {
        return this.hasProperties;
      } else {
        return true;
      }
    }
  },
  watch: {
    intelProperties: {
      handler() {
        this.redrawProperties = true;
        setTimeout(() => {
          this.redrawProperties = false;
        }, 100);
      }
    },
    deduplicateRecord: {
      handler() {
        if (this.deduplicateRecord) {
          this.$store.dispatch("addPropertyWithGetters", this.deduplicateRecord);
          this.$store.commit("clearDeduplicate");
          this.$store.commit("clearModal");
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.creatingIntel && !this.intelCreationInProcess) {
      this.$router.push({ path: "/intel/new/1" });
    }

    document.getElementById("property-search").focus();
  },
  methods: {
    focus(field) {
      this.focused = field;
    },
    blur() {
      this.focused = false;
    },
    searchProperties(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        this.$store.dispatch("executePropertySearch", input).then(
          json => {
            resolve(json.data);
          },
          failure => {
            this.$store.dispatch("flash", "Invalid search");
          }
        );
      });
    },
    getResultValue(result) {
      return `${result.name} (${result.cityState})`;
    },
    handlePropertySubmit(result) {
      if (result) {
        api.get(`properties/${result.id}`).then(json => {
          this.$store.dispatch("addPropertyWithGetters", json.data);
        });
        this.$refs.propertySearch.value = "";
      } else {
        this.$store.dispatch(
          "flash",
          "No matching properties in Tower Hunt. Why don't you create one?"
        );
      }
    },
    clickHandler() {
      if (this.postToken) {
        this.confirmPrivacy();
      } else {
        this.next();
      }
    },
    confirmPrivacy() {
      if (this.complete && this.postToken) {
        Swal.fire(anonymousPostUpdateOptions).then(result => {
          if (result.value) {
            this.$store.dispatch("updateIntel", {
              postToken: this.postToken,
              updateTags: true
            });

            this.$store.commit("closeModal");
            this.$store.commit("clearIntel");
          }
        });
      }
    },
    next() {
      if (this.complete) {
        this.deriveUsesFromProperties();

        switch (this.activeIntel.type) {
          case "developmentNews":
            this.$router.push({ path: "/intel/new/11" });
            break;
          case "marketCommentary":
          case "marketReport":
            this.$router.push({ path: "/intel/new/6" });
            break;
        }
      }
    },
    deriveUsesFromProperties() {
      this.$store.commit("clearActiveIntelUses");

      if (this.activeIntel.properties.length > 0) {
        const uses = _.uniq(
          this.activeIntel.properties.flatMap(p => p.uses)
        ).filter(use => use !== "Parking");

        uses.forEach(use => {
          this.$store.commit("addUseToSelection", { type: use });
        });
      }
    },
    createProperty() {
      let propertiesModal = _.cloneDeep(this.modal); // obtains the current component

      if (this.postToken) {
        this.$store.commit("openModal", {
          component: Property,
          props: {
            propertyId: null
          },
          afterClose: propertiesModal
        });
      } else {
        this.$store.commit("openModal", {
          component: Property,
          props: {
            propertyId: null
          }
        });
      }
    },
    hover(option) {
      this.hovering = option;
    },
    vacate() {
      this.hovering = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}
.content-wrap {
  display: flex;
  flex-direction: column;
  width: 330px;
}
.autocomplete ul {
  z-index: 10001 !important;
}
.autocomplete-result {
  text-align: left;
}
button.form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  margin-bottom: 5px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.privacy-complete {
    background: #d61f69;
    box-shadow: rgba(214, 31, 105, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #f17eb8;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
