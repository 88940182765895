<template>
  <div>
    <div class="relative pb-5 border-b border-gray-200 sm:pb-0 mb-6">
      <div>
        <!-- Dropdown menu up to large screens -->
        <div class="lg:hidden">
          <select v-model="selectValue" @change="navigate" aria-label="Selected tab" class="block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm sm:leading-5">
            <option value="dashboard">Home</option>
            <option value="reputation">Reputation</option>
            <option value="safezone">Safezone</option>
            <option value="watchlist">Property Watchlist</option>
            <option value="network">Network</option>
            <option value="premium">My Premium</option>
            <option value="settings">Settings</option>
          </select>
        </div>
        <!-- Tabs at large breakpoint and up -->
        <div class="hidden lg:block">
          <nav class="-mb-px flex space-x-8">
            <router-link
              to="/home/dashboard"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Home
              </a>
            </router-link>

            <router-link
              to="/home/reputation"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Reputation
              </a>
            </router-link>

            <router-link
              to="/home/safezone"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Safezone
              </a>
            </router-link>

            <router-link
              to="/home/watchlist"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Property Watchlist
              </a>
            </router-link>

            <router-link
              to="/home/network"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Network
              </a>
            </router-link>

            <router-link
              to="/home/premium"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                My Premium
              </a>
            </router-link>

            <router-link
              to="/home/settings"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Settings
              </a>
            </router-link>
          </nav>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      selectValue: null
    };
  },
  computed: {
    ...mapState(["route"]),
    pathTab() {
      return _.last(this.route.path.split("/"));
    }
  },
  watch: {
    pathTab: {
      handler() {
        if (this.pathTab === "home") {
          this.$router.push({ path: "/home/dashboard" });
          this.selectValue = "dashboard";
        }
      }
    }
  },
  mounted() {
    document.title = "My Dashboard | Tower Hunt";

    switch (this.$store.state.route.path) {
      case "/home/dashboard":
        this.selectValue = "dashboard";
        break;
      case "/home/reputation":
        this.selectValue = "reputation";
        break;
      case "/home/safezone":
        this.selectValue = "safezone";
        break;
      case "/home/watchlist":
        this.selectValue = "watchlist";
        break;
      case "/home/network":
      case "/home/network/teams":
      case "/home/network/suggestions":
      case "/home/network/connections":
        this.selectValue = "network";
        break;
      case "/home/premium":
        this.selectValue = "premium";
        break;
      case "/home/settings":
        this.selectValue = "settings";
        break;
      default:
        if (_.includes(this.$store.state.route.path, "/home/intents/")) {
          return;
        } else if (this.$store.state.route.path !== `/home/dashboard`) {
          this.$router.push({ path: "/home/dashboard" });
          this.selectValue = "dashboard";
        }
    }
  },
  methods: {
    navigate() {
      if (this.$store.state.route.path !== `/home/${this.selectValue}`) {
        this.$router.push({ path: `/home/${this.selectValue}` });
      }
    }
  }
};
</script>
