<template>
  <div v-if="activeDeal && activeDeal.token && activeDealMarketing">
    <main class="lg:relative">
      <div class="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
        <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span class="block xl:inline">
              <router-link
                v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized"
                @click.native="openModal({ type: 'deal-name' })"
                to=""
                :class="textEditClass"
              >
                {{ activeDeal.name }}
              </router-link>
              <span v-else>{{ activeDeal.name }}</span>
            </span>
            <span class="block text-indigo-600 xl:inline">
              <router-link
                v-if="canEditSeekingJV"
                @click.native="openModal({ type: 'deal-seeking-jv' })"
                to=""
                :class="textEditClass"
              >
                {{ activeDeal.type | dealTypeAlias(activeDeal.jointVentureRoles) }}
              </router-link>
              <span v-else>{{ activeDeal.type | dealTypeAlias(activeDeal.jointVentureRoles) }}</span>
            </span>
          </h1>
          <p @click="openModal({ type: 'secondaryHeadline' })" class="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl" :class="textEditClass">
            <template v-if="activeDealMarketing.secondaryHeadline">{{ activeDealMarketing.secondaryHeadline }}</template>
            <template v-else>Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua.</template>
          </p>
          <div v-if="activeDeal.state !== 'closed'" class="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div v-if="activeDeal.state !== 'awarded' && (canRegister || canInquire)" class="rounded-md shadow">
              <button v-if="canRegister" @click="register" v-tooltip="previewTooltip" type="button" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring md:py-4 md:text-md md:px-10">
                Register
              </button>
              <button v-else-if="canInquire" @click="requestContact" v-tooltip="previewTooltip" type="button" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring md:py-4 md:text-md md:px-10">
                Contact me
              </button>
            </div>
            <div :class="{ 'mt-3 sm:mt-0 sm:ml-3': activeDeal.state !== 'awarded' && (canRegister || canInquire) }">
              <button @click="save" v-tooltip="previewTooltip" type="button" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:ring focus:border-indigo-300 md:py-4 md:text-md md:px-10">
                <template v-if="activeDeal.followed">Unfollow</template>
                <template v-else>Follow</template>
              </button>
            </div>
            <div v-if="activeDeal.state !== 'awarded' && canDecline" class="mt-3 sm:mt-0 sm:ml-3">
              <button @click="decline" v-tooltip="previewTooltip" type="button" class="w-full flex items-center justify-center px-8 py-3 border border-gray-300 text-base leading-6 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50 md:py-4 md:text-md md:px-10">
                No thanks
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <template v-if="activeDealMarketing.coverPhoto">
          <img class="absolute inset-0 w-full h-full object-cover" :src="activeDealMarketing.coverPhoto" alt="Cover photo" />
          <div class="z-10 absolute inset-0 w-full h-full flex items-center justify-center">
            <svg v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" @click="replaceCoverPhoto" class="cursor-pointer h-20 w-20 text-indigo-500" v-tooltip="'Replace the cover photo'" fill="currentColor" viewBox="0 0 84 84">
              <circle opacity="0.9" cx="42" cy="42" r="42" fill="white" />
              <path d="M52.7023 23.8285C53.2549 23.2565 53.9158 22.8002 54.6465 22.4863C55.3772 22.1724 56.1632 22.0071 56.9585 22.0002C57.7537 21.9933 58.5424 22.1449 59.2785 22.446C60.0146 22.7472 60.6833 23.1919 61.2457 23.7543C61.8081 24.3166 62.2528 24.9854 62.554 25.7215C62.8551 26.4576 63.0067 27.2463 62.9998 28.0415C62.9929 28.8368 62.8276 29.6228 62.5137 30.3535C62.1998 31.0842 61.7435 31.7451 61.1715 32.2977L58.7966 34.6725L50.3275 26.2034L52.7023 23.8285V23.8285ZM46.0929 30.4379L21 55.5309V64H29.4691L54.5651 38.907L46.09 30.4379H46.0929Z" />
            </svg>
          </div>
        </template>
        <template v-else>
          <img class="absolute inset-0 w-full h-full object-cover" src="https://source.unsplash.com/collection/2155077" alt="" />
          <div class="z-10 absolute inset-0 w-full h-full flex items-center justify-center">
            <svg v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" @click="addCoverPhoto" class="cursor-pointer h-20 w-20 text-indigo-500" v-tooltip="'Add a cover photo'" fill="currentColor" viewBox="0 0 84 84">
              <circle opacity="0.9" cx="42" cy="42" r="42" fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M42 63C41.2044 63 40.4413 62.6839 39.8787 62.1213C39.3161 61.5587 39 60.7956 39 60V45H24C23.2044 45 22.4413 44.6839 21.8787 44.1213C21.3161 43.5587 21 42.7956 21 42C21 41.2044 21.3161 40.4413 21.8787 39.8787C22.4413 39.3161 23.2044 39 24 39H39V24C39 23.2044 39.3161 22.4413 39.8787 21.8787C40.4413 21.3161 41.2044 21 42 21C42.7956 21 43.5587 21.3161 44.1213 21.8787C44.6839 22.4413 45 23.2044 45 24V39H60C60.7956 39 61.5587 39.3161 62.1213 39.8787C62.6839 40.4413 63 41.2044 63 42C63 42.7956 62.6839 43.5587 62.1213 44.1213C61.5587 44.6839 60.7956 45 60 45H45V60C45 60.7956 44.6839 61.5587 44.1213 62.1213C43.5587 62.6839 42.7956 63 42 63V63Z" />
            </svg>
          </div>
        </template>
      </div>
    </main>

    <div class="py-12 bg-white">
      <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="lg:text-center">
          <p class="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Executive Summary</p>
          <h3 @click="openModal({ type: 'executiveSummaryHeadline' })" class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10" :class="textEditClass">
            <template v-if="activeDealMarketing.executiveSummaryHeadline">{{ activeDealMarketing.executiveSummaryHeadline }}</template>
            <template v-else>Your one-liner for the deal</template>
          </h3>
          <p @click="openModal({ type: 'executiveSummarySecondaryHeadline' })" class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto" :class="textEditClass">
            <template v-if="activeDealMarketing.executiveSummarySecondaryHeadline">{{ activeDealMarketing.executiveSummarySecondaryHeadline }}</template>
            <template v-else>Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.</template>
          </p>
        </div>

        <div class="mt-10">
          <ul class="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <template v-if="hasDataPoints('executiveSummaryTalkingPoints')">
              <icon-talking-point
                v-for="point in sortedDataPoints('executiveSummaryTalkingPoints')"
                :key="point.id"
                :order="point.order"
                collection="executiveSummaryTalkingPoints"
              />
            </template>
            <li v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" @click="addDataPoint('executiveSummaryTalkingPoint')" class="cursor-pointer">
              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-gray-500 text-white">
                    <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"/>
                    </svg>
                  </div>
                </div>
                <div class="ml-4" v-tooltip="'If you elect not to add talking points, no one will see this.'">
                  <h5 class="text-lg leading-6 font-medium text-gray-900">Add a talking point</h5>
                  <p class="mt-2 text-base leading-6 text-gray-500">
                    It will appear here and then you can edit it.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="relative bg-white">
      <div class="z-10 relative h-56 bg-indigo-600 sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-1/2">
        <div class="absolute inset-x-0 top-0 transform translate-y-px pointer-events-none" style="z-index: 9995;">
          <div class="flex justify-center transform -translate-y-1/2">
            <a @click.prevent="strategicMap" href="" class="inline-flex items-center rounded-full text-sm px-4 py-1 leading-5 bg-indigo-600 hover:bg-indigo-700 font-semibold tracking-wider uppercase text-white pointer-events-auto">
              <!-- Heroicon name: cursor-click -->
              <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z" clip-rule="evenodd" />
              </svg>
              Register to view comps map
            </a>
          </div>
        </div>

        <deal-map :deal="activeDeal" :show-legend="showMapLegend" />
      </div>
      <div class="relative max-w-screen-xl mx-auto px-4 py-8 sm:py-12 sm:px-6 lg:py-16">
        <div class="max-w-2xl mx-auto lg:max-w-none lg:mr-0 lg:ml-auto lg:w-1/2 lg:pl-10">
          <div>
            <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
            </div>
          </div>
          <h2 @click="openModal({ type: 'locationHeadline' })" class="mt-6 text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10" :class="textEditClass">
            <template v-if="activeDealMarketing.locationHeadline">{{ activeDealMarketing.locationHeadline }}</template>
            <template v-else>Your headline about the location</template>
          </h2>
          <p @click="openModal({ type: 'locationSecondaryHeadline' })" class="mt-6 text-lg leading-7 text-gray-500" :class="textEditClass">
            <template v-if="activeDealMarketing.locationSecondaryHeadline">{{ activeDealMarketing.locationSecondaryHeadline }}</template>
            <template v-else>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore nihil ea rerum ipsa. Nostrum consectetur sequi culpa doloribus omnis, molestiae esse placeat, exercitationem magnam quod molestias quia aspernatur deserunt voluptatibus.</template>
          </p>
          <div class="mt-8 overflow-hidden">
            <div class="-mx-8 -mt-8 flex flex-wrap">
              <template v-if="hasDataPoints('locationDataPoints')">
                <div
                  v-for="point in sortedDataPoints('locationDataPoints')"
                  :key="point.id"
                  class="px-8 pt-8"
                >
                  <p @click="openModal({ type: 'locationDataPointContent', order: point.order })" class="text-2xl leading-8 font-extrabold text-indigo-600 sm:text-3xl sm:leading-9" :class="textEditClass">
                    <template v-if="locationDataPointContent(point.order)">{{ locationDataPointContent(point.order) }}</template>
                    <template v-else>Data point</template>
                  </p>
                  <p @click="openModal({ type: 'locationDataPointLabel', order: point.order })" class="text-base leading-6 font-medium text-gray-500" :class="textEditClass">
                    <template v-if="locationDataPointLabel(point.order)">{{ locationDataPointLabel(point.order) }}</template>
                    <template v-else>Label</template>
                  </p>
                  <p class="text-base leading-6 mt-2">
                    <span v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" class="inline-flex rounded-md shadow-sm" v-tooltip="'Remove data point'">
                      <button @click="removeDataPoint(point.order)" type="button" class="inline-flex items-center px-2 py-1 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
                        <svg class="h-3 w-3" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                      </button>
                    </span>
                  </p>
                </div>
              </template>
              <div v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" @click="addDataPoint('locationDataPoint')" class="cursor-pointer px-8 pt-8" v-tooltip="'If you elect not to add data points, no one will see this.'">
                <p class="text-2xl leading-8 font-extrabold text-gray-600 sm:text-3xl sm:leading-9" :class="textEditClass">
                  Add data point
                </p>
                <p class="text-base leading-6 font-medium text-gray-500" :class="textEditClass">
                  Here
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-12 bg-white overflow-hidden shadow sm:rounded-lg">
      <div class="border-b border-gray-200 px-4 py-5 sm:px-6">
        <div class="lg:flex lg:items-center lg:justify-between">
          <div class="flex-1 min-w-0">
            <h2 class="pb-2 text-3xl font-extrabold leading-9 text-gray-900 sm:text-4xl sm:leading-10 sm:truncate">
              Additional Details
            </h2>
            <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">
              <div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" fill-rule="evenodd"></path>
                </svg>
                Updated {{ activeDealMarketing.updatedAt | moment("from") }}
              </div>
              <div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z"/>
                </svg>
                {{ activeDeal.type | dealTypeAlias(activeDeal.jointVentureRoles) }}
              </div>
              <div v-if="activeDeal.type === 'Lease'" class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clip-rule="evenodd"/>
                </svg>
                {{ activeDeal | totalLeaseSize }}
              </div>
              <div v-else class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M10 2a1 1 0 011 1v1.323l3.954 1.582 1.599-.8a1 1 0 01.894 1.79l-1.233.616 1.738 5.42a1 1 0 01-.285 1.05A3.989 3.989 0 0115 15a3.989 3.989 0 01-2.667-1.019 1 1 0 01-.285-1.05l1.715-5.349L11 6.477V16h2a1 1 0 110 2H7a1 1 0 110-2h2V6.477L6.237 7.582l1.715 5.349a1 1 0 01-.285 1.05A3.989 3.989 0 015 15a3.989 3.989 0 01-2.667-1.019 1 1 0 01-.285-1.05l1.738-5.42-1.233-.617a1 1 0 01.894-1.788l1.599.799L9 4.323V3a1 1 0 011-1zm-5 8.274l-.818 2.552c.25.112.526.174.818.174.292 0 .569-.062.818-.174L5 10.274zm10 0l-.818 2.552c.25.112.526.174.818.174.292 0 .569-.062.818-.174L15 10.274z" clip-rule="evenodd" />
                </svg>
                <template v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized">
                  <router-link
                    @click.native="openModal({ type: 'deal-risks' })"
                    to=""
                    class="text-indigo-600 hover:text-indigo-500"
                  >
                    {{ activeDeal.risks | sortedTypeLabels }}
                  </router-link>
                </template>
                <template v-else>{{ activeDeal.risks | sortedTypeLabels }}</template>
              </div>
              <div v-if="activeDeal.properties.length > 1" class="mt-2 flex items-center text-sm leading-5 text-gray-500">
                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clip-rule="evenodd" />
                </svg>
                {{ activeDeal.properties | propertiesCount }}: {{ activeDeal.properties | totalSize }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Property
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Location
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Size/Use
                  </th>
                  <th v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(property, index) in activeDeal.properties"
                  :key="index"
                  :class="{ 'bg-white': index % 2 === 0, 'bg-gray-50': index % 2 !== 0}"
                >
                  <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900">
                    {{ property.name }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                    {{ property.location }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                    {{ [property] | totalSize }}
                  </td>
                  <td v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" class="px-6 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium">
                    <a @click.prevent="editProperty(property)" href="" class="text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline">Edit Property</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="relative bg-white py-20 px-4 sm:px-6 md:py-16 lg:py-20 lg:px-8">
      <div class="relative max-w-7xl mx-auto">
        <div class="text-center">
          <h2 class="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
            Deal Team
          </h2>
          <p @click="openModal({ type: 'dealTeamSecondaryHeadline' })" class="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4" :class="textEditClass">
            <template v-if="activeDealMarketing.dealTeamSecondaryHeadline">{{ activeDealMarketing.dealTeamSecondaryHeadline }}</template>
            <template v-else-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized">
              <span v-tooltip="'If you elect not to add a deal team secondary headline, no one will see this.'">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa libero labore natus atque, ducimus sed.</span>
            </template>
          </p>
        </div>
        <div class="mt-12 grid gap-5 max-w-lg mx-auto sm:grid-cols-3 lg:grid-cols-5 sm:max-w-none">
          <person
            v-for="person in activeDeal.participants"
            :key="person.token"
            :contact="person"
            :deal-volume="false"
          />
        </div>
        <div class="mt-8">
          <button v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" @click="inviteTeammates" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
            Invite teammates
          </button>
        </div>
      </div>
    </div>

    <div v-if="activeDeal.state !== 'closed'" class="bg-white">
      <div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20">
        <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          <template v-if="canInquire">What do you think?</template>
          <template v-else-if="activeDeal.followed">Thanks for your interest!</template>
          <template v-else>Follow this deal to keep tabs on it</template>
          <br />
          <span @click="openModal({ type: 'bidDate' })" class="text-indigo-600" :class="textEditClass">
            <template v-if="activeDealMarketing.bidDate">Bids due {{ activeDealMarketing.bidDate | moment("dddd, MMMM Do, YYYY") }}</template>
            <template v-else-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized"><span v-tooltip="'If you elect not to set a bid date, no one will see this.'">Add a bid date if you want</span></template>
          </span>
        </h2>
        <div class="mt-8 flex">
          <div v-if="activeDeal.state !== 'awarded' && (canRegister || canInquire)" class="inline-flex rounded-md shadow">
            <button v-if="canRegister" @click="register" v-tooltip="previewTooltip" type="button" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring">
              Register
            </button>
            <button v-else-if="canInquire" @click="requestContact" v-tooltip="previewTooltip" type="button" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring">
              Contact me
            </button>
          </div>
          <div class="inline-flex" :class="{ 'ml-3': activeDeal.state !== 'awarded' && (canRegister || canInquire) }">
            <button @click="save" v-tooltip="previewTooltip" type="button" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:ring focus:border-indigo-300">
              <template v-if="activeDeal.followed">Unfollow</template>
              <template v-else>Follow</template>
            </button>
          </div>
          <div v-if="activeDeal.state !== 'awarded' && canDecline" v-tooltip="previewTooltip" class="ml-3 inline-flex">
            <button @click="decline" type="button" class="inline-flex items-center justify-center px-5 py-3 border border-gray-300 text-base leading-6 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
              No thanks
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AcceptConfidentialityAgreement from "./accept-confidentiality-agreement";
import AddDealParticipant from "../onboarding/deal/add-deal-participant";
import BidDateField from "./bid-date-field";
import DealMap from "../../components/maps/deal-map";
import DealMarketingWelcome from "../../components/cards/deal-marketing-welcome";
import DealName from "../onboarding/deal/deal-name";
import DealRisks from "../onboarding/deal/risk-type";
import DealSeekingJV from "../onboarding/deal/deal-seeking-jv-role";
import EditProperty from "../onboarding/deal/property";
import FileDragDrop from "../../components/file-drag-drop.vue";
import IconTalkingPoint from "./icon-talking-point";
import MarketingTextField from "./marketing-text-field";
import Person from "../../components/cards/person";
import UserMarketEmail from "./user-market-email";
/* global L */

export default {
  components: { DealMap, IconTalkingPoint, Person },
  computed: {
    ...mapState([
      "currentUser",
      "properties",
      "activeDeal",
      "activeDealMarketing",
      "modal",
      "adminMode"
    ]),
    ...mapGetters(["signedIn", "dealProspectInviteParam"]),
    showMapLegend() {
      return !L.Browser.mobile;
    },
    iconEditClass() {
      if (!this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
        return [
          "hover:bg-indigo-400",
          "focus:outline-none",
          "focus:ring",
          "transition",
          "cursor-pointer"
        ];
      } else {
        return [];
      }
    },
    textEditClass() {
      if (!this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
        return ["hover:underline", "cursor-pointer"];
      } else {
        return [];
      }
    },
    canRegister() {
      return (
        this.activeDeal.dealTeam ||
        (this.activeDeal.viewerDealMarketingState && this.activeDeal.viewerDealMarketingState === "viewed") ||
        (this.activeDealMarketing.visibility === "unrestricted" && !this.activeDeal.viewerDealMarketingState) ||
        (!this.signedIn && !!this.dealProspectInviteParam)
      );
    },
    canInquire() {
      if (this.signedIn) {
        switch (this.activeDeal.viewerDealMarketingState) {
          case "registered":
          case "downloaded documents":
          case "discussed":
            return true;
          default:
            return false;
        }
      } else {
        return !!this.dealProspectInviteParam;
      }
    },
    canDecline() {
      return (
        this.activeDeal.viewerDealMarketingState &&
        !_.includes(["declined"], this.activeDeal.viewerDealMarketingState)
      );
    },
    canEditSeekingJV() {
      return (
        !this.activeDealMarketing.investorPreview &&
        this.activeDeal.marketingWriteAuthorized &&
        this.activeDeal.type === "Recap Equity"
      );
    },
    shouldDisplayWelcome() {
      return (
        !this.activeDealMarketing.investorPreview &&
        this.activeDeal.marketingWriteAuthorized &&
        this.currentUser.dealMarketingWelcome &&
        !this.modal
      );
    },
    previewTooltip() {
      if (!this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
        return "This is a preview of what prospects will see";
      } else {
        return "";
      }
    }
  },
  watch: {
    currentUser: {
      handler: function() {
        this.displayWelcome();
      }
    }
  },
  mounted() {
    this.$store.dispatch("dealMarketingImpression");
    this.displayWelcome();

    document.title = `${this.activeDeal.name} · Overview | Tower Hunt`;
  },
  methods: {
    displayWelcome() {
      if (!this.adminMode && this.shouldDisplayWelcome) {
        this.$store.commit("openModal", {
          component: DealMarketingWelcome,
          props: {}
        });
      }
    },
    addCoverPhoto() {
      if (!this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
        this.$store.commit("openModal", {
          component: FileDragDrop,
          props: {
            endpoint: `deal_cover_photos/${this.activeDeal.id}`,
            fileTypes: "image/*",
            fileTypeLabels: "PNG, JPG, GIF",
            multiple: false,
            heading: "Let's add a cover photo",
            marginBottom: true,
            flashMessage: "Cover photo saved successfully",
            afterAction: {
              type: "commit",
              actionName: "mountDealMarketing",
              payloadKey: "dealMarketing"
            }
          }
        });
      }
    },
    replaceCoverPhoto() {
      if (!this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
        this.$store.commit("openModal", {
          component: FileDragDrop,
          props: {
            endpoint: `deal_cover_photos/${this.activeDeal.id}`,
            fileTypes: "image/*",
            fileTypeLabels: "PNG, JPG, GIF",
            multiple: false,
            heading: "Replace your cover photo with a new one",
            marginBottom: true,
            flashMessage: "Cover photo saved successfully",
            afterAction: {
              type: "commit",
              actionName: "mountDealMarketing",
              payloadKey: "dealMarketing"
            }
          }
        });
      }
    },
    register() {
      if (!this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
        this.$store.dispatch("flash", {
          message: "When a prospect clicks this, they'll be asked to accept your confidentiality agreement.",
          timeout: 3000
        });
      } else if (this.signedIn && (this.activeDealMarketing.investorPreview || this.activeDeal.marketingReadAuthorized)) {
        this.$store.commit("openXLModal", {
          component: AcceptConfidentialityAgreement,
          props: {},
          lightMode: true
        });
      } else if (this.activeDealMarketing.investorPreview || this.activeDeal.marketingReadAuthorized) {
        this.$store.commit("setNewUserDealProspectInvitation", {
          token: this.dealProspectInviteParam,
          action: "registerDeal",
          dealToken: this.activeDeal.token
        });
        this.$router.push({ path: "/start/1" });
      }
    },
    requestContact() {
      if (this.activeDeal.marketingWriteAuthorized) {
        this.$store.dispatch("flash", {
          message: "When a prospect clicks this, you'll be notified.",
          timeout: 3000
        });
      } else if (this.signedIn && this.activeDeal.marketingReadAuthorized) {
        if (this.currentUser.marketEmail) {
          this.$store.dispatch("createDealInquiry");
        } else {
          this.$store.commit("openModal", {
            component: UserMarketEmail,
            props: {}
          });
        }
      } else if (this.activeDeal.marketingReadAuthorized) {
        this.$store.commit("setNewUserDealProspectInvitation", {
          token: this.dealProspectInviteParam,
          action: "requestContact",
          dealToken: this.activeDeal.token
        });
        this.$router.push({ path: "/start/1" });
      }
    },
    save() {
      if (this.activeDeal.marketingWriteAuthorized) {
        this.$store.dispatch("flash", {
          message: "The prospect can track the deal by clicking this.",
          timeout: 3000
        });
      } else if (this.signedIn && this.activeDeal.marketingReadAuthorized) {
        if (this.activeDeal.followed) {
          this.$store.dispatch("unfollowDeal", this.activeDeal.token);
        } else {
          this.$store.dispatch("followDeal", this.activeDeal.token);
        }
      } else if (this.activeDeal.marketingReadAuthorized) {
        this.$store.commit("setNewUserDealProspectInvitation", {
          token: this.dealProspectInviteParam,
          action: "followDeal",
          dealToken: this.activeDeal.token
        });
        this.$router.push({ path: "/start/1" });
      }
    },
    decline() {
      if (this.activeDeal.marketingWriteAuthorized) {
        this.$store.dispatch("flash", {
          message:
            "When a prospect clicks this, the person's status will be updated in the marketing list.",
          timeout: 3000
        });
      } else if (this.activeDeal.marketingReadAuthorized) {
        this.$store.dispatch("declineDeal", { dealToken: null });
      }
    },
    inviteTeammates() {
      if (!this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
        this.$store.commit("openModal", {
          component: AddDealParticipant,
          props: {
            dealId: this.activeDeal.id
          }
        });
      }
    },
    hasDataPoints(itemType) {
      const collection = this.activeDealMarketing[itemType];

      return collection.length > 0;
    },
    sortedDataPoints(itemType) {
      const collection = this.activeDealMarketing[itemType];

      return _.sortBy(collection, "order");
    },
    locationDataPointAt(order) {
      return _.find(this.activeDealMarketing.locationDataPoints, { order });
    },
    hasDataPoint(order) {
      if (this.locationDataPointAt(order)) {
        return (
          this.locationDataPointAt(order).label ||
          this.locationDataPointAt(order).content
        );
      }
    },
    addDataPoint(itemType) {
      const collectionKey = itemType + "s";
      const collection = this.activeDealMarketing[collectionKey];
      const order = collection.length + 1;

      const newItem = {
        order: order,
        type: itemType,
        iconName: "lightning-bolt",
        label: null,
        content: null
      };

      this.$store.commit("addDealMarketingOrderedItem", { item: newItem });
      this.$store.dispatch("createDealMarketingOrderedItem", { item: newItem });
    },
    removeDataPoint(order) {
      this.$store.dispatch("removeDealMarketingOrderedItem", {
        item: this.locationDataPointAt(order)
      });
    },
    locationDataPointLabel(order) {
      return this.locationDataPointAt(order)
        ? this.locationDataPointAt(order).label
        : null;
    },
    locationDataPointContent(order) {
      return this.locationDataPointAt(order)
        ? this.locationDataPointAt(order).content
        : null;
    },
    editProperty(property) {
      setTimeout(() => {
        this.$store.commit("openModal", {
          component: EditProperty,
          props: {
            propertyId: property.id
          }
        });
      }, 200);
    },
    strategicMap() {
      this.$router.push({ path: `/map?deal=${this.activeDeal.token}` });
      this.$store.commit("clearModal");
    },
    openModal({ order, type }) {
      if (!this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
        switch (type) {
          case "deal-name":
            this.$store.commit("openModal", {
              component: DealName,
              props: {
                dealId: this.activeDeal.id
              }
            });
            break;
          case "deal-seeking-jv":
            this.$store.commit("openModal", {
              component: DealSeekingJV,
              props: {
                dealId: this.activeDeal.id
              }
            });
            break;
          case "deal-risks":
            this.$store.commit("openModal", {
              component: DealRisks,
              props: {
                dealId: this.activeDeal.id
              }
            });
            break;
          case "secondaryHeadline":
            this.$store.commit("openModal", {
              component: MarketingTextField,
              props: {
                heading: "Let's add a secondary headline",
                description: "e.g., An opportunity to acquire the fee simple interest in a 95% leased Class A office building located in one of Boston's strongest submarkets.",
                getterName: "secondaryHeadline",
                setterName: "setSecondaryHeadline"
              }
            });
            break;
          case "executiveSummaryHeadline":
            this.$store.commit("openModal", {
              component: MarketingTextField,
              props: {
                heading: "Let's add an executive summary headline",
                description: "e.g., The premier development site in Downtown Boston",
                getterName: "executiveSummaryHeadline",
                setterName: "setExecutiveSummaryHeadline"
              }
            });
            break;
          case "executiveSummarySecondaryHeadline":
            this.$store.commit("openModal", {
              component: MarketingTextField,
              props: {
                heading: "Let's add an executive summary secondary headline",
                description: "e.g., A tremendous mark-to-market opportunity where 35% of the rent roll expires within three years at rents that are 20-40% below market.",
                getterName: "executiveSummarySecondaryHeadline",
                setterName: "setExecutiveSummarySecondaryHeadline"
              }
            });
            break;
          case "locationHeadline":
            this.$store.commit("openModal", {
              component: MarketingTextField,
              props: {
                heading: "Let's add a deal location headline",
                description: "e.g., Greater Boston's premier suburban office market",
                getterName: "locationHeadline",
                setterName: "setLocationHeadline"
              }
            });
            break;
          case "locationSecondaryHeadline":
            this.$store.commit("openModal", {
              component: MarketingTextField,
              props: {
                heading: "Let's add a secondary headline for the deal location",
                description: "e.g., 20 Fortune 500 companies occupy space in this submarket, primarily due to its proximity to mass transit and abundance of amenities.",
                getterName: "locationSecondaryHeadline",
                setterName: "setLocationSecondaryHeadline"
              }
            });
            break;
          case "dealTeamSecondaryHeadline":
            this.$store.commit("openModal", {
              component: MarketingTextField,
              props: {
                heading: "Let's add a headline about the deal team",
                description: "e.g., Developed market-leading assets such as Oceanside, The Shoppes at Harbor Point, and Seagate Tower",
                getterName: "dealTeamSecondaryHeadline",
                setterName: "setDealTeamSecondaryHeadline"
              }
            });
            break;
          case "bidDate":
            this.$store.commit("openModal", {
              component: BidDateField,
              props: {}
            });
            break;
          case "locationDataPointLabel": {
            let description;

            switch (order) {
              case 1:
                description = "e.g., Occupied";
                break;
              case 2:
                description = "e.g., Infrastructure Investment";
                break;
              case 3:
                description = "e.g., Avg. Class A Rent";
                break;
              default:
                description = "e.g., Cars/day";
            }

            this.$store.commit("openModal", {
              component: MarketingTextField,
              props: {
                heading: "Label this data point",
                description: description,
                getterName: "locationDataPoints",
                getterField: "label",
                setterName: "setDealMarketingItemText",
                order
              }
            });
            break;
          }
          case "locationDataPointContent": {
            let description;

            switch (order) {
              case 1:
                description = "e.g., 95%";
                break;
              case 2:
                description = "e.g., $25M";
                break;
              case 3:
                description = "e.g., $35/SF NNN";
                break;
              default:
                description = "e.g., 125K";
            }

            this.$store.commit("openModal", {
              component: MarketingTextField,
              props: {
                heading: "Enter the data point",
                description: description,
                getterName: "locationDataPoints",
                getterField: "content",
                setterName: "setDealMarketingItemText",
                order
              }
            });
            break;
          }
        }
      }
    }
  }
};
</script>
