<template>
  <div class="space-y-6">
    <div class="shadow sm:rounded-md">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Appearance</h3>
          <p class="mt-1 text-sm text-gray-500">This information helps people quickly identify the property.</p>
        </div>

        <div class="grid grid-cols-3 gap-6">
          <div class="col-span-3 relative">
            <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
            <div class="mt-1 flex rounded-md shadow-sm">
              <div class="flex items-stretch flex-grow focus-within:z-10">
                <input v-model="name" type="text" name="name" id="name" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300" placeholder="Name or address">
              </div>
              <attribution-menu
                :content-token="activeProperty.id"
                content-type="property"
                field="name"
                buttonClass="-ml-px inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                svgClass="text-gray-400"
              />
            </div>
          </div>

          <div class="col-span-3">
            <label class="block text-sm font-medium text-gray-700">
              Photo
            </label>
            <div class="mt-1 flex items-center">
              <img v-if="photo" class="inline-block h-12 w-12 rounded-full object-cover object-center" :src="photo" alt="" />
              <span v-else class="inline-block bg-gray-100 rounded-full overflow-hidden h-12 w-12">
                <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </span>
              <span class="ml-5">
                <button @click="launchFilePicker" type="button" class="bg-white rounded-md shadow-sm border border-gray-300 py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Change
                </button>
                <input
                  ref="propertyPhoto"
                  type="file"
                  accept="image/*"
                  :data-direct-upload-url="fileUploadUrl"
                  class="hidden"
                  name="Property photo"
                  @change="uploadPropertyPhoto">
              </span>
              <span class="ml-2 relative">
                <attribution-menu
                  :content-token="activeProperty.id"
                  content-type="property"
                  field="cover_photo"
                  buttonClass="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  svgClass="text-gray-400"
                />
              </span>
            </div>
            <div class="mt-2">
              <span v-if="photoError" class="text-sm text-red-600">{{ photoError }}</span>
              <span v-else class="text-sm text-gray-500">By uploading your photo, you affirm that you have permission to use it per the <router-link to="/legal" target="_blank" class="purple">Terms of Service</router-link>. The file size must be under 25MB.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button @click="save" type="button" class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { DirectUpload } from "@rails/activestorage";
import { mapState } from "vuex";
import AttributionMenu from "../../crowdsourcing/attribution-menu.vue";

export default {
  components: { AttributionMenu },
  data() {
    return {
      fileUploadUrl: "/rails/active_storage/direct_uploads",
      propertyPhotoPreview: null,
      photoError: false
    };
  },
  computed: {
    ...mapState(["activeProperty"]),
    name: {
      get() {
        return this.activeProperty.name;
      },
      set(name) {
        this.$store.commit("setPropertyName", name);
      }
    },
    photo() {
      return this.propertyPhotoPreview || this.activeProperty.coverPhoto;
    },
    complete() {
      return this.name !== "";
    }
  },
  methods: {
    save() {
      if (this.complete) {
        this.$store.dispatch("updateProperty", {
          clear: false,
          propertyId: this.activeProperty.id
        });
      }
    },
    launchFilePicker(){
      this.$refs.propertyPhoto.click();
    },
    uploadPropertyPhoto() {
      Array.from(this.$refs.propertyPhoto.files).forEach((file, index) => {
        if (index === 0) {
          const fileSize = (file.size / 1024 / 1024).toFixed(4); // MB

          if (fileSize > 25) {
            this.photoError =
              "The file you tried to upload was too large. Please upload a file smaller than 25MB.";
          } else {
            this.uploadFile(file);

            this.photoError = false;
          }
        }
      });
      this.$refs.propertyPhoto.value = null;
    },
    uploadFile(file) {
      const upload = new DirectUpload(file, this.fileUploadUrl, this);
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        this.propertyPhotoPreview = reader.result;
      });

      reader.readAsDataURL(file);

      upload.create((error, blob) => {
        if (error) {
          console.error(error); // eslint-disable-line
        } else {
          this.$store.commit("setPropertyPhoto", blob.signed_id);
        }
      });
    }
  }
};
</script>
