<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
  -->
  <div class="bg-white">
    <product-marketing-top-bar />

    <div class="bg-gray-50">
      <!-- Header section -->
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="text-center">
          <h1 class="text-base leading-6 font-semibold text-teal-500 uppercase tracking-wide">Premium insights</h1>
          <p class="mt-1 text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">Monetize your intel. Over and over.</p>
          <p class="max-w-xl mx-auto mt-5 text-xl leading-7 text-gray-500">Realize an entirely new revenue stream with the expertise you already have. We help you every step of the way.</p>
        </div>
      </div>
    </div>

    <!-- Split brand panel -->
    <div class="relative bg-white">
      <div class="absolute inset-0" aria-hidden="true">
        <div class="absolute inset-y-0 right-0 w-1/2 bg-gradient-to-r from-teal-500 to-green-400"></div>
      </div>
      <div class="relative max-w-screen-xl mx-auto lg:grid lg:grid-cols-2 lg:px-8">
        <div class="bg-white py-16 px-4 sm:py-24 sm:px-6 lg:px-0 lg:pr-8">
          <div class="max-w-lg mx-auto lg:mx-0">
            <h2 class="text-base leading-6 font-semibold tracking-wide text-teal-500 uppercase">
              How it works
            </h2>
            <p class="mt-2 text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">Selling in 3 steps</p>
            <dl class="mt-12 space-y-10">
              <div class="flex">
                <div class="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-teal-500 to-green-400 rounded-md flex items-center justify-center">
                  <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
                  </svg>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-medium text-gray-900">
                    List your content for free
                  </dt>
                  <dd class="mt-2 text-base leading-6 text-gray-500">
                    No listing fees or volume limits. Turn-key setup.
                  </dd>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-teal-500 to-green-400 rounded-md flex items-center justify-center">
                  <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                  </svg>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-medium text-gray-900">
                    Decide how to sell
                  </dt>
                  <dd class="mt-2 text-base leading-6 text-gray-500">
                    Choose your own prices and pricing model. Launch when you're ready. We're there to help throughout the process.
                  </dd>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-teal-500 to-green-400 rounded-md flex items-center justify-center">
                  <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-medium text-gray-900">
                    Earn recurring revenue
                  </dt>
                  <dd class="mt-2 text-base leading-6 text-gray-500">
                    Via subscriptions and one-off sales. We only make money when you do.
                  </dd>
                </div>
              </div>
            </dl>
          </div>
        </div>
        <div class="bg-gradient-to-r from-teal-500 to-green-400 py-16 px-4 sm:py-24 sm:px-6 lg:bg-none lg:flex lg:items-center lg:justify-end lg:px-0 lg:pl-8">
          <div class="max-w-lg mx-auto w-full space-y-8 lg:mx-0">
            <div>
              <h2 class="sr-only">Control</h2>
              <p class="relative grid grid-cols-1">
                <span class="flex flex-col text-center">
                  <span class="text-5xl leading-none font-extrabold text-white tracking-tight">You're in charge</span>
                  <span class="mt-2 text-base leading-6 font-medium text-teal-100">Seller benefits:</span>
                </span>
              </p>
            </div>
            <ul class="bg-teal-600 bg-opacity-50 rounded sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col">
              <li class="py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-teal-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Own your content</span>
              </li>
              <li class="border-t border-teal-300 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-teal-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Set your pricing</span>
              </li>
              <li class="border-t border-teal-300 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-teal-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Turn-key setup</span>
              </li>
              <li class="border-t border-teal-300 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white sm:border-l">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-teal-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Payments built in</span>
              </li>
              <li class="border-t border-teal-300 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white sm:border-t-0 sm:border-l">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-teal-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Search indexing</span>
              </li>
              <li class="border-t border-teal-300 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white sm:border-l">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-teal-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Customer support</span>
              </li>
            </ul>
            <template v-if="signedIn">
              <router-link to="/home/premium" class="w-full bg-white border border-transparent rounded-md py-4 px-8 flex items-center justify-center text-lg leading-6 font-medium text-teal-500 hover:text-teal-400 md:px-10">
                Sell premium insights
              </router-link>
              <router-link to="/market/featured" class="block text-center text-base leading-6 font-medium text-teal-100 hover:text-white">
                Browse premium insights
              </router-link>
            </template>
            <router-link v-else to="/start/1" class="w-full bg-white border border-transparent rounded-md py-4 px-8 flex items-center justify-center text-lg leading-6 font-medium text-teal-500 hover:text-teal-400 md:px-10">
              Get started
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Logo cloud -->
    <div class="bg-teal-200 bg-opacity-25">
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="lg:grid lg:grid-cols-2 lg:gap-8 items-center">
          <h2 class="max-w-md mx-auto text-3xl leading-9 font-extrabold text-teal-900 text-center lg:max-w-xl lg:text-left">
            Join local market experts from
          </h2>
          <div class="mt-8 flow-root lg:mt-0 self-center">
            <div class="-mt-4 -ml-8 flex flex-wrap justify-around">
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/Bulfinch_teal0.png" alt="Bulfinch">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/CBRE_teal0.png" alt="CBRE">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/CushmanWakefield_teal0.png" alt="Cushman & Wakefield">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/DavisCompanies_teal0.png" alt="The Davis Companies">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/EastdilSecured_teal0.png" alt="Eastdil Secured">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/JLL_teal0.png" alt="JLL">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/newmark_teal0.png" alt="Newmark">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/Nordblom_teal0.png" alt="Nordblom">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FAQs -->
    <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <h2 class="text-3xl leading-9 font-extrabold text-gray-900 text-center">
        Frequently asked questions
      </h2>
      <div class="mt-12">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              How does this work?
            </template>
            <template v-slot:secondary>
              You prepare content and submit it. Tower Hunt converts your content into one or more sellable units that will appear in relevant markets. You review, set pricing, and launch. People buy/subscribe. You get paid. That's it!
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              What are the seller requirements?
            </template>
            <template v-slot:secondary>
              To earn money on Tower Hunt, you start by connecting a bank account. <a href="https://stripe.com" target="_blank" class="underline">Stripe</a> is the custodian of all financial information. Once you earn at least $600, you'll need to complete an identity verification for U.S. tax purposes.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              How do I create premium content?
            </template>
            <template v-slot:secondary>
              There are three ways to create new premium content. The first is the "Create" button on your <router-link to="/home/premium" class="underline">dashboard</router-link>. The second is the "Sell premium insights" button within the menu at the upper-right of any deal or intel card you click into across Tower Hunt. The third is the "Resell" button on any <router-link to="/bounties/my-engagements" class="underline">bounty you've successfully fulfilled</router-link>.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              What do my listings look like?
            </template>
            <template v-slot:secondary>
              Browse the <router-link to="/market/featured" class="underline">Featured page</router-link> to see examples.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              Are there any restrictions on what I can sell?
            </template>
            <template v-slot:secondary>
              Any information that you know as a result of your experience and expertise in a market is fair game. This is purposefully broad but does exclude certain things. For example, we will not accept deliverables that are exported from paid third-party databases. If we determine a particular deliverable is problematic, we'll attempt to reach you to correct it.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              Who owns my content?
            </template>
            <template v-slot:secondary>
              You retain control of your content. Once we approve it, you have the final say on pricing and availability. Your consent is required before your premium content appears in the marketplace.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              Anything else I should keep in mind when I submit my source material?
            </template>
            <template v-slot:secondary>
              If your source material includes any files, keep in mind that they can expose your identity if you're not careful. In particular, <a href="https://support.microsoft.com/en-us/office/remove-hidden-data-and-personal-information-by-inspecting-documents-presentations-or-workbooks-356b7b5d-77af-44fe-a07f-9aa4d085966f" target="_blank" class="underline">check for any metadata</a> that may be embedded in the file when you create it, especially in office file formats and PDFs.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              When do I get paid?
            </template>
            <template v-slot:secondary>
              We partner with <a href="https://stripe.com" target="_blank" class="underline">Stripe</a> for payments and they usually pay out within two business days.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              What about intel that belongs to my company, team, or building?
            </template>
            <template v-slot:secondary>
              In addition to a personal payouts account, you can set up shared payout accounts for revenue that should be recognized by a company, team, or building. You can then assign the appropriate payout account to each piece of premium content you create on a case-by-case basis. Visit your <router-link to="/home/settings#payouts" class="underline">payouts settings</router-link> to get this set up.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              How much does it cost to list my content?
            </template>
            <template v-slot:secondary>
              Signing up for Tower Hunt and listing your content is free. Once you sell a license to your content, you keep 70% of the revenue. Tower Hunt's 30% share covers the operating costs of the marketplace and makes us a profit.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              How do I keep track of my premium content?
            </template>
            <template v-slot:secondary>
              We created a <router-link to="/home/premium" class="underline">dashboard</router-link> to help you with this.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              I've fulfilled some <router-link to="/product/bounty-bids" class="underline">bounties</router-link>. Can I resell that info as premium content?
            </template>
            <template v-slot:secondary>
              Yes! Visit the <router-link to="/bounties/my-engagements" class="underline">My Engagements page</router-link> and open any fulfilled bid. Once the bid is payable, you will see a "Resell" button. Click this and we'll begin converting your bounty deliverable into premium content.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              Can I also license premium insights from other people on Tower Hunt?
            </template>
            <template v-slot:secondary>
              Yes! <router-link to="/product/premium-content" class="underline">Learn more about the marketplace.</router-link>
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              Is there any more documentation than this?
            </template>
            <template v-slot:secondary>
              Our <router-link to="/legal#terms-of-service" target="_blank" class="underline">Terms of Service</router-link> provide more detail.
            </template>
          </text-accordian>
        </dl>
      </div>
    </div>

    <!-- CTA section -->
    <div class="bg-gradient-to-r from-teal-500 to-green-400">
      <div class="max-w-2xl mx-auto py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
        <h2 class="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10">
          <span class="block">Ready to earn?</span>
          <span v-if="!signedIn" class="block text-teal-900">Create a profile today.</span>
        </h2>
        <p v-if="!signedIn" class="mt-4 text-lg leading-6 text-teal-100">We'll also help you make new connections and track your local markets.</p>
        <router-link :to="ctaPath" class="mt-8 w-full bg-white border border-transparent rounded-md py-3 px-5 inline-flex items-center justify-center text-base leading-6 font-medium text-teal-500 hover:text-teal-400 sm:w-auto">
          Get started for free
        </router-link>
      </div>
    </div>

    <!-- Simple footer -->
    <marketing-footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MarketingFooter from "../components/marketing-footer";
import ProductMarketingTopBar from "../components/product-marketing-top-bar";
import TextAccordian from "../components/text-accordian.vue";

export default {
  components: { MarketingFooter, ProductMarketingTopBar, TextAccordian },
  computed: {
    ...mapGetters(["signedIn"]),
    ctaPath() {
      if (this.signedIn) {
        return "/home/premium";
      } else {
        return "/start/1";
      }
    }
  },
  mounted() {
    document.title = "Premium insights: Monetize your intel | Tower Hunt";
  }
};
</script>
