<template>
  <div v-if="intent" class="mt-6">
    <!-- Page title & actions -->
    <div>
      <div>
        <nav class="sm:hidden">
          <router-link to="/home/premium" class="flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700">
            <!-- Heroicon name: chevron-left -->
            <svg class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
            Back
          </router-link>
        </nav>
        <nav class="hidden sm:flex items-center text-sm leading-5 font-medium">
          <router-link to="/home/premium" class="text-gray-500 hover:text-gray-700">Premium Insights Dashboard</router-link>
          <!-- Heroicon name: chevron-right -->
          <svg class="flex-shrink-0 mx-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
          <div class="text-gray-500 hover:text-gray-700">Create Premium Content</div>
        </nav>
      </div>
      <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="flex-1 min-w-0">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
            {{ reference }} <span v-if="content" class="truncate font-normal text-gray-500">to {{ content.name }}</span>
          </h2>
        </div>
        <create-premium-content-input-group :intent="intent" :content-type="intent.contentType" :content="content" @attach="attachToContent" @link-existing="linkExisting" @link-custom="linkCustom" @data-set="dataSet" />
      </div>
    </div>

    <!-- Intent narrative & attachments -->
    <div class="pt-10 pb-5 border-b border-gray-200 space-y-2" :class="sourceMaterial ? '' : 'mb-5'">
      <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
        <h3 class="ml-2 mt-2 text-lg leading-6 font-medium text-gray-900">
          Seller's source material
        </h3>
        <a @click.prevent="toggleSourceMaterial" href="" class="ml-2 mt-1 text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500">
          <template v-if="sourceMaterial">Hide</template>
          <template v-else>Show</template>
        </a>
      </div>
      <p class="max-w-4xl text-sm leading-5 text-gray-500">Content linked to and submitted by the seller as part of the intent.</p>
    </div>
    <ul v-show="sourceMaterial" class="pt-6 pb-5 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <multi-content-preview-card
        v-if="content"
        class="col-span-1"
        :content="content"
        :attached-bounty="attachedBounty"
      />
      <li v-if="intent.purchaseableNarrative" class="col-span-1 bg-white rounded-lg shadow">
        <div class="w-full p-6">
          <div class="relative text-base leading-7 font-medium text-gray-700 md:flex-grow">
            <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-6 w-6 text-gray-200" fill="currentColor" viewBox="0 0 32 32">
              <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
            </svg>
            <p v-html="linkifiedNarrative" class="relative whitespace-pre-line dont-break-out" />
          </div>
        </div>
      </li>
      <li v-for="(attachment, index) in intent.attachments" :key="index" class="col-span-1 bg-white rounded-lg shadow">
        <div class="w-full p-6 flex flex-col flex-1">
          <div class="iframely-embed flex-1 mb-2">
            <a data-iframely-url :href="attachment.file"></a>
          </div>
          <a :href="attachment.file" target="_blank" class="inline-flex items-center justify-center font-medium text-sm text-gray-700">
            <!-- Heroicon name: download -->
            <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd" />
            </svg>
            <span class="ml-2">Download</span>
          </a>
        </div>
      </li>
    </ul>

    <div class="space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
      <div class="space-y-2">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Premium Content
        </h3>
        <p class="max-w-4xl text-sm leading-5 text-gray-500">Productized units of content that can be purchased individually. Their appearance across Tower Hunt is controlled by what they are linked to.</p>
      </div>
      <div v-if="intent.state === 'seller_review'" class="mt-3 sm:mt-0 sm:ml-4">
        <button @click="launchAllAttempt" type="button" v-tooltip="'Offer this premium content for sale. Subject to final review by Tower Hunt.'" :disabled="!canLaunch" :class="launchButtonStyling" class="flex items-center justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white">
          Launch all
        </button>
      </div>
    </div>
    <catalog-table :intent-id="intentId" class="mb-24" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CatalogTable from "./catalog-table";
import CreatePremiumContentInputGroup from "./create-premium-content-input-group.vue";
import MultiContentPreviewCard from "../../cards/multi-content-preview-card.vue";
import PaymentConnectedAccountGateway from "../../payment-connected-account-gateway";
import api from "../../../api";
import linkifyHtml from "linkifyjs/html";
/* global iframely */

export default {
  components: {
    CatalogTable,
    MultiContentPreviewCard,
    CreatePremiumContentInputGroup
  },
  props: ["intentId"],
  data() {
    return {
      intent: null,
      sourceMaterial: true
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState(["temporaryAccess"]),
    canLaunch() {
      return (
        this.intent.complete &&
        this.intent.state === "seller_review" &&
        !this.intent.launchRequested
      );
    },
    launchButtonStyling() {
      if (this.canLaunch) {
        return "bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700";
      } else {
        return "border-gray-300 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 cursor-not-allowed";
      }
    },
    content() {
      return _.get(this.intent, "content.content", null) || this.intent.content;
    },
    attachedBounty() {
      return _.get(this.intent, "content.attachedBounty", null);
    },
    reference() {
      if (this.content) {
        return "Related";
      } else {
        return "Standalone";
      }
    },
    linkifiedNarrative() {
      return linkifyHtml(this.intent.purchaseableNarrative, {
        defaultProtocol: "https",
        className:
          "font-medium text-indigo-600 hover:text-indigo-500"
      });
    },
    hasFiles() {
      return _.get(this.intent, "attachments", []).length > 0;
    }
  },
  watch: {
    temporaryAccess: {
      handler() {
        if (_.get(this.temporaryAccess, "afterCloseMethodName", null) === "launchAll" && _.get(this.temporaryAccess, "intentId", null) === this.intentId) {
          console.log("invoking?");
          _.invoke(this, this.temporaryAccess.afterCloseMethodName);
        }
      }
    }
  },
  mounted() {
    this.fetchIntent();
  },
  methods: {
    toggleSourceMaterial() {
      this.sourceMaterial = !this.sourceMaterial;
    },
    fetchIntent() {
      api.get(`purchaseable_intents/${this.intentId}`).then(json => {
        this.intent = json.data;
        this.renderFrames();
        this.displaySourceMaterialViaStatus();
      });
    },
    displaySourceMaterialViaStatus() {
      if (this.intent.state !== "content_prep") {
        this.sourceMaterial = false;
      }
    },
    renderFrames() {
      if (this.hasFiles && window.iframely) {
        setTimeout(() => {
          iframely.load();
        }, 500);

        iframely.on("cancel", function(url, parentNode) {
          parentNode.remove();
        });
      }
    },
    attachToContent() {
      const payload = {
        contentType: this.content.newsfeedType,
        contentToken: this.content.token,
        intentId: this.intentId
      };

      this.createContent(payload);
    },
    linkExisting({ contentToken, contentType }) {
      const payload = {
        contentType: contentType,
        contentToken: contentToken,
        intentId: this.intentId
      };

      this.createContent(payload);
    },
    linkCustom() {
      const payload = {
        intentId: this.intentId
      };

      this.createContent(payload);
    },
    dataSet() {
      const payload = {
        intentId: this.intentId,
        dataSet: true
      };

      this.createContent(payload);
    },
    createContent(payload) {
      api.post(`purchaseables`, payload).then(json => {
        if (!!analytics && !this.adminMode) {
          analytics.track("Purchaseable created", {
            yearWeek: this.$store.state.yearWeek
          });
        }
        this.$store.dispatch("flash", "Purchaseable shell created. Ready for updates.");
        this.$store.commit("setRefetchPurchaseables", true);
        this.$scrollTo("#catalog-table", 100, { container: "#body-container" });
      });
    },
    launchAllAttempt() {
      this.$store.commit("openModal", {
        component: PaymentConnectedAccountGateway,
        props: {
          value: {
            component: null,
            props: {
              returnUrl: `https://towerhunt.com/intents/${this.intentId}`,
              closeOnSuccess: true,
              afterClosePayload: {
                afterCloseMethodName: `launchAll`,
                intentId: this.intentId
              }
            }
          }
        }
      });
    },
    launchAll() {
      api.post(`purchaseable_intents/${this.intent.id}/launch`).then(json => {
        this.$store.commit("clearTemporaryAccess");
        this.$store.commit("setRefetchPurchaseables", true);
        this.$store.dispatch("flash", "Launch requested!");
        this.intent.launchRequested = true;
      });
    }
  }
};
</script>
