<template>
  <div ref="accountMenu" class="relative flex justify-end items-center" style="z-index: 9998;">
    <button @click="toggleMenu" id="purchaseable-options-menu-0" aria-has-popup="true" type="button" class="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100">
      <!-- Heroicon name: dots-vertical -->
      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
      </svg>
    </button>
    <transition
      enter-active-class="duration-100 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-75 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-show="menuOpen" class="mx-3 origin-top-right absolute right-7 top-0 w-56 mt-1 rounded-md shadow-lg">
        <div class="z-10 rounded-md bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="project-options-menu-0">
          <div class="py-1">
            <span class="group pointer-events-none flex items-center px-4 py-2 text-sm leading-5 text-gray-700" role="menuitem">
              Account type:
            </span>
          </div>
          <div class="border-t border-gray-100"></div>
          <div class="relative z-0 flex divide-x divide-gray-200">
            <a href="" @click.prevent="setCategory('building')" aria-current="page" :class="account.category === 'building' ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'" class="group relative min-w-0 flex-1 overflow-hidden bg-white p-2 text-xs font-medium text-center hover:bg-gray-50 focus:z-10">
              <span>Building</span>
              <span aria-hidden="true" :class="account.category === 'building' ? 'bg-indigo-500' : 'bg-transparent'" class="absolute inset-x-0 bottom-0 h-0.5"></span>
            </a>

            <a href="" @click.prevent="setCategory('team')" aria-current="false" :class="account.category === 'building' ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'" class="group relative min-w-0 flex-1 overflow-hidden bg-white p-2 text-xs font-medium text-center hover:bg-gray-50 focus:z-10">
              <span>Team</span>
              <span aria-hidden="true" :class="account.category === 'team' ? 'bg-indigo-500' : 'bg-transparent'" class="absolute inset-x-0 bottom-0 h-0.5"></span>
            </a>

            <a href="" @click.prevent="setCategory('company')" aria-current="false" :class="account.category === 'building' ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'" class="group relative min-w-0 flex-1 overflow-hidden bg-white p-2 text-xs font-medium text-center hover:bg-gray-50 focus:z-10">
              <span>Company</span>
              <span aria-hidden="true" :class="account.category === 'company' ? 'bg-indigo-500' : 'bg-transparent'" class="absolute inset-x-0 bottom-0 h-0.5"></span>
            </a>
          </div>
          <div class="border-t border-gray-100"></div>
          <div class="py-1">
            <a href="" @click.prevent="manageMembers" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <!-- Heroicon name: search -->
              <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
              </svg>
              Manage members
            </a>
          </div>
          <div class="py-1">
            <a href="" @click.prevent="deleteAccount" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <!-- Heroicon name: trash -->
              <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
              Delete
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2";
import api from "../api";
import deleteItemOptions from "../sweet-alert-delete-item-options";

export default {
  props: ["account"],
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState([])
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.accountMenu &&
        !this.$refs.accountMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    deleteAccount() {
      Swal.fire(deleteItemOptions).then(result => {
        if (result.value && this.account.managed) {
          api.delete(`shared_payout_accounts/${this.account.id}`).then(json => {
            this.$store.dispatch("flash", "Account deleted");
            this.$emit("refetch");
          });
        }
      });
    },
    manageMembers() {
      this.menuOpen = false;
    },
    setCategory(category) {
      this.$emit("category", category);
    }
  }
};
</script>
