<template>
  <div class="border-t border-gray-200 w-full flex" :class="flat ? '' : 'rounded-b-lg'">
    <button v-if="isAdmin" @click="approve" :class="{ 'rounded-b-lg': !flat, 'p-2 text-xs leading-4': compact, 'py-4 text-sm leading-5': !compact }" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center text-gray-700 font-medium border border-transparent hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 focus:z-10">
      <!-- Heroicon name: thumb-up -->
      <svg class="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
        <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
      </svg>
      <span class="ml-3">Approve</span>
    </button>
    <div v-else-if="!isAdmin" :class="{ 'rounded-b-lg': !flat, 'p-2 text-xs leading-4': compact, 'py-4 text-sm leading-5': !compact }" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center text-gray-700 font-medium border border-transparent hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 focus:z-10">
      <!-- Heroicon name: clock -->
      <svg class="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
      </svg>
      <span class="ml-3">Approval pending</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../../../api";

export default {
  props: ["bounty", "flat", "compact"],
  computed: {
    ...mapGetters(["isAdmin"])
  },
  methods: {
    approve() {
      api.patch(`approve_bounty/${this.bounty.id}`).then(() => {
        if (!!analytics) {
          analytics.track("Bounty approved", {
            yearWeek: this.$store.state.yearWeek
          });
        }

        this.bounty.approvalStatus = "approved";
        this.bounty.state = "live";
      });
    }
  }
};
</script>
