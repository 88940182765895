<template>
  <div class="space-y-6">
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div class="ml-4 mt-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Location
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              Show exactly where this property is in the world.
            </p>
          </div>
          <div class="ml-4 mt-4 flex-shrink-0 relative">
            <attribution-menu
              :content-token="activeProperty.id"
              content-type="property"
              field="latitude"
              buttonClass="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              svgClass="text-gray-400"
            />
          </div>
        </div>

        <div class="grid grid-cols-3 gap-6">
          <div class="col-span-3">
            <label for="name" class="block text-sm font-medium text-gray-700">Address lookup</label>
            <div class="mt-1 flex">
              <div class="flex-grow">
                <gmap-autocomplete
                  :value="activeProperty.location"
                  @place_changed="setAddress"
                  :options="{fields: ['geometry', 'formatted_address', 'address_components', 'name']}"
                  autocomplete="new-password"
                  autocorrect="off"
                  spellcheck="false"
                  placeholder=""
                  tabindex="1"
                  type="text"
                  name="property_location"
                  id="gmap-autocomplete"
                  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                  <template v-slot:input="slotProps">
                    <input ref="input" type="text"
                      v-on:listeners="slotProps.listeners"
                      v-on:attrs="slotProps.attrs" />
                  </template>
                </gmap-autocomplete>
              </div>
              <span class="ml-3">
                <button @click="resetLocation" type="button" class="bg-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <!-- Heroicon name: solid/plus -->
                  <svg class="-ml-2 mr-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                  <span>Reset</span>
                </button>
              </span>
            </div>
          </div>

          <div class="col-span-3">
            <property-map v-if="hasLocation" class="h-64 sm:h-96 w-full" />
          </div>
        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button @click="save" type="button" class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GoogleCityTag,
  GoogleStateTag,
  GoogleStreetNumberTag,
  GoogleStreetTag
} from "../../../googleTags.js";
import { mapState } from "vuex";
import AttributionMenu from "../../crowdsourcing/attribution-menu.vue";
import PropertyMap from "../../../views/onboarding/deal/property-map.vue";

export default {
  components: { AttributionMenu, PropertyMap },
  data() {
    return {};
  },
  computed: {
    ...mapState(["activeProperty"]),
    hasLocation() {
      return this.activeProperty.lat && this.activeProperty.lng;
    }
  },
  methods: {
    save() {
      this.$store.dispatch("updateProperty", {
        clear: false,
        propertyId: this.activeProperty.id
      });
    },
    setAddress(addressData) {
      if (addressData) {
        const lat = addressData.geometry.location.lat();
        const lng = addressData.geometry.location.lng();
        const location = addressData.formatted_address;
        const cityState = this.setCityState(addressData);

        this.setPropertyName(addressData);
        this.resetLocation();
        var self = this;

        setTimeout(() => {
          self.$store.commit("setPropertyAddress", {
            lat,
            lng,
            location,
            cityState
          });
        }, 50);
      }
    },
    setCityState(addressData) {
      const city = this.findGeoTag(addressData, GoogleCityTag);
      const state = this.findGeoTag(addressData, GoogleStateTag);

      if (city !== "" && state !== "") {
        return `${city}, ${state}`;
      } else if (city === "" && state !== "") {
        return state;
      } else if (city !== "" && state === "") {
        return city;
      } else {
        return "";
      }
    },
    setPropertyName(addressData) {
      if (!_.isNull(this.activeProperty.name) && this.activeProperty.name !== "") {
        return;
      }
      const rawStreetNumber = this.findGeoTag(
        addressData,
        GoogleStreetNumberTag
      );

      if (rawStreetNumber !== "") {
        const name = `${rawStreetNumber} ${this.findGeoTag(
          addressData,
          GoogleStreetTag
        )}`;

        this.$store.commit("setPropertyName", name);
      } else {
        const name = `${this.findGeoTag(addressData, GoogleStreetTag)}`;

        this.$store.commit("setPropertyName", name);
      }
    },
    findGeoTag(addressData, tag) {
      const addressComponent = addressData.address_components.filter(
        component => component.types.includes(tag)
      );

      if (addressComponent[0]) {
        return addressComponent[0].short_name;
      } else {
        return "";
      }
    },
    resetLocation() {
      this.$store.commit("clearPropertyLocation");
    }
  }
};
</script>
