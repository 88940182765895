<template>
  <form @submit.prevent="updateEmailFields">
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h2 class="text-lg leading-6 font-medium text-gray-900">Teaser email fields</h2>
          <p class="mt-1 text-sm leading-5 text-gray-500">Customize the from and reply-to email fields.</p>
        </div>

        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6 sm:col-span-3">
            <label for="from_name" class="block text-sm font-medium leading-5 text-gray-700">From name</label>
            <input v-focus v-model="fromName" id="from_name" :placeholder="`e.g., ${activeDeal.name} Deal Team`" type="text" class="mt-1 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 shadow-sm py-2 px-3 sm:text-sm sm:leading-5">
            <p class="mt-2 text-sm text-gray-500 flex justify-between" id="email-description">
              <span>Default: {{ activeDealMarketing.dealTeamNames }}</span>
              <a v-if="hasFromName" href="" @click.prevent="fromName = ''" class="font-medium text-xs text-indigo-600 hover:text-indigo-500">Revert</a>
            </p>
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="from_email" class="block text-sm font-medium leading-5 text-gray-700">From email</label>
            <input v-model="fromEmail" id="from_email" type="email" autocomplete="new-password" autocorrect="off" spellcheck="false" placeholder="" class="mt-1 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 shadow-sm py-2 px-3 sm:text-sm sm:leading-5">
            <p class="mt-2 text-sm text-gray-500 flex justify-between" id="email-description">
              <span>Default: deals@towerhunt.com</span>
              <a v-if="hasFromEmail" href="" @click.prevent="fromEmail = ''" class="font-medium text-xs text-indigo-600 hover:text-indigo-500">Revert</a>
            </p>
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="to_name" class="block text-sm font-medium leading-5 text-gray-700">Reply-to name</label>
            <input v-model="toName" id="to_name" type="text" class="mt-1 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 shadow-sm py-2 px-3 sm:text-sm sm:leading-5">
            <p class="mt-2 text-sm text-gray-500 flex justify-between" id="email-description">
              <span>Default: {{ activeDeal.userName }}</span>
              <a v-if="hasToName" href="" @click.prevent="toName = ''" class="font-medium text-xs text-indigo-600 hover:text-indigo-500">Revert</a>
            </p>
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="to_email" class="block text-sm font-medium leading-5 text-gray-700">Reply-to email</label>
            <input v-model="toEmail" id="to_email" type="email" autocomplete="new-password" autocorrect="off" spellcheck="false" placeholder="" class="mt-1 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 shadow-sm py-2 px-3 sm:text-sm sm:leading-5">
            <p class="mt-2 text-sm text-gray-500 flex justify-between" id="email-description">
              <span>Default: deals@towerhunt.com</span>
              <a v-if="hasToEmail" href="" @click.prevent="toEmail = ''" class="font-medium text-xs text-indigo-600 hover:text-indigo-500">Revert</a>
            </p>
          </div>
        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <span class="inline-flex rounded-md shadow-sm">
          <button type="submit" class="bg-indigo-600 border border-transparent rounded-md py-2 px-4 inline-flex justify-center text-sm leading-5 font-medium text-white hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
            Save
          </button>
        </span>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      fromName: "",
      fromEmail: "",
      toName: "",
      toEmail: ""
    };
  },
  computed: {
    ...mapState(["activeDeal", "activeDealMarketing"]),
    hasFromName() {
      return _.trim(this.fromName) !== "";
    },
    hasFromEmail() {
      return _.trim(this.fromEmail) !== "";
    },
    hasToName() {
      return _.trim(this.toName) !== "";
    },
    hasToEmail() {
      return _.trim(this.toEmail) !== "";
    }
  },
  mounted() {
    this.mountFields();
  },
  methods: {
    mountFields() {
      const {
        customFromEmailAddress,
        customFromEmailName,
        customToEmailAddress,
        customToEmailName
      } = this.activeDealMarketing;

      this.fromName = customFromEmailName || "";
      this.fromEmail = customFromEmailAddress || "";
      this.toName = customToEmailName || "";
      this.toEmail = customToEmailAddress || "";
    },
    stripEmpty(str) {
      if (_.trim(str) === "") {
        return null;
      } else {
        return _.trim(str);
      }
    },
    updateEmailFields() {
      const payload = {
        fromName: this.stripEmpty(this.fromName),
        fromEmail: this.stripEmpty(this.fromEmail),
        toName: this.stripEmpty(this.toName),
        toEmail: this.stripEmpty(this.toEmail)
      };

      this.$store.dispatch("updateDealMarketingTeaserEmailFields", payload);
    }
  }
};
</script>
