export default [
  {
    order: 1,
    name: "Residential",
    value: "Multifamily",
    frontEndKey: "multifamily",
    backEndKey: "multifamily",
    description: "Apartments, condos, single-family rentals",
    measure: "Units"
  },
  {
    order: 2,
    name: "Office",
    value: "Office",
    frontEndKey: "office",
    backEndKey: "office",
    measure: "SF"
  },
  {
    order: 3,
    name: "Retail",
    value: "Retail",
    frontEndKey: "retail",
    backEndKey: "retail",
    measure: "SF"
  },
  {
    order: 4,
    name: "Industrial",
    value: "Industrial",
    frontEndKey: "industrial",
    backEndKey: "industrial",
    measure: "SF"
  },
  {
    order: 5,
    name: "Lodging & Gaming",
    value: "Hotel",
    frontEndKey: "hotel",
    backEndKey: "hotel",
    description: "Hotels and casinos.",
    measure: "Keys"
  },
  {
    order: 6,
    name: "Life Sciences",
    value: "Lab",
    frontEndKey: "lab",
    backEndKey: "lab",
    measure: "SF"
  },
  {
    order: 7,
    name: "Healthcare",
    value: "Healthcare",
    frontEndKey: "healthCare",
    backEndKey: "health_care",
    description: "Medical office, hospitals.",
    measure: "SF"
  },
  {
    order: 8,
    name: "Senior Housing",
    value: "Senior Housing",
    frontEndKey: "seniorHousing",
    backEndKey: "senior_housing",
    measure: "Units"
  },
  {
    order: 9,
    name: "Data Center",
    value: "Data Center",
    frontEndKey: "dataCenter",
    backEndKey: "data_center",
    measure: "SF"
  },
  {
    order: 10,
    name: "Student Housing",
    value: "Student Housing",
    frontEndKey: "studentHousing",
    backEndKey: "student_housing",
    measure: "Units"
  },
  {
    order: 11,
    name: "Self-Storage",
    value: "Self-Storage",
    frontEndKey: "selfStorage",
    backEndKey: "self_storage",
    measure: "Units"
  }
];
