<template>
  <div class="ml-3 relative">
    <button
      v-tooltip="'My Notes'"
      class="p-1 inline-block relative rounded-full focus:outline-none focus:ring"
      :class="hoverColor"
      @click="openNotepad"
    >
      <svg
        class="h-6 w-6"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
      </svg>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Notepad from "./notepad";
import api from "../api";

export default {
  data() {
    return {
      propertyFollowing: null
    };
  },
  computed: {
    ...mapState(["currentUser", "route", "activeDeal"]),
    ...mapGetters(["contactTokenParam", "userIsActiveContact"]),
    notepadContent() {
      if (this.isMarketingList) {
        return this.activeDeal;
      } else {
        return null;
      }
    },
    notepadContentType() {
      if (this.isMarketingList) {
        return "deal";
      } else {
        return null;
      }
    },
    isMarketingList() {
      return _.includes(this.route.path, "deal_offerings");
    },
    isPurchaseables() {
      return _.includes(this.route.path, "/market/");
    },
    isPricing() {
      return this.route.path === "/pricing";
    },
    hoverColor() {
      if (this.isMarketingList || this.isPurchaseables || this.isPricing) {
        return "text-indigo-200 hover:bg-indigo-600 hover:text-white focus:text-white";
      } else if (this.contactTokenParam || this.route.path === "/legal") {
        return "text-gray-400 hover:text-white focus:bg-gray-700";
      } else {
        return "text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:text-gray-500";
      }
    }
  },
  methods: {
    openNotepad() {
      this.$store.commit("openSlideover", {
        component: Notepad,
        props: {
          myNotepad: true,
          content: this.notepadContent,
          contentType: this.notepadContentType
        }
      });
    }
  }
};
</script>
