<template>
  <div class="px-4 py-5 sm:p-6">
    <div v-if="!confirmationFailed" class="flex justify-center">
      <grid-loader v-if="!loaded || submitting" :loading="!loaded || submitting" size="10px" color="#5850ec" />
    </div>

    <!-- TODO: DISPLAY PAYMENT METHODS IF CONFIRMATION FAILS -->
    <template v-if="confirmationFailed">
      <div v-if="downgrading" v-observe-visibility="{ callback: trackPurchaseFunnel }" class="sm:flex sm:items-center sm:justify-between">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Downgrade to Gatherer tier
          </h3>
          <div class="mt-2 max-w-2xl text-sm leading-5 text-gray-500">
            <p>
              You will continue to have access to {{ existingPlan | capitalize }} tier until the end of the current billing period. All of your Safezone data will be intact, and your payment method will not be charged again.
            </p>
          </div>
        </div>
        <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
          <grid-loader v-if="submitting" :loading="submitting" size="6px" color="#5850ec" />
          <span v-else class="inline-flex rounded-md shadow-sm">
            <button @click="downgrade" type="button" :disabled="submitting" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
              Downgrade
            </button>
          </span>
        </div>
      </div>

      <div v-else-if="newPlan === 'insider'" v-observe-visibility="{ callback: trackPurchaseFunnel }" class="sm:flex sm:items-center sm:justify-between">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Upgrade to Insider tier
          </h3>
          <div class="mt-2 max-w-2xl text-sm leading-5 text-gray-500">
            <p>
              I authorize Tower Hunt to charge my card at the end of each billing period for the Premium Insights I unlocked during that period. I understand that I am able to confirm each individual Premium Insight before unlocking it, and that the price of each Premium Insight is clearly listed. Finally, I understand that there are no refunds.
            </p>
          </div>
        </div>
        <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
          <grid-loader v-if="submitting" :loading="submitting" size="6px" color="#5850ec" />
          <span v-else class="inline-flex rounded-md shadow-sm">
            <button @click="authorizeInsider" type="button" :disabled="submitting" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
              Authorize
            </button>
          </span>
        </div>
      </div>

      <div v-else-if="selectedCard" v-observe-visibility="{ callback: trackPurchaseFunnel }" class="sm:flex sm:items-center sm:justify-between">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Subscribe to {{ newPlan | capitalize }}
          </h3>
          <div class="mt-2 max-w-2xl text-sm leading-5 text-gray-500">
            <p>
              <template v-if="creditBalance > 0">When my credit balance of ${{ creditBalance | formattedNumber(2) }} runs out, </template>I authorize Tower Hunt to charge my card <strong class="font-semibold"><span>${{ price | formattedNumber(2) }}<span class="font-normal">{{ billingFrequency === "monthly" ? "/mo" : "/yr" }}</span></span></strong> to license this tier. <router-link to="/product/premium-content" target="_blank" class="ml-1 font-medium text-indigo-600 hover:text-indigo-500">Learn more &rarr;</router-link>
            </p>
            <p>
              I also authorize Tower Hunt to charge my card at the end of each billing period for the Premium Insights I unlocked during that period. I understand that I am able to confirm each individual Premium Insight before unlocking it, and that the price of each Premium Insight is clearly listed. Finally, I understand that there are no refunds.
            </p>
          </div>
        </div>
        <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
          <grid-loader v-if="submitting" :loading="submitting" size="6px" color="#5850ec" />
          <span v-else class="inline-flex rounded-md shadow-sm">
            <button @click="createSubscription" type="button" :disabled="submitting" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
              Subscribe
            </button>
          </span>
        </div>
      </div>

      <payment-methods v-else :selectable="true" capturing-payment="now" @card-selected="selectCard" />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import PaymentMethods from "../../payment-methods";
import User from "../../../user";
import api from "../../../api";
/* global analytics */
/* global Stripe */

export default {
  components: { GridLoader, PaymentMethods },
  props: ["newPlan", "billingFrequency"],
  data() {
    return {
      submitting: false,
      clientSecret: null,
      intentId: null,
      subscription: null,
      paymentMethod: null,
      stripe: null,
      confirmationFailed: false,
      selectedCard: null
    };
  },
  computed: {
    ...mapState(["adminMode", "currentUser"]),
    existingPlan() {
      return _.get(this.currentUser, "productTier", "gatherer");
    },
    loaded() {
      if (this.downgrading) {
        return true;
      } else if (this.isSubscription) {
        return !!this.stripe;
      } else {
        return !!this.stripe && !!this.clientSecret && !!this.intentId;
      }
    },
    isSubscription() {
      return this.newPlan === "hunter";
    },
    downgrading() {
      return this.newPlan === "gatherer";
    },
    price() {
      if (this.newPlan === "hunter") {
        return this.billingFrequency === "monthly" ? 29 : 313;
      } else {
        return 0;
      }
    },
    creditBalance() {
      return this.currentUser.spendableCredits;
    }
  },
  mounted() {
    if (!!Stripe) {
      api.get(`payment_api_key`).then(json => {
        const stripeKey = json.data;

        this.stripe = Stripe(stripeKey);
        if (this.downgrading) {
          this.confirmationFailed = true;
        } else if (this.isSubscription) {
          this.confirmationFailed = true;
        }
      });
    } else {
      this.$store.commit("closeModal");
    }
  },
  beforeDestroy() {
    this.$store.dispatch("loadCurrentUser");
  },
  methods: {
    trackPurchaseFunnel(isVisible, entry) {
      if (!this.adminMode && !!analytics && isVisible) {
        analytics.track("Selected payment method for subscription tier", {
          yearWeek: this.$store.state.yearWeek,
          newPlan: this.newPlan
        });
      }
    },
    downgrade() {
      api.delete(`user_subscriptions`).then(json => {
        if (!this.adminMode && !!analytics) {
          analytics.track("Downgraded to Gatherer (free)", {
            yearWeek: this.$store.state.yearWeek
          });
        }

        this.submitting = false;
        this.$store.commit("closeModal");
      });
    },
    authorizeInsider() {
      this.$store.dispatch("flash", "Coming soon!");
      // this.confirmPayment();
    },
    createSubscription() {
      const payload = {
        paymentMethod: this.paymentMethod,
        plan: this.newPlan,
        billingFrequency: this.billingFrequency
      };

      this.submitting = true;
      api.post(`user_subscriptions`, payload).then(json => {
        this.subscription = json.data.subscription;

        if (this.subscription.status === "active") {
          this.$store.dispatch(
            "flash",
            `You successfully subscribed to ${this.newPlan}!`
          );
          this.confirmationFailed = false;
          this.activateSubscription();

          return;
        }

        let paymentIntent = this.subscription.latest_invoice.payment_intent;

        if (
          paymentIntent.status === "requires_action" ||
          paymentIntent.status === "requires_payment_method"
        ) {
          this.clientSecret = paymentIntent.client_secret;

          if (paymentIntent.status === "requires_action") {
            this.submitting = false;
            this.confirmPayment();
          }

          if (paymentIntent.status === "requires_payment_method") {
            this.selectedCard = null;
            this.confirmationFailed = true;
            this.submitting = false;
          }
        }
      });
    },
    confirmPayment() {
      this.submitting = true;
      this.stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: this.paymentMethod
        })
        .then(
          data => {
            if (data.error) {
              console.log(data.error.message);
              this.$store.dispatch("flash", data.error.message);
              this.confirmationFailed = true;
              this.selectedCard = null;
            } else {
              this.$store.dispatch("flash", "Payment successful!");
              this.confirmationFailed = false;
              this.activateSubscription();
            }
          },
          failure => {
            this.submitting = false;
          }
        );
    },
    selectCard(record) {
      this.paymentMethod = record.id;
      this.selectedCard = record;
    },
    activateSubscription() {
      const payload = {
        paymentMethod: this.paymentMethod,
        plan: this.newPlan,
        billingFrequency: this.billingFrequency,
        subscriptionId: _.get(this.subscription, "id", null)
      };

      api.patch(`user_subscriptions`, payload).then(json => {
        if (!this.adminMode && !!analytics) {
          let purchaseType;

          if (_.get(this.subscription, "id", null)) {
            purchaseType = "Purchased subscription";
          } else {
            purchaseType = "Authorized Insider metered billing";
          }

          analytics.track(purchaseType, {
            yearWeek: this.$store.state.yearWeek,
            newPlan: this.newPlan
          });
        }

        this.submitting = false;
        this.$store.commit("closeModal");
      });
    }
  }
};
</script>

<style>
.stripe-card {
  width: 300px;
  padding: 3px;
  display: inline-block;
  border: 1px solid transparent;
}
.stripe-card.complete {
  border-color: #0e9f6e;
  border-radius: 3px;
}

.payment-form {
  display: flex;
}
</style>
