<template>
  <div
    class="z-1 flex flex-grow flex-col"
    v-observe-visibility="{ callback: createImpression, once: true }"
  >
    <div v-if="post.type === 'jobPosting' && mappable && !compact" class="flex-shrink-0">
      <div class="h-64">
        <intel-map :post="post" />
      </div>
    </div>
    <div v-else-if="post.type !== 'jobPosting' && (post.thumbnail || primaryAttachmentIsImage || mappable)" class="flex-shrink-0 relative">
      <img v-if="post.thumbnail" @click="viewDetails" :class="compact ? 'h-28' : 'h-64'" class="w-full object-cover cursor-pointer" :src="post.thumbnail" alt="Post url thumbnail" />
      <img v-else-if="primaryAttachmentIsImage" @click="viewDetails" :class="compact ? 'h-28' : 'h-64'" class="w-full object-cover cursor-pointer" :src="post.primaryAttachment" alt="Deal attached photo" />
      <div v-else-if="mappable && !compact" class="h-64">
        <intel-map :post="post" />
      </div>
      <div v-if="mappable && !compact" class="absolute inset-x-0 bottom-0 transform translate-y-px pointer-events-none" style="z-index: 9995;">
        <div class="flex justify-center transform translate-y-1/2">
          <a @click.prevent="strategicMap" href="" class="inline-flex items-center rounded-full text-sm px-4 py-1 leading-5 bg-indigo-600 hover:bg-indigo-700 font-semibold tracking-wider uppercase text-white pointer-events-auto">
            <!-- Heroicon name: cursor-click -->
            <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z" clip-rule="evenodd" />
            </svg>
            See comps on strategic map
          </a>
        </div>
      </div>
    </div>
    <div :class="compact ? 'p-2' : 'p-6'" class="relative flex-1 bg-white flex flex-col justify-between">
      <right-edge-buttons :content="post" content-type="intel" />
      <div @click="viewDetails" class="flex-1 cursor-pointer">
        <div :class="compact ? 'text-xs leading-4' : 'text-sm leading-5'" class="flex items-center font-medium text-indigo-600">
          <img v-show="false" class="h-6 w-6 mr-2" src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png" alt="Tower Hunt" />
          <intel-type-badge :post="post" :compact="compact" />
          <template v-if="post.type === 'industryEvent' && post.dateType">
            <span class="mx-1">
              &middot;
            </span>
            <span>
              {{ post | eventDates }}
            </span>
          </template>
        </div>
        <span class="block">
          <h3 :class="compact ? 'mt-1 text-base leading-4' : 'mt-2 text-xl leading-7'" class="font-semibold text-gray-900">
            {{ post.name }}
          </h3>
          <div v-if="post.type === 'developmentNews' && hasProperties" :class="compact ? 'text-xs leading-4' : 'text-sm leading-5'" class="text-gray-500">
            {{ post.properties | summarizedLocation }}
          </div>
          <div v-if="post.type === 'jobPosting'" :class="compact ? 'text-xs leading-4' : 'text-sm leading-5'" class="text-gray-500">
            {{ post | location }}
          </div>
          <div v-if="post.type === 'jobPosting'" class="mt-4 text-sm leading-6 text-gray-500 whitespace-pre-line">
            <template v-if="post.uses.length > 0">Property uses: {{ post.uses | sortedTypeLabels }}</template>
            <template v-else>All property uses</template>
          </div>
          <div v-if="post.type === 'jobPosting'" class="mt-4 text-sm leading-6 text-gray-500 whitespace-pre-line">
            <template v-if="post.deals.length > 0">Exposure to: {{ post.deals | sortedTypeLabels }}</template>
            <template v-else>All deal types</template>
          </div>
          <div v-if="post.type === 'jobPosting'" class="mt-4 text-sm leading-6 text-gray-500 whitespace-pre-line">
            <template v-if="post.risks.length > 0">Strategies: {{ post.risks | sortedTypeLabels }}</template>
            <template v-else>All strategies</template>
          </div>
          <div v-else-if="post.notes" :class="compact ? 'mt-2 text-xs leading-4' : 'mt-4 text-sm leading-6'" class="text-gray-500 whitespace-pre-line">{{ truncatedNotes }}</div>
        </span>
      </div>
      <div :class="compact ? 'mt-2' : 'mt-6'" class="flex items-center">
        <div class="flex-shrink-0">
          <router-link v-if="post.author" :to="`/contacts/${post.author.token}`" class="flex-shrink-0 cursor-pointer">
            <avatar-photo v-tooltip="authorTooltip" :person="post.author" :circle-size="compact ? 6 : 8" :text-size="compact ? 'sm' : 'base'" />
          </router-link>
          <span
            v-else
            v-tooltip="'Posted anonymously'"
            :class="compact ? 'h-6 w-6' : 'h-8 w-8'"
            class="flex-shrink-0 inline-flex items-center justify-center rounded-full bg-gray-400"
          >
            <svg :class="compact ? 'h-3 w-3' : 'h-4 w-4'" class="text-white" fill="currentColor" viewBox="0 0 384 512">
              <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
            </svg>
          </span>
        </div>
        <div class="ml-3">
          <div :class="compact ? 'text-xs leading-4' : 'text-sm leading-5'" class="font-medium text-gray-900">
            <router-link v-if="post.author" :to="`/contacts/${post.author.token}`" class="hover:underline">
              {{ authorTooltip }}
            </router-link>
            <span v-else-if="post.bounty">Requested by {{ requsterAlias }}</span>
            <span v-else>Posted anonymously</span>
          </div>
          <div :class="compact ? 'text-xs leading-4' : 'text-sm leading-5'" class="flex text-gray-500">
            <time :datetime="post.createdAt">{{ post.createdAt | moment("from", now) }}</time>
            <template v-if="post.pinCount && post.pinCount > 0">
              <span class="mx-1">
                &middot;
              </span>
              <span class="flex items-center text-indigo-600" v-tooltip="pinHelp">
                <svg v-if="isBounty" class="mr-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" clip-rule="evenodd" />
                </svg>
                <svg v-else class="mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"/>
                </svg>
                <span>{{ post.pinCount }}</span>
              </span>
            </template>
            <template v-if="post.commentCount && post.commentCount > 0">
              <span class="mx-1">
                &middot;
              </span>
              <span @click="viewComments" class="flex items-center text-indigo-600 cursor-pointer" v-tooltip="'Comments'">
                <svg class="mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                  <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                </svg>
                <span>{{ post.commentCount }}</span>
              </span>
            </template>
            <template v-if="post.bountyCount && post.bountyCount > 0">
              <span class="mx-1">
                &middot;
              </span>
              <span @click="viewComments" class="flex items-center text-indigo-600 cursor-pointer" v-tooltip="'Bounties'">
                <svg class="mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
                <span>{{ post.bountyCount }}</span>
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AvatarPhoto from "../avatar-photo";
import Comments from "../comments";
import IntelCard from "./market-intel";
import IntelMap from "../maps/market-intel-map";
import IntelTypeBadge from "../intel-type-badge";
import RightEdgeButtons from "./right-edge-buttons";

export default {
  components: {
    AvatarPhoto,
    IntelMap,
    IntelTypeBadge,
    RightEdgeButtons
  },
  props: ["post", "compact", "property"],
  computed: {
    ...mapGetters(["signedIn"]),
    ...mapState(["now", "route", "adminMode"]),
    isBounty() {
      return _.get(this.post, "bounty.standalone", false);
    },
    pinHelp() {
      return this.isBounty
        ? "People following this bounty"
        : "People were helped by this";
    },
    requsterAlias() {
      if (this.post.bounty.sponsored) {
        return "Principal";
      } else {
        return _.capitalize(this.$options.filters.industryRoleAlias(this.post.bounty.requesterIndustryRole, this.post.bounty.requesterIndustrySubRole));
      }
    },
    authorTooltip() {
      const name = this.$options.filters.capitalize(this.post.author.name);
      const role = _.capitalize(this.$options.filters.industryRoleAlias(this.post.author.industryRole, this.post.author.industrySubRole, this.post.author.token));

      if (this.post.author.company) {
        return `${name} (${role} at ${this.post.author.company})`;
      } else {
        return `${name} (${role})`;
      }
    },
    isJobs() {
      return this.route.path === "/jobs";
    },
    mappable() {
      return this.hasRegions || this.post.location || this.hasProperties;
    },
    hasRegions() {
      return _.get(this.post, "regions", []).length > 0;
    },
    hasProperties() {
      return _.get(this.post, "properties", []).length > 0;
    },
    isVisible() {
      return (
        this.post.pinned ||
        !this.post.anonymous ||
        (!this.post.dismissed && !this.post.downvoted)
      );
    },
    truncatedNotes() {
      if (this.post.thumbnail || this.mappable) {
        return _.truncate(this.post.notes, { length: 140 });
      } else {
        return _.truncate(this.post.notes, { length: 320 });
      }
    },
    primaryAttachmentIsImage() {
      if (this.post.primaryAttachmentType) {
        return _.includes(this.post.primaryAttachmentType, "image");
      } else {
        return false;
      }
    }
  },
  methods: {
    createImpression(isVisible, entry) {
      if (!this.adminMode && this.signedIn && isVisible) {
        this.$store.dispatch("createIntelImpression", this.post.token).then(() => {
          this.$store.dispatch("loadUnreadCounts");
        });
      }
    },
    viewDetails() {
      if (!!analytics && !this.adminMode) {
        analytics.track("Clicked intel preview card", {
          intelType: this.post.type,
          yearWeek: this.$store.state.yearWeek
        });
      }

      if (this.signedIn) {
        this.$store.commit("openModal", {
          component: IntelCard,
          props: {
            postObject: this.post,
            property: this.property
          }
        });
      } else {
        this.$router.push({
          path: "/sign-in",
          query: { nextUrl: `/open_intel/${this.post.token}` }
        });
        this.$store.commit("closeModal");
      }
    },
    viewComments() {
      if (this.signedIn) {
        this.$store.commit("openSlideover", {
          component: Comments,
          props: {
            content: this.post
          }
        });
      } else {
        this.$router.push({
          path: "/sign-in",
          query: { nextUrl: `/open_intel/${this.post.token}` }
        });
        this.$store.commit("closeModal");
      }
    },
    strategicMap() {
      this.$router.push({ path: `/map?intel=${this.post.token}` });
      this.$store.commit("clearModal");
    }
  }
};
</script>
