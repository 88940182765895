<template>
  <div class="relative p-4 max-w-xl mx-auto">
    <div class="text-center">
      <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Contact the owner
      </h2>
      <p class="mt-4 text-lg leading-6 text-gray-500">
        <router-link to="/product/off-market" target="_blank" class="underline">Learn more</router-link> about what the owner sees.
      </p>
    </div>
    <div class="mt-12">
      <form @submit.prevent="sendMessage" class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        <div class="sm:col-span-2">
          <label for="message" class="block text-sm font-medium leading-5 text-gray-700">Message</label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <resizable-textarea>
              <textarea v-focus v-model="message" id="message" type="text" rows="4" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 py-3 px-4 block w-full"></textarea>
            </resizable-textarea>
          </div>
        </div>
        <div class="sm:col-span-2">
          <span class="w-full inline-flex rounded-md shadow-sm">
            <button type="submit" class="border rounded-md text-base leading-6 font-medium w-full inline-flex items-center justify-center px-6 py-3 border" :class="buttonStyling" :disabled="!complete">
              Let's talk
            </button>
          </span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ResizableTextarea from "../../resizable-textarea";
import api from "../../../api";
/* global analytics */

export default {
  components: { ResizableTextarea },
  props: ["card"],
  data() {
    return {
      message: ""
    };
  },
  computed: {
    ...mapState(["adminMode"]),
    complete() {
      return _.trim(this.message) !== "";
    },
    buttonStyling() {
      if (this.complete) {
        return "border-transparent text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700";
      } else {
        return "border-gray-300 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 cursor-not-allowed";
      }
    }
  },
  methods: {
    closeModal() {
      this.$store.commit("closeModal");
    },
    sendMessage() {
      if (this.complete) {
        const payload = {
          message: this.message,
          collectibleCardId: this.card.id
        };

        api.post(`off_market_property_owner_message`, payload).then(json => {
          if (!!analytics && !this.adminMode) {
            analytics.track("Off-market owner messaged", {
              yearWeek: this.$store.state.yearWeek
            });
          }
          this.$store.dispatch("flash", "Owner messaged successfully!");
          this.$store.commit("setTemporaryAccess", json.data);
          this.closeModal();
        });
      }
    }
  }
};
</script>
