<template>
  <div class="space-y-6">
    <div class="shadow sm:rounded-md">
      <div class="bg-white py-6 px-4 sm:p-6">
        <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div class="ml-4 mt-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Property Uses
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              How large is the building and what are its uses?
            </p>
          </div>
          <div v-if="possibleUses" class="ml-4 mt-4 flex-shrink-0 relative">
            <attribution-menu
              :content-token="activeProperty.id"
              content-type="property"
              field="possible_use"
              buttonClass="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              svgClass="text-gray-400"
            />
          </div>
        </div>

        <div class="mt-6 flex items-center justify-between">
          <span class="flex-grow flex flex-col">
            <span class="text-sm font-medium text-gray-900" id="size-precision-label">Precision</span>
            <span class="text-sm text-gray-500" id="size-precision-description">Exact sizes or possible uses?</span>
          </span>
          <div class="flex items-center">
            <button @click="togglePossibleUses" type="button" role="switch" :aria-checked="!possibleUses" aria-labelledby="size-precision-toggle-label" :class="possibleUses ? 'bg-gray-200' : 'bg-indigo-600'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span aria-hidden="true" :class="possibleUses ? 'translate-x-0' : 'translate-x-5'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
            </button>
            <span class="ml-3" id="size-precision-toggle-label">
              <span class="text-sm font-medium text-gray-900">{{ possibleUses ? 'Possible uses' : 'Exact sizes' }}</span>
            </span>
          </div>
        </div>

        <div v-if="possibleUses" class="mt-6 grid grid-cols-4 gap-6">
          <multi-select-checkbox
            v-for="useType in useTypes"
            :key="useType.backEndKey"
            setter-name="addPossibleUseToProperty"
            forgetter-name="removePossibleUseFromProperty"
            setter-type="commit"
            :store-record="activeProperty.possibleUses"
            :option-record="useType"
            class="col-span-4 sm:col-span-2"
          />
        </div>
        <div v-else class="mt-6 grid grid-cols-4 gap-6">
          <size-field v-for="useType in useTypesWithParking" :key="useType.backEndKey" :use-type="useType" class="col-span-4 sm:col-span-2" />
        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button @click="save" type="button" class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Save
        </button>
      </div>
    </div>

    <div class="shadow sm:rounded-md">
      <div class="bg-white py-6 px-4 sm:p-6">
        <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div class="ml-4 mt-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Land Area
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              How much land does the site include?
            </p>
          </div>
        </div>

        <div class="mt-6 grid grid-cols-3 gap-6">
          <div class="col-span-3 relative">
            <label for="land_area" class="block text-sm font-medium text-gray-700">Acreage</label>
            <div class="mt-1 flex rounded-md shadow-sm">
              <div class="flex relative items-stretch flex-grow focus-within:z-10">
                <input v-model="landArea" name="land_area" type="number" min="0" step="0.1" placeholder="" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pr-16 sm:text-sm border-gray-300">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span class="text-gray-500 sm:text-sm" id="acreage">
                    AC
                  </span>
                </div>
              </div>
              <attribution-menu
                :content-token="activeProperty.id"
                content-type="property"
                field="land_area"
                buttonClass="-ml-px inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                svgClass="text-gray-400"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button @click="save" type="button" class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AttributionMenu from "../../crowdsourcing/attribution-menu.vue";
import MultiSelectCheckbox from "../../multi-select-checkbox.vue";
import SizeField from "./size-field.vue";
import useTypes from "../../../use-types";

export default {
  components: { AttributionMenu, MultiSelectCheckbox, SizeField },
  data() {
    return {
      possibleUses: false
    };
  },
  computed: {
    ...mapState(["activeProperty"]),
    ...mapGetters(["propertyComplete"]),
    landArea: {
      get() {
        return this.activeProperty.landArea;
      },
      set(acres) {
        this.$store.commit("setPropertyLandArea", acres);
      }
    },
    useTypesWithParking() {
      return _.concat(useTypes, {
        order: 12,
        name: "Parking",
        value: "Parking",
        frontEndKey: "parking",
        backEndKey: "parking",
        measure: "Spaces"
      });
    },
    useTypes() {
      return useTypes;
    },
    complete() {
      const uses = [
        "office",
        "retail",
        "industrial",
        "multifamily",
        "lab",
        "hotel",
        "healthCare",
        "seniorHousing",
        "dataCenter",
        "studentHousing",
        "selfStorage",
        "parking"
      ];
      const hasSize = uses.some(use => this.$store.getters.hasSize(use));

      return hasSize || this.activeProperty.possibleUses.length > 0;
    }
  },
  mounted() {
    if (this.activeProperty.possibleUses.length > 0) {
      this.possibleUses = true;
    }
  },
  methods: {
    save() {
      if (this.complete) {
        this.$store.dispatch("updateProperty", {
          clear: false,
          propertyId: this.activeProperty.id
        });
      }
    },
    togglePossibleUses() {
      if (this.possibleUses) {
        this.possibleUses = false;
        this.$store.commit("clearPropertyPossibleUses");
      } else {
        this.possibleUses = true;
        this.$store.commit("clearPropertySizes");
      }
    }
  }
};
</script>
