<template>
  <div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="mb-64 shadow border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Document
                  </th>
                  <th v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Metrics
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Menu</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <document-table-row v-for="document in documents" :key="document.id" :document="document" />
                <tr v-if="unregistered">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <button @click="register" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <!-- Heroicon name: mail -->
                        <svg class="-ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
                        </svg>
                        Register
                      </button>
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          Additional documents available
                        </div>
                        <div class="text-sm text-gray-500 flex items-center">
                          to registered parties
                        </div>
                      </div>
                    </div>
                  </td>
                  <td v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" class="px-6 py-4 whitespace-nowrap" />
                  <td class="px-6 py-4 whitespace-nowrap" />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AcceptConfidentialityAgreement from "./accept-confidentiality-agreement";
import DocumentTableRow from "./document-table-row";

export default {
  components: { DocumentTableRow },
  props: ["documents"],
  computed: {
    ...mapState(["activeDeal", "activeDealMarketing", "modal", "xlModal"]),
    ...mapGetters(["signedIn", "dealProspectInviteParam"]),
    unregistered() {
      return (
        !this.activeDeal.dealTeam &&
        _.includes(["live", "awarded"], this.activeDeal.state) &&
        (this.activeDeal.viewerDealMarketingState === "viewed" || (this.activeDealMarketing.visibility === "unrestricted" && !this.activeDeal.viewerDealMarketingState))
      );
    }
  },
  methods: {
    register() {
      if (this.signedIn && this.activeDeal.marketingReadAuthorized) {
        this.$store.commit("openXLModal", {
          component: AcceptConfidentialityAgreement,
          props: {},
          lightMode: true
        });
      } else if (this.activeDeal.marketingReadAuthorized) {
        this.$store.commit("setNewUserDealProspectInvitation", {
          token: this.dealProspectInviteParam,
          action: "registerDeal",
          dealToken: this.activeDeal.token
        });
        this.$router.push({ path: "/start/1" });
      }
    }
  }
};
</script>
