<template>
  <div class="relative pb-8">
    <span v-if="extendLine" class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
    <div class="relative flex space-x-3">
      <div>
        <span :class="`h-8 w-8 rounded-full bg-${intelTypeColor}-100 text-${intelTypeColor}-400 flex items-center justify-center ring-8 ring-white`">
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
          </svg>
        </span>
      </div>
      <div class="min-w-0 flex-1 pt-1.5 flex flex-col">
        <div class="flex justify-between space-x-4">
          <div>
            <p class="text-sm text-gray-500">Referenced in
              <a @click.prevent="viewDetails" class="font-medium text-gray-900 hover:underline cursor-pointer">{{ intel.type | postTypeAlias }}<template v-if="showIntelName">: {{ intel.name }}</template></a>
            </p>
          </div>
          <div class="text-right text-sm whitespace-nowrap text-gray-500">
            <time :datetime="intel.newsfeedSortDate">{{ sortDate }}</time>
          </div>
        </div>
        <div v-if="intel.notes" v-html="linkifiedNotes" class="mt-1 text-xs leading-4 text-gray-500 whitespace-pre-line dont-break-out line-clamp-3" />
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters } from "vuex";
import IntelCard from "./cards/market-intel";
import linkifyHtml from "linkifyjs/html";

export default {
  props: ["intel", "extendLine", "property"],
  computed: {
    ...mapGetters(["signedIn"]),
    showIntelName() {
      if (this.property) {
        return _.toLower(this.property.name) !== _.toLower(this.intel.name);
      } else {
        return true;
      }
    },
    sortDate() {
      return moment(this.intel.newsfeedSortDate).format("MMM YYYY");
    },
    intelTypeColor() {
      switch (this.intel.type) {
        case "jobPosting":
        case "helpRequest":
          return "purple";
        case "industryEvent":
          return "red";
        case "developmentNews":
          return "orange";
        case "infrastructureNews":
          return "yellow";
        case "tenantInMarket":
          return "green";
        case "marketCommentary":
          return "teal";
        case "marketReport":
          return "blue";
        case "offMarketPropertySnapshot":
          return "gray";
        default:
          return "indigo";
      }
    },
    linkifiedNotes() {
      return linkifyHtml(_.get(this.intel, "notes", ""), {
        defaultProtocol: "https",
        className: "font-medium text-indigo-600 hover:text-indigo-500"
      });
    }
  },
  methods: {
    viewDetails() {
      if (this.signedIn) {
        this.$store.commit("closeSlideover");
        this.$store.commit("openModal", {
          component: IntelCard,
          props: {
            postObject: this.intel,
            property: this.property
          }
        });
      } else {
        this.$router.push({
          path: "/sign-in",
          query: { nextUrl: `/open_intel/${this.intel.token}` }
        });
      }
    },
  }
};
</script>
