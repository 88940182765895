<template>
  <div class="relative p-4 max-w-3xl mx-auto">
    <div class="text-center">
      <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        {{ title }}
      </h2>
      <p v-if="purchaseable.name" class="mt-4 text-xl font-bold leading-6 text-gray-900">
        {{ purchaseable.name }}
      </p>
    </div>
    <div class="mt-12 mb-20">
      <purchaseable-pricing-form v-if="editing" :purchaseable="purchaseable" :editing="editing" @done-editing="editing = null" />
      <price-list v-else :purchaseable="purchaseable" @new-price="newPrice" @edit-price="editPrice" />
    </div>
  </div>
</template>

<script>
import PriceList from "./price-list";
import PurchaseablePricingForm from "./purchaseable-pricing-form.vue";

export default {
  components: { PurchaseablePricingForm, PriceList },
  props: ["purchaseable"],
  data() {
    return {
      editing: null
    };
  },
  computed: {
    title() {
      if (_.isString(this.editing)) {
        return "Set Pricing";
      } else if (_.isObject(this.editing)) {
        return "Update Pricing";
      } else {
        return "Premium Content Pricing";
      }
    }
  },
  methods: {
    newPrice() {
      this.editing = "new";
    },
    editPrice(price) {
      this.editing = price;
    }
  }
};
</script>
