<template>
  <div>
    <div class="mt-6">
      <div class="my-4 bg-white overflow-hidden shadow rounded-lg">
        <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div class="ml-4 mt-2">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Live Deals (T12)
              </h3>
            </div>
            <div class="ml-4 mt-2 flex-shrink-0 flex items-center">
              <target-live-deal-consent v-if="target.authored || target.followed" :target="target" />
              <span v-if="target.authored" class="ml-3 inline-flex rounded-md shadow-sm">
                <button @click=newIndirectActiveDeal type="button" :class="privacyMode ? 'bg-pink-600 hover:bg-pink-500 focus:border-pink-700 focus:ring-pink active:bg-pink-700' : 'bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700'" class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none">
                  Report a live deal
                </button>
              </span>
            </div>
          </div>
        </div>
        <div v-if="hasRumoredDeals" class="px-4 py-5 sm:p-6">
          <div class="grid gap-5 max-w-lg mx-auto sm:grid-cols-2 lg:grid-cols-3 sm:max-w-none">
            <multi-content-preview-card
              v-for="deal in rumoredDeals"
              :key="`${deal.token}`"
              :content="deal"
            />
          </div>
        </div>
        <div v-else class="relative bg-gray-800">
          <div class="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
            <img class="w-full h-full object-cover" src="https://source.unsplash.com/collection/1976082" alt="Pipeline intro" />
          </div>
          <div class="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
            <div class="md:ml-auto md:w-1/2 md:pl-10">
              <div class="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
                Deals reported by the network
              </div>
              <h2 class="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
                Live deals sourced via intel
              </h2>
              <p class="mt-3 text-lg leading-7 text-gray-300">
                Anonymous reports about live deals in this market will appear here.
              </p>
              <div class="mt-8">
                <div class="inline-flex rounded-md shadow">
                  <a @click.prevent="newIndirectActiveDeal" href="" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:ring">
                    Report a live deal
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="rumoredDealPagination && rumoredDealPagination.count > 3" class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div class="hidden sm:block">
            <p class="text-sm leading-5 text-gray-700">
              Showing
              <span class="font-medium">{{ rumoredDealPagination.from }}</span>
              to
              <span class="font-medium">{{ rumoredDealPagination.to }}</span>
              of
              <span class="font-medium">{{ rumoredDealPagination.count }}</span>
              deals
            </p>
          </div>
          <div class="flex-1 flex justify-between sm:justify-end">
            <router-link @click.native="fetchRumoredDeals(rumoredDealPagination.prev_url)" to="" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
              Previous
            </router-link>
            <router-link @click.native="fetchRumoredDeals(rumoredDealPagination.next_url)" to="" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
              Next
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 bg-white shadow overflow-visible rounded-md">
      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div
          class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
        >
          <div class="ml-4 mt-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Closed Deals (T12)
            </h3>
          </div>

          <div class="ml-4 mt-2 flex-shrink-0 flex">
            <closed-deal-notifications-menu v-if="target.authored || target.followed" :source-record="target" type="target" />
            <span v-if="target.authored" class="hidden ml-3 md:inline-flex rounded-md shadow-sm">
              <button
                @click="newDeal"
                type="button"
                :class="privacyMode ? 'bg-pink-600 hover:bg-pink-500 focus:border-pink-700 focus:ring-pink active:bg-pink-700' : 'bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700'"
                class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none"
              >
                Add closed deal
              </button>
            </span>
          </div>
        </div>
      </div>
      <div v-if="activeTargetDeals.length > 0" class="px-4 py-5 sm:p-6">
        <div class="grid gap-5 max-w-lg mx-auto sm:grid-cols-2 lg:grid-cols-3 sm:max-w-none">
          <multi-content-preview-card
            v-for="deal in activeTargetDeals"
            :key="`${deal.token}`"
            :content="deal"
          />
        </div>
      </div>
      <div v-if="dealPagination && dealPagination.count > 3" class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div class="hidden sm:block">
          <p class="text-sm leading-5 text-gray-700">
            Showing
            <span class="font-medium">{{ dealPagination.from }}</span>
            to
            <span class="font-medium">{{ dealPagination.to }}</span>
            of
            <span class="font-medium">{{ dealPagination.count }}</span>
            deals
          </p>
        </div>
        <div class="flex-1 flex justify-between sm:justify-end">
          <router-link @click.native="fetchDeals(dealPagination.prev_url)" to="" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
            Previous
          </router-link>
          <router-link @click.native="fetchDeals(dealPagination.next_url)" to="" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
            Next
          </router-link>
        </div>
      </div>
      <div v-if="activeTargetDeals.length === 0" class="relative bg-gray-800">
        <div class="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <img class="w-full h-full object-cover" src="https://source.unsplash.com/collection/277102" alt="Deal intro" />
        </div>
        <div class="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <div class="md:ml-auto md:w-1/2 md:pl-10">
            <div class="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
              Deal flow
            </div>
            <h2 class="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
              Transactions happening here
            </h2>
            <p class="mt-3 text-lg leading-7 text-gray-300">
              Nothing new to show you at the moment.
            </p>
            <div class="mt-8">
              <div class="inline-flex rounded-md shadow">
                <a @click.prevent="newDeal" href="" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:ring">
                  Add closed deal
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ClosedDealNotificationsMenu from "../closed-deal-notifications-menu";
import MultiContentPreviewCard from "../cards/multi-content-preview-card";
import TargetLiveDealConsent from "../notification-consents/target-live-deal";
import _ from "lodash";

export default {
  components: {
    MultiContentPreviewCard,
    TargetLiveDealConsent,
    ClosedDealNotificationsMenu
  },
  data() {
    return {
      fetchingDealUpdates: false,
      fetchingDealLongTailUpdates: false,
    };
  },
  computed: {
    ...mapState([
      "now",
      "activeTarget",
      "refetchTargetPage",
      "refetchRumoredDeals",
      "currentUser",
      "modal"
    ]),
    ...mapGetters([
      "signedIn",
      "targetTokenParam",
      "userIsActiveContact",
      "activeTargetDeals",
      "showDealSize",
      "showLeaseSize",
      "showLeverageSize",
      "showLoanTypes",
      "showJVRoles",
      "targetHasPropertySize",
      "privacyMode"
    ]),
    rumoredDealPagination() {
      return this.activeTarget.rumoredDeals.pagy;
    },
    dealPagination() {
      return this.activeTarget.matchingDeals.pagy;
    },
    target() {
      return this.$store.state.activeTarget;
    },
    hasDeals() {
      if (this.activeTargetDeals && this.activeTargetDeals.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    rumoredDeals() {
      return this.activeTarget.rumoredDeals.data;
    },
    hasRumoredDeals() {
      if (this.rumoredDeals && this.rumoredDeals.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    targetTokenParam: {
      handler: function() {
        this.fetchDeals();
        this.fetchRumoredDeals();
        this.$store.commit("createInterval", { callback: this.updateDeals, delay: 3000 });
      }
    },
    refetchRumoredDeals: {
      handler() {
        if (this.refetchRumoredDeals) {
          this.fetchRumoredDeals();
        }
      }
    },
    refetchTargetPage: {
      handler() {
        if (this.refetchTargetPage) {
          this.$store.commit("createInterval", { callback: this.updateDeals, delay: 3000 });
        }
      }
    }
  },
  mounted() {
    this.fetchDeals();
    this.fetchRumoredDeals();
    this.$store.commit("createInterval", { callback: this.updateDeals, delay: 3000 });
  },
  methods: {
    updateDeals() {
      this.$store
        .dispatch("updateTargetDeals")
        .then(({ fetchPreview, fetchLongTail }) => {
          if (fetchPreview) {
            this.fetchingDealUpdates = true;
            this.fetchingDealLongTailUpdates = true;
          } else if (fetchLongTail) {
            if (this.fetchingDealUpdates) {
              this.fetchDeals();
              this.fetchRumoredDeals();
            }

            this.fetchingDealUpdates = false;
          } else {
            this.$store.dispatch("clearAllIntervals");
            this.fetchingDealLongTailUpdates = false;

            this.fetchDeals();
            this.fetchRumoredDeals();
          }
        });
    },
    fetchRumoredDeals(url = null) {
      const cleanUrl = url ? _.drop(url, 8).join("") : url;

      this.$store.dispatch("loadTargetRumoredDeals", cleanUrl);
    },
    fetchDeals(url = null) {
      const cleanUrl = url ? _.drop(url, 8).join("") : url;

      this.$store.dispatch("loadTargetDeals", cleanUrl);
    },
    newDeal() {
      this.$router.push({ path: "/deals/new/1" });
    },
    newIndirectActiveDeal() {
      this.$router.push({
        path: "/deals/new/1",
        query: { active: true, indirect: true }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.list-complete-item {
  transition: all 2s;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.list-complete-leave-active {
  position: absolute;
}
</style>
