export default {
  title: "Are you sure you want to remove this company?",
  text: "Any contacts will remain in the database for you to use again.",
  showCancelButton: true,
  showCloseButton: true,
  focusCancel: true,
  confirmButtonColor: "#5850ec",
  cancelButtonColor: "#b7b7b7",
  confirmButtonText: "Remove",
  reverseButtons: true,
  focusConfirm: false,
  customClass: {
    header: "thswal-header-class",
    title: "thswal-title-class",
    closeButton: "thswal-close-button-class",
    content: "thswal-content-class",
    actions: "thswal-actions-class",
    confirmButton: "thswal-confirm-button-class",
    cancelButton: "thswal-cancel-button-class"
  }
};
