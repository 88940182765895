<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div>
    <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Contributors</h2>
    <ul class="mt-3 grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2">
      <attribution-contributor-card v-for="contributor in contributors" :key="contributor.id" :contributor="contributor" />
    </ul>
  </div>
</template>

<script>
import AttributionContributorCard from "../../crowdsourcing/attribution-contributor-card.vue";
import api from "../../../api";

export default {
  components: { AttributionContributorCard },
  props: ["content", "contentType"],
  data() {
    return {
      contributors: []
    };
  },
  mounted() {
    this.fetchContributors();
  },
  methods: {
    fetchContributors() {
      api
        .get(
          `data_attribution_contributors/${this.contentType}/${this.content.token}`
        )
        .then(json => {
          this.contributors = json.data;
        });
    }
  }
};
</script>
