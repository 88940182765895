<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
  -->
  <div class="bg-white">
    <product-marketing-top-bar />

    <div class="bg-gray-50">
      <!-- Header section -->
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="text-center">
          <h1 class="text-base leading-6 font-semibold text-indigo-600 uppercase tracking-wide">Bounties</h1>
          <p class="mt-1 text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">Get help from local experts.</p>
          <p class="max-w-xl mx-auto mt-5 text-xl leading-7 text-gray-500">Request anything from comps to owner lists. Local experts bid. You pay upon fulfillment.</p>
        </div>
      </div>
    </div>

    <!-- Split brand panel -->
    <div class="relative bg-white">
      <div class="absolute inset-0" aria-hidden="true">
        <div class="absolute inset-y-0 right-0 w-1/2 bg-gradient-to-r from-indigo-600 to-blue-500"></div>
      </div>
      <div class="relative max-w-screen-xl mx-auto lg:grid lg:grid-cols-2 lg:px-8">
        <div class="bg-white py-16 px-4 sm:py-24 sm:px-6 lg:px-0 lg:pr-8">
          <div class="max-w-lg mx-auto lg:mx-0">
            <h2 class="text-base leading-6 font-semibold tracking-wide text-indigo-600 uppercase">
              Hyper-local
            </h2>
            <p class="mt-2 text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">Hustlers and insiders, on-demand</p>
            <dl class="mt-12 space-y-10">
              <div class="flex">
                <div class="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-indigo-600 to-blue-500 rounded-md flex items-center justify-center">
                  <!-- Heroicon name: view-list -->
                  <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                  </svg>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-medium text-gray-900">
                    Personalized service
                  </dt>
                  <dd class="mt-2 text-base leading-6 text-gray-500">
                    Whether it's a single, hard-to-find metric or a market-wide development pipeline, we target each request to the right part of the network.
                  </dd>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-indigo-600 to-blue-500 rounded-md flex items-center justify-center">
                  <!-- Heroicon name: view-boards -->
                  <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                  </svg>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-medium text-gray-900">
                    Built around trust
                  </dt>
                  <dd class="mt-2 text-base leading-6 text-gray-500">
                    Requests and bids are anonymous. Pay only upon fulfillment. Tower Hunt provides quality assurance to both parties along with full refund protection.
                  </dd>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-indigo-600 to-blue-500 rounded-md flex items-center justify-center">
                  <!-- Heroicon name: calendar -->
                  <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-medium text-gray-900">
                    Fast and easy
                  </dt>
                  <dd class="mt-2 text-base leading-6 text-gray-500">
                    It's simple to make requests, select bidders, and collect deliverables. No matter how big or small your request, the process is consistent.
                  </dd>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-indigo-600 to-blue-500 rounded-md flex items-center justify-center">
                  <!-- Heroicon name: users -->
                  <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div class="ml-4">
                  <dt class="text-lg leading-6 font-medium text-gray-900">
                    Dynamic pricing
                  </dt>
                  <dd class="mt-2 text-base leading-6 text-gray-500">
                    Bidders set their own prices based on the scope, urgency, and difficulty of each request. This incentivizes the best talent to help you quickly.
                  </dd>
                </div>
              </div>
            </dl>
          </div>
        </div>
        <div class="bg-gradient-to-r from-indigo-600 to-blue-500 py-16 px-4 sm:py-24 sm:px-6 lg:bg-none lg:flex lg:items-center lg:justify-end lg:px-0 lg:pl-8">
          <div class="max-w-lg mx-auto w-full space-y-8 lg:mx-0">
            <div>
              <h2 class="sr-only">Price</h2>
              <p class="relative grid grid-cols-1">
                <span class="flex flex-col text-center">
                  <span class="text-5xl leading-none font-extrabold text-white tracking-tight">$0.99</span>
                  <span class="mt-2 text-base leading-6 font-medium text-indigo-200">Minimum</span>
                </span>
              </p>
            </div>
            <ul class="bg-indigo-700 bg-opacity-50 rounded sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col">
              <li class="py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-indigo-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Sale comps</span>
              </li>
              <li class="border-t border-indigo-400 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-indigo-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Lease comps</span>
              </li>
              <li class="border-t border-indigo-400 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-indigo-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Development pipelines</span>
              </li>
              <li class="border-t border-indigo-400 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white sm:border-t-0 sm:border-l">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-indigo-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Owner lists</span>
              </li>
              <li class="border-t border-indigo-400 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white sm:border-l">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-indigo-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Modeling help</span>
              </li>
              <li class="border-t border-indigo-400 border-opacity-25 py-4 px-4 flex items-center space-x-3 text-base leading-6 text-white sm:border-l">
                <!-- Heroicon name: check -->
                <svg class="h-6 w-6 text-indigo-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>Individual metrics</span>
              </li>
            </ul>
            <template v-if="signedIn">
              <router-link to="/bounties/my-bounties" class="w-full bg-white border border-transparent rounded-md py-4 px-8 flex items-center justify-center text-lg leading-6 font-medium text-indigo-600 hover:text-indigo-500 md:px-10">
                Create a bounty
              </router-link>
              <router-link to="/bounties/active" class="block text-center text-base leading-6 font-medium text-indigo-200 hover:text-white">
                Bid on active bounties
              </router-link>
            </template>
            <router-link v-else to="/start/1" class="w-full bg-white border border-transparent rounded-md py-4 px-8 flex items-center justify-center text-lg leading-6 font-medium text-indigo-600 hover:text-indigo-500 md:px-10">
              Get started
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Logo cloud -->
    <div class="bg-indigo-300 bg-opacity-25">
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="lg:grid lg:grid-cols-2 lg:gap-8 items-center">
          <h2 class="max-w-md mx-auto text-3xl leading-9 font-extrabold text-indigo-900 text-center lg:max-w-xl lg:text-left">
            Tap local market experts from
          </h2>
          <div class="mt-8 flow-root lg:mt-0 self-center">
            <div class="-mt-4 -ml-8 flex flex-wrap justify-around">
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/Bulfinch_indigo0.png" alt="Bulfinch">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/CBRE_indigo0.png" alt="CBRE">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/CushmanWakefield_indigo0.png" alt="Cushman & Wakefield">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/DavisCompanies_indigo0.png" alt="The Davis Companies">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/EastdilSecured_indigo0.png" alt="Eastdil Secured">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/JLL_indigo0.png" alt="JLL">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/newmark_indigo0.png" alt="Newmark">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/Nordblom_indigo0.png" alt="Nordblom">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FAQs -->
    <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <h2 class="text-3xl leading-9 font-extrabold text-gray-900 text-center">
        Frequently asked questions
      </h2>
      <div class="mt-12">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              How do I make a request?
            </template>
            <template v-slot:secondary>
              Look for the "Create bounty" button on deals and intel, at the top of the <router-link to="/newsfeed" class="underline">newsfeed</router-link>, and on the <router-link to="/bounties/my-bounties" class="underline">Bounties page</router-link>. Describe what you need as specifically as you can. Indicate if you're willing to pay a bounty and, if so, when you need the request fulfilled. That's it!
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              Who sees my request?
            </template>
            <template v-slot:secondary>
              Before publishing the request, Tower Hunt reviews it for clarity and specificity. As part of this review, filtering metadata is added that helps target the request to the right local markets. Anyone in a matching market will be able to see and respond to the request.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              Is there anything I can't request?
            </template>
            <template v-slot:secondary>
              We welcome any request for information that people in the network may know as a result of their experience and expertise in a market. This is purposefully broad but does exclude certain things. For example, we will not facilitate requests for information from paid third-party databases. If we determine a particular request is problematic, we'll attempt to reach you to correct it.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              What does a fulfilled request include?
            </template>
            <template v-slot:secondary>
              The person fulfilling the request can provide a written response and/or a collection of file attachments. Tower Hunt reviews each submission for quality and completeness. Approved submissions are considered fulfilled, at which time you can review and download the deliverable(s).
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              When and how much will I pay?
            </template>
            <template v-slot:secondary>
              You'll pay nothing unless you accept a bid and that bid is fulfilled. Each bid specifies a price and describes what is to be provided. To accept a bid, you'll provide a credit card and agree to be charged upon fulfillment.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              Can I accept more than one bid for a given request?
            </template>
            <template v-slot:secondary>
              Yes. In some cases, multiple bids may combine to give you a better or faster result.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              What if I'm unsatisfied with the deliverable(s)?
            </template>
            <template v-slot:secondary>
              Tower Hunt holds the bounty for two weeks to guard against bad faith submissions. During that time, your feedback and/or concerns are considered along with news in the market to determine whether a refund is warranted. We also ask you to provide a simple yes/no rating: would you use this person again? We display the ratings to help build trust and encourage great performance.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              Is there any more documentation than this?
            </template>
            <template v-slot:secondary>
              Our <router-link to="/legal#terms-of-service" target="_blank" class="underline">Terms of Service</router-link> explain bounties further.
            </template>
          </text-accordian>
          <text-accordian primary-color="gray-900" secondary-color="gray-500">
            <template v-slot:primary>
              Can I earn money, too?
            </template>
            <template v-slot:secondary>
              Yes! Bid on active bounties <router-link to="/bounties/active" class="underline">here</router-link>. Learn more about bidding and earning <router-link to="/product/bounty-bids" class="underline">here</router-link>.
            </template>
          </text-accordian>
        </dl>
      </div>
    </div>

    <!-- CTA section -->
    <div v-if="!signedIn" class="bg-gradient-to-r from-indigo-600 to-blue-500">
      <div class="max-w-2xl mx-auto py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
        <h2 class="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10">
          <span class="block">Need help with something?</span>
          <span class="block text-indigo-900">Create a profile today.</span>
        </h2>
        <p class="mt-4 text-lg leading-6 text-indigo-200">We'll also help you make new connections and track your local markets.</p>
        <router-link to="/start/1" class="mt-8 w-full bg-white border border-transparent rounded-md py-3 px-5 inline-flex items-center justify-center text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 sm:w-auto">
          Get started for free
        </router-link>
      </div>
    </div>

    <!-- Simple footer -->
    <marketing-footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MarketingFooter from "../components/marketing-footer";
import ProductMarketingTopBar from "../components/product-marketing-top-bar";
import TextAccordian from "../components/text-accordian.vue";

export default {
  components: { MarketingFooter, ProductMarketingTopBar, TextAccordian },
  computed: {
    ...mapGetters(["signedIn"])
  },
  mounted() {
    document.title = "Bounties: Get help from local experts | Tower Hunt";
  }
};
</script>
