<template>
  <div class="px-4 mt-6 sm:px-6 lg:px-8">
    <ul v-if="cards.data.length > 0 || isAdmin" class="mt-3 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <li v-if="isAdmin" @click="createCard" class="col-span-1 flex flex-col text-center border-2 border-dashed border-gray-300 bg-white rounded-lg shadow cursor-pointer">
        <div class="flex-1 flex flex-col p-8">
          <svg class="w-32 h-32 flex-shrink-0 mx-auto rounded-full text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          <h3 class="mt-6 text-gray-900 text-sm leading-5 font-medium">Create new card</h3>
          <dl class="mt-1 flex-grow flex flex-col justify-between">
            <dd class="text-gray-500 text-sm leading-5">Gauge market interest</dd>
          </dl>
        </div>
      </li>

      <li v-for="card in cards.data" :key="card.token" class="relative col-span-1 flex flex-col text-center bg-white rounded-lg shadow">
        <off-market-card :auto-card-size="true" :card="card" />
        <div class="border-t border-gray-200">
          <div class="-mt-px flex">
            <div class="w-0 flex-1 flex border-r border-gray-200">
              <button @click="editCard(card.token)" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 focus:z-10">
                <!-- Heroicon name: pencil -->
                <svg class="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
                <span class="ml-3">Edit</span>
              </button>
            </div>
            <div class="-ml-px w-0 flex-1 flex">
              <router-link :to="`/gauge/${card.token}`" class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 focus:z-10">
                <!-- Heroicon name: chart-pie -->
                <svg class="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                </svg>
                <span class="ml-3">Gauge</span>
              </router-link>
            </div>
          </div>
        </div>
      </li>

      <infinite-loading
        v-if="loaded && !endOfFeed"
        :identifier="currentUser.token"
        @infinite="incrementCards"
      />
    </ul>

    <div v-else>
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div class="bg-indigo-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div class="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div class="lg:self-center">
              <h2 class="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10">
                <span class="block">Track demand for your property&mdash;</span>
                <span class="block">anonymously.</span>
              </h2>
              <p class="mt-4 text-lg leading-6 text-indigo-200">Gauge interest from real investors (and brokers if you want) without identifying your property. <router-link to="/product/gauge" class="underline">Learn more</router-link></p>
              <p v-if="helpRequested" class="mt-4 text-lg font-semibold leading-6 text-indigo-200">Great! We'll be in touch shortly.</p>
              <button v-else @click="requestHelp" class="mt-8 bg-white border border-transparent rounded-md shadow px-6 py-3 inline-flex items-center text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 hover:bg-gray-50">Setup my property</button>
            </div>
          </div>
          <div class="relative pb-3/5 -mt-6 md:pb-1/2">
            <img class="absolute inset-0 w-full h-full transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20" src="https://assets.towerhunt.com/site/gaugeMarketInterest2.jpg" alt="App screenshot">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import OffMarketCard from "../stacked/off-market-teaser-card";
import api from "../../../api";

export default {
  components: { InfiniteLoading, OffMarketCard },
  data() {
    return {
      cards: {
        data: [],
        pagy: null
      },
      helpRequested: false,
      busy: false,
      endOfFeed: false,
      loaded: false
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    ...mapGetters(["isAdmin"]),
    pagination() {
      return this.cards.pagy;
    }
  },
  mounted() {
    this.fetchCards();
  },
  methods: {
    createCard() {
      this.$emit("create-card");
    },
    editCard(token) {
      this.$emit("edit-card", token);
    },
    requestHelp() {
      api.post(`off_market_property_snapshot_help_requests`).then(json => {
        this.helpRequested = true;
      });
    },
    fetchCards() {
      api.get(`off_market_property_snapshots`).then(json => {
        this.cards = json.data;
        this.loaded = true;
      });
    },
    extendCards({ data, pagy }) {
      this.cards.data = this.cards.data.concat(data);
      this.cards.pagy = pagy;
    },
    incrementCards($state) {
      if (this.pagination) {
        const { count, to } = this.pagination;

        if (count === 0) {
          this.endOfFeed = true;
        } else if (to < count) {
          const cleanUrl = _.drop(this.pagination.next_url, 8).join("");

          this.busy = true;

          api.get(cleanUrl).then(json => {
            this.extendCards(json.data);
            $state.loaded();
            this.busy = false;
          });
        } else {
          this.endOfFeed = true;
          $state.complete();
        }
      }
    }
  }
};
</script>
