<template>
  <div>
    <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">{{ users.length }} Users</h2>
    <ul class="mt-3 space-y-2">
      <li v-for="user in users" :key="user.token" class="block sm:flex sm:items-center sm:space-x-2">
        <a href="" class="font-medium text-gray-500 underline" @click.prevent="masquerade(user.id)">{{ user.name }}</a>
        <div class="text-xs text-gray-500">
          &nbsp;({{ user.industryRole | capitalize }},&nbsp;<template v-if="user.company">{{ user.company }},&nbsp;</template>{{ user.email }},
          <template v-if="user.lastActive">{{ user.lastActive | moment("from") }}</template>
          <template v-else>inactive</template>)&nbsp;&mdash;&nbsp;{{ user.token }}
        </div>
        <platform-permissions :user="user" @edited="patchUsers" />
      </li>
    </ul>

    <h2 class="mt-6 text-gray-500 text-xs font-medium uppercase tracking-wide">Intel with no metadata</h2>
    <ul class="mt-3">
      <li v-for="intel in noMetadata" :key="intel.token">
        <a @click.prevent="viewDetails(intel)" href="" class="font-medium text-gray-500 underline">{{ intel.token }}</a>
        <span class="text-xs text-gray-500">&nbsp;({{ intel.type | postTypeAlias }}, {{ intel.name }}, {{ intel.updatedAt | moment("from") }})</span>
      </li>
    </ul>
  </div>
</template>

<script>
import IntelCard from "../components/cards/market-intel.vue";
import PlatformPermissions from "../components/admin/platform-permissions.vue";
import User from "user";
import api from "api";

export default {
  components: { PlatformPermissions },
  data() {
    return {
      users: [],
      noMetadata: []
    };
  },
  created() {
    api.get("admin/users").then(result => {
      this.users = result.data;
    });

    this.fetchIntel();
  },
  methods: {
    fetchIntel() {
      api.get("admin/no_metadata_intel").then(result => {
        this.noMetadata = result.data;
      });
    },
    masquerade(id) {
      api.get(`admin/masquerades/${id}`).then(result => {
        const token = result.data.id;

        localStorage.setItem("adminToken", localStorage.getItem("token"));
        this.$store.commit("setAdminMode");

        localStorage.setItem("token", token);
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        api.get("whoami?admin=true").then(result => {
          this.$store.dispatch("signIn", new User(result.data));
          this.$router.push({ path: "/" });
        });
      });
    },
    patchUsers(updatedUser) {
      const newUsers = this.users.map(user => {
        if (user.id === updatedUser.id) {
          return updatedUser;
        } else {
          return user;
        }
      });

      this.users = newUsers;
    },
    viewDetails(intel) {
      this.$store.commit("openModal", {
        component: IntelCard,
        props: { postObject: intel }
      });

      this.fetchIntel();
    }
  }
};
</script>
