<template>
  <div id="map">
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import 'leaflet/dist/leaflet.css';
import leaflet from "leaflet";
import gridLayer from "leaflet.gridlayer.googlemutant";
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

export default {
  props: ["mapData"],
  data() {
    return {
      featureGroup: L.featureGroup()
    }
  },
  mounted() {
    this.$store.commit("setActiveRegionMap", L.map('map', { fullscreenControl: true }).setView(this.center, this.zoom));

    L.gridLayer.googleMutant({
      type: 'roadmap',
      styles: []
    }).addTo(this.activeRegion.map);

    if (this.circle) {
      this.circle.addTo(this.activeRegion.map);
    } else if (this.polygon) {
      this.polygon.addTo(this.activeRegion.map);
    } else if (this.rectangle) {
      this.rectangle.addTo(this.activeRegion.map);
    } else if (!this.regionId) {
      this.$store.commit("setActiveRegionCircle", this.circleLayer.addTo(this.activeRegion.map));
    }

    if (this.regionId) {
      switch (this.activeRegionShape) {
        case "circle":
          this.$store.commit("setActiveRegionCircle", L.circle(this.activeRegion.center, {
            color: '#2F80ED',
            fillColor: '#2F80ED',
            fillOpacity: 0.3,
            radius: this.activeRegion.radius
          }).addTo(this.activeRegion.map));
          this.fitGeometry();
          break;
        case "rectangle":
          this.$store.commit("setActiveRegionRectangle", L.rectangle(this.activeRegion.coordinates).addTo(this.activeRegion.map));
          this.fitGeometry();
          break;
        case "polygon":
          this.$store.commit("setActiveRegionPolygon", L.polygon(this.activeRegion.coordinates).addTo(this.activeRegion.map));
          this.fitGeometry();
          break;
        default:
          console.log("invalid shape");
      }
    }

    this.setEditingControls();

    var self = this;

    this.activeRegion.map.on("fullscreenchange", function() {
      if (self.activeRegion.map.isFullscreen()) {
        self.activeRegion.map.scrollWheelZoom.enable();
      } else {
        self.activeRegion.map.scrollWheelZoom.disable();
      }
    });

    this.activeRegion.map.pm.enableDraw('Polygon', {
      allowSelfIntersection: false
    });
    this.activeRegion.map.pm.disableDraw('Polygon');

    this.activeRegion.map.on('pm:remove', e => {
      this.removeAllShapes();
    });

    this.activeRegion.map.on('pm:globaleditmodetoggled', ({ enabled }) => {
      this.$store.commit("setActiveRegionMapEditing", enabled);
    });

    this.activeRegion.map.on('pm:globaldragmodetoggled', ({ enabled }) => {
      this.$store.commit("setActiveRegionMovingShape", enabled);
    });

    this.activeRegion.map.on('pm:drawstart', ({ shape, workingLayer }) => {
      this.$store.commit("setActiveRegionDrawingShape", shape);

      workingLayer.on('pm:vertexadded', ({ shape, workingLayer }) => {
        if (shape === "Polygon" && workingLayer._latlngs.length >= 3) {
          this.$store.commit("setActiveRegionDrawingShape", `PolygonCompletable`);
        } else {
          this.$store.commit("setActiveRegionDrawingShape", `VertexAdded${shape}`);
        }
      });

      workingLayer.on('pm:centerplaced', e => {
        this.$store.commit("setActiveRegionDrawingShape", "CenterPlaced");
      });
    });

    this.activeRegion.map.on('pm:create', ({ shape, layer }) => {
      this.$store.commit("clearActiveRegionDrawingShape");
      layer.pm.enable({
        allowSelfIntersection: false,
      });
      layer.pm.disable();

      switch (shape) {
        case "Polygon":
          this.$store.commit("setActiveRegionPolygon", layer);
          break;
        case "Rectangle":
          this.$store.commit("setActiveRegionRectangle", layer);
          break;
        case "Circle":
          this.$store.commit("setActiveRegionCircle", layer);
          break;
      }
    });
  },
  computed: {
    ...mapState(["activeRegion"]),
    ...mapGetters(["hasNewUserRegion", "activeRegionShape", "activeRegionGeometry"]),
    regionId() {
      return this.$store.getters.regionIdParam || this.activeRegion.id;
    },
    circle() {
      return this.activeRegion.circle;
    },
    polygon() {
      return this.activeRegion.polygon;
    },
    rectangle() {
      return this.activeRegion.rectangle;
    },
    canDraw() {
      const { circle, polygon, rectangle } = this;

      return circle === null && polygon === null && rectangle === null;
    },
    circleLayer() {
      const layer = L.circle(this.center, {
        color: '#2F80ED',
        fillColor: '#2F80ED',
        fillOpacity: 0.3,
        radius: this.meters
      });

      return layer;
    },
    meters() {
      if (this.locationIs("neighborhood")) {
        return 1500;
      } else if (this.locationIs("locality", "sublocality_level_1")) {
        return 25000;
      } else if (this.locationIs("administrative_area_level_1", "colloquial_area")) {
        return 200000;
      } else {
        return 1000000;
      }
    },
    zoom() {
      if (this.regionId) {
        return 13;
      } else if (this.locationIs("neighborhood")) {
        return 13;
      } else if (this.locationIs("locality", "sublocality_level_1")) {
        return 9;
      } else if (this.locationIs("administrative_area_level_1", "colloquial_area")) {
        return 5;
      } else {
        return 4;
      }
    },
    center() {
      if (this.regionId) {
        return [51.505, -0.09];
      } else {
        return [this.mapData.lat, this.mapData.lng];
      }
    },
    locationScaleTypes() {
      if (this.mapData.addressData) {
        return this.mapData.addressData.address_components.map(comp => comp.types[0]);
      } else {
        return [];
      }
    }
  },
  methods: {
    locationIs(...types) {
      return _.intersection(this.locationScaleTypes, [...types]).length > 0
    },
    setEditingControls() {
      this.activeRegion.map.pm.addControls({
        position: 'topleft',
        drawMarker: false,
        drawCircleMarker: false,
        drawPolyline: false,
        drawRectangle: false,
        drawPolygon: false,
        drawCircle: false,
        editMode: true,
        dragMode: true,
        cutPolygon: false,
        removalMode: true
      });
    },
    setDrawingControls() {
      this.activeRegion.map.pm.addControls({
        position: 'topleft',
        drawMarker: false,
        drawCircleMarker: false,
        drawPolyline: false,
        drawRectangle: true,
        drawPolygon: true,
        drawCircle: true,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: false
      });
    },
    fitGeometry() {
      this.featureGroup.addLayer(this.activeRegionGeometry);
      this.activeRegion.map.fitBounds(this.featureGroup.getBounds());
      this.$store.commit("setActiveRegionNamingShape", true);
    },
    removeAllShapes() {
      this.$store.dispatch("clearActiveRegionShapes");
    },
    setDefaultCircle() {
      this.$store.commit("setActiveRegionCircle", this.circleLayer.addTo(this.activeRegion.map));
    }
  },
  watch: {
    center: {
      handler() {
        this.activeRegion.map.setView(this.center, this.zoom);
        this.activeRegion.map.removeLayer(this.circle);
        this.$store.commit("clearActiveRegionCircle");
        this.setDefaultCircle();
      }
    },
    canDraw: {
      handler() {
        if (this.canDraw) {
          this.setDrawingControls();
        } else {
          this.setEditingControls();
        }
      }
    }
  }
}
</script>
