<template>
  <div>
    <search-result-deal-row
      v-if="post.newsfeedType === 'deal'"
      :key="post.token"
      :deal="post"
      :class="{ 'border-t': index !== 0, 'border-gray-200': index !== 0 }"
    />
    <search-result-intel-row
      v-else-if="post.newsfeedType === 'intel'"
      :key="post.token"
      :post="post"
      :class="{ 'border-t': index !== 0, 'border-gray-200': index !== 0 }"
    />
  </div>
</template>

<script>
import SearchResultDealRow from "./search-result-deal-row";
import SearchResultIntelRow from "./intel-row";

export default {
  components: { SearchResultDealRow, SearchResultIntelRow },
  props: ["post", "index"]
};
</script>
