import StrategicMapContext from "./components/maps/strategic-map-context";
import Vue from "vue";
/* global L */

const Context = Vue.extend(StrategicMapContext);

export default function strategicMapContext({ router, store, featureGroup }) {
  L.Control.StrategicMapContext = L.Control.extend({
    options: {
      position: "bottomleft"
    },

    onAdd: function(map) {
      const contextVueEl = new Context({
        store,
        router,
        propsData: {
          map,
          featureGroup
        }
      }).$mount().$el;

      return contextVueEl;
    }
  });

  L.control.strategicMapContext = function(options) {
    return new L.Control.StrategicMapContext(options);
  };
}
