<template>
  <div class="space-y-4">
    <fieldset>
      <div class="flex justify-between items-center">
        <legend class="text-base font-medium text-gray-900">Source</legend>
        <span class="relative z-0 inline-flex shadow-sm rounded-md">
          <button @click="selectAll('sources', filters.sources)" type="button" class="relative inline-flex items-center p-1.5 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500">
            <span>All</span>
          </button>
          <button @click="selectNone('sources')" type="button" class="-ml-px relative inline-flex items-center p-1.5 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500">
            <span>None</span>
          </button>
        </span>
      </div>
      <div class="mt-4 space-y-4">
        <div v-for="option in filters.sources" :key="option.order" class="flex items-start">
          <div class="h-5 flex items-center">
            <input v-model="sources" :value="option" :id="option.name" :name="option.name" type="checkbox" class="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded">
          </div>
          <div class="ml-3 text-sm">
            <label :for="option.name" class="font-medium text-gray-700">{{ option.name }}</label>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset>
      <div class="flex justify-between items-center">
        <legend class="text-base font-medium text-gray-900">Region</legend>
        <span class="relative z-0 inline-flex shadow-sm rounded-md">
          <button @click="selectAll('regions', filters.regions)" type="button" class="relative inline-flex items-center p-1.5 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500">
            <span>All</span>
          </button>
          <button @click="selectNone('regions')" type="button" class="-ml-px relative inline-flex items-center p-1.5 border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500">
            <span>None</span>
          </button>
          <button @click="newRegion" type="button" class="-ml-px relative inline-flex items-center p-1.5 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
            <span>Create</span>
          </button>
        </span>
      </div>
      <div class="mt-4 space-y-4">
        <div v-if="filters.regions.length === 0" class="text-sm flex items-center">
          <label class="font-medium text-gray-700">Zoom in or create a region</label>
        </div>
        <div v-else v-for="region in filters.regions" :key="region.id" class="flex items-start">
          <div class="h-5 flex items-center">
            <input v-model="regions" :value="region" :id="region.id" :name="region.name" type="checkbox" class="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded">
          </div>
          <div class="ml-3 text-sm flex items-center">
            <label :for="region.id" class="font-medium text-gray-700">{{ region.name }}</label>
            <a v-if="isAdmin" @click.prevent="viewRegion(region)" href="" v-tooltip="'Edit'" class="ml-2 text-xs leading-5 text-indigo-600 hover:text-indigo-500">
              <svg class="h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </a>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset>
      <div class="flex justify-between items-center">
        <legend class="text-base font-medium text-gray-900">Deal Type</legend>
        <span class="relative z-0 inline-flex shadow-sm rounded-md">
          <button @click="selectAll('deals', filters.deals)" type="button" class="relative inline-flex items-center p-1.5 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500">
            <span>All</span>
          </button>
          <button @click="selectNone('deals')" type="button" class="-ml-px relative inline-flex items-center p-1.5 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500">
            <span>None</span>
          </button>
        </span>
      </div>
      <div class="mt-4 space-y-4">
        <div v-for="option in filters.deals" :key="option.order" class="flex items-start">
          <div class="h-5 flex items-center">
            <input v-model="deals" :value="option" :id="option.name" :name="option.name" type="checkbox" class="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded">
          </div>
          <div class="ml-3 text-sm">
            <label :for="option.name" class="font-medium text-gray-700">{{ option.name }}</label>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset v-if="loanSalesSelected">
      <div class="flex justify-between items-center">
        <legend class="text-base font-medium text-gray-900">Loan Seniority</legend>
        <span class="relative z-0 inline-flex shadow-sm rounded-md">
          <button @click="selectAll('loans', filters.loans)" type="button" class="relative inline-flex items-center p-1.5 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500">
            <span>All</span>
          </button>
          <button @click="selectNone('loans')" type="button" class="-ml-px relative inline-flex items-center p-1.5 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500">
            <span>None</span>
          </button>
        </span>
      </div>
      <div class="mt-4 space-y-4">
        <div v-for="option in filters.loans" :key="option.order" class="flex items-start">
          <div class="h-5 flex items-center">
            <input v-model="loans" :value="option" :id="option.name" :name="option.name" type="checkbox" class="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded">
          </div>
          <div class="ml-3 text-sm">
            <label :for="option.name" class="font-medium text-gray-700">{{ option.name }}</label>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset>
      <div class="flex justify-between items-center">
        <legend class="text-base font-medium text-gray-900">Strategy</legend>
        <span class="relative z-0 inline-flex shadow-sm rounded-md">
          <button @click="selectAll('risks', filters.risks)" type="button" class="relative inline-flex items-center p-1.5 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500">
            <span>All</span>
          </button>
          <button @click="selectNone('risks')" type="button" class="-ml-px relative inline-flex items-center p-1.5 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500">
            <span>None</span>
          </button>
        </span>
      </div>
      <div class="mt-4 space-y-4">
        <div v-for="option in filters.risks" :key="option.order" class="flex items-start">
          <div class="h-5 flex items-center">
            <input v-model="risks" :value="option" :id="option.name" :name="option.name" type="checkbox" class="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded">
          </div>
          <div class="ml-3 text-sm">
            <label :for="option.name" class="font-medium text-gray-700">{{ option.name }}</label>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset>
      <div class="flex justify-between items-center">
        <legend class="text-base font-medium text-gray-900">Property Use</legend>
        <span class="relative z-0 inline-flex shadow-sm rounded-md">
          <button @click="selectAll('uses', filters.uses)" type="button" class="relative inline-flex items-center p-1.5 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500">
            <span>All</span>
          </button>
          <button @click="selectNone('uses')" type="button" class="-ml-px relative inline-flex items-center p-1.5 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500">
            <span>None</span>
          </button>
        </span>
      </div>
      <div class="mt-4 space-y-4">
        <div v-for="option in filters.uses" :key="option.order" class="flex items-start">
          <div class="h-5 flex items-center">
            <input v-model="uses" :value="option" :id="option.name" :name="option.name" type="checkbox" class="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded">
          </div>
          <div class="ml-3 text-sm">
            <label :for="option.name" class="font-medium text-gray-700">{{ option.name }}</label>
          </div>
        </div>
      </div>
    </fieldset>
    <date-filter v-model="dates" @clear="clearDates" filter-type="map" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DateFilter from "../slideover-filter/date-filter";
import Region from "../../views/onboarding/onboarding-region";
import RegionComponent from "../../views/onboarding/onboarding-region";
import dealTypes from "../../deal-input-deal-types";
import loanTypes from "../../loan-types";
import riskTypes from "../../risk-types";
import useTypes from "../../use-types";

export default {
  components: { DateFilter },
  data() {
    return {
      sources: [],
      regions: [],
      deals: [],
      loans: [],
      risks: [],
      uses: [],
      dates: {
        allDates: true,
        start: new Date(),
        end: new Date()
      }
    };
  },
  computed: {
    ...mapState([
      "currentUser",
      "persistMapFilters",
      "mapFilters",
      "mapNearbyRegions"
    ]),
    ...mapGetters(["hasMapFilters", "isAdmin"]),
    industryRole() {
      return this.currentUser.industryRole;
    },
    matchingDealTypes() {
      return _.get(dealTypes, this.industryRole, []);
    },
    filters() {
      return {
        sources: [
          {
            name: "Watchlist",
            value: "watchlist"
          },
          {
            name: "Safezone",
            value: "safezone"
          },
          {
            name: "Private channel",
            value: "backchannel"
          },
          {
            name: "Crowdsourced",
            value: "public"
          }
        ],
        regions: _.unionBy(
          this.mapNearbyRegions,
          this.mapFilters.regions,
          "id"
        ),
        deals: this.matchingDealTypes,
        loans: loanTypes,
        risks: riskTypes,
        uses: useTypes
      };
    },
    loanSalesSelected() {
      return !!_.find(this.deals, function(d) {
        return _.includes(["Buy Debt"], d.value);
      });
    },
    capitalMarketsSelected() {
      return !!_.find(this.deals, function(d) {
        return _.includes(
          ["Buy Equity", "Originate Debt", "Recap Equity", "Buy Debt"],
          d.value
        );
      });
    }
  },
  watch: {
    hasMapFilters: {
      handler(val, oldVal) {
        if (oldVal && !val) {
          this.clearEverything();
          this.persist();
        }
      }
    },
    persistMapFilters: {
      handler() {
        if (this.persistMapFilters) {
          this.persist();
          this.$store.commit("shouldPersistMapFilters", false);
          this.$store.commit("closeSlideover");
        }
      }
    },
    loanSalesSelected: {
      handler() {
        if (!this.loanSalesSelected) {
          this.selectNone("loans");
        }
      }
    }
  },
  mounted() {
    if (this.hasMapFilters) {
      this.mountFilters();
    }
  },
  methods: {
    mountFilters() {
      var self = this;

      _.forEach(this.mapFilters, function(value, key) {
        self[key] = value;
      });
    },
    persist() {
      const payload = {
        sources: this.sources,
        regions: this.regions,
        deals: this.deals,
        loans: this.loans,
        risks: this.risks,
        uses: this.uses,
        dates: this.dates
      };

      this.$store.commit("setMapFilters", payload);
    },
    selectEverything() {
      ["sources", "regions", "deals", "loans", "risks", "uses"].forEach(key => {
        this.selectAll(key, this.filters[key]);
      });
    },
    clearEverything() {
      ["sources", "regions", "deals", "loans", "risks", "uses"].forEach(key => {
        this.selectNone(key);
      });
      this.clearDates();
    },
    selectAll(key, options) {
      this[key] = options;
    },
    selectNone(key) {
      this[key] = [];
    },
    clearDates() {
      this.dates = {
        allDates: true,
        start: new Date(),
        end: new Date()
      };
    },
    newRegion() {
      this.$store.commit("closeSlideover");
      this.$store.commit("openModal", {
        component: Region,
        props: {
          regionId: null
        }
      });
    },
    viewRegion(region) {
      setTimeout(() => {
        this.$store.commit("closeSlideover");
        this.$store.commit("openModal", {
          component: RegionComponent,
          props: {
            regionId: region.id
          }
        });
      }, 200);
    }
  }
};
</script>
