<template>
  <div>
    <div class="pb-6 px-6 sm:pb-10 sm:px-10">
      <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
        <svg class="h-6 w-6 text-green-600" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"/>
        </svg>
      </div>
      <div class="mt-3 text-center sm:mt-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Welcome to your newsfeed
        </h3>
        <div class="mt-2">
          <p class="text-sm leading-5 text-gray-500">
            Come here for a unified, personalized stream of market intel and deal flow
          </p>
        </div>
      </div>
    </div>
    <div class="border-t-2 border-gray-100 rounded-b-lg pt-6 sm:pt-10 pb-4 sm:pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
      <ul>
        <li class="flex items-start">
          <div class="flex-shrink-0">
            <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
          </div>
          <p class="ml-3 text-base leading-6 font-medium text-gray-500">
            Information is aggregated from your markets and any markets, contacts, and deals that you follow.
          </p>
        </li>
        <li class="mt-4 flex items-start">
          <div class="flex-shrink-0">
            <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
          </div>
          <p class="ml-3 text-base leading-6 font-medium text-gray-500">
            You can tailor your intel and deal notifications from the dropdowns on market and contact pages.
          </p>
        </li>
      </ul>
      <div class="mt-6 sm:mt-10">
        <span class="flex w-full rounded-md shadow-sm">
          <button @click="dismiss" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo sm:text-sm sm:leading-5">
            Got it
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    dismiss() {
      this.$store.dispatch("dismissNewsfeedWelcome");
      this.$store.commit("closeModal");
    }
  }
};
</script>
