<template>
  <div class="wrapper">
    <heading>Hey! I'm Matt. I'll help you set up a profile in seconds. Ready to go?</heading>
    <form @submit.prevent="next" autocomplete="off" novalidate>
      <div class="input-container" :class="{ 'mb-8': needsHelp }">
        <input
          v-focus
          v-model="name"
          @focus="focus"
          @blur="blur"
          type="text"
          autocomplete="new-password"
          autocorrect="off"
          spellcheck="false"
          placeholder=""
          tabindex="1"
          name="user_first_name"
          class="input-text black"
          :class="{ active: focused }"
        >
        <label class="input-label" :class="{ active: focused }">Your name</label>
      </div>
      <option-description v-if="needsHelp" description="People want to know at least your first and last name." />
      <button type="submit" class="cta" :class="{ complete: complete }" tabindex="999">Let's do this</button>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Heading from "../../components/onboarding/heading";
import OptionDescription from "../../components/onboarding/option-description";
import api from "../../api";

export default {
  components: { Heading, OptionDescription },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    ...mapState(["route"]),
    marketToken() {
      return _.get(this.route, "query.market", null);
    },
    name: {
      get() {
        return this.$store.state.newUser.name;
      },
      set(newName) {
        this.$store.commit("setNewUserName", newName);
      }
    },
    complete() {
      const stripped = _.trim(this.name, "1234567890 ");

      return _.words(stripped).length > 1;
    },
    needsHelp() {
      return this.name !== "" && !this.complete;
    }
  },
  mounted() {
    this.$store.commit("activateOnboarding");

    if (this.marketToken) {
      api.get(`open/${this.marketToken}`).then(
        json => {
          this.$store.commit("mountTarget", { target: json.data });
          this.$store.commit("setTargetName", null);
        },
        failure => {
          this.$store.dispatch("flash", "Market could not be loaded");
        }
      );
    }
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    next() {
      if (this.complete) {
        this.$router.push({ path: "/start/2" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
input {
  vertical-align: middle;
}
form {
  width: 330px;
}
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 26px 15px 8px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.active {
    border: 2px solid #5850ec;
  }
}
.input-label {
  position: absolute;
  top: 8px;
  left: 16px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
