<template>
  <div>
    <!-- Page title & actions -->
    <div class="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
      <div class="flex-1 flex items-center min-w-0">
        <h1 class="pr-2 text-lg font-medium leading-6 text-gray-900 sm:truncate">
          Property Watchlist
        </h1>
        <span class="inline-block py-0.5 px-2 text-xs leading-4 rounded-full text-white bg-cyan-400">
          Beta
        </span>
      </div>
      <div v-if="adding" class="mt-4 flex sm:mt-0 sm:ml-4 flex rounded-md shadow-sm">
        <div class="relative flex-grow focus-within:z-10">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <!-- Heroicon name: search -->
            <svg class="h-5 w-5 text-gray-400" style="z-index: 1;" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
            </svg>
          </div>
          <autocomplete
            id="property-search"
            ref="propertySearch"
            type="text"
            base-class="custom-autocomplete-leading-icon"
            :search="searchProperties"
            :auto-select="true"
            :get-result-value="getPropertyResultValue"
            :debounce-time="500"
            @submit="handlePropertySubmit"
            placeholder="Name, address, etc."
          />
        </div>
        <button @click="createProperty" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
          <!-- Heroicon name: plus -->
          <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
          </svg>
          <span class="ml-2">New</span>
        </button>
      </div>
      <div v-else class="mt-4 flex sm:mt-0 sm:ml-4">
        <span class="order-0 sm:order-1 sm:ml-3 shadow-sm rounded-md">
          <button @click="addProperty" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
            Add
          </button>
        </span>
      </div>
    </div>
    <div v-if="false" class="px-4 mt-6 sm:px-6 lg:px-8">
      <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Suggested Properties</h2>
    </div>
    <watchlist-table />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Property from "../onboarding/deal/property";
import WatchlistTable from "../../components/property-watchlist/watchlist-table.vue";
import api from "../../api";

export default {
  components: { WatchlistTable },
  data() {
    return {
      adding: false
    };
  },
  computed: {
    ...mapState(["deduplicateRecord", "route", "modal"])
  },
  watch: {
    modal: {
      handler(newModal, oldModal) {
        if (oldModal && !newModal) {
          // proximity modal fully closed
          this.$store.commit("resetWatchlistProximity");
        } else if (!oldModal && newModal) {
          // new proximity modal opened
          this.$store.commit("mountWatchlistProximity");
        }
      },
      deep: true
    },
    deduplicateRecord: {
      handler() {
        const modalAfterClose = _.get(this.modal, "afterClose", null);

        if (this.deduplicateRecord && !modalAfterClose) {
          this.addPropertyToWatchlist(this.deduplicateRecord);
        }
      },
      deep: true
    }
  },
  mounted() {
    if (_.get(this.route, "query.create", false)) {
      this.addProperty();
      this.$router.replace({ query: null });
    }
  },
  methods: {
    addProperty() {
      this.adding = true;

      setTimeout(() => {
        document.getElementById("property-search").focus();
      }, 50);
    },
    createProperty() {
      this.$store.commit("openModal", {
        component: Property,
        props: {
          propertyId: null
        }
      });
    },
    searchProperties(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        this.$store.dispatch("executePropertySearch", input).then(
          json => {
            resolve(json.data);
          },
          failure => {
            this.$store.dispatch("flash", "Invalid search");
          }
        );
      });
    },
    getPropertyResultValue(result) {
      return `${result.name} (${result.cityState})`;
    },
    handlePropertySubmit(result) {
      if (result) {
        this.addPropertyToWatchlist(result);
        this.$refs.propertySearch.value = "";
        this.adding = false;
      } else {
        this.$store.dispatch(
          "flash",
          "No matching properties in Tower Hunt. Why don't you create one?"
        );
      }
    },
    addPropertyToWatchlist(property) {
      this.$store
        .dispatch("addPropertyToWatchlist", property)
        .then(following => {
          this.adding = false;
          this.$router.push({ path: `/property-watchlist/${following.id}/` });
        });
    }
  }
};
</script>
