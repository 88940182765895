import { render, staticRenderFns } from "./multi-content-deduplicate-wrapper.vue?vue&type=template&id=b4b74b12&"
import script from "./multi-content-deduplicate-wrapper.vue?vue&type=script&lang=js&"
export * from "./multi-content-deduplicate-wrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports