<template>
  <div></div>
</template>

<script>
export default {
  props: ["requestToken"],
  computed: {
    path() {
      return this.$store.state.route.path;
    },
    accepting() {
      return _.includes(this.path, "accept");
    },
    declining() {
      return _.includes(this.path, "decline");
    }
  },
  mounted() {
    if (this.accepting) {
      this.$store.dispatch("acceptDealInvitationRequest", this.requestToken);
    } else if (this.declining) {
      this.$store.dispatch("declineDealInvitationRequest", this.requestToken);
    }

    this.$router.push({ path: "/home" });
  }
};
</script>
