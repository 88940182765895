<template>
  <div>
    <div id="stacked-cards-block" class="stackedcards stackedcards--animatable">
      <div class="stackedcards-container">
        <off-market-card v-for="card in cards" :key="card.content.token" :auto-card-size="false" :card="card.content" />
      </div>
      <!-- <div class="stackedcards--animatable stackedcards-overlay top bg-blue-400">
        <svg class="h-20 w-20 text-white" fill="currentColor" viewBox="0 0 20 20">
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
        </svg>
      </div> -->
      <div class="stackedcards--animatable stackedcards-overlay right bg-green-300">
        <svg class="h-20 w-20 text-white" fill="currentColor" viewBox="0 0 20 20">
          <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
        </svg>
      </div>
      <div class="stackedcards--animatable stackedcards-overlay left bg-red-500">
        <svg class="h-20 w-20 text-white" fill="currentColor" viewBox="0 0 20 20">
          <path d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z" />
        </svg>
      </div>
    </div>
    <div class="mt-10 flex justify-center items-center">
      <button type="button" v-tooltip="'Not interested'" class="flex-shrink-0 inline-flex items-center justify-center h-16 w-16 rounded-full bg-red-500" @click="onActionLeft">
        <svg class="h-8 w-8 text-white" fill="currentColor" viewBox="0 0 20 20">
          <path d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z" />
        </svg>
      </button>
      <!-- <button type="button" v-tooltip="'Love it!'" class="ml-4 flex-shrink-0 inline-flex items-center justify-center h-12 w-12 rounded-full bg-blue-400" @click="onActionTop">
        <svg class="h-6 w-6 text-white" fill="currentColor" viewBox="0 0 20 20">
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
        </svg>
      </button> -->
      <button type="button" v-tooltip="'Interested'" class="ml-4 flex-shrink-0 inline-flex items-center justify-center h-16 w-16 rounded-full bg-green-300" @click="onActionRight">
        <svg class="h-8 w-8 text-white" fill="currentColor" viewBox="0 0 20 20">
          <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapState } from "vuex";
import OffMarketCard from "./off-market-teaser-card";
import api from "../../../api";

export default {
  components: { OffMarketCard },
  props: ["cards", "packId"],
  data() {
    return {
      cardsCompleted: 0,
      cardStartTime: null,
      stackedOptions: "Top", //Change stacked cards view from 'Bottom', 'Top' or 'None'.
      rotate: true, // Activate the elements' rotation for each move on stacked cards.
      items: 3, // Number of visible elements when the stacked options are bottom or top.
      elementsMargin: 10, // Define the distance of each element when the stacked options are bottom or top.
      useOverlays: true, // Enable or disable the overlays for swipe elements.
      maxElements: null, // Total of stacked cards on DOM.
      currentPosition: 0, // Keep the position of active stacked card.
      velocity: 0.3, // Minimum velocity allowed to trigger a swipe.
      topObj: null, // Keep the swipe top properties.
      rightObj: null, // Keep the swipe right properties.
      leftObj: null, // Keep the swipe left properties.
      listElNodesObj: null, // Keep the list of nodes from stacked cards.
      listElNodesWidth: 330, // Keep the stacked cards width.
      currentElementObj: null, // Keep the stacked card element to swipe.
      stackedCardsObj: null,
      isFirstTime: true,
      elementHeight: null,
      obj: null,
      elTrans: null,
      element: null,
      startTime: null,
      startX: null,
      startY: null,
      translateX: null,
      translateY: null,
      currentX: null,
      currentY: null,
      touchingElement: false,
      timeTaken: null,
      topOpacity: null,
      rightOpacity: null,
      leftOpacity: null
    };
  },
  computed: {
    ...mapState(["adminMode"]),
    currentCard() {
      if (this.cards) {
        return this.cards[this.currentPosition];
      } else {
        return null;
      }
    }
  },
  mounted() {
    this.setupStackedCards();
    this.cardStartTime = moment();

    document.addEventListener("DOMContentLoaded", function(event) {
      console.log("loaded");
    });
  },
  methods: {
    setupStackedCards() {
      this.obj = document.getElementById("stacked-cards-block");
      this.stackedCardsObj = this.obj.querySelector(".stackedcards-container");
      this.listElNodesObj = this.stackedCardsObj.children;

      // this.topObj = this.obj.querySelector(".stackedcards-overlay.top");
      this.rightObj = this.obj.querySelector(".stackedcards-overlay.right");
      this.leftObj = this.obj.querySelector(".stackedcards-overlay.left");

      this.countElements();
      this.currentElement();
      // this.listElNodesWidth = this.stackedCardsObj.offsetWidth; hardwire to 330px;
      this.currentElementObj = this.listElNodesObj[0];
      this.updateUi();

      this.addMargins();
      this.positionCards();
      this.setActiveCard();
      this.setOverlays();

      //Touch events block
      this.element = this.obj;

      this.element.addEventListener("touchstart", this.gestureStart, false);
      this.element.addEventListener("touchmove", this.gestureMove, false);
      this.element.addEventListener("touchend", this.gestureEnd, false);
    },
    setOverlays() {
      if (this.useOverlays) {
        this.leftObj.style.transform =
          "translateX(0px) translateY(" +
          this.elTrans +
          "px) translateZ(0px) rotate(0deg)";
        this.leftObj.style.webkitTransform =
          "translateX(0px) translateY(" +
          this.elTrans +
          "px) translateZ(0px) rotate(0deg)";

        this.rightObj.style.transform =
          "translateX(0px) translateY(" +
          this.elTrans +
          "px) translateZ(0px) rotate(0deg)";
        this.rightObj.style.webkitTransform =
          "translateX(0px) translateY(" +
          this.elTrans +
          "px) translateZ(0px) rotate(0deg)";

        // this.topObj.style.transform =
        //   "translateX(0px) translateY(" +
        //   this.elTrans +
        //   "px) translateZ(0px) rotate(0deg)";
        // this.topObj.style.webkitTransform =
        //   "translateX(0px) translateY(" +
        //   this.elTrans +
        //   "px) translateZ(0px) rotate(0deg)";
      } else {
        this.leftObj.className = "";
        this.rightObj.className = "";
        // this.topObj.className = "";

        this.leftObj.classList.add("stackedcards-overlay-hidden");
        this.rightObj.classList.add("stackedcards-overlay-hidden");
        // this.topObj.classList.add("stackedcards-overlay-hidden");
      }
    },
    setActiveCard() {
      if (this.listElNodesObj[this.currentPosition]) {
        this.listElNodesObj[this.currentPosition].classList.add("stackedcards-active");
      }
    },
    positionCards() {
      for (let i = this.items; i < this.maxElements; i++) {
        this.listElNodesObj[i].style.zIndex = 0;
        this.listElNodesObj[i].style.opacity = 0;
        this.listElNodesObj[i].style.webkitTransform =
          "scale(" +
          (1 - this.items * 0.04) +
          ") translateX(0) translateY(" +
          this.elTrans +
          "px) translateZ(0)";
        this.listElNodesObj[i].style.transform =
          "scale(" +
          (1 - this.items * 0.04) +
          ") translateX(0) translateY(" +
          this.elTrans +
          "px) translateZ(0)";
      }
    },
    addMargins() {
      let addMargin = this.elementsMargin * (this.items - 1) + "px";

      if (this.stackedOptions === "Top") {
        for (let i = this.items; i < this.maxElements; i++) {
          this.listElNodesObj[i].classList.add(
            "stackedcards-top",
            "stackedcards--animatable",
            "stackedcards-origin-top"
          );
        }

        this.elTrans = this.elementsMargin * (this.items - 1);

        this.stackedCardsObj.style.marginBottom = addMargin;
      } else if (this.stackedOptions === "Bottom") {
        for (let i = this.items; i < this.maxElements; i++) {
          this.listElNodesObj[i].classList.add(
            "stackedcards-bottom",
            "stackedcards--animatable",
            "stackedcards-origin-bottom"
          );
        }

        this.elTrans = 0;

        this.stackedCardsObj.style.marginBottom = addMargin;
      } else if (this.stackedOptions === "None") {
        for (let i = this.items; i < this.maxElements; i++) {
          this.listElNodesObj[i].classList.add(
            "stackedcards-none",
            "stackedcards--animatable"
          );
        }

        this.elTrans = 0;
      }
    },
    backToMiddle() {
      this.removeNoTransition();
      this.transformUi(0, 0, 1, this.currentElementObj);

      if (this.useOverlays) {
        this.transformUi(0, 0, 0, this.leftObj);
        this.transformUi(0, 0, 0, this.rightObj);
        // this.transformUi(0, 0, 0, this.topObj);
      }

      this.setZindex(5);

      if (!(this.currentPosition >= this.maxElements)) {
        //roll back the opacity of second element
        if (this.currentPosition + 1 < this.maxElements) {
          this.listElNodesObj[this.currentPosition + 1].style.opacity = ".8";
        }
      }
    },
    countElements() {
      this.maxElements = this.listElNodesObj.length;
      if (this.items > this.maxElements) {
        this.items = this.maxElements;
      }
    },
    currentElement() {
      this.currentElementObj = this.listElNodesObj[this.currentPosition]; //Keep the active card.
    },
    onActionLeft() {
      // Functions to swipe left elements on logic external action.
      if (!(this.currentPosition >= this.maxElements)) {
        if (this.useOverlays) {
          this.leftObj.classList.remove("no-transition");
          // this.topObj.classList.remove("no-transition");
          this.leftObj.style.zIndex = "8";
          this.transformUi(0, 0, 1, this.leftObj);
        }

        var self = this;

        setTimeout(function() {
          self.onSwipeLeft();
          self.resetOverlayLeft();
        }, 300);
      }
    },
    onActionRight() {
      // Functions to swipe right elements on logic external action.
      if (!(this.currentPosition >= this.maxElements)) {
        if (this.useOverlays) {
          this.rightObj.classList.remove("no-transition");
          // this.topObj.classList.remove("no-transition");
          this.rightObj.style.zIndex = "8";
          this.transformUi(0, 0, 1, this.rightObj);
        }

        var self = this;

        setTimeout(function() {
          self.onSwipeRight();
          self.resetOverlayRight();
        }, 300);
      }
    },
    onActionTop() {
      // Functions to swipe top elements on logic external action.
      if (!(this.currentPosition >= this.maxElements)) {
        if (this.useOverlays) {
          this.leftObj.classList.remove("no-transition");
          this.rightObj.classList.remove("no-transition");
          this.topObj.classList.remove("no-transition");
          this.topObj.style.zIndex = "8";
          this.transformUi(0, 0, 1, this.topObj);
        }

        var self = this;

        setTimeout(function() {
          self.onSwipeTop();
          self.resetOverlays();
        }, 300); //wait animations end
      }
    },
    concludeSwipe() {
      this.currentPosition = this.currentPosition + 1;
      this.cardStartTime = moment();
      this.updateUi();
      this.currentElement();
      this.setActiveHidden();
      this.cardsCompleted++;
      this.checkForCompletion();
    },
    checkForCompletion() {
      if (this.cardsCompleted === this.cards.length) {
        var self = this;

        setTimeout(function() {
          api.patch(`complete_card_pack/${self.packId}`).then(() => {
            if (!!analytics && !self.adminMode) {
              analytics.track("Card pack completed", {
                yearWeek: self.$store.state.yearWeek
              });
            }

            self.$router.push({ path: "/off-market/watchlist" });
          });
        }, 300);
      }
    },
    getCardReviewTime() {
      const reviewTime = moment().diff(this.cardStartTime, "seconds");

      return reviewTime;
    },
    onSwipeLeft() {
      // Swipe active card to left.
      this.removeNoTransition();
      this.transformUi(-1000, 0, 0, this.currentElementObj);
      if (this.useOverlays) {
        this.transformUi(-1000, 0, 0, this.leftObj); //Move leftOverlay
        // this.transformUi(-1000, 0, 0, this.topObj); //Move topOverlay
        this.resetOverlayLeft();
      }

      api.patch(`discard_pack_card/${this.packId}/${this.currentCard.id}`);

      if (!!analytics && !this.adminMode) {
        analytics.track("Card discarded", {
          yearWeek: this.$store.state.yearWeek,
          cardReviewTime: this.getCardReviewTime()
        });
      }

      this.concludeSwipe();
    },
    onSwipeRight() {
      // Swipe active card to right.
      this.removeNoTransition();
      this.transformUi(1000, 0, 0, this.currentElementObj);
      if (this.useOverlays) {
        this.transformUi(1000, 0, 0, this.rightObj); //Move rightOverlay
        // this.transformUi(1000, 0, 0, this.topObj); //Move topOverlay
        this.resetOverlayRight();
      }

      api.patch(`accept_pack_card/${this.packId}/${this.currentCard.id}`);

      if (!!analytics && !this.adminMode) {
        analytics.track("Card watchlisted", {
          yearWeek: this.$store.state.yearWeek,
          cardReviewTime: this.getCardReviewTime()
        });
      }

      this.concludeSwipe();
    },
    onSwipeTop() {
      // Swipe active card to top.
      this.removeNoTransition();
      this.transformUi(0, -1000, 0, this.currentElementObj);
      if (this.useOverlays) {
        this.transformUi(0, -1000, 0, this.leftObj); //Move leftOverlay
        this.transformUi(0, -1000, 0, this.rightObj); //Move rightOverlay
        this.transformUi(0, -1000, 0, this.topObj); //Move topOverlay
        this.resetOverlays();
      }

      api.patch(`star_pack_card/${this.packId}/${this.currentCard.id}`);

      if (!!analytics && !this.adminMode) {
        analytics.track("Card starred", {
          yearWeek: this.$store.state.yearWeek,
          cardReviewTime: this.getCardReviewTime()
        });
      }

      this.concludeSwipe();
    },
    removeNoTransition() {
      //Remove transitions from all elements to be moved in each swipe movement to improve perfomance of stacked cards.
      if (this.listElNodesObj[this.currentPosition]) {
        if (this.useOverlays) {
          this.leftObj.classList.remove("no-transition");
          this.rightObj.classList.remove("no-transition");
          // this.topObj.classList.remove("no-transition");
        }

        this.listElNodesObj[this.currentPosition].classList.remove("no-transition");
        this.listElNodesObj[this.currentPosition].style.zIndex = 6;
      }
    },
    resetOverlayLeft() {
      // Move the overlay left to initial position.
      if (!(this.currentPosition >= this.maxElements)) {
        if (this.useOverlays) {
          var self = this;

          setTimeout(function() {
            if (self.stackedOptions === "Top") {
              self.elTrans = self.elementsMargin * (self.items - 1);
            } else if (
              self.stackedOptions === "Bottom" ||
              self.stackedOptions === "None"
            ) {
              self.elTrans = 0;
            }

            if (!self.isFirstTime) {
              self.leftObj.classList.add("no-transition");
              // self.topObj.classList.add("no-transition");
            }

            requestAnimationFrame(function() {
              self.leftObj.style.transform =
                "translateX(0) translateY(" + self.elTrans + "px) translateZ(0)";
              self.leftObj.style.webkitTransform =
                "translateX(0) translateY(" + self.elTrans + "px) translateZ(0)";
              self.leftObj.style.opacity = "0";

              // self.topObj.style.transform =
              //   "translateX(0) translateY(" + self.elTrans + "px) translateZ(0)";
              // self.topObj.style.webkitTransform =
              //   "translateX(0) translateY(" + self.elTrans + "px) translateZ(0)";
              // self.topObj.style.opacity = "0";
            });
          }, 300);

          this.isFirstTime = false;
        }
      }
    },
    resetOverlayRight() {
      // Move the overlay right to initial position.
      if (!(this.currentPosition >= this.maxElements)) {
        if (this.useOverlays) {
          var self = this;

          setTimeout(function() {
            if (self.stackedOptions === "Top") {
              +2;

              self.elTrans = self.elementsMargin * (self.items - 1);
            } else if (
              self.stackedOptions === "Bottom" ||
              self.stackedOptions === "None"
            ) {
              self.elTrans = 0;
            }

            if (!self.isFirstTime) {
              self.rightObj.classList.add("no-transition");
              // self.topObj.classList.add("no-transition");
            }

            requestAnimationFrame(function() {
              self.rightObj.style.transform =
                "translateX(0) translateY(" + self.elTrans + "px) translateZ(0)";
              self.rightObj.style.webkitTransform =
                "translateX(0) translateY(" + self.elTrans + "px) translateZ(0)";
              self.rightObj.style.opacity = "0";

              // self.topObj.style.transform =
              //   "translateX(0) translateY(" + self.elTrans + "px) translateZ(0)";
              // self.topObj.style.webkitTransform =
              //   "translateX(0) translateY(" + self.elTrans + "px) translateZ(0)";
              // self.topObj.style.opacity = "0";
            });
          }, 300);

          this.isFirstTime = false;
        }
      }
    },
    resetOverlays() {
      // Move the overlays to initial position.
      if (!(this.currentPosition >= this.maxElements)) {
        if (this.useOverlays) {
          var self = this;

          setTimeout(function() {
            if (self.stackedOptions === "Top") {
              self.elTrans = self.elementsMargin * (self.items - 1);
            } else if (
              self.stackedOptions === "Bottom" ||
              self.stackedOptions === "None"
            ) {
              self.elTrans = 0;
            }

            if (!self.isFirstTime) {
              self.leftObj.classList.add("no-transition");
              self.rightObj.classList.add("no-transition");
              // self.topObj.classList.add("no-transition");
            }

            requestAnimationFrame(function() {
              self.leftObj.style.transform =
                "translateX(0) translateY(" + self.elTrans + "px) translateZ(0)";
              self.leftObj.style.webkitTransform =
                "translateX(0) translateY(" + self.elTrans + "px) translateZ(0)";
              self.leftObj.style.opacity = "0";

              self.rightObj.style.transform =
                "translateX(0) translateY(" + self.elTrans + "px) translateZ(0)";
              self.rightObj.style.webkitTransform =
                "translateX(0) translateY(" + self.elTrans + "px) translateZ(0)";
              self.rightObj.style.opacity = "0";

              // self.topObj.style.transform =
              //   "translateX(0) translateY(" + self.elTrans + "px) translateZ(0)";
              // self.topObj.style.webkitTransform =
              //   "translateX(0) translateY(" + self.elTrans + "px) translateZ(0)";
              // self.topObj.style.opacity = "0";
            });
          }, 300); // wait for animations time

          this.isFirstTime = false;
        }
      }
    },
    setActiveHidden() {
      if (!(this.currentPosition >= this.maxElements)) {
        this.listElNodesObj[this.currentPosition - 1].classList.remove(
          "stackedcards-active"
        );
        this.listElNodesObj[this.currentPosition - 1].classList.add(
          "stackedcards-hidden"
        );
        this.listElNodesObj[this.currentPosition].classList.add(
          "stackedcards-active"
        );
      }
    },
    setZindex(zIndex) {
      //Set the new z-index for specific card.
      if (this.listElNodesObj[this.currentPosition]) {
        this.listElNodesObj[this.currentPosition].style.zIndex = zIndex;
      }
    },
    removeElement() {
      // Remove element from the DOM after swipe. To use this method you need to call this function in onSwipeLeft, onSwipeRight and onSwipeTop and put the method just above the variable 'currentPosition = currentPosition + 1'.
      // On the actions onSwipeLeft, onSwipeRight and onSwipeTop you need to remove the currentPosition variable (currentPosition = currentPosition + 1) and the function setActiveHidden
      this.currentElementObj.remove();
      if (!(this.currentPosition >= this.maxElements)) {
        this.listElNodesObj[this.currentPosition].classList.add(
          "stackedcards-active"
        );
      }
    },
    transformUi(moveX, moveY, opacity, elementObj) {
      //Add translate X and Y to active card for each frame.
      var self = this;

      requestAnimationFrame(function() {
        var element = elementObj;
        let rotateElement;

        // Function to generate rotate value
        function RotateRegulator(value) {
          if (value / 10 > 15) {
            return 15;
          } else if (value / 10 < -15) {
            return -15;
          }

          return value / 10;
        }

        if (self.rotate) {
          rotateElement = RotateRegulator(moveX);
        } else {
          rotateElement = 0;
        }

        if (self.stackedOptions === "Top") {
          self.elTrans = self.elementsMargin * (self.items - 1);
          if (element) {
            element.style.webkitTransform =
              "translateX(" +
              moveX +
              "px) translateY(" +
              (moveY + self.elTrans) +
              "px) translateZ(0) rotate(" +
              rotateElement +
              "deg)";
            element.style.transform =
              "translateX(" +
              moveX +
              "px) translateY(" +
              (moveY + self.elTrans) +
              "px) translateZ(0) rotate(" +
              rotateElement +
              "deg)";
            element.style.opacity = opacity;
          }
        } else if (
          self.stackedOptions === "Bottom" ||
          self.stackedOptions === "None"
        ) {
          if (element) {
            element.style.webkitTransform =
              "translateX(" +
              moveX +
              "px) translateY(" +
              moveY +
              "px) translateZ(0) rotate(" +
              rotateElement +
              "deg)";
            element.style.transform =
              "translateX(" +
              moveX +
              "px) translateY(" +
              moveY +
              "px) translateZ(0) rotate(" +
              rotateElement +
              "deg)";
            element.style.opacity = opacity;
          }
        }
      });
    },
    updateUi() {
      //Action to update all elements on the DOM for each stacked card.
      var self = this;

      requestAnimationFrame(function() {
        self.elTrans = 0;
        var elZindex = 5;
        var elScale = 1;
        var elOpac = 1;
        var elTransTop = self.items;
        var elTransInc = self.elementsMargin;

        for (
          let i = self.currentPosition;
          i < self.currentPosition + self.items;
          i++
        ) {
          if (self.listElNodesObj[i]) {
            if (self.stackedOptions === "Top") {
              self.listElNodesObj[i].classList.add(
                "stackedcards-top",
                "stackedcards--animatable",
                "stackedcards-origin-top"
              );

              if (self.useOverlays) {
                self.leftObj.classList.add("stackedcards-origin-top");
                self.rightObj.classList.add("stackedcards-origin-top");
                // self.topObj.classList.add("stackedcards-origin-top");
              }

              self.elTrans = elTransInc * elTransTop;
              elTransTop--;
            } else if (self.stackedOptions === "Bottom") {
              self.listElNodesObj[i].classList.add(
                "stackedcards-bottom",
                "stackedcards--animatable",
                "stackedcards-origin-bottom"
              );

              if (self.useOverlays) {
                self.leftObj.classList.add("stackedcards-origin-bottom");
                self.rightObj.classList.add("stackedcards-origin-bottom");
                // self.topObj.classList.add("stackedcards-origin-bottom");
              }

              self.elTrans = self.elTrans + elTransInc;
            } else if (self.stackedOptions === "None") {
              self.listElNodesObj[i].classList.add(
                "stackedcards-none",
                "stackedcards--animatable"
              );
              self.elTrans = self.elTrans + elTransInc;
            }

            self.listElNodesObj[i].style.transform =
              "scale(" +
              elScale +
              ") translateX(0) translateY(" +
              (self.elTrans - elTransInc) +
              "px) translateZ(0)";
            self.listElNodesObj[i].style.webkitTransform =
              "scale(" +
              elScale +
              ") translateX(0) translateY(" +
              (self.elTrans - elTransInc) +
              "px) translateZ(0)";
            self.listElNodesObj[i].style.opacity = elOpac;
            self.listElNodesObj[i].style.zIndex = elZindex;

            elScale = elScale - 0.04;
            elOpac = elOpac - 1 / self.items;
            elZindex--;
          }
        }
      });
    },
    setOverlayOpacity() {
      this.topOpacity = ((this.translateY + this.elementHeight / 2) / 100) * -1;
      this.rightOpacity = this.translateX / 100;
      this.leftOpacity = (this.translateX / 100) * -1;

      if (this.topOpacity > 1) {
        this.topOpacity = 1;
      }

      if (this.rightOpacity > 1) {
        this.rightOpacity = 1;
      }

      if (this.leftOpacity > 1) {
        this.leftOpacity = 1;
      }
    },
    gestureStart(evt) {
      this.startTime = new Date().getTime();

      this.startX = evt.changedTouches[0].clientX;
      this.startY = evt.changedTouches[0].clientY;

      this.currentX = this.startX;
      this.currentY = this.startY;

      this.setOverlayOpacity();

      this.touchingElement = true;
      if (!(this.currentPosition >= this.maxElements)) {
        if (this.listElNodesObj[this.currentPosition]) {
          this.listElNodesObj[this.currentPosition].classList.add("no-transition");
          this.setZindex(6);

          if (this.useOverlays) {
            this.leftObj.classList.add("no-transition");
            this.rightObj.classList.add("no-transition");
            // this.topObj.classList.add("no-transition");
          }

          if (this.currentPosition + 1 < this.maxElements) {
            this.listElNodesObj[this.currentPosition + 1].style.opacity = "1";
          }

          this.elementHeight = this.listElNodesObj[this.currentPosition].offsetHeight / 3;
        }
      }
    },
    gestureMove(evt) {
      this.currentX = evt.changedTouches[0].pageX;
      this.currentY = evt.changedTouches[0].pageY;

      this.translateX = this.currentX - this.startX;
      this.translateY = this.currentY - this.startY;

      this.setOverlayOpacity();

      if (!(this.currentPosition >= this.maxElements)) {
        evt.preventDefault();
        this.transformUi(this.translateX, this.translateY, 1, this.currentElementObj);

        if (this.useOverlays) {
          // this.transformUi(this.translateX, this.translateY, this.topOpacity, this.topObj);

          if (this.translateX < 0) {
            this.transformUi(this.translateX, this.translateY, this.leftOpacity, this.leftObj);
            this.transformUi(0, 0, 0, this.rightObj);
          } else if (this.translateX > 0) {
            this.transformUi(this.translateX, this.translateY, this.rightOpacity, this.rightObj);
            this.transformUi(0, 0, 0, this.leftObj);
          }

          if (this.useOverlays) {
            this.leftObj.style.zIndex = 8;
            this.rightObj.style.zIndex = 8;
            // this.topObj.style.zIndex = 7;
          }
        }
      }
    },
    gestureEnd(evt) {
      if (!this.touchingElement) {
        return;
      }

      this.translateX = this.currentX - this.startX;
      this.translateY = this.currentY - this.startY;

      this.timeTaken = new Date().getTime() - this.startTime;

      this.touchingElement = false;

      if (!(this.currentPosition >= this.maxElements)) {
        if (
          this.translateY < this.elementHeight * -1 &&
          this.translateX > (this.listElNodesWidth / 2) * -1 &&
          this.translateX < this.listElNodesWidth / 2
        ) {
          //is Top?

          // if (
          //   this.translateY < this.elementHeight * -1 ||
          //   Math.abs(this.translateY) / this.timeTaken > this.velocity
          // ) {
          //   // Did It Move To Top?
          //   this.onSwipeTop();
          // } else {
          //   this.backToMiddle();
          // }
          this.backToMiddle();
        } else {
          if (this.translateX < 0) {
            if (
              this.translateX < (this.listElNodesWidth / 2) * -1 ||
              Math.abs(this.translateX) / this.timeTaken > this.velocity
            ) {
              // Did It Move To Left?
              this.onSwipeLeft();
            } else {
              this.backToMiddle();
            }
          } else if (this.translateX > 0) {
            if (
              this.translateX > this.listElNodesWidth / 2 &&
              Math.abs(this.translateX) / this.timeTaken > this.velocity
            ) {
              // Did It Move To Right?
              this.onSwipeRight();
            } else {
              this.backToMiddle();
            }
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/* Stacked Cards component css */
.no-transition {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.stackedcards-overflow {
  overflow-y: hidden !important;
}

.stackedcards {
  position: relative;
}

.stackedcards * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.stackedcards--animatable {
  -webkit-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.stackedcards .stackedcards-container > *,
.stackedcards-overlay {
  position: absolute;
  width: 100%; /* set 100% */
  height: 100%; /* set 100% */
  will-change: transform, opacity;
  top: 0;
  border-radius: 10px;
}

.stackedcards-overlay.left > div,
.stackedcards-overlay.right > div,
.stackedcards-overlay.top > div {
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.stackedcards-overlay.left,
.stackedcards-overlay.right,
.stackedcards-overlay.top {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  opacity: 0;
  top: 0;
  height: 100%;
}

.stackedcards-overlay.left:empty,
.stackedcards-overlay.right:empty,
.stackedcards-overlay.top:empty {
  display: none !important;
}

.stackedcards-overlay-hidden {
  display: none;
}

.stackedcards-origin-bottom {
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
}

.stackedcards-origin-top {
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

.stackedcards-bottom,
.stackedcards-top,
.stackedcards-none {
  height: 100%;
}

.stackedcards .stackedcards-container > :nth-child(1) {
  position: relative;
  display: block;
}
</style>
