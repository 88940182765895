<template>
  <img src="https://assets.towerhunt.com/site/avatar0.png" :style="styleObject" />
</template>

<script>
export default {
  props: ["large", "small"],
  computed: {
    isDesktop() {
      return this.$store.getters.isDesktop;
    },
    styleObject() {
      if (this.isDesktop) {
        return {
         width: `${this.large}px`,
         height: `${this.large}px`
        }
      } else {
        return {
         width: `${this.small}px`,
         height: `${this.small}px`
        }
      }
    }
  }
}
</script>
