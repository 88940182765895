<template>
  <div class="flex flex-col" :class="{ 'bg-white shadow rounded-lg': isPostContent }">
    <div v-if="isPostContent && (bounty || content.premium)" class="relative pointer-events-none" style="z-index: 9995;">
      <div class="absolute inset-x-0 top-0 transform translate-y-px">
        <div class="flex justify-center transform -translate-y-1/2">
          <span v-if="bounty" :class="compact ? 'text-xs px-2 py-1 leading-3' : 'text-sm px-4 py-1 leading-5'" class="inline-flex rounded-full bg-gradient-to-r from-yellow-300 to-yellow-500 font-semibold tracking-wider uppercase text-white">
            <template v-if="bounty.state === 'fulfilled'">Fulfilled&nbsp;</template>
            <template v-else-if="bounty.expired">Expired&nbsp;</template>
            <template v-else-if="bounty.authored">My&nbsp;</template>
            Bounty
          </span>
          <span v-else :class="compact ? 'text-xs px-2 py-1 leading-3' : 'text-sm px-4 py-1 leading-5'" class="inline-flex rounded-full bg-indigo-600 font-semibold tracking-wider uppercase text-white">
            Premium
          </span>
        </div>
      </div>
    </div>

    <div class="relative flex flex-col flex-1 rounded-lg" :class="content.newsfeedType === 'message' ? 'overflow-visible' : 'overflow-hidden'">
      <div v-if="isPostContent && (bounty || content.premium)" :class="bounty ? 'border-yellow-300' : 'border-indigo-600'" class="pointer-events-none absolute inset-0 rounded-lg border-2" style="z-index: 9994;"></div>
      <content-visibility v-if="!preview && isPostContent && !isCollectibleCard" :content="content" :content-type="content.newsfeedType" :compact="compact" />
      <market-intel-preview-card v-if="content.newsfeedType === 'intel' && displayable" :id="`intel-${content.token}`" :post="content" :property="property" :compact="compact" />
      <deal-preview-card v-else-if="content.newsfeedType === 'deal' && displayable" :id="`deal-${content.token}`" :deal="content" :property="property" :compact="compact" />
      <property-preview-card v-else-if="(content.newsfeedType === 'property_following' || content.newsfeedType === 'comparable_property') && displayable" :id="`property-${content.id}`" :property-following="content" :property="content.property" :compact="compact" />
      <comment v-else-if="content.newsfeedType === 'message'" :id="`message-${content.id}`" :comment="content" background-color="white" :thankable="false" :respondable="false" @deleted="deleted" />
      <off-market-card v-else-if="isCollectibleCard" :auto-card-size="false" :card="content.content" />
      <preview-card-social-footer v-if="!preview && isPostContent && !isCollectibleCard" :content="content" :property="property" :content-type="content.newsfeedType" :top-border="true" :compact="compact" />
      <bounty-actions v-if="!preview && bounty" :bounty="bounty" :compact="compact" />
      <comment-preview v-if="!preview && content.newsfeedType !== 'message' && content.newsfeedType !== 'property_following' && content.newsfeedType !== 'comparable_property'" :content="content" :compact="compact" :bounty="attachedBounty" :top-border="true" @has-preview-content="setFlat" @loaded="commentPreviewLoaded = true" />
    </div>
  </div>
</template>

<script>
import Comment from "./comment";
import CommentPreview from "./comment-preview";
import ContentVisibility from "./content-visibility";
import DealPreviewCard from "./deal-preview";
import MarketIntelPreviewCard from "./market-intel-preview";
import OffMarketCard from "./stacked/off-market-teaser-card";
import PreviewCardSocialFooter from "./preview-card-social-footer";
import PropertyPreviewCard from "./property-preview.vue";

export default {
  components: {
    CommentPreview,
    ContentVisibility,
    DealPreviewCard,
    MarketIntelPreviewCard,
    OffMarketCard,
    Comment,
    PreviewCardSocialFooter,
    PropertyPreviewCard
  },
  props: ["content", "attachedBounty", "preview", "compact", "property"],
  data() {
    return {
      flatButton: false,
      commentPreviewLoaded: false
    };
  },
  computed: {
    displayable() {
      return this.compact
        ? this.preview ||
            this.commentPreviewLoaded ||
            this.content.newsfeedType === "property_following" ||
            this.content.newsfeedType === "comparable_property"
        : true;
    },
    bounty() {
      return _.get(this.content, "bounty", null);
    },
    isPostContent() {
      return _.includes(
        ["deal", "intel", "property_following"],
        this.content.newsfeedType
      );
    },
    isCollectibleCard() {
      if (this.content.content) {
        return this.content.content.type === "offMarketPropertySnapshot";
      } else {
        return false;
      }
    },
    confidentialDeal() {
      if (this.content) {
        return (
          this.content.newsfeedType === "deal" && this.content.confidential
        );
      } else {
        return false;
      }
    }
  },
  beforeCreate() {
    this.$options.components.BountyActions = require("../bounties/bounty-actions.vue").default;
  },
  methods: {
    deleted(commentId) {
      this.$emit("comment-deleted", commentId);
    },
    setFlat() {
      this.flatButton = true;
    }
  }
};
</script>
