<template>
  <div class="wrapper">
    <heading>When are bids due?</heading>
    <form @submit.prevent="save(true)" novalidate>
      <v-date-picker mode="single" v-model="bidDate" color="indigo" is-inline is-required />
      <button type="submit" class="cta mt-8" :class="{ complete: true }" tabindex="999">Save</button>
      <button @click="save(false)" type="button" class="secondary my-1" tabindex="999">No bid date</button>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Heading from "../../components/onboarding/heading";

export default {
  components: { Heading },
  computed: {
    ...mapState(["activeDeal", "activeDealMarketing"]),
    ...mapGetters([]),
    bidDate: {
      get() {
        if (this.activeDealMarketing.bidDate) {
          return new Date(this.activeDealMarketing.bidDate);
        } else {
          return new Date();
        }
      },
      set(date) {
        this.$store.commit("setBidDate", date);
      }
    }
  },
  methods: {
    save(bidDate = true) {
      if (!bidDate) {
        this.$store.commit("clearBidDate");
      }

      this.$store.dispatch("updateDealMarketing", this.activeDealMarketing.id);
      this.$store.commit("closeModal");
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
button {
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
