<template>
  <li>
    <a href="" @click.prevent="viewDetails" class="flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6">
      <div class="flex items-center truncate space-x-3">
        <safezone-color-dot :content="content" />
        <p class="font-medium truncate text-sm leading-6">{{ content.name }} <span class="truncate font-normal text-gray-500">{{ content.notes }}</span></p>
      </div>
      <!-- Heroicon name: chevron-right -->
      <svg class="ml-4 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
      </svg>
    </a>
  </li>
</template>

<script>
import { mapGetters } from "vuex";
import DealCard from "../cards/deal";
import IntelCard from "../cards/market-intel";
import SafezoneColorDot from "../safezone-color-dot.vue";

export default {
  components: { SafezoneColorDot },
  props: ["content"],
  computed: {
    ...mapGetters(["isAdmin", "signedIn"]),
    marketingListDeal() {
      return _.get(this.content, "source", null) === "direct";
    },
    liveDeal() {
      return _.includes(
        ["live", "awarded"],
        _.get(this.content, "state", null)
      );
    },
    activeDeal() {
      return _.includes(
        ["draft", "live", "awarded"],
        _.get(this.content, "state", null)
      );
    }
  },
  methods: {
    viewDetails() {
      switch (this.content.newsfeedType) {
        case "deal":
          if (this.activeDeal && this.marketingListDeal && this.content.dealTeam) {
            const marketingPage =
              this.content.state === "draft" ? "overview" : "marketing-list";

            this.$router.push({
              path: `/deal_offerings/${this.content.urlHandle || this.content.token}/${marketingPage}`
            });
          } else if (this.liveDeal && this.marketingListDeal && this.content.marketingReadAuthorized) {
            const marketingOverviewPath = `/deal_offerings/${this.content.urlHandle || this.content.token}/overview`;

            window.open(marketingOverviewPath, "_blank");
          } else {
            this.$store.commit("openModal", {
              component: DealCard,
              props: {
                dealObject: this.content,
                property: null
              }
            });
          }
          break;
        case "intel":
          this.$store.commit("openModal", {
            component: IntelCard,
            props: {
              postObject: this.content,
              property: null
            }
          });
          break;
        default:
          return;
      }
    }
  }
};
</script>
