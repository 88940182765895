<template>
  <div>
    <div class="pb-6 px-6 sm:pb-10 sm:px-10">
      <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
        <svg class="h-6 w-6 text-green-600" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"/>
        </svg>
      </div>
      <div class="mt-3 text-center sm:mt-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Welcome to your Deal Overview
        </h3>
        <div class="mt-2">
          <p class="text-sm leading-5 text-gray-500">
            This marketing page presents your high-level deal narrative. As a deal team member, you can edit the page content by clicking on each part. Once you launch the deal, this is the first page your prospects will see. Your goal is to convince them to register and seek more info.
          </p>
        </div>
      </div>
    </div>
    <div class="border-t-2 border-gray-100 rounded-b-lg pt-6 sm:pt-10 pb-4 sm:pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
      <div class="mb-4 sm:mb-8 relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
        <div class="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:ring">
          <img class="w-full" src="https://assets.towerhunt.com/site/dealMarketingOverviewDemo1.gif" alt="Demonstration" />
        </div>
      </div>
      <ul>
        <li class="flex items-start">
          <div class="flex-shrink-0">
            <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
          </div>
          <p class="ml-3 text-base leading-6 font-medium text-gray-500">
            Be sure to also build out the deal's photos, documents, and marketing list before you launch!
          </p>
        </li>
      </ul>
      <div class="mt-6 sm:mt-10">
        <span class="flex w-full rounded-md shadow-sm">
          <button @click="dismiss" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo sm:text-sm sm:leading-5">
            Got it
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    dismiss() {
      this.$store.dispatch("dismissDealMarketingWelcome");
    }
  }
};
</script>
