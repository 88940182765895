<template>
  <div class="relative rounded-lg border border-gray-300 bg-white px-6 py-4 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
    <div class="flex-shrink-0" v-tooltip="reputationHelp">
      <avatar-photo v-if="contributor.name" :person="contributor" :circle-size="12" text-size="lg" />
      <img v-else :class="reputationColor" class="p-1 inline-block h-12 w-12 rounded-full" src="https://assets.towerhunt.com/site/Transparent_White_T0.png" alt="Tower Hunt logo">
    </div>
    <div class="flex-1 min-w-0">
      <p v-tooltip="contributor.name ? 'Only you can see your identity.' : ''" class="text-sm font-medium text-gray-900">
        <template v-if="contributor.name">{{ contributor.name }} (Me)</template>
        <template v-else>{{ contributor.reputationLabel }}</template>
      </p>
      <p class="text-sm text-gray-500 truncate">
        <span>{{ contributionCount }}</span>
      </p>
      <p class="text-sm text-gray-500 truncate">
        <span>{{ contributionRoles }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import AvatarPhoto from "../avatar-photo.vue";
import pluralize from "pluralize";

export default {
  components: { AvatarPhoto },
  props: ["contributor"],
  computed: {
    contributionCount() {
      return pluralize(
        "contribution",
        this.contributor.attributions.length,
        true
      );
    },
    contributionRoles() {
      return _.uniq(this.contributor.attributions.map(attr => {
          return _.capitalize(attr.userRole);
        })
      ).join(", ");
    },
    reputationHelp() {
      return _.capitalize(this.contributor.reputationLevel);
    },
    reputationColor() {
      switch (this.contributor.reputationLevel) {
        case "bronze":
          return "bg-gradient-to-r from-yellow-500 to-orange-700 text-white hover:to-orange-800";
        case "silver":
          return "bg-gradient-to-r from-cool-gray-200 to-gray-400 text-gray-900 hover:to-gray-500";
        case "gold":
          return "bg-gradient-to-r from-yellow-300 to-yellow-500 text-white hover:to-yellow-600";
        default:
          return "text-white bg-gray-900 hover:bg-gray-800";
      }
    }
  }
};
</script>
