<template>
  <form @submit.prevent="submit">
    <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-8">
      <div class="sm:col-span-3">
        <div class="pb-5 border-b border-gray-200 space-y-2">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Add new price
          </h3>
          <p class="max-w-4xl text-sm leading-5 text-gray-500">Create multiple prices to target different customers.</p>
        </div>
      </div>
      <div class="sm:col-span-1">
        <label for="industry_role" class="block text-sm font-medium leading-5 text-gray-700">
          Industry role(s)
        </label>
        <div class="mt-1 rounded-md shadow-sm">
          <select v-model="industryRole" id="industry_role" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5">
            <option value="all">Everyone</option>
            <option value="principal">Principals</option>
            <option value="broker">Brokers</option>
            <option value="observer">Others</option>
          </select>
        </div>
        <p class="mt-2 text-sm text-gray-500 sm:text-xs" id="price-description">Who sees this price?</p>
      </div>
      <div class="sm:col-span-1">
        <label for="price" class="block text-sm font-medium leading-5 text-gray-700">Price</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none sm:text-sm sm:leading-5">
            <span class="text-gray-500">
              $
            </span>
          </div>
          <input v-focus v-model="price" id="price" type="number" min="0.99" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 pr-16 font-bold sm:leading-5" placeholder="0.99" aria-describedby="price-currency">
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span class="text-gray-500" id="price-currency">
              USD
            </span>
          </div>
        </div>
        <p class="mt-2 text-sm text-gray-500 sm:text-xs" id="price-description">Before 30% revenue share. $0.99 min.</p>
      </div>
      <div class="sm:col-span-1">
        <label for="frequency" class="block text-sm font-medium leading-5 text-gray-700">
          Frequency
        </label>
        <div class="mt-1 rounded-md shadow-sm">
          <select v-model="frequency" id="frequency" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5">
            <option value="once">Once</option>
            <option value="monthly">Per month</option>
          </select>
        </div>
        <p class="mt-2 text-sm text-gray-500 sm:text-xs" id="price-description">How often?</p>
      </div>
      <div class="sm:col-span-1">
        <div>
          <div class="flex justify-between">
            <label for="name" class="block text-sm font-medium leading-5 text-gray-700">Name</label>
            <span class="text-sm leading-5 text-gray-500" id="name-optional">Optional</span>
          </div>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input v-model="name" id="name" type="text" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5" placeholder="e.g., Basic" aria-describedby="name-optional">
          </div>
        </div>
      </div>

      <div class="sm:col-span-2">
        <div>
          <div class="flex justify-between">
            <label for="description" class="block text-sm font-medium leading-5 text-gray-700">Description</label>
            <span class="text-sm leading-5 text-gray-500" id="description-optional">Optional</span>
          </div>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input v-model="description" id="description" type="text" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5" placeholder="e.g., Includes bi-weekly updates." aria-describedby="description-optional">
          </div>
        </div>
      </div>

      <div class="sm:col-span-3">
        <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
            <button type="submit" class="inline-flex items-center justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo sm:text-sm sm:leading-5">
              <template v-if="editing === 'new'">Add</template>
              <template v-else>Edit</template>
            </button>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
            <button @click="cancel" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue sm:text-sm sm:leading-5">
              Cancel
            </button>
          </span>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import api from "../../api";

export default {
  props: ["purchaseable", "editing"],
  data() {
    return {
      industryRole: "all",
      frequency: "once",
      price: 19.99,
      name: "",
      description: ""
    };
  },
  computed: {
    complete() {
      return this.price >= 0.99;
    },
    buttonStyling() {
      if (this.complete) {
        return "bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700";
      } else {
        return "border-gray-300 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 cursor-not-allowed";
      }
    }
  },
  mounted() {
    if (_.isObject(this.editing)) {
      this.mountPrice();
    }
  },
  methods: {
    mountPrice() {
      const {
        price,
        frequency,
        industryRole,
        name,
        description
      } = this.editing;

      this.price = price;
      this.frequency = frequency;
      this.industryRole = industryRole;

      if (name) {
        this.name = name;
      }

      if (description) {
        this.description = description;
      }
    },
    cancel() {
      this.$emit("done-editing");
    },
    submit() {
      if (_.isObject(this.editing)) {
        this.updatePrice();
      } else {
        this.createPrice();
      }
    },
    createPrice() {
      if (this.complete) {
        const payload = {
          name: _.trim(this.name) === "" ? null : this.name,
          description:
            _.trim(this.description) === "" ? null : this.description,
          price: this.price,
          industryRole: this.industryRole,
          frequency: this.frequency
        };

        api
          .post(
            `purchaseables/${this.purchaseable.id}/purchaseable_prices`,
            payload
          )
          .then(json => {
            this.$store.commit("setRefetchPurchaseables", true);
            this.$store.dispatch("flash", "Price added!");
            this.$emit("done-editing");
          });
      }
    },
    updatePrice() {
      if (this.complete) {
        const payload = {
          name: _.trim(this.name) === "" ? null : this.name,
          description:
            _.trim(this.description) === "" ? null : this.description,
          price: this.price,
          industryRole: this.industryRole,
          frequency: this.frequency
        };

        api
          .patch(
            `purchaseables/${this.purchaseable.id}/purchaseable_prices/${this.editing.id}`,
            payload
          )
          .then(json => {
            this.$store.commit("setRefetchPurchaseables", true);
            this.$store.dispatch("flash", "Price edited!");
            this.$emit("done-editing");
          });
      }
    }
  }
};
</script>
