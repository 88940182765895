export default [
  {
    order: 1,
    name: "Deal in the market",
    value: "indirectLiveDeal",
    description: "Anonymously report a live deal."
  },
  {
    order: 2,
    name: "U/A or Closed deal",
    value: "closedDeal",
    description: "Add an under agreement or closed deal."
  },
  {
    order: 3,
    name: "Development project news",
    value: "developmentNews",
    description: "News about the status of a development project."
  },
  {
    order: 4,
    name: "Market discussion",
    value: "marketCommentary",
    description:
      "Inside info, opinions, articles, etc. Link to an outside source or share your own thoughts."
  },
  {
    order: 5,
    name: "Tenant in the market",
    value: "tenantInMarket",
    description: "An active tenant space requirement."
  },
  {
    order: 6,
    name: "Market report",
    value: "marketReport",
    description: "A published research report, survey, or study."
  },
  {
    order: 7,
    name: "Infrastructure project news",
    value: "infrastructureNews",
    description:
      "News about public infrastructure that may impact nearby properties."
  },
  {
    order: 8,
    name: "Industry event",
    value: "industryEvent",
    description: "An event where people gather to network and/or learn."
  },
  {
    order: 9,
    name: "Job",
    value: "jobPosting",
    description: "A position that you or someone else is hiring for."
  }
];
