<template>
  <div v-if="people.data.length > 0" class="my-4 bg-white overflow-hidden shadow rounded-lg">
    <div class="border-b border-gray-200 px-4 py-5 sm:px-6">
      <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
        <div class="ml-4 mt-4">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            <template v-if="preloaded">{{ industryRole | capitalize }}</template>
            <template v-else-if="market">
              <router-link :to="`/open/${market.token}`" class="text-indigo-600 hover:text-indigo-500">{{ market | marketName }}</router-link>: People you may want to connect with
            </template>
            <template v-else-if="deal">Marketing list recommendations</template>
          </h3>
          <p v-if="deal" class="mt-1 text-sm leading-5 text-gray-500">
            Your deal is a good match for these principals.
          </p>
        </div>
        <div v-if="canInvite" class="ml-4 mt-2 flex-shrink-0 flex">
          <span class="inline-flex rounded-md shadow-sm">
            <button @click="invite" type="button" class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
              Invite people
            </button>
          </span>
        </div>
        <div v-else-if="deal" class="ml-4 mt-2 flex-shrink-0 flex">
          <span v-if="dealMarketingVisibility !== 'unrestricted'" class="inline-flex rounded-md shadow-sm">
            <button @click="allowMatchingPrincipals" type="button" v-tooltip="'Allow any current and future matching principals to register for the deal.'" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-50 active:text-gray-800">
              <svg class="-ml-1 mr-2 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
              </svg>
              <span>
                Allow all
              </span>
            </button>
          </span>
          <span class="ml-3 inline-flex rounded-md shadow-sm">
            <button @click="toggleRecommendations" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-50 active:text-gray-800">
              <svg class="-ml-1 mr-2 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
              </svg>
              <template v-if="showRecommendations">Hide</template>
              <template v-else>View</template>
            </button>
          </span>
        </div>
      </div>
    </div>
    <div v-if="showRecommendations" class="px-4 py-5 sm:p-6">
      <div class="grid gap-5 max-w-lg mx-auto sm:grid-cols-3 lg:grid-cols-5 sm:max-w-none">
        <person
          v-for="person in people.data"
          :key="person.token"
          :contact="person"
          :suggestion="!preloaded"
          :deal-volume="true"
        />
      </div>
    </div>
    <div v-if="showRecommendations && peoplePagination && peoplePagination.count > 5" class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div class="hidden sm:block">
        <p class="text-sm leading-5 text-gray-700">
          Showing
          <span class="font-medium">{{ peoplePagination.from }}</span>
          to
          <span class="font-medium">{{ peoplePagination.to }}</span>
          of
          <span class="font-medium">{{ peoplePagination.count }}</span>
          people
        </p>
      </div>
      <div class="flex-1 flex justify-between sm:justify-end">
        <router-link @click.native="fetchPeople(peoplePagination.prev_url)" to="" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
          Previous
        </router-link>
        <router-link @click.native="fetchPeople(peoplePagination.next_url)" to="" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
          Next
        </router-link>
      </div>
    </div>
    <div v-if="preloaded && oneOrFewerParticipants" class="relative bg-gray-800">
      <div class="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img class="w-full h-full object-cover" src="https://source.unsplash.com/collection/2155077" alt="People intro" />
      </div>
      <div class="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div class="md:ml-auto md:w-1/2 md:pl-10">
          <div class="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
            Key players
          </div>
          <h2 class="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
            People who know this market
          </h2>
          <p class="mt-3 text-lg leading-7 text-gray-300">
            Try editing the geographic and/or industry focus.
          </p>
          <div class="mt-8">
            <div class="inline-flex rounded-md shadow">
              <a @click.prevent="invite" href="" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:ring">
                Invite people
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmailBatchUpload from "../views/offered-deal/email-batch-upload";
import Person from "./cards/person";
import api from "../api";

export default {
  components: { Person },
  props: ["market", "deal", "peopleData", "industryRole", "canInvite"],
  data() {
    return {
      showRecommendations: true,
      localPeople: {
        data: [],
        pagy: null
      }
    };
  },
  computed: {
    ...mapState(["contactsToRefetch", "activeDealMarketing"]),
    shouldShowRecommendations() {
      if (_.get(this.activeDealMarketing, "id", null)) {
        return _.includes(["restricted"], this.dealMarketingVisibility);
      } else {
        return true;
      }
    },
    dealMarketingVisibility() {
      return _.get(this.activeDealMarketing, "visibility", null);
    },
    dealMarketingSendTeaserEmails() {
      return _.get(this.activeDealMarketing, "sendTeaserEmails", true);
    },
    people() {
      if (this.preloaded) {
        return this.peopleData;
      } else {
        return this.localPeople;
      }
    },
    needsRefetch() {
      return this.contactsToRefetch.some(token => {
        return _.find(this.people.data, { token });
      });
    },
    peoplePagination() {
      return this.people.pagy;
    },
    currentPageUrl() {
      if (this.people.pagy) {
        return this.people.pagy.scaffold_url.replace(
          /__pagy_page__/,
          this.people.pagy.page
        );
      } else {
        return null;
      }
    },
    preloaded() {
      return !!this.peopleData && !!this.industryRole;
    },
    oneOrFewerParticipants() {
      return (
        (this.people.data.length <= 1 && !this.peoplePagination) ||
        (this.people.data.length <= 1 && this.peoplePagination.count < 2)
      );
    }
  },
  watch: {
    shouldShowRecommendations: {
      handler() {
        this.showRecommendations = this.shouldShowRecommendations;
      }
    },
    showRecommendations: {
      handler(val, oldVal) {
        if (this.showRecommendations && val !== oldVal) {
          this.fetchPeople();
        }
      }
    },
    contactsToRefetch: {
      handler() {
        if (this.needsRefetch && this.currentPageUrl) {
          this.fetchPeople(this.currentPageUrl);
        }
      },
      deep: true
    }
  },
  mounted() {
    if (!this.shouldShowRecommendations) {
      this.showRecommendations = false;
    }

    if (!this.preloaded) {
      this.fetchPeople();
    }
  },
  methods: {
    fetchPeople(url = null) {
      if (this.preloaded) {
        this.$emit("fetch", { url, industryRole: this.industryRole });
      } else {
        const cleanUrl = url ? _.drop(url, 8).join("") : url;
        const endpoint = this.endpointFor(cleanUrl);

        if (endpoint) {
          this.$store.commit("isLoading");
          api.get(endpoint).then(json => {
            this.localPeople = json.data;
            this.$store.commit("doneLoading");
          });
        }
      }
    },
    endpointFor(url = null) {
      if (url) {
        return url;
      } else if (this.market) {
        return `followable_target_people/${this.market.token}`;
      } else if (this.deal) {
        return `deal_marketing_list_recommendations/${this.deal.token}`;
      } else {
        return null;
      }
    },
    invite() {
      this.$store.commit("openModal", {
        component: EmailBatchUpload,
        props: {
          action: "createTowerHuntInvitation"
        }
      });
    },
    allowMatchingPrincipals() {
      this.$store.dispatch("allowDealMarketingMatchingPrincipals");
      this.toggleRecommendations();
    },
    toggleRecommendations() {
      this.showRecommendations = !this.showRecommendations;
    }
  }
};
</script>
