<template>
  <div class="mt-8">
    <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 class="text-lg leading-6 font-medium text-gray-900">Demand</h2>
      <div class="mt-2 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
        <div>
          <div class="px-4 py-5 sm:p-6 flex justify-between items-center md:block lg:flex">
            <dl>
              <dt class="text-base leading-6 font-normal text-gray-900">
                Matches
              </dt>
              <dd class="mt-1 flex items-baseline md:block lg:flex">
                <div class="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                  <template v-if="post.offeredTo.total > 0">{{ post.offeredTo.total }}</template>
                  <template v-else>Pending</template>
                  <span class="ml-2 text-sm leading-5 font-medium text-gray-500"></span>
                </div>
              </dd>
            </dl>
            <div v-if="post.offeredTo.total > 0" id="industry-role-pie" class="inline-flex md:mt-2 lg:mt-0" />
          </div>
        </div>
        <div class="border-t border-gray-200 md:border-0 md:border-l">
          <div class="px-4 py-5 sm:p-6 flex justify-between items-center md:block lg:flex">
            <dl>
              <dt class="text-base leading-6 font-normal text-gray-900">
                Reviewed by
              </dt>
              <dd class="mt-1 flex items-baseline md:block lg:flex">
                <div v-if="reviewedTotal > 0" class="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                  {{ reviewedTotal }}
                  <span class="ml-2 text-sm leading-5 font-medium text-gray-500">
                    {{ reviewedPercentage }}%
                  </span>
                </div>
                <div v-else class="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                  Pending
                  <span class="ml-2 text-sm leading-5 font-medium text-gray-500"></span>
                </div>
              </dd>
            </dl>
            <div v-if="reviewedTotal > 0" id="user-reactions-pie" class="inline-flex md:mt-2 lg:mt-0" />
          </div>
        </div>
        <div class="border-t border-gray-200 md:border-0 md:border-l">
          <div class="px-4 py-5 sm:p-6 h-full flex justify-between items-center md:block lg:flex">
            <dl>
              <dt class="text-base leading-6 font-normal text-gray-900">
                Engagement
              </dt>
              <dd class="mt-1 flex items-baseline md:block lg:flex">
                <div v-if="reviewedTotal > 0" class="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                  {{ post.marketInterest.priced + post.marketInterest.messaged }}
                  <span class="ml-2 text-sm leading-5 font-medium text-gray-500">
                    {{ extraEffortLabels }}
                  </span>
                </div>
                <div v-else class="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                  Pending
                  <span class="ml-2 text-sm leading-5 font-medium text-gray-500"></span>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <h2 class="max-w-screen-xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
      Cap Rates
    </h2>

    <div v-if="sufficientCapData">
      <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="bg-white overflow-hidden shadow rounded-lg mt-2">
          <cap-rate-box-plot :cap-rates="post.capRates" :snapshot-date="post.propertySnapshot.lastUpdated" />
        </div>
      </div>
    </div>

    <div v-else>
      <div class="max-w-screen-xl mx-auto mt-2 px-4 sm:px-6 lg:px-8">
        <div class="bg-indigo-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div class="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div class="lg:self-center">
              <h2 class="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10">
                <span>Awaiting cap rate data</span>
              </h2>
              <p class="mt-4 text-lg leading-6 text-indigo-200">View a box plot of submitted cap rates once there is sufficient data.</p>
            </div>
          </div>
          <div class="relative pb-3/5 -mt-6 md:pb-1/2">
            <img class="absolute inset-0 w-full h-full transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20" src="https://assets.towerhunt.com/site/capRateBoxPlot1.jpg" alt="Box plot screenshot">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CapRateBoxPlot from "./cap-rate-box-plot";
import Plotly from "plotly.js-dist";
import pluralize from "pluralize";

export default {
  components: { CapRateBoxPlot },
  props: ["post"],
  data() {
    return {
      industryRoleData: [
        {
          values: _.map(this.post.offeredTo.roles, function(value, key) {
            return value;
          }),
          labels: _.map(this.post.offeredTo.roles, function(value, key) {
            return _.capitalize(key);
          }),
          type: "pie",
          marker: { colors: ["#5850EC", "#8DA2FB"] },
          textinfo: "label+percent",
          textposition: "auto",
          automargin: true
        }
      ],
      industryRoleLayout: {
        font: {
          family: "Inter var, Sans-Serif",
          size: 10
        },
        showlegend: false,
        margin: { l: 0, r: 0, t: 0, b: 0 },
        height: 100,
        width: 180
      },
      userReactionsData: [
        {
          values: _.compact([
            this.post.marketInterest.starred,
            this.post.marketInterest.accepted,
            this.post.marketInterest.discarded
          ]),
          labels: _.compact([
            this.post.marketInterest.starred ? "Loved" : null,
            this.post.marketInterest.accepted ? "Interested" : null,
            this.post.marketInterest.discarded ? "Discarded" : null
          ]),
          type: "pie",
          marker: {
            colors: _.compact([
              this.post.marketInterest.starred ? "#76a9fa" : null,
              this.post.marketInterest.accepted ? "#84e1bc" : null,
              this.post.marketInterest.discarded ? "#f05252" : null
            ])
          },
          textinfo: "label+percent",
          textposition: "auto",
          automargin: true
        }
      ],
      userReactionsLayout: {
        font: {
          family: "Inter var, Sans-Serif",
          size: 10
        },
        showlegend: false,
        margin: { l: 0, r: 0, t: 0, b: 0 },
        height: 100,
        width: 180
      }
    };
  },
  computed: {
    reviewedTotal() {
      const { starred, accepted, discarded } = this.post.marketInterest;

      return starred + accepted + discarded;
    },
    reviewedPercentage() {
      if (this.reviewedTotal > 0 && this.post.offeredTo.total > 0) {
        const percentage = this.reviewedTotal / this.post.offeredTo.total;

        return _.round(percentage * 100);
      } else {
        return 0;
      }
    },
    extraEffortLabels() {
      const priced = pluralize(
        "cap rate range",
        this.post.marketInterest.priced,
        true
      );
      const messaged = pluralize(
        "message",
        this.post.marketInterest.messaged,
        true
      );

      return `${priced}, ${messaged}`;
    },
    sufficientCapData() {
      return this.post.capRates && this.post.capRates.length > 0;
    }
  },
  mounted() {
    if (this.reviewedTotal > 0) {
      Plotly.newPlot(
        "industry-role-pie",
        this.industryRoleData,
        this.industryRoleLayout,
        {
          responsive: true,
          displaylogo: false,
          displayModeBar: false
        }
      );

      Plotly.newPlot(
        "user-reactions-pie",
        this.userReactionsData,
        this.userReactionsLayout,
        {
          responsive: true,
          displaylogo: false,
          displayModeBar: false
        }
      );
    }
  }
};
</script>
