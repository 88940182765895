<template>
  <div class="wrapper">
    <heading>Alright! Let's get you an account.</heading>
    <form @submit.prevent="next" autocomplete="off" novalidate>
      <div class="input-container">
        <input
          v-focus
          v-model="email"
          @focus="focus('email')"
          @blur="blur"
          type="email"
          autocomplete="new-password"
          autocorrect="off"
          spellcheck="false"
          placeholder=""
          tabindex="1"
          name="user_email"
          class="input-text black"
          :class="{ active: focused === 'email' }"
          required
        >
        <label class="input-label" :class="{ active: focused === 'email' }">Email address</label>
        <label class="email-icon" :class="{ active: focused === 'email' }">
          <i class="far fa-envelope"></i>
        </label>
      </div>
      <div class="input-container">
        <input
          v-model="password"
          @focus="focus('password')"
          @blur="blur"
          type="password"
          autocomplete="new-password"
          autocorrect="off"
          spellcheck="false"
          placeholder=""
          tabindex="1"
          name="user_password"
          class="input-text black"
          :class="{ active: focused === 'password' }"
          required
        >
        <label class="input-label" :class="{ active: focused === 'password' }">Password</label>
        <label class="email-icon" :class="{ active: focused === 'password' }">
          <i class="fas fa-key"></i>
        </label>
      </div>

      <div class="tos-wrap">
        <small>By clicking Next, you agree to the <router-link to="/legal" target="_blank" class="purple">Terms of Service</router-link>.</small>
      </div>
      <button type="submit" class="cta" :class="{ complete: complete }" tabindex="999">Next</button>
    </form>
  </div>
</template>

<script>
import Avatar from "../../components/onboarding/avatar";
import Heading from "../../components/onboarding/heading";

export default {
  data() {
    return {
      focused: false
    }
  },
  mounted() {
    if (!this.$store.state.onboardingInProcess) {
      this.$router.push({ path: "/start/1" });
    }
  },
  components: { Avatar, Heading },
  computed: {
    email: {
      get() {
        return this.$store.state.newUser.email;
      },
      set(newEmail) {
        this.$store.commit("setNewUserEmail", newEmail);
      }
    },
    password: {
      get() {
        return this.$store.state.newUser.password;
      },
      set(newPassword) {
        this.$store.commit("setNewUserPassword", newPassword);
      }
    },
    complete() {
      return this.email !== "" && this.password !== "" && _.get(this, "password", []).length >= 5;
    }
  },
  methods: {
    focus(field) {
      this.focused = field;
    },
    blur() {
      this.focused = false;
    },
    next() {
      if (this.complete) {
        this.$store.dispatch("createOnboardingAccount");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
input {
  vertical-align: middle;
}
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 26px 38px 8px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.active {
    border: 2px solid #5850ec;
  }
}
.input-label {
  position: absolute;
  top: 8px;
  left: 38px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
.email-icon {
  position: absolute;
  top: 19px;
  left: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
.tos-wrap {
  align-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  small {
    margin-top: 20px;
  }
  a {
    text-decoration: none;
  }
}
</style>
