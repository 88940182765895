<template>
  <div>
    <img
      v-if="propertyName && propertyAvatar"
      :class="`h-${circleSize} w-${circleSize} ${border}`"
      class="inline-block rounded-full object-cover object-center"
      :src="propertyAvatar"
      alt=""
    />
    <span
      v-else-if="propertyName && iconSize"
      :class="`h-${circleSize} w-${circleSize} ${border}`"
      class="inline-flex items-center justify-center rounded-full bg-gray-400"
    >
      <svg :class="`h-${iconSize} w-${iconSize}`" class="text-white" fill="currentColor" viewBox="0 0 20 20">
        <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clip-rule="evenodd" />
      </svg>
    </span>
    <img
      v-else-if="person && person.avatar"
      :class="`h-${circleSize} w-${circleSize} ${border}`"
      class="inline-block rounded-full object-cover object-center"
      :src="person.avatar"
      alt=""
    />
    <span
      v-else
      :class="`h-${circleSize} w-${circleSize} ${border}`"
      class="inline-flex items-center justify-center rounded-full bg-gray-400"
    >
      <span
        :class="`text-${textSize}`"
        class="font-medium uppercase leading-none text-white"
      >
        {{ (teamName || person.name) | initials }}
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: [
    "teamName",
    "person",
    "propertyName",
    "propertyAvatar",
    "circleSize",
    "iconSize",
    "textSize",
    "borderClass"
  ],
  computed: {
    border() {
      return this.borderClass || "";
    }
  }
};
</script>
