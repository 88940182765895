<template>
  <div>
    <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div class="max-w-md w-full">
        <div>
          <router-link to="/">
            <img class="mx-auto h-16 w-auto" src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png" alt="Tower Hunt" />
          </router-link>
          <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Change your password
          </h2>
        </div>
        <form class="mt-8" @submit.prevent="updatePassword">
          <input type="hidden" name="remember" value="true" />
          <div class="rounded-md shadow-sm">
            <div>
              <input v-focus v-model="session.password" aria-label="Password" name="password" type="password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo focus:border-indigo-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="New password" />
            </div>
          </div>

          <div class="mt-6">
            <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
              <span class="absolute left-0 inset-y pl-3">
                <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                </svg>
              </span>
              Update password
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api";

export default {
  props: ["token"],
  data() {
    return {
      session: {
        password: ""
      }
    };
  },
  computed: {
    userId() {
      return this.token.split("-")[0]
    },
    resetToken() {
      return this.token.split("-")[1]
    },
    payload() {
      return {
        userId: this.userId,
        token: this.resetToken,
        password: this.session.password
      };
    }
  },
  methods: {
    updatePassword() {
      api.post("passwords/update", this.payload).then(
        success => {
          this.$store.dispatch("flash", {
            message: "Password reset successful",
            timeout: 5000
          });
          this.$router.push({ path: "/sign-in" });
        },
        failure => {
          this.$store.dispatch("flash", {
            message: "An error occurred.",
            timeout: 5000
          });
          console.log(failure);
        }
      );
    }
  }
};
</script>
