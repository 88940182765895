<template>
  <div v-if="currentChannel" :class="propertyFollowing ? '' : '-mt-6'" class="sm:mx-auto sm:w-full sm:max-w-xl md:max-w-3xl flex flex-col justify-end">
    <div class="mb-4 bg-white shadow sm:rounded-lg">
      <div key="end-of-feed" class="px-4 py-5 sm:p-6 list-complete-item">
        <h3 v-if="currentChannel.propertyFollowingName" class="text-lg leading-6 font-medium text-gray-900">
          {{ currentChannel | channelName }} property watchlist channel
        </h3>
        <h3 v-else-if="currentChannel.teamName" class="text-lg leading-6 font-medium text-gray-900">
          {{ currentChannel | channelName }} team channel
        </h3>
        <h3 v-else class="text-lg leading-6 font-medium text-gray-900">
          Private channel with {{ currentChannel | channelName }}
        </h3>
        <div class="mt-2 sm:flex sm:items-start sm:justify-between">
          <div class="max-w-xl text-sm leading-5 text-gray-500">
            <p v-if="currentChannel.propertyFollowingName">
              This is the very beginning of your private discussion about {{ currentChannel | channelName }}. Invited team members can see this, no matter when they join the team.
            </p>
            <p v-else-if="currentChannel.teamName">
              This is the very beginning of your team's private data history. Anyone on the team can see this, no matter when they join the team.
            </p>
            <p v-else>
              This is the very beginning of your direct message history with {{ currentChannel | channelName }}. Only you {{ currentChannel.members.length + 1 }} are in this conversation, and no one else can join it.
            </p>
          </div>
          <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
            <span class="inline-flex rounded-md shadow-sm">
              <button @click="newIntel" type="button" :class="privacyMode ? 'bg-pink-600 hover:bg-pink-500 focus:outline-none focus:border-pink-700 focus:ring-pink active:bg-pink-700' : 'bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700'" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white">
                <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
                  <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
                </svg>
                <svg
                  v-else
                  class="-ml-1 mr-2 h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"></path>
                </svg>
                Post intel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>

    <infinite-loading
      v-if="loaded && !endOfFeed"
      direction="top"
      :identifier="currentChannel.token"
      @infinite="incrementBackchannel"
    />

    <div class="flex flex-col-reverse space-y-2">
      <multi-content-preview-card
        v-for="(post, index) in backchannel.data"
        :key="index"
        :compact="true"
        :content="post.content"
        @comment-deleted="deleted"
        :class="index === 0 ? 'mt-2' : ''"
      />
    </div>

    <div id="channel-bottom"></div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapState } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import MultiContentPreviewCard from "../components/cards/multi-content-preview-card.vue";
import api from "../api";

var poll, nowUpdateInterval;

export default {
  components: {
    MultiContentPreviewCard,
    InfiniteLoading
  },
  props: ["channelToken", "propertyFollowing"],
  data() {
    return {
      busy: false,
      endOfFeed: false,
      loaded: false,
      backchannel: {
        data: [],
        pagy: null
      }
    };
  },
  computed: {
    ...mapState(["currentUser", "refetchBackchannel", "now", "adminMode"]),
    ...mapGetters(["backchannels", "privacyMode"]),
    newestPost() {
      return _.head(this.backchannel.data);
    },
    currentChannel() {
      return (
        _.find(this.backchannels, { token: this.channelToken }) ||
        _.find(this.currentUser.backchannels, {
          token: _.get(this.propertyFollowing, "backchannelToken", null)
        })
      );
    },
    pagination() {
      return this.backchannel.pagy;
    },
    markets() {
      return this.$store.getters.contactTargets(null);
    },
    primaryMarket() {
      if (this.markets.length > 0) {
        return this.markets[0];
      } else {
        return null;
      }
    }
  },
  watch: {
    channelToken: {
      handler() {
        this.updateTitle();
        this.endOfFeed = false;
        this.loaded = false;
        this.clearBackchannel();
        if (!this.propertyFollowing) {
          this.$store.commit("isLoading");
        }
        this.loadBackchannel();
      }
    },
    refetchBackchannel: {
      handler() {
        if (this.refetchBackchannel === this.currentChannel.token) {
          this.endOfFeed = false;
          this.loaded = false;
          this.loadBackchannel();
        }
      }
    }
  },
  created() {
    var self = this;

    nowUpdateInterval = setInterval(function() {
      self.$store.commit("setNow", moment());
    }, 5000);

    poll = setInterval(() => {
      this.unshiftBackchannel();
      this.$store.dispatch("loadBackchannels");
    }, 15000);
  },
  mounted() {
    if (this.currentChannel) {
      this.updateTitle();
      if (!this.propertyFollowing) {
        this.$store.commit("isLoading");
      }
      this.loadBackchannel();
    } else if (this.channelToken) {
      this.$store
        .dispatch("reopenPrivateChannel", { token: this.channelToken })
        .then(() => {
          this.updateTitle();
          if (!this.propertyFollowing) {
            this.$store.commit("isLoading");
          }
          this.loadBackchannel();
        });
    }
  },
  destroyed() {
    clearInterval(poll);
    clearInterval(nowUpdateInterval);
  },
  methods: {
    clearBackchannel() {
      this.backchannel = {
        data: [],
        pagy: null
      };
    },
    updateTitle() {
      if (this.currentChannel) {
        const channelName = this.$options.filters.channelName(
          this.currentChannel
        );

        document.title = `Backchannel | ${channelName} | Tower Hunt`;
      } else {
        document.title = `Backchannel | Tower Hunt`;
      }
    },
    newIntel() {
      this.$router.push({
        path: "/intel/new/privacy",
        query: { nextUrl: "/intel/new/1" }
      });
    },
    loadBackchannel(scroll = true) {
      const token = _.get(this.currentChannel, "token", null) || this.channelToken;

      api.get(`backchannels/${token}`).then(
        json => {
          this.backchannel = json.data;
          this.$store.commit("backchannelRefetched");
          if (this.backchannel.data.length === 0) {
            this.endOfFeed = true;
          }
          if (scroll) {
            setTimeout(() => {
              if (this.propertyFollowing) {
                this.$scrollTo("#channel-bottom", 100, { container: "#watchlist-discussion-container" });
              } else {
                this.$scrollTo("#channel-bottom", 100, { container: "#hub-body-container" });
              }
              this.$store.commit("doneLoading");
            }, 1000);
          }
          setTimeout(() => {
            this.loaded = true;
          }, 5000);
        },
        failure => {
          this.$store.commit("doneLoading");
          this.$store.dispatch("flash", "Access denied");
          this.$router.push({ path: "/" });
        }
      );
    },
    extendBackchannel({ data, pagy }) {
      this.backchannel.data = this.backchannel.data.concat(data);
      this.backchannel.pagy = pagy;
    },
    unshiftBackchannel() {
      if (this.newestPost) {
        const sinceTime = moment(this.newestPost.sortDate).add(2, 's').valueOf();

        api
          .get(`backchannels/${this.currentChannel.token}?since=${sinceTime}`)
          .then(json => {
            const newPosts = json.data.data;

            this.backchannel.data = newPosts.concat(this.backchannel.data);
          });
      }
    },
    deleted(commentId) {
      this.backchannel.data = this.backchannel.data.filter(post => {
        if (post.content.id) {
          return post.content.id !== commentId;
        } else {
          return true;
        }
      });
    },
    incrementBackchannel($state) {
      if (this.pagination) {
        const { count, to } = this.pagination;

        if (count === 0) {
          this.endOfFeed = true;
        } else if (to < count) {
          const cleanUrl = _.drop(this.pagination.next_url, 8).join("");

          this.busy = true;

          api.get(cleanUrl).then(json => {
            this.extendBackchannel(json.data);
            $state.loaded();
            this.busy = false;

            if (!!analytics && !this.adminMode) {
              analytics.track("Incremented backchannel", {
                yearWeek: this.$store.state.yearWeek
              });
            }
          });
        } else {
          this.endOfFeed = true;
          $state.complete();
        }
      }
    }
  }
};
</script>
