<template>
  <div>
    <h2 class="font-medium text-gray-900 mb-6">
      <dt class="text-lg leading-5 truncate">
        {{ reviewSummary.totalReviews | formattedNumber }} {{ reviewCount }}
      </dt>
      <dd class="mt-3 text-2xl leading-9 font-semibold text-gray-900">
        {{ positivePercentage }} Recommend
      </dd>
    </h2>

    <ul class="-my-4 divide-y divide-gray-200">
      <purchaseable-review v-for="review in reviews" :key="review.id" :review="review" />
    </ul>

    <div v-if="reviewSummary.totalReviews > 1 && reviews.length === 1" class="mt-6">
      <button type="button" @click="fetchReviews" class="w-full block text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
        View all
      </button>
    </div>
  </div>
</template>

<script>
import PurchaseableReview from "./purchaseable-review";
import api from "../../api";
import pluralize from "pluralize";

export default {
  components: { PurchaseableReview },
  props: ["purchaseable"],
  data() {
    return {
      reviews: [this.purchaseable.reviewSummary.mostRecent]
    };
  },
  computed: {
    reviewSummary() {
      return this.purchaseable.reviewSummary;
    },
    positivePercentage() {
      const percentage = this.reviewSummary.positivePercentage;

      if (percentage) {
        return `${percentage}%`;
      } else {
        return "Unrated";
      }
    },
    reviewCount() {
      return pluralize("Review", this.reviewSummary.totalReviews);
    }
  },
  methods: {
    fetchReviews() {
      api.get(`purchaseable_reviews/${this.purchaseable.id}`).then(json => {
        this.reviews = json.data;
      });
    }
  }
};
</script>
