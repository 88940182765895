<template>
  <div :id="containerId" v-observe-visibility="{ callback: initialNearbyFetch, once: true, throttle: 500 }" class="w-full h-full" />
</template>

<script>
import fetchBoundingBoxProperties from "../../fetch-bounding-box-properties";
import innerIconFor from "../../property-reference-inner-icons";
import router from "../../router";
import store from "../../store";
/* global L */

export default {
  props: [
    "post",
    "redrawProperties",
    "localRegions",
    "localProperties",
    "hideNearby"
  ],
  data() {
    return {
      map: null,
      featureGroup: L.featureGroup(),
      random: Math.random(),
      refreshing: false,
      fitting: false,
      visible: false
    };
  },
  computed: {
    intelHasLocation() {
      return this.post.lat && this.post.lng;
    },
    intelRegions() {
      return this.localRegions || this.post.regions;
    },
    hasRegions() {
      return this.intelRegions && this.intelRegions.length > 0;
    },
    intelProperties() {
      return this.localProperties || this.post.properties;
    },
    hasProperties() {
      return this.intelProperties && this.intelProperties.length > 0;
    },
    containerId() {
      return `${this.post.token || "intel-map"}-${this.random}`;
    },
    locationOnly() {
      return this.intelHasLocation && !this.hasRegions && !this.hasProperties;
    },
    postToken() {
      return this.post.token;
    }
  },
  watch: {
    postToken: {
      handler(val, oldVal) {
        if (oldVal && val !== oldVal) {
          this.clearMapLayers();
        }
      }
    },
    intelRegions: {
      handler() {
        if (this.visible && !this.refreshing) {
          this.refreshAllLayers({ fit: true });
        }
      },
      deep: true
    },
    intelProperties: {
      handler() {
        if (this.visible && !this.refreshing) {
          this.refreshAllLayers({ fit: true });
        }
      },
      deep: true
    },
    intelHasLocation: {
      handler() {
        if (this.visible && !this.refreshing) {
          this.refreshAllLayers({ fit: true });
        }
      }
    }
  },
  mounted() {
    this.map = L.map(this.containerId, {
      center: [37.0902, -95.7129],
      zoom: 4,
      scrollWheelZoom: false,
      fullscreenControl: false
    });

    L.tileLayer
      .grayscale("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        fadeAnimation: false
      })
      .addTo(this.map);

    var self = this;

    this.map.on("moveend", function() {
      if (!self.refreshing && !self.fitting) {
        self.debouncedRefreshAllLayers({ fit: false });
      }
    });

    this.refreshAllLayers({ fit: true });

    document.querySelector(".leaflet-popup-pane").addEventListener(
      "load",
      function(event) {
        var target = event.target,
          tagName = target.tagName,
          popup = self.map._popup;

        if (tagName === "IMG" && popup) {
          popup.update();
        }
      },
      true
    ); // Capture the load event, because it does not bubble.
  },
  methods: {
    debouncedRefreshAllLayers: _.debounce(function({ fit = false }) {
      this.refreshAllLayers({ fit });
    }, 1000),
    refreshAllLayers({ fit = false }) {
      this.refreshing = true;
      this.clearMapLayers();

      if (this.intelHasLocation) {
        this.displayLocation({ fit });
      }

      if (this.hasRegions) {
        this.displayRegions({ fit });
      }

      if (this.hasProperties) {
        this.displayProperties({ fit });
      }

      if (!this.locationOnly && !this.hideNearby) {
        this.fetchNearby();
      }
      this.refreshing = false;
    },
    clearMapLayers() {
      this.featureGroup.eachLayer(layer => {
        this.map.removeLayer(layer);
        this.featureGroup.removeLayer(layer);
      });
    },
    fitBounds(fit, maxZoom) {
      if (fit) {
        this.fitting = true;
        this.map.fitBounds(this.featureGroup.getBounds(), { maxZoom });
        var self = this;

        setTimeout(() => {
          self.fitting = false;
        }, 30);
      }
    },
    displayRegions({ fit = false }) {
      this.intelRegions.forEach(region => {
        const layer = this.mapRegion(region).addTo(this.map);

        this.featureGroup.addLayer(layer);
      });

      this.fitBounds(fit, 15);
    },
    displayProperties({ fit = false }) {
      this.intelProperties.forEach((property, index, arr) => {
        const marker = L.marker([property.lat, property.lng], {
          riseOnHover: true,
          icon: L.icon.pulse({
            iconSize: [30, 30],
            color: "#F87171",
            fillColor: "#EF4444",
            heartbeat: 1.5,
            innerIconSize: "1rem",
            innerIconContentCode: innerIconFor(
              property.mostRecentReferenceVisibility
            )
          })
        }).addTo(this.map);

        marker.bindTooltip(property.name);

        marker.on("click", e => {
          this.$router.push({
            path: `/map?intel=${property.mostRecentReferenceId || this.post.token}`
          });
          store.commit("clearModal");
        });

        this.featureGroup.addLayer(marker);
      });

      this.fitBounds(fit, 15);
    },
    displayLocation({ fit = false }) {
      const label = this.post.location
        ? this.$options.filters.location(this.post)
        : this.post.name;
      const marker = L.marker([this.post.lat, this.post.lng], {
        riseOnHover: true
      }).addTo(this.map);

      marker.bindTooltip(label);

      this.featureGroup.addLayer(marker);

      this.fitBounds(fit, this.locationOnly ? 10 : 17);
    },
    mapRegion(region) {
      switch (region.shape) {
        case "circle":
          return L.circle(region.center, {
            radius: _.toNumber(region.radius),
            interactive: false,
            color: "#4338CA",
            stroke: true,
            fill: true
          });
        case "rectangle":
          return L.rectangle(region.coordinates, {
            interactive: false,
            color: "#4338CA",
            stroke: true,
            fill: true
          });
        case "polygon":
          return L.polygon(region.coordinates, {
            interactive: false,
            color: "#4338CA",
            stroke: true,
            fill: true
          });
        default:
          console.log("invalid shape");
      }
    },
    initialNearbyFetch(isVisible, entry) {
      if (isVisible && !this.refreshing) {
        this.visible = true;
        if (!this.locationOnly && !this.hideNearby) {
          this.fetchNearby();
        }
      }
    },
    fetchNearby() {
      fetchBoundingBoxProperties({
        map: this.map,
        router,
        store,
        featureGroup: this.featureGroup,
        focalProperties: []
      });
    }
  }
};
</script>
