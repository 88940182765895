<template>
  <div class="relative">
    <div class="max-w-screen-xl mx-auto px-4 sm:px-6">
      <div class="flex justify-between items-center py-6 md:justify-start md:space-x-10">
        <div class="lg:w-0 lg:flex-1">
          <router-link to="/" class="flex">
            <img class="h-10 w-auto sm:h-12" src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png" alt="Tower Hunt" />
          </router-link>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <button @click="open = true" type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500">
            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        <div class="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
          <router-link v-if="!signedIn" to="/sign-in" class="whitespace-nowrap text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900">
            Log in
          </router-link>
          <router-link v-if="!signedIn" to="/start/1" class="whitespace-nowrap bg-indigo-100 border border-transparent rounded-md py-2 px-4 inline-flex items-center justify-center text-base leading-6 font-medium text-indigo-700 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-indigo-200">
            Sign up
          </router-link>
        </div>
      </div>
    </div>

    <div v-show="open" style="display: none;" class="absolute top-0 inset-x-0 p-2 md:hidden">
      <div class="rounded-lg shadow-md transition transform origin-top-right" v-show="open" x-transition:enter="duration-150 ease-out" x-transition:enter-start="opacity-0 scale-95" x-transition:enter-end="opacity-100 scale-100" x-transition:leave="duration-100 ease-in" x-transition:leave-start="opacity-100 scale-100" x-transition:leave-end="opacity-0 scale-95">
        <div class="rounded-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="px-5 pt-4 flex items-center justify-between">
            <div>
              <img class="h-10 w-auto" src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png" alt="Tower Hunt" />
            </div>
            <div class="-mr-2">
              <button @click="open = false" type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500">
                <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
          <div>
            <router-link v-if="!signedIn" to="/sign-in" class="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700">
              Log in
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      open: false
    };
  },
  computed: {
    ...mapGetters(["signedIn"])
  }
};
</script>
