<template>
  <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
    <div class="flex items-start px-4 py-2">
      <div class="text-sm leading-5">
        Closed within the...
      </div>
    </div>
    <div class="flex items-start px-4 py-2">
      <div class="absolute flex items-center h-5">
        <input
          id="month"
          v-model="closedDealsPeriod"
          value="month"
          name="push_notifications"
          type="radio"
          class="focus:ring-indigo-500 border-gray-300 h-4 w-4 text-indigo-600"
          @change="saveNotification"
        >
      </div>
      <div class="pl-7 text-sm leading-5">
        <label for="month" class="text-gray-700"
          >Last month</label
        >
      </div>
    </div>
    <div class="flex items-start px-4 py-2">
      <div class="absolute flex items-center h-5">
        <input
          id="quarter"
          v-model="closedDealsPeriod"
          value="quarter"
          name="push_notifications"
          type="radio"
          class="focus:ring-indigo-500 border-gray-300 h-4 w-4 text-indigo-600"
          @change="saveNotification"
        >
      </div>
      <div class="pl-7 text-sm leading-5">
        <label for="quarter" class="text-gray-700"
          >Last quarter</label
        >
      </div>
    </div>
    <div class="flex items-start px-4 py-2">
      <div class="absolute flex items-center h-5">
        <input
          id="year"
          v-model="closedDealsPeriod"
          value="year"
          name="push_notifications"
          type="radio"
          class="focus:ring-indigo-500 border-gray-300 h-4 w-4 text-indigo-600"
          @change="saveNotification"
        >
      </div>
      <div class="pl-7 text-sm leading-5">
        <label for="year" class="text-gray-700"
          >Last year</label
        >
      </div>
    </div>
    <div class="flex items-start px-4 py-2">
      <div class="absolute flex items-center h-5">
        <input
          id="none"
          v-model="closedDealsPeriod"
          value="none"
          name="push_notifications"
          type="radio"
          class="focus:ring-indigo-500 border-gray-300 h-4 w-4 text-indigo-600"
          @change="saveNotification"
        >
      </div>
      <div class="pl-7 text-sm leading-5">
        <label for="none" class="text-gray-700">Don't notify me</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["sourceRecord", "type"],
  data() {
    return {
      closedDealsPeriod: "none"
    };
  },
  computed: {
    closedDealsConsent() {
      return this.closedDealsPeriod !== "none";
    },
    notifications() {
      return _.get(this.sourceRecord, "notifications", null);
    }
  },
  watch: {
    notifications: {
      handler: function() {
        this.mountNotification();
      },
      deep: true
    }
  },
  mounted() {
    if (this.sourceRecord.notifications) {
      this.mountNotification();
    }
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    mountNotification() {
      if (this.sourceRecord.notifications) {
        const {
          closedDealsConsent,
          closedDealsPeriod
        } = this.sourceRecord.notifications;

        this.closedDealsPeriod = closedDealsConsent
          ? closedDealsPeriod
          : "none";
      } else {
        this.closedDealsPeriod = "none";
      }
    },
    saveNotification() {
      const { closedDealsConsent, closedDealsPeriod } = this;
      const newInfo = {
        closedDealsConsent,
        closedDealsPeriod: closedDealsConsent ? closedDealsPeriod : null
      };
      let action;

      switch (this.type) {
        case "target":
          action = "updateTargetNotifications";
          break;
        case "contact":
          action = "updateContactNotifications";
          break;
        default:
          action = null;
      }

      this.$store.dispatch(action, newInfo);
    }
  }
};
</script>
