<template>
  <div class="wrapper">
    <heading class-name="black">What geographic regions?</heading>
    <button @click="next" type="button" class="form cta" :class="{ complete: hasRegions }" tabindex="999">Save</button>
    <autocomplete
      id="region-search"
      ref="regionSearch"
      :search="searchRegions"
      :auto-select="true"
      :get-result-value="getResultValue"
      :debounce-time="500"
      @focus="focus('region')"
      @blur="blur"
      @submit="handleRegionSubmit"
      placeholder="Search your regions"
      class="mb-1"
      style="width:330px;"
    />
    <button @click="createRegion" type="button" class="form secondary">Create new region</button>
    <div class="content-wrap">
      <region-list :regions="targetRegions" class="mb-2" />
      <div class="h-48">
        <market-map v-if="hasRegions" :target="activeTarget" :redraw-regions="redrawRegions" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../components/onboarding/heading";
import MarketMap from "../../components/maps/target-map";
import Region from "./onboarding-region";
import RegionList from "../../components/region-list";
import api from "../../api";

export default {
  components: { Heading, MarketMap, RegionList },
  props: ["targetId"],
  data() {
    return {
      hovering: null,
      focused: false,
      redrawRegions: false
    };
  },
  computed: {
    ...mapState([
      "modal",
      "regions",
      "activeTarget",
      "targetCreationInProcess",
      "currentUser"
    ]),
    ...mapGetters(["creatingTarget"]),
    targetRegions() {
      return this.activeTarget.regions;
    },
    hasRegions() {
      return this.targetRegions.length > 0;
    }
  },
  watch: {
    targetRegions: {
      handler() {
        this.redrawRegions = true;
        setTimeout(() => {
          this.redrawRegions = false;
        }, 100);
      }
    }
  },
  mounted() {
    if (this.creatingTarget && !this.targetCreationInProcess) {
      this.$router.push({ path: "/targets/new/1" });
    }

    document.getElementById("region-search").focus();
  },
  methods: {
    focus(field) {
      this.focused = field;
    },
    blur() {
      this.focused = false;
    },
    searchRegions(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        this.$store.dispatch("executeRegionSearch", input).then(
          json => {
            resolve(json.data);
          },
          failure => {
            this.$store.dispatch("flash", "Invalid search");
          }
        );
      });
    },
    getResultValue(result) {
      return `${result.name} (${_.capitalize(result.shape)})`;
    },
    handleRegionSubmit(result) {
      if (result) {
        api.get(`regions/${result.id}`).then(json => {
          this.$store.dispatch("addRegionWithGetters", json.data.region);
        });
        this.$refs.regionSearch.value = "";
      } else {
        this.$store.dispatch(
          "flash",
          "No matching regions in Tower Hunt. Why don't you create one?"
        );
      }
    },
    next() {
      if (this.hasRegions) {
        if (this.targetId) {
          this.$store.dispatch("updateTarget", this.targetId);
          this.$store.commit("closeModal");
          this.$store.dispatch("loadContactData", this.currentUser.token);
        } else {
          this.$store.dispatch("createOnboardingTarget");
        }
      }
    },
    createRegion() {
      if (this.modal) {
        let regionsModal = _.cloneDeep(this.modal); // obtains the current component

        this.$store.commit("openModal", {
          component: Region,
          props: {
            regionId: null
          },
          afterClose: regionsModal
        });
      } else {
        this.$store.commit("openModal", {
          component: Region,
          props: {
            regionId: null
          }
        });
      }
    },
    hover(option) {
      this.hovering = option;
    },
    vacate() {
      this.hovering = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}
.content-wrap {
  display: flex;
  flex-direction: column;
  width: 330px;
}
.autocomplete ul {
  z-index: 10001 !important;
}
.autocomplete-result {
  text-align: left;
}
button.form {
  width: 330px;
  margin-bottom: 5px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
