<template>
  <div>
    <div class="relative mt-4 pb-5 border-b border-gray-200 px-4 py-5 sm:px-6 sm:pb-0">
      <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div class="ml-4 mt-2">
          <h3 class="text-lg leading-6 font-medium text-gray-900 truncate">
            <template v-if="activeTarget.authored">
              <router-link
                @click.native="openModal({ record: activeTarget, type: 'target-name' })"
                to=""
                class="text-indigo-600 hover:text-indigo-500"
                v-tooltip="'Customize market name'"
              >
                {{ activeTarget | marketName | truncate(30) }}
              </router-link>
            </template>
            <template v-else>{{ activeTarget | marketName | truncate(30) }}</template>
          </h3>
          <p class="text-sm leading-5 text-gray-500">
            <template v-if="activeTarget.authored">Personalize Tower Hunt by editing these criteria.</template>
            <template v-else>{{ activeTarget.userName }} focuses in these areas.</template>
          </p>
        </div>
        <div class="ml-4 mt-2 flex-shrink-0">
          <template v-if="activeTarget.authored">
            <button @click="clone" v-tooltip="'Clone this market and customize it for yourself.'" type="button" class="inline-flex items-center mr-2 px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
              <svg class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
              </svg>
              Clone
            </button>
            <target-menu :target="activeTarget" />
          </template>
          <span v-else class="sm:ml-3 shadow-sm rounded-md">
            <button @click="clone" v-tooltip="'Clone this market and customize it for yourself.'" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
              <svg class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
              </svg>
              Clone
            </button>
          </span>
        </div>
      </div>
      <div class="mt-4">
        <!-- Dropdown menu on small screens -->
        <div class="sm:hidden">
          <label for="current-tab" class="sr-only">Select a tab</label>
          <select v-model="tab" id="current-tab" name="current-tab" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
            <option value="criteria">Criteria</option>
            <option v-if="activeTarget.authored || activeTarget.followed" value="notifications">Notifications</option>
          </select>
        </div>
        <!-- Tabs at small breakpoint and up -->
        <div class="hidden sm:block">
          <nav class="-mb-px flex space-x-8">
            <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
            <a @click.prevent="tab = 'criteria'" href="" :class="tab === 'criteria' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'" class="whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm">
              Criteria
            </a>

            <a v-if="activeTarget.authored || activeTarget.followed" @click.prevent="tab = 'notifications'" href="" :class="tab === 'notifications' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'" class="whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm">
              Notifications
            </a>
          </nav>
        </div>
      </div>
    </div>
    <div class="px-4 py-5 sm:px-6">
      <market-criteria v-if="tab === 'criteria'" />
      <market-notifications v-else-if="(activeTarget.authored || activeTarget.followed) && tab === 'notifications'" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MarketCriteria from "./market-criteria";
import MarketName from "../../views/onboarding/onboarding-target-name";
import MarketNotifications from "./market-notifications";
import TargetMenu from "../target-menu";
import api from "../../api";
/* global analytics */

export default {
  components: { MarketCriteria, MarketNotifications, TargetMenu },
  props: ["target", "explorable"],
  data() {
    return {
      tab: "criteria"
    };
  },
  computed: {
    ...mapState(["modal", "currentUser", "activeTarget", "adminMode"]),
    ...mapGetters(["signedIn", "userIsActiveContact"])
  },
  mounted() {
    this.$store.dispatch("loadSharedTarget", this.target.token);
    this.$store.commit("closeSlideover");
  },
  methods: {
    clone() {
      if (this.signedIn) {
        api.post(`clone_target/${this.target.token}`).then(json => {
          if (!!analytics && !this.adminMode) {
            analytics.track("Market cloned", {
              yearWeek: this.$store.state.yearWeek,
              marketName: this.$options.filters.marketName(this.target)
            });
          }
          if (this.userIsActiveContact) {
            this.$store.dispatch("loadContactData", this.currentUser.token);
          }
          this.$store.dispatch("flash", "Market cloned successfully! Visit your dashboard or profile to customize.");
          this.$store.commit("closeModal");
        });
      } else {
        this.$router.push({ path: `/clone_markets/${this.target.token}` });
        this.$store.commit("closeModal");
      }
    },
    openModal({ record, type }) {
      let marketCardModal = _.cloneDeep(this.modal); // obtains the current component

      marketCardModal.props.target = record; // allows changes to flow through

      switch (type) {
        case "target-name":
          this.$store.commit("openModal", {
            component: MarketName,
            props: {
              targetId: record.id,
              existingName: record.name
            },
            afterClose: marketCardModal
          });
          break;
      }
    }
  }
};
</script>
