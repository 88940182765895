<template>
  <div ref="safezoneMenu" class="relative flex justify-end items-center" style="z-index: 9998;">
    <button @click="toggleMenu" type="button" class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500" id="project-options-menu-0-button" aria-expanded="false" aria-haspopup="true">
      <span class="sr-only">Open options</span>
      <!-- Heroicon name: solid/dots-vertical -->
      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
      </svg>
    </button>
    <transition
      enter-active-class="duration-100 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-75 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-show="menuOpen" class="mx-3 origin-top-right absolute right-7 top-0 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="project-options-menu-0-button" tabindex="-1">
        <div class="py-1" role="none">
          <a href="" @click.prevent="declassify" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem" tabindex="-1">
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
              <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
            </svg>
            Declassify
          </a>
        </div>
        <div class="py-1" role="none">
          <a href="" @click.prevent="archive" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem" tabindex="-1">
            <!-- Heroicon name: solid/trash -->
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
            Archive
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2";
import declassifyItemOptions from "../../sweet-alert-safezone-declassify-options";
import namedPostOptions from "../../sweet-alert-named-post-options";

export default {
  props: ["content"],
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState(["modal"])
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.safezoneMenu &&
        !this.$refs.safezoneMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    declassify() {
      Swal.fire(declassifyItemOptions).then(result => {
        if (result.value) {
          switch (this.content.newsfeedType) {
            case "deal":
              this.$store.dispatch("declassifySafezoneContent", {
                content: this.content
              });
              break;
            case "intel":
              Swal.fire(namedPostOptions).then(result => {
                if (result.value) {
                  this.$store.dispatch("declassifySafezoneContent", {
                    content: this.content,
                    anonymous: true
                  });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  this.$store.dispatch("declassifySafezoneContent", {
                    content: this.content,
                    anonymous: false
                  });
                }
              });
              break;
            default:
              return;
          }
        }
      });
      this.menuOpen = false;
    },
    archive() {
      switch (this.content.newsfeedType) {
        case "deal":
          this.$store.dispatch("archiveDeal", this.content.id);
          break;
        case "intel":
          this.$store.dispatch("deleteIntel", this.content.token);
          break;
        default:
          return;
      }

      this.menuOpen = false;
    }
  }
};
</script>
