<template>
  <div />
</template>

<script>
export default {
  props: ["invitationToken"],
  computed: {
    path() {
      return this.$store.state.route.path;
    },
    accepting() {
      return _.includes(this.path, "accept");
    },
    declining() {
      return _.includes(this.path, "decline");
    }
  },
  mounted() {
    if (this.accepting) {
      this.$store.dispatch(
        "acceptDealProspectInvitation",
        this.invitationToken
      );
    } else if (this.declining) {
      this.$store.dispatch(
        "declineDealProspectInvitation",
        this.invitationToken
      );
    }
  }
};
</script>
