<template>
  <div class="chat-wrap mb-2">
    <avatar :large="30" :small="30" />
    <aside class="whitespace-pre-line">{{ description }}</aside>
  </div>
</template>

<script>
import industryRoles from "../../industry-roles";
import Avatar from "./avatar";

export default {
  props: ["description"],
  components: { Avatar }
}
</script>

<style lang="scss" scoped>
.chat-wrap {
  align-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  aside {
    background-color: #F7F7F7;
    padding: 15px;
    margin-left: 10px;
    text-align: left;
    border-radius: 20px 20px 20px 5px;
    font-size: 14px;
  }
}
</style>
