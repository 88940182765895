<template>
  <div v-if="loaded" class="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-center sm:justify-between">
    <div class="sm:flex sm:flex-col sm:items-start">
      <card class='stripe-card'
        :class='{ complete }'
        :stripe='stripeKey'
        :options='stripeOptions'
        @change='complete = $event.complete'
      />
      <div v-if="complete" class="mt-2 max-w-xl text-xs leading-5 text-gray-500">
        <p>
          <template v-if="when === 'later'">I understand that I will not be charged now.</template> I authorize Tower Hunt to send instructions to the financial institution that issued my card to take future payments from my card account when I make purchases on the platform.
        </p>
      </div>
    </div>
    <div class="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
      <grid-loader v-if="submitting" :loading="submitting" size="5px" color="#5850ec" />
      <span v-else class="inline-flex rounded-md shadow-sm">
        <button v-if="complete || !cancellable" @click="submitCard" type="button" :disabled="!complete" :class="{ 'cursor-not-allowed': !complete }" class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
          Add credit card
        </button>
        <button v-else @click="cancel" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
          Cancel
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import { Card, handleCardSetup } from "vue-stripe-elements-plus";
import { mapState } from "vuex";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import api from "../api";
import config from "./stripeConfig.js";

export default {
  components: { Card, GridLoader },
  props: ["cancellable", "when"],
  data() {
    return {
      complete: false,
      submitting: false,
      stripeKey: null,
      stripeOptions: config.stripeOptions,
      clientSecret: null
    };
  },
  computed: {
    ...mapState(["adminMode"]),
    loaded() {
      return !!this.stripeKey && !!this.clientSecret;
    }
  },
  mounted() {
    api.get(`payment_api_key`).then(json => {
      this.stripeKey = json.data;
      api.post(`my_payment_methods`).then(json => {
        this.clientSecret = json.data.clientSecret;
      });
    });
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    submitCard() {
      this.submitting = true;
      handleCardSetup(this.clientSecret).then(
        data => {
          if (data.error) {
            console.log(data.error.message);
            this.$store.dispatch("flash", data.error.message);
          } else {
            if (!!analytics && !this.adminMode) {
              analytics.track("Payment method added", {
                yearWeek: this.$store.state.yearWeek
              });
            }
            this.$emit("refresh-cards");
          }
          this.submitting = false;
        },
        failure => {
          this.submitting = false;
        }
      );
    }
  }
};
</script>

<style>
.stripe-card {
  width: 300px;
  padding: 3px;
  display: inline-block;
  border: 1px solid transparent;
}
.stripe-card.complete {
  border-color: #0e9f6e;
  border-radius: 3px;
}

.payment-form {
  display: flex;
}
</style>
