<template>
  <div v-observe-visibility="{ callback: fetchContent, once: true }">
    <multi-content-preview-card v-if="content" :content="content" :property="property" :compact="true" />
  </div>
</template>

<script>
import api from "../../api";

export default {
  props: ["contentId", "contentType", "property"],
  data() {
    return {
      content: null
    };
  },
  beforeCreate() {
    this.$options.components.MultiContentPreviewCard = require("../cards/multi-content-preview-card.vue").default;
  },
  methods: {
    fetchContent() {
      switch (this.contentType) {
        case "Deal":
          api.get(`open_deal/${this.contentId}`).then(json => {
            this.content = json.data;
          });
          break;
        case "MarketIntelPost":
          api.get(`open_intel/${this.contentId}`).then(json => {
            this.content = json.data;
          });
          break;
        default:
          api.get(`followed_property/${this.property.id}`).then(json => {
            this.content = json.data;
          });
      }
    }
  }
};
</script>
