<template>
  <div>
    <contact-slideover-form v-if="editingSlideoverContact" :contact="contact" />
    <contact-slideover-details v-else :contact="contact" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContactSlideoverDetails from "./contact-slideover-details.vue";
import ContactSlideoverForm from "./contact-slideover-form.vue";

export default {
  components: { ContactSlideoverDetails, ContactSlideoverForm },
  props: ["involvedCompany", "contact", "editAtStartup"],
  computed: {
    ...mapState(["editingSlideoverContact"])
  },
  mounted() {
    if (this.editAtStartup) {
      this.$store.commit("setEditingSlideoverContact", true);
    }
  }
};
</script>
