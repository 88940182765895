<template>
  <main class="relative z-0 focus:outline-none" tabindex="0">
    <!-- Page title & actions -->
    <div class="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
      <div class="flex-1 min-w-0">
        <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
          My Premium Insights Dashboard
        </h1>
      </div>
      <div class="mt-4 flex sm:mt-0 sm:ml-4">
        <span class="order-0 sm:order-1 sm:ml-3 shadow-sm rounded-md">
          <button @click="newPurchaseable" type="button" v-tooltip="'Produce premium content that can be sold on Tower Hunt.'" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
            Create
          </button>
        </span>
      </div>
    </div>
    <pinned-content />
    <purchaseable-intents-table v-if="isAdmin" />
    <catalog-table />
    <licensed-table />
  </main>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CatalogTable from "./catalog-table";
import LicensedTable from "./licensed-table";
import PinnedContent from "./pinned-content";
import PurchaseableForm from "../purchaseable-form";
import PurchaseableIntentsTable from "./purchaseable-intents-table";

export default {
  components: {
    CatalogTable,
    LicensedTable,
    PinnedContent,
    PurchaseableIntentsTable
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState(["route"])
  },
  methods: {
    newPurchaseable() {
      this.$store.commit("openModal", {
        component: PurchaseableForm,
        props: {}
      });
    }
  }
};
</script>
