<template>
  <div v-if="signedIn && unmasked" class="absolute bottom-4 right-4 flex flex-col">
    <button v-if="canDismiss" @click="dismiss" type="button" v-tooltip="'Dismiss'" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
      <svg class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
      </svg>
    </button>
    <button v-if="canSave" @click="togglePin" type="button" v-tooltip="pinHelp" class="inline-flex items-center mt-2 px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
      <svg v-if="content.pinned" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
        <path v-if="isBounty" fill-rule="evenodd" d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" clip-rule="evenodd" />
        <path v-else d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
      </svg>
      <svg v-else class="h-4 w-4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
        <path v-if="isBounty" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
        <path v-else d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
      </svg>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
/* global L */

export default {
  props: ["content", "contentType"],
  computed: {
    ...mapState(["route", "adminMode"]),
    ...mapGetters(["signedIn"]),
    isBounty() {
      return (
        this.contentType === "intel" &&
        _.get(this.content, "bounty.standalone", false)
      );
    },
    unmasked() {
      switch (this.contentType) {
        case "deal":
          return (
            !this.content.confidential ||
            this.content.dealCloser ||
            (this.content.source === "indirect" && this.content.authored)
          );
        case "intel":
        default:
          return true;
      }
    },
    pinHelp() {
      if (this.content.pinned) {
        return this.isBounty ? "Following" : "Thanked";
      } else {
        return this.isBounty
          ? "Get notified if this bounty gets fulfilled and the content is available to buy."
          : "Anonymously show appreciation if this helped you. Also bookmarks the content for you.";
      }
    },
    canDismiss() {
      if (_.includes(this.route.path, "backchannel")) {
        return false;
      } else if (_.get(this.content, "bounty.myBid", null)) {
        return false;
      } else {
        switch (this.contentType) {
          case "deal":
            return (this.route.path === "/newsfeed" || this.content.state !== "closed") && !this.content.authored;
          case "intel":
            return !this.content.authored;
          default:
            return false;
        }
      }
    },
    isMobile() {
      return L.Browser.mobile;
    },
    canSave() {
      if (!this.isMobile || this.route.path === "/help-requests/my-bounties") {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    togglePin() {
      if (this.content.pinned) {
        this.unpin();
      } else {
        this.pin();
      }
    },
    dismiss() {
      switch (this.contentType) {
        case "deal":
          this.$store.dispatch("declineDeal", {
            dealToken: this.content.token,
            dealId: this.content.id
          });
          break;
        case "intel":
          this.$store.dispatch("dismissIntel", this.content.token);
          break;
        default:
          return;
      }
    },
    pin() {
      switch (this.contentType) {
        case "deal":
          this.$store.dispatch("pinDeal", this.content.token);

          if (!!analytics && !this.adminMode) {
            analytics.track("Upvoted deal", {
              yearWeek: this.$store.state.yearWeek
            });
          }
          break;
        case "intel":
          this.$store.dispatch("pinIntel", this.content.token);

          if (!!analytics && !this.adminMode) {
            analytics.track("Upvoted intel", {
              yearWeek: this.$store.state.yearWeek
            });
          }
          break;
        default:
          return;
      }

      this.content.pinned = !this.content.pinned;
      this.content.pinCount++;
    },
    unpin() {
      switch (this.contentType) {
        case "deal":
          this.$store.dispatch("unpinDeal", this.content.token);
          break;
        case "intel":
          this.$store.dispatch("unpinIntel", this.content.token);
          break;
        default:
          return;
      }

      this.content.pinned = !this.content.pinned;
      this.content.pinCount--;
    }
  }
};
</script>
