import MultiContentDeduplicateWrapper from "./components/maps/multi-content-deduplicate-wrapper";
import Vue from "vue";
/* global L */

const MapDeduplicateWrapper = Vue.extend(MultiContentDeduplicateWrapper);

export default function responsiveDeduplicatePopup({
  router,
  store,
  contentId,
  contentType
}) {
  const popupVueEl = new MapDeduplicateWrapper({
    store,
    router,
    propsData: { contentId, contentType }
  }).$mount().$el;

  return L.responsivePopup().setContent(popupVueEl);
}
