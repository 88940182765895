<template>
  <div>
    <!--
      Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
      Read the documentation to get started: https://tailwindui.com/documentation
    -->
    <div class="bg-yellow-50 my-4 border-l-4 border-yellow-400 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <!-- Heroicon name: exclamation -->
          <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm leading-5 text-yellow-700">
            You are anonymous throughout this process. Please be friendly and helpful.
            <router-link to="/product/bounty-bids" target="_blank" class="font-medium underline text-yellow-700 hover:text-yellow-600">
              Learn more &rarr;
            </router-link>
          </p>
        </div>
      </div>
    </div>

    <infinite-loading
      v-if="loaded && !endOfFeed"
      direction="top"
      :identifier="bid.id"
      @infinite="incrementBidChat"
    />

    <div class="flex flex-col-reverse">
      <bounty-chat-message
        v-for="(message, index) in bidChat.data"
        :key="index"
        :comment="message"
        background-color="gray-100"
        @deleted="deleted"
        class="my-2"
      />
    </div>

    <div id="chat-bottom"></div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapState } from "vuex";
import BountyChatMessage from "./bounty-chat-message";
import InfiniteLoading from "vue-infinite-loading";
import api from "../../api";

var poll;

export default {
  components: { BountyChatMessage, InfiniteLoading },
  props: ["bid"],
  data() {
    return {
      busy: false,
      endOfFeed: false,
      loaded: false,
      bidChat: {
        data: [],
        pagy: null
      }
    };
  },
  computed: {
    ...mapState(["currentUser", "refetchBidChat", "adminMode"]),
    newestMessage() {
      return _.head(this.bidChat.data);
    },
    pagination() {
      return this.bidChat.pagy;
    }
  },
  watch: {
    refetchBidChat: {
      handler() {
        if (this.refetchBidChat === this.bid.id) {
          this.endOfFeed = false;
          this.loaded = false;
          this.loadBidChat();
        }
      }
    }
  },
  created() {
    poll = setInterval(() => {
      this.unshiftBidChat();
    }, 15000);
  },
  mounted() {
    this.loadBidChat();
  },
  destroyed() {
    clearInterval(poll);
  },
  methods: {
    loadBidChat(scroll = true) {
      api.get(`bid_messages/${this.bid.id}`).then(
        json => {
          this.bidChat = json.data;
          this.$store.commit("bidChatRefetched");
          if (this.bidChat.data.length === 0) {
            this.endOfFeed = true;
          }
          if (scroll) {
            setTimeout(() => {
              this.$scrollTo("#chat-bottom", 100, { container: "#slideover-body-container" });
            }, 250);
          }
          setTimeout(() => {
            this.loaded = true;
          }, 5000);
        },
        failure => {
          this.$store.commit("closeSlideover");
          this.$store.commit("closeModal");
          this.$store.dispatch("flash", "Access denied");
          this.$router.push({ path: "/" });
        }
      );
    },
    extendBidChat({ data, pagy }) {
      this.bidChat.data = this.bidChat.data.concat(data);
      this.bidChat.pagy = pagy;
    },
    unshiftBidChat() {
      if (this.newestMessage) {
        const sinceTime = moment(this.newestMessage.sortDate).add(2, 's').valueOf();

        api.get(`bid_messages/${this.bid.id}?since=${sinceTime}`).then(json => {
          const newPosts = json.data.data;

          this.bidChat.data = newPosts.concat(this.bidChat.data);
        });
      }
    },
    deleted(commentId) {
      this.bidChat.data = this.bidChat.data.filter(message => {
        if (message.id) {
          return message.id !== commentId;
        } else {
          return true;
        }
      });
    },
    incrementBidChat($state) {
      if (this.pagination) {
        const { count, to } = this.pagination;

        if (count === 0) {
          this.endOfFeed = true;
        } else if (to < count) {
          const cleanUrl = _.drop(this.pagination.next_url, 8).join("");

          this.busy = true;

          api.get(cleanUrl).then(json => {
            this.extendBidChat(json.data);
            $state.loaded();
            this.busy = false;
          });
        } else {
          this.endOfFeed = true;
          $state.complete();
        }
      }
    }
  }
};
</script>
