<template>
  <div ref="notificationsMenu" class="ml-3 relative" style="z-index: 9999;">
    <button
      v-tooltip="'Notifications'"
      class="p-1 inline-block relative rounded-full focus:outline-none focus:ring"
      :class="hoverColor"
      @click="toggleMenu"
    >
      <svg
        class="h-6 w-6"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
        />
      </svg>
      <span v-if="hasUnreads" class="absolute top-0 right-0 block h-2 w-2 rounded-full text-white shadow-solid bg-red-400"></span>
      <span v-else-if="creditDelta > 0" class="absolute top-0 right-0 block h-2 w-2 rounded-full text-white shadow-solid bg-green-400"></span>
    </button>
    <transition
      enter-active-class="duration-150 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div
        v-show="menuOpen"
        class="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg max-h-screen overflow-auto"
      >
        <div class="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5">
          <div class="w-full p-4">
            <router-link @click.native="menuOpen = false" to="/home/reputation" class="flex justify-between text-sm font-medium text-gray-900 hover:text-gray-500">
              <span>Reputation</span>
              <div class="flex items-center">
                <span>{{ currentUser.creditsCount | formattedNumber }}</span>
                <div v-if="creditDelta > 0" class="ml-1 flex items-baseline text-sm leading-5 font-semibold text-green-600">
                  <svg class="self-center flex-shrink-0 h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <span class="sr-only">
                    Increased by
                  </span>
                  {{ creditDelta }}
                </div>
                <div v-else class="ml-1 flex items-baseline text-sm leading-5 font-semibold text-gray-500">
                  <svg class="self-center flex-shrink-0 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <span class="sr-only">
                    Unchanged
                  </span>
                  0
                </div>
              </div>
            </router-link>
          </div>

          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm leading-5 font-medium text-gray-900">
                  Invite colleagues and friends
                </p>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                  Earn $5 toward Premium Insights for every new user you recruit.
                </p>
                <div class="mt-2">
                  <button @click="batchImport" class="text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline">
                    Send invites
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="noTargets" class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm leading-5 font-medium text-gray-900">
                  Your profile has no markets
                </p>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                  Your markets personalize your newsfeed and show others where you focus. Without markets, no one can connect with you.
                </p>
                <div class="mt-2">
                  <button @click="newTarget" class="text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline">
                    Create a market
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!hasAvatar" class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"/>
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm leading-5 font-medium text-gray-900">
                  A photo of you drives more traffic to your profile
                </p>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                  Increase your click-through rate by adding a photo of you.
                </p>
                <div class="mt-2">
                  <button @click="settings" class="text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline">
                    Add photo
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!hasCompanies" class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm leading-5 font-medium text-gray-900">
                  Connect with industry alumni
                </p>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                  Increase your visibility by adding current and historical companies.
                </p>
                <div class="mt-2">
                  <button @click="settings" class="text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline">
                    Add companies
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="targetsNeedNames" class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm leading-5 font-medium text-gray-900">
                  Name your markets
                </p>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                  Customize the names of your markets to make your focus clearer to other people. For example, "NYC Capital Markets $10M+".
                </p>
                <div class="mt-2">
                  <button @click="viewNamelessMarket" class="text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline">
                    Edit an unnamed market
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!hasSchools" class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"/>
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm leading-5 font-medium text-gray-900">
                  Connect with academic alumni
                </p>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                  Add university connections to increase your visibility.
                </p>
                <div class="mt-2">
                  <button @click="settings('schools')" class="text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline">
                    Add schools
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="currentUser.dealCount === 0" class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"/>
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm leading-5 font-medium text-gray-900">
                  Drive more traffic to your profile
                </p>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                  Improve your market rankings by adding and verifying your closed deals. Top-ranked people make more connections.
                </p>
                <div class="mt-2">
                  <button @click="newDeal" class="text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline">
                    Add closed deal
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import EmailBatchUpload from "../views/offered-deal/email-batch-upload";

export default {
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    ...mapState(["currentUser", "route"]),
    ...mapGetters(["contactTokenParam", "userIsActiveContact"]),
    hasUnreads() {
      return (
        this.noTargets ||
        !this.hasAvatar ||
        !this.hasCompanies ||
        !this.hasSchools ||
        this.targetsNeedNames ||
        this.currentUser.dealCount === 0
      );
    },
    creditDelta() {
      return (
        this.currentUser.creditsCount - this.currentUser.creditsCountSnapshot
      );
    },
    isMarketingList() {
      return _.includes(this.route.path, "deal_offerings");
    },
    isPurchaseables() {
      return _.includes(this.route.path, "/market/");
    },
    isPricing() {
      return this.route.path === "/pricing";
    },
    targets() {
      return this.$store.getters.contactTargets(null);
    },
    noTargets() {
      return this.userIsActiveContact && this.targets.length === 0;
    },
    hasAvatar() {
      return this.currentUser.avatar;
    },
    hasCompanies() {
      return (
        this.currentUser.company &&
        this.currentUser.historicalCompanies.length > 0
      );
    },
    hasSchools() {
      return this.currentUser.schools.length > 0;
    },
    targetsNeedNames() {
      return (
        this.userIsActiveContact && this.targets.some(target => !target.name)
      );
    },
    hoverColor() {
      if (this.isMarketingList || this.isPurchaseables || this.isPricing) {
        return "text-indigo-200 hover:bg-indigo-600 hover:text-white focus:text-white";
      } else if (this.contactTokenParam || this.route.path === "/legal") {
        return "text-gray-400 hover:text-white focus:bg-gray-700";
      } else {
        return "text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:text-gray-500";
      }
    }
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.notificationsMenu &&
        !this.$refs.notificationsMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    newDeal() {
      this.$router.push({ path: "/deals/new/1" });
      this.menuOpen = false;
    },
    newTarget() {
      this.$router.push({ path: "/targets/new/1" });
      this.menuOpen = false;
    },
    viewNamelessMarket() {
      const firstUnnamed = this.targets.filter(target => !target.name)[0];

      this.$router.push({ path: `/open/${firstUnnamed.token}` });
      this.menuOpen = false;
    },
    settings(hash) {
      const id = hash ? `#${hash}` : "";

      this.$router.push({ path: `/home/settings${id}` });
      this.menuOpen = false;
    },
    batchImport() {
      this.$store.commit("openModal", {
        component: EmailBatchUpload,
        props: {
          action: "createTowerHuntInvitation"
        }
      });
      this.menuOpen = false;
    }
  }
};
</script>
