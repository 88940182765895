<template>
  <main class="max-w-lg mx-auto pt-10 pb-12 px-4 lg:pb-16">
    <form @submit.prevent>
      <div class="space-y-6">
        <div>
          <h1 class="text-lg leading-6 font-medium text-gray-900">
            Team Settings
          </h1>
          <p v-if="teamId" class="mt-1 text-sm text-gray-500">
            You can make changes to your team here.
          </p>
          <p v-else class="mt-1 text-sm text-gray-500">
            Let’s get started by filling in the information below to create your new team.
          </p>
        </div>

        <div>
          <label for="team_name" class="block text-sm font-medium text-gray-700">
            Team Name
          </label>
          <div class="mt-1">
            <input v-focus v-model="name" type="text" name="team_name" id="team_name" class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>

        <div class="space-y-2">
          <div class="space-y-1">
            <label for="add_team_members" class="flex justify-between text-sm font-medium text-gray-700">
              <div class="flex">
                <span>Add Team Members</span>
                <div class="flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" v-tooltip="'You can invite existing Tower Hunt contacts or bring on new users via email. Earn $5 toward Premium Insights for every new user you recruit.'" class="ml-1 h-5 w-5 text-gray-700" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
              <span v-if="rawEmailHelp" class="text-sm text-indigo-700" id="keyboard-help">Press ENTER to add</span>
            </label>
            <p id="add_team_members_helper" class="sr-only">Search by name or email address</p>
            <div class="flex">
              <div class="relative flex-grow focus-within:z-10">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <!-- Heroicon name: search -->
                  <svg class="h-5 w-5 text-gray-400" style="z-index: 1;" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg>
                </div>
                <autocomplete
                  id="add_team_members"
                  ref="addTeamMembers"
                  type="text"
                  base-class="custom-autocomplete-leading-icon-full-round"
                  :search="searchPeople"
                  :auto-select="true"
                  :get-result-value="getPeopleResultValue"
                  :debounce-time="500"
                  @submit="handlePersonSubmit"
                  @keydown="handleKeyDown"
                  placeholder="somebody or somebody@example.com"
                />
              </div>
            </div>
          </div>

          <div class="border-b border-gray-200">
            <ul v-if="membersLoaded" class="divide-y divide-gray-200">
              <li v-if="!teamId" class="py-4 flex">
                <div class="flex-shrink-0">
                  <avatar-photo :person="currentUser" circle-size="10" text-size="md" />
                </div>
                <div class="ml-3 flex flex-col">
                  <span class="text-sm font-medium text-gray-900">{{ currentUser.name }}</span>
                  <span class="text-sm text-gray-500">Primary owner<template v-if="currentUser.company">&nbsp;&middot; {{ currentUser.company }}</template></span>
                </div>
              </li>
              <li v-for="invitation in invitations" :key="invitation.token" class="relative py-4 flex items-center justify-between">
                <div class="flex items-center">
                  <div class="flex-shrink-0">
                    <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 text-white">
                      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                      </svg>
                    </span>
                  </div>
                  <div class="ml-3 flex flex-col">
                    <span class="text-sm font-medium text-gray-900">{{ invitation.email }}</span>
                    <span class="text-sm text-gray-500">Pending</span>
                  </div>
                </div>
                <team-member-menu :invitation="invitation" @refetch-invitations="fetchInvitations" />
              </li>
              <li v-for="member in members" :key="member.id" class="relative py-4 flex items-center justify-between">
                <div class="flex items-center">
                  <div class="flex-shrink-0">
                    <avatar-photo :person="member" circle-size="10" text-size="md" />
                  </div>
                  <div class="ml-3 flex flex-col">
                    <span class="text-sm font-medium text-gray-900">{{ member.name }}</span>
                    <span class="text-sm text-gray-500"><template v-if="member.teamRole">{{ member.teamRole }}</template><template v-else>Save team to invite</template><template v-if="member.company">&nbsp;&middot; {{ member.company }}</template></span>
                  </div>
                </div>
                <team-member-menu v-if="member.teamRole" :member="member" :role="role" @refetch-members="fetchMembers" />
                <button v-else @click="removePerson(member)" type="button" class="flex-shrink-0 ml-0.5 mr-2 h-8 w-8 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white">
                  <span class="sr-only">Remove person</span>
                  <svg class="h-3 w-3" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                    <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                  </svg>
                </button>
              </li>
            </ul>
            <div v-else class="flex justify-center">
              <grid-loader :loading="true" size="6px" color="#D4D4D8" />
            </div>
          </div>
        </div>

        <div class="space-y-2">
          <div class="space-y-1">
            <label for="add_default_markets" class="flex text-sm font-medium text-gray-700">
              <span>New User Default Markets</span>
              <div class="flex-shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" v-tooltip="'Give new Tower Hunt users copies of your markets that are relevant to this team. This makes it faster and easier for them to sign up.'" class="ml-1 h-5 w-5 text-gray-700" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
              </div>
            </label>
            <p id="add_default_markets_helper" class="sr-only">Search by name</p>
            <div class="flex">
              <div class="relative flex-grow focus-within:z-10">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <!-- Heroicon name: search -->
                  <svg class="h-5 w-5 text-gray-400" style="z-index: 1;" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg>
                </div>
                <autocomplete
                  id="add_default_markets"
                  ref="addDefaultMarkets"
                  type="text"
                  base-class="custom-autocomplete-leading-icon-full-round"
                  :search="searchMarkets"
                  :auto-select="true"
                  :get-result-value="getMarketResultValue"
                  :debounce-time="500"
                  @submit="handleMarketSubmit"
                  placeholder="Market name or location"
                />
              </div>
            </div>
          </div>

          <div class="border-b border-gray-200">
            <ul v-if="marketsLoaded && defaultMarkets.length > 0" class="divide-y divide-gray-200">
              <li v-for="market in defaultMarkets" :key="market.token" class="py-4 flex items-center justify-between">
                <div class="flex items-center">
                  <div class="flex-shrink-0">
                    <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-50 text-gray-700">
                      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </span>
                  </div>
                  <div class="ml-3 flex flex-col">
                    <span class="text-sm font-medium text-gray-900">{{ market | marketName }}</span>
                    <a href="" @click.prevent="view(market)" class="text-sm text-gray-500 truncate hover:text-gray-400 hover:underline">
                      Details
                    </a>
                  </div>
                </div>
                <button @click="removeMarket(market)" type="button" class="flex-shrink-0 ml-0.5 mr-2 h-8 w-8 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white">
                  <span class="sr-only">Remove market</span>
                  <svg class="h-3 w-3" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                    <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                  </svg>
                </button>
              </li>
            </ul>
            <div v-else-if="!marketsLoaded" class="flex justify-center">
              <grid-loader :loading="true" size="6px" color="#D4D4D8" />
            </div>
          </div>
        </div>

        <div class="space-y-2">
          <div class="space-y-1">
            <label for="add_default_watchlist_properties" class="flex text-sm font-medium text-gray-700">
              <span>New User Default Watchlist Properties</span>
              <div class="flex-shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" v-tooltip="'Grant new Tower Hunt users access to your watchlist properties that are relevant to this team.'" class="ml-1 h-5 w-5 text-gray-700" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
              </div>
            </label>
            <p id="add_default_markets_helper" class="sr-only">Search by name</p>
            <div class="flex">
              <div class="relative flex-grow focus-within:z-10">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <!-- Heroicon name: search -->
                  <svg class="h-5 w-5 text-gray-400" style="z-index: 1;" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg>
                </div>
                <autocomplete
                  id="add_default_properties"
                  ref="addDefaultProperties"
                  type="text"
                  base-class="custom-autocomplete-leading-icon-full-round"
                  :search="searchPropertyFollowings"
                  :auto-select="true"
                  :get-result-value="getPropertyFollowingResultValue"
                  :debounce-time="500"
                  @submit="handlePropertyFollowingSubmit"
                  placeholder="Property name or location"
                />
              </div>
            </div>
          </div>

          <div class="border-b border-gray-200">
            <ul v-if="propertiesLoaded && defaultPropertyFollowings.length > 0" class="divide-y divide-gray-200">
              <li v-for="propertyFollowing in defaultPropertyFollowings" :key="propertyFollowing.id" class="py-4 flex items-center justify-between">
                <div class="flex items-center">
                  <div class="flex-shrink-0">
                    <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-50 text-gray-700">
                      <template v-if="propertyFollowing.proximityType === 'radius'">
                        <svg v-tooltip="'Radius'" class="h-5 w-5" fill="currentColor" viewBox="0 0 512 512">
                          <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z" />
                        </svg>
                      </template>
                      <template v-else-if="propertyFollowing.proximityType === 'region'">
                        <svg v-tooltip="'Region'" class="h-5 w-5" fill="currentColor" viewBox="0 0 448 512">
                          <path fill="currentColor" d="M384 352c-.35 0-.67.1-1.02.1l-39.2-65.32c5.07-9.17 8.22-19.56 8.22-30.78s-3.14-21.61-8.22-30.78l39.2-65.32c.35.01.67.1 1.02.1 35.35 0 64-28.65 64-64s-28.65-64-64-64c-23.63 0-44.04 12.95-55.12 32H119.12C108.04 44.95 87.63 32 64 32 28.65 32 0 60.65 0 96c0 23.63 12.95 44.04 32 55.12v209.75C12.95 371.96 0 392.37 0 416c0 35.35 28.65 64 64 64 23.63 0 44.04-12.95 55.12-32h209.75c11.09 19.05 31.49 32 55.12 32 35.35 0 64-28.65 64-64 .01-35.35-28.64-64-63.99-64zm-288 8.88V151.12A63.825 63.825 0 0 0 119.12 128h208.36l-38.46 64.1c-.35-.01-.67-.1-1.02-.1-35.35 0-64 28.65-64 64s28.65 64 64 64c.35 0 .67-.1 1.02-.1l38.46 64.1H119.12A63.748 63.748 0 0 0 96 360.88zM272 256c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zM400 96c0 8.82-7.18 16-16 16s-16-7.18-16-16 7.18-16 16-16 16 7.18 16 16zM64 80c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zM48 416c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zm336 16c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16z" />
                        </svg>
                      </template>
                      <template v-else-if="propertyFollowing.proximityType === 'competitiveSet'">
                        <svg v-tooltip="'Competitive Set'" class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                          <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clip-rule="evenodd" />
                        </svg>
                      </template>
                    </span>
                  </div>
                  <div class="ml-3 flex flex-col">
                    <span class="text-sm font-medium text-gray-900">{{ propertyFollowing.customName || propertyFollowing.propertyName }}</span>
                    <span class="text-sm text-gray-500">{{ propertyFollowing.cityState }}</span>
                  </div>
                </div>
                <button @click="removeProperty(propertyFollowing)" type="button" class="flex-shrink-0 ml-0.5 mr-2 h-8 w-8 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white">
                  <span class="sr-only">Remove property</span>
                  <svg class="h-3 w-3" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                    <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                  </svg>
                </button>
              </li>
            </ul>
            <div v-else-if="!propertiesLoaded" class="flex justify-center">
              <grid-loader :loading="true" size="6px" color="#D4D4D8" />
            </div>
          </div>
        </div>

        <div class="flex justify-end">
          <button @click="cancel" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Cancel
          </button>
          <button v-if="teamId" @click="update" type="button" :disabled="!complete" class="ml-3 inline-flex justify-center py-2 px-4 rounded-md shadow-sm text-sm font-medium focus:ring-2 focus:ring-offset-2" :class="buttonStyling">
            Update team
          </button>
          <button v-else @click="save" type="button" :disabled="!complete" class="ml-3 inline-flex justify-center py-2 px-4 rounded-md shadow-sm text-sm font-medium focus:ring-2 focus:ring-offset-2" :class="buttonStyling">
            Create team
          </button>
        </div>
      </div>
    </form>
  </main>
</template>

<script>
import { mapState } from "vuex";
import AvatarPhoto from "./avatar-photo";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import Market from "./cards/market";
import TeamMemberMenu from "./team-member-menu";
import api from "../api";
/* global analytics */

export default {
  components: { AvatarPhoto, GridLoader, TeamMemberMenu },
  props: ["teamId"],
  data() {
    return {
      teamLoaded: false,
      rawEmailHelp: false,
      invitationsLoaded: false,
      membersLoaded: false,
      marketsLoaded: false,
      propertiesLoaded: false,
      name: null,
      role: null,
      invitations: [],
      members: [],
      defaultMarkets: [],
      defaultPropertyFollowings: []
    };
  },
  computed: {
    ...mapState(["temporaryAccess", "currentUser", "emailRegex", "modal"]),
    complete() {
      return this.name && _.trim(this.name) !== "";
    },
    buttonStyling() {
      if (this.complete) {
        return "inline-flex justify-center border-transparent text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700";
      } else {
        return "border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-indigo-500 cursor-not-allowed";
      }
    },
    persistPayload() {
      if (this.complete) {
        return {
          name: this.name,
          members: this.members.filter(person => !person.memberId),
          defaultMarkets: this.defaultMarkets.map(m => {
            return { id: m.id };
          }),
          defaultPropertyFollowings: this.defaultPropertyFollowings.map(pf => {
            return { id: pf.id };
          })
        };
      } else {
        return {};
      }
    }
  },
  watch: {
    modal: {
      handler() {
        if (!this.modal) {
          this.$store.commit("clearTarget");
        }
      }
    }
  },
  mounted() {
    if (this.teamId) {
      this.fetchTeam();
      this.fetchInvitations();
      this.fetchMembers();
      this.fetchMarkets();
      this.fetchPropertyFollowings();
    } else {
      this.teamLoaded = this.invitationsLoaded = this.membersLoaded = this.marketsLoaded = this.propertiesLoaded = true;
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    fetchTeam() {
      api.get(`teams/${this.teamId}`).then(json => {
        const team = json.data;

        this.name = team.name;
        this.role = team.role;
        this.teamLoaded = true;
      });
    },
    fetchInvitations() {
      api.get(`teams/${this.teamId}/team_invitations`).then(json => {
        const invitations = json.data;

        this.invitations = invitations;
        this.invitationsLoaded = true;
      });
    },
    fetchMembers() {
      api.get(`teams/${this.teamId}/team_memberships`).then(json => {
        const members = json.data;

        this.members = members;
        this.membersLoaded = true;
      });
    },
    fetchMarkets() {
      api.get(`teams/${this.teamId}/team_default_markets`).then(json => {
        const defaultMarkets = json.data;

        this.defaultMarkets = defaultMarkets;
        this.marketsLoaded = true;
      });
    },
    fetchPropertyFollowings() {
      api
        .get(`teams/${this.teamId}/team_default_property_followings`)
        .then(json => {
          const defaultPropertyFollowings = json.data;

          this.defaultPropertyFollowings = defaultPropertyFollowings;
          this.propertiesLoaded = true;
        });
    },
    searchPeople(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        const query = input === "" || input === null ? null : _.toLower(input);

        api
          .post(`search_contacts?output=compact&in_network=true`, {
            name: query
          })
          .then(
            json => {
              resolve(json.data);
            },
            failure => {
              this.$store.dispatch("flash", "Invalid search");
            }
          );
      });
    },
    getPeopleResultValue(result) {
      return result.name;
    },
    handleKeyDown(e) {
      const validAddition = this.emailRegex.test(
        this.$refs.addTeamMembers.value
      );

      if (validAddition && this.$refs.addTeamMembers.results.length === 0) {
        this.rawEmailHelp = true;
      } else {
        this.rawEmailHelp = false;
      }
    },
    handlePersonSubmit(person) {
      const output = person
        ? person
        : {
            name: this.$refs.addTeamMembers.value,
            email: this.$refs.addTeamMembers.value,
            avatar: null,
            id: Date.now()
          };
      const validAddition =
        person || this.emailRegex.test(this.$refs.addTeamMembers.value);

      if (validAddition) {
        const newContacts = _.unionBy([output], this.members, "name");

        this.members = newContacts;
      }

      this.$refs.addTeamMembers.value = "";
      this.$store.dispatch("flash", "New member added. Save the team to send invitations.");
      document.getElementById("add_team_members").focus();
    },
    removePerson(person) {
      const newContacts = this.members.filter(c => c.name !== person.name);

      this.members = newContacts;
      document.getElementById("add_team_members").focus();
    },
    searchMarkets(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        const query = input === "" || input === null ? null : _.toLower(input);

        api
          .post(`search_markets`, {
            name: query
          })
          .then(
            json => {
              resolve(json.data);
            },
            failure => {
              this.$store.dispatch("flash", "Invalid search");
            }
          );
      });
    },
    getMarketResultValue(result) {
      return this.$options.filters.marketName(result);
    },
    handleMarketSubmit(market) {
      if (market) {
        const newMarkets = _.unionBy(this.defaultMarkets, [market], "token");

        this.defaultMarkets = newMarkets;
        this.$refs.addDefaultMarkets.value = "";
        document.getElementById("add_default_markets").focus();
      }
    },
    removeMarket(market) {
      if (market.defaultMarketId) {
        api.delete(
          `teams/${this.teamId}/team_default_markets/${market.defaultMarketId}`
        );
      }

      const newMarkets = this.defaultMarkets.filter(
        m => m.token !== market.token
      );

      this.defaultMarkets = newMarkets;
      document.getElementById("add_default_markets").focus();
    },
    view(market) {
      this.$store.commit("openModal", {
        component: Market,
        props: {
          target: market,
          explorable: false
        }
      });
    },
    searchPropertyFollowings(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        const query = input === "" || input === null ? null : _.toLower(input);

        api
          .post(`search_property_followings`, {
            name: query
          })
          .then(
            json => {
              resolve(json.data);
            },
            failure => {
              this.$store.dispatch("flash", "Invalid search");
            }
          );
      });
    },
    getPropertyFollowingResultValue(result) {
      return `${result.propertyName} (${result.cityState})`;
    },
    handlePropertyFollowingSubmit(propertyFollowing) {
      if (propertyFollowing) {
        const newPropertyFollowings = _.unionBy(
          this.defaultPropertyFollowings,
          [propertyFollowing],
          "id"
        );

        this.defaultPropertyFollowings = newPropertyFollowings;
        this.$refs.addDefaultProperties.value = "";
        document.getElementById("add_default_properties").focus();
      }
    },
    removeProperty(property) {
      if (property.defaultPropertyFollowingId) {
        api.delete(
          `teams/${this.teamId}/team_default_property_followings/${property.defaultPropertyFollowingId}`
        );
      }

      const newPropertyFollowings = this.defaultPropertyFollowings.filter(
        pf => pf.id !== property.id
      );

      this.defaultPropertyFollowings = newPropertyFollowings;
      document.getElementById("add_default_properties").focus();
    },
    async update() {
      if (this.complete) {
        const teamPayload = { name: this.name };
        const membersPayload = {
          members: this.members.filter(person => !person.memberId)
        };
        const marketsPayload = {
          defaultMarkets: this.defaultMarkets.map(m => {
            return { id: m.id };
          })
        };
        const propertiesPayload = {
          defaultPropertyFollowings: this.defaultPropertyFollowings.map(pf => {
            return { id: pf.id };
          })
        };

        await Promise.all([
          api.patch(`teams/${this.teamId}`, teamPayload),
          api.post(`teams/${this.teamId}/team_memberships`, membersPayload),
          api.post(`teams/${this.teamId}/team_default_markets`, marketsPayload),
          api.post(
            `teams/${this.teamId}/team_default_property_followings`,
            propertiesPayload
          )
        ]);

        this.$emit("saved");
      }
    },
    save() {
      if (this.complete) {
        api.post(`teams`, this.persistPayload).then(() => {
          if (!!analytics) {
            analytics.track("Team created", {
              yearWeek: this.$store.state.yearWeek
            });
          }

          this.$store.dispatch("loadBackchannels");
          this.$emit("saved");
        });
      }
    }
  }
};
</script>
