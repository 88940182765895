<template>
  <div class="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8">
    <main id="newsfeed-top">
      <div class="sm:mx-auto sm:w-full sm:max-w-xl md:max-w-2xl">
        <div class="grid grid-cols-1 gap-x-4 gap-y-4">
          <span class="sm:col-span-1 rounded-md shadow-sm">
            <button @click="newIntel" v-tooltip="'Add news, deals, and more. Filterable and mappable.'" type="button" v-observe-visibility="{ callback: alternateButtonHandler }" :class="privacyMode ? 'bg-pink-600 hover:bg-pink-500 focus:outline-none focus:border-pink-700 focus:ring-pink active:bg-pink-700' : 'bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700'" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white">
              <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
                <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
              </svg>
              <svg
                v-else
                class="-ml-1 mr-2 h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"></path>
              </svg>
              Add intel
            </button>
          </span>
        </div>

        <div class="flex items-center pt-5 space-x-2">
          <grid-loader v-if="fetchingUpdates" :loading="fetchingUpdates" size="5px" color="#5850ec" />
          <span v-else class="inline-flex rounded-md shadow-sm" v-tooltip="'Refresh'">
            <button @click="refreshNewsfeed" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-indigo-300 focus:ring-indigo active:text-gray-800 active:bg-gray-50">
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
              </svg>
            </button>
          </span>
          <div class="flex-grow border-t border-gray-200" />
          <span class="inline-flex rounded-md shadow-sm" v-tooltip="'View newsfeed in strategic map'">
            <router-link to="/map" class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md bg-white text-gray-700 hover:text-gray-500 focus:border-indigo-300 focus:ring-indigo active:text-gray-800 focus:outline-none active:bg-gray-50">
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clip-rule="evenodd" />
              </svg>
            </router-link>
          </span>
          <span class="inline-flex rounded-md shadow-sm" v-tooltip="hasNewsfeedFilters ? 'Filters applied' : 'Filter'">
            <button @click="filterNewsfeed" type="button" :class="hasNewsfeedFilters ? 'text-green-500 hover:text-green-300 focus:border-green-300 focus:ring-green active:text-green-600' : 'text-gray-700 hover:text-gray-500 focus:border-indigo-300 focus:ring-indigo active:text-gray-800'" class="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md bg-white focus:outline-none active:bg-gray-50">
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd" />
              </svg>
            </button>
          </span>
          <newsfeed-sort-menu />
        </div>
      </div>

      <div class="sm:mx-auto sm:w-full sm:max-w-xl md:max-w-2xl">
        <div v-if="!loaded && newsfeed.data.length === 0" class="w-full flex justify-center mt-4">
          <grid-loader :loading="!loaded && newsfeed.data.length === 0" size="8px" color="#5850ec" />
        </div>

        <hunter-pack v-if="hunterPack" :pack="hunterPack" class="mt-4 shadow rounded-lg overflow-hidden" />

        <multi-content-preview-card
          v-for="(post, index) in newsfeed.data"
          :key="`${post.content.token}-${index}`"
          :content="post.content"
          class="col-span-1 mt-4"
        />

        <div v-if="endOfFeed" class="col-span-1 mt-4 bg-white shadow sm:rounded-lg">
          <div key="end-of-feed" class="px-4 py-5 sm:p-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Refresh the newsfeed to fetch the latest info
            </h3>
            <div class="mt-2">
              <div class="max-w-xl text-sm leading-5 text-gray-500">
                <p>
                  There's nothing else to show you right now. Do you have anything to share?
                </p>
              </div>
              <div class="mt-5">
                <span class="inline-flex rounded-md shadow-sm">
                  <button @click="newIntel" type="button" :class="privacyMode ? 'bg-pink-600 hover:bg-pink-500 focus:outline-none focus:border-pink-700 focus:ring-pink active:bg-pink-700' : 'bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700'" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white">
                    <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
                      <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
                    </svg>
                    <svg
                      v-else
                      class="-ml-1 mr-2 h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"></path>
                    </svg>
                    Post intel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <infinite-loading
          v-else-if="loaded"
          :identifier="currentUser.token"
          @infinite="incrementNewsfeed"
        />

        <!-- Simple footer -->
        <marketing-footer padding-y="py-6" padding-x="p-6" compact="true" />
      </div>
    </main>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapState } from "vuex";
import BountyRequest from "../components/bounties/bounty-request";
import DealCard from "../components/cards/deal";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import HunterPack from "../components/cards/stacked/hunter-pack";
import InfiniteLoading from "vue-infinite-loading";
import IntelCard from "../components/cards/market-intel";
import MarketingFooter from "../components/marketing-footer";
import MultiContentPreviewCard from "../components/cards/multi-content-preview-card";
import NewsfeedFilterSlideover from "../components/newsfeed/filter-slideover";
import NewsfeedSortMenu from "../components/newsfeed-sort-menu";
import NewsfeedWelcome from "../components/cards/newsfeed-welcome";
import PurchaseableForm from "../components/purchaseables/purchaseable-form";
import api from "../api";

let nowUpdateInterval, newsfeedUpdateInterval;

export default {
  components: {
    HunterPack,
    InfiniteLoading,
    MarketingFooter,
    MultiContentPreviewCard,
    NewsfeedSortMenu,
    GridLoader
  },
  data() {
    return {
      busy: false,
      loaded: false,
      endOfFeed: false,
      fetchingUpdates: false,
      hunterPack: null,
      top: [],
      purchaseableLimit: 5
    };
  },
  computed: {
    ...mapState([
      "newsfeed",
      "newsfeedFilters",
      "now",
      "currentUser",
      "modal",
      "adminMode",
      "route"
    ]),
    ...mapGetters([
      "dealTokenParam",
      "intelTokenParam",
      "privacyMode",
      "hasNewsfeedFilters"
    ]),
    pagination() {
      return this.newsfeed.pagy;
    },
    shouldDisplayWelcome() {
      return false;
      // return (
      //   !this.dealTokenParam &&
      //   !this.intelTokenParam &&
      //   this.currentUser.newsfeedWelcome &&
      //   !this.modal
      // );
    },
    markets() {
      return this.$store.getters.contactTargets(null);
    }
  },
  watch: {
    currentUser: {
      handler: function() {
        this.displayWelcome();
      }
    },
    modal: {
      handler() {
        if (!this.modal) {
          this.$store.commit("clearTarget");
        }
      }
    },
    newsfeedFilters: {
      handler() {
        this.loaded = false;
        this.endOfFeed = false;
        this.$store.dispatch("loadNewsfeed").then(() => {
          if (this.newsfeed.data.length === 0) {
            this.endOfFeed = true;
          }

          this.loaded = true;
        });
      },
      deep: true
    }
  },
  created() {
    var self = this;

    nowUpdateInterval = setInterval(function() {
      self.$store.commit("setNow", moment());
    }, 5000);

    newsfeedUpdateInterval = setInterval(function() {
      self.updateNewsfeed();
    }, 3000);
  },
  mounted() {
    document.title = "Newsfeed | Tower Hunt";

    let self = this;

    this.displayWelcome();
    this.fetchHunterPack();

    this.$store.dispatch("loadNewsfeed").then(() => {
      if (self.dealTokenParam) {
        this.$store.commit("openModal", {
          component: DealCard,
          props: {
            dealToken: self.dealTokenParam
          }
        });

        if (!!analytics && !this.adminMode) {
          analytics.track("Opened deal link", {
            yearWeek: this.$store.state.yearWeek
          });
        }
      } else if (self.intelTokenParam) {
        this.$store.commit("openModal", {
          component: IntelCard,
          props: {
            postToken: self.intelTokenParam
          }
        });

        if (!!analytics && !this.adminMode) {
          analytics.track("Opened intel link", {
            yearWeek: this.$store.state.yearWeek
          });
        }
      }

      if (this.newsfeed.data.length === 0) {
        this.endOfFeed = true;
      }

      this.loaded = true;
    });

    if (this.route.query.filter) {
      this.filterNewsfeed();
    }
  },
  destroyed() {
    clearInterval(nowUpdateInterval);
    clearInterval(newsfeedUpdateInterval);
    this.$store.commit("clearNewsfeedFilters");
    this.$store.commit("setGlobalIntelAddButtonVisibility", false);
  },
  methods: {
    displayWelcome() {
      if (!this.adminMode && this.shouldDisplayWelcome) {
        this.$store.commit("openModal", {
          component: NewsfeedWelcome,
          props: {}
        });
      }
    },
    alternateButtonHandler(isVisible, entry) {
      if (isVisible) {
        this.$store.commit("setGlobalIntelAddButtonVisibility", false);
      } else {
        this.$store.commit("setGlobalIntelAddButtonVisibility", true);
      }
    },
    newPurchaseable() {
      this.$store.commit("openModal", {
        component: PurchaseableForm,
        props: {}
      });
    },
    fetchHunterPack() {
      api.get("latest_off_market_pack").then(json => {
        this.hunterPack = json.data;
      });
    },
    newActiveDeal() {
      this.$router.push({ path: "/deals/new/1", query: { active: true } });
    },
    newDeal() {
      this.$router.push({ path: "/deals/new/1" });
    },
    newIntel() {
      this.$router.push({
        path: "/intel/new/privacy",
        query: { nextUrl: "/intel/new/1" }
      });
    },
    requestHelp() {
      this.$store.commit("openModal", {
        component: BountyRequest,
        props: {}
      });
    },
    refreshNewsfeed() {
      this.$store.dispatch("triggerNewsfeedRefresh").then(() => {
        var self = this;

        newsfeedUpdateInterval = setInterval(function() {
          self.updateNewsfeed();
        }, 3000);
        this.updateNewsfeed();
      });
    },
    filterNewsfeed() {
      this.$store.commit("openSlideover", {
        component: NewsfeedFilterSlideover,
        props: {
          newsfeedFilter: true
        }
      });
    },
    updateNewsfeed() {
      this.$store.dispatch("updateNewsfeed").then(fetchAgain => {
        if (fetchAgain) {
          this.fetchingUpdates = true;
        } else {
          clearInterval(newsfeedUpdateInterval);
          this.fetchingUpdates = false;

          this.$store.dispatch("loadNewsfeed");
          this.$store.dispatch("loadCreditTracking");
        }
      });
    },
    incrementNewsfeed($state) {
      if (this.pagination) {
        const { count, to } = this.pagination;

        if (count === 0) {
          this.endOfFeed = true;
        } else if (to < count) {
          const cleanUrl = _.drop(this.pagination.next_url, 8).join("");

          this.busy = true;
          this.$store.dispatch("loadNewsfeedIncrement", cleanUrl).then(() => {
            $state.loaded();
            this.busy = false;

            if (!!analytics && !this.adminMode) {
              analytics.track("Incremented newsfeed", {
                yearWeek: this.$store.state.yearWeek
              });
            }
          });
        } else {
          this.endOfFeed = true;
          $state.complete();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-complete-item {
  transition: all 0.5s;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.list-complete-leave-active {
  position: absolute;
}
</style>
