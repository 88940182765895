<template>
  <div id="catalog-table">
    <!-- Catalog list (only on smallest breakpoint) -->
    <div class="mt-10 sm:hidden">
      <div class="px-4 sm:px-6">
        <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">My Licensed Content</h2>
      </div>
      <ul class="mt-3 border-t border-gray-200 divide-y divide-gray-100">
        <licensed-list-row v-for="purchaseable in purchaseables" :key="purchaseable.id" :purchaseable="purchaseable" />
      </ul>
    </div>
    <!-- Catalog table (small breakpoint and up) -->
    <div class="hidden mt-8 sm:block">
      <div class="align-middle inline-block min-w-full border-b border-gray-200">
        <table class="min-w-full">
          <thead>
            <tr class="border-t border-gray-200">
              <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                <span class="lg:pl-2">My Licensed Content</span>
              </th>
              <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Price
              </th>
              <th class="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 whitespace-nowrap text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Acquired on
              </th>
              <th class="pr-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-100">
            <licensed-table-row v-for="purchaseable in purchaseables" :key="purchaseable.id" :purchaseable="purchaseable" />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LicensedListRow from "./licensed-list-row";
import LicensedTableRow from "./licensed-table-row";
import api from "../../../api";

export default {
  components: { LicensedListRow, LicensedTableRow },
  props: [],
  data() {
    return {
      purchaseables: []
    };
  },
  computed: {
    ...mapState(["refetchPurchaseables"]),
  },
  watch: {
    refetchPurchaseables: {
      handler() {
        if (this.refetchPurchaseables) {
          this.fetchPurchaseables();
        }
      }
    }
  },
  mounted() {
    this.fetchPurchaseables();
  },
  methods: {
    fetchPurchaseables() {
      api.get(`purchaseables?licensed=true`).then(json => {
        this.purchaseables = json.data;
        this.$store.commit("setRefetchPurchaseables", false);
      });
    }
  }
};
</script>
