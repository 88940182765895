<template>
  <div class="container mx-auto md:mt-12 mb-4 px-4 sm:px-8 lg:px-10 flex flex-col items-center">
    <div class="flex justify-center md:mb-2">
      <div class="flex items-center font-medium text-gray-300">
        <span>{{ document.name }}</span>
      </div>
    </div>

    <div :style="fileHeight" class="mt-4 mb-2 w-full flex flex-col flex-1">
      <div class="w-full flex justify-center space-x-4">
        <a @click="incrementCount('download')" :href="document.file || document.link" target="_blank" class="inline-flex items-center justify-center font-medium text-sm text-gray-300">
          <!-- Heroicon name: download -->
          <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd" />
          </svg>
          <span class="ml-2">Download</span>
        </a>
      </div>
      <div class="iframely-embed flex-1 mt-2 bg-gray-50">
        <a data-iframely-url :href="document.file || document.link"></a>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api";
/* global iframely */

export default {
  props: ["document"],
  data() {
    return {
      modalHeight: null
    };
  },
  computed: {
    fileHeight() {
      if (this.modalHeight) {
        const heightRatio = this.modalHeight > 400 ? 0.8 : 0.6;

        return `height: ${this.modalHeight * heightRatio}px;`;
      } else {
        return "height: 200px";
      }
    }
  },
  mounted() {
    this.modalHeight = document.getElementById("xl-modal").offsetHeight;

    this.debouncedHeightUpdate = _.debounce(() => {
      this.modalHeight = document.getElementById("xl-modal").offsetHeight;
    }, 100);

    window.addEventListener("resize", this.debouncedHeightUpdate);

    this.incrementCount("view", true);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedHeightUpdate);
  },
  methods: {
    incrementCount(countType, loadFrame = false) {
      if (this.document.hostName) {
        if (loadFrame) {
          this.loadFrame();
        }
      } else {
        api
          .post(`deal_marketing_document_engagement/${this.document.id}?${countType}=true`)
          .then(json => {
            if (loadFrame) {
              this.loadFrame();
            }
          });
      }
    },
    loadFrame() {
      if (window.iframely) {
        setTimeout(() => {
          iframely.load();
        }, 500);

        var self = this;

        iframely.on("cancel", function(url, parentNode) {
          parentNode.remove();
          self.$store.dispatch(
            "flash",
            "File is temporarily unavailable. Please download or try again shortly."
          );
        });
      }
    }
  }
};
</script>
