/* global L */

export default function customPulseIcon() {
  L.Icon.Pulse = L.DivIcon.extend({
    options: {
      className: "",
      iconSize: [12, 12],
      fillColor: "red",
      borderColor: null,
      color: "red",
      animate: true,
      heartbeat: 1,
      innerIcon: true,
      innerIconContentCode: "f57d",
      innerIconColor: "#3F3F46",
      innerIconSize: "0.75rem"
    },

    initialize: function(options) {
      L.setOptions(this, options);

      // css

      var uniqueClassName =
        "lpi-" +
        new Date().getTime() +
        "-" +
        Math.round(Math.random() * 100000);

      var before = [
        "background-color: " + this.options.fillColor,
        "color: " + this.options.innerIconColor,
        "font-size: " + this.options.innerIconSize,
        "display: flex",
        "align-items: center",
        "justify-content: center"
      ];
      var pseudoBefore = [
        "font-family: 'Font Awesome 5 Free'",
        "font-weight: 900",
        "content: '\\" + this.options.innerIconContentCode + "'"
      ];
      var after = [
        "box-shadow: 0 0 6px 2px " + this.options.color,

        "animation: pulsate " + this.options.heartbeat + "s ease-out",
        "animation-iteration-count: infinite",
        "animation-delay: " + (this.options.heartbeat + 0.1) + "s"
      ];

      if (!this.options.animate) {
        after.push("animation: none");
        after.push("box-shadow:none");
      }

      if (this.options.borderColor) {
        before.push("border-width: 3px");
        before.push("border-radius: .25rem");
        before.push(`border-color: ${this.options.borderColor}`);
      }

      let css;

      if (
        this.options.innerIcon &&
        this.options.innerIconContentCode !== "f57d"
      ) {
        css = [
          "." + uniqueClassName + "{" + before.join(";") + ";}",
          "." + uniqueClassName + ":before{" + pseudoBefore.join(";") + ";}",
          "." + uniqueClassName + ":after{" + after.join(";") + ";}"
        ].join("");
      } else {
        css = [
          "." + uniqueClassName + "{" + before.join(";") + ";}",
          "." + uniqueClassName + ":after{" + after.join(";") + ";}"
        ].join("");
      }

      var el = document.createElement("style");

      if (el.styleSheet) {
        el.styleSheet.cssText = css;
      } else {
        el.appendChild(document.createTextNode(css));
      }

      document.getElementsByTagName("head")[0].appendChild(el);

      // apply css class

      this.options.className =
        this.options.className + " leaflet-pulsing-icon " + uniqueClassName;

      // initialize icon

      L.DivIcon.prototype.initialize.call(this, options);
    }
  });

  L.icon.pulse = function(options) {
    return new L.Icon.Pulse(options);
  };

  L.Marker.Pulse = L.Marker.extend({
    initialize: function(latlng, options) {
      options.icon = L.icon.pulse(options);
      L.Marker.prototype.initialize.call(this, latlng, options);
    }
  });

  L.marker.pulse = function(latlng, options) {
    return new L.Marker.Pulse(latlng, options);
  };
}
