<template>
  <main>
    <div class="rounded-md bg-yellow-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm text-yellow-700">
            The information on this tab is accessible by anyone who can see this property.
          </p>
          <p class="mt-3 text-sm md:mt-0 md:ml-6">
            <a v-show="false" @click.prevent href="" class="whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600">Learn more <span aria-hidden="true">&rarr;</span></a>
          </p>
        </div>
      </div>
    </div>
    <div class="mt-4 lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
        <nav class="space-y-1">
          <a v-for="tab in tabs" @click.prevent="selectedTab = tab" :key="tab.name" :href="tab.href" :class="[selectedTab.name === tab.name ? 'bg-gray-50 text-indigo-600 hover:bg-white' : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50', 'group rounded-md px-3 py-2 flex items-center text-sm font-medium']" :aria-current="selectedTab.name === tab.name ? 'page' : undefined">
            <svg :class="[selectedTab.name === tab.name ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :d="tab.svgPath" />
            </svg>
            <span class="truncate">
              {{ tab.name }}
            </span>
          </a>
        </nav>
      </aside>

      <!-- Payment details -->
      <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <keep-alive>
          <component :is="selectedTab.component" v-bind="selectedTabProperties" />
        </keep-alive>
      </div>
    </div>
  </main>
</template>

<script>
import Appearance from "../property-forms/public/appearance.vue";
import Location from "../property-forms/public/location.vue";
import SizeUse from "../property-forms/public/size-use.vue";

export default {
  props: ["property"],
  data() {
    return {
      tabs: [
        {
          name: "Appearance",
          href: "",
          svgPath:
            "M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2",
          component: Appearance
        },
        {
          name: "Location",
          href: "",
          svgPath:
            "M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
          component: Location
        },
        {
          name: "Size & Use",
          href: "",
          svgPath:
            "M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z",
          component: SizeUse
        }
      ],
      selectedTab: {
        name: "Appearance",
        href: "",
        svgPath:
          "M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z",
        component: Appearance
      }
    };
  },
  computed: {
    selectedTabProperties() {
      switch (this.selectedTab.name) {
        case "Appearance":
        case "Location":
        case "Size & Use":
        default:
          return {};
      }
    }
  }
};
</script>
