<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div v-if="creatingNew" class="max-w-lg mx-auto bg-white">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-2xl leading-7 font-bold text-gray-900 text-center">
          Great! Let's finish up.
        </h3>
      </div>

      <div class="mt-4 flex flex-col items-center">
        <!-- FROM DEAL NAME -->
        <template v-if="creatingDirectActiveDeal">
          <button @click="postToMatches" type="button" class="cta complete" tabindex="999">Post to matching principals</button>
          <button @click="buildDealPage" type="button" class="secondary my-1" tabindex="999">Build my own marketing list</button>
        </template>

        <!-- FROM DEAL CLOSE DATE -->
        <template v-else-if="newRecordIsSafezone">
          <button @click="safezone" type="button" :class="privacyMode ? 'privacy-complete' : 'complete'" class="cta" tabindex="999">
            <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
              <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
            </svg>
            Save to safezone
          </button>
        </template>

        <template v-else>
          <template v-if="directDeal">
            <button @click="directPost(false)" type="button" class="cta complete" tabindex="999">Post deal to your profile</button>
            <button @click="directPost(true)" type="button" class="secondary my-1" tabindex="999">Post and set as confidential</button>
          </template>

          <template v-else>
            <button @click="confirmPrivacy" type="button" class="cta" :class="privacyMode ? 'privacy-complete' : 'complete'" tabindex="999">
              <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
                <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
              </svg>
              Confirm author and post
            </button>
          </template>
        </template>
      </div>
    </div>
    <div v-else class="max-w-lg mx-auto bg-white">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-2xl leading-7 font-bold text-gray-900 text-center">
          These deals seem similar
        </h3>
        <option-description class="mt-2" :description="helpText" />
      </div>
      <div class="px-4 py-5 sm:p-6">
        <template v-if="subjectDeal.type">
          <h2 class="mb-2 text-gray-500 text-xs font-medium uppercase tracking-wide">The deal you entered</h2>
          <label class="mb-6 relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 focus-within:ring-1 focus-within:ring-offset-2 focus-within:ring-indigo-500">
            <input type="radio" name="duplicate-deal" :disabled="true" class="sr-only" aria-labelledby="duplicate-deal-0-label" aria-describedby="duplicate-deal-0-description-0 duplicate-deal-0-description-1">
            <stacked-radio-deal-inner-details :deal="subjectDeal" />
            <div :class="selected ? 'border-transparent' : 'border-orange-500'" class="absolute -inset-px rounded-lg border-2 pointer-events-none" aria-hidden="true"></div>
          </label>
        </template>

        <template v-if="selected">
          <h2 class="mb-2 text-gray-500 text-xs font-medium uppercase tracking-wide">
            <template v-if="duplicateDeals.length === 1">Similar existing deal</template>
            <template v-else>Existing deal you selected</template>
          </h2>
          <label class="relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 focus-within:ring-1 focus-within:ring-offset-2 focus-within:ring-indigo-500">
            <input v-model="selected" type="radio" name="duplicate-deal" :value="selected" class="sr-only" aria-labelledby="duplicate-deal-0-label" aria-describedby="duplicate-deal-0-description-0 duplicate-deal-0-description-1">
            <stacked-radio-deal-inner-details :deal="selected" />
            <div :class="isSelected(selected) ? 'border-indigo-500' : 'border-transparent'" class="absolute -inset-px rounded-lg border-2 pointer-events-none" aria-hidden="true"></div>
          </label>
        </template>

        <template v-else>
          <h2 class="mb-2 text-gray-500 text-xs font-medium uppercase tracking-wide">Existing deals that could be your deal</h2>
          <fieldset>
            <legend class="sr-only">
              Potential duplicate deals
            </legend>
            <div class="space-y-4">
              <label v-for="deal in duplicateDeals" :key="deal.token" class="relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 focus-within:ring-1 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                <input v-model="selected" type="radio" name="duplicate-deal" :value="deal" class="sr-only" aria-labelledby="duplicate-deal-0-label" aria-describedby="duplicate-deal-0-description-0 duplicate-deal-0-description-1">
                <stacked-radio-deal-inner-details :deal="deal" />
                <div :class="isSelected(deal) ? 'border-indigo-500' : 'border-transparent'" class="absolute -inset-px rounded-lg border-2 pointer-events-none" aria-hidden="true"></div>
              </label>
            </div>
          </fieldset>
        </template>
      </div>
      <div class="px-4 py-5 sm:px-6 space-y-3 flex flex-col items-center">
        <template v-if="localDeals">
          <button v-if="selected" @click="mergeDeals" type="button" class="cta complete">
            Review merge with selected deal
          </button>
          <button v-if="selected && duplicateDeals.length > 1" @click="selected = null" type="button" class="secondary">
            Show me the similar deals again
          </button>
          <button @click="cancel" type="button" :class="!!selected ? 'secondary' : 'cta complete'" class="w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-base font-medium border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Cancel
          </button>
        </template>
        <template v-else>
          <button v-if="selected" @click="viewSelectedDeal" type="button" class="cta complete">
            Use the selected deal instead
          </button>
          <button v-if="selected && duplicateDeals.length > 1" @click="selected = null" type="button" class="secondary">
            Show me the similar deals again
          </button>
          <button @click="creatingNew = true" type="button" :class="!!selected ? 'secondary' : 'cta complete'" class="w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-base font-medium border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            The entered deal is unique
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapState } from "vuex";
import OptionDescription from "../../../components/onboarding/option-description.vue";
import StackedRadioDealInnerDetails from "../../../components/stacked-radio-deal-inner-details.vue";
import Swal from "sweetalert2";
import anonymousPostOptions from "../../../sweet-alert-always-anonymous-options";

export default {
  components: { OptionDescription, StackedRadioDealInnerDetails },
  props: ["deal", "localDeals"],
  data() {
    return {
      selected: null,
      creatingNew: false
    };
  },
  computed: {
    ...mapState([
      "currentUser",
      "dealCreationInProcess",
      "activeDeal",
      "newRecordIsSafezone"
    ]),
    ...mapGetters([
      "privacyMode",
      "creatingDeal",
      "creatingActiveDeal",
      "creatingDirectActiveDeal",
      "creatingIndirectActiveDeal",
      "dealApiPayload"
    ]),
    helpText() {
      if (this.localDeals) {
        return "Tower Hunt is at its best when there's a single version of a deal. We make it easy to merge deals while protecting your privacy.";
      } else {
        return "Tower Hunt is at its best when there's a single version of a deal. This doesn't prevent you from securely adding your own private data.";
      }
    },
    subjectDeal() {
      return this.deal || this.activeDeal;
    },
    duplicateDeals() {
      return (
        this.filteredLocalDeals ||
        this.activeDeal.potentialDuplicates.deals ||
        []
      );
    },
    filteredLocalDeals() {
      if (this.deal && this.localDeals) {
        return _.differenceBy(this.localDeals, [this.deal], "token");
      } else {
        return null;
      }
    },
    queryParams() {
      if (this.creatingIndirectActiveDeal) {
        return { query: { active: true, indirect: true } };
      } else if (this.creatingActiveDeal) {
        return { query: { active: true } };
      } else {
        return {};
      }
    },
    directDeal() {
      return (
        this.activeDeal.source === "direct" ||
        this.activeDeal.source === "direct lightweight"
      );
    }
  },
  mounted() {
    if (this.creatingDeal && !this.dealCreationInProcess) {
      this.$router.push(_.merge({ path: "/deals/new/1" }, this.queryParams));
    } else if (this.duplicateDeals.length === 1) {
      this.selected = this.duplicateDeals[0];
    }
  },
  methods: {
    cancel() {
      this.$store.commit("closeModal");
    },
    mergeDeals() {
      this.$router.push({
        path: `/merge/deals/${this.deal.token}/into/${this.selected.token}`
      });
      this.$store.commit("closeModal");
    },
    isSelected(deal) {
      return _.get(this.selected, "token", null) === deal.token;
    },
    liveDeal(deal) {
      return _.includes(["live", "awarded"], deal.state);
    },
    isActive(deal) {
      return _.includes(["draft", "live", "awarded"], deal.state);
    },
    marketingListDeal(deal) {
      return deal.source === "direct";
    },
    postToMatches() {
      this.$store.commit("setDealState", "live");
      this.$store.commit("setDealSource", "direct lightweight");
      this.$store.commit("setNewRecordSafezone", false);
      this.$store.dispatch("createDeal");
    },
    buildDealPage() {
      this.$store.dispatch("createDeal");
    },
    safezone() {
      this.$store.dispatch("createDeal");
    },
    directPost(confidential = false) {
      if (confidential) {
        this.$store.commit("toggleNewDealConfidentiality");
      }

      this.$store.dispatch("createDeal");
    },
    confirmPrivacy() {
      Swal.fire(anonymousPostOptions).then(result => {
        if (result.value) {
          this.$store.dispatch("createDeal");
        }
      });
    },
    viewSelectedDeal() {
      this.$store.commit("clearDeal");
      this.$store.commit("clearDealMarketing");

      if (
        this.isActive(this.selected) &&
        this.marketingListDeal(this.selected) &&
        this.selected.dealTeam
      ) {
        const marketingPage =
          this.deal.state === "draft" ? "overview" : "marketing-list";
        const marketingPath = `/deal_offerings/${this.selected.urlHandle || this.selected.token}/${marketingPage}`;

        this.$router.push({ path: marketingPath });
      } else if (
        this.liveDeal(this.selected) &&
        this.marketingListDeal(this.selected) &&
        this.selected.marketingReadAuthorized
      ) {
        const marketingOverviewPath = `/deal_offerings/${this.selected.urlHandle || this.selected.token}/overview`;

        this.$router.push({ path: marketingOverviewPath });
      } else {
        this.$router.push({ path: `/open_deal/${this.selected.token}` });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.privacy-complete {
    background: #d61f69;
    box-shadow: rgba(214, 31, 105, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #f17eb8;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
