<template>
  <div v-show="currentUser">
    <ul class="mt-4">
      <li v-for="contact in invitees" :key="contact.id" class="flex justify-between py-1">
        <div class="mr-1 truncate">
          <span
            class="inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-400"
          >
            <span class="text-md font-medium leading-none text-white">
              {{ contact.name | companyInitials | upperCase }}
            </span>
          </span>
          <span class="pl-3 text-sm font-medium leading-5 text-gray-700">{{ contact.name }}</span>
        </div>
        <span class="inline-flex rounded-md shadow-sm">
          <button @click="remove(contact)" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
            Remove
          </button>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["invitees"],
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },
  methods: {
    remove(contact) {
      this.$emit("remove", contact.name);
    }
  }
};
</script>
