<template>
  <tr v-observe-visibility="{ callback: createImpression }">
    <td class="px-6 py-4 whitespace-nowrap" :class="{ 'border-b border-gray-200': index !== lastIndex }">
      <div class="flex items-center">
        <router-link :to="`/contacts/${person.token}`" class="flex-shrink-0 h-10 w-10">
          <avatar-photo :person="person" circle-size="10" text-size="md" />
        </router-link>
        <div class="ml-4">
          <router-link
            :to="`/contacts/${person.token}`"
            class="text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500"
          >
            {{ person.name }}
          </router-link>
          <div class="text-sm leading-5 text-gray-500">
            {{ person.email }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap" :class="{ 'border-b border-gray-200': index !== lastIndex }">
      <div v-if="person.company" class="text-sm leading-5 text-gray-900">
        {{ person.company }}
      </div>
    </td>
    <td class="px-6 py-4 max-w-sm whitespace-nowrap" :class="{ 'border-b border-gray-200': index !== lastIndex }">
      <div class="text-sm leading-5 truncate" :class="message.offMarketOwnerReplied ? 'text-gray-500' : 'text-gray-900 font-medium'">
        {{ message.body }}
      </div>
    </td>
    <td
      class="px-6 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium"
      :class="{ 'border-b border-gray-200': index !== lastIndex }"
    >
      <div>
        <span v-if="message.offMarketOwnerReplied" class="text-gray-500">Replied</span>
        <a
          v-else
          href=""
          class="text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
          @click.prevent="view"
        >
          View &amp; reply
        </a>
      </div>
      <div>
        <a
          href=""
          class="text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
          @click.prevent="remove"
        >
          Dismiss
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapState } from "vuex";
import AvatarPhoto from "../../avatar-photo";
import OwnerMessageReply from "./owner-message-reply";
import api from "../../../api";

export default {
  components: { AvatarPhoto },
  props: ["message", "post", "index", "lastIndex"],
  computed: {
    ...mapState(["currentUser", "adminMode"]),
    person() {
      return this.message.author;
    }
  },
  methods: {
    remove() {
      api
        .delete(`off_market_property_owner_messages/${this.message.id}`)
        .then(() => {
          this.$store.dispatch("flash", "Message dismissed.");
          this.$emit("refetch");
        });
    },
    view() {
      this.$store.commit("openModal", {
        component: OwnerMessageReply,
        props: {
          message: this.message,
          post: this.post
        }
      });
    },
    createImpression(isVisible, entry) {
      if (!this.adminMode && isVisible) {
        this.$store.dispatch("createCommentImpression", this.message.id);
      }
    }
  }
};
</script>
