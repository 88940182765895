<template>
  <div class="flex text-sm leading-5 text-gray-500">
    <template v-if="bothVolumes">
      <span>{{ record | dealsCapitalMarketsVolume }}</span>
      <span class="mx-1">
        &middot;
      </span>
      <span>
        {{ record | dealsLeaseVolume }}
      </span>
    </template>

    <template v-else-if="capitalMarkets">
      <span>{{ record | dealsCapitalMarketsVolume }}</span>
    </template>

    <template v-else-if="leasing">
      <span>
        {{ record | dealsLeaseVolume }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  props: ["record"],
  computed: {
    bothVolumes() {
      return this.capitalMarkets && this.leasing;
    },
    capitalMarkets() {
      return _.toNumber(this.record.capitalMarketsDealVolume) > 0;
    },
    leasing() {
      return _.toNumber(this.record.leaseDealVolume) > 0;
    }
  }
};
</script>
