<template>
  <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
    <div class="flex-shrink-0">
      <img @click="viewPremiumContent" :class="compact ? 'h-36' : 'h-48 sm:h-64 lg:h-96'" class="w-full object-cover cursor-pointer" :src="showcase.coverPhoto || 'https://source.unsplash.com/collection/2155077'" alt="">
    </div>
    <div :class="compact ? 'p-3' : 'p-6'" class="flex-1 bg-white flex flex-col justify-between relative">
      <div class="flex-1">
        <p :class="compact ? 'text-xs' : 'text-sm'" class="font-medium text-indigo-600">
          <a href="" @click.prevent="viewPremiumContent">
            {{ showcase.category || "Category" }}
          </a>
        </p>
        <a href="" @click.prevent="viewPremiumContent" class="block mt-2">
          <p :class="compact ? 'text-base' : 'text-xl'" class="font-semibold text-gray-900">
            {{ showcase.title || "Title" }}
          </p>
          <p :class="compact ? 'text-sm' : 'text-base'" class="mt-3 text-gray-500">
            {{ showcase.headline || "Headline..." }}
          </p>
        </a>
      </div>
      <div v-if="isAdmin" class="absolute bottom-4 right-4 flex flex-col space-y-1">
        <showcase-visibility-toggle :showcase="showcase" />
        <button v-if="showcase.standalone" @click="editCustomFilters" type="button" v-tooltip="'Set custom filters'" class="inline-flex justify-center items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
          <svg class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
          </svg>
        </button>
        <button @click="edit" type="button" v-tooltip="'Edit'" class="inline-flex justify-center items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
          <svg class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
          </svg>
        </button>
        <button @click="destroy" type="button" v-tooltip="'Delete'" class="inline-flex justify-center items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
          <svg class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import IntelCard from "../cards/market-intel";
import LinkedPurchaseableBrowser from "../purchaseables/linked-purchaseable-browser";
import PurchaseableShowcaseForm from "../purchaseables/purchaseable-showcase-form";
import ShowcaseVisibilityToggle from "../purchaseables/showcase-visibility-toggle.vue";
import Swal from "sweetalert2";
import api from "../../api";
import deleteItemOptions from "../../sweet-alert-delete-item-options";
/* global analytics */

export default {
  components: { ShowcaseVisibilityToggle },
  props: ["showcase", "compact"],
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState(["modal", "adminMode"])
  },
  methods: {
    viewPremiumContent() {
      let existingModal = false;

      if (this.modal) {
        existingModal = _.cloneDeep(this.modal); // obtains the current component
      }

      this.$store.commit("openModal", {
        component: LinkedPurchaseableBrowser,
        props: {
          contentType: "purchaseable_showcase",
          contentToken: this.showcase.id
        },
        afterClose: existingModal
      });

      if (!this.adminMode && !!analytics) {
        analytics.track("Viewed showcase purchaseables", {
          yearWeek: this.$store.state.yearWeek,
          showcaseId: this.showcase.id
        });
      }
    },
    edit() {
      this.$store.commit("openModal", {
        component: PurchaseableShowcaseForm,
        props: { showcase: this.showcase }
      });
    },
    destroy() {
      Swal.fire(deleteItemOptions).then(result => {
        if (result.value) {
          api
            .delete(`purchaseable_showcases/${this.showcase.id}`)
            .then(json => {
              this.$store.commit("setRefetchShowcases", true);
            });
        }
      });
    },
    editCustomFilters() {
      this.$store.commit("openModal", {
        component: IntelCard,
        props: {
          postToken: this.showcase.contentToken,
          showcaseId: this.showcase.id
        }
      });
    },
  }
};
</script>
