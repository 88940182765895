<template>
  <div class="container mx-auto px-4">
    <go-home />
    <start-over />
    <privacy-alert v-if="!privacySelect" />
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import GoHome from "../../../components/onboarding/go-home";
import PrivacyAlert from "../../../components/onboarding/privacy-alert";
import StartOver from "../../../components/onboarding/start-over";

export default {
  components: { StartOver, GoHome, PrivacyAlert },
  computed: {
    ...mapState(["route"]),
    privacySelect() {
      return this.route.path === "/intel/new/privacy";
    }
  }
};
</script>
