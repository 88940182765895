<template>
  <button @click="select" :class="{ active: selected }" type="button">
    <horizontal-option v-if="isHorizontal" :icon-class="iconClass" :label="label" :is-multi-select="isMultiSelect" :selected="selected" />
    <tight-vertical-option v-else-if="isTightVertical" :icon-class="iconClass" :label="label" :is-multi-select="isMultiSelect" :selected="selected" :editable-id="editableId" :editable-type="optionType" />
    <vertical-option v-else :icon-class="iconClass" :label="label" :is-multi-select="isMultiSelect" :selected="selected" :editable-id="editableId" :editable-type="optionType" />
  </button>
</template>

<script>
import { mapGetters } from "vuex";
import HorizontalOption from "./horizontal-option";
import TightVerticalOption from "./tight-vertical-option";
import VerticalOption from "./vertical-option";

export default {
  props: [
    "layout",
    "optionsCount",
    "optionType",
    "setterName",
    "setterType",
    "forgetterName",
    "storeRecord",
    "optionRecord"
  ],
  components: { HorizontalOption, TightVerticalOption, VerticalOption },
  computed: {
    ...mapGetters(["privacyMode"]),
    isMultiSelect() {
      return _.isArray(this.storeRecord);
    },
    isHorizontal() {
      return this.layout === "horizontal";
    },
    isTightVertical() {
      return this.optionsCount > 5;
    },
    multiselected() {
      return this.selected && this.isMultiSelect;
    },
    multiunselected() {
      return !this.selected && this.isMultiSelect;
    },
    selected() {
      if (this.isMultiSelect && this.optionRecord.id) {
        return !!_.find(this.storeRecord, { id: this.value });
      } else if (this.isMultiSelect) {
        return !!_.find(this.storeRecord, { type: this.value });
      } else {
        return this.storeRecord === this.value;
      }
    },
    option() {
      return this.optionRecord;
    },
    label() {
      return this.option.name || this.value;
    },
    value() {
      return this.option.value || this.option.id || this.option.name;
    },
    editableId() {
      return !!this.option.id ? this.option.id : null;
    },
    iconClass() {
      if (this.option.icon && this.selected) {
        const activeClass = this.privacyMode ? "privacy-active" : "active";

        return this.option.icon + ` ${activeClass}`;
      } else if (this.option.icon) {
        return this.option.icon;
      } else {
        return null;
      }
    },
    isDesktop() {
      return this.$store.getters.isDesktop;
    },
    dynamicWidth() {
      const percent = (1 / this.optionsCount) * 100;

      return `calc(${percent}% - 10px)`;
    },
    buttonStyleObject() {
      if (this.isDesktop) {
        return {
          "width": this.isHorizontal ? this.dynamicWidth : "330px"
        };
      } else {
        return {
          "width": this.isHorizontal ? "30%" : "330px"
        };
      }
    }
  },
  methods: {
    select() {
      if (this.isMultiSelect) {
        let payload;

        if (this.option.id) {
          payload = this.optionRecord;
        } else if (this.setterType === "commit") {
          payload = { type: this.value };
        } else {
          payload = { data: this.value };
        }

        if (this.selected) {
          this.$store[this.setterType](this.forgetterName, payload);
        } else {
          this.$store[this.setterType](this.setterName, payload);
        }

        this.hover();
      } else {
        this.$store[this.setterType](this.setterName, this.value);
      }
    },
    hover() {
      this.$emit("hover");
      this.vacate();
    },
    vacate: _.debounce(function() {
      this.$emit("vacate");
    }, 5000)
  }
}
</script>

<style lang="scss" scoped>
button {
  pointer-events: all;
  margin: 5px;
  color: #4f4f4f;
  border-radius: 5px;
  border: 1px solid #b7b7b7;
  outline: none;
  background: none;
  &:hover {
    border-color: #777;
  }
  &.active {
    background-color: #f7f7f7;
  }
}
</style>
