<template>
  <div class="flex-1 flex flex-col justify-between">
    <div class="divide-y divide-gray-200">
      <div class="space-y-6 pb-5">
        <div>
          <label for="contact-name" class="block text-sm font-medium text-gray-900">
            Name
          </label>
          <div class="mt-1">
            <input v-focus v-model="name" type="text" name="contact-name" id="contact-name" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
          </div>
        </div>
        <div>
          <div class="flex justify-between">
            <label for="contact-title" class="block text-sm font-medium text-gray-700">Title</label>
            <span class="text-sm text-gray-500" id="contact-title-optional">Optional</span>
          </div>
          <div class="mt-1">
            <input v-model="title" type="text" name="contact-title" id="contact-title" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
          </div>
        </div>
        <div>
          <div class="flex justify-between">
            <label for="contact-info" class="block text-sm font-medium text-gray-700">Contact info</label>
            <span class="text-sm text-gray-500" id="contact-info-optional">Optional</span>
          </div>
          <div class="mt-1">
            <textarea v-model="contactInfo" placeholder="Phone(s), email(s), mailing address(es), etc." id="contact-info" name="contact-info" rows="5" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"></textarea>
          </div>
        </div>
      </div>
      <div v-if="false" class="pt-4 pb-6">
        <div class="mt-4 flex text-sm">
          <a @click.prevent href="" class="group inline-flex items-center text-gray-500 hover:text-gray-900">
            <!-- Heroicon name: solid/question-mark-circle -->
            <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
            </svg>
            <span class="ml-2">
              Learn more about sharing
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["contact"],
  computed: {
    ...mapState(["newContact"]),
    name: {
      get() {
        return this.newContact.name;
      },
      set(newName) {
        this.$store.commit("setNewContactName", newName);
      }
    },
    title: {
      get() {
        return this.newContact.title;
      },
      set(newTitle) {
        this.$store.commit("setNewContactTitle", newTitle);
      }
    },
    contactInfo: {
      get() {
        return this.newContact.contactInfo;
      },
      set(newInfo) {
        this.$store.commit("setNewContactInfo", newInfo);
      }
    }
  },
  mounted() {
    if (this.contact) {
      this.$store.commit("setNewContactName", this.contact.name);
      this.$store.commit("setNewContactTitle", this.contact.title);
      this.$store.commit("setNewContactInfo", this.contact.contactInfo);
    }
  }
};
</script>
