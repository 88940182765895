<template>
  <div>
    <!-- Page title & actions -->
    <div class="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
      <div class="flex-1 min-w-0">
        <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
          Safezone
        </h1>
      </div>
      <div class="mt-4 flex sm:mt-0 sm:ml-4">
        <span class="order-0 sm:order-1 sm:ml-3 shadow-sm rounded-md">
          <button @click="newIntel" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
            Add
          </button>
        </span>
      </div>
    </div>
    <safezone-table />
  </div>
</template>

<script>
import SafezoneTable from "../../components/safezone/safezone-table";

export default {
  components: { SafezoneTable },
  methods: {
    newIntel() {
      this.$router.push({
        path: "/intel/new/privacy",
        query: { nextUrl: "/intel/new/1" }
      });
    }
  }
};
</script>
