<template>
  <div class="wrapper">
    <heading v-if="activeIntel.type === 'marketCommentary'">What can you share?</heading>
    <heading v-else-if="postToken">Let's name this intel</heading>
    <heading v-else>What {{ label }}?</heading>
    <form @submit.prevent autocomplete="off" novalidate class="mb-2">
      <div v-if="(activeIntel.type === 'marketCommentary' || activeIntel.type === 'marketReport') && !postToken" class="input-container">
        <input
          v-focus
          v-model="url"
          @focus="focus('url')"
          @blur="blur"
          type="text"
          autocomplete="new-password"
          autocorrect="off"
          spellcheck="false"
          placeholder=""
          tabindex="1"
          name="intel_url"
          class="file-attachment-text black"
          :class="{ active: focused === 'url' && !privacyMode, 'privacy-active': focused === 'url' && privacyMode }"
          required
        >
        <label class="input-label" :class="{ active: focused === 'url' && !privacyMode, 'privacy-active': focused === 'url' && privacyMode }">URL or file</label>
        <label class="email-icon" :class="{ active: focused === 'url' && !privacyMode, 'privacy-active': focused === 'url' && privacyMode }">
          <i class="fas fa-link"></i>
        </label>
        <label @click="attachFile" class="right-label flex flex-col items-center cursor-pointer" :class="{ active: focused === 'url' && !privacyMode, 'privacy-active': focused === 'url' && privacyMode }">
          <svg class="h-5 w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"></path></svg>
          File
        </label>
        <div class="divider" :class="{ active: focused === 'url' && !privacyMode, 'privacy-active': focused === 'url' && privacyMode }" />
      </div>

      <div class="input-container">
        <input
          v-focus:activeIntel
          v-model="name"
          @focus="focus('name')"
          @blur="blur"
          type="text"
          autocomplete="new-password"
          autocorrect="off"
          spellcheck="false"
          placeholder=""
          :tabindex="activeIntel.type === 'marketCommentary' || activeIntel.type === 'marketReport' ? 2 : 1"
          name="intel_name"
          class="input-text black"
          :class="{ active: focused === 'name' && !privacyMode, 'privacy-active': focused === 'name' && privacyMode }"
        >
        <label v-if="activeIntel.type === 'marketCommentary' || activeIntel.type === 'marketReport'" class="input-label" :class="{ active: focused === 'name' && !privacyMode, 'privacy-active': focused === 'name' && privacyMode }">{{ label | capitalize }}</label>
        <label v-else class="input-label" :class="{ active: focused === 'name' && !privacyMode, 'privacy-active': focused === 'name' && privacyMode }">{{ label | capitalize }} name</label>
        <label class="email-icon" :class="{ active: focused === 'name' && !privacyMode, 'privacy-active': focused === 'name' && privacyMode }">
          <i class="fas fa-font"></i>
        </label>
      </div>

      <div v-if="!postToken" class="input-container">
        <resizable-textarea>
          <textarea
            v-model="notes"
            @focus="focus('notes')"
            @blur="blur"
            rows="3"
            autocorrect="off"
            spellcheck="false"
            :placeholder="notesPlaceholder"
            tabindex="2"
            name="intel_notes"
            class="text-area-text black"
            :class="{ active: focused === 'notes' && !privacyMode, 'privacy-active': focused === 'notes' && privacyMode }"
          />
        </resizable-textarea>
      </div>

      <div v-if="(activeIntel.type !== 'marketCommentary' && activeIntel.type !== 'marketReport') && !postToken" class="input-container">
        <input
          v-model="url"
          @focus="focus('url')"
          @blur="blur"
          type="text"
          autocomplete="new-password"
          autocorrect="off"
          spellcheck="false"
          placeholder=""
          tabindex="3"
          name="intel_url"
          class="file-attachment-text black"
          :class="{ active: focused === 'url' && !privacyMode, 'privacy-active': focused === 'url' && privacyMode }"
          required
        >
        <label class="input-label" :class="{ active: focused === 'url' && !privacyMode, 'privacy-active': focused === 'url' && privacyMode }">URL</label>
        <label class="email-icon" :class="{ active: focused === 'url' && !privacyMode, 'privacy-active': focused === 'url' && privacyMode }">
          <i class="fas fa-link"></i>
        </label>
        <label @click="attachFile" class="right-label flex flex-col items-center cursor-pointer" :class="{ active: focused === 'url' && !privacyMode, 'privacy-active': focused === 'url' && privacyMode }">
          <svg class="h-5 w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"></path></svg>
          File
        </label>
        <div class="divider" :class="{ active: focused === 'url' && !privacyMode, 'privacy-active': focused === 'url' && privacyMode }" />
      </div>

      <div v-if="url" class="mt-4 mb-2" style="width: 330px;">
        <div class="iframely-embed">
          <a data-iframely-url :href="url"></a>
        </div>
        <failed-url-preview-empty-state v-if="urlPreviewFailed" :url="url" />
      </div>

      <option-description v-if="!postToken" class="mt-4" description="Notes and URL are optional." />

      <button v-if="!newRecordIsSafezone" @click="confirmPostPrivacy" type="button" class="control cta mt-4" :class="{ complete: complete && !privacyMode, 'privacy-complete': complete && privacyMode }" tabindex="999">
        <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
          <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
        </svg>
        <template v-if="postToken">Confirm privacy and edit</template>
        <template v-else>Confirm author and post</template>
      </button>
      <button v-if="newRecordIsSafezone && !postToken" @click="safezone" type="button" :class="{ 'mt-4': newRecordIsSafezone, 'mt-1': !newRecordIsSafezone, 'privacy-complete': complete && privacyMode, 'complete': complete && !privacyMode }" class="control cta" tabindex="999">
        <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
          <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
        </svg>
        Save to Safezone
      </button>
      <button v-if="complete && !postToken" @click="next" type="button" class="control secondary mb-1" tabindex="999">
        <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
          <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
        </svg>
        Tag markets
        <svg class="h-4 w-4 text-green-500" fill="currentColor" viewBox="0 0 20 20">
          <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
        </svg>
        <span class="text-green-400">10</span>
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FailedUrlPreviewEmptyState from "../../../components/failed-url-preview-empty-state.vue";
import FileDragDrop from "../../../components/file-drag-drop.vue";
import Heading from "../../../components/onboarding/heading";
import OptionDescription from "../../../components/onboarding/option-description";
import ResizableTextarea from "../../../components/resizable-textarea";
import Swal from "sweetalert2";
import anonymousPostOptions from "../../../sweet-alert-always-anonymous-options";
import anonymousPostUpdateOptions from "../../../sweet-alert-always-anonymous-update-options";
import iframelyApi from "../../../iframely-api";
import namedPostOptions from "../../../sweet-alert-named-post-options";
/* global iframely */

export default {
  components: {
    FailedUrlPreviewEmptyState,
    Heading,
    OptionDescription,
    ResizableTextarea
  },
  props: ["postToken"],
  data() {
    return {
      focused: false,
      urlPreviewFailed: false
    };
  },
  computed: {
    ...mapState([
      "activeIntel",
      "intelCreationInProcess",
      "newRecordIsSafezone",
      "notepadConversionComment"
    ]),
    ...mapGetters(["creatingIntel", "privacyMode"]),
    fileName() {
      if (this.postToken) {
        return null;
      } else {
        return this.activeIntel.file.name;
      }
    },
    label() {
      switch (this.activeIntel.type) {
        case "jobPosting":
          return "job";
        case "industryEvent":
          return "event";
        case "developmentNews":
        case "infrastructureNews":
          return "project";
        case "tenantInMarket":
          return "tenant";
        case "marketCommentary":
          return "title";
        case "marketReport":
          return "report";
        default:
          return "title";
      }
    },
    notesPlaceholder() {
      switch (this.activeIntel.type) {
        case "jobPosting":
          return "What should people know?";
        case "industryEvent":
        case "developmentNews":
        case "infrastructureNews":
        case "tenantInMarket":
        case "marketReport":
          return "Additional context";
        case "marketCommentary":
        default:
          return "Share your thoughts";
      }
    },
    name: {
      get() {
        return this.activeIntel.name;
      },
      set(newName) {
        this.$store.commit("setIntelName", newName);
      }
    },
    notes: {
      get() {
        return this.activeIntel.notes;
      },
      set(text) {
        this.$store.commit("setActiveIntelNotes", text);
      }
    },
    url: {
      get() {
        return this.activeIntel.url;
      },
      set(url) {
        this.$store.commit("setIntelUrl", url);
      }
    },
    complete() {
      return this.name !== null && _.trim(this.name) !== "";
    }
  },
  watch: {
    url: {
      handler() {
        if (/^https?:\/\//i.test(this.url) && window.iframely) {
          setTimeout(() => {
            iframely.load();
            iframelyApi(this.url).then(data => {
              const thumbnail = _.get(data, "links.thumbnail[0].href", null) || _.get(data, "links.image[0].href", null);

              this.$store.commit("setIntelThumbnail", thumbnail);

              const title = _.get(data, "meta.title", null);

              if (title && !this.complete) {
                this.$store.commit("setIntelName", title);
              }

              const description = _.get(data, "meta.description", null);

              if (description && _.trim(this.notes) === "") {
                this.$store.commit("setActiveIntelNotes", description);
              }
            });

            var self = this;

            iframely.on("cancel", function(url, parentNode) {
              self.urlPreviewFailed = true;
            });
          }, 500);
        } else {
          this.urlPreviewFailed = false;
        }
      }
    },
    fileName: {
      handler() {
        if (this.fileName) {
          document.getElementsByName(
            "intel_url"
          )[0].placeholder = `${this.fileName}`;
          document.getElementsByName("intel_url")[0].disabled = true;
          document.getElementsByName("intel_name")[0].focus();
        }
      }
    }
  },
  mounted() {
    if (this.creatingIntel && !this.intelCreationInProcess) {
      this.$router.push({ path: "/intel/new/1" });
    }

    if (this.postToken) {
      this.$store.commit("setNewRecordSafezone", false);
    }

    if (this.notepadConversionComment && !this.activeIntel.notes) {
      this.$store.commit("setActiveIntelNotes", this.notepadConversionComment.body);
    }

    if (/^https?:\/\//i.test(this.url) && window.iframely) {
      setTimeout(() => {
        iframely.load();

        var self = this;

        iframely.on("cancel", function(url, parentNode) {
          self.urlPreviewFailed = true;
        });
      }, 500);
    }
  },
  methods: {
    focus(field) {
      this.focused = field;
    },
    blur() {
      this.focused = false;
    },
    attachFile() {
      this.focus("url");
      this.$store.commit("openModal", {
        component: FileDragDrop,
        props: {
          endpoint: null,
          localCommit: "setActiveIntelFile",
          fileTypes: "image/*,application/pdf",
          fileTypeLabels: "PDF, PNG, JPG, GIF",
          sizeLimit: 25,
          multiple: false,
          heading: "Let's add an attachment",
          marginBottom: true,
          flashMessage: "File attached successfully"
        }
      });
    },
    next() {
      if (this.complete) {
        switch (this.activeIntel.type) {
          case "jobPosting":
          case "industryEvent":
            this.$router.push({ path: "/intel/new/3" });
            break;
          case "developmentNews":
            this.$router.push({ path: "/intel/new/5" });
            break;
          case "marketCommentary":
          case "marketReport":
            this.$router.push({ path: "/intel/new/5" });
            break;
          case "infrastructureNews":
          case "tenantInMarket":
            this.$router.push({ path: "/intel/new/6" });
            break;
        }
      }
    },
    safezone() {
      if (this.complete) {
        this.$store.commit("setIntelPublic");
        this.save();
      }
    },
    confirmPostPrivacy() {
      if (this.complete) {
        if (this.postToken) {
          Swal.fire(anonymousPostUpdateOptions).then(result => {
            if (result.value) {
              this.save();
            }
          });
        } else {
          if (this.privacyMode) {
            Swal.fire(anonymousPostOptions).then(result => {
              if (result.value) {
                this.save();
              }
            });
          } else {
            Swal.fire(namedPostOptions).then(result => {
              if (result.value) {
                this.save();
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                this.$store.commit("setIntelPublic");
                this.save();
              }
            });
          }
        }
      }
    },
    save() {
      if (this.postToken) {
        this.$store.dispatch("updateIntel", {
          postToken: this.postToken,
          updateTags: false
        });

        this.$store.commit("closeModal");
        this.$store.commit("clearIntel");
      } else {
        switch (this.activeIntel.type) {
          case "jobPosting":
            this.$store.dispatch("createJobPosting");
            break;
          case "industryEvent":
            this.$store.commit("clearIntelDateType");
            this.$store.dispatch("createIndustryEvent");
            break;
          case "developmentNews":
            this.$store.dispatch("createDevelopmentNews");
            break;
          case "infrastructureNews":
            this.$store.dispatch("createInfrastructureNews");
            break;
          case "tenantInMarket":
            this.$store.dispatch("createTenantInMarket");
            break;
          case "marketCommentary":
            this.$store.dispatch("createMarketCommentary");
            break;
          case "marketReport":
            this.$store.dispatch("createMarketReport");
            break;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
}
textarea,
input {
  vertical-align: middle;
}
.input-container {
  position: relative;
}
.file-attachment-text,
.input-text,
.text-area-text {
  width: 330px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.privacy-active {
    border: 2px solid #d61f69;
  }
  &.active {
    border: 2px solid #5850ec;
  }
}
.text-area-text {
  padding: 8px 15px;
}
.file-attachment-text {
  padding: 26px 60px 8px 38px;
}
.input-text {
  padding: 26px 15px 8px 38px;
}
.input-label {
  position: absolute;
  top: 8px;
  left: 38px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.privacy-active {
    color: #d61f69;
  }
  &.active {
    color: #5850ec;
  }
}
.email-icon {
  position: absolute;
  top: 19px;
  left: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.privacy-active {
    color: #d61f69;
  }
  &.active {
    color: #5850ec;
  }
}
.right-label {
  position: absolute;
  top: 8px;
  right: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  pointer-events: auto;
  color: #b7b7b7;
  text-transform: uppercase;
  &.privacy-active {
    color: #d61f69;
  }
  &.active {
    color: #5850ec;
  }
}
.divider {
  position: absolute;
  bottom: 1px;
  right: 60px;
  width: 0px;
  height: 53px;
  border: 0.5px solid #dadada;
  &.privacy-active {
    border: 0.5px solid #d61f69;
  }
  &.active {
    border: 0.5px solid #5850ec;
  }
}
button.control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.privacy-complete {
    background: #d61f69;
    box-shadow: rgba(214, 31, 105, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #f17eb8;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
