<template>
  <div class="h-screen flex overflow-y-auto overflow-x-hidden bg-gray-900">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto">
        <go-home text-color="text-white" />
        <off-market-cards v-if="cards" :cards="cards" :pack-id="packId" class="pt-8 pb-3" />
      </div>
    </div>
  </div>
</template>

<script>
import GoHome from "../components/onboarding/go-home";
import OffMarketCards from "../components/cards/stacked/stack-container";
import api from "../api";

export default {
  components: { GoHome, OffMarketCards },
  props: ["packId"],
  data() {
    return {
      cards: null
    };
  },
  mounted() {
    if (this.packId) {
      api.get(`card_packs/${this.packId}/collectible_cards`).then(
        json => {
          this.cards = json.data;
        },
        failure => {
          this.$store.dispatch("flash", "You do not have permission to view this pack");
          this.$router.push({ path: "/" });
        }
      );
    } else {
      this.$router.push({ path: "/" });
    }
  }
};
</script>
