<template>
  <div ref="attributionMenu">
    <button @click="toggleMenu" v-tooltip="'Crowdsourcing metrics'" :class="buttonClass">
      <!-- Heroicon name: solid/trending-up -->
      <svg class="h-5 w-5" :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z" clip-rule="evenodd" />
      </svg>
    </button>

    <transition
      enter-active-class="duration-100 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-75 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-show="menuOpen" v-observe-visibility="{ callback: fetchAttributions }" class="origin-top-right absolute right-0 w-72 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="pinned-project-options-menu-0-button" tabindex="-1" style="z-index: 9999;">
        <div v-if="false" class="py-1" role="none">
          <a @click.prevent href="" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="pinned-project-options-menu-0-item-0">Live value: $0.001/view</a>
        </div>
        <div v-if="loaded">
          <div class="py-1 border-b border-gray-200">
            <span class="text-gray-700 block px-4 py-2 text-sm font-bold">{{ attributions.length > 0 ? 'Contributors:' : 'No contributors yet'}}</span>
          </div>
          <ul class="divide-y divide-gray-200">
            <li v-for="attribution in attributions" :key="attribution.id" class="flex">
              <div v-tooltip="reputationHelp(attribution)" class="flex-shrink-0 flex items-center justify-center w-16">
                <img
                  v-if="attribution.name && attribution.avatar"
                  class="inline-block h-16 w-16 object-cover object-center"
                  :src="attribution.avatar"
                  alt=""
                />
                <span
                  v-else-if="attribution.name"
                  class="inline-flex items-center justify-center h-16 w-16 bg-gray-400"
                >
                  <span class="font-medium uppercase leading-none text-white text-lg">
                    {{ attribution.name | initials }}
                  </span>
                </span>
                <img v-else :class="reputationColor(attribution)" class="p-1 inline-block h-16 w-16" src="https://assets.towerhunt.com/site/Transparent_White_T0.png" alt="Tower Hunt logo">
              </div>
              <div class="flex-1 flex items-center justify-between bg-white truncate">
                <div class="flex-1 px-4 py-2 text-sm truncate">
                  <a @click.prevent href="" v-tooltip="attribution.name ? 'Only you can see your identity.' : ''" class="text-gray-900 font-medium hover:text-gray-600">
                    <template v-if="attribution.name">{{ attribution.name }} (Me)</template>
                    <template v-else>{{ attribution.reputationLabel }}</template>
                  </a>
                  <p class="text-gray-500">{{ attribution.userRole | capitalize }}<template v-if="attribution.associationName">&nbsp;&middot;&nbsp;{{ attribution.associationName }}</template></p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import api from "../../api";

export default {
  props: ["contentType", "contentToken", "field", "buttonClass", "svgClass"],
  data() {
    return {
      menuOpen: false,
      attributions: [],
      loaded: false
    };
  },
  computed: {},
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.attributionMenu &&
        !this.$refs.attributionMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });

    this.fetchAttributions();
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    fetchAttributions(isVisible) {
      if (isVisible) {
        this.loaded = false;
        api
          .get(
            `data_attributions/${this.contentType}/${this.contentToken}?field=${this.field}`
          )
          .then(json => {
            this.attributions = json.data;
            this.loaded = true;
          });
      }
    },
    reputationHelp(attribution) {
      return _.capitalize(attribution.reputationLevel);
    },
    reputationColor(attribution) {
      switch (attribution.reputationLevel) {
        case "bronze":
          return "bg-gradient-to-r from-yellow-500 to-orange-700 text-white hover:to-orange-800";
        case "silver":
          return "bg-gradient-to-r from-cool-gray-200 to-gray-400 text-gray-900 hover:to-gray-500";
        case "gold":
          return "bg-gradient-to-r from-yellow-300 to-yellow-500 text-white hover:to-yellow-600";
        default:
          return "text-white bg-gray-900 hover:bg-gray-800";
      }
    }
  }
};
</script>
