export default {
  title: "Are you sure you want to remove this from your watchlist?",
  text: "You'll lose access to the detail page, email alerts, and shared data. You'll retain access to your own data. The watchlist private chat will retain access to data you shared and comments you made. This cannot be undone.",
  showCancelButton: true,
  showCloseButton: true,
  focusCancel: true,
  confirmButtonColor: "#5850ec",
  cancelButtonColor: "#b7b7b7",
  confirmButtonText: "Remove",
  reverseButtons: true,
  focusConfirm: false,
  customClass: {
    header: "thswal-header-class",
    title: "thswal-title-class",
    closeButton: "thswal-close-button-class",
    content: "thswal-content-class",
    actions: "thswal-actions-class",
    confirmButton: "thswal-confirm-button-class",
    cancelButton: "thswal-cancel-button-class"
  }
};
