<template>
  <div class="container mx-auto px-4">
    <go-home />
    <start-over />
    <avatar v-if="onboarding" class="container mx-auto mt-4" :large="80" :small="40" />
    <router-view />
  </div>
</template>

<script>
import Avatar from "../../components/onboarding/avatar";
import GoHome from "../../components/onboarding/go-home";
import StartOver from "../../components/onboarding/start-over";

export default {
  components: { StartOver, Avatar, GoHome },
  computed: {
    onboarding() {
      return this.$store.getters.onboarding;
    }
  }
}
</script>
