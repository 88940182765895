<template>
  <div>
    <div class="pb-5 border-b border-gray-200 space-y-3 sm:space-y-4 sm:pb-0">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        My Network
      </h3>
      <div>
        <!-- Dropdown menu on small screens -->
        <div class="sm:hidden">
          <select v-model="selectValue" aria-label="Selected tab" class="block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm sm:leading-5">
            <option value="teams">Teams</option>
            <option value="suggestions">Suggestions</option>
            <option value="connections">Connections</option>
          </select>
        </div>
        <!-- Tabs at small breakpoint and up -->
        <div class="hidden sm:block">
          <nav class="-mb-px flex space-x-8">
            <router-link
              to="/home/network/teams"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Teams
              </a>
            </router-link>

            <router-link
              to="/home/network/suggestions"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Suggestions
              </a>
            </router-link>

            <router-link
              to="/home/network/connections"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Connections
              </a>
            </router-link>
          </nav>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectValue: "teams"
    };
  },
  watch: {
    selectValue: {
      handler() {
        this.$router.push({ path: `/home/network/${this.selectValue}` });
      }
    }
  },
  mounted() {
    document.title = "My Network | Tower Hunt";

    if (this.$store.state.route.path === "/home/network") {
      this.$router.push({ path: "/home/network/teams" });
      this.selectValue = "teams";
    }
  },
  destroyed() {
    this.$store.commit("clearRefetchPeople");
  }
};
</script>
