export default [
  "pink-600",
  "purple-600",
  "orange-500",
  "green-400",
  "blue-500",
  "teal-400",
  "cyan-400",
  "lime-400",
  "fuchsia-400",
  "rose-400",
  "yellow-300"
];
