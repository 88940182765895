<template>
  <div ref="documentMenu" class="relative flex justify-end items-center" style="z-index: 9998;">
    <button @click="toggleMenu" id="purchaseable-options-menu-0" aria-has-popup="true" type="button" class="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100">
      <!-- Heroicon name: dots-vertical -->
      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
      </svg>
    </button>
    <transition
      enter-active-class="duration-100 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-75 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-show="menuOpen" class="mx-3 origin-top-right absolute right-7 top-0 w-48 mt-1 rounded-md shadow-lg">
        <div class="z-10 rounded-md bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="project-options-menu-0">
          <div class="py-1">
            <a href="" @click.prevent="previewDocument" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <!-- Heroicon name: search -->
              <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M5 8a1 1 0 011-1h1V6a1 1 0 012 0v1h1a1 1 0 110 2H9v1a1 1 0 11-2 0V9H6a1 1 0 01-1-1z" />
                <path fill-rule="evenodd" d="M2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8zm6-4a4 4 0 100 8 4 4 0 000-8z" clip-rule="evenodd" />
              </svg>
              Preview
            </a>
            <a @click="incrementDownloadCount" :href="document.file" target="_blank" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <!-- Heroicon name: download -->
              <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd" />
              </svg>
              Download
            </a>
            <a v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" href="" @click.prevent="togglePin" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <!-- Heroicon name: star -->
              <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
              {{ document.pinned ? "Unpin" : "Pin" }}
            </a>
            <a v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" href="" @click.prevent="toggleVisibility" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <!-- Heroicon name: lock-closed / lock-open -->
              <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path v-if="document.visibility === 'unrestricted'" fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                <path v-else-if="document.visibility === 'restricted'" d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
              </svg>
              {{ visibilityLabel }}
            </a>
          </div>
          <div class="border-t border-gray-100"></div>
          <div v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" class="py-1">
            <a href="" @click.prevent="deleteDocument" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <!-- Heroicon name: trash -->
              <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
              Delete
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FileViewer from "../../components/cards/file-viewer";
import Swal from "sweetalert2";
import api from "../../api";
import deleteItemOptions from "../../sweet-alert-delete-item-options";
import restrictOptions from "../../sweet-alert-restrict-document-options";
import unrestrictOptions from "../../sweet-alert-unrestrict-document-options";

export default {
  props: ["document"],
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "dealProspectInviteParam"]),
    ...mapState(["activeDeal", "activeDealMarketing", "modal", "xlModal"]),
    visibilityLabel() {
      if (this.document.visibility === "unrestricted") {
        return "Restrict";
      } else if (this.document.visibility === "restricted") {
        return "Unrestrict";
      } else {
        return "Toggle visibility";
      }
    }
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.documentMenu &&
        !this.$refs.documentMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    previewDocument() {
      this.$store.commit("openXLModal", {
        component: FileViewer,
        props: { document: this.document }
      });
      this.menuOpen = false;
    },
    incrementDownloadCount() {
      api
        .post(
          `deal_marketing_document_engagement/${this.document.id}?download=true`
        )
        .then(json => {
          this.menuOpen = false;
        });
    },
    deleteDocument() {
      Swal.fire(deleteItemOptions).then(result => {
        if (result.value) {
          api
            .delete(`deal_marketing_documents/${this.document.id}`)
            .then(json => {
              this.$store.dispatch("flash", "Document deleted");
              this.$store.commit("setRefetchDocuments", true);
            });
        }
      });
    },
    toggleVisibility() {
      if (this.document.visibility === "unrestricted") {
        this.confirmVisiblity(restrictOptions, "restrict");
      } else if (this.document.visibility === "restricted") {
        this.confirmVisiblity(unrestrictOptions, "unrestrict");
      } else {
        this.menuOpen = false;
      }
    },
    confirmVisiblity(swalOptions, endpointAction) {
      Swal.fire(swalOptions).then(result => {
        if (result.value) {
          api
            .patch(
              `deal_marketing_documents/${this.document.id}/${endpointAction}`
            )
            .then(json => {
              this.$store.dispatch("flash", "Document visibility updated");
              this.$store.commit("setRefetchDocuments", true);
            });
        }
      });
    },
    togglePin() {
      if (this.document.pinned) {
        api
          .delete(`deal_marketing_documents/${this.document.id}/pin`)
          .then(json => {
            this.$store.dispatch("flash", "Document unpinned");
            this.document.pinned = false;
            this.$store.commit("setRefetchDocuments", true);
          });
      } else {
        api
          .post(`deal_marketing_documents/${this.document.id}/pin`)
          .then(json => {
            this.$store.dispatch("flash", "Document pinned");
            this.document.pinned = true;
            this.$store.commit("setRefetchDocuments", true);
          });
      }
      this.menuOpen = false;
    }
  }
};
</script>
