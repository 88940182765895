import { render, staticRenderFns } from "./email-batch-upload.vue?vue&type=template&id=67bc0338&scoped=true&"
import script from "./email-batch-upload.vue?vue&type=script&lang=js&"
export * from "./email-batch-upload.vue?vue&type=script&lang=js&"
import style0 from "./email-batch-upload.vue?vue&type=style&index=0&id=67bc0338&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67bc0338",
  null
  
)

export default component.exports