<template>
  <li>
    <router-link
      @click.native="view"
      to=""
      class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
    >
      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="min-w-0 flex-1 flex items-center">
          <div class="flex-shrink-0">
            <img v-if="primaryAttachmentIsImage" class="h-12 w-12 rounded-full object-cover object-center" :src="post.primaryAttachment" alt="Attached photo">
            <img v-else-if="post.thumbnail" class="h-12 w-12 rounded-full object-cover object-center" :src="post.thumbnail" alt="Url thumbnail">
            <span v-else class="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-400">
              <svg class="h-5 w-5 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path fill="currentColor" d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
              </svg>
            </span>
          </div>

          <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <div class="text-sm leading-5 font-medium text-indigo-600 truncate">{{ post.name }}</div>
              <div class="mt-2 flex items-center text-sm leading-5 text-gray-500">
                <intel-type-badge :post="post" />
              </div>
            </div>
            <div class="hidden md:block">
              <div>
                <div class="text-sm leading-5 text-gray-900 truncate">
                  {{ post.notes }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
          </svg>
        </div>
      </div>
    </router-link>
  </li>
</template>

<script>
import IntelCard from "./cards/market-intel";
import IntelTypeBadge from "./intel-type-badge";

export default {
  components: { IntelTypeBadge },
  props: ["post"],
  computed: {
    isStrategicMap() {
      return this.$store.state.route.path === "/map";
    },
    primaryAttachmentIsImage() {
      if (this.post.primaryAttachmentType) {
        return _.includes(this.post.primaryAttachmentType, "image");
      } else {
        return false;
      }
    },
    authorTooltip() {
      const name = this.$options.filters.capitalize(this.post.author.name);
      const role = _.capitalize(
        this.$options.filters.industryRoleAlias(this.post.author.industryRole, this.post.author.industrySubRole, this.post.author.token)
      );

      if (this.post.author.company) {
        return `${name} (${role} at ${this.post.author.company})`;
      } else {
        return `${name} (${role})`;
      }
    }
  },
  methods: {
    view() {
      if (this.isStrategicMap && this.post.mappable) {
        this.$router.push({ path: `/map?intel=${this.post.token}` });
      } else {
        this.$store.commit("openModal", {
          component: IntelCard,
          props: {
            postToken: this.post.token
          }
        });
      }

      this.$store.commit("setSearchFlyover", false);
      this.$store.commit("clearSearch");
    }
  }
};
</script>
