<template>
  <div class="wrapper" :class="channelLookup ? 'mb-20' : ''">
    <heading v-if="!loaded">Fetching your contacts...</heading>
    <heading v-else-if="channelLookup">Who do you want to chat with?</heading>
    <heading v-else-if="isBackchannel">Who do you want to share with?</heading>
    <heading v-else-if="marketingList">Who do you want to market to?</heading>
    <heading v-else-if="activeDeal.state !== 'closed'">Who else is working on this deal?</heading>
    <heading v-else>Who else worked on this deal?</heading>
    <form v-if="loaded" @submit.prevent autocomplete="off" novalidate>
      <div class="px-4 py-5 bg-white sm:p-6">
        <autocomplete
          ref="contactSearch"
          v-focus
          :auto-select="true"
          :search="searchContacts"
          :get-result-value="getResultValue"
          :debounce-time="500"
          @submit="handleSubmit"
        />
        <deal-invitee-list v-if="!channelLookup" :invitees="invitees" @remove="remove" />
      </div>

      <template v-if="!channelLookup && isBackchannel && hasInvitees">
        <h3 class="mx-4 text-center text-lg sm:text-xl leading-9 font-bold text-gray-900">
          Optional message
        </h3>
        <div class="input-container my-2">
          <resizable-textarea>
            <textarea
              v-model="message"
              @focus="focus"
              @blur="blur"
              rows="1"
              autocorrect="off"
              spellcheck="false"
              placeholder="Thought you'd enjoy this."
              tabindex="3"
              name="message"
              class="input-text black"
              :class="{ active: focused && !isBackchannel && !privacyMode, 'privacy-active': focused && isBackchannel && privacyMode }"
            />
          </resizable-textarea>
        </div>
      </template>

      <option-description v-if="channelLookup" description="Search your contacts" />
      <template v-else>
        <option-description v-if="helpText !== ''" :description="helpText" />
        <template>
          <button v-if="marketingList" @click="invite" type="button" class="control cta mt-4" :class="{ complete: hasInvitees, 'mb-2': isBackchannel && !isIntelBackchannel }" tabindex="999">Add to list</button>
          <button v-else-if="isBackchannel" @click="confirmPostPrivacy" type="button" class="control cta mt-4" :class="{ complete: hasInvitees && !privacyMode, 'privacy-complete': hasInvitees && privacyMode, 'mb-2': isBackchannel && !isIntelBackchannel }" tabindex="999">
            <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
              <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
            </svg>
            Confirm author and post
          </button>
          <button v-else @click="invite" type="button" class="control cta mt-4" :class="{ complete: hasInvitees, 'mb-2': isBackchannel && !isIntelBackchannel }" tabindex="999">Send invites</button>
        </template>
        <button v-if="hasInvitees && isIntelBackchannel" @click="next" type="button" class="control secondary my-1" tabindex="999">
          <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
            <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
          </svg>
          Tag markets
          <svg class="h-4 w-4 text-green-500" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
          <span class="text-green-400">10</span>
        </button>
      </template>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DealInviteeList from "../../../components/deal-invitee-list";
import Heading from "../../../components/onboarding/heading";
import OptionDescription from "../../../components/onboarding/option-description";
import ResizableTextarea from "../../../components/resizable-textarea";
import Swal from "sweetalert2";
import anonymousPostOptions from "../../../sweet-alert-always-anonymous-options";
import selfPostOptions from "../../../sweet-alert-always-self-options";
/* global analytics */

export default {
  components: {
    Heading,
    DealInviteeList,
    OptionDescription,
    ResizableTextarea
  },
  props: ["dealId", "marketingList", "postContent", "channelLookup"],
  data() {
    return {
      invitees: [],
      loaded: false,
      helpText: "Search your contacts or enter an email address.",
      message: "",
      focused: false
    };
  },
  computed: {
    ...mapState([
      "activeDeal",
      "activeDealMarketing",
      "activeIntel",
      "currentUser",
      "nameFilter",
      "emailRegex",
      "intelCreationInProcess",
      "dealCreationInProcess",
      "yearWeek",
      "adminMode"
    ]),
    ...mapGetters([
      "creatingIntel",
      "creatingDeal",
      "creatingActiveDeal",
      "creatingIndirectActiveDeal",
      "privacyMode"
    ]),
    isDealBackchannel() {
      return this.$store.state.route.path === "/deals/new/12";
    },
    isIntelBackchannel() {
      return this.$store.state.route.path === "/intel/new/2a";
    },
    sendingPostToBackchannel() {
      return !!this.postContent;
    },
    isBackchannel() {
      return (
        this.isIntelBackchannel ||
        this.isDealBackchannel ||
        this.sendingPostToBackchannel
      );
    },
    combinedContacts() {
      const {
        followedUsers,
        followingUsers,
        fellowClosedDealParticipants
      } = this.currentUser.contacts;
      const combined = _.concat(
        followedUsers,
        followingUsers,
        fellowClosedDealParticipants
      );
      const unique = _.uniqBy(combined, "token");

      return _.sortBy(unique, ["name"]);
    },
    messageComplete() {
      return this.message !== "" && _.trim(this.message) !== "";
    },
    hasContacts() {
      return !_.isEmpty(this.combinedContacts);
    },
    hasInvitees() {
      return !_.isEmpty(this.invitees);
    },
    inviteeList() {
      return this.invitees.map(invitee => {
        return {
          email: invitee.email,
          token: invitee.token || null,
          message: this.messageComplete ? this.message : null
        };
      });
    },
    queryParams() {
      if (this.creatingIndirectActiveDeal) {
        return { query: { active: true, indirect: true } };
      } else if (this.creatingActiveDeal) {
        return { query: { active: true } };
      } else {
        return {};
      }
    },
    filteredContacts() {
      if (this.hasContacts) {
        if (this.nameFilter) {
          return this.combinedContacts.filter(c => {
            return this.$store.getters.contactNameFilter(c);
          });
        } else {
          return [];
        }
      } else {
        return [];
      }
    }
  },
  mounted() {
    if (this.creatingIntel && !this.intelCreationInProcess) {
      this.$router.push({ path: "/intel/new/1" });
    } else if (this.creatingDeal && !this.dealCreationInProcess) {
      this.$router.push(_.merge({ path: "/deals/new/1" }, this.queryParams));
    } else {
      this.$store.commit("creatingDealParticipantInvitation");

      this.$store.dispatch("loadMyContacts").then(() => {
        this.loaded = true;
        setTimeout(() => {
          document.getElementsByClassName("autocomplete-input")[0].focus();
        }, 100);
      });
    }
  },
  destroyed() {
    this.$store.commit("cancelDealParticipantInvitation");
  },
  methods: {
    confirmPostPrivacy() {
      if (this.hasInvitees) {
        if (this.sendingPostToBackchannel) {
          Swal.fire(selfPostOptions).then(result => {
            if (result.value) {
              this.postToBackchannel();
            }
          });
        } else {
          Swal.fire(anonymousPostOptions).then(result => {
            if (result.value) {
              this.postToBackchannel();
            }
          });
        }
      }
    },
    next() {
      if (this.hasInvitees && this.isBackchannel) {
        this.$store.commit("setPrivateAudience", this.inviteeList);

        if (analytics) {
          analytics.track("Private channel audience created", {
            invitees: this.inviteeList.length,
            type: this.isIntelBackchannel ? "intel" : "deal",
            yearWeek: this.yearWeek
          });
        }

        if (this.isIntelBackchannel) {
          switch (this.activeIntel.type) {
            case "jobPosting":
            case "industryEvent":
              this.$router.push({ path: "/intel/new/3" });
              break;
            case "developmentNews":
              this.$router.push({ path: "/intel/new/5" });
              break;
            case "marketCommentary":
            case "marketReport":
              this.$router.push({ path: "/intel/new/5" });
              break;
            case "infrastructureNews":
            case "tenantInMarket":
              this.$router.push({ path: "/intel/new/6" });
              break;
          }
        } else if (this.isDealBackchannel) {
          this.$router.push(_.merge({ path: "/deals/new/11" }, this.queryParams));
        }
      }
    },
    invite() {
      if (this.hasInvitees) {
        this.invitees.forEach(invitee => {
          const invitation = {
            email: invitee.email,
            token: invitee.token || null
          };
          const payload = {
            invitation,
            id: this.marketingList ? this.activeDealMarketing.id : this.dealId
          };

          if (this.marketingList) {
            this.$store.dispatch("createDealProspectInvitation", payload);
          } else {
            this.$store.dispatch("createDealParticipantInvitation", payload);
          }
        });
        this.$store.commit("closeModal");
      }
    },
    postToBackchannel() {
      if (this.hasInvitees) {
        if (this.isIntelBackchannel || this.isDealBackchannel) {
          this.$store.commit("setPrivateAudience", this.inviteeList);
        }

        if (analytics) {
          analytics.track("Private channel audience created", {
            invitees: this.inviteeList.length,
            type: this.isIntelBackchannel ? "intel" : "deal",
            yearWeek: this.yearWeek
          });
        }

        if (this.isIntelBackchannel) {
          switch (this.activeIntel.type) {
            case "jobPosting":
              this.$store.dispatch("createJobPosting");
              break;
            case "industryEvent":
              this.$store.commit("clearIntelDateType");
              this.$store.dispatch("createIndustryEvent");
              break;
            case "developmentNews":
              this.$store.dispatch("createDevelopmentNews");
              break;
            case "infrastructureNews":
              this.$store.dispatch("createInfrastructureNews");
              break;
            case "tenantInMarket":
              this.$store.dispatch("createTenantInMarket");
              break;
            case "marketCommentary":
              this.$store.dispatch("createMarketCommentary");
              break;
            case "marketReport":
              this.$store.dispatch("createMarketReport");
              break;
          }
        } else if (this.isDealBackchannel) {
          this.$store.dispatch("createDeal");
        } else if (this.sendingPostToBackchannel) {
          this.$store.dispatch("sendPostToBackchannels", {
            privateAudience: this.inviteeList,
            contentToken: this.postContent.token,
            contentType: this.postContent.newsfeedType
          });

          if (analytics) {
            analytics.track("Content shared", {
              invitees: this.inviteeList.length,
              type: this.postContent.newsfeedType,
              yearWeek: this.yearWeek
            });
          }

          this.$store.commit("closeModal");
        }
      }
    },
    searchContacts(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        this.$store.commit("setNameFilter", input);
        this.setLookupHelpText();
        resolve(this.filteredContacts);
      });
    },
    setLookupHelpText() {
      const blindCopy = this.isBackchannel
        ? "Each person enters a 1:1 private channel with you. No one but you is able to see the other recipients."
        : "";

      if (this.$refs.contactSearch.value === "") {
        this.helpText = this.hasInvitees
          ? blindCopy
          : "Search your contacts or enter an email address.";
      } else if (this.filteredContacts.length > 0) {
        this.helpText = "Select a contact from the results or press ENTER.";
      } else if (this.emailRegex.test(this.$refs.contactSearch.value)) {
        this.helpText = "Press ENTER to add this email to the invite list.";
      } else {
        this.helpText = "Not a valid email.";
      }
    },
    getResultValue(contact) {
      return contact.name;
    },
    handleSubmit(contact) {
      if (this.channelLookup) {
        this.channelLookupSubmit(contact);
      } else {
        this.inviteLookupSubmit(contact);
      }
    },
    channelLookupSubmit(contact) {
      if (contact) {
        const privateAudience = [
          {
            token: contact.token
          }
        ];

        this.$store
          .dispatch("createBackchannel", { privateAudience })
          .then(backchannelToken => {
            this.$router.push({
              path: `/backchannel/${backchannelToken}`
            });
            this.$store.commit("closeModal");
            this.$store.commit("clearNameFilter");
          });

        if (!!analytics && !this.adminMode) {
          analytics.track(`Opened private channel`, { yearWeek: this.$store.state.yearWeek });
        }
      }
    },
    inviteLookupSubmit(contact) {
      const output = contact
        ? contact
        : {
            name: this.$refs.contactSearch.value,
            email: this.$refs.contactSearch.value,
            id: Date.now()
          };
      const validAddition = contact || this.emailRegex.test(this.$refs.contactSearch.value);
      const duplicate = _.includes(this.invitees.map(invitee => invitee.name), output.name);

      if (validAddition && !duplicate) {
        this.invitees.push(output);
      }

      this.$refs.contactSearch.value = "";
      this.setLookupHelpText();
      this.$store.commit("clearNameFilter");
    },
    remove(inviteeName) {
      this.invitees = this.invitees.filter(c => c.name !== inviteeName);
    },
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form,
.description-wrapper {
  display: flex;
  flex-direction: column;
  width: 330px;
}
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.privacy-active {
    border: 2px solid #d61f69;
  }
  &.active {
    border: 2px solid #5850ec;
  }
}
button.control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.privacy-complete {
    background: #d61f69;
    box-shadow: rgba(214, 31, 105, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #f17eb8;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
