<template>
  <div>
    <form @submit.prevent="addToDataSet">
      <div>
        <label for="property-search" class="block text-sm font-medium leading-5 text-gray-700">
          Look up
        </label>
        <div class="mt-1 flex rounded-md shadow-sm">
          <div class="relative flex-grow focus-within:z-10">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <!-- Heroicon name: search -->
              <svg class="h-5 w-5 text-gray-400" style="z-index: 1;" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
              </svg>
            </div>
            <autocomplete
              id="search"
              ref="linkSearch"
              type="text"
              base-class="custom-autocomplete-leading-icon"
              :search="searchLinks"
              :auto-select="true"
              :get-result-value="getLinkResultValue"
              :debounce-time="500"
              @submit="handleLinkSubmit"
              placeholder="Name, address, etc."
            />
          </div>
          <div ref="searchCategoryMenu" class="relative inline-flex text-left">
            <button @click="toggleMenu" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
              <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path v-if="searchCategory === 'deal'" d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
                <path v-else-if="searchCategory === 'intel'" d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"/>
                <template v-else-if="searchCategory === 'premium'">
                  <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
                </template>
              </svg>
              {{ searchCategory | capitalize }}
              <!-- Heroicon name: chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>

            <transition
              enter-active-class="duration-100 ease-out"
              enter-class="opacity-0 scale-95"
              enter-to-class="opacity-100 scale-100"
              leave-active-class="duration-75 ease-in"
              leave-class="opacity-100 scale-100"
              leave-to-class="opacity-0 scale-95"
            >
              <div v-show="menuOpen" class="origin-top-right absolute left-0 sm:right-0 sm:left-auto mt-2 w-36 rounded-md shadow-lg" style="z-index: 10001 !important;">
                <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
                  <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <a href="" @click.prevent="dealSearch" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                      <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
                      </svg>
                      Deal
                    </a>
                    <a href="" @click.prevent="intelSearch" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                      <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"/>
                      </svg>
                      Intel
                    </a>
                    <a href="" @click.prevent="premiumSearch" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                      <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
                      </svg>
                      Premium
                    </a>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>

      <div v-if="content" class="max-w-xl mx-auto py-8 px-4 pointer-events-none">
        <multi-content-preview-card
          v-if="content.newsfeedType === 'deal' || content.newsfeedType === 'intel'"
          :content="content"
          :preview="true"
        />
        <purchaseable-preview v-else-if="content.newsfeedType === 'purchaseable'" class="max-w-lg my-16" :purchaseable="content" />
      </div>
      <div v-else class="max-w-3xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="text-center">
          <h1 class="text-base leading-6 font-semibold text-indigo-600 tracking-wide uppercase">preview</h1>
          <p class="mt-1 text-2xl leading-10 font-extrabold text-gray-900 sm:text-3xl sm:leading-none sm:tracking-tight lg:text-4xl">Preview here</p>
          <p class="max-w-xl mt-5 mx-auto text-lg leading-7 text-gray-500">See the record before you add it.</p>
        </div>
      </div>

      <div class="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
          <button type="submit" class="inline-flex items-center justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo sm:text-sm sm:leading-5">
            Add to Data Set
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
          <button @click="cancel" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue sm:text-sm sm:leading-5">
            Cancel
          </button>
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import * as moment from "moment";
import MultiContentPreviewCard from "../cards/multi-content-preview-card";
import PurchaseablePreview from "../cards/purchaseable-preview";
import api from "../../api";

export default {
  components: { MultiContentPreviewCard, PurchaseablePreview },
  props: ["dataSetId"],
  data() {
    return {
      menuOpen: false,
      searchCategory: "deal",
      content: null
    };
  },
  computed: {
    endpoint() {
      switch (this.searchCategory) {
        case "deal":
          return "search_deals";
        case "intel":
          return "search_intel";
        case "premium":
          return "search_purchaseables";
        default:
          return null;
      }
    }
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.searchCategoryMenu &&
        !this.$refs.searchCategoryMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    cancel() {
      this.$emit("done-editing");
    },
    dealSearch() {
      this.searchCategory = "deal";
      this.menuOpen = false;
    },
    intelSearch() {
      this.searchCategory = "intel";
      this.menuOpen = false;
    },
    premiumSearch() {
      this.searchCategory = "premium";
      this.menuOpen = false;
    },
    searchLinks(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        const query = input === "" || input === null ? null : _.toLower(input);

        if (this.endpoint) {
          api.post(this.endpoint, { name: query }).then(
            json => {
              this.content = null;
              resolve(json.data);
            },
            failure => {
              this.$store.dispatch("flash", "Invalid search");
            }
          );
        }
      });
    },
    getLinkResultValue(result) {
      switch (this.searchCategory) {
        case "deal":
          return this.dealSearchResult(result);
        case "intel":
          return this.intelSearchResult(result);
        case "premium":
          return this.premiumSearchResult(result);
        default:
          return `${result.name}`;
      }
    },
    dealSearchResult(deal) {
      const dealType = this.$options.filters.dealTypeAlias(
        deal.type,
        deal.jointVentureRoles
      );
      const location = this.$options.filters.summarizedLocation(
        deal.properties
      );
      const state = deal.closeDate
        ? moment(deal.closeDate).format("MMM YYYY")
        : this.$options.filters.capitalize(deal.state);

      return `${deal.name}\n${location} (${deal.useTypes})\n${dealType}\n${state}`;
    },
    intelSearchResult(intel) {
      const postType = this.$options.filters.postTypeAlias(intel.type);
      const createdAt = moment(intel.createdAt).format("MMM YYYY");
      const notes = this.$options.filters.truncate(intel.notes, 40);

      return `${intel.name}\n${postType}\n${createdAt}\n${notes}`;
    },
    premiumSearchResult(purchaseable) {
      const description = this.$options.filters.truncate(
        purchaseable.description,
        40
      );
      const updatedAt = moment(purchaseable.updatedAt).format("MMM YYYY");

      return `${purchaseable.name}\n${purchaseable.contentName}\n${description}\n${updatedAt}`;
    },
    handleLinkSubmit(result) {
      if (result) {
        this.fetchContent(result);
        this.$refs.linkSearch.value = "";
        this.searching = false;
      } else {
        this.$store.dispatch(
          "flash",
          "No matching content in Tower Hunt. Why don't you create it?"
        );
      }
    },
    fetchContent(result) {
      let fetchEndpoint = null;

      switch (this.searchCategory) {
        case "deal":
          fetchEndpoint = "open_deal";
          break;
        case "intel":
          fetchEndpoint = "open_intel";
          break;
        case "premium":
          fetchEndpoint = "purchaseables";
      }

      if (fetchEndpoint) {
        this.$store.commit("isLoading");
        api.get(`${fetchEndpoint}/${result.token}`).then(
          json => {
            this.content = json.data;
            this.$store.commit("doneLoading");
          },
          failure => {
            this.$store.dispatch("flash", "Sorry, we couldn't load this record.");
            this.$store.commit("doneLoading");
          }
        );
      }
    },
    addToDataSet() {
      if (this.content) {
        const payload = {
          contentToken: this.content.token,
          contentType: this.content.newsfeedType
        };

        this.$emit("link-element", payload);
      }
    }
  }
};
</script>
