<template>
  <header class="bg-gradient-to-r from-yellow-300 to-yellow-500 space-y-1 py-6 px-4 sm:px-6">
    <div class="flex items-center justify-between space-x-3">
      <h2 class="text-lg leading-7 font-medium text-white">
        Anonymous Chat
      </h2>
      <div class="h-7 flex items-center">
        <button @click="close" aria-label="Close panel" class="text-white">
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
    </div>
    <div>
      <p class="text-white text-sm leading-5">
        {{ bounty.requestName | truncate(140) }}
      </p>
    </div>
    <div>
      <p class="text-white flex items-center text-sm leading-5">
        ${{ bid.bountyPrice }} Bid &middot; Expires {{ expiration | moment("from") }}
      </p>
    </div>
  </header>
</template>

<script>
export default {
  props: ["bounty", "bid"],
  computed: {
    expiration() {
      if (this.bid.state === "pending") {
        return this.bounty.expiresAt;
      } else {
        return this.bid.expiresAt;
      }
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
