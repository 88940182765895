<template>
  <div>
    <dl v-if="target && hasRegions" class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div v-if="showDealSize" class="sm:col-span-1">
        <dt class="text-sm leading-5 font-medium text-gray-500">
          <template v-if="target.authored">
            <router-link
              @click.native="openModal({ record: target, type: 'target-risk-types' })"
              to=""
              class="flex items-center text-indigo-600 hover:text-indigo-500"
            >
              <span>Strategies</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </router-link>
          </template>
          <template v-else>Strategies</template>
        </dt>
        <dd class="mt-1 text-sm leading-5 text-gray-900">
          <template v-if="target.risks.length > 0">{{
            target.risks | sortedTypeLabels
          }}</template>
          <template v-else>Any</template>
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-sm leading-5 font-medium text-gray-500">
          <template v-if="target.authored">
            <router-link
              @click.native="openModal({ record: target, type: 'target-regions' })"
              to=""
              class="flex items-center text-indigo-600 hover:text-indigo-500"
            >
              <span>Regions</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </router-link>
          </template>
          <template v-else>Regions</template>
        </dt>
        <dd class="mt-1 text-sm leading-5 text-gray-900">
          {{ target.regions | regionLabels }}
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-sm leading-5 font-medium text-gray-500">
          <div class="flex items-center" v-if="target.authored">
            <router-link
              @click.native="openModal({ record: target, type: 'target-deal-types' })"
              to=""
              class="flex items-center text-indigo-600 hover:text-indigo-500"
            >
              <span>Deal types</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </router-link>
            <template v-if="showJVRoles">
              <span>&nbsp;&&nbsp;</span>
              <router-link
                @click.native="openModal({ record: target, type: 'target-jv-roles' })"
                to=""
                class="flex items-center text-indigo-600 hover:text-indigo-500"
              >
                <span>JV roles</span>
                <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
              </router-link>
            </template>
          </div>
          <template v-else>Deal types</template>
        </dt>
        <dd class="mt-1 text-sm leading-5 text-gray-900">
          <template v-if="target.deals.length > 0">{{
            target.deals | sortedTypeLabels
          }}</template>
          <template v-else>Any</template>
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-sm leading-5 font-medium text-gray-500">
          <template v-if="target.authored">
            <router-link
              @click.native="openModal({ record: target, type: 'target-uses' })"
              to=""
              class="flex items-center text-indigo-600 hover:text-indigo-500"
            >
              <span>Property uses</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </router-link>
          </template>
          <template v-else>Property uses</template>
        </dt>
        <dd class="mt-1 text-sm leading-5 text-gray-900">
          <template v-if="target.uses.length > 0">{{ target.uses | sortedTypeLabels }}</template>
          <template v-else>Any</template>
        </dd>
      </div>
      <div v-if="showDealSize" class="sm:col-span-1">
        <dt class="text-sm leading-5 font-medium text-gray-500">
          <template v-if="target.authored">
            <router-link
              @click.native="openModal({ record: target, type: 'target-deal-size' })"
              to=""
              class="flex items-center text-indigo-600 hover:text-indigo-500"
            >
              <span>Deal size</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </router-link>
          </template>
          <template v-else>Deal size</template>
        </dt>
        <dd class="mt-1 text-sm leading-5 text-gray-900">
          <template v-if="target.minimum || target.maximum">{{
            target | priceRange
          }}</template>
          <template v-else>Any</template>
        </dd>
      </div>
      <div v-if="showLeaseSize" class="sm:col-span-1">
        <dt class="text-sm leading-5 font-medium text-gray-500">
          <template v-if="target.authored">
            <router-link
              @click.native="openModal({ record: target, type: 'target-lease-size' })"
              to=""
              class="flex items-center text-indigo-600 hover:text-indigo-500"
            >
              <span>Lease size</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </router-link>
          </template>
          <template v-else>Lease size</template>
        </dt>
        <dd class="mt-1 text-sm leading-5 text-gray-900">
          <template v-if="target.leaseMinimum || target.leaseMaximum">{{
            target | leaseRange
          }}</template>
          <template v-else>Any</template>
        </dd>
      </div>
      <div v-if="showLeverageSize" class="sm:col-span-1">
        <dt class="text-sm leading-5 font-medium text-gray-500">
          <template v-if="target.authored">
            <router-link
              @click.native="openModal({ record: target, type: 'target-leverage' })"
              to=""
              class="flex items-center text-indigo-600 hover:text-indigo-500"
            >
              <span>Leverage</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </router-link>
          </template>
          <template v-else>Leverage</template>
        </dt>
        <dd class="mt-1 text-sm leading-5 text-gray-900">
          <template v-if="target.leverageMinimum || target.leverageMaximum">{{
            target | leverageRange
          }}</template>
          <template v-else>Any</template>
        </dd>
      </div>
      <div v-if="showLoanTypes" class="sm:col-span-1">
        <dt class="text-sm leading-5 font-medium text-gray-500">
          <template v-if="target.authored">
            <router-link
              @click.native="openModal({ record: target, type: 'target-loan-seniority' })"
              to=""
              class="flex items-center text-indigo-600 hover:text-indigo-500"
            >
              <span>Loan seniority</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </router-link>
          </template>
          <template v-else>Loan seniority</template>
        </dt>
        <dd class="mt-1 text-sm leading-5 text-gray-900">
          <template v-if="target.loans.length > 0">{{
            target.loans | sortedTypeLabels
          }}</template>
          <template v-else>Any</template>
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-sm leading-5 font-medium text-gray-500">
          <template v-if="target.authored">
            <router-link
              @click.native="openModal({ record: target, type: 'target-property-size' })"
              to=""
              class="flex items-center text-indigo-600 hover:text-indigo-500"
            >
              <span>Property size</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </router-link>
          </template>
          <template v-else>Property size</template>
        </dt>
        <dd class="mt-1 text-sm leading-5 text-gray-900">
          <template v-if="targetHasPropertySize">
            {{ target.uses | propertySizeRange }}
          </template>
          <template v-else>Any</template>
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-sm leading-5 font-medium text-gray-500">
          Location
        </dt>
        <div class="mt-4 h-48 relative">
          <div class="absolute inset-x-0 top-0 transform translate-y-px pointer-events-none" style="z-index: 9995;">
            <div class="flex justify-center transform -translate-y-1/2">
              <a @click.prevent="strategicMap" href="" class="inline-flex items-center rounded-full text-sm px-4 py-1 leading-5 bg-indigo-600 hover:bg-indigo-700 font-semibold tracking-wider uppercase text-white pointer-events-auto">
                <!-- Heroicon name: cursor-click -->
                <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z" clip-rule="evenodd" />
                </svg>
                See on strategic map
              </a>
            </div>
          </div>

          <target-map v-if="hasRegions" :target="target" />
        </div>
      </div>
      <div v-if="target.notes || target.authored" class="sm:col-span-1">
        <dt class="text-sm leading-5 font-medium text-gray-500">
          <template v-if="target.authored">
            <router-link
              @click.native="openModal({ record: target, type: 'target-notes' })"
              to=""
              class="flex items-center text-indigo-600 hover:text-indigo-500"
            >
              <span>Additional context</span>
              <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
            </router-link>
          </template>
          <template v-else>Additional context</template>
        </dt>
        <dd v-if="target.notes" class="mt-1 text-sm leading-5 text-gray-900 whitespace-pre-line">{{ target.notes }}</dd>
        <dd v-else class="mt-1 text-sm leading-5 text-gray-900 whitespace-pre-line">...</dd>
      </div>
    </dl>
    <div v-else class="flex justify-center">
      <grid-loader :loading="true" size="6px" color="#5850ec" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import TargetDealSize from "../../views/onboarding/onboarding-deal-size";
import TargetDealTypes from "../../views/onboarding/onboarding-deal-type";
import TargetJVRoles from "../../views/onboarding/onboarding-jv-role";
import TargetLeaseSize from "../../views/onboarding/onboarding-lease-size";
import TargetLeverage from "../../views/onboarding/onboarding-leverage-size";
import TargetLoanSeniority from "../../views/onboarding/onboarding-loan-seniority";
import TargetMap from "../maps/target-map";
import TargetNotes from "../../views/onboarding/general-notes";
import TargetPropertySizes from "../../views/onboarding/onboarding-property-size";
import TargetRegions from "../../views/onboarding/onboarding-regions";
import TargetRiskTypes from "../../views/onboarding/onboarding-risk-type";
import TargetUses from "../../views/onboarding/onboarding-use-type";

export default {
  components: { GridLoader, TargetMap },
  computed: {
    ...mapState([
      "refetchTargetPage",
      "refetchRumoredDeals",
      "currentUser",
      "modal",
      "activeTarget"
    ]),
    ...mapGetters([
      "signedIn",
      "showDealSize",
      "showLeaseSize",
      "showLeverageSize",
      "showLoanTypes",
      "showJVRoles",
      "targetHasPropertySize"
    ]),
    target() {
      return this.activeTarget;
    },
    hasRegions() {
      return _.get(this.target, "regions", []).length > 0;
    }
  },
  methods: {
    strategicMap() {
      if (this.signedIn) {
        this.$router.push({
          path: `/map`,
          query: { market: this.target.token }
        });
        this.$store.commit("closeModal");
      } else {
        this.$router.push({
          path: "/sign-in",
          query: { nextUrl: `/map?market=${this.target.token}` }
        });
        this.$store.commit("closeModal");
      }
    },
    openModal({ record, type }) {
      let marketCardModal = false;

      if (this.modal) {
        marketCardModal = _.cloneDeep(this.modal); // obtains the current component
        marketCardModal.props.target = this.target; // allows changes to flow through
      }

      switch (type) {
        case "target-risk-types":
          this.$store.commit("mountTarget", { target: record });
          this.$store.commit("openModal", {
            component: TargetRiskTypes,
            props: {
              targetId: record.id
            },
            afterClose: marketCardModal
          });
          break;
        case "target-uses":
          this.$store.commit("openModal", {
            component: TargetUses,
            props: {
              targetId: record.id
            },
            afterClose: marketCardModal
          });
          break;
        case "target-deal-size":
          this.$store.commit("openModal", {
            component: TargetDealSize,
            props: {
              targetId: record.id
            },
            afterClose: marketCardModal
          });
          break;
        case "target-lease-size":
          this.$store.commit("openModal", {
            component: TargetLeaseSize,
            props: {
              targetId: record.id
            },
            afterClose: marketCardModal
          });
          break;
        case "target-leverage":
          this.$store.commit("openModal", {
            component: TargetLeverage,
            props: {
              targetId: record.id
            },
            afterClose: marketCardModal
          });
          break;
        case "target-loan-seniority":
          this.$store.commit("openModal", {
            component: TargetLoanSeniority,
            props: {
              targetId: record.id
            },
            afterClose: marketCardModal
          });
          break;
        case "target-property-size":
          this.$store.commit("openModal", {
            component: TargetPropertySizes,
            props: {
              targetId: record.id
            },
            afterClose: marketCardModal
          });
          break;
        case "target-deal-types":
          this.$store.commit("openModal", {
            component: TargetDealTypes,
            props: {
              targetId: record.id
            },
            afterClose: marketCardModal
          });
          break;
        case "target-jv-roles":
          this.$store.commit("openModal", {
            component: TargetJVRoles,
            props: {
              targetId: record.id
            },
            afterClose: marketCardModal
          });
          break;
        case "target-regions":
          this.$store.commit("openModal", {
            component: TargetRegions,
            props: {
              targetId: record.id
            },
            afterClose: marketCardModal
          });
          break;
        case "target-notes":
          this.$store.commit("openModal", {
            component: TargetNotes,
            props: {
              targetId: record.id
            },
            afterClose: marketCardModal
          });
          break;
      }
    }
  }
};
</script>
