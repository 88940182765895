<template>
  <div>
    <div v-if="card.message && sufficientCapData" class="border-t border-gray-200 rounded-b-lg w-full flex">
      <button @click="flipCard" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-b-lg hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 focus:z-10">
        <!-- Heroicon name: trending-up -->
        <svg class="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z" clip-rule="evenodd" />
        </svg>
        <span class="ml-3">View cap rates</span>
      </button>
    </div>
    <div v-else-if="card.message" class="border-t border-gray-200 rounded-b-lg w-full flex">
      <div class="relative -mr-px w-0 flex-1 rounded-b-lg inline-flex items-center justify-center py-4 text-sm leading-5 font-medium border-gray-300 text-gray-700 focus:outline-none">
        <!-- Heroicon name: mail -->
        <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
        </svg>
        <span class="ml-3">Messaged owner {{ card.message.createdAt | moment("from") }}</span>
      </div>
    </div>
    <div v-else-if="motivation && sufficientCapData" class="border-t border-gray-200">
      <div class="-mt-px flex">
        <div class="w-0 flex-1 flex border-r border-gray-200">
          <button @click="flipCard" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 focus:z-10">
            <!-- Heroicon name: trending-up -->
            <svg class="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z" clip-rule="evenodd" />
            </svg>
            <span class="ml-2">View cap rates</span>
          </button>
        </div>
        <div v-if="card.expired" class="-ml-px w-0 flex-1 flex">
          <button class="relative -mr-px w-0 flex-1 rounded-br-lg inline-flex items-center justify-center py-4 text-sm leading-5 font-medium focus:outline-none border-transparent text-white bg-gray-500 hover:bg-gray-400 focus:border-gray-600 focus:ring-gray active:bg-gray-600 cursor-not-allowed">
            <!-- Heroicon name: clock -->
            <svg class="w-5 h-5 text-white" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
            </svg>
            <span class="ml-2">Expired</span>
          </button>
        </div>
        <div v-else class="-ml-px w-0 flex-1 flex">
          <button @click="messageOwner" class="relative -mr-px w-0 flex-1 rounded-br-lg inline-flex items-center justify-center py-4 text-sm leading-5 font-medium focus:outline-none" :class="buttonStyling">
            <!-- Heroicon name: chat -->
            <svg class="w-5 h-5" :class="buttonIconStyling" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd" />
            </svg>
            <span class="ml-2">{{ motivation | capitalize }} owner</span>
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="card.expired" class="border-t border-gray-200 rounded-b-lg w-full flex">
      <button class="relative -mr-px w-0 flex-1 rounded-b-lg inline-flex items-center justify-center py-4 text-sm leading-5 font-medium focus:outline-none border-transparent text-white bg-gray-500 hover:bg-gray-400 focus:border-gray-600 focus:ring-gray active:bg-gray-600 cursor-not-allowed">
        <!-- Heroicon name: clock -->
        <svg class="w-5 h-5 text-white" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
        </svg>
        <span class="ml-3">Expired</span>
      </button>
    </div>

    <div v-else-if="motivation" class="border-t border-gray-200 w-full flex">
      <button @click="messageOwner" class="relative -mr-px w-0 flex-1 rounded-b-lg inline-flex items-center justify-center py-4 text-sm leading-5 font-medium focus:outline-none" :class="buttonStyling">
        <!-- Heroicon name: chat -->
        <svg class="w-5 h-5" :class="buttonIconStyling" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd" />
        </svg>
        <span class="ml-3">{{ motivation | capitalize }} owner</span>
      </button>
    </div>

    <div v-else class="border-t border-gray-200 w-full flex">
      <div class="relative -mr-px w-0 flex-1 rounded-b-lg inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-300 font-medium border-gray-300">
        <!-- Heroicon name: clock -->
        <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
        </svg>
        <span class="ml-3">Cap rate approval pending</span>
      </div>
    </div>
  </div>
</template>

<script>
import OwnerMessage from "./owner-message";
import api from "../../../api";

export default {
  props: ["card"],
  data() {
    return {
      motivation: null
    };
  },
  computed: {
    sufficientCapData() {
      return (
        this.card.content.capRates && this.card.content.capRates.length > 0
      );
    },
    buttonStyling() {
      switch (this.motivation) {
        case "motivated":
          return "border-transparent text-white bg-green-500 hover:bg-green-400 focus:border-green-600 focus:ring-green active:bg-green-600";
        case "receptive":
          return "border-transparent text-white bg-teal-600 hover:bg-teal-500 focus:border-teal-700 focus:ring-teal active:bg-teal-700";
        case "unmotivated":
        default:
          return "border-gray-300 text-gray-700 hover:text-gray-500 focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800";
      }
    },
    buttonIconStyling() {
      switch (this.motivation) {
        case "motivated":
          return "text-white";
        case "receptive":
          return "text-white";
        case "unmotivated":
        default:
          return "text-gray-400";
      }
    }
  },
  mounted() {
    this.fetchMotivation();
  },
  methods: {
    fetchMotivation() {
      api.get(`off_market_property_owner_motivation/${this.card.id}`).then(
        json => {
          this.motivation = json.data.motivation;
        },
        failure => {
          return;
        }
      );
    },
    messageOwner() {
      this.$store.commit("openModal", {
        component: OwnerMessage,
        props: {
          card: this.card
        }
      });
    },
    flipCard() {
      this.$emit("flip");
    }
  }
};
</script>
