<template>
  <div />
</template>

<script>
import { mapGetters } from "vuex";
import api from "../api";

export default {
  props: ["propertyFollowingInvitationToken"],
  data() {
    return {
      invitation: null
    };
  },
  computed: {
    ...mapGetters(["signedIn"])
  },
  created() {
    api
      .get(
        `property_following_invitations/${this.propertyFollowingInvitationToken}`
      )
      .then(
        result => {
          const invitation = result.data;

          if (this.signedIn) {
            if (invitation.isInviter || invitation.isMember) {
              api
                .delete(
                  `property_following_invitations/${this.propertyFollowingInvitationToken}`
                )
                .then(() => {
                  let message, nextPath;

                  if (invitation.isInviter) {
                    message = "You can't accept your own invitation.";
                    nextPath = `/property-watchlist/${invitation.propertyFollowingId}/newsfeed`;
                  } else if (invitation.isMember) {
                    message = "This property is already on your watchlist.";
                    nextPath = `/property-watchlist/${invitation.propertyFollowingId}/newsfeed`;
                  }

                  this.$store.dispatch("flash", message);
                  this.$router.push({ path: nextPath });
                });
            } else {
              this.invitation = invitation;
              api
                .patch(
                  `property_following_invitations/${this.propertyFollowingInvitationToken}`
                )
                .then(() => {
                  this.$store.dispatch(
                    "flash",
                    `Access granted to ${invitation.propertyName}`
                  );
                  this.$router.push({
                    path: `/property-watchlist/${invitation.propertyFollowingId}/newsfeed`
                  });
                });
            }
          } else {
            this.$store.commit(
              "setNewUserPropertyFollowingInvitationToken",
              this.propertyFollowingInvitationToken
            );
            this.$router.push({ path: "/start/1" });
          }
        },
        failure => {
          this.$store.dispatch("flash", "Invitation no longer valid");
          this.$router.push({ path: "/" });
        }
      );
  }
};
</script>
