<template>
  <div class="z-1 flex flex-col relative">
    <div class="absolute bottom-2 left-2 text-gray-200" style="font-size: 8px;">{{ packMonthYear }}</div>
    <div class="flex-shrink-0">
      <div class="h-48 lg:h-64 bg-gray-800">
        <market-map :local-regions="pack.regions" />
      </div>
    </div>
    <div class="py-6 px-4 text-center bg-white lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-10">
      <div class="flex items-center justify-center">
        <img class="h-10 w-10 sm:h-12 sm:w-12" src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png" alt="Tower Hunt" />
      </div>
      <p class="mt-4 text-xl leading-6 font-semibold text-gray-900">
        Hunter Pack
      </p>
      <div class="mt-4 flex items-center justify-center text-lg leading-none font-medium text-gray-900">
        <span>{{ pack.cardCount }} Off-Market Assets</span>
      </div>
      <p class="mt-2 text-sm leading-5">
        <span class="font-medium text-gray-500">
          {{ pack.unmotivatedCount }} Unmotivated, {{ pack.receptiveCount }} Receptive, {{ pack.motivatedCount }} Motivated
        </span>
      </p>
      <div class="mt-6">
        <button @click="purchasePack" class="w-full rounded-md shadow">
          <div :class="buttonColor" class="flex items-center justify-center px-5 py-3 text-base leading-6 font-medium rounded-md focus:ring-gray">
            <template v-if="resumable && expiringSoon">{{ remainingCards }} left! &middot; Expires {{ expiresAt | moment("from") }}</template>
            <template v-else-if="expiringSoon">Open Pack &middot; Expires {{ expiresAt | moment("from") }}</template>
            <template v-else-if="purchasable">Open Pack</template>
            <template v-else-if="resumable">{{ remainingCards }} left!</template>
          </div>
        </button>
      </div>
      <div class="mt-4 text-sm leading-5">
        <router-link to="/product/off-market" class="font-medium text-gray-900">
          Learn more
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapState } from "vuex";
import MarketMap from "../../maps/target-map";
import api from "../../../api";
import pluralize from "pluralize";

export default {
  components: { MarketMap },
  props: ["pack"],
  computed: {
    ...mapState(["adminMode"]),
    expiresAt() {
      return moment(this.pack.createdAt).add(90, "days");
    },
    expiringSoon() {
      return moment(this.pack.createdAt)
        .add(90, "days")
        .subtract(1, "week")
        .isBefore();
    },
    packMonthYear() {
      return moment(this.pack.createdAt).format("MMMYYYY");
    },
    purchasable() {
      return !this.pack.purchased;
    },
    resumable() {
      return this.pack.purchased && !this.pack.completed;
    },
    remainingCards() {
      return pluralize("card", this.pack.untouchedCardCount || 0, true);
    },
    buttonColor() {
      // switch (this.pack.rating) {
      //   case "bronze":
      //     return "bg-gradient-to-r from-yellow-500 to-orange-700 text-white hover:to-orange-800";
      //   case "silver":
      //     return "bg-gradient-to-r from-cool-gray-200 to-gray-400 text-gray-900 hover:to-gray-500";
      //   case "gold":
      //     return "bg-gradient-to-r from-yellow-300 to-yellow-500 text-white hover:to-yellow-600";
      //   default:
      //     return "text-white bg-gray-900 hover:bg-gray-800";
      // }

      return "text-white bg-indigo-600 hover:bg-indigo-500";
    }
  },
  methods: {
    purchasePack() {
      api.patch(`purchase_card_pack/${this.pack.id}`).then(json => {
        if (!!analytics && !this.adminMode) {
          analytics.track("Card pack opened", {
            yearWeek: this.$store.state.yearWeek,
            rating: this.pack.rating
          });
        }

        this.$router.push({ path: `/open_pack/${this.pack.id}` });
      });
    }
  }
};
</script>
