<template>
  <div class="wrapper">
    <heading>When did this intel occur?</heading>
    <form novalidate>
      <v-date-picker mode="single" v-model="intelDate" :color="color" is-inline is-required />
      <button @click="confirmPrivacy" type="button" class="cta mt-4" :class="{ complete: true }" tabindex="999">Save</button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../../components/onboarding/heading";
import Swal from "sweetalert2";
import anonymousPostOptions from "../../../sweet-alert-always-anonymous-options";

export default {
  components: { Heading },
  props: ["postToken"],
  computed: {
    ...mapState(["activeIntel", "intelCreationInProcess"]),
    ...mapGetters(["creatingIntel", "intelHasSize", "privacyMode"]),
    color() {
      return this.privacyMode ? "pink" : "indigo";
    },
    intelDate: {
      get() {
        return new Date(this.activeIntel.createdAt);
      },
      set(date) {
        this.$store.commit("setIntelCreatedAt", date);
      }
    }
  },
  methods: {
    confirmPrivacy() {
      Swal.fire(anonymousPostOptions).then(result => {
        if (result.value) {
          this.next();
        }
      });
    },
    next() {
      this.$store.dispatch("updateIntel", {
        postToken: this.postToken,
        updateTags: false,
        backDate: true
      });
      this.$store.commit("closeModal");
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
}
button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.privacy-complete {
    background: #d61f69;
    box-shadow: rgba(214, 31, 105, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #f17eb8;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
  &.calendar-button {
    width: 256px !important;
  }
}
</style>
