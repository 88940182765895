<template>
  <li :class="{ 'mt-10 md:mt-0': order > 1 }">
    <div class="flex">
      <div class="flex-shrink-0">
        <div
          class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
          :class="iconEditClass"
          @click="openModal({ type: 'editIcon' })"
        >
          <svg
            v-if="talkingPoint"
            class="h-6 w-6"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
            v-html="pathsForIcon"
          />
          <svg
            v-else
            class="h-6 w-6"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 10V3L4 14h7v7l9-11h-7z"
            />
          </svg>
        </div>
      </div>
      <div class="ml-4 flex-grow">
        <div class="flex items-center justify-between">
          <h5
            @click="openModal({ type: 'editLabel' })"
            class="text-lg leading-6 font-medium text-gray-900"
            :class="textEditClass"
          >
            <template v-if="talkingPoint && talkingPoint.label">{{ talkingPoint.label }}</template>
            <template v-else>Talking point {{ order }}</template>
          </h5>
          <span v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" class="inline-flex rounded-md shadow-sm" v-tooltip="'Remove talking point'">
            <button @click="remove" type="button" class="inline-flex items-center px-2 py-1 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
              <svg class="h-3 w-3" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
            </button>
          </span>
        </div>
        <p
          @click="openModal({ type: 'editContent' })"
          class="mt-2 text-base leading-6 text-gray-500"
          :class="textEditClass"
        >
          <template v-if="talkingPoint && talkingPoint.content">{{ talkingPoint.content }}</template>
          <template v-else>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores
            impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis
            ratione.
          </template>
        </p>
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import IconSelector from "./icon-selector";
import MarketingTextField from "./marketing-text-field";
import icons from "../../heroicons-outline";

export default {
  props: ["order", "collection"],
  computed: {
    ...mapState(["activeDeal", "activeDealMarketing"]),
    talkingPoints() {
      if (this.collection) {
        return this.activeDealMarketing[this.collection];
      } else {
        return [];
      }
    },
    talkingPoint() {
      if (this.talkingPoints && this.talkingPoints.length === 0) {
        return null;
      } else {
        return _.find(this.talkingPoints, { order: this.order });
      }
    },
    iconEditClass() {
      if (!this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
        return [
          "hover:bg-indigo-400",
          "focus:outline-none",
          "focus:ring",
          "transition",
          "duration-150",
          "ease-in-out",
          "cursor-pointer"
        ];
      } else {
        return [];
      }
    },
    textEditClass() {
      if (!this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
        return ["hover:underline", "cursor-pointer"];
      } else {
        return [];
      }
    },
    icon() {
      return _.find(icons, { name: this.talkingPoint.iconName });
    },
    pathsForIcon() {
      const elements = this.icon.paths.map(path => {
        return `<path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="${path}"
            />`;
      });

      return elements.join("");
    },
    labelExample() {
      switch (this.order) {
        case 1:
          return "e.g., History of institutional ownership";
        case 2:
          return "e.g., LEED Gold";
        case 3:
          return "e.g., Investment-grade tenancy";
        case 4:
          return "e.g., Free and Clear";
        default:
          return "e.g., Nearby amenities";
      }
    },
    contentExample() {
      switch (this.order) {
        case 1:
          return "e.g., Current ownership invested $12M in capex over the last four years";
        case 2:
          return "e.g., One of only 4 gold-rated buildings in the city, Center Tower operating expenses are 10% below the competitive set.";
        case 3:
          return "e.g., 35% of the rent roll is investment grade, led by Amazon and Microsoft.";
        case 4:
          return "e.g., The property is being offered without existing debt, allowing an incoming owner to take advantage of historically low interest rates.";
        default:
          return "e.g., With one of the region's top-producing malls less than one mile away, the property provides convenient access to top-tier restaurants and shopping.";
      }
    }
  },
  methods: {
    remove() {
      this.$store.dispatch("removeDealMarketingOrderedItem", {
        item: this.talkingPoint
      });
    },
    openModal({ type }) {
      if (!this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
        switch (type) {
          case "editIcon":
            this.$store.commit("openModal", {
              component: IconSelector,
              props: {
                talkingPoint: this.talkingPoint,
                order: this.order,
                itemType: _.dropRight(this.collection, 1).join("")
              }
            });
            break;
          case "editLabel":
            this.$store.commit("openModal", {
              component: MarketingTextField,
              props: {
                heading: "Give this selling point a title",
                description: this.labelExample,
                getterName: "executiveSummaryTalkingPoints",
                getterField: "label",
                setterName: "setDealMarketingItemText",
                order: this.order
              }
            });
            break;
          case "editContent":
            this.$store.commit("openModal", {
              component: MarketingTextField,
              props: {
                heading: "Let's write the selling point",
                description: this.contentExample,
                getterName: "executiveSummaryTalkingPoints",
                getterField: "content",
                setterName: "setDealMarketingItemText",
                order: this.order
              }
            });
            break;
        }
      }
    }
  }
};
</script>
