<template>
  <div id="app">
    <loading :active.sync="loading" color="#5850ec" />
    <modal :value="modal">
      <component :is="modalComponent" v-bind="modalProps" />
    </modal>
    <xl-modal :value="xlModal">
      <component :is="xlModalComponent" v-bind="xlModalProps" />
    </xl-modal>
    <slideover :value="slideover">
      <component :is="slideoverComponent" v-bind="slideoverProps" />
    </slideover>
    <router-view v-if="identityChecked" id="body-container" />
    <flash />
    <GmapMap
      v-show="false"
      ref="gmap"
      :center="{ lat: 39.8283, lng: -98.5795 }"
      :zoom="4"
      map-type-id="roadmap"
      style="width: 1px; height: 1px;"
    />
    <!-- map is invoked but hidden to make sure api gets called -->
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters, mapState } from "vuex";
import Flash from "./components/flash";
import Loading from "vue-loading-overlay";
import Modal from "./components/modal";
import Slideover from "./components/slideover";
import User from "./user";
import api from "./api";
import xlModal from "./components/xl-modal";

export default {
  components: { Flash, Loading, Modal, xlModal, Slideover },
  data() {
    return {
      identityChecked: false
    };
  },
  created() {
    api.get("whoami").then(
      user => {
        this.$store.commit("setAdminMode");
        if (user.data.signedIn) {
          this.$store.dispatch("signIn", new User(user.data));
        } else if (localStorage["token"]) {
          localStorage.removeItem("token");
        }
        this.identityChecked = true;
      },
      failure => {
        this.$store.commit("setAdminMode");
        if (localStorage["token"]) {
          localStorage.removeItem("token");
          location.reload();
        }
        this.identityChecked = true;
      }
    );
  },
  mounted() {
    this.onResize();

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });

    const referrerToken = _.get(
      this.$store.state.route,
      "query.referrer",
      null
    );

    if (referrerToken) {
      this.$store.commit("setNewUserReferrerToken", referrerToken);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    ...mapState([
      "onboardingInProcess",
      "targetCreationInProcess",
      "loading",
      "modal",
      "xlModal",
      "slideover"
    ]),
    ...mapGetters(["signedIn", "isDesktop", "onboarding", "creatingTarget"]),
    isLoading() {
      return this.$store.state.loading;
    },
    slideoverComponent() {
      return _.get(this.slideover, "component", null);
    },
    slideoverProps() {
      return _.get(this.slideover, "props", {});
    },
    modalComponent() {
      return _.get(this.modal, "component", null);
    },
    modalProps() {
      return _.get(this.modal, "props", {});
    },
    xlModalComponent() {
      return _.get(this.xlModal, "component", null);
    },
    xlModalProps() {
      return _.get(this.xlModal, "props", {});
    }
  },
  methods: {
    onResize() {
      this.$store.commit("setWindowWidth", window.innerWidth);
    }
  }
};
</script>

<style lang="scss">
@import "tailwindcss/base";
@import "tailwindcss/components";

.leaflet-pulsing-icon {
  border-radius: 5px;
  box-shadow: 1px 1px 8px 0 rgba(0,0,0,0.75);
}

.leaflet-pulsing-icon:after {
  content: "";
  border-radius: 100%;
  height: 300%;
  width: 300%;
  position: absolute;
  // margin: -100% 0 0 -100%;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.leaflet-popup-content {
  margin: 0;
}

.leaflet-container {
  font: unset;
}

.custom-autocomplete-flush[data-loading="true"]::after {
  content: "";
  z-index: 1;
  border: 3px solid rgba(0, 0, 0, 0.12);
  border-right: 3px solid rgba(0, 0, 0, 0.48);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  animation: rotate 1s infinite linear;
}

.custom-autocomplete-flush-input {
  @apply block w-full rounded-md bg-gray-100 border-none transition;
}

.custom-autocomplete-flush-input:focus {
  @apply block ring-transparent ring-0;
}

.custom-autocomplete-flush-result-list {
  @apply rounded-md bg-white py-1 text-base leading-6 shadow-lg overflow-auto;
  max-height: 296px;
}

.custom-autocomplete-flush-result-list:focus {
  @apply outline-none;
}

.custom-autocomplete-flush-result {
  @apply font-normal block whitespace-pre-line text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9;
}

.custom-autocomplete-flush-result:hover,
.custom-autocomplete-flush-result[aria-selected="true"] {
  @apply text-indigo-700 bg-indigo-100;
}

@screen sm {
  .custom-autocomplete-flush-input,
  .custom-autocomplete-flush-result-list {
    @apply text-sm leading-5;
  }
}

.custom-autocomplete-leading-icon[data-loading="true"]::after,
.custom-autocomplete-leading-icon-full-round[data-loading="true"]::after {
  content: "";
  z-index: 1;
  border: 3px solid rgba(0, 0, 0, 0.12);
  border-right: 3px solid rgba(0, 0, 0, 0.48);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  animation: rotate 1s infinite linear;
}

.custom-autocomplete-leading-icon-input {
  @apply block w-full rounded-none rounded-l-md pl-10 shadow-sm border-gray-300 transition;
}

.custom-autocomplete-leading-icon-full-round-input {
  @apply block w-full rounded-none rounded-md pl-10 shadow-sm border-gray-300 transition;
}

.custom-autocomplete-leading-icon-result-list,
.custom-autocomplete-leading-icon-full-round-result-list {
  @apply rounded-md bg-white py-1 text-base leading-6 shadow-lg overflow-auto;
  max-height: 296px;
}

.custom-autocomplete-leading-icon-result-list:focus,
.custom-autocomplete-leading-icon-full-round-result-list:focus {
  @apply outline-none;
}

.custom-autocomplete-leading-icon-result,
.custom-autocomplete-leading-icon-full-round-result {
  @apply font-normal block whitespace-pre-line text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9;
}

.custom-autocomplete-leading-icon-result:hover,
.custom-autocomplete-leading-icon-result[aria-selected="true"],
.custom-autocomplete-leading-icon-full-round-result:hover,
.custom-autocomplete-leading-icon-full-round-result[aria-selected="true"] {
  @apply text-white bg-indigo-600;
}

@screen sm {
  .custom-autocomplete-leading-icon-input,
  .custom-autocomplete-leading-icon-result-list,
  .custom-autocomplete-leading-icon-full-round-input,
  .custom-autocomplete-leading-icon-full-round-result-list {
    @apply text-sm leading-5;
  }
}

.markdown {
  @apply text-lg text-gray-900 leading-normal;
  & > * + *,
  & li + li,
  & li > p + p {
    @apply mt-6;
  }
  & strong {
    @apply text-gray-900 font-bold;
  }
  & a {
    @apply text-gray-900 font-semibold;
  }
  & strong a {
    @apply font-bold;
  }
  & h1 {
    @apply leading-tight text-2xl font-bold text-gray-900 mb-2 mt-6;
  }
  & h2 {
    @apply leading-tight text-xl font-bold text-gray-900 mb-2 mt-10;
  }
  & h3 {
    @apply leading-tight text-lg font-bold text-gray-900 mt-8 -mb-2;
  }
  & code {
    @apply font-mono text-sm inline bg-gray-200 px-1;
  }
  & pre code {
    @apply block bg-gray-900 p-4 rounded;
  }
  & blockquote {
    @apply border-l-4 border-gray-200 pl-4 italic;
  }
  & ul,
  & ol {
    @apply pl-5;
    @screen sm {
      @apply pl-10;
    }
  }
}

.picker-dialog-bg {
  z-index: 20000 !important;
}

.picker-dialog {
  z-index: 20001 !important;
}

@import "tailwindcss/utilities";
</style>
