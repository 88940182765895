<template>
  <div class="flex space-x-3">
    <span
      v-tooltip="'Anonymous bid'"
      class="h-6 w-6 flex-shrink-0 inline-flex items-center justify-center rounded-full bg-gray-400"
      :class="engagedBorder"
    >
      <svg class="h-4 w-4 text-white" fill="currentColor" viewBox="0 0 384 512">
        <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
      </svg>
    </span>
    <div class="flex-1 space-y-1">
      <div class="flex items-center justify-between">
        <h3 class="text-xl font-bold leading-5">${{ bid.bountyPrice }}</h3>
        <p class="text-sm leading-5 text-gray-500">{{ bid.createdAt | moment("from") }}</p>
      </div>
      <p class="text-sm leading-5 font-medium text-gray-500">
        <span>Anonymous bid ({{ bid.bidderReputation.industryRole | industryRoleAlias(bid.bidderReputation.industrySubRole) | capitalize }})</span>
      </p>
      <bidder-reputation :bid="bid" />
      <div v-if="isDiscussable || isEditable" class="pt-1 flex flex-col space-y-1 sm:flex-row sm:flex-wrap sm:items-center sm:space-x-1 sm:space-y-0">
        <div v-if="isDiscussable" class="inline-flex self-start rounded-md shadow-sm">
          <button @click="openChat" v-tooltip="'Anonymous 1:1 chat'" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
            <svg class="-ml-0.5 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd" />
            </svg>
            Anonymous chat
          </button>
        </div>
        <div v-if="isEditable" class="inline-flex self-start rounded-md shadow-sm">
          <button @click="revise" type="button" class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
            Revise bid
          </button>
        </div>
      </div>
      <div class="pt-3 flex items-center justify-between">
        <p class="text-base leading-5 font-medium text-gray-500">{{ bid.qualifications }}</p>
        <span v-if="bid.state === 'cancelled' || bid.state === 'abandoned'" class="ml-6 inline-flex font-bold text-gray-400 text-base leading-5">
          {{ bid.state | capitalize }}
        </span>
        <span v-else-if="bid.state === 'submitted' && isAdmin" class="ml-3 inline-flex rounded-md shadow-sm">
          <button @click="adminApprove" type="button" v-tooltip="'Accept this submission.'" class="inline-flex items-center justify-center px-2 py-1 border border-gray-300 text-xs leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
            Approve
          </button>
        </span>
        <span v-else-if="bid.state === 'unpaid' && (!bid.authored || isAdmin)" class="ml-3 inline-flex rounded-md shadow-sm">
          <button @click="authenticate" type="button" v-tooltip="'Payment required to access deliverables.'" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:ring-red active:bg-red-700">
            Pay
          </button>
        </span>
        <span v-else-if="isEngaged || bid.state === 'fulfilled'" class="ml-6 inline-flex font-bold text-green-400 text-base leading-5">
          {{ bid.state | capitalize }}!
        </span>
        <span v-else-if="isAdmin || !bid.authored" class="ml-3 inline-flex rounded-md shadow-sm">
          <button @click="engage" type="button" v-tooltip="'Accept this bid. You may hire multiple bidders.'" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
            Hire
          </button>
        </span>
      </div>
      <ul v-if="deliverablesVisible" class="pt-3 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <li v-if="bid.fulfillmentNarrative" class="col-span-1 bg-white rounded-lg shadow">
          <div class="w-full p-6">
            <div class="relative text-base leading-7 font-medium text-gray-700 md:flex-grow">
              <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-6 w-6 text-gray-200" fill="currentColor" viewBox="0 0 32 32">
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p v-html="linkifiedNarrative" class="relative whitespace-pre-line dont-break-out" />
            </div>
          </div>
        </li>
        <li v-for="(deliverable, index) in bid.deliverables" :key="index" class="col-span-1 bg-white rounded-lg shadow">
          <div class="w-full p-6 flex flex-col flex-1">
            <div class="iframely-embed flex-1 mb-2">
              <a data-iframely-url :href="deliverable.file"></a>
            </div>
            <a :href="deliverable.file" target="_blank" class="inline-flex items-center justify-center font-medium text-sm text-gray-700">
              <!-- Heroicon name: download -->
              <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd" />
              </svg>
              <span class="ml-2">Download</span>
            </a>
          </div>
        </li>
        <provide-feedback v-if="bid.state === 'fulfilled' && !bid.authored" :bid="bid" />
        <span v-if="bid.state === 'payable' && bid.authored" class="col-span-1 inline-flex rounded-lg shadow">
          <button v-if="bid.reselling" @click="premiumContentDashboard" type="button" v-tooltip="'Click to visit your premium insights dashboard.'" class="inline-flex w-full justify-center items-center px-6 py-3 border border-gray-300 text-base leading-6 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
            <!-- Heroicon name: currency-dollar -->
            <svg class="-ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
            </svg>
            Productized
          </button>
          <button v-else @click="purchaseableIntent" type="button" v-tooltip="'Productize this as premium content and resell it to other market participants.'" class="inline-flex w-full justify-center items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
            <!-- Heroicon name: currency-dollar -->
            <svg class="-ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
            </svg>
            Resell
          </button>
        </span>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BidForm from "./bid-form";
import BidPrivateChat from "./bid-private-chat";
import BidderReputation from "./bounty-detail/bidder-reputation";
import ConfirmCardPayment from "../confirm-card-payment";
import EngageBid from "./bounty-detail/engage-bid";
import ProvideFeedback from "./bounty-detail/provide-feedback";
import api from "../../api";
import linkifyHtml from "linkifyjs/html";
/* global iframely */

export default {
  components: { BidderReputation, ProvideFeedback },
  props: ["bounty", "bid"],
  computed: {
    ...mapState(["adminMode", "modal"]),
    ...mapGetters(["isAdmin"]),
    engagedBorder() {
      if (this.isEngaged || this.bid.state === "fulfilled") {
        return "border-solid border-2 border-green-400";
      } else {
        return "";
      }
    },
    isEngaged() {
      return _.includes(
        ["engaged", "submitted", "unpaid", "fulfilled", "payable"],
        this.bid.state
      );
    },
    isDiscussable() {
      return _.includes(
        ["pending", "engaged", "submitted", "fulfilled"],
        this.bid.state
      );
    },
    isEditable() {
      return this.bid.authored && _.includes(["pending"], this.bid.state);
    },
    deliverablesVisible() {
      return (
        (_.includes(["submitted", "unpaid"], this.bid.state) && this.isAdmin) ||
        _.includes(["fulfilled", "payable"], this.bid.state) ||
        this.bid.authored
      );
    },
    linkifiedNarrative() {
      return linkifyHtml(this.bid.fulfillmentNarrative, {
        defaultProtocol: "https",
        className:
          "font-medium text-indigo-600 hover:text-indigo-500"
      });
    }
  },
  mounted() {
    const hasFiles = this.bid.deliverables.length > 0;

    if (hasFiles && window.iframely) {
      setTimeout(() => {
        iframely.load();
      }, 500);

      iframely.on("cancel", function(url, parentNode) {
        parentNode.remove();
      });
    }
  },
  methods: {
    engage() {
      this.$store.commit("openModal", {
        component: EngageBid,
        props: { bid: this.bid }
      });
    },
    authenticate() {
      this.$store.commit("openModal", {
        component: ConfirmCardPayment,
        props: { bid: this.bid }
      });
    },
    premiumContentDashboard() {
      this.$router.push({ path: "/home/premium" });
      this.$store.commit("closeModal");
    },
    purchaseableIntent() {
      const payload = {
        contentType: "bounty_bid",
        contentToken: this.bid.id
      };

      api.post(`purchaseable_intents`, payload).then(json => {
        if (!!analytics && !this.adminMode) {
          analytics.track("Purchaseable intent created", {
            yearWeek: this.$store.state.yearWeek
          });
        }
        this.$store.dispatch("flash", "We're processing your content now and will be in touch shortly!");
        this.bid.reselling = true;
      });
    },
    revise() {
      let bidModal = false;

      if (this.modal) {
        bidModal = _.cloneDeep(this.modal); // obtains the current component
        bidModal.props.bounty = this.bounty; // allows changes to flow through
        bidModal.props.bid = this.bid; // allows changes to flow through
      }

      this.$store.commit("openModal", {
        component: BidForm,
        props: {
          bounty: this.bounty,
          bid: this.bid
        },
        afterClose: bidModal
      });
    },
    openChat() {
      this.$store.commit("openSlideover", {
        component: BidPrivateChat,
        props: {
          bounty: this.bounty,
          bid: this.bid
        }
      });

      if (!!analytics && !this.adminMode) {
        analytics.track("Opened private bounty bid chat", {
          yearWeek: this.$store.state.yearWeek
        });
      }
    },
    adminApprove() {
      api.patch(`approve_bounty_bid_fulfillment/${this.bid.id}`).then(json => {
        if (_.get(json, "data.error", null)) {
          this.$store.dispatch("flash", "Payment failed. Notifying customer.");
          this.bid.state = "unpaid";
        } else {
          if (!!analytics) {
            analytics.track("Bounty fulfillment approved", {
              yearWeek: this.$store.state.yearWeek
            });
          }
          this.bid.state = "fulfilled";
        }
      });
    }
  }
};
</script>
