<template>
  <div v-if="signedIn && unmasked" :class="{ 'border-t': topBorder, 'border-b': bottomBorder, 'p-2': compact, 'px-4 py-2 sm:py-4 sm:px-6': !compact }" class="flex flex-wrap space-x-2 justify-end border-gray-200 sm:justify-start">
    <span v-if="showSaveButton" class="inline-flex rounded-md shadow-sm">
      <button v-if="content.pinned" @click="unpin" v-tooltip="pinHelp" type="button" :class="compact ? 'px-2 py-1' : 'px-2.5 py-1.5'" class="inline-flex items-center border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
        <svg v-if="isBounty" class="-ml-0.5 mr-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" clip-rule="evenodd" />
        </svg>
        <svg v-else class="-ml-0.5 mr-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
        </svg>
        <template v-if="isBounty">Following</template>
        <template v-else>Thanked</template>
      </button>

      <button v-else @click="pin" v-tooltip="pinHelp" type="button" :class="compact ? 'px-2 py-1' : 'px-2.5 py-1.5'" class="inline-flex items-center border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
        <svg class="-ml-0.5 mr-1 h-4 w-4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
          <path v-if="isBounty" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
          <path v-else d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
        </svg>
        <template v-if="isBounty">Follow</template>
        <template v-else>Thank</template>
      </button>
    </span>

    <span class="inline-flex rounded-md shadow-sm">
      <button @click="notepad" type="button" v-tooltip="'Add notes to this record that only you can see.'" :class="compact ? 'px-2 py-1' : 'px-2.5 py-1.5'" class="inline-flex items-center border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
        <svg class="-ml-0.5 mr-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
        </svg>
        My Notes
      </button>
    </span>

    <span class="inline-flex rounded-md shadow-sm">
      <button @click="discuss" type="button" v-tooltip="contentType === 'property_following' ? 'Open this watchlist property\'s private chat in a new tab.' : 'Share and discuss this post, publicly or privately.'" :class="compact ? 'px-2 py-1' : 'px-2.5 py-1.5'" class="inline-flex items-center border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
        <svg class="-ml-0.5 mr-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
          <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
        </svg>
        Discuss
      </button>
    </span>

    <span v-if="timelineProperty" class="inline-flex rounded-md shadow-sm">
      <button @click="viewPropertyTimeline" type="button" v-tooltip="'View historical activity for this property.'" :class="compact ? 'px-2 py-1' : 'px-2.5 py-1.5'" class="inline-flex items-center border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
        <svg class="-ml-0.5 mr-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clip-rule="evenodd" />
        </svg>
        Timeline
      </button>
    </span>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DiscussionSelector from "../discussion-selector";
import Notepad from "../notepad";
import PropertyTimeline from "../property-timeline";
/* global analytics */
/* global L */

export default {
  props: [
    "content",
    "contentType",
    "thankable",
    "topBorder",
    "bottomBorder",
    "compact",
    "property"
  ],
  computed: {
    ...mapGetters(["signedIn"]),
    ...mapState(["adminMode", "modal"]),
    isMobile() {
      return L.Browser.mobile;
    },
    singleProperty() {
      const contentProperties = _.get(this.content, "properties", []);

      if (contentProperties.length === 1) {
        return _.head(contentProperties);
      } else {
        return null;
      }
    },
    timelineProperty() {
      return this.property || this.singleProperty;
    },
    showSaveButton() {
      return (
        this.thankable ||
        (!this.isMobile && this.contentType !== "property_following")
      );
    },
    isBounty() {
      return (
        this.contentType === "intel" &&
        _.get(this.content, "bounty.standalone", false)
      );
    },
    pinHelp() {
      if (this.content.pinned) {
        return this.isBounty ? "Following" : "Thanked";
      } else {
        return this.isBounty
          ? "Get notified if this bounty gets fulfilled and the content is available to buy."
          : "Anonymously show appreciation if this helped you. Also bookmarks the content for you.";
      }
    },
    unmasked() {
      switch (this.contentType) {
        case "deal":
          return (
            !this.content.confidential ||
            this.content.dealCloser ||
            (this.content.source === "indirect" && this.content.authored)
          );
        case "intel":
        default:
          return true;
      }
    }
  },
  methods: {
    pin() {
      switch (this.contentType) {
        case "deal":
          this.$store.dispatch("pinDeal", this.content.token);
          if (!!analytics && !this.adminMode) {
            analytics.track("Upvoted deal", {
              yearWeek: this.$store.state.yearWeek
            });
          }
          break;
        case "intel":
          this.$store.dispatch("pinIntel", this.content.token);
          if (!!analytics && !this.adminMode) {
            analytics.track("Upvoted intel", {
              yearWeek: this.$store.state.yearWeek
            });
          }
          break;
        default:
          return;
      }
      this.content.pinned = !this.content.pinned;
      this.content.pinCount++;
    },
    unpin() {
      switch (this.contentType) {
        case "deal":
          this.$store.dispatch("unpinDeal", this.content.token);
          break;
        case "intel":
          this.$store.dispatch("unpinIntel", this.content.token);
          break;
        default:
          return;
      }
      this.content.pinned = !this.content.pinned;
      this.content.pinCount--;
    },
    discuss() {
      if (this.contentType === "property_following") {
        const watchlistDiscussionPath = `/property-watchlist/${this.content.id}/discussion`;

        window.open(watchlistDiscussionPath, "_blank");
      } else {
        this.chooseDiscussion();
      }
    },
    chooseDiscussion() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      this.$store.commit("openModal", {
        component: DiscussionSelector,
        props: { content: this.content, contentType: this.contentType },
        afterClose: currentModal
      });
    },
    viewPropertyTimeline() {
      this.$store.commit("openSlideover", {
        component: PropertyTimeline,
        props: {
          property: this.timelineProperty
        }
      });

      if (!!analytics && !this.adminMode) {
        analytics.track("Viewed property timeline", {
          yearWeek: this.$store.state.yearWeek
        });
      }
    },
    notepad() {
      this.$store.commit("openSlideover", {
        component: Notepad,
        props: {
          myNotepad: true,
          content: this.content,
          contentType: this.contentType
        }
      });
    }
  }
};
</script>
