<template>
  <div class="bg-white shadow overflow-visible rounded-md">
    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <div
        class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
      >
        <div class="ml-4 mt-2">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Market Intel (T12)
          </h3>
          <p v-if="userIsActiveContact" class="mt-1 text-sm leading-5 text-gray-500">
            Any intel posts that you've made as you will appear here. Safezone posts are only visible to you.
          </p>
        </div>

        <div v-if="userIsActiveContact && !privacyMode" class="ml-4 mt-2 flex-shrink-0 flex">
          <span class="inline-flex rounded-md shadow-sm md:hidden">
            <button
              @click="createIntel"
              type="button"
              :class="privacyMode ? 'bg-pink-600 hover:bg-pink-500 focus:border-pink-700 focus:ring-pink active:bg-pink-700' : 'bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700'"
              class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none"
            >
              Post intel
            </button>
          </span>
          <span class="hidden md:inline-flex rounded-md shadow-sm">
            <button
              @click="createIntel"
              type="button"
              :class="privacyMode ? 'bg-pink-600 hover:bg-pink-500 focus:border-pink-700 focus:ring-pink active:bg-pink-700' : 'bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700'"
              class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none"
            >
              Post intel
            </button>
          </span>
        </div>
      </div>
    </div>
    <div v-if="combinedIntel.length > 0" class="px-4 py-5 sm:p-6">
      <div class="grid gap-5 max-w-lg mx-auto sm:grid-cols-2 lg:grid-cols-3 sm:max-w-none">
        <multi-content-preview-card
          v-for="post in combinedIntel"
          :key="`${post.token}`"
          :content="post"
        />
      </div>
    </div>
    <div v-if="intelPagination && intelPagination.count > 3" class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div class="hidden sm:block">
        <p class="text-sm leading-5 text-gray-700">
          Showing
          <span class="font-medium">{{ intelPagination.from }}</span>
          to
          <span class="font-medium">{{ intelPagination.to }}</span>
          of
          <span class="font-medium">{{ intelPagination.count }}</span>
          posts
        </p>
      </div>
      <div class="flex-1 flex justify-between sm:justify-end">
        <router-link @click.native="fetchIntel(intelPagination.prev_url)" to="" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
          Previous
        </router-link>
        <router-link @click.native="fetchIntel(intelPagination.next_url)" to="" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
          Next
        </router-link>
      </div>
    </div>
    <div v-if="combinedIntel.length === 0" class="relative bg-gray-800">
      <div class="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img class="w-full h-full object-cover" src="https://source.unsplash.com/collection/1976082" alt="Intel intro" />
      </div>
      <div class="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div class="md:ml-auto md:w-1/2 md:pl-10">
          <div class="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
            News, opinions, and hard-to-find info
          </div>
          <h2 class="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
            <template v-if="userIsActiveContact">Share your insights</template>
            <template v-else>Learn from your network</template>
          </h2>
          <p class="mt-3 text-lg leading-7 text-gray-300">
            <template v-if="userIsActiveContact">Showcase your expertise and commitment to staying on top of your markets by posting market intel.</template>
            <template v-else>Recently shared news from this person will appear here.</template>
          </p>
          <div v-if="userIsActiveContact" class="mt-8">
            <div class="inline-flex rounded-md shadow">
              <a @click.prevent="createIntel" href="" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:ring">
                Post intel
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BountyActions from "./bounties/bounty-actions";
import IntelPreview from "./cards/market-intel-preview";
import MultiContentPreviewCard from "./cards/multi-content-preview-card";
import PreviewCardSocialFooter from "./cards/preview-card-social-footer";

export default {
  components: {
    BountyActions,
    IntelPreview,
    MultiContentPreviewCard,
    PreviewCardSocialFooter
  },
  computed: {
    ...mapGetters(["userIsActiveContact", "name", "signedIn", "privacyMode"]),
    ...mapState(["activeContact", "currentUser"]),
    combinedIntel() {
      return this.activeContact.intel.data.filter(post => this.isVisible(post));
    },
    intelPagination() {
      return this.activeContact.intel.pagy;
    },
    shouldFetch() {
      return _.get(this.activeContact, "contact.token", false);
    }
  },
  watch: {
    currentUser: {
      handler: function() {
        if (this.shouldFetch) {
          this.fetchIntel();
        }
      }
    },
    shouldFetch: {
      handler: function() {
        if (this.shouldFetch) {
          this.fetchIntel();
        }
      }
    }
  },
  mounted() {
    if (this.shouldFetch) {
      this.fetchIntel();
    }
  },
  methods: {
    fetchIntel(url = null) {
      const cleanUrl = url ? _.drop(url, 8).join("") : url;

      this.$store.dispatch("loadContactIntel", cleanUrl);
    },
    createIntel() {
      this.$router.push({
        path: "/intel/new/privacy",
        query: { nextUrl: "/intel/new/1" }
      });
    },
    isVisible(post) {
      return (
        post.pinned || !post.anonymous || (!post.dismissed && !post.downvoted)
      );
    }
  }
};
</script>
