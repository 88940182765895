<template>
  <div class="absolute top-0 left-0 p-4">
    <router-link
      to="/"
      class="text-sm font-medium"
      :class="color"
      @click.native="goHome"
    >
      Home
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["textColor"],
  computed: {
    color() {
      return this.textColor || "text-gray-400";
    }
  },
  methods: {
    goHome() {
      this.$store.commit("clearOnboarding");
      this.$store.commit("clearTarget");
      this.$store.commit("clearRegion");
      this.$store.commit("clearIntel");
      this.$store.commit("clearDeal");
      this.$store.commit("clearDealMarketing");
      this.$store.commit("clearProperty");
    }
  }
};
</script>
