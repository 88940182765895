<template>
  <div>
    <div class="relative pb-5 border-b border-gray-200 space-y-4 sm:pb-0">
      <div class="space-y-3 md:flex md:items-center md:justify-between md:space-y-0">
        <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
          <h3 class="ml-2 mt-2 text-lg leading-6 font-medium text-gray-900">
            Off-Market Assets
          </h3>
        </div>
        <div class="flex items-center space-x-3 md:absolute md:top-3 md:right-0">
          <p v-if="helpRequested" class="py-2 text-md font-medium leading-5 text-indigo-600">Great! We'll be in touch shortly.</p>
          <button v-else @click="requestHelp" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium shadow-sm rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
            <svg class="-ml-1 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z" clip-rule="evenodd" />
            </svg>
            Gauge my property
          </button>
        </div>
      </div>
      <div>
        <!-- Dropdown menu on small screens -->
        <div class="sm:hidden">
          <select v-model="selectValue" @change="navigate" aria-label="Selected tab" class="block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm sm:leading-5">
            <option value="packs">Available Packs</option>
            <option value="watchlist">Watchlist</option>
            <option value="card-manager">Card Manager</option>
          </select>
        </div>
        <!-- Tabs at small breakpoint and up -->
        <div class="hidden sm:block">
          <nav class="-mb-px flex space-x-8">
            <router-link
              to="packs"
              v-slot="{ href, navigate, isExactActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isExactActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Available Packs
              </a>
            </router-link>

            <router-link
              to="watchlist"
              v-slot="{ href, navigate, isExactActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isExactActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Watchlist
              </a>
            </router-link>

            <router-link
              to="card-manager"
              v-slot="{ href, navigate, isExactActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isExactActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Card Manager
              </a>
            </router-link>
          </nav>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import api from "../api";

export default {
  data() {
    return {
      selectValue: null,
      helpRequested: false
    };
  },
  mounted() {
    document.title = "Off-Market Assets | Tower Hunt";

    switch (this.$store.state.route.path) {
      case "/off-market/watchlist":
        this.selectValue = "watchlist";
        break;
      case "/off-market/card-manager":
        this.selectValue = "card-manager";
        break;
      default:
        if (this.$store.state.route.path !== `/off-market/packs`) {
          this.$router.push({ path: "/off-market/packs" });
        }
        this.selectValue = "packs";
    }
  },
  methods: {
    navigate() {
      if (this.$store.state.route.path !== `/off-market/${this.selectValue}`) {
        this.$router.push({ path: this.selectValue });
      }
    },
    requestHelp() {
      api.post(`off_market_property_snapshot_help_requests`).then(json => {
        this.helpRequested = true;
      });
    }
  }
};
</script>
