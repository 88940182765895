<template>
  <div v-if="loaded && (comment || fetchedBounty)" :class="{ 'border-t': topBorder, 'border-b': bottomBorder, 'p-2': compact, 'px-4 py-4 sm:px-6': !compact }" class="border-gray-200">
    <h3 :class="compact ? 'mb-2 text-sm leading-4' : 'mb-3 text-base leading-6'" class="font-medium text-gray-900">
      <template v-if="fetchedBounty">Featured bounty</template>
      <template v-else>Most recent public comment</template>
    </h3>
    <attached-bounty v-if="fetchedBounty" :bounty="fetchedBounty" :compact="compact" @deleted="bountyDeleted" />
    <comment v-else-if="comment" :comment="comment" :compact="compact" background-color="gray-100" :thankable="true" :respondable="content.commentable" @deleted="deleted" />
    <div v-if="moreToShow" :class="compact ? 'mt-1 text-xs leading-4' : 'mt-3 text-sm leading-5'">
      <a @click.prevent="viewComments" href="" class="font-medium text-indigo-600 hover:text-indigo-500">
        View more &rarr;
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Comment from "./comment";
import Comments from "../comments";
import api from "../../api";

export default {
  components: { Comment },
  props: ["content", "bounty", "topBorder", "bottomBorder", "compact"],
  data() {
    return {
      comment: null,
      fetchedBounty: null,
      loaded: false
    };
  },
  computed: {
    ...mapState(["adminMode"]),
    moreToShow() {
      if (this.fetchedBounty) {
        return this.content.bountyCount > 1;
      } else if (this.comment) {
        return this.comment.responses.length + 1 < this.content.commentCount;
      } else {
        return false;
      }
    }
  },
  mounted() {
    if (this.bounty) {
      this.fetchedBounty = this.bounty;
      this.loaded = true;
      this.$emit("loaded");
      this.$emit("has-preview-content");
    } else if (this.content.newsfeedType !== "message") {
      this.fetchPreviewContent();
    }
  },
  beforeCreate() {
    this.$options.components.AttachedBounty = require("../bounties/attached-bounty.vue").default;
  },
  methods: {
    fetchPreviewContent() {
      api
        .get(
          `bounty_previews/${this.content.newsfeedType}/${this.content.token}`
        )
        .then(json => {
          this.fetchedBounty = json.data.bounty;
          api
            .get(
              `comment_previews/${this.content.newsfeedType}/${this.content.token}`
            )
            .then(json => {
              this.comment = json.data.comment;
              this.loaded = true;
              this.$emit("loaded");

              if (this.comment || this.bounty) {
                this.$emit("has-preview-content");
              }
            });
        });
    },
    deleted() {
      this.comment = null;
    },
    bountyDeleted() {
      this.fetchedBounty = null;
    },
    viewComments() {
      this.$store.commit("openSlideover", {
        component: Comments,
        props: {
          content: this.content
        }
      });

      if (!!analytics && !this.adminMode) {
        analytics.track("Viewed post comments", {
          yearWeek: this.$store.state.yearWeek
        });
      }
    }
  }
};
</script>
