<template>
  <div class="px-4 py-5 sm:p-6">
    <div v-if="!confirmationFailed" class="flex justify-center">
      <grid-loader v-if="!loaded || submitting" :loading="!loaded || submitting" size="10px" color="#5850ec" />
    </div>

    <!-- TODO: DISPLAY PAYMENT METHODS IF CONFIRMATION FAILS -->
    <template v-if="confirmationFailed">
      <div v-if="selectedCard" class="sm:flex sm:items-center sm:justify-between">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Pay this bounty
          </h3>
          <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
            <p>
              I authorize Tower Hunt to charge my card <strong class="font-semibold">${{ bid.bountyPrice }}</strong> to fulfill this approved bounty.
              <router-link to="/product/bounties" target="_blank" class="ml-1 font-medium text-indigo-600 hover:text-indigo-500">
                Learn more &rarr;
              </router-link>
            </p>
          </div>
        </div>
        <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
          <grid-loader v-if="submitting" :loading="submitting" size="6px" color="#5850ec" />
          <span v-else class="inline-flex rounded-md shadow-sm">
            <button @click="confirmPayment" type="button" :disabled="submitting" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
              Pay
            </button>
          </span>
        </div>
      </div>

      <payment-methods v-else :selectable="true" capturing-payment="now" @card-selected="selectCard" />
    </template>
  </div>
</template>

<script>
import GridLoader from "vue-spinner/src/GridLoader.vue";
import PaymentMethods from "./payment-methods";
import api from "../api";
/* global Stripe */

export default {
  components: { GridLoader, PaymentMethods },
  props: ["bid"],
  data() {
    return {
      submitting: false,
      clientSecret: null,
      paymentMethod: null,
      stripe: null,
      confirmationFailed: false,
      selectedCard: null
    };
  },
  computed: {
    loaded() {
      return !!this.stripe && !!this.clientSecret && !!this.paymentMethod;
    }
  },
  mounted() {
    if (!!Stripe && this.bid.paymentIntent) {
      api.get(`payment_api_key`).then(json => {
        const stripeKey = json.data;

        this.stripe = Stripe(stripeKey);
        api.get(`payment_intents/${this.bid.paymentIntent}`).then(json => {
          this.clientSecret = json.data.clientSecret;
          this.paymentMethod = json.data.paymentMethod;
          this.confirmPayment();
        });
      });
    } else {
      this.$store.commit("closeModal");
    }
  },
  methods: {
    confirmPayment() {
      this.submitting = true;
      this.stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: this.paymentMethod
        })
        .then(
          data => {
            if (data.error) {
              console.log(data.error.message);
              this.$store.dispatch("flash", data.error.message);
              this.confirmationFailed = true;
              this.selectedCard = null;
            } else {
              this.$store.dispatch("flash", "Payment successful!");
              this.confirmationFailed = false;
              this.fulfill();
            }
            this.submitting = false;
          },
          failure => {
            this.submitting = false;
          }
        );
    },
    selectCard(record) {
      this.paymentMethod = record.id;
      this.selectedCard = record;
    },
    fulfill() {
      api.patch(`pay_bounty_bid/${this.bid.id}`).then(json => {
        this.bid.state = "fulfilled";
        this.$store.commit("closeModal");
      });
    }
  }
};
</script>

<style>
.stripe-card {
  width: 300px;
  padding: 3px;
  display: inline-block;
  border: 1px solid transparent;
}
.stripe-card.complete {
  border-color: #0e9f6e;
  border-radius: 3px;
}

.payment-form {
  display: flex;
}
</style>
