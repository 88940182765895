export default {
  "Buy Equity": [
    {
      name: "Seller",
      value: "seller",
      description: "My company sold the deal.",
      presentDescription: "My company is selling the deal.",
      icon: "fas fa-building",
      activeDeal: true
    },
    {
      name: "Broker",
      value: "broker",
      description: "My company brokered the deal.",
      presentDescription: "My company is brokering the deal.",
      icon: "fas fa-handshake",
      activeDeal: true
    },
    {
      name: "Buyer",
      value: "buyer",
      description: "My company bought the deal.",
      icon: "fas fa-hand-holding-usd",
      activeDeal: false
    }
  ],
  "Recap Equity": [
    {
      name: "Seller",
      value: "seller",
      description: "My company offered its share of the deal.",
      presentDescription: "My company is offering its share of the deal.",
      icon: "fas fa-percentage",
      activeDeal: true
    },
    {
      name: "Investor",
      value: "buyer",
      description: "My company joined the capital stack.",
      icon: "fas fa-hand-holding-usd",
      activeDeal: false
    }
  ],
  "Originate Debt": [
    {
      name: "Borrower",
      value: "borrower",
      description: "My company was the borrower.",
      presentDescription: "My company is seeking financing.",
      icon: "fas fa-file-signature",
      activeDeal: true
    },
    {
      name: "Broker",
      value: "broker",
      description: "My company brokered the deal.",
      presentDescription: "My company is brokering the deal.",
      icon: "fas fa-handshake",
      activeDeal: true
    },
    {
      name: "Lender",
      value: "lender",
      description: "My company originated the loan(s).",
      icon: "fas fa-hand-holding-usd",
      activeDeal: false
    }
  ],
  "Buy Debt": [
    {
      name: "Seller",
      value: "seller",
      description: "My company sold the loan(s).",
      presentDescription: "My company is selling the loan(s).",
      icon: "fas fa-file-signature",
      activeDeal: true
    },
    {
      name: "Broker",
      value: "broker",
      description: "My company brokered the deal.",
      presentDescription: "My company is brokering the deal.",
      icon: "fas fa-handshake",
      activeDeal: true
    },
    {
      name: "Buyer",
      value: "buyer",
      description: "My company bought the loans(s).",
      icon: "fas fa-hand-holding-usd",
      activeDeal: false
    }
  ],
  Lease: [
    {
      name: "Landlord",
      value: "landlord",
      description: "My company controls the space.",
      presentDescription: "My company controls the space.",
      icon: "fas fa-building",
      activeDeal: true
    },
    {
      name: "Landlord Broker",
      value: "landlord broker",
      description: "My company represented the landlord.",
      presentDescription: "My company is representing the landlord.",
      icon: "fas fa-handshake",
      activeDeal: true
    },
    {
      name: "Tenant",
      value: "tenant",
      description: "My company leased the space.",
      icon: "fas fa-hand-holding-usd",
      activeDeal: false
    },
    {
      name: "Tenant Broker",
      value: "tenant broker",
      description: "My company represented the tenant.",
      icon: "fas fa-handshake",
      activeDeal: false
    }
  ]
};
