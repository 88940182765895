<template>
  <main>
    <div class="rounded-md bg-green-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm text-green-700">
            Safezone data is private to you.
          </p>
          <p class="mt-3 text-sm md:mt-0 md:ml-6">
            <a v-show="false" @click.prevent href="" class="whitespace-nowrap font-medium text-green-700 hover:text-green-600">Learn more <span aria-hidden="true">&rarr;</span></a>
          </p>
        </div>
      </div>
    </div>
    <div class="mt-4 lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
        <nav class="space-y-1">
          <a v-for="tab in tabs" @click.prevent="selectedTab = tab" :key="tab.name" :href="tab.href" :class="[selectedTab.name === tab.name ? 'bg-gray-50 text-indigo-600 hover:bg-white' : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50', 'group rounded-md px-3 py-2 flex items-center text-sm font-medium']" :aria-current="selectedTab.name === tab.name ? 'page' : undefined">
            <svg :class="[selectedTab.name === tab.name ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :d="tab.svgPath" />
            </svg>
            <span class="truncate">
              {{ tab.name }}
            </span>
          </a>
        </nav>
      </aside>

      <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <keep-alive>
          <component :is="selectedTab.component" v-bind="selectedTabProperties" />
        </keep-alive>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from "vuex";
import ContentContacts from "./content-companies-contacts.vue";
import ContentFiles from "./content-files.vue";
import ContentTracking from "./content-internal-tracking.vue";

export default {
  props: ["content", "contentType"],
  data() {
    return {
      tabs: [
        {
          name: "Contacts",
          href: "",
          svgPath:
            "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
          component: ContentContacts
        },
        {
          name: "Files",
          href: "",
          svgPath:
            "M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z",
          component: ContentFiles
        },
        {
          name: "Tracking",
          href: "",
          svgPath:
            "M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9",
          component: ContentTracking
        }
      ],
      selectedTab: {
        name: "Contacts",
        href: "",
        svgPath:
          "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
        component: ContentContacts
      }
    };
  },
  computed: {
    ...mapState(["modalNavigationState"]),
    selectedTabProperties() {
      switch (this.selectedTab.name) {
        case "Contacts":
          return {
            content: this.content,
            contentType: this.contentType,
            safezone: true
          };
        case "Files":
        case "Tracking":
          return {
            content: this.content,
            contentType: this.contentType
          };
        default:
          return {};
      }
    }
  },
  mounted() {
    this.checkForModalNavigation();
  },
  methods: {
    checkForModalNavigation() {
      if (this.modalNavigationState.verticalNav) {
        this.selectedTab = _.find(this.tabs, {
          name: this.modalNavigationState.verticalNav
        });
      }
    }
  }
};
</script>
