<template>
  <div class="flex items-start">
    <div class="absolute flex items-center h-5">
      <input
        v-model="bountyConsent"
        @change="saveNotification"
        id="bounties"
        type="checkbox"
        class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600"
      />
    </div>
    <div class="pl-7 text-sm leading-5">
      <label for="bounties" class="font-medium text-gray-700"
      >Bounties</label
      >
      <p class="text-gray-500">
        Get notified when a new bounty is offered in one of your markets.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bountyConsent: true
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },
  watch: {
    currentUser: {
      handler(user, oldUser) {
        if (user.signedIn && !oldUser.signedIn) {
          this.mountNotification();
        }
      }
    }
  },
  mounted() {
    if (this.currentUser.signedIn) {
      this.mountNotification();
    }
  },
  methods: {
    mountNotification() {
      const { bountyConsent } = this.currentUser;

      this.bountyConsent = bountyConsent;
    },
    saveNotification() {
      const { bountyConsent } = this;
      const newInfo = { bountyConsent };

      this.$store.dispatch("updateMyNotifications", newInfo);
    }
  }
};
</script>
