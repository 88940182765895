<template>
  <div class="min-h-screen bg-gray-100">
    <nav @keydown.escape="open = false" class="bg-indigo-700">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <router-link to="/">
                <img class="h-10 w-auto sm:h-12" src="https://assets.towerhunt.com/site/Transparent_White_T0.png" alt="Tower Hunt logo" />
              </router-link>
            </div>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline">
                <router-link
                  to="/market/featured"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-indigo-800': isExactActive }"
                    class="px-3 py-2 rounded-md text-sm font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
                  >
                    Featured
                  </a>
                </router-link>
                <router-link
                  to="/market/top"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-indigo-800': isExactActive }"
                    class="ml-3 px-3 py-2 rounded-md text-sm font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
                  >
                    Top-Ranked
                  </a>
                </router-link>
              </div>
            </div>
            <div class="block md:hidden">
              <div class="ml-5 flex items-baseline">
                <router-link
                  to="/market/featured"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-indigo-800': isExactActive }"
                    class="px-3 py-2 rounded-md text-sm font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
                  >
                    Featured
                  </a>
                </router-link>
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <div v-if="signedIn" class="ml-4 flex items-center md:ml-6">
              <notifications-menu />
              <notepad-button />
              <avatar-menu />
            </div>
            <div v-else class="ml-4 flex items-center md:ml-6">
              <button
                @click="signIn"
                type="button"
                class="px-3 py-2 rounded-md text-sm font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
              >
                Sign in
              </button>
              <span class="ml-3 inline-flex rounded-md shadow-sm">
                <button
                  @click="joinNow"
                  type="button"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:ring-indigo active:bg-indigo-200"
                >
                  Join now
                </button>
              </span>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <button @click="toggleMenu" class="inline-flex items-center justify-center p-2 rounded-md text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 focus:text-white" x-bind:aria-label="open ? 'Close main menu' : 'Main menu'" x-bind:aria-expanded="open">
              <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path :class="{'hidden': open, 'inline-flex': !open }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                <path :class="{'hidden': !open, 'inline-flex': open }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div v-if="currentUser" :class="{'block': open, 'hidden': !open}" class="z-20 md:hidden">
        <div class="px-2 pt-2 pb-3 sm:px-3">
          <router-link
            to="/market/top"
            v-slot="{ href, navigate, isExactActive }"
            @click.native="open = false"
          >
            <a
              @click="navigate"
              :href="href"
              class="inline-flex items-center px-3 py-2 rounded-md text-base font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
            >
              <!-- Heroicon name: trending-up -->
              <svg class="-ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z" clip-rule="evenodd" />
              </svg>
              Top-ranked content
            </a>
          </router-link>
          <router-link
            v-if="signedIn"
            to="/home/premium"
            v-slot="{ href, navigate, isExactActive }"
            @click.native="open = false"
          >
            <a
              @click="navigate"
              :href="href"
              class="inline-flex items-center px-3 py-2 rounded-md text-base font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
            >
              <!-- Heroicon name: currency-dollar -->
              <svg class="-ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
              </svg>
              Sell premium insights
            </a>
          </router-link>
        </div>
        <div v-if="signedIn" class="pt-4 pb-3 border-t border-gray-700">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <avatar-photo :person="currentUser" circle-size="10" text-size="md" />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium leading-none text-white">{{ currentUser.name }}</div>
              <div class="mt-1 text-sm font-medium leading-none text-indigo-300">{{ currentUser.email }}</div>
            </div>
          </div>
          <div class="mt-3 px-2" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <router-link to="/home" @click.native="open = false" class="block px-3 py-2 rounded-md text-base font-medium text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600" role="menuitem">My Dashboard</router-link>
            <router-link to="/home/settings" @click.native="open = false" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600" role="menuitem">Settings</router-link>
            <a href="#" @click.prevent="signOut" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600" role="menuitem">Sign out</a>
          </div>
        </div>
        <div v-else class="pt-4 pb-3 border-t border-gray-700">
          <div class="mt-3 px-2" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <router-link to="/start/1" @click.native="open = false" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600" role="menuitem">Join now</router-link>
            <router-link to="/sign-in" @click.native="open = false" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600" role="menuitem">Sign in</router-link>
          </div>
        </div>
      </div>
    </nav>
    <main>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div v-if="hasSpendableCredit" class="rounded-md bg-green-50 border-l-4 border-green-400 p-4 mt-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/gift -->
              <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clip-rule="evenodd" />
                <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-sm text-green-700">
                You have ${{ currentUser.spendableCredits | formattedNumber(2) }} of credit to spend.
                <a href="" @click.prevent="batchImport" class="font-medium underline text-green-700 hover:text-green-600">
                  Recruit new users to earn more credits.
                </a>
              </p>
            </div>
          </div>
        </div>

        <router-view />
      </div>
    </main>

    <!-- Simple footer -->
    <marketing-footer />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AvatarMenu from "../components/avatar-menu";
import AvatarPhoto from "../components/avatar-photo";
import EmailBatchUpload from "./offered-deal/email-batch-upload";
import MarketingFooter from "../components/marketing-footer";
import NotepadButton from "../components/notepad-button";
import NotificationsMenu from "../components/notifications-menu";

export default {
  components: {
    AvatarMenu,
    AvatarPhoto,
    MarketingFooter,
    NotepadButton,
    NotificationsMenu
  },
  props: [],
  data() {
    return {
      open: false
    };
  },
  computed: {
    ...mapState(["loading", "currentUser", "route"]),
    ...mapGetters(["signedIn", "userIsActiveContact"]),
    hasSpendableCredit() {
      return this.signedIn && _.toNumber(this.currentUser.spendableCredits) > 0;
    },
    shouldFetchContact() {
      return this.signedIn && !this.userIsActiveContact;
    },
    profilePath() {
      if (this.currentUser) {
        return `/contacts/${this.currentUser.token}`;
      } else {
        return "#";
      }
    }
  },
  watch: {
    currentUser: {
      handler: function() {
        if (this.shouldFetchContact) {
          this.$store.dispatch("loadContactData", this.currentUser.token);
        }
      }
    }
  },
  mounted() {
    if (this.shouldFetchContact) {
      this.$store.dispatch("loadContactData", this.currentUser.token);
    }
  },
  methods: {
    signIn() {
      this.$router.push({ path: "/sign-in" });
    },
    joinNow() {
      this.$router.push({ path: "/start/1" });
    },
    batchImport() {
      this.$store.commit("openModal", {
        component: EmailBatchUpload,
        props: {
          action: "createTowerHuntInvitation"
        }
      });
      this.menuOpen = false;
    },
    toggleMenu() {
      this.open = !this.open;
    },
    signOut() {
      this.open = false;
      this.$store.dispatch("signOut");
      this.$store.dispatch("clearAll");
    }
  }
};
</script>
