<template>
  <div v-observe-visibility="{ callback: createImpression }" class="flex flex-grow">
    <router-link v-if="comment.author" @click.native="closeSlideover" :to="`/contacts/${comment.author.token}`" class="flex-shrink-0 mt-3">
      <avatar-photo :person="comment.author" circle-size="8" text-size="sm" />
    </router-link>
    <span
      v-else
      v-tooltip="'Posted anonymously'"
      class="mt-3 h-8 w-8 flex-shrink-0 inline-flex items-center justify-center rounded-full bg-gray-400"
    >
      <svg class="h-4 w-4 text-white" fill="currentColor" viewBox="0 0 384 512">
        <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
      </svg>
    </span>
    <div class="flex flex-col w-full">
      <div v-if="comment.premium" class="relative" style="z-index: 1;">
        <div class="absolute inset-x-0 top-0 transform translate-y-px">
          <div class="flex justify-center transform -translate-y-1/2">
            <span class="inline-flex rounded-full bg-indigo-600 px-4 py-1 text-xs leading-5 font-semibold tracking-wider uppercase text-white">
              Premium
            </span>
          </div>
        </div>
      </div>

      <div class="relative p-3 ml-2 w-full shadow text-gray-700 text-left" :class="`bg-${backgroundColor}`" style="border-radius: 5px 20px 20px 20px;">
        <div v-if="comment.premium" class="pointer-events-none absolute inset-0 border-2 border-indigo-600" style="border-radius: 5px 20px 20px 20px;"></div>
        <div class="flex justify-between">
          <router-link v-if="comment.author" @click.native="closeSlideover" :to="`/contacts/${comment.author.token}`">
            <h3 class="text-xs font-medium text-gray-900 hover:underline">
              {{ comment.author.name | truncate(25) }}
            </h3>
            <div class="text-xs text-gray-500">
              {{ roleCompany(comment.author) | truncate(25) }}
            </div>
          </router-link>
          <h3 v-else class="text-xs font-medium text-gray-900">
            Posted anonymously
          </h3>
          <div class="flex items-center">
            <div class="text-xs text-right text-gray-500">
              <template v-if="isRecent">{{ comment.createdAt | moment("from", now) }}</template>
              <template v-else>{{ comment.createdAt | moment("MMM D, YYYY") }}</template>
            </div>
            <comment-menu v-if="signedIn" :comment="comment" @edit="edit" @deleted="deleted" @unlinked="unlinked" />
          </div>
        </div>
        <resizable-textarea v-if="editing">
          <textarea v-focus v-model="localBody" id="body" type="text" rows="1" :class="compact ? 'text-xs' : 'text-sm'" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 mt-2 py-1 px-2 block w-full"></textarea>
        </resizable-textarea>
        <template v-else>
          <div :id="`comment-${comment.id}-body`" v-observe-visibility="{ callback: checkClampStatus, once: true }" v-html="linkifiedBody" :class="{ 'text-xs': compact, 'text-sm': !compact, 'line-clamp-4': clamp, 'line-clamp-none': !clamp }" class="mt-2 whitespace-pre-line dont-break-out" />
          <div v-if="bodyIsClamped" :class="compact ? 'text-xs leading-4' : 'text-sm leading-5'" class="mt-1">
            <a @click.prevent="toggleClamp" href="" class="font-medium text-indigo-600 hover:text-indigo-500">
              View more &rarr;
            </a>
          </div>
          <content-link-summary v-if="comment.notepad && comment.contentId && comment.contentType" :content-id="comment.contentId" :content-type="comment.contentType" class="mt-1" />
        </template>
      </div>
      <div class="ml-2 mt-2 flex">
        <button v-if="signedIn && thankable" @click="togglePin" type="button" v-tooltip="pinHelp" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
          <svg v-if="comment.pinned" class="-ml-0.5 mr-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
          </svg>
          <svg v-else class="-ml-0.5 mr-1 h-4 w-4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
          </svg>
          {{ comment.pinCount }}
        </button>
        <template v-if="!editing && comment.notepad && !comment.contentId && !comment.contentType">
          <button @click="lookupLink" type="button" v-tooltip="'Connect this note to an existing deal or intel record'" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Link to intel
          </button>
          <button @click="convertComment" type="button" v-tooltip="'Turn this note into a safezone deal or intel record'" class="ml-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Convert to intel
          </button>
        </template>
        <template v-else-if="editing">
          <button v-if="editingComplete" @click="updateComment" type="button" class="ml-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
            <svg class="-ml-0.5 mr-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
            </svg>
            Save
          </button>
          <button @click="cancelEditing" type="button" class="ml-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
            <svg class="-ml-0.5 mr-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
            Cancel
          </button>
        </template>
        <template v-else>
          <button v-if="signedIn && respondable && response === null" @click="createResponse" type="button" v-tooltip="'Reply to this comment'" class="ml-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
            <svg class="-ml-0.5 mr-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
              <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
            </svg>
            Reply
          </button>
          <button v-else-if="signedIn && respondable && response !== null" @click="cancelResponse" type="button" v-tooltip="'Reply to this comment'" class="ml-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50">
            <svg class="-ml-0.5 mr-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
            Cancel
          </button>
        </template>
      </div>
      <div v-if="response !== null" class="ml-2 my-3 space-y-2">
        <div class="rounded-md bg-yellow-50 p-2">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: information-circle -->
              <svg class="h-4 w-4 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-2 flex-1 md:flex md:justify-between">
              <p class="text-xs leading-4 text-yellow-700">
                Visible to anyone who can see this post.
              </p>
            </div>
          </div>
        </div>
        <div class="bg-white rounded-md shadow-sm">
          <resizable-textarea>
            <textarea v-focus v-model="response" id="response" type="text" aria-label="Reply" rows="1" class="border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm sm:leading-5" placeholder="Write a reply. Please be friendly and helpful." />
          </resizable-textarea>
          <div class="-mt-px flex">
            <div class="w-full flex-1 min-w-0">
              <button
                @click="confirmPostPrivacy"
                type="button"
                :class="complete ? completeColor : 'border border-gray-300 text-gray-700 bg-white hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700'"
                class="relative inline-flex justify-center items-center w-full px-4 py-2 rounded-none rounded-b-md text-sm leading-5 font-medium focus:z-10"
              >
                <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
                  <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
                </svg>
                Confirm author and post
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="comment.responses.length > 0" class="ml-2 mt-2">
        <div v-for="reply in comment.responses" :key="reply.id" class="py-3 flex">
          <reply :comment="reply" :compact="compact" background-color="gray-100" :thankable="true" @deleted="deleted" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapState } from "vuex";
import AvatarPhoto from "../avatar-photo";
import CommentMenu from "../comment-menu";
import ContentLinkSummary from "../content-link-summary";
import NotepadLinkingForm from "../notepad-linking-form";
import ResizableTextarea from "../resizable-textarea";
import Swal from "sweetalert2";
import anonymousPostOptions from "../../sweet-alert-always-anonymous-options";
import linkifyHtml from "linkifyjs/html";
import namedPostOptions from "../../sweet-alert-named-post-options";

export default {
  components: {
    AvatarPhoto,
    CommentMenu,
    ContentLinkSummary,
    ResizableTextarea
  },
  props: ["comment", "backgroundColor", "thankable", "respondable", "compact"],
  data() {
    return {
      editing: false,
      localBody: "",
      response: null,
      clamp: true,
      bodyIsClamped: false
    };
  },
  computed: {
    ...mapState(["now", "adminMode"]),
    ...mapGetters(["privacyMode", "signedIn"]),
    isRecent() {
      return moment(this.comment.createdAt)
        .add(1, "month")
        .isAfter();
    },
    refs() {
      return this.$refs;
    },
    linkifiedBody() {
      return linkifyHtml(this.comment.body, {
        defaultProtocol: "https",
        className: "font-medium text-indigo-600 hover:text-indigo-500"
      });
    },
    editingComplete() {
      return _.trim(this.localBody) !== "";
    },
    complete() {
      return (
        this.response && this.response !== "" && _.trim(this.response) !== ""
      );
    },
    completeColor() {
      if (this.privacyMode) {
        return "border-transparent text-white bg-pink-600 hover:bg-pink-500 focus:outline-none focus:ring-pink focus:border-pink-700 active:bg-pink-700";
      } else {
        return "border-transparent text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700";
      }
    },
    pinHelp() {
      if (this.comment.pinned) {
        return "Thanked";
      } else {
        return "Anonymously show appreciation if this helped you. Also bookmarks the content for you.";
      }
    }
  },
  watch: {
    linkifiedBody: {
      handler() {
        this.localBody = this.comment.body;
      }
    }
  },
  beforeCreate() {
    this.$options.components.Reply = require("./comment.vue").default;
  },
  mounted() {
    if (!this.thankable) {
      this.clamp = false;
      this.bodyIsClamped = false;
    }

    this.localBody = this.comment.body;
  },
  methods: {
    checkClampStatus() {
      if (this.thankable) {
        const bodyEl = document.getElementById(`comment-${this.comment.id}-body`);

        if (bodyEl) {
          this.bodyIsClamped = bodyEl.offsetHeight < bodyEl.scrollHeight;
        } else {
          this.bodyIsClamped = false;
        }
      }
    },
    toggleClamp() {
      if (this.clamp) {
        this.clamp = false;
        this.bodyIsClamped = false;
      } else {
        this.clamp = true;
        this.bodyIsClamped = true;
      }
    },
    createImpression(isVisible, entry) {
      if (!this.adminMode && this.signedIn && isVisible) {
        this.$store.dispatch("createCommentImpression", this.comment.id).then(() => {
          this.$store.dispatch("loadUnreadCounts");
        });
      }
    },
    closeSlideover() {
      this.$store.commit("closeSlideover");
    },
    togglePin() {
      if (this.comment.pinned) {
        this.unpin();
      } else {
        this.pin();
      }
    },
    pin() {
      this.comment.pinCount++;
      this.comment.pinned = true;
      this.$store.dispatch("pinComment", this.comment.id);

      if (!!analytics && !this.adminMode) {
        analytics.track("Upvoted comment", {
          yearWeek: this.$store.state.yearWeek
        });
      }
    },
    unpin() {
      this.comment.pinCount--;
      this.comment.pinned = false;
      this.$store.dispatch("unpinComment", this.comment.id);
    },
    edit() {
      this.editing = true;
    },
    cancelEditing() {
      this.editing = false;
      this.localBody = this.comment.body;
    },
    createResponse() {
      this.response = "";
    },
    cancelResponse() {
      this.response = null;
    },
    confirmPostPrivacy() {
      if (this.complete) {
        if (this.privacyMode) {
          Swal.fire(anonymousPostOptions).then(result => {
            if (result.value) {
              this.postResponse(true);
            }
          });
        } else {
          Swal.fire(namedPostOptions).then(result => {
            if (result.value) {
              this.postResponse(true);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.postResponse(false);
            }
          });
        }
      }
    },
    updateComment() {
      this.$store
        .dispatch("updateComment", {
          body: this.localBody,
          commentId: this.comment.id
        })
        .then(json => {
          this.comment.body = this.localBody;
          this.cancelEditing();
        });
    },
    postResponse(anonymous = true) {
      this.$store
        .dispatch("createComment", {
          contentType: null,
          contentToken: null,
          comment: this.comment,
          response: this.response,
          anonymous
        })
        .then(json => {
          this.comment.responses.push(json.data.comment);
          this.response = null;

          if (!!analytics && !this.adminMode) {
            analytics.track("Comment response posted", {
              yearWeek: this.$store.state.yearWeek
            });
          }
        });
    },
    roleCompany(author) {
      const role = _.capitalize(
        this.$options.filters.industryRoleAlias(author.industryRole, author.industrySubRole, author.token)
      );

      if (author.company) {
        return `(${role} at ${author.company})`;
      } else {
        return `(${role})`;
      }
    },
    deleted(commentId) {
      if (commentId) { // event came from a child comment
        this.$emit("deleted", commentId);
        this.comment.responses = this.comment.responses.filter(c => c.id !== commentId);
      } else { // event came from this comment
        this.$emit("deleted", this.comment.id);
      }
    },
    unlinked() {
      this.$emit("unlinked", this.comment.id);
    },
    lookupLink() {
      this.$store.commit("openModal", {
        component: NotepadLinkingForm,
        props: { comment: this.comment }
      });
      this.closeSlideover();
    },
    convertComment() {
      this.$store.commit("setNotepadConversionComment", this.comment);

      this.$router.push({
        path: "/intel/new/privacy",
        query: { nextUrl: "/intel/new/1" }
      });

      this.closeSlideover();
    }
  }
};
</script>
