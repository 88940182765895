<template>
  <header class="space-y-1 py-6 px-4 sm:px-6 bg-gray-800">
    <div class="flex items-center justify-between space-x-3">
      <h2 v-if="propertyDetail" class="text-lg leading-7 font-medium text-white">
        {{ propertyDetail.name | truncate(30) }}
      </h2>
      <div class="h-7 flex items-center">
        <button @click="close" aria-label="Close panel" class="text-gray-200 hover:text-white">
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
    </div>
    <div v-if="propertyDetail">
      <p class="text-sm leading-5 text-gray-300">
        {{ [propertyDetail] | summarizedLocation }}
      </p>
    </div>
    <div v-if="propertyDetail">
      <p class="flex items-center text-sm leading-5 text-gray-300">
        {{ [propertyDetail] | totalSize }}
      </p>
    </div>
  </header>
</template>

<script>
import api from "../api";

export default {
  props: ["property"],
  data() {
    return {
      propertyDetail: null
    };
  },
  mounted() {
    api.get(`properties/${this.property.id}`).then(json => {
      this.propertyDetail = json.data;
    });
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
