import * as moment from "moment";
import dealTypes from "./deal-input-deal-types";
import loanTypes from "./loan-types";
import riskTypes from "./risk-types";
import useTypes from "./use-types";
/* global _ */

export default function setMapFilters(
  filteringFocalRecord,
  industryRole,
  signedIn = false,
  dateOverride = false
) {
  const uses = (
    filteringFocalRecord.uses || filteringFocalRecord.rawUseTypes
  ).filter(use => use !== "Parking");
  const deals =
    filteringFocalRecord.deals ||
    translatedSingularDealType(filteringFocalRecord);
  const risks = filteringFocalRecord.risks || [];
  const loans = filteringFocalRecord.loans || [];
  const regions = filteringFocalRecord.regions || [];

  return {
    sources: [],
    regions,
    deals: matchFilterType(deals, matchingDealTypes(industryRole)),
    loans: matchFilterType(loans, loanTypes),
    risks: matchFilterType(risks, riskTypes),
    uses: matchFilterType(uses, useTypes),
    dates: dateFilter(signedIn, dateOverride)
  };
}

function matchingDealTypes(industryRole = "observer") {
  return _.get(dealTypes, industryRole, []);
}

function translatedSingularDealType(filteringFocalRecord) {
  return _.intersection(
    ["Buy Equity", "Originate Debt", "Recap Equity", "Buy Debt", "Lease"],
    [filteringFocalRecord.type]
  );
}

function matchFilterType(focalRecordArray, filterTypes) {
  return focalRecordArray.map(value => {
    if (_.isString(value)) {
      return _.find(filterTypes, { value });
    } else if (_.isObject) {
      return _.find(filterTypes, { value: value.type });
    } else {
      console.log("unknown type");

      return null;
    }
  });
}

function dateFilter(signedIn, dateOverride) {
  if (signedIn || dateOverride) {
    return {
      allDates: true,
      start: new Date(),
      end: new Date()
    };
  } else {
    return trailingTimePeriod(1, "month");
  }
}

function trailingTimePeriod(number, period) {
  const start = moment()
    .subtract(number, period)
    .startOf("month")
    .toDate();

  return { allDates: false, start, end: new Date() };
}
