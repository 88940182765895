<template>
  <div class="relative p-4 max-w-3xl mx-auto">
    <div class="text-center">
      <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Edit Premium Content
      </h2>
      <p class="mt-4 text-xl font-bold leading-6 text-gray-900">
        {{ contentName }}
      </p>
      <!--
        Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
        Read the documentation to get started: https://tailwindui.com/documentation
      -->
      <div class="mt-2 bg-indigo-50 border-l-4 border-indigo-400 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: information-circle -->
            <svg class="h-5 w-5 text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm leading-5 text-indigo-700">
              The changes you make here affect what people see before and after they purchase your content.
              <router-link to="/product/sell-premium-content" target="_blank" class="font-medium underline text-indigo-700 hover:text-indigo-600">
                Learn more &rarr;
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-10">
      <form @submit.prevent="confirmPrivacy" class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        <div class="sm:col-span-2">
          <div class="pb-5 border-b border-gray-200 space-y-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Marketing text
            </h3>
            <p class="max-w-4xl text-sm leading-5 text-gray-500">People will see this when they're browsing Tower Hunt. Use these fields to sell!</p>
          </div>
        </div>
        <div class="sm:col-span-1">
          <!--
            Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
            Read the documentation to get started: https://tailwindui.com/documentation
          -->
          <div>
            <div class="flex justify-between">
              <label for="name" class="block text-sm font-medium leading-5 text-gray-700">Name</label>
              <span class="text-sm leading-5 text-gray-500" id="name-required">Required</span>
            </div>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input v-model="name" id="name" type="text" class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5" placeholder="Premium Content" aria-describedby="name-required">
            </div>
          </div>
        </div>
        <div class="sm:col-span-1">
          <div class="flex justify-between">
            <label for="description" class="block text-sm font-medium leading-5 text-gray-700">Description</label>
            <span class="text-sm leading-5 text-gray-500" id="description-required">Required</span>
          </div>
          <div class="mt-2 relative rounded-md shadow-sm">
            <resizable-textarea>
              <textarea v-model="description" id="description" type="text" rows="4" placeholder="What are people buying? Specify fields/metrics and other identifying info." class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-sm py-3 px-4 block w-full"></textarea>
            </resizable-textarea>
          </div>
        </div>
        <div class="sm:col-span-2">
          <div class="pb-5 border-b border-gray-200 space-y-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Purchaseable content
            </h3>
            <p class="max-w-4xl text-sm leading-5 text-gray-500">The content people will receive after they purchase from you.</p>
          </div>
        </div>
        <div class="sm:col-span-1">
          <div class="flex justify-between">
            <label for="narrative" class="block text-sm font-medium leading-5 text-gray-700">Premium text content</label>
            <span class="text-sm leading-5 text-gray-500" id="narrative-optional">Optional</span>
          </div>
          <div class="mt-2 relative rounded-md shadow-sm">
            <resizable-textarea>
              <textarea v-model="narrative" id="narrative" type="text" rows="4" placeholder="Info people are paying for goes here." class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-sm py-3 px-4 block w-full"></textarea>
            </resizable-textarea>
          </div>
        </div>
        <div class="sm:col-span-1">
          <div class="flex justify-between">
            <label for="attachments" class="block text-sm font-medium leading-5 text-gray-700">File-based content</label>
            <span class="text-sm leading-5 text-gray-500" id="narrative-optional">Optional</span>
          </div>
          <ul v-if="hasDirectAttachments" class="mt-2 border border-gray-200 rounded-md">
            <li v-for="(attachment, index) in directAttachments" :key="index" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5" :class="index !== 0 ? 'border-t border-gray-200' : ''">
              <attachment-preview-toggle :attachment="attachment" />
              <a :href="attachment.file" target="_blank" v-tooltip="'View/download'" class="w-0 flex-1 flex items-center">
                <span v-if="attachment.name" class="ml-2 flex-1 w-0 truncate">{{ attachment.name }}</span>
                <span v-else class="ml-2 flex-1 w-0 truncate">File {{ index + 1 }}</span>
              </a>
              <div class="ml-4 flex-shrink-0">
                <a href="" @click.prevent="deleteAttachment(attachment.id)" class="font-medium text-indigo-600 hover:text-indigo-500">
                  Remove
                </a>
              </div>
            </li>
          </ul>
          <file-drag-drop
            v-if="addingFiles"
            class="mt-2"
            :endpoint="null"
            local-commit="setTemporaryAccess"
            :file-types="deliverableFileTypes"
            file-type-labels="XLS, PPT, DOC, PDF"
            :size-limit="25"
            :multiple="true"
            :heading="null"
            :full-width="true"
            bottom-margin="mb-0"
            flash-message="File(s) attached"
          />
          <div v-else class="mt-2">
            <span class="block w-full rounded-md shadow-sm">
              <button @click="addingFiles = true" type="button" class="w-full flex items-center justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:ring-blue">
                <!-- Heroicon name: plus -->
                <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                </svg>
                Add files
              </button>
            </span>
          </div>
        </div>
        <div class="sm:col-span-2">
          <button type="submit" :disabled="!complete" :class="buttonStyling" class="w-full flex items-center justify-center py-3 px-4 border border-transparent text-base leading-6 font-medium rounded-md text-white">
            <svg v-if="!isPublic" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
              <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
            </svg>
            <svg
              v-else
              class="-ml-1 mr-2 h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
              <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
            </svg>
            Save changes
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { all } from "../../document-file-types";
import { mapGetters, mapState } from "vuex";
import AttachmentPreviewToggle from "./attachment-preview-toggle";
import FileDragDrop from "../file-drag-drop";
import ResizableTextarea from "../resizable-textarea";
import Swal from "sweetalert2";
import anonymousPostOptions from "../../sweet-alert-always-anonymous-options";
import api from "../../api";
import deleteItemOptions from "../../sweet-alert-delete-item-options";

export default {
  components: { AttachmentPreviewToggle, FileDragDrop, ResizableTextarea },
  props: ["purchaseable"],
  data() {
    return {
      name: "",
      description: "",
      narrative: "",
      deliverables: [],
      documentFileTypes: all,
      addingFiles: false
    };
  },
  computed: {
    ...mapGetters(["privacyMode", "isAdmin"]),
    ...mapState(["temporaryAccess", "adminMode"]),
    contentName() {
      if (this.purchaseable.contentName && this.purchaseable.contentName !== "Purchaseable") {
        return this.purchaseable.contentName;
      } else {
        return "Custom filters";
      }
    },
    deliverableFileTypes() {
      return "image/*," + this.documentFileTypes.join(",");
    },
    isPublic() {
      return !this.privacyMode;
    },
    complete() {
      return this.marketingDataComplete && this.purchaseableDataComplete;
    },
    marketingDataComplete() {
      return _.trim(this.name) !== "" && _.trim(this.description) !== "";
    },
    purchaseableDataComplete() {
      return (
        this.deliverables.length > 0 ||
        this.hasDirectAttachments ||
        _.trim(this.narrative) !== ""
      );
    },
    hasDirectAttachments() {
      return (
        this.purchaseable.attachments &&
        this.purchaseable.attachments.length > 0 &&
        this.purchaseable.attachments.some(
          attachment => attachment.belongsToId === this.purchaseable.id
        )
      );
    },
    directAttachments() {
      return _.get(this.purchaseable, "attachments", []).filter(
        attachment => attachment.belongsToId === this.purchaseable.id
      );
    },
    buttonStyling() {
      if (this.complete) {
        if (!this.isPublic) {
          return "bg-pink-600 hover:bg-pink-500 focus:outline-none focus:border-pink-700 focus:ring-pink active:bg-pink-700";
        } else {
          return "bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700";
        }
      } else {
        return "border-gray-300 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 cursor-not-allowed";
      }
    },
    privacyBackgroundColor() {
      return !this.isPublic ? "bg-pink-600" : "bg-indigo-600";
    }
  },
  watch: {
    temporaryAccess: {
      handler() {
        if (_.get(this.temporaryAccess, "files", []).length > 0) {
          this.deliverables = _.union(
            this.deliverables,
            this.temporaryAccess.files
          );
        }

        this.$store.commit("clearTemporaryAccess");
      }
    }
  },
  mounted() {
    const {
      name,
      description,
      purchaseableNarrative,
      attachments
    } = this.purchaseable;

    if (name) {
      this.name = name;
    }

    if (description) {
      this.description = description;
    }

    if (purchaseableNarrative) {
      this.narrative = purchaseableNarrative;
    }

    if (!this.hasDirectAttachments) {
      this.addingFiles = true;
    }
  },
  methods: {
    closeModal() {
      this.$store.commit("closeModal");
    },
    deleteAttachment(id) {
      Swal.fire(deleteItemOptions).then(result => {
        if (result.value) {
          api.delete(`purchaseable_attachments/${id}`).then(json => {
            this.$store.dispatch("flash", "File deleted.");
            this.$store.commit("setRefetchPurchaseables", true);
            this.purchaseable.attachments = this.purchaseable.attachments.filter(file => {
              return file.id !== id;
            });
          });
        }
      });
    },
    confirmPrivacy() {
      if (this.complete) {
        Swal.fire(anonymousPostOptions).then(result => {
          if (result.value) {
            this.persist();
          }
        });
      }
    },
    persist() {
      if (this.complete) {
        const payload = {
          name: this.name,
          description: this.description,
          narrative: this.narrative,
          files: this.deliverables
        };

        api
          .patch(`purchaseables/${this.purchaseable.id}`, payload)
          .then(json => {
            this.$store.commit("setRefetchPurchaseables", true);
            this.$store.dispatch("flash", "Premium content saved!");
            this.closeModal();
          });
      }
    }
  }
};
</script>
