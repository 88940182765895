<template>
  <div class="border-t border-gray-200 w-full flex" :class="flat ? '' : 'rounded-b-lg'">
    <button v-if="bounty.expired && bounty.state !== 'fulfilled'" @click="renew" :class="{ 'rounded-b-lg': !flat, 'p-2 text-xs leading-4': compact, 'py-4 text-sm leading-5': !compact }" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center text-gray-700 font-medium border border-transparent hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 focus:z-10">
      <!-- Heroicon name: clock -->
      <svg class="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
      </svg>
      <span class="ml-3">Renew &middot; Expired {{ bounty.expiresAt | moment("from") }}</span>
    </button>

    <div v-else-if="awaitingBids" :class="{ 'rounded-b-lg': !flat, 'p-2 text-xs leading-4': compact, 'py-4 text-sm leading-5': !compact }" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center text-gray-700 font-medium border border-transparent hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 focus:z-10">
      <!-- Heroicon name: clock -->
      <svg class="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
      </svg>
      <span class="ml-3">Live &middot; Awaiting bids</span>
    </div>

    <router-link v-else @click.native="closeSlideover" :to="`/bounty/${bounty.id}`" :class="{ 'rounded-b-lg': !flat, 'p-2 text-xs leading-4': compact, 'py-4 text-sm leading-5': !compact }" class="relative w-0 flex-1 inline-flex items-center justify-center text-white font-medium border border-transparent bg-gradient-to-r from-yellow-300 to-yellow-500 hover:to-yellow-600 focus:border-yellow-500 focus:ring-yellow active:to-yellow-600 focus:z-10">
      <!-- Heroicon name: cash -->
      <svg class="w-5 h-5 text-white" viewBox="0 0 20 20" fill="currentColor">
        <path v-if="bounty.state === 'live'" fill-rule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
        <path v-else-if="bounty.state === 'awarded'" fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
        <path v-else-if="bounty.state === 'fulfilled'" fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
      </svg>
      <span v-if="bounty.state === 'live'" class="ml-3">Review {{ bidCount }}</span>
      <span v-else-if="bounty.state === 'awarded'" class="ml-3">In process &middot; View details</span>
      <span v-else-if="bounty.state === 'fulfilled'" class="ml-3">View deliverables</span>
    </router-link>
  </div>
</template>

<script>
import * as moment from "moment";
import api from "../../../api";
import pluralize from "pluralize";

export default {
  props: ["bounty", "flat", "compact"],
  computed: {
    bidCount() {
      return pluralize("bid", this.bounty.bidCount, true);
    },
    awaitingBids() {
      return this.bounty.state === "live" && this.bounty.bidCount === 0;
    }
  },
  methods: {
    closeSlideover() {
      this.$store.commit("closeSlideover");
    },
    renew() {
      api.patch(`renew_bounty/${this.bounty.id}`).then(() => {
        if (!!analytics) {
          analytics.track("Bounty renewed", {
            yearWeek: this.$store.state.yearWeek
          });
        }

        this.bounty.expired = false;
        this.bounty.expiresAt = moment().add(7, "d");
      });
    }
  }
};
</script>
