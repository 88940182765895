<template>
  <div class="input-container">
    <input
      v-focus
      v-model="name"
      @focus="focus"
      @blur="blur"
      @keydown.enter="submit"
      type="text"
      autocomplete="new-password"
      autocorrect="off"
      spellcheck="false"
      placeholder=""
      tabindex="1"
      id="name-input"
      name="user_first_name"
      class="input-text black"
      :class="{ active: focused }"
    >
    <label class="input-label" :class="{ active: focused }">Region name</label>
  </div>
</template>

<script>
export default {
  props: ["visible"],
  data() {
    return {
      focused: false
    }
  },
  computed: {
    name: {
      get() {
        return this.$store.state.activeRegion.name;
      },
      set(name) {
        this.$store.commit("setActiveRegionName", name);
      }
    },
    complete() {
      return this.name !== "";
    }
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    submit() {
      if (this.complete) {
        this.$emit("submit");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 26px 15px 8px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.active {
    border: 2px solid #5850ec;
  }
}
.input-label {
  position: absolute;
  top: 8px;
  left: 16px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
</style>
