<template>
  <div class="wrapper">
    <heading v-if="needsInsight">What insight will you share?</heading>
    <heading v-else>Any description or commentary?</heading>
    <form @submit.prevent="confirmPostPrivacy" autocomplete="off" novalidate>
      <div class="input-container">
        <resizable-textarea>
          <textarea
            v-focus
            v-model="notes"
            @focus="focus"
            @blur="blur"
            rows="3"
            autocorrect="off"
            spellcheck="false"
            placeholder=""
            tabindex="1"
            name="intel_notes"
            class="input-text black"
            :class="{ active: focused && !privacyMode, 'privacy-active': focused && privacyMode }"
          />
        </resizable-textarea>
      </div>
      <button v-if="newRecordIsSafezone" @click="safezone" type="button" :class="{ 'mt-4': newRecordIsSafezone, 'mt-1': !newRecordIsSafezone, 'privacy-complete': privacyMode, 'complete': !privacyMode }" class="control cta" tabindex="999">
        <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
          <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
        </svg>
        Save to Safezone
      </button>
      <button v-else type="submit" class="cta mt-4" :class="privacyMode ? 'privacy-complete' : 'complete'" tabindex="999">
        <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
          <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
        </svg>
        Confirm author and post
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../../components/onboarding/heading";
import ResizableTextarea from "../../../components/resizable-textarea";
import Swal from "sweetalert2";
import anonymousPostOptions from "../../../sweet-alert-always-anonymous-options";
import namedPostOptions from "../../../sweet-alert-named-post-options";

export default {
  components: { Heading, ResizableTextarea },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    ...mapState([
      "activeIntel",
      "intelCreationInProcess",
      "newRecordIsSafezone"
    ]),
    ...mapGetters(["creatingIntel", "privacyMode"]),
    notes: {
      get() {
        return this.activeIntel.notes;
      },
      set(text) {
        this.$store.commit("setActiveIntelNotes", text);
      }
    },
    provided() {
      return this.notes !== null && this.notes !== "";
    },
    needsInsight() {
      return (
        this.activeIntel.type === "marketCommentary" && !this.activeIntel.url
      );
    },
    hasPrivateAudience() {
      return this.activeIntel.privateAudience.length > 0;
    }
  },
  mounted() {
    if (this.creatingIntel && !this.intelCreationInProcess) {
      this.$router.push({ path: "/intel/new/1" });
    }
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    safezone() {
      this.$store.commit("setIntelPublic");
      this.save();
    },
    confirmPostPrivacy() {
      if (this.hasPrivateAudience || this.privacyMode) {
        Swal.fire(anonymousPostOptions).then(result => {
          if (result.value) {
            this.save();
          }
        });
      } else {
        Swal.fire(namedPostOptions).then(result => {
          if (result.value) {
            this.save();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.$store.commit("setIntelPublic");
            this.save();
          }
        });
      }
    },
    save() {
      switch (this.activeIntel.type) {
        case "jobPosting":
          this.$store.dispatch("createJobPosting");
          break;
        case "industryEvent":
          this.$store.dispatch("createIndustryEvent");
          break;
        case "developmentNews":
          this.$store.dispatch("createDevelopmentNews");
          break;
        case "infrastructureNews":
          this.$store.dispatch("createInfrastructureNews");
          break;
        case "tenantInMarket":
          this.$store.dispatch("createTenantInMarket");
          break;
        case "marketCommentary":
          this.$store.dispatch("createMarketCommentary");
          break;
        case "marketReport":
          this.$store.dispatch("createMarketReport");
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
textarea {
  vertical-align: middle;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
}
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.privacy-active {
    border: 2px solid #d61f69;
  }
  &.active {
    border: 2px solid #5850ec;
  }
}
button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.privacy-complete {
    background: #d61f69;
    box-shadow: rgba(214, 31, 105, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #f17eb8;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
