<template>
  <div class="bg-gray-50 flex flex-1 flex-col rounded-lg overflow-hidden" :class="autoCardSize ? '' : 'shadow-lg'" :style="cardStyle">
    <img class="h-6 w-auto sm:h-8 absolute top-2 left-2" src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png" alt="Tower Hunt" />
    <div class="absolute top-8 sm:top-10 left-2 text-gray-200" style="font-size: 8px;">{{ cardMonthYear }}</div>
    <div class="flex-1 flex flex-col">
      <div class="bg-white px-6 py-10">
        <div>
          <h3 class="text-center text-lg leading-8 font-medium text-gray-900" id="location">
            {{ card.region.name }}
          </h3>
          <h4 class="mt-2 text-center text-xl leading-8 font-semibold text-gray-900" id="use">
            {{ primaryUseAlias }} &middot; {{ snapshotData.bracketedSize }}
          </h4>
          <div class="mt-4 flex items-center justify-center">
            <span class="px-3 flex items-start text-5xl leading-none tracking-tight text-gray-900">
              <span class="mt-2 mr-2 text-3xl font-medium">
                $
              </span>
              <span class="font-extrabold">
                {{ snapshotData.inPlaceNoi }}{{ snapshotData.inPlaceNoiUnits }}
              </span>
            </span>
            <span class="text-lg leading-7 font-medium text-gray-500">
              IP NOI
            </span>
          </div>
        </div>
      </div>
      <div class="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 sm:p-10 lg:p-6 xl:p-10">
        <ul>
          <li class="flex items-start">
            <div class="flex-shrink-0">
              <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <p class="ml-3 flex-grow text-base leading-6 font-medium text-gray-500">
              {{ snapshotData.occupancy }} leased<span class="text-xs" v-if="longTermLeasable">&nbsp;({{ snapshotData.tenantCount }})</span>
            </p>
          </li>
          <li v-if="longTermLeasable" class="mt-4 flex items-start">
            <div class="flex-shrink-0">
              <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <p class="ml-3 flex-grow text-base leading-6 font-medium text-gray-500">
              {{ snapshotData.walt }}-yr WALT
            </p>
          </li>
          <li v-if="longTermLeasable" class="mt-4 flex items-start">
            <div class="flex-shrink-0">
              <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <p class="ml-3 flex-grow text-base leading-6 font-medium text-gray-500">
              {{ snapshotData.creditNra }}% investment-grade
            </p>
          </li>
          <li class="mt-4 flex items-start">
            <div class="flex-shrink-0">
              <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <p class="ml-3 flex-grow text-base leading-6 font-medium text-gray-500">
              {{ snapshotData.vintage }}
            </p>
          </li>
          <li v-if="snapshotData.holdPeriod" class="mt-4 flex items-start">
            <div class="flex-shrink-0">
              <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <p class="ml-3 flex-grow text-base leading-6 font-medium text-gray-500">
              {{ snapshotData.holdPeriod }}
            </p>
          </li>
          <li v-if="snapshotData.specialFeatures.length > 0" class="mt-4 flex items-start">
            <div class="flex-shrink-0">
              <svg class="h-6 w-6 text-yellow-400" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <p class="ml-3 flex-grow text-sm leading-6 font-medium text-gray-500">
              {{ specialFeatures }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import useTypes from "../../../use-types";

export default {
  props: ["autoCardSize", "card"],
  computed: {
    snapshotData() {
      return this.card.propertySnapshot;
    },
    cardStyle() {
      if (this.autoCardSize) {
        return "";
      } else {
        return "width: 330px; height: 575px;";
      }
    },
    primaryUseAlias() {
      return _.find(useTypes, { value: this.snapshotData.primaryUse }).name;
    },
    specialFeatures() {
      return this.snapshotData.specialFeatures.join(", ");
    },
    cardMonthYear() {
      return moment(this.snapshotData.lastUpdated).format("MMMYYYY");
    },
    longTermLeasable() {
      return _.includes(
        ["Office", "Retail", "Industrial", "Lab", "Healthcare"],
        this.snapshotData.primaryUse
      );
    }
  }
};
</script>
