<template>
  <div class="mt-6">
    <ul v-if="teams.data.length > 0" class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <li @click="createTeam" class="col-span-1 flex shadow-sm rounded-md cursor-pointer">
        <div class="flex-shrink-0 flex items-center justify-center w-16 bg-gray-300 text-gray-500 text-sm font-medium rounded-l-md">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
          <div class="flex-1 px-4 py-2 text-sm truncate">
            <span class="text-gray-900 font-medium">Create new team</span>
            <p class="text-gray-500">Build shared consciousness</p>
          </div>
        </div>
      </li>

      <li v-for="team in teams.data" :key="team.id" class="relative col-span-1 flex shadow-sm rounded-md">
        <div @click="viewTeam(team)" :class="`flex-shrink-0 flex items-center justify-center w-16 bg-${roleColor(team)} text-white text-sm font-medium rounded-l-md cursor-pointer`">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
            <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
          </svg>
        </div>
        <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
          <div class="flex-1 px-4 py-2 text-sm truncate">
            <a href="" @click.prevent="viewTeam(team)" class="text-gray-900 font-medium hover:text-gray-600">{{ team.name }}</a>
            <p class="text-gray-500">{{ memberCount(team) }}&nbsp;&middot;&nbsp;{{ team.role }}</p>
          </div>
          <team-menu :team="team" @refetch="fetchTeams" @edit="editTeam(team)" />
        </div>
      </li>

      <infinite-loading
        v-if="loaded && !endOfFeed"
        :identifier="currentUser.token"
        @infinite="incrementTeams"
      />
    </ul>

    <div v-else>
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div class="bg-indigo-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div class="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div class="lg:self-center">
              <h2 class="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10">
                <span class="block">Build shared consciousness.</span>
              </h2>
              <p class="mt-4 text-lg leading-6 text-indigo-200">Track markets together with teams. These private workspaces allow people within and across companies to share data and insights. When a new person joins a team, they gain instant context.</p>
              <button @click="createTeam" class="mt-8 bg-white border border-transparent rounded-md shadow px-6 py-3 inline-flex items-center text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 hover:bg-gray-50">Create new team</button>
            </div>
          </div>
          <div class="relative pb-3/5 -mt-6 md:pb-1/2">
            <img class="absolute inset-0 w-full h-full transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20" src="https://assets.towerhunt.com/site/team_compressed0.jpg" alt="App screenshot">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import TeamMenu from "./team-menu";
import api from "../api";
import pluralize from "pluralize";

export default {
  components: { InfiniteLoading, TeamMenu },
  data() {
    return {
      teams: {
        data: [],
        pagy: null
      },
      busy: false,
      endOfFeed: false,
      loaded: false
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    ...mapGetters(["isAdmin"]),
    pagination() {
      return this.teams.pagy;
    }
  },
  mounted() {
    this.fetchTeams();
  },
  methods: {
    createTeam() {
      this.$emit("create-team");
    },
    viewTeam(team) {
      this.$router.push({ path: `/backchannel/${team.backchannelToken}` });
    },
    editTeam(team) {
      this.$emit("edit-team", team.id);
    },
    memberCount(team) {
      return pluralize("Member", team.memberCount, true);
    },
    roleColor(team) {
      switch (team.role) {
        case "Primary owner":
          return "yellow-400";
        default:
          return "indigo-600";
      }
    },
    fetchTeams() {
      api.get(`teams`).then(json => {
        this.teams = json.data;
        this.loaded = true;
      });
    },
    extendTeams({ data, pagy }) {
      this.teams.data = this.teams.data.concat(data);
      this.teams.pagy = pagy;
    },
    incrementTeams($state) {
      if (this.pagination) {
        const { count, to } = this.pagination;

        if (count === 0) {
          this.endOfFeed = true;
        } else if (to < count) {
          const cleanUrl = _.drop(this.pagination.next_url, 8).join("");

          this.busy = true;

          api.get(cleanUrl).then(json => {
            this.extendTeams(json.data);
            $state.loaded();
            this.busy = false;
          });
        } else {
          this.endOfFeed = true;
          $state.complete();
        }
      }
    }
  }
};
</script>
