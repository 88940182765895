import sortedTypes from "./sorted-types";

export default function sortedTypeLabels(currentUser, types, limit = null) {
  const currentRole = _.get(currentUser, "industryRole", "observer");
  const unsortedTypes = types.map(obj => obj.type);
  const sortingArray = _.get(sortedTypes, currentRole);
  const sortable = unsortedTypes.map(type =>
    _.find(sortingArray, { value: type })
  );
  const sorted = _.sortBy(sortable, ["order"]);
  const overflow = limit ? sorted.length - limit : null;
  const showOverflow = overflow && overflow > 0;

  if (limit && showOverflow) {
    return (
      _.take(sorted, limit)
        .map(obj => obj.name)
        .join(", ") + ` +${overflow} more`
    );
  } else if (limit) {
    return _.take(sorted, limit)
      .map(obj => obj.name)
      .join(", ");
  } else {
    return sorted.map(obj => obj.name).join(", ");
  }
}
