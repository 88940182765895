<template>
  <li class="relative col-span-1 flex flex-col bg-white rounded-lg shadow divide-y divide-gray-200">
    <div class="w-full flex flex-grow flex-col p-6">
      <div class="flex items-center justify-between relative">
        <a @click.prevent="viewDetails" href="" class="flex-1 truncate relative">
          <!-- Extend touch target to entire panel -->
          <span class="absolute inset-0" aria-hidden="true"></span>
          <div class="flex items-center space-x-3">
            <h3 class="text-gray-900 text-sm font-medium truncate">{{ contact.name }}</h3>
          </div>
          <p class="mt-1 text-gray-500 text-xs truncate">
            <span v-if="contact.title">{{ contact.title }}</span>
            <span v-else>Updated: {{ contact.updatedAt | moment("MMM DD, YYYY") }}</span>
          </p>
        </a>
        <div ref="involvedContactMenu" style="z-index: 9998;">
          <button @click="toggleMenu" type="button" class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500" id="pinned-project-options-menu-0-button" aria-expanded="false" aria-haspopup="true">
            <span class="sr-only">Open options</span>
            <!-- Heroicon name: solid/dots-vertical -->
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
            </svg>
          </button>

          <transition
            enter-active-class="duration-100 ease-out"
            enter-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="duration-75 ease-in"
            leave-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-95"
          >
            <div v-show="menuOpen" class="z-10 mx-3 origin-top-right absolute right-10 top-3 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="pinned-project-options-menu-0-button" tabindex="-1">
              <div class="py-1" role="none">
                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                <a @click.prevent="viewDetails" href="" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="pinned-project-options-menu-0-item-0">View</a>
              </div>
              <div class="py-1" role="none">
                <a @click.prevent="remove" href="" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="pinned-project-options-menu-0-item-1">Remove</a>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="mt-2 text-xs text-gray-500 whitespace-pre-line dont-break-out line-clamp-3">
        {{ contact.contactInfo }}
      </div>
    </div>
  </li>
</template>

<script>
import ContactSlideover from "../../contact-slideover.vue";
import Swal from "sweetalert2";
import removeInvolvedContactOptions from "../../../sweet-alert-remove-involved-contact-options";

export default {
  props: ["contact", "involvedCompany"],
  data() {
    return {
      menuOpen: false
    };
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.involvedContactMenu &&
        !this.$refs.involvedContactMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    viewDetails() {
      this.$store.commit("openSlideover", {
        component: ContactSlideover,
        props: {
          involvedCompany: this.involvedCompany,
          contact: this.contact,
          editAtStartup: false
        }
      });

      this.menuOpen = false;
    },
    remove() {
      Swal.fire(removeInvolvedContactOptions).then(result => {
        if (result.value) {
          const involvedContactPayload = {
            involvementId: this.involvedCompany.id,
            contactId: this.contact.id
          };

          this.$store
            .dispatch("removeInvolvedContact", involvedContactPayload)
            .then(() => {
              this.$emit("removed");
            });
        }

        this.menuOpen = false;
      });
    }
  }
};
</script>
