export default {
  title: "Ready to launch?",
  text:
    "Launching makes the deal accessible to your chosen investor pool. Please confirm you've set your confidentiality agreement and finalized your Overview, Photos, and Documents pages.",
  showCancelButton: true,
  showCloseButton: true,
  focusCancel: true,
  confirmButtonColor: "#5850ec",
  cancelButtonColor: "#b7b7b7",
  confirmButtonText: "Launch",
  reverseButtons: true,
  focusConfirm: false,
  customClass: {
    header: "thswal-header-class",
    title: "thswal-title-class",
    closeButton: "thswal-close-button-class",
    content: "thswal-content-class",
    actions: "thswal-actions-class",
    confirmButton: "thswal-confirm-button-class",
    cancelButton: "thswal-cancel-button-class"
  }
};
