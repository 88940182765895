<template>
  <div ref="avatarMenu" class="ml-3 relative" style="z-index: 9999;">
    <div>
      <button
        class="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring"
        @click="toggleMenu"
      >
        <span
          v-if="privacyMode"
          class="h-10 w-10 flex-shrink-0 inline-flex items-center justify-center rounded-full bg-pink-600"
        >
          <svg class="h-4 w-4 text-white" fill="currentColor" viewBox="0 0 384 512">
            <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
          </svg>
        </span>
        <avatar-photo v-else :person="currentUser" circle-size="10" text-size="md" />
      </button>
    </div>
    <transition
      enter-active-class="duration-150 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div
        v-show="menuOpen"
        class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg"
      >
        <div class="pb-1 overflow-hidden rounded-md bg-white ring-1 ring-black ring-opacity-5">
          <privacy-mode-toggle />
          <router-link
            to="/home"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            @click.native="menuOpen = false"
          >
            My Dashboard
          </router-link>
          <router-link
            to="/home/settings"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            @click.native="menuOpen = false"
          >
            Settings
          </router-link>
          <router-link
            v-if="currentUser.admin"
            to="/admin"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            @click.native="menuOpen = false"
          >
            Admin mode
          </router-link>
          <a
            href=""
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            @click.prevent="signOut"
          >
            Sign out
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AvatarPhoto from "./avatar-photo";
import PrivacyModeToggle from "./privacy-mode-toggle";

export default {
  components: { AvatarPhoto, PrivacyModeToggle },
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    privacyMode() {
      return this.currentUser.privacyMode;
    },
    profilePath() {
      if (this.currentUser) {
        return `/contacts/${this.currentUser.token}`;
      } else {
        return "#";
      }
    },
    name() {
      return this.currentUser.name;
    }
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.avatarMenu &&
        !this.$refs.avatarMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    signOut() {
      this.menuOpen = false;
      this.$store.dispatch("signOut");
      this.$store.dispatch("clearAll");
    }
  }
};
</script>
