<template>
  <div v-if="shouldDisplayBidderActions" class="border-t border-gray-200 w-full flex" :class="flat ? '' : 'rounded-b-lg'">
    <button v-if="myBid && myBid.state === 'engaged'" @click="fulfill" v-tooltip="'Fulfill the request before it expires to earn the bounty.'" type="button" :class="myBidButtonClass" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center text-white font-medium border border-transparent bg-gradient-to-r focus:z-10">
      <!-- Heroicon name: cloud-upload -->
      <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
        <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
        <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
      </svg>
      <span class="ml-2">Fulfill bounty &middot; Expires {{ myBid.expiresAt | moment("from") }}</span>
    </button>
    <button v-else-if="myBid && myBid.state === 'submitted'" @click="reviewBid" v-tooltip="'Click to review. You\'ll book the bounty revenue once your submission is approved.'" :class="{ 'rounded-b-lg': !flat, 'p-2 text-xs leading-4': compact, 'py-4 text-sm leading-5': !compact }" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center font-medium border-gray-300 text-gray-700 focus:outline-none">
      <!-- Heroicon name: clock -->
      <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
      </svg>
      <span class="ml-2">Response submitted &middot; Approval pending</span>
    </button>
    <button v-else-if="myBid && (myBid.state === 'fulfilled' || myBid.state === 'payable')" @click="reviewBid" v-tooltip="'Nice work!'" :class="{ 'rounded-b-lg': !flat, 'p-2 text-xs leading-4': compact, 'py-4 text-sm leading-5': !compact }" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center font-medium border-gray-300 text-gray-700 focus:outline-none">
      <!-- Heroicon name: currency-dollar -->
      <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
        <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
      </svg>
      <span class="ml-2">${{ myBid.bountyPrice }} booked! &middot; View details</span>
    </button>
    <button v-else-if="myBid" @click="reviewBid" v-tooltip="'Click to review your bid.'" :class="{ 'rounded-b-lg': !flat, 'p-2 text-xs leading-4': compact, 'py-4 text-sm leading-5': !compact }" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center font-medium border-gray-300 text-gray-700 focus:outline-none">
      <!-- Heroicon name: currency-dollar -->
      <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
        <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
      </svg>
      <span class="ml-2">You bid ${{ myBid.bountyPrice }} &middot; View details</span>
    </button>
    <button v-else @click="bid" type="button" v-tooltip="bidHelp" :class="bidButtonClass" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center text-white font-medium border border-transparent bg-gradient-to-r focus:z-10">
      <!-- Heroicon name: currency-dollar -->
      <svg class="w-5 h-5 text-white" viewBox="0 0 20 20" fill="currentColor">
        <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
      </svg>
      <span v-if="expiringSoon" class="ml-2">Bid now &middot; Expires {{ bounty.expiresAt | moment("from") }}</span>
      <span v-else-if="bounty.priced" class="ml-2">Bid now &middot; Worth {{ bounty | bountyValueRange }}</span>
      <span v-else class="ml-2">Bid now &middot; Earn $$$</span>
    </button>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters } from "vuex";
import BidForm from "./bid-form";
import BidReview from "./bid-review";
import FulfillmentForm from "./fulfillment-form";
import PaymentConnectedAccountGateway from "../payment-connected-account-gateway";

export default {
  props: ["bounty", "flat", "compact"],
  computed: {
    ...mapGetters(["signedIn"]),
    myBid() {
      return this.bounty.myBid;
    },
    shouldDisplayBidderActions() {
      const unexpiredLive = !this.bounty.expired && this.bounty.state === "live";
      const unexpiredAwarded = !this.bounty.expired && this.bounty.state === "awarded";
      const unexpiredEngaged = !this.bounty.expired && this.bounty.myBid && this.bounty.myBid.state === "engaged";
      const submittedFulfilled = this.bounty.myBid && _.includes(["submitted", "fulfilled", "payable"], this.bounty.myBid.state);

      return (
        unexpiredLive ||
        unexpiredAwarded ||
        unexpiredEngaged ||
        submittedFulfilled
      );
    },
    expiringSoon() {
      return moment(this.bounty.expiresAt)
        .subtract(1, "week")
        .isBefore();
    },
    bidExpiringSoon() {
      if (this.myBid) {
        return moment(this.myBid.expiresAt)
          .subtract(3, "days")
          .isBefore();
      } else {
        return false;
      }
    },
    bidHelp() {
      if (this.expiringSoon && this.bounty.priced) {
        return `Worth ${this.$options.filters.bountyValueRange(this.bounty)}`;
      } else if (this.expiringSoon) {
        return "Earn $$$";
      } else {
        return `Expires ${moment(this.bounty.expiresAt).fromNow()}`;
      }
    },
    bidButtonClass() {
      const flatStyle = this.flat ? "" : "rounded-b-lg";
      const compactStyle = this.compact
        ? "p-2 text-xs leading-4"
        : "py-4 text-sm leading-5";
      const colorStyle = this.expiringSoon
        ? "from-red-500 to-red-700 hover:to-red-800 focus:border-red-700 focus:ring-red active:to-red-800"
        : "from-yellow-300 to-yellow-500 hover:to-yellow-600 focus:border-yellow-500 focus:ring-yellow active:to-yellow-600";

      return `${flatStyle} ${colorStyle} ${compactStyle}`;
    },
    myBidButtonClass() {
      const flatStyle = this.flat ? "" : "rounded-b-lg";
      const compactStyle = this.compact
        ? "p-2 text-xs leading-4"
        : "py-4 text-sm leading-5";
      const colorStyle = this.bidExpiringSoon
        ? "from-red-500 to-red-700 hover:to-red-800 focus:border-red-700 focus:ring-red active:to-red-800"
        : "from-yellow-300 to-yellow-500 hover:to-yellow-600 focus:border-yellow-500 focus:ring-yellow active:to-yellow-600";

      return `${flatStyle} ${colorStyle} ${compactStyle}`;
    }
  },
  methods: {
    bid() {
      if (this.signedIn) {
        this.$store.commit("openModal", {
          component: BidForm,
          props: { bounty: this.bounty }
        });
        this.$store.commit("closeSlideover");
      } else {
        this.$router.push({
          path: "/sign-in",
          query: { nextUrl: `/bounties/active` }
        });
        this.$store.commit("closeSlideover");
      }
    },
    fulfill() {
      if (this.signedIn) {
        this.$store.commit("openModal", {
          component: PaymentConnectedAccountGateway,
          props: {
            value: {
              component: FulfillmentForm,
              props: {
                bounty: this.bounty,
                returnUrl: `https://towerhunt.com/bounties/my-engagements`
              }
            }
          }
        });
        this.$store.commit("closeSlideover");
      } else {
        this.$router.push({
          path: "/sign-in",
          query: { nextUrl: `/bounties/my-engagements` }
        });
        this.$store.commit("closeSlideover");
      }
    },
    reviewBid() {
      if (this.signedIn) {
        this.$store.commit("openModal", {
          component: BidReview,
          props: { bounty: this.bounty, bid: this.bounty.myBid }
        });
        this.$store.commit("closeSlideover");
      } else {
        this.$router.push({
          path: "/sign-in",
          query: { nextUrl: `/bounties/my-bounties` }
        });
        this.$store.commit("closeSlideover");
      }
    }
  }
};
</script>
