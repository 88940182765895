<template>
  <div>
    <div class="mt-6">
      <div class="pb-5 border-b border-gray-200 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
        <div class="space-y-2">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Payout Account
          </h3>
          <p class="max-w-4xl text-sm leading-5 text-gray-500">Where should revenue be paid out?</p>
        </div>
        <div>
          <span class="shadow-sm rounded-md">
            <button @click="editPayoutAccounts" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
              Edit accounts
            </button>
          </span>
        </div>
      </div>
    </div>

    <fieldset v-if="payoutAccounts.length > 0" class="mt-6">
      <legend class="sr-only">
        Selected payout account
      </legend>

      <div class="bg-white rounded-md -space-y-px">
        <!-- On: "bg-indigo-50 border-indigo-200 z-10", Off: "border-gray-200" -->
        <div v-for="(account, index) in payoutAccounts" :key="index" class="relative border p-4 flex" :class="accountSelectorClassFor(account, index)">
          <div class="flex items-center h-5">
            <input @change="selectPayoutAccount(account)" :id="`settings-option-${index}`" name="account_selection" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 cursor-pointer border-gray-300" :checked="account.selectedForPurchaseable">
          </div>
          <label :for="`settings-option-${index}`" class="ml-3 flex flex-col cursor-pointer">
            <!-- On: "text-indigo-900", Off: "text-gray-900" -->
            <span class="block text-sm font-medium" :class="account.selectedForPurchaseable ? 'text-indigo-900' : 'text-gray-900'">
              <template v-if="account.personal">My personal account</template>
              <template v-else>{{ account.name }}</template>
            </span>
            <!-- On: "text-indigo-700", Off: "text-gray-500" -->
            <span class="block text-sm" :class="account.selectedForPurchaseable ? 'text-indigo-700' : 'text-gray-500'">
              <template v-if="account.personal">Private</template>
              <template v-else>{{ account.category | capitalize }}</template>
            </span>
          </label>
        </div>
      </div>
    </fieldset>

    <div v-else class="mt-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <!-- Heroicon name: solid/exclamation -->
          <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm text-yellow-700">
            We'll prompt you to add a payout account before you launch any content, or you can <a href="" @click.prevent="editPayoutAccounts" class="font-medium underline text-yellow-700 hover:text-yellow-600">add one now.</a>
          </p>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <div class="pb-5 border-b border-gray-200 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
        <div class="space-y-2">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Current Prices
          </h3>
          <p class="max-w-4xl text-sm leading-5 text-gray-500">Manage your pricing program.</p>
        </div>
        <div>
          <span class="shadow-sm rounded-md">
            <button @click="newPrice" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
              Add price
            </button>
          </span>
        </div>
      </div>
    </div>
    <ul class="mt-6 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2">
      <purchaseable-price v-for="price in prices" :key="price.id" :price="price" :purchaseable="purchaseable" @edit="edit(price)" @refetch-prices="refetchAll" />
    </ul>
  </div>
</template>

<script>
import PurchaseablePrice from "./purchaseable-price";
import api from "../../api";

export default {
  components: { PurchaseablePrice },
  props: ["purchaseable"],
  data() {
    return {
      payoutAccounts: [],
      prices: []
    };
  },
  mounted() {
    this.fetchPrices();
  },
  methods: {
    refetchAll() {
      this.fetchPrices();
      this.$store.commit("setRefetchPurchaseables", true);
    },
    fetchPrices() {
      api
        .get(`purchaseables/${this.purchaseable.id}/purchaseable_prices`)
        .then(json => {
          this.payoutAccounts = json.data.payoutAccounts;
          this.prices = json.data.prices;

          if (this.prices.length === 0) {
            this.$emit("new-price");
          }
        });
    },
    editPayoutAccounts() {
      this.$router.push({ path: "/home/settings#payouts" });
      this.$store.commit("closeModal");
    },
    selectPayoutAccount(account) {
      const payload = {
        sharedPayoutAccountId: account.personal ? null : account.id
      };

      api
        .patch(`purchaseables/${this.purchaseable.id}/payout_account`, payload)
        .then(json => {
          this.$store.dispatch("flash", "Payout account updated.");
          this.fetchPrices();
        });
    },
    accountSelectorClassFor(account, index) {
      const shading = account.selectedForPurchaseable
        ? "bg-indigo-50 border-indigo-200 z-10"
        : "border-gray-200";
      let shape = "";

      if (index === 0) {
        shape = "rounded-tl-md rounded-tr-md";
      } else if (index === this.payoutAccounts.length - 1) {
        shape = "rounded-bl-md rounded-br-md";
      }

      return `${shading} ${shape}`;
    },
    newPrice() {
      this.$emit("new-price");
    },
    edit(price) {
      this.$emit("edit-price", price);
    }
  }
};
</script>
