<template>
  <div v-if="activeDeal.marketingReadAuthorized && currentUser && activeDealMarketing.id" class="overflow-y-auto" :class="{ 'bg-gray-100': isMarketingList }">
    <nav @keydown.escape="open = false" v-observe-visibility="{ callback: composeMeta, once: true }" class="bg-indigo-700">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <router-link to="/">
                <img class="h-10 w-auto sm:h-12" src="https://assets.towerhunt.com/site/Transparent_White_T0.png" alt="Tower Hunt logo" />
              </router-link>
            </div>
            <div class="hidden md:block">
              <div v-if="activeDeal && activeDealMarketing" class="ml-10 flex items-baseline">
                <router-link
                  :to="tabPathWithQuery('overview')"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-indigo-800': isExactActive }"
                    class="px-3 py-2 rounded-md text-sm font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
                  >
                    Overview
                  </a>
                </router-link>
                <router-link
                  :to="tabPathWithQuery('photos')"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-indigo-800': isExactActive }"
                    class="ml-4 px-3 py-2 rounded-md text-sm font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
                  >
                    Photos
                  </a>
                </router-link>
                <router-link
                  :to="tabPathWithQuery('documents')"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-indigo-800': isExactActive }"
                    class="ml-4 px-3 py-2 rounded-md text-sm font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
                  >
                    Documents
                  </a>
                </router-link>
                <router-link
                  v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized"
                  to="marketing-list"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-indigo-800': isExactActive }"
                    class="ml-4 px-3 py-2 rounded-md text-sm font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
                  >
                    Marketing List
                  </a>
                </router-link>
              </div>
            </div>
            <div class="block md:hidden">
              <div v-if="activeDeal && activeDealMarketing" class="ml-5 flex items-baseline">
                <router-link
                  :to="tabPathWithQuery('overview')"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-indigo-800': isExactActive }"
                    class="px-3 py-2 rounded-md text-sm font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
                  >
                    Overview
                  </a>
                </router-link>
                <router-link
                  :to="tabPathWithQuery('photos')"
                  v-slot="{ href, navigate, isExactActive }"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="{ 'bg-indigo-800': isExactActive }"
                    class="ml-4 px-3 py-2 rounded-md text-sm font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
                  >
                    Photos
                  </a>
                </router-link>
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <div v-if="signedIn" class="ml-4 flex items-center md:ml-6">
              <notifications-menu />
              <notepad-button />
              <avatar-menu />
            </div>
            <div v-else class="ml-4 flex items-center md:ml-6">
              <button
                @click="signIn"
                type="button"
                class="px-3 py-2 rounded-md text-sm font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
              >
                Sign in
              </button>
              <span class="ml-3 inline-flex rounded-md shadow-sm">
                <button
                  @click="joinNow"
                  type="button"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:ring-indigo active:bg-indigo-200"
                >
                  Join now
                </button>
              </span>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <button @click="toggleMenu" class="inline-flex items-center justify-center p-2 rounded-md text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 focus:text-white" x-bind:aria-label="open ? 'Close main menu' : 'Main menu'" x-bind:aria-expanded="open">
              <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path :class="{'hidden': open, 'inline-flex': !open }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                <path :class="{'hidden': !open, 'inline-flex': open }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div v-if="currentUser && activeDeal && activeDealMarketing" :class="{'block': open, 'hidden': !open}" class="z-20 md:hidden">
        <div class="px-2 pt-2 pb-3 sm:px-3">
          <router-link
            :to="tabPathWithQuery('documents')"
            v-slot="{ href, navigate, isExactActive }"
          >
            <a
              @click="navigate"
              :href="href"
              :class="{ 'bg-indigo-800': isExactActive }"
              class="block px-3 py-2 rounded-md text-base font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
            >
              Documents
            </a>
          </router-link>
          <router-link
            v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized"
            to="marketing-list"
            v-slot="{ href, navigate, isExactActive }"
          >
            <a
              @click="navigate"
              :href="href"
              :class="{ 'bg-indigo-800': isExactActive }"
              class="block px-3 py-2 rounded-md text-base font-medium text-indigo-200 focus:outline-none focus:text-white hover:text-white hover:bg-indigo-600 focus:bg-indigo-600"
            >
              Marketing List
            </a>
          </router-link>
        </div>
        <div v-if="signedIn" class="pt-4 pb-3 border-t border-gray-700">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <avatar-photo :person="currentUser" circle-size="10" text-size="md" />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium leading-none text-white">{{ currentUser.name }}</div>
              <div class="mt-1 text-sm font-medium leading-none text-indigo-300">{{ currentUser.email }}</div>
            </div>
          </div>
          <div class="mt-3 px-2" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <router-link to="/home" @click.native="open = false" class="block px-3 py-2 rounded-md text-base font-medium text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600" role="menuitem">My Dashboard</router-link>
            <router-link to="/home/settings" @click.native="open = false" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600" role="menuitem">Settings</router-link>
            <a href="#" @click.prevent="signOut" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600" role="menuitem">Sign out</a>
          </div>
        </div>
        <div v-else class="pt-4 pb-3 border-t border-gray-700">
          <div class="mt-3 px-2" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <router-link to="/start/1" @click.native="open = false" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600" role="menuitem">Join now</router-link>
            <router-link to="/sign-in" @click.native="open = false" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-300 hover:text-white hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600" role="menuitem">Sign in</router-link>
          </div>
        </div>
      </div>
    </nav>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div v-if="activeDeal.state === 'draft' && activeDeal.marketingWriteAuthorized" class="bg-gray-600">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="flex flex-col items-center sm:flex-row sm:justify-between">
          <div class="w-full flex-1 flex items-center justify-start">
            <span class="flex p-2 rounded-lg bg-gray-800">
              <svg v-if="activeDealMarketing.investorPreview" class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
              </svg>
              <svg v-else class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
              </svg>
            </span>
            <p class="ml-3 font-medium text-white truncate">
              <span class="md:hidden">
                Toggle investor preview
              </span>
              <span class="hidden md:inline">
                <template v-if="activeDealMarketing.investorPreview">You're viewing the investor preview for this deal. Toggle off to resume editing.</template>
                <template v-else>See what the deal looks like to investors by toggling on preview mode.</template>
              </span>
            </p>
          </div>
          <div class="mt-2 flex-shrink-0 w-full sm:mt-0 sm:w-auto">
            <investor-preview-toggle />
          </div>
        </div>
      </div>
    </div>
    <main>
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <router-view />
      </div>
    </main>
    <div v-if="activeDeal.state !== 'draft' || activeDealMarketing.investorPreview" class="fixed bottom-2 right-2 sm:bottom-5 sm:right-5" style="z-index: 5000;">
      <button @click="notepad" type="button" v-tooltip="'Add notes to this deal that only you can see.'" class="inline-flex items-center p-3 border border-transparent rounded-full shadow-lg text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <!-- Heroicon name: light-bulb -->
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
      </button>
    </div>
    <deal-go-live-bar v-if="activeDeal.state === 'draft' && !activeDealMarketing.investorPreview" style="z-index: 5000;" />
    <prospect-invitation-expiration v-if="dealProspectInvitationViewedAt" style="z-index: 5000;" />
    <marketing-footer :compact="true" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AvatarMenu from "../components/avatar-menu";
import AvatarPhoto from "../components/avatar-photo";
import DealGoLiveBar from "./offered-deal/deal-go-live-bar";
import InvestorPreviewToggle from "./offered-deal/investor-preview-toggle";
import MarketingFooter from "../components/marketing-footer";
import Notepad from "../components/notepad";
import NotepadButton from "../components/notepad-button";
import NotificationsMenu from "../components/notifications-menu";
import ProspectInvitationExpiration from "./offered-deal/prospect-invitation-expiration";

let interval;

export default {
  components: {
    AvatarMenu,
    AvatarPhoto,
    DealGoLiveBar,
    InvestorPreviewToggle,
    MarketingFooter,
    NotepadButton,
    NotificationsMenu,
    ProspectInvitationExpiration
  },
  props: ["offeredDealToken"],
  data() {
    return {
      open: false
    };
  },
  computed: {
    ...mapState([
      "loading",
      "currentUser",
      "activeDeal",
      "activeDealMarketing",
      "route",
      "dealProspectInvitationViewedAt"
    ]),
    ...mapGetters([
      "signedIn",
      "offeredDealTokenParam",
      "userIsActiveContact",
      "dealProspectInviteParam"
    ]),
    shouldFetchContact() {
      return this.signedIn && !this.userIsActiveContact;
    },
    isMarketingList() {
      return _.includes(this.route.path, "marketing-list");
    },
    profilePath() {
      if (this.currentUser) {
        return `/contacts/${this.currentUser.token}`;
      } else {
        return "#";
      }
    },
    dealState() {
      return this.activeDeal.state;
    }
  },
  watch: {
    dealState: {
      handler() {
        if (this.dealState && !this.activeDeal.marketingReadAuthorized) {
          this.$store.dispatch("flash", "Invalid sharing link");
          this.$router.push({ path: "/" });
        }
      }
    },
    currentUser: {
      handler: function() {
        if (this.shouldFetchContact) {
          this.$store.dispatch("loadContactData", this.currentUser.token);
        }
      }
    }
  },
  created() {
    var self = this;

    interval = setInterval(function() {
      self.checkInviteExpiration();
    }, 30000);
  },
  destroyed() {
    clearInterval(interval);
    this.$store.commit("clearProspectInvitationViewedAt");
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Tower Hunt is a local commercial real estate market tracker for local experts, by local experts. Earn money for local expertise, see off-market leads, and go beyond the headlines."
      );
  },
  mounted() {
    if (this.shouldFetchContact) {
      this.$store.dispatch("loadContactData", this.currentUser.token);
    }

    this.checkInviteExpiration();
    this.$store.dispatch("loadDealMarketing", this.offeredDealToken);
    this.$store.dispatch("loadSharedDeal", this.offeredDealToken);
  },
  methods: {
    signIn() {
      this.$router.push({ path: "/sign-in" });
    },
    joinNow() {
      this.$router.push({ path: "/start/1" });
    },
    tabPathWithQuery(path) {
      if (this.dealProspectInviteParam) {
        return `${path}?invite=${this.route.query.invite}`;
      } else {
        return `${path}`;
      }
    },
    toggleMenu() {
      this.open = !this.open;
    },
    checkInviteExpiration() {
      if (this.dealProspectInviteParam) {
        this.$store.dispatch(
          "checkDealProspectInvitationExpiration",
          this.dealProspectInviteParam
        );
      }
    },
    signOut() {
      this.open = false;
      this.$store.dispatch("signOut");
      this.$store.dispatch("clearAll");
    },
    composeMeta(isVisible, entry) {
      const dealName = this.activeDeal.name;
      const dealType = this.$options.filters.dealTypeAlias(
        this.activeDeal.type,
        this.activeDeal.jointVentureRoles
      );
      const location = this.$options.filters.summarizedLocation(
        this.activeDeal.properties
      );
      const count = this.$options.filters.propertiesCount(
        this.activeDeal.properties
      );
      const size = this.$options.filters.totalSize(this.activeDeal.properties);
      const secondaryHeadline = this.activeDealMarketing.secondaryHeadline;
      const cta = "View the executive summary, photos, documents, nearby comps, nearby development projects, and nearby large tenants. Register to contact the deal team and pursue this investment opportunity on Tower Hunt.";

      const combinedContent = `${dealName} (${dealType}), ${location}, ${count}: ${size}. ${secondaryHeadline} ${cta}`;

      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", combinedContent);
    },
    notepad() {
      this.$store.commit("openSlideover", {
        component: Notepad,
        props: {
          myNotepad: true,
          content: this.activeDeal,
          contentType: "deal"
        }
      });
    }
  }
};
</script>
