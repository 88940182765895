<template>
  <div @mouseenter="highlightRecord" @mouseleave="unhighlightRecord" class="flex space-x-3 py-3 hover:bg-gray-100" :style="highlightedBackgroundColor">
    <safezone-color-dot :content="content" style-type="icon" :overlay-badge="true" @view-details="viewDetails" />
    <div class="min-w-0 flex-1">
      <p class="text-sm font-medium text-gray-900">
        <a href="" @click.prevent="viewDetails">{{ content.name }}</a>
      </p>
      <div v-if="content.notes" v-html="linkified(content.notes)" class="mt-1 text-xs text-gray-500 whitespace-pre-line dont-break-out line-clamp-3" />
      <div v-else-if="content.body" v-html="linkified(content.body)" class="mt-1 text-xs text-gray-500 whitespace-pre-line dont-break-out line-clamp-3" />
      <div v-else-if="content.name" class="mt-1 space-x-2 flex items-center text-xs text-gray-500">
        <avatar-photo v-if="content.badgeType === 'file' && content.sharedBy" :person="content.sharedBy" v-tooltip="userTooltip(content.sharedBy)" border-class="ring-2 ring-gray-300" circle-size="6" text-size="xs" />
        <span class="whitespace-pre-line dont-break-out line-clamp-3">{{ content.contentName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AvatarPhoto from "../avatar-photo.vue";
import DealCard from "../cards/deal";
import DropboxEmbedViewer from "../dropbox-embed-viewer.vue";
import FileViewer from "../cards/file-viewer.vue";
import IFrameEmbedViewer from "../iframe-embed-viewer.vue";
import IntelCard from "../cards/market-intel";
import Notepad from "../notepad";
import SafezoneColorDot from "../safezone-color-dot";
import api from "../../api";
import linkifyHtml from "linkifyjs/html";
import responsiveMyFeedPopup from "../../leaflet-responsive-map-my-feed-popup";
import router from "../../router";
import store from "../../store";

export default {
  components: { AvatarPhoto, SafezoneColorDot },
  props: ["content", "map", "featureGroup",],
  data() {
    return {
      mapInternalId: null,
      highlighted: false,
      mapInternalRegionIds: [],
      linkedContent: null
    };
  },
  computed: {
    ...mapGetters(["signedIn"]),
    ...mapState(["mapNearbyProperties", "currentUser", "highlightedMapRecord"]),
    highlightedBackgroundColor() {
      if (this.highlighted) {
        return `background-color: #E0E7FF;`;
      } else {
        return "";
      }
    },
    propertyIds() {
      if (
        this.content.badgeType === "file" &&
        this.content.contentType === "Property"
      ) {
        return [{ id: this.content.contentToken }];
      } else if (
        this.content.badgeType === "notepad" &&
        this.content.contentType === "Property"
      ) {
        return [{ id: this.content.contentId }];
      } else {
        return _.get(this.linkedContent || this.content, "properties", []).map(
          p => {
            return { id: p.id };
          }
        );
      }
    },
    matchingProperties() {
      return _.intersectionBy(this.mapNearbyProperties, this.propertyIds, "id");
    },
    mapInternalIds() {
      return this.matchingProperties.flatMap(p => p.mapInternalId);
    },
    marketingListDeal() {
      return _.get(this.content, "source", null) === "direct";
    },
    liveDeal() {
      return _.includes(
        ["live", "awarded"],
        _.get(this.content, "state", null)
      );
    },
    activeDeal() {
      return _.includes(
        ["draft", "live", "awarded"],
        _.get(this.content, "state", null)
      );
    },
    comparisonId() {
      switch (this.content.badgeType || this.content.newsfeedType) {
        case "deal":
        case "intel":
          return this.content.token;
        case "file":
          return this.content.localId;
        case "message":
        case "notepad":
        case "backchannel":
        case "team":
        case "property":
        default:
          return this.content.id;
      }
    },
    linkedContentEndpoint() {
      switch (this.content.badgeType || this.content.newsfeedType) {
        case "message":
        case "notepad":
        case "file":
          switch (this.content.contentType) {
            case "Deal":
              return "open_deal";
            case "MarketIntelPost":
              return "open_intel";
            default:
              return null;
          }
        case "backchannel":
        case "team":
        case "property":
          switch (this.content.backchannelLinkedContentType) {
            case "Deal":
              return "open_deal";
            case "MarketIntelPost":
              return "open_intel";
            default:
              return null;
          }
        case "deal":
        case "intel":
        default:
          return null;
      }
    }
  },
  watch: {
    highlightedMapRecord: {
      handler() {
        if (
          this.highlightedMapRecord.contentType ===
            (this.content.badgeType || this.content.newsfeedType) &&
          this.highlightedMapRecord.contentToken === this.comparisonId
        ) {
          this.highlighted = true;
          this.bringPopupForward();
        } else {
          this.highlighted = false;
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.linkedContentEndpoint) {
      this.fetchLinkedContent().then(() => {
        this.displayFeedPopups();
      });
    } else {
      this.displayFeedPopups();
    }
  },
  methods: {
    fetchLinkedContent() {
      return new Promise(resolve => {
        api
          .get(`${this.linkedContentEndpoint}/${this.content.backchannelLinkedContentToken || this.content.contentToken}`)
          .then(json => {
            this.linkedContent = json.data;
            resolve();
          });
      });
    },
    userTooltip(user) {
      const name = this.$options.filters.capitalize(user.name);
      const role = _.capitalize(
        this.$options.filters.industryRoleAlias(
          user.industryRole,
          user.industrySubRole,
          user.token
        )
      );

      if (user.company) {
        return `Shared by: ${name} (${role} at ${user.company})`;
      } else {
        return `Shared by: ${name} (${role})`;
      }
    },
    highlightRecord() {
      this.bringPopupForward();
      this.$store.commit("setHighlightedMapRecord", {
        contentType: this.content.badgeType || this.content.newsfeedType,
        contentToken: this.comparisonId,
        mapTool: "myFeed",
        touching: "listItem"
      });

      if (_.get(this.linkedContent || this.content, "regions", []).length > 0) {
        this.displayRegions();
      }
    },
    unhighlightRecord() {
      this.$store.commit("setHighlightedMapRecord", {
        contentType: null,
        contentToken: null,
        mapTool: null,
        touching: null
      });

      if (_.get(this.linkedContent || this.content, "regions", []).length > 0) {
        this.removeRegions();
      }
    },
    bringPopupForward() {
      if (this.mapInternalId) {
        this.featureGroup.getLayer(this.mapInternalId).bringToFront();
      } else {
        this.displayFeedPopups();
      }
    },
    displayFeedPopups() {
      const id = _.head(this.mapInternalIds);
      const marker = this.featureGroup.getLayer(id);

      if (marker) {
        const responsivePopup = responsiveMyFeedPopup({
          store,
          router,
          marker,
          content: this.content,
          isFeed: true
        });

        responsivePopup.setLatLng(marker.getLatLng()).addTo(this.map);
        responsivePopup.bringToBack();
        responsivePopup.on("remove", e => {
          this.mapInternalId = null;
        });

        this.featureGroup.addLayer(responsivePopup);
        this.mapInternalId = this.featureGroup.getLayerId(responsivePopup);
      }
    },
    removeRegions: _.debounce(function() {
      if (!this.highlighted) {
        this.mapInternalRegionIds.forEach(id => {
          let layer = this.featureGroup.getLayer(id);

          this.map.removeLayer(layer);
          this.featureGroup.removeLayer(layer);
        });

        this.mapInternalRegionIds = [];
      }
    }, 5),
    displayRegions() {
      const target = this.linkedContent || this.content;

      target.regions.forEach(region => {
        const layer = this.mapRegion(region).addTo(this.map);

        this.featureGroup.addLayer(layer);
        this.mapInternalRegionIds.push(this.featureGroup.getLayerId(layer));
      });
    },
    mapRegion(region) {
      switch (region.shape) {
        case "circle":
          return L.circle(region.center, {
            radius: _.toNumber(region.radius),
            interactive: false,
            color: "#A5B4FC",
            stroke: true,
            fill: true,
            fillOpacity: 0.2
          });
        case "rectangle":
          return L.rectangle(region.coordinates, {
            interactive: false,
            color: "#A5B4FC",
            stroke: true,
            fill: true,
            fillOpacity: 0.2
          });
        case "polygon":
          return L.polygon(region.coordinates, {
            interactive: false,
            color: "#A5B4FC",
            stroke: true,
            fill: true,
            fillOpacity: 0.2
          });
        default:
          console.log("invalid shape");
      }
    },
    viewDetails() {
      let fileEmbedComponent;

      switch (this.content.badgeType || this.content.newsfeedType) {
        case "deal":
          if (
            this.activeDeal &&
            this.marketingListDeal &&
            this.content.dealTeam
          ) {
            const marketingPage =
              this.content.state === "draft" ? "overview" : "marketing-list";

            this.$router.push({
              path: `/deal_offerings/${this.content.urlHandle || this.content.token}/${marketingPage}`
            });
          } else if (
            this.liveDeal &&
            this.marketingListDeal &&
            this.content.marketingReadAuthorized
          ) {
            const marketingOverviewPath = `/deal_offerings/${this.content.urlHandle || this.content.token}/overview`;

            window.open(marketingOverviewPath, "_blank");
          } else {
            this.$store.commit("openModal", {
              component: DealCard,
              props: {
                dealObject: this.content,
                property: null
              }
            });
          }
          break;
        case "intel":
          this.$store.commit("openModal", {
            component: IntelCard,
            props: {
              postObject: this.content,
              property: null
            }
          });
          break;
        case "message":
          switch (this.content.contentType) {
            case "Deal":
              this.$store.commit("openModal", {
                component: DealCard,
                props: {
                  dealToken: this.content.contentToken,
                  property: null,
                  openComments: true
                }
              });
              break;
            case "MarketIntelPost":
              this.$store.commit("openModal", {
                component: IntelCard,
                props: {
                  postToken: this.content.contentToken,
                  property: null,
                  openComments: true
                }
              });
              break;
          }
          break;
        case "notepad":
          this.$store.commit("openSlideover", {
            component: Notepad,
            props: {
              myNotepad: true,
              contentToken: this.content.contentToken || this.content.contentId,
              contentType: this.content.contentType
            }
          });
          break;
        case "backchannel":
        case "team":
          window.open(
            `/backchannel/${this.content.backchannelToken}`,
            "_blank"
          );
          break;
        case "property":
          window.open(
            `/property-watchlist/${this.content.propertyFollowingId}/discussion`,
            "_blank"
          );
          break;
        case "file":
          switch (this.content.hostName) {
            case "dropbox":
              fileEmbedComponent = DropboxEmbedViewer;
              break;
            case "google":
            case "microsoft":
              fileEmbedComponent = IFrameEmbedViewer;
              break;
            case "tower_hunt":
            default:
              fileEmbedComponent = FileViewer;
          }

          this.$store.commit("openXLModal", {
            component: fileEmbedComponent,
            props: { file: this.content, document: this.content }
          });
          break;
        default:
          return;
      }
    },
    linkified(text) {
      return linkifyHtml(text, {
        defaultProtocol: "https",
        className: "font-medium text-indigo-600 hover:text-indigo-500"
      });
    }
  }
};
</script>
