<template>
  <div ref="purchaseableMenu" class="relative flex justify-end items-center" style="z-index: 9998;">
    <button @click="toggleMenu" id="purchaseable-options-menu-0" aria-has-popup="true" type="button" class="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100">
      <!-- Heroicon name: dots-vertical -->
      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
      </svg>
    </button>
    <transition
      enter-active-class="duration-100 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-75 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-show="menuOpen" class="mx-3 origin-top-right absolute right-7 top-0 w-48 mt-1 rounded-md shadow-lg">
        <div class="z-10 rounded-md bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="project-options-menu-0">
          <div class="py-1">
            <a v-if="purchaseable.authored" href="" @click.prevent="editPurchaseable" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <!-- Heroicon name: pencil-alt -->
              <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
              </svg>
              Edit
            </a>
            <a v-if="purchaseable.authored && purchaseable.complete" href="" @click.prevent="previewPurchaseable" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <!-- Heroicon name: pencil-alt -->
              <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M5 8a1 1 0 011-1h1V6a1 1 0 012 0v1h1a1 1 0 110 2H9v1a1 1 0 11-2 0V9H6a1 1 0 01-1-1z" />
                <path fill-rule="evenodd" d="M2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8zm6-4a4 4 0 100 8 4 4 0 000-8z" clip-rule="evenodd" />
              </svg>
              Preview
            </a>
            <a v-if="launchable" href="" @click.prevent="requestLaunchAttempt" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <!-- Heroicon name: user-add -->
              <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
              </svg>
              Launch
            </a>
            <a v-if="isAdmin && purchaseable.approvalStatus === 'seller_approved'" href="" @click.prevent="adminApprove" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <!-- Heroicon name: paper-airplane -->
              <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
              </svg>
              Approve
            </a>
            <a v-if="purchaseable.authored || license" href="" @click.prevent="togglePin" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <!-- Heroicon name: star -->
              <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
              {{ purchaseable.pinned ? "Unpin" : "Pin" }}
            </a>
          </div>
          <div class="border-t border-gray-100"></div>
          <div v-if="purchaseable.authored || cancellable" class="py-1">
            <a v-if="purchaseable.authored" href="" @click.prevent="deletePurchaseable" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <!-- Heroicon name: trash -->
              <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
              Delete
            </a>
            <a v-if="cancellable" href="" @click.prevent="cancelSubscription" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <!-- Heroicon name: x -->
              <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
              Unsubscribe
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PaymentConnectedAccountGateway from "../payment-connected-account-gateway";
import PurchaseableCard from "../cards/purchaseable";
import PurchaseableDetailsForm from "./purchaseable-details-form";
import Swal from "sweetalert2";
import api from "../../api";
import cancelSubscriptionOptions from "../../sweet-alert-cancel-subscription-options";
import deleteItemOptions from "../../sweet-alert-delete-item-options";

export default {
  props: ["purchaseable", "license"],
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState(["modal", "temporaryAccess"]),
    launchable() {
      return (
        this.purchaseable.complete &&
        this.purchaseable.approvalStatus !== "seller_approved" &&
        this.purchaseable.approvalStatus !== "approved"
      );
    },
    cancellable() {
      return (
        this.license &&
        this.license.frequency === "monthly" &&
        (!this.license.revokedAt ||
          !_.includes(["pending", "canceled"], this.license.cancellationState))
      );
    }
  },
  watch: {
    temporaryAccess: {
      handler() {
        if (_.get(this.temporaryAccess, "afterCloseMethodName", null) === "requestLaunch" && _.get(this.temporaryAccess, "purchaseableId", null) === this.purchaseable.id) {
          _.invoke(this, this.temporaryAccess.afterCloseMethodName);
        }
      }
    }
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.purchaseableMenu &&
        !this.$refs.purchaseableMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    editPurchaseable() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      this.$store.commit("openModal", {
        component: PurchaseableDetailsForm,
        props: {
          purchaseable: this.purchaseable
        },
        afterClose: currentModal
      });
      this.menuOpen = false;
    },
    previewPurchaseable() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      this.$store.commit("openModal", {
        component: PurchaseableCard,
        props: {
          purchaseableId: this.purchaseable.id,
          backgroundColor: "indigo-600",
          isPreview: true
        },
        afterClose: currentModal
      });
      this.menuOpen = false;
    },
    deletePurchaseable() {
      Swal.fire(deleteItemOptions).then(result => {
        if (result.value) {
          api.delete(`purchaseables/${this.purchaseable.id}`).then(json => {
            this.$store.dispatch("flash", "Premium content deleted");
            this.$store.commit("setRefetchPurchaseables", true);
          });
        }
      });
      this.menuOpen = false;
    },
    requestLaunchAttempt() {
      this.$store.commit("openModal", {
        component: PaymentConnectedAccountGateway,
        props: {
          value: {
            component: null,
            props: {
              returnUrl: `https://towerhunt.com/home/premium`,
              closeOnSuccess: true,
              afterClosePayload: {
                afterCloseMethodName: `requestLaunch`,
                purchaseableId: this.purchaseable.id
              }
            }
          }
        }
      });
    },
    requestLaunch() {
      api.post(`purchaseables/${this.purchaseable.id}/launch`).then(json => {
        this.$store.commit("clearTemporaryAccess");
        this.$store.dispatch("flash", "Launch requested!");
        this.purchaseable.approvalStatus = "seller_approved";
      });
      this.menuOpen = false;
    },
    adminApprove() {
      api.post(`purchaseables/${this.purchaseable.id}/approve`).then(json => {
        this.$store.dispatch("flash", "Approved and launched!");
        this.purchaseable.approvalStatus = "approved";
        this.purchaseable.state = "live";
      });
      this.menuOpen = false;
    },
    togglePin() {
      if (this.purchaseable.pinned) {
        api.delete(`purchaseables/${this.purchaseable.id}/pin`).then(json => {
          this.$store.dispatch("flash", "Content unpinned");
          this.purchaseable.pinned = false;
          this.$store.commit("setRefetchPurchaseables", true);
        });
      } else {
        api.post(`purchaseables/${this.purchaseable.id}/pin`).then(json => {
          this.$store.dispatch("flash", "Content pinned");
          this.purchaseable.pinned = true;
          this.$store.commit("setRefetchPurchaseables", true);
        });
      }
      this.menuOpen = false;
    },
    cancelSubscription() {
      Swal.fire(cancelSubscriptionOptions).then(result => {
        if (result.value) {
          api
            .delete(`purchaseable_subscriptions/${this.purchaseable.id}`)
            .then(json => {
              this.$store.dispatch("flash", "Subscription cancelled. You may access until the end of the current billing period.");
              this.$store.commit("setRefetchPurchaseables", true);
              this.license.cancellationState = "pending";
            });
        }
      });
      this.menuOpen = false;
    }
  }
};
</script>
