<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
  -->
  <div class="bg-white">
    <product-marketing-top-bar />

    <div class="bg-gradient-to-b from-teal-50 via-white to-white">
      <main class="lg:relative">
        <div class="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
          <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
              Intel you crave.
              <br class="xl:hidden">
              <span class="text-teal-600">Sources you can trust.</span>
            </h2>
            <p class="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              A marketplace for hard-to-find intel from local experts.
            </p>
            <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div class="rounded-md shadow">
                <router-link to="/market/featured" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:ring-teal md:py-4 md:text-lg md:px-10">
                  Browse now
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <img class="absolute inset-0 w-full h-full object-contain" src="https://assets.towerhunt.com/site/premium_previews_2x3_0.jpg" alt="Premium insights">
        </div>
      </main>

      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 class="text-base leading-6 font-semibold text-teal-600 uppercase tracking-wide">Always relevant</h2>
          <p class="mt-2 text-3xl leading-9 font-extrabold text-gray-900">Integrated marketplace</p>
          <p class="mt-4 text-lg leading-7 text-gray-500">Designed to fit right into the core Tower Hunt network that's already personalized to your markets, our premium insights let you instantly dig deeper into the topics you care about.</p>
        </div>
        <div class="mt-12 lg:mt-0 lg:col-span-2">
          <dl class="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            <div class="flex space-x-3">
              <!-- Heroicon name: check -->
              <svg class="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
              <div class="space-y-2">
                <dt class="text-lg leading-6 font-medium text-gray-900">Featured collections</dt>
                <dd class="flex space-x-3 lg:py-0 lg:pb-4">
                  <span class="text-base leading-6 text-gray-500">Organized around key market trends and deals.</span>
                </dd>
              </div>
            </div>
            <div class="flex space-x-3">
              <!-- Heroicon name: check -->
              <svg class="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
              <div class="space-y-2">
                <dt class="text-lg leading-6 font-medium text-gray-900">Personalized</dt>
                <dd class="flex space-x-3">
                  <span class="text-base leading-6 text-gray-500">Tailored to the deals and intel in your newsfeed.</span>
                </dd>
              </div>
            </div>
            <div class="flex space-x-3">
              <!-- Heroicon name: check -->
              <svg class="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
              <div class="space-y-2">
                <dt class="text-lg leading-6 font-medium text-gray-900">Trust</dt>
                <dd class="flex space-x-3">
                  <span class="text-base leading-6 text-gray-500">Every submission is reviewed and approved.</span>
                </dd>
              </div>
            </div>
            <div class="flex space-x-3">
              <!-- Heroicon name: check -->
              <svg class="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
              <div class="space-y-2">
                <dt class="text-lg leading-6 font-medium text-gray-900">Customer Support</dt>
                <dd class="flex space-x-3">
                  <span class="text-base leading-6 text-gray-500">Issue? Refund? We're here for you.</span>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>

    <!-- Logo cloud -->
    <div class="bg-teal-200 bg-opacity-25">
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="lg:grid lg:grid-cols-2 lg:gap-8 items-center">
          <h2 class="max-w-md mx-auto text-3xl leading-9 font-extrabold text-teal-900 text-center lg:max-w-xl lg:text-left">
            Join local market experts from
          </h2>
          <div class="mt-8 flow-root lg:mt-0 self-center">
            <div class="-mt-4 -ml-8 flex flex-wrap justify-around">
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/Bulfinch_teal0.png" alt="Bulfinch">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/CBRE_teal0.png" alt="CBRE">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/CushmanWakefield_teal0.png" alt="Cushman & Wakefield">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/DavisCompanies_teal0.png" alt="The Davis Companies">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/EastdilSecured_teal0.png" alt="Eastdil Secured">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/JLL_teal0.png" alt="JLL">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/newmark_teal0.png" alt="Newmark">
              </div>
              <div class="mt-8 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0">
                <img class="h-8" src="https://assets.towerhunt.com/site/Nordblom_teal0.png" alt="Nordblom">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Branded FAQ -->
    <div class="bg-teal-900">
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 class="text-3xl leading-9 font-extrabold text-white">
          Frequently asked questions
        </h2>
        <div class="mt-6 border-t border-teal-400 border-opacity-25 pt-10">
          <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
            <text-accordian primary-color="white" secondary-color="teal-200">
              <template v-slot:primary>
                What is this?
              </template>
              <template v-slot:secondary>
                Tower Hunt's marketplace lets you discover and acquire the hardest-to-find intel, which we call premium insights.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="teal-200">
              <template v-slot:primary>
                What does it cost?
              </template>
              <template v-slot:secondary>
                Tower Hunt is free to use. You only pay for information you want to purchase based on the quoted prices.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="teal-200">
              <template v-slot:primary>
                How are you different from other paid data sources?
              </template>
              <template v-slot:secondary>
                We offer on-demand access to premium insights with no long-term commitment. Sellers own their content and earn revenue whenever you buy it.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="teal-200">
              <template v-slot:primary>
                What kinds of information will I find here?
              </template>
              <template v-slot:secondary>
                We prefer small units of specific, actionable data over long articles and reports. For examples of our content, browse the <router-link to="/market/featured" class="underline">Featured page</router-link>.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="teal-200">
              <template v-slot:primary>
                Who provides the info?
              </template>
              <template v-slot:secondary>
                Principals, brokers, and other professionals who are have proven expertise in their local markets.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="teal-200">
              <template v-slot:primary>
                Why should I trust you?
              </template>
              <template v-slot:secondary>
                We're former deal junkies ourselves and hold all submissions to our standards. Everything you see has been reviewed and approved.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="teal-200">
              <template v-slot:primary>
                I have intel I'd like to monetize. How do I sell it on Tower Hunt?
              </template>
              <template v-slot:secondary>
                Please visit <router-link to="/product/sell-premium-content" class="underline">our page about selling on Tower Hunt</router-link> and consider joining us!
              </template>
            </text-accordian>
          </dl>
        </div>
      </div>
    </div>

    <!-- CTA section -->
    <div class="bg-teal-50">
      <div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-teal-900 sm:text-4xl sm:leading-10">
          <span class="block">Ready to dig in?</span>
          <span v-if="!signedIn" class="block text-teal-600">Create a profile today.</span>
        </h2>
        <div class="mt-8 flex lg:flex-shrink-0 lg:mt-0">
          <div class="inline-flex rounded-md shadow">
            <router-link :to="ctaPath" class="bg-teal-600 border border-transparent rounded-md py-3 px-5 inline-flex items-center justify-center text-base leading-6 font-medium text-white hover:bg-teal-500 focus:outline-none focus:ring">
              Get started for free
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Simple footer -->
    <marketing-footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MarketingFooter from "../components/marketing-footer";
import ProductMarketingTopBar from "../components/product-marketing-top-bar";
import TextAccordian from "../components/text-accordian.vue";

export default {
  components: { MarketingFooter, ProductMarketingTopBar, TextAccordian },
  computed: {
    ...mapGetters(["signedIn"]),
    ctaPath() {
      if (this.signedIn) {
        return "/market/featured";
      } else {
        return "/start/1";
      }
    }
  },
  mounted() {
    document.title = "Premium insights: Intel you crave. Sources you can trust. | Tower Hunt";
  }
};
</script>
