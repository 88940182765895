<template>
  <div class="relative text-center bg-white flex flex-col flex-1">
    <svg v-if="card.userReaction === 'starred'" @click="unstarCard(card)" v-tooltip="'Click to unstar this card'" class="h-6 w-auto sm:h-8 absolute top-2 right-2 text-blue-400 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
    <svg v-else @click="starCard(card)" v-tooltip="'Click to star this card'" class="h-6 w-auto sm:h-8 absolute top-2 right-2 text-gray-300 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
    </svg>
    <off-market-card :auto-card-size="true" :card="card.content" />
    <card-cap-rate-form v-if="(!card.priced || isAdmin) && !card.expired" :card="card" @priced="cardPriced" />
    <owner-motivation v-if="card.priced || card.expired" :card="card" @flip="flip" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardCapRateForm from "./card-cap-rate-form";
import OffMarketCard from "../stacked/off-market-teaser-card";
import OwnerMotivation from "./owner-motivation-and-messaging";
import api from "../../../api";

export default {
  components: { CardCapRateForm, OffMarketCard, OwnerMotivation },
  props: ["card"],
  computed: {
    ...mapGetters(["isAdmin"]),
  },
  methods: {
    cardPriced() {
      this.card.priced = true;
    },
    starCard() {
      api
        .patch(`star_pack_card/${this.card.packId}/${this.card.id}`)
        .then(json => {
          this.card.userReaction = "starred";
        });
    },
    unstarCard() {
      api
        .delete(`star_pack_card/${this.card.packId}/${this.card.id}`)
        .then(json => {
          this.card.userReaction = "accepted";
        });
    },
    flip() {
      this.$emit("flip");
    }
  }
};
</script>
