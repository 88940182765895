<template>
  <div class="horizontal w-24 sm:w-40">
    <div class="icon-wrap">
      <i :class="iconClass"></i>
    </div>
    <section>
      <strong>{{ label }}</strong>

      <div v-if="isMultiSelect" class="square" :class="{ selected: selected && !privacyMode, 'privacy-selected': selected && privacyMode }">
        <i v-if="selected" class="fas fa-check selected-check"></i>
      </div>
      <div v-else class="circle">
        <div v-if="selected" class="selected-circle" :class="privacyMode ? 'pink' : 'purple'"></div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["iconClass", "label", "isMultiSelect", "selected"],
  computed: {
    ...mapGetters(["privacyMode"])
  }
}
</script>

<style lang="scss" scoped>
.horizontal {
  display: flex;
  align-items: center;
  flex-direction: column;
  .icon-wrap {
    display: flex;
    height: 90px;
    justify-content: center;
    align-items: center;
    i {
      font-size: 40px;
      &.privacy-active {
        color: #d61f69;
      }
      &.active {
        color: #5850ec;
      }
    }
  }
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    strong {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0 0 20px 0;
    }
    .circle {
      border: 1px solid #b7b7b7;
      border-radius: 50px;
      background: #fff;
      width: 30px;
      height: 30px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      .selected-circle {
        border: none;
        border-radius: 50px;
        width: 20px;
        height: 20px;
        &.pink {
          background-color: #d61f69;
        }
        &.purple {
          background-color: #5850ec;
        }
      }
    }
    .square {
      border: 1px solid #b7b7b7;
      border-radius: 5px;
      background: #fff;
      width: 30px;
      height: 30px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.privacy-selected {
        background-color: #d61f69;
      }
      &.selected {
        background-color: #5850ec;
      }
      .selected-check {
        font-size: 20px;
        color: #fff;
      }
    }
  }
}
</style>
