<template>
  <div>
    <div class="relative pb-5 border-b border-gray-200 space-y-4 sm:pb-0">
      <div class="space-y-3 md:flex md:items-center md:justify-between md:space-y-0">
        <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
          <h3 class="ml-2 mt-2 text-lg leading-6 font-medium text-gray-900">
            Bounties
          </h3>
        </div>
        <div class="flex items-center space-x-3 md:absolute md:top-3 md:right-0">
          <button @click="requestHelp" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium shadow-sm rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
            <svg class="-ml-1 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
            </svg>
            Create bounty
          </button>
        </div>
      </div>
      <div>
        <!-- Dropdown menu on small screens -->
        <div class="sm:hidden">
          <select v-model="selectValue" @change="navigate" aria-label="Selected tab" class="block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm sm:leading-5">
            <option value="active">Active</option>
            <option value="completed">Completed</option>
            <option value="my-engagements">My Engagements</option>
            <option value="my-bounties">My Bounties</option>
          </select>
        </div>
        <!-- Tabs at small breakpoint and up -->
        <div class="hidden sm:block">
          <nav class="-mb-px flex space-x-8">
            <router-link
              to="active"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Active
              </a>
            </router-link>

            <router-link
              to="completed"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Completed
              </a>
            </router-link>

            <router-link
              to="my-engagements"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                My Engagements
              </a>
            </router-link>

            <router-link
              to="my-bounties"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                My Bounties
              </a>
            </router-link>
          </nav>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import BountyRequest from "../components/bounties/bounty-request";

export default {
  data() {
    return {
      selectValue: null
    };
  },
  mounted() {
    document.title = "Bounties | Tower Hunt";

    switch (this.$store.state.route.path) {
      case "/bounties/completed":
        this.selectValue = "completed";
        break;
      case "/bounties/my-engagements":
        this.selectValue = "my-engagements";
        break;
      case "/bounties/my-bounties":
        this.selectValue = "my-bounties";
        break;
      default:
        if (this.$store.state.route.path !== `/bounties/active`) {
          this.$router.push({ path: "/bounties/active" });
        }
        this.selectValue = "active";
    }
  },
  methods: {
    navigate() {
      if (this.$store.state.route.path !== `/bounties/${this.selectValue}`) {
        this.$router.push({ path: this.selectValue });
      }
    },
    requestHelp() {
      this.$store.commit("openModal", {
        component: BountyRequest,
        props: {}
      });
    }
  }
};
</script>
