<template>
  <div ref="modal" style="z-index: 10000;">
    <transition name="main">
      <div
        v-show="value"
        class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center"
        style="z-index: 10000;"
      >
        <transition name="backdrop">
          <div @click="close" v-show="value" class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-800 opacity-75"></div>
          </div>
        </transition>
        <transition name="modal">
          <div
            v-show="value"
            id="xl-modal"
            :class="`bg-${backgroundColor} rounded-lg overflow-auto shadow-xl transform transition-all h-96 sm:h-80 md:h-full md:max-h-full sm:max-w-screen-xl sm:w-full`"
          >
            <div class="hidden md:block absolute top-0 right-0 pt-4 pr-4 z-10">
              <button @click="close" type="button" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500">
                <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                </svg>
              </button>
            </div>
            <div>
              <div class="mt-3 md:mt-5">
                <slot></slot>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  computed: {
    backgroundColor() {
      return _.get(this.value, "lightMode", false) ? "white" : "gray-900";
    }
  },
  methods: {
    close() {
      this.$store.commit("closeXLModal");
    }
  }
};
</script>

<style scoped>
.modal-enter-active {
  @apply ease-out duration-300;
}

.backdrop-enter-active {
  @apply ease-out duration-300;
}

.modal-leave-active {
  @apply ease-in duration-200;
}

.main-leave-active {
  @apply duration-300;
}

.backdrop-leave-active {
  @apply ease-in duration-200;
}

.modal-enter {
  @apply opacity-0 translate-y-4;

  @screen sm {
    @apply translate-y-0 scale-95;
  }
}

.modal-enter-to {
  @apply opacity-100 translate-y-0;

  @screen sm {
    @apply scale-100;
  }
}

.modal-leave {
  @apply opacity-100 translate-y-0;

  @screen sm {
    @applyscale-100;
  }
}
.modal-leave-to {
  @apply opacity-0 translate-y-4;

  @screen sm {
    @apply translate-y-0 scale-95;
  }
}

.backdrop-enter {
  @apply opacity-0;
}

.backdrop-enter-to {
  @apply opacity-100;
}

.backdrop-leave {
  @apply opacity-100;
}

.backdrop-leave-to {
  @apply opacity-0;
}
</style>
