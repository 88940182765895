<template>
  <div v-if="backchannel" :class="propertyFollowing ? 'bg-white' : 'h-16 bg-gray-100 shadow'" class="py-2 px-4 flex items-center" style="z-index: 2;">
    <div class="w-full flex justify-between items-center flex-nowrap">
      <div v-if="!backchannel.teamName && !backchannel.propertyFollowingName && backchannel.members.length === 1" class="flex items-center truncate">
        <router-link :to="`/contacts/${this.counterpart.token}`" class="flex-shrink-0 cursor-pointer">
          <avatar-photo v-tooltip="authorTooltip" :person="counterpart" circle-size="10" text-size="lg" />
        </router-link>
        <router-link :to="`/contacts/${this.counterpart.token}`" class="ml-4">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ counterpart.name }}
          </h3>
          <p class="text-sm leading-5 text-gray-500">
            <span class="truncate">
              {{ counterpart.industryRole | industryRoleAlias(counterpart.industrySubRole, counterpart.token) | capitalize }}<template v-if="counterpart.company"> ({{ counterpart.company }})</template>
            </span>
          </p>
        </router-link>
      </div>
      <div v-else class="flex items-center">
        <router-link v-if="backchannel.propertyFollowingName" to="/home/watchlist">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Property Watchlist Private Chat
          </h3>
          <p class="text-sm leading-5 text-gray-500">
            <span v-tooltip="membersTooltip" class="truncate">
              {{ memberCount }}
            </span>
          </p>
        </router-link>
        <router-link v-else-if="backchannel.teamName" to="/home/network/teams">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ backchannel | channelName | truncate(50) }}
          </h3>
          <p class="text-sm leading-5 text-gray-500">
            <span v-tooltip="membersTooltip" class="truncate">
              {{ memberCount }}
            </span>
          </p>
        </router-link>
        <h3 v-else class="text-lg leading-6 font-medium text-gray-900">
          {{ backchannel | channelName | truncate(50) }}
        </h3>
      </div>
      <div class="ml-4 flex-shrink-0 flex items-center">
        <instant-messaging-consent content-type="backchannel" :content-token="backchannel.token" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AvatarPhoto from "../avatar-photo";
import InstantMessagingConsent from "../notification-consents/instant-messaging";
import pluralize from "pluralize";

export default {
  components: { AvatarPhoto, InstantMessagingConsent },
  props: ["propertyFollowing"],
  computed: {
    ...mapState(["currentUser"]),
    ...mapGetters(["backchannels", "backchannelTokenParam"]),
    backchannel() {
      return (
        _.find(this.backchannels, { token: this.backchannelTokenParam }) ||
        _.find(this.currentUser.backchannels, {
          token: _.get(this.propertyFollowing, "backchannelToken", null)
        })
      );
    },
    counterpart() {
      return this.backchannel.members[0];
    },
    authorTooltip() {
      const name = this.$options.filters.capitalize(this.counterpart.name);
      const role = _.capitalize(this.$options.filters.industryRoleAlias(this.counterpart.industryRole, this.counterpart.industrySubRole, this.counterpart.token));

      if (this.counterpart.company) {
        return `${name} (${role} at ${this.counterpart.company})`;
      } else {
        return `${name} (${role})`;
      }
    },
    memberCount() {
      return pluralize("Member", this.backchannel.members.length, true);
    },
    membersTooltip() {
      const fullTeam = this.backchannel.members;

      if (!_.find(fullTeam, { token: this.currentUser.token })) {
        fullTeam.unshift(this.currentUser);
      }

      return fullTeam.map(user => this.userTooltip(user)).join(", ");
    }
  },
  methods: {
    userTooltip(user) {
      const name = this.$options.filters.capitalize(user.name);
      const role = _.capitalize(
        this.$options.filters.industryRoleAlias(
          user.industryRole,
          user.industrySubRole,
          user.token
        )
      );

      if (user.company) {
        return `${name} (${role} at ${user.company})`;
      } else {
        return `${name} (${role})`;
      }
    }
  }
};
</script>
