<template>
  <div class="space-y-3 pb-5">
    <div class="rounded-md bg-yellow-50 p-2">
      <div class="flex">
        <div class="flex-shrink-0">
          <!-- Heroicon name: information-circle -->
          <svg class="h-4 w-4 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-2 flex-1 md:flex md:justify-between">
          <p class="text-xs leading-4 text-yellow-700">
            Visible to anyone who can see this post.
          </p>
        </div>
      </div>
    </div>
    <div v-if="content.commentable" class="space-y-1 flex flex-col">
      <label for="comment" class="block text-sm font-medium leading-5 text-gray-700">Add a comment or bounty</label>
      <div class="mt-1 bg-white rounded-md shadow-sm -space-y-px">
        <resizable-textarea>
          <textarea v-model="comment" id="comment" aria-label="Comment" rows="1" type="text" class="resize-none text-xs relative block w-full rounded-none rounded-t-md border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 bg-transparent focus:z-10 sm:text-sm sm:leading-5" placeholder="Comment or bounty. Please be friendly and helpful." />
        </resizable-textarea>
        <div class="flex -space-x-px">
          <div class="w-1/2 flex-1 min-w-0">
            <button
              @click="confirmPostPrivacy"
              type="button"
              :class="complete ? completeColor : 'border border-gray-300 text-gray-700 bg-white hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700'"
              class="relative inline-flex justify-center items-center w-full px-4 py-2 rounded-none rounded-bl-md text-sm leading-5 font-medium focus:z-10"
              v-tooltip="'We\'ll ask whether you want to be anonymous before the comment is published.'"
            >
              <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
                <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
              </svg>
              Comment
            </button>
          </div>
          <div class="flex-1 min-w-0">
            <button
              @click="createBounty"
              type="button"
              :class="complete ? completeColor : 'border border-gray-300 text-gray-700 bg-white hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700'"
              class="relative inline-flex justify-center items-center w-full px-4 py-2 rounded-none rounded-br-md text-sm leading-5 font-medium focus:z-10"
              v-tooltip="'Get help from local experts. Pay upon fulfillment.'"
            >
              Bounty
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="space-y-1 flex justify-end">
      <instant-messaging-consent :content-type="content.newsfeedType" :content-token="content.token" />
    </div>
    <div class="space-y-1">
      <div v-for="record in combinedByDate" :key="record.id" class="py-3 flex">
        <attached-bounty v-if="record.commentsType === 'bounty'" :bounty="record" @deleted="bountyDeleted" />
        <comment v-else-if="record.commentsType === 'comment'" :comment="record" background-color="gray-100" :thankable="true" :respondable="content.commentable" @deleted="deleted" />
      </div>
    </div>
    <div v-if="loaded && combinedByDate.length === 0" class="text-sm leading-5 text-gray-500">
      <p>
        Be the first to comment on this
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AttachedBounty from "./bounties/attached-bounty";
import BountyRequest from "./bounties/bounty-request";
import Comment from "./cards/comment";
import InstantMessagingConsent from "./notification-consents/instant-messaging";
import ResizableTextarea from "./resizable-textarea";
import Swal from "sweetalert2";
import anonymousPostOptions from "../sweet-alert-always-anonymous-options";
import namedPostOptions from "../sweet-alert-named-post-options";

export default {
  components: {
    AttachedBounty,
    Comment,
    InstantMessagingConsent,
    ResizableTextarea
  },
  props: ["content"],
  data() {
    return {
      comment: "",
      comments: [],
      bounties: [],
      loaded: false
    };
  },
  computed: {
    ...mapGetters(["privacyMode"]),
    ...mapState(["adminMode", "modal"]),
    complete() {
      return this.comment !== "" && _.trim(this.comment) !== "";
    },
    completeColor() {
      if (this.privacyMode) {
        return "border-transparent text-white bg-pink-600 hover:bg-pink-500 focus:outline-none focus:ring-pink focus:border-pink-700 active:bg-pink-700";
      } else {
        return "border-transparent text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700";
      }
    },
    combinedByDate() {
      return _.orderBy(
        _.union(this.comments, this.bounties),
        "createdAt",
        "desc"
      );
    }
  },
  mounted() {
    if (this.content.commentable) {
      this.fetchComments();
      this.fetchBounties();
    } else {
      this.$store.commit("closeSlideover");
    }
  },
  methods: {
    fetchComments() {
      this.$store
        .dispatch("loadComments", {
          contentType: this.content.newsfeedType,
          contentToken: this.content.token
        })
        .then(json => {
          this.comments = json.data.comments;
          this.loaded = true;
        });
    },
    fetchBounties() {
      this.$store
        .dispatch("loadAttachedBounties", {
          contentType: this.content.newsfeedType,
          contentToken: this.content.token
        })
        .then(json => {
          this.bounties = json.data.bounties;
          this.loaded = true;
        });
    },
    createBounty() {
      if (this.complete && this.content.commentable) {
        let currentModal = false;

        if (this.modal) {
          currentModal = _.cloneDeep(this.modal); // obtains the current component
        }

        this.$store.commit("openModal", {
          component: BountyRequest,
          props: { postContent: this.content, prefill: this.comment },
          afterClose: currentModal
        });
        this.$store.commit("closeSlideover");
      }
    },
    confirmPostPrivacy() {
      if (this.complete && this.content.commentable) {
        if (this.privacyMode) {
          Swal.fire(anonymousPostOptions).then(result => {
            if (result.value) {
              this.postComment(true);
            }
          });
        } else {
          Swal.fire(namedPostOptions).then(result => {
            if (result.value) {
              this.postComment(true);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.postComment(false);
            }
          });
        }
      }
    },
    postComment(anonymous = true) {
      if (this.content.commentable) {
        this.$store
          .dispatch("createComment", {
            contentType: this.content.newsfeedType,
            contentToken: this.content.token,
            comment: this.comment,
            response: false,
            anonymous
          })
          .then(json => {
            this.comments.unshift(json.data.comment);
            this.comment = "";

            if (!!analytics && !this.adminMode) {
              analytics.track("Comment posted", {
                contentType: this.content.newsfeedType,
                yearWeek: this.$store.state.yearWeek
              });
            }
          });
      }
    },
    deleted(commentId) {
      this.comments = this.comments.filter(c => c.id !== commentId);
    },
    bountyDeleted(bountyId) {
      this.bounties = this.bounties.filter(b => b.id !== bountyId);
    }
  }
};
</script>
