<template>
  <div class="mt-8">
    <div v-if="data.length > 0" class="bg-white overflow-hidden shadow sm:rounded-lg">
      <div class="flex flex-col">
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div class="align-middle inline-block min-w-full overflow-hidden border-b border-gray-200">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Person
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Company
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Message
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Quick Action
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <message-list-row
                  v-for="(message, index) in data"
                  :key="index"
                  :index="index"
                  :message="message"
                  :post="post"
                  :last-index="data.length - 1"
                  @refetch="fetchMessages"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="pagy && pagy.count > 7" class="bg-white px-4 py-3 flex items-center justify-between sm:px-6">
        <div class="hidden sm:block">
          <p class="text-sm leading-5 text-gray-700">
            Showing
            <span class="font-medium">{{ pagy.from }}</span>
            to
            <span class="font-medium">{{ pagy.to }}</span>
            of
            <span class="font-medium">{{ pagy.count }}</span>
            messages
          </p>
        </div>
        <div class="flex-1 flex justify-between sm:justify-end">
          <router-link @click.native="fetchMessages(pagy.prev_url)" to="" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
            Previous
          </router-link>
          <router-link @click.native="fetchMessages(pagy.next_url)" to="" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
            Next
          </router-link>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="text-center">
          <h1 class="text-base leading-6 font-semibold text-indigo-600 tracking-wide uppercase">Message list</h1>
          <p class="mt-1 text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">People who like your asset</p>
          <p class="max-w-xl mt-5 mx-auto text-xl leading-7 text-gray-500">Once someone sends you a message about this asset, it will display here.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MessageListRow from "./message-list-row";
import api from "../../../api";

export default {
  components: { MessageListRow },
  props: ["post"],
  data() {
    return {
      data: [],
      pagy: null
    };
  },
  computed: {
    ...mapState(["temporaryAccess"])
  },
  watch: {
    temporaryAccess: {
      handler() {
        if (_.get(this.temporaryAccess, "action", false)) {
          let message = _.find(this.data, {
            id: this.temporaryAccess.messageId
          });

          message.offMarketOwnerReplied = true;

          this.$store.commit("clearTemporaryAccess");
        }
      }
    }
  },
  mounted() {
    this.fetchMessages();
  },
  methods: {
    fetchMessages(url = null) {
      const cleanUrl = url ? _.drop(url, 8).join("") : url;
      let endpoint = this.endpointFor(cleanUrl);

      if (endpoint) {
        api.get(endpoint).then(json => {
          const messageList = json.data;

          this.data = messageList.data;
          this.pagy = messageList.pagy;
        });
      }
    },
    endpointFor(url = null) {
      if (url) {
        return url;
      } else {
        return `off_market_property_owner_messages/${this.post.token}`;
      }
    },
  }
};
</script>
