<template>
  <div class="py-2 px-4 flex items-center min-h-16 bg-gray-100 shadow" style="z-index: 2;">
    <div class="w-full flex flex-nowrap">
      <h3 class="mt-2 leading-6 font-medium text-gray-900">
        To:
      </h3>
      <div class="flex flex-1 flex-wrap items-center">
        <ul v-if="privateAudience.length > 0 || (team.id && team.name) || (propertyFollowing.id && propertyFollowing.name)" class="mx-2 space-x-1 space-y-2">
          <li v-if="propertyFollowing.id && propertyFollowing.name" class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700">
            {{ propertyFollowing.name }}
            <button @click="removeProperty" type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white">
              <span class="sr-only">Remove property</span>
              <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
              </svg>
            </button>
          </li>
          <li v-else-if="team.id && team.name" class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700">
            {{ team.name }}
            <button @click="removeTeam" type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white">
              <span class="sr-only">Remove team</span>
              <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
              </svg>
            </button>
          </li>
          <template v-else>
            <li v-for="contact in privateAudience" :key="contact.token" class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700">
              {{ contact.name }}
              <button @click="remove(contact)" type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white">
                <span class="sr-only">Remove person</span>
                <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                  <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                </svg>
              </button>
            </li>
          </template>
        </ul>
        <div v-if="!team.id && !team.name && !propertyFollowing.id && !propertyFollowing.name" class="relative flex-grow focus-within:z-10">
          <autocomplete
            id="search"
            ref="contactSearch"
            type="text"
            base-class="custom-autocomplete-flush"
            :search="searchChannels"
            :auto-select="true"
            :get-result-value="getChannelResultValue"
            :debounce-time="500"
            @submit="handleChannelSubmit"
            @keydown="handleKeyDown"
            @blur="handleBlur"
            :placeholder="searchPlaceholder"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import api from "../../api";

export default {
  components: {},
  computed: {
    ...mapState(["newBackchannel", "route", "emailRegex"]),
    ...mapGetters([]),
    queryContentType() {
      return _.get(this.route, "query.type", null);
    },
    propertyFollowing() {
      return _.get(this.newBackchannel, "propertyFollowing", {
        id: null,
        name: null
      });
    },
    team() {
      return _.get(this.newBackchannel, "team", { id: null, name: null });
    },
    privateAudience() {
      return _.get(this.newBackchannel, "privateAudience", []);
    },
    searchPlaceholder() {
      if (this.privateAudience.length > 0) {
        return "somebody, or somebody@example.com";
      } else if (this.queryContentType === "property") {
        return "team, somebody, or somebody@example.com";
      } else {
        return "team, watchlist property, somebody, or somebody@example.com";
      }
    }
  },
  mounted() {
    var self = this;

    setTimeout(() => {
      if (
        !this.propertyFollowing.id &&
        !this.propertyFollowing.name &&
        !this.team.id &&
        !this.team.name &&
        self.privateAudience.length === 0
      ) {
        document.getElementById("search").focus();
      }
    }, 50);

    const contentToken = _.get(this.route, "query.content", null);
    const contentType = _.get(this.route, "query.type", null);

    if (contentToken && contentType) {
      this.$store.commit("setNewBackchannelContent", {
        contentToken,
        contentType
      });
    }
  },
  beforeDestroy() {
    this.$store.commit("clearNewBackchannel");
  },
  methods: {
    searchChannels(input) {
      return new Promise(resolve => {
        if (input.length < 2) {
          return resolve([]);
        }

        const query = input === "" || input === null ? null : _.toLower(input);
        const includeStandaloneChannels = this.privateAudience.length > 0 ? "false" : "true";

        api
          .post(`search_channels?team=${includeStandaloneChannels}&property=${includeStandaloneChannels && this.queryContentType !== "property"}`, {
            name: query
          })
          .then(
            json => {
              resolve(json.data);
            },
            failure => {
              this.$store.dispatch("flash", "Invalid search");
            }
          );
      });
    },
    getChannelResultValue(result) {
      return result.name;
    },
    handleBlur() {
      const validAddition = this.emailRegex.test(
        this.$refs.contactSearch.value
      );

      if (validAddition) {
        this.handleRawEmail();
        setTimeout(() => {
          this.$refs.contactSearch.value = "";
        }, 10);
      } else {
        var self = this;

        setTimeout(() => {
          if (self.$refs.contactSearch) {
            self.$refs.contactSearch.value = "";
          }
        }, 10);
      }
    },
    handleKeyDown(e) {
      const validAddition = this.emailRegex.test(
        this.$refs.contactSearch.value
      );

      if (validAddition && ["Tab", ",", " "].includes(e.key)) {
        this.handleRawEmail();
        var self = this;

        setTimeout(() => {
          self.$refs.contactSearch.value = "";
        }, 10);
      }
    },
    handleChannelSubmit(result) {
      if (result && result.className === "User") {
        this.$store.commit("addContactToNewBackchannel", result);
      } else if (result && result.className === "Team") {
        this.$store.commit("addTeamToNewBackchannel", {
          id: result.id,
          name: result.name
        });
      } else if (result && result.className === "PropertyFollowing") {
        this.$store.commit("addPropertyToNewBackchannel", {
          id: result.id,
          name: result.property.name
        });
      } else {
        this.handleRawEmail();
      }

      this.$refs.contactSearch.value = "";
    },
    handleRawEmail() {
      const output = {
        name: this.$refs.contactSearch.value,
        email: this.$refs.contactSearch.value,
        id: Date.now()
      };
      const validAddition = this.emailRegex.test(
        this.$refs.contactSearch.value
      );

      if (validAddition) {
        this.$store.commit("addContactToNewBackchannel", output);
      } else {
        this.$store.dispatch(
          "flash",
          "Sorry, this doesn't look like a valid team, watchlist property, person in your network, or email address."
        );
      }
    },
    remove(person) {
      this.$store.commit("removeContactFromNewBackchannel", person);
      document.getElementById("search").focus();
    },
    removeProperty() {
      this.$store.commit("removePropertyFromNewBackchannel");

      setTimeout(() => {
        document.getElementById("search").focus();
      }, 50);
    },
    removeTeam() {
      this.$store.commit("removeTeamFromNewBackchannel");

      setTimeout(() => {
        document.getElementById("search").focus();
      }, 50);
    }
  }
};
</script>
