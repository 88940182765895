<template>
  <span v-tooltip="tooltip" class="inline-flex items-center px-2.5 py-0.5 rounded-md font-medium" :class="`bg-${intelTypeBadgeColor}-100 text-${intelTypeBadgeColor}-800 ${textSizing}`">
    <svg class="-ml-0.5 mr-1.5 h-4 w-4" :class="`text-${intelTypeBadgeColor}-400`" fill="currentColor" viewBox="0 0 20 20">
      <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"/>
    </svg>
    <template v-if="nameOverride">{{ post.name | truncate(overrideLength || 10) }}</template>
    <template v-else>{{ post.type | postTypeAlias }}</template>
  </span>
</template>

<script>
export default {
  props: ["post", "nameOverride", "overrideLength", "compact"],
  computed: {
    tooltip() {
      if (this.nameOverride) {
        return this.$options.filters.postTypeAlias(this.post.type);
      } else {
        return "";
      }
    },
    textSizing() {
      return this.compact ? "text-xs leading-4" : "text-sm leading-5";
    },
    intelTypeBadgeColor() {
      switch (this.post.type) {
        case "jobPosting":
        case "helpRequest":
          return "purple";
        case "industryEvent":
          return "red";
        case "developmentNews":
          return "orange";
        case "infrastructureNews":
          return "yellow";
        case "tenantInMarket":
          return "green";
        case "marketCommentary":
          return "teal";
        case "marketReport":
          return "blue";
        case "offMarketPropertySnapshot":
          return "gray";
        default:
          return "indigo";
      }
    }
  }
};
</script>
