export default function iframelyApi(url) {
  const encoded = encodeURIComponent(url);

  return new Promise(resolve => {
    fetch(
      `https://cdn.iframe.ly/api/iframely/?url=${encoded}&key=53d109990def111ff59cd0a55aaaaf77`
    )
      .then(response => response.json())
      .then(data => {
        resolve(data);
      });
  });
}
