<template>
  <div ref="safezoneFileMenu" class="flex-shrink-0 pr-2" style="z-index: 9998;">
    <button @click="toggleMenu" type="button" class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500" id="safezone-file-options-menu-0-button" aria-expanded="false" aria-haspopup="true">
      <span class="sr-only">Open options</span>
      <!-- Heroicon name: solid/dots-vertical -->
      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
      </svg>
    </button>

    <transition
      enter-active-class="duration-100 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-75 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-show="menuOpen" class="mx-3 origin-top-right absolute right-10 top-3 w-24 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="safezone-file-options-menu-0-button" tabindex="-1">
        <div class="py-1" role="none">
          <a @click.prevent="share" href="" class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="safezone-file-options-menu-0-item-2">Share</a>
          <a v-if="file.authored" @click.prevent="remove" href="" class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="safezone-file-options-menu-0-item-1">Remove</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import SafezoneDataSlideover from "../../safezone/safezone-data-slideover.vue";
import api from "../../../api";

export default {
  props: ["file", "content", "contentType"],
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState(["modal"])
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.safezoneFileMenu &&
        !this.$refs.safezoneFileMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    remove() {
      api.delete(`safezone_files/${this.file.localId}`).then(json => {
        this.$store.dispatch("flash", "File unlinked");
        this.$emit("remove");
        this.menuOpen = false;
      });
    },
    share() {
      this.$store.commit("openSlideover", {
        component: SafezoneDataSlideover,
        props: {
          safezoneDataType: "SafezoneFile",
          safezoneData: this.file,
          decoratingContent: this.content,
          decoratingContentType: this.contentType
        }
      });
      this.menuOpen = false;
    }
  }
};
</script>
