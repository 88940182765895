<template>
  <div class="wrapper">
    <heading>
      <template v-if="hasLocation">Is the location correct?</template>
      <template v-else>{{ whereQuestion }}</template>
    </heading>
    <div v-if="hasLocation" class="mb-8 w-11/12 h-64 sm:max-w-xl sm:w-full">
      <intel-map :post="activeIntel" />
    </div>
    <form @submit.prevent autocomplete="off" novalidate class="mb-2">
      <div v-if="!hasLocation" class="input-container">
        <gmap-autocomplete
          v-focus
          :value="activeIntel.location"
          @place_changed="setAddress"
          @focus="focus"
          @blur="blur"
          :options="{fields: ['geometry', 'formatted_address', 'address_components', 'name']}"
          autocomplete="new-password"
          autocorrect="off"
          spellcheck="false"
          placeholder=""
          tabindex="1"
          name="user_location"
          id="gmap-autocomplete"
          class="input-text black"
          :class="{ active: focused && !privacyMode, 'privacy-active': focused && privacyMode }" >
          <template v-slot:input="slotProps">
            <input ref="input" type="text"
              v-on:listeners="slotProps.listeners"
              v-on:attrs="slotProps.attrs" />
          </template>
        </gmap-autocomplete>
        <label class="input-label" :class="{ active: focused && !privacyMode, 'privacy-active': focused && privacyMode }">Address or name</label>
        <label class="email-icon" :class="{ active: focused && !privacyMode, 'privacy-active': focused && privacyMode }">
          <i class="fas fa-map-marker-alt"></i>
        </label>
      </div>

      <template v-if="hasLocation">
        <button @click="clickHandler" type="button" class="cta" :class="privacyMode ? 'privacy-complete' : 'complete'" tabindex="999">
          <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
            <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
          </svg>
          Looks good
        </button>
        <button @click="clearLocation" type="button" class="secondary">
          <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
            <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
          </svg>
          Look up new location
        </button>
      </template>
      <button v-else @click="clickHandler" type="button" class="secondary my-4" tabindex="999">
        <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
          <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
        </svg>
        {{ noLocation }}
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../../components/onboarding/heading";
import IntelMap from "../../../components/maps/market-intel-map";
import Swal from "sweetalert2";
import anonymousPostUpdateOptions from "../../../sweet-alert-always-anonymous-update-options";

export default {
  components: {
    Heading,
    IntelMap
  },
  props: ["postToken"],
  data() {
    return {
      focused: false
    };
  },
  computed: {
    ...mapState(["activeIntel", "intelCreationInProcess"]),
    ...mapGetters(["creatingIntel", "privacyMode"]),
    hasLocation() {
      return this.activeIntel.lat && this.activeIntel.lng;
    },
    whereQuestion() {
      switch (this.activeIntel.type) {
        case "jobPosting":
          return "Where is the job located?";
        case "industryEvent":
          return "Where is the event being hosted?";
        default:
          return "Where is it?";
      }
    },
    noLocation() {
      switch (this.activeIntel.type) {
        case "jobPosting":
          return "Fully remote";
        case "industryEvent":
          return "Online only";
        default:
          return "No location";
      }
    }
  },
  watch: {
    hasLocation: {
      handler() {
        if (!this.hasLocation && document.getElementById("gmap-autocomplete")) {
          document.getElementById("gmap-autocomplete").focus();
        }
      }
    }
  },
  mounted() {
    if (this.creatingIntel && !this.intelCreationInProcess) {
      this.$router.push({ path: "/intel/new/1" });
    }
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    clickHandler() {
      if (this.postToken) {
        this.confirmPrivacy();
      } else {
        this.next();
      }
    },
    confirmPrivacy() {
      if (this.postToken) {
        Swal.fire(anonymousPostUpdateOptions).then(result => {
          if (result.value) {
            this.$store.dispatch("updateIntel", {
              postToken: this.postToken,
              updateTags: true
            });

            this.$store.commit("closeModal");
            this.$store.commit("clearIntel");
          }
        });
      }
    },
    next() {
      switch (this.activeIntel.type) {
        case "jobPosting":
          this.$router.push({ path: "/intel/new/6" });
          break;
        case "industryEvent":
          this.$router.push({ path: "/intel/new/4" });
          break;
      }
    },
    setAddress(addressData) {
      if (this.activeIntel && addressData) {
        const lat = addressData.geometry.location.lat();
        const lng = addressData.geometry.location.lng();
        const location = addressData.formatted_address;

        this.$store.commit("setEventAddress", { lat, lng, location });
      }
    },
    clearLocation() {
      this.$store.commit("clearEventLocation");
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
input {
  vertical-align: middle;
}
.subhead {
  padding: 0 20px;
}
.input-container {
  position: relative;
  margin-bottom: 20px;
}
.location-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 26px 38px 8px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.privacy-active {
    border: 2px solid #d61f69;
  }
  &.active {
    border: 2px solid #5850ec;
  }
}
.input-label {
  position: absolute;
  top: 8px;
  left: 38px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.privacy-active {
    color: #d61f69;
  }
  &.active {
    color: #5850ec;
  }
}
.email-icon {
  position: absolute;
  top: 19px;
  left: 13px;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.privacy-active {
    color: #d61f69;
  }
  &.active {
    color: #5850ec;
  }
}
.clear-action {
  position: absolute;
  top: 19px;
  right: 13px;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  pointer-events: all;
  cursor: pointer;
  color: #b7b7b7;
}
button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
    margin-bottom: 5px;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.privacy-complete {
    background: #d61f69;
    box-shadow: rgba(214, 31, 105, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #f17eb8;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
