<template>
  <div class="space-y-6">
    <!-- Proximity settings-->
    <section aria-labelledby="proximity-settings">
      <div class="bg-white shadow sm:rounded-lg">
        <alert-proximity :following="propertyFollowing" @refetch="refetchFollowing" />
      </div>
    </section>

    <!-- Remove members-->
    <section v-if="propertyFollowing.authored" aria-labelledby="members">
      <div class="bg-white shadow sm:rounded-lg px-4 py-5 sm:p-6 space-y-2">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Remove members
          </h3>
          <div class="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Anyone you remove here will need to be invited again.
            </p>
          </div>
        </div>
        <div class="border-b border-gray-200">
          <ul v-if="membersLoaded" class="divide-y divide-gray-200">
            <li v-for="invitation in invitations" :key="invitation.token" class="relative py-4 flex items-center justify-between">
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 text-white">
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </span>
                </div>
                <div class="ml-3 flex flex-col">
                  <span class="text-sm font-medium text-gray-900">{{ invitation.email }}</span>
                  <span class="text-sm text-gray-500">Pending</span>
                </div>
              </div>
              <team-member-menu :invitation="invitation" @refetch-invitations="fetchInvitations" :property-following="propertyFollowing" />
            </li>
            <li v-for="member in members" :key="member.id" class="relative py-4 flex items-center justify-between">
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  <avatar-photo :person="member" circle-size="10" text-size="md" />
                </div>
                <div class="ml-3 flex flex-col">
                  <span class="text-sm font-medium text-gray-900">{{ member.name }}</span>
                  <span v-if="member.company" class="text-sm text-gray-500">{{ member.company }}</span>
                </div>
              </div>
              <team-member-menu :member="member" @refetch-members="fetchMembers" :property-following="propertyFollowing" />
            </li>
          </ul>
          <div v-else class="flex justify-center">
            <grid-loader :loading="true" size="6px" color="#D4D4D8" />
          </div>
        </div>
      </div>
    </section>

    <!-- Remove property-->
    <section v-if="propertyFollowing.authored" aria-labelledby="unsubscribe">
      <div class="bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Archive from property watchlist
          </h3>
          <div class="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Archiving a watchlist property deactivates this page for you and anyone else who has access. Your safezone data and any discussion will be retained and accessible to you whenever you view the property elsewhere in Tower Hunt. To reactivate this page, add the property to your watchlist again.
            </p>
          </div>
          <div class="mt-5">
            <button @click="remove" type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm">
              Archive
            </button>
          </div>
        </div>
      </div>
    </section>

    <section v-else aria-labelledby="unsubscribe">
      <div class="bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Remove from property watchlist
          </h3>
          <div class="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Removing a watchlist property you were invited to will deactivate this page only for you. To reactivate this page, you'll need to be invited again.
            </p>
          </div>
          <div class="mt-5">
            <button @click="leave" type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm">
              Remove
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AlertProximity from "./watchlist-alert-proximity.vue";
import AvatarPhoto from "../avatar-photo";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import Swal from "sweetalert2";
import TeamMemberMenu from "../team-member-menu";
import api from "../../api";
import archiveItemOptions from "../../sweet-alert-archive-item-options";
import leaveWatchlistOptions from "../../sweet-alert-leave-watchlist-options";

export default {
  components: { AlertProximity, AvatarPhoto, GridLoader, TeamMemberMenu },
  props: ["propertyFollowing", "propertyFollowingId"],
  data() {
    return {
      invitationsLoaded: false,
      membersLoaded: false,
      invitations: [],
      members: []
    };
  },
  computed: {
    ...mapState(["currentUser"])
  },
  mounted() {
    if (this.propertyFollowing.authored) {
      this.fetchInvitations();
      this.fetchMembers();
    }
  },
  methods: {
    fetchInvitations() {
      api
        .get(
          `property_followings/${this.propertyFollowingId}/property_following_invitations`
        )
        .then(json => {
          const invitations = json.data;

          this.invitations = invitations;
          this.invitationsLoaded = true;
        });
    },
    fetchMembers() {
      api
        .get(
          `property_followings/${this.propertyFollowingId}/property_following_memberships`
        )
        .then(json => {
          const members = json.data;

          this.members = members;
          this.membersLoaded = true;
        });
    },
    refetchFollowing() {
      this.$emit("refetch");
    },
    remove() {
      Swal.fire(archiveItemOptions).then(result => {
        if (result.value) {
          this.$store.dispatch(
            "unfollowProperty",
            this.propertyFollowing.property.id
          );
          this.$router.push({ path: "/home/watchlist" });
        }
      });
    },
    leave() {
      Swal.fire(leaveWatchlistOptions).then(result => {
        if (result.value) {
          this.$store.dispatch("removeFollowedPropertyMembership", {
            followingId: this.propertyFollowing.id,
            membershipId: this.propertyFollowing.membershipId
          });
        }
      });
      this.menuOpen = false;
    }
  }
};
</script>
