<template>
  <div v-if="invitation" class="wrapper">
    <template v-if="form === 'jvRole'">
      <h3 class="mt-14 mb-6 mx-4 text-center text-xl sm:text-3xl leading-9 font-bold text-gray-900">
        What <template v-if="closed">was</template><template v-else>is</template> your role on <router-link @click.native="openDeal" to="" class="text-indigo-600 hover:text-indigo-500">{{ invitation.dealName | capitalize }}</router-link>?
      </h3>
      <div class="content-wrap">
        <section class="vertical">
          <option-select
            v-for="option in jvRoles"
            :key="option.name"
            option-type="dealInputDealRole"
            setter-name="setDealInvitationJVRole"
            setter-type="commit"
            :store-record="dealInvitation.jvRoleName"
            :option-record="option"
            layout="vertical"
            :options-count="jvRoles.length" />
        </section>
        <option-description v-if="description" :description="description" />
      </div>
      <button v-if="needsJVSellerBuyer" @click="next" type="button" class="cta" :class="{ complete: true }" tabindex="999">Next</button>
      <button v-else @click="accept" type="button" class="cta" :class="{ complete: complete }" tabindex="999">Join deal</button>
    </template>
    <template v-else-if="form === 'role'">
      <h3 v-if="dealType === 'Recap Equity'" class="mt-14 mb-6 mx-4 text-center text-xl sm:text-3xl leading-9 font-bold text-gray-900">
        Were you leaving or joining?
      </h3>
      <h3 v-else class="mt-14 mb-6 mx-4 text-center text-xl sm:text-3xl leading-9 font-bold text-gray-900">
        What <template v-if="closed">was</template><template v-else>is</template> your role on <router-link @click.native="openDeal" to="" class="text-indigo-600 hover:text-indigo-500">{{ invitation.dealName | capitalize }}</router-link>?
      </h3>
      <div class="content-wrap">
        <section :class="orientation">
          <option-select
            v-for="option in matchingRoles"
            :key="option.name"
            option-type="dealInputDealRole"
            setter-name="setDealInvitationRole"
            setter-type="commit"
            :store-record="dealInvitation.roleName"
            :option-record="option"
            :layout="orientation"
            :options-count="matchingRoles.length" />
        </section>
        <option-description v-if="description" :description="description" />
      </div>
      <button @click="accept" type="button" class="cta" :class="{ complete: complete }" tabindex="999">Join deal</button>
      <button v-if="dealType === 'Recap Equity'" @click="form = 'jvRole'" type="button" class="secondary" tabindex="999">Change JV role</button>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DealCard from "../components/cards/deal";
import OptionDescription from "../components/onboarding/option-description";
import OptionSelect from "../components/onboarding/option-select";
import api from "../api";
import dealTypeRoles from "../deal-type-roles";
import jvRoles from "../jv-roles";

export default {
  components: { OptionDescription, OptionSelect },
  props: ["dealInvitationToken"],
  data() {
    return {
      form: "role",
      invitation: null,
      roles: dealTypeRoles,
      jvRoles: jvRoles
    };
  },
  computed: {
    ...mapState(["dealInvitation"]),
    orientation() {
      return this.matchingRoles.length > 3 ? "vertical" : "horizontal";
    },
    dealType() {
      return _.get(this.invitation, "dealType", false);
    },
    closed() {
      return this.invitation.dealState === "closed";
    },
    matchingJVRoles() {
      if (this.invitation) {
        if (this.closed) {
          return this.jvRoles;
        } else {
          return this.jvRoles.filter(o => o.activeDeal);
        }
      } else {
        return [];
      }
    },
    matchingRoles() {
      if (this.invitation) {
        const typedOptions = _.get(this.roles, this.invitation.dealType, []);

        if (this.closed) {
          return typedOptions;
        } else {
          return typedOptions.filter(o => o.activeDeal);
        }
      } else {
        return [];
      }
    },
    optionData() {
      switch (this.form) {
        case "role":
          return _.find(this.matchingRoles, {
            value: this.dealInvitation.roleName
          });
        case "jvRole":
          return _.find(this.jvRoles, {
            value: this.dealInvitation.jvRoleName
          });
        default:
          return null;
      }
    },
    description() {
      if (this.optionData && this.optionData.description) {
        if (this.closed) {
          return this.optionData.description;
        } else {
          return this.optionData.presentDescription;
        }
      } else {
        return null;
      }
    },
    needsJVSellerBuyer() {
      return (
        this.dealType &&
        this.dealType === "Recap Equity" &&
        this.dealInvitation.jvRoleName !== null &&
        this.dealInvitation.jvRoleName !== "broker" &&
        this.dealInvitation.roleName === null &&
        this.form === "jvRole"
      );
    },
    complete() {
      if (this.dealType && this.dealType === "Recap Equity") {
        if (this.dealInvitation.roleName === "broker") {
          return this.dealInvitation.roleName !== null;
        } else {
          return (
            this.dealInvitation.roleName !== null &&
            this.dealInvitation.jvRoleName !== null
          );
        }
      } else {
        return this.dealInvitation.roleName !== null;
      }
    }
  },
  created() {
    api.get(`deal_invitations/${this.dealInvitationToken}`).then(
      result => {
        const invitation = result.data;

        if (invitation.isInviter) {
          this.$store.dispatch("flash", "You can't accept your own invitation.");
          this.$router.push({ path: "/" });
        } else if (invitation.isParticipating) {
          this.$store.dispatch("flash", "You already joined this deal.");
          this.$router.push({ path: "/" });
        } else {
          this.invitation = invitation;
          this.form = this.dealType === "Recap Equity" ? "jvRole" : "role";
          this.$router.push({
            path: `/deal_invitations/${this.invitation.token}/accept`,
            query: { closed: this.closed }
          });
        }
      },
      failure => {
        this.$store.dispatch("flash", "Invitation no longer valid");
        this.$router.push({ path: "/" });
      }
    );
  },
  methods: {
    accept() {
      if (this.complete) {
        let invitation = this.dealInvitation;

        if (invitation.jvRoleName === "broker") {
          invitation.jvRoleName = null;
        }

        api
          .post(
            `deal_invitations/${this.dealInvitationToken}/accept`,
            invitation
          )
          .then(() => {
            this.$store.dispatch("flash", "Deal joined successfully");
            this.$store.commit("clearDealInvitationRole");
            this.$router.push({ path: "/" });
          });
      }
    },
    next() {
      this.form = "role";
    },
    openDeal() {
      this.$store.commit("openModal", {
        component: DealCard,
        props: {
          dealToken: this.invitation.dealToken
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.content-wrap {
  display: flex;
  flex-direction: column;
}
section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  pointer-events: none;
  &.horizontal {
    flex-wrap: wrap;
  }
  &.vertical {
    flex-direction: column;
    align-items: center;
  }
}
button.cta,
button.secondary,
button.complete {
  width: 330px;
  margin-bottom: 5px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    margin-top: 20px;
    background: #b7b7b7;
    color: #fff;
  }
  &.secondary {
    border: 1px solid #b7b7b7;
    background: none;
    color: #4f4f4f;
    &:hover {
      border-color: #777;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
