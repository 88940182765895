<template>
  <div class="mb-6 bg-white overflow-visible shadow sm:rounded-lg">
    <div class="border-b border-gray-200 px-4 py-5 sm:px-6">
      <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div class="ml-4 mt-2">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Marketing overview
          </h3>
          <p class="flex items-center text-sm leading-5 text-gray-500">
            <span v-if="liveDeal" class="mr-1 inline-flex items-center justify-center h-3 w-3 rounded-full bg-red-400"></span>
            <template v-if="liveDeal">{{ activeDeal.name }}: {{ activeDeal.state | capitalize }}</template>
            <router-link
              v-else
              @click.native="openDealCard"
              to=""
              class="text-indigo-600 hover:text-indigo-500"
            >
              {{ activeDeal.name }}: {{ activeDeal.state | capitalize }}
            </router-link>
          </p>
        </div>
        <div v-if="activeDeal.state !== 'closed'" class="ml-4 mt-2 flex-shrink-0 flex">
          <span class="inline-flex rounded-md shadow-sm md:hidden">
            <button
              @click="closeDeal"
              type="button"
              class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700"
            >
              Deal closed
            </button>
          </span>
          <deal-marketing-actions-menu class="ml-3 md:ml-0" />
          <span class="hidden ml-3 md:inline-flex rounded-md shadow-sm">
            <button
              @click="closeDeal"
              type="button"
              class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700"
            >
              Deal closed
            </button>
          </span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 sm:rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
      <div>
        <div class="px-4 py-5 sm:p-6">
          <dl>
            <dt class="text-base leading-6 font-normal text-gray-900">
              Marketing Page Views
            </dt>
            <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div class="flex items-baseline text-2xl leading-8 font-semibold text-gray-900">
                {{ metrics.totalPageViews }}
                <span class="ml-2 text-sm leading-5 font-medium text-gray-500">
                  from {{ yesterdayPageViews }} yesterday
                </span>
              </div>
              <div v-if="dailyPageViewIncrease" class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
                </svg>
                <span class="sr-only">
                  Increased by
                </span>
                {{ dailyPageViewIncrease }}%
              </div>
            </dd>
          </dl>
        </div>
      </div>
      <div class="border-t border-gray-200 md:border-0 md:border-l">
        <div class="px-4 py-5 sm:p-6">
          <dl>
            <dt @click="filterInterested" class="text-base leading-6 font-normal text-indigo-600 hover:text-indigo-500 cursor-pointer">
              Interested Prospects
            </dt>
            <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div class="flex items-baseline">
                <div @click="filterInterested" class="text-2xl leading-8 font-semibold text-indigo-600 hover:text-indigo-500 cursor-pointer">{{ metrics.interestedProspects }}</div>
                <span class="ml-2 text-sm leading-5 font-medium text-gray-500">
                  of {{ metrics.totalProspects }}
                </span>
              </div>
              <div v-if="percentageInterested" class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                {{ percentageInterested }}%
              </div>
            </dd>
          </dl>
        </div>
      </div>
      <div class="border-t border-gray-200 md:border-0 md:border-l">
        <div class="px-4 py-5 sm:p-6">
          <dl>
            <dt @click="filterNeedFollowUp" class="text-base leading-6 font-normal text-indigo-600 hover:text-indigo-500 cursor-pointer">
              Need follow-up
            </dt>
            <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div @click="filterNeedFollowUp" class="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600 hover:text-indigo-500 cursor-pointer">
                {{ metrics.needFollowUp }}
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DealCard from "../../components/cards/deal";
import DealMarketingActionsMenu from "./deal-marketing-actions-menu";
import DealSize from "../onboarding/deal/deal-size";
import LeaseSize from "../onboarding/deal/lease-size";
import LoanSeniority from "../onboarding/deal/loan-seniority";

export default {
  components: { DealMarketingActionsMenu },
  props: ["metrics"],
  computed: {
    ...mapState(["activeDeal"]),
    ...mapGetters([
      "closedDealLoanSale",
      "closedDealCapitalMarkets",
      "closedDealLease"
    ]),
    dealState() {
      return this.activeDeal.state;
    },
    liveDeal() {
      if (this.dealState) {
        return _.includes(["live", "awarded"], this.dealState);
      } else {
        return false;
      }
    },
    yesterdayPageViews() {
      return this.metrics.totalPageViews - this.metrics.currentDayPageViews;
    },
    dailyPageViewIncrease() {
      if (this.yesterdayPageViews === 0) {
        return null;
      } else {
        const percentChange =
          ((this.metrics.totalPageViews - this.yesterdayPageViews) /
            this.yesterdayPageViews) *
          100;

        return _.round(percentChange);
      }
    },
    percentageInterested() {
      if (this.metrics.totalProspects === 0) {
        return null;
      } else {
        const percentage =
          (this.metrics.interestedProspects / this.metrics.totalProspects) *
          100;

        return _.round(percentage);
      }
    }
  },
  methods: {
    closeDeal() {
      this.$store.commit("openModal", {
        component: this.dealComponent(),
        props: {
          dealId: this.activeDeal.id
        }
      });
    },
    openDealCard() {
      this.$store.commit("openModal", {
        component: DealCard,
        props: {
          dealToken: this.activeDeal.token
        }
      });
    },
    dealComponent() {
      if (this.closedDealLoanSale) {
        return LoanSeniority;
      } else if (this.closedDealCapitalMarkets) {
        return DealSize;
      } else if (this.closedDealLease) {
        return LeaseSize;
      }
    },
    filterNeedFollowUp() {
      this.$emit("need-follow-up");
    },
    filterInterested() {
      this.$emit("interested");
    }
  }
};
</script>
