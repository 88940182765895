<template>
  <main class="relative z-0 focus:outline-none" tabindex="0">
    <div v-if="!activeDealMarketing.investorPreview && activeDeal.marketingWriteAuthorized" class="bg-white">
      <div class="max-w-screen-xl mx-auto pb-4 px-4 overflow-hidden sm:px-6 lg:px-8">
        <div class="flex justify-center space-x-4">
          <a @click.prevent="uploadDocuments" href="" class="flex items-center font-medium text-indigo-600 hover:text-indigo-500">
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
            </svg>
            <span class="ml-1">Upload</span>
          </a>
          <a v-if="activeDeal.state === 'draft'" @click.prevent="setConfidentialityAgreement" href="" class="flex items-center font-medium text-indigo-600 hover:text-indigo-500">
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
            </svg>
            <span class="ml-1">Configure CA</span>
          </a>
        </div>
      </div>
    </div>

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="mt-10">
      <h2 class="text-lg leading-6 font-medium text-gray-900">Pinned Documents</h2>
      <ul class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <li v-for="document in pinnedDocuments" :key="`pinned-${document.id}`" class="col-span-1 flex shadow-sm rounded-md">
          <a href="" @click.prevent="viewDocument(document)" class="flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md" :class="`bg-${iconColor(document)}`">
            <svg class="h-7 w-7 text-white" fill="currentColor" viewBox="0 0 384 512">
              <path v-if="document.documentType === 'PDF'" fill="currentColor" d="M181.9 256.1c-5-16-4.9-46.9-2-46.9 8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9 37.1 15.8 42.8 9 42.8 9z"></path>
              <path v-else-if="document.documentType === 'PowerPoint'" fill="currentColor" d="M193.7 271.2c8.8 0 15.5 2.7 20.3 8.1 9.6 10.9 9.8 32.7-.2 44.1-4.9 5.6-11.9 8.5-21.1 8.5h-26.9v-60.7h27.9zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm53 165.2c0 90.3-88.8 77.6-111.1 77.6V436c0 6.6-5.4 12-12 12h-30.8c-6.6 0-12-5.4-12-12V236.2c0-6.6 5.4-12 12-12h81c44.5 0 72.9 32.8 72.9 77z"></path>
              <path v-else-if="document.documentType === 'Word'" fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm57.1 120H305c7.7 0 13.4 7.1 11.7 14.7l-38 168c-1.2 5.5-6.1 9.3-11.7 9.3h-38c-5.5 0-10.3-3.8-11.6-9.1-25.8-103.5-20.8-81.2-25.6-110.5h-.5c-1.1 14.3-2.4 17.4-25.6 110.5-1.3 5.3-6.1 9.1-11.6 9.1H117c-5.6 0-10.5-3.9-11.7-9.4l-37.8-168c-1.7-7.5 4-14.6 11.7-14.6h24.5c5.7 0 10.7 4 11.8 9.7 15.6 78 20.1 109.5 21 122.2 1.6-10.2 7.3-32.7 29.4-122.7 1.3-5.4 6.1-9.1 11.7-9.1h29.1c5.6 0 10.4 3.8 11.7 9.2 24 100.4 28.8 124 29.6 129.4-.2-11.2-2.6-17.8 21.6-129.2 1-5.6 5.9-9.5 11.5-9.5zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"></path>
              <path v-else-if="document.documentType === 'Excel'" fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm60.1 106.5L224 336l60.1 93.5c5.1 8-.6 18.5-10.1 18.5h-34.9c-4.4 0-8.5-2.4-10.6-6.3C208.9 405.5 192 373 192 373c-6.4 14.8-10 20-36.6 68.8-2.1 3.9-6.1 6.3-10.5 6.3H110c-9.5 0-15.2-10.5-10.1-18.5l60.3-93.5-60.3-93.5c-5.2-8 .6-18.5 10.1-18.5h34.8c4.4 0 8.5 2.4 10.6 6.3 26.1 48.8 20 33.6 36.6 68.5 0 0 6.1-11.7 36.6-68.5 2.1-3.9 6.2-6.3 10.6-6.3H274c9.5-.1 15.2 10.4 10.1 18.4zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"></path>
              <path v-else-if="document.documentType === 'Comma Separated Values'" fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm-96 144c0 4.42-3.58 8-8 8h-8c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h8c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-8c-26.51 0-48-21.49-48-48v-32c0-26.51 21.49-48 48-48h8c4.42 0 8 3.58 8 8v16zm44.27 104H160c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h12.27c5.95 0 10.41-3.5 10.41-6.62 0-1.3-.75-2.66-2.12-3.84l-21.89-18.77c-8.47-7.22-13.33-17.48-13.33-28.14 0-21.3 19.02-38.62 42.41-38.62H200c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-12.27c-5.95 0-10.41 3.5-10.41 6.62 0 1.3.75 2.66 2.12 3.84l21.89 18.77c8.47 7.22 13.33 17.48 13.33 28.14.01 21.29-19 38.62-42.39 38.62zM256 264v20.8c0 20.27 5.7 40.17 16 56.88 10.3-16.7 16-36.61 16-56.88V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v20.8c0 35.48-12.88 68.89-36.28 94.09-3.02 3.25-7.27 5.11-11.72 5.11s-8.7-1.86-11.72-5.11c-23.4-25.2-36.28-58.61-36.28-94.09V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8zm121-159L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"></path>
              <path v-else fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"></path>
            </svg>
          </a>
          <div class="flex-1 flex items-center border-t border-b border-gray-200 bg-white truncate">
            <div class="flex-1 px-4 py-2 text-sm truncate">
              <a href="" @click.prevent="viewDocument(document)" class="text-gray-900 font-medium hover:text-gray-600">{{ document.name }}</a>
              <p class="text-gray-500">{{ document.createdAt | moment("from") }}</p>
            </div>
          </div>
          <div class="overflow-visible flex items-center border-t border-r border-b border-gray-200 bg-white rounded-r-md">
            <div class="flex-shrink-0 pr-2">
              <document-menu :document="document" />
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="mt-10 px-2 pb-5 sm:px-0 sm:flex sm:items-center sm:justify-between">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Deal Documents
      </h3>
      <div class="mt-3 sm:mt-0 sm:ml-4">
        <label for="search_candidate" class="sr-only">Search</label>
        <div class="flex rounded-md shadow-sm">
          <div class="relative flex-grow focus-within:z-10">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <!-- Heroicon name: search -->
              <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
              </svg>
            </div>
            <input v-model="filter" @keydown.escape="filter = ''" aria-label="Filter" type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:hidden border-gray-300" placeholder="Search">
            <input v-model="filter" @keydown.escape="filter = ''" aria-label="Filter" type="text" class="hidden focus:ring-indigo-500 focus:border-indigo-500 w-full rounded-none rounded-l-md pl-10 sm:block sm:text-sm border-gray-300" placeholder="Search documents">
          </div>
          <div ref="documentSortMenu" class="relative inline-block">
            <button @click="toggleMenu" type="button" class="-ml-px h-full relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
              <!-- Heroicon name: sort-descending -->
              <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
              </svg>
              <span class="ml-2">Sort</span>
              <!-- Heroicon name: chevron-down -->
              <svg class="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>

            <transition
              enter-active-class="duration-100 ease-out"
              enter-class="opacity-0 scale-95"
              enter-to-class="opacity-100 scale-100"
              leave-active-class="duration-75 ease-in"
              leave-class="opacity-100 scale-100"
              leave-to-class="opacity-0 scale-95"
            >
              <div v-show="menuOpen" class="origin-top-right absolute left-0 sm:right-0 sm:left-auto mt-2 w-24 rounded-md shadow-lg" style="z-index: 9999;">
                <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
                  <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <a href="" @click.prevent="fetchDocuments('alphabetical')" class="group flex items-center justify-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                      A-Z
                    </a>
                    <a href="" @click.prevent="fetchDocuments('newest')" class="group flex items-center justify-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                      Newest
                    </a>
                    <a href="" @click.prevent="fetchDocuments('largest')" class="group flex items-center justify-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                      Largest
                    </a>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <documents-table :documents="filteredDocuments" />
  </main>
</template>

<script>
import { all } from "../../document-file-types";
import { mapGetters, mapState } from "vuex";
import ConfidentialityAgreementForm from "./confidentiality-agreement-form";
import DocumentMenu from "./document-menu";
import DocumentsTable from "./documents-table";
import FileDragDrop from "../../components/file-drag-drop.vue";
import FileViewer from "../../components/cards/file-viewer";
import api from "../../api";

export default {
  components: { DocumentMenu, DocumentsTable },
  data() {
    return {
      menuOpen: false,
      pinnedDocuments: [],
      documents: [],
      filter: ""
    };
  },
  computed: {
    ...mapState([
      "activeDeal",
      "activeDealMarketing",
      "modal",
      "xlModal",
      "refetchDealMarketingDocuments"
    ]),
    ...mapGetters(["dealProspectInviteParam"]),
    filteredDocuments() {
      const query = _.toLower(this.filter);

      if (query.length < 2) {
        return this.documents;
      } else {
        return this.documents.filter(document => {
          const { name, category, documentType, fileExtension } = document;

          return (
            _.includes(_.toLower(name), query) ||
            _.includes(_.toLower(category), query) ||
            _.includes(_.toLower(documentType), query) ||
            _.includes(_.toLower(fileExtension), query)
          );
        });
      }
    }
  },
  watch: {
    refetchDealMarketingDocuments: {
      handler() {
        if (this.refetchDealMarketingDocuments) {
          this.fetchDocuments();
        }
      }
    },
    modal: {
      handler() {
        if (!this.modal) {
          this.fetchDocuments();
        }
      }
    },
    xlModal: {
      handler() {
        if (!this.xlModal && !this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
          this.fetchDocuments();
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch("dealMarketingImpression");
    this.fetchDocuments();

    document.title = `${this.activeDeal.name} · Documents | Tower Hunt`;

    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.documentSortMenu &&
        !this.$refs.documentSortMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    fetchDocuments(sort = "alphabetical") {
      this.$store.commit("isLoading");

      let query = `?sort=${sort}`;

      if (this.dealProspectInviteParam) {
        query = `${query}&invite=${this.dealProspectInviteParam}`;
      }

      this.documents = [];

      api
        .get(
          `deal_marketings/${this.activeDealMarketing.id}/deal_marketing_documents${query}`
        )
        .then(json => {
          this.documents = json.data;
          this.$store.commit("setRefetchDocuments", false);
          this.$store.commit("doneLoading");
          this.menuOpen = false;
        });

      api
        .get(
          `deal_marketings/${this.activeDealMarketing.id}/deal_marketing_documents${query}&pinned=true`
        )
        .then(json => {
          this.pinnedDocuments = json.data;
        });
    },
    iconColor(file) {
      switch (file.fileExtension) {
        case "pdf":
          return "red-600";
        case "ppt":
        case "pptx":
          return "red-400";
        case "doc":
        case "docx":
        case "csv":
          return "blue-600";
        case "xls":
        case "xlsx":
          return "green-600";
        default:
          return "gray-400";
      }
    },
    viewDocument(file) {
      this.$store.commit("openXLModal", {
        component: FileViewer,
        props: { document: file }
      });
    },
    setConfidentialityAgreement() {
      if (!this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
        this.$store.commit("openModal", {
          component: ConfidentialityAgreementForm,
          props: {}
        });
      }
    },
    uploadDocuments() {
      if (!this.activeDealMarketing.investorPreview && this.activeDeal.marketingWriteAuthorized) {
        this.$store.commit("openModal", {
          component: FileDragDrop,
          props: {
            endpoint: `deal_marketing_document_uploads/${this.activeDealMarketing.id}`,
            fileTypes: all.join(","),
            fileTypeLabels: "XLS, PPT, DOC, PDF",
            sizeLimit: 100,
            multiple: true,
            heading: "Let's add some documents",
            marginBottom: true,
            flashMessage: "Documents saved successfully"
          }
        });
      }
    }
  }
};
</script>
