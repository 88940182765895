<template>
  <div class="min-h-screen">
    <main class="pb-8">
      <div class="max-w-3xl mx-auto lg:max-w-7xl">
        <h1 class="sr-only">Profile</h1>
        <!-- Main 3 column grid -->
        <div class="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
          <!-- Left column -->
          <div class="grid grid-cols-1 gap-4 lg:col-span-2">
            <!-- Welcome panel -->
            <section aria-labelledby="profile-overview-title">
              <div class="rounded-lg bg-white overflow-hidden shadow">
                <h2 class="sr-only" id="profile-overview-title">Profile Overview</h2>
                <div class="bg-white p-6">
                  <div class="sm:flex sm:items-center sm:justify-between">
                    <div class="sm:flex sm:space-x-5">
                      <div class="flex-shrink-0">
                        <avatar-photo :person="currentUser" circle-size="20" text-size="2xl" />
                      </div>
                      <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                        <p class="text-sm font-medium text-gray-600">Welcome back,</p>
                        <p class="text-xl font-bold text-gray-900 sm:text-2xl">{{ currentUser.name }}</p>
                        <p class="text-sm font-medium text-gray-600">{{ userTooltip }}</p>
                      </div>
                    </div>
                    <div class="mt-5 flex justify-center sm:mt-0">
                      <router-link :to="profilePath" class="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                        View profile
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="relative border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-2 lg:grid-cols-3 sm:divide-y-0 sm:divide-x">
                  <div v-tooltip="'Your reputation'" class="px-6 py-5 text-sm text-center">
                    <router-link to="reputation" class="flex justify-between font-medium text-sm text-gray-900 hover:text-gray-500">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <div :class="reputationColor" class="inline-flex items-center px-2.5 py-1.5 text-xs font-medium rounded shadow-sm">
                        {{ currentUser.reputationLevel | capitalize }}
                      </div>
                      <div class="flex items-center">
                        <span>{{ currentUser.creditsCount | formattedNumber }}</span>
                        <div v-if="creditDelta > 0" class="ml-1 flex items-baseline text-sm leading-5 font-semibold text-green-600">
                          <svg class="self-center flex-shrink-0 h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                            <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                          </svg>
                          <span class="sr-only">
                            Increased by
                          </span>
                          {{ creditDelta }}
                        </div>
                        <div v-else class="ml-1 flex items-baseline text-sm leading-5 font-semibold text-gray-500">
                          <svg class="self-center flex-shrink-0 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                            <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                          </svg>
                          <span class="sr-only">
                            Unchanged
                          </span>
                          0
                        </div>
                      </div>
                    </router-link>
                  </div>

                  <div class="relative hidden px-6 py-5 lg:block text-sm">
                    <router-link to="network/teams" class="flex justify-between items-center font-medium text-sm text-gray-900 hover:text-gray-500">
                      <!-- Extend touch target to entire panel -->
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      <span>Teams</span>
                      <div class="flex items-center">
                        <span class="flex items-baseline">{{ currentUser.teamsCount }}</span>
                      </div>
                    </router-link>
                  </div>

                  <privacy-mode-toggle light-background="bg-gray-50" custom-padding="px-6 py-5" />
                </div>
              </div>
            </section>

            <!-- Actions panel -->
            <section aria-labelledby="quick-links-title">
              <div class="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
                <h2 class="sr-only" id="quick-links-title">Quick links</h2>

                <div class="rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                  <div>
                    <span class="rounded-lg inline-flex p-3 bg-teal-50 text-teal-700 ring-4 ring-white">
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                      </svg>
                    </span>
                  </div>
                  <div class="mt-8">
                    <h3 class="text-lg font-medium">
                      <a href="" @click.prevent="openNotepad" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true"></span>
                        Take Notes
                      </a>
                    </h3>
                    <p class="mt-2 text-sm text-gray-500">
                      Your private notepad. Jot down anything. See your notes on the map by linking them to deals/intel.
                    </p>
                  </div>
                  <span class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                  </span>
                </div>

                <div class="sm:rounded-tr-lg relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                  <div>
                    <span class="rounded-lg inline-flex p-3 bg-purple-50 text-purple-700 ring-4 ring-white">
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                      </svg>
                    </span>
                  </div>
                  <div class="mt-8">
                    <h3 class="text-lg font-medium">
                      <router-link to="/newsfeed" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true"></span>
                        Hunt Intel
                      </router-link>
                    </h3>
                    <p class="mt-2 text-sm text-gray-500">
                      Browse, discuss, map, filter, and search the latest news and deals. Everything you have access to is here.
                    </p>
                  </div>
                  <span class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                  </span>
                </div>

                <div class="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                  <div>
                    <span class="rounded-lg inline-flex p-3 bg-light-blue-50 text-light-blue-700 ring-4 ring-white">
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                    </span>
                  </div>
                  <div class="mt-8">
                    <h3 class="text-lg font-medium">
                      <a href="" @click.prevent="newIntel" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true"></span>
                        Add Intel
                      </a>
                    </h3>
                    <p class="mt-2 text-sm text-gray-500">
                      Input deals and intel that can be mapped and filtered. You can contribute to the Tower Hunt database or save to your safezone.
                    </p>
                  </div>
                  <span class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                  </span>
                </div>

                <div class="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                  <div>
                    <span class="rounded-lg inline-flex p-3 bg-yellow-50 text-yellow-700 ring-4 ring-white">
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                      </svg>
                    </span>
                  </div>
                  <div class="mt-8">
                    <h3 class="text-lg font-medium">
                      <router-link to="safezone" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true"></span>
                        Safezone
                      </router-link>
                    </h3>
                    <p class="mt-2 text-sm text-gray-500">
                      This is your private list of deals and intel. No one can see unless you explicitly share.
                    </p>
                  </div>
                  <span class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                  </span>
                </div>

                <div class="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                  <div>
                    <span class="rounded-lg inline-flex p-3 bg-orange-50 text-orange-700 ring-4 ring-white">
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                      </svg>
                    </span>
                  </div>
                  <div class="mt-8">
                    <h3 class="text-lg font-medium">
                      <a href="" @click.prevent="savedIntel" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true"></span>
                        Bookmarks
                      </a>
                    </h3>
                    <p class="mt-2 text-sm text-gray-500">
                      Mark your to-dos or save something for another time. Only you can see your saved items, so use them however you’d like.
                    </p>
                  </div>
                  <span class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                  </span>
                </div>

                <div class="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                  <div>
                    <span class="rounded-lg inline-flex p-3 bg-indigo-50 text-indigo-700 ring-4 ring-white">
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                      </svg>
                    </span>
                  </div>
                  <div class="mt-8">
                    <h3 class="text-lg font-medium">
                      <router-link to="watchlist" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true"></span>
                        Property Watchlist
                      </router-link>
                    </h3>
                    <p class="mt-2 text-sm text-gray-500">
                      Powerful private tracking for your important properties. Receive tailored proximity alerts and share/discuss in a dedicated private channel.
                    </p>
                  </div>
                  <span class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                  </span>
                </div>

                <div class="sm:rounded-bl-lg relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                  <div>
                    <span class="rounded-lg inline-flex p-3 bg-rose-50 text-rose-700 ring-4 ring-white">
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                      </svg>
                    </span>
                  </div>
                  <div class="mt-8">
                    <h3 class="text-lg font-medium">
                      <router-link to="/deals/new/1?active=true" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true"></span>
                        Market Your Deal
                      </router-link>
                    </h3>
                    <p class="mt-2 text-sm text-gray-500">
                      Market directly through Tower Hunt. Choose your audience. Use the Strategic Map to tell the story.
                    </p>
                  </div>
                  <span class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                  </span>
                </div>

                <div class="rounded-bl-lg rounded-br-lg sm:rounded-bl-none relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                  <div>
                    <span class="rounded-lg inline-flex p-3 bg-teal-50 text-teal-700 ring-4 ring-white">
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
                      </svg>
                    </span>
                  </div>
                  <div class="mt-8">
                    <h3 class="text-lg font-medium">
                      <router-link to="/home/premium" class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true"></span>
                        Sell Premium Insights
                      </router-link>
                    </h3>
                    <p class="mt-2 text-sm text-gray-500">
                      Manage content you're selling. Access content you've licensed.
                    </p>
                  </div>
                  <span class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                  </span>
                </div>
              </div>
            </section>
          </div>

          <!-- Right column -->
          <div class="grid grid-cols-1 gap-4">
            <!-- Trending -->
            <section aria-labelledby="trending-heading">
              <div class="bg-white rounded-lg shadow">
                <div class="p-6">
                  <h2 id="trending-heading" class="text-base font-medium text-gray-900">
                    Trending Intel
                  </h2>
                  <div class="mt-6 flow-root">
                    <div v-if="!trendingLoaded" class="flex justify-center">
                      <grid-loader :loading="true" size="6px" color="#D4D4D8" />
                    </div>
                    <ul v-else-if="trending.data.length > 0" class="-my-4 divide-y divide-gray-200">
                      <li v-for="post in trending.data" :key="post.content.token" class="flex py-4 space-x-3">
                        <safezone-color-dot :content="post.content" style-type="icon" />
                        <div class="min-w-0 flex-1">
                          <p class="text-sm font-medium text-gray-900">
                            <a href="" @click.prevent="viewDetails(post.content)">{{ post.content.name }}</a>
                          </p>
                          <div class="mt-2 flex">
                            <div class="flex text-sm leading-5 text-gray-500">
                              <template v-if="post.content.pinCount && post.content.pinCount > 0">
                                <span class="flex items-center text-gray-400" v-tooltip="pinHelp(post.content)">
                                  <svg v-if="isBounty(post.content)" class="mr-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" clip-rule="evenodd" />
                                  </svg>
                                  <svg v-else class="mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"/>
                                  </svg>
                                  <span class="text-gray-900">{{ post.content.pinCount }}</span>
                                </span>
                              </template>
                              <template v-if="post.content.commentCount && post.content.commentCount > 0">
                                <span v-if="post.content.pinCount && post.content.pinCount > 0" class="mx-1">
                                  &middot;
                                </span>
                                <span @click="viewComments(post.content)" class="flex items-center text-gray-400 hover:text-gray-500 cursor-pointer" v-tooltip="'Comments'">
                                  <svg class="mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                                    <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                                  </svg>
                                  <span class="text-gray-900">{{ post.content.commentCount }}</span>
                                </span>
                              </template>
                              <template v-if="post.content.bountyCount && post.content.bountyCount > 0">
                                <span v-if="(post.content.pinCount && post.content.pinCount > 0) || (post.content.commentCount && post.content.commentCount > 0)" class="mx-1">
                                  &middot;
                                </span>
                                <span @click="viewComments(post.content)" class="flex items-center text-gray-400 hover:text-gray-500 cursor-pointer" v-tooltip="'Bounties'">
                                  <svg class="mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                  </svg>
                                  <span class="text-gray-900">{{ post.content.bountyCount }}</span>
                                </span>
                              </template>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div v-else class="text-center">
                      <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <h3 class="mt-2 text-sm font-medium text-gray-900">Working on it</h3>
                      <p class="mt-1 text-sm text-gray-500">
                        We're building your personalized newsfeed.
                      </p>
                    </div>
                  </div>
                  <div class="mt-6">
                    <a href="" @click.prevent="topNewsfeed" v-tooltip="'View your newsfeed sorted by top posts'" class="w-full block text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                      View all
                    </a>
                  </div>
                </div>
              </div>
            </section>

            <!-- Property Watchlist -->
            <section v-if="false" aria-labelledby="property-watchlist-title">
              <div class="rounded-lg bg-white overflow-hidden shadow">
                <div class="p-6">
                  <h2 class="text-base font-medium text-gray-900" id="property-watchlist-title">Property Watchlist</h2>
                  <div class="flow-root mt-6">
                    <ul class="-my-5 divide-y divide-gray-200">
                      <li class="py-4">
                        <div class="flex items-center space-x-4">
                          <div class="flex-shrink-0">
                            <span class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-50 text-gray-700">
                              <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                              </svg>
                            </span>
                          </div>
                          <div class="flex-1 min-w-0">
                            <p class="text-sm font-medium text-gray-900 truncate">
                              123 Main Street
                            </p>
                            <p class="text-sm text-gray-500 truncate">
                              Office, lab
                            </p>
                          </div>
                          <div>
                            <a href="" @click.prevent class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50">
                              View
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="mt-6">
                    <router-link to="watchlist" class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                      View all
                    </router-link>
                  </div>
                </div>
              </div>
            </section>

            <!-- My Markets -->
            <section aria-labelledby="my-markets-title">
              <div class="rounded-lg bg-white overflow-hidden shadow">
                <div class="p-6">
                  <div class="flex items-center justify-between">
                    <h2 class="text-base font-medium text-gray-900" id="my-markets-title">My Markets</h2>
                    <router-link to="/targets/new/1" class="flex justify-center items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      Create
                    </router-link>
                  </div>
                  <div class="flow-root mt-6">
                    <div v-if="slicedTargets && slicedTargets.length === 0" class="flex justify-center">
                      <grid-loader :loading="true" size="6px" color="#D4D4D8" />
                    </div>
                    <ul v-else class="-my-5 divide-y divide-gray-200">
                      <li v-for="market in slicedTargets" :key="market.id" class="py-4">
                        <div class="flex items-center space-x-4">
                          <div class="flex-shrink-0">
                            <span class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-50 text-gray-700">
                              <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            </span>
                          </div>
                          <div class="flex-1 min-w-0">
                            <p class="text-sm font-medium text-gray-900 truncate">
                              {{ market | marketName }}
                            </p>
                            <a href="" @click.prevent="view(market)" class="text-sm text-gray-500 truncate hover:text-gray-400 hover:underline">
                              Edit
                            </a>
                          </div>
                          <div>
                            <a href="" @click.prevent="map(market)" class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50">
                              Map
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="mt-6">
                    <router-link to="/newsfeed?filter=open" class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                      View all
                    </router-link>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>

    <!-- Simple footer -->
    <marketing-footer padding-y="py-6" padding-x="p-6" compact="true" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import AvatarPhoto from "../../components/avatar-photo";
import Comments from "../../components/comments";
import DealCard from "../../components/cards/deal";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import IntelCard from "../../components/cards/market-intel";
import Market from "../../components/cards/market";
import MarketingFooter from "../../components/marketing-footer";
import NewsfeedFilterSlideover from "../../components/newsfeed/filter-slideover";
import Notepad from "../../components/notepad";
import PrivacyModeToggle from "../../components/privacy-mode-toggle.vue";
import SafezoneColorDot from "../../components/safezone-color-dot";
import api from "../../api";

export default {
  components: {
    AvatarPhoto,
    GridLoader,
    MarketingFooter,
    PrivacyModeToggle,
    SafezoneColorDot
  },
  data() {
    return {
      trending: {
        data: [],
        pagy: null
      },
      trendingLoaded: false
    };
  },
  computed: {
    ...mapState(["modal"]),
    currentUser() {
      return this.$store.state.currentUser;
    },
    reputationColor() {
      switch (this.currentUser.reputationLevel) {
        case "bronze":
          return "bg-gradient-to-r from-yellow-500 to-orange-700 text-white hover:to-orange-800";
        case "silver":
          return "bg-gradient-to-r from-cool-gray-200 to-gray-400 text-gray-900 hover:to-gray-500";
        case "gold":
          return "bg-gradient-to-r from-yellow-300 to-yellow-500 text-white hover:to-yellow-600";
        default:
          return "text-white bg-gray-900 hover:bg-gray-800";
      }
    },
    userTooltip() {
      const role = _.capitalize(
        this.$options.filters.industryRoleAlias(
          this.currentUser.industryRole,
          this.currentUser.industrySubRole,
          this.currentUser.token
        )
      );

      if (this.currentUser.company) {
        return `${role} at ${this.currentUser.company}`;
      } else {
        return `${role}`;
      }
    },
    slicedTargets() {
      return _.slice(this.$store.getters.contactTargets(null), 0, 3);
    },
    privacyMode() {
      return this.currentUser.privacyMode;
    },
    firstName() {
      return _.words(this.currentUser.name)[0];
    },
    profilePath() {
      if (this.currentUser) {
        return `/contacts/${this.currentUser.token}`;
      } else {
        return "#";
      }
    },
    creditDelta() {
      return (
        this.currentUser.creditsCount - this.currentUser.creditsCountSnapshot
      );
    }
  },
  watch: {
    modal: {
      handler() {
        if (!this.modal) {
          this.$store.commit("clearTarget");
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch("updateNewsfeed");
    this.$store.commit("closeSlideover");

    api.get(`top_newsfeed`).then(json => {
      this.trending = json.data;
      this.trendingLoaded = true;
    });
  },
  methods: {
    openNotepad() {
      this.$store.commit("openSlideover", {
        component: Notepad,
        props: {
          myNotepad: true
        }
      });
    },
    topNewsfeed() {
      this.$store.dispatch("updateNewsfeedSortBy", "top");
      this.$router.push({ path: "/newsfeed" });
    },
    newIntel() {
      this.$router.push({
        path: "/intel/new/privacy",
        query: { nextUrl: "/intel/new/1" }
      });
    },
    savedIntel() {
      const payload = {
        markets: [],
        intelType: [],
        relevance: [
          {
            name: "I saved",
            value: "ownSaved"
          }
        ],
        sources: [],
        dates: {
          allDates: true,
          start: new Date(),
          end: new Date()
        }
      };

      this.$store.commit("setNewsfeedFilters", payload);
      this.$router.push({ path: "/newsfeed" });
      this.$store.commit("openSlideover", {
        component: NewsfeedFilterSlideover,
        props: {
          newsfeedFilter: true
        }
      });
    },
    view(market) {
      this.$store.commit("openModal", {
        component: Market,
        props: {
          target: market,
          explorable: false
        }
      });
    },
    viewDetails(content) {
      switch (content.newsfeedType) {
        case "deal":
          if (this.activeDeal(content) && this.marketingListDeal(content) && content.dealTeam) {
            const marketingPage =
              content.state === "draft" ? "overview" : "marketing-list";

            this.$router.push({
              path: `/deal_offerings/${content.urlHandle || content.token}/${marketingPage}`
            });
          } else if (this.liveDeal(content) && this.marketingListDeal(content) && content.marketingReadAuthorized) {
            const marketingOverviewPath = `/deal_offerings/${content.urlHandle || content.token}/overview`;

            window.open(marketingOverviewPath, "_blank");
          } else {
            this.$store.commit("openModal", {
              component: DealCard,
              props: {
                dealObject: content,
                property: null
              }
            });
          }
          break;
        case "intel":
          this.$store.commit("openModal", {
            component: IntelCard,
            props: {
              postObject: content,
              property: null
            }
          });
          break;
        default:
          return;
      }
    },
    viewComments(post) {
      this.$store.commit("openSlideover", {
        component: Comments,
        props: {
          content: post
        }
      });
    },
    marketingListDeal(content) {
      return _.get(content, "source", null) === "direct";
    },
    liveDeal(deal) {
      return _.includes(["live", "awarded"], _.get(deal, "state", null));
    },
    activeDeal(deal) {
      return _.includes(
        ["draft", "live", "awarded"],
        _.get(deal, "state", null)
      );
    },
    isBounty(content) {
      return _.get(content, "bounty.standalone", false);
    },
    pinHelp(content) {
      return this.isBounty(content)
        ? "People following this bounty"
        : "People were helped by this";
    },
    map(market) {
      this.$router.push({
        path: `/map`,
        query: { market: market.token }
      });
    }
  }
};
</script>
