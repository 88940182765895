<template>
  <div ref="dealMenu" @keydown.escape="menuOpen = false" class="relative inline-block text-left" style="z-index: 9998;">
    <div>
      <button @click="toggleMenu" class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600">
        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
        </svg>
      </button>
    </div>
    <transition
      enter-active-class="duration-150 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div
        v-show="menuOpen"
        class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg"
      >
        <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
          <router-link
            v-if="isAdmin"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="togglePremium"
          >
            <svg v-if="deal.premium" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clip-rule="evenodd" />
              <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />
            </svg>
            <svg v-else class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd" />
            </svg>
            <template v-if="deal.premium">Make free</template>
            <template v-else>Make premium</template>
          </router-link>
          <router-link
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="purchaseableIntent"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
            </svg>
            Sell premium insights
          </router-link>
          <router-link
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="share"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
            </svg>
            Copy deal link
          </router-link>
          <router-link
            v-if="hasMarketingList"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="viewMarketing"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"/>
            </svg>
            View marketing page
          </router-link>
          <router-link
            v-if="deal.dealTeam"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="addParticipant"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"/>
            </svg>
            Add people
          </router-link>
          <router-link
            v-if="isAdmin || deal.authored"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="toggleDealCommentability"
          >
            <svg v-if="deal.commentable" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 640 512">
              <path d="M64 240c0 49.6 21.4 95 57 130.7-12.6 50.3-54.3 95.2-54.8 95.8-2.2 2.3-2.8 5.7-1.5 8.7 1.3 2.9 4.1 4.8 7.3 4.8 66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 27.4 0 53.7-3.6 78.4-10L72.9 186.4c-5.6 17.1-8.9 35-8.9 53.6zm569.8 218.1l-114.4-88.4C554.6 334.1 576 289.2 576 240c0-114.9-114.6-208-256-208-65.1 0-124.2 20.1-169.4 52.7L45.5 3.4C38.5-2 28.5-.8 23 6.2L3.4 31.4c-5.4 7-4.2 17 2.8 22.4l588.4 454.7c7 5.4 17 4.2 22.5-2.8l19.6-25.3c5.4-6.8 4.1-16.9-2.9-22.3z" />
            </svg>
            <svg v-else class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"></path><path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
            </svg>
            <template v-if="deal.commentable">Disable comments</template>
            <template v-else>Allow comments</template>
          </router-link>
          <router-link
            v-if="deal.authored"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="toggleDealConfidentiality"
          >
            <svg v-if="deal.confidential" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 576 512">
              <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path>
            </svg>
            <svg v-else class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 640 512">
              <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path>
            </svg>
            <template v-if="deal.confidential">Make public</template>
            <template v-else>Make confidential</template>
          </router-link>
          <router-link
            v-if="!deal.dealTeam && deal.state !== 'closed' && !deal.followed"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="follow"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
            </svg>
            Follow
          </router-link>
          <router-link
            v-else-if="!deal.dealTeam && deal.state !== 'closed' && deal.followed"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="unfollow"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
            </svg>
            Unfollow
          </router-link>
          <router-link
            v-if="!deal.dealTeam && !deal.authored && deal.state !== 'closed' && !deal.followed"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="dismiss"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
            Dismiss
          </router-link>
          <router-link
            v-if="deal.thumbnail && (isAdmin || deal.authored)"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="removeCoverImage"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd" />
            </svg>
            Remove URL image
          </router-link>
          <router-link
            v-if="(isAdmin || deal.authored) && duplicatesChecked && filteredDuplicateDeals.length > 0"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="viewDuplicates"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
              <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
            </svg>
            Review duplicates
          </router-link>
          <router-link
            v-if="deal.authored"
            to=""
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            @click.native="archive"
          >
            <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"/>
              <path fill-rule="evenodd" d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" clip-rule="evenodd"/>
            </svg>
            Archive
          </router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AddDealParticipant from "../views/onboarding/deal/add-deal-participant";
import DuplicateDealCheck from "../views/onboarding/deal/duplicate-deal-check.vue";
import PaymentConnectedAccountGateway from "./payment-connected-account-gateway";
import PurchaseableForm from "./purchaseables/purchaseable-form";
import api from "../api";

export default {
  props: ["deal"],
  data() {
    return {
      menuOpen: false,
      potentialDuplicateDeals: [],
      duplicatesChecked: false
    };
  },
  computed: {
    ...mapState(["currentUser", "modal", "route"]),
    ...mapGetters(["isAdmin"]),
    hasMarketingList() {
      return (
        this.deal.dealMarketingId &&
        this.deal.marketingReadAuthorized &&
        this.deal.source === "direct"
      );
    },
    filteredDuplicateDeals() {
      return _.differenceBy(this.potentialDuplicateDeals, [this.deal], "token");
    }
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (this.$refs.dealMenu && !this.$refs.dealMenu.contains(evt.target)) {
        this.menuOpen = false;
      }
    });

    this.duplicateCheck();
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    duplicateCheck() {
      api.post(`duplicate_deals`, { filters: this.deal }).then(json => {
        this.potentialDuplicateDeals = json.data;
        this.duplicatesChecked = true;
      });
    },
    viewDuplicates() {
      this.$store.commit("openModal", {
        component: DuplicateDealCheck,
        props: {
          deal: this.deal,
          localDeals: this.potentialDuplicateDeals
        }
      });
    },
    share() {
      this.$copyText(`https://towerhunt.com/open_deal/${this.deal.token}`).then(
        () => this.$store.dispatch("flash", `Copied deal link to clipboard`),
        () => this.$store.dispatch("flash", "Failed to copy")
      );

      if (!!analytics && !this.adminMode) {
        analytics.track(`Copied deal link`, { yearWeek: this.$store.state.yearWeek });
      }
    },
    purchaseableIntent() {
      let dealCardModal = false;

      if (this.modal) {
        dealCardModal = _.cloneDeep(this.modal); // obtains the current component
        dealCardModal.props.post = this.deal; // allows changes to flow through
      }

      this.$store.commit("openModal", {
        component: PaymentConnectedAccountGateway,
        props: {
          value: {
            component: PurchaseableForm,
            props: {
              contentType: "deal",
              contentToken: this.deal.token,
              contentName: this.deal.name,
              returnUrl: `https://towerhunt.com/open_deal/${this.deal.token}`
            },
            afterClose: dealCardModal
          }
        }
      });
    },
    removeCoverImage() {
      this.$store.dispatch("removeURLThumbnail", {
        contentType: "deal",
        contentToken: this.deal.token
      });
      this.menuOpen = false;
    },
    follow() {
      this.$store.dispatch("followDeal", this.deal.token);
      this.deal.followed = true;
    },
    unfollow() {
      this.$store.dispatch("unfollowDeal", this.deal.token);
      this.deal.followed = false;
    },
    dismiss() {
      this.$store.dispatch("declineDeal", {
        dealToken: this.deal.token,
        dealId: this.deal.id
      });
      this.$store.commit("closeModal");
    },
    archive() {
      this.$store.dispatch("archiveDeal", this.deal.id);
      this.$store.commit("closeModal");
    },
    toggleDealConfidentiality() {
      this.$store.dispatch("toggleDealConfidentiality", this.deal.id);
      this.deal.confidential = !this.deal.confidential;
    },
    toggleDealCommentability() {
      this.$store.dispatch("toggleDealCommentability", this.deal.id);
      this.deal.commentable = !this.deal.commentable;
    },
    togglePremium() {
      this.$store.dispatch("toggleDealPremium", this.deal.id);
      this.deal.premium = !this.deal.premium;
    },
    viewMarketing() {
      this.$router.push({
        path: `/deal_offerings/${this.deal.urlHandle || this.deal.token}/overview`
      });
      this.$store.commit("closeModal");
    },
    addParticipant() {
      let dealCardModal = false;

      if (this.modal) {
        dealCardModal = _.cloneDeep(this.modal); // obtains the current component
        dealCardModal.props.deal = this.deal; // allows changes to flow through
      }

      this.$store.commit("openModal", {
        component: AddDealParticipant,
        props: {
          dealId: this.deal.id
        },
        afterClose: dealCardModal
      });
      this.menuOpen = false;
    }
  }
};
</script>
