export default {
  principal: [
    {
      name: "Property Sale",
      value: "Buy Equity",
      description: "Acquisition or disposition."
    },
    {
      name: "Financing",
      value: "Originate Debt",
      description: "Providing or obtaining financing."
    },
    {
      name: "Joint Venture",
      value: "Recap Equity",
      description:
        "Building or modifying the equity capital stack. Distinct from a property sale."
    },
    {
      name: "Loan Sale",
      value: "Buy Debt",
      description: "Acquiring or selling the lender position of existing loan."
    },
    {
      name: "Lease",
      value: "Lease",
      description: "Leasing space as landlord and/or tenant."
    }
  ],
  broker: [
    {
      name: "Equity Sale",
      value: "Buy Equity",
      description: "Brokered on behalf of sellers and/or buyers."
    },
    {
      name: "Financing",
      value: "Originate Debt",
      description: "Sourcing debt on behalf of borrowers."
    },
    {
      name: "Equity Recap",
      value: "Recap Equity",
      description: "Sourcing JV equity on behalf of GPs and/or LPs."
    },
    {
      name: "Loan Sale",
      value: "Buy Debt",
      description: "Brokered on behalf of lender."
    },
    {
      name: "Lease",
      value: "Lease",
      description: "Brokered on behalf of landlord and/or tenant."
    }
  ],
  observer: [
    { name: "Property Sale", value: "Buy Equity" },
    { name: "Financing", value: "Originate Debt" },
    { name: "Joint Venture", value: "Recap Equity" },
    { name: "Loan Sale", value: "Buy Debt" },
    { name: "Lease", value: "Lease" }
  ]
};
