<template>
  <div></div>
</template>

<script>
import api from "../api";

export default {
  props: ["accountId"],
  mounted() {
    api.get(`shared_payout_accounts/${this.accountId}`).then(json => {
      if (json.data) {
        window.location.href = json.data;
      } else {
        this.$router.push({ path: "/home/settings" });
      }
    });
  }
};
</script>
