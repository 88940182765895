<template>
  <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
    <router-link
      to=""
      class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
      @click.native="allNotifications"
    >
      <svg
        class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fill-rule="evenodd"
          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
          clip-rule="evenodd"
        />
      </svg>
      All types
    </router-link>
    <router-link
      to=""
      class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
      @click.native="noNotifications"
    >
      <svg
        class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      None
    </router-link>
    <div class="flex items-start px-4 py-2">
      <div class="absolute flex items-center h-5">
        <input
          v-model="marketCommentaryConsent"
          @change="saveNotification"
          id="market-commentary"
          type="checkbox"
          class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600"
        />
      </div>
      <div class="pl-7 text-sm leading-5">
        <label for="market-commentary" class="text-gray-700">Market discussion</label>
      </div>
    </div>
    <div class="flex items-start px-4 py-2">
      <div class="absolute flex items-center h-5">
        <input
          v-model="helpRequestConsent"
          @change="saveNotification"
          id="help-request"
          type="checkbox"
          class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600"
        />
      </div>
      <div class="pl-7 text-sm leading-5">
        <label for="help-request" class="text-gray-700">Bounty</label>
      </div>
    </div>
    <div class="flex items-start px-4 py-2">
      <div class="absolute flex items-center h-5">
        <input
          v-model="jobPostingConsent"
          @change="saveNotification"
          id="job-posting"
          type="checkbox"
          class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600"
        />
      </div>
      <div class="pl-7 text-sm leading-5">
        <label for="job-posting" class="text-gray-700">Jobs</label>
      </div>
    </div>
    <div class="flex items-start px-4 py-2">
      <div class="absolute flex items-center h-5">
        <input
          v-model="industryEventConsent"
          @change="saveNotification"
          id="events"
          type="checkbox"
          class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600"
        />
      </div>
      <div class="pl-7 text-sm leading-5">
        <label for="events" class="text-gray-700">Industry events</label>
      </div>
    </div>
    <div class="flex items-start px-4 py-2">
      <div class="absolute flex items-center h-5">
        <input
          v-model="developmentNewsConsent"
          @change="saveNotification"
          id="development-news"
          type="checkbox"
          class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600"
        />
      </div>
      <div class="pl-7 text-sm leading-5">
        <label for="development-news" class="text-gray-700"
          >Development project news</label
        >
      </div>
    </div>
    <div class="flex items-start px-4 py-2">
      <div class="absolute flex items-center h-5">
        <input
          v-model="infrastructureNewsConsent"
          @change="saveNotification"
          id="infrastructure"
          type="checkbox"
          class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600"
        />
      </div>
      <div class="pl-7 text-sm leading-5">
        <label for="infrastructure" class="text-gray-700"
          >Infrastructure project news</label
        >
      </div>
    </div>
    <div class="flex items-start px-4 py-2">
      <div class="absolute flex items-center h-5">
        <input
          v-model="tenantInMarketConsent"
          @change="saveNotification"
          id="tenant-in-market"
          type="checkbox"
          class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600"
        />
      </div>
      <div class="pl-7 text-sm leading-5">
        <label for="tenant-in-market" class="text-gray-700"
          >Tenants in the market</label
        >
      </div>
    </div>
    <div class="flex items-start px-4 py-2">
      <div class="absolute flex items-center h-5">
        <input
          v-model="marketReportConsent"
          @change="saveNotification"
          id="market-report"
          type="checkbox"
          class="focus:ring-indigo-500 rounded h-4 w-4 text-indigo-600"
        />
      </div>
      <div class="pl-7 text-sm leading-5">
        <label for="market-report" class="text-gray-700"
          >Market reports</label
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["target"],
  data() {
    return {
      jobPostingConsent: false,
      industryEventConsent: false,
      developmentNewsConsent: false,
      infrastructureNewsConsent: false,
      tenantInMarketConsent: false,
      marketCommentaryConsent: false,
      marketReportConsent: false,
      helpRequestConsent: false
    };
  },
  computed: {
    localNotifications() {
      return [
        this.jobPostingConsent,
        this.industryEventConsent,
        this.developmentNewsConsent,
        this.infrastructureNewsConsent,
        this.tenantInMarketConsent,
        this.marketCommentaryConsent,
        this.marketReportConsent,
        this.helpRequestConsent
      ];
    },
    marketIntelConsent() {
      return this.localNotifications.some(n => n);
    },
    notifications() {
      return _.get(this.target, "notifications", null);
    },
    allSelected() {
      return this.localNotifications.every(n => n);
    }
  },
  watch: {
    notifications: {
      handler: function() {
        this.mountNotification();
      },
      deep: true
    }
  },
  mounted() {
    if (this.target.notifications) {
      this.mountNotification();
    }
  },
  methods: {
    allNotifications() {
      this.jobPostingConsent = true;
      this.industryEventConsent = true;
      this.developmentNewsConsent = true;
      this.infrastructureNewsConsent = true;
      this.tenantInMarketConsent = true;
      this.marketCommentaryConsent = true;
      this.marketReportConsent = true;
      this.helpRequestConsent = true;
      this.saveNotification();
    },
    noNotifications() {
      this.jobPostingConsent = false;
      this.industryEventConsent = false;
      this.developmentNewsConsent = false;
      this.infrastructureNewsConsent = false;
      this.tenantInMarketConsent = false;
      this.marketCommentaryConsent = false;
      this.marketReportConsent = false;
      this.helpRequestConsent = false;
      this.saveNotification();
    },
    mountNotification() {
      if (this.target.notifications) {
        const {
          marketIntelConsent,
          jobPostingConsent,
          industryEventConsent,
          developmentNewsConsent,
          infrastructureNewsConsent,
          tenantInMarketConsent,
          marketCommentaryConsent,
          marketReportConsent,
          helpRequestConsent
        } = this.target.notifications;

        this.jobPostingConsent = marketIntelConsent ? jobPostingConsent : false;
        this.industryEventConsent = marketIntelConsent
          ? industryEventConsent
          : false;
        this.developmentNewsConsent = marketIntelConsent
          ? developmentNewsConsent
          : false;
        this.infrastructureNewsConsent = marketIntelConsent
          ? infrastructureNewsConsent
          : false;
        this.tenantInMarketConsent = marketIntelConsent
          ? tenantInMarketConsent
          : false;
        this.marketCommentaryConsent = marketIntelConsent
          ? marketCommentaryConsent
          : false;
        this.marketReportConsent = marketIntelConsent
          ? marketReportConsent
          : false;
        this.helpRequestConsent = marketIntelConsent
          ? helpRequestConsent
          : false;
      } else {
        this.jobPostingConsent = false;
        this.industryEventConsent = false;
        this.developmentNewsConsent = false;
        this.infrastructureNewsConsent = false;
        this.tenantInMarketConsent = false;
        this.marketCommentaryConsent = false;
        this.marketReportConsent = false;
        this.helpRequestConsent = false;
      }
    },
    saveNotification() {
      const {
        marketIntelConsent,
        jobPostingConsent,
        industryEventConsent,
        developmentNewsConsent,
        infrastructureNewsConsent,
        tenantInMarketConsent,
        marketCommentaryConsent,
        marketReportConsent,
        helpRequestConsent
      } = this;
      const newInfo = {
        marketIntelConsent,
        jobPostingConsent,
        industryEventConsent,
        developmentNewsConsent,
        infrastructureNewsConsent,
        tenantInMarketConsent,
        marketCommentaryConsent,
        marketReportConsent,
        helpRequestConsent
      };

      this.$store.dispatch("updateTargetNotifications", newInfo);
    }
  }
};
</script>
