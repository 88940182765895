<template>
  <div
    ref="targetIntelNotificationsMenu"
    class="relative inline-block text-left"
    style="z-index: 9997;"
    @keydown.escape="menuOpen = false"
  >
    <div>
      <span class="rounded-md shadow-sm">
        <button
          @click="toggleMenu"
          type="button"
          class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800"
        >
          Email alerts
          <svg
            class="-mr-1 ml-2 h-5 w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </span>
    </div>
    <transition
      enter-active-class="duration-150 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div
        v-show="menuOpen"
        class="origin-top-right absolute left-0 sm:right-0 sm:left-auto mt-2 w-64 rounded-md shadow-lg"
      >
        <target-intel-notifications-menu-choices :target="target" />
      </div>
    </transition>
  </div>
</template>

<script>
import TargetIntelNotificationsMenuChoices from "./target-intel-notifications-menu-choices";

export default {
  components: { TargetIntelNotificationsMenuChoices },
  props: ["target"],
  data() {
    return {
      menuOpen: false
    };
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.targetIntelNotificationsMenu &&
        !this.$refs.targetIntelNotificationsMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    }
  }
};
</script>
