<template>
  <li class="flex items-start lg:col-span-1">
    <div v-if="displayValue" class="flex flex-shrink-0 items-center text-sm font-semibold text-gray-900">
      <span class="h-5 text-center" :class="creditData > 0 ? 'text-gray-900' : 'text-gray-400'">{{ creditData | formattedNumber }}</span>
      <div v-if="lastWeekDelta && lastWeekDelta > 0 && creditData > 0" class="ml-1 flex items-baseline text-xs leading-4 font-semibold text-green-600">
        <svg class="self-center flex-shrink-0 h-4 w-4 text-green-500" fill="currentColor" viewBox="0 0 20 20">
          <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
        </svg>
        <span class="sr-only">
          Increased by
        </span>
        {{ lastWeekDelta | formattedNumber }}
      </div>
      <div v-else-if="lastWeekDelta === 0" class="ml-1 flex items-baseline text-xs leading-4 font-semibold text-gray-500">
        <svg class="self-center flex-shrink-0 h-4 w-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
          <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
        </svg>
        <span class="sr-only">
          Unchanged
        </span>
        0
      </div>
    </div>
    <div v-else class="flex-shrink-0">
      <svg v-if="creditData > 0" class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
      </svg>
      <svg v-else class="h-5 w-5 text-gray-400" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
        <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </div>
    <p class="ml-3 text-sm leading-5 text-gray-700">
      <router-link v-if="routerPath" :to="routerPath" class="font-medium text-gray-500 underline">{{ description }}</router-link>
      <a v-else-if="clickAction" @click.prevent="action" href="" class="font-medium text-gray-500 underline">
        {{ description }}
      </a>
      <template v-else>{{ description }}</template>
      <span class="ml-1 text-xs text-gray-400">(+{{ value }}<template v-if="unit">/{{ unit }}</template>)</span>
    </p>
  </li>
</template>

<script>
import EmailBatchUpload from "../../views/offered-deal/email-batch-upload";

export default {
  props: [
    "displayValue",
    "lastWeekDelta",
    "creditData",
    "description",
    "value",
    "unit",
    "routerPath",
    "clickAction"
  ],
  methods: {
    action() {
      switch (this.clickAction) {
        case "inviteUsers":
          this.$store.commit("openModal", {
            component: EmailBatchUpload,
            props: {
              action: "createTowerHuntInvitation"
            }
          });
          break;
        default:
          return;
      }
    }
  }
};
</script>
