<template>
  <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow">
    <div class="flex-1 flex flex-col p-8">
      <h3 class="mb-6 text-gray-900 text-sm leading-5 font-medium">Would you use this person again?</h3>
      <h3 class="mb-2 text-2xl leading-7 font-bold" :class="rating ? 'text-gray-900' : 'text-gray-200'">{{ ratingLabel }}</h3>
      <span class="mx-auto relative z-0 inline-flex shadow-sm">
        <button @click="reject" type="button" v-tooltip="'No'" :class="thumbColor('rejected', 'red-500')" class="relative inline-flex flex-1 items-center justify-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium hover:text-red-400 focus:z-10 focus:outline-none focus:border-gray-300 focus:ring-red active:bg-red-100 active:text-red-500" aria-label="Reject">
          <!-- Heroicon name: thumb-down -->
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z" />
          </svg>
        </button>
        <button @click="approve" type="button" v-tooltip="'Yes'" :class="thumbColor('approved', 'green-300')" class="-ml-px relative inline-flex flex-1 items-center justify-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium hover:text-green-200 focus:z-10 focus:outline-none focus:border-green-300 focus:ring-green active:bg-green-100 active:text-green-300" aria-label="Approve">
          <!-- Heroicon name: thumb-up -->
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
          </svg>
        </button>
      </span>
      <h3 class="mt-6 text-gray-500 text-sm leading-5">All feedback is anonymous.</h3>
    </div>
  </li>
</template>

<script>
import { mapState } from "vuex";
import api from "../../../api";

export default {
  props: ["bid"],
  data() {
    return {
      rating: null
    };
  },
  computed: {
    ...mapState(["adminMode"]),
    ratingLabel() {
      if (this.rating) {
        return this.rating === "rejected" ? "No" : "Yes";
      } else {
        return "Unrated";
      }
    }
  },
  mounted() {
    if (this.bid.rating) {
      this.rating = this.bid.rating;
    }
  },
  methods: {
    thumbColor(rating, color) {
      const unrated = !this.rating;
      const selected = this.rating === rating;

      if (unrated || selected) {
        return `text-${color}`;
      } else {
        return "text-gray-200";
      }
    },
    reject() {
      this.rating = "rejected";
      this.submitFeedback();
    },
    approve() {
      this.rating = "approved";
      this.submitFeedback();
    },
    submitFeedback() {
      api
        .patch(`rate_bounty_bid_fulfillment/${this.bid.id}`, {
          rating: this.rating
        })
        .then(json => {
          if (!!analytics && !this.adminMode) {
            analytics.track("Bounty fulfillment feedback provided", {
              yearWeek: this.$store.state.yearWeek,
              rating: this.rating
            });
          }

          this.$store.dispatch("flash", "Anonymous feedback submitted!");
        });
    }
  }
};
</script>
