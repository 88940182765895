<template>
  <div v-if="propertyFollowing" class="divide-y divide-gray-200">
    <router-link :to="`/property-watchlist/${propertyFollowing.focalPropertyId || propertyFollowing.id}/`" target="_blank" v-tooltip="'Open the watchlist page in a new tab.'" class="w-full flex items-center justify-between p-3 space-x-3">
      <div class="flex-1 truncate">
        <div class="flex items-center space-x-3">
          <h3 class="text-gray-900 text-sm font-medium truncate">{{ property.customName || property.name }}</h3>
        </div>
        <div class="mt-1 text-gray-500 text-xs truncate">{{ [property] | totalSize }}</div>
      </div>
      <img class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 object-cover object-center" :src="property.coverPhoto || property.mostRecentReferenceThumbnail || 'https://source.unsplash.com/collection/277102'" alt="">
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["propertyFollowing", "property", "compact"]
};
</script>
