<template>
  <div v-if="form" class="min-h-screen bg-white flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <router-link to="/">
            <img class="h-12 w-auto" src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png" alt="Tower Hunt" />
          </router-link>
          <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
            Clone {{ previewMarket | marketName | truncate(60) }}
          </h2>
          <p class="hidden lg:block mt-2 text-sm text-gray-600">
            Customize it after you join. Here's a live preview &rarr;
          </p>
        </div>

        <div class="mt-8">
          <form @submit.prevent="register" class="space-y-6">
            <div class="space-y-1">
              <label for="name" class="block text-sm font-medium text-gray-700">
                Your name
              </label>
              <div class="mt-1">
                <input v-focus v-model="form.name" id="name" name="name" type="name" autocomplete="name" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              </div>
            </div>

            <div class="space-y-1">
              <label for="email" class="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div class="mt-1">
                <input v-model="form.email" id="email" name="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              </div>
            </div>

            <div class="space-y-1">
              <label for="password" class="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div class="mt-1">
                <input v-model="form.password" id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              </div>
            </div>

            <div>
              <p class="text-sm font-medium text-gray-700">
                Industry role<template v-if="form.role">: {{ form.role.name }}</template>
              </p>

              <div class="mt-1 grid grid-cols-3 gap-3">
                <div v-for="role in roles" :key="role.value">
                  <a href="" @click.prevent="form.role = role" :class="form.role && form.role.value === role.value ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-white text-gray-500 hover:bg-gray-50'" class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium">
                    <span class="sr-only">{{ role.name }}</span>
                    <i class="w-5 h-5 flex justify-center items-center" :class="role.icon" />
                  </a>
                </div>
              </div>

              <p v-if="form.role" class="mt-2 text-sm text-gray-500" id="role-description">{{ form.role.description }}</p>
            </div>

            <span class="block text-sm text-gray-900">
              By clicking Join, you agree to the <router-link to="/legal" target="_blank" class="font-medium text-indigo-600 hover:text-indigo-500">Terms of Service</router-link>.
            </span>

            <div>
              <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Join
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <strategic-map v-if="previewMarket" :market="previewMarket" :embedded="true" :date-override="true" />
    </div>
  </div>
</template>

<script>
import StrategicMap from "../components/maps/strategic-map";
import User from "../user";
import api from "../api";
import industryRoles from "../industry-roles";

export default {
  components: { StrategicMap },
  props: ["targetToken"],
  data() {
    return {
      previewMarket: null,
      form: null,
      roles: industryRoles
    };
  },
  created() {
    this.$store.commit("isLoading");

    if (this.targetToken) {
      api.get(`open/${this.targetToken}`).then(
        json => {
          this.previewMarket = json.data;
          this.form = {
            name: null,
            email: null,
            password: null,
            role: industryRoles[0]
          };
          this.$store.commit("doneLoading");
        },
        failure => {
          this.$store.dispatch("flash", "Invalid sharing link");
          this.$store.commit("doneLoading");
          this.$router.push({ path: "/" });
        }
      );
    } else {
      this.$store.dispatch("flash", "Invalid sharing link");
      this.$store.commit("doneLoading");
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    register() {
      const payload = this.form;

      payload.role = this.form.role.value;
      this.$store.commit("isLoading");
      api.post(`clone_market_onboardings/${this.targetToken}`, payload).then(
        json => {
          this.$store.dispatch("flash", `Welcome to Tower Hunt!`);

          const token = json.data.id;

          this.form = null;
          localStorage.setItem("token", token);
          api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

          api.get("whoami").then(user => {
            this.$store.dispatch("signIn", new User(user.data));
            this.$store.commit("doneLoading");
            this.$router.push({ path: `/home/dashboard` });
          });
        },
        failure => {
          this.$store.dispatch("flash", {
            message: "Account already exists for this email address",
            timeout: 5000
          });
          this.$store.commit("doneLoading");
          console.log(failure);
        }
      );
    }
  }
};
</script>
