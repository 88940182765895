<template>
  <div>
    <contact-list id="connections-list" :contacts="mutualConnections" :heading="mutualConnectionsHeading" description="People you follow who also follow you. You can exchange contact info with these people." />
    <contact-list :contacts="dealHistory" :heading="dealHistoryHeading" description="Join and invite others to deals to build this list." />
    <contact-list :contacts="followedUsers" :heading="followedUsersHeading" :hide="true" />
    <contact-list :contacts="followingUsers" :heading="followingUsersHeading" :hide="true" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContactList from "../components/contact-list";
import pluralize from "pluralize";

export default {
  components: { ContactList },
  computed: {
    ...mapState(["currentUser"]),
    mutualConnections() {
      return _.sortBy(
        _.uniqBy(this.currentUser.contacts.mutualConnections, "token"),
        ["name"]
      );
    },
    mutualConnectionsHeading() {
      return pluralize("Mutual Connection", this.mutualConnections.length, true);
    },
    dealHistory() {
      return _.sortBy(
        _.uniqBy(
          this.currentUser.contacts.fellowClosedDealParticipants,
          "token"
        ),
        ["name"]
      );
    },
    dealHistoryHeading() {
      return pluralize("Person", this.dealHistory.length, true) + " I've Done Deals With";
    },
    followedUsers() {
      return _.sortBy(
        _.uniqBy(this.currentUser.contacts.followedUsers, "token"), ["name"]);
    },
    followedUsersHeading() {
      return pluralize("Person", this.followedUsers.length, true) + " I Follow";
    },
    followingUsers() {
      return _.sortBy(
        _.uniqBy(this.currentUser.contacts.followingUsers, "token"),
        ["name"]
      );
    },
    followingUsersHeading() {
      return pluralize("Person", this.followingUsers.length, true) + " Following Me";
    }
  },
  mounted() {
    if (
      _.isEmpty(this.mutualConnections) &&
      _.isEmpty(this.dealHistory) &&
      _.isEmpty(this.followedUsers) &&
      _.isEmpty(this.followingUsers)
    ) {
      this.$store.dispatch("loadMyContacts");
    }
  }
};
</script>
