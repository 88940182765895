<template>
  <div class="space-y-1">
    <div v-if="approval" class="flex items-center text-sm leading-5 text-gray-500">
      <!-- Heroicon name: emoji-happy -->
      <svg class="flex-shrink-0 mr-1.5 h-5 w-5" :class="approval ? 'text-green-400' : 'text-gray-400'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z" clip-rule="evenodd" />
      </svg>
      <template>{{ approval }}% would use again</template>
    </div>
    <div class="flex items-center text-sm leading-5 text-gray-500">
      <!-- Heroicon name: check-circle -->
      <svg class="flex-shrink-0 mr-1.5 h-5 w-5" :class="fulfillments > 0 ? 'text-green-400' : 'text-gray-400'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
      </svg>
      {{ fulfillmentsLabel }} fulfilled
    </div>
    <div class="flex items-center text-sm leading-5 text-gray-500">
      <!-- Heroicon name: light-bulb -->
      <svg class="flex-shrink-0 mr-1.5 h-5 w-5" :class="bidderReputation.credits > 0 ? 'text-green-400' : 'text-gray-400'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
      </svg>
      <span v-tooltip="'Earned by being trusted and relevant on Tower Hunt.'">Reputation: ~{{ credits }}</span>
    </div>
    <div class="flex items-center text-sm leading-5 text-gray-500">
      <!-- Heroicon name: user-group -->
      <svg class="flex-shrink-0 mr-1.5 h-5 w-5" :class="connections > 0 ? 'text-green-400' : 'text-gray-400'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
      </svg>
      <span v-tooltip="'People who are followed and who also follow back.'">{{ connectionsLabel }}</span>
    </div>
  </div>
</template>

<script>
import { currencyAmount } from "../../../formatted-dollar-amount";
import { mapState } from "vuex";
import pluralize from "pluralize";

export default {
  props: ["bid"],
  computed: {
    ...mapState(["currentUser"]),
    roundingPrecision() {
      const credits = this.bidderReputation.credits;

      if (credits <= 100) {
        return -1;
      } else if (credits <= 1000) {
        return -2;
      } else if (credits <= 10000) {
        return -3;
      } else if (credits <= 100000) {
        return -4;
      } else {
        return -5;
      }
    },
    bidderReputation() {
      if (this.bid) {
        return this.bid.bidderReputation;
      } else {
        return this.currentUser.reputation;
      }
    },
    approval() {
      return this.bidderReputation.approval;
    },
    fulfillments() {
      return this.bidderReputation.fulfillments;
    },
    fulfillmentsLabel() {
      return pluralize("bounty", this.fulfillments, true);
    },
    credits() {
      return currencyAmount(_.round(this.bidderReputation.credits, this.roundingPrecision));
    },
    connections() {
      return this.bidderReputation.connections;
    },
    connectionsLabel() {
      return pluralize("connection", this.connections, true);
    }
  }
};
</script>
