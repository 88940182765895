<template>
  <div class="relative text-center bg-white flex flex-col flex-1">
    <img class="h-6 w-auto sm:h-8 absolute top-2 left-2" src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png" alt="Tower Hunt" />
    <div class="absolute top-8 sm:top-10 left-2 text-gray-200" style="font-size: 8px;">{{ cardMonthYear }}</div>
    <div class="flex flex-col flex-1 rounded-lg overflow-hidden">
      <div class="bg-white px-6 py-10">
        <div>
          <h3 class="text-center text-lg leading-8 font-medium text-gray-900" id="location">
            {{ card.content.region.name }}
          </h3>
          <h4 class="mt-2 text-center text-xl leading-8 font-semibold text-gray-900" id="use">
            {{ primaryUseAlias }} &middot; {{ snapshotData.bracketedSize }}
          </h4>
        </div>
      </div>
      <cap-rate-box-plot :cap-rates="capRates" :snapshot-date="snapshotDate" />
    </div>
    <div class="w-full flex">
      <button @click="flip" class="relative -mr-px w-0 flex-1 rounded-b-lg inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border-transparent bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
        <!-- Heroicon name: cube -->
        <svg class="w-5 h-5 text-white" viewBox="0 0 20 20" fill="currentColor">
          <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
        </svg>
        <span class="ml-3 text-white">View asset info</span>
      </button>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import CapRateBoxPlot from "../gauge/cap-rate-box-plot";
import useTypes from "../../../use-types";

export default {
  components: { CapRateBoxPlot },
  props: ["card"],
  computed: {
    snapshotData() {
      return this.card.content.propertySnapshot;
    },
    primaryUseAlias() {
      return _.find(useTypes, { value: this.snapshotData.primaryUse }).name;
    },
    capRates() {
      return this.card.content.capRates;
    },
    snapshotDate() {
      return this.card.content.propertySnapshot.lastUpdated;
    },
    cardMonthYear() {
      return moment(this.snapshotData.lastUpdated).format("MMMYYYY");
    }
  },
  methods: {
    flip() {
      this.$emit("flip");
    }
  }
};
</script>
