<template>
  <section aria-labelledby="companies">
    <div class="pb-5 border-b border-gray-200">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Involved Companies &amp; Contacts
      </h3>
      <p class="mt-2 max-w-4xl text-sm text-gray-500">List companies that are involved with this {{ contentType }}, then add contacts to companies.</p>
    </div>

    <div class="space-y-6">
      <involved-company v-for="safezoneField in companies" :key="safezoneField.fieldContent.id" :content="content" :content-type="contentType" :safezone="safezone" :involved-company="safezoneField.fieldContent" @completed="fetchInvolvedCompanies" />

      <involved-company v-if="creatingCompany" :content="content" :content-type="contentType" :safezone="safezone" :involved-company="null" @completed="companyCreated" @cancel="creatingCompany = false" />

      <button v-else @click="creatingCompany = true" type="button" class="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none" viewBox="0 0 24 24" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
        <span class="mt-2 block text-sm font-medium text-gray-900">
          Add a company
        </span>
      </button>
    </div>
  </section>
</template>

<script>
import InvolvedCompany from "./involved-company.vue";
import api from "../../../api";

export default {
  components: { InvolvedCompany },
  props: ["content", "contentType", "safezone"],
  data() {
    return {
      companies: [],
      creatingCompany: false
    };
  },
  mounted() {
    this.fetchInvolvedCompanies();
  },
  methods: {
    companyCreated() {
      this.creatingCompany = false;
      this.fetchInvolvedCompanies();
    },
    fetchInvolvedCompanies() {
      api
        .get(
          `safezone_data_fields/${this.contentType}/${this.content.token ||
            this.content.id}?field_name=CompanyInvolvement`
        )
        .then(json => {
          this.companies = json.data;
        });
    }
  }
};
</script>
