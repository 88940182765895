import StrategicMapLegend from "./components/maps/strategic-map-legend";
import Vue from "vue";
/* global L */

const Legend = Vue.extend(StrategicMapLegend);

export default function strategicMapLegend({ router, store }) {
  L.Control.StrategicMapLegend = L.Control.extend({
    options: {
      position: "bottomleft"
    },

    onAdd: function(map) {
      const legendVueEl = new Legend({
        store,
        router,
        propsData: {
          map
        }
      }).$mount().$el;

      return legendVueEl;
    }
  });

  L.control.strategicMapLegend = function(options) {
    return new L.Control.StrategicMapLegend(options);
  };
}
