<template>
  <div class="mx-8 mb-8 flex flex-col">
    <heading>Choose an icon</heading>
    <div class="pt-4 flex justify-center flex-wrap">
      <div class="py-2 px-2 flex-shrink-0" v-for="icon in icons" :key="icon.name">
        <button
          @click="select(icon)"
          type="button"
          class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white hover:bg-indigo-400 focus:outline-none focus:ring"
        >
          <svg
            class="h-6 w-6"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
            v-html="pathsForIcon(icon)"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../components/onboarding/heading";
import icons from "../../heroicons-outline";

export default {
  components: { Heading },
  props: ["talkingPoint", "itemType", "order"],
  data() {
    return {
      icons: icons
    };
  },
  computed: {
    ...mapState(["activeDeal", "activeDealMarketing"])
  },
  methods: {
    pathsForIcon(icon) {
      const elements = icon.paths.map(path => {
        return `<path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="${path}"
            />`;
      });

      return elements.join("");
    },
    select(icon) {
      if (this.talkingPoint) {
        this.$store.commit("setDealMarketingItemIcon", { item: this.talkingPoint, iconName: icon.name });

        if (this.talkingPoint.id) {
          this.$store.dispatch("updateDealMarketingOrderedItem", { item: this.talkingPoint });
        } else {
          this.$store.dispatch("createDealMarketingOrderedItem", { item: this.talkingPoint });
        }
      } else {
        const newItem = {
          order: this.order,
          type: this.itemType,
          iconName: icon.name,
          label: null,
          content: null
        };

        this.$store.commit("addDealMarketingOrderedItem", { item: newItem });
        this.$store.dispatch("createDealMarketingOrderedItem", { item: newItem });
      }
      this.$store.commit("closeModal");
    }
  }
};
</script>
