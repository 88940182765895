<template>
  <dl v-if="activeTarget" class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
    <div class="sm:col-span-1">
      <dt class="text-sm leading-5 font-medium text-gray-500">
        Live Deal Emails
      </dt>
      <dd class="mt-1 text-sm leading-5 text-gray-900">
        <target-live-deal-consent v-if="activeTarget.authored || activeTarget.followed" :target="activeTarget" />
      </dd>
    </div>
    <div class="sm:col-span-1">
      <dt class="text-sm leading-5 font-medium text-gray-500">
        Closed Deal Emails
      </dt>
      <dd class="mt-1 text-sm leading-5 text-gray-900">
        <closed-deal-notifications-menu-choices v-if="activeTarget.authored || activeTarget.followed" :source-record="activeTarget" type="target" />
      </dd>
    </div>
    <div class="sm:col-span-1">
      <dt class="text-sm leading-5 font-medium text-gray-500">
        Market Intel Emails
      </dt>
      <dd class="mt-1 text-sm leading-5 text-gray-900">
        <target-intel-notifications-menu-choices v-if="activeTarget.authored || activeTarget.followed" :target="activeTarget" />
      </dd>
    </div>
  </dl>
</template>

<script>
import { mapState } from "vuex";
import ClosedDealNotificationsMenuChoices from "../closed-deal-notifications-menu-choices";
import TargetIntelNotificationsMenuChoices from "../target-intel-notifications-menu-choices";
import TargetLiveDealConsent from "../notification-consents/target-live-deal";

export default {
  components: {
    ClosedDealNotificationsMenuChoices,
    TargetIntelNotificationsMenuChoices,
    TargetLiveDealConsent
  },
  computed: {
    ...mapState(["activeTarget"])
  }
};
</script>
