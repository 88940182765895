import _ from "lodash";

// See if two line segments intersect. This uses the
// vector cross product approach described below:
// http://stackoverflow.com/a/565282/786339

export function validPolygon(polygon) {
  if (polygon) {
    const coordinates = polygon
      .getPath()
      .getArray()
      .map(c => {
        return { x: c.lat(), y: c.lng() };
      });

    switch (coordinates.length) {
      case 0:
      case 1:
      case 2:
        return false;
      default:
        return testLines(coordinates);
    }
  } else {
    return false;
  }
}

function testLines(coordinates) {
  const lines = linesFrom(coordinates, coordinates[0]);

  return lines.every(line => {
    const tests = lines.map(other => {
      const intersects = lineSegmentsIntersect({ line1start: line.start, line1end: line.end, line2start: other.start, line2end: other.end });

      return !intersects;
    });

    return tests.every(t => t);
  });
}

function linesFrom(coordinates, firstCoordinate) {
  switch (coordinates.length) {
    case 0:
      return [];
    case 1:
      return [{ start: coordinates[0], end: firstCoordinate }].concat(linesFrom(_.tail(coordinates), firstCoordinate));
    default:
      return [{ start: coordinates[0], end: coordinates[1] }].concat(linesFrom(_.tail(coordinates), firstCoordinate));
  }
}

function lineSegmentsIntersect({ line1start, line1end, line2start, line2end }) {
  const r = subtractPoints(line1end, line1start);
  const s = subtractPoints(line2end, line2start);

  const uNumerator = crossProduct(subtractPoints(line2start, line1start), r);
  const denominator = crossProduct(r, s);
  const linesAreParallel = denominator === 0;

  if (linesAreParallel) {
    return false;
  }

  if (line1start.x === line2end.x && line1start.y === line2end.y) {
    return false;
  }

  const u = uNumerator / denominator;
  const t = crossProduct(subtractPoints(line2start, line1start), s) / denominator;

  return (t >= 0) && (t <= 1) && (u > 0) && (u <= 1);
}

function crossProduct(point1, point2) {
  return point1.x * point2.y - point1.y * point2.x;
}

function subtractPoints(point1, point2) {
  return {
    x: point1.x - point2.x,
    y: point1.y - point2.y
  };
}
