<template>
  <div class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
    <dt class="pr-4 text-sm font-medium text-gray-600">{{ feature.featureName }}</dt>
    <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
      <template v-if="plan.comparisonType === 'text'">
        <span class="text-gray-900 text-sm font-medium">{{ plan.comparisonValue }}</span>
      </template>
      <template v-else>
        <svg :class="plan.name === productTier ? 'text-indigo-600' : 'text-gray-400'" class="mx-auto h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path v-if="plan.comparisonValue === 'Yes'" fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
          <path v-else fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
        <span class="sr-only">{{ plan.comparisonValue }}</span>
      </template>
    </dd>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["feature", "tier"],
  computed: {
    ...mapState(["currentUser"]),
    productTier() {
      return this.currentUser.productTier;
    },
    plan() {
      return _.find(this.feature.planData, { name: this.tier });
    }
  }
};
</script>
