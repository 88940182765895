<template>
  <div class="px-4 mt-6 sm:px-6 lg:px-8">
    <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Pinned Content</h2>
    <ul class="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 xl:grid-cols-3 mt-3">
      <purchaseable-preview v-for="purchaseable in pinned" :key="purchaseable.id" class="col-span-1" :purchaseable="purchaseable" />
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PurchaseablePreview from "../../cards/purchaseable-preview.vue";
import api from "../../../api";

export default {
  components: { PurchaseablePreview },
  data() {
    return {
      pinned: []
    };
  },
  computed: {
    ...mapState(["refetchPurchaseables"]),
  },
  watch: {
    refetchPurchaseables: {
      handler() {
        if (this.refetchPurchaseables) {
          this.fetchPurchaseables();
        }
      }
    }
  },
  mounted() {
    this.fetchPurchaseables();
  },
  methods: {
    fetchPurchaseables() {
      api.get(`purchaseables?pinned=true`).then(json => {
        this.pinned = json.data;
        this.$store.commit("setRefetchPurchaseables", false);
      });
    }
  }
};
</script>
