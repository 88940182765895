<template>
  <div v-if="activeDeal.dealTeam">
    <marketing-stats v-if="activeDeal.state !== 'draft'" :metrics="metrics" @need-follow-up="needFollowUp" @interested="interested" />

    <div v-if="dealState !== 'closed'" class="mb-4 bg-white shadow sm:rounded-lg divide-y divide-gray-200">
      <div class="px-4 pt-5 sm:px-6 sm:pt-6">
        <div>
          <h2 class="text-lg leading-6 font-medium text-gray-900">Distribution</h2>
          <p class="mt-1 text-sm text-gray-500">
            How would you like to distribute this deal?
          </p>
        </div>
        <ul class="mt-2 divide-y divide-gray-200">
          <li class="py-4 flex items-center justify-between">
            <div class="flex flex-col">
              <p class="text-sm font-medium text-gray-900" id="privacy-option-1-label">
                Public Website
              </p>
              <p class="text-sm text-gray-500" id="privacy-option-1-description">
                Anyone on the internet can view and register for the deal while it is live.
              </p>
            </div>
            <button @click="toggleDealMarketingVisibility" type="button" :class="dealMarketingVisibility ? 'bg-indigo-600' : 'bg-gray-200'" class="ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200" :aria-pressed="dealMarketingVisibility" aria-labelledby="public-website-label" aria-describedby="public-website-description">
              <span class="sr-only">Use setting</span>
              <span aria-hidden="true" :class="dealMarketingVisibility ? 'translate-x-5' : 'translate-x-0'" class="inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
            </button>
          </li>
          <li v-if="dealMarketingVisibility" class="py-4">
            <div class="flex flex-col items-start sm:flex-row sm:justify-between sm:items-center">
              <label for="url_handle" class="flex flex-col">
                <p class="text-sm font-medium text-gray-900" id="custom-url">
                  Custom URL
                </p>
                <p class="text-sm text-gray-500" id="custom-url">
                  Makes the deal url easier to read and remember.
                </p>
              </label>
              <div class="mt-2 sm:mt-0">
                <div class="max-w-2xl flex rounded-md shadow-sm">
                  <span class="text-xs inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                    towerhunt.com/deal_offerings/
                  </span>
                  <input v-model.trim="dealMarketingUrlHandle" @input="removeUrlHandleSpaces" type="text" name="url_handle" id="url_handle" autocomplete="new-password" autocorrect="off" spellcheck="false" class="text-xs flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300">
                </div>
              </div>
            </div>
            <div class="pt-2">
              <div class="flex items-center justify-end">
                <p v-if="!validHandle" class="text-sm text-red-600" id="handle-error">Must be unique.</p>
                <button v-else-if="canClearHandle" @click="clearHandle" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Clear
                </button>
                <button v-if="canSaveHandle" @click="saveHandle" type="button" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Save
                </button>
              </div>
            </div>
          </li>
          <li v-if="teaserSendable" class="py-4 flex items-center justify-between">
            <div class="flex flex-col">
              <p class="text-sm font-medium text-gray-900" id="privacy-option-2-label">
                Send Teaser Emails
              </p>
              <p class="text-sm text-gray-500" id="privacy-option-2-description">
                Automatically email marketing list members about the deal when it launches.
              </p>
            </div>
            <button @click="toggleDealMarketingSendTeaserEmails" type="button" :class="activeDealMarketing.sendTeaserEmails ? 'bg-indigo-600' : 'bg-gray-200'" class="ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200" :aria-pressed="activeDealMarketing.sendTeaserEmails" aria-labelledby="send-teaser-emails-label" aria-describedby="send-teaser-emails-description">
              <span class="sr-only">Use setting</span>
              <span aria-hidden="true" :class="activeDealMarketing.sendTeaserEmails ? 'translate-x-5' : 'translate-x-0'" class="inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="teaserSendable" class="mb-4 bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div class="ml-4 mt-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Teaser Email Settings
            </h3>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              When the deal is launched, everyone on the marketing list receives a teaser email that invites them to view the offering. Customize the from and reply-to addresses if you'd like. Send yourself a test to see how it looks.
            </p>
          </div>
          <div class="ml-4 mt-4 flex-shrink-0 flex">
            <span v-tooltip="'Change the from and reply-to fields.'" class="inline-flex rounded-md shadow-sm">
              <button @click="customizeTeaserFields" type="button" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-50 active:text-gray-800">
                <!-- Heroicon name: pencil-alt -->
                <svg class="-ml-1 mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                  <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                </svg>
                <span>
                  Customize
                </span>
              </button>
            </span>

            <span v-tooltip="'Send yourself the teaser.'" class="ml-3 inline-flex rounded-md shadow-sm">
              <button @click="testTeaser" type="button" :disabled="sendingTest" :class="{ 'cursor-not-allowed': sendingTest }" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-50 active:text-gray-800">
                <!-- Heroicon name: mail -->
                <svg class="-ml-1 mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                <span>
                  <template v-if="sendingTest">Sending test...</template>
                  <template v-else>Test</template>
                </span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="teaserSendable" class="mb-4 bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Confidentiality Agreement Settings
        </h3>
        <div class="mt-2 sm:flex sm:items-start sm:justify-between">
          <div class="text-sm text-gray-500">
            <p>
              We ask the user to digitally accept a PDF version of the CA. You can optionally make a Word version available in case someone feels a mark-up is necessary.
            </p>
          </div>
          <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
            <button @click="setConfidentialityAgreement" type="button" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-50 active:text-gray-800">
              <!-- Heroicon name: lock -->
              <svg class="-ml-1 mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
              </svg>
              <span>
                Configure CA
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <people-grid v-if="teaserSendable" :deal="activeDeal" />

    <div class="mb-24 bg-white overflow-hidden shadow sm:rounded-lg">
      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div class="ml-4 mt-2">
            <h3 class="flex text-lg leading-6 font-medium text-gray-900">
              <template v-if="interestedFiltered">Interested Prospects</template>
              <template v-else-if="followUpFiltered">Prospects Needing Follow-up</template>
              <template v-else>Marketing List</template>
            </h3>
            <p @click="clearFilter" v-if="interestedFiltered || followUpFiltered" class="mt-1 text-sm leading-5 text-indigo-600 hover:text-indigo-500 cursor-pointer">
              Clear filter
            </p>
          </div>
          <div v-if="dealState !== 'closed'" class="ml-4 mt-4 flex-shrink-0 flex">
            <template v-if="data.length > 0 || filter !== '' || processingQuery">
              <label for="search_candidate" class="sr-only">Search</label>
              <div class="flex rounded-md shadow-sm">
                <div class="relative flex-grow focus-within:z-10">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <div v-if="processingQuery" class="-ml-1 mr-2 h-5 w-6">
                      <pulse-loader :loading="processingQuery" size="4px" color="#5850ec" />
                    </div>
                    <!-- Heroicon name: search -->
                    <svg v-else class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <input v-model="filter" @keydown.escape="clearQuery" aria-label="Filter" type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:hidden border-gray-300" placeholder="Search">
                  <input v-model="filter" @keydown.escape="clearQuery" aria-label="Filter" type="text" class="hidden focus:ring-indigo-500 focus:border-indigo-500 w-full rounded-none rounded-l-md pl-10 sm:block sm:text-sm border-gray-300" placeholder="Search prospects">
                </div>
                <div ref="listMenu" class="relative inline-block">
                  <button @click="toggleMenu" type="button" class="-ml-px h-full relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                    <span>Actions</span>
                    <!-- Heroicon name: chevron-down -->
                    <svg class="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </button>

                  <transition
                    enter-active-class="duration-100 ease-out"
                    enter-class="opacity-0 scale-95"
                    enter-to-class="opacity-100 scale-100"
                    leave-active-class="duration-75 ease-in"
                    leave-class="opacity-100 scale-100"
                    leave-to-class="opacity-0 scale-95"
                  >
                    <div v-show="menuOpen" class="origin-top-right absolute left-0 sm:right-0 sm:left-auto mt-2 w-36 rounded-md shadow-lg" style="z-index: 9999;">
                      <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
                        <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                          <a href="" @click.prevent="setSort('alphabetical')" class="group flex items-center justify-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                            Name A-Z
                          </a>
                          <a href="" @click.prevent="setSort('company')" class="group flex items-center justify-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                            Company A-Z
                          </a>
                          <a href="" @click.prevent="setSort('status')" class="group flex items-center justify-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                            Status
                          </a>
                          <a href="" @click.prevent="addPeople" class="group flex items-center justify-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                            Add people
                          </a>
                          <a href="" @click.prevent="batchImport" class="group flex items-center justify-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                            Batch import
                          </a>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </template>
            <template v-else>
              <span class="inline-flex rounded-md shadow-sm">
                <button @click="addPeople" type="button" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-50 active:text-gray-800">
                  <svg class="-ml-1 mr-2 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"/>
                  </svg>
                  <span>
                    Add people
                  </span>
                </button>
              </span>

              <span class="ml-3 inline-flex rounded-md shadow-sm">
                <button @click="batchImport" type="button" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-50 active:text-gray-800">
                  <div v-if="processingEmailBatch" class="-ml-1 mr-2 h-5 w-6">
                    <pulse-loader :loading="processingEmailBatch" size="4px" color="#5850ec" />
                  </div>
                  <svg v-else class="-ml-1 mr-2 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
                  </svg>
                  <span>
                    Batch import
                  </span>
                </button>
              </span>
            </template>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="-my-2 py-2 overflow-x-auto overflow-y-visible sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div class="align-middle inline-block min-w-full border-b border-gray-200">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Person
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Company
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Page Views
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Docs
                  </th>
                  <th v-if="activeDeal.state !== 'closed'" scope="col" class="relative px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    <span class="sr-only">Menu</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white overflow-y-visible">
                <marketing-list-row
                  v-for="(person, index) in data"
                  :key="index"
                  :index="index"
                  :person="person"
                  :last-index="data.length - 1"
                  @refetch="fetchPeople"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="pagy && pagy.count > 7" class="bg-white px-4 py-3 flex items-center justify-between sm:px-6">
        <div class="hidden sm:block">
          <p class="text-sm leading-5 text-gray-700">
            Showing
            <span class="font-medium">{{ pagy.from }}</span>
            to
            <span class="font-medium">{{ pagy.to }}</span>
            of
            <span class="font-medium">{{ pagy.count }}</span>
            people
          </p>
        </div>
        <div class="flex-1 flex justify-between sm:justify-end">
          <router-link @click.native="fetchPeople(pagy.prev_url)" to="" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
            Previous
          </router-link>
          <router-link @click.native="fetchPeople(pagy.next_url)" to="" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
            Next
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="data.length === 0 && !interestedFiltered && !followUpFiltered" class="relative bg-gray-800">
      <div class="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img class="w-full h-full object-cover" src="https://source.unsplash.com/collection/2155077" alt="Marketing list intro" />
      </div>
      <div class="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div class="md:ml-auto md:w-1/2 md:pl-10">
          <div class="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
            Marketing list
          </div>
          <h2 class="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
            The command center
          </h2>
          <p class="mt-3 text-lg leading-7 text-gray-300">
            The marketing list governs access to this deal once it goes live. Add people from your Tower Hunt contacts or simply by email address. Once the deal is live, this page presents key metrics and controls to help you manage the deal through to closing. This page is only accessible to the deal team.
          </p>
          <div class="mt-8">
            <div class="inline-flex rounded-md shadow">
              <a @click.prevent="addPeople" href="" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:ring">
                Add people
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AddMarketingListMembers from "../onboarding/deal/add-deal-participant";
import ConfidentialityAgreementForm from "./confidentiality-agreement-form";
import DealMarketingTeaserFields from "./deal-marketing-teaser-fields";
import MarketingListBatchUpload from "./email-batch-upload";
import MarketingListRow from "./marketing-list-row";
import MarketingStats from "./marketing-stats";
import PeopleGrid from "../../components/people-grid";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Swal from "sweetalert2";
import api from "../../api";
import reblastTeaserOptions from "../../sweet-alert-reblast-teaser-options";

let emailBatchUpdateInterval;

export default {
  components: { PulseLoader, MarketingListRow, MarketingStats, PeopleGrid },
  data() {
    return {
      menuOpen: false,
      filter: "",
      sendingTest: false,
      sort: "alphabetical",
      followUpFiltered: false,
      interestedFiltered: false,
      metrics: {
        totalPageViews: 0,
        currentDayPageViews: 0,
        interestedProspects: 0,
        totalProspects: 0,
        needFollowUp: 0
      },
      data: [],
      pagy: null,
      processingEmailBatch: false,
      pollingForBatchUpdates: false,
      processingQuery: false,
      validHandle: true,
      canClearHandle: false
    };
  },
  computed: {
    ...mapState([
      "activeDeal",
      "activeDealMarketing",
      "invitingDealParticipant"
    ]),
    teaserSendable() {
      return _.includes(["draft", "live"], this.dealState);
    },
    canSaveHandle() {
      return _.trim(this.dealMarketingUrlHandle) !== "" && this.validHandle;
    },
    dealMarketingUrlHandle: {
      get() {
        return this.activeDealMarketing.customUrlHandle;
      },
      set(handle) {
        const stripped = _.toLower(_.replace(handle, /\s/g, ""));

        this.$store.commit("setDealMarketingUrlHandle", stripped);
      }
    },
    dealMarketingVisibility() {
      switch (this.activeDealMarketing.visibility) {
        case "restricted":
        case "matching_principals":
          return false;
        case "unrestricted":
          return true;
        default:
          return true;
      }
    },
    dealToken() {
      return _.get(this.activeDeal, "token", null);
    },
    dealState() {
      return this.activeDeal.state;
    },
    draft() {
      return this.dealState === "draft";
    },
    queryParam() {
      if (_.trim(this.filter) === "") {
        return "";
      } else if (_.trim(this.filter).length > 2) {
        return `&query=${this.filter}`;
      } else {
        return "";
      }
    }
  },
  watch: {
    queryParam: {
      handler:_.debounce(function(val, oldVal) {
        if (
          this.queryParam !== "" ||
          (this.queryParam === "" && val !== oldVal)
        ) {
          this.processingQuery = true;
          this.fetchPeople();
        }
      }, 500)
    },
    dealToken: {
      handler() {
        if (this.dealToken) {
          this.fetchPeople();
        }
      }
    },
    dealState: {
      handler() {
        if (this.dealState && !this.activeDeal.dealTeam) {
          this.$store.dispatch("flash", "Invalid sharing link");
          this.$router.push({ path: "/" });
        }
      }
    },
    invitingDealParticipant: {
      handler(bool, oldBool) {
        if (!bool && oldBool) {
          setTimeout(() => {
            this.fetchPeople();
          }, 1000);
        }
      }
    },
    dealMarketingUrlHandle: {
      handler:_.debounce(function(val, oldVal) {
        if (_.trim(this.dealMarketingUrlHandle) !== "") {
          this.removeUrlHandleSpaces();
          this.checkHandle();
        }
      }, 500)
    }
  },
  mounted() {
    this.fetchPeople();

    document.title = `${this.activeDeal.name} · Marketing List | Tower Hunt`;

    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (this.$refs.listMenu && !this.$refs.listMenu.contains(evt.target)) {
        this.menuOpen = false;
      }
    });

    if (this.dealMarketingUrlHandle) {
      this.canClearHandle = true;
    }
  },
  destroyed() {
    clearInterval(emailBatchUpdateInterval);
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    removeUrlHandleSpaces() {
      const stripped = _.toLower(
        _.replace(_.trim(this.activeDealMarketing.customUrlHandle), /\s/g, "")
      );
      console.log("remove", stripped);

      this.$store.commit("setDealMarketingUrlHandle", stripped);
    },
    clearHandle() {
      api
        .delete(`deal_marketing_url_handles/${this.activeDealMarketing.id}`)
        .then(json => {
          this.dealMarketingUrlHandle = "";
          this.canClearHandle = false;
          this.validHandle = true;
          this.$store.dispatch("flash", "Custom URL cleared.");
          this.$router.push({ path: `/deal_offerings/${this.activeDeal.token}/marketing-list` });
        });
    },
    saveHandle() {
      api
        .patch(`deal_marketing_url_handles/${this.activeDealMarketing.id}`, { urlHandle: this.dealMarketingUrlHandle })
        .then(json => {
          this.$store.dispatch("flash", "Custom URL saved!");
          this.canClearHandle = true;
          this.$router.push({ path: `/deal_offerings/${this.dealMarketingUrlHandle}/marketing-list` });
        });
    },
    checkHandle() {
      api
        .post(`deal_marketing_url_handles/${this.activeDealMarketing.id}`, { urlHandle: this.dealMarketingUrlHandle })
        .then(json => {
          if (json.data.valid) {
            this.validHandle = true;
          } else {
            this.validHandle = false;
          }
        });
    },
    toggleDealMarketingVisibility() {
      const newVisibility = this.dealMarketingVisibility
        ? "restricted"
        : "unrestricted";

      this.$store.commit("setDealMarketingVisibility", newVisibility);
      this.$store.dispatch("updateDealMarketing", this.activeDealMarketing.id);
    },
    toggleDealMarketingSendTeaserEmails() {
      if (this.activeDealMarketing.sendTeaserEmails === false && this.activeDeal.state === "live") {
        Swal.fire(reblastTeaserOptions).then(result => {
          if (result.value) {
            this.$store.commit(
              "setDealMarketingSendEmailTeasers",
              !this.activeDealMarketing.sendTeaserEmails
            );
            this.$store.dispatch("updateDealMarketing", this.activeDealMarketing.id);
          }
        });
      } else {
        this.$store.commit(
          "setDealMarketingSendEmailTeasers",
          !this.activeDealMarketing.sendTeaserEmails
        );
        this.$store.dispatch("updateDealMarketing", this.activeDealMarketing.id);
      }
    },
    setSort(sort = "alphabetical") {
      this.sort = sort;
      this.processingQuery = true;
      this.fetchPeople();
    },
    addPeople() {
      this.$store.commit("openModal", {
        component: AddMarketingListMembers,
        props: {
          dealId: this.activeDeal.id,
          marketingList: true
        }
      });
      this.menuOpen = false;
    },
    batchImport() {
      this.$store.commit("openModal", {
        component: MarketingListBatchUpload,
        props: {
          action: "createDealProspectInvitationBatch"
        }
      });
      this.menuOpen = false;
    },
    checkBatchStatus() {
      api
        .get(`email_batch_status/${this.activeDealMarketing.id}`)
        .then(json => {
          this.processingEmailBatch = json.data;

          if (!this.processingEmailBatch) {
            clearInterval(emailBatchUpdateInterval);
            this.pollingForBatchUpdates = false;
            this.fetchPeople();
          }
        });
    },
    fetchPeople(url = null) {
      const cleanUrl = url ? _.drop(url, 8).join("") : url;
      let endpoint = this.endpointFor(cleanUrl);

      if (this.followUpFiltered) {
        endpoint = _.includes(endpoint, "page=")
          ? `${endpoint}&filter=follow_up&sort=${this.sort}${this.queryParam}`
          : `${endpoint}?filter=follow_up&sort=${this.sort}${this.queryParam}`;
      } else if (this.interestedFiltered) {
        endpoint = _.includes(endpoint, "page=")
          ? `${endpoint}&filter=interested&sort=${this.sort}${this.queryParam}`
          : `${endpoint}?filter=interested&sort=${this.sort}${this.queryParam}`;
      } else {
        endpoint = _.includes(endpoint, "page=")
          ? `${endpoint}&sort=${this.sort}${this.queryParam}`
          : `${endpoint}?sort=${this.sort}${this.queryParam}`;
      }

      if (endpoint) {
        api.get(endpoint).then(json => {
          const marketingList = json.data;

          this.metrics = marketingList.metrics;
          this.data = marketingList.data;
          this.pagy = marketingList.pagy;
          this.processingEmailBatch = marketingList.processingEmailBatch;
          this.processingQuery = false;
          this.menuOpen = false;

          if (this.processingEmailBatch) {
            if (!this.pollingForBatchUpdates) {
              this.pollingForBatchUpdates = true;
              var self = this;

              emailBatchUpdateInterval = setInterval(function() {
                self.checkBatchStatus();
              }, 5000);
            }
          } else {
            this.pollingForBatchUpdates = false;
          }
        });
      }
    },
    endpointFor(url = null) {
      if (url) {
        return url;
      } else if (this.activeDeal.token) {
        return `marketing_lists/${this.activeDeal.token}`;
      } else {
        return null;
      }
    },
    needFollowUp() {
      this.interestedFiltered = false;
      this.followUpFiltered = true;
      this.fetchPeople();
    },
    interested() {
      this.followUpFiltered = false;
      this.interestedFiltered = true;
      this.fetchPeople();
    },
    clearFilter() {
      this.followUpFiltered = false;
      this.interestedFiltered = false;
      this.fetchPeople();
    },
    clearQuery() {
      if (_.trim(this.filter) !== "") {
        this.processingQuery = true;
      }

      this.filter = "";
    },
    setConfidentialityAgreement() {
      this.$store.commit("openModal", {
        component: ConfidentialityAgreementForm,
        props: {}
      });
    },
    customizeTeaserFields() {
      this.$store.commit("openModal", {
        component: DealMarketingTeaserFields,
        props: {}
      });
    },
    testTeaser() {
      if (this.sendingTest) {
        return;
      } else {
        this.sendingTest = true;
        this.$store.dispatch("sendTestTeaser").then(() => {
          this.sendingTest = false;
        });
      }
    }
  }
};
</script>
