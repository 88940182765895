<template>
  <div>
    <bounty-approval v-if="bounty.approvalStatus === 'pending'" :bounty="bounty" :flat="flat" :compact="compact" />
    <bounty-bidder-actions v-else-if="isAdmin || !bounty.authored" :bounty="bounty" :flat="flat" :compact="compact" />
    <bounty-value-estimate-form v-if="isAdmin && bounty.state !== 'fulfilled'" :bounty="bounty" />
    <bounty-detail-button v-if="bounty.authored && bounty.approvalStatus === 'approved'" :bounty="bounty" :flat="flat" :compact="compact" />
    <premium-content-button v-if="bounty.premiumCount > 0" :content="bounty" :flat="flat" :compact="compact" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BountyApproval from "./my-requests/bounty-approval";
import BountyBidderActions from "./bounty-bidder-actions";
import BountyDetailButton from "./my-requests/bounty-detail-button";
import BountyValueEstimateForm from "./bounty-value-estimate-form.vue";
import PremiumContentButton from "../cards/premium-content-button";

export default {
  components: {
    BountyApproval,
    BountyBidderActions,
    BountyDetailButton,
    BountyValueEstimateForm,
    PremiumContentButton
  },
  props: ["bounty", "flat", "compact"],
  computed: {
    ...mapGetters(["isAdmin"])
  }
};
</script>
