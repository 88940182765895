<template>
  <li>
    <a href="" @click.prevent="viewDetails" class="flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6">
      <div class="flex items-center truncate space-x-3">
        <div v-if="license" v-tooltip="statusHelp" :class="`w-2.5 h-2.5 flex-shrink-0 rounded-full bg-${statusColor}`"></div>
        <p class="font-medium truncate text-sm leading-6">{{ purchaseable.name || "Name" }} <span class="truncate font-normal text-gray-500">{{ purchaseable.description || "Description" }}</span></p>
      </div>
      <!-- Heroicon name: chevron-right -->
      <svg class="ml-4 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
      </svg>
    </a>
  </li>
</template>

<script>
import { mapState } from "vuex";
import PurchaseableCard from "../../cards/purchaseable";
import api from "../../../api";
import backgroundColors from "../../../background-colors";

export default {
  props: ["purchaseable"],
  data() {
    return {
      license: null,
      backgroundColors: backgroundColors
    };
  },
  computed: {
    ...mapState(["modal"]),
    randomColor() {
      return _.sample(this.backgroundColors);
    },
    statusColor() {
      if (this.license.revokedAt) {
        return "gray-400";
      } else {
        return "green-400";
      }
    },
    statusHelp() {
      if (this.license.revokedAt) {
        return "Active";
      } else {
        return "Inactive";
      }
    }
  },
  mounted() {
    this.fetchLicense();
  },
  methods: {
    fetchLicense() {
      api.get(`purchaseable_licenses/${this.purchaseable.id}`).then(json => {
        this.license = json.data;
      });
    },
    viewDetails() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
      }

      this.$store.commit("openModal", {
        component: PurchaseableCard,
        props: {
          purchaseableId: this.purchaseable.id,
          backgroundColor: this.randomColor
        },
        afterClose: currentModal
      });
    }
  }
};
</script>
