<template>
  <div class="wrapper">
    <heading>Add a link</heading>
    <form @submit.prevent autocomplete="off" novalidate>
      <div class="input-container">
        <input
          v-model="url"
          v-focus
          @focus="focus"
          @blur="blur"
          type="text"
          autocomplete="new-password"
          autocorrect="off"
          spellcheck="false"
          placeholder=""
          tabindex="1"
          name="url"
          class="input-text black"
          :class="{ active: focused }"
        >
        <label class="input-label" :class="{ active: focused }">URL</label>
        <label class="email-icon" :class="{ active: focused }">
          <i class="fas fa-link"></i>
        </label>
      </div>

      <div v-if="url" class="mt-4 mb-2" style="width: 330px;">
        <div class="iframely-embed">
          <a data-iframely-url :href="url"></a>
        </div>
        <failed-url-preview-empty-state v-if="urlPreviewFailed" :url="url" />
      </div>

      <button @click="clickHandler" type="button" class="cta" :class="{ complete: true }" tabindex="999">
        <template v-if="dealId || postToken">Confirm privacy and edit</template>
        <template v-else>Save</template>
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FailedUrlPreviewEmptyState from "../../components/failed-url-preview-empty-state.vue";
import Heading from "../../components/onboarding/heading";
import Swal from "sweetalert2";
import anonymousPostUpdateOptions from "../../sweet-alert-always-anonymous-update-options";
import iframelyApi from "../../iframely-api";
/* global iframely */

export default {
  components: { FailedUrlPreviewEmptyState, Heading },
  props: ["dealId", "targetId", "postToken", "safezone"],
  data() {
    return {
      localUrl: null,
      focused: false,
      urlPreviewFailed: false
    };
  },
  computed: {
    ...mapState(["activeDeal", "activeTarget", "activeIntel"]),
    ...mapGetters([]),
    url: {
      get() {
        if (this.dealId) {
          return this.activeDeal.url;
        } else if (this.targetId) {
          return "";
        } else if (this.postToken) {
          return this.activeIntel.url;
        } else if (this.safezone) {
          return this.localUrl;
        } else {
          return "";
        }
      },
      set(url) {
        if (this.dealId) {
          this.$store.commit("setActiveDealUrl", url);
        } else if (this.targetId) {
          return;
        } else if (this.postToken) {
          this.$store.commit("setIntelUrl", url);
        } else if (this.safezone) {
          this.localUrl = url;
        }
      }
    }
  },
  watch: {
    url: {
      handler() {
        if (/^https?:\/\//i.test(this.url) && window.iframely) {
          setTimeout(() => {
            iframely.load();
            iframelyApi(this.url).then(data => {
              const thumbnail = _.get(data, "links.thumbnail[0].href", null) || _.get(data, "links.image[0].href", null);

              if (this.dealId) {
                this.$store.commit("setDealThumbnail", thumbnail);
              } else if (this.targetId) {
                return;
              } else if (this.postToken) {
                this.$store.commit("setIntelThumbnail", thumbnail);
              }
            });

            var self = this;

            iframely.on("cancel", function(url, parentNode) {
              self.urlPreviewFailed = true;
            });
          }, 500);
        } else {
          this.urlPreviewFailed = false;
        }
      }
    }
  },
  mounted() {
    if (/^https?:\/\//i.test(this.url) && window.iframely) {
      setTimeout(() => {
        iframely.load();

        var self = this;

        iframely.on("cancel", function(url, parentNode) {
          self.urlPreviewFailed = true;
        });
      }, 500);
    }
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    clickHandler() {
      if (this.postToken) {
        this.confirmPrivacy();
      } else {
        this.save();
      }
    },
    confirmPrivacy() {
      if (this.postToken) {
        Swal.fire(anonymousPostUpdateOptions).then(result => {
          if (result.value) {
            this.$store.dispatch("updateIntel", {
              postToken: this.postToken,
              updateTags: false
            });

            this.$store.commit("closeModal");
            this.$store.commit("clearIntel");
          }
        });
      }
    },
    save() {
      if (this.dealId) {
        this.$store.dispatch("updateDeal", {
          dealId: this.dealId,
          updateTags: false
        });
        this.$store.commit("closeModal");
        this.$store.commit("clearDeal");
        this.$store.commit("clearDealMarketing");
      } else if (this.targetId) {
        return;
      } else if (this.safezone) {
        this.$store.commit("setTemporaryAccess", {
          safezoneFileUrl: this.localUrl
        });
        this.$store.commit("closeModal");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
}
textarea {
  vertical-align: middle;
}
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 26px 38px 8px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.active {
    border: 2px solid #5850ec;
  }
}
.input-label {
  position: absolute;
  top: 8px;
  left: 38px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
.email-icon {
  position: absolute;
  top: 19px;
  left: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  pointer-events: none;
  color: #b7b7b7;
  text-transform: uppercase;
  &.active {
    color: #5850ec;
  }
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
