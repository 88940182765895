<template>
  <li>
    <router-link :to="`intents/${intent.id}`" class="flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6">
      <div class="flex items-center truncate space-x-3">
        <div v-tooltip="statusHelp" :class="`w-2.5 h-2.5 flex-shrink-0 rounded-full bg-${statusColor}`"></div>
        <p class="font-medium truncate text-sm leading-6">{{ reference }} <span v-if="content" class="truncate font-normal text-gray-500">to {{ content.name }}</span></p>
      </div>
      <!-- Heroicon name: chevron-right -->
      <svg class="ml-4 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
      </svg>
    </router-link>
  </li>
</template>

<script>
export default {
  props: ["intent"],
  computed: {
    content() {
      return _.get(this.intent, "content.content", null) || this.intent.content;
    },
    reference() {
      if (this.content) {
        return "Related";
      } else {
        return "Standalone";
      }
    },
    statusColor() {
      if (this.intent.complete) {
        return "green-400";
      } else {
        return "pink-600";
      }
    },
    statusHelp() {
      if (this.intent.complete) {
        return "All premium content is complete.";
      } else {
        return "Some premium content is either incomplete or not live.";
      }
    }
  }
};
</script>
