<template>
  <div :id="containerId" v-observe-visibility="{ callback: initialNearbyFetch, once: true, throttle: 500 }" class="w-full h-full" />
</template>

<script>
import { mapGetters, mapState } from "vuex";
import fetchBoundingBoxProperties from "../../fetch-bounding-box-properties";
import filterControl from "../../leaflet-filter-control";
import innerIconFor from "../../property-reference-inner-icons";
import router from "../../router";
import store from "../../store";
import strategicMapLegend from "../../leaflet-strategic-map-legend";
/* global L */

export default {
  props: ["deal", "redrawProperties", "showLegend", "noClick"],
  data() {
    return {
      map: null,
      featureGroup: L.featureGroup(),
      random: Math.random(),
      refreshing: false,
      fitting: false,
      visible: false
    };
  },
  computed: {
    ...mapState(["mapFilters", "mapBoundaryMeta", "currentUser", "route"]),
    ...mapGetters(["mapMode", "signedIn"]),
    isStrategicMapPath() {
      return this.route.path === "/map";
    },
    strategicMap() {
      return this.mapMode === "strategic";
    },
    automaticModeTransition() {
      if (this.signedIn) {
        return this.currentUser.mapModeTransition === "automatic";
      } else {
        return true;
      }
    },
    maxZoom() {
      // return this.strategicMap ? null : 15;
      return 20;
    },
    clickableMarkers() {
      return !this.noClick;
    },
    dealProperties() {
      return this.deal.properties;
    },
    containerId() {
      return `${this.deal.token || "new-deal-map"}-${this.random}`;
    },
    mapFocalProperties() {
      return this.dealProperties.map(property => {
        return _.merge(property, { markerType: "focalPoint" });
      });
    }
  },
  watch: {
    mapFilters: {
      handler() {
        if (this.map) {
          this.refreshMap({ fit: false });
        }
      },
      deep: true
    },
    dealProperties: {
      handler() {
        if (this.visible && !this.refreshing && (this.redrawProperties || (this.dealProperties && this.dealProperties.length > 0))) {
          this.refreshMap({ fit: true });
        }
      },
      deep: true
    },
    strategicMap: {
      handler() {
        this.$store.commit("clearMapFilters");
        this.$store.commit("setMapNearbyProperties", []);
        this.$store.commit("setMapFocalRecord", null);
        this.refreshMap({ fit: false });
      }
    }
  },
  mounted() {
    this.map = L.map(this.containerId, {
      center: [37.0902, -95.7129],
      zoom: 4,
      maxZoom: this.maxZoom,
      scrollWheelZoom: this.showLegend,
      fullscreenControl: false
    });

    let filterButton;

    if (this.showLegend) {
      this.$store.commit("setMapFocalRecord", this.deal);
      strategicMapLegend({ store, router });
      filterButton = filterControl({ store, router });
    }

    L.tileLayer
      .grayscale("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        fadeAnimation: false
      })
      .addTo(this.map);

    var self = this;

    this.map.on("moveend", function() {
      self.zoomBasedMapModeToggle();
      if (!self.refreshing && !self.fitting) {
        self.debouncedRefreshMap({ fit: false });
      }
    });

    document.querySelector(".leaflet-popup-pane").addEventListener(
      "load",
      function(event) {
        var target = event.target,
          tagName = target.tagName,
          popup = self.map._popup;

        if (tagName === "IMG" && popup) {
          popup.update();
        }
      },
      true
    ); // Capture the load event, because it does not bubble.

    if (this.showLegend) {
      filterButton.addTo(this.map);
      L.control.strategicMapLegend().addTo(this.map);
    }

    if (this.dealProperties && this.dealProperties.length > 0) {
      this.displayProperties({ fit: true });
    }
  },
  beforeDestroy() {
    if (this.showLegend) {
      this.$store.commit("clearMapFilters");
      this.$store.commit("setMapNearbyProperties", []);
      this.$store.commit("setMapFocalRecord", null);

      if (this.signedIn) {
        this.$store.dispatch("updateMapMode", { mapMode: "heat" });
      }
    }
  },
  methods: {
    zoomBasedMapModeToggle() {
      if (!this.isStrategicMapPath) {
        const oldZoom = this.mapBoundaryMeta.zoom;
        const newZoom = this.map.getZoom();

        if (this.automaticModeTransition && oldZoom <= 15 && newZoom >= 16) {
          this.$store.dispatch("updateMapMode", { mapMode: "strategic" });
        } else if (this.automaticModeTransition && oldZoom >= 16 && newZoom <= 15) {
          this.$store.dispatch("updateMapMode", { mapMode: "heat" });
        }

        this.map.setMaxZoom(this.maxZoom);
      }
    },
    debouncedRefreshMap: _.debounce(function({ fit = false }) {
      this.refreshMap({ fit });
    }, 1000),
    refreshMap({ fit = false }) {
      this.refreshing = true;
      this.clearMapLayers();
      this.displayProperties({ fit });
      this.fetchNearby();
      this.refreshing = false;
    },
    clearMapLayers() {
      this.featureGroup.eachLayer(layer => {
        this.map.removeLayer(layer);
        this.featureGroup.removeLayer(layer);
      });
    },
    displayProperties({ fit = true }) {
      this.dealProperties.forEach(property => {
        var pulsingIcon = L.icon.pulse({
          iconSize: [30, 30],
          color: "#F87171",
          fillColor: "#EF4444",
          heartbeat: 1.5,
          innerIconSize: "1rem",
          innerIconContentCode: innerIconFor(
            property.mostRecentReferenceVisibility
          )
        });
        const marker = L.marker([property.lat, property.lng], {
          riseOnHover: true,
          icon: pulsingIcon
        }).addTo(this.map);

        marker.bindTooltip(property.name);

        if (this.clickableMarkers) {
          marker.on("click", e => {
            this.$router.push({
              path: `/map?deal=${property.mostRecentReferenceId || this.deal.token}`
            });
            store.commit("clearModal");
          });
        }

        this.featureGroup.addLayer(marker);
      });

      if (fit) {
        this.fitting = true;
        this.map.fitBounds(this.featureGroup.getBounds(), { maxZoom: 15 });
        var self = this;

        setTimeout(() => {
          self.fitting = false;
        }, 30);
      }
    },
    initialNearbyFetch(isVisible, entry) {
      if (isVisible && !this.refreshing) {
        this.visible = true;
        this.fetchNearby();
      }
    },
    fetchNearby() {
      fetchBoundingBoxProperties({
        map: this.map,
        router,
        store,
        featureGroup: this.featureGroup,
        focalProperties: [],
        strategic: this.showLegend,
        heatmap: !this.strategicMap && this.showLegend,
        clickableMarkers: this.clickableMarkers
      });
    }
  }
};
</script>
