<template>
  <header class="pb-6">
    <div class="bg-indigo-700 h-24 flex items-start justify-end">
      <div class="pr-4 pt-6 h-7 flex items-center">
        <button @click="close" aria-label="Close panel" class="text-white">
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
    </div>
    <div class="-mt-12 flow-root px-4 sm:px-6">
      <div>
        <div class="-m-1 flex">
          <div class="inline-flex items-center justify-center rounded-lg overflow-hidden border-4 border-white bg-gray-400">
            <img v-if="contact && contact.avatar" class="flex-shrink-0 h-24 w-24 object-cover object-center" :src="contact.avatar" alt="">
            <span v-else-if="contactName && contactName !== ''" class="flex-shrink-0 h-24 w-24 flex justify-center items-center font-medium uppercase leading-none text-indigo-700 text-4xl">
              {{ contactName | initials }}
            </span>
            <img v-else class="flex-shrink-0 h-24 w-24 object-cover object-center" src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png" alt="Tower Hunt">
          </div>
        </div>
      </div>
      <div class="mt-6 flex items-center justify-between">
        <div>
          <div class="flex items-center">
            <h3 v-if="contactName && contactName !== ''" class="font-bold text-xl text-gray-900">{{ contactName }}</h3>
            <h3 v-else class="font-bold text-xl text-gray-900">New Contact</h3>
            <span v-if="isTowerHuntMember" v-tooltip="'Tower Hunt member'" class="ml-2.5 bg-green-400 flex-shrink-0 inline-block h-2 w-2 rounded-full">
              <span class="sr-only">Tower Hunt member</span>
            </span>
          </div>
          <p v-if="involvedCompany || contact" class="text-sm text-gray-500">
            <span>
              <template v-if="involvedCompany">{{ involvedCompany.name }}</template>
              <template v-else-if="contact && contact.company">{{ contact.company }}</template>
            </span>
            <span v-if="contact && contact.title">&nbsp;&middot;&nbsp;{{ contact.title }}</span>
          </p>
        </div>

        <span v-if="contact" class="inline-flex">
          <div ref="contactMenu" class="relative inline-block text-left" style="z-index: 9998;">
            <button @click="toggleMenu" type="button" class="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-400 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" id="options-menu-button" aria-expanded="false" aria-haspopup="true">
              <span class="sr-only">Open options menu</span>
              <!-- Heroicon name: solid/dots-vertical -->
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
              </svg>
            </button>

            <transition
              enter-active-class="duration-100 ease-out"
              enter-class="opacity-0 scale-95"
              enter-to-class="opacity-100 scale-100"
              leave-active-class="duration-75 ease-in"
              leave-class="opacity-100 scale-100"
              leave-to-class="opacity-0 scale-95"
            >
              <div v-show="menuOpen" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu-button" tabindex="-1">
                <div class="py-1" role="none">
                  <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                  <a v-if="isTowerHuntMember" @click.prevent href="" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="options-menu-item-0">View profile</a>
                  <a v-if="isTowerHuntMember" @click.prevent href="" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="options-menu-item-1">Copy profile link</a>
                  <a @click.prevent="editContact" href="" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="options-menu-item-1">Edit</a>
                </div>
              </div>
            </transition>
          </div>
        </span>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["contact", "involvedCompany"],
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    ...mapState(["currentUser", "newContact", "emailRegex"]),
    contactName() {
      return _.get(this.contact, "name", null) || this.newContact.name;
    },
    canEmail() {
      return _.get(this.contact, "email", null) || this.validEmail;
    },
    canCall() {
      return (
        _.get(this.contact, "phone", null) ||
        _.trim(this.newContact.phone) !== ""
      );
    },
    actionableEmail() {
      return _.get(this.contact, "email", null) || this.newContact.email;
    },
    actionablePhone() {
      return _.get(this.contact, "phone", null) || this.newContact.phone;
    },
    validEmail() {
      return this.emailRegex.test(_.trim(this.newContact.email));
    },
    isTowerHuntMember() {
      return false;
    }
  },
  mounted() {
    document.addEventListener("click", evt => {
      evt.stopPropagation();
      if (
        this.$refs.contactMenu &&
        !this.$refs.contactMenu.contains(evt.target)
      ) {
        this.menuOpen = false;
      }
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    close() {
      this.$emit("close");
    },
    editContact() {
      this.$emit("edit-contact");
      this.menuOpen = false;
    }
  }
};
</script>
