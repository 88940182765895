<template>
  <div class="container mx-auto md:mt-12 mb-4 px-4 sm:px-8 lg:px-10 flex flex-col items-center">
    <div v-if="canDelete" class="flex justify-center md:mb-2">
      <a @click.prevent="deletePhoto" href="" class="flex items-center font-medium text-red-600 hover:text-red-500">
        <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
          <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"/>
        </svg>
        <span class="ml-2">Delete</span>
      </a>
    </div>

    <img :style="photoHeight" class="h-full w-auto object-contain" :src="photo.image" />

    <div v-if="!activeDealMarketing.investorPreview && activeDeal.dealTeam && captionable" class="mt-4 sm:mx-auto w-full max-w-md md:max-w-xl">
      <div class="flex justify-between">
        <label for="caption" class="block text-xs font-medium leading-5 text-gray-300">Caption</label>
        <span v-if="captionSaved" class="text-xs leading-5 text-green-500">Saved!</span>
        <span v-else-if="noCaptionEdits" class="text-xs leading-5 text-gray-300"></span>
        <span v-else-if="validCaption" class="text-xs leading-5 text-white">ENTER to save</span>
        <span v-else-if="captionLength === 0" class="text-xs leading-5 text-gray-300">Optional</span>
        <span v-else class="text-xs leading-5 text-red-500">Too long</span>
      </div>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input v-focus v-model="caption" @input="captionSaved = false" @keyup.enter="saveCaption" id="caption" type="text" class="bg-transparent border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-white block w-full pr-12 text-xs sm:leading-5" placeholder="Keep it short" />
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span class="text-xs sm:leading-5" :class="{ 'text-gray-300': captionLength === 0 || validCaption, 'text-red-500': caption && !validCaption }">
            {{ captionLength }}/{{ captionLimit }}
          </span>
        </div>
      </div>
    </div>

    <div v-else-if="caption && captionable" class="max-w-3xl mx-auto text-center text-sm leading-9 text-white">
      <p>
        {{ caption }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["photo", "captionable", "coverPhotoDealId"],
  data() {
    return {
      modalHeight: null,
      caption: null,
      captionSaved: false
    };
  },
  computed: {
    ...mapState(["activeDeal", "activeDealMarketing"]),
    canDelete() {
      return this.marketingCanDelete || this.coverPhotoCanDelete;
    },
    coverPhotoCanDelete() {
      return this.coverPhotoDealId;
    },
    marketingCanDelete() {
      return (
        !this.activeDealMarketing.investorPreview &&
        this.activeDeal.dealTeam &&
        this.captionable
      );
    },
    photoHeight() {
      if (this.modalHeight) {
        const heightRatio = this.modalHeight > 400 ? 0.8 : 0.6;

        return `height: ${this.modalHeight * heightRatio}px;`;
      } else {
        return "height: 200px";
      }
    },
    captionLength() {
      if (this.caption) {
        return this.caption.length;
      } else {
        return 0;
      }
    },
    validCaption() {
      return (
        this.caption &&
        this.captionLength > 0 &&
        this.captionLength <= this.captionLimit
      );
    },
    captionLimit() {
      return 80;
    },
    noCaptionEdits() {
      return this.captionLength > 0 && this.caption === this.photo.caption;
    }
  },
  mounted() {
    this.modalHeight = document.getElementById("xl-modal").offsetHeight;
    this.caption = this.photo.caption;

    this.debouncedHeightUpdate = _.debounce(() => {
      this.modalHeight = document.getElementById("xl-modal").offsetHeight;
    }, 100);

    window.addEventListener("resize", this.debouncedHeightUpdate);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedHeightUpdate);
  },
  methods: {
    saveCaption() {
      if (this.validCaption && this.caption !== this.photo.caption) {
        this.$store
          .dispatch("updateDealMarketingPhotoCaption", {
            photoId: this.photo.id,
            caption: this.caption
          })
          .then(() => {
            this.captionSaved = true;
          });
      }
    },
    deletePhoto() {
      if (!this.activeDealMarketing.investorPreview && this.activeDeal.dealTeam) {
        this.$store
          .dispatch("deleteDealMarketingPhoto", { photoId: this.photo.id })
          .then(() => {
            this.$store.commit("closeXLModal");
          });
      } else if (this.coverPhotoDealId) {
        this.$store
          .dispatch("deleteDealCoverPhoto", { dealId: this.coverPhotoDealId })
          .then((json) => {
            this.$store.commit("setTemporaryAccess", json.data);
            this.$store.commit("closeXLModal");
          });
      }
    }
  }
};
</script>
