<template>
  <!-- Comments-->
  <section aria-labelledby="notes-title">
    <div class="bg-white shadow sm:rounded-lg sm:overflow-hidden">
      <div class="divide-y divide-gray-200">
        <backchannel-header :property-following="propertyFollowing" />
        <div id="watchlist-discussion-container" class="max-h-140 overflow-y-auto px-4 py-6 sm:px-6">
          <backchannel :property-following="propertyFollowing" />
        </div>
        <backchannel-message-input :property-following="propertyFollowing" />
      </div>
    </div>
  </section>
</template>

<script>
import Backchannel from "../../views/backchannel.vue";
import BackchannelHeader from "../backchannels/backchannel-header.vue";
import BackchannelMessageInput from "../backchannels/backchannel-message-input.vue";

export default {
  components: { Backchannel, BackchannelHeader, BackchannelMessageInput },
  props: ["propertyFollowing", "propertyFollowingId"]
};
</script>
