<template>
  <div class="relative p-4 max-w-3xl mx-auto">
    <div>
      <h2 class="text-xl leading-6 tracking-tight font-extrabold text-gray-900 sm:text-2xl sm:leading-8">
        Premium Insights
      </h2>
      <div class="mt-2 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
        <p class="text-lg leading-5 text-gray-500">
          Intel you crave. Sources you can trust.
        </p>
        <form class="mt-4 flex lg:mt-0 lg:justify-end">
          <input v-model="filter" @keydown.escape="filter = ''" aria-label="Filter" type="text" class="appearance-none w-full px-4 py-2 text-base leading-6 text-gray-900 bg-white placeholder-gray-500 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 lg:max-w-xs" placeholder="Filter">
        </form>
      </div>
    </div>
    <ul v-if="linkedContent.length > 0" class="mt-4 pt-6 border-t-2 border-gray-100 grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2" :class="crossSell ? '' : 'mb-24'">
      <purchaseable-preview v-for="purchaseable in filteredContent" :key="purchaseable.id" class="col-span-1" :purchaseable="purchaseable" />
    </ul>
    <div v-else-if="!loaded" class="mt-4 pt-6 border-t-2 border-gray-100 flex justify-center">
      <grid-loader :loading="!loaded" size="6px" color="#5850ec" />
    </div>
    <div v-else class="mt-12 text-center">
      <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
      </svg>
      <h3 class="mt-2 text-sm font-medium text-gray-900">No premium insights</h3>
      <p class="mt-1 text-sm text-gray-500">
        Get paid for your expertise.
      </p>
      <div class="mt-6">
        <button @click="purchaseableIntent" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <!-- Heroicon name: solid/plus -->
          <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
          </svg>
          Sell premium insights
        </button>
      </div>
    </div>
    <bounty-cross-sell v-if="crossSell" background-color="gray-100" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import BountyCrossSell from "./bounty-cross-sell";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import PaymentConnectedAccountGateway from "../payment-connected-account-gateway";
import PurchaseableForm from "./purchaseable-form";
import PurchaseablePreview from "../cards/purchaseable-preview";
import api from "../../api";
/* global analytics */

export default {
  components: { BountyCrossSell, GridLoader, PurchaseablePreview },
  props: ["contentType", "contentToken", "contentName"],
  data() {
    return {
      loaded: false,
      linkedContent: [],
      filter: ""
    };
  },
  computed: {
    ...mapState(["adminMode", "modal"]),
    crossSell() {
      return this.contentType !== "purchaseable_showcase";
    },
    filteredContent() {
      const query = _.toLower(this.filter);

      if (query.length < 2) {
        return this.linkedContent;
      } else {
        return this.linkedContent.filter(purchaseable => {
          const { name, description, contentName } = purchaseable;

          return (
            _.includes(_.toLower(name), query) ||
            _.includes(_.toLower(description), query) ||
            _.includes(_.toLower(contentName), query)
          );
        });
      }
    }
  },
  mounted() {
    this.fetchLinkedPurchaseables();
  },
  methods: {
    fetchLinkedPurchaseables() {
      api
        .get(`linked_purchaseables/${this.contentType}/${this.contentToken}`)
        .then(json => {
          this.linkedContent = json.data;
          this.loaded = true;

          if (!!analytics && !this.adminMode) {
            analytics.track("Browsed premium content", {
              yearWeek: this.$store.state.yearWeek,
              contentType: this.contentType
            });
          }
        });
    },
    purchaseableIntent() {
      let currentModal = false;

      if (this.modal) {
        currentModal = _.cloneDeep(this.modal); // obtains the current component
        currentModal.props.post = this.deal; // allows changes to flow through
      }

      this.$store.commit("openModal", {
        component: PaymentConnectedAccountGateway,
        props: {
          value: {
            component: PurchaseableForm,
            props: {
              contentType: this.contentType,
              contentToken: this.contentToken,
              contentName: this.contentName,
              returnUrl: `https://towerhunt.com/open_${this.contentType}/${this.contentToken}`
            },
            afterClose: currentModal
          }
        }
      });
    }
  }
};
</script>
