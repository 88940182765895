/* global analytics */
import * as moment from "moment";
import { validPolygon } from "./polygon-validation";
import AcceptConfidentialityAgreement from "./views/offered-deal/accept-confidentiality-agreement.vue";
import ContactSlideover from "./components/contact-slideover.vue";
import Guest from "guest";
import Swal from "sweetalert2";
import User from "user";
import UserMarketEmail from "./views/offered-deal/user-market-email";
import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import api from "api";
import dealAwardingOptions from "./sweet-alert-award-deal-options";
import dealLaunchNoTeaserOptions from "./sweet-alert-launch-deal-no-teaser-options";
import dealLaunchOptions from "./sweet-alert-launch-deal-options";
import dealWithdrawalOptions from "./sweet-alert-withdraw-deal-options";
import reblastTeaserOptions from "./sweet-alert-reblast-teaser-options";
import router from "./router";
import sweetAlertOptions from "./sweet-alert-options";

Vue.use(Vuex);

const loadUnreadCounts = _.debounce(
  async () => {
    const json = await api.get("my_unread_counts");

    return json;
  },
  5000,
  { leading: true }
);

export default new Vuex.Store({
  state: {
    temporaryAccess: null,
    deduplicateRecord: null,
    modalNavigationState: {
      horizontalNav: null,
      verticalNav: null
    },
    yearWeek: `${moment().year()}-${moment().isoWeek()}`,
    loading: false,
    localLoading: 0,
    modal: null,
    xlModal: null,
    slideover: null,
    searchFlyoverOpen: false,
    displayGlobalIntelAdd: false,
    now: moment(),
    intervals: new Set(),
    adminMode: null,
    windowWidth: 0,
    emailRegex: RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
    flashMessage: null,
    backNavigation: { path: "#", pageName: null },
    currentUser: new Guest(),
    refetchTargetPage: false,
    refetchRumoredDeals: false,
    refetchNetworkJobs: false,
    refetchBackchannel: null,
    refetchBidChat: null,
    refetchNotepad: false,
    refetchPurchaseables: false,
    refetchSafezone: false,
    refetchWatchlist: false,
    refetchInvolvedCompanyContacts: null,
    refetchDealMarketingDocuments: false,
    refetchShowcases: false,
    persistMapFilters: false,
    persistNewsfeedFilters: false,
    creatingSharedGroup: false,
    invitingDealParticipant: false,
    sharedGroupTargets: [],
    nameFilter: null,
    search: null,
    searchResults: null,
    contactsToRefetch: [],
    hoverObject: null,
    notepadConversionComment: null,
    showThirdPartyTargetDeals: true,
    onboardingInProcess: false,
    targetCreationInProcess: false,
    dealCreationInProcess: false,
    intelCreationInProcess: false,
    newRecordIsSafezone: true,
    propertyWatchlistProximity: {
      proximityType: "radius",
      radius: 0.25,
      region: null,
      competitiveSetProperties: []
    },
    highlightedMapRecord: {
      contentType: null,
      contentToken: null,
      mapTool: null,
      touching: null
    },
    mapFocalRecord: null,
    mapNearbyProperties: [],
    mapNearbyRegions: [],
    mapBoundaryMeta: {
      southwestLatLng: null,
      northeastLatLng: null,
      zoom: null
    },
    mapFiltering: false,
    mapFilters: {
      sources: [],
      regions: [],
      deals: [],
      loans: [],
      risks: [],
      uses: [],
      dates: {
        allDates: true,
        start: new Date(),
        end: new Date()
      }
    },
    newsfeedFiltering: false,
    newsfeedFilters: {
      markets: [],
      intelType: [],
      relevance: [],
      sources: [],
      dates: {
        allDates: true,
        start: new Date(),
        end: new Date()
      }
    },
    newBackchannel: {
      propertyFollowing: { id: null, name: null },
      team: { id: null, name: null },
      privateAudience: [],
      contentToken: null,
      contentType: null
    },
    newUser: {
      name: "",
      industryRole: null,
      email: "",
      password: "",
      referrerToken: null,
      propertyFollowingInvitationToken: null,
      teamInvitationToken: null,
      userInvitationToken: null,
      dealProspectInvitation: {
        token: null,
        action: null,
        dealToken: null
      },
      wantsToFollow: {
        token: null,
        type: null
      },
      dealInvitationPath: null
    },
    editingSlideoverContact: false,
    newContact: {
      linkedUserId: null,
      name: "",
      title: "",
      contactInfo: ""
    },
    dealInvitation: {
      roleName: null,
      jvRoleName: null
    },
    activeDeal: {
      confidential: false,
      state: null,
      source: null,
      safezone: false,
      privateAudience: [],
      type: null,
      risks: [],
      jointVentureRoles: [],
      loans: [],
      name: null,
      url: null,
      thumbnail: null,
      closeDate: new Date(),
      totalValue: null,
      leverage: null,
      office: null,
      retail: null,
      industrial: null,
      lab: null,
      healthCare: null,
      dataCenter: null,
      properties: [],
      notes: null,
      viewerDealRole: null,
      viewerDealJVRole: null,
      defaultDealName: true,
      potentialDuplicates: {
        loaded: false,
        deals: []
      }
    },
    activeDealMarketing: {
      id: null,
      visibility: null,
      investorPreview: false,
      customUrlHandle: null,
      sendTeaserEmails: true,
      coverPhoto: null,
      secondaryHeadline: null,
      executiveSummaryHeadline: null,
      executiveSummarySecondaryHeadline: null,
      locationHeadline: null,
      locationSecondaryHeadline: null,
      dealTeamSecondaryHeadline: null,
      bidDate: null,
      executiveSummaryTalkingPoints: [],
      locationDataPoints: []
    },
    dealProspectInvitationViewedAt: null,
    activeTarget: {
      map: null,
      searchable: true,
      showcase: false,
      name: null,
      type: null,
      deals: [],
      risks: [],
      uses: [],
      loans: [],
      jointVentureRoles: [],
      minimum: null,
      maximum: null,
      leaseMinimum: null,
      leaseMaximum: null,
      leverageMinimum: null,
      leverageMaximum: null,
      regions: [],
      notes: null,
      followerNotes: null,
      people: {
        principal: {
          data: [],
          pagy: null
        },
        broker: {
          data: [],
          pagy: null
        },
        observer: {
          data: [],
          pagy: null
        }
      },
      intel: {
        data: [],
        pagy: null
      },
      matchingDeals: {
        data: [],
        pagy: null
      },
      rumoredDeals: {
        data: [],
        pagy: null
      }
    },
    activeIntel: {
      type: null,
      name: null,
      showcaseId: null,
      anonymous: true,
      safezone: false,
      defaultIntelName: true,
      notes: null,
      url: null,
      file: {
        signedId: null,
        name: null
      },
      thumbnail: null,
      deals: [],
      risks: [],
      uses: [],
      totalValue: null,
      leaseSize: null,
      properties: [],
      regions: [],
      roles: [],
      lat: null,
      lng: null,
      location: null,
      eventDate: {
        type: "single",
        dates: new Date()
      },
      office: null,
      retail: null,
      industrial: null,
      lab: null,
      healthCare: null,
      dataCenter: null,
      privateAudience: []
    },
    activeRegion: {
      name: "",
      polygon: null,
      coordinates: null,
      circle: null,
      rectangle: null,
      drawingShape: null,
      editingShape: false,
      movingShape: false,
      namingShape: false,
      map: null,
      mapData: {
        addressData: null,
        location: null,
        lat: null,
        lng: null
      }
    },
    activeProperty: {
      name: null,
      coverPhoto: null,
      location: null,
      cityState: null,
      lat: null,
      lng: null,
      possibleUses: [],
      office: null,
      retail: null,
      industrial: null,
      multifamily: null,
      lab: null,
      healthCare: null,
      seniorHousing: null,
      dataCenter: null,
      studentHousing: null,
      hotel: null,
      selfStorage: null,
      parking: null,
      landArea: null,
      naming: false,
      sizing: false,
      map: null
    },
    resettingPolygon: false,
    validPolygon: false,
    activeContact: {
      authoredTargets: [],
      contact: null,
      activeDeals: [],
      deals: {
        data: [],
        pagy: null
      },
      liveDeals: {
        data: [],
        pagy: null
      },
      intel: {
        data: [],
        pagy: null
      },
      searchableTargets: []
    },
    regions: null,
    properties: null,
    newsfeed: {
      data: [],
      pagy: null
    }
  },
  getters: {
    signedIn: (state) => {
      return state.currentUser.signedIn;
    },
    isAdmin: (state) => {
      return state.currentUser.admin || state.adminMode;
    },
    privacyMode: (state) => {
      return state.currentUser.privacyMode;
    },
    mapMode: (state) => {
      return state.currentUser.mapMode;
    },
    ableToComposeProfileMeta: (state, getters) => {
      return (
        state.activeContact.contact && getters.contactTargets(null).length > 0
      );
    },
    dealProspectInviteParam: (state) => {
      return state.route.query.invite;
    },
    overrideTopbar: (state, getters) => {
      return getters.onboarding || getters.creatingTarget;
    },
    isStrategicMap: (state) => {
      return state.route.path === "/map";
    },
    backchannels: (state) => {
      if (state.currentUser.backchannels) {
        const structuredChannels = state.currentUser.backchannels.map(b => {
          const filteredMembers = b.members.filter(
            m => m.token !== state.currentUser.token
          );

          if ((filteredMembers.length > 0 && !b.propertyFollowingId) || b.teamName) {
            return {
              members: filteredMembers,
              token: b.token,
              teamName: b.teamName
            };
          } else {
            return null;
          }
        });

        const compactedChannels = _.compact(structuredChannels)

        return _.sortBy(compactedChannels, ["teamName", "member.name"]);
      } else {
        return [];
      }
    },
    dealApiPayload: state => {
      const {
        confidential,
        source,
        type,
        name,
        url,
        thumbnail,
        risks,
        jointVentureRoles,
        loans,
        closeDate,
        totalValue,
        leverage,
        office,
        retail,
        industrial,
        lab,
        healthCare,
        dataCenter,
        properties,
        notes,
        viewerDealRole,
        viewerDealJVRole,
        privateAudience
      } = state.activeDeal;
      const safezone = state.newRecordIsSafezone;

      return {
        confidential,
        safezone,
        source,
        state: state.activeDeal.state,
        type,
        name,
        url,
        thumbnail,
        risks,
        jointVentureRoles,
        loans,
        closeDate: _.includes(["draft", "live", "awarded"], state.activeDeal.state)
          ? null
          : closeDate,
        totalValue,
        leverage,
        office,
        retail,
        industrial,
        lab,
        healthCare,
        dataCenter,
        properties,
        notes,
        viewerDealRole,
        viewerDealJVRole: viewerDealJVRole === "broker" ? null : viewerDealJVRole,
        privateAudience
      };
    },
    creatingActiveDeal: (state) => {
      return (
        _.includes(state.route.path, "/deals/new/") &&
        _.get(state.route, "query.active", false)
      );
    },
    creatingDirectActiveDeal: (state, getters) => {
      return (
        getters.creatingActiveDeal &&
        !_.get(state.route, "query.indirect", false)
      );
    },
    creatingIndirectActiveDeal: (state, getters) => {
      return (
        getters.creatingActiveDeal &&
        _.get(state.route, "query.indirect", false)
      );
    },
    creatingDeal: (state) => {
      return _.includes(state.route.path, "/deals/new/")
    },
    creatingDeal1: (state) => {
      return state.route.path === "/deals/new/1";
    },
    creatingIntel: (state) => {
      return _.includes(state.route.path, "/intel/new/")
    },
    creatingIntel1: (state) => {
      return state.route.path === "/intel/new/1";
    },
    creatingTarget: (state) => {
      return _.includes(state.route.path, "/targets/new/")
    },
    creatingTarget1: (state) => {
      return state.route.path === "/targets/new/1";
    },
    onboarding: (state) => {
      return _.includes(state.route.path, "start");
    },
    onboarding1: (state) => {
      return state.route.path === "/start/1";
    },
    hasNewUserRegion: (state) => {
      const { circle, polygon, rectangle } = state.activeRegion;

      return circle !== null || polygon !== null || rectangle !== null;
    },
    activeRegionGeometry: (state, getters) => {
      if (getters.activeRegionShape) {
        return state.activeRegion[getters.activeRegionShape];
      } else {
        return null;
      }
    },
    activeRegionShape: (state) => {
      const { polygon, circle, rectangle } = state.activeRegion;

      if (polygon) {
        return "polygon";
      } else if (rectangle) {
        return "rectangle";
      } else if (circle) {
        return "circle";
      } else {
        return _.get(state.activeRegion, "shape", null);
      }
    },
    activeRegionCoordinates: (state, getters) => {
      if (getters.activeRegionGeometry) {
        if (getters.activeRegionShape === "circle") {
          const latlng = getters.activeRegionGeometry._latlng;

          return [{ lat: _.round(latlng.lat, 7), lng: _.round(latlng.lng, 7) }];
        } else {
          return getters.activeRegionGeometry.getLatLngs()[0].map(c => {
            return { lat: c.lat, lng: c.lng };
          });
        }
      } else {
        return [];
      }
    },
    activeRegionRadius: (state, getters) => {
      if (getters.activeRegionGeometry && getters.activeRegionShape === "circle") {
          return getters.activeRegionGeometry._mRadius;
      } else {
        return null;
      }
    },
    intelForm: (state) => {
      const modalProps = _.get(state.modal, "props", {});
      const modalAfterClose = _.get(state.modal, "afterClose", {});
      const afterCloseProps = _.get(modalAfterClose, "props", {});

      return (
        state.intelCreationInProcess ||
        _.includes(_.keys(modalProps), "postToken") ||
        _.includes(_.keys(afterCloseProps), "postToken")
      );
    },
    closedDealCapitalMarkets: (state) => {
      return _.includes(["Buy Equity", "Recap Equity", "Originate Debt", "Buy Debt"], state.activeDeal.type);
    },
    closedDealLease: (state) => {
      return state.activeDeal.type === "Lease";
    },
    closedDealJV: (state) => {
      return state.activeDeal.type === "Recap Equity";
    },
    closedDealFinancing: (state) => {
      return state.activeDeal.type === "Originate Debt";
    },
    closedDealLoanSale: (state) => {
      return state.activeDeal.type === "Buy Debt";
    },
    closedDealJVNeedsBuyerSeller: (state, getters) => {
      return getters.closedDealJV && state.activeDeal.viewerDealJVRole === "lp";
    },
    targetObject: (state) => {
      let subject;

      if (state.search) {
        subject = state.search;
      } else if (state.intelCreationInProcess) {
        subject = state.activeIntel;
      } else {
        subject = state.activeTarget;
      }

      return subject;
    },
    dealTypes: (state, getters) => {
      return getters.targetObject.deals;
    },
    dealTypesMapped: (state, getters) => {
      return getters.dealTypes.map(dealType => dealType.type);
    },
    industryRole: (state, getters) => {
      return state.currentUser.industryRole || state.newUser.industryRole;
    },
    hasDealTypes: (state, getters) => {
      return getters.dealTypesMapped.length > 0;
    },
    hasJVs: (state, getters) => {
      if (_.isArray(getters.dealTypes)) {
        return _.includes(getters.dealTypesMapped, "Recap Equity");
      }
    },
    hasCapitalMarkets: (state, getters) => {
      if (_.isArray(getters.dealTypes)) {
        return _.intersection(getters.dealTypesMapped, ["Buy Equity", "Recap Equity", "Originate Debt", "Buy Debt"]).length > 0;
      }
    },
    hasFinancing: (state, getters) => {
      if (_.isArray(getters.dealTypes)) {
        return _.intersection(getters.dealTypesMapped, ["Buy Equity", "Originate Debt"]).length > 0;
      }
    },
    hasLoanSales: (state, getters) => {
      if (_.isArray(getters.dealTypes)) {
        return _.includes(getters.dealTypesMapped, "Buy Debt");
      }
    },
    hasLeases: (state, getters) => {
      if (_.isArray(getters.dealTypes)) {
        return _.includes(getters.dealTypesMapped, "Lease");
      }
    },
    showDealSize: (state, getters) => {
      return !getters.hasDealTypes || getters.hasCapitalMarkets;
    },
    showLeverageSize: (state, getters) => {
      return getters.industryRole === "principal" && (!getters.hasDealTypes || getters.hasFinancing);
    },
    showLoanTypes: (state, getters) => {
      return !getters.hasDealTypes || getters.hasLoanSales;
    },
    showLeaseSize: (state, getters) => {
      return !getters.hasDealTypes || getters.hasLeases;
    },
    showJVRoles: (state, getters) => {
      return getters.industryRole === "principal" && (!getters.hasDealTypes || getters.hasJVs);
    },
    userIsActiveContact: (state) => {
      return _.get(state.activeContact, "contact.id", null) === state.currentUser.id;
    },
    contact: (state, getters) => {
      if ((getters.contactTokenParam || getters.targetGroupTokenParam) && !getters.userIsActiveContact) {
        return _.get(state, "activeContact.contact", null);
      } else {
        return state.currentUser;
      }
    },
    connectedContact: (state, getters) => {
      return getters.followingMe || getters.followedByMe || getters.dealHistory;
    },
    contactSearchable: (state, getters) => {
      return _.get(getters, "contact.searchable", true);
    },
    contactUnrestrictedSearchable: (state, getters) => {
      const { currentUser } = state;
      const { principalVisible, brokerVisible, observerVisible } = currentUser;
      const permissions = [principalVisible, brokerVisible, observerVisible];

      return getters.contactSearchable && permissions.every(p => p);
    },
    targetSearchable: (state, getters) => {
      return getters.contactSearchable && state.activeTarget.searchable;
    },
    targetSearchabilityOverridden: (state, getters) => {
      return state.activeTarget.searchable && !getters.contactSearchable;
    },
    followedByMe: (state, getters) => {
      return _.get(getters.contact, "followedByMe", false);
    },
    followingMe: (state, getters) => {
      return _.get(getters.contact, "followingMe", false);
    },
    dealHistory: (state, getters) => {
      return _.get(getters.contact, "dealHistory", false);
    },
    name: (state, getters) => {
      return _.get(getters.contact, "name", null);
    },
    targetIdParam: (state) => {
      return _.get(state.route, "params.targetId", null);
    },
    regionIdParam: (state) => {
      return _.get(state.route, "params.regionId", null);
    },
    dealIdParam: (state) => {
      return _.get(state.route, "params.dealId", null);
    },
    propertyIdParam: (state) => {
      return _.get(state.route, "params.propertyId", null);
    },
    propertyFollowingIdParam: (state) => {
      return _.get(state.route, "params.propertyFollowingId", null);
    },
    backchannelTokenParam: (state) => {
      return _.get(state.route, "params.channelToken", null);
    },
    contactTokenParam: (state) => {
      return _.get(state.route, "params.contactToken", null);
    },
    targetTokenParam: (state) => {
      return _.get(state.route, "params.targetToken", null);
    },
    targetGroupTokenParam: (state) => {
      return _.get(state.route, "params.targetGroupToken", null);
    },
    dealTokenParam: (state) => {
      return _.get(state.route, "params.dealToken", null);
    },
    offeredDealTokenParam: (state) => {
      return _.get(state.route, "params.offeredDealToken", null);
    },
    dealInvitationTokenParam: (state) => {
      return _.get(state.route, "params.dealInvitationToken", null);
    },
    intelTokenParam: (state) => {
      return _.get(state.route, "params.intelToken", null);
    },
    contactTargets: state => type => {
      const authoredTargets = _.get(state, "activeContact.authoredTargets", []);
      const searchableTargets = _.get(state, "activeContact.searchableTargets", []);
      const union = _.unionBy(authoredTargets, searchableTargets, "id");
      let unsorted;

      if (type) {
        unsorted = union.filter(tgt => tgt.type === type);
      } else {
        unsorted = union;
      }

      return _.orderBy(
        unsorted,
        target => {
          const capitalMarkets = _.toNumber(target.capitalMarketsDealVolume);
          const leasing = _.toNumber(target.leaseDealVolume) / 1000000;

          return capitalMarkets + leasing;
        },
        ["desc"]
      );
    },
    contactNameFilter: state => contact => {
      const name = _.toLower(contact.name);
      const companyName = contact.company ? _.toLower(contact.company) : "";
      const historicalCompanies = contact.historicalCompanies ? contact.historicalCompanies.map(c => _.toLower(c.name)) : [];
      const query = _.toLower(state.nameFilter);

      return _.includes(name, query) || _.includes(companyName, query) || historicalCompanies.some(name => _.includes(name, query));
    },
    hasSize: state => use => {
      const size = state.activeProperty[use];

      return size !== null && size !== "";
    },
    hasTargets: (state, getters) => {
      return getters.macroTargets.length > 0 || getters.microTargets.length > 0;
    },
    macroTargets: (state, getters) => {
      if (_.get(state, "activeContact.searchableTargets", false)) {
        return state.activeContact.searchableTargets.filter(tgt => tgt.type === "macro");
      } else {
        return [];
      }
    },
    microTargets: (state, getters) => {
      if (_.get(state, "activeContact.searchableTargets", false)) {
        return state.activeContact.searchableTargets.filter(tgt => tgt.type === "micro");
      } else {
        return [];
      }
    },
    canSetCustomName: (state, getters) => {
      if (getters.intelForm) {
        return _.get(state.activeIntel, "defaultIntelName", false);
      } else {
        return _.get(state.activeDeal, "defaultDealName", false);
      }
    },
    dynamicName: (state, getters) => {
      const properties = _.get(
        state,
        getters.intelForm ? "activeIntel.properties" : "activeDeal.properties",
        []
      );

      switch (properties.length) {
        case 0:
          return "";
        case 1:
          return properties[0].name;
        case 2:
          return _.take(properties, 2)
            .map(property => property.name)
            .join(" & ");
        default:
          return properties.length + "-Property Portfolio";
      }
    },
    activeTargetDeals: (state, getters) => {
      return state.showThirdPartyTargetDeals ? getters.allTargetDeals : getters.authorTargetDeals;
    },
    allTargetDeals: (state) => {
      return _.get(state.activeTarget, "matchingDeals.data", null);
    },
    authorTargetDeals: (state, getters) => {
      if (getters.allTargetDeals) {
        return getters.allTargetDeals.filter(deal => deal.targetAuthorParticipating);
      } else {
        return [];
      }
    },
    newContactComplete: (state) => {
      const { name, linkedUserId } = state.newContact;

      return _.trim(name) !== "" || !!linkedUserId;
    },
    dealComplete: (state, getters) => {
      const { name, type, risks, properties, totalValue, viewerDealRole } = state.activeDeal;

      if (type === "Lease") {
        return name && viewerDealRole && properties.length > 0 && getters.dealHasSize;
      } else {
        return name && viewerDealRole && risks.length > 0 && properties.length > 0 && totalValue;
      }
    },
    dealHasSize: (state) => {
      const { office, retail, industrial, lab, healthCare, dataCenter } = state.activeDeal;

      return _.sum(_.compact([office, retail, industrial, lab, healthCare, dataCenter])) > 0;
    },
    targetComplete: (state) => {
      const { regions } = state.activeTarget;

      return regions.length > 0;
    },
    industryEventComplete: (state, getters) => {
      const { name, location, lat, lng } = state.activeIntel;

      return name && location && lat && lng && getters.hasEventDate;
    },
    developmentNewsComplete: (state) => {
      const { properties, name, notes } = state.activeIntel;

      return !!name && !!notes && !!properties && properties.length > 0;
    },
    infrastructureNewsComplete: (state) => {
      const { regions, name, notes } = state.activeIntel;

      return !!name && !!notes && regions && regions.length > 0;
    },
    tenantInMarketComplete: (state, getters) => {
      const { regions, name } = state.activeIntel;

      return !!name && regions && regions.length > 0 && getters.intelHasSize;
    },
    intelHasSize: (state) => {
      const { office, retail, industrial, lab, healthCare, dataCenter } = state.activeIntel;

      return _.sum(_.compact([office, retail, industrial, lab, healthCare, dataCenter])) > 0;
    },
    targetHasPropertySize: (state) => {
      return state.activeTarget.uses.some(use => {
        return use.minimum || use.maximum;
      });
    },
    hasEventDate: (state) => {
      const dates = _.get(state.activeIntel, "eventDate.dates", null);

      if (_.isArray(dates)) {
        return dates.length > 0;
      } else {
        return true;
      }
    },
    propertyComplete: (state, getters) => {
      const {
        name,
        location,
        cityState,
        lat,
        lng,
        possibleUses
      } = state.activeProperty;
      const uses = [
        "office",
        "retail",
        "industrial",
        "multifamily",
        "lab",
        "hotel",
        "healthCare",
        "seniorHousing",
        "dataCenter",
        "studentHousing",
        "selfStorage",
        "parking"
      ];
      const hasSize = uses.some(use => getters.hasSize(use));

      return name && location && cityState && lat && lng && (hasSize || possibleUses.length > 0);
    },
    regionComplete: (state) => {
      const { name, polygon } = state.activeRegion;

      return !state.resettingPolygon && !!name && state.validPolygon;
    },
    blankSearch: (state) => {
      if (state.search) {
        const { deals, loans, risks, roles, uses, lat, lng, maximum, minimum } = state.search;
        const emptyMultiSelects = [deals, loans, risks, roles, uses].every(list => _.isEmpty(list));
        const emptyValues = [lat, lng, maximum, minimum].every(val => _.isNull(val) || val === "");

        return emptyMultiSelects && emptyValues;
      } else {
        return true;
      }
    },
    hasMapFilters: state => {
      const present = arr => arr.length > 0;

      return _.some(state.mapFilters, present) || !state.mapFilters.dates.allDates;
    },
    hasNewsfeedFilters: state => {
      const present = arr => arr.length > 0;

      return _.some(state.newsfeedFilters, present) || !state.newsfeedFilters.dates.allDates;
    },
    newsfeedFilterPayload: state => {
      const newsfeedFilters = state.newsfeedFilters;

      return {
        markets: newsfeedFilters.markets.map(market => market.id),
        intelType: newsfeedFilters.intelType.map(option => option.value),
        relevance: newsfeedFilters.relevance.map(option => option.value),
        sources: newsfeedFilters.sources.map(option => option.value),
        dates: newsfeedFilters.dates
      };
    },
    isDesktop: (state) => {
      return state.windowWidth >= 1280;
    }
  },
  mutations: {
    setAdminMode(state) {
      state.adminMode = localStorage.getItem("adminToken");
    },
    setWindowWidth(state, newWidth) {
      state.windowWidth = newWidth;
    },
    setNow(state, time) {
      state.now = time;
    },
    createInterval(state, { callback, delay }) {
      var newInterval = setInterval(callback, delay);

      state.intervals.add(newInterval);

      return newInterval;
    },
    clearInterval(state, id) {
      state.intervals.delete(id);

      return clearInterval(id);
    },
    setGlobalIntelAddButtonVisibility(state, bool) {
      state.displayGlobalIntelAdd = bool;
    },
    setSearchFlyover(state, bool) {
      state.searchFlyoverOpen = bool;
    },
    openSlideover(state, payload) {
      state.slideover = payload;
    },
    closeSlideover(state) {
      if (state.slideover && state.slideover.afterClose) {
        state.slideover = state.slideover.afterClose;
      } else {
        state.slideover = null;
      }
    },
    openModal(state, payload) {
      state.modal = payload;
    },
    closeModal(state) {
      if (state.modal && state.modal.afterClose) {
        state.modal = state.modal.afterClose;
      } else {
        state.modal = null;
      }
    },
    clearModal(state) {
      state.modal = null;
    },
    openXLModal(state, payload) {
      state.xlModal = payload;
    },
    closeXLModal(state) {
      if (state.xlModal && state.xlModal.afterClose) {
        if (state.xlModal.afterCloseDestination === "modal") {
          const destination = state.xlModal.afterClose;

          state.xlModal = null;
          state.modal = destination;
        } else {
          state.xlModal = state.xlModal.afterClose;
        }
      } else {
        state.xlModal = null;
      }
    },
    setModalNavigationState(
      state,
      { horizontalNav = null, verticalNav = null }
    ) {
      state.modalNavigationState = {
        horizontalNav,
        verticalNav
      };
    },
    setTemporaryAccess(state, payload) {
      state.temporaryAccess = payload;
    },
    clearTemporaryAccess(state) {
      state.temporaryAccess = null;
    },
    setDeduplicate(state, payload) {
      state.deduplicateRecord = payload;
    },
    clearDeduplicate(state) {
      state.deduplicateRecord = null;
    },
    activateTargetCreation(state) {
      state.targetCreationInProcess = true;
    },
    concludeTargetCreation(state) {
      state.targetCreationInProcess = false;
    },
    activateDealCreation(state) {
      state.dealCreationInProcess = true;
    },
    concludeDealCreation(state) {
      state.dealCreationInProcess = false;
    },
    activateIntelCreation(state) {
      state.intelCreationInProcess = true;
    },
    concludeIntelCreation(state) {
      state.intelCreationInProcess = false;
    },
    activateOnboarding(state) {
      state.onboardingInProcess = true;
    },
    concludeOnboarding(state) {
      state.onboardingInProcess = false;
      state.newUser.dealInvitationPath = null;
    },
    showAllTargetDeals(state) {
      state.showThirdPartyTargetDeals = true;
    },
    showAuthorTargetDeals(state) {
      state.showThirdPartyTargetDeals = false;
    },
    creatingDealParticipantInvitation(state) {
      state.invitingDealParticipant = true;
    },
    cancelDealParticipantInvitation(state) {
      state.invitingDealParticipant = false;
    },
    setNewUserName(state, name) {
      state.newUser.name = name;
    },
    setNewUserReferrerToken(state, token) {
      state.newUser.referrerToken = token;
    },
    setNewUserInvitationToken(state, token) {
      state.newUser.userInvitationToken = token;
    },
    setNewUserTeamInvitationToken(state, token) {
      state.newUser.teamInvitationToken = token;
    },
    setNewUserPropertyFollowingInvitationToken(state, token) {
      state.newUser.propertyFollowingInvitationToken = token;
    },
    setNewUserDealProspectInvitation(state, actionObject) {
      state.newUser.dealProspectInvitation = actionObject;
    },
    setNewUserDealInvitationPath(state, path) {
      state.newUser.dealInvitationPath = path;
    },
    setNewUserFollowObject(state, followObject) {
      state.newUser.wantsToFollow = followObject;
    },
    setActiveTargetMap(state, map) {
      state.activeTarget.map = map;
    },
    clearActiveTargetMap(state) {
      state.activeTarget.map = null;
    },
    setActiveRegionMap(state, map) {
      state.activeRegion.map = map;
    },
    clearActiveRegionId(state) {
      if (state.activeRegion.id) {
        state.activeRegion.id = null;
      }
    },
    clearActiveRegionMap(state) {
      state.activeRegion.map = null;
    },
    setActiveRegionMapData(state, mapData) {
      state.activeRegion.mapData = mapData;
    },
    clearActiveRegionMapData(state) {
      state.activeRegion.mapData = {
        addressData: null,
        location: null,
        lat: null,
        lng: null
      };
    },
    setActivePropertyMap(state, map) {
      state.activeProperty.map = map;
    },
    clearActivePropertyMap(state) {
      state.activeProperty.map = null;
    },
    setActiveRegionMapEditing(state, bool) {
      state.activeRegion.editingShape = bool;
    },
    setActiveRegionMovingShape(state, bool) {
      state.activeRegion.movingShape = bool;
    },
    setActiveRegionNamingShape(state, bool) {
      state.activeRegion.namingShape = bool;

      if (state.activeRegion.map) {
        if (state.activeRegion.namingShape) {
          state.activeRegion.map.pm.removeControls();
        } else {
          state.activeRegion.map.pm.addControls({
            position: 'topleft',
            drawMarker: false,
            drawCircleMarker: false,
            drawPolyline: false,
            drawRectangle: false,
            drawPolygon: false,
            drawCircle: false,
            editMode: true,
            dragMode: true,
            cutPolygon: false,
            removalMode: true
          });
        }
      }
    },
    setActivePropertyNaming(state, bool) {
      state.activeProperty.naming = bool;
    },
    setActivePropertySizing(state, bool) {
      state.activeProperty.sizing = bool;
    },
    setActiveRegionName(state, name) {
      state.activeRegion.name = name;
    },
    clearActiveRegionName(state) {
      state.activeRegion.name = "";
    },
    setActiveRegionDrawingShape(state, shape) {
      state.activeRegion.drawingShape = shape;
    },
    clearActiveRegionDrawingShape(state) {
      state.activeRegion.drawingShape = null;
    },
    setActiveRegionCircle(state, circle) {
      state.activeRegion.circle = circle;
    },
    setActiveRegionPolygon(state, polygon) {
      state.activeRegion.polygon = polygon;
    },
    setActiveRegionRectangle(state, rectangle) {
      state.activeRegion.rectangle = rectangle;
    },
    clearActiveRegionCircle(state) {
      if (state.activeRegion.circle) {
        state.activeRegion.circle.remove();
      }
      if (state.activeRegion.shape) {
        state.activeRegion.shape = null;
      }
      state.activeRegion.circle = null;
    },
    clearActiveRegionPolygon(state) {
      if (state.activeRegion.coordinates) {
        state.activeRegion.coordinates = null;
      }
      if (state.activeRegion.polygon) {
        state.activeRegion.polygon.remove();
      }
      if (state.activeRegion.shape) {
        state.activeRegion.shape = null;
      }
      state.activeRegion.polygon = null;
    },
    clearActiveRegionRectangle(state) {
      if (state.activeRegion.rectangle) {
        state.activeRegion.rectangle.remove();
      }
      if (state.activeRegion.shape) {
        state.activeRegion.shape = null;
      }
      state.activeRegion.rectangle = null;
    },
    setNewUserIndustryRole(state, role) {
      state.newUser.industryRole = role;
    },
    setNewUserEmail(state, email) {
      state.newUser.email = email;
    },
    setNewUserPassword(state, password) {
      state.newUser.password = password;
    },
    setUser(state, user) {
      if (user) {
        state.currentUser = user;
        if (!user.signedIn) {
          localStorage.removeItem("token");
        }
      }
    },
    setUserSpendableCredits(state, newCredits) {
      state.currentUser.spendableCredits = newCredits;
    },
    setUnreadCounts(
      state,
      {
        newsfeedUnreadCount,
        networkUnreadCount,
        pipelineUnreadCount,
        jobsUnreadCount,
        marketUnreadCounts,
        backchannelUnreadCounts,
        offeredDealUnreadCounts,
      }
    ) {
      state.currentUser.newsfeedUnreadCount = newsfeedUnreadCount;
      state.currentUser.networkUnreadCount = networkUnreadCount;
      state.currentUser.pipelineUnreadCount = pipelineUnreadCount;
      state.currentUser.jobsUnreadCount = jobsUnreadCount;
      state.currentUser.marketUnreadCounts = marketUnreadCounts;
      state.currentUser.backchannelUnreadCounts = backchannelUnreadCounts;
      state.currentUser.offeredDealUnreadCounts = offeredDealUnreadCounts;
    },
    setCurrentUserCredits(state, { creditsCount }) {
      state.currentUser.creditTracking = creditsCount;
      state.currentUser.creditsCount = _.get(creditsCount, "totalCredits", 0);
    },
    setCurrentUserNewsfeedSortBy(state, sortBy) {
      state.currentUser.newsfeedSortBy = sortBy;
    },
    setCurrentUserBackchannels(state, backchannels) {
      state.currentUser.backchannels = backchannels;
    },
    setCurrentUserDirectPipeline(state, pipeline) {
      state.currentUser.pipeline.direct = pipeline;
    },
    setCurrentUserIndirectPipeline(state, pipeline) {
      state.currentUser.pipeline.indirect = pipeline;
      state.refetchRumoredDeals = false;
    },
    setCurrentUserNetworkJobs(state, jobs) {
      state.currentUser.jobs.network = jobs;
      state.refetchNetworkJobs = false;
    },
    refetchIndirectPipeline(state) {
      state.refetchRumoredDeals = true;
    },
    refetchNetworkJobs(state) {
      state.refetchNetworkJobs = true;
    },
    setRefetchTargetPage(state, bool) {
      state.refetchTargetPage = bool;
    },
    setRefetchPurchaseables(state, bool) {
      state.refetchPurchaseables = bool;
    },
    setRefetchSafezone(state, bool) {
      state.refetchSafezone = bool;
    },
    setRefetchWatchlist(state, bool) {
      state.refetchWatchlist = bool;
    },
    setRefetchDocuments(state, bool) {
      state.refetchDealMarketingDocuments = bool;
    },
    setRefetchShowcases(state, bool) {
      state.refetchShowcases = bool;
    },
    shouldPersistMapFilters(state, bool) {
      state.persistMapFilters = bool;
    },
    shouldPersistNewsfeedFilters(state, bool) {
      state.persistNewsfeedFilters = bool;
    },
    mountWatchlistProximity(state, following = null) {
      const propertyFollowing =
        following || _.get(state.modal, "props.following", null);

      if (propertyFollowing) {
        const {
          proximityType,
          radius,
          region,
          competitiveSetProperties
        } = propertyFollowing;

        state.propertyWatchlistProximity = {
          proximityType,
          radius,
          region,
          competitiveSetProperties
        };
      }
    },
    resetWatchlistProximity(state) {
      state.propertyWatchlistProximity = {
        proximityType: "radius",
        radius: 0.25,
        region: null,
        competitiveSetProperties: []
      };
    },
    setWatchlistProximityType(state, newType) {
      state.propertyWatchlistProximity.proximityType = newType;
    },
    setWatchlistProximityRadius(state, radius) {
      state.propertyWatchlistProximity.radius = radius;
    },
    setWatchlistProximityRegion(state, region) {
      state.propertyWatchlistProximity.region = region;
    },
    addWatchlistProximityCompetitiveSetProperty(state, property) {
      const newProperties = _.unionBy(
        [property],
        state.propertyWatchlistProximity.competitiveSetProperties,
        "id"
      );

      state.propertyWatchlistProximity.competitiveSetProperties = newProperties;
    },
    removeWatchlistProximityCompetitiveSetProperty(state, property) {
      const newProperties = state.propertyWatchlistProximity.competitiveSetProperties.filter(
        p => {
          return p.id !== property.id;
        }
      );

      state.propertyWatchlistProximity.competitiveSetProperties = newProperties;
    },
    setHighlightedMapRecord(
      state,
      { contentType, contentToken, mapTool, touching }
    ) {
      state.highlightedMapRecord.contentType = contentType;
      state.highlightedMapRecord.contentToken = contentToken;
      state.highlightedMapRecord.mapTool = mapTool;
      state.highlightedMapRecord.touching = touching;
    },
    setMapFocalRecord(state, record) {
      state.mapFocalRecord = record;
    },
    setMapNearbyProperties(state, properties) {
      state.mapNearbyProperties = properties;
    },
    setMapNearbyRegions(state, regions) {
      state.mapNearbyRegions = regions;
    },
    setMapFiltering(state, bool = false) {
      state.mapFiltering = bool;
    },
    setMapBoundaryMeta(state, payload) {
      state.mapBoundaryMeta = payload;
    },
    clearMapBoundaryMeta(state) {
      state.mapBoundaryMeta = {
        southwestLatLng: null,
        northeastLatLng: null,
        zoom: null
      };
    },
    setMapFilters(state, payload) {
      state.mapFilters = payload;
    },
    clearMapFilters(state) {
      state.mapFilters = {
        sources: [],
        regions: [],
        deals: [],
        loans: [],
        risks: [],
        uses: [],
        dates: {
          allDates: true,
          start: new Date(),
          end: new Date()
        }
      };
    },
    setNewsfeedFiltering(state, bool = false) {
      state.newsfeedFiltering = bool;
    },
    setNewsfeedFilters(state, payload) {
      state.newsfeedFilters = payload;
    },
    clearNewsfeedFilters(state) {
      state.newsfeedFilters = {
        markets: [],
        intelType: [],
        relevance: [],
        sources: [],
        dates: {
          allDates: true,
          start: new Date(),
          end: new Date()
        }
      };
    },
    clearNewBackchannel(state) {
      state.newBackchannel = {
        propertyFollowing: { id: null, name: null },
        team: { id: null, name: null },
        privateAudience: [],
        contentToken: null,
        contentType: null
      };
    },
    setNewBackchannelContent(state, { contentToken, contentType }) {
      state.newBackchannel.contentToken = contentToken;
      state.newBackchannel.contentType = contentType;
    },
    addPropertyToNewBackchannel(state, { id, name }) {
      state.newBackchannel.propertyFollowing = { id, name };
    },
    addTeamToNewBackchannel(state, { id, name }) {
      state.newBackchannel.team = { id, name };
    },
    addContactToNewBackchannel(state, contact) {
      const newContacts = _.unionBy(
        state.newBackchannel.privateAudience,
        [contact],
        "name"
      );

      state.newBackchannel.privateAudience = newContacts;
    },
    removePropertyFromNewBackchannel(state) {
      state.newBackchannel.propertyFollowing = { id: null, name: null };
    },
    removeTeamFromNewBackchannel(state) {
      state.newBackchannel.team = { id: null, name: null };
    },
    removeContactFromNewBackchannel(state, contact) {
      const newContacts = state.newBackchannel.privateAudience.filter(
        c => c.name !== contact.name
      );

      state.newBackchannel.privateAudience = newContacts;
    },
    refetchBackchannel(state, token) {
      state.refetchBackchannel = token;
    },
    backchannelRefetched(state) {
      state.refetchBackchannel = null;
    },
    refetchInvolvedCompanyContacts(state, companyInvolvementId) {
      state.refetchInvolvedCompanyContacts = companyInvolvementId;
    },
    companyContactsRefetched(state) {
      state.refetchInvolvedCompanyContacts = null;
    },
    refetchBidChat(state, id) {
      state.refetchBidChat = id;
    },
    bidChatRefetched(state) {
      state.refetchBidChat = null;
    },
    refetchNotepad(state, bool) {
      state.refetchNotepad = bool;
    },
    setCurrentUserContacts(state, contacts) {
      state.currentUser.contacts = contacts;
    },
    initiateSearch(state) {
      state.search = {
        roles: [],
        deals: [],
        loans: [],
        risks: [],
        uses: [],
        minimum: null,
        maximum: null,
        leaseMinimum: null,
        leaseMaximum: null,
        lat: null,
        lng: null,
        location: null
      };
      state.nameFilter = null;
      state.searchResults = null;
    },
    clearOnboarding(state) {
      state.newUser = {
        name: "",
        industryRole: null,
        email: "",
        password: "",
        referrerToken: null,
        propertyFollowingInvitationToken: null,
        teamInvitationToken: null,
        userInvitationToken: null,
        dealProspectInvitation: {
          token: null,
          action: null,
          dealToken: null
        },
        wantsToFollow: {
          token: null,
          type: null
        },
        dealInvitationPath: null
      };
    },
    setEditingSlideoverContact(state, bool) {
      state.editingSlideoverContact = bool;
    },
    setNewContactLinkedUser(state, linkedUserId) {
      state.newContact.linkedUserId = linkedUserId;
    },
    setNewContactName(state, newName) {
      state.newContact.name = newName;
    },
    setNewContactTitle(state, newTitle) {
      state.newContact.title = newTitle;
    },
    setNewContactInfo(state, newInfo) {
      state.newContact.contactInfo = newInfo;
    },
    clearNewContact(state) {
      state.newContact = {
        linkedUserId: null,
        name: "",
        title: "",
        contactInfo: ""
      };
      state.editingSlideoverContact = false;
    },
    clearSearch(state) {
      state.search = null;
      state.nameFilter = null;
      state.searchResults = null;
    },
    clearSearchLocation(state) {
      state.search.location = null;
      state.search.lat = null;
      state.search.lng = null;
    },
    clearEventLocation(state) {
      state.activeIntel.location = null;
      state.activeIntel.lat = null;
      state.activeIntel.lng = null;
    },
    clearPropertyLocation(state) {
      state.activeProperty.location = null;
      state.activeProperty.cityState = null;
      state.activeProperty.lat = null;
      state.activeProperty.lng = null;
    },
    setNameFilter(state, name) {
      state.nameFilter = name;
    },
    clearNameFilter(state) {
      state.nameFilter = null;
    },
    setSearchAddress(state, { lat, lng, location }) {
      state.search.lat = lat;
      state.search.lng = lng;
      state.search.location = location;
    },
    setEventAddress(state, { lat, lng, location }) {
      state.activeIntel.lat = lat;
      state.activeIntel.lng = lng;
      state.activeIntel.location = location;
    },
    setPropertyAddress(state, { lat, lng, location, cityState}) {
      state.activeProperty.lat = lat;
      state.activeProperty.lng = lng;
      state.activeProperty.location = location;
      state.activeProperty.cityState = cityState;
    },
    updatePropertyLatLng(state, { lat, lng }) {
      state.activeProperty.lat = lat;
      state.activeProperty.lng = lng;
    },
    setSearchResults(state, contacts) {
      state.searchResults = contacts;
    },
    setFlash(state, message) {
      state.flashMessage = message;
    },
    clearFlash(state) {
      state.flashMessage = null;
    },
    createSharedGroup(state) {
      state.creatingSharedGroup = true;
      state.sharedGroupTargets = [];
    },
    cancelSharedGroup(state) {
      state.creatingSharedGroup = false;
      state.sharedGroupTargets = [];
    },
    addTargetToSharedGroup(state, target) {
      const newTargets = _.unionBy([target], state.sharedGroupTargets, "id");

      state.sharedGroupTargets = newTargets;
    },
    removeTargetFromSharedGroup(state, target) {
      const newTargets = state.sharedGroupTargets.filter(r => r.id !== target.id);

      state.sharedGroupTargets = newTargets;
    },
    mountTarget(state, { target = null }) {
      const targetToken = _.get(state.route, "params.targetToken", null);
      const shouldMount = !!targetToken || state.activeTarget.type === null;

      if (target && shouldMount) {
        if (state.activeTarget !== null) {
          state.activeTarget = Object.assign({}, state.activeTarget, target);
        } else {
          state.activeTarget = target;
        }
      }
    },
    mountDeal(state, { deal = null }) {
      if (deal) {
        state.activeDeal = deal;
      }
    },
    mountDealMarketing(state, { dealMarketing = null }) {
      if (dealMarketing) {
        state.activeDealMarketing = dealMarketing;
      }
    },
    mountIntel(state, { intel = null, showcaseId = null }) {
      if (intel) {
        Vue.set(state, "activeIntel", intel);

        if (state.activeIntel.type === "industryEvent") {
          const rawDates = state.activeIntel.dates;
          const dateObjects = rawDates.map(date => new Date(date));
          let newDates;

          switch (state.activeIntel.dateType) {
            case "single":
              newDates = dateObjects[0];
              break;
            case "multiple":
              newDates = dateObjects;
              break;
            case "range":
              newDates = {
                start: dateObjects[0],
                end: dateObjects[1]
              };
              break;
          }

          Vue.set(state.activeIntel, "eventDate", {
            type: state.activeIntel.dateType,
            dates: newDates
          });
        }

        if (showcaseId) {
          Vue.set(state.activeIntel, "showcaseId", showcaseId);
        }
      }
    },
    overwriteDeal(state, { deal = null }) {
      if (deal) {
        const newPosts = state.newsfeed.data.map(post => {
          if (post.content.token === deal.token) {
            return { content: deal };
          } else {
            return post;
          }
        });

        state.newsfeed.data = newPosts;

        const dealCollections = [
          "activeContact.deals",
          "activeContact.liveDeals",
          "activeTarget.matchingDeals",
          "activeTarget.rumoredDeals",
          "currentUser.pipeline.direct",
          "currentUser.pipeline.indirect"
        ];

        dealCollections.forEach(collection => {
          const updatedRecords = _.get(state, `${collection}.data`, []).map(
            d => {
              if (d.token === deal.token) {
                return deal;
              } else {
                return d;
              }
            }
          );

          Vue.set(_.get(state, collection), "data", updatedRecords);
        });
      }
    },
    overwriteIntel(state, { intel = null }) {
      if (intel) {
        const newPosts = state.newsfeed.data.map(post => {
          if (post.content.token === intel.token) {
            return { content: intel };
          } else {
            return post;
          }
        });

        state.newsfeed.data = newPosts;

        const intelCollections = [
          "activeContact.intel",
          "activeTarget.intel",
          "currentUser.jobs.network"
        ];

        intelCollections.forEach(collection => {
          const updatedRecords = _.get(state, `${collection}.data`, []).map(
            i => {
              if (i.token === intel.token) {
                return intel;
              } else {
                return i;
              }
            }
          );

          Vue.set(_.get(state, collection), "data", updatedRecords);
        });
      }
    },
    removeCardImage(state, token = null) {
      if (token) {
        state.newsfeed.data.forEach(post => {
          if (post.content.token === token) {
            post.content.thumbnail = null;
          }
        });

        const collections = [
          "activeContact.deals",
          "activeContact.liveDeals",
          "activeTarget.matchingDeals",
          "activeTarget.rumoredDeals",
          "currentUser.pipeline.direct",
          "currentUser.pipeline.indirect",
          "activeContact.intel",
          "activeTarget.intel",
          "currentUser.jobs.network"
        ];

        collections.forEach(collection => {
          const updatedRecords = _.get(state, `${collection}.data`, []).map(
            p => {
              if (p.token === token) {
                const newPost = p;

                newPost.thumbnail = null;

                return newPost;
              } else {
                return p;
              }
            }
          );

          Vue.set(_.get(state, collection), "data", updatedRecords);
        });
      }
    },
    removeIntelFromNewsfeed(state, { intel = null, targetToken = null }) {
      if (intel) {
        const newPosts = state.newsfeed.data.filter(
          post => post.content.token !== intel.token
        );

        state.newsfeed.data = newPosts;

        if (targetToken) {
          const newTargetPosts = state.activeTarget.intel.data.filter(
            post => post.token !== intel.token
          );

          state.activeTarget.intel.data = newTargetPosts;
        }
      }
    },
    removeDealFromNewsfeed(state, { dealId = null, targetToken = null }) {
      if (dealId) {
        const newDeals = state.newsfeed.data.filter(
          post => post.content.id !== dealId
        );

        state.newsfeed.data = newDeals;

        if (targetToken) {
          const newTargetDeals = state.activeTarget.matchingDeals.data.filter(
            post => post.id !== dealId
          );

          state.activeTarget.matchingDeals.data = newTargetDeals;
        }
      }
    },
    clearTarget(state) {
      state.activeTarget = {
        map: null,
        searchable: true,
        showcase: false,
        name: null,
        type: null,
        deals: [],
        risks: [],
        uses: [],
        loans: [],
        jointVentureRoles: [],
        minimum: null,
        maximum: null,
        leaseMinimum: null,
        leaseMaximum: null,
        leverageMinimum: null,
        leverageMaximum: null,
        regions: [],
        notes: null,
        followerNotes: null,
        people: {
          principal: {
            data: [],
            pagy: null
          },
          broker: {
            data: [],
            pagy: null
          },
          observer: {
            data: [],
            pagy: null
          }
        },
        intel: {
          data: [],
          pagy: null
        },
        matchingDeals: {
          data: [],
          pagy: null
        },
        rumoredDeals: {
          data: [],
          pagy: null
        }
      };
    },
    clearIntel(state) {
      state.activeIntel = {
        type: null,
        name: null,
        showcaseId: null,
        anonymous: true,
        defaultIntelName: true,
        notes: null,
        url: null,
        file: {
          signedId: null,
          name: null
        },
        thumbnail: null,
        deals: [],
        risks: [],
        uses: [],
        totalValue: null,
        leaseSize: null,
        properties: [],
        regions: [],
        roles: [],
        lat: null,
        lng: null,
        location: null,
        eventDate: {
          type: "single",
          dates: new Date()
        },
        office: null,
        retail: null,
        industrial: null,
        lab: null,
        healthCare: null,
        dataCenter: null,
        privateAudience: []
      };
    },
    clearDeal(state) {
      state.activeDeal = {
        confidential: false,
        state: null,
        source: null,
        privateAudience: [],
        type: null,
        risks: [],
        jointVentureRoles: [],
        loans: [],
        name: null,
        url: null,
        thumbnail: null,
        closeDate: new Date(),
        totalValue: null,
        leverage: null,
        office: null,
        retail: null,
        industrial: null,
        lab: null,
        healthCare: null,
        dataCenter: null,
        properties: [],
        notes: null,
        viewerDealRole: null,
        viewerDealJVRole: null,
        defaultDealName: true,
        potentialDuplicates: {
          loaded: false,
          deals: []
        }
      };
    },
    clearDealMarketing(state) {
      state.activeDealMarketing = {
        id: null,
        visibility: null,
        investorPreview: false,
        customUrlHandle: null,
        sendTeaserEmails: true,
        coverPhoto: null,
        secondaryHeadline: null,
        executiveSummaryHeadline: null,
        executiveSummarySecondaryHeadline: null,
        locationHeadline: null,
        locationSecondaryHeadline: null,
        dealTeamSecondaryHeadline: null,
        bidDate: null,
        executiveSummaryTalkingPoints: [],
        locationDataPoints: []
      };
    },
    clearRegion(state) {
      state.activeRegion = {
        name: "",
        polygon: null,
        coordinates: null,
        circle: null,
        rectangle: null,
        drawingShape: null,
        editingShape: false,
        movingShape: false,
        namingShape: false,
        map: null,
        mapData: {
          addressData: null,
          location: null,
          lat: null,
          lng: null
        }
      };
      state.resettingPolygon = false;
      state.validPolygon = false;
    },
    clearProperty(state) {
      state.activeProperty = {
        name: null,
        coverPhoto: null,
        location: null,
        cityState: null,
        lat: null,
        lng: null,
        possibleUses: [],
        office: null,
        retail: null,
        industrial: null,
        multifamily: null,
        lab: null,
        healthCare: null,
        seniorHousing: null,
        dataCenter: null,
        studentHousing: null,
        hotel: null,
        selfStorage: null,
        parking: null,
        landArea: null,
        naming: false,
        sizing: false,
        map: null
      };
    },
    setDealRole(state, roleName) {
      state.activeDeal.viewerDealRole = roleName;
      state.activeDeal.source = "direct";
    },
    setDealJVRole(state, roleName) {
      state.activeDeal.viewerDealJVRole = roleName;
      state.activeDeal.viewerDealRole = null;
      state.activeDeal.source = "direct";

      if (roleName === "gp") {
        state.activeDeal.viewerDealRole = "seller";
      } else if (roleName === "co-gp") {
        state.activeDeal.viewerDealRole = "buyer";
      } else if (roleName === "broker") {
        state.activeDeal.viewerDealRole = "broker";
      }
    },
    setDealSource(state, dealSource) {
      state.activeDeal.source = dealSource;
    },
    setDealState(state, dealState) {
      state.activeDeal.state = dealState;
    },
    setCloseDate(state, date) {
      state.activeDeal.closeDate = date;
    },
    setEventDateMode(state, mode) {
      state.activeIntel.dateType = mode;
      switch (mode) {
        case "single":
          state.activeIntel.eventDate = {
            type: mode,
            dates: new Date()
          };
          break;
        case "multiple":
          state.activeIntel.eventDate = {
            type: mode,
            dates: []
          };
          break;
        case "range":
          state.activeIntel.eventDate = {
            type: mode,
            dates: {
              start: new Date(),
              end: new Date()
            }
          };
          break;
      }
    },
    setEventDate(state, { newDates, rawDates }) {
      state.activeIntel.eventDate.dates = newDates;
      state.activeIntel.dates = rawDates;
    },
    setDealInvitationRole(state, role) {
      state.dealInvitation.roleName = role;
    },
    setDealInvitationJVRole(state, role) {
      const closedParam = state.route.query.closed;
      let closed;

      if (closedParam === "true") {
        closed = true;
      } else if (closedParam === "false") {
        closed = false;
      } else {
        closed = null;
      }

      state.dealInvitation.jvRoleName = role;
      state.dealInvitation.roleName = null;

      if (role === "gp") {
        state.dealInvitation.roleName = "seller";
      } else if (role === "co-gp") {
        state.dealInvitation.roleName = closed ? "buyer" : "seller";
      } else if (role === "lp" && !closed) {
        state.dealInvitation.roleName = "seller";
      } else if (role === "broker") {
        state.dealInvitation.roleName = "broker";
      }
    },
    clearDealInvitationRole(state) {
      state.dealInvitation = {
        roleName: null,
        jvRoleName: null
      };
    },
    setDealName(state, name) {
      state.activeDeal.name = name;
    },
    setNewRecordSafezone(state, bool) {
      state.newRecordIsSafezone = bool;
    },
    setIntelPublic(state) {
      state.activeIntel.anonymous = false;
    },
    setIntelPrivate(state) {
      state.activeIntel.anonymous = true;
    },
    setIntelCreatedAt(state, date) {
      if (state.activeIntel.createdAt) {
        state.activeIntel.createdAt = date;
      }
    },
    setIntelName(state, name) {
      state.activeIntel.name = name;
      state.activeIntel.defaultIntelName = false;
    },
    setIntelType(state, type) {
      state.activeIntel.type = type;
    },
    setIntelUrl(state, url) {
      state.activeIntel.url = url;
    },
    setIntelThumbnail(state, thumbnail) {
      state.activeIntel.thumbnail = thumbnail;
    },
    setPrivateAudience(state, inviteeList) {
      if (state.route.path === "/intel/new/2a") {
        state.activeIntel.privateAudience = inviteeList;
      } else if (state.route.path === "/deals/new/12") {
        state.activeDeal.privateAudience = inviteeList;
      }
    },
    clearIntelDateType(state) {
      state.activeIntel.eventDate.type = null;
    },
    setCustomDealName(state) {
      if (state.activeDeal.defaultDealName) {
        state.activeDeal.defaultDealName = false;
      }
    },
    setCustomIntelName(state) {
      if (state.activeIntel.defaultIntelName) {
        state.activeIntel.defaultIntelName = false;
      }
    },
    setDealType(state, type) {
      state.activeDeal.type = type;
      state.activeDeal.viewerDealRole = null;
      state.activeDeal.viewerDealJVRole = null;

      if (type === "Lease") {
        state.activeDeal.totalValue = null;
        state.activeDeal.risks = [];
      } else {
        state.activeDeal.office = null;
        state.activeDeal.retail = null;
        state.activeDeal.industrial = null;
        state.activeDeal.lab = null;
        state.activeDeal.healthCare = null;
        state.activeDeal.dataCenter = null;
      }
    },
    setRegions(state, regions) {
      state.regions = regions;
    },
    setProperties(state, properties) {
      state.properties = properties;
    },
    setNewsfeed(state, payload) {
      state.newsfeed = payload;
    },
    extendNewsfeed(state, { data, pagy }) {
      state.newsfeed.data = state.newsfeed.data.concat(data);
      state.newsfeed.pagy = pagy;
    },
    clearMarketIntel(state) {
      state.newsfeed = null;
    },
    setActiveContact(
      state,
      { authoredTargets, contact, searchableTargets, activeDeals }
    ) {
      state.activeContact.authoredTargets = authoredTargets;
      state.activeContact.contact = contact;
      state.activeContact.searchableTargets = searchableTargets;
      state.activeContact.activeDeals = activeDeals;
    },
    setActiveContactDeals(state, data) {
      state.activeContact.deals = data;
    },
    setActiveContactLiveDeals(state, data) {
      state.activeContact.liveDeals = data;
    },
    setActiveContactIntel(state, data) {
      state.activeContact.intel = data;
    },
    clearActiveContact(state) {
      state.activeContact = {
        authoredTargets: [],
        contact: null,
        activeDeals: [],
        deals: {
          data: [],
          pagy: null
        },
        liveDeals: {
          data: [],
          pagy: null
        },
        intel: {
          data: [],
          pagy: null
        },
        searchableTargets: []
      };
    },
    setTargetRumoredDeals(state, data) {
      state.activeTarget.rumoredDeals = data;
      state.refetchRumoredDeals = false;
    },
    setTargetDeals(state, data) {
      state.activeTarget.matchingDeals = data;
    },
    setTargetIntel(state, data) {
      state.activeTarget.intel = data;
    },
    setTargetPeople(state, { peopleData, industryRole }) {
      state.activeTarget.people[industryRole] = peopleData;
    },
    addTarget(state, { target, id, to }) {
      const toAdd = target || state.activeTarget;
      const key = to + "Targets";

      if (id) {
        const newTargets = _.unionBy([toAdd], state[key], "id");

        state[key] = newTargets;
      } else {
        state[key].unshift(toAdd);
      }
    },
    toggleNewTargetSearchability(state) {
      state.activeTarget.searchable = !state.activeTarget.searchable;
    },
    toggleNewTargetShowcase(state) {
      state.activeTarget.showcase = !state.activeTarget.showcase;
    },
    toggleNewDealConfidentiality(state) {
      state.activeDeal.confidential = !state.activeDeal.confidential;
    },
    setTargetType(state, type) {
      state.activeTarget.type = type;
    },
    addRoleToSelection(state, role) {
      const subject = state.search;
      const newRoles = _.unionBy([role], subject.roles, "type");

      subject.roles = newRoles;
    },
    removeRoleFromSelection(state, role) {
      const subject = state.search;
      const newRoles = subject.roles.filter(r => r.type !== role.type);

      subject.roles = newRoles;
    },
    cleanUpDealTypes(state, { getters }) {
      let subject;

      if (state.search) {
        subject = state.search;
      } else if (state.intelCreationInProcess) {
        subject = state.activeIntel;
      } else {
        subject = state.activeTarget;
      }

      if (getters.hasCapitalMarkets && !getters.hasLeases) {
        subject.leaseMinimum = null;
        subject.leaseMaximum = null;
      }
      if (getters.hasLeases && !getters.hasCapitalMarkets) {
        subject.risks = [];
        subject.loans = [];
        subject.uses = _.differenceBy(
          subject.uses,
          [
            { type: "Multifamily" },
            { type: "Hotel" },
            { type: "Student Housing" },
            { type: "Self-Storage" }
          ],
          "type"
        );
        subject.jointVentureRoles = [];
        subject.minimum = null;
        subject.maximum = null;
        subject.leverageMinimum = null;
        subject.leverageMaximum = null;
      }
      if (!getters.showLeverageSize) {
        subject.leverageMinimum = null;
        subject.leverageMaximum = null;
      }
      if (!getters.showJVRoles) {
        subject.jointVentureRoles = [];
      }
      if (!getters.showDealSize) {
        subject.minimum = null;
        subject.maximum = null;
      }
      if (!getters.showLeaseSize) {
        subject.leaseMinimum = null;
        subject.leaseMaximum = null;
      }
      if (!getters.showLoanTypes) {
        subject.loans = [];
      }
    },
    addDealToSelection(state, { getters, deal }) {
      let subject;
      const modalProps = _.get(state.modal, "props", {});

      if (state.search) {
        subject = state.search;
      } else if (state.intelCreationInProcess || _.includes(_.keys(modalProps), "postToken")) {
        subject = state.activeIntel;
      } else {
        subject = state.activeTarget;
      }

      const transformed = { type: deal.data };
      const newDeals = _.unionBy([transformed], subject.deals, "type");

      subject.deals = newDeals;
    },
    removeDealFromSelection(state, { getters, deal }) {
      let subject;
      const modalProps = _.get(state.modal, "props", {});

      if (state.search) {
        subject = state.search;
      } else if (state.intelCreationInProcess || _.includes(_.keys(modalProps), "postToken")) {
        subject = state.activeIntel;
      } else {
        subject = state.activeTarget;
      }
      const transformed = { type: deal.data };
      const newDeals = subject.deals.filter(r => r.type !== transformed.type);

      subject.deals = newDeals;
    },
    addJVRoleToSelection(state, role) {
      let subject;
      const modalProps = _.get(state.modal, "props", {});

      if (state.search) {
        subject = state.search;
      } else if (state.intelCreationInProcess) {
        subject = state.activeIntel;
      } else if (_.includes(state.route.path, "deals") || _.includes(_.keys(modalProps), "dealId")) {
        subject = state.activeDeal;
      } else {
        subject = state.activeTarget;
      }
      const newRoles = _.unionBy([role], subject.jointVentureRoles, "type");

      subject.jointVentureRoles = newRoles;
    },
    removeJVRoleFromSelection(state, role) {
      let subject;
      const modalProps = _.get(state.modal, "props", {});

      if (state.search) {
        subject = state.search;
      } else if (state.intelCreationInProcess) {
        subject = state.activeIntel;
      } else if (_.includes(state.route.path, "deals") || _.includes(_.keys(modalProps), "dealId")) {
        subject = state.activeDeal;
      } else {
        subject = state.activeTarget;
      }
      const newRoles = subject.jointVentureRoles.filter(l => l.type !== role.type);

      subject.jointVentureRoles = newRoles;
    },
    addLoanToSelection(state, loan) {
      let subject;
      const modalProps = _.get(state.modal, "props", {});

      if (state.search) {
        subject = state.search;
      } else if (state.intelCreationInProcess) {
        subject = state.activeIntel;
      } else if (_.includes(state.route.path, "deals")  || _.includes(_.keys(modalProps), "dealId")) {
        subject = state.activeDeal;
      } else {
        subject = state.activeTarget;
      }
      const newLoans = _.unionBy([loan], subject.loans, "type");

      subject.loans = newLoans;
    },
    removeLoanFromSelection(state, loan) {
      let subject;
      const modalProps = _.get(state.modal, "props", {});

      if (state.search) {
        subject = state.search;
      } else if (state.intelCreationInProcess) {
        subject = state.activeIntel;
      } else if (_.includes(state.route.path, "deals")  || _.includes(_.keys(modalProps), "dealId")) {
        subject = state.activeDeal;
      } else {
        subject = state.activeTarget;
      }
      const newLoans = subject.loans.filter(l => l.type !== loan.type);

      subject.loans = newLoans;
    },
    addRiskToSelection(state, risk) {
      let subject;
      const modalProps = _.get(state.modal, "props", {});

      if (state.search) {
        subject = state.search;
      } else if (state.intelCreationInProcess || _.includes(_.keys(modalProps), "postToken")) {
        subject = state.activeIntel;
      } else if (_.includes(state.route.path, "deals") || _.includes(_.keys(modalProps), "dealId")) {
        subject = state.activeDeal;
      } else {
        subject = state.activeTarget;
      }
      const newRisks = _.unionBy([risk], subject.risks, "type");

      subject.risks = newRisks;
    },
    removeRiskFromSelection(state, risk) {
      let subject;
      const modalProps = _.get(state.modal, "props", {});

      if (state.search) {
        subject = state.search;
      } else if (state.intelCreationInProcess || _.includes(_.keys(modalProps), "postToken")) {
        subject = state.activeIntel;
      } else if (_.includes(state.route.path, "deals") || _.includes(_.keys(modalProps), "dealId")) {
        subject = state.activeDeal;
      } else {
        subject = state.activeTarget;
      }
      const newRisks = subject.risks.filter(r => r.type !== risk.type);

      subject.risks = newRisks;
    },
    clearPropertyPossibleUses(state) {
      state.activeProperty.possibleUses = [];
    },
    clearPropertySizes(state) {
      state.activeProperty.office = null;
      state.activeProperty.retail = null;
      state.activeProperty.industrial = null;
      state.activeProperty.multifamily = null;
      state.activeProperty.lab = null;
      state.activeProperty.healthCare = null;
      state.activeProperty.seniorHousing = null;
      state.activeProperty.dataCenter = null;
      state.activeProperty.studentHousing = null;
      state.activeProperty.hotel = null;
      state.activeProperty.selfStorage = null;
      state.activeProperty.parking = null;
    },
    addPossibleUseToProperty(state, use) {
      const newUses = _.unionBy(
        [use],
        state.activeProperty.possibleUses,
        "type"
      );

      state.activeProperty.possibleUses = newUses;
    },
    removePossibleUseFromProperty(state, use) {
      const newUses = state.activeProperty.possibleUses.filter(
        r => r.type !== use.type
      );

      state.activeProperty.possibleUses = newUses;
    },
    addUseToSelection(state, use) {
      let subject;
      const modalProps = _.get(state.modal, "props", {});

      if (state.search) {
        subject = state.search;
      } else if (state.intelCreationInProcess || _.includes(_.keys(modalProps), "postToken")) {
        subject = state.activeIntel;
      } else if (_.includes(state.route.path, "deals")) {
        subject = state.activeDeal;
      } else {
        subject = state.activeTarget;
      }
      const newUses = _.unionBy([use], subject.uses, "type");

      subject.uses = newUses;
    },
    removeUseFromSelection(state, use) {
      let subject;
      const modalProps = _.get(state.modal, "props", {});

      if (state.search) {
        subject = state.search;
      } else if (state.intelCreationInProcess || _.includes(_.keys(modalProps), "postToken")) {
        subject = state.activeIntel;
      } else if (_.includes(state.route.path, "deals")) {
        subject = state.activeDeal;
      } else {
        subject = state.activeTarget;
      }
      const newUses = subject.uses.filter(r => r.type !== use.type);

      subject.uses = newUses;
    },
    setDealValue(state, num) {
      if (_.toNumber(num)) {
        state.activeDeal.totalValue = num;
      } else {
        state.activeDeal.totalValue = null;
      }
      state.activeDeal.office = null;
      state.activeDeal.retail = null;
      state.activeDeal.industrial = null;
      state.activeDeal.lab = null;
      state.activeDeal.healthCare = null;
      state.activeDeal.dataCenter = null;
    },
    setDealLeverage(state, num) {
      if (_.toNumber(num)) {
        state.activeDeal.leverage = num;
      } else {
        state.activeDeal.leverage = null;
      }
    },
    setTargetName(state, name) {
      state.activeTarget.name = name;
    },
    setPropertySizeMinimum(state, { use, minimum }) {
      const targetUse = _.find(state.activeTarget.uses, { type: use });

      if (targetUse) {
        targetUse.minimum = minimum;
      }
    },
    setPropertySizeMaximum(state, { use, maximum }) {
      const targetUse = _.find(state.activeTarget.uses, { type: use });

      if (targetUse) {
        targetUse.maximum = maximum;
      }
    },
    setMinimum(state, num) {
      const subject = state.search ? state.search : state.activeTarget;

      if (_.toNumber(num)) {
        subject.minimum = num;
      } else {
        subject.minimum = null;
      }
    },
    setMaximum(state, num) {
      const subject = state.search ? state.search : state.activeTarget;

      if (_.toNumber(num)) {
        subject.maximum = num;
      } else {
        subject.maximum = null;
      }
    },
    setLeverageMinimum(state, num) {
      const subject = state.search ? state.search : state.activeTarget;

      if (_.toNumber(num)) {
        subject.leverageMinimum = num;
      } else {
        subject.leverageMinimum = null;
      }
    },
    setLeverageMaximum(state, num) {
      const subject = state.search ? state.search : state.activeTarget;

      if (_.toNumber(num)) {
        subject.leverageMaximum = num;
      } else {
        subject.leverageMaximum = null;
      }
    },
    setLeaseMinimum(state, num) {
      const subject = state.search ? state.search : state.activeTarget;

      if (_.toNumber(num)) {
        subject.leaseMinimum = num;
      } else {
        subject.leaseMinimum = null;
      }
    },
    setLeaseMaximum(state, num) {
      const subject = state.search ? state.search : state.activeTarget;

      if (_.toNumber(num)) {
        subject.leaseMaximum = num;
      } else {
        subject.leaseMaximum = null;
      }
    },
    setPropertySize(state, { use, size }) {
      state.activeProperty[use] = size;
    },
    setDealLeaseSize(state, { use, size }) {
      state.activeDeal[use] = size;
      state.activeDeal.totalValue = null;
      state.activeDeal.risks = [];
    },
    setIntelLeaseSize(state, { use, size }) {
      state.activeIntel[use] = size;
    },
    clearSize(state) {
      const subject = state.search ? state.search : state.activeTarget;

      subject.minimum = null;
      subject.maximum = null;
    },
    clearLeaseSize(state) {
      const subject = state.search ? state.search : state.activeTarget;

      subject.leaseMinimum = null;
      subject.leaseMaximum = null;
    },
    setActiveDealDuplicates(state, deals = []) {
      state.activeDeal.potentialDuplicates = {
        loaded: true,
        deals
      };
    },
    clearActiveDealDuplicates(state) {
      state.activeDeal.potentialDuplicates = {
        loaded: false,
        deals: []
      }
    },
    setActiveDealNotes(state, text) {
      state.activeDeal.notes = text;
    },
    setActiveDealUrl(state, url) {
      state.activeDeal.url = url;
    },
    setDealThumbnail(state, thumbnail) {
      state.activeDeal.thumbnail = thumbnail;
    },
    clearActiveIntelUses(state) {
      state.activeIntel.uses = [];
    },
    setActiveIntelFile(state, payload) {
      if (payload.files) {
        console.log("TODO: multiple files");
      } else if (payload.file) {
        state.activeIntel.file.signedId = payload.file.signedId;
        state.activeIntel.file.name = payload.file.name;
      }
    },
    setActiveIntelNotes(state, text) {
      state.activeIntel.notes = text;
    },
    setActiveTargetNotes(state, text) {
      state.activeTarget.notes = text;
    },
    setFollowerNotes(state, text) {
      state.activeTarget.followerNotes = text;
    },
    setRegionName(state, name) {
      state.activeRegion.name = name;
    },
    setPropertyLandArea(state, acres) {
      state.activeProperty.landArea = acres;
    },
    setPropertyName(state, name) {
      state.activeProperty.name = name;
    },
    setPropertyPhoto(state, signedId) {
      state.activeProperty.coverPhoto = signedId;
    },
    setRegionPolygon(state, polygon) {
      state.activeRegion.polygon = polygon;
    },
    clearRegionPolygon(state) {
      if (state.activeRegion.coordinates) {
        state.activeRegion.coordinates = null;
      }
      state.activeRegion.polygon = null;
      state.resettingPolygon = false;
      state.validPolygon = false;
    },
    resetPolygon(state) {
      state.resettingPolygon = true;
    },
    doneResettingPolygon(state) {
      state.resettingPolygon = false;
    },
    validatePolygon(state) {
      if (!!state.activeRegion.polygon) {
        state.validPolygon = validPolygon(state.activeRegion.polygon);
      } else {
        state.validPolygon = false;
      }
    },
    addRegion(state, { region, id }) {
      const toAdd = region || state.activeRegion;

      if (id) {
        const newRegions = _.unionBy([toAdd], state.regions, "id");

        state.regions = newRegions;
      } else {
        state.regions.unshift(toAdd);
      }
    },
    mountRegion(state, { region = null, id = null }) {
      const regionId = id || _.get(state.route, "params.regionId", null);
      const toMount = region || _.find(state.regions, { id: _.toNumber(regionId) });
      const placeholderMapData = {
        mapData: {
          addressData: null,
          location: "placeholder",
          lat: 50,
          lng: 50
        }
      };

      if (toMount) {
        state.activeRegion = _.assign(
          {},
          state.activeRegion,
          _.cloneDeep(toMount),
          placeholderMapData
        );
      }
    },
    mountProperty(state, { property = null, id = null }) {
      const propertyId = id || _.get(state.route, "params.propertyId", null);
      const toMount = property || _.find(state.properties, { id: _.toNumber(propertyId) });

      if (toMount) {
        const uses = [
          "office",
          "retail",
          "industrial",
          "multifamily",
          "lab",
          "hotel",
          "healthCare",
          "seniorHousing",
          "dataCenter",
          "studentHousing",
          "selfStorage",
          "parking"
        ];

        uses.forEach(use => {
          if (toMount[use] === 0) {
            toMount[use] = null;
          }
        });

        const cloned = _.cloneDeep(toMount);

        state.activeProperty = Object.assign({}, cloned, { naming: false, sizing: false });
      }
    },
    addRegionToSelection(state, { getters, region }) {
      const toAdd = _.find(state.regions, { id: _.toNumber(region.id) }) || region;
      const record = getters.intelForm ? state.activeIntel : state.activeTarget;
      const newRegions = _.unionBy([toAdd], record.regions, "id");

      record.regions = newRegions;
    },
    removeRegionFromSelection(state, { getters, region }) {
      const record = getters.intelForm ? state.activeIntel : state.activeTarget;
      const newRegions = record.regions.filter(r => r.id !== _.toNumber(region.id));

      record.regions = newRegions;
    },
    addPropertyToSelection(state, { getters, property }) {
      const toAdd = _.find(state.properties, { id: _.toNumber(property.id) }) || property;
      const record = getters.intelForm ? state.activeIntel : state.activeDeal;
      const newProperties = _.unionBy([toAdd], record.properties, "id");

      record.properties = newProperties;

      if (getters.canSetCustomName) {
        record.name = getters.dynamicName;
      }
    },
    removePropertyFromSelection(state, { getters, property }) {
      const record = getters.intelForm ? state.activeIntel : state.activeDeal;
      const newProperties = record.properties.filter(p => p.id !== _.toNumber(property.id));

      record.properties = newProperties;

      if (getters.canSetCustomName) {
        record.name = getters.dynamicName;
      }
    },
    setContactNotifications(state, notifications) {
      state.activeContact.contact.notifications = notifications;
    },
    setTargetNotifications(state, notifications) {
      state.activeTarget.notifications = notifications;
    },
    setCurrentUserNewsfeedWelcome(state, bool) {
      state.currentUser.newsfeedWelcome = bool;
    },
    setCurrentUserDealMarketingWelcome(state, bool) {
      state.currentUser.dealMarketingWelcome = bool;
    },
    setCurrentUserMarketPageWelcome(state, bool) {
      state.currentUser.marketPageWelcome = bool;
    },
    setCurrentUserContactProfileWelcome(state, bool) {
      state.currentUser.contactProfileWelcome = bool;
    },
    setDealMarketingVisibility(state, visibility) {
      state.activeDealMarketing.visibility = visibility;
    },
    setDealMarketingUrlHandle(state, handle) {
      state.activeDealMarketing.customUrlHandle = handle;
    },
    toggleDealMarketingInvestorPreview(state) {
      state.activeDealMarketing.investorPreview = !state.activeDealMarketing.investorPreview;
    },
    setDealMarketingSendEmailTeasers(state, bool) {
      state.activeDealMarketing.sendTeaserEmails = bool;
    },
    setSecondaryHeadline(state, text) {
      state.activeDealMarketing.secondaryHeadline = text;
    },
    setExecutiveSummaryHeadline(state, text) {
      state.activeDealMarketing.executiveSummaryHeadline = text;
    },
    setExecutiveSummarySecondaryHeadline(state, text) {
      state.activeDealMarketing.executiveSummarySecondaryHeadline = text;
    },
    setLocationHeadline(state, text) {
      state.activeDealMarketing.locationHeadline = text;
    },
    setLocationSecondaryHeadline(state, text) {
      state.activeDealMarketing.locationSecondaryHeadline = text;
    },
    setDealTeamSecondaryHeadline(state, text) {
      state.activeDealMarketing.dealTeamSecondaryHeadline = text;
    },
    setBidDate(state, date) {
      state.activeDealMarketing.bidDate = date;
    },
    clearBidDate(state) {
      state.activeDealMarketing.bidDate = null;
    },
    addDealMarketingOrderedItem(state, { item }) {
      const collection = item.type + "s";

      state.activeDealMarketing[collection].push(item);
    },
    setProspectInvitationViewedAt(state, time) {
      state.dealProspectInvitationViewedAt = time;
    },
    clearProspectInvitationViewedAt(state) {
      state.dealProspectInvitationViewedAt = null;
    },
    setDealMarketingItemId(state, { item }) {
      const collection = item.type + "s";
      const oldItem = _.find(state.activeDealMarketing[collection], {
        order: item.order
      });
      const newItem = Object.assign({}, oldItem, { id: item.id });
      const newCollection = _.unionBy(
        [newItem],
        state.activeDealMarketing[collection],
        "order"
      );

      state.activeDealMarketing[collection] = newCollection;
    },
    setDealMarketingItemIcon(state, { item, iconName }) {
      const collection = item.type + "s";
      const oldItem = _.find(state.activeDealMarketing[collection], {
        order: item.order
      });
      const newItem = Object.assign({}, oldItem, { iconName });
      const newCollection = _.unionBy(
        [newItem],
        state.activeDealMarketing[collection],
        "order"
      );

      state.activeDealMarketing[collection] = newCollection;
    },
    setDealMarketingItemText(state, { type, order, field, value }) {
      const oldItem = _.find(state.activeDealMarketing[type], {
        order: order
      });
      let fieldItem = {};

      fieldItem[field] = value;
      const newItem = Object.assign({}, oldItem, fieldItem);
      const newCollection = _.unionBy(
        [newItem],
        state.activeDealMarketing[type],
        "order"
      );

      state.activeDealMarketing[type] = newCollection;
    },
    setRefetchPerson(state, contactToken) {
      const newArray = _.union(state.contactsToRefetch, [contactToken]);

      state.contactsToRefetch = newArray;
    },
    clearRefetchPeople(state) {
      state.contactsToRefetch = [];
    },
    setHoverObject(state, newObject) {
      state.hoverObject = newObject;
    },
    clearHoverObject(state) {
      state.hoverObject = null;
    },
    setNotepadConversionComment(state, comment) {
      state.notepadConversionComment = comment;
    },
    clearNotepadConversionComment(state) {
      state.notepadConversionComment = null;
    },
    isLoading(state) {
      state.loading = true;
    },
    doneLoading(state) {
      state.loading = false;
    },
    isLocalLoading(state) {
      state.localLoading++;
    },
    doneLocalLoading(state) {
      state.localLoading = _.max([state.localLoading - 1, 0]);
    }
  },
  actions: {
    dismissNewsfeedWelcome({ commit, dispatch }) {
      api.patch("newsfeed_welcome").then(() => {
        commit("setCurrentUserNewsfeedWelcome", false);
        dispatch("loadNewsfeed");
      });
    },
    dismissDealMarketingWelcome({ commit, dispatch }) {
      api.patch("deal_marketing_welcome").then(() => {
        commit("setCurrentUserDealMarketingWelcome", false);
        commit("closeModal");
      });
    },
    dismissMarketPageWelcome({ commit, dispatch }) {
      api.patch("market_page_welcome").then(() => {
        commit("setCurrentUserMarketPageWelcome", false);
        commit("closeModal");
      });
    },
    dismissContactProfileWelcome({ commit, dispatch }) {
      api.patch("contact_profile_welcome").then(() => {
        commit("setCurrentUserContactProfileWelcome", false);
        commit("closeModal");
      });
    },
    updateHubExpansionSettings({ state, commit, dispatch }, payload) {
      api.patch("hub_expansion_settings", payload).then(user => {
        commit("setUser", new User(user.data));
      });
    },
    createOnboardingAccount({ state, getters, commit, dispatch }) {
      const {
        name,
        email,
        password,
        industryRole,
        wantsToFollow,
        dealProspectInvitation,
        propertyFollowingInvitationToken,
        teamInvitationToken,
        userInvitationToken,
        referrerToken
      } = state.newUser;
      const registration = {
        name,
        email,
        password,
        propertyFollowingInvitationToken,
        teamInvitationToken,
        userInvitationToken,
        referrerToken
      };
      const followType = wantsToFollow.type;
      const followToken = wantsToFollow.token;
      const prospectInvitationToken = dealProspectInvitation.token;
      const prospectInvitationAction = dealProspectInvitation.action;

      commit("isLoading");
      dispatch("register", registration).then(success => {
        api.patch("user_searchability");
        api.patch("industry_role", { role: industryRole }).then(user => {
          commit("setUser", new User(user.data));
        });

        if (followType === "target") {
          dispatch("followTarget", followToken);
        } else if (followType === "targetGroup") {
          dispatch("followTargetGroup", {
            targetGroupToken: followToken,
            registering: true
          });
        } else if (followType === "contact") {
          dispatch("followContact", {
            contactToken: followToken,
            registering: true
          });
        }

        if (prospectInvitationToken) {
          dispatch(
            "acceptDealProspectInvitation",
            prospectInvitationToken
          ).then(() => {
            if (prospectInvitationAction === "requestContact") {
              dispatch("createDealInquiry", dealProspectInvitation.dealToken);
            } else if (prospectInvitationAction === "followDeal") {
              dispatch("followDeal", dealProspectInvitation.dealToken);
            }
          });

          commit("clearProspectInvitationViewedAt");
        }

        dispatch("createRegion", { announce: false }).then(() => {
          dispatch("createTarget", { announce: false }).then(() => {
            commit("doneLoading");
            commit("clearTarget");
            commit("clearRegion");

            if (state.newUser.dealInvitationPath) {
              router.push({ path: state.newUser.dealInvitationPath });
            } else if (dealProspectInvitation.dealUrlHandle || dealProspectInvitation.dealToken) {
              router.push({
                path: `/deal_offerings/${dealProspectInvitation.dealUrlHandle || dealProspectInvitation.dealToken}/overview`
              });

              if (prospectInvitationAction === "registerDeal") {
                commit("openXLModal", {
                  component: AcceptConfidentialityAgreement,
                  props: {},
                  lightMode: true
                });
              } else if (prospectInvitationAction === "requestContact") {
                commit("openModal", {
                  component: UserMarketEmail,
                  props: {}
                });
              }
            } else {
              router.push({ path: `/home/dashboard` });
            }

            commit("concludeOnboarding");
          });
        });
      });
    },
    createOnboardingTarget({ state, getters, commit, dispatch }) {
      commit("isLoading");
      dispatch("createTarget", { announce: true }).then(() => {
        commit("doneLoading");
        commit("concludeTargetCreation");
        dispatch("loadContactData", state.currentUser.token);
        router.push({ path: "/" });
      });
    },
    createOnboardingRegion({ state, getters, commit, dispatch }) {
      commit("isLoading");
      dispatch("createRegion", { announce: true }).then(() => {
        commit("doneLoading");
      });
    },
    saveNewContact(
      { state, commit, dispatch },
      { existingContact, involvedCompany }
    ) {
      return new Promise(resolve => {
        const { linkedUserId, name, title, contactInfo } = state.newContact;
        const companyInvolvementId = _.get(involvedCompany, "id", null);
        const payload = {
          name,
          title,
          contactInfo,
          contactId: _.get(existingContact, "id", null),
          linkedUserId,
          companyInvolvementId
        };

        if (existingContact) {
          api.patch(`contacts/${existingContact.id}`, payload).then(json => {
            const contact = json.data;

            dispatch("flash", "Contact saved!");

            if (companyInvolvementId) {
              commit("refetchInvolvedCompanyContacts", companyInvolvementId);
            }

            commit("openSlideover", {
              component: ContactSlideover,
              props: {
                involvedCompany: involvedCompany,
                contact,
                editAtStartup: false
              }
            });
            commit("setEditingSlideoverContact", false);
            commit("clearNewContact");
            resolve();
          });
        } else {
          api.post(`contacts`, payload).then(json => {
            dispatch("flash", "Contact saved!");

            if (companyInvolvementId) {
              commit("refetchInvolvedCompanyContacts", companyInvolvementId);
            }
            commit("closeSlideover");
            commit("clearNewContact");
            resolve();
          });
        }
      });
    },
    register({ state, commit, dispatch }, registration) {
      return new Promise(resolve => {
        api.post("registrations", registration).then(
          result => {
            if (!!analytics) {
              analytics.track("Registered", { yearWeek: state.yearWeek });
            }
            const token = result.data.id;

            localStorage.setItem("token", token);
            api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

            api.get("whoami").then(user => {
              dispatch("signIn", new User(user.data));
              resolve();
            });
          },
          failure => {
            dispatch("flash", {
              message: "Account already exists for this email address",
              timeout: 5000
            });
            commit("doneLoading");
            console.log(failure);
          }
        );
      });
    },
    signIn({ state, commit, dispatch }, user) {
      return new Promise(resolve => {
        commit("setUser", user);

        if (!state.adminMode) {
          if (!!window.LogRocket) {
            window.LogRocket.identify(state.currentUser.id, {
              name: state.currentUser.name,
              email: state.currentUser.email,
              industryRole: state.currentUser.industryRole,
              createdAt: state.currentUser.userCreatedAt
            });
          }

          if (!!analytics) {
            analytics.identify(state.currentUser.id, {
              name: state.currentUser.name,
              email: state.currentUser.email,
              industryRole: state.currentUser.industryRole,
              createdAt: state.currentUser.userCreatedAt,
              signupMonthYear: state.currentUser.signupMonthYear
            });
          }
        }

        resolve();
      });
    },
    signOut({ state, commit, dispatch }) {
      localStorage.removeItem("token");

      const adminToken = localStorage.getItem("adminToken");

      if (adminToken) {
        localStorage.setItem("token", adminToken);
        localStorage.removeItem("adminToken");
        commit("setAdminMode");
        api.defaults.headers.common["Authorization"] = `Bearer ${adminToken}`;

        api.get("whoami").then(user => {
          dispatch("signIn", new User(user.data)).then(() => {
            router.push({ path: "/admin" });
          });
        });
      } else {
        commit("setUser", new Guest());
        if (state.route.path !== "/") {
          router.push({ path: "/" });
        }
        location.reload();
      }
    },
    loadCurrentUser({ dispatch, commit }) {
      // commit("isLoading");
      api.get("whoami").then(user => {
        dispatch("signIn", new User(user.data)).then(result => {
          api.get("my_unread_counts").then(json => {
            commit("setUnreadCounts", json.data);
          })
          // commit("doneLoading");
        });
      });
    },
    loadCreditTracking({ dispatch, commit }) {
      api.get("my_credits").then(json => {
        commit("setCurrentUserCredits", json.data);
      });
    },
    loadSpendableCredits({ commit }) {
      api.get("my_spendable_credits").then(json => {
        commit("setUserSpendableCredits", json.data);
      });
    },
    loadBackchannels({ dispatch, commit }) {
      api.get("my_backchannels").then(json => {
        commit("setCurrentUserBackchannels", json.data.backchannels);
      });
    },
    loadUnreadCounts({ dispatch, commit }) {
      loadUnreadCounts().then(json => {
        commit("setUnreadCounts", json.data);
      });
    },
    loadDirectPipeline({ commit }, url) {
      const endpoint = url ? url : `my_direct_pipeline`;

      api.get(endpoint).then(json => {
        commit("setCurrentUserDirectPipeline", json.data);
      });
    },
    loadIndirectPipeline({ commit }, url) {
      const endpoint = url ? url : `my_indirect_pipeline`;

      commit("isLoading");
      api.get(endpoint).then(json => {
        commit("setCurrentUserIndirectPipeline", json.data);
        commit("doneLoading");
      });
    },
    loadNetworkJobs({ commit }, url) {
      const endpoint = url ? url : `my_network_jobs`;

      commit("isLoading");
      api.get(endpoint).then(json => {
        commit("setCurrentUserNetworkJobs", json.data);
        commit("doneLoading");
      });
    },
    loadMyContacts({ commit }) {
      commit("isLoading");

      return new Promise(resolve => {
        api.get("my_contacts").then(json => {
          commit("setCurrentUserContacts", json.data);
          commit("doneLoading");
          resolve();
        });
      });
    },
    clearAll({ commit, dispatch }) {
      commit("clearActiveContact");
      commit("clearTarget");
      commit("clearRegion");
      commit("clearDeal");
      commit("clearDealMarketing");
      commit("clearIntel");
      commit("clearProperty");
      commit("clearSearch");
      commit("clearNameFilter");
      commit("cancelDealParticipantInvitation");
      dispatch("clearAllIntervals");
    },
    clearAllIntervals({ state, commit }) {
      state.intervals.forEach(interval => {
        commit("clearInterval", interval);
      });
    },
    triggerNewsfeedRefresh({ commit, dispatch }) {
      return new Promise(resolve => {
        api.post("refresh_newsfeed").then(json => {
          resolve();
        });
      });
    },
    updateNewsfeed({ commit, dispatch }) {
      return new Promise(resolve => {
        api.patch("newsfeed").then(json => {
          resolve(json.data.fetchAgain);
        });
      });
    },
    loadNewsfeed({ getters, commit }) {
      return new Promise(resolve => {
        api.post("newsfeed", getters.newsfeedFilterPayload).then(
          json => {
            commit("setNewsfeed", json.data);
            setTimeout(() => {
              commit("setNewsfeedFiltering", false);
            }, 1000);
            resolve();
          },
          failure => {
            resolve();
          }
        );
      });
    },
    loadNewsfeedIncrement({ getters, commit }, url) {
      return new Promise(resolve => {
        api.post(url, getters.newsfeedFilterPayload).then(json => {
          commit("extendNewsfeed", json.data);
          resolve();
        });
      });
    },
    loadDealParticipants({ state }, dealId) {
      return api.get(`deal_participants/${dealId}`);
    },
    loadRegion({ state, commit }, regionId) {
      commit("isLoading");

      api.get(`regions/${regionId}`).then(
        json => {
          commit("mountRegion", { region: json.data.region });
          commit("doneLoading");
        },
        failure => {
          commit("doneLoading");
        }
      );
    },
    loadRegions({ commit }) {
      commit("isLoading");

      return api.get("regions").then(
        json => {
          commit("setRegions", json.data.regions);
          commit("mountRegion", {});
          commit("doneLoading");
        },
        failure => {
          commit("doneLoading");
        }
      );
    },
    loadProperty({ commit }, propertyId) {
      return new Promise(resolve => {
        api.get(`properties/${propertyId}`).then(json => {
          commit("mountProperty", { property: json.data });
          resolve(json.data);
        });
      });
    },
    loadProperties({ commit }) {
      commit("isLoading");

      return api.get("properties").then(
        json => {
          commit("setProperties", json.data.properties);
          commit("mountProperty", {});
          commit("doneLoading");
        },
        failure => {
          commit("doneLoading");
        }
      );
    },
    loadContactData({ commit }, contactId) {
      return new Promise(resolve => {
        api.get(`data/${contactId}`).then(json => {
          commit("setActiveContact", json.data);
          resolve();
        });
      });
    },
    loadContactDeals({ commit, getters }, url) {
      const endpoint = url ? url : `contact_deals/${getters.contactTokenParam}`;

      commit("isLoading");
      api.get(endpoint).then(json => {
        commit("setActiveContactDeals", json.data);
        commit("doneLoading");
      });
    },
    loadContactLiveDeals({ commit, getters }, url) {
      const endpoint = url ? url : `contact_live_deals/${getters.contactTokenParam}`;

      commit("isLoading");
      api.get(endpoint).then(json => {
        commit("setActiveContactLiveDeals", json.data);
        commit("doneLoading");
      });
    },
    loadContactIntel({ commit, getters }, url) {
      const endpoint = url ? url : `contact_intel/${getters.contactTokenParam}`;

      commit("isLoading");
      api.get(endpoint).then(json => {
        commit("setActiveContactIntel", json.data);
        commit("doneLoading");
      });
    },
    executeSearch({ state, commit, dispatch }) {
      const {
        roles,
        deals,
        risks,
        uses,
        minimum,
        maximum,
        leaseMinimum,
        leaseMaximum,
        lat,
        lng
      } = state.search;
      const rawName = state.nameFilter;
      const name =
        rawName === "" || rawName === null ? null : _.toLower(rawName);
      const contactQuery = {
        roles,
        deals,
        risks,
        uses,
        minimum,
        maximum,
        leaseMinimum,
        leaseMaximum,
        lat,
        lng,
        name
      };
      const nameQuery = { name };
      const searchCategory = state.currentUser.searchCategory;

      commit("isLoading");
      switch (searchCategory) {
        case "people":
          api.post(`search_contacts`, contactQuery).then(
            json => {
              const contacts = json.data;

              commit("setSearchResults", contacts);
              commit("doneLoading");
            },
            failure => {
              dispatch("flash", "Invalid search");
              commit("doneLoading");
            }
          );
          break;
        case "intel":
          api.post(`search_intel`, nameQuery).then(
            json => {
              const intel = json.data;

              commit("setSearchResults", intel);
              commit("doneLoading");
            },
            failure => {
              dispatch("flash", "Invalid search");
              commit("doneLoading");
            }
          );
          break;
        default:
          return;
      }
    },
    executeDealSearch({ commit, dispatch }, query) {
      return api.post(`search_deals`, query);
    },
    executePropertySearch({ getters }, query) {
      return api.post(`search_properties`, { query, admin: getters.isAdmin });
    },
    executeRegionSearch({}, query) {
      return api.post(`search_regions`, { query });
    },
    executeCompanySearch({}, query) {
      return api.post(`search_companies`, { query });
    },
    executeSchoolSearch({}, query) {
      return api.post(`search_schools`, { query });
    },
    createCompany({ dispatch }, name) {
      return new Promise(resolve => {
        api.post(`companies`, { name }).then(json => {
          resolve(json.data);
        });
      });
    },
    createInvolvedCompany({ dispatch }, payload) {
      return new Promise(resolve => {
        api.post(`company_involvements`, payload).then(json => {
          dispatch("flash", "Company created");
          resolve(json.data);
        });
      });
    },
    updateInvolvedCompany({ dispatch }, payload) {
      return new Promise(resolve => {
        api
          .patch(`company_involvements/${payload.involvementId}`, payload)
          .then(json => {
            dispatch("flash", "Company updated");
            resolve(json.data);
          });
      });
    },
    removeInvolvedCompany({ dispatch }, payload) {
      return new Promise(resolve => {
        api
          .patch(
            `company_involvement_removals/${payload.involvementId}`,
            payload
          )
          .then(json => {
            dispatch("flash", "Company removed");
            resolve();
          });
      });
    },
    removeInvolvedContact({ dispatch }, payload) {
      return new Promise(resolve => {
        api.patch(`contact_involvement_removals`, payload).then(json => {
          dispatch("flash", "Company removed");
          resolve();
        });
      });
    },
    createHistoricalCompany({ state, commit, dispatch }, name) {
      commit("isLoading");
      api.post("user_companies", { name }).then(json => {
        if (json.data.status === "error") {
          dispatch("flash", json.data.message);
        } else {
          dispatch("flash", "Company added successfully.");
          commit("setUser", new User(json.data));
          dispatch("loadContactData", state.currentUser.token);

          if (!!analytics && !state.adminMode) {
            analytics.track("Added Historical Company", { yearWeek: state.yearWeek });
          }
        }
        commit("doneLoading");
      },
      failure => {
        dispatch("flash", "Company already exists.");
        commit("doneLoading");
      });
    },
    removeHistoricalCompany({ state, commit, dispatch }, companyId) {
      commit("isLoading");
      api.delete(`user_companies/${companyId}`).then(user => {
        dispatch("flash", "Company removed successfully.");
        commit("setUser", new User(user.data));
        dispatch("loadContactData", state.currentUser.token);
        commit("doneLoading");
      },
      failure => {
        dispatch("flash", "Failed attempt to remove company.");
        commit("doneLoading");
      });
    },
    createSchool({ state, commit, dispatch }, name) {
      commit("isLoading");
      api.post("user_schools", { name }).then(json => {
        if (json.data.status === "error") {
          dispatch("flash", json.data.message);
        } else {
          dispatch("flash", "School added successfully.");
          commit("setUser", new User(json.data));
          dispatch("loadContactData", state.currentUser.token);

          if (!!analytics && !state.adminMode) {
            analytics.track("Added School", { yearWeek: state.yearWeek });
          }
        }
        commit("doneLoading");
      },
      failure => {
        dispatch("flash", "School already exists.");
        commit("doneLoading");
      });
    },
    removeSchool({ state, commit, dispatch }, companyId) {
      commit("isLoading");
      api.delete(`user_schools/${companyId}`).then(user => {
        dispatch("flash", "Company removed successfully.");
        commit("setUser", new User(user.data));
        dispatch("loadContactData", state.currentUser.token);
        commit("doneLoading");
      },
      failure => {
        dispatch("flash", "Failed attempt to remove company.");
        commit("doneLoading");
      });
    },
    askToJoinDeal({ dispatch }, { contact, deal }) {
      const request = { contactToken: contact.token };

      api.post(`deals/${deal.id}/deal_invitation_requests`, request).then(
        json => {
          dispatch("flash", `Asked ${contact.name} to join ${deal.name}`);
        },
        failure => {
          dispatch("flash", "Email already invited!");
        }
      );
    },
    acceptDealInvitationRequest({ dispatch }, requestToken) {
      api.post(`deal_invitation_requests/${requestToken}/accept`).then(() => {
        dispatch("flash", "Deal participant invitation sent");
      });
    },
    declineDealInvitationRequest({ dispatch }, requestToken) {
      api.delete(`deal_invitation_requests/${requestToken}`).then(() => {
        dispatch("flash", "Deal join request declined");
      });
    },
    acceptDealProspectInvitation({ state, dispatch }, invitationToken) {
      return new Promise(resolve => {
        api
          .post(`deal_prospect_invitations/${invitationToken}/accept`)
          .then(json => {
            resolve();
            if (json.data.dealUrlHandle || json.data.dealToken) {
              router.push({
                path: `/deal_offerings/${json.data.dealUrlHandle || json.data.dealToken}/overview`,
                query: { invite: invitationToken }
              });
            } else {
              router.push({ path: "/" });
              dispatch("flash", "Link expired");
            }
          });
      });
    },
    declineDealProspectInvitation({ state, dispatch }, invitationToken) {
      api
        .post(`deal_prospect_invitations/${invitationToken}/decline`)
        .then(() => {
          router.push({ path: "/" });
          dispatch("flash", "Deal declined");
        });
    },
    checkDealProspectInvitationExpiration(
      { state, commit, dispatch },
      invitationToken
    ) {
      api
        .get(`deal_prospect_invitations/${invitationToken}/expired`)
        .then(json => {
          const expired = json.data.expired;

          if (expired) {
            router.push({ path: "/" });
            commit("clearProspectInvitationViewedAt");
            dispatch("flash", "Link expired");
          } else {
            commit("setProspectInvitationViewedAt", json.data.viewedAt);
          }
        });
    },
    removeMarketingListProspect({ dispatch }, { prospect }) {
      return new Promise(resolve => {
        if (prospect.participationId) {
          api
            .delete(`marketing_list_prospects/${prospect.participationId}`)
            .then(() => {
              resolve();
            });
        } else if (prospect.invitationId) {
          api
            .delete(`deal_prospect_invitations/${prospect.invitationId}`)
            .then(() => {
              resolve();
            });
        } else {
          dispatch("flash", "Removal unsuccessful");
        }
      });
    },
    reinviteMarketingListProspect({ dispatch }, { prospect }) {
      return new Promise(resolve => {
        if (prospect.invitationId) {
          api
            .patch(`deal_prospect_reinvitations/${prospect.invitationId}`)
            .then(() => {
              resolve();
            });
        } else {
          dispatch("flash", "Re-invitation unsuccessful");
        }
      });
    },
    revokeDocumentAccess({ dispatch }, { prospect }) {
      return new Promise(resolve => {
        if (prospect.participationId) {
          api
            .delete(
              `deal_marketing_document_licenses/${prospect.participationId}`
            )
            .then(() => {
              resolve();
            });
        } else {
          dispatch("flash", "Document access update failed");
        }
      });
    },
    grantDocumentAccess({ dispatch }, { prospect }) {
      return new Promise(resolve => {
        if (prospect.participationId) {
          api
            .post(
              `deal_marketing_document_licenses/${prospect.participationId}`
            )
            .then(() => {
              resolve();
            });
        } else {
          dispatch("flash", "Document access update failed");
        }
      });
    },
    createTowerHuntInvitation({ commit, dispatch }, { invitation, id }) {
      api.post(`user_invitations`, invitation).then(json => {
        dispatch("flash", "Invitation sent!");
      });
    },
    allowDealMarketingMatchingPrincipals({ state, commit, dispatch }) {
      commit("creatingDealParticipantInvitation");
      api
        .patch(`deal_marketings/${state.activeDeal.token}/recommendations`)
        .then(json => {
          dispatch("flash", "Matching principals allowed");
          commit("cancelDealParticipantInvitation");
        });
    },
    createDealProspectInvitation({ commit, dispatch }, { invitation, id }) {
      api.post(`deal_marketings/${id}/prospects`, invitation).then(
        json => {
          dispatch("flash", "Prospect added to deal");
        },
        failure => {
          dispatch("flash", "Email already invited!");
        }
      );
    },
    createDealProspectInvitationBatch({ commit, dispatch }, { emails, id }) {
      const payload = { emails };

      api.post(`deal_marketings/${id}/prospect_batch`, payload).then(
        json => {
          dispatch("flash", "Processing prospect emails.");
        },
        failure => {
          dispatch("flash", "An error occurred.");
        }
      );
    },
    createDealParticipantInvitation({ commit, dispatch }, { invitation, id }) {
      api.post(`deals/${id}/deal_invitations`, invitation).then(
        json => {
          dispatch("flash", "Deal participant invitation sent")
        },
        failure => {
          dispatch("flash", "Email already invited!");
        }
      );
    },
    fetchPotentialDuplicateDeals({ state, getters, commit }) {
      return new Promise(resolve => {
        api
          .post(`duplicate_deals`, { filters: getters.dealApiPayload })
          .then(json => {
            const duplicateDeals = json.data;

            commit("setActiveDealDuplicates", duplicateDeals);
            resolve();
          });
      });
    },
    createDeal({ state, getters, commit, dispatch }) {
      const deal = getters.dealApiPayload;

      if (state.notepadConversionComment) {
        deal.notes = state.notepadConversionComment.body;
        deal.notepadCommentId = state.notepadConversionComment.id;
      }

      api.post(`deals`, deal).then(json => {
        if (!!analytics && !state.adminMode) {
          analytics.track("Deal Created", {
            state: deal.state,
            source: deal.source,
            yearWeek: state.yearWeek
          });
        }
        if (deal.privateAudience.length > 0) {
          dispatch("loadBackchannels");
        }

        dispatch("flash", "Deal saved");
        commit("clearDeal");
        commit("clearDealMarketing");
        commit("concludeDealCreation");
        commit("setNewRecordSafezone", true);
        dispatch("loadContactData", state.currentUser.token);
        dispatch("loadCreditTracking");

        if (json.data.state === "draft") {
          router.push({ path: `/deal_offerings/${json.data.token}/overview` });
        } else if (deal.safezone) {
          router.push({ path: "/home/safezone" });
        } else {
          router.push({ path: `/open_deal/${json.data.token}` });
        }
      });
    },
    claimIndirectDeal({ state, commit, dispatch }, dealId) {
      const { viewerDealRole, viewerDealJVRole } = state.activeDeal;
      const payload = {
        viewerDealRole,
        viewerDealJVRole: viewerDealJVRole === "broker" ? null : viewerDealJVRole
      };

      api.patch(`claim_deal/${dealId}`, payload).then(json => {
        dispatch("flash", "Deal added to your profile");
        dispatch("loadContactData", state.currentUser.token);
        dispatch("loadCreditTracking");
        commit("clearDeal");
      });
    },
    declassifySafezoneContent({ commit }, { content, anonymous = true }) {
      const payload = {
        anonymous,
        contentToken: content.token,
        contentType: content.newsfeedType
      };

      commit("isLoading");
      api.patch(`declassify`, payload).then(() => {
        commit("doneLoading");
        commit("setRefetchSafezone", true);
      });
    },
    updateDeal(
      { state, getters, commit, dispatch },
      { dealId, updateTags = false }
    ) {
      const deal = getters.dealApiPayload;

      deal.updateTags = updateTags;

      api.patch(`deals/${dealId}`, deal).then(json => {
        dispatch("flash", "Deal saved");
        dispatch("loadContactData", state.currentUser.token);
        dispatch("loadCreditTracking");
      });
    },
    updateDealMarketing({ state, commit, dispatch }, dealMarketingId) {
      const {
        visibility,
        sendTeaserEmails,
        secondaryHeadline,
        executiveSummaryHeadline,
        executiveSummarySecondaryHeadline,
        locationHeadline,
        locationSecondaryHeadline,
        dealTeamSecondaryHeadline,
        bidDate
      } = state.activeDealMarketing;
      const dealMarketing = {
        visibility,
        sendTeaserEmails,
        secondaryHeadline,
        executiveSummaryHeadline,
        executiveSummarySecondaryHeadline,
        locationHeadline,
        locationSecondaryHeadline,
        dealTeamSecondaryHeadline,
        bidDate
      };

      api.patch(`deal_marketings/${dealMarketingId}`, dealMarketing).then(json => {
        commit("mountDealMarketing", { dealMarketing: json.data });
        dispatch("flash", { message: "Deal saved", timeout: 5000 });
      });
    },
    removeDealMarketingOrderedItem({ state, dispatch }, { item }) {
      const collection = item.type + "s";
      const existingItem = _.find(state.activeDealMarketing[collection], {
        order: item.order
      });

      api
        .delete(`deal_marketing_ordered_items/${existingItem.id}`, existingItem)
        .then(json => {
          dispatch("flash", "Item removed");
          dispatch("loadDealMarketing", json.data.dealToken);
        });
    },
    updateDealMarketingOrderedItem({ state, dispatch }, { item }) {
      const collection = item.type + "s";
      const existingItem = _.find(state.activeDealMarketing[collection], {
        order: item.order
      });

      api
        .patch(`deal_marketing_ordered_items/${existingItem.id}`, existingItem)
        .then(json => {
          dispatch("flash", { message: "Deal saved", timeout: 5000 });
          dispatch("loadDealMarketing", state.activeDeal.token);
        });
    },
    createDealMarketingOrderedItem({ state, commit, dispatch }, { item }) {
      api
        .post(
          `deal_marketings/${
            state.activeDealMarketing.id
          }/deal_marketing_ordered_items`,
          item
        )
        .then(json => {
          commit("setDealMarketingItemId", { item: json.data });
        });
    },
    updateParticipantDealRole({ state, dispatch }, dealId) {
      const { viewerDealRole } = state.activeDeal;
      const deal = { viewerDealRole };

      api.patch(`deal_participations/${dealId}`, deal).then(json => {})
    },
    archiveDeal({ state, getters, commit, dispatch }, dealId) {
      api.delete(`deals/${dealId}`).then(result => {
        commit("clearDeal");
        commit("clearDealMarketing");

        dispatch("loadContactData", state.currentUser.token);
        if (getters.targetTokenParam) {
          commit("removeDealFromNewsfeed", { dealId: dealId, targetToken: getters.targetTokenParam });
        } else if (getters.contactTokenParam) {
          dispatch("loadContactDeals");
        } else if (getters.backchannelTokenParam) {
          commit("refetchBackchannel", getters.backchannelTokenParam);
        } else if (state.route.path === "/home/safezone") {
          commit("setRefetchSafezone", true);
        } else {
          commit("removeDealFromNewsfeed", { dealId: dealId });
        }

        dispatch("loadCreditTracking");
      });
    },
    deleteIntel({ state, getters, commit, dispatch }, intelToken) {
      api.delete(`market_intel_posts/${intelToken}`).then(json => {
        dispatch("flash", "Post deleted successfully");
        commit("clearIntel");

        if (getters.targetTokenParam) {
          commit("removeIntelFromNewsfeed", { intel: json.data, targetToken: getters.targetTokenParam });
        } else if (getters.contactTokenParam) {
          dispatch("loadContactIntel");
        } else if (getters.backchannelTokenParam) {
          commit("refetchBackchannel", getters.backchannelTokenParam);
        } else if (state.route.path === "/home/safezone") {
          commit("setRefetchSafezone", true);
        } else {
          commit("removeIntelFromNewsfeed", { intel: json.data });
        }

        dispatch("loadCreditTracking");
      });
    },
    updateIntel(
      { state, commit, dispatch },
      { postToken, updateTags = false, backDate = false }
    ) {
      const {
        showcaseId,
        createdAt,
        name,
        anonymous,
        eventDate,
        location,
        lat,
        lng,
        deals,
        risks,
        uses,
        regions,
        properties,
        office,
        retail,
        industrial,
        lab,
        healthCare,
        dataCenter,
        notes,
        url,
        file,
        thumbnail,
        privateAudience,
        type
      } = state.activeIntel;
      let cleanRegions;

      if (_.isArray(regions)) {
        cleanRegions = regions.map(r => {
          return { id: r.id, name: r.name };
        });
      }

      const intel = {
        name,
        anonymous,
        dateType: type === "industryEvent" ? eventDate.type : null,
        dates: type === "industryEvent" ? eventDate.dates : null,
        location,
        lat,
        lng,
        deals,
        risks,
        uses,
        regions: cleanRegions,
        properties,
        office,
        retail,
        industrial,
        lab,
        healthCare,
        dataCenter,
        notes,
        url,
        file,
        thumbnail,
        privateAudience,
        updateTags,
        backDate,
        createdAt
      };

      const endpoint = showcaseId
        ? `market_intel_posts/${postToken}?showcase_id=${showcaseId}`
        : `market_intel_posts/${postToken}`;

      api.patch(endpoint, intel).then(json => {
        dispatch("flash", "Intel saved");
        commit("overwriteIntel", { intel: json.data });
        dispatch("loadCreditTracking");
      });
    },
    openIntelCard({}, intelToken) {
      router.push({ path: `/open_intel/${intelToken}` });
    },
    createJobPosting({ state, commit, dispatch }) {
      const {
        name,
        anonymous,
        location,
        lat,
        lng,
        deals,
        risks,
        uses,
        regions,
        notes,
        url,
        file,
        thumbnail,
        privateAudience
      } = state.activeIntel;
      const safezone = state.newRecordIsSafezone;
      const cleanRegions = regions.map(r => {
        return { id: r.id, name: r.name }
      });
      const jobPosting = {
        name,
        anonymous,
        safezone,
        location,
        lat,
        lng,
        deals,
        risks,
        uses,
        regions: cleanRegions,
        notes,
        url,
        file,
        thumbnail,
        privateAudience
      };

      if (state.notepadConversionComment) {
        jobPosting.notepadCommentId = state.notepadConversionComment.id;
      }

      api.post(`job_postings`, jobPosting).then(json => {
        if (!!analytics && !state.adminMode) {
          analytics.track("Market Intel Posted", {
            type: "Job Posting",
            yearWeek: state.yearWeek
          });
        }
        if (privateAudience.length > 0) {
          dispatch("loadBackchannels");
        }
        dispatch("flash", "Job posted");
        commit("clearIntel");
        commit("concludeIntelCreation");
        commit("setNewRecordSafezone", true);
        dispatch("loadCreditTracking");
        if (safezone) {
          router.push({ path: "/home/safezone" });
        } else {
          dispatch("openIntelCard", json.data.token);
        }
      });
    },
    createIndustryEvent({ state, commit, dispatch }) {
      const {
        name,
        anonymous,
        eventDate,
        location,
        lat,
        lng,
        deals,
        risks,
        uses,
        regions,
        notes,
        url,
        file,
        thumbnail,
        privateAudience
      } = state.activeIntel;
      const safezone = state.newRecordIsSafezone;
      const cleanRegions = regions.map(r => {
        return { id: r.id, name: r.name }
      });
      const industryEvent = {
        name,
        anonymous,
        safezone,
        dateType: eventDate.type,
        dates: eventDate.dates,
        location,
        lat,
        lng,
        deals,
        risks,
        uses,
        regions: cleanRegions,
        notes,
        url,
        file,
        thumbnail,
        privateAudience
      };

      if (state.notepadConversionComment) {
        industryEvent.notepadCommentId = state.notepadConversionComment.id;
      }

      api.post(`industry_events`, industryEvent).then(json => {
        if (!!analytics && !state.adminMode) {
          analytics.track("Market Intel Posted", {
            type: "Industry Event",
            yearWeek: state.yearWeek
          });
        }
        if (privateAudience.length > 0) {
          dispatch("loadBackchannels");
        }
        dispatch("flash", "Industry event posted");
        commit("clearIntel");
        commit("concludeIntelCreation");
        commit("setNewRecordSafezone", true);
        dispatch("loadCreditTracking");
        if (safezone) {
          router.push({ path: "/home/safezone" });
        } else {
          dispatch("openIntelCard", json.data.token);
        }
      });
    },
    createDevelopmentNews({ state, commit, dispatch }) {
      const {
        name,
        anonymous,
        properties,
        uses,
        notes,
        url,
        file,
        thumbnail,
        privateAudience
      } = state.activeIntel;
      const safezone = state.newRecordIsSafezone;
      const developmentNews = {
        name,
        anonymous,
        safezone,
        properties,
        uses,
        notes,
        url,
        file,
        thumbnail,
        privateAudience
      };

      if (state.notepadConversionComment) {
        developmentNews.notepadCommentId = state.notepadConversionComment.id;
      }

      api.post(`development_news`, developmentNews).then(json => {
        if (!!analytics && !state.adminMode) {
          analytics.track("Market Intel Posted", {
            type: "Development News",
            yearWeek: state.yearWeek
          });
        }
        if (privateAudience.length > 0) {
          dispatch("loadBackchannels");
        }
        dispatch("flash", "Development news posted");
        commit("clearIntel");
        commit("concludeIntelCreation");
        commit("setNewRecordSafezone", true);
        dispatch("loadCreditTracking");
        if (safezone) {
          router.push({ path: "/home/safezone" });
        } else {
          dispatch("openIntelCard", json.data.token);
        }
      });
    },
    createInfrastructureNews({ state, commit, dispatch }) {
      const {
        name,
        anonymous,
        regions,
        notes,
        url,
        file,
        thumbnail,
        privateAudience
      } = state.activeIntel;
      const safezone = state.newRecordIsSafezone;
      const cleanRegions = regions.map(r => {
        return { id: r.id, name: r.name }
      });
      const infrastructureNews = {
        name,
        anonymous,
        safezone,
        regions: cleanRegions,
        notes,
        url,
        file,
        thumbnail,
        privateAudience
      };

      if (state.notepadConversionComment) {
        infrastructureNews.notepadCommentId = state.notepadConversionComment.id;
      }

      api.post(`infrastructure_news`, infrastructureNews).then(json => {
        if (!!analytics && !state.adminMode) {
          analytics.track("Market Intel Posted", {
            type: "Infrastructure News",
            yearWeek: state.yearWeek
          });
        }
        if (privateAudience.length > 0) {
          dispatch("loadBackchannels");
        }
        dispatch("flash", "Infrastructure news posted");
        commit("clearIntel");
        commit("concludeIntelCreation");
        commit("setNewRecordSafezone", true);
        dispatch("loadCreditTracking");
        if (safezone) {
          router.push({ path: "/home/safezone" });
        } else {
          dispatch("openIntelCard", json.data.token);
        }
      });
    },
    createTenantInMarket({ state, commit, dispatch }) {
      const {
        name,
        anonymous,
        regions,
        notes,
        office,
        retail,
        industrial,
        lab,
        healthCare,
        dataCenter,
        uses,
        url,
        file,
        thumbnail,
        privateAudience
      } = state.activeIntel;
      const safezone = state.newRecordIsSafezone;
      const cleanRegions = regions.map(r => {
        return { id: r.id, name: r.name }
      });
      const tenantInMarket = {
        name,
        anonymous,
        safezone,
        regions: cleanRegions,
        notes,
        office,
        retail,
        industrial,
        lab,
        healthCare,
        dataCenter,
        uses,
        url,
        file,
        thumbnail,
        privateAudience
      };

      if (state.notepadConversionComment) {
        tenantInMarket.notepadCommentId = state.notepadConversionComment.id;
      }

      api.post(`tenant_in_market`, tenantInMarket).then(json => {
        if (!!analytics && !state.adminMode) {
          analytics.track("Market Intel Posted", {
            type: "Tenant in the Market",
            yearWeek: state.yearWeek
          });
        }
        if (privateAudience.length > 0) {
          dispatch("loadBackchannels");
        }
        dispatch("flash", "Tenant in the Market posted");
        commit("clearIntel");
        commit("concludeIntelCreation");
        commit("setNewRecordSafezone", true);
        dispatch("loadCreditTracking");
        if (safezone) {
          router.push({ path: "/home/safezone" });
        } else {
          dispatch("openIntelCard", json.data.token);
        }
      });
    },
    createMarketCommentary({ state, commit, dispatch }) {
      const {
        name,
        anonymous,
        properties,
        regions,
        deals,
        risks,
        uses,
        notes,
        url,
        file,
        thumbnail,
        privateAudience
      } = state.activeIntel;
      const safezone = state.newRecordIsSafezone;
      const cleanRegions = regions.map(r => {
        return { id: r.id, name: r.name }
      });
      const marketCommentary = {
        name,
        anonymous,
        safezone,
        properties,
        regions: cleanRegions,
        deals,
        risks,
        uses,
        notes,
        url,
        file,
        thumbnail,
        privateAudience
      };

      if (state.notepadConversionComment) {
        marketCommentary.notepadCommentId = state.notepadConversionComment.id;
      }

      api.post(`market_commentary`, marketCommentary).then(json => {
        if (!!analytics && !state.adminMode) {
          analytics.track("Market Intel Posted", {
            type: "Market commentary",
            yearWeek: state.yearWeek
          });
        }
        if (privateAudience.length > 0) {
          dispatch("loadBackchannels");
        }
        dispatch("flash", "Market commentary posted");
        commit("clearIntel");
        commit("concludeIntelCreation");
        commit("setNewRecordSafezone", true);
        dispatch("loadCreditTracking");
        if (safezone) {
          router.push({ path: "/home/safezone" });
        } else {
          dispatch("openIntelCard", json.data.token);
        }
      });
    },
    createMarketReport({ state, commit, dispatch }) {
      const {
        name,
        anonymous,
        properties,
        regions,
        deals,
        risks,
        uses,
        notes,
        url,
        file,
        thumbnail,
        privateAudience
      } = state.activeIntel;
      const safezone = state.newRecordIsSafezone;
      const cleanRegions = regions.map(r => {
        return { id: r.id, name: r.name }
      });
      const marketReport = {
        name,
        anonymous,
        safezone,
        properties,
        regions: cleanRegions,
        deals,
        risks,
        uses,
        notes,
        url,
        file,
        thumbnail,
        privateAudience
      };

      if (state.notepadConversionComment) {
        marketReport.notepadCommentId = state.notepadConversionComment.id;
      }

      api.post(`market_reports`, marketReport).then(json => {
        if (!!analytics && !state.adminMode) {
          analytics.track("Market Intel Posted", {
            type: "Market report",
            yearWeek: state.yearWeek
          });
        }
        if (privateAudience.length > 0) {
          dispatch("loadBackchannels");
        }
        dispatch("flash", "Market report posted");
        commit("clearIntel");
        commit("concludeIntelCreation");
        commit("setNewRecordSafezone", true);
        dispatch("loadCreditTracking");
        if (safezone) {
          router.push({ path: "/home/safezone" });
        } else {
          dispatch("openIntelCard", json.data.token);
        }
      });
    },
    createTarget({ state, getters, commit, dispatch }, { announce = true }) {
      const { searchable, name, type, deals, jointVentureRoles, loans, risks, uses, minimum, maximum, leaseMinimum, leaseMaximum, leverageMinimum, leverageMaximum, regions, notes } = state.activeTarget;
      const cleanRegions = regions.map(r => {
        return { id: r.id, name: r.name }
      });
      const target = { searchable, name, type: type || "macro", deals, jointVentureRoles, loans, risks, uses, minimum, maximum, leaseMinimum, leaseMaximum, leverageMinimum, leverageMaximum, regions: cleanRegions, notes };

      return api.post(`targets`, target).then(json => {
        if (!!analytics && !state.adminMode) {
          analytics.track("Target Created", { yearWeek: state.yearWeek });
        }
        if (announce) {
          dispatch("flash", "Target saved");
        }
        if (getters.targetHasPropertySize) {
          dispatch("updateTargetPropertySizes", {
            targetId: json.data.id,
            refetch: false
          });
        }
        commit("clearTarget");
        dispatch("loadCreditTracking");
      });
    },
    updateTarget({ state, getters, commit, dispatch }, targetId) {
      const {
        type,
        name,
        deals,
        jointVentureRoles,
        loans,
        risks,
        uses,
        minimum,
        maximum,
        leaseMinimum,
        leaseMaximum,
        leverageMinimum,
        leverageMaximum,
        regions,
        notes
      } = state.activeTarget;
      const cleanRegions = regions.map(r => {
        return { id: r.id, name: r.name }
      });
      const target = {
        type,
        name,
        deals,
        jointVentureRoles,
        loans,
        risks,
        uses,
        minimum,
        maximum,
        leaseMinimum,
        leaseMaximum,
        leverageMinimum,
        leverageMaximum,
        regions: cleanRegions,
        notes
      };

      api.patch(`targets/${targetId}`, target).then(json => {
        dispatch("flash", "Target saved");

        if (getters.targetTokenParam) {
          dispatch("loadSharedTarget", getters.targetTokenParam);
          dispatch("loadContactData", state.currentUser.token);
          commit("setRefetchTargetPage", true);
        } else if (getters.contactTokenParam) {
          dispatch("loadContactData", state.currentUser.token);
        }

        dispatch("loadCreditTracking");
      });
    },
    updateTargetPropertySizes(
      { state, getters, dispatch },
      { targetId, refetch }
    ) {
      const payload = { uses: state.activeTarget.uses };
      const token = state.activeTarget.token;

      api.patch(`target_property_sizes/${targetId}`, payload).then(json => {
        if (refetch) {
          dispatch("flash", "Target saved");
          dispatch("loadSharedTarget", token || getters.targetTokenParam);
          dispatch("loadContactData", state.currentUser.token);
        }
      });
    },
    updateTargetName({ getters, commit }, { targetId, name }) {
      if (getters.targetTokenParam) {
        commit("setTargetName", name);
      }

      return api.patch(`target_names/${targetId}`, { name });
    },
    updateFollowedTarget({ state, commit, dispatch }, token) {
      const followerNotes = { notes: state.activeTarget.followerNotes };

      commit("isLoading");
      api.patch(`follow/${token}`, followerNotes).then(json => {
        dispatch("flash", "Notes saved successfully");
        commit("addTarget", { target: json.data, id: json.data.id, to: "followed" });
        commit("doneLoading");
      })
    },
    updateContactNotifications({ state, commit, getters, dispatch }, newInfo) {
      const token = getters.contactTokenParam;
      const notifications = { notifications: newInfo };

      commit("isLoading");
      api.patch(`contact_notifications/${token}`, notifications).then(json => {
        const notifications = json.data;

        commit("setContactNotifications", notifications);
        dispatch("flash", "Notifications saved successfully");
        commit("doneLoading");
      })
    },
    updateTargetNotifications({ state, commit, getters, dispatch }, newInfo) {
      const token = getters.targetTokenParam || state.activeTarget.token;
      const notifications = { notifications: newInfo };

      commit("isLoading");
      api.patch(`target_notifications/${token}`, notifications).then(json => {
        const notifications = json.data;

        commit("setTargetNotifications", notifications);
        dispatch("flash", "Notifications saved successfully");
        commit("doneLoading");
      })
    },
    updatePrivacyMode({ state, commit, dispatch }, payload) {
      api.patch(`privacy_mode`, payload).then(user => {
        commit("setUser", new User(user.data));

        dispatch(
          "flash",
          payload.privacyMode ? "Privacy mode enabled" : "Privacy mode disabled"
        );
      });
    },
    updateMapMode({ state, commit, dispatch }, payload) {
      return new Promise(resolve => {
        api.patch(`map_mode`, payload).then(user => {
          commit("setUser", new User(user.data));
          resolve();
        });
      });
    },
    updateUserSearchCategory({ commit }, newCategory) {
      const payload = { searchCategory: newCategory };

      return new Promise(resolve => {
        api.patch(`search_categories`, payload).then(user => {
          commit("setUser", new User(user.data));
          resolve();
        });
      });
    },
    updateMyNotifications({ state, commit, dispatch }, newInfo) {
      const notifications = { notifications: newInfo };

      commit("isLoading");
      api.patch(`my_notifications`, notifications).then(user => {
        dispatch("flash", "Notifications saved successfully");
        if (state.route.path !== "/jobs") {
          commit("setUser", new User(user.data));
        }
        commit("doneLoading");
      });
    },
    loadSharedIntel({ state, commit, dispatch }, token) {
      commit("isLoading");
      api.get(`open_intel/${token}`).then(
        json => {
          commit("mountIntel", { intel: json.data });
          commit("doneLoading");
        },
        failure => {
          dispatch("flash", "Invalid sharing link");
          commit("doneLoading");
          router.push({ path: "/" })
        }
      );
    },
    loadSharedDeal({ state, getters, commit, dispatch }, token) {
      commit("isLoading");

      let query = "";

      if (getters.dealProspectInviteParam) {
        query = `?invite=${getters.dealProspectInviteParam}`;
      }

      return new Promise(resolve => {
        api.get(`open_deal/${token}${query}`).then(
          json => {
            commit("mountDeal", { deal: json.data });
            commit("doneLoading");
            resolve();
          },
          failure => {
            dispatch("flash", "Invalid sharing link");
            commit("doneLoading");
            resolve();
            router.push({ path: "/" })
          }
        );
      });
    },
    loadDealMarketing({ state, getters, commit, dispatch }, token) {
      commit("isLoading");

      let query = "";

      if (getters.dealProspectInviteParam) {
        query = `?invite=${getters.dealProspectInviteParam}`;
      }

      return new Promise(resolve => {
        api.get(`deal_marketings/${token}${query}`).then(
          json => {
            commit("mountDealMarketing", { dealMarketing: json.data });
            commit("doneLoading");
            resolve();
          },
          failure => {
            dispatch("flash", "Deal marketing data failed to load");
            commit("doneLoading");
            resolve();
            router.push({ path: "/" })
          }
        );
      });
    },
    loadSharedTarget({ commit, dispatch }, token) {
      commit("isLoading");

      api.get(`open/${token}`).then(
        json => {
          commit("mountTarget", { target: json.data });
          commit("doneLoading");
        },
        failure => {
          dispatch("flash", "Invalid sharing link");
          commit("doneLoading");
          router.push({ path: "/" });
        }
      );
    },
    updateTargetDeals({ getters }) {
      return new Promise(resolve => {
        api.patch(`target_deals/${getters.targetTokenParam}`).then(json => {
          resolve(json.data);
        });
      });
    },
    loadTargetRumoredDeals({ commit, getters }, url) {
      const endpoint = url ? url : `target_rumored_deals/${getters.targetTokenParam}`;

      commit("isLoading");
      api.get(endpoint).then(json => {
        commit("setTargetRumoredDeals", json.data);
        commit("doneLoading");
      });
    },
    loadTargetDeals({ commit, getters }, url) {
      const endpoint = url ? url : `target_deals/${getters.targetTokenParam}`;

      commit("isLoading");
      api.get(endpoint).then(json => {
        commit("setTargetDeals", json.data);
        commit("doneLoading");
      });
    },
    updateTargetIntel({ getters }) {
      return new Promise(resolve => {
        api.patch(`target_intel/${getters.targetTokenParam}`).then(json => {
          resolve(json.data);
        });
      });
    },
    loadTargetIntel({ commit, getters }, url) {
      const endpoint = url ? url : `target_intel/${getters.targetTokenParam}`;

      commit("isLoading");
      api.get(endpoint).then(json => {
        commit("setTargetIntel", json.data);
        commit("doneLoading");
      });
    },
    loadTargetPeople({ commit, getters }, { url, industryRole }) {
      commit("isLoading");
      api.get(url).then(json => {
        commit("setTargetPeople", { peopleData: json.data, industryRole });
        commit("doneLoading");
      });
    },
    loadSharedTargetGroup({ commit, dispatch }, token) {
      commit("isLoading");
      api.get(`group/${token}`).then(
        json => {
          commit("setActiveContact", json.data);
          commit("doneLoading");
        },
        failure => {
          dispatch("flash", "Invalid sharing link");
          commit("doneLoading");
          router.push({ path: "/" })
        }
      );
    },
    dismissContact({ state, commit }, contactToken) {
      api.post(`dismiss_contact/${contactToken}`).then(json => {
        commit("setRefetchPerson", contactToken);
      });
    },
    dismissProspect({ state, commit }, { contactToken, dealToken }) {
      api.post(`dismiss_prospect/${dealToken}/${contactToken}`).then(json => {
        commit("setRefetchPerson", contactToken);
      });
    },
    pinComment({ state, dispatch, commit, getters }, commentId) {
      api.post(`pin_comment/${commentId}`).then(() => {
        dispatch("loadCreditTracking");
      });
    },
    pinDeal({ state, dispatch, commit, getters }, dealToken) {
      api.post(`pin_deal/${dealToken}`).then(json => {
        commit("overwriteDeal", { deal: json.data });
        dispatch("loadCreditTracking");
      });
    },
    pinIntel({ state, dispatch, commit, getters }, intelToken) {
      const endpoint = getters.targetTokenParam ? `pin_intel/${getters.targetTokenParam}/${intelToken}` : `pin_intel/${intelToken}`

      api.post(endpoint).then(json => {
        commit("overwriteIntel", { intel: json.data });
        dispatch("loadCreditTracking");
      });
    },
    dismissIntel({ state, dispatch, commit, getters }, intelToken) {
      const endpoint = getters.targetTokenParam ? `dismiss_intel/${getters.targetTokenParam}/${intelToken}` : `dismiss_intel/${intelToken}`

      api.post(endpoint).then(json => {
        commit("removeIntelFromNewsfeed", { intel: json.data, targetToken: getters.targetTokenParam });
        dispatch("flash", "Intel dismissed");
        commit("refetchNetworkJobs");
      });
    },
    downvoteIntel({ state, dispatch, commit, getters }, intelToken) {
      const endpoint = getters.targetTokenParam ? `downvote_intel/${getters.targetTokenParam}/${intelToken}` : `downvote_intel/${intelToken}`

      api.post(endpoint).then(json => {
        commit("removeIntelFromNewsfeed", { intel: json.data, targetToken: getters.targetTokenParam });
      });
    },
    unpinComment({ state, dispatch, commit, getters }, commentId) {
      api.delete(`pin_comment/${commentId}`).then(() => {
        dispatch("loadCreditTracking");
      });
    },
    unpinDeal({ state, dispatch, commit, getters }, dealToken) {
      api.delete(`pin_deal/${dealToken}`).then(json => {
        commit("overwriteDeal", { deal: json.data });
        dispatch("loadCreditTracking");
      });
    },
    unpinIntel({ state, dispatch, commit, getters }, intelToken) {
      const endpoint = getters.targetTokenParam ? `pin_intel/${getters.targetTokenParam}/${intelToken}` : `pin_intel/${intelToken}`

      api.delete(endpoint).then(json => {
        commit("overwriteIntel", { intel: json.data });
        dispatch("loadCreditTracking");
      });
    },
    followTarget({ state, dispatch, commit, getters }, targetToken) {
      api.post(`follow/${targetToken}`).then(json => {
        commit("mountTarget", { target: json.data });
        if (getters.signedIn) {
          dispatch("loadCurrentUser");
        }
      });
    },
    unfollowTarget({ commit, dispatch }, targetToken) {
      api.delete(`unfollow/${targetToken}`).then(json => {
        commit("mountTarget", { target: json.data });
        dispatch("loadCurrentUser");
      });
    },
    followDeal({ state, dispatch, commit, getters }, dealToken) {
      api.post(`follow_deal/${dealToken}`).then(json => {
        dispatch("flash", "Deal followed");
        commit("mountDeal", { deal: json.data });
        if (getters.signedIn && state.route.path !== "/pipeline") {
          dispatch("loadCurrentUser");
        }
      });
    },
    unfollowDeal({ state, commit, dispatch }, dealToken) {
      api.delete(`unfollow_deal/${dealToken}`).then(json => {
        dispatch("flash", "Deal unfollowed");
        commit("mountDeal", { deal: json.data });
        if (state.route.path !== "/pipeline") {
          dispatch("loadCurrentUser");
        }
      });
    },
    unfollowProperty({ state, commit, dispatch }, propertyId) {
      api.delete(`unfollow_property/${propertyId}`).then(json => {
        dispatch("flash", "Property removed from watchlist");
        commit("setRefetchWatchlist", true);
        dispatch("loadCurrentUser");
        router.push({ path: `/home/watchlist` });
      });
    },
    removeFollowedPropertyMembership({ state, commit, dispatch }, { followingId, membershipId }) {
      api
        .delete(
          `property_followings/${followingId}/property_following_memberships/${membershipId}`
        )
        .then(json => {
          dispatch("flash", "Property removed from watchlist");
          commit("setRefetchWatchlist", true);
          dispatch("loadCurrentUser");
          router.push({ path: `/home/watchlist` });
        });
    },
    followTargetGroup({ commit, dispatch, getters }, { targetGroupToken, registering = false }) {
      api.post(`follow_group/${targetGroupToken}`).then(json => {
        if (getters.targetGroupTokenParam && !registering) {
          commit("setActiveContact", json.data);
        }
        if (getters.signedIn) {
          dispatch("loadCurrentUser");
        }
      });
    },
    followContact({ state, commit, dispatch, getters }, { contactToken, message = null, registering = false}) {
      api.post(`follow_contact/${contactToken}`, { message }).then(json => {
        dispatch("flash", "Contact followed");
        if (getters.contactTokenParam && !registering) {
          dispatch("loadContactData", contactToken);
        }
        if (state.route.path === "/network/suggestions") {
          commit("setRefetchPerson", contactToken);
          dispatch("loadMyContacts");
        }
        dispatch("loadCreditTracking");
      });
    },
    unfollowContact({ commit, dispatch, getters }, contactToken) {
      api.delete(`follow_contact/${contactToken}`).then(json => {
        dispatch("flash", "Contact unfollowed");
        if (getters.contactTokenParam) {
          dispatch("loadContactData", contactToken);
        }
        dispatch("loadCreditTracking");
      });
    },
    archiveTarget({ state, getters, commit, dispatch }, targetId) {
      api.delete(`targets/${targetId}`).then(result => {
        commit("clearTarget");

        if (getters.signedIn) {
          dispatch("loadContactData", state.currentUser.token);
        }
      });
    },
    createRegion({ state, getters, commit, dispatch }, { announce = true }) {
      const { name } = state.activeRegion;
      const region = {
        name,
        coordinates: getters.activeRegionCoordinates,
        radius: getters.activeRegionRadius,
        shape: getters.activeRegionShape
      };

      return new Promise(resolve => {
        api.post(`regions`, region).then(
          json => {
            if (!!analytics && !state.adminMode) {
              analytics.track("Region Created", { yearWeek: state.yearWeek });
            }
            if (announce) {
              dispatch("flash", "Region saved");
            }

            if (state.route.path === "/off-market/card-manager") {
              commit("setTemporaryAccess", { type: "region", data: json.data });
            } else if (state.route.path === "/home/watchlist") {
              commit("setWatchlistProximityRegion", json.data);
            } else if (_.includes(state.route.path, "/property-watchlist/") && _.includes(state.route.path, "/settings")) {
              commit("setWatchlistProximityRegion", json.data);
            } else {
              dispatch("addRegionWithGetters", json.data);
            }
            resolve();
            commit("clearRegion");

            if (getters.isStrategicMap) {
              commit("setTemporaryAccess", "refetchNearbyRegions");
            }
          },
          failure => {
            dispatch("flash", "Overlapping or invalid polygon. Please try again.");
          }
        );
      })
    },
    updateRegion({ state, getters, commit, dispatch }, { announce = true, regionId }) {
      const { name } = state.activeRegion;
      const region = {
        name,
        coordinates: getters.activeRegionCoordinates,
        radius: getters.activeRegionRadius,
        shape: getters.activeRegionShape
      };

      api.patch(`regions/${regionId}`, region).then(
        json => {
          if (announce) {
            dispatch("flash", "Region saved");
          }

          if (state.route.path === "/off-market/card-manager") {
            commit("setTemporaryAccess", { type: "region", data: json.data });
          } else {
            dispatch("addRegionWithGetters", json.data);
          }
          commit("clearRegion");

          if (getters.targetTokenParam) {
            dispatch("loadSharedTarget", getters.targetTokenParam);
            dispatch("loadContactData", state.currentUser.token);
          } else if (getters.contactTokenParam) {
            if (state.currentUser.admin) {
              dispatch("loadContactData", getters.contactTokenParam);
            } else {
              dispatch("loadContactData", state.currentUser.token);
            }
          }

          dispatch("loadCreditTracking");
        },
        failure => {
          dispatch("flash", "Overlapping or invalid polygon. Please try again.");
        }
      )
    },
    addPropertyToWatchlist({ state, commit, dispatch }, property) {
      return new Promise(resolve => {
        api.post(`follow_property/${property.id}`).then(json => {
          dispatch("flash", `${property.name} is now on your watchlist!`);
          commit("clearDeduplicate");
          commit("clearModal");
          commit("setRefetchWatchlist", true);
          dispatch("loadCurrentUser");

          if (!!analytics && !state.adminMode) {
            analytics.track("Property Watchlisted", {
              name: property.name,
              yearWeek: state.yearWeek
            });
          }

          resolve(json.data);
        });
      });
    },
    updateWatchlistProximity({ state, commit, dispatch }, following) {
      return new Promise(resolve => {
        const {
          proximityType,
          radius,
          region,
          competitiveSetProperties
        } = state.propertyWatchlistProximity;
        const payload = {
          proximityType,
          radius: _.toNumber(radius),
          regionId: _.get(region, "id", null),
          competitiveSetPropertyIds: competitiveSetProperties.map(p => p.id)
        };

        api.patch(`property_followings/${following.id}`, payload).then(json => {
          dispatch(
            "flash",
            `Alert proximity setings updated for ${following.property.name}!`
          );
          commit("closeModal");
          commit("setRefetchWatchlist", true);
          dispatch("loadCurrentUser");
          resolve();
        });
      });
    },
    createProperty(
      { state, commit, dispatch },
      { announce = true, propertyPageRedirect = false }
    ) {
      const {
        name,
        location,
        lat,
        lng,
        cityState,
        possibleUses,
        office,
        retail,
        industrial,
        multifamily,
        lab,
        healthCare,
        seniorHousing,
        dataCenter,
        studentHousing,
        hotel,
        selfStorage,
        parking
      } = state.activeProperty;
      const property = {
        name,
        location,
        lat,
        lng,
        cityState,
        possibleUses,
        office,
        retail,
        industrial,
        multifamily,
        lab,
        healthCare,
        seniorHousing,
        dataCenter,
        studentHousing,
        hotel,
        selfStorage,
        parking
      };

      commit("isLocalLoading");
      api.post(`properties`, property).then(json => {
        if (!!analytics && !state.adminMode) {
          analytics.track("Property Created", { yearWeek: state.yearWeek });
        }
        if (announce) {
          dispatch("flash", "Property saved");
        }
        commit("doneLocalLoading");

        if (state.route.path === "/off-market/card-manager") {
          commit("setTemporaryAccess", { type: "property", data: json.data });
        } else if (state.route.path === "/home/watchlist") {
          setTimeout(() => {
            if (state.modal) {
              commit("addWatchlistProximityCompetitiveSetProperty", json.data);
            } else {
              dispatch("addPropertyToWatchlist", json.data).then(following => {
                if (propertyPageRedirect) {
                  router.push({ path: `/property-watchlist/${following.id}/` });
                }
              });
            }
          }, 100);
        } else if (_.includes(state.route.path, "/property-watchlist/") && _.includes(state.route.path, "/settings")) {
          commit("addWatchlistProximityCompetitiveSetProperty", json.data);
        } else {
          dispatch("addPropertyWithGetters", json.data);
        }
        commit("clearProperty");
      });
    },
    updateProperty(
      { state, commit, dispatch },
      { announce = true, clear = true, propertyId }
    ) {
      const {
        name,
        coverPhoto,
        location,
        lat,
        lng,
        cityState,
        possibleUses,
        office,
        retail,
        industrial,
        multifamily,
        lab,
        healthCare,
        seniorHousing,
        dataCenter,
        studentHousing,
        hotel,
        selfStorage,
        parking,
        landArea
      } = state.activeProperty;
      const property = {
        name,
        coverPhoto,
        location,
        lat,
        lng,
        cityState,
        possibleUses,
        office,
        retail,
        industrial,
        multifamily,
        lab,
        healthCare,
        seniorHousing,
        dataCenter,
        studentHousing,
        hotel,
        selfStorage,
        parking,
        landArea
      };

      if (_.includes(property.coverPhoto, "cloudfront.net")) {
        delete property.coverPhoto;
      }

      return new Promise(resolve => {
        api.patch(`properties/${propertyId}`, property).then(json => {
          if (announce) {
            dispatch("flash", "Property saved");
          }

          if (state.route.path === "/off-market/card-manager") {
            commit("setTemporaryAccess", { type: "property", data: json.data });
          } else if (_.includes(state.route.path, "property-watchlist")) {
            commit("mountProperty", { property: json.data });
          } else {
            dispatch("addPropertyWithGetters", json.data);
          }

          if (clear) {
            commit("clearProperty");
          }

          resolve(json.data);
        });
      });
    },
    updateContactInfo({ state, commit, dispatch }, newInfo) {
      if (_.includes(newInfo.avatar, "cloudfront.net")) {
        delete newInfo.avatar;
      }

      commit("isLoading");
      api.patch("contact_info", newInfo).then(user => {
        dispatch("flash", "Contact info updated successfully.");
        commit("setUser", new User(user.data));
        dispatch("loadContactData", state.currentUser.token);

        if (!!analytics && !state.adminMode) {
          analytics.track("Edited Profile Info", { yearWeek: state.yearWeek });
        }
        commit("doneLoading");
      });
    },
    updateSearchPermissions({ commit, dispatch }, newPermissions) {
      commit("isLoading");
      api.patch("search_permissions", newPermissions).then(user => {
        dispatch("flash", "Search permissions updated successfully.");
        commit("setUser", new User(user.data));
        commit("doneLoading");
      });
    },
    updateMapSettings({ commit, dispatch }, newSettings) {
      commit("isLoading");
      api.patch("map_settings", newSettings).then(user => {
        dispatch("flash", "Map settings updated successfully.");
        commit("setUser", new User(user.data));
        commit("doneLoading");
      });
    },
    toggleUserSearchability({ commit, dispatch }) {
      commit("isLoading");
      api.patch("user_searchability").then(user => {
        dispatch("flash", "Search visibility updated successfully.");
        commit("setUser", new User(user.data));
        commit("doneLoading");
      });
    },
    toggleTargetSearchability({ state, commit, dispatch }, { targetId, targetToken }) {
      commit("isLoading");
      api.patch("target_searchability", { target_id: targetId }).then(json => {
        dispatch("flash", "Market visibility updated successfully.");
        dispatch("loadContactData", state.currentUser.token);
        dispatch("loadSharedTarget", targetToken);
        commit("doneLoading");
      });
    },
    toggleTargetShowcase({ state, commit, dispatch }, { targetId, targetToken }) {
      commit("isLoading");
      api.patch("target_showcase", { target_id: targetId }).then(json => {
        dispatch("flash", "Market showcase status updated successfully.");
        dispatch("loadSharedTarget", targetToken);
        commit("doneLoading");
      });
    },
    toggleDealConfidentiality({ commit, dispatch }, dealId) {
      commit("isLoading");
      api.patch("deal_confidentiality", { deal_id: dealId }).then(json => {
        dispatch("flash", "Confidentiality updated successfully.");
        commit("toggleNewDealConfidentiality");
        commit("doneLoading");
      });
    },
    toggleDealCommentability({ commit, dispatch }, dealId) {
      commit("isLoading");
      api.patch("deal_commentability", { deal_id: dealId }).then(json => {
        dispatch("flash", "Commentability updated successfully.");
        commit("doneLoading");
      });
    },
    setIndustryRole({ commit, dispatch }, role) {
      commit("isLoading");
      api.patch("industry_role", { role }).then(
        user => {
          dispatch("flash", "Industry role updated successfully.");
          commit("setUser", new User(user.data));
          commit("doneLoading");
        },
        failure => {
          dispatch("flash", "You can only change roles once every 6 months.");
          commit("doneLoading");
        }
      );
    },
    setIndustrySubRole({ commit, dispatch }, role) {
      commit("isLoading");
      api.patch("industry_sub_role", { role }).then(user => {
        dispatch("flash", "Industry role updated successfully.");
        commit("setUser", new User(user.data));
        commit("doneLoading");
      });
    },
    saveSharedGroup({ state, commit, dispatch }) {
      const targetGroup = { targets: state.sharedGroupTargets };

      api.post(`target_groups`, targetGroup).then(json => {
        const token = json.data;
        dispatch("flash", "Target group created successfully.");
        router.push({ path: `/group/${token}` });
        commit("cancelSharedGroup");
      });
    },
    addPropertyWithGetters({ commit, getters }, property) {
      commit("addPropertyToSelection", { getters, property });
    },
    removePropertyWithGetters({ commit, getters }, property) {
      commit("removePropertyFromSelection", { getters, property });
    },
    addRegionWithGetters({ commit, getters }, region) {
      commit("addRegionToSelection", { getters, region });
    },
    removeRegionWithGetters({ commit, getters }, region) {
      commit("removeRegionFromSelection", { getters, region });
    },
    addDealTypeWithGetters({ state, commit, getters }, deal) {
      commit("addDealToSelection", { getters, deal });
      if (!state.intelCreationInProcess) {
        commit("cleanUpDealTypes", { getters });
      }
    },
    removeDealTypeWithGetters({ state, commit, getters }, deal) {
      commit("removeDealFromSelection", { getters, deal });
      if (!state.intelCreationInProcess) {
        commit("cleanUpDealTypes", { getters });
      }
    },
    clearActiveRegionShapes({ commit }) {
      commit("clearActiveRegionCircle");
      commit("clearActiveRegionPolygon");
      commit("clearActiveRegionRectangle");
      commit("setActiveRegionMapEditing", false);
      commit("setActiveRegionNamingShape", false);
    },
    flash({ commit }, payload) {
      if (_.isString(payload)) {
        commit("setFlash", payload);
        setTimeout(() => {
          commit("clearFlash");
        }, 3000);
      } else if (_.isObject(payload)) {
        commit("setFlash", payload.message);
        setTimeout(() => {
          commit("clearFlash");
        }, payload.timeout);
      }
    },
    confirmOnboardingReset({ state, getters, commit }) {
      Swal.fire(sweetAlertOptions).then((result) => {
        if (result.value) {
          if (getters.onboarding && !getters.onboarding1) {
            commit("clearOnboarding");
            commit("clearTarget");
            commit("clearRegion");

            router.push({ path: "/start/1" });
          } else if (getters.creatingTarget && !getters.creatingTarget1) {
            commit("clearOnboarding");
            commit("clearTarget");
            commit("clearRegion");

            router.push({ path: "/targets/new/1" });
          } else if (getters.creatingDeal && !getters.creatingDeal1) {
            commit("clearDeal");
            commit("clearDealMarketing");
            commit("clearProperty");
            commit("setNewRecordSafezone", getters.privacyMode);

            if (getters.creatingIndirectActiveDeal) {
              router.push({
                path: "/deals/new/1",
                query: { active: true, indirect: true }
              });
            } else if (getters.creatingActiveDeal) {
              router.push({ path: "/deals/new/1", query: { active: true } });
            } else {
              router.push({ path: "/deals/new/1" });
            }
          } else if (getters.creatingIntel && !getters.creatingIntel1) {
            commit("clearIntel");
            commit("clearRegion");
            commit("clearProperty");
            commit("setNewRecordSafezone", getters.privacyMode);

            router.push({ path: "/intel/new/privacy" });
          }
        }
      });
    },
    updateDealMarketingConfidentialityEmailFields({ state, commit, dispatch }, payload) {
      api
        .patch(`confidentiality_email_fields/${state.activeDeal.token}`, payload)
        .then(json => {
          commit("mountDealMarketing", { dealMarketing: json.data });
          dispatch("flash", "CA contact email updated.");
        });
    },
    updateDealMarketingTeaserEmailFields({ state, commit, dispatch }, payload) {
      api
        .patch(`teaser_email_fields/${state.activeDeal.token}`, payload)
        .then(json => {
          commit("mountDealMarketing", { dealMarketing: json.data });
          dispatch("flash", {
            message: "Teaser email fields updated. Try testing the teaser now.",
            timeout: 5000
          });
          commit("closeModal");
        });
    },
    sendTestTeaser({ state, dispatch }) {
      return new Promise(resolve => {
        api.post(`test_teaser/${state.activeDeal.token}`).then(json => {
          dispatch("flash", {
            message: "Teaser sent. You should receive it shortly.",
            timeout: 5000
          });
          resolve();
        });
      });
    },
    confirmTeaserReblast({ state, commit }) {
      Swal.fire(reblastTeaserOptions).then(result => {
        if (result.value) {
          api.patch(`launch/${state.activeDeal.token}`).then(json => {
            commit("mountDeal", { deal: json.data });

            if (!!analytics && !state.adminMode) {
              analytics.track("Reblasted deal teaser", { yearWeek: state.yearWeek });
            }
          });
        }
      });
    },
    confirmDealLaunch({ state, commit }) {
      const sweetAlert = state.activeDealMarketing.sendTeaserEmails
        ? dealLaunchOptions
        : dealLaunchNoTeaserOptions;

      Swal.fire(sweetAlert).then(result => {
        if (result.value) {
          api.post(`launch/${state.activeDeal.token}`).then(json => {
            let deal = json.data;

            deal.state = "live";
            commit("mountDeal", { deal });

            if (!!analytics && !state.adminMode) {
              analytics.track("Launched Deal", { yearWeek: state.yearWeek });
            }
          });
        }
      });
    },
    confirmDealWithdrawal({ dispatch }) {
      Swal.fire(dealWithdrawalOptions).then(result => {
        if (result.value) {
          dispatch("withdrawDeal");
          router.push({ path: "/" });
        }
      });
    },
    confirmDealAwarding({ dispatch }) {
      Swal.fire(dealAwardingOptions).then(result => {
        if (result.value) {
          dispatch("awardDeal");
        }
      });
    },
    awardDeal({ state, commit }) {
      api.post(`award/${state.activeDeal.token}`).then(json => {
        commit("mountDeal", { deal: json.data });
      });
    },
    withdrawDeal({ state, commit, dispatch }) {
      api.post(`withdraw/${state.activeDeal.token}`).then(json => {
        commit("mountDeal", { deal: json.data });
        dispatch("loadContactData", state.currentUser.token);
      });
    },
    dropDeal({ state, commit }) {
      api.delete(`award/${state.activeDeal.token}`).then(json => {
        commit("mountDeal", { deal: json.data });
      });
    },
    reportDealStateChange({ dispatch }, payload) {
      api.post(`deal_state_changes`, payload).then(json => {
        dispatch("flash", "Deal status report submitted");
      });
    },
    cancelClosedDealInvitations({ state, commit }) {
      api.delete(`close/${state.activeDeal.token}`).then(json => {});
    },
    declineDeal(
      { state, getters, commit, dispatch },
      {
        dealToken = null,
        dealId = null,
        participationId = null,
        invitationId = null
      }
    ) {
      const endpointToken = dealToken ? dealToken : state.activeDeal.token;

      let query = "";

      if (getters.dealProspectInviteParam) {
        query = `?invite=${getters.dealProspectInviteParam}`;
      } else if (participationId) {
        query = `?participant=${participationId}`;
      } else if (invitationId) {
        query = `?invite_id=${invitationId}`;
      }

      return new Promise(resolve => {
        api.post(`decline/${endpointToken}${query}`).then(json => {
          if (participationId || invitationId) {
            commit("mountDeal", { deal: json.data });
            dispatch("flash", "Prospect declined.");
            resolve();
          } else if (query !== "") {
            router.push({ path: "/" });
            dispatch("flash", {
              message: "Deal declined.",
              timeout: 5000
            });
            resolve();
          } else if (dealToken) {
            dispatch("flash", "Deal dismissed.");
            commit("removeDealFromNewsfeed", {
              dealId,
              targetToken: getters.targetTokenParam
            });
            commit("refetchIndirectPipeline");
            resolve();
          } else {
            commit("mountDeal", { deal: json.data });
            dispatch("flash", {
              message:
                "Deal declined. If you want to keep tracking it, follow the deal before leaving this page.",
              timeout: 5000
            });
            resolve();
          }
        });
      });
    },
    registerDeal({ state, commit, dispatch }, dealToken = null) {
      const token = dealToken || state.activeDeal.token;

      api.post(`register_deal/${token}`).then(json => {
        commit("mountDeal", { deal: json.data });
        dispatch("flash", {
          message: "Thanks for your interest! Deal document access granted.",
          timeout: 5000
        });
        commit("closeXLModal");
        router.push({ path: `/deal_offerings/${state.activeDeal.urlHandle || state.activeDeal.token}/documents` });
      });
    },
    createDealInquiry({ state, commit, dispatch }, dealToken = null) {
      const token = dealToken || state.activeDeal.token;

      api.post(`inquire/${token}`).then(json => {
        commit("mountDeal", { deal: json.data });
        dispatch("flash", {
          message: "Thanks for your interest! We'll be in touch shortly.",
          timeout: 5000
        });
      });
    },
    prospectDiscussionComplete({ state }, { prospect }) {
      return new Promise(resolve => {
        api.post(`discuss/${prospect.participationId}`).then(() => {
          resolve();
        });
      });
    },
    createPurchaseableImpression({ state }, id) {
      api.post(`purchaseable_impressions/${id}`);
    },
    createBountyImpression({ state }, id) {
      api.post(`bounty_impressions/${id}`);
    },
    createCommentImpression({ state }, id) {
      api.post(`comment_impressions/${id}`);
    },
    createDealImpression({ state }, token) {
      api.post(`deal_impressions/${token}`);
    },
    createIntelImpression({ state }, token) {
      api.post(`market_intel_impressions/${token}`);
    },
    createContactImpression({ state }, token) {
      api.post(`contact_impressions/${token}`);
    },
    dealMarketingImpression({ state, getters }) {
      let query = "";

      if (getters.dealProspectInviteParam) {
        query = `?invite=${getters.dealProspectInviteParam}`;
      }

      api.post(
        `deal_marketing_impressions/${state.activeDealMarketing.id}${query}`
      );
    },
    updateDealMarketingPhotoCaption({ state }, { photoId, caption }) {
      return api.patch(`deal_marketing_photo_captions/${photoId}`, { caption });
    },
    deleteDealMarketingPhoto({ state }, { photoId }) {
      return api.delete(
        `deal_marketings/${state.activeDealMarketing.id}/deal_marketing_photos/${photoId}`
      );
    },
    deleteDealCoverPhoto({ state }, { dealId }) {
      return api.delete(`deal_cover_photos/${dealId}`);
    },
    archiveBounty({ getters }, bountyId) {
      const adminParam = getters.isAdmin ? "?admin=true" : "";

      return new Promise(resolve => {
        api.delete(`attached_bounties/${bountyId}${adminParam}`).then(json => {
          resolve();
        });
      });
    },
    archiveComment({ state }, commentId) {
      return new Promise(resolve => {
        api.delete(`comments/${commentId}`).then(json => {
          resolve();
        });
      });
    },
    unlinkComment({ state }, commentId) {
      return new Promise(resolve => {
        api.delete(`notepad_links/${commentId}`).then(json => {
          resolve();
        });
      });
    },
    reportComment({ state }, commentId) {
      return new Promise(resolve => {
        api.post(`comment_reports/${commentId}`).then(json => {
          resolve();
        });
      });
    },
    updateComment({ state }, { body, commentId }) {
      return new Promise(resolve => {
        api.patch(`comments/${commentId}`, { body }).then(json => {
          resolve();
        });
      });
    },
    createComment({ state, commit, dispatch }, payload) {
      return new Promise(resolve => {
        api.post(`comments`, payload).then(json => {
          const content = json.data.content;

          switch (content.newsfeedType) {
            case "deal":
              commit("overwriteDeal", { deal: content });
              break;
            case "intel":
              commit("overwriteIntel", { intel: content });
          }

          dispatch("loadCreditTracking");
          resolve(json);
        });
      });
    },
    loadComments({ state, dispatch }, { contentType, contentToken }) {
      return new Promise(resolve => {
        api.get(`comments/${contentType}/${contentToken}`).then(json => {
          dispatch("loadCreditTracking");
          resolve(json);
        });
      });
    },
    loadAttachedBounties({ state, dispatch }, { contentType, contentToken }) {
      return new Promise(resolve => {
        api.get(`attached_bounties/${contentType}/${contentToken}`).then(json => {
          resolve(json);
        });
      });
    },
    loadInstantMessagingConsent({ state }, { contentType, contentToken }) {
      return new Promise(resolve => {
        api
          .get(`instant_messaging/${contentType}/${contentToken}`)
          .then(json => {
            resolve(json.data.consent);
          });
      });
    },
    createInstantMessagingConsent({ dispatch }, { contentType, contentToken }) {
      api
        .post(`instant_messaging/${contentType}/${contentToken}`)
        .then(json => {
          dispatch("flash", "Alerts updated successfully");
        });
    },
    destroyInstantMessagingConsent({ dispatch }, { contentType, contentToken }) {
      api
        .delete(`instant_messaging/${contentType}/${contentToken}`)
        .then(json => {
          dispatch("flash", "Alerts updated successfully");
        });
    },
    updateNewsfeedSortBy({ state, commit, dispatch }, sortBy) {
      const payload = { sortBy };

      api.patch(`newsfeed_sort_by`, payload).then(json => {
        commit("setCurrentUserNewsfeedSortBy", sortBy);
        dispatch("loadNewsfeed");
      });
    },
    createBackchannel({ state, dispatch }, { privateAudience }) {
      const payload = { privateAudience };

      return new Promise(resolve => {
        api
          .post(`contact_backchannels`, payload)
          .then(json => {
            dispatch("loadBackchannels");
            resolve(json.data.backchannelToken);
          });
      });
    },
    sendPostToBackchannels(
      { state, dispatch },
      { privateAudience, contentType, contentToken }
    ) {
      const payload = { privateAudience };

      api
        .post(`backchannels/${contentType}/${contentToken}`, payload)
        .then(json => {
          dispatch("loadBackchannels");
          dispatch("flash", "Shared successfully");
        });
    },
    inviteToWatchlistProperty({ state, commit, dispatch }, { message, propertyFollowingId }) {
      let newMembers = state.newBackchannel;

      newMembers.message = message;

      api.post(`property_followings/${propertyFollowingId}/property_following_memberships`, newMembers).then(json => {
        dispatch("flash", "Watchlist property members added!");
        commit("clearNewBackchannel");

        router.push({
          path: `/property-watchlist/${propertyFollowingId}/discussion`
        });
      });
    },
    sendNewBackchannelMessage({ state, commit, dispatch }, { message }) {
      let newBackchannel = state.newBackchannel;

      newBackchannel.message = message;

      api.post(`backchannel_messages`, newBackchannel).then(json => {
        const token = json.data.backchannelToken;
        const propertyFollowingId = json.data.propertyFollowingId;

        dispatch("flash", "Private message sent!");
        commit("clearNewBackchannel");
        dispatch("loadBackchannels");

        if (propertyFollowingId) {
          router.push({
            path: `/property-watchlist/${propertyFollowingId}/discussion`
          });
        } else if (token) {
          router.push({
            path: `/backchannel/${token}`
          });
        } else {
          router.push({ path: "/" });
        }
      });
    },
    sendExistingBackchannelMessage({ state, commit }, { message, backchannelToken }) {
      const payload = { message };

      api
        .patch(`backchannel_messages/${backchannelToken}`, payload)
        .then(json => {
          commit("refetchBackchannel", backchannelToken);
        });
    },
    reopenPrivateChannel({ dispatch }, { token }) {
      return new Promise(resolve => {
        api.post(`backchannel_visibility/${token}`).then(() => {
          dispatch("loadBackchannels");
          resolve();
        });
      });
    },
    closePrivateChannel({ dispatch }, { token }) {
      api.delete(`backchannel_visibility/${token}`).then(() => {
        dispatch("loadBackchannels");
        dispatch("flash", "Private channel closed. You can reopen it any time.");
      })
    },
    closeWatchlistProperty({ commit, dispatch }, { id }) {
      api.delete(`property_following_visibility/${id}`).then(() => {
        commit("setRefetchWatchlist", true);
        dispatch("loadCurrentUser");
        dispatch("flash", "Watchlist property hidden. You can display it again any time.");
      })
    },
    sendBountyBidChatMessage({ state, commit }, { message, bidId }) {
      const payload = { message };

      api.post(`bid_messages/${bidId}`, payload).then(json => {
        commit("refetchBidChat", bidId);
      });
    },
    sendNotepadMessage(
      { state, commit },
      { message, contentToken, contentType }
    ) {
      const payload = { message, contentToken, contentType };

      api.post(`notepad_messages`, payload).then(json => {
        commit("refetchNotepad", true);
      });
    },
    removeURLThumbnail({ commit, dispatch }, { contentType, contentToken }) {
      api.delete(`url_thumbnails/${contentType}/${contentToken}`).then(json => {
        dispatch("flash", "URL image deleted");
        commit("removeCardImage", contentToken);
      });
    },
    logExternalLinkClick({ dispatch }, { contentType, contentToken }) {
      api.post(`external_link_clicks/${contentType}/${contentToken}`);
    },
    toggleDealPremium({ commit, dispatch }, dealId) {
      commit("isLoading");
      api.patch("premium_deals", { deal_id: dealId }).then(json => {
        dispatch("flash", "Deal premium status successfully changed.");
        commit("doneLoading");
      });
    },
    toggleIntelPremium({ commit, dispatch }, intelToken) {
      commit("isLoading");
      api.patch("premium_intel", { token: intelToken }).then(json => {
        dispatch("flash", "Intel premium status successfully changed.");
        commit("doneLoading");
      });
    },
    toggleCommentPremium({ commit, dispatch }, commentId) {
      commit("isLoading");
      api.patch("premium_comments", { comment_id: commentId }).then(json => {
        dispatch("flash", "Comment premium status successfully changed.");
        commit("doneLoading");
      });
    },
    createCustomerPaymentPortalSession({}) {
      api.post(`customer_payment_portals`).then(json => {
        const portalPath = json.data.url;

        window.open(portalPath, "_blank");
      });
    },
    createPayoutsDashboardSession({}) {
      api.get(`payment_connected_accounts`).then(json => {
        const dashboardPath = json.data.url;

        window.open(dashboardPath, "_blank");
      });
    }
  }
});
