<template>
  <div class="wrapper">
    <heading>When is the event?</heading>
    <form @submit.prevent novalidate class="mb-2">
      <section class="vertical">
        <option-select
          v-for="option in options"
          :key="option.name"
          option-type="intelEventDates"
          setter-name="setEventDateMode"
          setter-type="commit"
          :store-record="activeIntel.eventDate.type"
          :option-record="option"
          layout="vertical"
          :options-count="options.length" />
      </section>
      <v-date-picker :mode="dateMode" v-model="eventDates" :color="color" is-inline />
      <button v-if="postToken" @click="confirmPrivacy" type="button" class="cta mt-8" :class="{ complete: hasDates && !privacyMode, 'privacy-complete': hasDates && privacyMode }" tabindex="999">
        <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
          <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
        </svg>
        Confirm privacy and edit
      </button>
      <button v-else @click="next" type="submit" class="cta mt-8" :class="{ complete: hasDates && !privacyMode, 'privacy-complete': hasDates && privacyMode }" tabindex="999">
        <svg v-if="privacyMode" class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 384 512">
          <path fill="currentColor" d="M186.1.09C81.01 3.24 0 94.92 0 200.05v263.92c0 14.26 17.23 21.39 27.31 11.31l24.92-18.53c6.66-4.95 16-3.99 21.51 2.21l42.95 48.35c6.25 6.25 16.38 6.25 22.63 0l40.72-45.85c6.37-7.17 17.56-7.17 23.92 0l40.72 45.85c6.25 6.25 16.38 6.25 22.63 0l42.95-48.35c5.51-6.2 14.85-7.17 21.51-2.21l24.92 18.53c10.08 10.08 27.31 2.94 27.31-11.31V192C384 84 294.83-3.17 186.1.09zM128 224c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm128 0c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path>
        </svg>
        Next
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../../components/onboarding/heading";
import OptionSelect from "../../../components/onboarding/option-select";
import Swal from "sweetalert2";
import anonymousPostUpdateOptions from "../../../sweet-alert-always-anonymous-update-options";

export default {
  components: { Heading, OptionSelect },
  props: ["postToken"],
  data() {
    return {
      options: [
        {
          name: "On a single date",
          value: "single"
        },
        {
          name: "On multiple dates",
          value: "multiple"
        },
        {
          name: "Across a date range",
          value: "range"
        }
      ]
    };
  },
  computed: {
    ...mapState(["activeIntel", "intelCreationInProcess"]),
    ...mapGetters(["creatingIntel", "privacyMode"]),
    color() {
      return this.privacyMode ? "pink" : "indigo";
    },
    hasDates() {
      if (_.isArray(this.eventDates)) {
        return this.eventDates.length > 0;
      } else {
        return this.eventDates !== null && this.eventDates !== "";
      }
    },
    eventDates: {
      get() {
        return this.activeIntel.eventDate.dates;
      },
      set(dates) {
        let newDates;
        let rawDates;

        if (dates === null) {
          switch (this.dateMode) {
            case "single":
              newDates = new Date();
              break;
            case "multiple":
              newDates = [];
              break;
            case "range":
              newDates = {
                start: new Date(),
                end: new Date()
              };
              break;
          }
        } else {
          newDates = dates;
          switch (this.dateMode) {
            case "single":
              rawDates = [dates];
              break;
            case "multiple":
              rawDates = dates;
              break;
            case "range":
              rawDates = [dates.start, dates.end];
              break;
          }
        }

        this.$store.commit("setEventDate", { newDates, rawDates });
      }
    },
    dateMode() {
      return this.activeIntel.eventDate.type;
    }
  },
  mounted() {
    if (this.creatingIntel && !this.intelCreationInProcess) {
      this.$router.push({ path: "/intel/new/1" });
    }
  },
  methods: {
    confirmPrivacy() {
      if (this.hasDates && this.postToken) {
        Swal.fire(anonymousPostUpdateOptions).then(result => {
          if (result.value) {
            this.$store.dispatch("updateIntel", {
              postToken: this.postToken,
              updateTags: false
            });

            this.$store.commit("closeModal");
            this.$store.commit("clearIntel");
          }
        });
      }
    },
    next() {
      if (this.hasDates) {
        switch (this.activeIntel.type) {
          case "industryEvent":
            this.$router.push({ path: "/intel/new/6" });
            break;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  pointer-events: none;
  &.horizontal {
    flex-wrap: wrap;
  }
  &.vertical {
    flex-direction: column;
    align-items: center;
  }
}
button.cta,
button.complete {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  align-self: center;
  margin-bottom: 20px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  &.cta {
    background: #b7b7b7;
    color: #fff;
  }
  &.privacy-complete {
    background: #d61f69;
    box-shadow: rgba(214, 31, 105, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #f17eb8;
    }
  }
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
