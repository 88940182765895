<template>
  <div>
    <!-- Header section -->
    <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="text-center">
        <h1 class="text-base leading-6 font-semibold uppercase tracking-wide" :class="isDark ? 'text-white' : 'text-indigo-600'">Premium insights</h1>
        <p class="mt-2 leading-10 font-extrabold sm:leading-none sm:tracking-tight" :class="isDark ? 'text-3xl text-indigo-900 sm:text-4xl' : 'text-4xl text-gray-900 sm:text-5xl lg:text-6xl'">Get a better view</p>
        <p class="max-w-2xl mx-auto mt-4 text-xl leading-7" :class="isDark ? 'text-indigo-100' : 'text-gray-500'">Intel you crave. Sources you can trust. <router-link to="/product/premium-content" class="font-medium hover:text-indigo-200">Learn more &rarr;</router-link></p>
      </div>
    </div>

    <div class="max-w-3xl mx-auto grid grid-cols-1 gap-6 px-4 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
      <div v-if="isAdmin || showcases.length > 0" class="space-y-6 lg:col-start-1 lg:col-span-2">
        <div class="flex justify-between items-center">
          <h2 class="text-lg font-medium" :class="isDark ? 'text-indigo-100' : 'text-gray-900'">Premium Insight Showcases</h2>
          <create-premium-content-input-group v-if="isAdmin" @link-existing="linkExisting" @link-custom="linkCustom" class="justify-end" />
        </div>

        <!-- Featured card -->
        <purchaseable-showcase v-for="showcase in showcases" :key="showcase.id" :showcase="showcase" />

        <div v-if="showcaseLimit" class="mt-6 flex flex-col justify-stretch">
          <router-link to="/market/featured" :class="isDark ? darkButton : lightButton" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none">
            See more
          </router-link>
        </div>
      </div>

      <section class="lg:col-start-3 lg:col-span-1">
        <div class="" :class="isDark ? '' : 'px-4 sm:px-6 bg-white shadow py-5 sm:rounded-lg'">
          <h2 class="text-lg font-medium" :class="isDark ? 'text-indigo-100' : 'text-gray-900'">Top-Ranked Premium Insights</h2>

          <template v-if="top.length > 0">
            <div class="mt-6 flow-root">
              <ul class="space-y-2">
                <purchaseable-preview v-for="purchaseable in top" :key="purchaseable.id" :purchaseable="purchaseable" />
              </ul>
            </div>
            <div class="mt-6 flex flex-col justify-stretch">
              <router-link to="/market/top" :class="isDark ? darkButton : lightButton" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none">
                See more
              </router-link>
            </div>
          </template>
          <div v-else class="mt-6 flex justify-center">
            <grid-loader :loading="true" size="6px" color="#5850ec" />
          </div>
        </div>
      </section>
    </div>

    <bounty-cross-sell v-if="!isDark" background-color="gray-200" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BountyCrossSell from "./bounty-cross-sell";
import CreatePremiumContentInputGroup from "./dashboard/create-premium-content-input-group";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import PurchaseablePreview from "../cards/purchaseable-preview.vue";
import PurchaseableShowcase from "../cards/purchaseable-showcase";
import api from "../../api";

export default {
  components: {
    BountyCrossSell,
    CreatePremiumContentInputGroup,
    GridLoader,
    PurchaseablePreview,
    PurchaseableShowcase
  },
  props: ["showcaseLimit", "purchaseableLimit", "isDark"],
  data() {
    return {
      top: [],
      showcases: []
    };
  },
  computed: {
    ...mapState(["refetchPurchaseables", "refetchShowcases"]),
    ...mapGetters(["isAdmin"]),
    darkButton() {
      return "bg-indigo-50 text-indigo-700 hover:bg-indigo-100";
    },
    lightButton() {
      return "shadow-sm text-white bg-indigo-600 hover:bg-indigo-700";
    },
    ableToComposeMeta() {
      return this.top.length > 0 && this.showcases.length > 0;
    },
    tagline() {
      return "Tower Hunt is a local commercial real estate market tracker for local experts, by local experts. Earn money for local expertise, see off-market leads, and go beyond the headlines.";
    }
  },
  watch: {
    refetchPurchaseables: {
      handler() {
        if (this.refetchPurchaseables) {
          this.fetchPurchaseables();
        }
      }
    },
    refetchShowcases: {
      handler() {
        if (this.refetchShowcases) {
          this.fetchShowcases();
        }
      }
    },
    ableToComposeMeta: {
      handler() {
        this.composeMeta();
      }
    }
  },
  mounted() {
    this.fetchPurchaseables();
    this.fetchShowcases();
  },
  destroyed() {
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.tagline);
  },
  methods: {
    fetchPurchaseables() {
      const endpoint = this.purchaseableLimit
        ? `featured_purchaseables?limit=${this.purchaseableLimit}`
        : `featured_purchaseables?limit=5`;

      api.get(endpoint).then(json => {
        this.top = json.data;
        this.$store.commit("setRefetchPurchaseables", false);
      });
    },
    fetchShowcases() {
      const endpoint = this.showcaseLimit
        ? `purchaseable_showcases?limit=${this.showcaseLimit}`
        : `purchaseable_showcases`;

      api.get(endpoint).then(json => {
        this.showcases = json.data;
        this.$store.commit("setRefetchShowcases", false);
      });
    },
    composeMeta() {
      const introSentence = `Our premium content brings you actionable insights and data from local commercial real estate experts.`;
      const curatedCollections = `Premium insight showcases: ${this.showcases
        .map(showcase => {
          return `${showcase.category}: ${showcase.title} - ${showcase.headline}`;
        })
        .join("; ")}`;
      const topContent = `Top-ranked premium insights: ${this.top
        .map(purchaseable => {
          return `${purchaseable.name}: ${purchaseable.description}`;
        })
        .join("; ")}`;

      const combined = `${this.tagline} ${introSentence} ${curatedCollections} ${topContent}`;

      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", combined);

      if (!this.isDark) {
        document.title = "Premium insights: Get a better view | Tower Hunt";
      }
    },
    linkCustom() {
      this.createShowcase();
    },
    linkExisting({ contentToken, contentType }) {
      const payload = {
        contentType: contentType,
        contentToken: contentToken
      };

      this.createShowcase(payload);
    },
    createShowcase(payload = {}) {
      api.post(`purchaseable_showcases`, payload).then(json => {
        this.$store.dispatch("flash", "Showcase ready for editing.");
        this.$store.commit("setRefetchShowcases", true);
      });
    }
  }
};
</script>
