<template>
  <div>
    <div v-if="currentUser.pipeline">
      <div v-if="directDeals.length > 0" class="my-4 bg-white overflow-hidden shadow rounded-lg">
        <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div class="ml-4 mt-2">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Deals offered to me
              </h3>
              <p class="mt-1 text-sm leading-5 text-gray-500">
                When you're given access to a live deal, it will appear here.
              </p>
            </div>
            <div v-if="!privacyMode" class="ml-4 mt-2 flex-shrink-0">
              <span class="inline-flex rounded-md shadow-sm">
                <button @click="newDirectActiveDeal" type="button" class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
                  Offer your own deal
                </button>
              </span>
            </div>
          </div>
        </div>
        <div class="px-4 py-5 sm:p-6">
          <div class="grid gap-5 max-w-lg mx-auto sm:grid-cols-2 lg:grid-cols-3 sm:max-w-none">
            <multi-content-preview-card
              v-for="deal in directDeals"
              :key="`${deal.token}`"
              :content="deal"
            />
          </div>
        </div>
        <div v-if="directPagination && directPagination.count > 3" class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div class="hidden sm:block">
            <p class="text-sm leading-5 text-gray-700">
              Showing
              <span class="font-medium">{{ directPagination.from }}</span>
              to
              <span class="font-medium">{{ directPagination.to }}</span>
              of
              <span class="font-medium">{{ directPagination.count }}</span>
              deals
            </p>
          </div>
          <div class="flex-1 flex justify-between sm:justify-end">
            <router-link @click.native="fetchDirectDeals(directPagination.prev_url)" to="" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
              Previous
            </router-link>
            <router-link @click.native="fetchDirectDeals(directPagination.next_url)" to="" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
              Next
            </router-link>
          </div>
        </div>
      </div>

      <div v-else class="relative bg-gray-800">
        <div class="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <img class="w-full h-full object-cover" src="https://source.unsplash.com/collection/2155077" alt="Pipeline intro" />
        </div>
        <div class="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <div class="md:ml-auto md:w-1/2 md:pl-10">
            <div class="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
              Tower Hunt deals
            </div>
            <h2 class="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
              Live deals offered directly
            </h2>
            <p class="mt-3 text-lg leading-7 text-gray-300">
              When you're given access to a live deal, it will appear here.
            </p>
            <div v-if="!privacyMode" class="mt-8">
              <div class="inline-flex rounded-md shadow">
                <a @click.prevent="newDirectActiveDeal" href="" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:ring">
                  Offer your own deal
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="currentUser.pipeline" class="mt-8">
      <div v-if="indirectDeals.length > 0" class="my-4 bg-white overflow-hidden shadow rounded-lg">
        <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div class="ml-4 mt-2">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Deals reported by the network
              </h3>
              <p class="mt-1 text-sm leading-5 text-gray-500">
                Anonymous reports about live deals in your markets.
              </p>
            </div>
            <div class="ml-4 mt-2 flex-shrink-0">
              <span class="inline-flex rounded-md shadow-sm">
                <button @click=newIndirectActiveDeal type="button" :class="privacyMode ? 'bg-pink-600 hover:bg-pink-500 focus:border-pink-700 focus:ring-pink active:bg-pink-700' : 'bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700'" class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none">
                  Report a live deal
                </button>
              </span>
            </div>
          </div>
        </div>
        <div class="px-4 py-5 sm:p-6">
          <div class="grid gap-5 max-w-lg mx-auto sm:grid-cols-2 lg:grid-cols-3 sm:max-w-none">
            <multi-content-preview-card
              v-for="deal in indirectDeals"
              :key="`${deal.token}`"
              :content="deal"
            />
          </div>
        </div>
        <div v-if="indirectPagination && indirectPagination.count > 3" class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div class="hidden sm:block">
            <p class="text-sm leading-5 text-gray-700">
              Showing
              <span class="font-medium">{{ indirectPagination.from }}</span>
              to
              <span class="font-medium">{{ indirectPagination.to }}</span>
              of
              <span class="font-medium">{{ indirectPagination.count }}</span>
              deals
            </p>
          </div>
          <div class="flex-1 flex justify-between sm:justify-end">
            <router-link @click.native="fetchIndirectDeals(indirectPagination.prev_url)" to="" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
              Previous
            </router-link>
            <router-link @click.native="fetchIndirectDeals(indirectPagination.next_url)" to="" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-indigo focus:border-indigo-300 active:bg-gray-100 active:text-gray-700">
              Next
            </router-link>
          </div>
        </div>
      </div>

      <div v-else class="relative bg-gray-800">
        <div class="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <img class="w-full h-full object-cover" src="https://source.unsplash.com/collection/1976082" alt="Pipeline intro" />
        </div>
        <div class="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <div class="md:ml-auto md:w-1/2 md:pl-10">
            <div class="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
              Deals Reported by the Network
            </div>
            <h2 class="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
              Live deals sourced via intel
            </h2>
            <p class="mt-3 text-lg leading-7 text-gray-300">
              Anonymous reports about live deals in your markets will appear here.
            </p>
            <div class="mt-8">
              <div class="inline-flex rounded-md shadow">
                <a @click.prevent="newIndirectActiveDeal" href="" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:ring">
                  Report a live deal
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MultiContentPreviewCard from "../components/cards/multi-content-preview-card";

export default {
  components: { MultiContentPreviewCard },
  computed: {
    ...mapState(["currentUser", "refetchRumoredDeals"]),
    ...mapGetters(["privacyMode"]),
    directDeals() {
      return this.currentUser.pipeline.direct.data;
    },
    directPagination() {
      return this.currentUser.pipeline.direct.pagy;
    },
    indirectDeals() {
      return this.currentUser.pipeline.indirect.data;
    },
    indirectPagination() {
      return this.currentUser.pipeline.indirect.pagy;
    }
  },
  watch: {
    refetchRumoredDeals: {
      handler() {
        if (this.refetchRumoredDeals) {
          this.fetchIndirectDeals();
        }
      }
    }
  },
  mounted() {
    document.title = "Deals in the Market | Tower Hunt";

    this.fetchDirectDeals();
    this.fetchIndirectDeals();
  },
  methods: {
    fetchDirectDeals(url = null) {
      const cleanUrl = url ? _.drop(url, 8).join("") : url;

      this.$store.dispatch("loadDirectPipeline", cleanUrl);
    },
    fetchIndirectDeals(url = null) {
      const cleanUrl = url ? _.drop(url, 8).join("") : url;

      this.$store.dispatch("loadIndirectPipeline", cleanUrl);
    },
    newDirectActiveDeal() {
      this.$router.push({ path: "/deals/new/1", query: { active: true } });
    },
    newIndirectActiveDeal() {
      this.$router.push({
        path: "/deals/new/1",
        query: { active: true, indirect: true }
      });
    }
  }
};
</script>
