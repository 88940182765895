<template>
  <div>
    <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div class="max-w-md w-full">
        <div>
          <router-link to="/">
            <img class="mx-auto h-16 w-auto" src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png" alt="Tower Hunt" />
          </router-link>
          <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Sign in to your account
          </h2>
          <p class="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
            Or
            <router-link to="/start/1" class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline">
              create your profile
            </router-link>
          </p>
        </div>
        <form class="mt-8" @submit.prevent="signIn">
          <input type="hidden" name="remember" value="true" />
          <div class="rounded-md shadow-sm">
            <div>
              <input v-focus v-model="session.email" aria-label="Email address" name="email" type="email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm sm:leading-5" placeholder="Email address" />
            </div>
            <div class="-mt-px">
              <input v-model="session.password" aria-label="Password" name="password" type="password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm sm:leading-5" placeholder="Password" />
            </div>
          </div>

          <div class="mt-6 flex items-center justify-end">
            <div class="text-sm leading-5">
              <router-link to="/forgot-password" class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline">
                Forgot your password?
              </router-link>
            </div>
          </div>

          <div class="mt-2 flex items-center justify-end">
            <div class="text-sm leading-5">
              <a href="mailto:team@towerhunt.com?subject=Change%20my%20login%20email" target="_blank" rel="noopener noreferrer" class="flex items-center font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline">
                <span>Need to change your login email?</span>
                <svg class="ml-1 h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
                </svg>
              </a>
            </div>
          </div>

          <div class="mt-6">
            <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
              <span class="absolute left-0 inset-y pl-3">
                <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                </svg>
              </span>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import User from "../user";
import api from "../api";

export default {
  data() {
    return {
      session: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapState(["modal", "slideover"])
  },
  mounted() {
    if (this.modal) {
      this.$store.commit("closeModal");
    }
    if (this.slideover) {
      this.$store.commit("closeSlideover");
    }

    const nextUrl = _.get(this.$store.state.route, "query.nextUrl", null);

    if (
      _.includes(nextUrl, "deal_invitations") ||
      _.includes(nextUrl, "deal_prospect_invitations") ||
      _.includes(nextUrl, "deal_invitation_requests")
    ) {
      this.$store.commit("setNewUserDealInvitationPath", nextUrl);
    }
  },
  methods: {
    signIn() {
      this.$store.commit("isLoading");
      api.post("authenticate", this.session).then(
        result => {
          const token = result.data.id;

          localStorage.setItem("token", token);
          api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          api.get("whoami").then(user => {
            this.$store.dispatch("signIn", new User(user.data));
            this.$store.commit("doneLoading");
            if (this.$route.query.nextUrl) {
              this.$router.push({ path: this.$route.query.nextUrl });
            } else {
              this.$router.push({ path: "/" });
            }
          });
        },
        failure => {
          this.$store.dispatch("flash", "Permission denied");
          this.$store.commit("doneLoading");
          console.log(failure);
        }
      );
    }
  }
}
</script>
