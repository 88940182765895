<template>
  <div v-if="deal">
    <content-visibility :content="deal" content-type="deal" :compact="false" />
    <div class="border-b border-gray-200 px-4 py-5 sm:px-6" v-observe-visibility="{ callback: createImpression, once: true }">
      <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div class="ml-4 mt-2">
          <h3 class="flex items-center text-lg leading-6 font-medium text-gray-900">
            <template v-if="deal.authored || isAdmin">
              <a
                @click.prevent="openModal({ record: deal, type: 'deal-name' })"
                class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
              >
                <span>{{ deal.name }}</span>
                <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
              </a>
            </template>
            <template v-else>{{ deal.name }}</template>
            <template v-if="deal.authored || isAdmin">
              <a
                @click.prevent="openModal({ record: deal, type: 'deal-properties' })"
                class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
              >
                <span>&nbsp;({{ deal.properties | propertiesCount }})</span>
                <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
              </a>
            </template>
            <template v-else-if="deal.properties.length > 1">({{ deal.properties | propertiesCount }})</template>
          </h3>
          <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">
            <div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
              <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z"/>
              </svg>
              {{ deal.type | dealTypeAlias(deal.jointVentureRoles) }}
            </div>
            <template v-if="deal.type === 'Lease'">
              <div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clip-rule="evenodd"/>
                </svg>
                <a
                  v-if="deal.authored || isAdmin"
                  @click.prevent="openModal({ record: deal, type: 'lease-size' })"
                  class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
                >
                  <span><template v-if="!deal.closeDate">~</template>{{ deal | totalLeaseSize }}</span>
                  <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
                </a>
                <template v-else>
                  <template v-if="!deal.closeDate">~</template>{{ deal | totalLeaseSize }}
                </template>
              </div>
            </template>
            <template v-else>
              <div v-if="deal.totalValue || deal.authored || isAdmin" v-tooltip="valueHelpText" class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                  <pathd="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"/>
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                    clip-rule="evenodd"
                  />
                </svg>
                <a
                  v-if="deal.authored || isAdmin"
                  @click.prevent="openModal({ record: deal, type: 'total-value' })"
                  class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
                >
                  <span v-if="deal.totalValue">
                    <template v-if="!deal.closeDate">~</template>{{ deal.totalValue | largeDollar }}
                    <template v-if="deal.goingInPerUnit">({{ deal | perUnitPrice }})</template>
                  </span>
                  <span v-else>...</span>
                  <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
                </a>
                <template v-else>
                  <template v-if="!deal.closeDate">~</template>{{ deal.totalValue | largeDollar }}
                  <template v-if="deal.goingInPerUnit">({{ deal | perUnitPrice }})</template>
                </template>

                <a
                  v-if="(deal.authored || isAdmin) && deal.leverage > 0"
                  @click.prevent="openModal({ record: deal, type: 'leverage' })"
                  class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
                >
                  <span>&nbsp;({{ deal.leverage }}% LTC)</span>
                  <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
                </a>
                <template v-else-if="deal.leverage > 0">&nbsp;({{ deal.leverage }}% LTC)</template>
                <template v-if="deal.loans.length > 0">&nbsp;({{ deal.loans | sortedTypeLabels }})</template>
              </div>
            </template>
            <div v-if="deal.closeDate" class="mt-2 flex items-center text-sm leading-5 text-gray-500">
              <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"/>
              </svg>
              <a
                v-if="deal.authored || isAdmin"
                @click.prevent="openModal({ record: deal, type: 'close-date' })"
                class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
              >
                <span>Closed {{ deal.closeDate | moment("MMMM YYYY") }}</span>
                <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
              </a>
              <template v-else>
                Closed {{ deal.closeDate | moment("MMMM YYYY") }}
              </template>
            </div>
            <div v-else class="mt-2 flex items-center text-sm leading-5 text-gray-500">
              <span class="mr-1 inline-flex items-center justify-center h-3 w-3 rounded-full bg-red-400"></span>
              <a
                v-tooltip="stateHelpText"
                @click.prevent="changeState"
                class="flex items-center text-indigo-600 hover:text-indigo-500 cursor-pointer"
              >
                <span>{{ deal.state | capitalize }}</span>
                <svg class="ml-1 h-3 w-3" fill="currentColor" viewBox="0 0 20 20"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path></svg>
              </a>
            </div>
          </div>
        </div>
        <div class="ml-4 mt-2 flex-shrink-0">
          <deal-menu :deal="deal" />
        </div>
      </div>
    </div>
    <preview-card-social-footer :content="deal" :property="property" content-type="deal" :thankable="true" :bottom-border="true" />
    <div class="border-b border-gray-200 mt-3 sm:mt-4 px-4 sm:px-6">
      <div class="sm:hidden">
        <label for="current-tab" class="sr-only">Select a tab</label>
        <select v-model="selectedTab" id="current-tab" name="current-tab" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
          <option v-for="tab in tabs" :key="tab.name" :value="tab">{{ tab.name }}</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <nav class="-mb-px flex space-x-8">
          <a v-for="tab in tabs" @click.prevent="selectedTab = tab" :key="tab.name" :href="tab.href" :class="[selectedTab.name === tab.name ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm']" :aria-current="selectedTab.name === tab.name ? 'page' : undefined">
            {{ tab.name }}
          </a>
        </nav>
      </div>
    </div>
    <div class="px-4 py-5 sm:p-6">
      <keep-alive>
        <component :is="selectedTab.component" v-bind="selectedTabProperties" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CloseDate from "../../views/onboarding/deal/deal-close-date";
import Comments from "../comments";
import ContentEngagement from "./details/content-engagement.vue";
import ContentSafezone from "./details/content-safezone.vue";
import ContentVisibility from "./content-visibility.vue";
import DealLeaseSize from "../../views/onboarding/deal/lease-size";
import DealMenu from "../deal-menu";
import DealName from "../../views/onboarding/deal/deal-name";
import DealProperties from "../../views/onboarding/deal/properties";
import DealSize from "../../views/onboarding/deal/deal-size";
import DealState from "../../views/onboarding/deal/deal-state";
import DealVitals from "./details/deal-vitals.vue";
import GeneralNotes from "../../views/onboarding/general-notes";
import Leverage from "../../views/onboarding/deal/leverage";
import LinkedPurchaseableBrowser from "../purchaseables/linked-purchaseable-browser.vue";
import PreviewCardSocialFooter from "./preview-card-social-footer";
import api from "../../api";
/* global analytics */

export default {
  components: {
    ContentVisibility,
    DealMenu,
    PreviewCardSocialFooter
  },
  props: ["dealObject", "dealToken", "property", "openComments"],
  data() {
    return {
      deal: null,
      tabs: [
        {
          name: "Vitals",
          href: "",
          component: DealVitals
        },
        {
          name: "Safezone",
          href: "",
          component: ContentSafezone
        },
        {
          name: "Premium Insights",
          href: "",
          component: LinkedPurchaseableBrowser
        },
        {
          name: "Engagement",
          href: "",
          component: ContentEngagement
        }
      ],
      selectedTab: {
        name: "Vitals",
        href: "",
        component: DealVitals
      }
    };
  },
  computed: {
    ...mapState([
      "modal",
      "properties",
      "route",
      "currentUser",
      "temporaryAccess",
      "adminMode",
      "modalNavigationState"
    ]),
    ...mapGetters(["signedIn", "isAdmin"]),
    selectedTabProperties() {
      switch (this.selectedTab.name) {
        case "Vitals":
          return {
            deal: this.deal
          };
        case "Safezone":
        case "Engagement":
          return {
            content: this.deal,
            contentType: "deal"
          };
        case "Premium Insights":
          return {
            contentToken: this.deal.token,
            contentType: "deal",
            contentName: this.deal.name
          };
        default:
          return {};
      }
    },
    modalDeal() {
      return _.get(this.modal, "props.deal", null);
    },
    stateHelpText() {
      if (this.deal.authored) {
        return "Change deal state";
      } else {
        return "Anonymously report a different deal state";
      }
    },
    valueHelpText() {
      if (this.deal.state !== "closed") {
        switch (this.deal.type) {
          case "Buy Equity":
            return "Pricing guidance";
          case "Recap Equity":
            return "Total asset valuation guidance";
          case "Originate Debt":
            return "Fully-funded loan amount guidance";
          case "Buy Debt":
            return "Loan pricing guidance";
          default:
            return "Pricing guidance";
        }
      } else {
        switch (this.deal.type) {
          case "Buy Equity":
            return "Purchase price";
          case "Recap Equity":
            return "Total asset valuation";
          case "Originate Debt":
            return "Fully-funded loan amount";
          case "Buy Debt":
            return "Loan purchase price";
          default:
            return "Total deal size";
        }
      }
    }
  },
  watch: {
    dealObject: {
      handler() {
        if (this.dealObject) {
          this.deal = null;
          var self = this;

          setTimeout(() => {
            self.deal = self.dealObject;
          }, 250);
        }
      },
      deep: true
    },
    modalDeal: {
      handler() {
        if (this.modalDeal) {
          this.deal = this.modalDeal;
          this.checkForCommentsHash();
          this.checkForModalNavigation();
        }
      },
      deep: true
    },
    temporaryAccess: {
      handler() {
        this.attachFiles();
      }
    }
  },
  mounted() {
    this.$store.commit("clearIntel");

    if (this.modalDeal && !this.deal) {
      this.deal = this.modalDeal;
      this.checkForCommentsHash();
      this.checkForModalNavigation();
    } else if (this.dealToken && !this.deal) {
      this.$store.commit("isLoading");
      this.fetchDeal();
    } else {
      this.deal = this.dealObject;
      this.checkForCommentsHash();
      this.checkForModalNavigation();
    }

    if (this.properties && this.properties.length > 0) {
      const newDealProperties = this.deal.properties.map(property => {
        const matchingProperty = _.find(this.properties, {
          id: _.toNumber(property.id)
        });

        return matchingProperty || property;
      });

      this.deal.properties = newDealProperties;
    }

    this.attachFiles();
  },
  methods: {
    attachFiles() {
      if (this.temporaryAccess && this.temporaryAccess.content) {
        if (this.dealObject) {
          this.dealObject.coverPhoto = this.temporaryAccess.content.coverPhoto;
          this.dealObject.primaryAttachment = this.temporaryAccess.content.primaryAttachment;
        }

        this.deal.coverPhoto = this.temporaryAccess.content.coverPhoto;
        this.deal.primaryAttachment = this.temporaryAccess.content.primaryAttachment;
        this.$store.commit("clearTemporaryAccess");
      } else if (this.temporaryAccess && this.temporaryAccess.coverPhoto) {
        if (this.dealObject) {
          this.dealObject.coverPhoto = this.temporaryAccess.coverPhoto;
        }

        this.deal.coverPhoto = this.temporaryAccess.coverPhoto;
        this.$store.commit("clearTemporaryAccess");
      }
    },
    checkForModalNavigation() {
      if (this.modalNavigationState.horizontalNav) {
        this.selectedTab = _.find(this.tabs, {
          name: this.modalNavigationState.horizontalNav
        });
      }
    },
    checkForCommentsHash() {
      if (this.openComments || _.includes(this.route.hash, "#comments")) {
        this.viewComments();
      }
    },
    createImpression(isVisible, entry) {
      if (!this.adminMode && isVisible && this.deal.token) {
        this.$store.dispatch("createDealImpression", this.deal.token).then(() => {
          this.$store.dispatch("loadUnreadCounts");
        });
      }
    },
    fetchDeal() {
      api.get(`open_deal/${this.dealToken}`).then(
        json => {
          this.deal = json.data;
          this.checkForCommentsHash();
          this.checkForModalNavigation();
          this.$store.commit("doneLoading");
        },
        failure => {
          this.$store.dispatch("flash", "Invalid sharing link");
          this.$store.commit("doneLoading");
          this.$store.commit("closeModal");
        }
      );
    },
    viewComments() {
      this.$store.commit("openSlideover", {
        component: Comments,
        props: {
          content: this.deal
        }
      });

      if (!!analytics && !this.adminMode) {
        analytics.track("Viewed post comments", {
          yearWeek: this.$store.state.yearWeek
        });
      }
    },
    changeState() {
      if (this.deal.authored || this.isAdmin) {
        this.openModal({ record: this.deal, type: "edit-deal-state" });
      } else {
        this.openModal({ record: this.deal, type: "report-deal-state" });
      }
    },
    openModal({ record, type, contact = null }) {
      let dealCardModal = false;

      if (this.modal) {
        dealCardModal = _.cloneDeep(this.modal); // obtains the current component
        dealCardModal.props.deal = record; // allows changes to flow through
      }

      switch (type) {
        case "lease-size":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: DealLeaseSize,
            props: {
              dealId: record.id
            },
            afterClose: dealCardModal
          });
          break;
        case "total-value":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: DealSize,
            props: {
              dealId: record.id
            },
            afterClose: dealCardModal
          });
          break;
        case "leverage":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: Leverage,
            props: {
              dealId: record.id
            },
            afterClose: dealCardModal
          });
          break;
        case "deal-name":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: DealName,
            props: {
              dealId: record.id
            },
            afterClose: dealCardModal
          });
          break;
        case "close-date":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: CloseDate,
            props: {
              dealId: record.id
            },
            afterClose: dealCardModal
          });
          break;
        case "deal-properties":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: DealProperties,
            props: {
              dealId: record.id
            },
            afterClose: dealCardModal
          });
          break;
        case "edit-deal-state":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: DealState,
            props: {
              dealId: record.id
            },
            afterClose: dealCardModal
          });
          break;
        case "report-deal-state":
          this.$store.commit("mountDeal", { deal: record });
          this.$store.commit("openModal", {
            component: GeneralNotes,
            props: {
              dealId: record.id,
              stateChange: true
            },
            afterClose: dealCardModal
          });
          break;
      }
    }
  }
};
</script>
