<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="px-4 py-5 sm:p-6">
    <h3 class="text-base leading-6 font-medium text-gray-900">
      Shared Payout Accounts
    </h3>
    <p class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
      Shared payout accounts let you share certain earnings with a team or allocate them to an owned building. You'll need an existing bank account to link to. Tower Hunt partners with <a href="https://stripe.com" target="_blank" class="underline">Stripe</a> to manage payments and payouts.
    </p>
    <div v-if="accounts.length > 0" class="flow-root mt-6">
      <ul class="-my-5 divide-y divide-gray-200">
        <shared-payout-account-row v-for="account in accounts" :key="account.id" :account="account" @refetch="fetchAccounts" />
      </ul>
    </div>
    <div class="mt-6">
      <a href="" @click.prevent="newAccount" :disabled="creating" :class="creating ? 'cursor-not-allowed' : ''" class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
        Setup new account
      </a>
    </div>
  </div>
</template>

<script>
import SharedPayoutAccountRow from "./shared-payout-account-row.vue";
import api from "../api";

export default {
  components: { SharedPayoutAccountRow },
  data() {
    return {
      accounts: [],
      creating: false
    };
  },
  mounted() {
    this.fetchAccounts();
  },
  methods: {
    fetchAccounts() {
      api.get(`shared_payout_accounts`).then(json => {
        this.accounts = json.data;
      });
    },
    newAccount() {
      this.creating = true;
      api.post(`shared_payout_accounts`).then(json => {
        this.creating = false;
        this.fetchAccounts();
      });
    }
  }
};
</script>
