<template>
  <div>
    <!-- Header section -->
    <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="text-center">
        <h1 class="text-base leading-6 font-semibold uppercase tracking-wide" :class="isDark ? 'text-white' : 'text-indigo-600'">Top-ranked</h1>
        <p class="mt-2 leading-10 font-extrabold sm:leading-none sm:tracking-tight" :class="isDark ? 'text-3xl text-indigo-900 sm:text-4xl' : 'text-4xl text-gray-900 sm:text-5xl lg:text-6xl'">Hidden gems</p>
        <p class="max-w-xl mx-auto mt-4 text-xl leading-7" :class="isDark ? 'text-indigo-100' : 'text-gray-500'">Browse new and highly-rated premium insights {{ topScope }}. <router-link to="/product/premium-content" class="font-medium hover:text-indigo-200">Learn more &rarr;</router-link></p>
      </div>
    </div>

    <ul class="grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <purchaseable-preview v-for="purchaseable in top" :key="purchaseable.id" :purchaseable="purchaseable" class="col-span-1" />
    </ul>

    <bounty-cross-sell background-color="gray-200" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BountyCrossSell from "./bounty-cross-sell";
import PurchaseablePreview from "../cards/purchaseable-preview.vue";
import api from "../../api";

export default {
  components: { BountyCrossSell, PurchaseablePreview },
  props: ["purchaseableLimit", "isDark"],
  data() {
    return {
      top: []
    };
  },
  computed: {
    ...mapState(["refetchPurchaseables", "refetchShowcases"]),
    ...mapGetters(["isAdmin", "signedIn"]),
    topScope() {
      if (this.signedIn) {
        return "from your markets";
      } else {
        return "from across Tower Hunt";
      }
    },
    tagline() {
      return "Tower Hunt is a local commercial real estate market tracker for local experts, by local experts. Earn money for local expertise, see off-market leads, and go beyond the headlines.";
    }
  },
  watch: {
    refetchPurchaseables: {
      handler() {
        if (this.refetchPurchaseables) {
          this.fetchPurchaseables();
        }
      }
    }
  },
  mounted() {
    this.fetchPurchaseables();
  },
  destroyed() {
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.tagline);
  },
  methods: {
    fetchPurchaseables() {
      const endpoint = this.purchaseableLimit
        ? `featured_purchaseables?limit=${this.purchaseableLimit}`
        : `featured_purchaseables`;

      this.$store.commit("isLoading");
      api.get(endpoint).then(json => {
        this.top = json.data;
        this.$store.commit("setRefetchPurchaseables", false);
        this.$store.commit("doneLoading");
        this.composeMeta();
      });
    },
    composeMeta() {
      const introSentence = `Browse new and highly-rated premium insights ${this.topScope}.`;
      const topContent = `Top insights: ${this.top
        .map(purchaseable => {
          return `${purchaseable.name}: ${purchaseable.description}`;
        })
        .join("; ")}`;

      const combined = `${this.tagline} ${introSentence} ${topContent}`;

      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", combined);

      if (!this.isDark) {
        document.title = "Top-ranked premium insights | Tower Hunt";
      }
    }
  }
};
</script>
