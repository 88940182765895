<template>
  <div class="border-t border-gray-200 rounded-b-lg">
    <div v-if="bounty || bounty.priced" class="p-2">
      <div class="text-left">
        <h3 class="text-sm leading-4 text-gray-900">
          <template v-if="bounty.priced">Estimated value range</template>
          <template v-else>Submit value range</template>
        </h3>
      </div>
      <form @submit.prevent="submitPricing" class="pt-2 grid grid-cols-3 gap-2 items-stretch">
        <label for="cap_range" class="sr-only">Value range</label>
        <div class="mt-0 col-span-1">
          <div class="relative max-w-lg rounded-md shadow-sm sm:max-w-xs">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm">
                $
              </span>
            </div>
            <input v-model=minValue type="number" min="1.00" :id="`min_value_${bounty.id}`" placeholder="Min" class="pl-7 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5">
          </div>
        </div>
        <div class="mt-0 col-span-1">
          <div class="relative max-w-lg rounded-md shadow-sm sm:max-w-xs">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm">
                $
              </span>
            </div>
            <input v-model="maxValue" type="number" min="2.00" :id="`max_value_${bounty.id}`" placeholder="Max" class="pl-7 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm sm:leading-5">
          </div>
        </div>

        <span class="inline-flex rounded-md shadow-sm mt-0 col-span-1">
          <button type="submit" :disabled="!complete" class="w-full py-2 px-4 border rounded-md text-sm leading-5 font-medium" :class="buttonStyling">
            Submit
          </button>
        </span>
      </form>
    </div>
    <div v-else class="w-full flex">
      <button @click="pricing = true" class="relative -mr-px w-0 flex-1 rounded-b-lg inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border-transparent bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700">
        <!-- Heroicon name: lock-open -->
        <svg class="w-5 h-5 text-white" viewBox="0 0 20 20" fill="currentColor">
          <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
        </svg>
        <span class="ml-3 text-white">Estimate bounty value</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import api from "../../api";

export default {
  props: ["bounty"],
  data() {
    return {
      pricing: false,
      minValue: null,
      maxValue: null
    };
  },
  computed: {
    ...mapState(["adminMode"]),
    ...mapGetters(["isAdmin"]),
    minValueNumber() {
      return _.toNumber(this.minValue);
    },
    maxValueNumber() {
      return _.toNumber(this.maxValue);
    },
    properlyOrdered() {
      if (this.minComplete && this.maxComplete) {
        return this.minValueNumber < this.maxValueNumber;
      } else {
        return true;
      }
    },
    minComplete() {
      return this.minValue && this.minValueNumber > 0;
    },
    maxComplete() {
      return this.maxValue && this.maxValueNumber > 0;
    },
    complete() {
      return (this.minComplete || this.maxComplete) && this.properlyOrdered;
    },
    buttonStyling() {
      if (this.complete) {
        return "inline-flex justify-center border-transparent text-white bg-gradient-to-r from-yellow-300 to-yellow-500 hover:to-yellow-600 focus:border-yellow-500 focus:ring-yellow active:to-yellow-600";
      } else {
        return "border-gray-300 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 cursor-not-allowed";
      }
    }
  },
  mounted() {
    if (this.bounty.priced && this.isAdmin) {
      this.minValue = this.bounty.minimumValue;
      this.maxValue = this.bounty.maximumValue;
    }
  },
  methods: {
    submitPricing() {
      if (this.complete) {
        api
          .patch(`estimate_bounty_value/${this.bounty.id}`, {
            minValue: this.minComplete ? this.minValue : null,
            maxValue: this.maxComplete ? this.maxValue : null
          })
          .then(json => {
            this.$store.dispatch("flash", "Submission successful.");
          });
      } else {
        this.$store.dispatch("flash", "Value range is incomplete!");
      }
    }
  }
};
</script>
