<template>
  <div class="mt-12 flex justify-center items-center">
    <div :class="`max-w-3xl rounded-md bg-${privacyColor}-50 p-4`">
      <div class="flex">
        <div class="flex-shrink-0">
          <!-- Heroicon name: solid/information-circle -->
          <svg :class="`h-5 w-5 text-${privacyColor}-400`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path v-if="newRecordIsSafezone && !creatingDirectActiveDeal" fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            <path v-else fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p :class="`text-sm text-${privacyColor}-700`">Privacy: {{ privacyDescription }}</p>
          <p v-if="!creatingDirectActiveDeal" class="mt-3 text-sm md:mt-0 md:ml-6">
            <a href="" @click.prevent="updatePrivacy" :class="`whitespace-nowrap font-medium text-${privacyColor}-700 hover:text-${privacyColor}-600`">Edit <span aria-hidden="true">&rarr;</span></a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapGetters(["creatingDirectActiveDeal"]),
    ...mapState(["newRecordIsSafezone", "route"]),
    privacyDescription() {
      if (this.creatingDirectActiveDeal) {
        return "Quasi-public (Listings are available to an audience of your choosing.)";
      } else {
        return this.newRecordIsSafezone
          ? "Safezone (Only you will be able to see this)"
          : "Public (Available to anyone in same market)";
      }
    },
    privacyColor() {
      if (this.creatingDirectActiveDeal) {
        return "red";
      } else {
        return this.newRecordIsSafezone ? "green" : "red";
      }
    }
  },
  methods: {
    updatePrivacy() {
      this.$router.push({
        path: "/intel/new/privacy",
        query: { nextUrl: this.route.fullPath }
      });
    }
  }
};
</script>
