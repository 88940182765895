<template>
  <vue-markdown>
# TOWER HUNT PRIVACY POLICY
Last modified: November 8, 2020

## 1. Introduction
This Privacy Policy (“Policy”) governs Pinevale, Inc.’s (“Pinevale”, "us," "we," or "our,") use of personally identifiable information about users of our products, services and/or software (our "Services") that is available for access on our website www.towerhunt.com (“Site”). It also describes the choices available to you regarding our use of your personally identifiable information.

Pinevale may update this Privacy Policy from time to time. If so, we will post our updated Privacy Policy on this page, and if we make material changes to this policy, we will notify you here, by email, or by means of a notice on our home page. Pinevale encourages you to review this Privacy Policy regularly for any changes.

## 2. Information We May Collect
In certain sections of this Site, we may invite you to participate in surveys, contact us with questions or comments or request information, participate in chat, or complete a profile or registration form. Furthermore, we require you to complete a registration form to use and access certain Services. Due to the nature of some of these activities, we may collect personally identifiable information such as your name, e-mail address, address, phone number, password, credit card information and other contact information that you voluntarily transmit with your communications to us.

## 3. Use of Information
We may use your personally identifiable information to register you to use our Services, to inform you about our Services (such as letting you know about upcoming changes or improvements), and to improve the content and general administration of the Site and our Services.

Pinevale’s Services rest on Heroku's Cloud Platform. In order to enable Pinevale to provide the Services, any personally identifiable information we collect, analyze and store when you use our Services is shared with Heroku.

We will not sell your personally identifiable information to any organization, except we may transfer your personally identifiable information to a successor entity. We may provide your personally identifiable information and the data generated by cookies and the aggregate information to partners, vendors and service agencies that we may engage to assist us in providing our Services to you. For example, we may provide your personally identifiable information to a credit card processing company to process your payment. We will also disclose your personally identifiable information (a) if we are required to do so by law, regulation or other government authority or otherwise in cooperation with an ongoing investigation of a governmental authority, (b) to enforce the Pinevale Terms of Service or \(c\) to protect the safety of users of our Site and our Services.

We employ operational and technological security measures that are reasonably designed to help protect your personally identifiable information.

## 4. Customer Service Communications
Based upon the personally identifiable information you provide us, we will send you an email to verify your username and password. We will also communicate with you in response to your inquiries, to provide the Services you request, and to manage your account.

We will occasionally send you information on products, services, updates, events, special deals, and/or promotions. Out of respect for your privacy, we present the option not to receive these types of communications.

We will send you strictly service-related announcements on rare occasions when it is necessary to do so. For instance, if our Service is temporarily suspended for maintenance, we might send you an email. Generally, you may not opt-out of these communications, which are not promotional in nature. If you do not wish to receive them, you have the option to deactivate your account.

## 5. Cookies & Aggregate Information
When using the Site, we and any of our third party service providers may use cookies and other tracking mechanisms to track your user activity on the Site. If you register with the Site, we, and our third party service providers, will be able to associate all of your user activity with your personally identifiable registration information. We will use such user activity information to improve the Site, to provide context for our sales and support staff when interacting with you, and for other internal business analysis.

The Site may track information that will be maintained, used and disclosed in aggregate form only and which will not contain your personally identifiable information, for example, without limitation, the total number of visitors to our Site, the number of visitors to each page of our Site, browser type, and IP addresses. We may analyze this data for trends and statistics in the aggregate, and we may use such aggregate information to administer the Site, track users’ movement, and gather broad demographic information for aggregate use.

## 6. Contact Us
To request updates or changes to your information or your preferences regarding receiving future messages from us, you may contact us using the information in the Contact Us section of our Site or follow the opt-out instructions that are contained in the bottom of the email communication you received.
  </vue-markdown>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  components: { VueMarkdown }
};
</script>
