<template>
  <div v-if="activeTarget">
    <div class="relative pb-5 border-b border-gray-200 space-y-4 sm:pb-0">
      <div class="space-y-3 md:flex md:items-center md:justify-between md:space-y-0">
        <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
          <h3 class="ml-2 mt-2 text-lg leading-6 font-medium text-gray-900">
            {{ target | marketName | truncate(30) }}
          </h3>
          <p class="ml-2 mt-1 text-sm leading-5 text-gray-500 truncate">
            by&nbsp;
            <router-link
              :to="`/contacts/${target.userToken}`"
              class="font-medium text-gray-500 underline"
            >
              {{ target.userName }}
            </router-link>
          </p>
        </div>
        <div class="flex items-center space-x-3 md:absolute md:top-3 md:right-0">
          <template v-if="activeTarget.authored">
            <button @click="editMarket" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium shadow-sm rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-indigo focus:border-indigo-700 active:bg-indigo-700">
              <svg class="-ml-1 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
              </svg>
              Edit
            </button>
          </template>
        </div>
      </div>
      <div>
        <!-- Dropdown menu on small screens -->
        <div class="sm:hidden">
          <select v-model="selectValue" aria-label="Selected tab" class="block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm sm:leading-5">
            <option value="deals">Deals</option>
            <option value="intel">Intel</option>
            <option value="people">People</option>
            <option value="reports">Market Reports</option>
          </select>
        </div>
        <!-- Tabs at small breakpoint and up -->
        <div class="hidden sm:block">
          <nav class="-mb-px flex space-x-8">
            <router-link to="deals" v-slot="{ href, navigate, isActive }">
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Deals
              </a>
            </router-link>

            <router-link to="intel" v-slot="{ href, navigate, isActive }">
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Intel
              </a>
            </router-link>

            <router-link to="people" v-slot="{ href, navigate, isActive }">
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                People
              </a>
            </router-link>

            <router-link to="reports" v-slot="{ href, navigate, isActive }">
              <a
                @click="navigate"
                :href="href"
                :class="{ 'border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700': isActive }"
                class="whitespace-nowrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                aria-current="page"
              >
                Market Reports
              </a>
            </router-link>
          </nav>
        </div>
      </div>
    </div>

    <router-view :target-token="targetToken" />
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapState } from "vuex";
import Market from "../components/cards/market";
import MarketPageWelcome from "../components/cards/market-page-welcome";
import _ from "lodash";

let nowUpdateInterval;

export default {
  props: ["targetToken"],
  data() {
    return {
      selectValue: "deals"
    };
  },
  computed: {
    ...mapState([
      "now",
      "activeTarget",
      "refetchTargetPage",
      "refetchRumoredDeals",
      "currentUser",
      "modal",
      "adminMode",
      "route"
    ]),
    ...mapGetters([
      "signedIn",
      "userIsActiveContact",
      "activeTargetDeals",
      "showDealSize",
      "showLeaseSize",
      "showLeverageSize",
      "showLoanTypes",
      "showJVRoles",
      "targetHasPropertySize",
      "privacyMode"
    ]),
    target() {
      return this.$store.state.activeTarget;
    },
    pathTab() {
      return _.last(this.route.path.split("/"));
    },
    hasRegions() {
      return _.get(this.activeTarget, "regions", []).length > 0;
    },
    shouldDisplayWelcome() {
      return false;
      // return this.currentUser.marketPageWelcome && !this.modal;
    }
  },
  watch: {
    pathTab: {
      handler() {
        this.setSelectFromPath();
      }
    },
    selectValue: {
      handler() {
        if (this.selectValue !== this.pathTab) {
          this.$router.push({ path: this.selectValue });
        }
      }
    },
    targetToken: {
      handler: function() {
        this.forwardEmptyPath();
        this.$store.commit("clearTarget");
        this.$store.dispatch("loadSharedTarget", this.targetToken);
      }
    },
    currentUser: {
      handler: function() {
        this.displayWelcome();
      }
    },
    hasRegions: {
      handler() {
        if (this.hasRegions) {
          document.title = this.$options.filters.marketName(this.activeTarget) + " | Tower Hunt";
        }
      }
    }
  },
  created() {
    var self = this;

    nowUpdateInterval = setInterval(function() {
      self.$store.commit("setNow", moment());
    }, 5000);
  },
  destroyed() {
    clearInterval(nowUpdateInterval);
    this.$store.dispatch("clearAllIntervals");
  },
  mounted() {
    this.forwardEmptyPath();
    this.setSelectFromPath();
    this.$store.dispatch("loadSharedTarget", this.targetToken);
    this.displayWelcome();

    if (this.hasRegions) {
      document.title = this.$options.filters.marketName(this.activeTarget) + " | Tower Hunt";
    }
  },
  methods: {
    editMarket() {
      this.$store.commit("openModal", {
        component: Market,
        props: {
          target: this.activeTarget,
          explorable: false
        }
      });
    },
    displayWelcome() {
      if (!this.adminMode && this.shouldDisplayWelcome) {
        this.$store.commit("openModal", {
          component: MarketPageWelcome,
          props: {}
        });
      }
    },
    forwardEmptyPath() {
      if (this.$store.state.route.path === `/open/${this.targetToken}/`) {
        this.$router.push({ path: "deals" });
        this.selectValue = "deals";
      }
    },
    setSelectFromPath() {
      if (
        _.includes(["deals", "intel", "people", "reports", "map"], this.pathTab)
      ) {
        this.selectValue = this.pathTab;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-complete-item {
  transition: all 2s;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.list-complete-leave-active {
  position: absolute;
}
</style>
