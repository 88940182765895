<template>
  <div class="wrapper">
    <heading>{{ heading }}</heading>
    <form @submit.prevent="save" autocomplete="off" novalidate>
      <div class="input-container mb-2">
        <resizable-textarea>
          <textarea
            v-focus
            v-model="content"
            @focus="focus"
            @blur="blur"
            rows="1"
            autocorrect="off"
            spellcheck="false"
            placeholder=""
            tabindex="1"
            name="notes"
            class="input-text black"
            :class="{ active: focused }"
          />
        </resizable-textarea>
      </div>
      <option-description v-if="description" :description="description" />
      <button type="submit" class="cta" :class="{ complete: complete }" tabindex="999">Save</button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Heading from "../../components/onboarding/heading";
import OptionDescription from "../../components/onboarding/option-description";
import ResizableTextarea from "../../components/resizable-textarea";

export default {
  components: { Heading, OptionDescription, ResizableTextarea },
  props: [
    "heading",
    "description",
    "setterName",
    "getterName",
    "getterField",
    "order"
  ],
  data() {
    return {
      focused: false
    };
  },
  computed: {
    ...mapState(["activeDeal", "activeDealMarketing"]),
    ...mapGetters([]),
    content: {
      get() {
        if (this.order) {
          const item = _.find(this.activeDealMarketing[this.getterName], {
            order: this.order
          });

          if (item) {
            return item[this.getterField];
          } else {
            return null;
          }
        } else {
          return this.activeDealMarketing[this.getterName];
        }
      },
      set(text) {
        if (this.order) {
          let newItem = {
            order: this.order,
            type: this.getterName,
            field: this.getterField,
            value: text
          };

          this.$store.commit(this.setterName, newItem);
        } else {
          this.$store.commit(this.setterName, text);
        }
      }
    },
    complete() {
      return this.content !== null && _.trim(this.content) !== "";
    }
  },
  mounted() {
    if (this.order) {
      const item = _.find(this.activeDealMarketing[this.getterName], {
        order: this.order
      });

      if (!item) {
        const newItem = {
          order: this.order,
          type: _.dropRight(this.getterName, 1).join(""),
          iconName: "lightning-bolt",
          label: null,
          content: null
        };

        this.$store.commit("addDealMarketingOrderedItem", { item: newItem });
        this.$store.dispatch("createDealMarketingOrderedItem", { item: newItem });
      }
    }
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    save() {
      if (this.complete) {
        if (this.order) {
          const item = _.find(this.activeDealMarketing[this.getterName], {
            order: this.order
          });

          this.$store.dispatch("updateDealMarketingOrderedItem", { item });
        } else {
          this.$store.dispatch(
            "updateDealMarketing",
            this.activeDealMarketing.id
          );
        }

        this.$store.commit("closeModal");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
form {
  width: 330px;
}
textarea {
  vertical-align: middle;
}
.input-container {
  position: relative;
}
.input-text {
  width: 330px;
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid #dadada;
  resize: none;
  outline: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 18.2px;
  &.active {
    border: 2px solid #5850ec;
  }
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
