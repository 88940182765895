import StrategicMapFeed from "./components/maps/strategic-map-feed";
import Vue from "vue";
/* global L */

const Feed = Vue.extend(StrategicMapFeed);

export default function strategicMapFeed({ router, store, featureGroup }) {
  L.Control.StrategicMapFeed = L.Control.extend({
    options: {
      position: "topright"
    },

    onAdd: function(map) {
      const feedVueEl = new Feed({
        store,
        router,
        propsData: {
          map,
          featureGroup
        }
      }).$mount().$el;

      return feedVueEl;
    }
  });

  L.control.strategicMapFeed = function(options) {
    return new L.Control.StrategicMapFeed(options);
  };
}
