<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
  <div class="bg-white">
    <product-marketing-top-bar />

    <div class="bg-gradient-to-b from-indigo-50 via-white to-white">
      <main class="lg:relative">
        <div class="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
          <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
              Off-market leads
              <br class="xl:hidden">
              <span class="text-indigo-600">directly from owners</span>
            </h2>
            <p class="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Personalized deal leads with unlockable cap rate data and owner messaging.
            </p>
            <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div class="rounded-md shadow">
                <router-link v-if="signedIn" to="/off-market/packs" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo md:py-4 md:text-lg md:px-10">
                  View available packs
                </router-link>
                <router-link v-else to="/start/1" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo md:py-4 md:text-lg md:px-10">
                  Get started
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full" style="background-color: #222936;">
          <video poster="https://assets.towerhunt.com/site/offMarketCard4.jpg" class="absolute inset-0 w-full h-full object-contain" autoplay loop preload="auto">
            <source src="https://assets.towerhunt.com/site/cardReviewBlackBackground0.webm" type='video/webm'>
            <source src="https://assets.towerhunt.com/site/cardReviewBlackBackground1.mp4" type='video/mp4'>
            <source src="https://assets.towerhunt.com/site/cardReviewBlackBackground0.ogg" type='video/ogg'>
            <p>Your browser does not support the video element.</p>
          </video>
        </div>
      </main>

      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="relative mt-6 lg:mt-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div class="relative">
            <h4 class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
              Actionable deal leads
            </h4>
            <p class="mt-3 text-lg leading-7 text-gray-500">
              Each Hunter Pack contains 3-5 anonymized property cards that are easy to react to. When you like one, it's easy to unlock cap rate data and contact the owner.
            </p>

            <ul class="mt-10">
              <li>
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <!-- Heroicon name: badge-check -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-lg leading-6 font-medium text-gray-900">Data you can trust</h5>
                    <p class="mt-2 text-base leading-6 text-gray-500">
                      Card data is provided by the property owner and is reviewed by our team for consistency and reliability.
                    </p>
                  </div>
                </div>
              </li>
              <li class="mt-10">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <!-- Heroicon name: lightning-bolt -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-lg leading-6 font-medium text-gray-900">Fast and easy</h5>
                    <p class="mt-2 text-base leading-6 text-gray-500">
                      Reviewing cards is smooth and simple on any device. You can process an entire card pack, contact owners, and be back to work in seconds.
                    </p>
                  </div>
                </div>
              </li>
              <li class="mt-10">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <!-- Heroicon name: puzzle -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-lg leading-6 font-medium text-gray-900">Exclusive data</h5>
                    <p class="mt-2 text-base leading-6 text-gray-500">
                      The info we provide isn't available anywhere else. Use it to quickly make a sound read even without knowing the address.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="mt-10 -mx-4 lg:mt-0">
            <img class="relative mx-auto rounded-md" width="400" src="https://assets.towerhunt.com/site/offMarketCard3.jpg" alt="Off-market property snapshot card">
          </div>
        </div>

        <div class="max-w-screen-xl mx-auto pt-16">
          <img class="relative rounded-lg shadow-lg" src="https://assets.towerhunt.com/site/offMarketWatchlist0.jpg" alt="App screenshot">
        </div>
      </div>
    </div>

    <!-- Branded FAQ -->
    <div class="bg-indigo-900">
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 class="text-3xl leading-9 font-extrabold text-white">
          Frequently asked questions
        </h2>
        <div class="mt-6 border-t border-indigo-400 border-opacity-25 pt-10">
          <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                What are Hunter Packs?
              </template>
              <template v-slot:secondary>
                A Hunter Pack is a collection of 3-5 properties in your markets (based on location and property type). The properties aren't necessarily for sale, financing, or JV, but the owner is interested in gauging market interest. Each property is displayed on its own card. The information shown provides a current performance snapshot that you use to determine your interest.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                What is the map on the pack showing me?
              </template>
              <template v-slot:secondary>
                The map on each pack displays the generalized locations of the enclosed properties. Each shaded area on the map corresponds to a property, and the property may be anywhere within the shaded area.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                What kinds of properties are in the packs?
              </template>
              <template v-slot:secondary>
                The initial card design supports apartment properties and single- or multi-tenant industrial, office, lab, medical office, and retail properties. Our product road map includes support for additional property types, type-specific attributes (e.g., clear heights for industrial), and development sites.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                I'm seeing properties that are too big or small. Can I fix this?
              </template>
              <template v-slot:secondary>
                Your markets govern the kinds of cards you'll see. You can apply property size limits for any of your markets.<template v-if="signedIn">&nbsp;Go to <router-link :to="`/contacts/${currentUser.token}`" class="underline">your profile</router-link> and click into a market to edit it.</template>
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                What do I get when I open a pack?
              </template>
              <template v-slot:secondary>
                Opening a pack allows you to view and rate its property cards and add cards you like to your personal collection. You can unlock aggregated cap rate data for each card by submitting an anonymous good faith cap rate estimate. Finally, you're able to contact a property's owner on a card-by-card basis.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                Where does the cap rate data come from?
              </template>
              <template v-slot:secondary>
                Anyone who reviews an off-market card may submit an anonymous good faith cap rate estimate. People who submit a good faith estimate are able to then view the aggregated, anonymized data set once a sufficient number of submissions have been made. The data is displayed in a box plot, which helps show the distribution and any outliers.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                Why won't you show me property addresses?
              </template>
              <template v-slot:secondary>
                Property owners create these cards to measure market interest without any further obligation. To protect the owner's privacy, property cards are anonymized. The format is intentionally designed to balance an investor's (or broker's) desire to evaluate the opportunity with the owner's need for privacy.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                How do I know these properties aren't fake?
              </template>
              <template v-slot:secondary>
                Tower Hunt reviews and standardizes the property data that the owner is required to provide. Our team has over $11 billion of closed deal experience across a variety of asset classes and transaction types. We use this to produce consistent, reliable cards that are well-suited to investment screening.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                How do I contact the owner of a property I'm interested in?
              </template>
              <template v-slot:secondary>
                Go to your <router-link to="/off-market/watchlist" class="underline">off-market assets watchlist</router-link>. Press the "View owner motivation" button at the bottom of any card and then submit an anonymous, good faith cap rate range estimate. The owner's deal motivation and the cost to send a message will appear.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                What does the owner see when I send a message?
              </template>
              <template v-slot:secondary>
                Your message is kept separate from your anonymous cap rate range estimate. The message includes your photo, name, company, industry role, and a link to your Tower Hunt profile.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                Does my cap rate estimate affect my chances of hearing back from the owner?
              </template>
              <template v-slot:secondary>
                No. Aggregated, anonymized cap rate data is viewable once enough responses are received. You are never identified as having submitted a particular cap rate range.
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                Why hasn't the owner responded to me?
              </template>
              <template v-slot:secondary>
                The owner has no obligation to reply to you. Whether or not you receive a reply depends upon, among other things, the owner's deal motivation, the level of interest in the property, the content of your message, and the completeness of your Tower Hunt profile (if the owner doesn't already know you).
              </template>
            </text-accordian>
            <text-accordian primary-color="white" secondary-color="indigo-200">
              <template v-slot:primary>
                Can I create a card for a property that I own?
              </template>
              <template v-slot:secondary>
                Yes! Please visit our <router-link to="/off-market/card-manager" class="underline">page for property owners</router-link> and apply! Learn more about measuring demand for your properties <router-link to="/product/gauge" class="underline">here</router-link>.
              </template>
            </text-accordian>
          </dl>
        </div>
      </div>
    </div>

    <!-- CTA section -->
    <div v-if="!signedIn" class="bg-indigo-50">
      <div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-indigo-900 sm:text-4xl sm:leading-10">
          <span class="block">Ready to make things happen?</span>
          <span class="block text-indigo-600">Create a profile today.</span>
        </h2>
        <div class="mt-8 flex lg:flex-shrink-0 lg:mt-0">
          <div class="inline-flex rounded-md shadow">
            <router-link to="/start/1" class="bg-indigo-600 border border-transparent rounded-md py-3 px-5 inline-flex items-center justify-center text-base leading-6 font-medium text-white hover:bg-indigo-500 focus:outline-none focus:ring">
              Get started for free
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Simple footer -->
    <marketing-footer />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MarketingFooter from "../components/marketing-footer";
import ProductMarketingTopBar from "../components/product-marketing-top-bar";
import TextAccordian from "../components/text-accordian.vue";

export default {
  components: { MarketingFooter, ProductMarketingTopBar, TextAccordian },
  computed: {
    ...mapGetters(["signedIn"]),
    ...mapState(["currentUser"])
  },
  mounted() {
    document.title = "Off-Market Leads | Tower Hunt";
  }
};
</script>
