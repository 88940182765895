<template>
  <div class="wrapper">
    <div class="content-wrap">
      <section class="vertical">
        <option-select
          v-for="option in matchingOptions"
          :key="option.name"
          @hover="hover(option.value)"
          @vacate="vacate"
          option-type="propertyPossibleUse"
          setter-name="addPossibleUseToProperty"
          forgetter-name="removePossibleUseFromProperty"
          setter-type="commit"
          :store-record="possibleUses"
          :option-record="option"
          layout="vertical"
          :options-count="matchingOptions.length" />
      </section>
      <option-description v-if="hovering && description" :description="description" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import OptionDescription from "./option-description";
import useTypes from "../../use-types";

export default {
  components: { OptionDescription },
  props: ["dealId"],
  data() {
    return {
      options: useTypes,
      hovering: null
    };
  },
  computed: {
    ...mapState(["activeProperty"]),
    ...mapGetters(["creatingDeal"]),
    possibleUses() {
      return this.activeProperty.possibleUses;
    },
    matchingOptions() {
      return this.options;
    },
    optionData() {
      return _.find(this.matchingOptions, { value: this.hovering });
    },
    description() {
      if (this.optionData && this.optionData.description) {
        return this.optionData.description;
      } else {
        return null;
      }
    }
  },
  mounted() {
    this.$scrollTo("#property-top", 500, {
      container: "#modal-container"
    });
  },
  beforeCreate() {
    this.$options.components.OptionSelect = require("./option-select.vue").default;
  },
  methods: {
    hover(option) {
      this.hovering = option;
    },
    vacate() {
      this.hovering = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.content-wrap {
  display: flex;
  flex-direction: column;
}
section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  pointer-events: none;
  &.vertical {
    flex-direction: column;
    align-items: center;
  }
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
