<template>
  <div class="wrapper">
    <heading>What loans do you focus on?</heading>
    <button @click="next" type="button" class="cta mb-2" :class="{ complete: true }" tabindex="999">
      <template v-if="hasTypes && targetId">Save</template>
      <template v-else-if="hasTypes">Next</template>
      <template v-else>Any type</template>
    </button>
    <div class="content-wrap">
      <section class="vertical">
        <option-select
          v-for="option in matchingOptions"
          :key="option.name"
          @hover="hover(option.value)"
          @vacate="vacate"
          option-type="onboardingLoanType"
          setter-name="addLoanToSelection"
          forgetter-name="removeLoanFromSelection"
          setter-type="commit"
          :store-record="targetLoanTypes"
          :option-record="option"
          layout="vertical"
          :options-count="matchingOptions.length" />
      </section>
      <option-description v-if="hovering && description" :description="description" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import loanTypes from "../../loan-types";
import Heading from "../../components/onboarding/heading";
import OptionDescription from "../../components/onboarding/option-description";
import OptionSelect from "../../components/onboarding/option-select";

export default {
  props: ["targetId"],
  data() {
    return {
      options: loanTypes,
      hovering: null
    };
  },
  mounted() {
    if (this.onboarding && !this.onboardingInProcess) {
      this.$router.push({ path: "/start/1" });
    } else if (this.creatingTarget && !this.targetCreationInProcess) {
      this.$router.push({ path: "/targets/new/1" });
    }
  },
  components: { Heading, OptionSelect, OptionDescription },
  computed: {
    ...mapState(["onboardingInProcess", "targetCreationInProcess"]),
    ...mapGetters(["onboarding", "creatingTarget"]),
    matchingOptions() {
      return this.options;
    },
    targetLoanTypes() {
      return this.$store.state.activeTarget.loans;
    },
    optionData() {
      return _.find(this.matchingOptions, { value: this.hovering });
    },
    description() {
      if (this.optionData && this.optionData.description) {
        return this.optionData.description;
      } else {
        return null;
      }
    },
    hasTypes() {
      return this.targetLoanTypes.length > 0;
    }
  },
  methods: {
    next() {
      if (this.targetId) {
        this.$store.dispatch("updateTarget", this.targetId);
        this.$store.commit("closeModal");
      } else {
        if (this.onboarding) {
          this.$router.push({ path: "/start/7" });
        } else if (this.creatingTarget) {
          this.$router.push({ path: "/targets/new/5" });
        }
      }
    },
    hover(option) {
      this.hovering = option;
    },
    vacate() {
      this.hovering = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.content-wrap {
  display: flex;
  flex-direction: column;
}
section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  pointer-events: none;
  &.horizontal {
    flex-wrap: wrap;
  }
  &.vertical {
    flex-direction: column;
    align-items: center;
  }
}
button.cta {
  margin: 20px 0;
  width: 330px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border: none;
  outline: none;
  background: #b7b7b7;
  color: #fff;
  &.complete {
    background: #5850ec;
    box-shadow: rgba(88, 80, 236, 0.75) 0px 10px 40px -10px;
    &:hover {
      background: #8DA2FB;
    }
  }
}
</style>
