import industryRoleDealTypes from "./industry-role-deal-types";
import industryRoles from "./industry-roles";
import jvRoles from "./jv-roles";
import loanTypes from "./loan-types";
import riskTypes from "./risk-types";
import useTypes from "./use-types";

let allTypes = {};

_.forEach(industryRoleDealTypes, (dealTypes, industryRole) => {
  allTypes[industryRole] =_.union(
    dealTypes,
    industryRoles,
    jvRoles,
    loanTypes,
    riskTypes,
    useTypes,
    [{ order: 11, name: "Parking", value: "Parking" }]
  );
});

export default allTypes;
