<template>
  <div class="px-4 mt-6 sm:px-6 lg:px-8">
    <ul v-if="packs.data.length > 0" class="mt-3 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <li v-for="pack in packs.data" :key="pack.id" class="overflow-hidden col-span-1 flex flex-col text-center bg-white rounded-lg shadow">
        <hunter-pack :pack="pack" />
      </li>

      <infinite-loading
        v-if="loaded && !endOfFeed"
        :identifier="currentUser.token"
        @infinite="incrementPacks"
      />
    </ul>
    <div v-else>
      <div class="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div class="text-center">
          <h1 class="text-base leading-6 font-semibold text-indigo-600 tracking-wide uppercase">Available packs</h1>
          <p class="mt-1 text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">Personalized packs of off-market asset cards</p>
          <p class="max-w-xl mt-5 mx-auto text-xl leading-7 text-gray-500">Packs are pre-filtered against your locations and property types. The newest available pack appears at the top of your newsfeed. <router-link to="/product/off-market" class="underline">Learn more</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HunterPack from "../components/cards/stacked/hunter-pack";
import InfiniteLoading from "vue-infinite-loading";
import api from "../api";

export default {
  components: { HunterPack, InfiniteLoading },
  data() {
    return {
      packs: {
        data: [],
        pagy: null
      },
      busy: false,
      endOfFeed: false,
      loaded: false
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    pagination() {
      return this.packs.pagy;
    }
  },
  mounted() {
    this.fetchPacks();
  },
  methods: {
    fetchPacks() {
      api.get(`available_property_snapshots_packs`).then(json => {
        this.packs = json.data;
        this.loaded = true;
      });
    },
    extendPacks({ data, pagy }) {
      this.packs.data = this.packs.data.concat(data);
      this.packs.pagy = pagy;
    },
    incrementPacks($state) {
      if (this.pagination) {
        const { count, to } = this.pagination;

        if (count === 0) {
          this.endOfFeed = true;
        } else if (to < count) {
          const cleanUrl = _.drop(this.pagination.next_url, 8).join("");

          this.busy = true;

          api.get(cleanUrl).then(json => {
            this.extendPacks(json.data);
            $state.loaded();
            this.busy = false;
          });
        } else {
          this.endOfFeed = true;
          $state.complete();
        }
      }
    }
  }
};
</script>
