<template>
  <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow">
    <div class="flex-1 flex flex-col p-4">
      <h3 class="mb-6 text-gray-900 text-sm leading-5 font-medium">Would you recommend this content?</h3>
      <h3 class="mb-2 text-2xl leading-7 font-bold" :class="rating ? 'text-gray-900' : 'text-gray-200'">{{ ratingLabel }}</h3>
      <span class="mx-auto relative z-0 inline-flex shadow-sm">
        <button @click="no" type="button" v-tooltip="'No'" :class="thumbColor('not_recommended', 'red-500')" class="relative inline-flex flex-1 items-center justify-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium hover:text-red-400 focus:z-10 focus:outline-none focus:border-gray-300 focus:ring-red active:bg-red-100 active:text-red-500" aria-label="Not recommended">
          <!-- Heroicon name: thumb-down -->
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z" />
          </svg>
        </button>
        <button @click="yes" type="button" v-tooltip="'Yes'" :class="thumbColor('recommended', 'green-300')" class="-ml-px relative inline-flex flex-1 items-center justify-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium hover:text-green-200 focus:z-10 focus:outline-none focus:border-green-300 focus:ring-green active:bg-green-100 active:text-green-300" aria-label="Recommended">
          <!-- Heroicon name: thumb-up -->
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
          </svg>
        </button>
      </span>
      <div v-if="rating" class="mt-3">
        <div class="flex justify-between">
          <label for="narrative" class="block text-sm font-medium leading-5 text-gray-700">Feedback</label>
          <span class="text-sm leading-5 text-gray-500" id="narrative-required">Required</span>
        </div>
        <div class="mt-1 relative rounded-md shadow-sm">
          <resizable-textarea>
            <textarea v-model="ratingNarrative" id="narrative" type="text" rows="3" placeholder="Please describe what you liked or disliked about this content." class="border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-sm py-3 px-4 block w-full"></textarea>
          </resizable-textarea>
        </div>
      </div>
      <div v-if="rating" class="mt-3 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
          <button @click="submitReview" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo sm:text-sm sm:leading-5">
            Post review
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
          <button @click="resetForm" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue sm:text-sm sm:leading-5">
            Cancel
          </button>
        </span>
      </div>
      <h3 class="mt-2 text-gray-500 text-xs leading-4">All feedback is anonymous.</h3>
    </div>
  </li>
</template>

<script>
import { mapState } from "vuex";
import ResizableTextarea from "../resizable-textarea";
import api from "../../api";

export default {
  components: { ResizableTextarea },
  props: ["purchaseable"],
  data() {
    return {
      rating: null,
      ratingNarrative: ""
    };
  },
  computed: {
    ...mapState(["adminMode"]),
    complete() {
      return this.rating && _.trim(this.ratingNarrative) !== "";
    },
    ratingLabel() {
      if (this.rating) {
        return this.rating === "not_recommended" ? "No" : "Yes";
      } else {
        return "Unrated";
      }
    }
  },
  methods: {
    thumbColor(rating, color) {
      const unrated = !this.rating;
      const selected = this.rating === rating;

      if (unrated || selected) {
        return `text-${color}`;
      } else {
        return "text-gray-200";
      }
    },
    no() {
      this.rating = "not_recommended";
    },
    yes() {
      this.rating = "recommended";
    },
    resetForm() {
      this.rating = null;
      this.ratingNarrative = "";
    },
    submitReview() {
      if (this.complete) {
        api
          .post(`purchaseables/${this.purchaseable.id}/review`, {
            rating: this.rating,
            ratingNarrative: this.ratingNarrative
          })
          .then(json => {
            if (!!analytics && !this.adminMode) {
              analytics.track("Premium content reviewed", {
                yearWeek: this.$store.state.yearWeek,
                rating: this.rating
              });
            }

            this.$store.dispatch("flash", "Thanks for your review!");
            this.$emit("refetch");
            this.purchaseable.reviewed = true;
          });
      }
    }
  }
};
</script>
