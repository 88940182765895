<template>
  <div class="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-4">
    <div v-if="content.type !== 'standalonePurchaseable'" class="bg-white overflow-hidden shadow rounded-lg">
      <div class="p-2">
        <div class="flex items-center">
          <div class="flex-shrink-0 bg-indigo-500 rounded-md p-2">
            <svg class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
              <path d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
            </svg>
          </div>
          <div class="ml-3 w-0 flex-1">
            <dl>
              <dt class="text-xs leading-5 font-medium text-gray-500 truncate">
                Thanked by
              </dt>
              <dd class="flex items-baseline">
                <div class="text-xl leading-8 font-semibold text-gray-900">
                  {{ content.pinCount || 0 }}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div v-if="content.commentable" @click="viewComments" class="bg-white overflow-hidden shadow rounded-lg cursor-pointer">
      <div class="p-2">
        <div class="flex items-center">
          <div class="flex-shrink-0 bg-indigo-500 rounded-md p-2">
            <svg class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
            </svg>
          </div>
          <div class="ml-3 w-0 flex-1">
            <dl>
              <dt class="text-xs leading-5 font-medium text-gray-500 truncate">
                Comments
              </dt>
              <dd class="flex items-baseline">
                <div class="text-xl leading-8 font-semibold text-gray-900">
                  {{ content.commentCount || 0 }}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div v-if="content.authored || currentUser.admin" class="bg-white overflow-hidden shadow rounded-lg" v-tooltip="'How many unique Tower Hunt users viewed this'">
      <div class="p-2">
        <div class="flex items-center">
          <div class="flex-shrink-0 bg-indigo-500 rounded-md p-2">
            <svg class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
              <path d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
          </div>
          <div class="ml-3 w-0 flex-1">
            <dl>
              <dt class="text-xs leading-5 font-medium text-gray-500 truncate">
                Uniq. views
              </dt>
              <dd class="flex items-baseline">
                <div class="text-xl leading-8 font-semibold text-gray-900">
                  {{ content.impressions || 0 }}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div v-if="(content.authored || currentUser.admin) && content.url" class="bg-white overflow-hidden shadow rounded-lg" v-tooltip="'How many times was the external link clicked'">
      <div class="p-2">
        <div class="flex items-center">
          <div class="flex-shrink-0 bg-indigo-500 rounded-md p-2">
            <svg class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
            </svg>
          </div>
          <div class="ml-3 w-0 flex-1">
            <dl>
              <dt class="text-xs leading-5 font-medium text-gray-500 truncate">
                Click-throughs
              </dt>
              <dd class="flex items-baseline">
                <div class="text-xl leading-8 font-semibold text-gray-900">
                  {{ content.externalLinkClickCount || 0 }}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Comments from "../comments";

export default {
  props: ["content"],
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },
  methods: {
    viewComments() {
      this.$store.commit("openSlideover", {
        component: Comments,
        props: {
          content: this.content
        }
      });

      if (!!analytics && !this.adminMode) {
        analytics.track("Viewed post comments", {
          yearWeek: this.$store.state.yearWeek
        });
      }
    }
  }
};
</script>
