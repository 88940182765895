<template>
  <div v-observe-visibility="{ callback: fetchContent, once: true }">
    <div v-if="content" class="divide-y divide-gray-200">
      <div class="w-full flex items-center justify-between p-3 space-x-3">
        <div class="flex-1 truncate">
          <div class="flex items-center space-x-3">
            <h3 class="text-gray-900 text-sm font-medium truncate">{{ content.name }}</h3>
          </div>
          <div class="mt-1 text-gray-500 text-xs truncate">{{ [content] | totalSize }}</div>
        </div>
        <img class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 object-cover object-center" :src="content.coverPhoto || content.mostRecentReferenceThumbnail || 'https://source.unsplash.com/collection/277102'" alt="">
      </div>
      <div>
        <div class="-mt-px flex divide-x divide-gray-200">
          <div class="-ml-px w-0 flex-1 flex">
            <a href="" @click.prevent="selectProperty" style="color: white !important;" class="relative w-0 flex-1 inline-flex items-center justify-center py-2 text-sm bg-green-500 hover:bg-green-600 text-white font-medium border border-transparent rounded-b-lg">
              <!-- Heroicon name: solid/badge-check -->
              <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
              <span class="ml-3">Use this property instead</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api";

export default {
  props: ["contentId", "contentType"],
  data() {
    return {
      content: null
    };
  },
  methods: {
    fetchContent() {
      switch (this.contentType) {
        case "Property":
          api.get(`properties/${this.contentId}`).then(json => {
            this.content = json.data;
          });
          break;
      }
    },
    selectProperty() {
      this.$store.commit("setDeduplicate", this.content);
      if (document.fullscreenElement) {
        document
          .exitFullscreen()
          .then(() => console.log(""))
          .catch(err => console.error(err));
      }
    }
  }
};
</script>
