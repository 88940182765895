<template>
  <div class="h-screen overflow-auto bg-gray-100">
    <div class="bg-gray-800 pb-32">
      <nav class="bg-gray-800">
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div class="flex items-center justify-between h-16 px-4 sm:px-0">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <router-link to="/">
                  <img
                    class="h-10 w-auto sm:h-12"
                    src="https://assets.towerhunt.com/site/Transparent_T0.png"
                    alt="Tower Hunt"
                  />
                </router-link>
              </div>
            </div>
            <topbar-right-avatar-cluster />
          </div>
        </div>
      </nav>
      <header class="py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <contact-header />
        </div>
      </header>
    </div>
    <main class="-mt-32" v-observe-visibility="{ callback: createImpression, once: true }">
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <contact-targets />
      </div>
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <contact-deals />
      </div>
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <contact-live-deals />
      </div>
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <contact-intel />
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ContactDeals from "../components/contact-deals.vue";
import ContactHeader from "../components/contact-header.vue";
import ContactIntel from "../components/contact-intel.vue";
import ContactLiveDeals from "../components/contact-live-deals.vue";
import ContactProfileWelcome from "../components/cards/contact-profile-welcome";
import ContactTargets from "../components/contact-targets.vue";
import TopbarRightAvatarCluster from "../components/topbar-right-avatar-cluster";

export default {
  components: {
    ContactDeals,
    ContactHeader,
    ContactIntel,
    ContactLiveDeals,
    ContactTargets,
    TopbarRightAvatarCluster
  },
  props: ["contactToken"],
  computed: {
    ...mapState(["loading", "currentUser", "modal", "adminMode"]),
    ...mapGetters([
      "signedIn",
      "contact",
      "userIsActiveContact",
      "ableToComposeProfileMeta"
    ]),
    tokenMismatch() {
      if (this.contactToken && this.contact) {
        return this.contactToken !== this.contact.token;
      } else {
        return true;
      }
    },
    shouldDisplayWelcome() {
      return (
        this.signedIn &&
        this.userIsActiveContact &&
        this.currentUser.contactProfileWelcome &&
        !this.modal
      );
    }
  },
  watch: {
    contactToken: {
      handler: function(newToken, oldToken) {
        if (newToken !== oldToken) {
          this.$store.commit("clearActiveContact");
          this.$store.dispatch("loadContactData", newToken).then(() => {
            this.displayWelcome();
            this.composeProfileMeta();
          });
          this.$store.dispatch("loadCreditTracking");
        }
      }
    },
    currentUser: {
      handler: function() {
        this.displayWelcome();
      }
    },
    ableToComposeProfileMeta: {
      handler() {
        this.composeProfileMeta();
      }
    },
    modal: {
      handler() {
        if (!this.modal) {
          this.$store.commit("clearTarget");
        }
      }
    }
  },
  mounted() {
    if (this.contactToken && this.tokenMismatch) {
      this.$store.commit("clearActiveContact");
      this.$store.dispatch("loadContactData", this.contactToken).then(() => {
        this.displayWelcome();
        this.composeProfileMeta();
      });
    } else {
      this.displayWelcome();
      this.composeProfileMeta();
    }
  },
  destroyed() {
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Tower Hunt is a local commercial real estate market tracker for local experts, by local experts. Earn money for local expertise, see off-market leads, and go beyond the headlines."
      );
  },
  methods: {
    displayWelcome() {
      if (!this.adminMode && this.shouldDisplayWelcome) {
        this.$store.commit("openModal", {
          component: ContactProfileWelcome,
          props: {}
        });
      }
    },
    createImpression(isVisible, entry) {
      if (!this.adminMode && isVisible) {
        this.$store.dispatch("createContactImpression", this.contactToken);
      }
    },
    composeProfileMeta() {
      if (this.ableToComposeProfileMeta) {
        const markets = this.$store.getters.contactTargets(null);
        const dealTypes = _.uniq(
          markets.flatMap(market => {
            if (market.deals.length === 0) {
              return [
                "Buy Equity",
                "Originate Debt",
                "Recap Equity",
                "Buy Debt",
                "Lease"
              ];
            } else {
              return market.deals.map(dealType => dealType.type);
            }
          })
        );
        const capitalMarkets =
          _.intersection(dealTypes, [
            "Buy Equity",
            "Originate Debt",
            "Recap Equity",
            "Buy Debt"
          ]).length > 0;
        const uses = _.uniq(
          markets.flatMap(market => {
            if (market.uses.length === 0) {
              return [
                "Office",
                "Retail",
                "Industrial",
                "Multifamily",
                "Hotel",
                "Healthcare",
                "Senior Housing",
                "Data Center",
                "Student Housing",
                "Self-Storage"
              ];
            } else {
              return market.uses.map(use => use.type);
            }
          })
        );
        const regions = _.uniq(
          markets.flatMap(market => market.regions.map(region => region.name))
        );
        const risks = _.uniq(
          markets.flatMap(market => {
            if (market.risks.length === 0) {
              return ["Stabilized", "Value-Add", "Distressed", "Development"]
            } else {
              return market.risks.map(risk => risk.type);
            }
          })
        );
        const deals = this.$store.state.activeContact.deals.data;
        const publicDeals = deals.some(deal => !deal.confidential);

        const introSentence = `Learn about ${this.contact.name}'s commercial real estate focus and deal history.`;
        const marketSentence = `Markets covered: ${markets
          .map(market => this.$options.filters.marketName(market))
          .join("; ")}.`;
        const dealTypesSentence = `Deal types: ${dealTypes
          .map(dealType => this.$options.filters.dealTypeAlias(dealType))
          .join(", ")}.`;
        const usesSentence = `Property uses: ${uses.join(", ")}.`;
        const regionsSentence = `Regions: ${regions.join(", ")}.`;
        const strategiesSentence = capitalMarkets
          ? `Strategies: ${risks.join(", ")}.`
          : "";
        const dealsSentence = publicDeals
          ? `Recent deals: ${deals.map(deal => deal.name).join("; ")}.`
          : "";

        const combinedContent = `${introSentence} ${marketSentence} ${dealTypesSentence} ${usesSentence} ${regionsSentence} ${strategiesSentence} ${dealsSentence}`;

        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", combinedContent);
      }
    }
  }
};
</script>
